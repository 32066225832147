import React from 'react'
import { motion } from 'framer-motion'

type FadeInSlideDownProps = {
  children: React.ReactNode
  delay?: number
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  style?: React.CSSProperties
}

const start = {
  y: -10,
  opacity: 0,
}

const end = {
  y: 0,
  opacity: 1,
}

const FadeInSlideDown = ({
  children,
  delay = 0,
  className,
  onClick,
  style,
}: FadeInSlideDownProps) => {
  return (
    <motion.div
      initial={start}
      animate={end}
      transition={{ duration: 0.2, delay }}
      className={className}
      onClick={onClick}
      style={style}
    >
      {children}
    </motion.div>
  )
}

export default FadeInSlideDown
