import { typedOrgsApi } from 'utils'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'

const useDeleteResponsiblePartyMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}'],
    deleteResponsiblePartyArgs
  >({
    mutationFn: deleteResponsibleParty,
    successMsg: t('Responsible party deleted'),
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => [
          'getResponsiblePartiesQuery',
          subscriberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/responsible-parties'],
          deleteResponsiblePartyArgs
        >((oldData, { responsiblePartyId }) => {
          if (!oldData) return {}

          const filteredData = _.orderBy(
            oldData.items?.filter(
              (filterRp) => filterRp.id !== responsiblePartyId
            ),
            'priority'
          )
          const newPriority = filteredData.map((party, index) => {
            return {
              ...party,
              priority: index + 1,
            }
          })
          return {
            ...oldData,
            items: newPriority,
          }
        }),
      },
    ],
  })

  return mutation
}
export default useDeleteResponsiblePartyMutation

type deleteResponsiblePartyArgs = {
  subscriberId: string
  responsiblePartyId: string
}

const deleteResponsibleParty = ({
  subscriberId,
  responsiblePartyId,
}: deleteResponsiblePartyArgs) =>
  typedOrgsApi.delete(
    '/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}',
    { pathParams: { subscriberId, responsiblePartyId } }
  )
