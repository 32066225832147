import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useAddressTypesQuery = () => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/seed-data/address-types'],
    KyError
  >('getAddressTypes', getAddressTypes)

  return query
}

export default useAddressTypesQuery

const getAddressTypes = async () => {
  return typedOrgsApi.get(`/api/seed-data/address-types`)
}
