import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import { motion } from 'framer-motion'
import { useEventListener } from 'hooks'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import tw from 'twin.macro'
import { isPresent } from 'utils'

const ITEM_OUTER_HEIGHT = 40

type AnchorPosition = {
  vertical: 'top' | 'center' | 'bottom'
  horizontal: 'left' | 'center' | 'right'
}

type DropdownProps = {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  parentRef: React.RefObject<HTMLDivElement>
  children: ReactNode
  parentAnchor?: AnchorPosition
  contentAnchor?: AnchorPosition
  isParentWidth?: boolean
  verticalOffset?: number
  horizontalOffset?: number
  disableBorder?: boolean
  className?: string
  animationType?: 'normal' | 'reverse' | 'simple' | 'simple-reverse'
  maxHeight?: number
  search?: boolean
  onSearch?: (searchedValue: string) => void
  scrollToIndex?: number
}

const Dropdown = ({
  visible,
  setVisible,
  parentRef,
  children,
  parentAnchor = { vertical: 'bottom', horizontal: 'right' }, //anchorOrigin
  contentAnchor = { vertical: 'top', horizontal: 'right' }, //transformOrigin
  isParentWidth = false,
  verticalOffset = 0,
  horizontalOffset = 0,
  disableBorder,
  className,
  animationType = 'normal',
  maxHeight = 240,
  search,
  onSearch,
  scrollToIndex,
}: DropdownProps) => {
  const parentEl = parentRef.current

  const scrollRef = useRef<OverlayScrollbarsComponent>(null)

  const searchRef = useRef<HTMLInputElement>(null)

  // A normal useRef on the child won't rerender this component if it changes
  const [contentRect, setContentRect] = useState<DOMRect | null>(null)

  const contentRef = useCallback((node: HTMLDivElement) => {
    if (isPresent(node)) {
      setContentRect(node.getBoundingClientRect())
    }
  }, [])

  // Scroll to the selected/highlighted item on the dropdown list
  useEffect(() => {
    if (scrollToIndex)
      scrollRef.current
        ?.osInstance()
        ?.scroll({ y: `${scrollToIndex * ITEM_OUTER_HEIGHT}px` })
  }, [scrollToIndex, visible])

  // if a click is outside of the dropdown, set the visibility to false
  useEventListener({
    eventName: 'mousedown',
    handler: (event) => {
      if (visible && parentEl && !parentEl.contains(event.target as Node)) {
        // TODO: find out why this visible never gets set to false, visible is always true
        setVisible(false)
      }
    },
  })

  useEffect(() => {
    if (visible) searchRef.current?.focus()
  }, [visible])
  if (!visible || !parentEl) return null

  const position = calculatePosition(
    contentRect,
    parentEl,
    parentAnchor,
    contentAnchor,
    verticalOffset,
    horizontalOffset
  )

  return (
    <motion.div
      ref={contentRef}
      variants={
        ({
          normal: normalDropdownVariants,
          reverse: reverseDropdownVariants,
          simple: simpleDropdownVariants,
          'simple-reverse': simpleReverseDropdownVariants,
        } as const)[animationType]
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      animate={visible ? 'visible' : 'visible'}
      initial="hidden"
      className={clsx(
        'absolute bg-white shadow-lg z-20 rounded-lg overflow-hidden',
        disableBorder ? '' : 'border border-gray-200',
        className
      )}
      style={{
        top: position.top,
        left: position.left,
        width: isParentWidth ? `${parentEl.offsetWidth}px` : undefined,
        minWidth: isParentWidth ? `${parentEl.offsetWidth}px` : `auto`,
      }}
    >
      {search ? (
        <SearchContainer>
          <SearchIcon type="search" />
          <Search
            ref={searchRef}
            onChange={(e) => {
              if (onSearch) onSearch(e.target.value)
            }}
            placeholder="Search..."
          />
        </SearchContainer>
      ) : null}

      <OverlayScrollbarsComponent
        ref={scrollRef}
        options={{
          scrollbars: {
            autoHide: 'scroll',
          },
        }}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        {/* children must be an array to stagger framer-motion animations */}
        {React.Children.map(children, (child) => {
          if (!child) return null

          return React.cloneElement(child as React.ReactElement, {
            // @ts-expect-error the react types hate this so much
            ...child.props,

            // disable item animations if the animationType is simple
            disableAnimations:
              animationType === 'simple' || animationType === 'simple-reverse',
          })
        })}
        {/* {React.Children.toArray(children)} */}
      </OverlayScrollbarsComponent>
    </motion.div>
  )
}

export default Dropdown

// no stagger for this one
const simpleDropdownVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    y: -10,
  },
}

// stagger the animations for each dropdown item
const normalDropdownVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.025,
      delayChildren: 0,
      duration: 0.2,
    },
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: -10,
  },
}

// stagger the animations for each dropdown item
const reverseDropdownVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.075,
      delayChildren: 0,
      duration: 0.2,
    },
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 10,
  },
}

// no stagger for this one
const simpleReverseDropdownVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    y: 10,
  },
}

const calculatePosition = (
  contentRect: DOMRect | null,
  parentEl: HTMLDivElement,
  parentAnchor: AnchorPosition,
  contentAnchor: AnchorPosition,
  verticalOffset: number,
  horizontalOffset: number
) => {
  if (!contentRect)
    return {
      top: '0',
      left: '0',
    }
  const parentRect = parentEl.getBoundingClientRect()

  // Calculate position based on size of parent and content rects and the anchor parameters
  const top =
    getOffsetTop(parentRect, parentAnchor.vertical) -
    getOffsetTop(contentRect, contentAnchor.vertical) +
    verticalOffset
  const left =
    getOffsetLeft(parentRect, parentAnchor.horizontal) -
    getOffsetLeft(contentRect, contentAnchor.horizontal) +
    horizontalOffset
  return {
    top: `${Math.round(top)}px`,
    left: `${Math.round(left)}px`,
  }
}

const getOffsetTop = (rect: DOMRect, vertical: AnchorPosition['vertical']) => {
  let offset = 0

  if (vertical === 'center') {
    offset = rect.height / 2
  } else if (vertical === 'bottom') {
    offset = rect.height
  }

  return offset
}

const getOffsetLeft = (
  rect: DOMRect,
  horizontal: AnchorPosition['horizontal']
) => {
  let offset = 0

  if (horizontal === 'center') {
    offset = rect.width / 2
  } else if (horizontal === 'right') {
    offset = rect.width
  }

  return offset
}

const SearchContainer = tw.span`flex flex-row items-center p-2 border-b border-gray-200`

const SearchIcon = tw(Icon)`w-4 h-4 text-gray-400`

const Search = tw.input`flex w-full pl-2 focus:outline-none`
