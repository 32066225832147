import {
  AutoComplete,
  EntityLabel,
  LoadingIcon,
  Select,
  SelectItem,
  Switch,
  TextField,
  Icon,
} from 'elements'
import { DatePicker, Tooltip } from 'atlas'
import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useSalutationTypesQuery } from 'hooks/seed-data'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  useOrganizationListQuery,
  useSubscriberQuery,
  useUpdateSubscriberMutation,
} from 'hooks/organizations'
import { SingleProps } from 'elements/AutoComplete'
import { useSearchParam } from 'react-use'
import {
  useCreateSubscriberMutation,
  useSubscriberDetailsQuery,
  useUpdateSubscriberProfileMutation,
} from 'hooks/user-subscriber'
import { formatDate } from 'utils'
import { useNavigate } from 'hooks'
import { parse } from 'date-fns'
import { useWizardStepForm } from 'context/wizard-form'
import { ChildStepContainer } from 'elements/WizardForm'
import { useParams } from 'react-router'

type PersonalInformationForm = {
  personalInformation: Omit<PersonalInformation, 'dob'> & {
    dob?: Date
  }
  firstName: string
  lastName: string
  mrnNumber?: SubscriberForm['mrnNumber']
  nonPERS: boolean
}

const PersonalInformation = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const createSubscriberId = useSearchParam('subscriberId')
  const { subscriberId: editSubscriberId } = useParams()
  const subscriberId = createSubscriberId || editSubscriberId

  const [isPrivatePay, setIsPrivatePay] = useState(false)
  const [isOrder, setIsOrder] = useState(false)
  const [organizationFieldError, setOrganizationFieldError] = useState<
    'Organization is required' | undefined
  >()
  const [subscriberOrg, setSubscriberOrg] = useState<
    OrganizationListItem | undefined
  >(
    assumedOrganizationRole
      ? {
          id: assumedOrganizationRole.orgID,
          displayName: assumedOrganizationRole.orgDisplayName,
          status: '',
          legacyIdentifier: '',
          businessName: '',
        }
      : undefined
  )

  const subscriberDetailsQuery = useSubscriberDetailsQuery(subscriberId || '')
  const subscriberQuery = useSubscriberQuery(subscriberId || '')
  const salutationTypesQuery = useSalutationTypesQuery()
  const organizationListQuery = useOrganizationListQuery({
    disabled: !!assumedOrganizationRole,
  })
  const createSubscriberMutation = useCreateSubscriberMutation()
  const updateSubscriberProfileMutation = useUpdateSubscriberProfileMutation()
  const updateSubscriberMutation = useUpdateSubscriberMutation({
    showSuccessMsg: false,
  })

  // NOTE: this useEffect MUST be executed before the useWizardStepForm on each render
  // so the onSyncWithApi logic overwrites this if both executed on the same render
  useEffect(() => {
    // set organization to undefined if isPrivatePay set to true
    if (isPrivatePay) setSubscriberOrg(undefined)
  }, [isPrivatePay])

  const { errors, control } = useWizardStepForm<
    PersonalInformationForm,
    SubscriberProfile
  >({
    validationFn: () => {
      if (!(isPrivatePay || subscriberOrg))
        // manually validate organization field
        setOrganizationFieldError('Organization is required')

      // if custom validation passes return true
      return !!(isPrivatePay || subscriberOrg)
    },
    apiData: subscriberDetailsQuery.data
      ? {
          ...subscriberDetailsQuery.data,
          organizationId: subscriberQuery.data?.organization?.id,
          metadata: subscriberQuery.data?.integrationMetadata,
        }
      : undefined,
    customFields: {
      type: { value: isPrivatePay ? 'Private' : 'HCO' },
      organizationId: { value: subscriberOrg?.id },
    },
    apiToForm: (apiData) => ({
      ...apiData,
      personalInformation: {
        ...apiData.personalInformation,
        dob: apiData.personalInformation?.dob
          ? parse(apiData.personalInformation.dob, 'MM/d/yyyy', new Date())
          : undefined,
      },
      firstName: subscriberQuery.data?.person.firstName || '',
      lastName: subscriberQuery.data?.person.lastName || '',
      nonPERS: subscriberQuery.data?.nonPERS || false,
    }),
    formSyncDependencies: [
      subscriberQuery.data,
      organizationListQuery.data,
      assumedOrganizationRole,
    ],
    onSyncWithApi: () => {
      setIsPrivatePay(!subscriberQuery.data?.organization)
      setSubscriberOrg(
        assumedOrganizationRole
          ? {
              id: assumedOrganizationRole.orgID,
              displayName: assumedOrganizationRole.orgDisplayName,
              status: '',
              legacyIdentifier: '',
              businessName: '',
            }
          : organizationListQuery.data?.items.find(
              (org) => org.id === subscriberQuery.data?.organization?.id
            )
      )
    },
    submitFn: (formData) => {
      // map the local form type to the api POST request type
      const subscriberForm: SubscriberForm & {
        firstName: string
        lastName: string
        nonPERS: boolean
      } = {
        ...formData,
        personalInformation: {
          ...Object.fromEntries(
            Object.entries(formData.personalInformation).filter(
              // remove any falsey properties from personalInformation
              ([, value]) => !!value
            )
          ),
          ...(formData.personalInformation.dob
            ? {
                dob: formatDate(formData.personalInformation.dob, 'MM/d/yyyy'),
              }
            : {}),
        },
        type: isPrivatePay ? 'Private' : 'HCO',
        organizationId: subscriberOrg?.id,
        sourceInformation: {
          creationSource: 'ADDI',
          organization: subscriberOrg,
        },
      }

      if (subscriberId) {
        updateSubscriberMutation.mutate({
          subscriberId,
          subscriberForm,
        })

        return updateSubscriberProfileMutation.mutateAsync({
          subscriberId,
          subscriberForm,
        })
      } else
        return createSubscriberMutation.mutateAsync(subscriberForm, {
          onSuccess: (subscriber) =>
            // save subscriberId to the searchParams
            navigate({
              searchParams: { subscriberId: subscriber.id },
            }),
        })
    },
    dependencies: [
      isPrivatePay,
      subscriberOrg,
      subscriberId,
      organizationListQuery.data,
    ],
  })

  // reset organization error if filled
  useEffect(() => {
    if (subscriberOrg && organizationFieldError)
      setOrganizationFieldError(undefined)
  }, [subscriberOrg, organizationFieldError])

  if (
    !salutationTypesQuery.data?.items ||
    (subscriberId && !subscriberDetailsQuery.data)
  )
    return <LoadingIcon />

  // if subscriberId exists show the readonly version
  return (
    <>
      <ChildStepContainer>
        {!assumedOrganizationRole ? (
          <>
            <Title>{t('Organization')}</Title>
            <RowContainer>
              <PrivatePaySwitch
                onChange={setIsPrivatePay}
                checked={isPrivatePay}
                label={t('Private Pay Subscriber?')}
                disabled={!!subscriberId}
              />
              <OrganizationAutoComplete<
                React.FC<SingleProps<OrganizationListItem | null>>
              >
                label={t('Organization *')}
                options={organizationListQuery.data?.items || []}
                single
                onChange={(subscriberOrg) =>
                  setSubscriberOrg(subscriberOrg || undefined)
                }
                optionLabel={(option) => option?.businessName || ''}
                selectedOption={subscriberOrg}
                renderOption={(props, option) =>
                  option ? (
                    <AutoCompleteOption {...props}>
                      <EntityLabel id={option.id} name={option.businessName} />
                    </AutoCompleteOption>
                  ) : null
                }
                renderTags={(options) =>
                  options.map((option) =>
                    option ? (
                      <EntityLabel id={option.id} name={option.businessName} />
                    ) : null
                  )
                }
                error={organizationFieldError}
                disabled={!!subscriberId}
                hidden={isPrivatePay}
              />
            </RowContainer>
            <SubscriberTitle>{t('Subscriber Profile')}</SubscriberTitle>
          </>
        ) : null}
        <RowContainer>
          <Controller
            control={control}
            as={
              <Select
                label={t('Prefix')}
                variant="outlined"
                fullWidth={false}
                selectClassName="w-24"
                required
              >
                {salutationTypesQuery.data.items.map((salutation, index) => (
                  <SelectItem value={salutation.name} key={index}>
                    {salutation.name}
                  </SelectItem>
                ))}
              </Select>
            }
            name="personalInformation.salutation"
            defaultValue={
              (
                salutationTypesQuery.data.items.find(
                  (salutation) => salutation.name === 'Mr.'
                ) || salutationTypesQuery.data.items[0]
              ).name
            }
          />
          <Controller
            control={control}
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="firstName"
            label={t('First Name')}
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('First Name is required') }}
            error={errors.firstName?.message}
            className="w-56 mt-4"
            required
          />
          <Controller
            control={control}
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="lastName"
            label={t('Last Name')}
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Last Name is required') }}
            error={errors.lastName?.message}
            className="w-56 mt-4"
            required
          />
          <Controller
            control={control}
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="personalInformation.suffix"
            label={t('Suffix')}
            className="w-20 mt-4"
          />
        </RowContainer>
        <RowContainer>
          <Controller
            control={control}
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="personalInformation.nickName"
            label={t('Nickname')}
            className="w-56 mt-4"
          />
          <Controller
            control={control}
            as={DatePicker}
            datepickerDefaultDate={new Date(1960, 0, 1)}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="personalInformation.dob"
            label={t('Date of Birth')}
            dense={false}
            className="mt-4"
          />
        </RowContainer>
        <RowContainer>
          <Controller
            control={control}
            as={
              <Select
                label={t('Gender *')}
                variant="outlined"
                fullWidth={false}
                selectClassName="w-28"
              >
                {genderOptions.map((gender, index) => (
                  <SelectItem value={gender} key={index}>
                    {gender}
                  </SelectItem>
                ))}
              </Select>
            }
            name="personalInformation.gender"
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Gender is required') }}
            error={errors.personalInformation?.gender?.message}
            defaultValue=""
          />
          <Controller
            control={control}
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="mrnNumber"
            label={t('MRN #')}
            className="w-56 mt-4"
          />
        </RowContainer>
        {!editSubscriberId ? (
          <RowContainer>
            <OrderSwitch
              onChange={setIsOrder}
              checked={isOrder}
              label={t('I want to place an Order for this Subscriber')}
            />
          </RowContainer>
        ) : null}
        <RowContainer>
          <Controller
            control={control}
            render={({ onChange, value }) => (
              <Switch
                label={t('Non-PERS Subscriber')}
                onChange={onChange}
                checked={value}
              />
            )}
            name={'nonPERS'}
          />
          <InfoTooltip
            content={
              <TooltipText>
                {t(
                  'Use this option to create/edit a Subscriber that will not use PERS (i.e. Rapid Response Management System)'
                ) + '.'}
              </TooltipText>
            }
          >
            <InfoIcon type="info" />
          </InfoTooltip>
        </RowContainer>
      </ChildStepContainer>
    </>
  )
}

export default PersonalInformation

const RowContainer = tw.div`flex gap-4`

const Title = tw.h3`text-xl font-semibold flex-grow`

const SubscriberTitle = tw.h3`text-xl font-semibold mb-2`

const PrivatePaySwitch = tw(Switch)`mb-3`

const OrderSwitch = tw(Switch)`my-2`

const OrganizationAutoComplete = styled(AutoComplete)<{
  hidden: boolean
}>(({ hidden }) => [
  tw`ml-4 mt-2 -mb-2 flex-grow max-w-md`,
  hidden && tw`invisible`,
])

const AutoCompleteOption = tw.li`cursor-pointer hover:bg-gray-100 py-2 px-4`

const genderOptions: PersonalInformation['gender'][] = [
  'Male',
  'Female',
  'Non-Binary',
  'Other',
]

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`h-fit-content`

const TooltipText = tw.p`w-60`
