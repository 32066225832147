import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

const useOrganizationHierarchyQuery = (orgId?: string) => {
  const query = useQuery<OrganizationHierarchyItem[], KyError>(
    ['getOrganizationHierarchy', orgId],
    () => getOrganizationList(orgId),
    {
      enabled: !!orgId,
    }
  )

  return query
}

export default useOrganizationHierarchyQuery

// get list user's organizations
type getOrganizationList = (
  orgId: string | undefined
) => Promise<OrganizationHierarchyItem[]>

const getOrganizationList: getOrganizationList = async (orgId) => {
  if (!orgId) return []

  const result = await typedOrgsApi.get(
    '/api/organizations/{organizationId}/hierarchy',
    {
      searchParams: [
        ['mode', 'flat'],
        ['take', 10000],
      ],
      pathParams: { organizationId: orgId },
    }
  )

  // typegaurd for union type (we should only be getting the flat variant)
  if (!('items' in result) || !Array.isArray(result.items)) return []

  return result.items
}
