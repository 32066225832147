import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useSubscriberDetailsQuery = (subscriberId: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}'],
    KyError
  >(
    ['getSubscriberDetails', subscriberId],
    () => getSubscriberDetails(subscriberId),
    { enabled: !!subscriberId }
  )

  return query
}

export default useSubscriberDetailsQuery

const getSubscriberDetails = (subscriberId: string) =>
  typedOrgsApi.get('/subscribers-profile/{subscriberId}', {
    pathParams: { subscriberId },
  })
