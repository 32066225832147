import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CopyToClipboardButton } from 'elements'
import { Button } from 'atlas'
import { useWebhookSecretQuery } from 'hooks/settings-webhooks'
import tw from 'twin.macro'

type SecretKeyDisplayProps = {
  subscriptionId: string
  disabled?: boolean
}

const SecretKeyDisplay = ({
  subscriptionId,
  disabled,
}: SecretKeyDisplayProps) => {
  const [isSecretRevealed, setIsSecretRevealed] = useState(false)
  const {
    data: webhookSecret,
    isSuccess: isSecretLoaded,
    isLoading: isLoadingSecret,
  } = useWebhookSecretQuery({
    subscriptionId,
    enabled: isSecretRevealed,
  })
  const { t } = useTranslation()

  return (
    <SecretKeyContainer>
      <Title>{t('Webhook Secret')}</Title>
      <Clipboard>
        {isSecretRevealed && isSecretLoaded ? (
          <>
            <CopyToClipboardButton value={webhookSecret?.secretKey}>
              {webhookSecret?.secretKey}
            </CopyToClipboardButton>
            <Button
              onClick={() => {
                setIsSecretRevealed(false)
              }}
              type="primary-link"
            >
              {t('Hide')}
            </Button>
          </>
        ) : (
          <Button
            disabled={disabled}
            onClick={() => setIsSecretRevealed(true)}
            isLoading={isLoadingSecret}
          >
            {t('Reveal Secret')}
          </Button>
        )}
      </Clipboard>
    </SecretKeyContainer>
  )
}

export default SecretKeyDisplay

const Clipboard = tw.div`bg-white flex justify-between items-center p-4 border mb-1 h-16 transition-all`

const SecretKeyContainer = tw.div`mb-8`

const Title = tw.h3`text-xl font-semibold mb-4`
