import React from 'react'
import { FadeInSlideDown } from 'animations'
import { Layout, Statistic } from 'elements'
import { useDelay } from 'hooks'
import {
  useOrganizationListQuery,
  usePinnedOrganizationsQuery,
  usePinnedSubscribersQuery,
  useSubscribersQuery,
} from 'hooks/organizations'
import { useGlobalMemberStatistics } from 'hooks/statistics'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { isPresent } from 'utils/type-guards'
import PinnedOrganizations from './PinnedOrganizations'
import PinnedSubscribers from './PinnedSubscribers'

const ECGAdminOverview = () => {
  const globalMemberStatistics = useGlobalMemberStatistics()
  const pinnedOrganizationQuery = usePinnedOrganizationsQuery()
  const organizationListQuery = useOrganizationListQuery()
  const pinnedSubscribersQuery = usePinnedSubscribersQuery()
  const subscribersListQuery = useSubscribersQuery()
  const { t } = useTranslation()
  const delay = useDelay()

  return (
    <Layout
      title={t('Global Platform Overview')}
      description={t('Gain a quick understanding of the ECG System metrics.')}
      isLoading={
        globalMemberStatistics.isLoading ||
        pinnedOrganizationQuery.isLoading ||
        organizationListQuery.isLoading ||
        pinnedSubscribersQuery.isLoading ||
        subscribersListQuery.isLoading
      }
    >
      <StatisticsGrid>
        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="organizations"
            iconColor="yellow"
            label="Total Organizations"
            value={globalMemberStatistics.data?.organizations.total}
            to="/organizations"
          />
        </FadeInSlideDown>

        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="subscribers"
            iconColor="purple"
            label="Total Subscribers"
            value={globalMemberStatistics.data?.subscribers.total}
            to="/subscribers"
          />{' '}
        </FadeInSlideDown>

        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="partners"
            iconColor="green"
            label="Total Partners"
            value={globalMemberStatistics.data?.partners.total}
            to="/partners"
          />
        </FadeInSlideDown>
      </StatisticsGrid>

      <PinnedGrid>
        {pinnedOrganizationQuery.data && organizationListQuery.data ? (
          <FadeInSlideDown delay={delay()}>
            <PinnedOrganizations
              pinnedOrganizations={pinnedOrganizationQuery.data
                .map((orgId) =>
                  organizationListQuery.data.items.find(
                    (org) => org.id === orgId
                  )
                )
                // filter out the undefined's
                .filter(isPresent)}
            />
          </FadeInSlideDown>
        ) : null}

        {pinnedSubscribersQuery.data && subscribersListQuery.data ? (
          <FadeInSlideDown delay={delay()}>
            <PinnedSubscribers
              pinnedSubscribers={pinnedSubscribersQuery.data
                .map((subId) =>
                  subscribersListQuery.data.items?.find(
                    (sub) => sub.id === subId
                  )
                )
                // filter out the undefined's
                .filter(isPresent)}
            />
          </FadeInSlideDown>
        ) : null}
      </PinnedGrid>
    </Layout>
  )
}

export default ECGAdminOverview

const StatisticsGrid = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-8`

const PinnedGrid = tw.div`grid grid-cols-1 xl:grid-cols-2 gap-4`
