import React from 'react'
import { Chip } from 'atlas'
import { InformationCard } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type StaffInfoCardProps = {
  staffInfo: StaffMember
}

const StaffInfoCard = ({ staffInfo }: StaffInfoCardProps) => {
  const { t } = useTranslation()

  return (
    <InformationCard>
      <InformationCard.Item
        label={t('Name')}
        value={`${staffInfo.person.firstName} ${staffInfo.person.lastName}`}
      />

      <InformationCard.Item
        label={t('Status')}
        value={
          staffInfo.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          )
        }
      />

      <InformationCard.Item
        label={t('Suffix')}
        value={staffInfo.person.suffix || '-'}
      />

      <InformationCard.Item
        label={t('Email')}
        value={staffInfo.person.emailAddress || '-'}
      />

      <InformationCard.Item
        label={t('ID')}
        value={<IDText>{staffInfo.id}</IDText>}
      />
    </InformationCard>
  )
}

export default StaffInfoCard

const IDText = tw.p`font-mono`
