import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { ArchivedPermissionsList } from 'components/access-control/permissions'
import { usePermissionsQuery } from 'hooks/access-control/permissions'

const ArchivedAccessControlPermissions = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const {
    data: permissions,
    isLoading: isLoadingPermissions,
  } = usePermissionsQuery()

  const { t } = useTranslation()

  return (
    <Layout
      title={t('Archived Permissions')}
      description={t('Restore or delete archived permissions.')}
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Permissions'), route: '/access-control/permissions' },
        { name: t('Archived Permissions') },
      ]}
      isLoading={isLoadingPermissions}
      isUnauthorized={
        !accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly')
      }
    >
      <ArchivedPermissionsList
        // Only show archived permissions
        data={
          permissions?.items?.filter(
            (permission) => permission.archiveInfo.archived
          ) || []
        }
      />
    </Layout>
  )
}

export default ArchivedAccessControlPermissions
