import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const usePhoneNumbersQuery = (subscriberId: string | null) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/phone-numbers'],
    KyError
  >(
    ['getSubscriberPhones', subscriberId],
    () => getSubscriberPhones(subscriberId || ''),
    { enabled: !!subscriberId }
  )

  return query
}

export default usePhoneNumbersQuery

const getSubscriberPhones = (subscriberId: string) =>
  typedOrgsApi.get('/subscribers-profile/{subscriberId}/phone-numbers', {
    pathParams: { subscriberId },
  })
