import * as React from 'react'
import clsx from 'clsx'
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepIconProps,
  StepConnector,
} from '@mui/material'
import { Icon } from 'elements'
import muiTheme from 'styles/material-theme'
import { ThemeProvider } from '@mui/material/styles'

function generateStepIcon(icon: IconType, isClickable: boolean) {
  const StepIcon = ({ active, completed }: StepIconProps) => {
    return (
      <div
        className={clsx(
          'flex rounded-full w-12 h-12 border-2 items-center justify-center transition',
          active && 'bg-blue-400 border-blue-400 text-white',
          !isClickable && completed && 'border-blue-400 text-blue-400',
          !(active || (!isClickable && completed)) &&
            'border-gray-500 text-gray-500'
        )}
      >
        <Icon type={icon} className="h-6 w-6" />
      </div>
    )
  }
  return StepIcon
}

type StepperProps = {
  steps: {
    label?: string
    icon?: IconType
  }[]
  activeStep: number
  setActiveStep?: (activeStep: number) => void
  navigatableSteps?: boolean
  className?: string
  orientation?: 'horizontal' | 'vertical'
}

const Stepper = ({
  steps,
  activeStep,
  setActiveStep,
  navigatableSteps = false,
  className,
  orientation = 'horizontal',
}: StepperProps) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <MuiStepper
        alternativeLabel={orientation === 'horizontal'}
        activeStep={activeStep}
        className={className}
        orientation={orientation}
        connector={
          <StepConnector
            style={
              orientation === 'vertical' ? { marginLeft: '23px' } : undefined
            }
            classes={{
              line:
                'h-full min-h-0' + (navigatableSteps ? ' border-gray-400' : ''),
            }}
          />
        }
      >
        {steps.map(({ label, icon }, stepNumber) => (
          <Step
            key={label}
            onClick={
              navigatableSteps ? () => setActiveStep?.(stepNumber) : undefined
            }
            classes={navigatableSteps ? { root: 'cursor-pointer' } : undefined}
          >
            <StepLabel
              {...(icon
                ? {
                    StepIconComponent: generateStepIcon(icon, navigatableSteps),
                  }
                : {})}
              classes={
                navigatableSteps ? { root: 'cursor-pointer' } : undefined
              }
            >
              <p
                className={clsx(
                  navigatableSteps
                    ? 'text-gray-500 hover:text-gray-800'
                    : [
                        stepNumber < activeStep && 'text-blue-400',
                        stepNumber === activeStep && 'text-blue-400',
                        stepNumber > activeStep && 'text-gray-500',
                      ]
                )}
              >
                {label}
              </p>
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </ThemeProvider>
  )
}

export default Stepper
