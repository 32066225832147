import React, { useState } from 'react'
import { InformationCard, Icon } from 'elements'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import tw from 'twin.macro'
import { parse } from 'date-fns'
import { formatDate } from 'utils'
import EditScheduleForm from './EditScheduleForm'

type AlertDeliveryRuleScheduleProps = {
  alertDeliveryRule: AlertDeliveryRule
}

const AlertDeliveryRuleSchedule = ({
  alertDeliveryRule,
}: AlertDeliveryRuleScheduleProps) => {
  const { t } = useTranslation()

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  return (
    <>
      <Header>
        <Title>{t('Schedule')}</Title>
        <EditIcon
          type="edit"
          onClick={() => setIsFormOpen(true)}
          data-testid="schedule-edit"
        />
      </Header>
      <InformationCard size="md">
        <InformationCard.Item
          label={t('Frequency')}
          value={
            <>
              {(() => {
                if (alertDeliveryRule.schedule.frequency === 'weekly')
                  return _.orderBy(
                    alertDeliveryRule.schedule.weekday,
                    (alertDeliveryRule) => weekDaySorter[alertDeliveryRule]
                  ).map((day: AlertWeekdays) => (
                    <DayOfTheWeekChip key={day} color="cyan">
                      {_.upperCase(weekdayMap[day])}
                    </DayOfTheWeekChip>
                  ))
                if (alertDeliveryRule.schedule.frequency === 'daily')
                  if (
                    alertDeliveryRule.schedule.interval &&
                    alertDeliveryRule.schedule.interval > 1
                  )
                    return (
                      <>
                        {t('Every ')}
                        {alertDeliveryRule.schedule.interval}
                        {t(' Days')}
                      </>
                    )
                  else return <>{t('Every Day')}</>
                if (alertDeliveryRule.schedule.frequency === 'hourly')
                  if (
                    alertDeliveryRule.schedule.interval &&
                    alertDeliveryRule.schedule.interval > 1
                  )
                    return (
                      <>
                        {t('Every ')}
                        {alertDeliveryRule.schedule.interval}
                        {t(' Hours')}
                      </>
                    )
                  else return <>{t('Every Hour')}</>
                if (alertDeliveryRule.schedule.frequency === 'minutely')
                  if (
                    alertDeliveryRule.schedule.interval &&
                    alertDeliveryRule.schedule.interval > 1
                  )
                    return (
                      <>
                        {t('Every ')}
                        {alertDeliveryRule.schedule.interval}
                        {t(' Minutes')}
                      </>
                    )
                  else return <>{t('Every Minute')}</>

                // default case: 'real-time'
                return 'Real-Time'
              })()}
            </>
          }
        />

        {alertDeliveryRule.schedule.frequency === 'weekly' ||
        alertDeliveryRule.schedule.frequency === 'daily' ? (
          <InformationCard.Item
            label={t('Time')}
            value={formatDate(
              parse(alertDeliveryRule.schedule.time || '', 'HHmm', new Date()),
              'h:mm a'
            )}
          />
        ) : null}
      </InformationCard>
      <EditScheduleForm
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        alertDeliveryRule={alertDeliveryRule}
      />
    </>
  )
}

export default AlertDeliveryRuleSchedule

const DayOfTheWeekChip = tw(Chip)`mr-1`

const weekDaySorter: { [key: string]: number } = {
  M: 1,
  T: 2,
  W: 3,
  H: 4,
  F: 5,
  S: 6,
  U: 7,
}

const weekdayMap: Record<AlertWeekdays, Weekdays> = {
  M: 'mon',
  T: 'tue',
  W: 'wed',
  H: 'thu',
  F: 'fri',
  S: 'sat',
  U: 'sun',
}

const Header = tw.div`flex items-center`

const Title = tw.h3`text-xl font-semibold mb-2`

const EditIcon = tw(Icon)`text-gray-600 hover:text-gray-900 mb-2 ml-2`
