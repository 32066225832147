import { useEffect } from 'react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { useAuth } from 'oidc-react'

const useLogRocket = () => {
  const auth = useAuth()
  useEffect(() => {
    // only log production environment events
    if (
      process.env.NODE_ENV === 'production' &&
      !window.Cypress &&
      auth?.userData
    ) {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID || '')
      setupLogRocketReact(LogRocket)
      LogRocket.identify(auth.userData.profile.username, {
        email: auth.userData.profile.email || '',
        name: auth.userData.profile.username,
      })
    }
  }, [auth?.userData])
}

export default useLogRocket
