import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useCreateOrgVitalThresholdsMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['POST']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds'],
    KyError<ErrorResponse>,
    CreateVitalThresholdsProps,
    | VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
    | undefined
  >(createOrgVitalThresholds, {
    onMutate: (mutationProps) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
      >(['vitalsThresholds', mutationProps.orgId])

      // optimistic update the query cache
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']['items']
      >(['vitalsThresholds', mutationProps.orgId], (oldThresholds) =>
        oldThresholds
          ? [...oldThresholds, thresholdsFormToObject(mutationProps)]
          : [thresholdsFormToObject(mutationProps)]
      )

      return snapshot
    },
    onSuccess: () => {
      // Notify user of successful thresholds creation
      success({
        message: t('Vital thresholds created'),
      })
    },
    onError: async (error, { orgId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
        | undefined
      >(['vitalsThresholds', orgId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { orgId }) => {
      // refetch from API to make sure the new threshold is in sync with the server
      queryCache.invalidateQueries(['vitalsThresholds', orgId])
    },
  })

  return mutation
}

export default useCreateOrgVitalThresholdsMutation

type CreateVitalThresholdsProps = {
  newVitalThresholds: VitalThresholdsForm
  orgId: string
  vitalSign: VitalAssignment
}

// Post new vital thresholds to backend
const createOrgVitalThresholds = async ({
  newVitalThresholds,
  orgId,
  vitalSign,
}: CreateVitalThresholdsProps) => {
  const request: VitalsAPIRequest['POST']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']['body'] = {
    acceptedDisclaimers: newVitalThresholds.acceptedDisclaimers,
    normalHigh: newVitalThresholds.normalHigh || 0,
    normalLow: newVitalThresholds.normalLow || 0,
    thresholds: newVitalThresholds.thresholds,
  }

  const result = await vitalsApi
    .post(`organizations/${orgId}/vital-signs/${vitalSign.id}/thresholds`, {
      json: request,
    })
    .json<
      VitalsAPIResponse['POST']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']
    >()

  return result
}

const thresholdsFormToObject = ({
  newVitalThresholds,
  vitalSign,
}: CreateVitalThresholdsProps): VitalThresholds => ({
  // @ts-expect-error bad swagger type
  vitalSign,
  thresholds: newVitalThresholds.thresholds,
  normalHigh: newVitalThresholds.normalHigh || 0,
  normalLow: newVitalThresholds.normalLow || 0,
  disclaimers: newVitalThresholds.acceptedDisclaimers,
})
