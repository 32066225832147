import React, { useState } from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import {
  ArchivedPoliciesList,
  CreateEditPolicyForm,
} from 'components/access-control/policies'
import { usePoliciesQuery } from 'hooks/access-control/policies'

const ArchivedAccessControlPolicies = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { data: policies, isLoading: isLoadingPolicies } = usePoliciesQuery()

  const [isCreateEditPolicyFormOpen, setIsCreateEditPolicyFormOpen] = useState(
    false
  )
  const [policy, setPolicy] = useState<Policy | undefined>()

  const { t } = useTranslation()

  const handleEditPolicy = (policy: Policy) => {
    setPolicy(policy)
    setIsCreateEditPolicyFormOpen(true)
  }

  const handlePolicyFormToggle = (isFormOpen: boolean) => {
    // If the form is closing, reset the selected Permission to edit
    if (isFormOpen) setPolicy(undefined)

    // Call the normal setState
    setIsCreateEditPolicyFormOpen(isFormOpen)
  }

  return (
    <Layout
      title={t('Archived Policies')}
      description={t('Manage, restore, or delete archived policies.')}
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Policies'), route: '/access-control/policies' },
        { name: t('Archived Policies') },
      ]}
      isLoading={isLoadingPolicies}
      isUnauthorized={
        !accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly')
      }
    >
      <ArchivedPoliciesList
        // Only show archived policies
        data={
          policies?.items?.filter((policy) => policy.archiveInfo.archived) || []
        }
        onEditPolicy={handleEditPolicy}
      />
      <CreateEditPolicyForm
        isFormOpen={isCreateEditPolicyFormOpen}
        setIsFormOpen={handlePolicyFormToggle}
        policy={policy}
      />
    </Layout>
  )
}

export default ArchivedAccessControlPolicies
