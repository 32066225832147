import React, { useMemo, useCallback, useState } from 'react'
import {
  List,
  EntityLabel,
  ConfirmationDialogs,
  EllipsisDropdown,
  entityStatusToColor,
} from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  TableInstance,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import CategoryFilter from 'elements/CategoryFilter'
import {
  useEntityLabelFilter,
  useEntityLabelSort,
  useStatusSort,
} from 'hooks/react-table'
import {
  useActivatePermissionMutation,
  ouGetPermissionsQueryToActivatePermission,
  useArchivePermissionMutation,
  ouGetPermissionsQueryToArchivePermission,
  useDeactivatePermissionMutation,
  ouGetPermissionsQueryToDeactivatePermission,
} from 'hooks/access-control/permissions'
import tw from 'twin.macro'

type PermissionsListProps = {
  data: Permission[]
  onEditPermission: (permission: Permission) => void
  baseDelay?: number
}

const PermissionsList = ({
  data,
  onEditPermission,
  baseDelay,
}: PermissionsListProps) => {
  const { t } = useTranslation()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['name'],
  })

  const {
    getEntityLabels,
    filterRowsByEntityLabel,
  } = useEntityLabelFilter<Permission>()

  const entityLabelSort = useEntityLabelSort<Permission>({
    accessor: (row) => row.module.name,
  })

  const tableData: Permission[] = useMemo(() => {
    return data
  }, [data])

  const statusSort = useStatusSort()

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Permission>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const code = row.original.code.toLowerCase()
        // optional when creating/editing, description may be null
        const description = String(row.original.description).toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          code.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<Permission>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.name}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortType: primaryLabelSort,
      },

      {
        id: 'MODULE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('MODULE'),
        accessor: (row) => (
          <EntityLabel
            size="md"
            name={row.module.name || ''}
            id={row.module.id || ''}
            to={`/access-control/modules/${row.module.id}`}
            statusTag={entityStatusToColor({
              isActive: row.module.activeInfo.active,
              isArchived: row.module.archiveInfo.archived,
            })}
          />
        ),
        Filter: (tableInstance: TableInstance<Permission>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="module"
            label={t('Module')}
            categories={getEntityLabels({
              tableInstance,
              accessor: (row) => row.module.name,
            })}
          />
        ),
        filter: filterRowsByEntityLabel,
        sortType: entityLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) =>
          row.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          ),
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => (
          <ActionsCell row={row} onEdit={() => onEditPermission(row)} />
        ),
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'permissions'
      )}`}
      emptyTitle={t('No Permissions Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
      baseDelay={baseDelay}
    />
  )
}

export default PermissionsList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: Permission
  onEdit: () => void
}

const ActionsCell = ({ row, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )

  const { mutate: archivePermission } = useArchivePermissionMutation()
  const { mutate: activatePermission } = useActivatePermissionMutation()
  const { mutate: deactivatePermission } = useDeactivatePermissionMutation()

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t(`Archive "${row.name}" Permission`),
      content: <p>{t('Are you sure you want to archive this permission?')}</p>,
      actions: (
        <Button
          onClick={() => {
            // archive the permission and update the queryCache
            archivePermission(row.id, {
              onSuccess: (_, permissionId) =>
                ouGetPermissionsQueryToArchivePermission({ permissionId }),
            })
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${row.name}" Permission`),
      content: (
        <p>{t('Are you sure you want to deactivate this permission?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            // deactivate the permission and update the queryCache
            deactivatePermission(row.id, {
              onSuccess: (response, permissionId) =>
                ouGetPermissionsQueryToDeactivatePermission({
                  permissionId,
                }),
            })
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${row.name}" Permission`),
      content: <p>{t('Are you sure you want to activate this permission?')}</p>,
      actions: (
        <Button
          onClick={() => {
            // activate the permission and update the queryCache
            activatePermission(row.id, {
              onSuccess: (response, permissionId) =>
                ouGetPermissionsQueryToActivatePermission({
                  permissionId,
                }),
            })
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: () => {
              onEdit()
            },
          },
          {
            label: row.activeInfo.active ? t('Deactivate') : t('Activate'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDropdownVisible(false)
              if (row.activeInfo.active) return setIsDeactivateDialogOpen(true)
              setIsActivateDialogOpen(true)
            },
          },
          {
            label: t('Archive'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsArchiveDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
