import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const usePolicyQuery = () => {
  const query = useQuery<OrgsAPIResponse['GET']['/api/policies'], KyError>(
    'getPolicies',
    getPolicies
  )

  return query
}

export default usePolicyQuery

const getPolicies = async () =>
  typedOrgsApi.get('/api/policies', {
    searchParams: [
      ['include', ['permissions']],
      ['order_by', 'ASC'],
      ['take', 10000],
      ['includeInternalUseOnly', 'true'],
    ],
  })
