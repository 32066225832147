import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useDeleteLineItemMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['DELETE']['/organizations/{organizationId}/contracts/{contractId}/line-items/{lineItemId}'],
    deleteLineItemArgs
  >({
    mutationFn: deleteLineItem,
    successMsg: t('Line item deleted'),
    // TODO: replace with Optimistic Update
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getOrganizationContracts', organizationId],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ contractId, organizationId }) => [
          'getLineItems',
          organizationId,
          contractId,
        ],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}/line-items'],
          deleteLineItemArgs
        >((oldCache, { lineItemId }) =>
          oldCache
            ? {
                ...oldCache,
                items: [
                  ...(oldCache.items?.filter(
                    (lineItem) => lineItem.id !== lineItemId
                  ) || []),
                ],
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useDeleteLineItemMutation

type deleteLineItemArgs = {
  organizationId: string
  contractId: string
  lineItemId: string
}

const deleteLineItem = async ({
  organizationId,
  contractId,
  lineItemId,
}: deleteLineItemArgs) =>
  ordersApi.delete(
    '/organizations/{organizationId}/contracts/{contractId}/line-items/{lineItemId}',
    {
      pathParams: { organizationId, contractId, lineItemId },
    }
  )
