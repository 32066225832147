import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { orgsApi } from 'utils'

const useCreatePartnerTypeCommissionsMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    CreatePartnerTypeCommissionResponse,
    CreatePartnerTypeCommissionsProps
  >({
    mutationFn: createPartnerTypeCommissions,
    successMsg: t('Partner Type Commissions created'),
    // invalidate all indirect commissions caches
    additionalCachesToInvalidate: ['getPartnerTypeIndirectCommissions'],
    optimisticUpdates: [
      {
        cacheKey: ({ partnerType }) => [
          'getPartnerTypeDirectCommissions',
          partnerType.id,
        ],
        updateFn: updateFnConstructor<
          GetPartnerTypeCommissionsResponse,
          CreatePartnerTypeCommissionsProps
        >((oldCache, { commissionsForm, partnerType }) =>
          oldCache
            ? [
                ...oldCache,
                ...commissionsForm.map((commissionForm) => ({
                  id: '123',
                  partnerType,
                  productId: commissionForm.productId,
                  isOneTimeCommission: commissionForm.oneTimeCommission.allowed,
                  isRecurringCommission:
                    commissionForm.recurringCommission.allowed,
                  ...(commissionForm.oneTimeCommission.allowed
                    ? {
                        oneTimeCommissionAmount:
                          commissionForm.oneTimeCommission.commissionAmount,
                      }
                    : {}),
                  ...(commissionForm.recurringCommission.allowed
                    ? {
                        recurringCommissionAmount:
                          commissionForm.recurringCommission.commissionAmount,
                      }
                    : {}),
                })),
              ]
            : oldCache
        ),
      },
    ],
  })

  return mutation
}

export default useCreatePartnerTypeCommissionsMutation

type CreatePartnerTypeCommissionsProps = {
  partnerType: PartnerType
  commissionsForm: CommissionForm[]
}

const createPartnerTypeCommissions = async ({
  partnerType,
  commissionsForm,
}: CreatePartnerTypeCommissionsProps) => {
  const createPartnerTypeCommissionsResult = await commissionsForm.reduce<
    Promise<CreatePartnerTypeCommissionResponse> | undefined
  >(
    (_, commission) =>
      orgsApi
        .post(`api/partner-types/${partnerType.id}/direct-commissions`, {
          json: commission,
        })
        .json<CreatePartnerTypeCommissionResponse>(),
    undefined
  )

  if (!createPartnerTypeCommissionsResult)
    throw new Error('No commissions provided in request')

  return createPartnerTypeCommissionsResult
}
