import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

const useReminderTypesQuery = () => {
  const query = useQuery<VitalsAPIResponse['GET']['/reminder-types'], KyError>(
    ['getReminderTypes'],
    () => getReminderTypes()
  )

  return query
}

export default useReminderTypesQuery

const getReminderTypes = () => typedVitalsApi.get('/reminder-types')
