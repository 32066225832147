import React, { useEffect, useState } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { RightPopup, TextField } from 'elements'
import { Button } from 'atlas'
import {
  useCreateVitalSignMutation,
  useUpdateVitalSignMutation,
} from 'hooks/vitals'
import { useTranslation } from 'react-i18next'
import ECGAdminCreateUnitForm from './ECGAdminCreateUnitForm'

type ECGAdminCreateUpdateVitalSignsFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  existingVitalSign: VitalSign | null
}

const ECGAdminCreateUpdateVitalSignsForm = ({
  isFormOpen,
  setIsFormOpen,
  existingVitalSign,
}: ECGAdminCreateUpdateVitalSignsFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<VitalSign>({
    // These must be set on the useForm or calling reset() with values won't work
    defaultValues: {
      name: '',
      displayName: '',
      code: '',
    },
  })
  const { handleSubmit, reset, watch, errors } = formMethods

  const [isUnitFormOpen, setIsUnitFormOpen] = useState<boolean>(false)

  const createVitalSign = useCreateVitalSignMutation()
  const updateVitalSign = useUpdateVitalSignMutation()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) reset({ ...existingVitalSign })
  }, [isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={existingVitalSign ? t('Edit Vital Sign') : t('Create Vital Sign')}
      controls={
        <>
          {!isUnitFormOpen && (
            <>
              <Button
                type="primary-filled"
                htmlType="submit"
                isLoading={
                  createVitalSign.isLoading || updateVitalSign.isLoading
                }
                // disables Save button if user currently creating a new unit or if no units have been created
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                disabled={!watch('units')?.length}
                onClick={handleSubmit(async (formData) => {
                  // Set defaultUnitCode
                  formData.defaultUnitCode = formData.units[0].code

                  // API call
                  if (existingVitalSign)
                    updateVitalSign.mutate({
                      ...existingVitalSign,
                      ...formData,
                    })
                  else createVitalSign.mutate({ ...formData })

                  // Close form drawer
                  setIsFormOpen(false)
                })}
              >
                {existingVitalSign ? t('Update') : t('Create')}
              </Button>
              &nbsp;
              <Button
                disabled={
                  createVitalSign.isLoading || updateVitalSign.isLoading
                }
                type="secondary"
                onClick={() => setIsFormOpen(false)}
              >
                {t('Cancel')}
              </Button>
            </>
          )}
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Vital Sign Name is required') }}
            name="name"
            label={t('Vital Sign Name')}
            fullWidth
            error={errors.name?.message}
            required
          />
          <Controller
            as={TextField}
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Display Name is required') }}
            name="displayName"
            label={t('Display Name')}
            fullWidth
            error={errors.displayName?.message}
            required
          />
          <Controller
            as={TextField}
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Code is required') }}
            name="code"
            label={t('Code')}
            fullWidth
            error={errors.code?.message}
            required
          />
          <br />
          <br />
          <br />
          <ECGAdminCreateUnitForm setIsUnitFormOpen={setIsUnitFormOpen} />
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default ECGAdminCreateUpdateVitalSignsForm
