import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

type useAlertStatisticsArgs = {
  subscriberId: string
}

const useResponsiblePartiesQuery = ({
  subscriberId,
}: useAlertStatisticsArgs) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/responsible-parties'],
    KyError
  >(['getResponsiblePartiesQuery', subscriberId], () =>
    getResponsiblePartiesQuery(subscriberId)
  )

  return query
}

export default useResponsiblePartiesQuery

const getResponsiblePartiesQuery = async (subscriberId: string) =>
  typedOrgsApi.get('/subscribers-profile/{subscriberId}/responsible-parties', {
    searchParams: [['include', 'subscriber']],
    pathParams: { subscriberId },
  })
