import React, { useState } from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTranslation } from 'react-i18next'
import { WebhooksList } from 'components/settings-webhooks'
import { CreateEditWebhooksForm } from 'components/settings-webhooks'
import { useWebhookSubscriptionsQuery } from 'hooks/settings-webhooks'
import tw from 'twin.macro'
import { useNavigate } from 'hooks'

const Webhooks = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    data: webhookSubscriptions,
    isLoading,
  } = useWebhookSubscriptionsQuery()

  const [
    isCreateEditWebhooksFormOpen,
    setIsCreateEditWebhooksFormOpen,
  ] = useState(false)
  const [webhookSubscriptionToEdit, setWebhookSubscriptionToEdit] = useState<
    WebhookSubscription | undefined
  >()

  return (
    <>
      <Layout
        title="Webhooks"
        description={t('View, add, and edit organization webhooks.')}
        isUnauthorized={!assumedOrganizationRole}
        breadcrumbs={[
          { name: t('Organization Settings'), route: '/organization-settings' },
          { name: t('Webhooks') },
        ]}
        isLoading={isLoading}
        isEmpty={webhookSubscriptions?.items.length === 0}
        emptyComponent={
          <WebhooksEmpty
            title={t('No Data Found')}
            description={t(
              'No webhooks have been created for this organization'
            )}
          />
        }
        controls={[
          {
            label: t('Create Webhooks'),
            onClick: () => {
              setWebhookSubscriptionToEdit(undefined)
              setIsCreateEditWebhooksFormOpen(true)
            },
          },
          {
            label: t('View Archived Webhooks'),
            onClick: () => navigate('archive'),
          },
          { label: t('View Documentation'), onClick: () => null },
        ]}
      >
        <WebhooksList
          webhookSubscriptions={
            webhookSubscriptions?.items.filter(
              (webhook) => !webhook.archiveInfo.archived
            ) || []
          }
        />
      </Layout>
      <CreateEditWebhooksForm
        isFormOpen={isCreateEditWebhooksFormOpen}
        setIsFormOpen={setIsCreateEditWebhooksFormOpen}
        webhookSubscriptionToEdit={webhookSubscriptionToEdit}
      />
    </>
  )
}

export default Webhooks

const WebhooksEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`
