import React from 'react'
import { FadeInSlideDown } from 'animations'
import { Empty } from 'atlas'
import clsx from 'clsx'
import tw, { styled } from 'twin.macro'

type SimpleListProps<T> = {
  data: T[]
  renderItem: (item: T) => React.ReactNode
  baseDelay?: number
  emptyProps?: {
    title?: string
    description?: string
    className?: string
  }
  animate?: boolean
  className?: string
}

const SimpleList = <T,>({
  data,
  renderItem,
  baseDelay = 0.1,
  emptyProps,
  animate = true,
  className,
}: SimpleListProps<T>) => {
  if (data.length === 0) {
    return (
      <FadeInSlideDown
        className={clsx(
          className,
          'flex flex-grow border-2 border-dashed rounded'
        )}
        delay={baseDelay}
      >
        <Empty title="No Data Found" {...emptyProps} delay={baseDelay} />
      </FadeInSlideDown>
    )
  }

  return (
    <div className="w-full">
      {data.map((item, index) => (
        <>
          {animate ? (
            <FadeInSlideDown
              key={`item-${index}`}
              delay={baseDelay + 0.05 * index}
              className="w-full"
            >
              {renderItem(item)}
            </FadeInSlideDown>
          ) : (
            <div key={`item-${index}`} className="w-full">
              {renderItem(item)}
            </div>
          )}
        </>
      ))}
    </div>
  )
}

export default SimpleList

type SimpleListItemProps = {
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const SimpleListItem = ({ children, onClick }: SimpleListItemProps) => {
  return (
    <ListItem onClick={onClick} clickable={!!onClick}>
      {children}
    </ListItem>
  )
}

SimpleList.Item = SimpleListItem

const ListItem = styled.div<{ clickable: boolean }>(({ clickable }) => [
  tw`bg-white p-4 border border-gray-200 mb-2`,
  clickable && tw`hover:bg-blue-50 hover:cursor-pointer`,
])
