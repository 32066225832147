import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { generatePaginatable } from 'test-utils/api/utils'
import { isPresent, toUpdateObj, typedOrgsApi } from 'utils'

const useUpdateStaffMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}/staff-members/{personId}'],
    updateStaffArgs
  >({
    mutationFn: updateStaff,
    successMsg: t('Person updated'),
    optimisticUpdates: [
      {
        cacheKey: ({ staffMember }) => [
          'getStaff',
          staffMember.organization.id,
          staffMember.id,
        ],
        updateOn: 'onSuccess',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}'],
          updateStaffArgs
        >((oldStaffMember, { editStaffForm }) => {
          return (
            oldStaffMember && {
              ...oldStaffMember,
              isProvider: editStaffForm.isProvider,
              memberType: editStaffForm.memberType,
              person: {
                ...oldStaffMember.person,
                ...editStaffForm,
                ...(editStaffForm.suffix
                  ? { suffix: editStaffForm.suffix }
                  : {}),
                ...(editStaffForm.salutation
                  ? { salutation: editStaffForm.salutation }
                  : {}),
              },
            }
          )
        }),
      },
      {
        cacheKey: 'getStaffList',
        updateOn: 'onSuccess',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members'],
          updateStaffArgs
        >(
          (
            staffMembers,
            { staffMember: staffMemberToUpdate, editStaffForm }
          ) => {
            return (
              staffMembers && {
                items: staffMembers.items?.map((subscriber) =>
                  subscriber.id === staffMemberToUpdate.id
                    ? {
                        ...staffMemberToUpdate,
                        isProvider: editStaffForm.isProvider,
                        memberType: editStaffForm.memberType,
                        person: {
                          ...staffMemberToUpdate.person,
                          firstName: editStaffForm.firstName,
                          lastName: editStaffForm.lastName,
                          ...(editStaffForm.suffix
                            ? { suffix: editStaffForm.suffix }
                            : {}),
                          ...(editStaffForm.salutation
                            ? { salutation: editStaffForm.salutation }
                            : {}),
                        },
                      }
                    : subscriber
                ),
                ...generatePaginatable(),
              }
            )
          }
        ),
      },
    ],
    additionalCachesToInvalidate: ['getSubscriberList'],
  })

  return mutation
}

export default useUpdateStaffMutation

type updateStaffArgs = {
  staffMember: StaffMember
  editStaffForm: EditStaffMemberForm
}

const updateStaff = async ({ editStaffForm, staffMember }: updateStaffArgs) =>
  typedOrgsApi.patch(
    `/api/organizations/{organizationId}/staff-members/{personId}`,
    {
      pathParams: {
        organizationId: staffMember.organization.id,
        personId: staffMember.id,
      },
      // convert editStaffForm to array of UpdateObjs
      body: Object.entries(editStaffForm)
        .map(([key, value]) =>
          value !== undefined ? toUpdateObj(key, value) : undefined
        )
        .filter(isPresent),
    }
  )
