const dateRangePresets: DateRangePreset[] = [
  'today',
  'this-week',
  'last-week',
  'this-month',
  'last-month',
  'this-quarter',
  'last-quarter',
  'this-year',
  'last-year',
  'custom',
]

export default dateRangePresets
