import React, { useState } from 'react'
import { InformationCard, Icon } from 'elements'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import tw from 'twin.macro'
import EditGeneralInformationForm from './EditGeneralInformationForm'

type AlertDeliveryRuleDetailsProps = {
  alertDeliveryRule: AlertDeliveryRule
  alertCategory?: AlertCategory
}

const AlertDeliveryRuleDetails = ({
  alertDeliveryRule,
  alertCategory,
}: AlertDeliveryRuleDetailsProps) => {
  const { t } = useTranslation()

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  return (
    <>
      <Header>
        <Title>{t('General Information')}</Title>
        <EditIcon
          type="edit"
          onClick={() => setIsFormOpen(true)}
          data-testid="general-information-edit"
        />
      </Header>
      <InformationCard>
        <InformationCard.Item
          label={t('Name')}
          value={alertDeliveryRule.name}
        />

        <InformationCard.Item
          label={t('Description')}
          value={alertDeliveryRule.description}
        />

        <InformationCard.Item
          label={t('Alert Category')}
          value={_.capitalize(alertCategory?.name)}
        />
        {alertDeliveryRule.criteria.alertCategory === 'vitals' ? (
          <InformationCard.Item
            label={t('Severity Level')}
            value={<SeverityChip deliveryRule={alertDeliveryRule} />}
          />
        ) : null}
      </InformationCard>
      <EditGeneralInformationForm
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        alertDeliveryRule={alertDeliveryRule}
        alertCategory={alertCategory}
      />
    </>
  )
}

export default AlertDeliveryRuleDetails

const SeverityChip = ({
  deliveryRule,
}: {
  deliveryRule: AlertDeliveryRule
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {deliveryRule.criteria.severity &&
      deliveryRule.criteria.alertCategory === 'vitals' ? (
        <Chip
          color={
            (['yellow', 'orange', 'red'] as const)[
              deliveryRule.criteria.severity - 1
            ]
          }
        >{`${t('Severity')} ${deliveryRule.criteria.severity}`}</Chip>
      ) : (
        '-'
      )}
    </div>
  )
}

const Header = tw.div`flex items-center`

const Title = tw.h3`text-xl font-semibold mb-2`

const EditIcon = tw(Icon)`text-gray-600 hover:text-gray-900 mb-2 ml-2`
