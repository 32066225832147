/**
 * Given a LineItem object returns an object with fields specific to the line item's type
 * @param {ContractLineItem} lineItem
 * @returns {ContractLineItem[LineItemTypeKeys]} The inner fields
 */
const getLineItemFields = <T extends LineItemTypeKeys>(
  lineItem: ContractLineItem
): ContractLineItem[T] =>
  lineItem.lineItemType
    ? lineItem[lineItemTypeMap[lineItem.lineItemType]]
    : undefined

export default getLineItemFields

type LineItemTypeKeys =
  | 'primaryProductLineItem'
  | 'deviceOrServiceLineItem'
  | 'productTypeLineItem'
  | 'groupedProductsLineItem'

export const lineItemTypeMap: Record<LineItemType, LineItemTypeKeys> = {
  PrimaryProduct: 'primaryProductLineItem',
  DeviceOrService: 'deviceOrServiceLineItem',
  ProductType: 'productTypeLineItem',
  GroupedProduct: 'groupedProductsLineItem',
}
