import React, { useMemo, useCallback, useRef, useState } from 'react'
import {
  List,
  Icon,
  Dropdown,
  DropdownItem,
  ConfirmationDialogs,
} from 'elements'
import { Button, Chip } from 'atlas'
import { FadeInSlideDown } from 'animations'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useStatusSort } from 'hooks/react-table'
import { AssignApplicationsForm } from 'components/access-control/modules'
import { useRemoveApplicationFromModuleMutation } from 'hooks/access-control/modules'
import tw, { styled } from 'twin.macro'

type AssignedApplicationssListProps = {
  delay: () => number
  data?: Application[]
  currentModule?: Module
  disabled?: boolean
}

const AssignedApplicationssList = ({
  delay,
  data = [],
  currentModule,
  disabled,
}: AssignedApplicationssListProps) => {
  const { t } = useTranslation()

  const tableData: Application[] = useMemo(() => {
    return data
  }, [data])
  const [
    isAssignApplicationsModuleFormOpen,
    setIsAssignApplicationsFormOpen,
  ] = useState<boolean>(false)

  const statusSort = useStatusSort()

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Application>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const cognitoAppId = row.original.cognitoAppId.toLowerCase()
        const description = row.original.description.toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          cognitoAppId.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )

  const columns: Column<Application>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: 'name',
      },
      {
        id: 'COGNITOAPPID',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('COGNITOAPPID'),
        accessor: 'cognitoAppId',
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) =>
          row.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          ),
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'ACTIONS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ACTIONS'),
        accessor: (row) => (
          <ActionsCell
            row={row}
            currentModule={currentModule}
            disabled={disabled}
          />
        ),
        disableSortBy: true,
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  if (!currentModule) return null

  return (
    <>
      <AssignedApplicationsListContainer delay={delay()}>
        <Title>{t('Assigned Applications')}</Title>

        <Button
          type="primary-filled"
          disabled={disabled}
          onClick={() => setIsAssignApplicationsFormOpen(true)}
        >
          {t('Assign Applications')}
        </Button>
      </AssignedApplicationsListContainer>

      <ListContainer>
        <List
          tableInstance={tableInstance}
          searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
            'applications'
          )}`}
          emptyTitle={t('No Applications Assigned')}
          emptyDescription={t(
            'This module has not been assigned to any applications within the ECG ecosystem yet. Click the button "Assign Applications" to add applications and begin using the module.'
          )}
          baseDelay={delay()}
        />
      </ListContainer>

      <AssignApplicationsForm
        isFormOpen={isAssignApplicationsModuleFormOpen}
        setIsFormOpen={setIsAssignApplicationsFormOpen}
        module={currentModule}
      />
    </>
  )
}

export default AssignedApplicationssList

type ActionsCellProps = {
  row: Application
  currentModule?: Module
  disabled?: boolean
}

const ActionsCell = ({ row, currentModule, disabled }: ActionsCellProps) => {
  const { t } = useTranslation()
  const dropdownAnchorEl = useRef(null)
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false)
  const {
    mutate: removeApplicationFromModule,
  } = useRemoveApplicationFromModuleMutation()

  if (!currentModule) return null

  const confirmations = [
    // Remove Dialog
    {
      setIsOpen: setIsRemoveDialogOpen,
      isOpen: isRemoveDialogOpen,
      title: t(`Remove "${row.name}" assigned application`),
      content: (
        <p>{t('Are you sure you want to remove this assigned application?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            removeApplicationFromModule({
              moduleId: currentModule.id,
              applicationId: row.id,
            })
            return setIsRemoveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Remove')}
        </Button>
      ),
    },
  ]

  return (
    <ActionsContainer ref={dropdownAnchorEl}>
      <>
        <EllipsisIcon
          type="ellipsis"
          disableCursor={disabled}
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          data-testid={`${row.name}-ellipsis`}
          disabled={disabled}
        />
        <Dropdown
          visible={isDropdownVisible}
          setVisible={setIsDropdownVisible}
          parentRef={dropdownAnchorEl}
        >
          <DropdownItem
            onClick={() => {
              setIsRemoveDialogOpen(true)

              setIsDropdownVisible(false)
            }}
          >
            {t('Remove')}
          </DropdownItem>
        </Dropdown>
        <ConfirmationDialogs confirmations={confirmations} />
      </>
    </ActionsContainer>
  )
}

const Title = tw.h3`text-xl font-semibold self-center`

const ListContainer = tw.div`flex flex-col h-96`

const ActionsContainer = tw.div`relative inline-flex self-center`

const AssignedApplicationsListContainer = tw(
  FadeInSlideDown
)`flex justify-between mb-4`

const EllipsisIcon = styled(Icon)<{ disableCursor?: boolean }>(
  ({ disableCursor }) => [
    tw`text-gray-900 w-5 h-5`,
    disableCursor && tw`opacity-50 cursor-not-allowed`,
  ]
)
