import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useStaffMemberTypesQuery = () => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/seed-data/staff-member-types'],
    KyError
  >('getStaffMemberTypes', getStaffMemberTypes)

  return query
}

export default useStaffMemberTypesQuery

const getStaffMemberTypes = async () =>
  typedOrgsApi.get('/api/seed-data/staff-member-types')
