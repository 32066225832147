import { format, formatDistanceToNow } from 'date-fns'
import getDateFnLocale from '../locale/getDateFnLocale'

type formatDate = (
  date: number | Date,
  formatStr?: string,
  options?:
    | {
        locale?: Locale | undefined
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
        firstWeekContainsDate?: number | undefined
        useAdditionalWeekYearTokens?: boolean | undefined
        useAdditionalDayOfYearTokens?: boolean | undefined
      }
    | undefined
) => string

/**
 * by providing a default string of 'PP' or any of its variants for `formatStr`
 * it will format dates in whichever way is appropriate to the locale
 * @param date
 * @param formatStr
 * @returns readable date string
 */
const formatDate: formatDate = (date, formatStr = 'PP') => {
  if (formatStr === 'distanceToNow') {
    return formatDistanceToNow(date, {
      locale: getDateFnLocale(),
    })
  }

  return format(date, formatStr, {
    locale: getDateFnLocale(),
  })
}

export default formatDate
