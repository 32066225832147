import { useMutation } from 'hooks'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useCreatePersonMutation = () => {
  const { t } = useTranslation()
  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/people'],
    CreatePersonProps
  >({
    mutationFn: createPerson,
    successMsg: { showOn: 'onSuccess', message: t('Person Created') },
  })

  return mutation
}

export default useCreatePersonMutation

type CreatePersonProps = {
  person: CreatePersonForm
  account: {
    email: string
    password: string
    isAutoGenerated: boolean
  }
}

const createPerson = async ({ person, account }: CreatePersonProps) =>
  typedOrgsApi.post('/api/people', {
    body: {
      account,
      person,
      addresses: [],
      phoneNumbers: [],
    },
  })
