import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { AdminList } from 'components/admins'
import { Button } from 'atlas'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

const Admins = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  // default case
  return (
    <Layout
      title={t('Admins')}
      description={t('Create ECG admins and manage ECG admin access.')}
      controls={
        <Button type="primary-filled" to="create-admin">
          {t('Create Admin')}
        </Button>
      }
      isUnauthorized={!!assumedOrganizationRole}
    >
      <AdminListContainer>
        <AdminList />
      </AdminListContainer>
    </Layout>
  )
}

export default Admins

const AdminListContainer = tw.div`flex flex-col flex-grow`
