import { Icon, RadioGroup, RightPopup, TextField } from 'elements'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip } from 'atlas'

type WarrantyOptionFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  warrantyOptionToEdit: WarrantyOption | undefined
  onSubmit: (warrantyOption: WarrantyOption) => void
}

const WarrantyOptionForm = ({
  isFormOpen,
  setIsFormOpen,
  warrantyOptionToEdit,
  onSubmit,
}: WarrantyOptionFormProps) => {
  const { t } = useTranslation()

  const { errors, control, handleSubmit, watch, reset } = useForm<
    Omit<WarrantyOption, 'priceDescriptor'> & {
      priceDescriptor: Omit<
        Required<WarrantyOption>['priceDescriptor'],
        'price'
      > & {
        price: Omit<
          Required<Required<WarrantyOption>['priceDescriptor']>['price'],
          'amount'
        > & {
          amount: string
        }
      }
    }
  >()

  // reset form fields when form opens or warrantyOptionToEdit changes
  useEffect(() => {
    if (isFormOpen) {
      reset(
        warrantyOptionToEdit
          ? {
              ...warrantyOptionToEdit,
              priceDescriptor: {
                ...warrantyOptionToEdit.priceDescriptor,
                price: {
                  ...warrantyOptionToEdit.priceDescriptor?.price,
                  amount:
                    '' + warrantyOptionToEdit.priceDescriptor?.price?.amount,
                },
              },
            }
          : {
              priceDescriptor: {
                descriptionInfo: {
                  title: '',
                  description: '',
                },
                price: {
                  flatOrPercent: 'Flat',
                  amount: undefined,
                  interval: 'OneTime',
                },
              },
            }
      )
    }
  }, [warrantyOptionToEdit, isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={
        warrantyOptionToEdit
          ? t('Edit Warranty Option')
          : t('Add Warranty Option')
      }
      controls={
        <>
          <Button
            onClick={handleSubmit((formData) => {
              onSubmit({
                ...formData,
                priceDescriptor: {
                  ...formData.priceDescriptor,
                  price: {
                    flatOrPercent: 'Flat',
                    ...formData.priceDescriptor.price,
                    amount: +formData.priceDescriptor.price.amount,
                  },
                },
              })

              setIsFormOpen(false)
            })}
          >
            {warrantyOptionToEdit ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          {!warrantyOptionToEdit ? (
            <Button
              onClick={handleSubmit((formData) => {
                onSubmit({
                  ...formData,
                  priceDescriptor: {
                    ...formData.priceDescriptor,
                    price: {
                      flatOrPercent: 'Flat',
                      ...formData.priceDescriptor.price,
                      amount: +formData.priceDescriptor.price.amount,
                    },
                  },
                })

                reset({
                  priceDescriptor: {
                    descriptionInfo: {
                      title: '',
                      description: '',
                    },
                    price: {
                      flatOrPercent: 'Flat',
                      amount: undefined,
                      interval: 'OneTime',
                    },
                  },
                })
              })}
            >
              {t('Create & Add More')}
            </Button>
          ) : null}
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="priceDescriptor.descriptionInfo.title"
          label={t('Title')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Title is required') }}
          error={errors.priceDescriptor?.descriptionInfo?.title?.message}
          className="flex-grow max-w-md mt-4"
          required
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="priceDescriptor.descriptionInfo.description"
          label={t('Description')}
          className="flex-grow max-w-xl mt-4"
          multiline
          rows={3}
        />
        <InfoTooltip
          anchor="right"
          content={
            <TooltipText>{`${t(
              'Enter a meaningful description that will help customers understand the exact details of this warranty option'
            )}. ${t('Be as specific as possible')}`}</TooltipText>
          }
        >
          <InfoIcon type="info" />
        </InfoTooltip>
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          render={({ onChange, value, ref, name }) => (
            <RadioGroup
              ref={ref}
              name={name}
              onChange={onChange}
              options={[
                { value: 'OneTime', label: t('One-time') },
                { value: 'Monthly', label: t('Monthly') },
                { value: 'Yearly', label: t('Annual') },
              ]}
              checked={value}
              label={t('Pricing Interval')}
              className="flex-grow mb-2"
            />
          )}
          name="priceDescriptor.price.interval"
          defaultValue={'OneTime'}
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="priceDescriptor.price.amount"
          label={t('Cost')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Cost is required') }}
          error={errors.priceDescriptor?.price?.amount?.message}
          startAdornment="$"
          endAdornment={
            // Optional check needed to prevent undefined errors
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            watch('priceDescriptor')?.price.interval === 'Monthly'
              ? '/mo'
              : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              watch('priceDescriptor')?.price.interval === 'Yearly'
              ? '/yr'
              : ''
          }
          type="number"
          className="w-56 mt-4"
          required
        />
      </RowContainer>
    </RightPopup>
  )
}

export default WarrantyOptionForm

const RowContainer = tw.div`flex`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`ml-2 mt-4 h-fit-content`

const TooltipText = tw.p`w-60`
