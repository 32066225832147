import { useQuery } from 'react-query'
import { ordersApi } from 'utils'

const PAGE_LENGTH = 30

const useContractsQuery = (filters: ContractFilters = {}) => {
  const query = useQuery<OrdersAPIResponse['GET']['/contracts'], KyError>(
    ['getContracts', filters],
    () => getContracts(filters),
    { keepPreviousData: true }
  )

  return query
}

export default useContractsQuery

const getContracts = async ({
  organizationIds,
  searchTerm,
  skip,
}: ContractFilters) =>
  ordersApi.get(`/contracts`, {
    searchParams: [
      ['skip', skip],
      ['take', skip !== undefined ? PAGE_LENGTH : 9999],
      ['searchTerm', searchTerm],
      ['organizationIds', organizationIds],
    ],
  })
