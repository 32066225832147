import React, { useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  CreateAdminFormBasicInformation,
  CreateAdminFormPermissions,
} from 'components/admins'
import { Layout, Stepper } from 'elements'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import clsx from 'clsx'
import { generate } from 'generate-password'
import { FadeInSlideDown } from 'animations'
import tw from 'twin.macro'

const CreateAdminForm = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  // Label Text and Icons for Stepper
  const steps: { label: string; icon: IconType }[] = [
    { label: t('Basic Information'), icon: 'user' },
    { label: t('Permissions'), icon: 'permissions' },
  ]

  const [activeStep, setActiveStep] = useState<number>(0)

  const formMethods = useForm<CreateAdminForm>({
    defaultValues: {
      policies: [],
      account: {
        email: '',
        password: generate({
          length: 10,
          numbers: true,
          symbols: true,
          lowercase: true,
          uppercase: true,
          excludeSimilarCharacters: true,
          strict: true,
        }),
        isAutoGenerated: true,
      },
      person: {
        salutation: undefined,
        firstName: '',
        lastName: '',
        suffix: '',
        contactEmailAddress: '',
      },
      phoneNumbers: [{ number: '', extension: '' }],
      addresses: [
        {
          addressLine1: '',
          addressLine2: '',
          stateProvinceCode: '',
          city: '',
          postalCode: '',
        },
        {
          addressLine1: '',
          addressLine2: '',
          stateProvinceCode: '',
          city: '',
          postalCode: '',
        },
      ],
    },
  })

  // default case
  return (
    <Layout
      title={t('Create Admin')}
      breadcrumbs={[
        {
          name: t('Admins'),
          route: '/admins',
        },
        {
          name: t('Create Admin'),
        },
      ]}
      isUnauthorized={!!assumedOrganizationRole}
    >
      <AdminFormContainer>
        <FadeInSlideDown>
          <Stepper steps={steps} activeStep={activeStep} className="mb-4" />
        </FadeInSlideDown>
        <FormProvider {...formMethods}>
          <CreateAdminFormBasicInformation
            nextStep={() => setActiveStep(1)}
            className={clsx(activeStep !== 0 && 'hidden')}
            aria-hidden={activeStep !== 0}
          />
          <CreateAdminFormPermissions
            previousStep={() => setActiveStep(0)}
            className={clsx(activeStep !== 1 && 'hidden')}
            aria-hidden={activeStep !== 1}
          />
        </FormProvider>
      </AdminFormContainer>
    </Layout>
  )
}

export default CreateAdminForm

const AdminFormContainer = tw.div`flex flex-col flex-grow`
