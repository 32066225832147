import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useSubscriberAddressesQuery = (subscriberId: string | null) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/addresses'],
    KyError
  >(
    ['getSubscriberAddresses', subscriberId],
    () => getSubscriberAddresses(subscriberId || ''),
    { enabled: !!subscriberId }
  )

  return query
}

export default useSubscriberAddressesQuery

const getSubscriberAddresses = (subscriberId: string) =>
  typedOrgsApi.get('/subscribers-profile/{subscriberId}/addresses', {
    pathParams: { subscriberId },
  })
