import React, { ReactNode } from 'react'
import { Button } from 'atlas'
import { Dialog } from 'elements'
import { useTranslation } from 'react-i18next'

type ConfirmationDialogsProps = {
  confirmations: Array<{
    setIsOpen: (isOpen: boolean) => void
    isOpen: boolean
    title: string
    content: ReactNode
    actions: ReactNode
    disableCancellation?: boolean
  }>
}

const ConfirmationDialogs = ({ confirmations }: ConfirmationDialogsProps) => {
  const { t } = useTranslation()

  return (
    <>
      {confirmations.map((confirmation, index) => {
        return (
          <Dialog
            key={index}
            open={confirmation.isOpen}
            title={confirmation.title}
            content={confirmation.content}
            actions={
              <>
                {confirmation.actions}
                <Button
                  onClick={() => confirmation.setIsOpen(false)}
                  type="secondary"
                  disabled={confirmation.disableCancellation}
                >
                  {t('Cancel')}
                </Button>
              </>
            }
          />
        )
      })}
    </>
  )
}

export default ConfirmationDialogs
