import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  List,
  CategoryFilter,
  LoadingIcon,
  Dialog,
  EllipsisDropdown,
} from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  useFilters,
  TableInstance,
  Row,
} from 'react-table'
import i18next from 'i18next'
import _ from 'lodash'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useCategoryFilter, useEntityLabelSort } from 'hooks/react-table'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  useReminderSchedulesQuery,
  useDeleteReminderScheduleMutation,
} from 'hooks/reminder-schedules'
import tw from 'twin.macro'

type SubscriberReminderPresetsListProps = {
  editReminderPreset: (selectedPreset: ReminderSchedule) => void
}

const SubscriberReminderPresetsList = ({
  editReminderPreset,
}: SubscriberReminderPresetsListProps) => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const {
    getCategories,
    filterRowsByCategory,
  } = useCategoryFilter<ReminderSchedule>()
  const { subscriberId } = useParams()
  const { data: reminderSchedules, isLoading } = useReminderSchedulesQuery(
    subscriberId
  )
  const { t } = useTranslation()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['description'],
  })

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (
      rows: Row<ReminderSchedule>[],
      columnIDs: string[],
      globalFilter: string
    ) => {
      return rows.filter((row) => {
        const name = row.original.description.toLowerCase()

        return name.includes(globalFilter.toLowerCase())
      })
    },
    []
  )

  const weekDaySorter: { [key: string]: number } = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  }

  const tableData: ReminderSchedule[] = useMemo(() => {
    if (!reminderSchedules?.items) return []
    return reminderSchedules.items
  }, [reminderSchedules])

  const columns: Column<ReminderSchedule>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => (
          <PrimaryColumnText>{row.description}</PrimaryColumnText>
        ),
        sortType: primaryLabelSort,
      },
      {
        id: 'FREQUENCY',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('FREQUENCY'),
        accessor: (row) => (
          <FrequencyContainer>
            <span>
              {
                {
                  weekly: _.orderBy(
                    // incorrect type, schedule may be undefined
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    row.schedule?.weekdays,
                    (row) => weekDaySorter[row]
                  ).map((day) => (
                    <DayOfTheWeekChip key={day} color="cyan">
                      {_.upperCase(day)}
                    </DayOfTheWeekChip>
                  )),
                  daily:
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    row.schedule?.interval && row.schedule?.interval > 1
                      ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        t('Every ') + row.schedule?.interval + t(' Days')
                      : t('Every Day'),
                  monthly: null,
                  yearly: null,
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                }[row.schedule?.frequency]
              }
            </span>
          </FrequencyContainer>
        ),
        Filter: (tableInstance: TableInstance<ReminderSchedule>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="reminders"
            label={t('Frequency')}
            categories={getCategories({
              tableInstance,
              accessor: (row) => ({
                // incorrect type, schedule may be undefined
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                value: row.schedule?.frequency,
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                label: _.startCase(row.schedule?.frequency),
              }),
            })}
          />
        ),
        filter: filterRowsByCategory((row) => row.schedule.frequency),
      },
      {
        id: 'ACTIONS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ACTIONS'),
        accessor: (row) => (
          <ActionsCell editReminderPreset={editReminderPreset} row={row} />
        ),
        width: '0.5fr',
        disableSortBy: true,
      },
    ],
    [accountPermissions, i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      globalFilter,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  if (isLoading || !reminderSchedules) return <LoadingIcon />
  return (
    <>
      <List
        tableInstance={tableInstance}
        searchPlaceholder={t('Search reminder schedules')}
        emptyDescription={t(
          'Try changing your search term or contact your administrator'
        )}
      />
    </>
  )
}

export default SubscriberReminderPresetsList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const DayOfTheWeekChip = tw(Chip)`mr-1`

type ActionsCellProps = {
  row: ReminderSchedule
  editReminderPreset: (selectedPreset: ReminderSchedule) => void
}

const ActionsCell = ({ row, editReminderPreset }: ActionsCellProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const dropdownAnchor = useRef(null)
  const { mutate: deleteReminderSchedule } = useDeleteReminderScheduleMutation()

  return (
    <ActionsContainer ref={dropdownAnchor}>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: ({ setIsDropdownVisible }) => {
              editReminderPreset(row)
              setIsDropdownVisible(false)
            },
          },
          {
            label: t('Delete'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDropdownVisible(false)
              setIsDialogOpen(true)
            },
          },
        ]}
        testid={`${row.description}-ellipsis`}
      />
      <Dialog
        open={isDialogOpen}
        title={`${t('Delete')} "${row.description}" ${t('reminder schedule')}?`}
        content={
          <p>
            {t(
              'Are you sure you want to delete this reminder schedule? This will remove it completely'
            )}
          </p>
        }
        actions={
          <>
            <Button
              type="danger-filled"
              onClick={() => {
                deleteReminderSchedule({
                  reminderId: row.id,

                  subscriberId: row.subscriberId,
                })
                return setIsDialogOpen(false)
              }}
            >
              {t('Delete')}
            </Button>
            <Button type="secondary" onClick={() => setIsDialogOpen(false)}>
              {t('Cancel')}
            </Button>
          </>
        }
      />
    </ActionsContainer>
  )
}

const FrequencyContainer = tw.div`flex self-center`

const ActionsContainer = tw.div`relative inline-flex self-center`
