import useMutation from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCreateContractMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/organizations/{organizationId}/contracts'],
    createContractArgs
  >({
    mutationFn: createContract,
    successMsg: t('Contract created'),
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getContracts', organizationId],
      ['getContracts', undefined],
    ],
  })

  return mutation
}

export default useCreateContractMutation

type createContractArgs = { contractForm: ContractForm; organizationId: string }

const createContract = async ({
  contractForm,
  organizationId,
}: createContractArgs) =>
  ordersApi.post('/organizations/{organizationId}/contracts', {
    pathParams: { organizationId },
    body: contractForm,
  })
