import { useQuery } from 'react-query'
import { webhooksApi } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const useWebhookSubscriptionsQuery = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<GetWebhookSubscriptionsResponse, KyError>(
    ['getWebhookSubscriptions', assumedOrganizationRole?.orgID],
    () => getWebhookSubscriptions(assumedOrganizationRole?.orgID),
    {
      enabled: !!assumedOrganizationRole?.orgID,
    }
  )

  return query
}

export default useWebhookSubscriptionsQuery

const getWebhookSubscriptions = async (organizationId?: string) => {
  const searchParams = [
    ['order_by', 'ASC'],
    ['take', '10000'],
  ]

  const result = webhooksApi
    .get(`api/organizations/${organizationId}/webhook-subscriptions`, {
      searchParams,
    })
    .json<GetWebhookSubscriptionsResponse>()

  return result
}
