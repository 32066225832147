import React, { useMemo, useCallback } from 'react'
import { List, LoadingIcon, EntityLabel } from 'elements'
import { useTable, Column, useSortBy, useGlobalFilter, Row } from 'react-table'
import { useEntityLabelSort } from 'hooks/react-table'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useNavigate } from 'hooks'
import { useStaffListQuery } from 'hooks/user-staff'

const StaffList = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { data: staffList, isLoading } = useStaffListQuery(
    assumedOrganizationRole?.orgID || ''
  )
  const navigate = useNavigate()
  const staffMemberLabelSort = useEntityLabelSort({
    accessor: ['firstName', 'lastName'],
  })
  const organizationLabelSort = useEntityLabelSort({
    accessor: 'organization.businessName',
  })
  const { t } = useTranslation()

  const tableData: StaffMember[] = useMemo(() => {
    return staffList?.items || []
  }, [staffList, i18next.language])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<StaffMember>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const staffMemberName = `${row.original.person.firstName.toLowerCase()} ${row.original.person.lastName.toLowerCase()}`
        const organizationName = row.original.organization.businessName.toLowerCase()

        return (
          staffMemberName.includes(globalFilter.toLowerCase()) ||
          organizationName.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )

  const columns: Column<StaffMember>[] = useMemo(
    () => [
      {
        id: 'STAFF MEMBER',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STAFF MEMBER'),
        accessor: (row) => (
          <EntityLabel
            name={`${row.person.firstName} ${row.person.lastName}`}
            id={row.id}
            bold
          />
        ),
        sortType: staffMemberLabelSort,
      },
      {
        id: 'ORGANIZATION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ORGANIZATION'),
        accessor: (row) => (
          <EntityLabel
            name={row.organization.businessName}
            id={row.organization.id}
            to={`/organizations/${row.organization.id}`}
          />
        ),
        sortType: organizationLabelSort,
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      onRowClick: (row) => navigate(row.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'STAFF MEMBER',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy
  )

  if (isLoading) return <LoadingIcon />

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'staff'
      )}`}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default StaffList
