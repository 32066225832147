const translation: Translation = {
  translation: {
    Home: 'Zuhause',
    Organizations: 'Organisationen',
    Vitals: 'Vitale',
    Account: 'Konto',
    Logout: 'Ausloggen',
    'Account Settings': 'Account Einstellungen',
    Update: 'Aktualisieren',
    Cancel: 'Stornieren',
    NAME: 'NAME',
    'TOTAL SUBSCRIBERS': 'GESAMT ABONNENTEN',
    ACTIONS: 'AKTIONEN',
    'Assume Role': 'Rolle übernehmen',
    'Assumed organization role of': 'Angenommene Organisationsrolle von',
    'Search organizations': 'Organisationen suchen',
    'Create Vital Sign': 'Vitalzeichen erstellen',
    'Archived Vital Signs': 'Archivierte Vitalfunktionen',
    'Audit Trail': 'Buchungskontrolle',
    'Account settings updated': 'Kontoeinstellungen aktualisiert',
    Edit: 'Bearbeiten',
    Archive: 'Archiv',
    Deactivate: 'Deaktivieren',
    Activate: 'Reaktivieren',
    activated: 'aktiviert',
    deactivated: 'deaktiviert',
    'Vital sign': 'Lebenszeichen',
    archived: 'archiviert',
    restored: 'restauriert',
    Create: 'Erstellen',
    'Vital Sign Name is required':
      'Der Name des Vitalzeichens ist erforderlich',
    'Vital Sign Name': 'Name des Vitalzeichens',
    'Display Name is required': 'Anzeigename ist erforderlich',
    'Display Name': 'Anzeigename',
    'Code is required': 'Code ist erforderlich',
    Code: 'Code',
    'Vital Sign Unit of Measurement': 'Maßeinheit für das Vitalzeichen',
    'Unit Name is required': 'Einheitenname ist erforderlich',
    'Unit Name': 'Einheitenname',
    'Unit Display Name is required':
      'Der Anzeigename der Einheit ist erforderlich',
    'Unit Display Name': 'Anzeigename der Einheit',
    'Unit Code is required': 'Gerätecode ist erforderlich',
    'Unit Code': 'Gerätecode',
    'Save Unit': 'Einheit speichern',
    'No Units Added Yet': 'Es wurden noch keine Einheiten hinzugefügt',
    'Add one or more units of measurement for this Vital Sign by clicking the "Add Unit" button':
      'Fügen Sie eine oder mehrere Maßeinheiten für dieses Vitalzeichen hinzu, indem Sie auf die Schaltfläche "Einheit hinzufügen" klicken.',
    'Add Unit': 'Einheit hinzufügen',
    'Vital sign created': 'Vitalzeichen erstellt',
    'Vital sign updated': 'Vitalzeichen aktualisiert',
    'Archived Vitals': 'Archivierte Vitale',
    'No Archived Vital Sign Assignments Found':
      'Keine archivierten Vitalzeichen gefunden',
    Restore: 'Wiederherstellen',
    Delete: 'Löschen',
    'Vital sign deleted': 'Vitalzeichen gelöscht',
    'Assumed Role': 'Angenommene Rolle',
    'Discard assumed role': 'Verwendete Rolle verwerfen',
    'Discarded assumed organization role':
      'Verworfene übernommene Organisationsrolle',
    Staff: 'Mitarbeiterin',
    Subscribers: 'Abonnentinnen',
    'Create Staff Member': 'Mitarbeiter erstellen',
    ORGANIZATION: 'ORGANISATION',
    'Try changing your search term or contact your administrator':
      'Versuchen Sie, Ihren Suchbegriff zu ändern, oder wenden Sie sich an Ihren Administrator',
    'No Data Found': 'Keine Daten gefunden',
    'Search staff': 'Personal suchen',
    Details: 'Einzelheiten',
    'Search subscribers': 'Abonnenten suchen',
    'Create Subscriber': 'Abonnenten erstellen',
    'Vitals Audit Trail': 'Vitals Audit Trail',
    'VITAL SIGN': 'LEBENSZEICHEN',
    'ACTION TYPE': 'AKTIONSTYP',
    'MODIFIED BY': 'GEÄNDERT DURCH',
    'MODIFIED AT': 'GEÄNDERTES DATUM',
    DETAILS: 'EINZELHEITEN',
    'Active Vital Signs': 'Aktive Vitalfunktionen',
    'Edit Vital Sign': 'Vitalzeichen bearbeiten',
    'Audit Item Details': 'Details zum Prüfgegenstand',
    'No audit events found': 'Es wurden keine Überwachungsereignisse gefunden',
    Units: 'Einheiten',
    'Access Control': 'Zugangskontrolle',
    Modules: 'Module',
    Applications: 'Anwendungen',
    Permissions: 'Berechtigungen',
    Policies: 'Richtlinien',
    Settings: 'die Einstellungen',
    'You must assume the role of an Organization to view this page':
      'Sie müssen die Rolle einer Organisation übernehmen, um diese Seite anzuzeigen',
    Unauthorized: 'Unberechtigt',
    'Vital Signs': 'Vitalfunktionen',
    'Vitals Settings': 'Vitale-Einstellungen',
    'No Vital Sign Assignments Assigned Yet':
      'Noch keine Vitalzeichen zugeordnet',
    'Assign one or more Vital Sign Assignments by clicking the "Assign Vitals Sign" button':
      'Weisen Sie ein oder mehrere Vitalzeichen zu, indem Sie auf die Schaltfläche "Vitalzeichen zuweisen" klicken',
    'Vital sign assigned to this organization':
      'Lebenszeichen dieser Organisation zugeordnet',
    Assign: 'Zuordnen',
    'Assign Vital Sign': 'Vitalzeichen zuweisen',
    Inactive: 'Inaktiv',
    'Inactive By ECG Admin': 'Inaktiv durch EKG-Admin',
    Active: 'Aktiv',
    Archived: 'Archiviert',
    'Archived By ECG Admin': 'Archiviert durch EKG-Admin',
    'Create Module': 'Modul erstellen',
    'Module Name': 'Modulname',
    Description: 'Beschreibung',
    Add: 'Hinzufügen',
    'Module Name is required': 'Modulname ist erforderlich',
    'Module created': 'Modul erstellt',
    'No Vital Sign Assignments Assigned To This Organization Yet':
      'Dieser Organisation sind noch keine Vitalzeichen zugeordnet',
    'Assign one or more Vital Sign Assignments by going to "Vital Signs" on the Settings page':
      'Weisen Sie ein oder mehrere Vitalzeichen zu, indem Sie auf der Seite "Einstellungen" zu "Vitalfunktionen" gehen',
    'Vitals Thresholds': 'Vitale Schwellenwerte',
    'Vitals Thresholds Settings': 'Einstellungen der Vitale Schwellenwerte',
    Normal: 'Normal',
    Low: 'Niedrig',
    High: 'Hoch',
    'Severity Level': 'Schweregrad',
    'Manage Module': 'Modul verwalten',
    'Assigned Applications': 'Zugewiesene Anwendungen',
    'Module Permissions': 'Modulberechtigungen',
    'Create Permissions': 'Berechtigungen erstellen',
    'Manage Applications': 'Anwendungen verwalten',
    Remove: 'Entfernen',
    Manage: 'Verwalten',
    Severity: 'Schwere',
    'High Risk': 'Hohes Risiko',
    'Medium Risk': 'Mittleres Risiko',
    'Low Risk': 'Niedriges Risiko',
    'Update Thresholds': 'Schwellenwerte aktualisieren',
    LOW: 'NIEDRIG',
    HIGH: 'HOCH',
    Thresholds: 'Schwellenwerte',
    'Vital Thresholds updated': 'Wichtige Schwellenwerte aktualisiert',
    'Normal Low': 'Normal niedrig',
    'Normal High': 'Normal hoch',
    Range: 'Angebot',
    'Edit Module': 'Modul bearbeiten',
    'Module updated': 'Modul aktualisiert',
    'Assign Applications': 'Anwendungen zuweisen',
    'Applications assigned': 'Zugewiesene Anwendungen',
    'Staff Members': 'Mitarbeiter',
    'Parent Organization': 'Übergeordnete Organisation',
    'View Flat List': 'Flache Liste anzeigen',
    'View Nested List': 'Verschachtelte Liste anzeigen',
    'Organization Directory': 'Organisationsverzeichnis',
    'No sub organizations found for this organization':
      'Für diese Organisation wurden keine Unterorganisationen gefunden',
    'Subscriber Not Found': 'Abonnent nicht gefunden',
    'Create & Add Another': 'Erstellen und Hinzufügen eines weiteren',
    'Create & Close': 'E rstellen & Schließen',
    'Permission created': 'Berechtigung erstellt',
    'Permission Name': 'Berechtigungsname',
    'Permission Code': 'Berechtigungscode',
    'Permission Name is required': 'Der Berechtigungsname ist erforderlich',
    'Permission Code is required': 'Berechtigungscode ist erforderlich',
    Reminders: 'Erinnerungen',
    'Edit Reminder Messages': 'Erinnerungsnachrichten bearbeiten',
    'Reminder Messages': 'Erinnerungsnachrichten',
    Translations: 'Übersetzungen',
    LOCALE: 'GEBIETSSCHEMA',
    Locale: 'Gebietsschema',
    'MESSAGE TYPE': 'NACHRICHTENTYP',
    'Modified By': 'Geändert durch',
    'Reminders Audit Trail': 'Erinnerungen Buchungskontrolle',
    'Edit Translation': 'Übersetzung bearbeiten',
    translation: 'übersetzung',
    'Are you sure you want to delete this translation? This will remove it completely':
      'Möchten Sie diese Übersetzung wirklich löschen? Dadurch wird es vollständig entfernt.',
    'Reminder messages updated': 'Erinnerungsnachrichten aktualisiert',
    'Reminder messages failed to update':
      'Erinnerungsnachrichten konnten nicht aktualisiert werden',
    'Translation successfully deleted': 'Übersetzung erfolgreich gelöscht',
    'Translation failed to delete': 'Übersetzung konnte nicht gelöscht werden',
    'Translation updated': 'Übersetzung aktualisiert',
    'Translation messages failed to update':
      'Übersetzungsnachrichten konnten nicht aktualisiert werden',
    'message is required': 'nachricht ist erforderlich',
    'Search audit trail': 'Audit-Trail durchsuchen',
    'General Information': 'Allgemeine Information',
    Organization: 'Organisation',
    Email: 'Email',
    PCP: 'GA',
    Age: 'Alter',
    Location: 'Ort',
    Phone: 'Telefon',
    'Language Preference': 'Spracheinstellung',
    Alerts: 'Warnungen',
    'View subscriber-level vital signs, vital measurements, and vital thresholds':
      'Zeigen Sie Vitalfunktionen, Vitalmessungen und Vitalschwellenwerte auf Abonnentenebene an.',
    'View subscriber-level reminder types, messages, and missed reminders':
      'Anzeigen von Erinnerungstypen, Nachrichten und fehlenden Erinnerungen auf Abonnentenebene.',
    'View subscriber-level active alerts and alert history':
      'Aktive Warnungen und Warnungsverlauf auf Abonnentenebene anzeigen.',
    'Last Alert': 'Letzte Warnung',
    'Active For': 'Aktiv für',
    'Weight Medium Risk Threshold': 'Gewicht mittlerer Risikoschwelle',
    ago: 'vor',
    pending: 'steht aus',
    'Organization Subscribers': 'Organisationsabonnenten',
    'Subscriber Vitals': 'Abonnenten Vitals',
    'Graph View': 'Diagrammansicht',
    'List View': 'Listenansicht',
    'Vital Sign': 'Lebenszeichen',
    OBSERVATION: 'ÜBERWACHUNG',
    TIMESTAMP: 'ZEITSTEMPEL',
    'Create and manage vital signs':
      'Erstellen und verwalten Sie Vitalfunktionen',
    'Create and manage organization reminder messages and translations':
      'Erstellen und verwalten Sie Erinnerungsnachrichten und Übersetzungen',
    'Create Translation': 'Übersetzung erstellen',
    'Policy Groups': 'Richtliniengruppen',
    'Date Range': 'Datumsbereich',
    Start: 'Start',
    End: 'Ende',
    'Assign and manage organization-level vital signs':
      'Zuweisung von Vitalzeichen der Organisation',
    'Manage organization-level vital sign thresholds':
      'chwellenwerteinstellungen für die Vitalfunktionen der Organisation',
    'Create Reminder Messages': 'Erinnerungsnachrichten erstellen',
    'No Reminder Messages Created Yet':
      'Es wurden noch keine erinnerungsnachrichten erstellt',
    'Create reminder messages by clicking the Create Reminder Messages button':
      'Erstellen Sie Erinnerungsnachrichten, indem Sie auf die Schaltfläche "Erinnerungsnachrichten erstellen" klicken.',
    'Use ECG default messages for organization':
      'Verwenden Sie EKG-Standardnachrichten für die Organisation',
    'No Modules Found': 'Keine Module gefunden',
    STATUS: 'STATUS',
    CODE: 'CODE',
    MODULE: 'MODUL',
    DESCRIPTION: 'BESCHREIBUNG',
    'Archived Permissions': 'Archivierte Berechtigungen',
    'Here are all the Permissions available for the ECG Platform':
      'Hier finden Sie alle für die EKG-Plattform verfügbaren Berechtigungen',
    'Search permissions': 'Suchberechtigungen',
    'No Permissions Found': 'Keine Berechtigungen gefunden',
    Module: 'Modul',
    'Language Locale': 'Sprachgebietsschema',
    'Create and manage modules (Vitals Module, Medications Module, etc) for the platform':
      'Erstellen und Verwalten von Modulen (Vitals Module, Medications Module, usw.) für die Plattform.',
    'Create and manage applications (ARPM, Addison, etc) for the platform':
      'Erstellen und verwalten Sie Anwendungen (ARPM, Addison usw.) für die Plattform.',
    'Create and manage permissions (vitals-readonly, medications-admin, etc) for the platform':
      'Erstellen und verwalten Sie Berechtigungen (vitals.readonly, medications.admin, etc.) für die Plattform.',
    'Create and manage policies (groups of permissions) for the platform':
      'Erstellen und verwalten Sie Richtlinien (Gruppen von Berechtigungen) für die Plattform.',
    'Create and manage policy groups (groups of policies) for the platform':
      'Erstellen und verwalten Sie Richtliniengruppen (Gruppen von Richtlinien) für die Plattform.',
    'Create Policies': 'Richtlinien erstellen',
    'Archived Policies': 'Archivierte Richtlinien',
    'Search policies': 'Suchrichtlinien',
    'No Policies Found': 'Keine Richtlinien gefunden',
    'Policy Name': 'Versicherungsname',
    'Policy Code': 'Richtliniencode',
    'Available Permissions': 'Berechtigungen hinzufügen',
    'No Permissions Added Yet':
      'Es wurden noch keine Berechtigungen hinzugefügt',
    'Add Selected Permissions To Policy':
      'Hinzufügen ausgewählter Berechtigungen zur Richtlinie',
    'You can group permissions from various modules into a POLICY':
      'Sie können Berechtigungen aus verschiedenen Modulen in einer POLICY gruppieren',
    'Policies grant a set of permissions to assigned users':
      'Richtlinien gewähren zugewiesenen Benutzern eine Reihe von Berechtigungen',
    'At least one module must exist in order to assign permissions to a policy':
      'Es muss mindestens ein Modul vorhanden sein, um einer Richtlinie Berechtigungen zuweisen zu können',
    'Create Modules': 'Module erstellen',
    'Selected Permissions': 'Ausgewählte Berechtigungen',
    'No Permissions Available In This Module':
      'In diesem Modul sind keine Berechtigungen verfügbar',
    'Create Policy Groups': 'Erstellen Sie Richtliniengruppen',
    'Archived Policy Groups': 'Archivierte Richtliniengruppen',
    'Search policy groups': 'Suchen Sie nach Richtliniengruppen',
    'No Policy Groups Found': 'Keine Richtliniengruppen gefunden',
    'You can group policies into a POLICY GROUP':
      'Sie können Richtlinien in einer POLICY GROUP gruppieren',
    'Policy groups grant a set of policies to assigned users':
      'Richtliniengruppen gewähren zugewiesenen Benutzern eine Reihe von Richtlinien',
    'Policy Group Title': 'Titel der Richtliniengruppe',
    'Policy Group Code': 'Richtliniengruppencode',
    'Add Policies': 'Richtlinien hinzufügen',
    'Add Selected Policies To Policy Group':
      'Hinzufügen ausgewählter Richtlinien zur Richtliniengruppe',
    'No Policies Added Yet': 'Es wurden noch keine Richtlinien hinzugefügt',
    'No Policies Available To Add':
      'Keine Richtlinien zum Hinzufügen verfügbar',
    'Selected Policies': 'Ausgewählte Richtlinien',
    'At least one policy must exist in order to assign policies to a policy group':
      'Es muss mindestens eine Richtlinie vorhanden sein, um einer Richtliniengruppe Richtlinien zuweisen zu können',
    'Edit Archived Policy': 'Archivierte Richtlinie bearbeiten',
    'Permission updated': 'Berechtigung aktualisiert',
    'Permission deactivated': 'Berechtigung deaktiviert',
    'Permission activated': 'Berechtigung aktiviert',
    'Permission archived': 'Berechtigung archiviert',
    'Permission restored': 'Berechtigung wiederhergestellt',
    'Permission deleted': 'Berechtigung gelöscht',
    'Policy created': 'Richtlinie erstellt',
    'Policy updated': 'Richtlinie aktualisiert',
    'Policy deactivated': 'Richtlinie deaktiviert',
    'Policy activated': 'Richtlinie aktiviert',
    'Policy archived': 'Richtlinie archiviert',
    'Policy restored': 'Richtlinie wiederhergestellt',
    'Policy deleted': 'Richtlinie gelöscht',
    'Policy Group created': 'Richtliniengruppe erstellt',
    'Policy Group updated': 'Richtliniengruppe aktualisiert',
    'Policy Group deactivated': 'Richtliniengruppe deaktiviert',
    'Policy Group activated': 'Richtliniengruppe aktiviert',
    'Policy Group archived': 'Richtliniengruppe archiviert',
    'Policy Group restored': 'Richtliniengruppe wiederhergestellt',
    'Policy Group deleted': 'Richtliniengruppe gelöscht',
    'Application created': 'Anwendung erstellt',
    'Application updated': 'Anwendung aktualisiert',
    'Application deactivated': 'Anwendung deaktiviert',
    'Application activated': 'Anwendung aktiviert',
    'Application archived': 'Anwendung archiviert',
    'Application restored': 'Anwendung wiederhergestellt',
    'Application deleted': 'Anwendung gelöscht',
    'Create Applications': 'Anwendungen erstellen',
    'Archived Applications': 'Archivierte Anwendungen',
    'Search applications': 'Anwendungen suchen',
    'No Applications Found': 'Keine Anwendungen gefunden',
    'COGNITO APP ID': 'COGNITO APP ID',
    'Edit Application': 'Anwendung bearbeiten',
    'Edit Archived Application': 'Archivierte Anwendung bearbeiten',
    'Available Modules': 'Module hinzufügen',
    'Add Selected Modules To Application':
      'Ausgewählte Module zur Anwendung hinzufügen',
    'Selected Modules': 'Ausgewählte Module',
    'No Modules Selected': 'Es wurden noch keine Module hinzugefügt',
    'Cognito App Id': 'Cognito App Id',
    'At least one module must exist in order to assign modules to a Application':
      'Es muss mindestens ein Modul vorhanden sein, um einer Anwendung Module zuzuweisen',
    'Application Name': 'Anwendungsname',
    'No Policy Groups Created': 'Keine Richtliniengruppen erstellt',
    'No Policies Created': 'Keine Richtlinien erstellt',
    'No Permissions Created': 'Keine Berechtigungen erstellt',
    'No Applications Created': 'Keine Anwendungen erstellt',
    'No Modules Created': 'Keine Module erstellt',
    DATE: 'DATUM',
    ISSUE: 'PROBLEM',
    Issue: 'Problem',
    'DATE ADDRESSED': 'DATUM ADRESSIERT',
    'ADDRESSED BY': 'ADRESSIERT VON',
    'Addressed By': 'Adressiert von',
    'Search alerts': 'Suchalarme',
    'Try changing your search term':
      'Versuchen Sie, Ihren Suchbegriff zu ändern',
    Disclaimers: 'Haftungsausschluss',
    'Manage disclaimer types, messages, and translations for the platform':
      'Verwalten Sie Haftungsausschlusstypen, Nachrichten und Übersetzungen für die Plattform.',
    'Basic Information': 'Grundinformation',
    'Personal Information': 'Persönliche Angaben',
    Prefix: 'Präfix',
    'First Name': 'Vorname',
    'Last Name': 'Nachname',
    Suffix: 'Suffix',
    'Staff Member Type': 'itarbeitertyp',
    'Assign Staff Member As Provider': 'Mitarbeiter als Anbieter zuweisen',
    'Selecting this option will allow this staff member to act as a provider for subscribers':
      'Durch Auswahl dieser Option kann dieser Mitarbeiter als Anbieter für Abonnenten fungieren',
    'This gives them access rights to view and manage PHI':
      'Dadurch erhalten sie Zugriffsrechte zum Anzeigen und Verwalten von PHI',
    'Contact Information': 'Kontakt Informationen',
    'Contact Email Address': 'Kontakt-E-Mailadresse',
    'Account Information': 'Kontoinformationen',
    'Creating a staff member for': 'Erstellen eines Mitarbeiters für',
    'Create Staff': 'Mitarbeiter erstellen',
    'Account Email Address': 'Konto E-Mail-Adresse',
    'Password Options': 'Passwortoptionen',
    'Auto-Generated': 'Automatisch generiert',
    Manual: 'Handbuch',
    'This information will be emailed to the staff member once the user has been created':
      'Diese Informationen werden per E-Mail an den Mitarbeiter gesendet, sobald der Benutzer erstellt wurde',
    'They will then be able to login to the RPM Platform with these credentials':
      'Sie können sich dann mit diesen Anmeldeinformationen bei der RPM-Plattform anmelden',
    'Save & Continue': 'Speichern fortsetzen',
    'First Name is required': 'Vorname ist erforderlich',
    'Last Name is required': 'Nachname ist erforderlich',
    'Account Email is required': 'Konto-E-Mail ist erforderlich',
    'Password is required': 'Passwort wird benötigt',
    Password: 'Passwort',
    Back: 'Zurück',
    'Assign Policies': 'Richtlinien zuweisen',
    'No Policies Assigned Yet': 'Noch keine Richtlinien zugewiesen',
    'No Policies are assigned to this Staff Member yet':
      'No Diesen Mitarbeitern sind noch Richtlinien zugewiesen',
    'Click on the button below to grant access rights to this Staff Member':
      'Klicken Sie auf die Schaltfläche unten, um diesem Mitarbeiter Zugriffsrechte zu gewähren',
    Save: 'speichern',
    'Add Selected Policies': 'Ausgewählte Richtlinien hinzufügen',
    'No Policies Selected': 'Keine Richtlinien ausgewählt',
    'Contact Email is required': 'Kontakt E-Mail ist erforderlich',
    'No Sub-Organizations Found': 'Keine Unterorganisationen gefunden',
    'Create a sub-organization through Apptivo and it will show up here':
      'Erstellen Sie über Apptivo eine Unterorganisation, die hier angezeigt wird.',
    'No Organizations Found': 'Keine Organisationen gefunden',
    'Contact your administrator to get assigned to correct organization':
      'Wenden Sie sich an Ihren Administrator, um die richtige Organisation zu erhalten.',
    'View, add and manage organization reminder presets':
      'Anzeigen, Hinzufügen und Verwalten von Organisationserinnerungsvoreinstellungen.',
    'View, add and manage organization webhooks':
      'Anzeigen, Hinzufügen und Verwalten von Organisations-Webhooks.',
    'Search webhooks': 'Suche nach Webhooks',
    'No Webhooks Found': 'Keine Webhooks gefunden',
    Webhooks: 'Webhooks',
    URL: 'URL',
    VERSION: 'AUSFÜHRUNG',
    MODE: 'MODUS',
    ERRORS: 'FEHLER',
    'Reminder time should be set relative to the subscribers timezone':
      'Die Erinnerungszeit sollte relativ zur Zeitzone des Teilnehmers eingestellt werden.',
    ADMIN: 'ADMIN',
    Admins: 'Admins',
    'Search admins': 'Suche nach Administratoren',
    'Create Admin': 'Admin erstellen',
    'This information will be emailed to the admin once the user has been created':
      'Diese Informationen werden per E-Mail an den Administrator gesendet, sobald der Benutzer erstellt wurde',
    'They will then be able to login to ADDI with these credentials':
      'Sie können sich dann mit diesen Anmeldeinformationen bei ADDI anmelden',
    'No Policies are assigned to this Admin yet':
      'Diesem Administrator sind noch keine Richtlinien zugewiesen',
    'Click on the button below to grant access rights to this Admin':
      'Klicken Sie auf die Schaltfläche unten, um diesem Administrator Zugriffsrechte zu gewähren',
    'This organization has at least one assigned vital sign with no thresholds set':
      'Dieser Organisation ist mindestens ein Vitalzeichen zugewiesen, für das keine Schwellenwerte festgelegt wurden',
    'Create Thresholds': 'Schwellenwerte erstellen',
    'Create Vital Thresholds': 'Schwellenwerte erstellen',
    'Normal Low is required': 'Normal Niedrig ist erforderlich',
    'Normal High is required': 'Normales Hoch ist erforderlich',
    'Go To Vital Signs': 'Gehen Sie zu den Vitalfunktionen',
    'Assign & Add Another': 'Zuweisen & Hinzufügen eines weiteren',
    'Assign & Close': 'Zuweisen & Schließen',
    'No Vitals Thresholds Assigned To This Organization Yet':
      'Dieser Organisation sind noch keine Vitalwerte zugeordnet',
    'Subscriber Thresholds': 'Teilnehmerschwellenwerte',
    'Default Organization Thresholds': 'Standard-Organisationsschwellenwerte',
    'No Default Organization Thresholds Found':
      'Keine Standard-Organisationsschwellen gefunden',
    'Assign Thresholds': 'Schwellenwerte zuweisen',
    'Global Platform Overview': 'Überblick über die globale Plattform',
    Overview: 'Überblick',
    'Language preference updated': 'Spracheinstellung aktualisiert',
  },
}

export default translation
