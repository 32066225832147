import clsx from 'clsx'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { LoadingIcon, Select, SelectItem, Switch, Icon } from 'elements'
import { Button, Tooltip } from 'atlas'
import { useOrganizationQuery } from 'hooks/organizations'
import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { useStaffMemberTypesQuery } from 'hooks/seed-data'
import { AssignedPoliciesList } from 'components/access-control/policies'
import { useCreateStaffMutation } from 'hooks/user-staff'
import tw, { styled } from 'twin.macro'

type CreateStaffFormPermissionsProps = {
  previousStep: () => void
  className?: string
  personId: string
}

const CreateStaffFormPermissions = ({
  className,
  personId,
}: CreateStaffFormPermissionsProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { data: organization, isLoading: isLoadingOrg } = useOrganizationQuery(
    assumedOrganizationRole?.orgID || ''
  )
  const { t } = useTranslation()
  const { watch, handleSubmit } = useFormContext<CreateStaffMemberForm>()
  const navigate = useNavigate()
  const {
    mutate: createStaffMember,
    isLoading: isCreateStaffMemberLoading,
  } = useCreateStaffMutation()
  const staffMemberTypesQuery = useStaffMemberTypesQuery()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedPolicies, setSelectedPolicies] = useState<Policy[]>([])
  const [savedPolicies, setSavedPolicies] = useState<Policy[]>([])
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  useEffect(() => {
    setSelectedPolicies(
      savedPolicies.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    )
  }, [searchValue])

  if (isLoadingOrg || staffMemberTypesQuery.isLoading)
    return <LoadingIcon className={className} />
  return (
    <div className={clsx('flex flex-col h-full w-full flex-grow', className)}>
      <PermissionsPageHeader>{t('Staff Member Info')}</PermissionsPageHeader>
      <StaffInfoContainter>
        <StaffTypeContainer>
          <Controller
            as={
              <Select
                label={t('Staff Member Type')}
                variant="outlined"
                fullWidth={false}
                selectClassName="w-36 mr-6"
              >
                {staffMemberTypesQuery.data?.items?.map(
                  (staffMemberType, index) => (
                    <SelectItem value={staffMemberType.id} key={index}>
                      {staffMemberType.name}
                    </SelectItem>
                  )
                )}
              </Select>
            }
            name="memberType"
            defaultValue={staffMemberTypesQuery.data?.items?.[0].id}
          />
          {/* If Staff Member type has a description, show an info tooltip for it */}
          {staffMemberTypesQuery.data?.items?.find(
            (memberType) => Number(memberType.id) === watch('memberType')
          )?.description ? (
            <ToolTip
              content={
                <p className="w-60">
                  {
                    // @ts-expect-error This find will return a result because this is conditionally rendered for such
                    staffMemberTypesQuery.data.items.find(
                      (memberType) =>
                        Number(memberType.id) === watch('memberType')
                    ).description
                  }
                </p>
              }
            >
              <InfoIcon type="info" />
            </ToolTip>
          ) : null}
          <Span>
            <Controller
              render={({ onChange, value, name, ref }) => (
                <Switch
                  onChange={(newVal) => onChange(newVal)}
                  checked={value}
                  id={name}
                  ref={ref}
                  label={t('Assign Staff Member As Provider')}
                  className="mt-3 mr-1"
                />
              )}
              defaultValue={false}
              name="isProvider"
            />
            <ToolTip
              content={
                <p className="w-60">{`${t(
                  'Selecting this option will allow this staff member to act as a provider for subscribers'
                )}. ${t(
                  'This gives them access rights to view and manage PHI'
                )}`}</p>
              }
            >
              <InfoIcon type="info" />
            </ToolTip>
          </Span>
        </StaffTypeContainer>
      </StaffInfoContainter>

      <PoliciesContainer>
        <PermissionsPageHeader>{t('Policies')}</PermissionsPageHeader>

        <AssignPoliciesButton
          type="primary-filled"
          hidden={!selectedPolicies.length}
          onClick={() => {
            setSelectedPolicies(savedPolicies)
            setIsFormOpen(true)
          }}
        >
          {t('Assign Policies')}
        </AssignPoliciesButton>
      </PoliciesContainer>

      <AssignedPoliciesList
        userType="staff"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        attachedPolicies={selectedPolicies}
        isAssignPoliciesFormOpen={isFormOpen}
        setIsAssignPoliciesFormOpen={setIsFormOpen}
        onRemovePolicy={(policyToRemove) => {
          setSavedPolicies((policy) =>
            policy.filter((item) => item.id !== policyToRemove.id)
          )
          setSelectedPolicies((policy) =>
            policy.filter((item) => item.id !== policyToRemove.id)
          )
        }}
        onUpdatePolicies={(policyToUpdate) => {
          setSavedPolicies([...policyToUpdate])
          setSelectedPolicies([...policyToUpdate])
        }}
      />
      <div>
        <Button
          type="primary-filled"
          disabled={isCreateStaffMemberLoading || selectedPolicies.length === 0}
          onClick={handleSubmit(() => {
            const formData = watch()
            createStaffMember(
              {
                staffData: {
                  ...formData,
                  policies: savedPolicies.map((policy) => policy.id),
                  personId: personId || '',
                },
                orgId: organization?.id || '',
              },
              {
                onSuccess: () => navigate('/staff'),
              }
            )
          })}
        >
          {t('Create Staff Member')}
        </Button>
        <CancelButton type="primary-link" to="/staff">
          {t('Cancel')}
        </CancelButton>
      </div>
    </div>
  )
}

export default CreateStaffFormPermissions

const AssignPoliciesButton = styled(Button)<{ hidden: boolean }>`
  ${({ hidden }) => hidden && tw`hidden`}
`

const CancelButton = tw(Button)`ml-5`

const StaffInfoContainter = tw.div`bg-white p-5 rounded-lg border border-gray-300 my-4`

const StaffTypeContainer = tw.div`flex gap-4 items-center my-4`

const Span = tw.span`flex items-center`

const ToolTip = tw(Tooltip)`flex items-center`

const InfoIcon = tw(
  Icon
)`text-gray-600 hover:text-gray-900 mt-3 w-5 h-5 align-middle`

const PermissionsPageHeader = tw.h3`text-xl font-semibold`

const PoliciesContainer = tw.div`flex justify-between my-3`
