import React, { useEffect, useState } from 'react'
import { Icon } from 'elements'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHover } from 'react-use'
import tw, { styled } from 'twin.macro'

type CopyToClipboardButtonProps = {
  value?: string
  children: React.ReactNode
  onCopy?: (text: string, result: boolean) => void
  className?: string
}

const CopyToClipboardButton = ({
  value = '',
  children,
  onCopy,
  className,
}: CopyToClipboardButtonProps) => {
  const [hasUserCopied, setHasUserCopied] = useState(false)

  const element = () => {
    return (
      // className required due to twin macro issue https://twinredirect.page.link/group
      <Container className="group">
        <CopyToClipboard
          text={value}
          onCopy={(text, result) => {
            // call the user provided onCopy
            onCopy?.(text, result)

            // set the internal hasUserCopied to true
            setHasUserCopied(true)
          }}
        >
          <Text className={className}>
            {children}
            <Copied
              type={hasUserCopied ? 'check' : 'clipboard'}
              copied={hasUserCopied}
            />
          </Text>
        </CopyToClipboard>
      </Container>
    )
  }

  const [hoverable, hovered] = useHover(element)

  useEffect(() => {
    if (!hovered && hasUserCopied) setHasUserCopied(false)
  }, [hovered])

  return <>{hoverable}</>
}

export default CopyToClipboardButton

const Copied = styled(Icon)<{ copied: boolean }>`
  ${({ copied }) => {
    if (copied)
      return tw`ml-1 text-emerald-400 transition-all opacity-0 group-hover:opacity-100`
    return tw`ml-1 text-blue-400 opacity-0 group-hover:opacity-100`
  }}
`
const Container = tw.div``

const Text = tw.span`inline-flex items-center cursor-pointer`
