import React from 'react'
import { Statistic, NavigationCard } from 'elements'
import { FadeInSlideDown } from 'animations'
import { useDelay, useNavigate } from 'hooks'
import tw from 'twin.macro'
import MostActiveSubscribers from './MostActiveSubscribers'
import { useOrganizationMemberStatistics } from 'hooks/statistics'
import numeral from 'numeral'

const OrganizationSubscriberOverview = () => {
  const navigate = useNavigate()
  const delay = useDelay()

  const organizationMemberStatistics = useOrganizationMemberStatistics()

  return (
    <Grid>
      <TwoColumnSpan delay={delay()}>
        <MostActiveSubscribers />
      </TwoColumnSpan>
      <Statistics>
        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="subscribers"
            iconColor="yellow"
            label="Subscribers"
            value={numeral(
              organizationMemberStatistics.data?.subscribers.total.toString()
            ).format('0,0')}
          />
        </FadeInSlideDown>

        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="subscribers"
            iconColor="yellow"
            label="Subscriber Directory"
            description="View and manage subscribers in list format"
            onClick={() => navigate('directory')}
          />
        </FadeInSlideDown>
      </Statistics>
    </Grid>
  )
}

export default OrganizationSubscriberOverview

const Grid = tw.div`grid grid-cols-2 xl:grid-cols-3 gap-4`

const TwoColumnSpan = tw(FadeInSlideDown)`col-span-2`

const Statistics = tw.div`grid col-span-2 xl:col-span-1 gap-1 h-56`
