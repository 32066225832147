import { Button, Chip, Empty } from 'atlas'
import { SubscriberAddressForm } from 'components/subscriber-create'
import { useWizardForm } from 'context/wizard-form'
import { ConfirmationDialogs, Icon, LoadingIcon } from 'elements'
import { ChildStepContainer } from 'elements/WizardForm'
import {
  useDeleteSubscriberAddressMutation,
  useDeleteSubscriberPhoneMutation,
  useSubscriberAddressesQuery,
  useSubscriberPhonesQuery,
} from 'hooks/user-subscriber'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSearchParam } from 'react-use'
import tw from 'twin.macro'
import { addressToMapBox, formatPhoneNumber } from 'utils'
import PhoneNumberForm from './PhoneNumberForm'

export type FormAddress = SubscriberAddress & {
  resultObj: MapBoxFeature
}

const ContactInformation = () => {
  const { t } = useTranslation()

  const createSubscriberId = useSearchParam('subscriberId')
  const { subscriberId: editSubscriberId } = useParams()
  const subscriberId = createSubscriberId || editSubscriberId

  const { setStepState } = useWizardForm()

  // address variables
  const [isAddressFormOpen, setIsAddressFormOpen] = useState<boolean>(false)
  const [addresses, setAddresses] = useState<SubscriberAddress[]>([])
  const [addressToMutate, setAddressToMutate] = useState<SubscriberAddress>()
  const [
    isDeleteAddressDialogOpen,
    setIsDeleteAddressDialogOpen,
  ] = useState<boolean>(false)

  // phone number variables
  const [isPhoneNumberFormOpen, setIsPhoneNumberFormOpen] = useState<boolean>(
    false
  )
  const [phoneToMutate, setPhoneToMutate] = useState<SubscriberPhone>()
  const [
    isDeletePhoneDialogOpen,
    setIsDeletePhoneDialogOpen,
  ] = useState<boolean>(false)

  const subscriberAddressesQuery = useSubscriberAddressesQuery(subscriberId)
  const subscriberPhonesQuery = useSubscriberPhonesQuery(subscriberId)

  const deleteAddressMutation = useDeleteSubscriberAddressMutation()
  const deletePhoneMutation = useDeleteSubscriberPhoneMutation()

  // sync local addresses with those returned by the API
  useEffect(() => {
    if (subscriberAddressesQuery.data)
      setAddresses(
        subscriberAddressesQuery.data.addresses.map((address, index) => ({
          ...address,
          address: {
            ...address.address,
            id: '' + index,
          },
          resultObj: addressToMapBox(address.address),
        }))
      )
  }, [subscriberAddressesQuery.data])

  // handle toggling disabling next step depending on phoneNumbers/addresses
  useEffect(() => {
    setStepState({
      isSubmitDisabled:
        !subscriberPhonesQuery.data?.length || !addresses.length,
    })
  }, [subscriberPhonesQuery.data, addresses])

  const confirmations = [
    // Delete Address Dialog
    {
      setIsOpen: setIsDeleteAddressDialogOpen,
      isOpen: isDeleteAddressDialogOpen,
      title: t(`Delete Address`),
      content: (
        <p>{t('Are you sure you want to permanently delete this address?')}</p>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            deleteAddressMutation.mutate({
              addressId: addressToMutate?.id || '',
              subscriberId: subscriberId || '',
            })
            setIsDeleteAddressDialogOpen(false)
          }}
        >
          {t('Delete')}
        </Button>
      ),
    },

    //Delete Phone Dialog
    {
      setIsOpen: setIsDeletePhoneDialogOpen,
      isOpen: isDeletePhoneDialogOpen,
      title: t('Delete Phone'),
      content: (
        <p>{t('Are you sure you want to permanently delete this phone?')}</p>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            deletePhoneMutation.mutate({
              phoneNumberId: phoneToMutate?.id || '',
              subscriberId: subscriberId || '',
            })
            setIsDeletePhoneDialogOpen(false)
          }}
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]
  if (!subscriberAddressesQuery.data || !subscriberPhonesQuery.data)
    return <LoadingIcon />

  return (
    <ChildStepContainer>
      <Header>
        <AddressTitle>{t('Addresses')}</AddressTitle>
        {addresses.length ? (
          <Button
            onClick={() => {
              setAddressToMutate(undefined)
              setIsAddressFormOpen(true)
            }}
            // if all 3 address types are assigned to addresses disable ability to create more
            disabled={
              _.flatten(addresses.map((address) => address.types)).length === 3
            }
          >
            {t('Add Address')}
          </Button>
        ) : null}
      </Header>
      {addresses.length ? (
        <AddressList>
          {addresses.map((address, index) => (
            <AddressCard key={index}>
              <Address>{`${address.address.addressLine1}${
                address.address.addressLine2
                  ? ' ' + address.address.addressLine2
                  : ''
              }, ${
                address.address.city
                // remove the 'US-' at the front of the province iso code
              }, ${address.address.stateProvinceCode.slice(3)} ${
                address.address.postalCode
              }`}</Address>
              <AddressTypes>
                {address.types.map((addressType, index) => (
                  <ChipContainer key={index}>
                    <Chip
                      color={
                        {
                          primary: 'lightblue' as const,
                          billing: 'red' as const,
                          shipping: 'yellow' as const,
                        }[addressType]
                      }
                    >
                      {_.capitalize(addressType)}
                    </Chip>
                  </ChipContainer>
                ))}
              </AddressTypes>
              <IconContainer>
                <EditIcon
                  type="edit"
                  onClick={() => {
                    setAddressToMutate(address)
                    setIsAddressFormOpen(true)
                  }}
                  buttonClassName="align-middle"
                />
                <TrashIcon
                  type="trash"
                  onClick={() => {
                    setAddressToMutate(address)
                    setIsDeleteAddressDialogOpen(true)
                  }}
                  buttonClassName="align-middle"
                />
              </IconContainer>
            </AddressCard>
          ))}
        </AddressList>
      ) : (
        <EmptyContainer>
          <EmptyDisplay
            title={t('No Address Information')}
            callToAction={
              <Button
                onClick={() => {
                  setAddressToMutate(undefined)
                  setIsAddressFormOpen(true)
                }}
              >
                {t('Add Address')}
              </Button>
            }
          />
        </EmptyContainer>
      )}
      <Header>
        <PhoneNumberTitle>{t('Phone Numbers')}</PhoneNumberTitle>
        {subscriberPhonesQuery.data.length ? (
          <AddPhoneButton
            disabled={
              subscriberPhonesQuery.data.map((phone) => phone.type).length >= 4
            }
            onClick={() => {
              setPhoneToMutate(undefined)
              setIsPhoneNumberFormOpen(true)
            }}
          >
            {t('Add Phone Number')}
          </AddPhoneButton>
        ) : null}
      </Header>
      {subscriberPhonesQuery.data.length ? (
        subscriberPhonesQuery.data.map((phone, index) => {
          return (
            <PhoneNumberCard key={index}>
              <Phone>
                {formatPhoneNumber(phone.number)}
                {phone.extension ? `, ext. ${phone.extension}` : null}
              </Phone>
              <Chip
                color={
                  {
                    Mobile: 'orange' as const,
                    Home: 'green' as const,
                    Work: 'yellow' as const,
                    Main: 'lightblue' as const,
                  }[phone.type]
                }
              >
                {_.capitalize(phone.type)}
              </Chip>
              <IconContainer>
                <EditIcon
                  type="edit"
                  onClick={() => {
                    setPhoneToMutate(phone)
                    setIsPhoneNumberFormOpen(true)
                  }}
                />
                <TrashIcon
                  type="trash"
                  onClick={() => {
                    setPhoneToMutate(phone)
                    setIsDeletePhoneDialogOpen(true)
                  }}
                />
              </IconContainer>
            </PhoneNumberCard>
          )
        })
      ) : (
        <EmptyContainer>
          <EmptyDisplay
            title={t('No Phone Numbers')}
            callToAction={
              <Button
                onClick={() => {
                  setPhoneToMutate(undefined)
                  setIsPhoneNumberFormOpen(true)
                }}
              >
                {t('Add Phone Number')}
              </Button>
            }
          />
        </EmptyContainer>
      )}
      <SubscriberAddressForm
        subscriberId={subscriberId}
        isFormOpen={isAddressFormOpen}
        setIsFormOpen={setIsAddressFormOpen}
        addressToMutate={addressToMutate}
        takenAddressTypes={_.flatten(
          addresses.map(({ types }) =>
            types.map(
              (type): Lowercase<typeof type> =>
                _.lowerCase(type) as Lowercase<typeof type>
            )
          )
        )}
      />
      <PhoneNumberForm
        isFormOpen={isPhoneNumberFormOpen}
        setIsFormOpen={setIsPhoneNumberFormOpen}
        phoneToMutate={phoneToMutate}
        takenPhoneTypes={subscriberPhonesQuery.data
          .map((phone) => phone.type)
          .filter((phoneType) => phoneType !== phoneToMutate?.type)}
      ></PhoneNumberForm>
      <ConfirmationDialogs confirmations={confirmations} />
    </ChildStepContainer>
  )
}

export default ContactInformation

const Header = tw.div`flex`

const AddressTitle = tw.h3`text-xl font-semibold flex-grow`

const AddressList = tw.div`grid`

const AddressCard = tw.div`flex items-center w-full my-2 py-2 px-4 border rounded`

const Address = tw.div`text-lg flex-grow`

const AddressTypes = tw.div`flex font-medium ml-2`

const IconContainer = tw.div`flex items-center`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600 ml-2`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900 ml-4`

const PhoneNumberTitle = tw.h3`text-xl font-semibold mt-4 flex-grow`

const PhoneNumberCard = tw.div`flex items-center w-full my-2 py-2 px-4 border rounded`

const Phone = tw.div`text-lg flex-grow`

const AddPhoneButton = tw(Button)`mt-4`

const EmptyDisplay = tw(Empty)`border-2 border-dashed rounded`

const EmptyContainer = tw.div`flex-grow`

const ChipContainer = tw.div`ml-2`
