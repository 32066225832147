import React, { useRef, useState } from 'react'
import { ConfirmationDialogs, Dropdown, DropdownItem, Icon } from 'elements'
import { Button, Chip } from 'atlas'
import { useRestoreSubscriberVitalAssignmentMutation } from 'hooks/vitals/vital-assignments'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'

type SubscriberVitalSignCardProps = {
  vitalAssignment: VitalAssignment
  vitalAssignments: SubscriberVitalAssignments
}

const SubscriberArchivedVitalSignCard = ({
  vitalAssignment,
  vitalAssignments,
}: SubscriberVitalSignCardProps) => {
  const { t } = useTranslation()
  const { subscriberId } = useParams()

  const {
    mutate: restoreVitalAssignment,
  } = useRestoreSubscriberVitalAssignmentMutation()
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const dropdownAnchorEl = useRef(null)

  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${vitalAssignment.name}" vital sign assignment`),
      content: (
        <p>
          {t('Are you sure you want to restore this vital sign assignment?')}
        </p>
      ),
      actions: (
        <Button
          onClick={() => {
            restoreVitalAssignment({
              subscriberId,
              vitalAssignments,
              vitalSignId: vitalAssignment.id,
            })
            return setIsRestoreDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
  ]

  return (
    <VitalSignCard>
      <DropdownIconContainer>
        <DropdownItemContainer>
          <VitalAssignmentTitle>{vitalAssignment.name}</VitalAssignmentTitle>
          <DropdownAnchor ref={dropdownAnchorEl}>
            <EllipsisIcon
              data-testid={`${vitalAssignment.name}-ellipsis`}
              type="ellipsis"
              onClick={handleDropdownToggle}
            />
            <Dropdown
              visible={isDropdownVisible}
              setVisible={setIsDropdownVisible}
              parentRef={dropdownAnchorEl}
            >
              <DropdownItem
                onClick={() => {
                  setIsRestoreDialogOpen(true)

                  // Reset Popover
                  setIsDropdownVisible(false)
                }}
              >
                {t('Restore')}
              </DropdownItem>
            </Dropdown>
          </DropdownAnchor>
        </DropdownItemContainer>
        <Chip color="red">{t('Archived')}</Chip>
      </DropdownIconContainer>
      <DisplayTitle>
        {t('Display Name')}:{' '}
        <DisplayValue>{vitalAssignment.displayName}</DisplayValue>
      </DisplayTitle>
      <DisplayTitle>
        {t('Units')}:{' '}
        <DisplayValue>
          {
            vitalAssignment.units.find(
              (unit) => unit.code === vitalAssignment.defaultUnitCode
            )?.displayName
          }
        </DisplayValue>
      </DisplayTitle>
      <ConfirmationDialogs confirmations={confirmations} />
    </VitalSignCard>
  )
}

export default SubscriberArchivedVitalSignCard

const VitalSignCard = tw.div`bg-white pr-5 rounded-lg border border-gray-200`

const DropdownIconContainer = tw.div`border-b pb-2 mb-2`

const DropdownItemContainer = tw.div`mb-1 flex justify-between align-middle`

const VitalAssignmentTitle = tw.h3`text-xl`

const DropdownAnchor = tw.div`relative inline-flex self-center`

const DisplayTitle = tw.p`text-gray-500`

const DisplayValue = tw.span`text-gray-800`

const EllipsisIcon = tw(Icon)`text-gray-900 w-5 h-5`
