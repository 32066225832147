import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { OrgAdminVitalsAuditTrailList } from 'components/settings-vital-signs'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'

const OrgAdminVitalsAuditTrail = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Vitals Audit Trail')}
      description={t(
        'Review all organization-level actions in relation to vital signs.'
      )}
      breadcrumbs={[
        {
          name: t('Organization Settings'),
          route: '/organization-settings',
        },
        {
          name: t('Vitals Settings'),
          route: '/organization-settings/vital-sign-assignments',
        },
        {
          name: t('Vitals Audit Trail'),
        },
      ]}
      isUnauthorized={!assumedOrganizationRole}
    >
      <OrgAdminVitalsAuditTrailList />
    </Layout>
  )
}

export default OrgAdminVitalsAuditTrail
