import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { updateDatabaseArray } from 'test-utils/api/utils'
import { typedOrgsApi } from 'utils'

const useUpdateModuleMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/modules/{moduleId}'],
    updateModuleArgs
  >({
    mutationFn: updateModule,
    successMsg: t('Module updated'),
    additionalCachesToInvalidate: ['getModules'],
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          updateModuleArgs
        >((oldCache, { requests, moduleId }) => {
          const itemToUpdate = oldCache?.items?.find(
            (module) => module.id === moduleId
          )
          return oldCache && itemToUpdate
            ? {
                ...oldCache,
                items: updateDatabaseArray<Module>({
                  array: oldCache.items || [],
                  itemToUpdate,
                  requests,
                  matchBy: 'id',
                }),
              }
            : undefined
        }),
      },
    ],
  })

  return mutation
}

export default useUpdateModuleMutation

type updateModuleArgs = {
  requests: OrgsAPIRequest['PATCH']['/api/modules/{moduleId}']['body']
  moduleId: string
}

const updateModule = ({ requests, moduleId }: updateModuleArgs) =>
  typedOrgsApi.patch('/api/modules/{moduleId}', {
    pathParams: { moduleId },
    body: requests,
  })
