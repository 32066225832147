import { useQuery } from 'react-query'
import { webhooksApi } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

type useWebhookSecretQueryArgs = {
  subscriptionId: string
  enabled: boolean
}

const useWebhookSecretQuery = ({
  subscriptionId,
  enabled,
}: useWebhookSecretQueryArgs) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<GetWebhookSecretResponse, KyError>(
    ['getWebhookSecret', assumedOrganizationRole?.orgID, subscriptionId],
    () => getWebhookSecret(assumedOrganizationRole?.orgID, subscriptionId),
    {
      enabled: !!assumedOrganizationRole?.orgID && !!subscriptionId && enabled,
    }
  )

  return query
}

export default useWebhookSecretQuery

const getWebhookSecret = async (
  organizationId?: string,
  subscriptionId?: string
) => {
  const result = webhooksApi
    .get(
      `api/organizations/${organizationId}/webhook-subscriptions/${subscriptionId}/secrets`
    )
    .json<GetWebhookSecretResponse>()

  return result
}
