import React, { useCallback, useMemo } from 'react'
import { List, CategoryFilter, LoadingIcon } from 'elements'
import { DateRangePicker } from 'atlas'
import { useTable, Column, useSortBy, Row } from 'react-table'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { formatDate, isVitalSignLabel, dateRangePresetToDateRange } from 'utils'
import { useECGVitalSignsQuery, useVitalsReadingsQuery } from 'hooks/vitals'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useURLSyncState } from 'hooks'
import { VitalSignChip } from 'components/vitals'
import tw from 'twin.macro'

const SubscriberVitalsActivityList = () => {
  const { subscriberId } = useParams()
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { t } = useTranslation()

  const [
    vitalReadingFilters,
    setVitalReadingFilters,
  ] = useURLSyncState<VitalReadingFilters>({
    defaultValue: {
      offset: 0,
      dateRangeState: {
        preset: 'this-week',
        value: dateRangePresetToDateRange('this-week'),
      },
      orderBy: 'DESC',
      orderByColId: 'TIMESTAMP',
    },
    isListFilters: true,
  })

  const vitalsReadingsQuery = useVitalsReadingsQuery({
    ...vitalReadingFilters,
    subscriberIds: [subscriberId],
  })

  const vitalSignsQuery = useECGVitalSignsQuery()

  // Sort Function for the Observation column
  const observationSortBy = useCallback(
    (rowA: Row<VitalReading>, rowB: Row<VitalReading>) =>
      +rowA.original.reading > +rowB.original.reading ? -1 : 1,
    []
  )

  // Sort Function for the Timestamp column
  const timestampSortBy = useCallback(
    (rowA: Row<VitalReading>, rowB: Row<VitalReading>) =>
      new Date(rowA.original.recordedAt) > new Date(rowB.original.recordedAt)
        ? 1
        : -1,
    []
  )

  const tableData: VitalReading[] = useMemo(() => {
    const activityData: VitalReading[] = _.flatten(
      vitalsReadingsQuery.data?.items?.map((item) => item.readings)
    )

    // combine systolic and diastolic observations
    const bloodPressureObservations = activityData.filter(
      (observation) =>
        observation.vitalSign.name === 'Systolic Blood Pressure' ||
        observation.vitalSign.name === 'Diastolic Blood Pressure'
    )

    // create array of all unique timestamps to iterate through to build the BP observations
    const timestamps = _.uniqBy(bloodPressureObservations, 'timestamp').map(
      (observation) => observation.recordedAt
    )

    const combinedBPObservations: VitalReading[] = timestamps.map(
      (timestamp) => {
        const sysObservation = bloodPressureObservations.find(
          (observation) =>
            observation.recordedAt === timestamp &&
            observation.vitalSign.name === 'Systolic Blood Pressure'
        )
        const diaObservation = bloodPressureObservations.find(
          (observation) =>
            observation.recordedAt === timestamp &&
            observation.vitalSign.name === 'Diastolic Blood Pressure'
        )

        // build the BP observation object
        const combinedReading: VitalReading = {
          ...((sysObservation || diaObservation) as VitalReading),
          // @ts-expect-error one of them has to exist
          vitalSign: {
            ...(sysObservation?.vitalSign || diaObservation?.vitalSign),
            name: 'Blood Pressure',
          },
          // @ts-expect-error we need to display this as a string
          reading: `${sysObservation?.reading || ''}/${
            diaObservation?.reading || ''
          }`,
          recordedAt:
            sysObservation?.recordedAt ||
            diaObservation?.recordedAt ||
            new Date().toString(),
        }

        return combinedReading
      }
    )

    // return all observations
    return [
      ...activityData.filter(
        (observation) =>
          observation.vitalSign.name !== 'Systolic Blood Pressure' &&
          observation.vitalSign.name !== 'Diastolic Blood Pressure'
      ),
      ...combinedBPObservations,
    ]
  }, [accountPermissions, i18next.language, vitalsReadingsQuery.data])

  const columns: Column<VitalReading>[] = useMemo(
    () => [
      {
        id: 'VITAL SIGN',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('VITAL SIGN'),
        accessor: (observation) =>
          isVitalSignLabel(observation.vitalSign.name) ? (
            <VitalSignChip label={observation.vitalSign.name} />
          ) : observation.vitalSign.name.includes('Blood Pressure') ? (
            <VitalSignChip label={'Blood Pressure'} />
          ) : (
            observation.vitalSign.name
          ),
        Filter: (
          <CategoryFilter<string | string[]>
            icon="vitals"
            label={t('Vital Sign')}
            categories={[
              ...(vitalSignsQuery.data?.items
                // remove diastolic/systolic vital signs
                ?.filter(
                  (vitalSign) => !vitalSign.name.includes('Blood Pressure')
                )
                .map((vitalSign) => ({
                  label: vitalSign.name,
                  value: vitalSign.id,
                })) || []),
              // manually add blood pressure
              ...(vitalSignsQuery.data?.items?.some((vitalSign) =>
                vitalSign.name.includes('Blood Pressure')
              )
                ? [
                    {
                      label: 'Blood Pressure',
                      value: vitalSignsQuery.data.items
                        .filter((vitalSign) =>
                          vitalSign.name.includes('Blood Pressure')
                        )
                        .map((vitalSign) => vitalSign.id),
                    },
                  ]
                : []),
            ]}
            selectedCategories={[
              ...(vitalSignsQuery.data?.items
                ?.filter(
                  (vitalSign) =>
                    vitalReadingFilters.vitalSignIds?.includes(vitalSign.id) &&
                    // remove diastolic/systolic vital signs
                    !vitalSign.name.includes('Blood Pressure')
                )
                .map((vitalSign) => ({
                  label: vitalSign.name,
                  value: vitalSign.id,
                })) || []),
              // manually check if blood-pressure is selected
              ...(vitalReadingFilters.vitalSignIds?.some((vitalSignId) =>
                vitalSignsQuery.data?.items
                  ?.filter((vitalSign) =>
                    vitalSign.name.includes('Blood Pressure')
                  )
                  .map((vitalSign) => vitalSign.id)
                  .includes(vitalSignId)
              )
                ? [
                    {
                      label: 'Blood Pressure',
                      value:
                        vitalSignsQuery.data?.items
                          ?.filter((vitalSign) =>
                            vitalSign.name.includes('Blood Pressure')
                          )
                          .map((vitalSign) => vitalSign.id) || '',
                    },
                  ]
                : []),
            ]}
            setSelectedCategories={(selectedVitalSigns) =>
              setVitalReadingFilters({
                ...vitalReadingFilters,
                vitalSignIds: _.flatten(
                  selectedVitalSigns.map(
                    (selectedVitalSign) => selectedVitalSign.value
                  )
                ),
                offset: 0,
              })
            }
            renderOption={({ label }) =>
              isVitalSignLabel(label) ? (
                <VitalSignChip label={label} className="-my-1" />
              ) : (
                <p>{label}</p>
              )
            }
          />
        ),
        disableSortBy: true,
      },
      {
        id: 'OBSERVATION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('OBSERVATION'),
        accessor: (row, index) => (
          <ObservationContainer key={index}>
            <p>{row.reading}</p>&nbsp;
            <ObservationDefaultCode>
              {row.vitalSign.units.find(
                (unit) => unit.code === row.vitalSign.defaultUnitCode
              )?.displayName || ''}
            </ObservationDefaultCode>
          </ObservationContainer>
        ),
        sortType: observationSortBy,
        disableSortBy: true,
      },
      {
        id: 'TIMESTAMP',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('TIMESTAMP'),
        accessor: (row, index) => (
          <TimestampContainer key={index}>
            {formatDate(new Date(row.recordedAt), 'Pp')}
          </TimestampContainer>
        ),
        Filter: (
          <DateRangePicker
            value={vitalReadingFilters.dateRangeState}
            onChange={(dateRangePreset, dateRange) =>
              setVitalReadingFilters({
                ...vitalReadingFilters,
                dateRangeState: { preset: dateRangePreset, value: dateRange },
                offset: 0,
              })
            }
          />
        ),
        sortMethod: 'string',
        sortType: timestampSortBy,
      },
    ],
    [i18next.language, vitalReadingFilters, vitalSignsQuery.data]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'TIMESTAMP',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  )

  return vitalSignsQuery.data ? (
    <List
      tableInstance={tableInstance}
      setSearchTerm={(searchTerm) =>
        setVitalReadingFilters({
          ...vitalReadingFilters,
          search: searchTerm,
          offset: 0,
        })
      }
      searchTerm={vitalReadingFilters.search}
      searchPlaceholder={`${t('Search')} ${
        vitalsReadingsQuery.data?.totalRecords
          ? vitalsReadingsQuery.data.totalRecords + ' '
          : ''
      }${t('observations')}`}
      emptyDescription={t('Try changing your search term')}
      isLoading={vitalsReadingsQuery.isFetching}
      paginatable
      paginationInfo={vitalsReadingsQuery.data}
      onPageChange={(newPageNumber) => {
        setVitalReadingFilters({
          ...vitalReadingFilters,
          offset: newPageNumber - 1,
        })
      }}
    />
  ) : (
    <LoadingIcon />
  )
}

export default SubscriberVitalsActivityList

const ObservationContainer = tw.div`flex`

const ObservationDefaultCode = tw.p`text-gray-600`

const TimestampContainer = tw.div`flex`
