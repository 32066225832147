import clsx from 'clsx'
import { LoadingIcon } from 'elements'
import { Button } from 'atlas'
import { usePoliciesQuery } from 'hooks/access-control/policies'
import { useCreateAdminMutation } from 'hooks/admins'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { AssignedPoliciesList } from 'components/access-control/policies'
import tw from 'twin.macro'

type CreateAdminFormPermissionsProps = {
  previousStep: () => void
  className?: string
}

const CreateAdminFormPermissions = ({
  previousStep,
  className,
}: CreateAdminFormPermissionsProps) => {
  const { isLoading: isLoadingPolicies } = usePoliciesQuery()
  const [searchValue, setSearchValue] = useState<string>('')
  const { t } = useTranslation()
  const { watch, handleSubmit } = useFormContext<CreateAdminForm>()
  const navigate = useNavigate()
  const {
    mutate: createAdmin,
    isLoading: isCreateAdminLoading,
  } = useCreateAdminMutation()
  const [selectedPolicies, setSelectedPolicies] = useState<Policy[]>([])
  const [savedPolicies, setSavedPolicies] = useState<Policy[]>([])
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  useEffect(() => {
    setSelectedPolicies(
      savedPolicies.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    )
  }, [searchValue])

  if (isLoadingPolicies) return <LoadingIcon className={className} />

  return (
    <div className={clsx('flex flex-col h-full w-full flex-grow', className)}>
      <PoliciesListContainer>
        <Title>{t('Policies')}</Title>
        <Button
          type="primary-filled"
          onClick={() => {
            setSelectedPolicies(savedPolicies)
            return setIsFormOpen(true)
          }}
        >
          {t('Assign Policies')}
        </Button>
      </PoliciesListContainer>
      <AssignedPoliciesList
        userType="admin"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        attachedPolicies={selectedPolicies}
        isAssignPoliciesFormOpen={isFormOpen}
        setIsAssignPoliciesFormOpen={setIsFormOpen}
        onRemovePolicy={(policyToRemove) => {
          setSavedPolicies((policy) =>
            policy.filter((item) => item.id !== policyToRemove.id)
          )
          setSelectedPolicies((policy) =>
            policy.filter((item) => item.id !== policyToRemove.id)
          )
        }}
        onUpdatePolicies={(policyToUpdate) => {
          setSavedPolicies([...policyToUpdate])
          setSelectedPolicies([...policyToUpdate])
        }}
      />

      <div>
        <Button onClick={previousStep}>{t('Back')}</Button>&nbsp;
        <Button
          type="primary-filled"
          disabled={isCreateAdminLoading || selectedPolicies.length === 0}
          onClick={handleSubmit(() => {
            const formData = watch()
            // if any billing address or shipping address fields are truthy add them to the API call
            const isBillingAddress =
              formData.addresses?.[0].addressLine1 ||
              formData.addresses?.[0].city ||
              formData.addresses?.[0].postalCode ||
              formData.addresses?.[0].stateProvinceCode
            const isShippingAddress =
              formData.addresses?.[1].addressLine1 ||
              formData.addresses?.[1].city ||
              formData.addresses?.[1].postalCode ||
              formData.addresses?.[1].stateProvinceCode
            createAdmin(
              {
                ...formData,
                policies: savedPolicies.map((policy) => policy.id),
                phoneNumbers: [
                  ...(formData.phoneNumbers?.[0].number
                    ? // set the phone type to be the id for the business type
                      [{ ...formData.phoneNumbers[0], type: 'Main' as const }]
                    : []),
                ],
                addresses: [
                  ...(isBillingAddress && formData.addresses
                    ? // set the address type to be the id for the billing type
                      [{ ...formData.addresses[0], addressType: 1 }]
                    : []),
                  ...(isShippingAddress && formData.addresses
                    ? // set the address type to be the id for the shipping type
                      [{ ...formData.addresses[1], addressType: 2 }]
                    : []),
                ],
              },
              {
                onSuccess: () => navigate('/admins'),
              }
            )
          })}
        >
          {t('Create Admin')}
        </Button>
        <CancelButton type="primary-link" to="/admins">
          {t('Cancel')}
        </CancelButton>
      </div>
    </div>
  )
}

export default CreateAdminFormPermissions

const CancelButton = tw(Button)`ml-5`

const PoliciesListContainer = tw.div`flex justify-between mb-4`

const Title = tw.h3`text-xl font-semibold`
