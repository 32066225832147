import { LoadingIcon } from 'elements'
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useSearchParam } from 'react-use'
import { useUpdateOrderLineItemsMutation } from 'hooks/orders'
import { useWizardStepForm } from 'context/wizard-form'
import { useContractQuery } from 'hooks/contracts'
import { Empty } from 'atlas'
import { useAPIQuery, useDelay } from 'hooks'
import { ContractInfoCard } from 'components/contracts'
import ProductCard from './ProductCard'
import { FadeInSlideDown } from 'animations'
import { isPresent } from 'utils'
import _ from 'lodash'
import ProductTypeSection from './ProductTypeSection'

const Products = () => {
  const delay = useDelay()
  const { t } = useTranslation()
  const orderId = useSearchParam('orderId') || ''

  const [baseProduct, setBaseProduct] = useState<
    ContractLineItem['primaryProductLineItem'] & {
      selectedWarrantyId: string | null | undefined
      id?: string | null
    }
  >()

  const [productTypes, setProductTypes] = useState<
    (ContractLineItem['productTypeLineItem'] & {
      selectedProducts?: {
        id: string
        label: string
        selectedWarrantyId?: string | null | undefined
      }[]
      id?: string | null
    })[]
  >([])
  const [individualProducts, setIndividualProducts] = useState<
    (ContractLineItem['deviceOrServiceLineItem'] & {
      isSelected: boolean
      selectedWarrantyId: string | null | undefined
      id?: string | null
      optional?: boolean
    })[]
  >([])

  const optionalIndividualProducts = individualProducts.filter(
    (product) => product.optional
  )
  const mandatoryIndividualProducts = individualProducts.filter(
    (product) => !product.optional
  )

  const orderQuery = useAPIQuery('order', {
    pathParams: {
      orderId,
    },
  })

  const contractQuery = useContractQuery({
    contractId: orderQuery.data?.contractId,
    organizationId: orderQuery.data?.organizationId,
  })

  const productsQuery = useAPIQuery('products')

  const updateOrderLineItems = useUpdateOrderLineItemsMutation()

  const { watch, setValue, reset } = useWizardStepForm<
    {
      lineItems: OrderLineItemForm[]
    },
    Order
  >({
    apiData: orderQuery.data,
    apiToForm: (apiData) => ({
      lineItems: _.sortBy(
        apiData.orderLineItems?.map((orderLineItem) => ({
          productId: orderLineItem.productId,
          associatedContractLineItemId: contractQuery.data?.lineItems?.find(
            (lineItem) =>
              // use the productId to find the right contract line item connected with this order line item
              lineItem.primaryProductLineItem?.productId ===
                orderLineItem.productId ||
              lineItem.deviceOrServiceLineItem?.productId ===
                orderLineItem.productId ||
              lineItem.productTypeLineItem?.availableProducts?.includes(
                orderLineItem.productId || ''
              )
          )?.id,
          warrantySelection: orderLineItem.priceSelection?.warrantySelection,
        })),
        'productId'
      ),
    }),
    submitFn: (formData) =>
      updateOrderLineItems.mutateAsync({
        order: orderQuery.data,
        newLineItems: formData.lineItems,
      }),
    formSyncDependencies: [contractQuery.data],
  })

  // initialize available productTypes, individualProducts, and baseProduct when contract & order loads
  useEffect(() => {
    if (contractQuery.data?.lineItems && orderQuery.data?.orderLineItems) {
      const initialBaseProduct = contractQuery.data.lineItems
        .map((lineItem) =>
          lineItem.primaryProductLineItem
            ? {
                ...lineItem.primaryProductLineItem,
                selectedWarrantyId:
                  orderQuery.data.orderLineItems?.find(
                    (orderLineItem) =>
                      orderLineItem.productId ===
                      lineItem.primaryProductLineItem?.productId
                  )?.priceSelection?.warrantySelection?.id || undefined,
                id: lineItem.id,
              }
            : undefined
        )
        .find(isPresent)

      const initialIndividualProducts = contractQuery.data.lineItems
        .map((lineItem) =>
          lineItem.deviceOrServiceLineItem
            ? {
                ...lineItem.deviceOrServiceLineItem,
                optional: !!lineItem.optional,
                id: lineItem.id,
              }
            : undefined
        )
        .filter(isPresent)
        .map((lineItem) => ({
          ...lineItem,
          isSelected:
            !lineItem.optional ||
            // if a matching order line item exists initialize to selected
            !!orderQuery.data.orderLineItems?.find(
              (orderLineItem) => orderLineItem.productId === lineItem.productId
            ),
          selectedWarrantyId:
            orderQuery.data.orderLineItems?.find(
              (orderLineItem) => orderLineItem.productId === lineItem.productId
            )?.priceSelection?.warrantySelection?.id || undefined,
        }))

      const initialProductTypes = contractQuery.data.lineItems
        .map((lineItem) =>
          lineItem.productTypeLineItem
            ? { ...lineItem.productTypeLineItem, id: lineItem.id }
            : undefined
        )
        .filter(isPresent)
        .map((lineItem) => ({
          ...lineItem,
          selectedProducts:
            // initialize selected products by finding all order line items with productIds matching availableProducts of the productType
            orderQuery.data.orderLineItems
              ?.filter((orderLineItem) =>
                lineItem.availableProducts?.includes(
                  orderLineItem.productId || ''
                )
              )
              .map((orderLineItem) => ({
                id: orderLineItem.productId || '',
                label:
                  productsQuery.data?.items?.find(
                    (product) => product.id === orderLineItem.productId
                  )?.title || '',
                selectedWarrantyId:
                  orderLineItem.priceSelection?.warrantySelection?.id,
              })) || [],
        }))

      setBaseProduct(initialBaseProduct)

      setIndividualProducts(initialIndividualProducts)

      setProductTypes(initialProductTypes)

      // initialize form state
      reset({
        lineItems: _.sortBy(
          [
            {
              productId: initialBaseProduct?.productId,
              warrantySelection: {
                id: initialBaseProduct?.selectedWarrantyId || null,
              },
              associatedContractLineItemId: initialBaseProduct?.id,
            },
            ...initialIndividualProducts.map((individualProduct) => ({
              productId: individualProduct.productId,
              warrantySelection: {
                id: individualProduct.selectedWarrantyId || null,
              },
              associatedContractLineItemId: individualProduct.id,
            })),
            ..._.flatten(
              initialProductTypes.map((productType) =>
                productType.selectedProducts.map((selectedProduct) => ({
                  productId: selectedProduct.id,
                  warrantySelection: {
                    id: selectedProduct.selectedWarrantyId || null,
                  },
                  associatedContractLineItemId: productType.id,
                }))
              )
            ),
          ],
          'productId'
        ),
      })
    }
  }, [contractQuery.data, orderQuery.data])

  // baseProduct -> form state sync
  useEffect(() => {
    setValue(
      'lineItems',
      _.sortBy(
        // incorrect typing, 'watch' may return undefined object
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        watch('lineItems')?.map((lineItem) =>
          lineItem.productId === baseProduct?.productId
            ? {
                ...lineItem,
                warrantySelection: {
                  id: baseProduct?.selectedWarrantyId || null,
                },
              }
            : lineItem
        ),
        'productId'
      )
    )
  }, [baseProduct])

  // individualProducts -> form state sync
  useEffect(() => {
    const individualProductIds = individualProducts.map(
      (product) => product.productId
    )
    const existingLineItemProductIds = watch('lineItems').map(
      (lineItem) => lineItem.productId
    )
    const newProducts = individualProducts.filter(
      (product) =>
        product.isSelected &&
        !existingLineItemProductIds.includes(product.productId)
    )

    setValue(
      'lineItems',
      _.sortBy(
        [
          ...watch('lineItems')
            // remove products that have been deselected
            .filter(
              (lineItem) =>
                !individualProductIds.includes(lineItem.productId) ||
                individualProducts.find(
                  (product) => product.productId === lineItem.productId
                )?.isSelected
            )
            .map((lineItem) =>
              individualProductIds.includes(lineItem.productId)
                ? {
                    ...lineItem,
                    // update warranty
                    warrantySelection: {
                      id:
                        individualProducts.find(
                          (product) => product.productId === lineItem.productId
                        )?.selectedWarrantyId || null,
                    },
                  }
                : lineItem
            ),
          // add newly selected products
          ...newProducts.map(
            (product): OrderLineItemForm => ({
              productId: product.productId,
              warrantySelection: { id: product.selectedWarrantyId || null },
              associatedContractLineItemId: product.id,
            })
          ),
        ],
        'productId'
      )
    )
  }, [individualProducts])

  // productTypes -> form state sync
  useEffect(() => {
    const availableProductIds = _.flatten(
      productTypes.map((product) => product.availableProducts)
    ).filter(isPresent)

    const selectedProducts = _.flatten(
      productTypes.map((product) => product.selectedProducts)
    ).filter(isPresent)

    const selectedProductIds = selectedProducts.map(
      (selectedProduct) => selectedProduct.id
    )

    const existingLineItemProductIds = watch('lineItems').map(
      (lineItem) => lineItem.productId
    )

    const newProducts = selectedProducts.filter(
      (product) => !existingLineItemProductIds.includes(product.id)
    )

    setValue(
      'lineItems',
      _.sortBy(
        [
          ...watch('lineItems')
            // remove products that have been deselected
            .filter(
              (lineItem) =>
                !availableProductIds.includes(lineItem.productId || '') ||
                selectedProducts.some(
                  (product) => product.id === lineItem.productId
                )
            )
            .map((lineItem) =>
              selectedProductIds.includes(lineItem.productId || '')
                ? {
                    ...lineItem,
                    // update warranty
                    warrantySelection: {
                      id:
                        productTypes
                          .find((productType) =>
                            productType.availableProducts?.includes(
                              lineItem.productId || ''
                            )
                          )
                          ?.selectedProducts?.find(
                            (selectedProduct) =>
                              selectedProduct.id === lineItem.productId
                          )?.selectedWarrantyId || null,
                    },
                  }
                : lineItem
            ),
          // add newly selected products
          ...newProducts.map(
            (product): OrderLineItemForm => ({
              productId: product.id,
              warrantySelection: { id: product.selectedWarrantyId || null },
              associatedContractLineItemId: productTypes.find((productType) =>
                productType.availableProducts?.includes(product.id)
              )?.id,
            })
          ),
        ],
        'productId'
      )
    )
  }, [productTypes])

  if (!contractQuery.data || !productsQuery.data || !orderQuery.data)
    return <LoadingIcon />

  return (
    <>
      <Header delay={delay()}>
        <TopTitle>{t('Order Contract Information')}</TopTitle>
      </Header>
      <FadeInSlideDown delay={delay()}>
        <ContractInfoCard contract={contractQuery.data} />
      </FadeInSlideDown>
      <Header delay={delay()}>
        <Title>{t('Included Products')}</Title>
      </Header>
      {baseProduct ? (
        <ProductCard
          delay={delay()}
          lineItem={baseProduct}
          setLineItem={setBaseProduct}
        />
      ) : (
        <EmptyContainer delay={delay()}>
          <EmptyDisplay title={t('No Base Product Found')} />
        </EmptyContainer>
      )}
      {mandatoryIndividualProducts.map((lineItem, index) => (
        <ProductCard
          key={index}
          delay={delay()}
          lineItem={lineItem}
          setLineItem={(lineItem) =>
            setIndividualProducts(
              individualProducts.map((product) =>
                product.productId === lineItem.productId
                  ? {
                      ...lineItem,
                      isSelected:
                        typeof lineItem.isSelected === 'boolean'
                          ? lineItem.isSelected
                          : product.isSelected,
                    }
                  : product
              )
            )
          }
        />
      ))}
      {productTypes.length
        ? productTypes.map((productType, index) => (
            <>
              {/* Add spacing between product type cards */}
              {index > 0 ? <Spacer /> : null}
              <ProductTypeSection
                key={index}
                delay={delay()}
                lineItem={productType}
                setLineItem={(lineItem) =>
                  setProductTypes(
                    productTypes.map((productType) =>
                      !_.isEqual(
                        productType.descriptionInfo,
                        lineItem.descriptionInfo
                      )
                        ? productType
                        : lineItem
                    )
                  )
                }
              />
            </>
          ))
        : null}
      {optionalIndividualProducts.length ? (
        <>
          <Header delay={delay()}>
            <Title>{t('Additional Products')}</Title>
          </Header>
          {optionalIndividualProducts.map((individualProduct, index) => (
            <ProductCard
              key={index}
              delay={delay()}
              lineItem={individualProduct}
              setLineItem={(lineItem) =>
                setIndividualProducts(
                  individualProducts.map((product) =>
                    product.productId === individualProduct.productId
                      ? {
                          ...lineItem,
                          isSelected:
                            typeof lineItem.isSelected === 'boolean'
                              ? lineItem.isSelected
                              : product.isSelected,
                        }
                      : product
                  )
                )
              }
              optional
            />
          ))}
        </>
      ) : null}
    </>
  )
}

export default Products

const TopTitle = tw.h3`text-xl font-semibold flex-grow mb-2`

const Header = tw(FadeInSlideDown)`flex justify-between`

const Title = tw.h3`text-xl font-semibold flex-grow mt-6`

const EmptyDisplay = tw(Empty)`border-2 border-dashed rounded`

const EmptyContainer = tw(FadeInSlideDown)`flex-grow mt-1`

const Spacer = tw.div`py-2`
