import React, { useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  CreateStaffFormBasicInformation,
  CreateStaffFormPermissions,
} from 'components/staff'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { Layout, Stepper, Unauthorized } from 'elements'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import clsx from 'clsx'
import { FadeInSlideDown } from 'animations'
import { useURLSyncState } from 'hooks'
import tw from 'twin.macro'

const CreateStaffForm = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { t } = useTranslation()

  // Label Text and Icons for Stepper
  const steps: { label: string; icon: IconType }[] = [
    { label: t('Basic Information'), icon: 'user' },
    { label: t('Permissions'), icon: 'permissions' },
  ]

  const [urlParams, setUrlParams] = useURLSyncState({
    defaultValue: { personId: '' },
    urlObjKey: 'staff',
  })

  const [activeStep, setActiveStep] = useState<number>(0)

  const formMethods = useForm<CreateStaffMemberForm>({
    defaultValues: {
      isProvider: false,
      policies: [],
      memberType: 1,
      personId: 'placeholder',
    },
  })

  // ECG Admin case (ECG Admin that's NOT assuming an organization role)
  if (
    !assumedOrganizationRole &&
    accountPermissions?.data.ecgAdmin.permissions.length !== 0
  )
    return (
      <Layout title="Staff">
        <Unauthorized />
      </Layout>
    )

  // default case
  return (
    <Layout
      title={t('Create Staff')}
      breadcrumbs={[
        {
          name: t('Staff'),
          route: '/staff',
        },
        {
          name: t('Create Staff'),
        },
      ]}
    >
      <CreateStaffFormContainer>
        <FadeInSlideDown>
          <Stepper steps={steps} activeStep={activeStep} className="mb-4" />
        </FadeInSlideDown>
        <FormProvider {...formMethods}>
          <CreateStaffFormBasicInformation
            nextStep={() => setActiveStep(1)}
            className={clsx(activeStep !== 0 && 'hidden')}
            aria-hidden={activeStep !== 0}
            setUrlParams={setUrlParams}
          />
          <CreateStaffFormPermissions
            previousStep={() => setActiveStep(0)}
            className={clsx(activeStep !== 1 && 'hidden')}
            aria-hidden={activeStep !== 1}
            personId={urlParams.personId}
          />
        </FormProvider>
      </CreateStaffFormContainer>
    </Layout>
  )
}

export default CreateStaffForm

const CreateStaffFormContainer = tw.div`flex flex-col flex-grow`
