type DateRangePresetToFormat = { [key in DateRangePreset]: string }

const dateRangePresetToAxisFormat: DateRangePresetToFormat = {
  today: 'PPPP',
  'this-week': 'cccc',
  'last-week': 'cccc',
  'this-month': 'L/d',
  'last-month': 'L/d',
  'this-quarter': 'LLL',
  'last-quarter': 'LLL',
  'this-year': 'LLL',
  'last-year': 'LLL',
  custom: 'L/d',
}

export default dateRangePresetToAxisFormat
