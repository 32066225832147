import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useContractQuery } from 'hooks/contracts'
import { useParams } from 'react-router'
import { useSearchParam } from 'react-use'
import tw from 'twin.macro'

const ProductGroup = () => {
  const { t } = useTranslation()

  const { contractId } = useParams()
  const organizationId = useSearchParam('organizationId')
  const lineItemId = useSearchParam('lineItemId')

  const contractQuery = useContractQuery({
    organizationId: organizationId || '',
    contractId,
  })

  const lineItem = contractQuery.data?.lineItems?.find(
    (lineItem) => lineItem.id === lineItemId
  )

  return (
    <Layout
      title={lineItem ? t('Base Product') : t('Add Base Product')}
      description={
        lineItem
          ? 'Edit the base product for this contract'
          : t('Create a base product for this contract') + '.'
      }
      breadcrumbs={[
        { name: t('Contracts'), route: '../../../' },
        {
          name:
            contractQuery.data?.descriptionInfo?.title || t('Contract Details'),
          route: '../../',
        },
        { name: t('Add Line Item'), route: '../' },
        { name: lineItem ? t('Base Product') : t('Add Base Product') },
      ]}
      isLoading={contractQuery.isLoading}
    >
      <FormContainer>{lineItem ? null : null}</FormContainer>
    </Layout>
  )
}

export default ProductGroup

const FormContainer = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`
