import React from 'react'
import { CodeViewer, LoadingIcon } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type SampleDataCodeViewerProps = {
  data: unknown
  isLoading: boolean
  controls: React.ReactNode
}

const SampleDataCodeViewer = ({
  data,
  isLoading,
  controls,
}: SampleDataCodeViewerProps) => {
  const { t } = useTranslation()

  if (isLoading) return <LoadingIcon />

  if (!data)
    return (
      <>
        <NoSampleData>
          {t('No sample data exists for this event.')}
        </NoSampleData>
        {controls}
      </>
    )

  return (
    <>
      <CodeViewer code={JSON.stringify(data, null, 2)} language="json" />
      {controls}
    </>
  )
}

export default SampleDataCodeViewer

const NoSampleData = tw.h3`text-lg mb-2`
