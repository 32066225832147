import { AutoComplete, LoadingIcon } from 'elements'
import React from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useTimezonesQuery } from 'hooks/seed-data'
import {
  useSubscriberDetailsQuery,
  useUpdateSubscriberProfileMutation,
} from 'hooks/user-subscriber'
import { useWizardStepForm } from 'context/wizard-form'
import { ChildStepContainer } from 'elements/WizardForm'
import { useLocalesQuery } from 'hooks/locales'
import { useSearchParam } from 'react-use'
import { useParams } from 'react-router'

type SiteSettingsForm = Pick<
  PersonalInformation,
  'timeZone' | 'preferredLanguage' | 'locale'
>

// TODO: get this from API somewhere
const languages = [
  {
    code: 'en-US',
    name: 'English (US)',
  },
  {
    code: 'de-DE',
    name: 'German',
  },
]

const SiteSettings = () => {
  const { t } = useTranslation()

  const createSubscriberId = useSearchParam('subscriberId')
  const { subscriberId: editSubscriberId } = useParams()
  const subscriberId = createSubscriberId || editSubscriberId

  const subscriberDetailsQuery = useSubscriberDetailsQuery(subscriberId || '')
  const locales = useLocalesQuery()
  const timezones = useTimezonesQuery()

  const updateSubscriberMutation = useUpdateSubscriberProfileMutation()

  const { errors, control, watch } = useWizardStepForm<
    SiteSettingsForm,
    SubscriberProfile
  >({
    apiData: subscriberDetailsQuery.data,
    apiToForm: (apiData) =>
      apiData.personalInformation || {
        preferredLanguage: '',
        locale: '',
        timeZone: '',
      },
    submitFn: (formData) =>
      updateSubscriberMutation.mutateAsync({
        subscriberForm: {
          personalInformation: {
            ...subscriberDetailsQuery.data?.personalInformation,
            locale: formData.locale,
            preferredLanguage: formData.preferredLanguage,
            timeZone: formData.timeZone,
          },
        },
        subscriberId,
      }),
    dependencies: [subscriberId, subscriberDetailsQuery.data],
  })

  if (
    !locales.data?.items ||
    !timezones.data?.items ||
    (subscriberId && !subscriberDetailsQuery.data)
  )
    return <LoadingIcon />

  // if subscriberId exists show the readonly version
  return (
    <ChildStepContainer>
      <Title>{t('Site Settings')}</Title>
      <RowContainer>
        <Controller
          control={control}
          render={({ onChange }) => (
            <OrganizationAutoComplete
              label={t('Preferred Language *')}
              options={languages}
              single
              onChange={(language) => onChange(language?.code || undefined)}
              optionLabel={(language) => language.name}
              selectedOption={languages.find(
                (language) => language.code === watch('preferredLanguage')
              )}
              error={errors.preferredLanguage?.message}
            />
          )}
          name="preferredLanguage"
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Preferred Language is required') }}
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          render={({ onChange }) => (
            <OrganizationAutoComplete
              label={t('Preferred Locale *')}
              options={locales.data.items || []}
              single
              onChange={(locale) => onChange(locale?.name || undefined)}
              optionLabel={(locale) => locale.displayName}
              selectedOption={locales.data.items?.find(
                (locale) => locale.name === watch('locale')
              )}
              error={errors.locale?.message}
            />
          )}
          name="locale"
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Preferred Locale is required') }}
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          render={({ onChange }) => (
            <OrganizationAutoComplete
              label={t('Timezone *')}
              options={timezones.data.items || []}
              single
              onChange={(timezone) => onChange('' + timezone?.id)}
              optionLabel={(timezone) => timezone.displayName}
              selectedOption={timezones.data.items?.find(
                (timezone) => '' + timezone.id === watch('timeZone')
              )}
              error={errors.timeZone?.message}
            />
          )}
          name="timeZone"
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Timezone is required') }}
        />
      </RowContainer>
    </ChildStepContainer>
  )
}

export default SiteSettings

const RowContainer = tw.div`flex gap-4`

const Title = tw.h3`text-xl font-semibold mb-4`

const OrganizationAutoComplete = tw(AutoComplete)`flex-grow max-w-md`
