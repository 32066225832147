import React from 'react'
import { LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import { FadeInSlideDown } from 'animations'
import { useECGVitalSignsQuery } from 'hooks/vitals'
import _ from 'lodash'
import tw from 'twin.macro'
import ECGAdminVitalSignsCard from './ECGAdminVitalSignsCard'

type ECGAdminVitalSignsGridProps = {
  openEditForm: (vitalSign: VitalSign) => void
}

const ECGAdminVitalSignsGrid = ({
  openEditForm,
}: ECGAdminVitalSignsGridProps) => {
  const ECGVitalSignsQuery = useECGVitalSignsQuery()

  const activeVitalSigns = _.orderBy(
    ECGVitalSignsQuery.data?.items?.filter(
      (vitalSign) => !vitalSign.archiveInfo.archived
    ),
    ['activeInfo.active', 'name'],
    ['desc', 'asc']
  )

  if (ECGVitalSignsQuery.isLoading) return <LoadingIcon />

  // If no vitalSigns are returned show empty grid placeholder
  if (!activeVitalSigns.length)
    return (
      <VitalSignsEmpty
        title="No Vital Sign Assignments Created Yet"
        description='
        Add one or more Vital Sign Assignments by clicking the "Create Vitals
        Sign" button.'
        delay={0.1}
      />
    )

  return (
    <VitalSignsContainer>
      {activeVitalSigns.map((vitalSign, index) => (
        <FadeInSlideDown
          delay={0.1 + index * 0.05}
          key={`vitals-card-${index}`}
        >
          <ECGAdminVitalSignsCard
            vitalSign={vitalSign}
            openEditForm={openEditForm}
          />
        </FadeInSlideDown>
      ))}
    </VitalSignsContainer>
  )
}

export default ECGAdminVitalSignsGrid

const VitalSignsEmpty = tw(Empty)`border-2 border-dashed rounded flex-grow`

const VitalSignsContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
