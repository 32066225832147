import { useTranslation } from 'react-i18next'
import {
  generateArchivable,
  generateAuditable,
  generateDisableable,
} from '../../test-utils/api/utils'
import faker from 'faker'
import { typedOrgsApi, hideEmail, hidePhoneNumber } from 'utils'
import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'

const useCreateResponsiblePartyMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/subscribers-profile/{subscriberId}/responsible-parties'],
    createResponsiblePartyArgs
  >({
    mutationFn: createResponsibleParty,
    successMsg: {
      message: t('Responsible party created'),
      showOn: 'onSuccess',
    },
    additionalCachesToInvalidate: ['getResponsiblePartiesQuery'],
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => [
          'getResponsiblePartiesQuery',
          subscriberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/responsible-parties'],
          createResponsiblePartyArgs
        >((oldData, { responsibleParty }) => {
          if (!oldData || !oldData.items?.[0]?.subscriber) return {}
          const rpItems = oldData.items.map((rp) => rp)
          return {
            ...oldData,
            items: [
              ...rpItems,
              {
                id: faker.random.uuid(),
                archiveInfo: generateArchivable().archiveInfo,
                auditInfo: generateAuditable().auditInfo,
                activeInfo: generateDisableable().activeInfo,
                priority: rpItems.length + 1,
                person: {
                  ...responsibleParty.person,
                  id: faker.random.uuid(),
                  salutation: 1,
                  suffix: responsibleParty.person.suffix || '',
                  // email name is different for getting then when posting
                  emailAddress: hideEmail({
                    email: responsibleParty.person.email as string,
                  }),
                },
                // party is not required when posting

                addresses: [
                  {
                    id: faker.random.uuid(),
                    addressLine1:
                      responsibleParty.addresses[0].addressLine1 || '',
                    addressLine2:
                      responsibleParty.addresses[0].addressLine2 || '',

                    addressType: 1,
                    auditInfo: generateAuditable().auditInfo,
                    city: responsibleParty.addresses[0].city || '',
                    countryCode:
                      responsibleParty.addresses[0].countryCode || '',
                    postalCode: responsibleParty.addresses[0].postalCode || '',
                    stateProvinceCode:
                      responsibleParty.addresses[0].stateProvinceCode || '',
                  },
                ],
                phoneNumbers: [
                  // we only care about one phone number for displaying
                  {
                    id: faker.random.uuid(),
                    extension: responsibleParty.phoneNumbers[0].extension || '',
                    phoneType: 1,
                    phoneNumber: hidePhoneNumber({
                      phoneNumber:
                        (responsibleParty.phoneNumbers[0].number as string) ||
                        '',
                    }),
                  },
                ],
                relationship: responsibleParty.relationship,
                subscriber: oldData.items[0].subscriber,
              },
            ],
          }
        }),
      },
    ],
  })

  return mutation
}
export default useCreateResponsiblePartyMutation

type createResponsiblePartyArgs = {
  subscriberId: string
  responsibleParty: CreateResponsiblePartiesForm
}

const createResponsibleParty = ({
  responsibleParty,
  subscriberId,
}: createResponsiblePartyArgs) =>
  typedOrgsApi.post('/subscribers-profile/{subscriberId}/responsible-parties', {
    pathParams: { subscriberId },
    body: [responsibleParty],
  })
