import React from 'react'
import { Icon } from 'elements'
import { Tooltip } from 'atlas'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import tw from 'twin.macro'
import {
  usePartnerTypeCommissionsQuery,
  usePartnerTypeIndirectCommissionsQuery,
} from 'hooks/partners'
import _ from 'lodash'
import { useProductsQuery } from 'hooks/seed-data'
import { useNavigate } from 'hooks'

type PartnerTypeIndirectCommissionsProps = {
  currentPartnerType: PartnerType
  childPartnerTypes: PartnerType[]
  delay: (diff?: number) => number
}

const PartnerTypeIndirectCommissions = ({
  currentPartnerType,
  childPartnerTypes,
  delay,
}: PartnerTypeIndirectCommissionsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: directCommissions } = usePartnerTypeCommissionsQuery(
    currentPartnerType.id
  )

  const { data: productList } = useProductsQuery()
  const { data: indirectCommissions } = usePartnerTypeIndirectCommissionsQuery(
    currentPartnerType.id
  )

  const childPartnerTypesWithCommissions = childPartnerTypes.map(
    (childPartnerType) => ({
      partnerType: childPartnerType,
      commissions: indirectCommissions?.filter(
        (partnerTypeCommission) =>
          partnerTypeCommission.childPartnerType.id === childPartnerType.id
      ),
    })
  )

  if (
    !directCommissions?.length ||
    !indirectCommissions?.length ||
    !childPartnerTypesWithCommissions.length
  )
    return null

  return (
    <>
      <FadeInSlideDown delay={delay()}>
        <IndirectCommissionsHeader>
          <IndirectCommissionsTitleGroup>
            <IndirectCommissionsTitle>
              {t('Indirect Commissions')}
            </IndirectCommissionsTitle>
            <InfoTooltip>
              <Tooltip
                className="flex items-center"
                content={
                  <p tw="w-60">{`${t(
                    'Commissions earned indirectly from sales made by onboarded partners'
                  )}.`}</p>
                }
              >
                <InfoIcon type="info" />
              </Tooltip>
            </InfoTooltip>
          </IndirectCommissionsTitleGroup>
        </IndirectCommissionsHeader>
      </FadeInSlideDown>
      <IndirectCommissionsGrid>
        {childPartnerTypesWithCommissions.map((childPartnerType, index) =>
          childPartnerType.commissions &&
          childPartnerType.commissions.length ? (
            <FadeInSlideDown delay={delay()} key={index}>
              <IndirectCommissionCard>
                <IndirectCommissionHeader
                  onClick={() =>
                    navigate({
                      pathname: `../${childPartnerType.partnerType.id}`,
                    })
                  }
                >
                  <IndirectCommissionTitle>
                    {childPartnerType.partnerType.name}
                  </IndirectCommissionTitle>
                  <LinkIcon type="link" />
                </IndirectCommissionHeader>
                <div>
                  {_.orderBy(
                    childPartnerType.commissions,
                    (commission) =>
                      productList?.items?.find(
                        (product) => product.id === commission.productId
                      )?.name
                  ).map((indirectCommission, commissionIndex) => {
                    const directCommission = directCommissions.find(
                      (childCommission) =>
                        childCommission.productId ===
                        indirectCommission.productId
                    )
                    return directCommission ? (
                      <CommissionCard key={commissionIndex}>
                        <div>
                          <ProductName>
                            {productList?.items?.find(
                              (product) =>
                                product.id == directCommission.productId
                            )?.name || ''}
                          </ProductName>
                          <CommissionsContainer>
                            {directCommission.oneTimeCommissionAmount ? (
                              <OneTimeContainer>
                                <p>{`${t('One-Time')}:`}</p>
                                &nbsp;
                                <OneTimeCommission>
                                  {`$${
                                    indirectCommission.oneTimeCommissionAmount ||
                                    0
                                  }`}
                                </OneTimeCommission>
                                &nbsp;
                                <DirectCommission>
                                  {`($${
                                    directCommission.oneTimeCommissionAmount
                                  } - $${_.clamp(
                                    directCommission.oneTimeCommissionAmount -
                                      (indirectCommission.oneTimeCommissionAmount ||
                                        0),
                                    0,
                                    Infinity
                                  )})`}
                                </DirectCommission>
                              </OneTimeContainer>
                            ) : null}
                            {directCommission.recurringCommissionAmount ? (
                              <RecurringContainer>
                                <p>{`${t('Recurring')}:`}</p>&nbsp;
                                <OneTimeCommission>
                                  {`$${
                                    indirectCommission.recurringCommissionAmount ||
                                    0
                                  }/mo`}
                                </OneTimeCommission>
                                &nbsp;
                                <DirectCommission>
                                  {`($${
                                    directCommission.recurringCommissionAmount
                                  } - $${_.clamp(
                                    directCommission.recurringCommissionAmount -
                                      (indirectCommission.recurringCommissionAmount ||
                                        0),
                                    0,
                                    Infinity
                                  )})`}
                                </DirectCommission>
                              </RecurringContainer>
                            ) : null}
                          </CommissionsContainer>
                        </div>
                      </CommissionCard>
                    ) : null
                  })}
                </div>
              </IndirectCommissionCard>
            </FadeInSlideDown>
          ) : null
        )}
      </IndirectCommissionsGrid>
    </>
  )
}

export default PartnerTypeIndirectCommissions

const IndirectCommissionsHeader = tw.div`flex justify-between mb-2 mt-6`

const IndirectCommissionsTitleGroup = tw.div`flex`

const IndirectCommissionsTitle = tw.h3`text-xl font-semibold mb-1.5`

const IndirectCommissionsGrid = tw.div`grid grid-cols-1 xl:grid-cols-2 gap-2`

const IndirectCommissionHeader = tw.div`flex items-center cursor-pointer mb-2 w-max`

const IndirectCommissionTitle = tw.h4`text-lg font-medium mr-2`

const CommissionCard = tw.div`py-1`

const IndirectCommissionCard = tw.div`bg-white p-4 rounded border border-gray-200`

const InfoIcon = tw(Icon)`text-gray-600 ml-2 mt-1 w-5 h-5 align-middle`

const InfoTooltip = tw.div`h-fit-content`

const LinkIcon = tw(Icon)`w-3.5 h-3.5`

const ProductName = tw.p`self-start font-bold`

const CommissionsContainer = tw.div`flex`

const OneTimeContainer = tw.div`flex mr-2`

const OneTimeCommission = tw.p`text-center font-medium`

const DirectCommission = tw.p`text-center text-gray-500`

const RecurringContainer = tw.div`flex`
