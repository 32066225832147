import React, { useEffect } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useOrganizationOnboardingQuery } from 'hooks/organizations'
import { Layout } from 'elements'
import { Alert } from 'atlas'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import { NavigationCard } from 'elements'
import { useNavigate, useDelay } from 'hooks'
import _ from 'lodash'
import tw from 'twin.macro'

type Setting = {
  name: string
  icon: IconType
  iconColor: IconColor
  permission: string
  to: string
  description?: string
  requiresOrgRole?: boolean
}

const Settings = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  // const { data: accountPermissions } = useAccountPermissionsQuery()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organizationOnboardingQuery = useOrganizationOnboardingQuery({
    organizationId: assumedOrganizationRole?.orgID,
  })
  const delay = useDelay()

  // redirect the user to the correct settings page if there is a mismatch
  useEffect(() => {
    if (
      assumedOrganizationRole &&
      !window.location.href.includes('organization-settings')
    )
      navigate('/organization-settings')

    if (
      !assumedOrganizationRole &&
      window.location.href.includes('organization-settings')
    )
      navigate('/settings')
  })

  const settingCards: Setting[] = _.orderBy(
    [
      {
        name: t('Vital Sign Assignments'),
        icon: 'vitals',
        iconColor: 'yellow',
        permission: 'vitals.settings.readonly',
        to: 'vital-sign-assignments',
        description: t('Manage vital signs available to your organization.'),
        requiresOrgRole: true,
      },
      {
        name: t('Vitals Thresholds'),
        icon: 'vitals',
        iconColor: 'yellow',
        permission: 'vitals.settings.readonly',
        to: 'vitals-thresholds',
        description: t('Manage organization-level vital sign thresholds.'),
        requiresOrgRole: true,
      },
      {
        name: t('Reminder Messages'),
        icon: 'reminders',
        iconColor: 'purple',
        permission: 'vitals.settings.readonly',
        to: 'reminder-messages',
        description: t(
          'Create and manage organization reminder messages and translations.'
        ),
        requiresOrgRole: true,
      },
      {
        name: t('Reminder Presets'),
        icon: 'reminders',
        iconColor: 'purple',
        permission: 'vitals.settings.readonly',
        to: 'reminder-presets',
        description: t(
          'Create and manage reminder presets available to your organization.'
        ),
        requiresOrgRole: true,
      },
      {
        name: t('Webhooks'),
        icon: 'alert',
        iconColor: 'red',
        permission: 'webhooks.settings.readonly',
        to: 'webhooks',
        description: t('View, add, and edit organization webhooks.'),
        requiresOrgRole: true,
      },
      {
        name: t('Alert Delivery Rules'),
        icon: 'alert',
        iconColor: 'red',
        permission: 'vitals.settings.readonly',
        to: 'alert-delivery-rules',
        description: t(
          'Manage when and who in your organization is contacted when specific alerts are generated.'
        ),
        requiresOrgRole: true,
      },
      {
        name: t('Disclaimer Types'),
        icon: 'disclaimers',
        iconColor: 'cyan',
        permission: 'disclaimers.settings.readonly',
        to: 'disclaimer-types',
        description: t(
          'Create and organize disclaimers and their associated translations within disclaimer types.'
        ),
      },
    ],
    ['icon', 'name']
  )

  return (
    <Layout
      title={
        assumedOrganizationRole ? t('Organization Settings') : t('Settings')
      }
      description={
        assumedOrganizationRole
          ? t('Manage your organization’s settings in one place.')
          : t('Manage settings available to all organizations.')
      }
      isLoading={organizationOnboardingQuery.isLoading}
    >
      {_.find(organizationOnboardingQuery.data, (value) => !value.complete) ? (
        <FadeInSlideDown delay={delay()}>
          <Alert
            message={t('Organization setup incomplete')}
            type="warning"
            onClick={() => {
              navigate('/organization/setup')
            }}
          />
        </FadeInSlideDown>
      ) : null}

      <SettingsContainer>
        {settingCards
          .filter((setting) => {
            // if the user is assuming a role, check for the correct permissions on the assumed role
            if (setting.requiresOrgRole) {
              // TODO: uncomment this out when Org permissions are live
              // if (
              //   assumedOrganizationRole?.staff.permissions.includes(
              //     setting.permission
              //   )
              // )
              if (assumedOrganizationRole) return true

              return false
            }

            // if not, check for the correct permissions in the ECG admin permissions
            // TODO: uncomment this out when ECG admin permissions are live
            // if (
            //   accountPermissions?.data.ecgAdmin.permissions.includes(
            //     setting.permission
            //   )
            // )
            if (!assumedOrganizationRole) return true

            // otherwise they don't have permissions (normal users don't have any settings yet)
            return false
          })
          .map((setting, index) => (
            <FadeInSlideDown
              delay={0.1 + index * 0.05}
              key={`settings-card-${index}`}
            >
              <NavigationCard
                icon={setting.icon}
                iconColor={setting.iconColor}
                label={setting.name}
                onClick={() => navigate(setting.to)}
                description={setting.description}
              />
            </FadeInSlideDown>
          ))}
      </SettingsContainer>
    </Layout>
  )
}

export default Settings

const SettingsContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
