import React from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSubscriberQuery } from 'hooks/organizations'
// import { SubscriberRemidnerSchedulesAuditTrailList } from 'components/subscriber-reminder-schedules'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const SubscriberReminderPresetsAuditTrail = () => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const { subscriberId } = useParams()

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  return (
    <Layout
      title={t('Reminder Schedules Audit Trail')}
      description={t(
        'Review all actions taken in relation to this subscriber’s reminder schedules.'
      )}
      isLoading={isSubLoading}
      isEmpty={!currentSubscriber}
      emptyComponent={
        <Empty
          title={t('Subscriber Not Found')}
          description={t(
            `Either this subscriber doesn't exist or you don't have access to view them`
          )}
        />
      }
      isUnauthorized={!assumedOrganizationRole}
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `../../../`,
        },
        {
          name: t('Subscriber Settings'),
          route: `../../`,
        },
        {
          name: t('Reminder Schedules'),
          route: `../`,
        },
        {
          name: t('Audit Trail'),
        },
      ]}
    >
      {/* <SubscriberRemidnerSchedulesAuditTrailList /> */}
    </Layout>
  )
}

export default SubscriberReminderPresetsAuditTrail
