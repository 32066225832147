import React from 'react'
import tw, { styled } from 'twin.macro'

type CheckboxProps = {
  label?: string | React.ReactNode
  id?: string
  name?: string
  defaultChecked?: boolean
  checked?: boolean
  value?: unknown
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  disabled?: boolean
  testid?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      id,
      name,
      defaultChecked,
      checked,
      value,
      onChange,
      className,
      disabled,
      testid,
    }: CheckboxProps,
    ref
  ) => {
    return (
      <CheckboxLabel className={className} disabled={disabled} htmlFor={id}>
        <CheckboxInput
          id={id}
          ref={ref}
          name={name}
          value={JSON.stringify(value)}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          defaultValue={defaultChecked ? 1 : 0}
          disabled={disabled}
          data-testid={testid}
          onClick={(e) => e.stopPropagation()}
        />
        {label}
      </CheckboxLabel>
    )
  }
)

export default Checkbox

const CheckboxLabel = styled.label<{ disabled?: boolean; className?: string }>(
  ({ disabled, className }) => [
    tw`select-none align-top flex items-center`,
    disabled ? tw`text-gray-500 opacity-80` : tw`cursor-pointer`,
    className,
  ]
)

const CheckboxInput = styled.input<{ disabled?: boolean }>(({ disabled }) => [
  tw`mr-4`,
  !disabled && tw`cursor-pointer`,
])
