import React, { useEffect, useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { DatePicker, Tooltip } from 'atlas'
import { useWizardStepForm } from 'context/wizard-form'
import { addDays, parseISO } from 'date-fns'
import { Checkbox, Icon, LoadingIcon } from 'elements'
import { ChildStepContainer as ServiceDetailsContainer } from 'elements/WizardForm'
import { useAPIQuery, useDelay } from 'hooks'
import {
  useCreateServiceDetailsMutation,
  useUpdateOrderMutation,
} from 'hooks/orders'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParam } from 'react-use'
import tw from 'twin.macro'

const ServiceDetails = () => {
  const delay = useDelay()
  const { t } = useTranslation()
  const orderId = useSearchParam('orderId') || ''

  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false)

  const orderQuery = useAPIQuery('order', {
    pathParams: {
      orderId,
    },
  })

  const createServiceDetails = useCreateServiceDetailsMutation()
  const updateOrder = useUpdateOrderMutation()

  const { control, errors } = useWizardStepForm<
    { expectedServiceStartDate: Date | undefined },
    Required<Order>['serviceDetails']
  >({
    apiData: orderQuery.data?.serviceDetails,
    apiToForm: (apiData) => ({
      expectedServiceStartDate: apiData.expectedServiceStartDate
        ? parseISO(apiData.expectedServiceStartDate)
        : undefined,
    }),
    submitFn: (formData) =>
      orderQuery.data?.serviceDetails
        ? updateOrder.mutateAsync({
            orderId,
            orderForm: {
              serviceDetails: {
                ...orderQuery.data.serviceDetails,
                expectedServiceStartDate: formData.expectedServiceStartDate?.toISOString(),
                serviceStartDisclaimerAccepted: true,
              },
            },
          })
        : createServiceDetails.mutateAsync({
            orderId,
            serviceDetailsForm: {
              expectedServiceStartDate: formData.expectedServiceStartDate?.toISOString(),
              serviceStartDisclaimerAccepted: true,
            },
          }),
    isSubmitDisabled: !isDisclaimerChecked,
  })

  // If service details already exist set checkbox to true
  useEffect(() => {
    if (orderQuery.data?.serviceDetails?.serviceStartDisclaimerAccepted)
      setIsDisclaimerChecked(true)
  }, [orderQuery.data])

  if (!orderQuery.data) return <LoadingIcon />

  return (
    <div>
      <PageTitle delay={delay()}>{t('Service Details')}</PageTitle>

      <ServiceDetailsContainer>
        <Header delay={delay()}>
          <TopTitle>{t('Expected Service Start Date')}</TopTitle>
        </Header>

        <StartDateContainer delay={delay()}>
          <Controller
            control={control}
            as={DatePicker}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="expectedServiceStartDate"
            label={t('Start Date')}
            dense={false}
            rules={{ required: t('Start Date is required').toString() }}
            error={errors.expectedServiceStartDate?.message}
            // business requirement that latest start date is 14 days after making the order
            minDate={new Date()}
            maxDate={addDays(new Date(), 14)}
          />

          <InfoTooltip
            content={
              <TooltipText>{`${t(
                'The Service Start Date helps us in provisioning your devices in a timely manner'
              )}.`}</TooltipText>
            }
          >
            <InfoIcon type="info" />
          </InfoTooltip>
        </StartDateContainer>
        <FadeInSlideDown delay={delay()}>
          <DisclaimerCheckbox
            label={
              t(
                'I understand that billing will start when the Subscriber starts using their device'
              ) + '.'
            }
            checked={isDisclaimerChecked}
            onChange={(e) => setIsDisclaimerChecked(e.target.checked)}
          />
        </FadeInSlideDown>
      </ServiceDetailsContainer>
    </div>
  )
}

export default ServiceDetails

const TopTitle = tw.h3` font-medium flex-grow`

const Header = tw(FadeInSlideDown)`flex justify-between mb-3 items-center pb-1`

const PageTitle = tw(FadeInSlideDown)`text-xl font-semibold mb-2`

const DisclaimerCheckbox = tw(Checkbox)`mt-12`

const StartDateContainer = tw(FadeInSlideDown)`flex`

const InfoTooltip = tw(Tooltip)`ml-4 mt-4 h-fit-content`

const TooltipText = tw.p`w-60`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`
