import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

type useVitalObservationStatisticsQueryArgs = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

type VitalObservationStatistics = Array<{
  category: 'vital-reading' | 'phone-log' | 'reminder-acknowledgement'
  total: number
}>

const useVitalObservationStatisticsQuery = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: useVitalObservationStatisticsQueryArgs) => {
  const query = useQuery<VitalObservationStatistics, KyError>(
    [
      'getVitalObservationStatistics',
      dateRangeState,
      organizationId,
      subscriberId,
    ],
    () =>
      getVitalObservationStatistics(
        dateRangeState,
        organizationId,
        subscriberId
      )
  )

  return query
}

export default useVitalObservationStatisticsQuery

const getVitalObservationStatistics = async (
  dateRangeState: DateRangeState,
  organizationId?: string,
  subscriberId?: string
) => {
  const result: VitalObservationStatistics = await Promise.all(
    ['vital-reading' as const, 'phone-log' as const].map(
      async (observationType) => {
        return {
          category: observationType,
          total: (
            await typedVitalsApi
              .get(`/insights/vital-observations/statistics`, {
                searchParams: {
                  dateRangeType: dateRangeState.preset,
                  organizationId,
                  subscriberId,
                  observationType,
                  ...(dateRangeState.preset === 'custom'
                    ? {
                        from: dateRangeState.value[0].toISOString(),
                        to: dateRangeState.value[1].toISOString(),
                      }
                    : {}),
                },
              })
              .catch(async (e) => {
                const error = await e.response.json()
                if (error.code === 404) {
                  const result: VitalsAPIResponse['GET']['/insights/vital-observations/statistics'] = {
                    fromDate: dateRangeState.value[0].toString(),
                    toDate: dateRangeState.value[1].toString(),
                    data: { observationType: '', total: 0 },
                  }

                  return result
                }
                throw e
              })
          ).data.total,
        }
      }
    )
  )

  // if all categories are zero, return empty array
  if (result.every((result) => result.total === 0)) return []
  return result
}
