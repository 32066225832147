import { createTheme } from '@mui/material'

export const theme = {
  palette: {
    primary: {
      main: '#60A5FA',
    },
    secondary: {
      main: '#d2d6e8',
    },
    success: {
      main: '#009f93',
    },
    error: {
      main: '#d14d4d',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: [
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      '"Segoe UI"',
      '"Roboto"',
      '"Helvetica Neue"',
      '"Arial"',
      'Noto Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(','),
  },
  components: {
    MuiStepConnector: {
      styleOverrides: {
        alternativeLabel: {
          top: '24px',
          left: 'calc(-50% + 30px)',
          right: 'calc(50% + 30px)',
        },
        lineHorizontal: {
          borderTopWidth: undefined,
        },
        line: {
          borderColor: 'inherit',
        },
      },
    },
  },
}

const muiTheme = createTheme(theme)

export default muiTheme
