import React, { useState, useEffect } from 'react'
import {
  RightPopup,
  RadioGroup,
  Select,
  SelectItem,
  LoadingIcon,
  AutoComplete,
} from 'elements'
import { Button, DateRangePicker } from 'atlas'
import { InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  useOrganizationListQuery,
  useSubscribersQuery,
} from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import tw from 'twin.macro'

type ExportAlertsFormProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setExportAlerts: React.Dispatch<
    React.SetStateAction<AlertReportRequest | null | undefined>
  >
  exportLoading: boolean
  dateRange: DateRangeState
  subscriberIds?: Array<string>
}

const ExportAlertsForm = ({
  isOpen,
  setIsOpen,
  setExportAlerts,
  exportLoading,
  dateRange,
  subscriberIds,
}: ExportAlertsFormProps) => {
  const { t } = useTranslation()

  const subscribers = useSubscribersQuery()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  // If user has assumed an Org Role filter out subscribers belonging to other orgs
  const subscriberList = assumedOrganizationRole
    ? subscribers.data?.items?.filter(
        (subscriber) =>
          subscriber.organization?.id === assumedOrganizationRole.orgID
      )
    : subscribers.data?.items

  const [selectedSubs, setSelectedSubs] = useState<
    Array<SubscriberDisplay> | undefined
  >(
    subscriberList?.filter((sub) =>
      subscriberIds?.find((subId) => subId === sub.id)
    )
  )
  const [searchOpen, setSearchOpen] = useState<boolean>(
    !!(subscriberIds && subscriberIds.length > 0)
  )
  const [dateRangeState, setDateRangeState] = useState<DateRangeState>(
    dateRange
  )

  const { data: organizationList } = useOrganizationListQuery({
    orgId: assumedOrganizationRole?.orgID,
    // TODO: remove this once we have a paginated autocomplete here
    pageLength: 99999,
  })

  const formMethods = useForm<AlertReportRequest>()

  const { handleSubmit, setError, reset, errors } = formMethods

  useEffect(() => {
    if (isOpen) {
      setSelectedSubs(
        subscriberList?.filter((sub) =>
          subscriberIds?.find((subId) => subId === sub.id)
        )
      )
      setSearchOpen(!!(subscriberIds && subscriberIds.length > 0))
      setDateRangeState(dateRange)
      reset()
    }
  }, [isOpen])

  return (
    <RightPopup
      title={t('Export Alerts Report')}
      open={isOpen}
      setOpen={setIsOpen}
      controls={
        <>
          <Button
            isLoading={exportLoading}
            onClick={handleSubmit(async (formData) => {
              if (!selectedSubs?.[0]?.id && formData.subscriberId !== 'all') {
                setError('subscriberId', {
                  message: 'Select one subscriber',
                })
                return
              }

              setExportAlerts({
                dateRangeState,
                mode: formData.mode,
                subscriberId:
                  selectedSubs?.map((sub) => sub.id) || formData.subscriberId,
                organizationId: assumedOrganizationRole
                  ? assumedOrganizationRole.orgID
                  : formData.organizationId,
              })
            })}
            type="primary-filled"
          >
            {t('Export')}
          </Button>
          &nbsp;
          <Button
            disabled={exportLoading}
            onClick={() => setIsOpen(false)}
            type="secondary"
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {!subscriberList ? (
        <LoadingIcon />
      ) : (
        <>
          <FormProvider {...formMethods}>
            <form>
              <RadioGroupContainer>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioGroup
                      onChange={(radioValue) => {
                        onChange(radioValue)
                      }}
                      options={[
                        { value: 'pdf', label: t('PDF') },
                        { value: 'csv', label: t('CSV') },
                      ]}
                      checked={value}
                      name="mode"
                      label={t('Export Format')}
                    />
                  )}
                  name="mode"
                  defaultValue="pdf"
                />
              </RadioGroupContainer>

              {!assumedOrganizationRole ? (
                <SelectOrgContainer>
                  <Controller
                    render={({ onChange }) => (
                      <Select
                        defaultValue="all"
                        label={t('Organization')}
                        onChange={onChange}
                      >
                        <SelectItem value="all">{t('All')}</SelectItem>

                        {organizationList?.items.map((org) => {
                          return (
                            <SelectItem key={org.id} value={org.id}>
                              {t(org.businessName)}
                            </SelectItem>
                          )
                        })}
                      </Select>
                    )}
                    name="organizationId"
                    defaultValue="all"
                  />
                </SelectOrgContainer>
              ) : null}

              <SubscriberSelectionContainer>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioGroup
                      onChange={(radioValue) => {
                        if (radioValue === 'select') setSearchOpen(true)
                        if (radioValue === 'all') setSearchOpen(false)
                        return onChange(radioValue)
                      }}
                      options={[
                        { value: 'all', label: t('All Subscribers') },
                        { value: 'select', label: t('Select Subscribers') },
                      ]}
                      checked={value}
                      name="subscriber"
                      label={t('Subscribers')}
                    />
                  )}
                  name="subscriberId"
                  defaultValue={
                    selectedSubs && selectedSubs.length > 0 ? 'select' : 'all'
                  }
                />

                {searchOpen ? (
                  <AutoCompleteContainer>
                    <Controller
                      render={() => (
                        <AutoComplete
                          label={t('Subscribers')}
                          placeholder={t('Search Subscriber')}
                          error={errors.subscriberId?.message}
                          options={subscriberList}
                          selectedOptions={selectedSubs}
                          onChange={setSelectedSubs}
                          optionLabel={(value) => {
                            return (
                              value.person.firstName +
                              ' ' +
                              value.person.lastName
                            )
                          }}
                        />
                      )}
                      name="subscriberId"
                    />
                  </AutoCompleteContainer>
                ) : null}
              </SubscriberSelectionContainer>
            </form>
          </FormProvider>
          <div>
            <InputLabel shrink>{t('Date Range')}</InputLabel>
            <DateRangePicker
              value={dateRangeState}
              onChange={(preset, [startDate, endDate]) =>
                setDateRangeState({ preset, value: [startDate, endDate] })
              }
            />
          </div>
        </>
      )}
    </RightPopup>
  )
}

export default ExportAlertsForm

const SelectOrgContainer = tw.div`mb-4`

const RadioGroupContainer = tw.div`mb-4`

const SubscriberSelectionContainer = tw.div`mb-8`

const AutoCompleteContainer = tw.div`mt-4 -mb-6`
