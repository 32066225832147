import React from 'react'
import { EntityLabel, CustomSelect } from 'elements'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useNavigate } from 'hooks'
import { useStaffListQuery } from 'hooks/user-staff'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const StaffMemberSelect = () => {
  const { staffid } = useParams()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const staffMemberList = useStaffListQuery(assumedOrganizationRole?.orgID)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const currentStaffMember = staffMemberList.data?.items?.find(
    (staffMember) => staffMember.id === staffid
  )

  if (!currentStaffMember) return null

  const staffSelect = staffMemberList.data?.items?.map((staff) => {
    return {
      value: staff.id,
      label: (
        <>
          <EntityLabel
            name={staff.person.firstName + ' ' + staff.person.lastName}
            id={staff.id}
            iconOnly
            size="sm"
          />
          {staff.person.firstName + ' ' + staff.person.lastName}
        </>
      ),
      searchValue: `${staff.person.firstName} ${staff.person.lastName}`,
    }
  })

  return (
    <CustomSelect
      id={currentStaffMember.id}
      options={staffSelect || [{ label: '', value: '' }]}
      className="w-64"
      placeholder={
        currentStaffMember.person.firstName +
        ' ' +
        currentStaffMember.person.lastName
      }
      onChange={(e) => navigate(pathname.replace(staffid, e.value))}
      variant="thin"
      searchable
      emptyText={t('No staff members found')}
    />
  )
}

export default StaffMemberSelect
