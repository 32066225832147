import { useState } from 'react'

type DelayState<T> = [T | undefined, (content: T) => NodeJS.Timeout]

/**
 * Delay the display of an element
 * @param delay - Delay time in milliseconds. Default is 500ms
 * @returns DelayState<T>
 */
const useDelayState = <T>(delay = 500): DelayState<T> => {
  const [contentToDelay, setContentToDelay] = useState<T>()

  const delayContent = (content: T) =>
    setTimeout(() => setContentToDelay(content), delay)

  return [contentToDelay, delayContent]
}

export default useDelayState
