import { startOfDay, startOfMonth, isFuture } from 'date-fns'
import _ from 'lodash'

/**
 * Takes ecg chart data along with other state and converts it into an array of usable Nivo line chart data
 * @param data - An array of chart data from the ECG API
 * @param series - An array of series that pairs with the ecg chart data
 * @param dateRangePreset - The date range preset the pairs with the ecg chart data
 * @returns An array of Nivo line chart data that can be displayed
 */
type ecgChartDataToLineChartData = (args: {
  data: Array<Array<{ [key: string]: unknown; X: string; Y: number }>>
  series: Series[]
  dateRangePreset?: DateRangePreset
}) => Array<{
  id: string
  data: Array<{ [key: string]: unknown; x: Date; y: number }>
}>

const ecgChartDataToLineChartData: ecgChartDataToLineChartData = ({
  data,
  series,
  dateRangePreset,
}) => {
  // remove all the duplicates
  const uniqLineData = data.map((dataSeries) => {
    return {
      ...dataSeries,
      data: _.uniqBy(dataSeries, 'X'),
    }
  })

  // group the values by timestamp
  const nivoLineData = uniqLineData.map((item, index) => {
    return {
      id: series[index].key,
      data: item.data
        // remove futures dates
        .filter((item) => !isFuture(startOfDay(new Date('' + item.X))))
        .map((dataItem) => {
          return {
            ...dataItem,
            X: undefined,
            Y: undefined,
            x: new Date('' + dataItem.X),
            y: _.sumBy(
              data[index].filter((nestedItem) => nestedItem.X === dataItem.X),
              (data) => +data.Y
            ),
          }
        }),
    }
  })

  // round the timestamps if needed
  const roundedLineData = dateRangePreset
    ? nivoLineData.map((lineData) => {
        return {
          ...lineData,
          data: lineData.data.map((item) => {
            if (dateRangePreset === 'this-week')
              return { ...item, x: startOfDay(item.x) }

            if (dateRangePreset === 'last-week')
              return { ...item, x: startOfDay(item.x) }

            if (dateRangePreset === 'this-month')
              return { ...item, x: startOfDay(item.x) }

            if (dateRangePreset === 'last-month')
              return { ...item, x: startOfDay(item.x) }

            if (dateRangePreset === 'this-quarter')
              return { ...item, x: startOfMonth(item.x) }

            if (dateRangePreset === 'last-quarter')
              return { ...item, x: startOfMonth(item.x) }

            if (dateRangePreset === 'this-year')
              return { ...item, x: startOfMonth(item.x) }

            if (dateRangePreset === 'last-year')
              return { ...item, x: startOfMonth(item.x) }

            return item
          }),
        }
      })
    : nivoLineData

  return roundedLineData
}

export default ecgChartDataToLineChartData
