import React from 'react'
import { AutoComplete, TextField, RightPopup } from 'elements'
import { Button, DatePicker } from 'atlas'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAPIQuery } from 'hooks'
import tw from 'twin.macro'
import { addYears } from 'date-fns'

type MedicationFormProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const MedicationForm = ({ isOpen, setIsOpen }: MedicationFormProps) => {
  const { t } = useTranslation()

  const medications = useAPIQuery('medications')
  const routeTypes = useAPIQuery('routeTypes')
  const actionTypes = useAPIQuery('actionTypes')

  const formMethods = useForm()
  const { control, watch, handleSubmit } = formMethods

  return (
    <RightPopup
      title={t('Add Medication')}
      open={isOpen}
      setOpen={setIsOpen}
      controls={
        <>
          <Button
            onClick={handleSubmit((formValues) => {
              console.log('formValues', formValues)
            })}
          >
            {t('Add')}
          </Button>
          &nbsp;
          <Button type="secondary">{t('Cancel')}</Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <Header>{t('Medication Details')}</Header>
        <RowContainer>
          <Controller
            render={({ onChange, value }) => (
              <Autocomplete<MedicationModel>
                label={t('Medication Name *')}
                placeholder={t('Search Medication Name')}
                single
                options={medications.data?.items || []}
                selectedOption={value}
                onChange={onChange}
                optionLabel={(value) =>
                  value.brandNameBase || value.genericName
                }
              />
            )}
            name="medicationMasterId"
          />

          <ViewDetailsButton
            type="primary"
            onClick={() => null}
            disabled={!watch('medicationMasterId')}
          >
            {t('View Details')}
          </ViewDetailsButton>
        </RowContainer>

        <ActionRouteContainer>
          <Controller
            render={({ onChange, value }) => (
              <Autocomplete<ActionTypeListItem>
                label={t('Action *')}
                placeholder={t('Select Action Type')}
                single
                options={actionTypes.data?.items || []}
                selectedOption={value}
                onChange={onChange}
                optionLabel={(value) => value.name || ''}
              />
            )}
            name="action"
          />

          <Controller
            render={({ onChange, value }) => (
              <Autocomplete<RouteTypeListItem>
                label={t('Route *')}
                placeholder={t('Search Route Type')}
                single
                options={routeTypes.data?.items || []}
                selectedOption={value}
                onChange={onChange}
                optionLabel={(value) => value.name || ''}
              />
            )}
            name="route"
          />
        </ActionRouteContainer>

        <RowContainer>
          <Controller
            control={control}
            as={TextField}
            required={true}
            placeholder={t('Enter Purpose of Medication')}
            name="medicationFor"
            label={t('Medication For')}
            className="flex-grow max-w-xl"
          />
        </RowContainer>

        <DateContainer>
          <Controller
            control={control}
            as={DatePicker}
            defaultValue={new Date()}
            name="effectiveFrom"
            label={t('Effective From *')}
            dense={false}
            className="mr-16"
            buttonClassName="w-56"
            maxDate={
              (watch('effectiveTo') as Date | undefined) ||
              addYears(new Date(), 10)
            }
          />
          <Controller
            control={control}
            as={DatePicker}
            label={t('Effective To (optional)')}
            dense={false}
            name="effectiveTo"
            buttonClassName="w-56"
            minDate={(watch('effectiveFrom') as Date | undefined) || new Date()}
            maxDate={addYears(new Date(), 10)}
          />
        </DateContainer>
      </FormProvider>
    </RightPopup>
  )
}

export default MedicationForm

const RowContainer = tw.div`flex mb-4`

const ActionRouteContainer = tw.div`flex flex-grow gap-8`

const DateContainer = tw.div`flex flex-grow`

const Autocomplete = tw(AutoComplete)`max-w-xl flex-grow`

const Header = tw.h2`text-xl font-semibold mb-4`

const ViewDetailsButton = tw(Button)`h-fit-content mt-2 mx-2 `
