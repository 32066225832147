import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useUpdateDisclaimerMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/disclaimers/{disclaimerId}'],
    KyError<ErrorResponse>,
    updateDisclaimerProps,
    VitalsAPIResponse['GET']['/disclaimers'] | undefined
  >(updateDisclaimer, {
    onMutate: ({ disclaimerTypeId, updatedDisclaimer }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/disclaimers']
      >(['getDisclaimers', disclaimerTypeId])

      // optimistic update
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/disclaimers'] | undefined
      >(
        ['getDisclaimers', undefined, 'en-US', disclaimerTypeId],
        (oldDisclaimers) => {
          if (!oldDisclaimers) return undefined
          return {
            totalRecords: oldDisclaimers.totalRecords,
            items:
              oldDisclaimers.items?.map((disclaimer) =>
                disclaimer.id === updatedDisclaimer.id
                  ? {
                      ...updatedDisclaimer,
                      disclaimerType: disclaimer.disclaimerType,
                    }
                  : disclaimer
              ) || [],
          }
        }
      )

      return snapshot
    },
    onSuccess: () => {
      // Notify user of successful disclaimer update
      success({
        message: t('Disclaimer updated'),
      })
    },
    onError: async (error, { disclaimerTypeId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/disclaimers'] | undefined
      >(['getDisclaimers', undefined, undefined, disclaimerTypeId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { disclaimerTypeId }) => {
      // Refetch from API to make sure the updated disclaimer is in sync with the server
      queryCache.invalidateQueries([
        'getDisclaimers',
        undefined,
        undefined,
        disclaimerTypeId,
      ])
    },
  })

  return mutation
}

export default useUpdateDisclaimerMutation

type updateDisclaimerProps = {
  updatedDisclaimer: Disclaimer
  disclaimerTypeId: string
}

// Post updated disclaimer type to backend
const updateDisclaimer = async ({
  updatedDisclaimer,
  disclaimerTypeId,
}: updateDisclaimerProps) => {
  const parentDisclaimerRequest: VitalsAPIRequest['PATCH']['/disclaimers/{disclaimerId}']['body'] = {
    disclaimerText: updatedDisclaimer.disclaimer,
    locale: updatedDisclaimer.locale,
    disclaimerType: disclaimerTypeId,
    translations: updatedDisclaimer.translations || [],
    parentDisclaimerId: updatedDisclaimer.id,
  }

  const result = vitalsApi
    .patch(`disclaimers/${updatedDisclaimer.id}`, {
      json: parentDisclaimerRequest,
    })
    .json<VitalsAPIResponse['PATCH']['/disclaimers/{disclaimerId}']>()

  return result
}
