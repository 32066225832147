import React, { InputHTMLAttributes } from 'react'
import { InputLabel } from '@mui/material'
import tw, { styled } from 'twin.macro'
import { Icon } from 'elements'
import { Tooltip } from 'atlas'

type RadioGroupProps<T> = {
  options?: { value: T; label?: string; description?: string }[]
  defaultChecked?: T
  checked?: T
  onChange?: (newValue: T) => void
  className?: string
  name?: string
  label?: string
  disabled?: boolean
}

const RadioGroup = <T extends InputHTMLAttributes<unknown>['value'] = string>(
  {
    options,
    defaultChecked,
    checked,
    onChange,
    className = '',
    name,
    label,
    disabled,
  }: RadioGroupProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  return (
    <div ref={ref} className={className}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      {options?.map((option, index) => (
        <RadioContainer
          key={index}
          onClick={() => onChange?.(option.value)}
          disabled={disabled}
        >
          <RadioInput
            type="radio"
            id={(name || '') + option.value}
            value={option.value}
            name={'' + option.value}
            checked={option.value === checked}
            defaultChecked={option.value === defaultChecked}
            disabled={disabled}
          />
          <RadioLabel htmlFor={'' + option.value} disabled={disabled}>
            {option.label || option.value}
          </RadioLabel>
          {option.description ? (
            <InfoTooltip
              content={<TooltipText>{option.description}</TooltipText>}
            >
              <InfoIcon type="info" />
            </InfoTooltip>
          ) : null}
        </RadioContainer>
      ))}
    </div>
  )
}

export default React.forwardRef(RadioGroup)

const RadioContainer = styled.div<{ disabled: boolean | undefined }>(
  ({ disabled }) => [
    tw`align-top flex items-center`,
    disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`,
  ]
)

const RadioInput = styled.input<{ disabled: boolean | undefined }>(
  ({ disabled }) => [disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`]
)

const RadioLabel = styled.label<{ disabled: boolean | undefined }>(
  ({ disabled }) => [
    tw`ml-2 select-none`,
    disabled ? tw`cursor-not-allowed text-gray-600` : tw`cursor-pointer`,
  ]
)

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`ml-2 h-fit-content`

const TooltipText = tw.p`w-60`
