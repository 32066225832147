import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { isPresent, typedOrgsApi } from 'utils'

const useUpdateSubscriberMutation = (
  { showSuccessMsg } = { showSuccessMsg: true }
) => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/subscribers/{personId}'][],
    updateSubscriberArgs
  >({
    mutationFn: updateSubscriber,
    successMsg: showSuccessMsg ? t('Subscriber updated') : undefined,
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => ['getSubscriber', subscriberId],
        updateOn: 'onSuccess',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/subscribers/{personId}'],
          updateSubscriberArgs
        >((oldSubscriber, { newMetadata, subscriberForm }) => {
          return (
            oldSubscriber && {
              ...oldSubscriber,
              nonPERS: subscriberForm.nonPERS,
              ...(newMetadata
                ? {
                    integrationMetadata: Object.fromEntries(newMetadata),
                  }
                : {}),
            }
          )
        }),
      },
    ],
    additionalCachesToInvalidate: ['getSubscriberList'],
  })

  return mutation
}

export default useUpdateSubscriberMutation

type updateSubscriberArgs = {
  subscriberId: string
  subscriberForm: Partial<UpdateSubscriberForm>
  oldMetadata?: Record<string, string>
  newMetadata?: Array<[string, string]>
}

const updateSubscriber = async ({
  subscriberId,
  subscriberForm,
  oldMetadata,
  newMetadata,
}: updateSubscriberArgs) => {
  const apiPromises = []
  // if metadata is sent call endpoints to PATCH/POST metadata props
  if (newMetadata) {
    // PATCH metadata call
    const patchbody: OrgsAPIRequest['PATCH']['/api/subscribers/{personId}/metadata']['body'] = []

    // for each existing metadata field that changed add to the requestBody of the PATCH
    oldMetadata &&
      Object.entries(oldMetadata).forEach(([entryKey, originalValue]) => {
        const [, newValue] = newMetadata.find(([key]) => key === entryKey) || [
          entryKey,
          undefined,
        ]

        // DELETE
        if (!newValue) {
          patchbody.push({
            value: 'remove',
            path: `/${entryKey}`,
            op: 'remove',
          })
          return
        }
        // PATCH
        if (newValue && originalValue !== newValue)
          patchbody.push({
            value:
              typeof newValue !== 'string'
                ? JSON.stringify(newValue)
                : newValue,
            path: `/${entryKey}`,
            op: 'replace',
          })
        return
      })

    if (patchbody.length)
      apiPromises.push(
        typedOrgsApi.patch('/api/subscribers/{personId}/metadata', {
          pathParams: { personId: subscriberId },
          body: patchbody,
        })
      )

    // POST
    const existingMetadataKeys = Object.keys(oldMetadata || {})

    // for each metadata field that doesn't exist in the existing integrationMetadata add to the requestBody
    const postbody = newMetadata.filter(
      ([key]) => !existingMetadataKeys.includes(key)
    )

    // metadata API call
    if (postbody.length)
      apiPromises.push(
        typedOrgsApi.post('/api/subscribers/{personId}/metadata', {
          pathParams: { personId: subscriberId },
          body: postbody.map(([key, value]) => ({ key, value })),
        })
      )
  }

  // PATCH traditional org properties call
  const requestBody: OrgsAPIRequest['PATCH']['/api/subscribers/{personId}']['body'] = [
    {
      value: subscriberForm.firstName || '',
      path: '/firstName',
      op: 'replace' as const,
    },
    {
      value: subscriberForm.lastName || '',
      path: '/lastName',
      op: 'replace' as const,
    },
    {
      value: !!subscriberForm.nonPERS,
      path: '/nonPERS',
      op: 'replace' as const,
    },
  ].filter((updateObj) => isPresent(updateObj.value))

  if (requestBody.length)
    apiPromises.push(
      typedOrgsApi.patch('/api/subscribers/{personId}', {
        pathParams: { personId: subscriberId },
        body: requestBody,
      })
    )

  return Promise.all(apiPromises)
}
