type updateDatabaseArray = <T>(args: {
  array: T[]
  requests: Array<UpdateObject>
  itemToUpdate: T
  matchBy: keyof T
}) => T[]

const updateDatabaseArray: updateDatabaseArray = ({
  array,
  requests,
  itemToUpdate,
  matchBy,
}) => {
  const updatedItem = itemToUpdate

  requests.forEach((request) => {
    // TODO: implement the logic for this better
    // @ts-expect-error typescript hates this
    updatedItem[request.path.replace('/', '')] = request.value
  })

  const updatedArray = array.map((item) => {
    // TODO: implement the logic for this better
    if (updatedItem[matchBy] === item[matchBy]) return updatedItem

    return item
  })

  return updatedArray
}

export default updateDatabaseArray
