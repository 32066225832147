import React from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { EntityLabel } from 'elements'
import { Empty } from 'atlas'
import { Pin } from 'animations'
import { usePinnedSubscribersMutation } from 'hooks/organizations'
import { useNavigate } from 'hooks'

type PinnedSubscribersProps = {
  pinnedSubscribers: SubscriberDisplay[]
}

const PinnedSubscribers = ({
  pinnedSubscribers = [],
}: PinnedSubscribersProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const pinnedSubscribersMutation = usePinnedSubscribersMutation()

  return (
    <div>
      <Header>{t('Pinned Subscribers')}</Header>
      {pinnedSubscribers.length ? (
        pinnedSubscribers.map((sub) => (
          <Card key={sub.id} onClick={() => navigate(`subscribers/${sub.id}`)}>
            <EntityLabel
              name={`${sub.person.firstName} ${sub.person.lastName}`}
              id={sub.id}
            />

            <Actions>
              <Pin
                className="flex"
                pinned={true}
                onClick={() => {
                  pinnedSubscribersMutation.mutate({
                    action: 'unpin',
                    subscriberId: sub.id,
                  })
                }}
              />
            </Actions>
          </Card>
        ))
      ) : (
        <EmptyDisplay
          title={t('No Data Found')}
          description={t('Pin a subscriber to see them here')}
        />
      )}
    </div>
  )
}

export default PinnedSubscribers

const Header = tw.h3`text-xl font-semibold mb-4`

const Card = tw.div`flex items-center justify-between p-4 bg-white hover:shadow-sm hover:bg-gray-50 transition-all mb-1 border border-gray-200 rounded-lg cursor-pointer`

const Actions = tw.div`flex items-center`

const EmptyDisplay = tw(Empty)`border-2 border-dashed rounded`
