import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, toUpdateObj, handleQueryError, typedOrgsApi } from 'utils'

const useUpdateStaffPoliciesMutation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}/staff-members/{personId}'],
    KyError,
    updateStaffPoliciesArgs,
    | OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}']
    | undefined
  >(updateStaffPolicies, {
    onMutate: ({ orgId, staffId, policies }) => {
      // snapshot the current value
      const snapshot = queryClient.getQueryData<
        OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}']
      >(['getStaff', orgId, staffId])

      // immediately update the module list
      if (snapshot) {
        queryClient.setQueryData<
          | OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}']
          | undefined
        >(['getStaff', orgId, staffId], (oldCache) => {
          if (!oldCache) return undefined

          const updatedValue: OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}'] = {
            ...oldCache,
            policies: policies.map((policy) => policy.id),
          }

          return updatedValue
        })
      }

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Staff member policies updated') })
    },
    onError: async (error, { orgId, staffId }, snapshot) => {
      // revert to snapshot
      queryClient.setQueryData<
        | OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}']
        | undefined
      >(['getStaff', orgId, staffId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { orgId, staffId }) => {
      // background fetch to sync new data with the server
      queryClient.invalidateQueries(['getStaff', orgId, staffId])
    },
  })

  return mutation
}

export default useUpdateStaffPoliciesMutation

type updateStaffPoliciesArgs = {
  orgId: string
  staffId: string
  policies: Policy[]
}

const updateStaffPolicies = async ({
  orgId,
  staffId,
  policies,
}: updateStaffPoliciesArgs) => {
  const request = [
    toUpdateObj(
      'policies',
      policies.map((policy) => policy.id)
    ),
  ]

  const result = typedOrgsApi.patch(
    '/api/organizations/{organizationId}/staff-members/{personId}',
    {
      pathParams: { organizationId: orgId, personId: staffId },
      body: request,
    }
  )

  return result
}
