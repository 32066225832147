import { useMutation } from 'hooks'
import { ordersApi } from 'utils'
import { useTranslation } from 'react-i18next'

const useCancelOrderMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/cancel'],
    cancelOrderArgs
  >({
    mutationFn: cancelOrder,
    successMsg: { message: t('Order Cancelled'), showOn: 'onSuccess' },
    additionalCachesToInvalidate: [
      (orderId) => ['order', { orderId }],
      (orderId) => ['orderActivity', { orderId }],
    ],
  })
  return mutation
}

export default useCancelOrderMutation

type cancelOrderArgs = string

const cancelOrder = (orderId: cancelOrderArgs) =>
  ordersApi.post('/orders/{orderId}/cancel', {
    pathParams: { orderId },
  })
