import React, { useState } from 'react'
import { Layout } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'
import {
  CreateEditPolicyGroupForm,
  PolicyGroupsList,
} from 'components/access-control/policy-groups'
import { usePolicyGroupsQuery } from 'hooks/access-control/policy-groups'

const AccessControlPolicyGroups = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const {
    data: policyGroups,
    isLoading: isLoadingPolicyGroups,
  } = usePolicyGroupsQuery()

  const [
    isCreateEditPolicyGroupFormOpen,
    setIsCreateEditPolicyGroupFormOpen,
  ] = useState(false)
  const [policyGroup, setPolicyGroup] = useState<PolicyGroup | undefined>()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEditPolicyGroup = (policyGroup: PolicyGroup) => {
    setPolicyGroup(policyGroup)
    setIsCreateEditPolicyGroupFormOpen(true)
  }

  const handlePolicyGroupFormToggle = (isFormOpen: boolean) => {
    // If the form is closing, reset the selected Permission to edit
    if (isFormOpen) setPolicyGroup(undefined)

    // Call the normal setState
    setIsCreateEditPolicyGroupFormOpen(isFormOpen)
  }

  const filteredPolicyGroups =
    policyGroups?.items?.filter(
      (policyGroup) => !policyGroup.archiveInfo.archived
    ) || []

  return (
    <>
      <Layout
        title={t('Policy Groups')}
        description={t('Structure existing policies into policy groups.')}
        breadcrumbs={[
          { name: t('Access Control'), route: '/access-control' },
          { name: t('Policy Groups') },
        ]}
        controls={[
          {
            label: t('Create Policy Groups'),
            onClick: () => handlePolicyGroupFormToggle(true),
          },
          {
            label: t('View Archived Policy Groups'),
            onClick: () => navigate('archive'),
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
          },
        ]}
        isLoading={isLoadingPolicyGroups}
        isUnauthorized={
          !accountPermissions?.data.ecgAdmin.permissions.includes(
            'acm.readonly'
          )
        }
        isEmpty={filteredPolicyGroups.length === 0}
        emptyComponent={
          <PolicyGroupsEmpty
            title={t('No Data Found')}
            description={t('No policy groups have been created')}
            callToAction={
              <Button
                type="primary-filled"
                onClick={() => handlePolicyGroupFormToggle(true)}
              >
                {t('Create Policy Groups')}
              </Button>
            }
          />
        }
      >
        <PolicyGroupsList
          // Only show unarchived policy groups
          data={filteredPolicyGroups}
          onEditPolicyGroup={handleEditPolicyGroup}
        />
      </Layout>
      <CreateEditPolicyGroupForm
        isFormOpen={isCreateEditPolicyGroupFormOpen}
        setIsFormOpen={handlePolicyGroupFormToggle}
        policyGroup={policyGroup}
      />
    </>
  )
}

export default AccessControlPolicyGroups

const PolicyGroupsEmpty = tw(
  Empty
)`flex flex-grow border-2 border-dashed rounded`
