import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useDeleteApplicationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/api/applications/{applicationId}'],
    deleteApplicationArgs
  >({
    mutationFn: deleteApplication,
    successMsg: t('Application deleted'),
    additionalCachesToInvalidate: ['getModules'],
    optimisticUpdates: [
      {
        cacheKey: 'getApplications',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/applications'],
          deleteApplicationArgs
        >((oldCache, { applicationId }) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items?.filter(
                  (application) => application.id !== applicationId
                ),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useDeleteApplicationMutation

type deleteApplicationArgs = {
  applicationId: string
}

const deleteApplication = ({ applicationId }: deleteApplicationArgs) =>
  typedOrgsApi.delete('/api/applications/{applicationId}', {
    pathParams: { applicationId },
  })
