import { vitalsApi, handleQueryError, success } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import faker from 'faker'

const useCreateDisclaimerTypeMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['POST']['/disclaimer-types'],
    KyError<ErrorResponse>,
    CreateDisclaimerTypeProps
  >(createDisclaimerType, {
    onSuccess: (disclaimerType) => {
      // Notify user of successful disclaimer type creation
      success({
        message: t('Disclaimer Type created'),
      })

      // Immediately add disclaimerType to local cache
      queryCache.setQueryData<VitalsAPIResponse['GET']['/disclaimer-types']>(
        'getDisclaimerTypes',
        (oldCache) => {
          return {
            items: [
              ...(oldCache?.items || []),
              {
                ...disclaimerType,
                id: faker.random.uuid(),
              },
            ],
            currentPageNumber: 1,
            totalRecords: 1,
            totalPages: 1,
            recordsPerPage: 25,
          }
        }
      )

      // Refetch from API to make sure the new disclaimerType is in sync with the server
      queryCache.invalidateQueries('getDisclaimerTypes')
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useCreateDisclaimerTypeMutation

type CreateDisclaimerTypeProps = DisclaimerTypeForm

// Post new disclaimerType to backend
const createDisclaimerType = async (newDisclaimerType: DisclaimerTypeForm) => {
  const request: VitalsAPIRequest['POST']['/disclaimer-types']['body'] = newDisclaimerType

  const result = await vitalsApi
    .post(`disclaimer-types`, {
      json: request,
    })
    .json<VitalsAPIResponse['POST']['/disclaimer-types']>()
  return result
}
