import React from 'react'
import { Empty } from 'atlas'
import { SubscriberSettingsGrid } from 'components/subscriber-settings'
import { SubscriberSelect } from 'components/subscribers'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { useSubscribersQuery } from 'hooks/organizations'
import { useSubscriberOnboardingQuery } from 'hooks/user-subscriber'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const SubscriberSettings = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const {
    data: subscribers,
    isLoading: isSubListLoading,
  } = useSubscribersQuery()
  const { subscriberId } = useParams()
  const subscriberOnboardingQuery = useSubscriberOnboardingQuery({
    subscriberId: subscriberId,
  })

  const currentSubscriber = subscribers?.items?.find(
    (subscriber) => subscriber.id === subscriberId
  )

  // default case
  return (
    <Layout
      title={
        <div className="flex gap-2 items-center">
          <h1 className="text-2xl font-bold">{t('Settings')}:</h1>
          <SubscriberSelect />
        </div>
      }
      isLoading={isSubListLoading || subscriberOnboardingQuery.isLoading}
      isUnauthorized={
        currentSubscriber?.organization && !assumedOrganizationRole
      }
      isEmpty={!currentSubscriber}
      emptyComponent={
        <Empty
          title={t('Subscriber Not Found')}
          description={t(
            `Either this subscriber doesn't exist or you don't have access to view them`
          )}
        />
      }
      description={t('Manage subscriber-specific settings.')}
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `/subscribers/${
            subscribers?.items?.find((sub) => sub.id === currentSubscriber?.id)
              ?.id
          }`,
        },
        {
          name: t('Subscriber Settings'),
        },
      ]}
    >
      <SubscriberSettingsGrid />
    </Layout>
  )
}

export default SubscriberSettings
