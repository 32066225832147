import React from 'react'
import { Icon } from 'elements'
import tw, { styled, TwStyle } from 'twin.macro'

type NavigationCardProps = {
  icon: IconType
  iconColor: keyof typeof iconColors
  label: string | React.ReactNode
  onClick?: () => void
  description?: React.ReactNode
  ['data-testid']?: string
  className?: string
  disabled?: boolean
}

const NavigationCard = ({
  icon,
  iconColor,
  label,
  onClick,
  description,
  ['data-testid']: dataTestId,
  className,
  disabled,
}: NavigationCardProps) => {
  return (
    <RelativeContainer className={className}>
      <Card
        onClick={!disabled ? onClick : undefined}
        data-testid={dataTestId}
        disabled={disabled}
      >
        {/* TODO: fix this type */}
        <Highlight color={highlightColors[iconColor] as TwStyle & string} />
        <Content>
          <Title>
            <TitleIcon type={icon} color={iconColors[iconColor]} /> {label}
          </Title>
          {description ? <Description>{description}</Description> : null}
        </Content>
      </Card>
    </RelativeContainer>
  )
}

export default NavigationCard

const RelativeContainer = styled.div<{ className?: string }>`
  ${tw`relative h-full`}
  ${({ className }) => className}
`

const Card = styled.div<{ disabled?: boolean }>(({ disabled }) => [
  tw`rounded flex flex-col border h-full border-gray-200 transition-all cursor-pointer`,
  disabled
    ? tw`bg-gray-100 cursor-not-allowed`
    : tw`bg-white hover:border-gray-300 hover:shadow-lg`,
])

const Highlight = styled.span<{ color: TwStyle }>(({ color }) => [
  tw`w-full h-1 absolute top-0 left-0 rounded-t`,
  color,
])

const Content = tw.div`p-3`

const Title = tw.h4`flex items-center font-semibold text-xl leading-6`

const TitleIcon = styled(Icon)<{ color: TwStyle }>`
  ${tw`w-7 h-7 mr-2 p-1 rounded`}
  ${({ color }) => color}
`

const Description = tw.p`text-gray-600 pt-1 text-sm`

const highlightColors = {
  red: tw`bg-red-400`,
  purple: tw`bg-purple-400`,
  green: tw`bg-green-400`,
  yellow: tw`bg-yellow-400`,
  cyan: tw`bg-cyan-400`,
  orange: tw`bg-orange-400`,
  grey: tw`bg-gray-400`,
  pink: tw`bg-pink-400`,
}

const iconColors = {
  red: tw`bg-red-100 text-red-500`,
  purple: tw`bg-purple-100 text-purple-500`,
  green: tw`bg-green-100 text-green-500`,
  yellow: tw`bg-yellow-100 text-yellow-500`,
  cyan: tw`bg-cyan-100 text-cyan-500`,
  orange: tw`bg-orange-100 text-orange-500`,
  grey: tw`bg-gray-100 text-gray-500`,
  pink: tw`bg-pink-100 text-pink-500`,
}
