import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

type useSpeechQueryProps = {
  message: string
  locale?: LocaleCode
}

const useSpeechQuery = ({ message, locale }: useSpeechQueryProps) => {
  const query = useQuery<string, KyError>(
    ['getSpeech', message, locale],
    () => getSpeech(message, locale),
    { enabled: !!message }
  )

  return query
}

export default useSpeechQuery

const getSpeech = async (message: string, locale?: LocaleCode) => {
  const result = await typedVitalsApi.get('/api/speech', {
    searchParams: {
      message,
      locale,
    },
  })

  // change the buffer into an 8bit blob
  const buff = new Uint8Array(result.data).buffer

  // change the blob into a wav file that howler can read
  const blob = new Blob([buff], { type: 'audio/wav' })
  const audioUrl = URL.createObjectURL(blob)

  return audioUrl
}
