import React, { useState, useMemo } from 'react'
import { useVitalReadingsChartQuery } from 'hooks/vitals'
import { LineChart, Icon } from 'elements'
import {
  dateRangePresetToAxisFormat,
  dateRangePresetToTooltipFormat,
  dateRangeStateToURL,
} from 'utils'
import { isMatch } from 'date-fns'
import colors from 'tailwindcss/colors'
import tw from 'twin.macro'
import { useNavigate } from 'hooks'
import NormalRangeLayer from './NormalRangeLayer'
import _ from 'lodash'

type VitalReadingsLineChartProps = {
  dateRangeState: DateRangeState
  subscriberId: string
  vitalAssignment: VitalAssignment
}

const VitalReadingsLineChart = ({
  dateRangeState,
  subscriberId,
  vitalAssignment,
}: VitalReadingsLineChartProps) => {
  const navigate = useNavigate()
  const { data, isLoading } = useVitalReadingsChartQuery({
    dateRangeState,
    subscriberId,
    vitalSignId: vitalAssignment.id,
  })
  const [barChartSeries] = useState<Series[]>([
    { key: 'Reading', color: colors.blue[400], active: true },
  ])

  // check if the raw API data is grouped by month
  const isGroupedByMonth = isMatch('' + data?.SeriesData[0]?.X, 'yyyy-m')

  // format data for LineChart
  const formattedData = useMemo(() => {
    if (!data?.SeriesData) return []

    return data.SeriesData.filter((item) => item.Y.reading !== 0).map(
      (item) => {
        return {
          normalLow: item.Y.normalLow,
          normalHigh: item.Y.normalHigh,
          X: item.X,
          Y: +item.Y.reading.toFixed(1),
        }
      }
    )
  }, [data])

  const {
    normalLow,
    normalHigh,
    smallestDataPoint,
    largestDataPoint,
  } = useMemo(() => {
    const normalLow = +(
      data?.SeriesData.find((item) => item.Y.normalLow)?.Y.normalLow || 0
    )

    const normalHigh = +(
      data?.SeriesData.find((item) => item.Y.normalHigh)?.Y.normalHigh || 0
    )

    const smallestDataPoint = +(
      _.minBy(formattedData, (reading) => reading.Y)?.Y || 0
    )

    const largestDataPoint = +(
      _.maxBy(formattedData, (reading) => reading.Y)?.Y || 0
    )

    return { normalLow, normalHigh, smallestDataPoint, largestDataPoint }
  }, [formattedData])

  return (
    <Card>
      <Header
        onClick={() =>
          navigate({
            pathname: `/subscribers/${subscriberId}/vitals`,
            searchParams: {
              subscriberIds: [subscriberId],
              vitalSignIds: [vitalAssignment.id],
              ...dateRangeStateToURL(dateRangeState),
            },
          })
        }
      >
        <Title>{`${vitalAssignment.name} Readings Over Time`}</Title>
        <LinkIcon type="link" />
      </Header>
      <LineChart
        // reverse the data and series to be in the correct order
        // @ts-expect-error bad swagger type
        data={[formattedData]}
        series={[...barChartSeries].reverse()}
        XAxis={{
          format: (() => {
            if (dateRangeState.preset === 'custom' && isGroupedByMonth)
              return 'LLL'
            return dateRangePresetToAxisFormat[dateRangeState.preset]
          })(),
        }}
        tooltip={{
          unit: vitalAssignment.units[0].displayName,
          x: {
            format: (() => {
              if (dateRangeState.preset === 'custom' && isGroupedByMonth)
                return 'LLL y'
              return dateRangePresetToTooltipFormat[dateRangeState.preset]
            })(),
          },
          renderMetadata: (e) => {
            return (
              <>
                {/* @ts-expect-error TODO: fix this type */}
                {e.slice.points[0].data.normalHigh ? (
                  <TooltipData>
                    <ArrowUpIcon type="arrow-up" />
                    <>
                      <p>
                        {/* @ts-expect-error TODO: fix this type */}
                        {e.slice.points[0].data.normalHigh}
                      </p>
                    </>
                    &nbsp;
                    <TooltipUnit>Normal High</TooltipUnit>
                  </TooltipData>
                ) : null}
                {/* @ts-expect-error TODO: fix this type */}
                {e.slice.points[0].data.normalLow ? (
                  <TooltipData>
                    <ArrowDownIcon type="arrow-down" />
                    <>
                      {/* @ts-expect-error TODO: fix this type */}
                      <p>{e.slice.points[0].data.normalLow}</p>
                    </>
                    &nbsp;
                    <TooltipUnit>Normal Low</TooltipUnit>
                  </TooltipData>
                ) : null}
              </>
            )
          },
        }}
        dateRangePreset={dateRangeState.preset}
        isLoading={isLoading}
        margin={{ top: 20, right: 20, bottom: 55, left: 30 }}
        legends={[]}
        layers={[
          'grid',
          (props) => <NormalRangeLayer {...props} />,
          'markers',
          'areas',
          'crosshair',
          'lines',
          'slices',
          'axes',
          'points',
          'legends',
        ]}
        yScale={{
          type: 'linear',
          min: Math.min(normalLow, smallestDataPoint) - 5,
          max: Math.max(normalHigh, largestDataPoint) + 5,
        }}
      />
    </Card>
  )
}

export default VitalReadingsLineChart

const Card = tw.div`bg-white p-3 h-72`

const Header = tw.div`flex items-center cursor-pointer`

const Title = tw.h3`text-lg lg:mb-0 mr-1`

const TooltipData = tw.div`flex items-center`

const TooltipUnit = tw.span`text-gray-500`

const LinkIcon = tw(Icon)`w-3.5 h-3.5`

const ArrowUpIcon = tw(Icon)`h-4 w-4 mr-2`

const ArrowDownIcon = tw(Icon)`h-4 w-4 mr-2`
