import { useQuery } from 'react-query'

const useAccountPermissionsQuery = () => {
  const query = useQuery<GetAccountPermissionsReponse, KyError>(
    'getAccountPermissions',
    getAccountPermissions
  )

  return query
}

export default useAccountPermissionsQuery

const getAccountPermissions = async () => {
  const result = {
    data: JSON.parse(localStorage.getItem('accountPermissions') || '{}'),
  } as GetAccountPermissionsReponse

  return result
}
