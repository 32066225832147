import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useOrganizationTypesQuery = (
  { enabled }: { enabled: boolean } | undefined = { enabled: true }
) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/seed-data/organization-types'],
    KyError
  >('getOrganizationTypes', getOrganizationTypes, { enabled })

  return query
}

export default useOrganizationTypesQuery

const getOrganizationTypes = async () =>
  typedOrgsApi.get(`/api/seed-data/organization-types`)
