import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { orgsApi, fromUpdateObjects } from 'utils'

const useUpdatePartnerTypeCommissionsMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    UpdatePartnerTypeCommissionResponse,
    UpdatePartnerTypeCommissionProps
  >({
    mutationFn: updatePartnerTypeCommission,
    successMsg: t('Commission updated'),
    // invalidate all indirect commissions caches b/c no way to only invalidate all parents of partner type
    additionalCachesToInvalidate: ['getPartnerTypeIndirectCommissions'],
    optimisticUpdates: [
      {
        cacheKey: ({ partnerTypeId }) => [
          'getPartnerTypeDirectCommissions',
          partnerTypeId,
        ],
        updateFn: updateFnConstructor<
          GetPartnerTypeCommissionsResponse,
          UpdatePartnerTypeCommissionProps
        >((oldCache, { commission, updateObjects }) =>
          oldCache
            ? [
                ...oldCache.map((cachedCommission) => {
                  if (cachedCommission.id === commission.id)
                    return fromUpdateObjects(cachedCommission, updateObjects)

                  return cachedCommission
                }),
              ]
            : oldCache
        ),
      },
      // optimistic update for indirect commissions of the updated partner type
      {
        cacheKey: ({ partnerTypeId }) => [
          'getPartnerTypeIndirectCommissions',
          partnerTypeId,
        ],
        updateFn: updateFnConstructor<
          GetPartnerTypeIndirectCommissionsResponse,
          UpdatePartnerTypeCommissionProps
        >((oldCache, { commission: oldParentcommission, updateObjects }) =>
          oldCache
            ? [
                ...oldCache.map((indirectCommission) => {
                  if (
                    indirectCommission.productId ===
                    oldParentcommission.productId
                  ) {
                    const newParentRecurring = updateObjects.find(
                      (updateObj) =>
                        updateObj.path === '/recurringCommissionAmount'
                    )?.value
                    const newParentOneTime = updateObjects.find(
                      (updateObj) =>
                        updateObj.path === '/oneTimeCommissionAmount'
                    )?.value

                    // calculate the new indirect commissions
                    return {
                      ...indirectCommission,
                      ...(newParentRecurring !== undefined &&
                      oldParentcommission.recurringCommissionAmount !==
                        undefined &&
                      indirectCommission.recurringCommissionAmount !== undefined
                        ? // equation for new indirect commission:
                          // newIndirect = newParentDirect - oldParentDirect + oldIndirect
                          {
                            recurringCommissionAmount:
                              Number(newParentRecurring) -
                              oldParentcommission.recurringCommissionAmount +
                              indirectCommission.recurringCommissionAmount,
                          }
                        : {}),
                      ...(newParentOneTime !== undefined &&
                      oldParentcommission.oneTimeCommissionAmount !==
                        undefined &&
                      indirectCommission.oneTimeCommissionAmount !== undefined
                        ? {
                            oneTimeCommissionAmount:
                              Number(newParentOneTime) -
                              oldParentcommission.oneTimeCommissionAmount +
                              indirectCommission.oneTimeCommissionAmount,
                          }
                        : {}),
                    }
                  }

                  return indirectCommission
                }),
              ]
            : oldCache
        ),
      },
    ],
  })

  return mutation
}

export default useUpdatePartnerTypeCommissionsMutation

type UpdatePartnerTypeCommissionProps = {
  partnerTypeId: string
  commission: Commission
  updateObjects: UpdateObject[]
}

const updatePartnerTypeCommission = async ({
  partnerTypeId,
  commission,
  updateObjects,
}: UpdatePartnerTypeCommissionProps) => {
  const updatePartnerTypeCommissionResult = await orgsApi
    .patch(
      `api/partner-types/${partnerTypeId}/direct-commissions/${commission.id}`,
      {
        json: updateObjects,
      }
    )
    .json<UpdatePartnerTypeCommissionResponse>()

  return updatePartnerTypeCommissionResult
}
