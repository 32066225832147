import { useMutation } from 'hooks'
import { useTranslation } from 'react-i18next/'
import { typedOrgsApi } from 'utils'

const useAddAneltoCredentialsMutation = () => {
  const { t } = useTranslation()
  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/anelto-credentials'],
    addAneltoCredentialsArgs
  >({
    mutationFn: AddAneltoCredentials,
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getAneltoCredentials', organizationId],
    ],
    successMsg: {
      message: t('Anelto Credentials Updated'),
      showOn: 'onSuccess',
    },
  })

  return mutation
}

export default useAddAneltoCredentialsMutation

type addAneltoCredentialsArgs = {
  organizationId: string
  username: string
  password: string
}
const AddAneltoCredentials = ({
  organizationId,
  username,
  password,
}: addAneltoCredentialsArgs) =>
  typedOrgsApi.post('/api/organizations/{organizationId}/anelto-credentials', {
    pathParams: { organizationId: organizationId },
    body: { aneltoUsername: username, aneltoPassword: password },
  })
