import { Chip } from 'atlas'
import { colors } from 'atlas/Chip/Chip'
import React from 'react'

const VitalSignChip = ({
  label,
  className,
}: {
  label: VitalSignLabel
  className?: string
}) => {
  return (
    <Chip color={vitalSignLabelToColor[label]} className={className}>
      {label}
    </Chip>
  )
}

export default VitalSignChip

const vitalSignLabelToColor: { [K in VitalSignLabel]: keyof typeof colors } = {
  Temperature: 'purple',
  'Heart Rate': 'red',
  'Blood Pressure': 'orange',
  'Spirometer FEV1': 'blue',
  'Spirometer PEF': 'lightblue',
  Weight: 'green',
  Glucose: 'yellow',
  Oxygen: 'cyan',
}
