import { rest } from 'msw'
import { formatISO } from 'date-fns'
import db from '../../data/db'
import faker from 'faker'
import {
  generateAuditable,
  generateDisableable,
  generateArchivable,
} from '../utils'
import _ from 'lodash'
import { reminderMessageTypes } from 'test-utils/data/rawMockData'
import { components as vitalComponents } from 'types/build/vitals'
import { isPresent } from 'utils'

const vitalsModuleHandlers = [
  // get vital sign list
  rest.get<undefined, VitalsAPIResponse['GET']['/vital-signs']>(
    '/vital-signs',
    (req, res, ctx) => {
      const name = req.url.searchParams.get('name')

      return res(
        ctx.json({
          items:
            // if a name is provided filter returned vitalSigns by provided name
            name
              ? db
                  .get<VitalSign[]>('vitalSigns')
                  .filter((vitalSign) => vitalSign.name === name)
              : db.get<VitalSign[]>('vitalSigns'),
        })
      )
    }
  ),

  // get a vital sign
  rest.get<undefined, VitalsAPIResponse['GET']['/vital-signs/{vitalSignId}']>(
    '/vital-signs/:vitalSignId',
    (req, res, ctx) => {
      // if a name is provided filter returned vitalSigns by provided name
      const vitalSign = db
        .get<VitalSign[]>('vitalSigns')
        .find((vitalSign) => vitalSign.id === req.params.vitalSignId)

      if (!vitalSign) throw new Error('Vital Sign not found!')

      return res(ctx.json(vitalSign))
    }
  ),

  // create new vital sign
  rest.post<
    VitalsAPIRequest['POST']['/vital-signs']['body'],
    VitalsAPIResponse['POST']['/vital-signs']
  >('/vital-signs', (req, res, ctx) => {
    const existingVitalSigns = db.get<VitalSign[]>('vitalSigns')

    const now = new Date()

    const newVitalSign: VitalSign = {
      ...req.body,
      id: faker.random.uuid(),
      auditInfo: {
        createdAt: formatISO(now),
        createdBy: '',
        updatedAt: formatISO(now),
        updatedBy: 'Addison',
      },
      media: { audioURL: '', ssmlURL: '' },
      units: req.body.units.map((unit) => ({
        ...unit,
        media: { audioURL: '', ssmlURL: '' },
      })),
      activeInfo: {
        active: true,
        activeStatusChangedAt: '',
      },
      archiveInfo: {
        archived: false,
        archivedStatusChangedAt: '',
      },
    }

    // Update stored vitalSigns in db by adding newVitalSign to existing array of vital signs
    const newVitalSigns = [...existingVitalSigns, newVitalSign]

    db.set<VitalSign[]>('vitalSigns', newVitalSigns)

    // add a create audit event
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvent: VitalsAuditEvent = {
      id: faker.random.uuid(),
      organizationId: '',
      entityName: 'vital-sign',
      action: 'CREATE',
      newValue: newVitalSign,
      modifiedAt: formatISO(now),
      modifiedBy: 'Addison',
      metadata: {},
    }
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      newAuditEvent,
    ])

    return res(ctx.status(201), ctx.json(newVitalSign))
  }),

  // update vital sign
  rest.patch<
    VitalsAPIRequest['PATCH']['/vital-signs/{vitalSignId}']['body'],
    VitalsAPIResponse['PATCH']['/vital-signs/{vitalSignId}']
  >('/vital-signs/:id', (req, res, ctx) => {
    // Find vital sign to be updated
    const vitalSign = db
      .get<VitalSign[]>('vitalSigns')
      .find((vitalSign) => vitalSign.id === req.params.id)

    if (!vitalSign)
      throw new Error(`There was an error updating vital sign ${req.params.id}`)

    const now = new Date()

    const newVitalSign: VitalSign = {
      ...vitalSign,
      ...req.body,
      units: req.body.units.map((unit) => ({
        ...unit,
        media: { audioURL: '', ssmlURL: '' },
      })),
      ...generateAuditable(),
      ...generateDisableable(),
      ...generateArchivable(),
    }

    const existingVitalSigns = db.get<VitalSign[]>('vitalSigns')

    const newVitalSigns = existingVitalSigns.map((vitalSign) =>
      req.params.id === vitalSign.id ? newVitalSign : vitalSign
    )

    db.set<VitalSign[]>('vitalSigns', newVitalSigns)

    // Add an update audit event
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvent: VitalsAuditEvent = {
      id: faker.random.uuid(),
      organizationId: '',
      entityName: 'vital-sign',
      action: 'UPDATE',
      newValue: newVitalSign,
      modifiedAt: formatISO(now),
      modifiedBy: 'Addison',
      metadata: {},
    }
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      newAuditEvent,
    ])

    return res(ctx.json({ result: true }))
  }),

  // archive a vital sign
  rest.post<
    undefined,
    VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/archive']
  >('/vital-signs/:id/archive', (req, res, ctx) => {
    // Find vital sign to be archived
    const vitalSign = db
      .get<VitalSign[]>('vitalSigns')
      .find((vitalSign) => vitalSign.id === req.params.id)

    if (!vitalSign)
      throw new Error(`There was an error updating vital sign ${req.params.id}`)

    const now = new Date()

    const newVitalSign = {
      ...vitalSign,
      archiveInfo: {
        archived: true,
        archivedStatusChangedAt: formatISO(now),
      },
    }

    const existingVitalSigns = db.get<VitalSign[]>('vitalSigns')

    const newVitalSigns = existingVitalSigns.map((vitalSign) =>
      req.params.id === vitalSign.id ? newVitalSign : vitalSign
    )

    db.set<VitalSign[]>('vitalSigns', newVitalSigns)

    // Add an archive audit event
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvent: VitalsAuditEvent = {
      id: faker.random.uuid(),
      organizationId: '',
      entityName: 'vital-sign',
      action: 'UPDATE',
      newValue: newVitalSign,
      modifiedAt: formatISO(now),
      modifiedBy: 'Addison',
      metadata: {},
    }
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      newAuditEvent,
    ])

    return res(ctx.json({ result: true }))
  }),

  // restore a vital sign
  rest.post<
    undefined,
    VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/restore']
  >('/vital-signs/:id/restore', (req, res, ctx) => {
    // Find vital sign to be restored
    const vitalSign = db
      .get<VitalSign[]>('vitalSigns')
      .find((vitalSign) => vitalSign.id === req.params.id)

    if (!vitalSign)
      throw new Error(`There was an error updating vital sign ${req.params.id}`)

    const now = new Date()

    const newVitalSign = {
      ...vitalSign,
      archiveInfo: {
        archived: false,
        archivedStatusChangedAt: formatISO(now),
      },
    }

    const existingVitalSigns = db.get<VitalSign[]>('vitalSigns')

    const newVitalSigns = existingVitalSigns.map((vitalSign) =>
      req.params.id === vitalSign.id ? newVitalSign : vitalSign
    )

    db.set<VitalSign[]>('vitalSigns', newVitalSigns)

    // Add a restore audit event
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvent: VitalsAuditEvent = {
      id: faker.random.uuid(),
      organizationId: '',
      entityName: 'vital-sign',
      action: 'UPDATE',
      newValue: newVitalSign,
      modifiedAt: formatISO(now),
      modifiedBy: 'Addison',
      metadata: {},
    }
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      newAuditEvent,
    ])

    return res(ctx.json({ result: true }))
  }),

  // activate a vital sign
  rest.post<
    undefined,
    VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/activate']
  >('/vital-signs/:id/activate', (req, res, ctx) => {
    // Find vital sign to be activated
    const vitalSign = db
      .get<VitalSign[]>('vitalSigns')
      .find((vitalSign) => vitalSign.id === req.params.id)

    if (!vitalSign)
      throw new Error(`There was an error updating vital sign ${req.params.id}`)

    const now = new Date()

    const newVitalSign = {
      ...vitalSign,
      activeInfo: {
        active: true,
        activeStatusChangedAt: formatISO(now),
      },
    }

    const existingVitalSigns = db.get<VitalSign[]>('vitalSigns')

    const newVitalSigns = existingVitalSigns.map((vitalSign) =>
      req.params.id === vitalSign.id ? newVitalSign : vitalSign
    )

    db.set<VitalSign[]>('vitalSigns', newVitalSigns)

    // Add an activate audit event
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvent: VitalsAuditEvent = {
      id: faker.random.uuid(),
      organizationId: '',
      entityName: 'vital-sign',
      action: 'UPDATE',
      newValue: newVitalSign,
      modifiedAt: formatISO(now),
      modifiedBy: 'Addison',
      metadata: {},
    }
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      newAuditEvent,
    ])

    return res(ctx.json({ result: true }))
  }),

  // deactivate a vital sign
  rest.post<
    undefined,
    VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/deactivate']
  >('/vital-signs/:id/deactivate', (req, res, ctx) => {
    // Find vital sign to be deactivated
    const vitalSign = db
      .get<VitalSign[]>('vitalSigns')
      .find((vitalSign) => vitalSign.id === req.params.id)

    if (!vitalSign)
      throw new Error(`There was an error updating vital sign ${req.params.id}`)

    const now = new Date()

    const newVitalSign = {
      ...vitalSign,
      activeInfo: {
        active: false,
        activeStatusChangedAt: formatISO(now),
      },
    }

    const existingVitalSigns = db.get<VitalSign[]>('vitalSigns')

    const newVitalSigns = existingVitalSigns.map((vitalSign) =>
      req.params.id === vitalSign.id ? newVitalSign : vitalSign
    )

    db.set<VitalSign[]>('vitalSigns', newVitalSigns)

    // Add a deactivate audit event
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvent: VitalsAuditEvent = {
      id: faker.random.uuid(),
      organizationId: '',
      entityName: 'vital-sign',
      action: 'UPDATE',
      newValue: newVitalSign,
      modifiedAt: formatISO(now),
      modifiedBy: 'Addison',
      metadata: {},
    }
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      newAuditEvent,
    ])

    return res(ctx.json({ result: true }))
  }),

  // delete vital sign
  rest.delete<
    undefined,
    VitalsAPIResponse['DELETE']['/vital-signs/{vitalSignId}']
  >('/vital-signs/:id', (req, res, ctx) => {
    // Find vital sign to be deleted
    const vitalSign = db
      .get<VitalSign[]>('vitalSigns')
      .find((vitalSign) => vitalSign.id === req.params.id)

    if (!vitalSign)
      throw new Error(`There was an error updating vital sign ${req.params.id}`)

    const existingVitalSigns = db.get<VitalSign[]>('vitalSigns')

    // filter out vitalSign that has same id as the one specified in the url
    const newVitalSigns = existingVitalSigns.filter(
      (vitalSign) => vitalSign.id !== req.params.id
    )

    db.set<VitalSign[]>('vitalSigns', newVitalSigns)

    const now = new Date()

    // Add a delete audit event
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvent: VitalsAuditEvent = {
      id: faker.random.uuid(),
      organizationId: '',
      entityName: 'vital-sign',
      action: 'DELETE',
      newValue: {},
      modifiedAt: formatISO(now),
      modifiedBy: 'Addison',
      metadata: {},
    }
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      newAuditEvent,
    ])

    return res(ctx.json({ result: true }))
  }),

  // VITAL SIGN API AT AN ORGANIZATION LEVEL

  // get vital sign list for an organization
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
  >('/organizations/:orgId/vital-sign-assignments', (req, res, ctx) =>
    res(
      ctx.json(
        // get array of vital sign assignments that this org has access to
        db
          .get<OrgVitalAssignments[]>('orgVitalAssignments')
          .find(
            (vitalSignSubAssignment) =>
              vitalSignSubAssignment.organizationId === req.params.orgId
          ) || {
          id: '',
          organizationId: req.params.orgId,
          assignedVitalSigns: [],
        }
      )
    )
  ),

  // assign array of vital signs to an organization
  rest.post<
    VitalsAPIRequest['POST']['/organizations/{organizationId}/vital-signs']['body'],
    VitalsAPIResponse['POST']['/organizations/{organizationId}/vital-signs']
  >('/organizations/:orgId/vital-signs', (req, res, ctx) => {
    // replace oldSubAssignments with newSubAssignments in DB
    db.set<OrgVitalAssignments[]>('orgVitalAssignments', [
      ...db
        .get<OrgVitalAssignments[]>('orgVitalAssignments')
        .filter(
          (vitalAssignments) =>
            vitalAssignments.organizationId === req.params.orgId
        ),
      {
        id: faker.random.uuid(),
        organizationId: req.params.orgId,
        assignedVitalSigns: req.body.vitalSigns,
      },
    ])

    const now = new Date()

    // Add create audit events
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvents: VitalsAuditEvent[] = req.body.vitalSigns.map(
      (assignment) => ({
        id: faker.random.uuid(),
        organizationId: req.params.orgId,
        entityName: 'vital-sign-assignment',
        action: 'CREATE',
        newValue: assignment,
        modifiedAt: formatISO(now),
        modifiedBy: 'Addison',
        metadata: {},
      })
    )
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      ...newAuditEvents,
    ])

    return res(ctx.status(201), ctx.json({ result: true }))
  }),

  // update an org's vital sign assignment
  rest.patch<
    VitalsAPIRequest['PATCH']['/organizations/{organizationId}/vital-sign-assignments/{vitalSignAssignmentId}/update']['body'],
    VitalsAPIResponse['PATCH']['/organizations/{organizationId}/vital-sign-assignments/{vitalSignAssignmentId}/update']
  >(
    '/organizations/:orgId/vital-sign-assignments/:assignmentId/update',
    (req, res, ctx) => {
      // Get all vital sign assignments for this org
      const allAssignments = db.get<OrgVitalAssignments[]>(
        'orgVitalAssignments'
      )

      // remove the vitalAssignments record for this org
      const [orgAssignments] = _.remove(
        allAssignments,
        (vitalAssignments) =>
          vitalAssignments.organizationId === req.params.orgId
      )

      if (!isPresent(orgAssignments))
        throw new Error('vital assignment not found')

      // create a newAssignments array without the current orgAssignment
      const newAssignments = {
        ...orgAssignments,
        assignedVitalSigns: orgAssignments.assignedVitalSigns.map(
          (orgAssignment) => {
            if (orgAssignment.id === req.body.vitalSign.id) {
              return {
                ...orgAssignment,
                code: req.body.vitalSign.code,
                defaultUnitCode: req.body.vitalSign.code,
              }
            }
            return orgAssignment
          }
        ),
      }

      db.set<OrgVitalAssignments[]>('orgVitalAssignments', [
        ...allAssignments,
        newAssignments,
      ])

      const now = new Date()

      // Add an archive audit event
      const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
      const newAuditEvent: VitalsAuditEvent = {
        id: faker.random.uuid(),
        organizationId: req.params.orgId,
        entityName: 'vital-sign-assignment',
        action: 'UPDATE',
        newValue: newAssignments,
        modifiedAt: formatISO(now),
        modifiedBy: 'Addison',
        metadata: {},
      }
      db.set<VitalsAuditEvent[]>('vitalSignAudit', [
        ...existingAuditTrail,
        newAuditEvent,
      ])

      return res(ctx.json({ result: true }))
    }
  ),

  // VITAL SIGN API AT A SUBSCRIBER LEVEL

  // get vital sign list for a subscriber
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
  >('/subscribers/:subscriberId/vital-sign-assignments', (req, res, ctx) =>
    res(
      ctx.json(
        // get array of vital sign assignments that exists for this subscriber
        db
          .get<SubscriberVitalAssignments[]>('subVitalAssignments')
          .find(
            (vitalSignSubAssignment) =>
              vitalSignSubAssignment.subscriberId === req.params.subscriberId
          ) || {
          id: '',
          subscriberId: req.params.subscriberId,
          assignedVitalSigns: [],
        }
      )
    )
  ),

  // assign array of vital signs to a subscriber
  rest.post<
    VitalsAPIRequest['POST']['/subscribers/{subscriberId}/vital-signs']['body'],
    VitalsAPIResponse['POST']['/subscribers/{subscriberId}/vital-signs']
  >('/subscribers/:subscriberId/vital-signs', (req, res, ctx) => {
    // replace oldSubAssignments with newSubAssignments in DB
    db.set<SubscriberVitalAssignments[]>('subVitalAssignments', [
      ...db
        .get<SubscriberVitalAssignments[]>('subVitalAssignments')
        .filter(
          (vitalAssignments) =>
            vitalAssignments.subscriberId === req.params.subscriberId
        ),
      {
        id: faker.random.uuid(),
        subscriberId: req.params.subscriberId,
        assignedVitalSigns: req.body.vitalSigns,
      },
    ])

    const now = new Date()

    // Add create audit events
    const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
    const newAuditEvents: VitalsAuditEvent[] = req.body.vitalSigns.map(
      (assignment) => ({
        id: faker.random.uuid(),
        organizationId: 'electronicCaregiverId',
        subscriberId: req.params.subscriberId,
        entityName: 'vital-sign-assignment',
        action: 'CREATE',
        newValue: assignment,
        modifiedAt: formatISO(now),
        modifiedBy: 'Addison',
        metadata: {},
      })
    )
    db.set<VitalsAuditEvent[]>('vitalSignAudit', [
      ...existingAuditTrail,
      ...newAuditEvents,
    ])

    return res(ctx.status(201), ctx.json({ result: true }))
  }),

  // assign array of vital signs to a subscriber with existing vital assignments
  rest.patch<
    VitalsAPIRequest['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}']['body'],
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}']
  >(
    '/subscribers/:subscriberId/vital-sign-assignments/:assignmentId',
    (req, res, ctx) => {
      // get the ids of all vital signs getting new vital assignments
      const vitalSignIds = req.body.vitalSigns.map((vitalSign) => vitalSign.id)

      // get the vital signs that correspond to the vital sign assignments
      const vitalSigns = db
        .get<VitalSign[]>('vitalSigns')
        .filter((vitalSign) => vitalSignIds.includes(vitalSign.id))

      // convert incoming VitalAssignmentForms to VitalAssignments
      const newVitalAssignments: VitalAssignment[] = req.body.vitalSigns.map(
        (assignment) => {
          const vitalSign = vitalSigns.find(
            (vitalSign) => vitalSign.id === assignment.id
          )

          if (!vitalSign) throw new Error('Vital Sign not found!')

          // return OrgVitalAssignment getting its active and archived values from the Vital Sign
          return {
            ...assignment,
            name: vitalSign.name,
            displayName: vitalSign.displayName,
            active: true,
            archived: false,
            units: vitalSign.units,
            defaultUnitCode: vitalSign.defaultUnitCode,
          }
        }
      )

      // get all Vital Sign Assignments in the db
      const allAssignments = db.get<SubscriberVitalAssignments[]>(
        'subVitalAssignments'
      )

      // remove this subscriber's vitalAssignments from all
      const [subAssignments] = _.remove(
        allAssignments,
        (vitalAssignments) =>
          vitalAssignments.subscriberId === req.params.subscriberId
      )

      const newSubAssignments = {
        ...subAssignments,
        assignedVitalSigns: [
          ...subAssignments.assignedVitalSigns,
          ...newVitalAssignments,
        ],
      }

      // replace oldSubAssignments with newSubAssignments in DB
      db.set<SubscriberVitalAssignments[]>('subVitalAssignments', [
        ...allAssignments,
        newSubAssignments,
      ])

      const now = new Date()

      // Add create audit events
      const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
      const newAuditEvents: VitalsAuditEvent[] = newVitalAssignments.map(
        (assignment) => ({
          id: faker.random.uuid(),
          organizationId: 'electronicCaregiverId',
          subscriberId: req.params.subscriberId,
          entityName: 'vital-sign-assignment',
          action: 'CREATE',
          newValue: assignment,
          modifiedAt: formatISO(now),
          modifiedBy: 'Addison',
          metadata: {},
        })
      )
      db.set<VitalsAuditEvent[]>('vitalSignAudit', [
        ...existingAuditTrail,
        ...newAuditEvents,
      ])

      return res(ctx.status(201), ctx.json({ result: true }))
    }
  ),

  // archive an sub's vital sign assignment
  rest.patch<
    undefined,
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}/vital-signs/{vitalSignId}/archive']
  >(
    '/subscribers/:subscriberId/vital-sign-assignments/:assignmentId/vital-signs/:vitalSignId/archive',
    (req, res, ctx) => {
      // Get all vital sign assignments for this sub
      const allAssignments = db.get<SubscriberVitalAssignments[]>(
        'subVitalAssignments'
      )

      // remove the vitalAssignments record for this sub
      const [subAssignments] = _.remove(
        allAssignments,
        (vitalAssignments) =>
          vitalAssignments.subscriberId === req.params.subscriberId
      )

      if (!isPresent(subAssignments))
        throw new Error('vital assignment not found')

      // create a newAssignments array without the current subAssignment
      const newAssignments: SubscriberVitalAssignments = {
        ...subAssignments,
        assignedVitalSigns: subAssignments.assignedVitalSigns.map(
          (subAssignment) =>
            subAssignment.id === req.params.vitalSignId
              ? {
                  ...subAssignment,
                  archived: true,
                }
              : subAssignment
        ),
      }

      db.set<SubscriberVitalAssignments[]>('subVitalAssignments', [
        ...allAssignments,
        newAssignments,
      ])

      const now = new Date()

      // Add an archive audit event
      const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
      const newAuditEvent: VitalsAuditEvent = {
        id: faker.random.uuid(),
        organizationId: 'electronicCaregiverId',
        // subscriberId: req.params.subscriberId,
        entityName: 'vital-sign-assignment',
        action: 'UPDATE',
        newValue: newAssignments,
        modifiedAt: formatISO(now),
        modifiedBy: 'Addison',
        metadata: {},
      }
      db.set<VitalsAuditEvent[]>('vitalSignAudit', [
        ...existingAuditTrail,
        newAuditEvent,
      ])

      return res(ctx.json({ result: true }))
    }
  ),

  // restore an sub's vital sign assignment
  rest.patch<
    undefined,
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}/vital-signs/{vitalSignId}/restore']
  >(
    '/subscribers/:subscriberId/vital-sign-assignments/:assignmentId/vital-signs/:vitalSignId/restore',
    (req, res, ctx) => {
      // Get all vital sign assignments for this sub
      const allAssignments = db.get<SubscriberVitalAssignments[]>(
        'subVitalAssignments'
      )

      // remove the vitalAssignments record for this sub
      const [subAssignments] = _.remove(
        allAssignments,
        (vitalAssignments) =>
          vitalAssignments.subscriberId === req.params.subscriberId
      )

      if (!isPresent(subAssignments))
        throw new Error('vital assignment not found')

      // create a newAssignments array without the current subAssignment
      const newAssignments = {
        ...subAssignments,
        assignedVitalSigns: subAssignments.assignedVitalSigns.map(
          (subAssignment) =>
            subAssignment.id === req.params.vitalSignId
              ? {
                  ...subAssignment,
                  archived: false,
                }
              : subAssignment
        ),
      }

      db.set<SubscriberVitalAssignments[]>('subVitalAssignments', [
        ...allAssignments,
        newAssignments,
      ])

      const now = new Date()

      // Add a restore audit event
      const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
      const newAuditEvent: VitalsAuditEvent = {
        id: faker.random.uuid(),
        organizationId: 'electronicCaregiverId',
        // subscriberId: req.params.subscriberId,
        entityName: 'vital-sign-assignment',
        action: 'UPDATE',
        newValue: newAssignments,
        modifiedAt: formatISO(now),
        modifiedBy: 'Addison',
        metadata: {},
      }
      db.set<VitalsAuditEvent[]>('vitalSignAudit', [
        ...existingAuditTrail,
        newAuditEvent,
      ])

      return res(ctx.json({ result: true }))
    }
  ),

  // activate an sub's vital sign assignment
  rest.patch<
    undefined,
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}/vital-signs/{vitalSignId}/activate']
  >(
    '/subscribers/:subscriberId/vital-sign-assignments/:assignmentId/vital-signs/:vitalSignId/activate',
    (req, res, ctx) => {
      // Get all vital sign assignments for this sub
      const allAssignments = db.get<SubscriberVitalAssignments[]>(
        'subVitalAssignments'
      )

      // remove the vitalAssignments record for this sub
      const [subAssignments] = _.remove(
        allAssignments,
        (vitalAssignments) =>
          vitalAssignments.subscriberId === req.params.subscriberId
      )

      if (!isPresent(subAssignments))
        throw new Error('vital assignment not found')

      // create a newAssignments array without the current subAssignment
      const newAssignments = {
        ...subAssignments,
        assignedVitalSigns: subAssignments.assignedVitalSigns.map(
          (subAssignment) =>
            subAssignment.id === req.params.vitalSignId
              ? {
                  ...subAssignment,
                  active: true,
                }
              : subAssignment
        ),
      }

      db.set<SubscriberVitalAssignments[]>('subVitalAssignments', [
        ...allAssignments,
        newAssignments,
      ])

      const now = new Date()

      // Add a restore audit event
      const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
      const newAuditEvent: VitalsAuditEvent = {
        id: faker.random.uuid(),
        organizationId: 'electronicCaregiverId',
        // subscriberId: req.params.subscriberId,
        entityName: 'vital-sign-assignment',
        action: 'UPDATE',
        newValue: newAssignments,
        modifiedAt: formatISO(now),
        modifiedBy: 'Addison',
        metadata: {},
      }
      db.set<VitalsAuditEvent[]>('vitalSignAudit', [
        ...existingAuditTrail,
        newAuditEvent,
      ])

      return res(ctx.json({ result: true }))
    }
  ),

  // deactivate an sub's vital sign assignment
  rest.patch<
    undefined,
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}/vital-signs/{vitalSignId}/deactivate']
  >(
    '/subscribers/:subscriberId/vital-sign-assignments/:assignmentId/vital-signs/:vitalSignId/deactivate',
    (req, res, ctx) => {
      // Get all vital sign assignments for this sub
      const allAssignments = db.get<SubscriberVitalAssignments[]>(
        'subVitalAssignments'
      )

      // remove the vitalAssignments record for this sub
      const [subAssignments] = _.remove(
        allAssignments,
        (vitalAssignments) =>
          vitalAssignments.subscriberId === req.params.subscriberId
      )

      if (!isPresent(subAssignments))
        throw new Error('vital assignment not found')

      // create a newAssignments array without the current subAssignment
      const newAssignments: SubscriberVitalAssignments = {
        ...subAssignments,
        assignedVitalSigns: subAssignments.assignedVitalSigns.map(
          (subAssignment) =>
            subAssignment.id === req.params.vitalSignId
              ? {
                  ...subAssignment,
                  active: false,
                }
              : subAssignment
        ),
      }

      db.set<SubscriberVitalAssignments[]>('subVitalAssignments', [
        ...allAssignments,
        newAssignments,
      ])

      const now = new Date()

      // Add a restore audit event
      const existingAuditTrail = db.get<VitalsAuditEvent[]>('vitalSignAudit')
      const newAuditEvent: VitalsAuditEvent = {
        id: faker.random.uuid(),
        organizationId: 'electronicCaregiverId',
        // subscriberId: req.params.subscriberId,
        entityName: 'vital-sign-assignment',
        action: 'UPDATE',
        newValue: newAssignments,
        modifiedAt: formatISO(now),
        modifiedBy: 'Addison',
        metadata: {},
      }
      db.set<VitalsAuditEvent[]>('vitalSignAudit', [
        ...existingAuditTrail,
        newAuditEvent,
      ])

      return res(ctx.json({ result: true }))
    }
  ),

  // REMINDERS

  // get reminder types query
  rest.get<undefined, ReminderTypes>('/reminder-types', (req, res, ctx) =>
    res(ctx.json(db.get<ReminderTypes>('reminderTypes')))
  ),

  //get ecg and org available translations
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
  >(
    '/organization/:orgId/reminder-messages/available-translations',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<
            vitalComponents['schemas']['OrgReminderMessageLocalesDto'][]
          >(
            req.params.orgId === 'electronicCaregiverId'
              ? 'orgAvailableTranslations'
              : 'ecgAvailableTranslations'
          ),
        })
      )
  ),

  //get reminder message types
  rest.get<undefined, VitalsAPIResponse['GET']['/reminder-message-types']>(
    '/reminder-message-types',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<vitalComponents['schemas']['ReminderMessageType'][]>(
            'reminderMessageTypes'
          ),
        })
      )
  ),

  //create ecg and org reminder messages
  rest.post<
    VitalsAPIRequest['POST']['/organization/{organizationId}/reminder-messages']['body'],
    VitalsAPIResponse['POST']['/organization/{organizationId}/reminder-messages']
  >('/organization/:orgId/reminder-messages', (req, res, ctx) => {
    if (req.body.localeKey !== 'en-US') {
      // if assumed role, use different data sets
      if (req.params.orgId === 'electronicCaregiverId') {
        //orgAvailableTranslations
        const oldAvailableTranslations = db.get<Array<AvailableTranslation>>(
          'orgAvailableTranslations'
        )

        // get the preferred locales to ensure translation names are set up
        const preferredLocales = db.get<Array<ADDILocale>>('preferredLocales')

        db.set<Array<AvailableTranslation>>('orgAvailableTranslations', [
          ...oldAvailableTranslations,
          {
            locale: {
              key: req.body.localeKey,
              name:
                preferredLocales.find(
                  (locale) => locale.name === req.body.localeKey
                )?.displayName || '',
            },
          },
        ])

        const oldTranslations = db.get<Array<ReminderMessage>>(
          'orgReminderMessageTranslations'
        )

        // @ts-expect-error bad swagger type
        const newTranslations: Array<ReminderMessage> = req.body.messages.map(
          (message, index) => {
            return {
              id: faker.random.uuid(),
              organizationId: req.params.orgId,
              messageType: {
                id: message.messageTypeId,
                name: reminderMessageTypes[index].name,
                description: reminderMessageTypes[index].description,
                displayOrder: reminderMessageTypes[index].displayOrder,
              },
              localeKey: req.body.localeKey,
              message: message.message,
            }
          }
        )

        db.set<Array<ReminderMessage>>('orgReminderMessageTranslations', [
          ...oldTranslations,
          ...newTranslations,
        ])
        return res(ctx.json({ result: true }))
      }
      const oldAvailableTranslations = db.get<Array<AvailableTranslation>>(
        'ecgAvailableTranslations'
      )
      // get the preferred locales to ensure translation names are set up
      const preferredLocales = db.get<Array<ADDILocale>>('preferredLocales')

      db.set<Array<AvailableTranslation>>('ecgAvailableTranslations', [
        ...oldAvailableTranslations,
        {
          locale: {
            key: req.body.localeKey,
            name:
              preferredLocales.find(
                (locale) => locale.name === req.body.localeKey
              )?.displayName || '',
          },
        },
      ])

      const oldTranslations = db.get<Array<ReminderMessage>>(
        'ecgReminderMessageTranslations'
      )

      // @ts-expect-error bad swagger type
      const newTranslations: Array<ReminderMessage> = req.body.messages.map(
        (message, index) => {
          return {
            id: faker.random.uuid(),
            organizationId: req.params.orgId,
            messageType: {
              id: message.messageTypeId,
              name: reminderMessageTypes[index].name,
              description: reminderMessageTypes[index].description,
              displayOrder: reminderMessageTypes[index].displayOrder,
            },
            localeKey: req.body.localeKey,
            message: message.message,
          }
        }
      )

      db.set<Array<ReminderMessage>>('ecgReminderMessageTranslations', [
        ...oldTranslations,
        ...newTranslations,
      ])

      return res(ctx.json({ result: true }))
    }

    if (req.params.orgId === 'electronicCaregiverId') {
      // @ts-expect-error bad swagger type
      const newReminders: Array<ReminderMessage> = req.body.messages.map(
        (message, index) => {
          return {
            id: faker.random.uuid(),
            organizationId: req.params.orgId,
            messageType: {
              id: message.messageTypeId,
              name: reminderMessageTypes[index].name,
              description: reminderMessageTypes[index].description,
              displayOrder: reminderMessageTypes[index].displayOrder,
            },
            localeKey: req.body.localeKey,
            message: message.message,
          }
        }
      )
      const translations = db.get<Array<AvailableTranslation>>(
        'orgAvailableTranslations'
      )
      db.set<Array<AvailableTranslation>>('orgAvailableTranslations', [
        ...translations,
        {
          locale: {
            key: req.body.localeKey,
            name: 'English (United States)',
          },
        },
      ])

      db.set<Array<ReminderMessage>>('orgReminderMessages', newReminders)
      db.set<Array<ReminderMessage>>(
        'orgReminderMessageTranslations',
        newReminders
      )
    } else {
      // @ts-expect-error bad swagger type
      const newReminders: Array<ReminderMessage> = req.body.messages.map(
        (message, index) => {
          return {
            id: faker.random.uuid(),
            organizationId: req.params.orgId,
            messageType: {
              id: message.messageTypeId,
              name: reminderMessageTypes[index].name,
              description: reminderMessageTypes[index].description,
              displayOrder: reminderMessageTypes[index].displayOrder,
            },
            localeKey: req.body.localeKey,
            message: message.message,
          }
        }
      )
      const translations = db.get<Array<AvailableTranslation>>(
        'ecgAvailableTranslations'
      )
      db.set<Array<AvailableTranslation>>('ecgAvailableTranslations', [
        ...translations,
        {
          locale: {
            key: req.body.localeKey,
            name: 'English (United States)',
          },
        },
      ])
      // update both queries
      db.set<Array<ReminderMessage>>(
        'ecgReminderMessageTranslations',
        newReminders
      )

      db.set<Array<ReminderMessage>>('ecgReminderMessages', newReminders)
    }

    return res(ctx.json({ result: true }))
  }),

  // Delete Ecg and Org Reminder Translation
  rest.delete<
    undefined,
    VitalsAPIResponse['DELETE']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']
  >(
    '/organization/:orgId/reminder-messages/:reminderMessageId',
    (req, res, ctx) => {
      // assumed org role
      if (req.params.orgId === 'electronicCaregiverId') {
        const oldAvailableTranslations = db.get<Array<AvailableTranslation>>(
          'orgAvailableTranslations'
        )
        const currentTranslations = db.get<Array<ReminderMessage>>(
          'orgReminderMessageTranslations'
        )
        const localeToDelete = currentTranslations.find((translation) => {
          return translation.id === req.params.reminderMessageId
        })?.localeKey
        const deleteTranslation = oldAvailableTranslations.filter(
          (translation) => {
            return translation.locale.key !== localeToDelete
          }
        )
        if (
          currentTranslations.find((translation) => {
            return translation.id === req.params.reminderMessageId
          })?.messageType.displayOrder === 5
        ) {
          db.set<Array<AvailableTranslation>>(
            'orgAvailableTranslations',
            deleteTranslation
          )
        }

        ctx.status(200)

        return res(ctx.json({ result: true }))
      }

      // default ecg
      const oldAvailableTranslations = db.get<Array<AvailableTranslation>>(
        'ecgAvailableTranslations'
      )
      const currentTranslations = db.get<Array<ReminderMessage>>(
        'ecgReminderMessageTranslations'
      )
      const localeToDelete = currentTranslations.find((translation) => {
        return translation.id === req.params.reminderMessageId
      })?.localeKey

      const deleteTranslation = oldAvailableTranslations.filter(
        (translation) => {
          return translation.locale.key !== localeToDelete
        }
      )
      // once we are at the last reminder, delete all reminders
      if (
        currentTranslations.find((translation) => {
          return translation.id === req.params.reminderMessageId
        })?.messageType.displayOrder === 5
      ) {
        db.set<Array<AvailableTranslation>>(
          'ecgAvailableTranslations',
          deleteTranslation
        )
        const newTranslations = currentTranslations.filter(
          (translation) => translation.localeKey !== localeToDelete
        )

        db.set<Array<ReminderMessage>>(
          'ecgReminderMessageTranslations',
          newTranslations
        )
      }
      ctx.status(200)
      return res(ctx.json({ result: true }))
    }
  ),

  // ECG and ORG Reminder Messages Translations
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
  >('/organization/:orgId/reminder-messages', (req, res, ctx) =>
    res(
      ctx.json({
        items: req.url.search
          ? // if url.search provided get the message translation matching the provided locale
            db
              .get<Array<ReminderMessage>>(
                req.params.orgId === 'electronicCaregiverId'
                  ? 'orgReminderMessageTranslations'
                  : 'ecgReminderMessageTranslations'
              )
              .filter(
                (translation) =>
                  !req.url.search ||
                  translation.localeKey === req.url.search.slice(8)
              )
          : db.get<Array<ReminderMessage>>(
              req.params.orgId === 'electronicCaregiverId'
                ? 'orgReminderMessages'
                : 'ecgReminderMessages'
            ),
      })
    )
  ),

  //update ecg and org reminder messages
  rest.patch<
    VitalsAPIRequest['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']['body'],
    VitalsAPIResponse['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']
  >(
    '/organization/:orgId/reminder-messages/:reminderMessageId',
    (req, res, ctx) => {
      // need to update reminder message individually, inserting every message into array one at a time
      const updateReminder: ReminderMessage = {
        id: req.params.reminderMessageId,
        organizationId: req.params.orgId,
        vitalSignId: null,
        messageType: {
          id: req.body.messageTypeId || '',
          name:
            reminderMessageTypes.find(
              (type) => type.id === req.body.messageTypeId
            )?.name || '',
          description:
            reminderMessageTypes.find(
              (type) => type.id === req.body.messageTypeId
            )?.description || '',
          displayOrder:
            reminderMessageTypes.find(
              (type) => type.id === req.body.messageTypeId
            )?.displayOrder || 0,
        },
        localeKey: req.body.localeKey || '',
        message: req.body.message || '',
        ...generateAuditable(),
      }

      //if assumed role, use org reminders data sets
      if (req.params.orgId === 'electronicCaregiverId') {
        const oldReminders = db.get<Array<ReminderMessage>>(
          'orgReminderMessages'
        )

        db.get<Array<ReminderMessage>>('orgReminderMessageTranslations')

        const newTranslations = oldReminders.map((reminder) => {
          if (updateReminder.id === reminder.id) return updateReminder
          else return reminder
        })

        db.set<Array<ReminderMessage>>(
          'orgReminderMessageTranslations',
          newTranslations
        )

        const newReminders = oldReminders.map((reminder) => {
          if (updateReminder.id === reminder.id) return updateReminder
          else return reminder
        })

        db.set<Array<ReminderMessage>>('orgReminderMessages', newReminders)

        return res(ctx.json({ result: true }))
      }
      // use ecg data sets
      const oldReminders = db.get<Array<ReminderMessage>>('ecgReminderMessages')

      const translations = db.get<Array<ReminderMessage>>(
        'ecgReminderMessageTranslations'
      )

      const newTranslations = translations.map((translation) => {
        if (updateReminder.id === translation.id) return updateReminder
        else return translation
      })

      db.set<Array<ReminderMessage>>(
        'ecgReminderMessageTranslations',
        newTranslations
      )

      const newReminders = oldReminders.map((reminder) => {
        if (updateReminder.id === reminder.id) return updateReminder
        else return reminder
      })

      db.set<Array<ReminderMessage>>('ecgReminderMessages', newReminders)

      return res(ctx.json({ result: true }))
    }
  ),

  // PREFERRED LOCALES

  rest.get<VitalsAPIResponse['GET']['/locales/preferred-locales']>(
    '/locales/preferred-locales',
    (req, res, ctx) =>
      res(ctx.json({ items: db.get<ADDILocale[]>('preferredLocales') }))
  ),
  // REMINDER PRESETS AND SCHEDULING

  // get reminder presets
  rest.get<
    VitalsAPIRequest['POST']['/organizations/{organizationId}/reminder-presets']['body'],
    VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
  >('/organizations/:orgId/reminder-presets', (req, res, ctx) =>
    res(
      ctx.json({
        items: db.get<Array<ReminderPreset>>('reminderPresets'),
      })
    )
  ),

  // create reminder preset
  rest.post<
    VitalsAPIRequest['POST']['/organizations/{organizationId}/reminder-presets']['body'],
    VitalsAPIResponse['POST']['/organizations/{organizationId}/reminder-presets']
  >('/organizations/:orgId/reminder-presets', (req, res, ctx) => {
    const oldReminderPresets = db.get<Array<ReminderPreset>>('reminderPresets')

    db.set<Array<ReminderPreset>>('reminderPresets', [
      ...oldReminderPresets,
      {
        ...req.body,
        description: req.body.description || '',
        id: faker.random.uuid(),
        organizationId: req.params.orgId,
        schedule: req.body.schedule,
      },
    ])

    return res(ctx.json({ result: true }))
  }),

  //patch reminder preset
  rest.patch<
    VitalsAPIRequest['PATCH']['/organizations/{organizationId}/reminder-presets/{presetId}']['body'],
    VitalsAPIResponse['PATCH']['/organizations/{organizationId}/reminder-presets/{presetId}']
  >('/organizations/:orgId/reminder-presets/:reminderId', (req, res, ctx) => {
    const oldReminderPresets = db.get<Array<ReminderPreset>>('reminderPresets')

    const filterReminderPresets = oldReminderPresets.filter(
      (preset) => preset.id !== req.params.reminderId
    )

    db.set<Array<ReminderPreset>>('reminderPresets', [
      ...filterReminderPresets,
      {
        name: req.body.name,
        description: req.body.description,
        id: req.params.reminderId,
        organizationId: req.params.orgId,
        schedule: req.body.schedule,
      },
    ])

    return res(ctx.json({ result: true }))
  }),

  // delete reminder preset
  rest.delete<
    undefined,
    VitalsAPIResponse['DELETE']['/organizations/{organizationId}/reminder-presets/{presetId}']
  >('/organizations/:orgId/reminder-presets/:reminderId', (req, res, ctx) => {
    const oldReminderPresets = db.get<Array<ReminderPreset>>('reminderPresets')

    const filterReminderPresets = oldReminderPresets.filter(
      (preset) => preset.id !== req.params.reminderId
    )

    db.set<Array<ReminderPreset>>('reminderPresets', [...filterReminderPresets])

    return res(ctx.json({ result: true }))
  }),

  // get reminder schedules
  rest.get<
    string,
    VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
  >('/subscribers/:subId/vital-reminders', (req, res, ctx) =>
    res(
      ctx.json({
        items: db.get<Array<ReminderSchedule>>('reminderSchedule'),
      })
    )
  ),

  //get single reminder message
  rest.get<
    string,
    VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']
  >(
    '/organization/:organizationId/reminder-messages/:reminderMessageId',
    (req, res, ctx) => {
      const reminders = db.get<
        Array<vitalComponents['schemas']['GetReminderMessagesDto']>
      >('orgReminderMessages')

      return res(
        ctx.json(
          reminders.find(
            (selectReminder) =>
              selectReminder.id === req.params.reminderMessageId
          ) || reminders[0]
        )
      )
    }
  ),

  //create reminder schedules
  rest.post<
    VitalsAPIRequest['POST']['/subscribers/{subscriberId}/vital-reminders']['body'],
    VitalsAPIResponse['POST']['/subscribers/{subscriberId}/vital-reminders']
  >('/subscribers/:subId/vital-reminders', (req, res, ctx) => {
    const oldSchedules = db.get<Array<ReminderSchedule>>('reminderSchedule')
    const reminderMessages = db.get<Array<ReminderMessage>>(
      'orgReminderMessages'
    )

    const selectedMessage = reminderMessages.find(
      (message) => message.messageType.id === req.body.messageId
    )
    db.set<Array<ReminderSchedule>>('reminderSchedule', [
      ...oldSchedules,
      {
        ...req.body,
        subscriberId: req.params.subId,
        schedule: {
          ...req.body.schedule,
          time: req.body.schedule?.time || '',
          frequency: req.body.schedule?.frequency || 'daily',
        },
        message: {
          id: req.body.messageId || '',
          message: selectedMessage?.message || '',
        },
        description: req.body.description || '',
        audio: {
          audioUrl: req.body.audio?.audioUrl || '',
          ssmUrl: req.body.audio?.ssmUrl || '',
        },
        customReminderMessage: req.body.customReminderMessage || '',
        locale: req.body.locale || '',
        preset: {
          id: faker.random.uuid(),
          description: req.body.preset?.description || '',
          name: req.body.preset?.name || '',
        },
        id: faker.random.uuid(),
        vitalSignIds: req.body.vitalSignIds ? [...req.body.vitalSignIds] : [],
      },
    ])

    return res(ctx.json({ result: true }))
  }),

  // update reminder schedules
  rest.patch<
    VitalsAPIRequest['PATCH']['/subscribers/{subscriberId}/vital-reminders/{reminderId}']['body'],
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-reminders/{reminderId}']
  >('/subscribers/:subId/vital-reminders/:reminderId', (req, res, ctx) => {
    const oldSchedules = db.get<Array<ReminderSchedule>>('reminderSchedule')
    const reminderMessages = db.get<Array<ReminderMessage>>(
      'orgReminderMessages'
    )

    const filterSchedules = oldSchedules.filter(
      (schedule) => schedule.id !== req.params.reminderId
    )

    const selectedMessage = reminderMessages.find(
      (message) => message.messageType.id === req.body.messageId
    )
    db.set<Array<ReminderSchedule>>('reminderSchedule', [
      ...filterSchedules,
      {
        ...req.body,
        audio: { audioUrl: '', ssmUrl: '' },
        schedule: req.body.schedule as NonNullable<typeof req.body.schedule>,
        description: req.body.description || '',
        customReminderMessage: req.body.customReminderMessage || '',
        locale: req.body.locale || '',
        reminderTypeId: req.body.reminderTypeId || '',
        subscriberId: req.params.subId,
        message: {
          id: req.body.messageId || '',
          message: selectedMessage?.message || '',
        },
        preset: { id: req.body.presetId || '', description: '', name: '' },
        id: faker.random.uuid(),
      },
    ])

    return res(ctx.json({ result: true }))
  }),

  // delete reminder schedule
  rest.delete<
    undefined,
    VitalsAPIResponse['DELETE']['/vital-reminders/{reminderId}']
  >('/vital-reminders/:reminderId', (req, res, ctx) => {
    db.set<Array<ReminderSchedule>>(
      'reminderSchedule',
      db
        .get<Array<ReminderSchedule>>('reminderSchedule')
        .filter((schedule) => schedule.id !== req.params.reminderId)
    )

    return res(ctx.json({ result: true }))
  }),

  // get organization on-boarding
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/organizations/{organizationId}/on-boarding']
  >('/organizations/:organizationId/on-boarding', (req, res, ctx) =>
    res(
      ctx.json({
        alertDeliveryRules: {
          complete: true,
          message: '',
        },
        reminderMessages: {
          complete: true,
          message: '',
        },
        vitalSignAssignments: {
          complete: true,
          message: '',
        },
        vitalThresholds: {
          complete: true,
          message: '',
        },
      })
    )
  ),

  // get subscriber on-boarding
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/subscribers/{subscriberId}/on-boarding']
  >('/subscribers/:subscriberId/on-boarding', (req, res, ctx) =>
    res(
      ctx.json({
        reminderSchedules: {
          complete: true,
          message: '',
        },
        vitalSignAssignments: {
          complete: true,
          message: '',
        },
        vitalThresholds: {
          complete: true,
          message: '',
        },
      })
    )
  ),
]

export default vitalsModuleHandlers
