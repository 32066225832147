import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CodeViewer, List, RightPopup, CrudOperationToChip } from 'elements'
import { Button, DateRangePicker } from 'atlas'
import { useModulesAuditTrailQuery } from 'hooks/audit-trails'
import { useTable, Column, TableInstance, useSortBy, Row } from 'react-table'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import CategoryFilter from 'elements/CategoryFilter'
import { formatDate, dateRangePresetToDateRange } from 'utils'
import tw from 'twin.macro'
import { ModuleAuditSearchParams } from 'hooks/audit-trails/orgs-svcs/useModulesAuditTrailQuery'
import { isCrudOperation } from 'elements/CrudOperationToChip'

const ModulesAuditTrailList = () => {
  const { t } = useTranslation()

  const [auditDetails, setAuditDetails] = useState<OrgsAuditEvent>()
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  const [auditFilters, setAuditFilters] = useState<ModuleAuditSearchParams>({
    dateRangeType: 'this-week',
    take: 30,
    skip: 0,
    order_by: 'DESC',
  })

  // Sort Function for the 'Modified At' column
  const modifiedAtSortBy = useCallback(
    (rowA: Row<OrgsAuditEvent>, rowB: Row<OrgsAuditEvent>) => {
      if (!rowA.original.modifiedAt) return -1
      if (!rowB.original.modifiedAt) return 1
      return new Date(rowA.original.modifiedAt) >
        new Date(rowB.original.modifiedAt)
        ? 1
        : -1
    },
    []
  )

  const auditTrailQuery = useModulesAuditTrailQuery({
    filters: auditFilters,
  })

  const tableData = useMemo(() => auditTrailQuery.data?.items || [], [
    auditTrailQuery.data,
  ])

  const columns: Column<OrgsAuditEvent>[] = useMemo(
    () => [
      {
        id: 'NAME',
        Header: t('MODULE').toString(),
        accessor: (row) => row.newValue.name,
        disableSortBy: true,
      },
      {
        id: 'ACTION_TYPE',
        Header: t('ACTION TYPE').toString(),
        accessor: (row) =>
          isCrudOperation(row.action) ? (
            <CrudOperationToChip operation={row.action} />
          ) : (
            row.action
          ),
        disableSortBy: true,
        Filter: (tableInstance: TableInstance<OrgsAuditEvent>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="settings"
            label={t('Action')}
            categories={(['INSERT', 'UPDATE', 'DELETE'] as const).map(
              (action) => ({
                label: action,
                value: action,
              })
            )}
            selectedCategories={
              auditFilters.searchTerm
                ? [
                    {
                      label: auditFilters.searchTerm,
                      value: auditFilters.searchTerm,
                    },
                  ]
                : undefined
            }
            setSelectedCategories={(selectedActions) =>
              setAuditFilters({
                ...auditFilters,
                searchTerm: selectedActions[selectedActions.length - 1]?.value,
              })
            }
          />
        ),
      },
      {
        id: 'MODIFIED_BY',
        Header: t('MODIFIED BY').toString(),
        accessor: (row) => row.modifiedBy,
        disableSortBy: true,
      },
      {
        id: 'MODIFIED_DATE',
        Header: t('MODIFIED AT').toString(),
        accessor: (row, index) => (
          <ModifiedAtContainer key={index}>
            {row.modifiedAt ? formatDate(parseISO(row.modifiedAt), 'Pp') : '-'}
          </ModifiedAtContainer>
        ),
        sortType: modifiedAtSortBy,
        Filter: (
          <DateRangePicker
            value={{
              preset: auditFilters.dateRangeType || 'this-week',
              value:
                auditFilters.dateRangeType === 'custom' &&
                auditFilters.from &&
                auditFilters.to
                  ? [parseISO(auditFilters.from), parseISO(auditFilters.to)]
                  : dateRangePresetToDateRange(
                      auditFilters.dateRangeType || 'this-week'
                    ),
            }}
            onChange={(dateRangePreset, dateRange) =>
              setAuditFilters({
                ...auditFilters,
                dateRangeType: dateRangePreset,
                from:
                  dateRangePreset === 'custom'
                    ? dateRange[0].toISOString()
                    : undefined,
                to:
                  dateRangePreset === 'custom'
                    ? dateRange[1].toISOString()
                    : undefined,
                skip: 0,
              })
            }
          />
        ),
      },
      {
        id: 'DETAILS',
        Header: t('DETAILS').toString(),
        accessor: (row) => (
          <Button
            type="primary-link"
            onClick={() => {
              setAuditDetails(row)
              setIsDetailsModalOpen(true)
            }}
          >
            {t('Details')}
          </Button>
        ),
        width: '0.5fr',
        disableSortBy: true,
      },
    ],
    [i18next.language, auditFilters]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetSortBy: false,
      // default: sort in descending order by date
      initialState: {
        sortBy: [
          {
            id: 'MODIFIED_DATE',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  )

  // because url state isn't being used manually set the sort state if the table sort is toggled
  // TODO: This solution breaks pagination UI when changing filters when not on first page.
  // TODO: Ensure a solution with new List refactor
  useEffect(() => {
    setAuditFilters({
      ...auditFilters,
      order_by:
        tableInstance.state.sortBy[0] && !tableInstance.state.sortBy[0].desc
          ? 'ASC'
          : 'DESC',
    })
  }, [tableInstance.state.sortBy])

  return (
    <>
      <List
        tableInstance={tableInstance}
        emptyTitle={t('No audit events found')}
        isLoading={auditTrailQuery.isLoading}
        paginatable
        paginationInfo={auditTrailQuery.data}
        onPageChange={(newPageNumber) => {
          setAuditFilters({
            ...auditFilters,
            skip: newPageNumber - 1,
          })
        }}
      />
      <RightPopup
        open={isDetailsModalOpen}
        setOpen={setIsDetailsModalOpen}
        title={t('Audit Item Details')}
        width="800px"
        controls={
          <CancelButton
            onClick={() => {
              setIsDetailsModalOpen(false)
            }}
          >
            {t('Cancel')}
          </CancelButton>
        }
      >
        <CodeViewer
          code={JSON.stringify(auditDetails, null, 2)}
          language="json"
        />
      </RightPopup>
    </>
  )
}

export default ModulesAuditTrailList

const CancelButton = tw(Button)`mt-4`
const ModifiedAtContainer = tw.div`flex`
