type isProvisioningNeededType = {
  orderStatusTitle?: string | undefined | null
  provisioningNeeded?: boolean
}

/**
 * checks the status of an order if it needs provisioning
 * @param {isProvisioningNeededType} Object
 * @returns
 */
const isProvisioningNeeded = ({
  orderStatusTitle: orderStatusDescription,
  provisioningNeeded,
}: isProvisioningNeededType) => {
  if (!provisioningNeeded) return 'Provisioning Done'
  if (orderStatusDescription === 'Provisioning In-Process')
    return 'Release from provision'
  if (orderStatusDescription === 'Confirmed') return 'Provision'
  return 'Provisioning Done'
}

export default isProvisioningNeeded
