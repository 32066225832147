import { vitalsApi } from 'utils'
import { useQuery } from 'react-query'
import _ from 'lodash'

const useThresholdsQuery = (subscriberId: string) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']['items'],
    KyError
  >(['vitalsThresholds', subscriberId], () => getVitalsThresholds(subscriberId))

  return query
}

export default useThresholdsQuery

// get list of vital thresholds from backend
const getVitalsThresholds = async (subscriberId: string) => {
  const result = await vitalsApi
    .get(`subscribers/${subscriberId}/thresholds`)
    .json<VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']>()

  return _.orderBy(result.items, 'vitalSign.displayName')
}
