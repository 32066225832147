import React, { useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { Alert, Button, Empty } from 'atlas'
import {
  OrganizationInfoCard,
  OrganizationStatusChip,
} from 'components/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Dialog, EntityLabel, Icon, LoadingIcon, Statistic } from 'elements'
import { useDelay, useNavigate } from 'hooks'
import {
  useOrganizationHierarchyQuery,
  useOrganizationOnboardingQuery,
} from 'hooks/organizations'
import { useOrganizationMemberStatistics } from 'hooks/statistics'
import _ from 'lodash'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { sortOrgHierarchy } from 'utils'
import OrganizationMetadata from './OrganizationMetadata'

type OrganizationDetailsContainerProps = {
  organization: Organization
}

const OrganizationDetailsContainer = ({
  organization,
}: OrganizationDetailsContainerProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const delay = useDelay()

  const [isListFlattened, setIsListFlattened] = useState<boolean>(false)
  const [
    isStaffRedirectDialogOpen,
    setIsStaffRedirectDialogOpen,
  ] = useState<boolean>(false)
  const [
    isSubscriberRedirectDialogOpen,
    setIsSubscriberRedirectDialogOpen,
  ] = useState<boolean>(false)
  const organizationOnboardingQuery = useOrganizationOnboardingQuery({
    organizationId: organization.id,
  })

  const {
    assumedOrganizationRole,
    assumeOrganizationRole,
  } = useAssumedOrganizationRole()

  const organizationMemberStatistics = useOrganizationMemberStatistics({
    organizationId: organization.id,
  })

  const organizationHierarchyQuery = useOrganizationHierarchyQuery(
    organization.id
  )

  // filter out the top level org and order children after their parents
  const orgHierarchy = sortOrgHierarchy(
    Array.isArray(organizationHierarchyQuery.data)
      ? organizationHierarchyQuery.data.filter((org) => org.level > 1)
      : []
  )

  if (organizationHierarchyQuery.isLoading) return <LoadingIcon />

  return (
    <>
      {_.find(organizationOnboardingQuery.data, (value) => !value.complete) ? (
        <FadeInSlideDown delay={delay()}>
          <Alert
            message={t('Organization setup incomplete')}
            type="warning"
            onClick={() => {
              navigate('setup')
            }}
          />
        </FadeInSlideDown>
      ) : null}

      <OrgBasicInfoGrid>
        <FadeInSlideDown delay={delay()} className="col-span-4">
          <OrganizationInfoCard organization={organization} />
        </FadeInSlideDown>

        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="subscribers"
            iconColor="red"
            label={t('Staff Members')}
            value={numeral(
              organizationMemberStatistics.data?.staffMembers.total.toString()
            ).format('0,0')}
            actions={
              <Button
                type="primary-link"
                onClick={() =>
                  // If user has already assumed this org's role then redirect to staff, if not open dialog
                  assumedOrganizationRole?.orgID === organization.id
                    ? navigate('/staff')
                    : setIsStaffRedirectDialogOpen(true)
                }
              >
                {t('Manage')}
              </Button>
            }
          />
        </FadeInSlideDown>

        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="subscribers"
            iconColor="purple"
            label={t('Subscribers')}
            value={numeral(
              organizationMemberStatistics.data?.subscribers.total.toString()
            ).format('0,0')}
            actions={
              <Button
                type="primary-link"
                onClick={() =>
                  // If user has already assumed this org's role then redirect to subscribers, if not open dialog
                  assumedOrganizationRole?.orgID === organization.id
                    ? navigate('/subscribers')
                    : setIsSubscriberRedirectDialogOpen(true)
                }
              >
                {t('Manage')}
              </Button>
            }
          />
        </FadeInSlideDown>
      </OrgBasicInfoGrid>

      <OrgDetailsGrid>
        <FadeInSlideDown delay={delay()}>
          <OrganizationMetadata metadata={organization.integrationMetadata} />
        </FadeInSlideDown>

        <OrgDetailsContainer>
          <FadeInSlideDown
            delay={delay()}
            className="flex justify-between items-center mb-4 mt-8 h-8"
          >
            <Title>{t('Organization Directory')}</Title>

            <HierarchyHeaderRow>
              <HierarchyControlIcon
                type={isListFlattened ? 'align-right' : 'align-justify'}
                onClick={() => setIsListFlattened(!isListFlattened)}
                disabled={!orgHierarchy.find((subOrg) => subOrg.level > 2)}
              />
            </HierarchyHeaderRow>
          </FadeInSlideDown>

          <div>
            {orgHierarchy.length ? (
              orgHierarchy.map((org, index) => (
                <FadeInSlideDown
                  // If parentOrganization card was rendered delay by an extra 0.1
                  key={org.id + index}
                  delay={delay()}
                  className="flex items-center w-full"
                >
                  <span
                    className="border-b-2 border-gray-200 -mt-2"
                    style={{
                      width: `${isListFlattened ? 0 : (org.level - 2) * 4}rem`,
                    }}
                  />
                  <ChildOrgLine
                    style={{
                      width: `${isListFlattened ? 0 : (org.level - 2) * 4}rem`,
                    }}
                  />
                  <HierarchyCard
                    onClick={() =>
                      navigate(
                        assumedOrganizationRole
                          ? `/organization/${org.id}`
                          : `/organizations/${org.id}`
                      )
                    }
                  >
                    <OrganizationDirectory>
                      <EntityLabel
                        name={org.businessName}
                        description={org.displayName}
                        id={org.id}
                        size="md"
                      />

                      <OrganizationStatusChip status={org.status} />
                    </OrganizationDirectory>
                    <AssumeRoleButton
                      type="primary-link"
                      onClick={(e) => {
                        e.stopPropagation()

                        assumeOrganizationRole({
                          orgID: org.id,
                        })
                      }}
                    >
                      {t('Assume Role')}
                    </AssumeRoleButton>
                  </HierarchyCard>
                </FadeInSlideDown>
              ))
            ) : (
              <FadeInSlideDown delay={delay()}>
                <SubOrganizationEmpty
                  title={t('No Data')}
                  description={t(
                    'No sub-organizations exists for this organization'
                  )}
                />
              </FadeInSlideDown>
            )}
          </div>
        </OrgDetailsContainer>
      </OrgDetailsGrid>

      {/* MANAGE STAFF REDIRECT */}
      <Dialog
        open={isStaffRedirectDialogOpen}
        title={`${t('Assume Role of')} ${organization.businessName}?`}
        content={
          <p>
            {t(
              `To manage Staff Members for this organization you must assume the organization's role`
            )}
          </p>
        }
        actions={
          <>
            <Button
              onClick={() => setIsStaffRedirectDialogOpen(false)}
              type="secondary"
            >
              {t('Cancel')}
            </Button>
            <Button
              type="primary-filled"
              onClick={() => {
                assumeOrganizationRole({
                  orgID: organization.id,
                  onSuccess: (searchParams) =>
                    navigate({ pathname: '/staff', searchParams }),
                })

                setIsStaffRedirectDialogOpen(false)
              }}
            >
              {t('Assume Role')}
            </Button>
          </>
        }
      />
      {/* MANAGE STAFF REDIRECT */}
      <Dialog
        open={isSubscriberRedirectDialogOpen}
        title={`${t('Assume Role of')} ${organization.businessName}?`}
        content={
          <p>
            {t(
              `To manage Subscribers for this organization you must assume the organization's role`
            )}
          </p>
        }
        actions={
          <>
            <Button
              onClick={() => setIsSubscriberRedirectDialogOpen(false)}
              type="secondary"
            >
              {t('Cancel')}
            </Button>
            <Button
              type="primary-filled"
              onClick={() => {
                assumeOrganizationRole({
                  orgID: organization.id,
                  onSuccess: (searchParams) =>
                    navigate({ pathname: '/subscribers', searchParams }),
                })

                setIsSubscriberRedirectDialogOpen(false)
              }}
            >
              {t('Assume Role')}
            </Button>
          </>
        }
      />
    </>
  )
}

export default OrganizationDetailsContainer

const SubOrganizationEmpty = tw(Empty)`border-2 border-dashed rounded p-11`

const OrgBasicInfoGrid = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`

const OrgDetailsGrid = tw.div`grid grid-cols-1 xl:grid-cols-3 gap-3`

const HierarchyHeaderRow = tw.div`flex justify-between align-middle`

const HierarchyControlIcon = tw(Icon)`text-2xl text-gray-900 cursor-pointer`

const HierarchyCard = tw.div`flex flex-grow justify-between items-center bg-white border border-gray-200 hover:border-blue-200 hover:shadow cursor-pointer p-4 mb-2`

const OrganizationDirectory = tw.div`flex flex-row flex-grow items-center justify-between space-x-2 mr-4`

const AssumeRoleButton = tw(
  Button
)`border rounded-full hover:shadow px-3 py-0.5 mr-2 hover:no-underline`

const OrgDetailsContainer = tw.div`col-span-2`

const ChildOrgLine = tw.span`border-b-2 border-gray-200 -mt-2`

const Title = tw.h3`text-2xl font-semibold`
