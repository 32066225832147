import {
  AutoComplete,
  ConfirmationDialogs,
  Icon,
  RadioGroup,
  TextField,
} from 'elements'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useAPIQuery, useDelay, useNavigate } from 'hooks'
import { FadeInSlideDown } from 'animations'
import { Button, Empty, Tooltip } from 'atlas'
import {
  useCreateLineItemMutation,
  useContractLineItemsQuery,
  useUpdateLineItemMutation,
} from 'hooks/contracts'
import { useParams } from 'react-router'
import { useSearchParam } from 'react-use'
import { WarrantyOptionForm } from '.'
import _ from 'lodash'
import { getLineItemFields } from 'utils'

const BaseProductForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const delay = useDelay()
  const { contractId } = useParams()
  const organizationId = useSearchParam('organizationId') as string
  const lineItemId = useSearchParam('lineItemId')

  const [product, setProduct] = useState<Product | null>(null)
  const [productError, setProductError] = useState<string>()

  const [isWarrantyFormOpen, setIsWarrantyFormOpen] = useState<boolean>(false)
  const [
    isDeleteWarrantyDialogOpen,
    setIsDeleteWarrantyDialogOpen,
  ] = useState<boolean>(false)
  const [warrantyToMutate, setWarrantyToMutate] = useState<WarrantyOption>()
  const [warrantyOptions, setWarrantyOptions] = useState<WarrantyOption[]>([])

  const productsQuery = useAPIQuery('products', {
    searchParams: { tags: 'Base Console' },
  })

  const lineItemsQuery = useContractLineItemsQuery({
    organizationId,
    contractId,
  })

  const createLineItemMutation = useCreateLineItemMutation()
  const updateLineItemMutation = useUpdateLineItemMutation()

  const { errors, control, trigger, watch, reset } = useForm<
    Required<ContractLineItemForm>['primaryProductLineItem']
  >({ shouldUnregister: false }) // keep form values that don't have a registered input

  const baseProductToEdit = lineItemsQuery.data?.items?.find(
    (lineItem) => lineItem.id === lineItemId
  )

  // if baseProductToEdit exists set the form values to the existing baseProduct
  useEffect(() => {
    if (baseProductToEdit) {
      const baseProductFields = getLineItemFields<'primaryProductLineItem'>(
        baseProductToEdit
      )
      setWarrantyOptions(
        baseProductFields?.pricing?.warrantySelection?.options || []
      )
      setProduct(
        productsQuery.data?.items?.find(
          (product) => '' + product.id === baseProductFields?.productId
        ) || null
      )

      reset({
        ...baseProductFields,
        productId: baseProductFields?.productId || undefined,
      })
    }
  }, [baseProductToEdit, productsQuery.data])

  const confirmations = [
    //Delete Warranty Dialog
    {
      isOpen: isDeleteWarrantyDialogOpen,
      setIsOpen: setIsDeleteWarrantyDialogOpen,
      title: t('Delete Warranty Option'),
      content: (
        <p>{t('Are you sure you want to delete this warranty option?')}</p>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            setWarrantyOptions(
              warrantyOptions.filter(
                (warrantyOption) => !_.isEqual(warrantyOption, warrantyToMutate)
              )
            )

            setIsDeleteWarrantyDialogOpen(false)
          }}
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <>
      <FormContainer>
        <FadeInSlideDown delay={delay()}>
          <RowContainer>
            <ProductAutoComplete
              label={t('Base Product *')}
              options={productsQuery.data?.items || []}
              single
              onChange={(product) => setProduct(product)}
              optionLabel={(option) => option.title || ''}
              selectedOption={product}
              disableAutofill
              error={productError}
            />
          </RowContainer>
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="descriptionInfo.title"
              label={t('Title')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Title is required') }}
              error={errors.descriptionInfo?.title?.message}
              className="flex-grow max-w-md mt-4"
              required
            />
            <InfoTooltip
              content={
                <TooltipText>
                  {t(
                    'This is the title customers will see when they are placing an Order'
                  ) + '.'}
                </TooltipText>
              }
            >
              <InfoIcon type="info" />
            </InfoTooltip>
          </RowContainer>
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="descriptionInfo.description"
              label={t('Description')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Description is required') }}
              error={errors.descriptionInfo?.description?.message}
              className="flex-grow max-w-xl mt-4"
              multiline
              rows={3}
              required
            />
            <InfoTooltip
              content={
                <TooltipText>
                  {t(
                    'Enter a meaningful description that will help customers understand what they get when adding this item to the cart'
                  ) + '.'}
                </TooltipText>
              }
            >
              <InfoIcon type="info" />
            </InfoTooltip>
          </RowContainer>
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <Header>
            <Title>{t('Pricing')}</Title>
          </Header>
          <SubTitle>{t('Product Fee')}</SubTitle>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="pricing.productFee.price.amount"
              label={t('Amount')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Product Fee Amount is required') }}
              error={errors.pricing?.productFee?.price?.amount?.message}
              className="w-56"
              endAdornment={
                // incorrect typing, 'watch' may return undefined object
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                watch('pricing')?.productFee?.price?.interval === 'Monthly'
                  ? '/mo'
                  : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  watch('pricing')?.productFee?.price?.interval === 'Yearly'
                  ? '/yr'
                  : ''
              }
              type="number"
              required
            />
            <Controller
              control={control}
              render={({ onChange, value, ref, name }) => (
                <RadioGroup
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  options={[
                    { value: 'OneTime', label: t('One-time') },
                    { value: 'Monthly', label: t('Monthly') },
                    { value: 'Yearly', label: t('Annual') },
                  ]}
                  checked={value}
                  label={t('Pricing Interval')}
                  className="mx-12 -mt-2"
                />
              )}
              defaultValue={'Monthly'}
              name="pricing.productFee.price.interval"
              required
            />
          </RowContainer>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="pricing.productFee.descriptionInfo.title"
              label={t('Product Fee Title')}
              className="w-56 mr-4"
            />
          </RowContainer>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="pricing.productFee.descriptionInfo.description"
              label={t('Product Fee Description')}
              className="flex-grow max-w-lg"
              multiline
              rows={2}
            />
          </RowContainer>
          <SubTitle>{t('Activation Fee')}</SubTitle>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="pricing.activationFee.price.amount"
              label={t('Amount')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Activation Fee Amount is required') }}
              error={errors.pricing?.activationFee?.price?.amount?.message}
              className="w-56 mr-4"
              endAdornment={
                // incorrect typing, 'watch' may return undefined object
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                watch('pricing')?.activationFee?.price?.interval === 'Monthly'
                  ? '/mo'
                  : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  watch('pricing')?.activationFee?.price?.interval === 'Yearly'
                  ? '/yr'
                  : ''
              }
              type="number"
              required
            />
          </RowContainer>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="pricing.activationFee.descriptionInfo.title"
              label={t('Activation Fee Title')}
              className="w-56 mr-4"
            />
          </RowContainer>
          <RowContainer>
            <Controller
              control={control}
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="pricing.activationFee.descriptionInfo.description"
              label={t('Activation Fee Description')}
              className="flex-grow max-w-lg"
              multiline
              rows={2}
            />
          </RowContainer>
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <Header delay={delay()}>
            <Title>{t('Warranty Options')}</Title>
            {warrantyOptions.length ? (
              <AddWarrantyButton
                onClick={() => {
                  setWarrantyToMutate(undefined)
                  setIsWarrantyFormOpen(true)
                }}
              >
                {t('Add Warranty Option')}
              </AddWarrantyButton>
            ) : null}
          </Header>
          {warrantyOptions.length ? (
            warrantyOptions.map((warrantyOption, index) => (
              <WarrantyOptionCard delay={delay()} key={index}>
                <WarrantyOptionTitle>
                  {warrantyOption.priceDescriptor?.descriptionInfo?.title +
                    ': $'}
                  <WarrantyOptionPrice>
                    {warrantyOption.priceDescriptor?.price?.amount}
                  </WarrantyOptionPrice>

                  {warrantyOption.priceDescriptor?.price?.interval === 'Monthly'
                    ? ' /mo'
                    : warrantyOption.priceDescriptor?.price?.interval ===
                      'Yearly'
                    ? ' /yr'
                    : ''}
                </WarrantyOptionTitle>
                <WarrantyOptionDescription>
                  {warrantyOption.priceDescriptor?.descriptionInfo?.description}
                </WarrantyOptionDescription>
                <IconContainer>
                  <EditIcon
                    type="edit"
                    onClick={() => {
                      setWarrantyToMutate(warrantyOption)
                      setIsWarrantyFormOpen(true)
                    }}
                  />
                  <TrashIcon
                    type="trash"
                    onClick={() => {
                      setWarrantyToMutate(warrantyOption)
                      setIsDeleteWarrantyDialogOpen(true)
                    }}
                  />
                </IconContainer>
              </WarrantyOptionCard>
            ))
          ) : (
            <Empty
              title={t('No Warranty Options Added')}
              description={`${t('No warranty options added yet')}. ${t(
                'Click on the button below to add warranty selections'
              )}.`}
              callToAction={
                <Button
                  onClick={() => {
                    setWarrantyToMutate(undefined)
                    setIsWarrantyFormOpen(true)
                  }}
                >
                  {t('Add Warranty Option')}
                </Button>
              }
            />
          )}
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <RowContainer>
            <SubmitButton
              onClick={async () => {
                if (!product) setProductError(t('Product is required'))
                else setProductError(undefined)

                if (!(await trigger()) || !product) return

                const formData = watch()

                baseProductToEdit
                  ? updateLineItemMutation.mutate(
                      {
                        lineItemId: lineItemId || '',
                        lineItemType: baseProductToEdit.lineItemType as 'PrimaryProduct',
                        lineItemForm: {
                          ...formData,
                          productId: '' + product.id,
                          pricing: {
                            activationFee: {
                              price: formData.pricing.activationFee?.price,
                              // Don't send descriptionInfo if title is falsey
                              ...(formData.pricing.activationFee
                                ?.descriptionInfo?.title
                                ? {
                                    descriptionInfo:
                                      formData.pricing.activationFee
                                        .descriptionInfo,
                                  }
                                : {}),
                            },
                            productFee: {
                              price: formData.pricing.productFee?.price,
                              // Don't send descriptionInfo if title is falsey
                              ...(formData.pricing.productFee?.descriptionInfo
                                ?.title
                                ? {
                                    descriptionInfo:
                                      formData.pricing.productFee
                                        .descriptionInfo,
                                  }
                                : {}),
                            },
                            warrantySelection: { options: warrantyOptions },
                          },
                        },
                        organizationId: organizationId || '',
                        contractId,
                      },
                      {
                        // on successful creation redirect to the contract's detail page to continue contract setup
                        onSuccess: () => navigate('../../'),
                      }
                    )
                  : createLineItemMutation.mutate(
                      {
                        lineItemForm: {
                          optional: false,
                          lineItemType: 'PrimaryProduct',
                          primaryProductLineItem: {
                            ...formData,
                            productId: '' + product.id,
                            quantity: 1,
                            pricing: {
                              activationFee: {
                                price: {
                                  flatOrPercent: 'Flat',
                                  interval: 'OneTime',
                                  ...formData.pricing.activationFee?.price,
                                },
                                // Don't send descriptionInfo if title is falsey
                                ...(formData.pricing.activationFee
                                  ?.descriptionInfo?.title
                                  ? {
                                      descriptionInfo:
                                        formData.pricing.activationFee
                                          .descriptionInfo,
                                    }
                                  : {}),
                              },
                              productFee: {
                                price: {
                                  flatOrPercent: 'Flat',
                                  interval: 'Monthly',
                                  ...formData.pricing.productFee?.price,
                                },
                                // Don't send descriptionInfo if title is falsey
                                ...(formData.pricing.productFee?.descriptionInfo
                                  ?.title
                                  ? {
                                      descriptionInfo:
                                        formData.pricing.productFee
                                          .descriptionInfo,
                                    }
                                  : {}),
                              },
                              warrantySelection: { options: warrantyOptions },
                            },
                          },
                        },
                        organizationId: organizationId || '',
                        contractId,
                      },
                      {
                        // on successful creation redirect to the contract's detail page to continue contract setup
                        onSuccess: () => navigate('../../'),
                      }
                    )
              }}
            >
              {baseProductToEdit ? t('Update') : t('Create Base Product')}
            </SubmitButton>
            <CancelButton type="primary-link" to="../../">
              {t('Cancel')}
            </CancelButton>
          </RowContainer>
        </FadeInSlideDown>
      </FormContainer>
      <WarrantyOptionForm
        isFormOpen={isWarrantyFormOpen}
        setIsFormOpen={setIsWarrantyFormOpen}
        warrantyOptionToEdit={warrantyToMutate}
        onSubmit={(newWarranty) =>
          setWarrantyOptions(
            // if mutating existing warranty find and replace, otherwise add a new warranty
            warrantyToMutate
              ? warrantyOptions.map((warrantyOption) =>
                  _.isEqual(warrantyOption, warrantyToMutate)
                    ? newWarranty
                    : warrantyOption
                )
              : [...warrantyOptions, newWarranty]
          )
        }
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </>
  )
}

export default BaseProductForm

const FormContainer = tw(
  FadeInSlideDown
)`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const RowContainer = tw.div`flex`

const ProductAutoComplete = tw(AutoComplete)`mt-2 flex-grow max-w-md`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`ml-2 mt-4 h-fit-content`

const TooltipText = tw.p`w-60`

const SubmitButton = tw(Button)`mt-4`

const Title = tw.h3`text-xl font-semibold flex-grow`

const SubTitle = tw.h4`text-lg font-medium mb-2`

const IconContainer = tw.div`flex items-center`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600 ml-2`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900 ml-4`

const WarrantyOptionCard = tw(
  FadeInSlideDown
)`bg-white flex items-center w-full my-2 py-2 px-4 border rounded`

const WarrantyOptionTitle = tw.div`text-lg flex-grow`

const WarrantyOptionPrice = tw.div`font-semibold inline text-lg`

const WarrantyOptionDescription = tw.div`text-gray-600 flex-grow truncate px-4`

const Header = tw(FadeInSlideDown)`flex items-center mb-3`

const CancelButton = tw(Button)`mt-6 ml-4`

const AddWarrantyButton = tw(Button)`h-fit-content`
