import React, { useState, useEffect, useMemo } from 'react'
import { useAlertStatistics } from 'hooks/statistics'
import colors from 'tailwindcss/colors'
import { CustomSelect, Icon, BarChart } from 'elements'
import { useNavigate } from 'hooks'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import {
  dateRangePresetToTooltipFormat,
  dateRangePresetToAxisFormat,
  dateRangeStateToURL,
} from 'utils'
import _ from 'lodash'
import { format } from 'date-fns'

type AlertsBarChartProps = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const AlertsBarChart = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: AlertsBarChartProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [severityFilter, setSeverityFilter] = useState<
    'all' | 'severity-1' | 'severity-2' | 'severity-3'
  >('all')
  const [barChartSeries, setBarChartSeries] = useState<Series[]>([
    { key: 'Severity 1', color: colors.amber[200], active: true },
    { key: 'Severity 2', color: colors.orange[300], active: true },
    { key: 'Severity 3', color: colors.red[400], active: true },
  ])
  const useAlertStatisticsQuery = useAlertStatistics({
    dateRangeState,
    organizationId,
    subscriberId,
  })

  // convert ECG stats data to nivo
  const nivoData = useMemo(() => {
    if (!useAlertStatisticsQuery.data) return []

    const rawNivoData = ECGAlertStatsToNivoBar({
      // @ts-expect-error bad swagger type
      data: useAlertStatisticsQuery.data,
      keys: barChartSeries.map((seriesItem) => seriesItem.key),
    })

    // filter out inactive series
    const displayedSeries = barChartSeries
      .filter((seriesItem) => seriesItem.active)
      .map((seriesItem) => seriesItem.key)

    // filter out empty data
    const filteredData = rawNivoData.filter((nivoItem) => {
      return displayedSeries.some(
        (series) => nivoItem[series] && nivoItem[series] > 0
      )
    })

    return filteredData
  }, [useAlertStatisticsQuery.data, barChartSeries])

  useEffect(() => {
    if (severityFilter === 'severity-1')
      return setBarChartSeries(
        barChartSeries.map((series) => {
          if (series.key === 'Severity 1') return { ...series, active: true }

          return { ...series, active: false }
        })
      )

    if (severityFilter === 'severity-2')
      return setBarChartSeries(
        barChartSeries.map((series) => {
          if (series.key === 'Severity 2') return { ...series, active: true }

          return { ...series, active: false }
        })
      )

    if (severityFilter === 'severity-3')
      return setBarChartSeries(
        barChartSeries.map((series) => {
          if (series.key === 'Severity 3') return { ...series, active: true }

          return { ...series, active: false }
        })
      )

    // severityFilter === 'all'
    return setBarChartSeries(
      barChartSeries.map((series) => {
        return { ...series, active: true }
      })
    )
  }, [severityFilter])

  return (
    <Card>
      <HeaderRow>
        <Header
          onClick={() =>
            navigate({
              pathname: subscriberId
                ? `/subscribers/${subscriberId}/alerts-activity`
                : '/vitals/alerts-activity',
              searchParams: {
                addressed: 'unaddressed',
                // filter alerts to vital sign readings to match what this chart shows
                alertTypes: 'vital-sign-reading',
                // if a severityFilter is specified send it in the url
                ...(severityFilter !== 'all'
                  ? { severities: severityFilter.slice(-1) }
                  : {}),
                ...dateRangeStateToURL(dateRangeState),
              },
            })
          }
        >
          <Title>{t('Total Vital Alerts by Severity')}</Title>
          <LinkIcon type="link" />
        </Header>
        <CustomSelectContainer>
          <CustomSelect<typeof severityFilter>
            className="w-full"
            options={[
              {
                label: 'All',
                value: 'all',
              },
              {
                label: 'Severity 1',
                value: 'severity-1',
              },
              {
                label: 'Severity 2',
                value: 'severity-2',
              },
              {
                label: 'Severity 3',
                value: 'severity-3',
              },
            ]}
            value={severityFilter}
            onChange={(e) => setSeverityFilter(e.value)}
            variant="thin"
          />
        </CustomSelectContainer>
      </HeaderRow>
      <BarChart
        data={nivoData}
        series={barChartSeries}
        indexBy={(data) => new Date(data.timestamp).toString()}
        YAxis={{
          label: 'Total Vital Alerts',
        }}
        XAxis={{
          format: (value) => {
            return format(
              new Date(value),
              dateRangePresetToAxisFormat[dateRangeState.preset] || 'PPP'
            )
          },
        }}
        tooltip={{
          unit: 'Alerts',
          x: {
            format: (value) => {
              return format(
                new Date(value),
                dateRangePresetToTooltipFormat[dateRangeState.preset] || 'PPP'
              )
            },
          },
        }}
        isLoading={useAlertStatisticsQuery.isLoading}
      />
    </Card>
  )
}

export default AlertsBarChart

const Card = tw.div`bg-white p-3 h-72`

const HeaderRow = tw.div`flex items-center justify-between`

const Header = tw.div`flex items-center cursor-pointer`

const Title = tw.h3`text-lg lg:mb-0 mr-1`

const CustomSelectContainer = tw.div`w-52`

const LinkIcon = tw(Icon)`w-3.5 h-3.5`

type ECGAlertStatsToNivoBar = (args: {
  data: ECGChartData[]
  keys: string[]
}) => { timestamp: string; [key: string]: string | number }[]

const ECGAlertStatsToNivoBar: ECGAlertStatsToNivoBar = ({ data, keys }) => {
  // find the longest series to use as our base
  const longestSeries = _.maxBy(data, (seriesItem) => seriesItem.data?.length)

  // combine all the series into 1 series
  const combinedSeries = longestSeries?.data?.map((currentSeries) => {
    return {
      timestamp: currentSeries.X,

      // generate attributes for each series
      // TODO: simply this once the API is fixed
      ...keys.reduce((accumulator, key) => {
        return {
          ...accumulator,
          [key]: 0,
        }
      }, {}),
    }
  })

  // remove all the duplicates
  // TODO: remove this once the API is fixed
  const uniqSeries = _.uniqBy(combinedSeries, 'timestamp')

  // calculate the sum of each series for each timestamp
  const calculatedSeries = uniqSeries.map((item) => {
    return {
      timestamp: item.timestamp,
      ...keys.reduce((accumulator, key, index) => {
        return {
          ...accumulator,
          [key]: _.sumBy(
            data[index].data?.filter(
              (nestedItem) => nestedItem.X === item.timestamp
            ),
            (data) => data.Y
          ),
        }
      }, {}),
    }
  })

  // filter out any entries that have no data
  const filteredSeries = calculatedSeries.filter((data) => {
    return Object.keys(data).some(
      (key) => key !== 'timestamp' && data[key as keyof typeof data]
    )
  })

  return filteredSeries
}
