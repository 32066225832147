import React, { useMemo } from 'react'
import { LoadingIcon, SimpleList, Dialog } from 'elements'
import { Button } from 'atlas'
import {
  useReminderMessageTranslationsQuery,
  useDeleteReminderMessageTranslationMutation,
} from 'hooks/reminders'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import tw from 'twin.macro'

type ECGAdminReminderMessagesListProps = {
  selectedLocale: { key: string; name: string }
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ECGAdminReminderMessagesList = ({
  selectedLocale,
  isDialogOpen,
  setIsDialogOpen,
}: ECGAdminReminderMessagesListProps) => {
  const { t } = useTranslation()
  const {
    data: reminderMessages,
    isLoading,
  } = useReminderMessageTranslationsQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
    selectedLocale.key
  )
  const {
    mutate: deleteReminderMessageTranslation,
  } = useDeleteReminderMessageTranslationMutation()

  const tableData: ReminderMessage[] = useMemo(() => {
    if (!reminderMessages) return []

    return _.sortBy(
      reminderMessages.items,
      (reminder) => reminder.messageType.displayOrder
    )
  }, [reminderMessages])

  if (isLoading) return <LoadingIcon />

  return (
    <RemindersListContainer>
      <SimpleList
        data={tableData}
        baseDelay={0.25}
        emptyProps={{
          title: t('No Reminder Messages Created Yet'),
        }}
        renderItem={(item) => (
          <SimpleList.Item key={item.id}>
            <ReminderItem>
              <ReminderMessageTitle>
                {item.messageType.name}
                <ReminderDescription>
                  {item.messageType.description}
                </ReminderDescription>
              </ReminderMessageTitle>

              <ReminderMessage>{item.message}</ReminderMessage>
            </ReminderItem>
          </SimpleList.Item>
        )}
      />
      <Dialog
        open={isDialogOpen}
        title={`${t('Delete')} ${selectedLocale.name} ${t('Translation')}`}
        content={
          <p>
            {t(
              'Are you sure you want to delete this translation? This will remove the translation from the ADDI platform'
            )}
          </p>
        }
        actions={
          <>
            <Button
              type="danger-filled"
              onClick={() => {
                if (!reminderMessages?.items) return setIsDialogOpen(false)

                deleteReminderMessageTranslation({
                  locale: selectedLocale.key,
                  orgId: '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
                  reminderMessageIds: reminderMessages.items.map(
                    (translation) => translation.id || ''
                  ),
                })

                return setIsDialogOpen(false)
              }}
            >
              {t('Delete')}
            </Button>

            <Button onClick={() => setIsDialogOpen(false)} type="secondary">
              {t('Cancel')}
            </Button>
          </>
        }
      />
    </RemindersListContainer>
  )
}

export default ECGAdminReminderMessagesList

const RemindersListContainer = tw.div`w-full mb-8`

const ReminderItem = tw.div`grid grid-cols-6  mx-4 items-center`

const ReminderMessageTitle = tw.p`col-span-2 text-lg`

const ReminderDescription = tw.p`opacity-50 text-xs`

const ReminderMessage = tw.p`col-start-4 col-end-7 text-lg`
