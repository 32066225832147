import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const useAdminQuery = (adminId: string) => {
  const query = useQuery<GetAdminResponse, KyError>(['getAdmin', adminId], () =>
    getAdmin(adminId)
  )

  return query
}

export default useAdminQuery

// get admin info from an adminId
type getAdmin = (adminId: string) => Promise<GetAdminResponse>
const getAdmin: getAdmin = async (adminId) => {
  const result = await orgsApi
    .get(`api/ecg-admins/${adminId}`)
    .json<GetAdminResponse>()
  return result
}
