import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCheckoutOrderMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/checkout'],
    orderCheckoutArgs
  >({
    mutationFn: checkoutOrder,
    successMsg: {
      message: t('Order checked out for provisioning'),
      showOn: 'onSuccess',
    },
    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['order', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          orderCheckoutArgs
        >((oldCache) => {
          if (!oldCache) return undefined

          return {
            ...oldCache,
            orderStatus: {
              id: 3,
              description: '',
              title: 'Provisioning In-Process',
            },
          }
        }),
      },
    ],
    additionalCachesToInvalidate: [
      ({ orderId }) => ['orderActivity', { orderId }],
    ],
  })

  return mutation
}

export default useCheckoutOrderMutation

type orderCheckoutArgs = { orderId: string }

const checkoutOrder = ({ orderId }: orderCheckoutArgs) =>
  ordersApi.post('/orders/{orderId}/checkout', {
    pathParams: { orderId },
  })
