import React, { useState } from 'react'
import { Button, Empty } from 'atlas'
import { Layout, ConfirmationDialogs } from 'elements'
import { useTranslation } from 'react-i18next'
import {
  useOrganizationQuery,
  useDeactivateOrganizationMutation,
  useActivateOrganizationMutation,
  useArchiveOrganizationMutation,
  useRestoreOrganizationMutation,
  useDeleteOrganizationMutation,
  useOrganizationOnboardingQuery,
} from 'hooks/organizations'
import {
  CreateOrganizationForm,
  OrganizationDetailsContainer,
  AneltoCredentials,
  UpdateOrganizationForm,
} from 'components/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const OrganizationDetails = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const { mutate: deactivateOrganization } = useDeactivateOrganizationMutation()
  const { mutate: activateOrganization } = useActivateOrganizationMutation()
  const { mutate: archiveOrganization } = useArchiveOrganizationMutation()
  const { mutate: restoreOrganization } = useRestoreOrganizationMutation()
  const { mutate: deleteOrganization } = useDeleteOrganizationMutation()

  const { data: organizationDetails, isLoading } = useOrganizationQuery(
    assumedOrganizationRole?.orgID
  )
  const [isAneltoFormOpen, setIsAneltoFormOpen] = useState<boolean>(false)
  const [
    isUpdateOrganizationFormOpen,
    setUpdateIsOrganizationFormOpen,
  ] = useState<boolean>(false)
  const [
    isCreateChildOrganizationFormOpen,
    setIsCreateChildOrganizationFormOpen,
  ] = useState(false)
  const [
    isActivateOrganizationFormOpen,
    setIsActivateOrganizationFormOpen,
  ] = useState(false)
  const [
    isDeactivateOrganizationFormOpen,
    setIsDeactivateOrganizationFormOpen,
  ] = useState(false)
  const [
    isArchiveOrganizationFormOpen,
    setIsArchiveOrganizationFormOpen,
  ] = useState(false)
  const [
    isRestoreOrganizationFormOpen,
    setIsRestoreOrganizationFormOpen,
  ] = useState(false)
  const [
    isDeleteOrganizationFormOpen,
    setIsDeleteOrganizationFormOpen,
  ] = useState(false)

  const organizationOnboardingQuery = useOrganizationOnboardingQuery({
    organizationId: assumedOrganizationRole?.orgID,
  })

  const confirmations = [
    // activate organization
    {
      setIsOpen: setIsActivateOrganizationFormOpen,
      isOpen: isActivateOrganizationFormOpen,
      title:
        t('Activate "') +
        organizationDetails?.businessName +
        t('" Organization'),
      content: (
        <p>{t('Are you sure you want to activate this organization?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            if (organizationDetails)
              activateOrganization({
                organization: organizationDetails,
              })
            setIsActivateOrganizationFormOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },

    // deactivate organization
    {
      setIsOpen: setIsDeactivateOrganizationFormOpen,
      isOpen: isDeactivateOrganizationFormOpen,
      title:
        t('Deactivate "') +
        organizationDetails?.businessName +
        t('" Organization'),
      content: (
        <p>{t('Are you sure you want to deactivate this organization?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            if (organizationDetails)
              deactivateOrganization({
                organization: organizationDetails,
              })
            setIsDeactivateOrganizationFormOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },

    // archive organization
    {
      setIsOpen: setIsArchiveOrganizationFormOpen,
      isOpen: isArchiveOrganizationFormOpen,
      title:
        t('Archive "') +
        organizationDetails?.businessName +
        t('" Organization'),
      content: (
        <p>{t('Are you sure you want to archive this organization?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            if (organizationDetails)
              archiveOrganization({
                organization: organizationDetails,
              })
            setIsArchiveOrganizationFormOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },

    // restore organization
    {
      setIsOpen: setIsRestoreOrganizationFormOpen,
      isOpen: isRestoreOrganizationFormOpen,
      title:
        t('Restore "') +
        organizationDetails?.businessName +
        t('" Organization'),
      content: (
        <p>{t('Are you sure you want to restore this organization?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            if (organizationDetails)
              restoreOrganization({
                organization: organizationDetails,
              })
            setIsRestoreOrganizationFormOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },

    // delete organization
    {
      setIsOpen: setIsDeleteOrganizationFormOpen,
      isOpen: isDeleteOrganizationFormOpen,
      title:
        t('Delete "') + organizationDetails?.businessName + t('" Organization'),
      content: <p>{t('Are you sure you want to delete this organization?')}</p>,
      actions: (
        <Button
          onClick={() => {
            if (organizationDetails)
              deleteOrganization({
                organization: organizationDetails,
              })
            setIsDeleteOrganizationFormOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <Layout
      title={t('Organization')}
      description={t(
        'View all existing organizations and add child organizations to your network.'
      )}
      controls={[
        // hide these options if the organization is archived
        ...(organizationDetails?.status !== 'Archived'
          ? [
              {
                label: t('Create Child Organization'),
                onClick: () => setIsCreateChildOrganizationFormOpen(true),
              },
              {
                label: t('Edit'),
                onClick: () => setUpdateIsOrganizationFormOpen(true),
              },
              ...(organizationDetails?.status === 'Active'
                ? [
                    {
                      label: t('Deactivate'),
                      onClick: () => setIsDeactivateOrganizationFormOpen(true),
                    },
                  ]
                : [
                    {
                      label: t('Activate'),
                      onClick: () => setIsActivateOrganizationFormOpen(true),
                    },
                  ]),
              {
                label: t('Archive'),
                onClick: () => setIsArchiveOrganizationFormOpen(true),
              },
            ]
          : [
              {
                label: t('Restore'),
                onClick: () => setIsRestoreOrganizationFormOpen(true),
              },
              {
                label: t('Delete'),
                onClick: () => setIsDeleteOrganizationFormOpen(true),
              },
            ]),
        {
          label: t('Set Anelto Credentials'),
          onClick: () => setIsAneltoFormOpen(true),
        },
      ]}
      isUnauthorized={!assumedOrganizationRole}
      isLoading={isLoading || organizationOnboardingQuery.isLoading}
      isEmpty={!organizationDetails}
      emptyComponent={
        <Empty
          title={t('Organization Not Found')}
          description={t(
            `Either this organization doesn't exist or you don't have access to view it`
          )}
        />
      }
    >
      <OrganizationDetailsContainer
        // @ts-expect-error the layout guards the type here
        organization={organizationDetails}
      />
      <ConfirmationDialogs confirmations={confirmations} />
      <UpdateOrganizationForm
        isFormOpen={isUpdateOrganizationFormOpen}
        setIsFormOpen={setUpdateIsOrganizationFormOpen}
        // @ts-expect-error the layout guards the type here
        organization={organizationDetails}
      />
      <CreateOrganizationForm
        isFormOpen={isCreateChildOrganizationFormOpen}
        setIsFormOpen={setIsCreateChildOrganizationFormOpen}
      />
      <AneltoCredentials
        isAneltoFormOpen={isAneltoFormOpen}
        setIsAneltoFormOpen={setIsAneltoFormOpen}
        organizationId={organizationDetails?.id}
      />
    </Layout>
  )
}

export default OrganizationDetails
