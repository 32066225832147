import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { vitalsApi, handleQueryError, success } from 'utils'

// used to update default reminder messages
const useUpdateReminderMessageTranslationsMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}'][],
    KyError,
    updateReminderMessageTranslationArgs,
    | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
    | undefined
  >(updateReminderMessageTranslation, {
    onMutate: (data) => {
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
      >(['getReminderMessageTranslations', data.orgId, data.localeKey])

      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(
        ['getReminderMessageTranslations', data.orgId, data.localeKey],
        (oldData) => {
          if (!oldData?.items) return undefined
          return {
            items: oldData.items.map((reminderMessage) => {
              return {
                ...reminderMessage,
                message:
                  data.messages.find(
                    (newReminder) =>
                      newReminder.messageTypeId ===
                      reminderMessage.messageType.id
                  )?.message || '',
              }
            }),
          }
        }
      )
      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Translation updated') })
    },
    onError: (error, { orgId, localeKey }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(['getReminderMessageTranslations', orgId, localeKey], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      queryCache.invalidateQueries([
        'getReminderMessageTranslations',
        args.orgId,
        args.localeKey,
      ])
    },
  })

  return mutation
}

export default useUpdateReminderMessageTranslationsMutation

type updateReminderMessageTranslationArgs = {
  orgId?: string
  localeKey: string
  messages: Array<{
    messageTypeId: string
    message: string
    reminderMessageId?: string
  }>
}

// update default reminder
const updateReminderMessageTranslation = async (
  data: updateReminderMessageTranslationArgs
) => {
  // update each reminder message
  const response = await Promise.all(
    data.messages.map(async (message) => {
      const requestBody: VitalsAPIRequest['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']['body'] = {
        localeKey: data.localeKey,
        message: message.message,
        messageTypeId: message.messageTypeId,
      }
      return await vitalsApi
        .patch(
          `organization/${data.orgId}/reminder-messages/${message.reminderMessageId}`,
          {
            json: requestBody,
          }
        )
        .json<
          VitalsAPIResponse['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']
        >()
    })
  )
  return response
}
