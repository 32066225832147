import React from 'react'
import { Select, SelectItem } from 'elements'
import { useTranslation } from 'react-i18next'
import { SelectChangeEvent } from '@mui/material'

type FrequencyOfReminderPresetProps = {
  frequency: string
  name: string
  defaultValue: number
  label: string
  disabled?: boolean
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void
  className?: string
}

const FrequencyOfReminderPreset = ({
  frequency,
  name,
  defaultValue,
  label,
  disabled,
  onChange,
  className,
}: FrequencyOfReminderPresetProps) => {
  const { t } = useTranslation()
  return (
    <Select
      label={label}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      className={className}
    >
      <SelectItem value={1}>
        {frequency === 'daily' || !frequency ? t('Day') : t('Week')}
      </SelectItem>
      <SelectItem value={2}>
        {t('2')}&nbsp;
        {frequency === 'daily' || !frequency ? t('Day') : t('Week')}
        {t('s')}
      </SelectItem>
      <SelectItem value={3}>
        {t('3')}&nbsp;
        {frequency === 'daily' || !frequency ? t('Day') : t('Week')}
        {t('s')}
      </SelectItem>
      <SelectItem value={4}>
        {t('4')}&nbsp;
        {frequency === 'daily' || !frequency ? t('Day') : t('Week')}
        {t('s')}
      </SelectItem>
      <SelectItem value={5}>
        {t('5')}&nbsp;
        {frequency === 'daily' || !frequency ? t('Day') : t('Week')}
        {t('s')}
      </SelectItem>
      <SelectItem value={6}>
        {t('6')}&nbsp;
        {frequency === 'daily' || !frequency ? t('Day') : t('Week')}
        {t('s')}
      </SelectItem>
    </Select>
  )
}

export default FrequencyOfReminderPreset
