import React from 'react'
import { NavigationCard, Layout } from 'elements'
import { FadeInSlideDown } from 'animations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useNavigate, useDelay } from 'hooks'
import { useTranslation } from 'react-i18next'
import { OrgAdminVitals } from 'components/org-admin-vitals'
import tw from 'twin.macro'
import { useUserQuery } from 'hooks/user-profile'
import { isPresent } from 'utils'

const Vitals = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const delay = useDelay()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const userInfoQuery = useUserQuery()

  if (assumedOrganizationRole) return <OrgAdminVitals />

  return (
    <Layout
      title={t('Vitals')}
      description={t(
        'Manage all aspects of the vitals module from this central location.'
      )}
    >
      <VitalsContainer>
        {userInfoQuery.data?.roles.includes('org-admin') ? (
          <FadeInSlideDown delay={delay()}>
            <NavigationCard
              icon="vitals"
              iconColor="yellow"
              label={t('Vitals Activity')}
              onClick={() => navigate('vitals-activity')}
              description={`${t(
                isPresent(assumedOrganizationRole)
                  ? 'Monitor all vitals signs activity for this organization'
                  : 'Monitor vitals signs activity for all subscribers'
              )}.`}
            />
          </FadeInSlideDown>
        ) : null}

        {userInfoQuery.data?.roles.includes('ecg-admin') ? (
          <FadeInSlideDown delay={delay()}>
            <NavigationCard
              icon="vitals"
              iconColor="yellow"
              label={t('Vital Signs')}
              onClick={() => navigate('/vitals/vital-signs')}
              description={`${t(
                'Create and manage vital signs available to onboarded organizations'
              )}.`}
            />
          </FadeInSlideDown>
        ) : null}

        {userInfoQuery.data?.roles.includes('ecg-admin') ? (
          <FadeInSlideDown delay={delay()}>
            <NavigationCard
              icon="reminders"
              iconColor="purple"
              label={t('Reminder Messages')}
              onClick={() => navigate('/vitals/reminder-messages')}
              description={`${t(
                'Create and manage reminder messages here'
              )}. ${t(
                'Manage translations to allow your Subscribers to receive messages in multiple languages'
              )}.`}
            />
          </FadeInSlideDown>
        ) : null}

        {userInfoQuery.data?.roles.includes('org-admin') ? (
          <FadeInSlideDown delay={delay()}>
            <NavigationCard
              icon="alert"
              iconColor="red"
              label={t('Alerts Activity')}
              onClick={() => navigate('alerts-activity')}
              description={`${t(
                isPresent(assumedOrganizationRole)
                  ? 'Review, sort, and address any alerts generated by Subscribers and their devices for this organization'
                  : 'Review, sort, and address any alerts generated by Subscribers and their devices'
              )}.`}
            />
          </FadeInSlideDown>
        ) : null}
      </VitalsContainer>
    </Layout>
  )
}

export default Vitals

const VitalsContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
