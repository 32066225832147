import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useContractQuery } from 'hooks/contracts'
import { useParams } from 'react-router'
import { useSearchParam } from 'react-use'
import { DeviceOrServiceForm } from 'components/contracts/line-items'

const SpecificProduct = () => {
  const { t } = useTranslation()

  const { contractId } = useParams()
  const organizationId = useSearchParam('organizationId')
  const lineItemId = useSearchParam('lineItemId')

  const contractQuery = useContractQuery({
    organizationId: organizationId || '',
    contractId,
  })

  const lineItem = contractQuery.data?.lineItems?.find(
    (lineItem) => lineItem.id === lineItemId
  )

  return (
    <Layout
      title={lineItem ? t('Device or Service') : t('Add Device or Service')}
      description={
        lineItem
          ? 'Edit a specific product for this contract'
          : t('Create a specific product for this contract') + '.'
      }
      breadcrumbs={[
        { name: t('Contracts'), route: '../../../' },
        {
          name:
            contractQuery.data?.descriptionInfo?.title || t('Contract Details'),
          route: '../../',
        },
        { name: t('Add Line Item'), route: '../' },
        {
          name: lineItem ? t('Device or Service') : t('Add Device or Service'),
        },
      ]}
      isLoading={contractQuery.isLoading}
    >
      <DeviceOrServiceForm />
    </Layout>
  )
}

export default SpecificProduct
