import React from 'react'
import tw, { styled } from 'twin.macro'

export type ChipProps = {
  /**
   * The contents of the Chip.
   */
  children: React.ReactNode

  /**
   * Color preset of the Chip. This only effects the style of the Chip, no functionality is changed.
   */
  color: keyof typeof colors

  /**
   * Appends to the className of the outer-most element of the component. *Note: never directly use this prop, always opt for styled-components.*
   */
  className?: string

  /**
   * Callback function called when the Button is clicked. *Note: this also adds "clickable" styles.*
   */
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

const Chip = ({ children, color, className, onClick }: ChipProps) => {
  return (
    <Container color={color} className={className} onClick={onClick}>
      {children}
    </Container>
  )
}

export default Chip

export const colors = {
  red: tw`bg-red-200 text-red-800`,
  purple: tw`bg-purple-200 text-purple-800`,
  green: tw`bg-green-200 text-green-800`,
  yellow: tw`bg-amber-200 text-amber-800`,
  cyan: tw`bg-cyan-200 text-cyan-800`,
  blue: tw`bg-blue-200 text-blue-800`,
  gray: tw`bg-gray-200 text-gray-800`,
  orange: tw`bg-orange-200 text-orange-800`,
  lightblue: tw`bg-blue-100 text-blue-600`,
}

const Container = styled.span<{
  color: keyof typeof colors
  className?: string
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}>`
  ${tw`px-2 rounded-full text-xs inline-flex font-semibold leading-5 justify-between align-middle whitespace-nowrap`}
  ${({ color }) => colors[color]}
  ${({ className }) => className}
  ${({ onClick }) => onClick && tw`cursor-pointer`}
`
