import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useWebhookSubscriptionQuery,
  useDeactivateWebhookSubscriptionMutation,
  useActivateWebhookSubscriptionMutation,
  useDeleteWebhookSubscriptionMutation,
  useWebhookActivityQuery,
  useArchiveWebhookSubscriptionMutation,
  useRestoreWebhookSubscriptionMutation,
} from 'hooks/settings-webhooks'
import {
  Layout,
  InformationCard,
  ConfirmationDialogs,
  EntityStatusChip,
} from 'elements'
import { Chip, Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import { useDelay } from 'hooks'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  WebhookEventsList,
  CreateEditWebhooksForm,
  SecretKeyDisplay,
  WebhookActivityList,
} from 'components/settings-webhooks'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const WebhookDetails = () => {
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )
  const {
    mutate: archiveWebhookSubscription,
  } = useArchiveWebhookSubscriptionMutation()
  const {
    mutate: restoreWebhookSubscription,
  } = useRestoreWebhookSubscriptionMutation()
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)

  const { subscriptionId } = useParams()
  const { data: webhookSubscription, isLoading } = useWebhookSubscriptionQuery({
    subscriptionId,
  })
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const [
    isCreateEditWebhooksFormOpen,
    setIsCreateEditWebhooksFormOpen,
  ] = useState(false)
  const {
    mutate: deactivateWebhookSubscription,
  } = useDeactivateWebhookSubscriptionMutation()
  const {
    mutate: deleteWebhookSubscription,
  } = useDeleteWebhookSubscriptionMutation()
  const {
    mutate: activateWebhookSubscription,
  } = useActivateWebhookSubscriptionMutation()
  const { data: webhookActivity } = useWebhookActivityQuery({ subscriptionId })
  const { t } = useTranslation()
  const delay = useDelay()
  const navigate = useNavigate()

  const confirmations = [
    // Activate Webhook
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t('Activate "') + webhookSubscription?.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to activate this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            if (webhookSubscription)
              activateWebhookSubscription({ webhookSubscription })
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
    // Deactivate Webhook
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t('Deactivate "') + webhookSubscription?.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to deactivate this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            if (webhookSubscription)
              deactivateWebhookSubscription({ webhookSubscription })
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Delete Webhook
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t('Delete "') + webhookSubscription?.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to delete this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            if (webhookSubscription)
              deleteWebhookSubscription({
                organizationId: webhookSubscription.organizationId,
                subscriptionId: webhookSubscription.id,
              })
            setIsDeleteDialogOpen(false)
            return navigate('..')
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
    // Archive Webhook
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t('Archive "') + webhookSubscription?.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to archive this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            if (webhookSubscription)
              archiveWebhookSubscription({
                organizationId: webhookSubscription.organizationId,
                subscriptionId: webhookSubscription.id,
              })
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
    // Restore Webhook
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t('Restore "') + webhookSubscription?.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to archive this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            if (webhookSubscription)
              restoreWebhookSubscription({
                organizationId: webhookSubscription.organizationId,
                subscriptionId: webhookSubscription.id,
              })
            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
  ]

  return (
    <Layout
      title={t('Webhook Details')}
      description={t(
        'Manage webhook details and review webhook-specific activity.'
      )}
      isUnauthorized={!assumedOrganizationRole}
      isLoading={isLoading || !webhookSubscription}
      breadcrumbs={[
        { name: t('Organization Settings'), route: '/organization-settings' },
        { name: t('Webhooks'), route: '/organization-settings/webhooks' },
        { name: t('Webhook Details') },
      ]}
      controls={[
        ...(!webhookSubscription?.archiveInfo.archived
          ? [
              {
                label: t('Edit'),
                onClick: () => setIsCreateEditWebhooksFormOpen(true),
              },
              ...(webhookSubscription?.activeInfo.active
                ? [
                    {
                      label: t('Deactivate'),
                      onClick: () => setIsDeactivateDialogOpen(true),
                    },
                  ]
                : [
                    {
                      label: t('Activate'),
                      onClick: () => setIsActivateDialogOpen(true),
                    },
                  ]),
              {
                label: t('Archive'),
                onClick: () => setIsArchiveDialogOpen(true),
              },
            ]
          : [
              {
                label: t('Restore'),
                onClick: () => setIsRestoreDialogOpen(true),
              },
              {
                label: t('Delete'),
                onClick: () => setIsDeleteDialogOpen(true),
              },
            ]),
      ]}
    >
      <FadeInSlideDown delay={delay()}>
        <InformationCard className="mb-8">
          <InformationCard.Item
            label={t('URL')}
            value={webhookSubscription?.postUri}
          />

          <InformationCard.Item
            label={t('Status')}
            value={
              <EntityStatusChip
                isActive={!!webhookSubscription?.activeInfo.active}
                isArchived={!!webhookSubscription?.archiveInfo.archived}
              />
            }
          />

          <InformationCard.Item
            label={t('Mode')}
            value={
              webhookSubscription?.isDevelopment ? (
                <Chip color="cyan">{t('DEV')}</Chip>
              ) : (
                <Chip color="green">{t('LIVE')}</Chip>
              )
            }
          />

          <InformationCard.Item
            label={t('Version')}
            value={webhookSubscription?.version}
          />
        </InformationCard>
      </FadeInSlideDown>

      <FadeInSlideDown delay={delay()}>
        {/* @ts-expect-error Layout ensures this isn't undefined */}
        <WebhookEventsList webhookSubscription={webhookSubscription} />
      </FadeInSlideDown>

      <FadeInSlideDown delay={delay()}>
        <SecretKeyDisplay
          disabled={webhookSubscription?.archiveInfo.archived}
          subscriptionId={subscriptionId}
        />
      </FadeInSlideDown>

      <FadeInSlideDown delay={delay()}>
        <Title>{t('Webhook Activity')}</Title>
        <WebhookListContainer>
          <WebhookActivityList webhookActivity={webhookActivity?.items || []} />
        </WebhookListContainer>
      </FadeInSlideDown>

      <CreateEditWebhooksForm
        isFormOpen={isCreateEditWebhooksFormOpen}
        setIsFormOpen={setIsCreateEditWebhooksFormOpen}
        webhookSubscriptionToEdit={webhookSubscription}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </Layout>
  )
}

export default WebhookDetails

const Title = tw.h3`text-xl font-semibold mb-4`

const WebhookListContainer = tw.div`h-96 flex flex-col`
