import _ from 'lodash'

/**
 * Converts a phone number into a hidden phone number for Hipaa guide lines
 * @param phoneNumber - phoneNumber string
 * @returns A string of original phoneNumber param, but hidden other than last four digits
 */

type hidePhoneNumberArgs = {
  phoneNumber: string
}

const hidePhoneNumber = ({ phoneNumber }: hidePhoneNumberArgs) => {
  if (phoneNumber) {
    const last4 = phoneNumber.substring(phoneNumber.length - 4)

    const firstHalf = _.split(phoneNumber, last4)[0]

    const hide = _.replace(
      phoneNumber,
      firstHalf,
      _.repeat('*', firstHalf.length)
    )

    return hide
  }
  return phoneNumber
}
export default hidePhoneNumber
