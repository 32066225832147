import React, { useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { useOrganizationMemberStatistics } from 'hooks/statistics'
import { useOrganizationOnboardingQuery } from 'hooks/organizations'
import { Layout, Statistic } from 'elements'
import { DateRangePicker, Alert } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTranslation } from 'react-i18next'
import { useDelay, useNavigate } from 'hooks'
import {
  AlertsBarChart,
  AlertsLineChart,
  TotalAlertsByCategoryBarChart,
  TotalObservationsByTypeBarChart,
  AddressedAlertsPieChart,
} from 'components/statistics'
import { dateRangePresetToDateRange } from 'utils'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'

const OrganizationOverview = () => {
  const navigate = useNavigate()
  const [dateRangeState, setDateRangeState] = useState<DateRangeState>({
    preset: 'this-week',
    value: dateRangePresetToDateRange('this-week'),
  })
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const {
    data: organizationMemberStatistics,
    isLoading: isLoadingOrganizationMemberStatistics,
  } = useOrganizationMemberStatistics()
  const { t } = useTranslation()
  const delay = useDelay()
  const organizationOnboardingQuery = useOrganizationOnboardingQuery({
    organizationId: assumedOrganizationRole?.orgID,
  })

  return (
    <Layout
      title={t('Organization Overview')}
      description={t('Here’s a quick snapshot of your organization’s metrics.')}
      isLoading={
        isLoadingOrganizationMemberStatistics ||
        organizationOnboardingQuery.isLoading
      }
    >
      {_.find(organizationOnboardingQuery.data, (value) => !value.complete) ? (
        <FadeInSlideDown delay={delay()}>
          <Alert
            message={t('Organization setup incomplete')}
            type="warning"
            onClick={() => {
              navigate('organization/setup')
            }}
          />
        </FadeInSlideDown>
      ) : null}

      <StatisticsGrid>
        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="organizations"
            iconColor="yellow"
            label="Total Organizations"
            value={organizationMemberStatistics?.organizations.total}
            to="/organization"
          />
        </FadeInSlideDown>

        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="subscribers"
            iconColor="purple"
            label="Total Subscribers"
            value={organizationMemberStatistics?.subscribers.total}
            to="/subscribers"
          />{' '}
        </FadeInSlideDown>

        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="subscribers"
            iconColor="red"
            label="Total Staff Members"
            value={organizationMemberStatistics?.staffMembers.total}
            to="/staff"
          />
        </FadeInSlideDown>
      </StatisticsGrid>

      <StickyHeader>
        <FadeInSlideDown delay={delay()}>
          <ReportsSectionHeader>
            <SectionTitle>Reports</SectionTitle>

            <DateRangePicker
              value={dateRangeState}
              onChange={(preset, [startDate, endDate]) =>
                setDateRangeState({ preset, value: [startDate, endDate] })
              }
            />
          </ReportsSectionHeader>
        </FadeInSlideDown>
      </StickyHeader>

      <ReportsGridTwoColumn delay={delay()}>
        <ReportsGridItem>
          <AlertsBarChart dateRangeState={dateRangeState} />
        </ReportsGridItem>
        <ReportsGridItem>
          <AlertsLineChart
            dateRangeState={dateRangeState}
            organizationId={assumedOrganizationRole?.orgID}
          />
        </ReportsGridItem>
      </ReportsGridTwoColumn>
      <ReportsGridThreeColumn delay={delay()}>
        <ReportsGridItem>
          <TotalAlertsByCategoryBarChart
            dateRangeState={dateRangeState}
            organizationId={assumedOrganizationRole?.orgID}
          />
        </ReportsGridItem>
        <ReportsGridItem>
          <TotalObservationsByTypeBarChart
            dateRangeState={dateRangeState}
            organizationId={assumedOrganizationRole?.orgID}
          />
        </ReportsGridItem>
        <ReportsGridItem>
          <AddressedAlertsPieChart
            dateRangeState={dateRangeState}
            organizationId={assumedOrganizationRole?.orgID}
          />
        </ReportsGridItem>
      </ReportsGridThreeColumn>
    </Layout>
  )
}

export default OrganizationOverview

const StickyHeader = tw.div`sticky top-0 bg-gray-50 z-20`

const StatisticsGrid = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 z-30`

const ReportsSectionHeader = tw.div`justify-between items-center my-4`

const SectionTitle = tw.h2`text-2xl font-semibold mb-2`

const ReportsGridTwoColumn = styled(FadeInSlideDown)`
  ${tw`grid grid-cols-1 2xl:grid-cols-2 border-l border-t border-r border-gray-200 bg-gray-200`}
  grid-gap: 1px;
  padding-bottom: 1px;
`

const ReportsGridThreeColumn = styled(FadeInSlideDown)`
  ${tw`grid grid-cols-1 2xl:grid-cols-3 border-l border-r border-b border-gray-200 bg-gray-200`}
  grid-gap: 1px;
`

const ReportsGridItem = tw.div`bg-white`
