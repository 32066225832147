import { formatISO } from 'date-fns'

type generateDisableableArgs = {
  active?: boolean
  activeStatusChangedAt?: Date
}

const generateDisableable = ({
  active,
  activeStatusChangedAt,
}: generateDisableableArgs = {}) => {
  const result: Disableable = {
    activeInfo: {
      active: active || true,
      activeStatusChangedAt: activeStatusChangedAt
        ? formatISO(activeStatusChangedAt)
        : '',
    },
  }

  return result
}

export default generateDisableable
