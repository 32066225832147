import React, { useState } from 'react'
import { InformationCard, Icon } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import EditDeliveryInformationForm from './EditDeliveryInformationForm'

type AlertDeliveryRuleDetailsProps = {
  alertDeliveryRule: AlertDeliveryRule
}

const AlertDeliveryRuleDetails = ({
  alertDeliveryRule,
}: AlertDeliveryRuleDetailsProps) => {
  const { t } = useTranslation()

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  return (
    <>
      <Header>
        <Title>{t('Delivery Information')}</Title>
        <EditIcon
          type="edit"
          onClick={() => setIsFormOpen(true)}
          data-testid="delivery-information-edit"
        />
      </Header>
      <InformationCard size="md">
        <InformationCard.Item
          label={t('Email Subject')}
          value={alertDeliveryRule.delivery.message.subject}
        />

        <InformationCard.Item
          label={t('Email Message')}
          value={alertDeliveryRule.delivery.message.message}
        />
      </InformationCard>
      <EditDeliveryInformationForm
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        alertDeliveryRule={alertDeliveryRule}
      />
    </>
  )
}

export default AlertDeliveryRuleDetails

const Header = tw.div`flex items-center`

const Title = tw.h3`text-xl font-semibold mb-2`

const EditIcon = tw(Icon)`text-gray-600 hover:text-gray-900 mb-2 ml-2`
