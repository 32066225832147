import React, { useMemo } from 'react'
import { ResponsiveLine, Layer, SliceTooltipProps } from '@nivo/line'
import { Margin } from '@nivo/core'
import {
  formatDate,
  lineChartDataToDatesArray,
  ecgChartDataToLineChartData,
} from 'utils'
import { LegendProps } from '@nivo/legends'
import { LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { isMatch } from 'date-fns'
import { Scale } from '@nivo/scales'

type LineChartProps = {
  data: Array<Array<{ [key: string]: unknown; X: string; Y: number }>>
  series: Series[]
  YAxis?: {
    label?: string
  }
  XAxis?: {
    format?: string
  }
  tooltip?: {
    unit?: string
    x?: {
      format?: string
    }
    renderMetadata?: (
      arg: React.PropsWithChildren<SliceTooltipProps>
    ) => React.ReactNode
  }
  dateRangePreset?: DateRangePreset
  isLoading?: boolean
  curve?:
    | 'linear'
    | 'monotoneX'
    | 'basis'
    | 'cardinal'
    | 'catmullRom'
    | 'monotoneY'
    | 'natural'
    | 'step'
    | 'stepAfter'
    | 'stepBefore'
  margin?: Partial<Margin>
  legends?: LegendProps[]
  layers?: Layer[]
  yScale?: Scale
}

const LineChart = ({
  data,
  series,
  YAxis,
  XAxis,
  tooltip,
  dateRangePreset,
  isLoading,
  curve,
  margin = { top: 20, right: 105, bottom: 55, left: 50 },
  legends = [
    {
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 20,
      symbolShape: 'circle',
    },
  ],
  layers,
  yScale,
}: LineChartProps) => {
  const { t } = useTranslation()
  const filteredData = useMemo(
    () => data.filter((_, index) => series[index].active),
    [data, series]
  )

  const filteredSeries = useMemo(
    () => series.filter((seriesItem) => seriesItem.active),
    [data, series]
  )

  // check if the raw API data is grouped by month
  const isGroupedByMonth = isMatch('' + data[0]?.[0]?.X, 'yyyy-m')

  // convert ECG stats data to nivo
  const nivoData = useMemo(
    () =>
      ecgChartDataToLineChartData({
        data: filteredData,
        series: filteredSeries,
        dateRangePreset,
      }),
    [filteredData, filteredSeries]
  )

  const xAxisTickValues = useMemo(() => {
    return dateRangePreset
      ? lineChartDataToDatesArray(
          nivoData[0]?.data,
          dateRangePreset,
          isGroupedByMonth
        )
      : []
  }, [nivoData])

  if (isLoading) return <LoadingIcon height="230px" />

  if (nivoData[0]?.data?.length === 0 || filteredData.length === 0)
    return (
      <LineChartEmpty
        title="No Data Found"
        description={t('Try changing the date range or filter criteria')}
      />
    )

  return (
    <ResponsiveLine
      // @ts-expect-error TODO: fix this type
      colors={filteredSeries.map((seriesItem) => seriesItem.color)}
      data={nivoData}
      margin={margin}
      animate={false}
      xScale={{
        type: 'time',

        // don't set the min/max if there's only one data point
        // this makes the 1 data point view clean
        min: nivoData[0]?.data.length > 1 ? xAxisTickValues[0] : undefined,
        max:
          nivoData[0]?.data.length > 1
            ? xAxisTickValues[xAxisTickValues.length]
            : undefined,
      }}
      yScale={yScale}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: YAxis?.label,
        legendPosition: 'middle',
        legendOffset: -40,
        tickValues: 6,
      }}
      axisBottom={{
        format: (value) => formatDate(new Date(value), XAxis?.format || 'PPP'),
        tickValues: xAxisTickValues,
      }}
      legends={legends}
      pointSize={8}
      enableSlices="x"
      curve={curve}
      layers={layers}
      sliceTooltip={(e) => {
        return (
          <Tooltip>
            <Timestamp>
              {formatDate(
                new Date(e.slice.points[0].data.x),
                tooltip?.x?.format || 'PPP'
              )}
            </Timestamp>
            {filteredSeries.map((series, index) => {
              return (
                <TooltipData key={series.key}>
                  <TooltipColorIndicator
                    style={{ backgroundColor: e.slice.points[index].color }}
                  />
                  <p>
                    {e.slice.points[index].data.y}{' '}
                    <TooltipUnit>{tooltip?.unit || series.key}</TooltipUnit>
                  </p>
                </TooltipData>
              )
            })}
            {tooltip?.renderMetadata?.(e)}
          </Tooltip>
        )
      }}
    />
  )
}

export default LineChart

const Tooltip = tw.div`bg-white p-2 rounded shadow-lg border border-gray-200`

const Timestamp = tw.p`mb-1`

const TooltipData = tw.div`flex items-center`

const TooltipColorIndicator = tw.div`w-4 h-4 mr-2 rounded-full`

const TooltipUnit = tw.span`text-gray-500`

const LineChartEmpty = tw(Empty)`pt-4`
