import React from 'react'
import { InformationCard } from 'elements'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type AdminInfoCardProps = {
  adminInfo: Admin
}

const AdminInfoCard = ({ adminInfo }: AdminInfoCardProps) => {
  const { t } = useTranslation()

  return (
    <InformationCard>
      <InformationCard.Item
        label={t('Name')}
        value={`${adminInfo.person.firstName} ${adminInfo.person.lastName}`}
      />

      <InformationCard.Item
        label={t('Status')}
        value={
          adminInfo.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          )
        }
      />

      <InformationCard.Item
        label={t('Suffix')}
        value={adminInfo.person.suffix || '-'}
      />

      <InformationCard.Item
        label={t('Email')}
        value={adminInfo.person.emailAddress || '-'}
      />

      {adminInfo.person.party.phoneNumbers.length > 0 && (
        <InformationCard.Item
          label={t('Phone')}
          value={adminInfo.person.party.phoneNumbers[0].phoneNumber}
        />
      )}

      <InformationCard.Item
        label={t('ID')}
        value={<IDText>{adminInfo.id}</IDText>}
      />
    </InformationCard>
  )
}

export default AdminInfoCard

const IDText = tw.p`font-mono`
