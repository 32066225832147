import { LoadingIcon } from 'elements'
import React, { useState } from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import {
  useSubscriberQuery,
  useUpdateSubscriberMutation,
} from 'hooks/organizations'
import { useSearchParam } from 'react-use'
import { useSubscriberDetailsQuery } from 'hooks/user-subscriber'
import { useWizardStepForm } from 'context/wizard-form'
import { ChildStepContainer } from 'elements/WizardForm'
import { MetadataForm } from 'components/metadata'
import _ from 'lodash'
import {
  getMetadataErrorMessage,
  MetadataError,
} from 'components/metadata/MetadataForm'
import { useParams } from 'react-router'

const Metadata = () => {
  const { t } = useTranslation()

  const createSubscriberId = useSearchParam('subscriberId')
  const { subscriberId: editSubscriberId } = useParams()
  const subscriberId = createSubscriberId || editSubscriberId

  const subscriberDetailsQuery = useSubscriberDetailsQuery(subscriberId || '')
  const subscriberQuery = useSubscriberQuery(subscriberId || '')

  const [metadata, setMetadata] = useState<Array<[string, string]> | undefined>(
    subscriberQuery.data?.integrationMetadata
      ? _.orderBy(Object.entries(subscriberQuery.data.integrationMetadata))
      : undefined
  )
  const [metadataErrors, setMetadataErrors] = useState<MetadataError[]>()

  const updateSubscriberMutation = useUpdateSubscriberMutation()

  useWizardStepForm<
    { metadata?: Record<string, string> },
    { metadata?: Record<string, string> }
  >({
    validationFn: () => {
      // Metadata validation: For each row do validation on the key and value
      const newMetadataErrors = metadata
        ?.map(([key, value], index) => ({
          index,
          key: getMetadataErrorMessage(
            {
              index,
              key,
            },
            metadata
          ),
          value: getMetadataErrorMessage(
            {
              index,
              value,
            },
            metadata
          ),
        }))
        .filter(({ key, value }) => key || value)
      setMetadataErrors(newMetadataErrors)

      // if custom validation passes return true
      return !newMetadataErrors?.length
    },
    apiData: {
      metadata: subscriberQuery.data?.integrationMetadata,
    },
    customFields: {
      metadata: {
        value: metadata ? Object.fromEntries(metadata) : undefined,
        // Deep compare all properties in metadata for equality to also trigger on missing fields or new falsey fields
        customIsEqualFn: (
          valueA: Record<string, string> | undefined,
          valueB: Record<string, string> | undefined
        ) => _.isEqual(valueA, valueB),
      },
    },
    apiToForm: (apiData) => apiData,
    formSyncDependencies: [subscriberQuery.data],
    onSyncWithApi: ({ metadata }) =>
      setMetadata(metadata ? _.orderBy(Object.entries(metadata)) : undefined),
    submitFn: ({ metadata }) =>
      updateSubscriberMutation.mutateAsync({
        subscriberId,
        subscriberForm: {},
        oldMetadata: subscriberQuery.data?.integrationMetadata,
        newMetadata: metadata ? Object.entries(metadata) : undefined,
      }),
    dependencies: [subscriberId, metadata],
  })

  if (subscriberId && !subscriberDetailsQuery.data) return <LoadingIcon />

  // if subscriberId exists show the readonly version
  return (
    <ChildStepContainer>
      <MetadataTitle>{t('Subscriber Metadata')}</MetadataTitle>
      <MetadataForm
        metadata={metadata}
        setMetadata={setMetadata}
        originalMetadata={
          subscriberQuery.data?.integrationMetadata
            ? Object.entries(subscriberQuery.data.integrationMetadata)
            : undefined
        }
        errors={metadataErrors}
      />
    </ChildStepContainer>
  )
}

export default Metadata

const MetadataTitle = tw.h3`text-xl font-semibold mb-4`
