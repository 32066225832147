import { typedOrgsApi } from 'utils'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'

const useRemoveApplicationFromModuleMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/api/modules/{moduleId}/application/{applicationId}'],
    removeApplicationFromModuleArgs
  >({
    mutationFn: removeApplicationFromModule,

    successMsg: t('Application removed from module'),
    additionalCachesToInvalidate: ['getApplications'],
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          removeApplicationFromModuleArgs
        >((oldCache, { moduleId, applicationId }) =>
          oldCache?.items
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items.map((module) =>
                    module.id === moduleId
                      ? {
                          ...module,
                          applications: module.applications?.filter(
                            (application) => application.id !== applicationId
                          ),
                        }
                      : module
                  ),
                ],
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useRemoveApplicationFromModuleMutation

type removeApplicationFromModuleArgs = {
  applicationId: string
  moduleId: string
}

const removeApplicationFromModule = async ({
  applicationId,
  moduleId,
}: removeApplicationFromModuleArgs) =>
  typedOrgsApi.delete('/api/modules/{moduleId}/application/{applicationId}', {
    pathParams: { moduleId, applicationId },
  })
