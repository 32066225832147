import { Icon, RightPopup, TextField } from 'elements'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip } from 'atlas'
import {
  useCreateShippingOptionMutation,
  useUpdateShippingOptionMutation,
} from 'hooks/contracts'
import { useSearchParam } from 'react-use'
import { useParams } from 'react-router'

type ShippingOptionFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  shippingOptionToEdit: ContractShippingOption | undefined
}

const ShippingOptionForm = ({
  isFormOpen,
  setIsFormOpen,
  shippingOptionToEdit,
}: ShippingOptionFormProps) => {
  const { t } = useTranslation()

  const { contractId } = useParams()
  const organizationId = useSearchParam('organizationId')

  const { errors, control, handleSubmit, reset } = useForm<
    Omit<ContractShippingOptionForm, 'price'> & {
      price: Omit<Required<ContractShippingOptionForm>['price'], 'amount'> & {
        amount: string
      }
    }
  >()

  const createShippingOptionMutation = useCreateShippingOptionMutation()
  const updateShippingOptionMutation = useUpdateShippingOptionMutation()

  // reset form fields when form opens or shippingOptionToEdit changes
  useEffect(() => {
    if (isFormOpen) {
      reset(
        shippingOptionToEdit?.priceDescriptor
          ? {
              ...shippingOptionToEdit.priceDescriptor,
              price: {
                ...shippingOptionToEdit.priceDescriptor.price,
                amount: '' + shippingOptionToEdit.priceDescriptor.price?.amount,
              },
            }
          : {
              descriptionInfo: {
                title: '',
                description: '',
              },
              price: { amount: '' },
            }
      )
    }
  }, [shippingOptionToEdit, isFormOpen, organizationId])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={
        shippingOptionToEdit
          ? t('Edit Shipping Option')
          : t('Add Shipping Option')
      }
      controls={
        <>
          <Button
            onClick={handleSubmit((formData) => {
              shippingOptionToEdit
                ? updateShippingOptionMutation.mutate({
                    shippingOptionForm: {
                      price: {
                        flatOrPercent: 'Flat',
                        amount: +formData.price.amount,
                        interval: 'OneTime',
                      },
                      descriptionInfo: formData.descriptionInfo,
                    },
                    optionId: shippingOptionToEdit.id || '',
                    organizationId: organizationId || '',
                    contractId,
                  })
                : createShippingOptionMutation.mutate({
                    shippingOptionForm: {
                      ...formData,
                      price: {
                        flatOrPercent: 'Flat',
                        amount: +formData.price.amount,
                        interval: 'OneTime',
                      },
                    },
                    organizationId: organizationId || '',
                    contractId,
                  })

              setIsFormOpen(false)
            })}
          >
            {shippingOptionToEdit ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          {!shippingOptionToEdit ? (
            <Button
              onClick={handleSubmit((formData) => {
                createShippingOptionMutation.mutate({
                  shippingOptionForm: {
                    ...formData,
                    price: {
                      flatOrPercent: 'Flat',
                      amount: +formData.price.amount,
                      interval: 'OneTime',
                    },
                  },
                  organizationId: organizationId || '',
                  contractId,
                })

                reset({
                  descriptionInfo: {
                    title: '',
                    description: '',
                  },
                  price: {
                    amount: '',
                  },
                })
              })}
            >
              {t('Create & Add More')}
            </Button>
          ) : null}
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="descriptionInfo.title"
          label={t('Title')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Title is required') }}
          error={errors.descriptionInfo?.title?.message}
          className="flex-grow max-w-md mt-4"
          required
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="descriptionInfo.description"
          label={t('Description')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Description is required') }}
          error={errors.descriptionInfo?.description?.message}
          className="flex-grow max-w-xl mt-4"
          multiline
          rows={3}
          required
        />
        <InfoTooltip
          anchor="right"
          content={
            <TooltipText>{`${t(
              'Enter a meaningful description that will help customers understand the exact details of this shipping option'
            )}. ${t('Be as specific as possible')}`}</TooltipText>
          }
        >
          <InfoIcon type="info" />
        </InfoTooltip>
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="price.amount"
          label={t('Cost')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Cost is required') }}
          error={errors.price?.amount?.message}
          startAdornment="$"
          type="number"
          className="w-56 mt-4"
          required
        />
      </RowContainer>
    </RightPopup>
  )
}

export default ShippingOptionForm

const RowContainer = tw.div`flex`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`ml-2 mt-4 h-fit-content`

const TooltipText = tw.p`w-60`
