import {
  startOfDay,
  startOfWeek,
  endOfWeek,
  sub,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
} from 'date-fns'

type dateRangePresetToDateRange = (preset: DateRangePreset) => [Date, Date]

/**
 * turns a preset string to two dates representing the start and end of the preset range
 * @param preset
 * @returns Date tuple
 */
const dateRangePresetToDateRange: dateRangePresetToDateRange = (preset) => {
  const now = new Date()

  if (preset === 'today') return [startOfDay(now), now]

  if (preset === 'this-week') return [startOfWeek(now), now]

  if (preset === 'last-week')
    return [
      startOfWeek(sub(now, { weeks: 1 })),
      endOfWeek(sub(now, { weeks: 1 })),
    ]

  if (preset === 'this-month') return [startOfMonth(now), now]

  if (preset === 'last-month')
    return [
      startOfMonth(sub(now, { months: 1 })),
      endOfMonth(sub(now, { months: 1 })),
    ]

  if (preset === 'this-quarter') return [startOfQuarter(now), now]

  if (preset === 'last-quarter')
    return [
      startOfQuarter(sub(now, { months: 3 })),
      endOfQuarter(sub(now, { months: 3 })),
    ]

  if (preset === 'this-year') return [startOfYear(now), now]

  if (preset === 'last-year')
    return [
      startOfYear(sub(now, { years: 1 })),
      endOfYear(sub(now, { years: 1 })),
    ]

  return [now, now]
}

export default dateRangePresetToDateRange
