import useMutation from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useOrderConfirmMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/confirm'],
    ConfirmOrderArgs
  >({
    mutationFn: confirmOrder,
    successMsg: { message: t('Order Confirmed'), showOn: 'onSuccess' },
    additionalCachesToInvalidate: [({ orderId }) => ['order', { orderId }]],
  })

  return mutation
}

export default useOrderConfirmMutation

type ConfirmOrderArgs = {
  orderId: string
}

const confirmOrder = async ({ orderId }: ConfirmOrderArgs) =>
  ordersApi.post('/orders/{orderId}/confirm', {
    pathParams: { orderId },
    body: {
      authorizeECGToContactWithWelcomePackageDelivery: true,
      priceBreakdownForMonthlyAndOneTimeFees: true,
      makeChangesUntilShipmentIsReady: true,
    },
  })
