import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react'
import { useBlocker } from 'react-router'
import { Dialog } from 'elements'
import { useTranslation } from 'react-i18next'
import { Button } from 'atlas'

const NavigateWarningContext = createContext<NavigateWarningContext>(undefined)

type NavigateWarningProviderProps = {
  children: React.ReactNode
}

export const NavigateWarningProvider = ({
  children,
}: NavigateWarningProviderProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [onConfirm, setOnConfirm] = useState(() => () => {
    return
  })
  const [message, setMessageObj] = useState<Required<MessageObj>>({
    title: t('Leave Page?'),
    description: t('Are you sure you want to leave this page?'),
    confirmationText: t('Leave Page'),
  })

  const context = useMemo<NavigateWarningContext>(
    () => ({
      isOpen,
      message,
      onConfirm,
      setContext: ({ isOpen, onConfirm, message: customMessage }) => {
        setMessageObj({ ...message, ...customMessage })
        setIsOpen(isOpen)
        setOnConfirm(() => onConfirm)
      },
    }),
    [isOpen, message]
  )

  return (
    <NavigateWarningContext.Provider value={context}>
      {children}
      <NavigationWarningDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        message={message}
        onConfirm={onConfirm}
      />
    </NavigateWarningContext.Provider>
  )
}

export const useNavigateWarning = (
  shouldPrompt: boolean | (() => boolean) = true,
  message?: MessageObj
) => {
  const context = useContext(NavigateWarningContext)

  if (!context) {
    throw new Error(
      'useNavigateWarning must be used within a NavigateWarningProvider'
    )
  }

  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const retryFn = useRef(() => {
    return
  })
  useEffect(() => {
    if (confirmedNavigation) {
      retryFn.current()
    }
  }, [confirmedNavigation])

  useBlocker(
    ({ retry, location }) =>
      (typeof shouldPrompt === 'boolean'
        ? shouldPrompt
        : // skip showing warning if shouldPrompt is false or if the pathname is the same (only searchParams are changing)
          shouldPrompt()) && window.location.pathname !== location.pathname
        ? context.setContext({
            isOpen: true,
            onConfirm: () => {
              setConfirmedNavigation(true)
              retryFn.current = retry
            },
            ...(message ? { message } : {}),
          })
        : // call react-router function to complete the navigation
          retry(),
    !confirmedNavigation
  )
}

type NavigateWarningContext =
  | {
      /** toggles if dialog is open */
      isOpen: boolean
      /** object that describes title, description and button texts */
      message: MessageObj
      /** function to set isOpen & message */
      setContext: ({
        isOpen,
        onConfirm,
        message,
      }: {
        isOpen: boolean
        onConfirm: () => void
        message?: MessageObj
      }) => void
    }
  | undefined

type MessageObj = Partial<{
  title: string
  description: string
  confirmationText: string
}>

/** Dialog component shown when user tries to navigate away from page */
const NavigationWarningDialog = ({
  isOpen,
  setIsOpen,
  message: { title, description, confirmationText },
  onConfirm,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  message: { title: string; description: string; confirmationText: string }
  onConfirm: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={isOpen}
      title={title}
      content={description}
      actions={
        <>
          <Button
            onClick={() => {
              onConfirm()
              setIsOpen(false)
            }}
            type="danger-filled"
          >
            {confirmationText}
          </Button>
          <Button onClick={() => setIsOpen(false)} type="secondary">
            {t('Cancel')}
          </Button>
        </>
      }
    />
  )
}

export default NavigationWarningDialog
