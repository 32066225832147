import React from 'react'
import { useParams } from 'react-router-dom'
import { useAdminQuery, useAdminListQuery } from 'hooks/admins'
import { Layout, NavigationCard } from 'elements'
import { Empty } from 'atlas'
import { FadeInSlideDown } from 'animations'
import { AdminInfoCard, AdminSelect } from 'components/admins'
import { useTranslation } from 'react-i18next'
import { useDelay } from 'hooks'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const AdminDetails = () => {
  const { adminId } = useParams()
  const { t } = useTranslation()
  const delay = useDelay()
  const navigate = useNavigate()
  const { data: admin, isLoading } = useAdminQuery(adminId)
  const adminListQuery = useAdminListQuery()

  return (
    <Layout
      title={
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold">{t('Admin Details')}:</h1>
          <AdminSelect />
        </div>
      }
      isLoading={isLoading || adminListQuery.isLoading}
      isEmpty={!admin}
      emptyComponent={
        <Empty
          title={t('Admin Not Found')}
          description={t(
            `Either this admin doesn't exist or you don't have access to view them`
          )}
        />
      }
      description={t('Manage and review admin-related details.')}
      breadcrumbs={[
        {
          name: t('Admins'),
          route: '/admins',
        },
        {
          name: `${admin?.person.firstName} ${admin?.person.lastName}`,
        },
      ]}
    >
      <FadeInSlideDown delay={delay()}>
        {/* @ts-expect-error Layout will handle empty case */}
        <AdminInfoCard adminInfo={admin} />
      </FadeInSlideDown>

      <NavCardContainer>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="lock"
            iconColor="pink"
            label={t('Access Control')}
            onClick={() => navigate('access-control')}
            description={t(
              'Manage this specific admin’s access to data across the platform.'
            )}
          />
        </FadeInSlideDown>
      </NavCardContainer>
    </Layout>
  )
}

export default AdminDetails

const NavCardContainer = tw.div`grid gap-3 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-4`
