import { Icon, RightPopup, SearchBox } from 'elements'
import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { Button, Empty } from 'atlas'
import _ from 'lodash'
import { success } from 'utils'
import { useAPIQuery } from 'hooks'

type AddProductFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  productType:
    | (ContractLineItem['productTypeLineItem'] & {
        selectedProducts?: {
          id: string
          label: string
          selectedWarrantyId?: string | null
        }[]
      })
    | undefined
  setProductTypeSelectedProducts: (
    selectedProducts?: {
      id: string
      label: string
      selectedWarrantyId?: string | null
    }[]
  ) => void
}

const AddProductForm = ({
  isFormOpen,
  setIsFormOpen,
  productType,
  setProductTypeSelectedProducts,
}: AddProductFormProps) => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  const productsQuery = useAPIQuery('products')

  const availableProducts = productsQuery.data?.items?.filter(
    (product) =>
      product.id && productType?.availableProducts?.includes(product.id)
  )
  const displayedProducts = availableProducts?.filter(
    (product) =>
      product.title?.toLowerCase().includes(searchValue.toLowerCase()) &&
      !selectedProducts.map((product) => product.id).includes(product.id)
  )

  // reset form fields when form opens
  useEffect(() => {
    if (isFormOpen && productsQuery.data) {
      const selectedProductIds = productType?.selectedProducts?.map(
        (selectedProduct) => selectedProduct.id
      )
      setSearchValue('')
      setSelectedProducts(
        productsQuery.data.items?.filter(
          (product) => product.id && selectedProductIds?.includes(product.id)
        ) || []
      )
    }
  }, [isFormOpen, productsQuery.data])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={productType?.descriptionInfo?.title || ''}
      controls={
        <>
          <Button
            onClick={() => {
              setProductTypeSelectedProducts(
                selectedProducts.map((selectedProduct) => ({
                  id: selectedProduct.id || '',
                  label: selectedProduct.title || '',
                  selectedWarrantyId:
                    productType?.selectedProducts?.find(
                      (product) => product.id === selectedProduct.id
                    )?.selectedWarrantyId || null,
                }))
              )

              setIsFormOpen(false)

              success({ message: t('Updated Selected Products') })
            }}
            disabled={_.isEqual(
              productType?.selectedProducts?.map((product) => product.id) || [],
              selectedProducts.map((product) => product.id)
            )}
          >
            {productType?.selectedProducts?.length
              ? t('Update')
              : t('Add Products')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <Header>
        <Title>{t('Selected Products')}</Title>
        <SubTitle>{`${t('selected')} ${selectedProducts.length || '0'}/${
          productType?.allowedQuantity || '0'
        }`}</SubTitle>
      </Header>
      {selectedProducts.length > 0 ? (
        <Card>
          {_.orderBy(selectedProducts, 'title').map((selectedProduct) => (
            <SelectedProduct
              key={selectedProduct.id}
              onClick={() =>
                setSelectedProducts(
                  selectedProducts.filter(
                    (product) => product.id !== selectedProduct.id
                  )
                )
              }
            >
              <LeftContent>
                {/* {selectedProduct.raw_Json?.Item?.Images?.[0] ? (
                  <img
                    src={selectedProduct.raw_Json.Item.Images[0].Src}
                    alt={
                      selectedProduct.raw_Json.Item.Images[0].Alt || undefined
                    }
                  />
                ) : null} */}
              </LeftContent>

              <RightContent>
                <div>
                  <ProductTitle>{selectedProduct.title}</ProductTitle>
                </div>
                <CheckIcon type="check" />
              </RightContent>
            </SelectedProduct>
          ))}
        </Card>
      ) : (
        <ProductsEmpty title={t('No products selected')} />
      )}
      <SearchBox
        disabled={
          (availableProducts &&
            availableProducts.length <= 0 &&
            searchValue === '') ||
          !availableProducts
        }
        className="mb-2"
        placeholder={t('Search products')}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {displayedProducts && displayedProducts.length > 0 ? (
        <Card>
          {_.orderBy(displayedProducts, 'title').map((product) => (
            <Product
              key={product.id}
              onClick={() =>
                selectedProducts.length !== productType?.allowedQuantity &&
                setSelectedProducts([...selectedProducts, product])
              }
              disabled={
                selectedProducts.length === productType?.allowedQuantity
              }
            >
              <LeftContent>
                {/* {product.raw_Json?.Item?.Images?.[0] ? (
                  <img
                    src={product.raw_Json.Item.Images[0].Src}
                    alt={product.raw_Json.Item.Images[0].Alt || undefined}
                  />
                ) : null} */}
              </LeftContent>

              <RightContent>
                <div>
                  <ProductTitle>{product.title}</ProductTitle>
                </div>
              </RightContent>
            </Product>
          ))}
        </Card>
      ) : (
        <ProductsEmpty
          title={
            searchValue === '' && !selectedProducts.length
              ? t('No products exist for this product type')
              : t('No products found')
          }
        />
      )}
    </RightPopup>
  )
}

export default AddProductForm

const Header = tw.div`flex mb-2`

const Title = tw.h3`text-xl font-semibold flex-grow mt-2`

const SubTitle = tw.p`mt-2 text-lg text-gray-500`

const SelectedProduct = tw.div`py-2 px-5 bg-blue-100 hover:bg-blue-50 grid grid-cols-3 items-center justify-between cursor-pointer text-lg`

const Product = styled.div<{ disabled: boolean }>(({ disabled }) => [
  tw`py-2 px-5 grid grid-cols-3 items-center justify-between text-lg`,
  disabled
    ? tw`bg-gray-100 cursor-not-allowed`
    : tw`hover:bg-blue-50 cursor-pointer `,
])

const LeftContent = tw.div`flex items-center col-span-1`

const RightContent = tw.div`flex items-center justify-between col-span-2`

const ProductTitle = tw.p``

const Card = tw.div`bg-white border border-gray-200 rounded mb-2`

const ProductsEmpty = tw(
  Empty
)`flex-grow border-2 border-dashed rounded-lg mb-2`

const CheckIcon = tw(Icon)`w-6 h-6`
