import React, { useMemo } from 'react'
import { List, EntityLabel, CategoryFilter } from 'elements'
import { useTable, Column, useSortBy } from 'react-table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { formatDate } from 'utils'
import { useNavigate, useURLSyncState } from 'hooks'
import { useContractsQuery } from 'hooks/contracts'
import { useOrganizationListQuery } from 'hooks/organizations'
import tw from 'twin.macro'

const ContractsList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // sync contractFilters with url
  const [
    contractFilters,
    setContractFilters,
  ] = useURLSyncState<ContractFilters>({
    defaultValue: {
      skip: 0,
      organizationIds: [],
      searchTerm: '',
    },
    isListFilters: true,
  })

  const contractsQuery = useContractsQuery(contractFilters)
  const organizationsQuery = useOrganizationListQuery()

  const tableData: Contract[] = useMemo(() => {
    return contractsQuery.data?.items || []
  }, [contractsQuery.data])

  const columns: Column<Contract>[] = useMemo(
    () => [
      {
        id: 'TITLE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('TITLE'),
        accessor: (row) => row.descriptionInfo?.title || '-',
        disableSortBy: true,
      },
      {
        id: 'ORGANIZATION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ORGANIZATION'),
        accessor: (row) => {
          const rowOrg = organizationsQuery.data?.items.find(
            (org) => org.id === row.organizationId
          )
          return rowOrg ? (
            <EntityLabel
              name={rowOrg.businessName || ''}
              id={rowOrg.id}
              to={`/organizations/${rowOrg.id}`}
            />
          ) : (
            '-'
          )
        },
        Filter: (
          <CategoryFilter
            icon="organizations"
            label={t('Organizations')}
            categories={organizationsQuery.data?.items.map((organization) => ({
              label: organization.businessName,
              value: organization.id,
            }))}
            selectedCategories={organizationsQuery.data?.items
              .filter((organization) =>
                contractFilters.organizationIds?.includes(organization.id)
              )
              .map((organization) => ({
                label: organization.businessName,
                value: organization.id,
              }))}
            setSelectedCategories={(selectedOrgs) =>
              setContractFilters({
                ...contractFilters,
                organizationIds: selectedOrgs.map(
                  (selectedSubscriber) => selectedSubscriber.value
                ),
                skip: 0,
              })
            }
          />
        ),
        disableSortBy: true,
      },
      {
        id: 'CREATED AT',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('CREATED AT'),
        accessor: (row, index) => (
          <CreatedAtContainer key={index}>
            {row.audit?.createdAt
              ? formatDate(new Date(row.audit.createdAt), 'P')
              : '-'}
          </CreatedAtContainer>
        ),
        width: '0.5fr',
      },
    ],
    [i18next.language, contractFilters]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetSortBy: false,
      onRowClick: (row) => {
        if (row.original.id)
          navigate({
            pathname: row.original.id,
            searchParams: { organizationId: row.original.organizationId || '' },
          })
      },

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'CREATED AT',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      setSearchTerm={(searchTerm) =>
        setContractFilters({
          ...contractFilters,
          searchTerm,
          skip: 0,
        })
      }
      searchTerm={contractFilters.searchTerm}
      searchPlaceholder={`${t('Search')} ${
        contractsQuery.data?.totalRecords
          ? contractsQuery.data.totalRecords + ' '
          : ''
      }${t('contracts')}`}
      emptyDescription={t('Try changing your search term')}
      isLoading={contractsQuery.isFetching}
      paginatable
      paginationInfo={contractsQuery.data}
      onPageChange={(newPageNumber) => {
        setContractFilters({
          ...contractFilters,
          skip: newPageNumber - 1,
        })
      }}
    />
  )
}

export default ContractsList

const CreatedAtContainer = tw.div`flex`
