import React, { useState } from 'react'
import {
  CreateOrganizationForm,
  OrganizationList,
} from 'components/organizations'
import { useTranslation } from 'react-i18next'
import { Layout } from 'elements'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const Organizations = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isOrganizationFormOpen, setIsOrganizationFormOpen] = useState<boolean>(
    false
  )

  return (
    <Layout
      title={t('Organizations')}
      description={t(
        'View all onboarded organizations and add child organizations to the system.'
      )}
      controls={[
        {
          label: t('Create Organization'),
          onClick: () => setIsOrganizationFormOpen(true),
        },
        {
          label: t('View Archived Organizations'),
          onClick: () => navigate('archive'),
        },
      ]}
    >
      <OrgListContainer>
        <OrganizationList />
      </OrgListContainer>
      <CreateOrganizationForm
        isFormOpen={isOrganizationFormOpen}
        setIsFormOpen={setIsOrganizationFormOpen}
      />
    </Layout>
  )
}

export default Organizations

const OrgListContainer = tw.div`flex flex-col flex-grow`
