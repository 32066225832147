import { handleQueryError } from 'utils'
import { QueryFunction, useQuery } from 'react-query'

const usePinnedSubscribersQuery = () => {
  const query = useQuery<string[], KyError>(
    ['getPinnedSubscribers'],
    getPinnedSubscribers,
    {
      onError: (error) => {
        handleQueryError({ error })
      },
    }
  )
  return query
}

export default usePinnedSubscribersQuery

type getPinnedSubscribersArgs = QueryFunction<string[]>
const getPinnedSubscribers: getPinnedSubscribersArgs = async () => {
  const result: string[] = JSON.parse(localStorage.getItem('subPinned') || '[]')
  return result
}
