import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useActivatePolicyMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/policies/{policyId}/activate'],
    KyError,
    string
  >(activatePolicy, {
    onSuccess: (response, policyId) => {
      const policies:
        | OrgsAPIResponse['GET']['/api/policies']
        | undefined = queryCache.getQueryData(['getPolicies'])

      if (policies) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/policies'] = {
          ...policies,
          items:
            policies.items?.map((policy) => {
              if (policy.id === policyId)
                return {
                  ...policy,
                  activeInfo: { ...policy.activeInfo, active: true },
                }

              return policy
            }) || [],
        }
        // immediately update the policy list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/policies']>(
          ['getPolicies'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the policy list is in sync with the server
      queryCache.invalidateQueries('getPolicies')

      // notify the user
      success({ message: t('Policy activated') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useActivatePolicyMutation

const activatePolicy = async (policyId: string) => {
  const result = typedOrgsApi.post('/api/policies/{policyId}/activate', {
    pathParams: { policyId },
  })

  return result
}
