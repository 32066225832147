import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useUpdateVitalSignMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/vital-signs/{vitalSignId}'],
    KyError<ErrorResponse>,
    VitalSign
  >(updateVitalSign, {
    onSuccess: (_, updatedVitalSign) => {
      // Notify user of successful vital sign creation
      success({
        message: t('Vital sign updated'),
      })

      // Grab the old vital sign list from the queryCache
      const oldVitalSigns = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/vital-signs']
      >('ECGAdmin_getVitalSigns')

      // Update the vital sign in oldVitalSigns
      const newVitalSigns = oldVitalSigns?.items?.map((vitalSign) => {
        if (vitalSign.id === updatedVitalSign.id) return updatedVitalSign
        else return vitalSign
      })

      // Immediately add updated Vital to local cache
      queryCache.setQueryData(['ECGAdmin_getVitalSigns'], newVitalSigns)

      // Refetch from API to make sure the new vital sign is in sync with the server
      queryCache.invalidateQueries('ECGAdmin_getVitalSigns')
      queryCache.invalidateQueries('getVitalAssignments')
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useUpdateVitalSignMutation

// Post updated vital sign to backend
const updateVitalSign = async (vitalSign: VitalSign) => {
  const request: VitalsAPIRequest['PATCH']['/vital-signs/{vitalSignId}']['body'] = {
    name: vitalSign.name,
    displayName: vitalSign.displayName,
    code: vitalSign.code,
    units: vitalSign.units,
    defaultUnitCode: vitalSign.defaultUnitCode,
  }

  const result = await vitalsApi
    .patch(`vital-signs/${vitalSign.id}`, {
      json: request,
    })
    .json<VitalsAPIResponse['PATCH']['/vital-signs/{vitalSignId}']>()
  return result
}
