import React, { useState } from 'react'
import { Dialog, Icon, LoadingIcon } from 'elements'
import { Button, Chip, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useSubscriberVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import _ from 'lodash'
import {
  useDeleteSubscriberVitalThresholdsMutation,
  useOrgVitalsThresholdsQuery,
  useSubscriberVitalsThresholdsQuery,
} from 'hooks/vitals/thresholds'
import { useParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { SubscriberVitalsThresholdsForm } from '.'

const SubscriberVitalsThresholdsGrid = () => {
  const { t } = useTranslation()
  const { subscriberId } = useParams()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const {
    mutate: deleteSubscriberVitalThresholds,
  } = useDeleteSubscriberVitalThresholdsMutation()
  const subVitalAssignments = useSubscriberVitalAssignmentsQuery(subscriberId)

  const activeSubscriberVitalSigns = subVitalAssignments.data?.assignedVitalSigns.filter(
    (vital) => vital.active && !vital.archived
  )

  const activeSubscriberVitalSignsIds = activeSubscriberVitalSigns?.map(
    (vitalSign) => vitalSign.id
  )

  const {
    isLoading: isOrgThresholdsLoading,
    data: orgVitalThresholds,
  } = useOrgVitalsThresholdsQuery(assumedOrganizationRole?.orgID)

  const {
    isLoading: isSubscriberThresholdsLoading,
    data: subscriberVitalThresholds,
  } = useSubscriberVitalsThresholdsQuery(subscriberId)

  const [thresholdsToEdit, setThresholdsToEdit] = useState<
    { threshold: VitalThresholds; isSub: boolean } | undefined
  >()
  const [thresholdsToDelete, setThresholdsToDelete] = useState<
    VitalThresholds | undefined
  >()
  const [isCreatingThresholds, setIsCreatingThresholds] = useState<boolean>(
    false
  )

  const orgThresholdsWithNoSubscriber = _.differenceWith(
    orgVitalThresholds,
    subscriberVitalThresholds || [],
    (orgThreshold, subThreshold) =>
      orgThreshold.vitalSign.id === subThreshold.vitalSign.id
  )

  const allThresholds = [
    ..._.orderBy(
      subscriberVitalThresholds || [],
      (threshold) => threshold.vitalSign.name
    ).map((threshold) => ({
      isSub: true,
      threshold,
    })),
    ..._.orderBy(
      orgThresholdsWithNoSubscriber,
      (threshold) => threshold.vitalSign.name
    ).map((threshold) => ({
      isSub: false,
      threshold,
    })),
  ].filter((threshold) =>
    // only show thresholds for vital signs assigned to the subscriber
    activeSubscriberVitalSignsIds?.includes(threshold.threshold.vitalSign.id)
  )

  const vitalsWithNoThresholds = _.differenceWith(
    activeSubscriberVitalSigns,
    allThresholds,
    (vitalSign, threshold) => vitalSign.id === threshold.threshold.vitalSign.id
  )

  if (isOrgThresholdsLoading || isSubscriberThresholdsLoading)
    return <LoadingIcon />

  if (subVitalAssignments.data && activeSubscriberVitalSigns?.length === 0)
    return (
      <VitalAssignmentsEmpty
        title={t('No Data Found')}
        description={t('No vital signs have been assigned to this subscriber')}
        callToAction={
          <Button
            type="primary-filled"
            to={`/subscribers/${subscriberId}/settings/vital-sign-assignments`}
          >
            {t('Go To Vital Signs')}
          </Button>
        }
      />
    )

  return (
    <VitalsThresholdsCard>
      {/* HEADER */}
      <VitalsThresholdsGrid>
        <TitleSpacer />
        <HeaderContainer>
          <TitleContainer>
            <div>{t('Severity Level')}</div>
          </TitleContainer>
          <LowTitleContainer>
            <LeftTitleSpacer />
            <RangeTitle>{t('Low')}</RangeTitle>
            <RightTitleSpacer />
          </LowTitleContainer>
          <NormalSpacer />
          <RightTitleContainer>
            <LeftTitleSpacer />
            <RangeTitle>{t('High')}</RangeTitle>
            <RightTitleSpacer />
          </RightTitleContainer>
          <SeverityLevel>3</SeverityLevel>
          <SeverityLevel>2</SeverityLevel>
          <SeverityLevel>1</SeverityLevel>
          <NormalLevel>{t('Normal')}</NormalLevel>
          <SeverityLevel>1</SeverityLevel>
          <SeverityLevel>2</SeverityLevel>
          <SeverityLevel>3</SeverityLevel>
        </HeaderContainer>
        {/* BODY */}
        {allThresholds.map((thresholdsObj, index) =>
          // If the current threshold is not being edited show the normal row
          !(
            thresholdsToEdit?.threshold.vitalSign.id ===
            thresholdsObj.threshold.vitalSign.id
          ) ? (
            <React.Fragment key={`vitals-card-${index}`}>
              <ThresholdTypeContainer thresholdsToEdit={!!thresholdsToEdit}>
                <div>
                  <VitalSignTitle>
                    {thresholdsObj.threshold.vitalSign.name}
                  </VitalSignTitle>
                  <p>{`[${thresholdsObj.threshold.vitalSign.displayName}] (${
                    // find the sub assignment matching the vitalSign and use the sub's defaultUnitCode
                    (() => {
                      const vitalAssignment = subVitalAssignments.data?.assignedVitalSigns.find(
                        (subAssignment) =>
                          subAssignment.id ===
                          thresholdsObj.threshold.vitalSign.id
                      )
                      return vitalAssignment?.units.find(
                        (unit) => unit.code === vitalAssignment.defaultUnitCode
                      )?.displayName
                    })()
                  })`}</p>
                </div>
                <ThresholdTitleCellRight>
                  {thresholdsObj.isSub ? (
                    <OverrideChip color="green">
                      {t('Subscriber Override')}
                    </OverrideChip>
                  ) : null}
                  <IconContainer>
                    <EditIcon
                      type="edit"
                      onClick={
                        thresholdsToEdit || isCreatingThresholds
                          ? undefined
                          : () => setThresholdsToEdit(thresholdsObj)
                      }
                      data-testid={`${thresholdsObj.threshold.vitalSign.displayName}-edit`}
                      hidden={!!(thresholdsToEdit || isCreatingThresholds)}
                    />
                    {thresholdsObj.isSub ? (
                      <TrashIcon
                        type="trash"
                        onClick={
                          thresholdsToEdit || isCreatingThresholds
                            ? undefined
                            : () =>
                                setThresholdsToDelete(thresholdsObj.threshold)
                        }
                        data-testid={`${thresholdsObj.threshold.vitalSign.displayName}-delete`}
                        hidden={!!(thresholdsToEdit || isCreatingThresholds)}
                      />
                    ) : null}
                  </IconContainer>
                </ThresholdTitleCellRight>
              </ThresholdTypeContainer>
              <LowSeverityThree thresholdsToEdit={!!thresholdsToEdit}>
                {
                  thresholdsObj.threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 3 && threshold.direction === 'low'
                  )?.limit
                }
              </LowSeverityThree>
              <LowSeverityTwo thresholdsToEdit={!!thresholdsToEdit}>
                {
                  thresholdsObj.threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 2 && threshold.direction === 'low'
                  )?.limit
                }
              </LowSeverityTwo>
              <LowSeverityOne thresholdsToEdit={!!thresholdsToEdit}>
                {
                  thresholdsObj.threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 1 && threshold.direction === 'low'
                  )?.limit
                }
              </LowSeverityOne>
              <NormalSeverity thresholdsToEdit={!!thresholdsToEdit}>
                {`${thresholdsObj.threshold.normalLow || ''} - ${
                  thresholdsObj.threshold.normalHigh || ''
                }`}
              </NormalSeverity>
              <HighSeverityOne thresholdsToEdit={!!thresholdsToEdit}>
                {
                  thresholdsObj.threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 1 && threshold.direction === 'high'
                  )?.limit
                }
              </HighSeverityOne>
              <HighSeverityTwo thresholdsToEdit={!!thresholdsToEdit}>
                {
                  thresholdsObj.threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 2 && threshold.direction === 'high'
                  )?.limit
                }
              </HighSeverityTwo>
              <HighSeverityThree
                thresholdsToEdit={!!thresholdsToEdit}
                // On the bottom right corner, round the bottom right border to conform to the containing border radius
                // if there are vitals w/ no thresholds then the shadow row to create a new threshold will be below this row
                roundBottom={
                  index === allThresholds.length - 1 &&
                  vitalsWithNoThresholds.length === 0
                }
              >
                {
                  thresholdsObj.threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 3 && threshold.direction === 'high'
                  )?.limit
                }
              </HighSeverityThree>
            </React.Fragment>
          ) : (
            // Edit Form
            <SubscriberVitalsThresholdsForm
              thresholds={allThresholds.map((threshold) => threshold.threshold)}
              thresholdToUpdate={thresholdsObj}
              onFormFinished={() => setThresholdsToEdit(undefined)}
            />
          )
        )}
        {!thresholdsToEdit && vitalsWithNoThresholds.length > 0 ? (
          isCreatingThresholds ? (
            // Create Threshold Form
            <SubscriberVitalsThresholdsForm
              thresholds={allThresholds.map((threshold) => threshold.threshold)}
              onFormFinished={() => setIsCreatingThresholds(false)}
            />
          ) : (
            // Create Threshold Call to Action
            <CreateThresholdContainer
              onClick={() => setIsCreatingThresholds(true)}
            >
              <CreateThresholdTextContainer>
                <CreateThresholdText>
                  {t('Create New Threshold')}
                </CreateThresholdText>
              </CreateThresholdTextContainer>
            </CreateThresholdContainer>
          )
        ) : null}
      </VitalsThresholdsGrid>

      <Dialog
        open={!!thresholdsToDelete}
        title={`${t('Remove Subscriber Thresholds Override For')} ${
          thresholdsToDelete?.vitalSign.name
        }?`}
        content={t('Are you sure you want to remove this override?')}
        actions={
          <>
            <Button
              type="danger-filled"
              onClick={() => {
                deleteSubscriberVitalThresholds({
                  orgId: assumedOrganizationRole?.orgID || '',
                  subscriberId,
                  vitalSignId: thresholdsToDelete?.vitalSign.id || '',
                })
                setThresholdsToDelete(undefined)
              }}
            >
              {t('Delete')}
            </Button>

            <Button
              onClick={() => {
                setThresholdsToDelete(undefined)
              }}
              type="secondary"
            >
              {t('Cancel')}
            </Button>
          </>
        }
      />
    </VitalsThresholdsCard>
  )
}

export default SubscriberVitalsThresholdsGrid

const VitalAssignmentsEmpty = tw(
  Empty
)`border-2 border-dashed rounded flex-grow`

const ThresholdTitleCellRight = tw.div`flex items-center flex-wrap h-full justify-between`

const OverrideChip = tw(Chip)`ml-auto`

const IconContainer = tw.div`flex ml-auto`

const EditIcon = styled(Icon)<{ hidden: boolean }>(({ hidden }) => [
  tw`w-4 h-4 text-gray-600 hover:text-gray-900 ml-2 self-center`,
  hidden && tw`opacity-0`,
])

const TrashIcon = styled(Icon)<{ hidden: boolean }>(({ hidden }) => [
  tw`w-4 h-4 text-red-500 hover:text-red-600 ml-2 self-center`,
  hidden && tw`opacity-0`,
])

const VitalsThresholdsCard = tw.div`bg-white rounded-lg border border-gray-300`

const VitalsThresholdsGrid = tw.div`grid grid-cols-12 xl:grid-cols-10`

const TitleSpacer = tw.div`col-span-4 xl:col-span-3`

const HeaderContainer = tw.div`col-span-8 xl:col-span-7 grid grid-cols-8 xl:grid-cols-7`

const TitleContainer = tw.h1`col-span-8 xl:col-span-7 text-xl font-semibold text-gray-600 p-3 flex place-content-center items-center`

const LowTitleContainer = tw.div`col-span-3 flex place-content-center items-center`

const LeftTitleSpacer = tw.div`border border-gray-500 translate-y-2/4 w-full ml-2`

const RangeTitle = tw.span`text-gray-600 font-semibold mx-4`

const RightTitleSpacer = tw.div`border border-gray-500 translate-y-2/4 w-full mr-2`

const NormalSpacer = tw.div`col-span-2 xl:col-span-1`

const RightTitleContainer = tw.div`col-span-3 flex place-content-center items-center`

const SeverityLevel = tw.div`text-gray-600 text-center my-1`

const NormalLevel = tw.div`text-gray-600 text-center my-1 col-span-2 xl:col-span-1`

const ThresholdTypeContainer = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`col-span-4 xl:col-span-3 border-t border-gray-800 px-4 py-2 flex justify-between items-center`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const VitalSignTitle = tw.h5`font-semibold text-lg`

const LowSeverityThree = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-red-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const LowSeverityTwo = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-orange-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const LowSeverityOne = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-amber-200 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const NormalSeverity = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-emerald-200 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const HighSeverityOne = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-amber-200 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const HighSeverityTwo = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-orange-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const HighSeverityThree = styled.div<{
  thresholdsToEdit: boolean
  roundBottom: boolean
}>(({ thresholdsToEdit, roundBottom }) => [
  tw`bg-red-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
  thresholdsToEdit && tw`opacity-40`,
  roundBottom && tw`rounded-br-lg`,
])

const CreateThresholdContainer = tw.div`col-span-12 xl:col-span-10 border-t rounded-b-lg border-gray-800 p-2 flex items-center cursor-pointer h-20 hover:bg-gray-100 text-gray-500 hover:text-gray-700 transition-all`

const CreateThresholdTextContainer = tw.div`h-full w-full flex place-content-center items-center`

const CreateThresholdText = tw.div`text-lg font-medium`
