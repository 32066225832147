import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { EntityLabel, LoadingIcon } from 'elements'
import { Button, Empty, DateRangePicker } from 'atlas'
import { useSubscriberSeverityReportQuery } from 'hooks/user-subscriber'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { dateRangePresetToDateRange } from 'utils'

const MostActiveSubscribers = () => {
  const { t } = useTranslation()
  const [dateRangeState, setDateRangeState] = useState<DateRangeState>({
    preset: 'this-week',
    value: dateRangePresetToDateRange('this-week'),
  })

  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const subscriberSeverityReportQuery = useSubscriberSeverityReportQuery({
    organizationId: assumedOrganizationRole?.orgID,
    dateRangeState,
  })

  return (
    <div>
      <HeaderRow>
        <Header>{t('Active Alert Count by Subscriber')}</Header>
        <div>
          <DateRangePicker
            value={dateRangeState}
            onChange={(preset, value) =>
              setDateRangeState({
                preset,
                value,
              })
            }
            justify="end"
          />
        </div>
      </HeaderRow>

      {subscriberSeverityReportQuery.isLoading ? (
        <LoadingIcon height="24rem" />
      ) : subscriberSeverityReportQuery.data?.subscribers.items.length ? (
        subscriberSeverityReportQuery.data.subscribers.items.map(
          (subscriber) => {
            return (
              <Card
                severity={calculateSeverity(subscriber.totalAlerts)}
                key={subscriber.id}
              >
                <SubscriberLabel
                  name={`${subscriber.firstName} ${subscriber.lastName}`}
                  id={subscriber.id}
                  to={`/subscribers/${subscriber.id}`}
                />

                <TotalAlerts>
                  <HighlightedTotalAlerts>
                    {subscriber.totalAlerts}
                  </HighlightedTotalAlerts>{' '}
                  {t('active alert(s)')}
                </TotalAlerts>
                <ReviewButton
                  type="primary-link"
                  to={`/subscribers/${subscriber.id}/alerts-activity`}
                >
                  {t('Review')}
                </ReviewButton>
              </Card>
            )
          }
        )
      ) : (
        <EmptyList
          title={t('No Data Found')}
          description={t(
            'No subscribers with active alerts found for the given date preset'
          )}
        />
      )}
    </div>
  )
}

export default MostActiveSubscribers

const HeaderRow = tw.div`flex justify-between items-center mb-4`

const Header = tw.h3`text-2xl font-semibold`

const SubscriberLabel = tw(EntityLabel)`w-fit-content col-span-2`

const Card = styled.div<{ severity?: 'high' | 'medium' }>`
  ${tw`grid grid-cols-4 items-center p-4 mb-1 rounded border`}
  ${({ severity }) => {
    if (severity === 'high') return tw`bg-red-50 border-red-200`
    if (severity === 'medium') return tw`bg-amber-50 border-amber-200`
    return tw`bg-white border-gray-200 border-collapse`
  }}
`

const TotalAlerts = tw.p`text-gray-500 place-self-start self-center`

const HighlightedTotalAlerts = tw.span`font-bold text-gray-900`

const ReviewButton = tw(Button)`place-self-end self-center`

const EmptyList = tw(Empty)`flex flex-grow border-2 border-dashed rounded h-96`

const calculateSeverity = (
  totalAlerts: number
): 'high' | 'medium' | undefined => {
  if (totalAlerts >= 6) return 'high'
  if (totalAlerts >= 3) return 'medium'
  return undefined
}
