import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const usePartnerTypesQuery = (partnerTypeId: string) => {
  const query = useQuery<GetPartnerTypeResponse, KyError>(
    ['getPartnerType', partnerTypeId],
    () => getPartnerType(partnerTypeId)
  )

  return query
}

export default usePartnerTypesQuery

type getPartnerType = (partnerTypeId: string) => Promise<GetPartnerTypeResponse>
const getPartnerType: getPartnerType = async (partnerTypeId) => {
  const result = await orgsApi
    .get(`api/partner-types/${partnerTypeId}`)
    .json<GetPartnerTypeResponse>()
  return result
}
