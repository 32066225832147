import React from 'react'
import { Layout, LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSubscriberQuery } from 'hooks/organizations'
import { SubscriberSelect } from 'components/subscribers'
import { SubscriberAlertsActivityList } from 'components/subscriber-alerts'

const SubscriberAlerts = () => {
  const { t } = useTranslation()

  const { subscriberId } = useParams()

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  if (isSubLoading) return <LoadingIcon />

  if (!currentSubscriber)
    return (
      <Empty
        title={t('Subscriber Not Found')}
        description={t(
          `Either this subscriber doesn't exist or you don't have access to view them`
        )}
      />
    )

  return (
    <Layout
      title={
        <div className="flex gap-2">
          <h1 className="text-2xl font-bold">{t('Alerts Activity')}:</h1>
          <SubscriberSelect />
        </div>
      }
      description={t(
        'Address active alerts and review alert history for this subscriber.'
      )}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '/subscribers',
        },
        {
          name: `${currentSubscriber.person.firstName} ${currentSubscriber.person.lastName}`,
          route: `/subscribers/${subscriberId}`,
        },
        {
          name: t('Alerts Activity'),
        },
      ]}
    >
      <SubscriberAlertsActivityList subscriber={currentSubscriber} />
    </Layout>
  )
}

export default SubscriberAlerts
