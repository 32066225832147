import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useBlockedStaffOrganizationsQuery = (staffMemberId?: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/staff-members/{personId}/blocked-organizations'],
    KyError
  >(
    ['getBlockedStaffOrganizations', staffMemberId],
    () => getBlockedStaffOrganizations(staffMemberId || ''),
    { enabled: !!staffMemberId }
  )

  return query
}

export default useBlockedStaffOrganizationsQuery

const getBlockedStaffOrganizations = (staffMemberId: string) =>
  typedOrgsApi.get('/api/staff-members/{personId}/blocked-organizations', {
    pathParams: { personId: staffMemberId },
  })
