import React, { useState, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Icon, ConfirmationDialogs, SearchBox } from 'elements'
import { Button, Empty, Tooltip } from 'atlas'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import AssignPoliciesForm from './AssignPoliciesForm'

type AssignedPoliciesListProps = {
  userType: 'admin' | 'staff'
  staff?: StaffMember
  attachedPolicies: Policy[]
  onRemovePolicy?: (removedPolicy: Policy) => void
  onUpdatePolicies?: (newPolicies: Policy[]) => void
  isAssignPoliciesFormOpen: boolean
  setIsAssignPoliciesFormOpen: (newValue: boolean) => void
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

const AssignedPoliciesList = ({
  userType,
  staff,
  attachedPolicies,
  onRemovePolicy,
  onUpdatePolicies,
  isAssignPoliciesFormOpen,
  setIsAssignPoliciesFormOpen,
  searchValue,
  setSearchValue,
}: AssignedPoliciesListProps) => {
  const { t } = useTranslation()
  const [expandedPolicies, setExpandedPolicies] = useState<string[]>([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [policyToMutate, setPolicyToMutate] = useState<Policy>()

  useEffect(() => {
    if (isAssignPoliciesFormOpen) setSearchValue('')
  }, [isAssignPoliciesFormOpen])
  return (
    <>
      <PoliciesListContainer>
        <PolicySearch
          disabled={
            (attachedPolicies.length <= 0 && searchValue === '') ||
            !attachedPolicies
          }
          placeholder="Search policies"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {attachedPolicies.length > 0 ? (
          <Card>
            {_.orderBy(attachedPolicies, 'name').map((policy) => (
              <>
                <Policy
                  key={policy.id}
                  onClick={() =>
                    // either toggle the displayed policy list off or display the new policy list
                    setExpandedPolicies(
                      expandedPolicies.includes(policy.id)
                        ? expandedPolicies.filter(
                            (expandedPolicyId) => policy.id !== expandedPolicyId
                          )
                        : [...expandedPolicies, policy.id]
                    )
                  }
                >
                  <LeftContent>
                    <PoliciesIcon type="policies" />
                    <PolicyName>{policy.name}</PolicyName>
                    <PolicyCode>({policy.code})</PolicyCode>
                  </LeftContent>

                  <RightContent>
                    {expandedPolicies.includes(policy.id) ? (
                      <Icon type="chevron-up" />
                    ) : (
                      <Icon type="chevron-down" />
                    )}
                    <TrashIcon
                      type="trash"
                      data-testid={`${policy.name}-delete`}
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsDeleteDialogOpen(true)
                        setPolicyToMutate(policy)
                      }}
                    />
                  </RightContent>
                </Policy>
                <PolicyPermissionsList
                  hidden={!expandedPolicies.includes(policy.id)}
                >
                  {policy.permissions?.length ? (
                    policy.permissions.map((permission) => (
                      <Permission key={permission.id}>
                        <Tooltip
                          content={permission.description}
                          orientation="start"
                        >
                          <PermissionFlex>
                            <PermissionsIcon type="permissions" />
                            <PermissionName>{permission.name}</PermissionName>
                            <PermissionCode>({permission.code})</PermissionCode>
                          </PermissionFlex>
                        </Tooltip>
                      </Permission>
                    ))
                  ) : (
                    // empty case
                    <Permission>
                      <PermissionCode>
                        {t(
                          "There aren't any permissions attached to this policy"
                        )}
                      </PermissionCode>
                    </Permission>
                  )}
                </PolicyPermissionsList>
              </>
            ))}
          </Card>
        ) : (
          <PoliciesListEmpty
            title={
              searchValue === ''
                ? t('No Policies Assigned Yet')
                : t('No policies found')
            }
            callToAction={(() => {
              if (searchValue === '') {
                return (
                  <Button
                    type="primary-filled"
                    onClick={() => setIsAssignPoliciesFormOpen(true)}
                  >
                    {t('Assign Policies')}
                  </Button>
                )
              }
            })()}
            isOffset={searchValue !== ''}
          />
        )}

        <ConfirmationDialogs
          confirmations={[
            // Archive Dialog
            {
              setIsOpen: setIsDeleteDialogOpen,
              isOpen: isDeleteDialogOpen,
              title: t(`Detach "${policyToMutate?.name}" Policy`),
              content: (
                <p>{t('Are you sure you want to detach this policy?')}</p>
              ),
              actions: (
                <Button
                  onClick={() => {
                    if (onRemovePolicy && policyToMutate)
                      // remove this policy from selectedPolicies
                      onRemovePolicy(policyToMutate)

                    // close the policy of expanded
                    setExpandedPolicies(
                      expandedPolicies.filter(
                        (item) => item !== policyToMutate?.id
                      )
                    )

                    setIsDeleteDialogOpen(false)
                  }}
                  type="danger-filled"
                >
                  {t('Detach')}
                </Button>
              ),
            },
          ]}
        />
        <AssignPoliciesForm
          staff={staff}
          isFormOpen={isAssignPoliciesFormOpen}
          setIsFormOpen={setIsAssignPoliciesFormOpen}
          existingPolicies={attachedPolicies}
          setExistingPolicies={onUpdatePolicies}
          userType={userType}
        />
      </PoliciesListContainer>
    </>
  )
}

export default AssignedPoliciesList

const Policy = tw.div`py-2 px-5 hover:bg-blue-50 flex items-center justify-between cursor-pointer text-lg`

const PolicyName = tw.p`mr-2`

const PolicyCode = tw.p`text-gray-500`

const LeftContent = tw.div`flex items-center`

const RightContent = tw.div`flex items-center`

const PolicyPermissionsList = tw.div`mb-0.5 mt-0.5`

const Permission = tw.div`py-1 px-6 border-l border-gray-300 ml-7 text-lg`

const PermissionFlex = tw.div`flex items-center`

const PermissionName = tw.p`mr-2`

const PermissionCode = tw.p`text-gray-500`

const Card = tw.div`bg-white border border-gray-200 rounded mb-2`

const PoliciesListEmpty = styled(Empty)<{ isOffset: boolean }>`
  ${tw`flex-grow mt-5 border-2 border-dashed rounded`}
  ${({ isOffset }) => {
    if (!isOffset) return tw`transform -translate-y-4`
    return tw`transform translate-y-2`
  }}
`
const PoliciesListContainer = tw.div`flex flex-col flex-grow`

const PoliciesIcon = tw(Icon)`mr-2`

const PolicySearch = tw(SearchBox)`mb-2`

const TrashIcon = tw(Icon)`ml-2`

const PermissionsIcon = tw(Icon)`mr-2`
