import { Alert, Button } from 'atlas'
import { AutoComplete, Icon, RightPopup, TextField } from 'elements'
import { Empty } from 'atlas'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { OrderProductLineItem } from './OrderDetailsLineItems'
import _ from 'lodash'
import { useCompleteProvisioningLineItemMutation } from 'hooks/provisioning'
import { useSubscriberQuery } from 'hooks/organizations'
import { useParams } from 'react-router'
import { useAPIQuery } from 'hooks'
import { isAneltoNonPersBaseConsole } from './OrderDetailsLineItems'

type LineItemProvisionAttribute = {
  provisioningAttribute: ProvisioningAttribute
  value: string
}

type ProvisionLineItemFormProps = {
  open: boolean
  setOpen: (newValue: boolean) => void
  lineItem: OrderProductLineItem | undefined
}

const ProvisionLineItemForm = ({
  open,
  setOpen,
  lineItem,
}: ProvisionLineItemFormProps) => {
  const { t } = useTranslation()
  const { orderId } = useParams()

  const orderQuery = useAPIQuery('order', {
    pathParams: {
      orderId,
    },
  })

  const subscriberQuery = useSubscriberQuery(
    orderQuery.data?.subscriberId || ''
  )

  const provisioningAttributesQuery = useAPIQuery('orderAttributes')

  const completeProvisioningLineItem = useCompleteProvisioningLineItemMutation()

  const [createdAttributes, setCreatedAttributes] = useState<
    ReadonlyArray<LineItemProvisionAttribute>
  >([])

  const [attributeValue, setAttributeValue] = useState('')
  const [attributeValueToEdit, setAttributeValueToEdit] = useState('')
  const [attributeToEditIndex, setAttributeToEditIndex] = useState<number>()
  const [
    provisioningAttribute,
    setProvisioningAttribute,
  ] = useState<ProvisioningAttribute | null>(null)
  const [errors, setErrors] = useState<
    Partial<
      Record<keyof LineItemProvisionAttribute | 'attributeToEdit', string>
    >
  >({})

  // reset form state when form opens
  useEffect(() => {
    if (open) {
      // initialize createdAttributes to lineItem.provisioningData if lineItem changes
      setCreatedAttributes(
        Object.entries<string>(
          lineItem?.orderLineItem.provisioningData || {}
        ).map(([attributeCode, value]) => ({
          provisioningAttribute:
            provisioningAttributesQuery.data?.find(
              (provisioningAttribute) =>
                provisioningAttribute.code === attributeCode
            ) || {},
          value,
        }))
      )
      setAttributeValue('')
      setAttributeValueToEdit('')
      setAttributeToEditIndex(undefined)
      setProvisioningAttribute(null)
      setErrors({})
    }
  }, [open, lineItem])

  return (
    <RightPopup
      title={`${lineItem?.product.title || t('Line Item')} ${t(
        'Provisioning'
      )}`}
      open={open}
      setOpen={setOpen}
      width="700px"
      controls={
        <>
          <SaveButton
            onClick={() => {
              completeProvisioningLineItem.mutate({
                orderId: lineItem?.orderLineItem.orderId || '',
                lineItemId: lineItem?.orderLineItem.id || '',
                newProvisioningAttributes: Object.fromEntries(
                  createdAttributes.map((attribute) => [
                    attribute.provisioningAttribute.code,
                    attribute.value,
                  ])
                ),
                oldProvisioningAttributes:
                  lineItem?.orderLineItem.provisioningData || {},
              })
              setOpen(false)
            }}
            type="primary-filled"
          >
            {t('Complete Provisioning')}
          </SaveButton>
          <CancelButton onClick={() => setOpen(false)} type="secondary">
            {t('Cancel')}
          </CancelButton>
        </>
      }
    >
      <FormContainer>
        <RowContainer>
          <AttributeAutoComplete<ProvisioningAttribute>
            single
            label={t('Provisioning Attribute')}
            options={
              provisioningAttributesQuery.data?.filter(
                (provisioningAttribute) =>
                  !createdAttributes.some((attribute) =>
                    _.isEqual(
                      attribute.provisioningAttribute,
                      provisioningAttribute
                    )
                  )
              ) || []
            }
            selectedOption={provisioningAttribute}
            optionLabel={(attribute) => attribute.title || ''}
            onChange={(selectedOption) =>
              setProvisioningAttribute(selectedOption)
            }
            error={errors.provisioningAttribute}
          />
          <TextField
            name="Attribute Value"
            label={t('Attribute Value')}
            value={attributeValue}
            onChange={(e) => setAttributeValue(e.target.value)}
            error={errors.value}
          />
          <AddAttributeButton
            onClick={() =>
              attributeValue && provisioningAttribute
                ? (setCreatedAttributes([
                    ...createdAttributes,
                    {
                      provisioningAttribute,
                      value: attributeValue,
                    },
                  ]),
                  setErrors({}),
                  setAttributeValue(''),
                  setProvisioningAttribute(null))
                : setErrors({
                    provisioningAttribute: !provisioningAttribute
                      ? t('Provisioning Attribute is required')
                      : undefined,
                    value: !attributeValue
                      ? t('Attribute Value is required')
                      : undefined,
                  })
            }
          >
            {t('Add Attribute')}
          </AddAttributeButton>
        </RowContainer>
        <TitleRowContainer>
          <Title>{t('Added Attributes')}</Title>
        </TitleRowContainer>
        {createdAttributes.length ? (
          createdAttributes.map((attribute, index) => {
            const isDisabled =
              isAneltoNonPersBaseConsole(
                lineItem,
                subscriberQuery.data?.nonPERS
              ) &&
              anltoTags.some(
                (tag) => tag === attribute.provisioningAttribute.code
              )
            return (
              <AttributeCard isDisabled={isDisabled} key={index}>
                <AttributeTitle>
                  {attribute.provisioningAttribute.title}
                </AttributeTitle>
                {attributeToEditIndex === index ? (
                  <TextField
                    label={t('Attribute Value')}
                    value={attributeValueToEdit}
                    onChange={(e) => setAttributeValueToEdit(e.target.value)}
                    error={errors.attributeToEdit}
                    className="col-span-3 -mb-6"
                    noMargin
                    size="small"
                  />
                ) : (
                  <AttributeValue>{attribute.value}</AttributeValue>
                )}
                {attributeToEditIndex === undefined ? (
                  <AttributeIconContainer>
                    <EditIcon
                      type="edit"
                      disabled={isDisabled}
                      onClick={() => {
                        setAttributeValueToEdit(attribute.value)
                        setAttributeToEditIndex(index)
                        setErrors({ ...errors, attributeToEdit: undefined })
                      }}
                    />
                    <TrashIcon
                      type="trash"
                      disabled={isDisabled}
                      onClick={() =>
                        setCreatedAttributes(
                          createdAttributes.filter(
                            (_, attIndex) => index !== attIndex
                          )
                        )
                      }
                    />
                  </AttributeIconContainer>
                ) : attributeToEditIndex === index ? (
                  <AttributeIconContainer>
                    <ConfirmIcon
                      type="check"
                      onClick={() => {
                        attributeValueToEdit
                          ? (setAttributeToEditIndex(undefined),
                            setCreatedAttributes(
                              createdAttributes.map((attribute, index) =>
                                attributeToEditIndex === index
                                  ? {
                                      ...attribute,
                                      value: attributeValueToEdit,
                                    }
                                  : attribute
                              )
                            ))
                          : setErrors({
                              ...errors,
                              attributeToEdit: ' ',
                            })
                        return null
                      }}
                    />
                    <CancelIcon
                      type="x"
                      onClick={() => {
                        setAttributeToEditIndex(undefined)
                        setAttributeValueToEdit('')
                      }}
                    />
                  </AttributeIconContainer>
                ) : null}
              </AttributeCard>
            )
          })
        ) : (
          <EmptyDashed title={t('No Attributes have been added')} />
        )}
        <br />
        {subscriberQuery.data?.nonPERS === false ? (
          <Alert
            message={`${t(
              'Changing this information does not change the information on the Anelto Platform'
            )}. ${t(
              'Please confirm that any changes made here are reflected on the Anelto API'
            )}.`}
            type="information"
          />
        ) : null}
      </FormContainer>
    </RightPopup>
  )
}

export default ProvisionLineItemForm

// tags that can't be edited if non-pers, synced, and base console
const anltoTags = ['ANL_ACCT_NUM', 'ANL_DEVICE_ICCID', 'ANL_DEVICE_IMEI']

const FormContainer = tw.div`flex flex-col`

const AttributeCard = styled.div<{ isDisabled?: boolean }>(({ isDisabled }) => [
  tw` grid grid-cols-10 w-full height[57px] my-2 py-2 px-4 border bg-white rounded items-center`,
  isDisabled && tw`bg-trueGray-200 hover:cursor-not-allowed text-trueGray-500 `,
])

const AttributeTitle = tw.div`text-lg col-span-5`

const AttributeValue = tw.div`text-gray-600 truncate col-span-3 text-center`

const AttributeIconContainer = tw.div`col-span-2 flex items-center justify-end`

const Title = tw.div`text-lg font-semibold flex-grow pt-2`

const SaveButton = tw(Button)`mr-4`

const CancelButton = tw(Button)``

const AddAttributeButton = tw(Button)` h-fit-content mt-2 ml-4`

const RowContainer = tw.div`flex`

const TitleRowContainer = tw.div`flex mb-4`

const AttributeAutoComplete = tw(AutoComplete)`flex-grow mr-4`

const EmptyDashed = tw(Empty)`border-2 border-dashed rounded`

const TrashIcon = tw(
  Icon
)`w-4 h-4 text-red-500 hover:text-red-700 ml-2 cursor-pointer`

const EditIcon = tw(
  Icon
)`w-4 h-4 text-gray-600 hover:text-gray-900 ml-4 cursor-pointer`

const ConfirmIcon = tw(
  Icon
)`w-5 h-5 text-green-600 hover:text-green-800 ml-4 cursor-pointer`

const CancelIcon = tw(
  Icon
)`w-5 h-5 text-red-500 hover:text-red-700 ml-2 cursor-pointer`
