import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

type useMedicationStatisticsQueryArgs = {
  organizationId?: string
  status: MedicationActivityStatus
  dateRangeState?: DateRangeState
}

const useMedicationStatisticsQuery = ({
  organizationId,
  status,
  dateRangeState,
}: useMedicationStatisticsQueryArgs) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/api/organizations/{organizationId}/medication-activities/count'],
    KyError
  >(
    ['getMedicationStatistics', organizationId, status, dateRangeState],
    () => getMedicationStatistics(organizationId || '', status, dateRangeState),
    { enabled: !!organizationId }
  )
  return query
}

export default useMedicationStatisticsQuery

const getMedicationStatistics = async (
  organizationId: string,
  status: MedicationActivityStatus,
  dateRangeState?: DateRangeState
) =>
  typedVitalsApi
    .get('/api/organizations/{organizationId}/medication-activities/count', {
      pathParams: { organizationId },
      searchParams: {
        status,
        dateRangeType: dateRangeState?.preset,
      },
    })
    .catch(async (e) => {
      const error = await e.response.json()
      if (error.code === 400) {
        return {
          medicationActivityStatus: status || '',
          total: 0,
        }
      }
      throw e
    })
