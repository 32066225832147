import { useQuery } from 'react-query'
import { ordersApi } from 'utils'

const useContractLineItemsQuery = ({
  organizationId,
  contractId,
}: {
  organizationId: string
  contractId: string
}) => {
  const query = useQuery<
    OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}/line-items'],
    KyError
  >(
    ['getLineItems', organizationId, contractId],
    () => getLineItems({ organizationId, contractId }),
    { keepPreviousData: true }
  )

  return query
}

export default useContractLineItemsQuery

const getLineItems = async ({
  organizationId,
  contractId,
}: {
  organizationId: string
  contractId: string
}) =>
  ordersApi.get(
    `/organizations/{organizationId}/contracts/{contractId}/line-items`,
    {
      pathParams: { organizationId: organizationId || '', contractId },
    }
  )
