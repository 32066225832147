import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { generateDisableable } from 'test-utils/api/utils'
import { success, orgsApi, handleQueryError } from 'utils'

const useCreatePartnerTypeMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    CreatePartnerTypeResponse,
    KyError,
    CreatePartnerTypeProps,
    GetPartnerTypesResponse | undefined
  >(createPartnerType, {
    onMutate: ({ partnerTypeData }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        GetPartnerTypesResponse | undefined
      >(['getPartnerTypes'])

      // immediately update the Partner Type list
      queryCache.setQueryData<GetPartnerTypesResponse | undefined>(
        ['getPartnerTypes'],
        (oldCache) =>
          oldCache
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items,
                  {
                    id: '123',
                    ...partnerTypeData,
                    ...generateDisableable(),
                    description: partnerTypeData.description || '',
                  },
                ],
              }
            : oldCache
      )
      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Partner Type created') })
    },
    onError: async (error, _, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetPartnerTypesResponse | undefined>(
        'getPartnerTypes',
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: () => {
      // refetch from API to make sure the partner list is in sync with the server
      queryCache.invalidateQueries('getPartnerTypes')
    },
  })

  return mutation
}

export default useCreatePartnerTypeMutation

type CreatePartnerTypeProps = {
  partnerTypeData: CreatePartnerTypeForm
}

const createPartnerType = async ({
  partnerTypeData,
}: CreatePartnerTypeProps) => {
  const createPartnerTypeReq: CreatePartnerTypeRequest = {
    ...partnerTypeData,
  }

  const createPartnerTypeResult = await orgsApi
    .post(`api/partner-types`, {
      json: createPartnerTypeReq,
    })
    .json<CreatePartnerTypeResponse>()

  return createPartnerTypeResult
}
