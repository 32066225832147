import React from 'react'
import { ResponsiveBar, IndexByFunc } from '@nivo/bar'
import { Box } from '@nivo/core'
import { LegendProps } from '@nivo/legends'
import colors from 'tailwindcss/colors'
import { LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

type BarChartProps = {
  data: Record<string, unknown>[]
  series: Series[]
  indexBy: string | IndexByFunc
  groupMode?: 'grouped' | 'stacked'
  YAxis?: {
    label?: string
  }
  XAxis?: {
    format?: (value: string | number | Date) => string
  }
  tooltip?: {
    unit?: string
    x?: {
      format?: (value: string | number | Date) => string
    }
  }
  isLoading?: boolean
  legends?:
    | ({
        dataFrom: 'keys' | 'indexes'
      } & LegendProps)[]
    | undefined
  margin?: Box
}

const BarChart = ({
  data,
  series,
  indexBy,
  groupMode = 'grouped',
  YAxis,
  XAxis,
  tooltip,
  isLoading,
  legends = [
    {
      dataFrom: 'keys',
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 20,
      symbolShape: 'circle',
    },
  ],
  margin = { top: 20, right: 105, bottom: 55, left: 50 },
}: BarChartProps) => {
  const { t } = useTranslation()

  if (isLoading) return <LoadingIcon height="230px" />

  if (data.length === 0)
    return (
      <BarChartEmpty
        title="No Data Found"
        description={t('Try changing the date range or filter criteria')}
      />
    )

  return (
    <ResponsiveBar
      data={data}
      indexBy={indexBy}
      margin={margin}
      groupMode={groupMode}
      animate={false}
      enableLabel={false}
      keys={series
        .filter((seriesItem) => seriesItem.active)
        .map((seriesItem) => seriesItem.key)}
      colors={(e) => {
        const color = series.find((seriesItem) => e.id === seriesItem.key)
          ?.color

        return color || colors.gray[400]
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: YAxis?.label,
        legendPosition: 'middle',
        legendOffset: -40,
        tickValues: 6,
      }}
      axisBottom={{
        format: XAxis?.format,
      }}
      borderRadius={5}
      legends={legends}
      tooltip={(e) => {
        return (
          <Tooltip>
            {tooltip?.x?.format?.(e.indexValue) ? (
              <Timestamp>{tooltip.x.format(e.indexValue)}</Timestamp>
            ) : null}
            <TooltipData>
              <TooltipColorIndicator style={{ backgroundColor: e.color }} />

              <p>
                {e.value}{' '}
                <TooltipUnit>
                  {tooltip?.unit ||
                    series.find((item) => item.key === e.id)?.unit}
                </TooltipUnit>
              </p>
            </TooltipData>
          </Tooltip>
        )
      }}
      theme={{
        tooltip: {
          container: {
            background: 'none',
            boxShadow: 'none',
          },
        },
      }}
    />
  )
}

export default BarChart

const Tooltip = tw.div`bg-white p-2 rounded shadow-lg border border-gray-200`

const TooltipData = tw.div`flex items-center`

const Timestamp = tw.p`mb-1`

const TooltipColorIndicator = tw.div`w-4 h-4 mr-2 rounded-full`

const TooltipUnit = tw.span`text-gray-500`

const BarChartEmpty = tw(Empty)`pt-4`
