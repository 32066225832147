import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { PartnerList } from 'components/partners'
import tw from 'twin.macro'

const PartnersList = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Partner List')}
      description={t('View and edit existing partners or add new partners.')}
      isUnauthorized={!!assumedOrganizationRole}
      controls={
        <Button type="primary-filled" to="/partners/create-partner">
          {t('Create Partner')}
        </Button>
      }
      breadcrumbs={[
        {
          name: t('Partners'),
          route: '/partners',
        },
        {
          name: t('Partner List'),
        },
      ]}
    >
      <PartnerListContainer>
        <PartnerList />
      </PartnerListContainer>
    </Layout>
  )
}

export default PartnersList

const PartnerListContainer = tw.div`flex flex-col flex-grow`
