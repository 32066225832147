import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { isPresent, ordersApi } from 'utils'

const useUpdateShippingOptionMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['PATCH']['/organizations/{organizationId}/contracts/{contractId}/shipping-options/{optionId}'],
    updateShippingOptionArgs
  >({
    mutationFn: updateShippingOption,
    successMsg: t('Shipping option updated'),
    // TODO: replace with Optimistic Update
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getOrganizationContracts', organizationId],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ contractId }) => ['getContract', contractId],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}'],
          updateShippingOptionArgs
        >((oldCache, { optionId, shippingOptionForm }) =>
          oldCache
            ? {
                ...oldCache,
                shippingPrices:
                  oldCache.shippingPrices?.map((shippingOption) =>
                    shippingOption.id === optionId
                      ? {
                          ...shippingOption,
                          priceDescriptor: {
                            ...shippingOption.priceDescriptor,
                            ...shippingOptionForm,
                          },
                        }
                      : shippingOption
                  ) || [],
              }
            : undefined
        ),
      },
      {
        cacheKey: ({ contractId }) => [
          'getContractShippingOptions',
          contractId,
        ],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}/shipping-options'],
          updateShippingOptionArgs
        >((oldCache, { optionId, shippingOptionForm }) =>
          oldCache?.items
            ? {
                items: oldCache.items.map((shippingOption) =>
                  shippingOption.id === optionId
                    ? {
                        ...shippingOption,
                        priceDescriptor: {
                          ...shippingOption.priceDescriptor,
                          ...shippingOptionForm,
                        },
                      }
                    : shippingOption
                ),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useUpdateShippingOptionMutation

type updateShippingOptionArgs = {
  shippingOptionForm: Partial<
    NonNullable<ContractShippingOption['priceDescriptor']>
  >
  optionId: string
  organizationId: string
  contractId: string
}

const updateShippingOption = async ({
  shippingOptionForm,
  optionId,
  organizationId,
  contractId,
}: updateShippingOptionArgs) =>
  ordersApi.patch(
    '/organizations/{organizationId}/contracts/{contractId}/shipping-options/{optionId}',
    {
      pathParams: { organizationId, contractId, optionId },
      body: Object.entries(shippingOptionForm)
        .filter((field): field is [string, Record<string, unknown>] =>
          isPresent(field[1])
        )
        .map(([fieldKey, fieldValue]) => ({
          value: fieldValue,
          path: `/${fieldKey}`,
          op: 'replace',
        })),
    }
  )
