import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useBlockStaffOrganizationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/staff-members/{personId}/block'],
    blockOrgArgs
  >({
    mutationFn: blockStaffOrg,
    successMsg: t('Organization Blocked'),
    additionalCachesToInvalidate: [
      ({ staffMemberId }) => ['getBlockedStaffOrganizations', staffMemberId],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ staffMemberId }) => [
          'getBlockedStaffOrganizations',
          staffMemberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/staff-members/{personId}/blocked-organizations'],
          blockOrgArgs
        >((oldBlockedList, { organization }) => {
          return oldBlockedList?.items
            ? {
                ...oldBlockedList,
                items: [...oldBlockedList.items, organization],
              }
            : undefined
        }),
      },
    ],
  })

  return mutation
}

export default useBlockStaffOrganizationMutation

type blockOrgArgs = {
  staffMemberId: string
  organization: Organization
}

const blockStaffOrg = ({ staffMemberId, organization }: blockOrgArgs) =>
  typedOrgsApi.post(
    `/api/organizations/{organizationId}/staff-members/{personId}/block`,
    {
      pathParams: {
        organizationId: organization.id,
        personId: staffMemberId,
      },
    }
  )
