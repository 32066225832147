import React from 'react'
import tw, { styled } from 'twin.macro'
import Icon from '../../elements/Icon'

type AlertType = 'success' | 'danger' | 'information' | 'warning'

type BaseAlertProps = {
  /**
   * Style preset for the Alert. This only effects the style of the Alert, no functionality is changed.
   */
  type: AlertType

  /**
   * Callback function called when the Alert is clicked.
   */
  onClick?: () => void
}

export type AlertProps = BaseAlertProps &
  (
    | {
        /**
         * The contents of the Alert.
         */
        message: undefined | string

        /**
         * The Child Nodes of Alert, to allow for customization eg: CallToAction component
         */
        children?: never
      }
    | {
        /**
         * The contents of the Alert.
         */
        message?: never

        /**
         * The Child Nodes of Alert, to allow for customization eg: CallToAction component
         */
        children: React.ReactNode
      }
  )

const Alert = ({ message, type, onClick, children }: AlertProps) => {
  return (
    <Container alertType={type} onClick={onClick}>
      <MessageInfo>
        <AlertIconColor alertType={type}>
          <Icon type={alertTypeToIcon[type]} />
        </AlertIconColor>
        {message ? <Message>{message}</Message> : children}
      </MessageInfo>

      {onClick ? <NavigationIcon type="arrow-right" /> : null}
    </Container>
  )
}

export default Alert

const Container = styled.div<{ alertType: AlertType }>(
  ({ alertType, onClick }) => [
    tw`py-2 px-3 mb-4 border-l-4 flex justify-between items-center shadow-sm text-white`,
    onClick && tw`cursor-pointer`,
    alertType === 'success' &&
      tw`bg-emerald-100 text-emerald-900 border-emerald-500`,
    alertType === 'danger' && tw`bg-red-100 text-red-900 border-red-500`,
    alertType === 'warning' &&
      tw`bg-yellow-100 text-yellow-900 border-yellow-500`,
    alertType === 'information' &&
      tw`bg-cyan-100 text-cyan-900 border-cyan-500`,
  ]
)

const NavigationIcon = tw(Icon)`text-lg`

const Message = styled.h5`
  color: inherit;
`

const MessageInfo = tw.div`flex items-center`

const alertTypeToIcon: {
  [key in AlertType]: IconType
} = {
  success: 'check',
  danger: 'error',
  warning: 'alert2',
  information: 'info',
}

const AlertIconColor = styled.div<{ alertType: AlertType }>(({ alertType }) => [
  tw`flex mr-2 text-lg`,
  alertType === 'success' && tw`text-emerald-500`,
  alertType === 'danger' && tw`text-red-500`,
  alertType === 'warning' && tw`text-yellow-500`,
  alertType === 'information' && tw`text-cyan-500`,
])
