import React, { useEffect } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { LoadingIcon, RightPopup } from 'elements'
import { Button } from 'atlas'
import {
  useAssignSubscriberVitalSignsMutation,
  useOrgVitalAssignmentsQuery,
} from 'hooks/vitals/vital-assignments'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useSubscriberQuery } from 'hooks/organizations'
import useSubscriberVitalAssignmentsQuery from '../../hooks/vitals/vital-assignments/useSubscriberVitalAssignmentsQuery'
import { useParams } from 'react-router-dom'
import {
  isPresent,
  assignmentsToDisplayAssignments,
  displayAssignmentsToAssignments,
} from 'utils'

type SubscriberAssignVitalSignsFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SubscriberAssignVitalSignsForm = ({
  isFormOpen,
  setIsFormOpen,
}: SubscriberAssignVitalSignsFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { subscriberId } = useParams()
  const { t } = useTranslation()

  const { handleSubmit, control, watch, reset } = useForm<{
    vitalSigns: Array<{
      vitalSign: VitalAssignment
      selected: boolean
    }>
  }>({
    defaultValues: {
      vitalSigns: [],
    },
  })
  const { fields } = useFieldArray<{
    vitalSign: VitalAssignment
    selected: boolean
  }>({
    control,
    name: 'vitalSigns',
  })

  const currentSubscriber = useSubscriberQuery(subscriberId)
  const subscriberVitalAssignments = useSubscriberVitalAssignmentsQuery(
    subscriberId
  )
  const organizationVitalAssignments = useOrgVitalAssignmentsQuery(
    // if the subscriber has an org, use the organization's vital assignments
    currentSubscriber.data?.organization
      ? assumedOrganizationRole?.orgID
      : // else if they're private pay, use the ECG's organization's vital assignments
        '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const assignSubscriberVitalSigns = useAssignSubscriberVitalSignsMutation()

  const subVitalSignIds = subscriberVitalAssignments.data?.assignedVitalSigns.map(
    (vitalSign) => vitalSign.id
  )
  // Only show vital signs that aren't already in subVitalSigns and that are unarchived
  const unassignedVitalSigns = organizationVitalAssignments.data?.assignedVitalSigns.filter(
    (vitalSign) =>
      !subVitalSignIds?.includes(vitalSign.id) && !vitalSign.archived
  )

  // reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen && unassignedVitalSigns)
      reset({
        vitalSigns: assignmentsToDisplayAssignments(unassignedVitalSigns).map(
          (vitalSign) => ({
            vitalSign,
            selected: false,
          })
        ),
      })
  }, [isFormOpen])

  // if there are no unassignedVitalSigns form should not be shown
  if (!unassignedVitalSigns) return null

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Assign Vital Sign')}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={assignSubscriberVitalSigns.isLoading}
            // disables Save button if Vital Sign or Default Unit Code has no value
            disabled={watch('vitalSigns').every(
              (vitalSign) => !vitalSign.selected
            )}
            onClick={handleSubmit(async (formData) => {
              // convert blood pressure to 2 BP vital assignments if it was selected
              const vitalSignAssignments: VitalAssignment[] = displayAssignmentsToAssignments(
                unassignedVitalSigns,
                // convert form data to an array VitalAssignmentss
                fields
                  /* RHF updates properties with an input in formData (but not fields) and sets those
                without an input to undefined so we have to get 'selected' from formData but
                'vitalSign' from fields */
                  .filter((_, index) => formData.vitalSigns[index].selected)
                  .map(
                    (selectedVitalAssignment) =>
                      selectedVitalAssignment.vitalSign
                  )
                  .filter(isPresent)
              )

              // if blood pressure vital exists in vitalSignAssignments replace with both of them
              // API call
              assignSubscriberVitalSigns.mutate({
                newVitalSigns: vitalSignAssignments,
                subscriberId,
                subscriberAssignmentId: subscriberVitalAssignments.data?.id,
              })

              // Close form drawer
              setIsFormOpen(false)
            })}
          >
            {t('Assign')}
          </Button>
          &nbsp;
          <Button
            disabled={assignSubscriberVitalSigns.isLoading}
            type="secondary"
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {subscriberVitalAssignments.isLoading ||
      organizationVitalAssignments.isLoading ||
      currentSubscriber.isLoading ? (
        <LoadingIcon />
      ) : (
        <form>
          {fields.map((field, index) => (
            <div key={field.id}>
              <Controller
                control={control}
                render={({ onChange, value, ref }) => (
                  <InputCard
                    onClick={() => {
                      onChange(!value)
                    }}
                  >
                    <CheckboxContainer>
                      <Checkbox
                        type="checkbox"
                        onChange={(e) => {
                          onChange(e.target.checked)
                        }}
                        checked={value}
                        ref={ref}
                        value={value}
                      />
                      &nbsp;
                      {field.vitalSign?.name}
                    </CheckboxContainer>

                    <InfoDescription>
                      {t('Display Name')}:&nbsp;
                      <Info>{field.vitalSign?.displayName}</Info>
                    </InfoDescription>
                    <InfoDescription>
                      {t('Units')}:&nbsp;
                      <Info>
                        {
                          field.vitalSign?.units.find(
                            (unit) =>
                              unit.code === field.vitalSign?.defaultUnitCode
                          )?.displayName
                        }
                      </Info>
                    </InfoDescription>
                  </InputCard>
                )}
                name={`vitalSigns[${index}].selected`}
              />
            </div>
          ))}
        </form>
      )}
    </RightPopup>
  )
}

export default SubscriberAssignVitalSignsForm

const InputCard = tw.div`flex flex-col mb-4 bg-white p-5 rounded-lg border border-gray-200 h-full w-full cursor-pointer hover:border-gray-300 hover:shadow-lg`

const CheckboxContainer = tw.div`border-b pb-2 mb-2 align-top flex items-center text-lg font-semibold`

const Checkbox = tw.input`cursor-pointer w-4 h-4 mr-4 align-middle`

const InfoDescription = tw.p`text-gray-500`

const Info = tw.span`text-gray-800`
