import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

type UseApplicationsAuditTrailQueryProps = {
  filters: ApplicationAuditSearchParams
}

const useApplicationsAuditTrailQuery = ({
  filters,
}: UseApplicationsAuditTrailQueryProps) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/applications/audit-trails'],
    KyError
  >(['getAuditTrail', 'applications', filters], () => getAuditTrail(filters), {
    staleTime: 0,
  })

  return query
}

export default useApplicationsAuditTrailQuery

const getAuditTrail = async (filters: ApplicationAuditSearchParams) =>
  typedOrgsApi.get('/api/applications/audit-trails', {
    searchParams: {
      ...filters,
    },
  })

export type ApplicationAuditSearchParams = NonNullable<
  Extract<
    OrgsAPIRequest['GET']['/api/applications/audit-trails']['searchParams'],
    Record<string, unknown>
  >
>
