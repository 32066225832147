import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

// TODO: Refactor Commissions when the actual API is released for it
const usePartnerTypeCommissionsQuery = (partnerTypeId: string | undefined) => {
  const query = useQuery<GetPartnerTypeCommissionsResponse, KyError>(
    ['getPartnerTypeDirectCommissions', partnerTypeId],
    () => getPartnerTypeDirectCommissions(partnerTypeId),
    { enabled: !!partnerTypeId }
  )

  return query
}

export default usePartnerTypeCommissionsQuery

type getPartnerTypeDirectCommissions = (
  partnerTypeId: string | undefined
) => Promise<GetPartnerTypeCommissionsResponse>
const getPartnerTypeDirectCommissions: getPartnerTypeDirectCommissions = async (
  partnerTypeId
) => {
  const result = await orgsApi
    .get(`api/partner-types/${partnerTypeId}/direct-commissions`)
    .json<GetPartnerTypeCommissionsResponse>()
  return result
}
