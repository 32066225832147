import { rest } from 'msw'
import db from '../../data/db'

const alertCategoryHandlers = [
  // get alert categories
  rest.get<undefined, VitalsAPIResponse['GET']['/api/alert-categories']>(
    '/api/alert-categories',
    (req, res, ctx) => {
      const response = db.get<AlertCategory[]>('alertCategories')

      return res(
        ctx.json({
          items: response,
        })
      )
    }
  ),
]

export default alertCategoryHandlers
