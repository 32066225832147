import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useCountryCodesQuery = () => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/seed-data/country-codes'],
    KyError
  >('getCountryCodes', getCountryCodes)

  return query
}

export default useCountryCodesQuery

const getCountryCodes = () => typedOrgsApi.get('/api/seed-data/country-codes')
