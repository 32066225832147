import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useActivateModuleMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/activate'],
    activateModuleArgs
  >({
    mutationFn: activateModule,
    successMsg: t('Module activated'),
    additionalCachesToInvalidate: ['getPermissions'],
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          activateModuleArgs
        >((oldCache, { moduleId }) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items?.map((module) => {
                  if (module.id === moduleId)
                    return {
                      ...module,
                      activeInfo: {
                        ...module.activeInfo,
                        active: true,
                      },
                    }

                  return module
                }),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useActivateModuleMutation

type activateModuleArgs = {
  moduleId: string
}

const activateModule = ({ moduleId }: activateModuleArgs) =>
  typedOrgsApi.post('/api/modules/{moduleId}/activate', {
    pathParams: { moduleId },
  })
