/**
 * Converts a SubscriberDisplay object to a SubscriberProfile.
 * Used for the mock API because each type is stored separately in the mock DB
 * @param {SubscriberDisplay} subscriberDisplay
 * @returns {SubscriberProfile}
 */
const subscriberDisplayToProfile = (
  subscriberDisplay: SubscriberDisplay
): SubscriberProfile => ({
  id: subscriberDisplay.id,
  version: '',
  type: 'HCO',
  sourceInformation: {
    creationSource: 'ADDI',
  },
  status: '' + subscriberDisplay.status,
})

export default subscriberDisplayToProfile
