import React from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from 'elements'
import { useParams } from 'react-router-dom'
import { useAPIQuery } from 'hooks'
import { useSubscriberQuery } from 'hooks/organizations'
import { useMedicationDetailsQuery } from 'hooks/medications'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  MedicationSelect,
  SubscriberMedicationInfoCard,
  SubscriberMedicationReminderList,
} from 'components/subscriber-medications'
import tw from 'twin.macro'
import _ from 'lodash'

const SubscriberMedicationDetails = () => {
  const { t } = useTranslation()

  const { subscriberId, medicationId } = useParams()

  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const subscriber = useSubscriberQuery(subscriberId)

  const subscriberMedicationQuery = useAPIQuery('subscriberMedication', {
    pathParams: {
      subscriberId,
      medicationId,
    },
  })

  const medicationDetails = useMedicationDetailsQuery({
    medicationId:
      typeof subscriberMedicationQuery.data?.medicationMaster === 'string'
        ? subscriberMedicationQuery.data.medicationMaster
        : '',
  })

  return (
    <Layout
      title={
        <TitleContainer>
          <Title>{t('Medication')}:</Title>
          <MedicationSelect />
        </TitleContainer>
      }
      description={t('View subscriber medication details') + '.'}
      isLoading={
        subscriber.isLoading ||
        subscriberMedicationQuery.isLoading ||
        medicationDetails.isLoading
      }
      isUnauthorized={subscriber.data?.organization && !assumedOrganizationRole}
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${subscriber.data?.person.firstName} ${subscriber.data?.person.lastName}`,
          route: `../../../`,
        },
        {
          name: t('Subscriber Settings'),
          route: `../../`,
        },
        { name: t('Manage Medications'), route: `../` },
        { name: `${_.startCase(medicationDetails.data?.brandNameBase)}` },
      ]}
    >
      <SubscriberMedicationInfoCard
        subscriberMedication={subscriberMedicationQuery.data}
        medicationDetails={medicationDetails.data}
      />

      <SubscriberMedicationReminderList />
    </Layout>
  )
}

export default SubscriberMedicationDetails

const TitleContainer = tw.div`flex gap-2 items-end`

const Title = tw.h1`text-2xl font-bold`
