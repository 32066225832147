import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { OrgAdminVitalsThresholdsGrid } from 'components/settings-vital-thresholds'
import { Empty, Button } from 'atlas'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import { useDelay } from 'hooks'
import { useOrgVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import { useOrgVitalsThresholdsQuery } from 'hooks/vitals/thresholds'
import tw from 'twin.macro'
import _ from 'lodash'

const OrgAdminVitalsThresholdsSettings = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const delay = useDelay()

  const orgVitalAssignmentsQuery = useOrgVitalAssignmentsQuery(
    assumedOrganizationRole?.orgID
  )

  const orgVitalsThresholdsQuery = useOrgVitalsThresholdsQuery(
    assumedOrganizationRole?.orgID
  )

  const orgVitalThresholdsSorted = _.orderBy(
    orgVitalsThresholdsQuery.data,
    (vitalThreshold) => vitalThreshold.vitalSign.name
  )

  return (
    <Layout
      title={t('Vitals Thresholds')}
      description={t('Manage organization-level vital sign thresholds.')}
      isUnauthorized={!assumedOrganizationRole}
      isEmpty={
        !orgVitalAssignmentsQuery.data ||
        orgVitalAssignmentsQuery.data.assignedVitalSigns.length === 0
      }
      emptyComponent={
        // If there are no orgVitalSigns show a call to action to assign Vital Signs
        <VitalSignEmpty
          title={t('No Data Found')}
          delay={delay()}
          description={t(
            'No vital signs have been assigned to this organization'
          )}
          callToAction={
            <Button
              type="primary-filled"
              to="/organization-settings/vital-sign-assignments"
            >
              {t('Go To Vital Signs')}
            </Button>
          }
        />
      }
      isLoading={orgVitalAssignmentsQuery.isLoading}
      breadcrumbs={[
        {
          name: t('Organization Settings'),
          route: '/organization-settings',
        },
        {
          name: t('Vitals Thresholds'),
        },
      ]}
    >
      {
        <FadeInSlideDown delay={delay()}>
          <OrgAdminVitalsThresholdsGrid thresholds={orgVitalThresholdsSorted} />
        </FadeInSlideDown>
      }
    </Layout>
  )
}

export default OrgAdminVitalsThresholdsSettings

const VitalSignEmpty = tw(Empty)`border-2 border-dashed rounded flex-grow`
