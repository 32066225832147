import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import { useParams } from 'react-router-dom'
import { useSubscriberQuery, useSubscribersQuery } from 'hooks/organizations'
import { useOrgVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import { SubscriberVitalsThresholdsGrid } from 'components/subscriber-settings'
import { useDelay } from 'hooks'
import { SubscriberSelect } from 'components/subscribers'
import tw from 'twin.macro'

const SubscriberVitalsThresholdsSettings = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const subscribers = useSubscribersQuery()
  const { subscriberId } = useParams()

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  const {
    isLoading: isVitalSignsLoading,
    data: orgVitalSigns,
  } = useOrgVitalAssignmentsQuery(
    // if the subscriber has an org, use the organization's vital assignments
    currentSubscriber?.organization
      ? assumedOrganizationRole?.orgID
      : // else if they're private pay, use the ECG's organization's vital assignments
        '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const { t } = useTranslation()

  const delay = useDelay()

  return (
    <Layout
      title={
        <div className="flex gap-2">
          <h1 className="text-2xl font-bold">{t('Vitals Thresholds')}:</h1>
          <SubscriberSelect />
        </div>
      }
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `/subscribers/${currentSubscriber?.id}`,
        },
        {
          name: t('Subscriber Settings'),
          route: `/subscribers/${currentSubscriber?.id}/settings`,
        },
        {
          name: t('Vitals Thresholds'),
        },
      ]}
      isLoading={isSubLoading || isVitalSignsLoading || subscribers.isLoading}
      isEmpty={!currentSubscriber}
      emptyComponent={
        <Empty
          title={t('Subscriber Not Found')}
          description={t(
            `Either this subscriber doesn't exist or you don't have access to view them`
          )}
        />
      }
      isUnauthorized={
        currentSubscriber?.organization && !assumedOrganizationRole
      }
      description={
        currentSubscriber?.organization
          ? t(
              'Customize the vital sign thresholds for this Subscriber. If no settings are configured, we will use your Organization’s defaults'
            )
          : t(
              'Customize the vital sign thresholds for this Subscriber. If no settings are configured, we will use ECG’s defaults'
            )
      }
    >
      {/* If there are no orgVitalSigns show a call to action to assign Vital Signs */}
      {!orgVitalSigns || orgVitalSigns.assignedVitalSigns.length === 0 ? (
        <VitalSignsEmpty
          title={t('No Data Found')}
          description={t(
            'No vital signs have been assigned to this organization'
          )}
          delay={delay()}
          callToAction={
            <Button
              type="primary-filled"
              to="/organization-settings/vital-sign-assignments"
            >
              Go To Vital Signs
            </Button>
          }
        />
      ) : (
        <FadeInSlideDown delay={delay()}>
          <SubscriberVitalsThresholdsGrid />
        </FadeInSlideDown>
      )}
    </Layout>
  )
}

export default SubscriberVitalsThresholdsSettings

const VitalSignsEmpty = tw(Empty)`border-2 border-dashed rounded flex-grow`
