import React, { useEffect, useState } from 'react'
import {
  RightPopup,
  TextField,
  Select,
  SelectItem,
  LoadingIcon,
} from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  useAddPermissionMutation,
  useUpdatePermissionMutation,
} from 'hooks/access-control/permissions'
import { useModulesQuery } from 'hooks/access-control/modules'
import tw from 'twin.macro'

type CreateEditPermissionFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  permission?: Permission
  module?: Module
}

const CreateEditPermissionForm = ({
  isFormOpen,
  setIsFormOpen,
  permission,
  module,
}: CreateEditPermissionFormProps) => {
  const { data: modules, isLoading: isLoadingModules } = useModulesQuery()
  const { t } = useTranslation()
  const formMethods = useForm<CreatePermissionForm>()
  const { handleSubmit, errors, reset, watch } = formMethods
  const [action, setAction] = useState<'add-another' | 'close'>()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) reset({ ...permission })
  }, [isFormOpen])

  const {
    mutate: addPermission,
    isLoading: isAddPermissionLoading,
  } = useAddPermissionMutation()

  const {
    mutate: updatePermission,
    isLoading: isUpdatePermissionLoading,
  } = useUpdatePermissionMutation()

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={permission ? t('Edit Permission') : t('Create Permissions')}
      controls={
        <>
          {/* Create & Add Another Button (only shown for creating a permission) */}
          {!permission ? (
            <>
              <Button
                type="primary-filled"
                disabled={action !== 'add-another' && isAddPermissionLoading}
                isLoading={action === 'add-another' && isAddPermissionLoading}
                onClick={handleSubmit((formData) => {
                  setAction('add-another')
                  addPermission(
                    {
                      ...formData,
                      ...(module && { moduleId: module.id }),
                    },
                    {
                      onSuccess: () => {
                        reset({
                          name: '',
                          code: '',
                          description: '',
                          moduleId: watch('moduleId'),
                        })
                      },
                    }
                  )
                })}
              >
                {t('Create & Add Another')}
              </Button>
              &nbsp;
            </>
          ) : null}
          {/* Create & Close / Update Button (shown for both) */}
          {permission ? (
            <Button
              type="primary-filled"
              isLoading={isUpdatePermissionLoading}
              onClick={handleSubmit((formData) => {
                updatePermission(
                  {
                    permissionId: permission.id,
                    req: Object.entries(formData).map(
                      ([fieldKey, fieldValue]) => ({
                        value: fieldValue || '',
                        path: `/${fieldKey}`,
                        op: 'replace',
                      })
                    ),
                  },
                  {
                    onSuccess: () => {
                      setIsFormOpen(false)
                    },
                  }
                )
              })}
            >
              {t('Update')}
            </Button>
          ) : (
            <Button
              type="primary-filled"
              disabled={action !== 'close' && isAddPermissionLoading}
              isLoading={action === 'close' && isAddPermissionLoading}
              onClick={handleSubmit((formData) => {
                setAction('close')
                addPermission(
                  {
                    ...formData,
                    ...(module && { moduleId: module.id }),
                  },
                  {
                    onSuccess: () => {
                      setIsFormOpen(false)
                    },
                  }
                )
              })}
            >
              {t('Create & Close')}
            </Button>
          )}
          &nbsp;
          {/* Cancel Button (shown for both) */}
          <Button
            type="secondary"
            disabled={isAddPermissionLoading || isUpdatePermissionLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {isLoadingModules ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          {/* If no modules are found instead of showing form show an empty with button to create modules */}
          {modules?.items?.length && modules.items.length > 0 ? (
            <form>
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Permission Name is required') }}
                name="name"
                label={t('Permission Name')}
                fullWidth
                error={errors.name?.message}
                required
              />

              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Permission Code is required') }}
                name="code"
                label={t('Permission Code')}
                fullWidth
                error={errors.code?.message}
                required
              />

              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="description"
                label={t('Description')}
                fullWidth
                multiline
                rows={4}
                error={errors.description?.message}
              />
              {!module ? (
                <Controller
                  as={
                    <SelectPermission
                      name="moduleId"
                      label="Module"
                      variant="outlined"
                    >
                      {modules.items
                        // Only display active modules
                        .filter(
                          (module) =>
                            module.activeInfo.active ||
                            //  Also display module that is already attached if applicable
                            permission?.module.id === module.id
                        )
                        .map((module, index) => (
                          <SelectItem value={module.id} key={index}>
                            {module.name}
                          </SelectItem>
                        ))}
                    </SelectPermission>
                  }
                  name="moduleId"
                  defaultValue={permission?.module.id || modules.items[0]?.id}
                  //@ts-expect-error it's fine if the required message is undefined
                  rules={{ required: t('Module is required') }}
                  error={errors.moduleId?.message}
                />
              ) : null}
            </form>
          ) : (
            <Empty
              title={t('No Data Found')}
              description={t(
                'At least one module must exist in order to create permissions'
              )}
              callToAction={
                <Button type="primary-filled" to="/access-control/modules">
                  {t('Create Modules')}
                </Button>
              }
            />
          )}
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default CreateEditPermissionForm

const SelectPermission = tw(Select)`mb-6`
