import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { generate } from 'generate-password'
import { CreatePartnerFormBasicInformation } from 'components/partners'
import tw from 'twin.macro'

const CreatePartnerForm = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const formMethods = useForm<CreatePartnerForm>({
    defaultValues: {
      allowSubscriberAccess: false,
      account: {
        email: '',
        password: generate({
          length: 10,
          numbers: true,
          symbols: true,
          lowercase: true,
          uppercase: true,
          excludeSimilarCharacters: true,
          strict: true,
        }),
      },
      person: {
        salutation: 1,
        firstName: '',
        lastName: '',
        suffix: '',
        emailAddress: '',
      },
    },
  })
  return (
    <Layout
      title={t('Create Partner')}
      breadcrumbs={[
        {
          name: t('Partners'),
          route: '/partners',
        },
        {
          name: t('Partner List'),
          route: '/partners/list',
        },
        {
          name: t('Create Partner'),
        },
      ]}
      isUnauthorized={!!assumedOrganizationRole}
    >
      <CreatePartnerFormContainer>
        <FormProvider {...formMethods}>
          <CreatePartnerFormBasicInformation />
        </FormProvider>
      </CreatePartnerFormContainer>
    </Layout>
  )
}

export default CreatePartnerForm

const CreatePartnerFormContainer = tw.div`flex flex-col flex-grow`
