import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, webhooksApi, handleQueryError } from 'utils'

const useRemoveWebhookEventMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    RemoveWebhookEventResponse,
    KyError,
    removeWebhookEventArgs,
    GetWebhookSubscriptionResponse | undefined
  >(removeWebhookEvent, {
    onMutate: ({ subscriptionId, organizationId, eventId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<GetWebhookSubscriptionResponse>([
        'getWebhookSubscription',
        organizationId,
        subscriptionId,
      ])

      // optimistic update
      queryCache.setQueryData<GetWebhookSubscriptionResponse | undefined>(
        ['getWebhookSubscription', organizationId, subscriptionId],
        (old) => {
          if (!old) return

          const newValue: GetWebhookSubscriptionResponse = {
            ...old,
            events: old.events.filter((event) => event.id !== eventId),
          }

          return newValue
        }
      )

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Webhook event removed') })
    },
    onError: async (error, { organizationId, subscriptionId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetWebhookSubscriptionResponse | undefined>(
        ['getWebhookSubscription', organizationId, subscriptionId],
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: (_, error, req) => {
      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries([
        'getWebhookSubscriptions',
        req.organizationId,
      ])
      queryCache.invalidateQueries([
        'getWebhookSubscription',
        req.organizationId,
        req.subscriptionId,
      ])
    },
  })

  return mutation
}

export default useRemoveWebhookEventMutation

type removeWebhookEventArgs = {
  organizationId: string
  subscriptionId: string
  eventId: string
}

const removeWebhookEvent = async ({
  organizationId,
  subscriptionId,
  eventId,
}: removeWebhookEventArgs) => {
  const result = await webhooksApi
    .delete(
      `api/organizations/${organizationId}/webhook-subscriptions/${subscriptionId}/events/${eventId}`
    )
    .json<RemoveWebhookEventResponse>()

  return result
}
