import Icon from 'elements/Icon'
import { useNavigate } from 'hooks'
import React from 'react'
import tw, { styled } from 'twin.macro'

export type ButtonProps = {
  /**
   * The contents of the Button.
   */
  children: React.ReactNode

  /**
   * Style preset for the Button. This only effects the style of the Button, no functionality is changed.
   */
  type?:
    | 'success'
    | 'success-filled'
    | 'primary'
    | 'primary-filled'
    | 'primary-link'
    | 'danger-link'
    | 'secondary'
    | 'danger'
    | 'danger-filled'

  /**
   * Callback function called when the Button is clicked. *Note: this also adds "clickable" styles.*
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void

  /**
   * Relative URL path the Button should navigate the user to when clicked. This can be used in conjunction with the onClick handler.
   */
  to?: Parameters<ReturnType<typeof useNavigate>>[0]

  /**
   * Disables the Button, preventing click events and showing disabled styles.
   */
  disabled?: boolean

  /**
   * Disables the Button, preventing click events and showing loading styles.
   */
  isLoading?: boolean

  /**
   * data-testid property used for testing-library.
   */
  ['data-testid']?: string

  /**
   * Sets the "type" prop of the semantic HTML button itself. Used to set the aria role of the Button.
   */
  htmlType?: 'button' | 'submit' | 'reset'

  /**
   * Appends to the className of the outer-most element of the component. *Note: never directly use this prop, always opt for styled-components.*
   */
  className?: string
}

const Button = ({
  'data-testid': dataTestId,
  children,
  to,
  onClick,
  disabled = false,
  isLoading = false,
  type = 'primary-filled',
  htmlType = 'button',
  className,
}: ButtonProps) => {
  const navigate = useNavigate()

  const onButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // if disabled, don't do anything (used for integration tests)
    if (disabled || isLoading) {
      return
    }

    // call the user provided function (if exists)
    if (onClick) {
      onClick(e)
    }

    // push to history (if 'to' prop is provided)
    if (to) {
      navigate(to)
    }
  }

  const buttonProps = {
    'data-testid': dataTestId,
    to,
    onClick: onButtonClick,
    disabled: disabled || isLoading,
    isLoading,
    type: htmlType,
    className,
  }

  if (type === 'success-filled') {
    return (
      <SuccessFilledButton {...buttonProps}>
        <Centered>
          {isLoading ? <Loading type="loading" /> : null}
          {children}
        </Centered>
      </SuccessFilledButton>
    )
  }

  if (type === 'success') {
    return (
      <SuccessButton {...buttonProps}>
        <Centered>
          {isLoading ? <Loading type="loading" /> : null}
          {children}
        </Centered>
      </SuccessButton>
    )
  }

  if (type === 'primary-filled') {
    return (
      <PrimaryFilledButton {...buttonProps}>
        <Centered>
          {isLoading ? <Loading type="loading" /> : null}
          {children}
        </Centered>
      </PrimaryFilledButton>
    )
  }

  if (type === 'primary') {
    return (
      <PrimaryButton {...buttonProps}>
        <Centered>{children}</Centered>
      </PrimaryButton>
    )
  }

  if (type === 'primary-link') {
    return (
      // @ts-expect-error TODO: fix this type
      <PrimaryLink {...buttonProps} role="button">
        {children}
      </PrimaryLink>
    )
  }

  if (type === 'danger-link') {
    return (
      // @ts-expect-error TODO: fix this type
      <DangerLink {...buttonProps} role="button">
        {children}
      </DangerLink>
    )
  }

  if (type === 'secondary') {
    return (
      <SecondaryButton {...buttonProps}>
        <Centered>
          {isLoading ? <Loading type="loading" /> : null}
          {children}
        </Centered>
      </SecondaryButton>
    )
  }

  if (type === 'danger') {
    return (
      <DangerButton {...buttonProps}>
        <Centered>
          {isLoading ? <Loading type="loading" /> : null}
          {children}
        </Centered>
      </DangerButton>
    )
  }

  // default: danger-filled
  return (
    <DangerFilledButton {...buttonProps}>
      <Centered>
        {isLoading ? <Loading type="loading" /> : null}
        {children}
      </Centered>
    </DangerFilledButton>
  )
}

export default Button

const Centered = tw.span`flex justify-center whitespace-nowrap items-center`

const Loading = tw(Icon)`mr-2  animate-spin`

const SuccessFilledButton = styled.button<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`px-5 py-2 bg-emerald-400 text-white rounded-lg transition-all outline-none focus:outline-none focus:ring-2 focus:ring-emerald-300 disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:bg-emerald-500`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`

const SuccessButton = styled.button<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`px-5 py-2 bg-emerald-100 text-emerald-900 rounded-lg transition-all outline-none focus:outline-none focus:ring-2 focus:ring-emerald-400 disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:bg-emerald-200`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`

const PrimaryFilledButton = styled.button<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`px-5 py-2 bg-blue-400 text-white rounded-lg transition-all outline-none focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:bg-blue-500`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`

const PrimaryButton = styled.button<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`px-5 py-2 bg-blue-100 text-blue-900 rounded-lg transition-all outline-none focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:bg-blue-200`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`

const PrimaryLink = styled.a<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`text-blue-500 rounded-lg cursor-pointer disabled:opacity-50`}
  ${({ disabled }) =>
    disabled
      ? tw`cursor-not-allowed! opacity-50`
      : tw`hover:text-blue-700 hover:underline`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`

const DangerLink = styled.a<{
  disabled: boolean
  className?: string
}>`
  ${tw`text-red-400 rounded-lg cursor-pointer disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:text-red-500`}
  ${({ className }) => className}
`

const SecondaryButton = styled.button<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`px-5 py-2 bg-gray-100 text-gray-800 rounded-lg transition-all outline-none focus:outline-none focus:ring-2 focus:ring-gray-300 disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:bg-gray-200`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`

const DangerButton = styled.button<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`px-5 py-2 bg-red-100 hover:bg-red-200 text-red-900 rounded-lg transition-all outline-none focus:outline-none focus:ring-2 focus:ring-red-300 disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:bg-red-200`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`

const DangerFilledButton = styled.button<{
  disabled: boolean
  isLoading: boolean
  className?: string
}>`
  ${tw`px-5 py-2 bg-red-400 hover:bg-red-500 text-white rounded-lg transition-all outline-none focus:outline-none focus:ring-2 focus:ring-red-300 flex items-center disabled:opacity-50`}
  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed` : tw`hover:bg-red-500`}
  ${({ isLoading }) => isLoading && tw`align-bottom`}
  ${({ className }) => className}
`
