import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { orgsApi } from 'utils'

const useDeletePartnerTypeCommissionMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    DeletePartnerTypeCommissionResponse,
    DeletePartnerTypeCommissionProps
  >({
    mutationFn: deletePartnerTypeCommissions,
    successMsg: t('Commission deleted'),
    // invalidate all indirect commissions caches
    additionalCachesToInvalidate: ['getPartnerTypeIndirectCommissions'],
    optimisticUpdates: [
      {
        cacheKey: ({ partnerTypeId }) => [
          'getPartnerTypeDirectCommissions',
          partnerTypeId,
        ],
        updateFn: updateFnConstructor<
          GetPartnerTypeCommissionsResponse,
          DeletePartnerTypeCommissionProps
        >((oldCache, { commissionId }) =>
          oldCache
            ? oldCache.filter((commission) => commission.id !== commissionId)
            : oldCache
        ),
      },
    ],
  })

  return mutation
}

export default useDeletePartnerTypeCommissionMutation

type DeletePartnerTypeCommissionProps = {
  partnerTypeId: string
  commissionId: string
}

const deletePartnerTypeCommissions = async ({
  partnerTypeId,
  commissionId,
}: DeletePartnerTypeCommissionProps) => {
  const result = await orgsApi
    .delete(
      `api/partner-types/${partnerTypeId}/direct-commissions/${commissionId}`
    )
    .json<DeletePartnerTypeCommissionResponse>()

  return result
}
