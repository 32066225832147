import React from 'react'
import { Icon } from 'elements'
import { useNavigate } from 'hooks'
import tw, { styled } from 'twin.macro'

type StatisticProps = {
  icon: IconType
  iconColor: IconColor
  label: string
  value?: string | number
  actions?: React.ReactNode
  className?: string
  to?: Parameters<ReturnType<typeof useNavigate>>[0]
}

const Statistic = ({
  icon,
  label,
  value,
  actions,
  iconColor,
  to,
  className,
}: StatisticProps) => {
  const navigate = useNavigate()

  return (
    <Container className={className} to={to} onClick={() => to && navigate(to)}>
      <div className="flex justify-center items-center mr-4">
        <Icon
          type={icon}
          className={`w-16 h-16 p-4 rounded-lg ${iconColors[iconColor]}`}
        />
      </div>
      <div className="flex flex-col justify-center">
        <h3 className="text-gray-500">{label}</h3>
        <h4 className="font-semibold text-xl leading-5">{value ?? '-'}</h4>
        {actions}
      </div>
    </Container>
  )
}

export default Statistic

const iconColors = {
  red: 'bg-red-100 text-red-500',
  purple: 'bg-purple-100 text-purple-500',
  green: 'bg-green-100 text-green-500',
  yellow: 'bg-yellow-100 text-yellow-500',
  cyan: 'bg-cyan-100 text-cyan-500',
  orange: 'bg-orange-100 text-orange-500',
  grey: 'bg-gray-100 text-gray-500',
}

const Container = styled.div<{
  to?: Parameters<ReturnType<typeof useNavigate>>[0]
  className?: string
}>(({ to, className }) => [
  tw`bg-white flex p-4 rounded border border-gray-200 transition-all`,
  to && tw`cursor-pointer hover:shadow-lg`,
  className,
])
