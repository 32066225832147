import { FadeInSlideDown } from 'animations'
import clsx from 'clsx'
import {
  Icon,
  LoadingIcon,
  RadioGroup,
  Select,
  SelectItem,
  TextField,
} from 'elements'
import { Button } from 'atlas'
import { generate } from 'generate-password'
import { useDelay } from 'hooks'
import { useSalutationTypesQuery } from 'hooks/seed-data'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { emailValidation } from 'utils'
import { useCreatePersonMutation } from 'hooks/people'

type CreateStaffFormBasicInformationProps = {
  nextStep: () => void
  className?: string
  setUrlParams: (urlParams: { personId: string }) => void
}

const CreateStaffFormBasicInformation = ({
  nextStep,
  className,
  setUrlParams,
}: CreateStaffFormBasicInformationProps) => {
  const { t } = useTranslation()
  const delay = useDelay()

  const {
    data: salutationTypes,
    isLoading: isLoadingSalutationTypes,
  } = useSalutationTypesQuery()

  const { errors, watch, trigger, setValue, getValues } = useFormContext<
    CreateStaffMemberForm & {
      person: CreatePersonForm
      account: { email: string; password: string }
    }
  >()

  const createPerson = useCreatePersonMutation()

  const [passwordType, setPasswordType] = useState<string>('Auto-Generated')
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false)
  const [contactEmailTouched, setContactEmailTouched] = useState<boolean>(false)
  const [accountEmailTouched, setAccountEmailTouched] = useState<boolean>(false)

  useEffect(() => {
    if (passwordType === 'Manual') setValue('account.password', '')
    if (passwordType === 'Auto-Generated') {
      setValue(
        'account.password',
        generate({
          length: 10,
          numbers: true,
          symbols: true,
          lowercase: true,
          uppercase: true,
          excludeSimilarCharacters: true,
          strict: true,
        })
      )
    }
  }, [passwordType])
  if (isLoadingSalutationTypes || !salutationTypes)
    return <LoadingIcon className={className} />

  return (
    <div className={className}>
      <FadeInSlideDown delay={delay()}>
        <SubSectionContainer>
          <SubSectionHeader>{t('Personal Information')}</SubSectionHeader>
          <PersonalInfoContainer>
            <Controller
              as={
                <Select
                  label={t('Prefix')}
                  variant="outlined"
                  fullWidth={false}
                  selectClassName="w-24"
                >
                  {salutationTypes.items?.map((salutation, index) => (
                    <SelectItem value={salutation.id} key={index}>
                      {salutation.name}
                    </SelectItem>
                  ))}
                </Select>
              }
              name="person.salutation"
              defaultValue={salutationTypes.items?.[0].id}
            />
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.firstName"
              label={t('First Name')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('First Name is required') }}
              error={errors.person?.firstName?.message}
              className="w-56 mt-4"
              required
            />
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.lastName"
              label={t('Last Name')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Last Name is required') }}
              error={errors.person?.lastName?.message}
              className="w-56 mt-4"
              required
            />
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.suffix"
              label={t('Suffix')}
              className="w-20 mt-4"
            />
          </PersonalInfoContainer>
        </SubSectionContainer>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <SubSectionContainer>
          <SubSectionHeader>{t('Contact Information')}</SubSectionHeader>
          <ContactInfoContainer>
            <Controller
              render={({ onChange, value, name, ref }) => (
                <TextField
                  onChange={(e) => {
                    // If contact email field hasn't been modified mimic this value
                    if (!accountEmailTouched)
                      setValue('account.email', e.target.value)
                    return onChange(e)
                  }}
                  value={value}
                  name={name}
                  ref={ref}
                  className="w-72 mt-4"
                  label={t('Contact Email Address')}
                  onFocus={() => setContactEmailTouched(true)}
                  error={errors.person?.contactEmailAddress?.message}
                  required
                />
              )}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.contactEmailAddress"
              rules={{
                //@ts-expect-error it's fine if the required message is undefined
                required: t('Contact Email is required'),
                pattern: {
                  value: emailValidation,
                  message: 'Invalid email format',
                },
              }}
            />
          </ContactInfoContainer>
        </SubSectionContainer>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <SubSectionContainer>
          <AccountInfoHeader>{t('Account Information')}</AccountInfoHeader>
          <AccountInfoTitle>
            {`${t(
              'This information will be emailed to the staff member once the user has been created'
            )}. ${t(
              'They will then be able to login to ADDI with these credentials'
            )}.`}
          </AccountInfoTitle>
          <Controller
            render={({ onChange, value, name, ref }) => (
              <TextField
                onChange={(e) => {
                  // If contact email field hasn't been modified mimic this value
                  if (!contactEmailTouched)
                    setValue('person.contactEmailAddress', e.target.value)
                  return onChange(e)
                }}
                value={value}
                name={name}
                ref={ref}
                className="w-72 mt-4"
                label={t('Account Email Address')}
                onFocus={() => setAccountEmailTouched(true)}
                error={errors.account?.email?.message}
                required
              />
            )}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="account.email"
            rules={{
              //@ts-expect-error it's fine if the required message is undefined
              required: t('Account Email is required'),
              pattern: {
                value: emailValidation,
                message: 'Invalid email format',
              },
            }}
          />
          <PasswordContainer>
            <RadioContainer>
              <PasswordTitle>{`${t('Password Options')}: `}</PasswordTitle>
              <RadioGroup
                onChange={(value) => setPasswordType(value)}
                options={[
                  { value: 'Auto-Generated', label: t('Auto-Generated') },
                  { value: 'Manual', label: t('Manual') },
                ]}
                checked={passwordType}
              />
            </RadioContainer>
            <PasswordRelative>
              <Controller
                render={({ onBlur, onChange, value, name, ref }) => (
                  <TextField
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => {
                      setIsPasswordFocused(false)
                      onBlur()
                    }}
                    error={errors.account?.password?.message}
                    onChange={onChange}
                    value={value}
                    name={name}
                    ref={ref}
                    type="password"
                    label={t('Password')}
                    className={clsx(
                      'w-56',
                      passwordType === 'Auto-Generated' && 'hidden'
                    )}
                    required
                  />
                )}
                // this is purely here to prevent console.warns
                defaultValue={generate({
                  length: 10,
                  numbers: true,
                  symbols: true,
                  lowercase: true,
                  uppercase: true,
                  excludeSimilarCharacters: true,
                  strict: true,
                })}
                name="account.password"
                rules={{
                  //@ts-expect-error it's fine if the required message is undefined
                  required: t('Password is required'),
                  validate: {
                    isLongEnough: (value) =>
                      isPasswordLongEnough(value) ||
                      'Password requires at least 8 characters',
                    hasSpecialChar: (value) =>
                      isPasswordSpecialChar(value) ||
                      'Password requires a special character',
                    hasNumber: (value) =>
                      isPasswordNumber(value) || 'Password requires a number',
                    hasCapitalLetter: (value) =>
                      isPasswordCapitalLetter(value) ||
                      'Password requires a capital letter',
                    hasLowercaseLetter: (value) =>
                      isPasswordLowercaseLetter(value) ||
                      'Password requires a lowercase letter',
                  },
                }}
              />

              <PasswordCheck isFocused={!isPasswordFocused}>
                <PasswordInfoTitle>
                  {t('Password must include')}:
                </PasswordInfoTitle>
                <IconContainer>
                  <Icon
                    type={
                      isPasswordLongEnough(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordLongEnough(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('minimum of eight characters')}</p>
                </IconContainer>
                <IconContainer>
                  <Icon
                    type={
                      isPasswordSpecialChar(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordSpecialChar(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('special character')}</p>
                </IconContainer>

                <IconContainer>
                  <Icon
                    type={
                      isPasswordNumber(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordNumber(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('number')}</p>
                </IconContainer>

                <IconContainer>
                  <Icon
                    type={
                      isPasswordCapitalLetter(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordCapitalLetter(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('capital letter')}</p>
                </IconContainer>

                <IconContainer>
                  <Icon
                    type={
                      isPasswordLowercaseLetter(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordLowercaseLetter(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('lowercase letter')}</p>
                </IconContainer>
              </PasswordCheck>
            </PasswordRelative>
          </PasswordContainer>
        </SubSectionContainer>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <Button
          disabled={createPerson.isLoading}
          onClick={async () => {
            // If no errors for fields on this page, call nextStep
            if (
              await trigger([
                'person.firstName',
                'person.lastName',
                'person.contactEmailAddress',
                'account.email',
                'account.password',
              ])
            ) {
              const { person, account } = getValues()
              createPerson.mutateAsync(
                {
                  person,
                  account: {
                    ...account,
                    isAutoGenerated: passwordType === 'Auto-Generated',
                  },
                },
                {
                  onSuccess: (person) => {
                    nextStep()
                    /* save personId to useSearchParams*/
                    setUrlParams({ personId: person.id })
                  },
                }
              )
            }
          }}
          type="primary-filled"
          data-testid="basic-information-continue"
        >
          {t('Save & Continue')}
        </Button>
        <CancelButton type="primary-link" to="/staff">
          {t('Cancel')}
        </CancelButton>
      </FadeInSlideDown>
    </div>
  )
}

export default CreateStaffFormBasicInformation

const isPasswordLongEnough = (password: string): boolean =>
  !!password && password.length >= 8

const isPasswordSpecialChar = (password: string): boolean =>
  /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/.test(password)

const isPasswordNumber = (password: string): boolean => /\d/.test(password)

const isPasswordCapitalLetter = (password: string): boolean =>
  /[A-Z]/.test(password)

const isPasswordLowercaseLetter = (password: string): boolean =>
  /[a-z]/.test(password)

const CancelButton = tw(Button)`ml-5`

const SubSectionContainer = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const SubSectionHeader = tw.h3`text-xl font-semibold mb-2`

const AccountInfoHeader = tw.h3`text-xl font-semibold`

const PersonalInfoContainer = tw.div`flex gap-4`

const ContactInfoContainer = tw.div`flex gap-4`

const AccountInfoTitle = tw.p`text-gray-500`

const PasswordContainer = tw.div`flex gap-4`

const RadioContainer = tw.div`flex mt-1`

const PasswordTitle = tw.p`text-gray-600 mr-4 mt-3`

const PasswordRelative = tw.div`relative`

const PasswordCheck = styled.div<{ isFocused: boolean }>(({ isFocused }) => [
  tw`bg-white border shadow-lg transition-all p-2 absolute bottom-24`,
  isFocused && tw`invisible`,
])

const PasswordInfoTitle = tw.p`font-semibold`

const IconContainer = tw.div`flex`
