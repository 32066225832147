import { useQueryClient, useMutation } from 'react-query'
import { typedOrgsApi, handleQueryError, success, isPresent } from 'utils'
import { useTranslation } from 'react-i18next'

const useUpdatePolicyGroupMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/policy-groups/{policyGroupId}'],
    KyError,
    EditPolicyGroupProps
  >(updatePolicyGroup, {
    onSuccess: (returnedPolicyGroup) => {
      const policyGroups:
        | OrgsAPIResponse['GET']['/api/policy-groups']
        | undefined = queryCache.getQueryData(['getPolicyGroups'])

      if (policyGroups) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/policy-groups'] = {
          ...policyGroups,
          items:
            policyGroups.items?.map((policyGroup) => {
              if (policyGroup.id === returnedPolicyGroup.id) {
                const updatedPolicyGroup: PolicyGroup = {
                  ...returnedPolicyGroup,
                  policies: {
                    ...policyGroup.policies,
                  },
                }
                return updatedPolicyGroup
              }

              return policyGroup
            }) || [],
        }
        // immediately update the policyGroup list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/policy-groups']>(
          ['getPolicyGroups'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the policyGroup list is in sync with the server
      queryCache.invalidateQueries('getPolicyGroups')

      // notify the user
      success({ message: t('Policy Group updated') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useUpdatePolicyGroupMutation

type EditPolicyGroupProps = {
  existingPolicyGroup: PolicyGroup
  policyGroupData: CreatePolicyGroupForm
}

const updatePolicyGroup = async ({
  existingPolicyGroup,
  policyGroupData,
}: EditPolicyGroupProps) => {
  const policyGroupFields = {
    title: policyGroupData.title,
    code: policyGroupData.code,
    description: policyGroupData.description,
    policies: policyGroupData.policies,
  }

  const patchPolicyGroupReq: OrgsAPIRequest['PATCH']['/api/policy-groups/{policyGroupId}']['body'] = Object.entries(
    policyGroupFields
  )
    .filter((field): field is [string, string | string[]] =>
      isPresent(field[1])
    )
    .map(([fieldKey, fieldValue]) => ({
      value: fieldValue,
      path: `/${fieldKey}`,
      op: 'replace',
    }))

  const patchPolicyGroupResult = typedOrgsApi.patch(
    '/api/policy-groups/{policyGroupId}',
    {
      pathParams: { policyGroupId: existingPolicyGroup.id },
      body: patchPolicyGroupReq,
    }
  )

  return patchPolicyGroupResult
}
