import { error as errorToast } from 'utils'
import LogRocket from 'logrocket'
import ky from 'ky'

/**
 *  Handle query errors of different types (KyErrors, Javascript promise errors, Orders errors)
 *  Generate toast error element
 * @param errorResponse contains the `error` and an optional `message` override when displaying to the user
 */
const handleQueryError = async ({
  error,
  message,
}: {
  error: KyError | Error
  message?: string
}) => {
  if (isKyError(error)) handleKyError({ error, message })
  else {
    errorToast({ message: message || error.message })

    // if in production send to logRocket
    if (process.env.NODE_ENV === 'production')
      LogRocket.captureException(error, { tags: { priority: 'HIGH' } })
  }
}

type handleKyError = (args: { error: KyError; message?: string }) => void

/**
 * KyError handler, generates a toast containing an error message
 * @param errorResponse contains the KyError and an optional `message` override when displaying to the user
 */
const handleKyError: handleKyError = async ({ error, message }) => {
  const errorResponse = await error.response?.json().catch(() => {
    // json() will error when json() has already been called
    return undefined
  })

  // if no API body, use a generic error toast
  if (!errorResponse)
    return errorToast({ message: message || 'Oops! There was an error' })

  // get the errorMessage based off the kind of error object
  const errorMessage = isOrdersError(errorResponse)
    ? errorResponse.message
    : (typeof errorResponse.error.error === 'string'
        ? errorResponse.error.error
        : errorResponse.error.error?.message) ||
      (Array.isArray(errorResponse.error.message)
        ? errorResponse.error.message[0]
        : errorResponse.error.message)

  errorToast({ message: errorMessage })

  // if in production send to logRocket
  if (process.env.NODE_ENV === 'production')
    LogRocket.captureException(new Error(message || errorMessage), {
      tags: { type: 'API ERROR' },
    })
}

export default handleQueryError

/**
 * type guard to check if incoming error is KyError or normal javascript error
 * @param error
 * @returns ytpe assertion where `error` is a `KyError`
 */
const isKyError = (error: KyError | Error): error is KyError => {
  return error instanceof ky.HTTPError || error instanceof ky.TimeoutError
}

/**
 * type guard to check if incoming error is of the type returned from the Orders API
 * @param error
 * @returns orders error
 */
const isOrdersError = (error: ErrorResponse): error is OrdersError => {
  return !Object.prototype.hasOwnProperty.call(error, 'error')
}
