import React from 'react'
import { CustomSelect, Icon } from 'elements'
import { useAPIQuery, useNavigate } from 'hooks'
import { useLocation, useParams } from 'react-router-dom'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { colors } from 'atlas/Chip/Chip'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'

type ActionTypeToIcon = {
  [key in Exclude<SubscriberMedicationActionTypes, undefined>]: {
    icon: IconType
    color: keyof typeof colors
  }
}

const MedicationSelect = () => {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const { subscriberId, medicationId } = useParams()

  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const subscriberMedications = useAPIQuery('subscriberMedications', {
    pathParams: { subscriberId },
  })

  const subscriberMedicationQuery = useAPIQuery('subscriberMedication', {
    pathParams: {
      subscriberId,
      medicationId,
    },
  })

  const actionTypes = useAPIQuery('actionTypes')

  if (
    !subscriberMedications.data ||
    !subscriberMedicationQuery.data ||
    !actionTypes.data
  )
    return null

  return (
    <CustomSelect
      id={subscriberMedicationQuery.data.id}
      className="w-64"
      options={
        subscriberMedications.data.items?.map((medication) => ({
          value: medication.id,
          label: (
            <Label>
              <LabelIconContainer
                color={actionTypesToIcon[medication.actionType].color}
              >
                <LabelIcon
                  //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  type={actionTypesToIcon[medication.actionType || 'take'].icon}
                />
              </LabelIconContainer>
              {_.startCase(
                typeof medication.medicationMaster !== 'string'
                  ? medication.medicationMaster.brandNameBase ||
                      medication.medicationMaster.genericName
                  : medication.medicationMaster
              )}
            </Label>
          ),
        })) || [{ label: '', value: '' }]
      }
      placeholder={_.truncate(
        _.startCase(
          (subscriberMedications.data.items?.find(
            (medication) =>
              typeof medication.medicationMaster !== 'string' &&
              (medication.medicationMaster.id ||
                medication.medicationMaster) ===
                `${subscriberMedicationQuery.data.medicationMaster}`
          )?.medicationMaster as MedicationModel).brandNameBase
        ),
        { length: 26 }
      )}
      placeholderIcon={(() => {
        const subscriberMedication =
          subscriberMedications.data.items &&
          subscriberMedications.data.items.find(
            (medication) => medication.id === `${medicationId}`
          )

        return (
          <PlaceholderIconContainer
            color={
              subscriberMedication &&
              subscriberMedication.actionType in actionTypesToIcon
                ? actionTypesToIcon[subscriberMedication.actionType].color
                : 'blue'
            }
          >
            <Icon
              type={
                actionTypesToIcon[
                  subscriberMedications.data.items?.find(
                    (medication) => medication.id === `${medicationId}`
                  )?.actionType || 'take'
                ].icon
              }
            />
          </PlaceholderIconContainer>
        )
      })()}
      onChange={(e) =>
        navigate({
          pathname: pathname.replace(medicationId, e.value),
          searchParams: { role: assumedOrganizationRole?.orgID || '' },
        })
      }
      variant="thin"
    />
  )
}

export default MedicationSelect

const Label = tw.div`text-gray-700 hover:text-gray-900 leading-3 truncate w-full h-full`

const LabelIconContainer = styled.div<{ color: keyof typeof colors }>(
  ({ color }) => [
    tw`mr-2 bg-gray-100 rounded p-1 w-6 h-6 inline-block`,
    colors[color],
  ]
)

const PlaceholderIconContainer = styled.div<{ color?: keyof typeof colors }>(
  ({ color }) => [
    tw`inline-block rounded p-1 w-6 h-6 leading-3`,
    color && colors[color],
  ]
)

const LabelIcon = styled(Icon)`
  ${tw`color[inherit]`}
`
export const actionTypesToIcon: ActionTypeToIcon = {
  take: { icon: 'medications', color: 'blue' },
  chew: { icon: 'tablets', color: 'yellow' },
  place: { icon: 'medications', color: 'cyan' },
  inject: { icon: 'syringe', color: 'red' },
  inhale: { icon: 'medications', color: 'green' },
  spray: { icon: 'spray', color: 'orange' },
  put: { icon: 'medications', color: 'gray' },
  apply: { icon: 'lotion', color: 'purple' },
  insert: { icon: 'medications', color: 'lightblue' },
}
