import React from 'react'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/github'

type CodeViewerProps = {
  code: string
  language: Language
  noBackgroundColor?: boolean
}

const CodeViewer = ({ code, language, noBackgroundColor }: CodeViewerProps) => {
  return (
    <Highlight
      {...defaultProps}
      theme={theme}
      // JSON.stringify if an object is passed in and indent lines by two spaces
      code={code}
      language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={`overflow-auto p-2 ${className}`}
          style={{
            ...style,
            backgroundColor: noBackgroundColor
              ? 'none'
              : (style.backgroundColor as string),
          }}
        >
          {tokens.map((line, index) => (
            <div
              key={index}
              {...getLineProps({ line, key: index })}
              className="table-row"
            >
              <span className="select-none table-cell pr-2 text-gray-500">
                {index + 1}
              </span>
              <span className="table-cell">
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </span>
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}

export default CodeViewer
