import React, { useMemo, useState } from 'react'
import { SimpleList, Dialog, EllipsisDropdown } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useDeleteDisclaimerMutation } from 'hooks/disclaimers'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'

type DisclaimersListProps = {
  data: Disclaimer[]
  onEditDisclaimer: (policy: Disclaimer) => void
}

const DisclaimersList = ({ data, onEditDisclaimer }: DisclaimersListProps) => {
  const { t } = useTranslation()

  const tableData: Disclaimer[] = useMemo(() => {
    return data
  }, [data])

  return (
    <SimpleListContainer>
      <SimpleList
        data={tableData}
        baseDelay={0.15}
        emptyProps={{
          title: t('No Disclaimers Found'),
        }}
        renderItem={(item) => (
          <SimpleList.Item key={item.id}>
            <ListItemContainer>
              <ListItem>{item.disclaimer}</ListItem>
              <Ellipsis item={item} onEdit={() => onEditDisclaimer(item)} />
            </ListItemContainer>
          </SimpleList.Item>
        )}
      />
    </SimpleListContainer>
  )
}

export default DisclaimersList

type EllipsisProps = {
  item: Disclaimer
  onEdit: () => void
}

const Ellipsis = ({ item, onEdit }: EllipsisProps) => {
  const { t } = useTranslation()
  const { disclaimerTypeId } = useParams()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const {
    mutate: deleteDisclaimer,
    isLoading: isDeleteDisclaimerLoading,
  } = useDeleteDisclaimerMutation()

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: () => {
              onEdit()
            },
          },
          {
            label: t('Delete'),
            onClick: () => {
              setIsDialogOpen(true)
            },
          },
        ]}
        testid={`${item.disclaimer}-ellipsis`}
      />
      {isDialogOpen ? (
        <Dialog
          open={isDialogOpen}
          title={`${t('Remove Disclaimer')}?`}
          content={t('Are you sure you want to remove this disclaimer?')}
          actions={
            <>
              <Button
                type="danger-filled"
                isLoading={isDeleteDisclaimerLoading}
                onClick={() => {
                  deleteDisclaimer({
                    disclaimerId: item.id,
                    disclaimerTypeId,
                  })
                  setIsDialogOpen(false)
                }}
              >
                {t('Delete')}
              </Button>

              <Button
                onClick={() => {
                  setIsDialogOpen(false)
                }}
                type="secondary"
              >
                {t('Cancel')}
              </Button>
            </>
          }
        />
      ) : null}
    </div>
  )
}

const SimpleListContainer = tw.div`w-full mb-8`

const ListItemContainer = tw.div`flex justify-between mx-4 items-center`

const ListItem = tw.p`text-lg`
