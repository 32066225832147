import { handleQueryError } from 'utils'
import { QueryFunction, useQuery } from 'react-query'

const usePinnedOrganizationsQuery = () => {
  const query = useQuery<string[], KyError>(
    ['getPinnedOrganizations'],
    getPinnedOrganizations,
    {
      onError: (error) => {
        handleQueryError({ error })
      },
    }
  )
  return query
}

export default usePinnedOrganizationsQuery

type getPinnedOrganizationsArgs = QueryFunction<string[]>
const getPinnedOrganizations: getPinnedOrganizationsArgs = async () => {
  const result: string[] = JSON.parse(localStorage.getItem('orgPinned') || '[]')
  return result
}
