import { useTranslation } from 'react-i18next'
import { useMutation } from 'hooks'
import { typedOrgsApi } from 'utils'
import { updateFnConstructor } from 'hooks/useMutation'

const useUpdatePhoneMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/subscribers-profile/{subscriberId}/phone-numbers/{phoneNumberId}'],
    UpdateSubscriberPhoneProps
  >({
    mutationFn: updateSubscriberPhone,
    successMsg: t('Phone Number updated'),
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => ['getSubscriberPhones', subscriberId],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/phone-numbers'],
          UpdateSubscriberPhoneProps
        >((oldCache, { phoneForm, phoneNumberId }) =>
          oldCache
            ? oldCache.map((phone) =>
                phone.id === phoneNumberId
                  ? { id: phoneNumberId, ...phoneForm }
                  : phone
              )
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useUpdatePhoneMutation

type UpdateSubscriberPhoneProps = {
  phoneForm: SubscriberPhoneForm
  phoneNumberId: string
  subscriberId: string
}

const updateSubscriberPhone = ({
  phoneForm,
  phoneNumberId,
  subscriberId,
}: UpdateSubscriberPhoneProps) => {
  const req = Object.entries(phoneForm)
    .filter((field): field is [string, string] => !!field[1])
    .map(([fieldKey, fieldValue]) => ({
      value: fieldValue,
      path: `/${fieldKey}`,
      op: 'replace' as const,
    }))
  return typedOrgsApi.patch(
    '/subscribers-profile/{subscriberId}/phone-numbers/{phoneNumberId}',
    {
      pathParams: { phoneNumberId, subscriberId },
      body: req,
    }
  )
}
