import React, { useState } from 'react'
import { Layout, Select, SelectItem } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { useUserQuery } from 'hooks/user-profile'
import { useDisplayLanguageMutation } from 'hooks/user-profile'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { ChangePasswordForm } from 'components/user-profile'
import { FadeInSlideDown } from 'animations'
import { useDelay } from 'hooks'

const UserProfile = () => {
  const [isChangePasswordFormOpen, setIsChangePasswordFormOpen] = useState(
    false
  )
  const { data: user, isLoading } = useUserQuery()
  const { t } = useTranslation()
  const formMethods = useForm<{ language: Language }>()
  const { mutate: updateLanguagePreference } = useDisplayLanguageMutation()
  const { handleSubmit, control } = formMethods
  const delay = useDelay()

  return (
    <Layout
      title={t('User Profile')}
      isLoading={isLoading}
      controls={
        <Button
          type="primary-filled"
          onClick={() => setIsChangePasswordFormOpen(true)}
        >
          Change Password
        </Button>
      }
    >
      <Card delay={delay()}>
        <Grid>
          <Label>{t('Email')}</Label>
          <Value>{user?.email}</Value>

          <Label>{t('Name')}</Label>
          <Value>{`${user?.person.firstName} ${user?.person.lastName}`}</Value>

          <Label>{t('Password')}</Label>
          <Value>••••••••</Value>
        </Grid>
      </Card>

      <FadeInSlideDown delay={delay()}>
        <Header>{t('Language Preference')}</Header>
        <SubHeader>
          {t(
            'Please select a preferred language for your Dashboard, including date, time, and number formatting'
          )}
        </SubHeader>

        <FormProvider {...formMethods}>
          <form>
            <LanguageSettings>
              <Controller
                as={
                  <LanguageSelect name="language">
                    <SelectItem value="en-US">
                      &nbsp;🇺🇸&nbsp; English (US)
                    </SelectItem>
                    <SelectItem value="de-DE">&nbsp;🇩🇪&nbsp; German</SelectItem>
                  </LanguageSelect>
                }
                name="language"
                control={control}
                defaultValue={
                  // get default value to value from localStorage
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  (localStorage.getItem(
                    'user-profile__language'
                  ) as Language) || 'en-US'
                }
              />

              <Button
                tw="ml-2"
                onClick={handleSubmit((formData) => {
                  updateLanguagePreference(formData)
                })}
              >
                Save
              </Button>
            </LanguageSettings>
          </form>
        </FormProvider>
      </FadeInSlideDown>

      <ChangePasswordForm
        isFormOpen={isChangePasswordFormOpen}
        setIsFormOpen={setIsChangePasswordFormOpen}
      />
    </Layout>
  )
}

export default UserProfile

const Card = tw(
  FadeInSlideDown
)`bg-white p-5 rounded-lg border border-gray-200 mb-8`

const Grid = tw.div`grid grid-cols-3 gap-y-4`

const Label = tw.p`font-semibold`

const Value = tw.p`col-span-2 text-gray-700`

const Header = tw.div`text-xl font-semibold`

const SubHeader = tw.div`text-gray-700 mb-4`

const LanguageSettings = tw.div`flex items-center`

const LanguageSelect = styled(Select)`
  ${tw`w-96`}
`
