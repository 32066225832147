import React, { useState } from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { DisclaimerTypesList } from 'components/disclaimers'
import { useNavigate } from 'hooks'

const DisclaimerTypes = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [
    isDisclaimerTypeFormOpen,
    setIsDisclaimerTypeFormOpen,
  ] = useState<boolean>(false)

  return (
    <Layout
      title="Disclaimer Types"
      description={t(
        'Create and organize disclaimers and their associated translations within disclaimer types.'
      )}
      breadcrumbs={[
        { name: t('Settings'), route: '/settings' },
        { name: t('Disclaimer Types') },
      ]}
      controls={[
        {
          label: t('Create Disclaimer Type'),
          onClick: () => setIsDisclaimerTypeFormOpen(true),
        },
        {
          label: t('View Audit Trail'),
          onClick: () => navigate('audit-trail'),
          disabled: true,
        },
      ]}
    >
      <DisclaimerTypesList
        setIsDisclaimerTypeFormOpen={setIsDisclaimerTypeFormOpen}
        isDisclaimerTypeFormOpen={isDisclaimerTypeFormOpen}
      />
    </Layout>
  )
}

export default DisclaimerTypes
