import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useActivatePermissionMutation = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/permissions/{permissionId}/activate'],
    KyError,
    string
  >(activatePermission, {
    onSuccess: () => {
      queryClient.invalidateQueries('getPermissions')
      queryClient.invalidateQueries('getModules')

      success({ message: t('Permission activated') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useActivatePermissionMutation

const activatePermission = async (permissionId: string) => {
  const result = typedOrgsApi.post('/api/permissions/{permissionId}/activate', {
    pathParams: { permissionId },
  })

  return result
}

type ouGetPermissionsQueryToActivatePermissionArgs = {
  permissionId: string
}

export const ouGetPermissionsQueryToActivatePermission = ({
  permissionId,
}: ouGetPermissionsQueryToActivatePermissionArgs) => {
  const queryClient = useQueryClient()
  queryClient.setQueryData<
    OrgsAPIResponse['GET']['/api/permissions'] | undefined
  >(['getPermissions'], (old) => {
    if (!old) return undefined

    const newValue: OrgsAPIResponse['GET']['/api/permissions'] = {
      ...old,
      items:
        old.items?.map((permission) => {
          if (permission.id === permissionId)
            return {
              ...permission,
              activeInfo: {
                ...permission.activeInfo,
                active: true,
              },
            }

          return permission
        }) || [],
    }

    return newValue
  })
}

type ouGetModulesQueryToActivatePermissionArgs = {
  currentModuleId: string
  permissionId: string
}

export const ouGetModulesQueryToActivatePermission = ({
  currentModuleId,
  permissionId,
}: ouGetModulesQueryToActivatePermissionArgs) => {
  const queryClient = useQueryClient()
  queryClient.setQueryData<OrgsAPIResponse['GET']['/api/modules'] | undefined>(
    ['getModules'],
    (old) => {
      const currentModule = old?.items?.find(
        (module) => module.id === currentModuleId
      )

      if (!old || !currentModule) return undefined

      const newModuleValue: Module = {
        ...currentModule,
        permissions: currentModule.permissions?.map((permission) => {
          if (permission.id === permissionId) {
            return {
              ...permission,
              activeInfo: {
                ...permission.activeInfo,
                active: true,
              },
            }
          }

          return permission
        }),
      }

      const newValue: OrgsAPIResponse['GET']['/api/modules'] = {
        ...old,
        items: old.items?.map((module) => {
          if (module.id === currentModule.id) {
            return newModuleValue
          }

          return module
        }),
      }

      return newValue
    }
  )
}
