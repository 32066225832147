import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
// import { SubscriberVitalsAuditTrailList } from 'components/subscriber-settings'
import { useSubscriberQuery } from 'hooks/organizations'
import { useParams } from 'react-router-dom'

const SubscriberVitalsAuditTrail = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const { subscriberId } = useParams()

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  return (
    <Layout
      title={t('Vital Sign Assignments Audit Trail')}
      description={t(
        'Review all actions taken in relation to this subscriber’s vital signs.'
      )}
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `../../../`,
        },
        {
          name: t('Subscriber Settings'),
          route: `../../`,
        },
        {
          name: t('Vital Sign Assignments'),
          route: `../`,
        },
        {
          name: t('Audit Trail'),
        },
      ]}
      isUnauthorized={!assumedOrganizationRole}
      isLoading={isSubLoading}
    >
      {/* <SubscriberVitalsAuditTrailList /> */}
    </Layout>
  )
}

export default SubscriberVitalsAuditTrail
