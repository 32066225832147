import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useUpdateOrgVitalThresholdMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds'],
    KyError<ErrorResponse>,
    UpdateVitalThresholdsProps,
    | VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
    | undefined
  >(updateOrgVitalThreshold, {
    onMutate: (mutationProps) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
      >(['vitalsThresholds', mutationProps.orgId])

      // optimistic update the org vital thresholds query
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']['items']
      >(['vitalsThresholds', mutationProps.orgId], (oldThresholds) =>
        oldThresholds
          ? oldThresholds.map((threshold) =>
              threshold.vitalSign.id === mutationProps.vitalSign.id
                ? thresholdsFormToObject(mutationProps)
                : threshold
            )
          : [thresholdsFormToObject(mutationProps)]
      )

      return snapshot
    },
    onSuccess: () => {
      // Notify user of successful vital sign creation
      success({
        message: t('Vital Thresholds updated'),
      })
    },
    onError: async (error, { orgId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
        | undefined
      >(['vitalsThresholds', orgId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { orgId }) => {
      // refetch from API to make sure the new threshold is in sync with the server
      queryCache.invalidateQueries(['vitalsThresholds', orgId])
    },
  })

  return mutation
}

export default useUpdateOrgVitalThresholdMutation

type UpdateVitalThresholdsProps = {
  vitalThresholds: VitalThresholdsForm
  orgId: string
  vitalSign: VitalAssignment
}

// Post updated vital sign to backend
const updateOrgVitalThreshold = async ({
  vitalThresholds,
  orgId,
  vitalSign,
}: UpdateVitalThresholdsProps) => {
  const request: VitalsAPIRequest['PATCH']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']['body'] = vitalThresholds

  const result = await vitalsApi
    .patch(
      // TODO: orgID is hardcoded for now to work with mock API
      `organizations/${orgId}/vital-signs/${vitalSign.id}/thresholds`,
      {
        json: request,
      }
    )
    .json<
      VitalsAPIResponse['PATCH']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']
    >()

  return result
}

const thresholdsFormToObject = ({
  vitalThresholds,
  vitalSign,
}: UpdateVitalThresholdsProps): VitalThresholds => ({
  // @ts-expect-error bad swagger type
  vitalSign,
  thresholds: vitalThresholds.thresholds,
  normalHigh: vitalThresholds.normalHigh || 0,
  normalLow: vitalThresholds.normalLow || 0,
  disclaimers: vitalThresholds.acceptedDisclaimers,
})
