import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, vitalsApi, handleQueryError } from 'utils'

// used to update default reminder messages
const useReminderMessagesMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}'][],
    KyError,
    updateReminderMessageArgs,
    | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
    | undefined
  >(updateReminderMessage, {
    onSuccess: () => {
      success({ message: t('Reminder messages updated') })
    },
    onMutate: (data) => {
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
      >(['getReminderMessages', data.orgId])

      // optimistic update for reminder messages/en-US
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(['getReminderMessages', data.orgId], (oldData) => {
        if (!oldData?.items) return undefined
        return {
          items: oldData.items.map((reminderMessage) => ({
            ...reminderMessage,
            message:
              data.messages.find(
                (newReminder) =>
                  newReminder.reminderMessageId === reminderMessage.id
              )?.message || '',
          })),
        }
      })

      // optimistic update Reminder Translations
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(
        ['getReminderMessageTranslations', data.orgId, data.localeKey],
        (oldData) => {
          if (!oldData?.items) return undefined
          return {
            items: oldData.items.map((reminderMessage) => ({
              ...reminderMessage,
              message:
                data.messages.find(
                  (newReminder) =>
                    newReminder.messageTypeId === reminderMessage.messageType.id
                )?.message || '',
            })),
          }
        }
      )

      return snapshot
    },
    onError: (error, { orgId }, snapshot) => {
      //revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(['getReminderMessages', orgId], snapshot)
      handleQueryError({ error })
    },

    onSettled: (_, error, args) => {
      queryCache.invalidateQueries(['getReminderMessages', args.orgId])
      queryCache.invalidateQueries([
        'getReminderMessageTranslations',
        args.orgId,
        args.localeKey,
      ])
    },
  })

  return mutation
}

export default useReminderMessagesMutation

type updateReminderMessageArgs = {
  orgId?: string
  localeKey: string
  messages: Array<{
    messageTypeId: string
    message: string
    reminderMessageId?: string
  }>
}

// update default reminder
const updateReminderMessage = async (data: updateReminderMessageArgs) => {
  // update each reminder message

  const response = await Promise.all(
    data.messages.map(async (message) => {
      const requestBody: VitalsAPIRequest['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']['body'] = {
        localeKey: data.localeKey,
        message: message.message,
        messageTypeId: message.messageTypeId,
      }

      return await vitalsApi
        .patch(
          `organization/${data.orgId}/reminder-messages/${message.reminderMessageId}`,
          {
            json: requestBody,
          }
        )
        .json<
          VitalsAPIResponse['PATCH']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']
        >()
    })
  )
  return response
}
