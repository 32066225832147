import React from 'react'
import { Layout, WizardForm } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import {
  ContactInformation,
  Metadata,
  PersonalInformation,
  ResponsibleParties,
  SiteSettings,
} from 'components/subscriber-create'
import { useNavigate } from 'hooks'
import { useParams } from 'react-router'
import { useSubscriberQuery } from 'hooks/organizations'

const EditSubscriber = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { subscriberId } = useParams()

  const subscriberQuery = useSubscriberQuery(subscriberId)

  return (
    <Layout
      title={t('Edit Subscriber')}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '/subscribers',
        },
        {
          name: `${subscriberQuery.data?.person.firstName} ${subscriberQuery.data?.person.lastName}`,
          route: `/subscribers/${subscriberId}`,
        },
        {
          name: t('Subscriber Information'),
          route: '../',
        },
        {
          name: t('Edit Subscriber'),
        },
      ]}
      description={t('Edit subscriber information.')}
      isLoading={subscriberQuery.isLoading}
    >
      <FormContainer>
        <WizardForm
          formName={t('Subscriber Form')}
          onComplete={() => {
            navigate('../')
          }}
          isEditForm
        >
          {[
            {
              label: t('Personal Information'),
              icon: 'user',
              component: <PersonalInformation />,
            },
            {
              label: t('Contact Information'),
              icon: 'phone',
              component: <ContactInformation />,
            },
            {
              label: t('Responsible Parties'),
              icon: 'subscribers',
              component: <ResponsibleParties />,
            },
            // { label: t('Insurance Information'), icon: 'clipboard' },
            // { label: t('Service Providers'), icon: 'organizations' },
            // { label: t('Medical Providers'), icon: 'organizations' },
            // { label: t('Medical Information'), icon: 'medications' },
            {
              label: t('Site Settings'),
              icon: 'tool',
              component: <SiteSettings />,
            },
            {
              label: t('Metadata'),
              icon: 'disclaimers',
              component: <Metadata />,
            },
          ]}
        </WizardForm>
      </FormContainer>
    </Layout>
  )
}

export default EditSubscriber

const FormContainer = tw.div`flex flex-row flex-grow`
