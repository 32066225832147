import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useAneltoCredentialsQuery = (organizationId: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/anelto-credentials'],
    KyError
  >(['getAneltoCredentials', organizationId], () =>
    getAneltoCredentials({ organizationId })
  )
  return query
}

export default useAneltoCredentialsQuery

type getAneltoCredentialsArgs = {
  organizationId: string
}
const getAneltoCredentials = ({ organizationId }: getAneltoCredentialsArgs) => {
  const result = typedOrgsApi
    .get('/api/organizations/{organizationId}/anelto-credentials', {
      pathParams: { organizationId },
    })
    .catch(async (e) => {
      const error = await e.response.json()
      if (error.code === 404)
        return {
          aneltoUsername: '',
          aneltoPasswordReference: '',
          secretsManagerArn: '',
          auditInfo: {
            createdAt: '',
            createdBy: '',
            updatedAt: '',
            updatedBy: '',
          },
        }
      throw e
    })

  return result
}
