import React, { useRef, useState } from 'react'
import { Dropdown, DropdownItem, Icon } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type OrgAdminVitalSignCardProps = {
  vitalAssignment: VitalAssignment
  onEditVitalAssignment: () => void
}

const OrgAdminVitalSignCard = ({
  vitalAssignment,
  onEditVitalAssignment,
}: OrgAdminVitalSignCardProps) => {
  const { t } = useTranslation()

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const dropdownAnchorEl = useRef(null)

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  return (
    <VitalSignsCard>
      <VitalSignsContainer>
        <IconContainer>
          <VitalSignsTitle>
            {vitalAssignment.name}
            &nbsp;&nbsp;
          </VitalSignsTitle>
          <EditContainer ref={dropdownAnchorEl}>
            <Ellipsis
              data-testid={`${vitalAssignment.name}-ellipsis`}
              type="ellipsis"
              onClick={handleDropdownToggle}
            />
            <Dropdown
              visible={isDropdownVisible}
              setVisible={setIsDropdownVisible}
              parentRef={dropdownAnchorEl}
            >
              <DropdownItem
                onClick={() => {
                  // Open Form to edit this Vital Sign
                  onEditVitalAssignment()
                  // Reset Popover
                  setIsDropdownVisible(false)
                }}
              >
                {t('Edit')}
              </DropdownItem>
            </Dropdown>
          </EditContainer>
        </IconContainer>
      </VitalSignsContainer>
      <DisplayContainer>
        {t('Display Name')}:{' '}
        <ContentContainer>{vitalAssignment.displayName}</ContentContainer>
      </DisplayContainer>
      <DisplayContainer>
        {t('Units')}:{' '}
        <ContentContainer>
          {
            vitalAssignment.units.find(
              (unit) => unit.code === vitalAssignment.defaultUnitCode
            )?.displayName
          }
        </ContentContainer>
      </DisplayContainer>
    </VitalSignsCard>
  )
}

export default OrgAdminVitalSignCard

const VitalSignsCard = tw.div`bg-white p-5 rounded-lg border border-gray-200 h-full`

const VitalSignsContainer = tw.div`border-b pb-2 mb-2`

const IconContainer = tw.div`mb-1 flex justify-between align-middle`

const VitalSignsTitle = tw.h3`text-xl`

const EditContainer = tw.div`relative inline-flex self-center`

const DisplayContainer = tw.p`text-gray-500`

const ContentContainer = tw.span`text-gray-800`

const Ellipsis = tw(Icon)`text-gray-900 w-5 h-5`
