import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { RightPopup, Select, SelectItem } from 'elements'
import { Button } from 'atlas'
import { useUpdateVitalAssignmentMutation } from 'hooks/vitals/vital-assignments'
import { useTranslation } from 'react-i18next'

type OrgEditVitalAssignmentFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  vitalAssignment?: VitalAssignment
  vitalAssignments: OrgVitalAssignments
}

const OrgEditVitalAssignmentForm = ({
  isFormOpen,
  setIsFormOpen,
  vitalAssignment,
  vitalAssignments,
}: OrgEditVitalAssignmentFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const { handleSubmit, control, watch, reset } = useForm<{
    defaultUnitCode: string
  }>()

  const updateVitalSigns = useUpdateVitalAssignmentMutation()

  // When the user changes Vital Signs make sure the Default Unit Code value is changed as well
  useEffect(() => {
    reset({
      defaultUnitCode: vitalAssignment?.defaultUnitCode,
    })
  }, [vitalAssignment])

  return vitalAssignment ? (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Edit Vital Assignment')}
      description={vitalAssignment.name}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateVitalSigns.isLoading}
            // disables Save button if default unit code is unchanged
            disabled={watch('defaultUnitCode') === vitalAssignment.defaultUnitCode}
            onClick={handleSubmit(async (formData) => {
              // convert form data to an array VitalSignAssignmentForms
              const vitalSignAssignment: VitalAssignmentForm = {
                id: vitalAssignment.id,
                code: formData.defaultUnitCode,
              }

              // API call
              updateVitalSigns.mutate({
                updatedVitalAssignment: vitalSignAssignment,
                orgId: assumedOrganizationRole?.orgID || '',
                vitalAssignments,
              })

              setIsFormOpen(false)
            })}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updateVitalSigns.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <form>
        <Controller
          as={
            <Select name="defaultUnitCode" label={t('Default Unit Code')}>
              {vitalAssignment.units // show list of units available for the selected vitalSign
                .map((unit) => (
                  <SelectItem key={unit.code} value={unit.code}>
                    {unit.name}
                  </SelectItem>
                ))}
            </Select>
          }
          name="defaultUnitCode"
          control={control}
          defaultValue={''}
        />
      </form>
    </RightPopup>
  ) : null
}

export default OrgEditVitalAssignmentForm
