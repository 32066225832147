import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useAddPermissionMutation = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/permissions'],
    KyError,
    OrgsAPIRequest['POST']['/api/permissions']['body']
  >(addPermission, {
    onSuccess: () => {
      queryClient.invalidateQueries('getPermissions')
      queryClient.invalidateQueries('getModules')

      success({ message: t('Permission created') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useAddPermissionMutation

const addPermission = async (
  req: OrgsAPIRequest['POST']['/api/permissions']['body']
) => {
  const result = typedOrgsApi.post('/api/permissions', {
    body: req,
  })

  return result
}

// type ouGetModulesQueryToAddPermissionArgs = {
//   newPermission: Permission
// }

// // optimistic update for the getModules query when a permission is added
// export const ouGetModulesQueryToAddPermission = ({
//   newPermission,
// }: ouGetModulesQueryToAddPermissionArgs) => {
//   const queryClient = useQueryClient()
//   queryClient.setQueryData<OrgsAPIResponse['GET']['/api/modules'] | undefined>(
//     ['getModules'],
//     (old) => {
//       if (!old) return undefined

//       const newValue: OrgsAPIResponse['GET']['/api/modules'] = {
//         ...old,
//         items: old.items.map((module) => {
//           if (module.id === newPermission?.module?.id) {
//             // if no permissions object, don't try to update the module
//             if (!module.permissions) return module

//             return {
//               ...module,
//               permissions: [newPermission, ...module.permissions],
//             }
//           }

//           return module
//         }),
//       }

//       return newValue
//     }
//   )
// }
