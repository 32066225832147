const generatePaginatable = () => {
  const result: Paginatable = {
    currentPageNumber: 0,
    recordsPerPage: 0,
    totalPages: 0,
    totalRecords: 0,
  }

  return result
}

export default generatePaginatable
