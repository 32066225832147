import React, { useMemo, useCallback, useState } from 'react'
import { List, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { useStatusSort } from 'hooks/react-table'
import {
  useRestoreModuleMutation,
  useDeleteModuleMutation,
} from 'hooks/access-control/modules'

type ArchivedModulesListProps = {
  data: Module[]
}

const ArchivedModulesList = ({ data }: ArchivedModulesListProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const tableData: Module[] = useMemo(() => {
    return data
  }, [data])

  const statusSort = useStatusSort()

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Module>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const description = row.original.description.toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<Module>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: 'name',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: () => <Chip color="gray">{t('Archived')}</Chip>,
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => <ActionsCell row={row} />,
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (row) =>
        navigate({
          pathname: `../${row.original.id}`,
        }),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'modules'
      )}`}
      emptyTitle={t('No Modules Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default ArchivedModulesList

type ActionsCellProps = {
  row: Module
}

const ActionsCell = ({ row }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const { mutate: restoreModule } = useRestoreModuleMutation()
  const { mutate: deleteModule } = useDeleteModuleMutation()
  const navigate = useNavigate()

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${row.name}" module`),
      content: <p>{t('Are you sure you want to restore this module?')}</p>,
      actions: (
        <Button
          onClick={() => {
            restoreModule({ moduleId: row.id })
            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${row.name}" module`),
      content: <p>{t('Are you sure you want to delete this module?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deleteModule({ moduleId: row.id })
            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Manage'),
            onClick: () => {
              navigate(`../${row.id}`)
            },
          },
          {
            label: t('Restore'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsRestoreDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
          {
            label: t('Delete'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDeleteDialogOpen(true)

              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
