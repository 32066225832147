import React, { useState } from 'react'
import { SimpleList, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { WebhookEventSampleDataModal } from 'components/settings-webhooks'
import { useRemoveWebhookEventMutation } from 'hooks/settings-webhooks'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'

type WebhookEventsListProps = {
  webhookSubscription: WebhookSubscription
}

const WebhookEventsList = ({ webhookSubscription }: WebhookEventsListProps) => {
  const { t } = useTranslation()
  const [isSampleDataModalOpen, setIsSampleDataModalOpen] = useState(false)
  const [displayedEvent, setDisplayedEvent] = useState<
    WebhookEvent | undefined
  >()

  return (
    <WebhookListContainer>
      <Title>{t('Webhook Events')}</Title>
      <SimpleList
        data={webhookSubscription.events}
        renderItem={(event) => (
          <SimpleList.Item key={event.id}>
            <WebhookEvent>
              <p>
                {event.name} <EventCode>({event.code})</EventCode>
              </p>
              <Ellipsis
                event={event}
                webhookSubscription={webhookSubscription}
                openSampleDataModal={() => {
                  setIsSampleDataModalOpen(true)
                  setDisplayedEvent(event)
                }}
              />
            </WebhookEvent>
          </SimpleList.Item>
        )}
      />

      <WebhookEventSampleDataModal
        isOpen={isSampleDataModalOpen}
        setIsOpen={setIsSampleDataModalOpen}
        displayedEvent={displayedEvent}
      />
    </WebhookListContainer>
  )
}

export default WebhookEventsList

type EllipsisProps = {
  event: WebhookEvent
  openSampleDataModal: () => void
  webhookSubscription: WebhookSubscription
}

const Ellipsis = ({
  event,
  openSampleDataModal,
  webhookSubscription,
}: EllipsisProps) => {
  const { t } = useTranslation()
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false)
  const { mutate: removeWebhookEvent } = useRemoveWebhookEventMutation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const confirmations = [
    // Archive Webhook
    {
      setIsOpen: setIsRemoveDialogOpen,
      isOpen: isRemoveDialogOpen,
      title: t(`Remove "${event.name}" webhook event`),
      content: (
        <p>{t('Are you sure you want to remove this webhook event?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            removeWebhookEvent({
              eventId: event.id,
              subscriptionId: webhookSubscription.id,
              organizationId: assumedOrganizationRole?.orgID || '',
            })
            return setIsRemoveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Remove')}
        </Button>
      ),
    },
  ]

  return (
    <>
      <EllipsisDropdown
        disabled={webhookSubscription.archiveInfo.archived}
        options={[
          {
            label: t('Show Sample Data'),
            onClick: openSampleDataModal,
          },
          {
            label: t('Send Sample Test'),
            onClick: () => console.log('sample test'),
          },
          {
            label: t('Remove Event'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsRemoveDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${event.id}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </>
  )
}

const WebhookListContainer = tw.div`mb-8`

const Title = tw.h3`text-xl font-semibold mb-4`

const EventCode = tw.span`text-gray-500`

const WebhookEvent = tw.div`flex justify-between items-center`
