import { Button, Tooltip } from 'atlas'
import { OrganizationAutoComplete } from 'components/organizations'
import { SubscriberAutoComplete } from 'components/subscribers'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useWizardStepForm } from 'context/wizard-form'
import { AutoComplete, ConfirmationDialogs, Icon, Switch } from 'elements'
import { ChildStepContainer } from 'elements/WizardForm'
import { useAPIQuery, useDelay, useNavigate } from 'hooks'
import { useContractsQuery } from 'hooks/contracts'
import { useCreateOrderMutation } from 'hooks/orders'
import {
  useSubscriberQuery,
  useUpdateSubscriberMutation,
} from 'hooks/organizations'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParam } from 'react-use'
import tw from 'twin.macro'

type BasicInformationForm = {
  subscriberId: string
  contract: Contract | null
}

const BasicInformation = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()
  const delay = useDelay()
  const { t } = useTranslation()
  const orderId = useSearchParam('orderId') || ''

  const [isNonPers, setIsNonPers] = useState(false)
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false)
  const [organizationId, setOrganizationId] = useState<string | undefined>(
    assumedOrganizationRole?.orgID
  )
  const [subscriberId, setSubscriberId] = useState<string>()
  const [organizationError, setOrganizationError] = useState<string>()

  const orderQuery = useAPIQuery('order', { pathParams: { orderId } })

  const contractList = useContractsQuery(
    organizationId
      ? {
          organizationIds: [organizationId],
        }
      : {}
  )

  const subscriberQuery = useSubscriberQuery(subscriberId || '')

  const createOrderMutation = useCreateOrderMutation()
  const updateSubscriberMutation = useUpdateSubscriberMutation()

  const { control, errors, setValue } = useWizardStepForm<
    BasicInformationForm,
    Order
  >({
    apiData: orderQuery.data,
    apiToForm: (apiData) => ({
      subscriberId: apiData.subscriberId || '',
      contract:
        contractList.data?.items?.find(
          (contract) => contract.id === apiData.contractId
        ) || null,
      organizationId: apiData.organizationId,
    }),
    onSyncWithApi: (apiData) => {
      setOrganizationId(
        apiData.organizationId || assumedOrganizationRole?.orgID
      )
      setSubscriberId(apiData.subscriberId || undefined)
    },
    customFields: {
      isPers: {
        value: isNonPers,
        customIsEqualFn: () => isNonPers === subscriberQuery.data?.nonPERS,
      },
      ...(!assumedOrganizationRole
        ? { organizationId: { value: organizationId } }
        : {}),
    },
    validationFn: () => {
      if (!assumedOrganizationRole && organizationId === undefined) {
        setOrganizationError(t('Organization is required'))
      } else setOrganizationError(undefined)

      return !!(assumedOrganizationRole || organizationId)
    },
    submitFn: (formData) => {
      // if the order exists only call mutation to update the subscriber
      if (orderId)
        return updateSubscriberMutation.mutateAsync({
          subscriberId: subscriberId || '',
          subscriberForm: { nonPERS: isNonPers },
        })

      // if this is a new order only update subscriber if isPers flag was toggled
      if (subscriberId && isNonPers !== subscriberQuery.data?.nonPERS)
        updateSubscriberMutation.mutate({
          subscriberId,
          subscriberForm: { nonPERS: isNonPers },
        })

      return createOrderMutation.mutateAsync(
        {
          subscriberId: formData.subscriberId,
          contractId: formData.contract?.id,
          organizationId,
          orderType: 'New',
        },
        {
          onSuccess: (order) => {
            if (order.id)
              // save orderId to the searchParams
              navigate({
                searchParams: {
                  orderId: order.id,
                },
              })
          },
        }
      )
    },
    // don't show default navigation warning if the custom leave Dialog is open
    disableNavigationWarning: isLeaveDialogOpen,
    formSyncDependencies: [contractList.data, subscriberQuery.data],
  })

  // set the pers switch when subscriber is selected to the nonPERS property for the subscriber
  useEffect(() => {
    if (subscriberQuery.data) setIsNonPers(!!subscriberQuery.data.nonPERS)
  }, [subscriberQuery.data])

  // Create Subscriber redirect dialog
  const confirmations = [
    {
      setIsOpen: setIsLeaveDialogOpen,
      isOpen: isLeaveDialogOpen,
      title: t('Leave Order Form and Create Subscriber?'),
      content: (
        <p>
          {t(
            "Are you sure you want to leave this form? Any unsaved form data will be lost and you'll be redirected to the create subscriber form."
          )}
        </p>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => navigate('/subscribers/create-subscriber')}
        >
          {t('Leave Form')}
        </Button>
      ),
    },
  ]
  return (
    <ChildStepContainer delay={delay()}>
      {!assumedOrganizationRole ? (
        <OrganizationAutoComplete
          label={t('Organization')}
          placeholder={t('Select Organization')}
          error={organizationError}
          selectedOrganization={organizationId || null}
          setSelectedOrganization={(newOrganization) => {
            // reset subscriber/contract selection
            if (newOrganization?.id !== organizationId) {
              setValue('subscriberId', null)
              setValue('contract', null)
            }

            setOrganizationId(newOrganization?.id)
          }}
          disabled={!!orderId}
          className="max-w-md"
        />
      ) : null}
      <Controller
        control={control}
        render={({ onChange, value }) => (
          <Autocomplete<Contract>
            label={t('Contract')}
            placeholder={t('Search Contract')}
            error={errors.contract?.message}
            single
            options={contractList.data?.items || []}
            selectedOption={value}
            onChange={onChange}
            optionLabel={(value) => value.descriptionInfo?.title || ''}
            disabled={!organizationId || !!orderId}
          />
        )}
        // @ts-expect-error it's fine if the required message is undefined
        rules={{ required: t('Contract is required') }}
        name="contract"
        defaultValue={null}
      />
      <RowContainer>
        <Controller
          control={control}
          render={({ onChange, value }) => (
            <SubscriberAutoComplete
              label={t('Subscriber')}
              placeholder={t('Search Subscriber')}
              error={errors.subscriberId?.message}
              selectedSubscriber={value}
              setSelectedSubscriber={(selectedSubscriber) => {
                onChange(selectedSubscriber?.id || '')
                setSubscriberId(selectedSubscriber?.id)
              }}
              disabled={!organizationId || !!orderId}
              organizationId={organizationId}
              className="max-w-md flex-grow"
            />
          )}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Subscriber is required') }}
          name="subscriberId"
          defaultValue=""
        />
        {!orderId ? (
          <CreateSubscriberButton
            type="primary"
            onClick={() => setIsLeaveDialogOpen(true)}
          >
            {t('Create Subscriber')}
          </CreateSubscriberButton>
        ) : null}
      </RowContainer>

      <RowContainer>
        <PersSwitch
          onChange={setIsNonPers}
          checked={isNonPers}
          label={t('Non-PERS Subscriber')}
          disabled={!subscriberId}
        />
        <InfoTooltip
          content={
            <TooltipText>
              {t(
                'Use this option to create a Subscriber that will not use PERS (i.e. Rapid Response Management System)'
              ) + '.'}
            </TooltipText>
          }
        >
          <InfoIcon type="info" />
        </InfoTooltip>
      </RowContainer>
      <ConfirmationDialogs confirmations={confirmations} />
    </ChildStepContainer>
  )
}

export default BasicInformation

const RowContainer = tw.div`flex gap-4`

const CreateSubscriberButton = tw(Button)`h-fit-content mt-2`

const Autocomplete = tw(AutoComplete)`max-w-md flex-grow`

const PersSwitch = tw(Switch)`mb-3`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`h-fit-content`

const TooltipText = tw.p`w-60`
