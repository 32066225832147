import React from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <FooterContainer>
      <Copyright>
        &copy;{' '}
        {`2020-${new Date().getFullYear()} ${t('Electronic Caregiver, Inc.')}`}
      </Copyright>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = tw.footer`bg-gray-50 relative flex justify-center py-3 before:(height[.5px] w-full bg-gray-300 absolute top-0 background-image[-webkit-linear-gradient(left , #F7FAFC 2%, rgba(203, 213, 225) 50%, #F7FAFC 100%)])`

const Copyright = tw.span`text-gray-400 text-sm`
