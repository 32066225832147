import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { PermissionsAuditTrailList } from 'components/access-control/permissions'

const AccessControlPermissionsAuditTrail = () => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Audit Trail')}
      description={t(
        'Review all actions taken in relation to ECG Platform permissions.'
      )}
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Permissions'), route: '/access-control/permissions' },
        { name: t('Audit Trail') },
      ]}
    >
      <PermissionsAuditTrailList />
    </Layout>
  )
}

export default AccessControlPermissionsAuditTrail
