import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const usePartnerTypeChildrenQuery = (partnerTypeId: string) => {
  const query = useQuery<GetPartnerTypeChildrenResponse, KyError>(
    ['getPartnerTypeChildren', partnerTypeId],
    () => getPartnerTypeChildren(partnerTypeId)
  )

  return query
}

export default usePartnerTypeChildrenQuery

const searchParams = [
  ['order_by', 'ASC'],
  ['take', '10000'],
]

const getPartnerTypeChildren = async (partnerTypeId: string) => {
  const result = await orgsApi
    .get(`api/partner-types/${partnerTypeId}/children`, {
      searchParams,
    })
    .json<GetPartnerTypeChildrenResponse>()
  return result
}
