import React from 'react'
import { EntityLabel, CustomSelect } from 'elements'
import { useParams, useLocation } from 'react-router-dom'
import { useNavigate } from 'hooks'
import { usePartnersQuery } from 'hooks/partners'
import { useTranslation } from 'react-i18next'

const PartnerSelect = () => {
  const { partnerId } = useParams()
  const partnerList = usePartnersQuery()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const currentPartner = partnerList.data?.items.find(
    (partner) => partner.id === partnerId
  )

  if (!currentPartner) return null

  const currentPartnerDisplayName = currentPartner.person
    ? `${currentPartner.person.firstName} ${currentPartner.person.lastName}`
    : currentPartner.organization
    ? currentPartner.organization.businessName
    : ''

  const partnerSelect = partnerList.data?.items.map((partner) => {
    return {
      value: partner.id,
      label: (
        <>
          <EntityLabel
            name={partner.person?.firstName + ' ' + partner.person?.lastName}
            id={partner.id}
            iconOnly
            size="sm"
          />
          {partner.person?.firstName + ' ' + partner.person?.lastName}
        </>
      ),
      searchValue: `${partner.person?.firstName || ''} ${
        partner.person?.lastName || ''
      }`,
    }
  })

  return (
    <CustomSelect
      options={partnerSelect || [{ label: '', value: '' }]}
      className="w-64"
      id={currentPartner.id}
      placeholder={currentPartnerDisplayName}
      onChange={(e) => navigate(pathname.replace(partnerId, e.value))}
      variant="thin"
      searchable
      emptyText={t('No partners found')}
    />
  )
}

export default PartnerSelect
