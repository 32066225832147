import React, { useEffect, useState } from 'react'
import { RightPopup, TextField, LoadingIcon } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  useUpdateOrganizationMutation,
  useOrganizationListQuery,
} from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { MetadataForm } from 'components/metadata'
import tw from 'twin.macro'
import _ from 'lodash'

type UpdateOrganizationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  organization: Organization
}

const UpdateOrganizationForm = ({
  isFormOpen,
  setIsFormOpen,
  organization,
}: UpdateOrganizationFormProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const formMethods = useForm<{
    displayName: string
    businessName: string
  }>()
  const { handleSubmit, errors, reset } = formMethods

  const [metadata, setMetadata] = useState<Array<[string, string]> | undefined>(
    organization.integrationMetadata
      ? _.orderBy(Object.entries(organization.integrationMetadata))
      : undefined
  )
  const [parentId, setParentId] = useState<string>(
    assumedOrganizationRole?.orgID || ''
  )

  const orgnaizationList = useOrganizationListQuery({
    orgId: assumedOrganizationRole?.orgID,
  })

  const updateOrganization = useUpdateOrganizationMutation()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset({
        displayName: organization.businessName,
        businessName: organization.businessName,
      })
      setMetadata(
        organization.integrationMetadata
          ? _.orderBy(Object.entries(organization.integrationMetadata))
          : undefined
      )
    }
  }, [isFormOpen])

  // when organizations load set the parent organization to one of them if not assuming an org role
  useEffect(() => {
    if (orgnaizationList.data && !assumedOrganizationRole)
      setParentId(orgnaizationList.data.items[0]?.id)
  }, [orgnaizationList.data])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Edit Organization')}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateOrganization.isLoading}
            disabled={
              !parentId ||
              (metadata &&
                metadata.some(([key, value]) => key === '' || value === ''))
            }
            onClick={handleSubmit((formData) => {
              updateOrganization.mutate(
                {
                  organization,
                  businessName: formData.businessName,
                  displayName: formData.displayName,
                  ...(metadata ? { metadata } : {}),
                },
                { onSuccess: () => setIsFormOpen(false) }
              )
            })}
          >
            {t('Update')}
          </Button>
          &nbsp;
          {/* Cancel Button (shown for both) */}
          <Button
            type="secondary"
            disabled={updateOrganization.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {orgnaizationList.isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <FormProvider {...formMethods}>
            <form>
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Business Name is required') }}
                name="businessName"
                label={t('Business Name')}
                fullWidth
                error={errors.businessName?.message}
                required
              />

              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Display Name is required') }}
                name="displayName"
                label={t('Display Name')}
                fullWidth
                error={errors.businessName?.message}
                required
              />
            </form>
          </FormProvider>
          <MetadataTitle>{t('Organization Metadata')}</MetadataTitle>
          <MetadataForm metadata={metadata} setMetadata={setMetadata} />
        </>
      )}
    </RightPopup>
  )
}

export default UpdateOrganizationForm

const MetadataTitle = tw.h3`text-lg font-medium my-2`
