import React, { useState } from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useSubscribersQuery } from 'hooks/organizations'
import { ManualObservationsForm, VitalsActivityList } from 'components/vitals'

const VitalsActivity = () => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const subscribers = useSubscribersQuery()

  const [isObservationFormOpen, setIsObservationFormOpen] = useState<boolean>(
    false
  )

  // If user has assumed an Org Role filter out subscribers belonging to other orgs
  const subscriberList = assumedOrganizationRole
    ? subscribers.data?.items?.filter(
        (subscriber) =>
          subscriber.organization?.id === assumedOrganizationRole.orgID
      )
    : subscribers.data?.items

  return (
    <Layout
      title={
        <div className="flex gap-2">
          <h1 className="text-2xl font-bold">{t('Vitals Activity')}</h1>
        </div>
      }
      description={t(
        assumedOrganizationRole
          ? 'Monitor all vitals signs activity for this organization'
          : 'Monitor vitals signs activity for all subscribers'
      )}
      isLoading={subscribers.isLoading}
      breadcrumbs={[
        {
          name: t('Vitals'),
          route: '/vitals',
        },
        {
          name: t('Vitals Activity'),
        },
      ]}
      controls={[
        {
          label: t('Create Manual Observations'),
          onClick: () => setIsObservationFormOpen(true),
        },
      ]}
    >
      <VitalsActivityList subscribers={subscriberList || []} />
      <ManualObservationsForm
        isFormOpen={isObservationFormOpen}
        setIsFormOpen={setIsObservationFormOpen}
      />
    </Layout>
  )
}

export default VitalsActivity
