import React, { useEffect, useState } from 'react'
import { Chip, Empty } from 'atlas'
import OrganizationAddressForm from 'components/organizations/OrganizationAddressForm'
import { SubscriberAddressForm } from 'components/subscriber-create'
import { Icon, LoadingIcon } from 'elements'
import { useAddressTypesQuery } from 'hooks/seed-data'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { formatAddress } from 'utils'
import { ShippingOption } from './ShippingInformation'
import { useAPIQuery } from 'hooks'

type OrderAddressCardProps = {
  customerName: string
  shipTo: ShippingOption
  // TODO: explore with api team to discover if we can have a genertic address dto?
  addresses: Array<SubscriberAddress> | Array<OrganizationAddress>
  setShippingAddress: (
    address: OrderShippingInformationForm['shippingAddress']
  ) => void
  initialShippingAddressId?: string | undefined
}

const OrderAddressCard = ({
  customerName,
  addresses,
  setShippingAddress,
  initialShippingAddressId,
}: OrderAddressCardProps) => {
  const { t } = useTranslation()

  const addressTypesQuery = useAddressTypesQuery()

  const [searchParams] = useSearchParams()

  const orderQuery = useAPIQuery('order', {
    pathParams: {
      orderId: searchParams.get('orderId') || '',
    },
  })

  const [isAddressFormOpen, setIsAddressFormOpen] = useState<boolean>(false)

  const [addressToMutate, setAddressToMutate] = useState<
    SubscriberAddress | OrganizationAddress
  >()

  const [selectedAddress, setSelectedAddress] = useState<
    SubscriberAddress | OrganizationAddress
  >()

  // Auto detect and select shipping address if it exists else default to primary
  useEffect(() => {
    setSelectedAddress(
      // Find and Filter functions are not callable on union types (https://github.com/microsoft/TypeScript/issues/44373)
      (addresses as Array<SubscriberAddress | OrganizationAddress>).find(
        (address) => address.id === initialShippingAddressId
      ) ??
        (addresses as Array<
          SubscriberAddress | OrganizationAddress
        >).find((address) =>
          isSubscriberAddress(address)
            ? address.types.find((value) => value === 'shipping')
            : address.addressType ===
              addressTypesQuery.data?.items?.find(
                (value) => value.code === 'shipping'
              )?.id
        ) ??
        (addresses as Array<
          SubscriberAddress | OrganizationAddress
        >).find((address) =>
          isSubscriberAddress(address)
            ? address.types.find((value) => value === 'primary')
            : address.addressType ===
              addressTypesQuery.data?.items?.find(
                (value) => value.code === 'primary'
              )?.id
        )
    )
  }, [addresses, initialShippingAddressId])

  // Set selected subscriber or organization shipping address
  useEffect(() => {
    if (!selectedAddress) setShippingAddress(undefined)
    else
      isSubscriberAddress(selectedAddress)
        ? setShippingAddress(selectedAddress.address)
        : setShippingAddress({
            addressLine1: selectedAddress.addressLine1,
            addressLine2: selectedAddress.addressLine2,
            city: selectedAddress.city,
            postalCode: selectedAddress.postalCode,
            countryCode: selectedAddress.countryCode,
            stateProvinceCode: selectedAddress.stateProvinceCode,
          })
  }, [selectedAddress])

  if (!orderQuery.data) return <LoadingIcon />

  return addresses.length ? (
    <Card>
      <NameContainer>
        <Label>{t('Name')}:</Label>
        <Value>{customerName}</Value>
      </NameContainer>
      <AddressContainer>
        <Label>{t('Shipping Address')}:</Label>

        {addresses.map(
          (value: OrganizationAddress | SubscriberAddress, index: number) => {
            const addressCode = addressTypesQuery.data?.items?.find(
              (addressType) =>
                !isSubscriberAddress(value) &&
                addressType.id === value.addressType
            )?.code

            return (
              <AddressCard
                key={index}
                isSelected={selectedAddress?.id === value.id}
                onClick={() => setSelectedAddress(value)}
              >
                <Value>
                  {formatAddress(
                    isSubscriberAddress(value) ? value.address : value
                  )}
                </Value>
                {(isSubscriberAddress(value)
                  ? value.types
                  : [isAddressType(addressCode) ? addressCode : 'primary']
                ).map((type, index) => (
                  <ChipContainer key={index}>
                    <Chip
                      color={
                        {
                          primary: 'lightblue' as const,
                          billing: 'yellow' as const,
                          shipping: 'green' as const,
                        }[type]
                      }
                    >
                      {_.capitalize(type)}
                    </Chip>
                  </ChipContainer>
                ))}

                <AddressIconContainer>
                  <EditIcon
                    type="edit"
                    onClick={(e) => {
                      setAddressToMutate(value)
                      setIsAddressFormOpen(true)
                      e.stopPropagation()
                    }}
                  />
                  <CheckIcon
                    isSelected={selectedAddress?.id === value.id}
                    type={
                      selectedAddress?.id === value.id
                        ? 'check-marked'
                        : 'check-circle'
                    }
                  />
                </AddressIconContainer>
              </AddressCard>
            )
          }
        )}
      </AddressContainer>

      {addressToMutate && isSubscriberAddress(addressToMutate) ? (
        <SubscriberAddressForm
          subscriberId={orderQuery.data.subscriberId || ''}
          isFormOpen={isAddressFormOpen}
          setIsFormOpen={setIsAddressFormOpen}
          addressToMutate={addressToMutate}
          takenAddressTypes={_.flatten(
            isSubscriberAddresses(addresses)
              ? addresses.map(({ types }) =>
                  types.map(
                    (type): Lowercase<typeof type> =>
                      _.lowerCase(type) as Lowercase<typeof type>
                  )
                )
              : []
          )}
        />
      ) : (
        <OrganizationAddressForm
          isFormOpen={isAddressFormOpen}
          setIsFormOpen={setIsAddressFormOpen}
          organizationId={orderQuery.data.organizationId || ''}
          addressToMutate={addressToMutate}
        />
      )}
    </Card>
  ) : (
    <EmptyDashed
      title={t('No Shipping Address Found')}
      // TODO: When endpoint exists to allow creating an organization address uncomment this
      // callToAction={
      //   <EmptyButton onClick={() => setIsAddressFormOpen(true)}>
      //     {t(`Add ${shipTo} Shipping Address`)}
      //   </EmptyButton>
      // }
    />
  )
}

export default OrderAddressCard

const EmptyDashed = tw(Empty)`flex-grow h-52 border-2 border-dashed rounded`

const Card = tw.div`grid grid-cols-1 gap-4 h-auto bg-white p-6 pb-1 rounded-lg border border-gray-200`

const NameContainer = tw.div`flex`

const AddressContainer = tw.div`flex flex-col mb-4`

const AddressCard = styled.div<{ isSelected: boolean }>(({ isSelected }) => [
  tw`flex w-full py-2 px-4 mb-2 border rounded cursor-pointer`,
  isSelected ? tw`border-blue-400` : tw`hover:border-gray-400`,
])

const Label = tw.p`text-gray-500 inline-block pr-2 mb-2`

const Value = tw.div`text-gray-900 flex-grow`

const ChipContainer = tw.div`ml-2`

const AddressIconContainer = tw.div`flex items-center`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900 ml-4`

const CheckIcon = styled(Icon)<{ isSelected: boolean }>(({ isSelected }) => [
  tw`w-5 h-5 pt-0.5 ml-3`,
  isSelected ? tw` text-blue-400` : tw`text-gray-300`,
])

const isSubscriberAddress = (
  address: OrganizationAddress | SubscriberAddress
): address is SubscriberAddress => {
  return 'types' in address
}

const isSubscriberAddresses = (
  addresses: Array<SubscriberAddress> | Array<OrganizationAddress>
): addresses is Array<SubscriberAddress> => {
  return 'types' in addresses[0]
}

const isAddressType = (
  addressCode: string | undefined
): addressCode is SubscriberAddress['types'][number] => {
  return (
    !!addressCode && ['billing', 'shipping', 'primary'].includes(addressCode)
  )
}
