import React, { useMemo, useState, useRef } from 'react'
import {
  LoadingIcon,
  Dialog,
  Dropdown,
  DropdownItem,
  Icon,
  SimpleList,
} from 'elements'
import { Button } from 'atlas'
import { ECGAdminEditTranslationForm } from 'components/ecg-admin-reminder-messages'
import {
  useReminderMessageTranslationsQuery,
  useAvailableTranslationsQuery,
  useDeleteReminderMessageTranslationMutation,
} from 'hooks/reminders'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

const ECGAdminTranslationsList = () => {
  const { t } = useTranslation()
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  // organizationId hard coded because it is ECG Admin
  const {
    data: availableTranslations,
    isLoading: translationsLoading,
  } = useAvailableTranslationsQuery('7151e6ce-07e1-4a81-823e-6db3f5f14dd5')

  const [selectedTranslation, setSelectedTranslation] = useState<{
    key: string
    name: string
  }>({ key: 'en-US', name: 'English (United States)' })

  const tableData: Array<AvailableTranslation> | [] = useMemo(() => {
    if (!availableTranslations?.items) return []
    return availableTranslations.items.filter((translation) => {
      if (translation.locale.key !== 'en-US')
        return { locale: translation.locale }
    })
  }, [availableTranslations])

  if (translationsLoading) return <LoadingIcon />

  return (
    <TranslationsList>
      <SimpleList
        data={tableData}
        emptyProps={{
          title: t('No Translations Created Yet'),
          className: 'flex border-2 border-dashed rounded',
        }}
        baseDelay={0.35}
        renderItem={(item) => (
          <SimpleList.Item key={item.locale.key}>
            <TranslationItemContainer>
              <Locale>{item.locale.name}</Locale>
              <ActionDropdown
                row={item}
                setIsFormOpen={setIsFormOpen}
                setSelectedTranslation={setSelectedTranslation}
              />
            </TranslationItemContainer>
          </SimpleList.Item>
        )}
      />

      <ECGAdminEditTranslationForm
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        selectedTranslation={selectedTranslation}
      />
    </TranslationsList>
  )
}

type ActionDropdownProps = {
  row: AvailableTranslation
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedTranslation: React.Dispatch<
    React.SetStateAction<{
      key: string
      name: string
    }>
  >
}

const ActionDropdown = ({
  row,
  setIsFormOpen,
  setSelectedTranslation,
}: ActionDropdownProps) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false)
  const dropdownAnchorEl = useRef(null)

  const {
    mutate: deleteReminderMessageTranslation,
  } = useDeleteReminderMessageTranslationMutation()

  const { data: translations, isLoading } = useReminderMessageTranslationsQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
    row.locale.key
  )

  if (isLoading) return <LoadingIcon />
  return (
    <DropdownContainer ref={dropdownAnchorEl}>
      <EllipsisButton
        type="ellipsis"
        data-testid={`${row.locale.key}-ellipsis`}
        onClick={() => setIsDropDownVisible(!isDropDownVisible)}
      />
      <Dropdown
        visible={isDropDownVisible}
        setVisible={setIsDropDownVisible}
        parentRef={dropdownAnchorEl}
      >
        <DropdownItem
          onClick={() => {
            setSelectedTranslation({
              key: row.locale.key,
              name: row.locale.name,
            })
            return setIsFormOpen(true)
          }}
        >
          {t('Edit')}
        </DropdownItem>

        <DropdownItem
          onClick={() => {
            return setIsDialogOpen(true)
          }}
        >
          {t('Delete')}
        </DropdownItem>
      </Dropdown>
      <Dialog
        open={isDialogOpen}
        title={`${t('Delete')} ${row.locale.name} ${t('translation')}?`}
        content={
          <p>
            {t(
              'Are you sure you want to delete this translation? This will remove it completely'
            )}
          </p>
        }
        actions={
          <>
            <Button
              type="danger-filled"
              onClick={() => {
                if (!translations?.items) return setIsDialogOpen(false)

                deleteReminderMessageTranslation({
                  locale: row.locale.key,
                  orgId: '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
                  reminderMessageIds: translations.items.map(
                    (translation) => translation.id || ''
                  ),
                })

                return setIsDialogOpen(false)
              }}
            >
              {t('Delete')}
            </Button>

            <Button onClick={() => setIsDialogOpen(false)} type="secondary">
              {t('Cancel')}
            </Button>
          </>
        }
      />
    </DropdownContainer>
  )
}

export default ECGAdminTranslationsList

const TranslationsList = tw.div`mb-8`

const TranslationItemContainer = tw.div`grid grid-cols-3 mx-4`

const Locale = tw.p`text-lg`

const DropdownContainer = tw.div`relative place-self-end self-center col-span-2`

const EllipsisButton = tw(Icon)`text-gray-900 w-5 h-5 transform translate-y-1`
