import React, { useMemo, useCallback, useRef, useState } from 'react'
import {
  List,
  Icon,
  Dropdown,
  DropdownItem,
  ConfirmationDialogs,
} from 'elements'
import { Button, Chip } from 'atlas'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { FadeInSlideDown } from 'animations'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useStatusSort, useEntityLabelSort } from 'hooks/react-table'
import {
  useActivatePermissionMutation,
  useArchivePermissionMutation,
  useDeactivatePermissionMutation,
} from 'hooks/access-control/permissions'
import { CreateEditPermissionForm } from '../permissions'
import tw, { styled } from 'twin.macro'
import { isPresent } from 'utils'

type ModulePermissionsListProps = {
  delay: () => number
  data?: Permission[]
  currentModule?: Module
  disabled?: boolean
}

const ModulePermissionsList = ({
  delay,
  data = [],
  currentModule,
  disabled,
}: ModulePermissionsListProps) => {
  const { t } = useTranslation()

  const primaryLabelSort = useEntityLabelSort({
    accessor: ['name'],
  })
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const [
    isCreateEditPermissionsFormOpen,
    setIsCreateEditPermissionsFormOpen,
  ] = useState<boolean>(false)
  const [editFormPermission, setEditFormPermission] = useState<
    Permission | undefined
  >()
  const tableData: Permission[] = useMemo(() => {
    return data
  }, [data])

  const statusSort = useStatusSort()

  const openCreateEditPermissionsForm = (permissionToEdit?: Permission) => {
    // set the current permission to edit (if exists)
    setEditFormPermission(permissionToEdit)

    // open the modal
    setIsCreateEditPermissionsFormOpen(true)
  }

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Permission>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const code = row.original.code.toLowerCase()
        const description = row.original.description.toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          code.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )

  const columns: Column<Permission>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.name}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortType: primaryLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) =>
          row.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          ),
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'ACTIONS',
        accessor: (row) => (
          <ActionsCell
            row={row}
            onEdit={() => openCreateEditPermissionsForm(row)}
            currentModule={currentModule}
            disabled={disabled}
          />
        ),
        disableSortBy: true,
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  if (!currentModule) return null

  return (
    <>
      <FadeInSlideDown delay={delay()} className="flex justify-between mb-4">
        <Title>{t('Module Permissions')}</Title>
        {/* acm.admin required */}
        {accountPermissions?.data.ecgAdmin.permissions.includes('acm.admin') ? (
          <Button
            type="primary-filled"
            onClick={() => openCreateEditPermissionsForm()}
            disabled={disabled}
          >
            {t('Create Permissions')}
          </Button>
        ) : null}
      </FadeInSlideDown>

      <ListContainer>
        <List
          tableInstance={tableInstance}
          searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
            'permissions'
          )}`}
          emptyTitle={t('No Permissions Created Yet')}
          emptyDescription={t(
            'Every module must provide permissions that dictate what a user can and cannot do when using the module. Create one or more permissions by clicking the button below.'
          )}
          baseDelay={delay()}
        />
      </ListContainer>

      <CreateEditPermissionForm
        isFormOpen={isCreateEditPermissionsFormOpen}
        setIsFormOpen={setIsCreateEditPermissionsFormOpen}
        permission={editFormPermission}
        module={currentModule}
      />
    </>
  )
}

export default ModulePermissionsList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: Permission
  onEdit: () => void
  currentModule?: Module
  disabled?: boolean
}

const ActionsCell = ({
  row,
  onEdit,
  currentModule,
  disabled,
}: ActionsCellProps) => {
  const { t } = useTranslation()
  const dropdownAnchorEl = useRef(null)
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )

  const { mutate: archivePermission } = useArchivePermissionMutation()
  const { mutate: activatePermission } = useActivatePermissionMutation()
  const { mutate: deactivatePermission } = useDeactivatePermissionMutation()

  if (!currentModule) return null

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t(`Archive "${row.name}" Module Permission`),
      content: (
        <p>{t('Are you sure you want to archive this module permission?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            // archive the permission and update the queryCache
            archivePermission(row.id)
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${row.name}" Module Permission`),
      content: (
        <p>
          {t('Are you sure you want to deactivate this module permission?')}
        </p>
      ),
      actions: (
        <Button
          onClick={() => {
            // deactivate the permission and update the queryCache
            deactivatePermission(row.id)
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${row.name}" Module Permission`),
      content: (
        <p>{t('Are you sure you want to activate this module permission?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            // activate the permission and update the queryCache
            activatePermission(row.id)
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
  ]

  return (
    <ActionsContainer ref={dropdownAnchorEl}>
      {accountPermissions?.data.ecgAdmin.permissions.includes('acm.admin') && (
        <>
          <EllipsisIcon
            type="ellipsis"
            disableCursor={disabled}
            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            data-testid={`${row.name}-ellipsis`}
            disabled={disabled}
          />
          <Dropdown
            visible={isDropdownVisible}
            setVisible={setIsDropdownVisible}
            parentRef={dropdownAnchorEl}
          >
            <DropdownItem onClick={() => onEdit()}>{t('Edit')}</DropdownItem>
            <DropdownItem
              onClick={() => {
                row.activeInfo.active
                  ? setIsDeactivateDialogOpen(true)
                  : setIsActivateDialogOpen(true)
                setIsDropdownVisible(false)
              }}
            >
              {row.activeInfo.active ? t('Deactivate') : t('Activate')}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setIsArchiveDialogOpen(true)
                setIsDropdownVisible(false)
              }}
            >
              {t('Archive')}
            </DropdownItem>
          </Dropdown>
          <ConfirmationDialogs confirmations={confirmations} />
        </>
      )}
    </ActionsContainer>
  )
}

const Title = tw.h3`text-xl font-semibold self-center`

const ListContainer = tw.div`flex flex-col h-96 mb-8`

const ActionsContainer = tw.div`relative inline-flex self-center`

const EllipsisIcon = styled(Icon)<{ disableCursor?: boolean }>(
  (disableCursor) => [
    tw`text-gray-900 w-5 h-5`,
    isPresent(disableCursor) && tw`opacity-50 cursor-not-allowed`,
  ]
)
