import React from 'react'
import { Empty } from 'atlas'
import { useResponsiblePartiesQuery } from 'hooks/responsible-parties'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { formatPhoneNumber } from 'utils'

type ResponsiblePartiesInfoCardProps = {
  subscriberInfo: SubscriberDisplay
}

const ResponsiblePartiesInfoCard = ({
  subscriberInfo,
}: ResponsiblePartiesInfoCardProps) => {
  const { t } = useTranslation()

  const { data } = useResponsiblePartiesQuery({
    subscriberId: subscriberInfo.id,
  })

  return (
    <Container>
      <Title>{t('Responsible Parties')}</Title>
      <Card>
        {data?.items?.length !== 0 ? (
          _.orderBy(data?.items, 'priority').map((responsibleParty) => (
            <ResponsibleParty key={responsibleParty.id}>
              <ResponsiblePartyAttribute>
                {responsibleParty.priority}
              </ResponsiblePartyAttribute>
              <p>
                {responsibleParty.person.firstName}{' '}
                {responsibleParty.person.lastName}
              </p>
              <ResponsiblePartyAttribute>
                {responsibleParty.relationship || '-'}
              </ResponsiblePartyAttribute>
              <ResponsiblePartyAttribute>
                {responsibleParty.person.emailAddress || '-'}
              </ResponsiblePartyAttribute>
              <ResponsiblePartyAttribute>
                {responsibleParty.phoneNumbers[0]?.number
                  ? formatPhoneNumber(responsibleParty.phoneNumbers[0].number)
                  : '-'}
              </ResponsiblePartyAttribute>
            </ResponsibleParty>
          ))
        ) : (
          <Empty
            title="No Data Found"
            description="No responsible party information exists for this subscriber"
          />
        )}
      </Card>
    </Container>
  )
}

export default ResponsiblePartiesInfoCard

const Container = tw.div`flex flex-col`

const Title = tw.h2`text-2xl font-semibold mb-2`

const ResponsiblePartyAttribute = tw.p`text-gray-500`

const Card = tw.div`grid grid-cols-1 gap-4 bg-white p-4 rounded-lg border border-gray-200 flex-grow`

const ResponsibleParty = styled.div`
  ${tw`grid`}
  grid-template-columns: 30px 1fr 1fr 1.5fr 1fr;
  grid-auto-rows: min-content;
`
