import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { OrgAdminVitalSignsGrid } from 'components/settings-vital-signs'
import { Button } from 'atlas'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'

const OrgAdminVitalsSettings = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const { t } = useTranslation()

  return (
    <Layout
      title={t('Vital Sign Assignments')}
      description={t('Manage vital signs available to your organization.')}
      breadcrumbs={[
        {
          name: t('Organization Settings'),
          route: '/organization-settings',
        },
        {
          name: t('Vital Sign Assignments'),
        },
      ]}
      isUnauthorized={!assumedOrganizationRole}
      controls={<Button to="audit-trail">{t('Audit Trail')}</Button>}
    >
      <OrgAdminVitalSignsGrid />
    </Layout>
  )
}

export default OrgAdminVitalsSettings
