import { useMutation } from 'hooks'
import { typedOrgsApi } from 'utils'
import { useTranslation } from 'react-i18next'
import { updateFnConstructor } from 'hooks/useMutation'

const useUnblockStaffOrganizationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/staff-members/{personId}/unblock'],
    unblockOrgArgs
  >({
    mutationFn: unblockStaffOrg,
    successMsg: t('Organization Unblocked'),

    additionalCachesToInvalidate: [
      ({ staffMemberId }) => ['getBlockedStaffOrganizations', staffMemberId],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ staffMemberId }) => [
          'getBlockedStaffOrganizations',
          staffMemberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/staff-members/{personId}/blocked-organizations'],
          unblockOrgArgs
        >((oldBlockedList, { organization }) => {
          return oldBlockedList?.items
            ? {
                ...oldBlockedList,
                items: oldBlockedList.items.filter(
                  (blockedOrg) => blockedOrg.id !== organization.id
                ),
              }
            : undefined
        }),
      },
    ],
  })

  return mutation
}

export default useUnblockStaffOrganizationMutation

type unblockOrgArgs = {
  staffMemberId: string
  organization: Organization
}

const unblockStaffOrg = ({ staffMemberId, organization }: unblockOrgArgs) =>
  typedOrgsApi.post(
    '/api/organizations/{organizationId}/staff-members/{personId}/unblock',
    {
      pathParams: {
        organizationId: organization.id,
        personId: staffMemberId,
      },
    }
  )
