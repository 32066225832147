import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { OrganizationReminderMessagesAuditTrailList } from 'components/org-admin-reminder-messages'

const OrganizationReminderMessagesAuditTrail = () => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Audit Trail')}
      description={t(
        'Review all organization-level actions in relation to reminder messages.'
      )}
      breadcrumbs={[
        {
          name: t('Organization Settings'),
          route: '/organization-settings',
        },
        {
          name: t('Reminder Messages'),
          route: '/organization-settings/reminder-messages',
        },
        {
          name: t('Audit Trail'),
        },
      ]}
    >
      <OrganizationReminderMessagesAuditTrailList />
    </Layout>
  )
}

export default OrganizationReminderMessagesAuditTrail
