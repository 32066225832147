import React from 'react'
import { Chip } from 'atlas'
import { colors } from 'atlas/Chip/Chip'
import { useTranslation, TFunction } from 'react-i18next'

type ProvisioningStatusChipProps = {
  status: OrderStatus
  provisionNeeded?: boolean
}

const ProvisioningStatusChip = ({
  status,
  provisionNeeded,
}: ProvisioningStatusChipProps) => {
  const { t } = useTranslation()

  return (
    <Chip
      color={
        orderProvisionStatusToColors[
          provisionNeeded &&
          status.title &&
          isProvisionStatusTitle(status.title)
            ? status.title
            : 'default'
        ]
      }
    >
      {provisionStatusToText(t, status.title)}
    </Chip>
  )
}

export default ProvisioningStatusChip

export enum OrderProvisioningStatusTitles {
  PROVISIONING_IN_PROCESS = 'Provisioning In-Process',
  PROVISIONING_COMPLETE = 'Provisioning Complete',
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
}

const orderProvisionStatusToColors: Record<
  OrderProvisioningStatusTitles | 'default',
  keyof typeof colors
> = {
  'Provisioning In-Process': 'orange',
  'Provisioning Complete': 'cyan',
  Confirmed: 'yellow',
  Cancelled: 'red',
  default: 'gray',
}

const provisionStatusToText = (t: TFunction, status: OrderStatus['title']) =>
  ({
    'Provisioning In-Process': t('In-Process'),
    'Provisioning Complete': t('Complete'),
    Confirmed: t('Provisioning Needed'),
    Cancelled: t('Cancelled'),
    default: t('Provisioning Not Needed'),
  }[status && isProvisionStatusTitle(status) ? status : 'default'])

const isProvisionStatusTitle = (
  str: string
): str is OrderProvisioningStatusTitles =>
  Object.keys(orderProvisionStatusToColors).includes(str)
