import React, { useMemo, useCallback, useState } from 'react'
import { List, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Chip, Button } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  useActivateApplicationMutation,
  useDeactivateApplicationMutation,
  useArchiveApplicationMutation,
} from 'hooks/access-control/applications'
import { useStatusSort, useEntityLabelSort } from 'hooks/react-table'
import tw from 'twin.macro'

type ApplicationsListProps = {
  data: Application[]
  onEditApplication: (application: Application) => void
}

const ApplicationsList = ({
  data,
  onEditApplication,
}: ApplicationsListProps) => {
  const { t } = useTranslation()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['name'],
  })

  const statusSort = useStatusSort()

  const tableData: Application[] = useMemo(() => {
    return data
  }, [data])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Application>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        // optional when creating/editing, cognitoAppId may be null
        const cognitoAppId = String(row.original.cognitoAppId).toLowerCase()
        const description = row.original.description.toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          cognitoAppId.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<Application>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => <PrimaryColumnText>{row.name}</PrimaryColumnText>,
        sortType: primaryLabelSort,
      },
      {
        id: 'COGNITO_APP_ID',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('COGNITO APP ID'),
        accessor: 'cognitoAppId',
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) =>
          row.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          ),
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => (
          <ActionsCell row={row} onEdit={() => onEditApplication(row)} />
        ),
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'applications'
      )}`}
      emptyTitle={t('No Applications Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default ApplicationsList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

type ActionsCellProps = {
  row: Application
  onEdit: () => void
}

const ActionsCell = ({ row, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )

  const { mutate: archiveApplication } = useArchiveApplicationMutation()
  const { mutate: deactivateApplication } = useDeactivateApplicationMutation()
  const { mutate: activateApplication } = useActivateApplicationMutation()

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t(`Archive "${row.name}" Application`),
      content: <p>{t('Are you sure you want to archive this application?')}</p>,
      actions: (
        <Button
          onClick={() => {
            archiveApplication(row.id)
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${row.name}" Application`),
      content: (
        <p>{t('Are you sure you want to deactivate this application?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            deactivateApplication(row.id)
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${row.name}" Application`),
      content: (
        <p>{t('Are you sure you want to activate this application?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            activateApplication(row.id)
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: () => {
              onEdit()
            },
          },
          {
            label: row.activeInfo.active ? t('Deactivate') : t('Activate'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDropdownVisible(false)
              if (row.activeInfo.active) return setIsDeactivateDialogOpen(true)
              setIsActivateDialogOpen(true)
            },
          },
          {
            label: t('Archive'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsArchiveDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
