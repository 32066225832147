import React, { useState, useEffect } from 'react'
import { Button, Alert } from 'atlas'
import { RightPopup, TextField } from 'elements'
import {
  useAneltoCredentialsQuery,
  useAddAneltoCredentialsMutation,
  useUpdateAneltoCredentialsMutation,
} from 'hooks/organizations'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type AneltoCredentialsProps = {
  isAneltoFormOpen: boolean
  setIsAneltoFormOpen: (isOpen: boolean) => void
  organizationId?: string
}
const AneltoCredentials = ({
  isAneltoFormOpen,
  setIsAneltoFormOpen,
  organizationId,
}: AneltoCredentialsProps) => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState(false)

  const aneltoCreds = useAneltoCredentialsQuery(organizationId || '')
  const formMethods = useForm<{
    username: string
    password: string
  }>()

  const addAneltoCreds = useAddAneltoCredentialsMutation()
  const updateAneltoCreds = useUpdateAneltoCredentialsMutation()
  const { handleSubmit, register, errors, setValue } = formMethods

  const [username, setUsername] = useState<string | undefined>(
    aneltoCreds.data?.aneltoUsername
  )

  useEffect(() => {
    if (aneltoCreds.data?.aneltoUsername !== '') {
      setUsername(aneltoCreds.data?.aneltoUsername)
      setValue('username', aneltoCreds.data?.aneltoUsername)
      setIsEdit(true)
    } else setIsEdit(false)
  }, [aneltoCreds, isAneltoFormOpen])

  return (
    <RightPopup
      title={t(`${isEdit ? 'Update' : 'Set'} Anelto Credentials`)}
      controls={
        <>
          <Button
            onClick={handleSubmit(async (formData) => {
              if (aneltoCreds.data?.aneltoUsername === '') {
                addAneltoCreds.mutate(
                  {
                    organizationId: organizationId || '',
                    username: formData.username,
                    password: formData.password,
                  },
                  { onSuccess: () => setIsAneltoFormOpen(false) }
                )
                return
              }

              updateAneltoCreds.mutate(
                {
                  organizationId: organizationId || '',
                  username: formData.username,
                  password: formData.password,
                },
                { onSuccess: () => setIsAneltoFormOpen(false) }
              )
              return
            })}
          >
            {t('Save')}
          </Button>
          &nbsp;
          <Button onClick={() => setIsAneltoFormOpen(false)} type="secondary">
            {t('Cancel')}
          </Button>
        </>
      }
      open={isAneltoFormOpen}
      setOpen={setIsAneltoFormOpen}
    >
      <FormProvider {...formMethods}>
        <AneltoCredsContainer>
          <TextField
            error={errors.username?.message}
            name="username"
            defaultValue={username}
            inputRef={register({
              required: `${t('Username is required')}`,
            })}
            fullWidth
            label={t('Anelto Username')}
          />
          <Password
            error={errors.password?.message}
            defaultValue={''}
            name="password"
            inputRef={register({
              required: `${t('Password is required')}`,
            })}
            type="password"
            fullWidth
            label={t('Anelto Password')}
          />
        </AneltoCredsContainer>
      </FormProvider>
      {isEdit ? (
        <Alert
          message={`${t('Password must be set when updating the username')}.`}
          type="warning"
        />
      ) : null}
      <Alert
        message={`${t(
          'Changing this information does not change the information on the Anelto Platform'
        )}. ${t(
          'Please confirm that any changes made here are reflected on the Anelto API'
        )}.`}
        type="information"
      />
    </RightPopup>
  )
}
export default AneltoCredentials

const AneltoCredsContainer = tw.div`flex flex-col `
const Password = tw(TextField)``
