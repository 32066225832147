import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useUpdateSubscriberAddressMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/subscribers-profile/{subscriberId}/addresses/{addressId}'],
    UpdateSubscriberAddressProps
  >({
    mutationFn: updateSubscriberAddress,
    successMsg: t('Address updated'),
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => [
          'getSubscriberAddresses',
          subscriberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/addresses'],
          UpdateSubscriberAddressProps
        >((oldCache, { addressForm, addressId }) =>
          oldCache
            ? {
                addresses: oldCache.addresses.map((address) =>
                  address.id === addressId
                    ? {
                        id: addressId,
                        ...addressForm,
                      }
                    : address
                ),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useUpdateSubscriberAddressMutation

type UpdateSubscriberAddressProps = {
  addressForm: SubscriberAddressForm
  addressId: string
  subscriberId: string
}

const updateSubscriberAddress = ({
  addressForm,
  addressId,
  subscriberId,
}: UpdateSubscriberAddressProps) =>
  typedOrgsApi.patch(
    '/subscribers-profile/{subscriberId}/addresses/{addressId}',
    {
      pathParams: { addressId, subscriberId },
      body: Object.entries(addressForm)
        .filter((field): field is [string, string] => !!field[1])
        .map(([fieldKey, fieldValue]) => ({
          value: fieldValue,
          path: `/${fieldKey}`,
          op: 'replace' as const,
        })),
    }
  )
