import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

const useAlertCategoryQuery = () => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/api/alert-categories'],
    KyError
  >(
    // 'addressed' is its own field of the query key to allow separate update logic on addressed/unaddressed caches when addressing an alert
    ['getAlertCategory'],
    () => getAlertCategory(),
    { keepPreviousData: true }
  )

  return query
}

const getAlertCategory = async () => {
  const result = await typedVitalsApi.get('/api/alert-categories', {
    searchParams: [
      ['order_by', 'DESC'],
      ['sort_by', 'name'],
      ['take', 99999],
      ['include', 'parent'],
    ],
  })

  return result
}

export default useAlertCategoryQuery

// weeks selection is broken, and only alert delivery rule that accepts severity is code: 'vitals-reading'
