import { useMutation } from 'hooks'
import { useTranslation } from 'react-i18next/'
import { typedOrgsApi } from 'utils'

const useUpdateAneltoCredentialsMutation = () => {
  const { t } = useTranslation()
  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}/anelto-credentials'],
    updateAneltoCredentialsArgs
  >({
    mutationFn: UpdateAneltoCredentials,
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getAneltoCredentials', organizationId],
    ],
    successMsg: {
      message: t('Anelto Credentials Updated'),
      showOn: 'onSuccess',
    },
  })

  return mutation
}

export default useUpdateAneltoCredentialsMutation

type updateAneltoCredentialsArgs = {
  organizationId: string
  username: string
  password: string
}
const UpdateAneltoCredentials = ({
  organizationId,
  username,
  password,
}: updateAneltoCredentialsArgs) =>
  typedOrgsApi.patch('/api/organizations/{organizationId}/anelto-credentials', {
    pathParams: { organizationId },
    body: { aneltoUsername: username, aneltoPassword: password },
  })
