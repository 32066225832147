import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

type UseModulesAuditTrailQueryProps = {
  filters: ModuleAuditSearchParams
}

const useModulesAuditTrailQuery = ({
  filters,
}: UseModulesAuditTrailQueryProps) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/modules/audit-trails'],
    KyError
  >(['getAuditTrail', 'modules', filters], () => getAuditTrail(filters), {
    staleTime: 0,
  })

  return query
}

export default useModulesAuditTrailQuery

const getAuditTrail = async (filters: ModuleAuditSearchParams) =>
  typedOrgsApi.get('/api/modules/audit-trails', {
    searchParams: {
      ...filters,
    },
  })

export type ModuleAuditSearchParams = NonNullable<
  Extract<
    OrgsAPIRequest['GET']['/api/modules/audit-trails']['searchParams'],
    Record<string, unknown>
  >
>
