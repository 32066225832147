import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { ContractForm } from 'components/contracts'

const CreateContract = () => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Create Contract')}
      description={t('Create a new order contract for an organization.')}
      breadcrumbs={[
        { name: t('Contracts'), route: '../' },
        { name: t('Create Contract') },
      ]}
    >
      <ContractForm />
    </Layout>
  )
}

export default CreateContract
