import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { handleQueryError, typedOrgsApi, success } from 'utils'

const useCreateAdminMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/ecg-admins'],
    KyError,
    CreateAdminForm
  >(createAdmin, {
    onSuccess: (admin) => {
      const admins:
        | OrgsAPIResponse['GET']['/api/ecg-admins']
        | undefined = queryCache.getQueryData(['getAdminList'])

      if (admins) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/ecg-admins'] = {
          ...admins,
          items: [admin, ...(admins.items || [])],
        }
        // immediately update the admin list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/ecg-admins']>(
          ['getAdminList'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the admin list is in sync with the server
      queryCache.invalidateQueries('getAdminList')

      // notify the user
      success({ message: t('Admin created') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useCreateAdminMutation

const createAdmin = async (adminData: CreateAdminForm) =>
  typedOrgsApi.post(`/api/ecg-admins`, {
    //@ts-expect-error bad swagger type //TODO: create the issue for isAutoGenerated to be a boolean
    body: adminData,
  })
