import React, { useMemo, useCallback } from 'react'
import { List, LoadingIcon, EntityLabel, CategoryFilter } from 'elements'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  TableInstance,
  useFilters,
} from 'react-table'
import { useCategoryFilter, useEntityLabelSort } from 'hooks/react-table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { usePartnersQuery } from 'hooks/partners'
import { useNavigate } from 'hooks'

const PartnerList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: partnerList, isLoading } = usePartnersQuery()
  const { getCategories, filterRowsByCategory } = useCategoryFilter<Partner>()
  const partnerLabelSort = useEntityLabelSort({
    accessor: ['person.firstName', 'person.lastName'],
  })

  const tableData: Partner[] = useMemo(() => {
    return partnerList?.items || []
  }, [partnerList, i18next.language])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Partner>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const partnerName = (row.original.organization
          ? row.original.organization.businessName
          : `${row.original.person?.firstName} ${row.original.person?.lastName}`
        ).toLowerCase()
        const partnerType = row.original.partnerType.name.toLowerCase()

        return (
          partnerName.includes(globalFilter.toLowerCase()) ||
          partnerType.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )

  const columns: Column<Partner>[] = useMemo(
    () => [
      {
        id: 'PARTNER',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('PARTNER'),
        accessor: (row) => (
          <EntityLabel
            name={
              row.organization
                ? row.organization.businessName
                : `${row.person?.firstName} ${row.person?.lastName}`
            }
            id={row.id}
            bold
          />
        ),
        sortType: partnerLabelSort,
      },
      {
        id: 'TYPE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('TYPE'),
        accessor: (row) => row.partnerType.name,
        Filter: (tableInstance: TableInstance<Partner>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="subscribers"
            label={t('Type')}
            categories={getCategories({
              tableInstance,
              accessor: (row) => row.partnerType.name,
            })}
          />
        ),
        filter: filterRowsByCategory(),
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (row) => navigate(row.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'PARTNER',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  if (isLoading) return <LoadingIcon />

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'partners'
      )}`}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default PartnerList
