import React, { useState } from 'react'
import { Layout, LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSubscriberQuery } from 'hooks/organizations'
import { SubscriberVitalsActivityList } from 'components/subscriber-vitals'
import { SubscriberSelect } from 'components/subscribers'
import { ManualObservationsForm } from 'components/vitals'

const SubscriberVitals = () => {
  const { subscriberId } = useParams()
  const { t } = useTranslation()
  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  const [isObservationFormOpen, setIsObservationFormOpen] = useState<boolean>(
    false
  )

  if (isSubLoading) return <LoadingIcon />

  if (!currentSubscriber)
    return (
      <Empty
        title={t('Subscriber Not Found')}
        description={t(
          `Either this subscriber doesn't exist or you don't have access to view them`
        )}
      />
    )

  return (
    <Layout
      title={
        <div className="flex gap-2">
          <h1 className="text-2xl font-bold">{t('Vitals Activity')}:</h1>
          <SubscriberSelect />
        </div>
      }
      description={t(
        'Monitor data for all vitals signs assigned to this subscriber.'
      )}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '/subscribers',
        },
        {
          name: `${currentSubscriber.person.firstName} ${currentSubscriber.person.lastName}`,
          route: `/subscribers/${subscriberId}`,
        },
        {
          name: t('Vitals Activity'),
        },
      ]}
      controls={[
        {
          label: t('Create Manual Observations'),
          onClick: () => setIsObservationFormOpen(true),
        },
      ]}
    >
      <SubscriberVitalsActivityList />
      <ManualObservationsForm
        isFormOpen={isObservationFormOpen}
        setIsFormOpen={setIsObservationFormOpen}
      />
    </Layout>
  )
}

export default SubscriberVitals
