import { vitalsApi } from 'utils'
import { useQuery } from 'react-query'

// used to get translation keys
const useAvailableTranslationsQuery = (organizationId: string) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations'],
    KyError
  >(['getAvailableTranslations', organizationId], () =>
    getAvailableTranslations(organizationId)
  )

  return query
}

export default useAvailableTranslationsQuery

// get translation keys
const getAvailableTranslations = async (organizationId: string) => {
  const result = await vitalsApi
    .get(
      `organization/${organizationId}/reminder-messages/available-translations`
    )
    .json<
      VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
    >()
  return result
}
