import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useApplicationsQuery = () => {
  const query = useQuery<OrgsAPIResponse['GET']['/api/applications'], KyError>(
    'getApplications',
    getApplications
  )

  return query
}

export default useApplicationsQuery

const getApplications = () =>
  typedOrgsApi.get('/api/applications', {
    searchParams: [
      ['include', 'modules'],
      ['order_by', 'ASC'],
      ['take', 9999],
    ],
  })
