import React, { useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import {
  SubscriberAssignVitalSignsForm,
  SubscriberVitalSignsGrid,
} from 'components/subscriber-settings'
import { useParams } from 'react-router-dom'
import { useSubscriberQuery, useSubscribersQuery } from 'hooks/organizations'
import {
  useOrgVitalAssignmentsQuery,
  useSubscriberVitalAssignmentsQuery,
} from 'hooks/vitals/vital-assignments'
import { SubscriberSelect } from 'components/subscribers'
import { useNavigate } from 'hooks'

const SubscriberVitalAssignments = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { subscriberId } = useParams()
  const subscribers = useSubscribersQuery()

  const [
    isAssignVitalSignFormOpen,
    setIsAssignVitalSignFormOpen,
  ] = useState<boolean>(false)

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  // Get both all vital signs and vital signs already assigned to this org
  const {
    isLoading: orgVitalsLoading,
    data: orgVitalSigns,
  } = useOrgVitalAssignmentsQuery(
    // if the subscriber has an org, use the organization's vital assignments
    currentSubscriber?.organization
      ? assumedOrganizationRole?.orgID
      : // else if they're private pay, use the ECG's organization's vital assignments
        '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const {
    isLoading: subVitalsLoading,
    data: subVitalSigns,
  } = useSubscriberVitalAssignmentsQuery(subscriberId)

  const isDataLoading = isSubLoading || orgVitalsLoading || subVitalsLoading

  const subVitalSignIds = subVitalSigns?.assignedVitalSigns.map(
    (vitalSign) => vitalSign.id
  )

  const unassignedVitalSigns = orgVitalSigns?.assignedVitalSigns // Only show vital signs that aren't already in subVitalSigns and that are unarchived
    .filter(
      (vitalSign) =>
        !subVitalSignIds?.includes(vitalSign.id) && !vitalSign.archived
    )

  return (
    <Layout
      title={
        <div className="flex gap-2">
          <h1 className="text-2xl font-bold">{t('Vital Sign Assignments')}:</h1>
          <SubscriberSelect />
        </div>
      }
      description={t(
        'Review and manage the vital signs that are assigned to this Subscriber. Remember, readings will only be collected for vital signs configured here.'
      )}
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `/subscribers/${currentSubscriber?.id}`,
        },
        {
          name: t('Subscriber Settings'),
          route: `/subscribers/${currentSubscriber?.id}/settings`,
        },
        {
          name: t('Vital Sign Assignments'),
        },
      ]}
      isUnauthorized={
        currentSubscriber?.organization && !assumedOrganizationRole
      }
      //
      isLoading={isDataLoading || subscribers.isLoading}
      // Show empty here if no org vital signs exist
      isEmpty={!orgVitalSigns?.assignedVitalSigns.length}
      emptyComponent={
        <VitalAssignmentEmpty
          title={t('No Data Found')}
          description={t(
            'No vital sign assignments exist for this organization'
          )}
          callToAction={
            <Button
              type="primary-filled"
              to="/organization-settings/vital-sign-assignments"
            >
              {t('Organization Vital Assignments')}
            </Button>
          }
        />
      }
      controls={[
        {
          label: t('Assign Vital Sign'),
          onClick: () => setIsAssignVitalSignFormOpen(true),
          disabled: !unassignedVitalSigns || unassignedVitalSigns.length === 0,
        },
        {
          label: t('View Archived Vital Signs'),
          onClick: () => navigate('archive'),
        },
        {
          label: t('View Audit Trail'),
          onClick: () => navigate('audit-trail'),
          // TODO: enable this audit trail when Vitals API supports subscriber level audits
          disabled: true,
        },
      ]}
    >
      <SubscriberVitalSignsGrid
        setIsAssignVitalSignFormOpen={setIsAssignVitalSignFormOpen}
      />

      <SubscriberAssignVitalSignsForm
        isFormOpen={isAssignVitalSignFormOpen}
        setIsFormOpen={setIsAssignVitalSignFormOpen}
      />
    </Layout>
  )
}

export default SubscriberVitalAssignments

const VitalAssignmentEmpty = tw(
  Empty
)`flex flex-grow border-2 border-dashed rounded`
