import faker from 'faker'
import { components } from 'types/build/organizations'
import { generateAuditable, generateArchivable, generateDisableable } from '.'

type generatePartyArgs = Partial<{
  phoneNumbers: components['schemas']['AddPhoneNumberDto'][]
  addresses: components['schemas']['AddAddressDto'][]
}>

const generateParty = ({ phoneNumbers, addresses }: generatePartyArgs = {}) => {
  const result: Party = {
    id: faker.random.uuid(),
    legacyIdentifier: '',
    phoneNumbers:
      phoneNumbers?.map((phoneNumber, index) => ({
        id: '' + index,
        phoneNumber: phoneNumber.phoneNumber || '',
        extension: phoneNumber.extension || '',
        phoneType: phoneNumber.phoneType,
      })) ?? [],
    addresses:
      addresses?.map((address, index) => ({
        id: '' + index,
        ...address,
        addressLine1: '' + address.addressLine1,
        addressLine2: '' + address.addressLine2,
        stateProvinceCode: '' + address.stateProvinceCode,
        city: '' + address.city,
        postalCode: '' + address.postalCode,
        countryCode: '' + address.countryCode,
        regionCode: '' + address.regionCode,
        ...generateAuditable(),
      })) ?? [],
    ...generateDisableable(),
    ...generateArchivable(),
    ...generateAuditable(),
  }

  return result
}

export default generateParty
