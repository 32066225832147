/**
 * return a new updated version of originalObj given an array of updateObjects
 * @param originalObj
 * @param updateObjects
 * @returns updated cache object
 */
const fromUpdateObjects = <
  T extends Record<string, unknown> = Record<string, unknown>
>(
  originalObj: T,
  updateObjects: UpdateObject[]
): T => ({
  ...originalObj,
  // map through property keys of originalObj
  ...Object.keys(originalObj).reduce((acc, key) => {
    const updateObj = updateObjects.find(
      (updateObject) => updateObject.path.slice(1) === key
    )

    // if there is an updateObj for this key add
    // the updated val to the accumulator
    if (updateObj) acc[key] = updateObj.value

    return acc
  }, {} as Record<string, unknown>),
})

export default fromUpdateObjects
