import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, vitalsApi, handleQueryError } from 'utils'
import { formatISO } from 'date-fns'

// used to update default reminder messages
const useCreateReminderMessagesMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['POST']['/organization/{organizationId}/reminder-messages'],
    KyError,
    createReminderMessageArgs,
    | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
    | undefined
  >(createReminderMessage, {
    onSuccess: () => {
      success({ message: t('Reminder messages created') })
    },
    onMutate: (data) => {
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
      >(['getReminderMessages', data.orgId])

      // grab preferred locales in order to optimistic update available translations query

      const preferredLocales = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/locales/preferred-locales']
      >('getLocales')

      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
        | undefined
      >(['getAvailableTranslations', data.orgId], (oldData) => {
        if (!oldData?.items) return undefined
        return {
          items: [
            ...oldData.items,
            {
              createdAt: formatISO(new Date()),
              createdBy: 'Addison',
              updatedAt: formatISO(new Date()),
              updatedBy: 'Addison',
              locale: {
                key: data.localeKey,
                name:
                  preferredLocales?.items?.find(
                    (locale) => locale.name === data.localeKey
                  )?.displayName || '',
              },
            },
          ],
        }
      })

      // optimistic update Reminder Messages
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(['getReminderMessages', data.orgId], (oldData) => {
        if (!oldData?.items) return undefined
        return {
          items: oldData.items.map((reminderMessage) => ({
            ...reminderMessage,
            message:
              data.messages.find(
                (newReminder) =>
                  newReminder.messageTypeId === reminderMessage.messageType.id
              )?.message || '',
          })),
        }
      })

      // optimistic update Reminder Translations
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(
        ['getReminderMessageTranslations', data.orgId, data.localeKey],
        (oldData) => {
          if (!oldData?.items) return undefined
          return {
            items: oldData.items.map((reminderMessage) => ({
              ...reminderMessage,
              message:
                data.messages.find(
                  (newReminder) =>
                    newReminder.messageTypeId === reminderMessage.messageType.id
                )?.message || '',
            })),
          }
        }
      )

      return snapshot
    },
    onError: (error, { orgId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
        | undefined
      >(['getReminderMessages', orgId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      queryCache.invalidateQueries(['getReminderMessages', args.orgId])
      queryCache.invalidateQueries(['getAvailableTranslations', args.orgId])
      queryCache.invalidateQueries([
        'getReminderMessageTranslations',
        args.orgId,
        args.localeKey,
      ])
    },
  })

  return mutation
}

export default useCreateReminderMessagesMutation

type createReminderMessageArgs = {
  orgId?: string
  localeKey: string
  messages: Array<{
    messageTypeId: string
    message: string
    reminderMessageId?: string
  }>
}
// update default reminder
const createReminderMessage = async (data: createReminderMessageArgs) => {
  const requestBody: VitalsAPIRequest['POST']['/organization/{organizationId}/reminder-messages']['body'] = {
    localeKey: data.localeKey,
    messages: data.messages,
  }

  const result = await vitalsApi
    .post(`organization/${data.orgId}/reminder-messages`, {
      json: requestBody,
    })
    .json<
      VitalsAPIResponse['POST']['/organization/{organizationId}/reminder-messages']
    >()
  return result
}
