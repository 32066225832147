import React, { useEffect, useState } from 'react'
import { Button } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  AutoComplete,
  RightPopup,
  Select,
  SelectItem,
  TextField,
} from 'elements'
import { useUpdateAlertDeliveryRuleMutation } from 'hooks/alert-delivery-rules'
import { useAlertCategoryQuery } from 'hooks/alerts'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { toUpdateObj } from 'utils'

type EditGeneralInformationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  alertDeliveryRule: AlertDeliveryRule
  alertCategory?: AlertCategory
}

const EditGeneralInformationForm = ({
  isFormOpen,
  setIsFormOpen,
  alertDeliveryRule,
  alertCategory,
}: EditGeneralInformationFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const alertCategories = useAlertCategoryQuery()

  const { t } = useTranslation()

  const [selectedAlertCategory, setSelectedAlertCategory] = useState<
    AlertCategory | undefined | null
  >(alertCategory)

  const {
    handleSubmit,
    errors,
    watch,
    control,
    reset,
  } = useForm<AlertDeliveryRule>()

  const updateAlertDeliveryRule = useUpdateAlertDeliveryRuleMutation()

  // reset form if alertDeliveryRule changes or form is opened
  useEffect(() => {
    if (isFormOpen) {
      reset(alertDeliveryRule)
      setSelectedAlertCategory(alertCategory)
    }
  }, [alertDeliveryRule, isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Edit General Information')}
      description={alertDeliveryRule.name || ''}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateAlertDeliveryRule.isLoading}
            onClick={handleSubmit(async (formData) => {
              // API call
              updateAlertDeliveryRule.mutate(
                {
                  requests: [
                    toUpdateObj('name', formData.name),
                    toUpdateObj('description', formData.description),
                    toUpdateObj('criteria', {
                      alertCategory: String(formData.criteria.alertCategory),
                      severity: formData.criteria.severity || undefined,
                    }),
                  ],
                  orgId: assumedOrganizationRole?.orgID || '',
                  deliveryRuleId: alertDeliveryRule.id,
                },
                // Close form drawer
                { onSuccess: () => setIsFormOpen(false) }
              )
            })}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updateAlertDeliveryRule.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <form>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          name="name"
          label={t('Name')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Name is required') }}
          error={errors.name?.message}
          defaultValue={alertDeliveryRule.name}
          placeholder={alertDeliveryRule.name}
          fullWidth
          required
        />
        <br />
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          name="description"
          label={t('Description')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Description is required') }}
          error={errors.description?.message}
          className="mt-4"
          defaultValue={alertDeliveryRule.description}
          placeholder={alertDeliveryRule.description}
          fullWidth
          multiline
          required
        />
        <br />
        <AlertCategoryContainer>
          <Controller
            control={control}
            render={({ onChange }) => {
              return (
                <CategoryAutoComplete<AlertCategory>
                  label={t('Alert Category')}
                  single
                  selectedOption={selectedAlertCategory}
                  error={errors.criteria?.alertCategory?.message}
                  options={alertCategories.data?.items || []}
                  optionLabel={(option) => option.name}
                  onChange={(option) => {
                    onChange(option?.id)
                    setSelectedAlertCategory(option)
                  }}
                />
              )
            }}
            name="criteria.alertCategory"
            defaultValue={alertCategory}
          />
          {watch('criteria.alertCategory') ===
          // check for vitals reading selected
          '694f33b4-1a16-4b38-8041-e6b600b0dc0e' ? (
            <Controller
              control={control}
              as={
                <Select
                  label={t('Severity Level')}
                  variant="outlined"
                  fullWidth={false}
                  selectClassName="w-48"
                >
                  {[1, 2, 3].map((severity, index) => (
                    <SelectItem value={severity} key={index}>
                      {`Severity ${severity}`}
                    </SelectItem>
                  ))}
                </Select>
              }
              name="criteria.severity"
              defaultValue={1}
            />
          ) : null}
        </AlertCategoryContainer>
      </form>
    </RightPopup>
  )
}

export default EditGeneralInformationForm

const CategoryAutoComplete = tw(AutoComplete)`w-96`

const AlertCategoryContainer = tw.div`flex flex-col`
