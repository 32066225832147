import React from 'react'
import { RightPopup, TextField } from 'elements'
import { Button } from 'atlas'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUpdateAlertDeliveryRuleMutation } from 'hooks/alert-delivery-rules'
import { toUpdateObj } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

type EditDeliveryInformationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  alertDeliveryRule: AlertDeliveryRule
}

const EditDeliveryInformationForm = ({
  isFormOpen,
  setIsFormOpen,
  alertDeliveryRule,
}: EditDeliveryInformationFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const { handleSubmit, errors, control, reset } = useForm<
    AlertDeliveryRule['delivery']
  >()

  const updateAlertDeliveryRule = useUpdateAlertDeliveryRuleMutation()

  // reset form if alertDeliveryRule changes or form is opened
  useEffect(() => {
    if (isFormOpen) reset(alertDeliveryRule.delivery)
  }, [alertDeliveryRule, isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Edit Delivery Details')}
      description={alertDeliveryRule.name || ''}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateAlertDeliveryRule.isLoading}
            onClick={handleSubmit((formData) => {
              // API call
              updateAlertDeliveryRule.mutate(
                {
                  requests: [
                    toUpdateObj('delivery', {
                      message: formData.message,
                      contacts: alertDeliveryRule.delivery.contacts,
                    }),
                  ],
                  orgId: assumedOrganizationRole?.orgID || '',
                  deliveryRuleId: alertDeliveryRule.id,
                },
                {
                  // Close form drawer
                  onSuccess: () => setIsFormOpen(false),
                }
              )
            })}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updateAlertDeliveryRule.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <form>
        <Controller
          control={control}
          as={TextField}
          name="message.subject"
          label={t('Email Subject')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Email Subject is required') }}
          error={errors.message?.subject?.message}
          // this is purely here to prevent console.warns
          defaultValue={alertDeliveryRule.delivery.message.subject}
          placeholder={alertDeliveryRule.delivery.message.subject}
          required
          fullWidth
        />
        <br />
        <Controller
          control={control}
          as={TextField}
          name="message.message"
          label={t('Email Message')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Email Message is required') }}
          error={errors.message?.message?.message}
          // this is purely here to prevent console.warns
          defaultValue=""
          className={alertDeliveryRule.delivery.message.message}
          placeholder={alertDeliveryRule.delivery.message.message}
          multiline
          required
          fullWidth
        />
      </form>
    </RightPopup>
  )
}

export default EditDeliveryInformationForm
