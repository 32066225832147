import React, { useState, useEffect } from 'react'
import { Icon, LoadingIcon, AutoComplete } from 'elements'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { useAddressAutocompleteQuery } from 'hooks/address-autocomplete'
import { useDebounce } from 'react-use'
import { SingleProps } from './AutoComplete'

type AddressAutoCompleteProps = {
  label?: string
  placeholder?: string
  onChange: (newSelectedOption: MapBoxFeature | null) => void
  selectedOption: MapBoxFeature | null
  setSelectedOption: (selectedOption: MapBoxFeature | null) => void
  className?: string
  disabled?: boolean
  error?: string
}

const AddressAutoComplete = ({
  label,
  placeholder,
  onChange,
  selectedOption,
  setSelectedOption,
  className,
  disabled,
  error,
}: AddressAutoCompleteProps) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [options, setOptions] = useState<MapBoxFeature[]>([])

  const addressAutocompleteQuery = useAddressAutocompleteQuery(
    debouncedSearchTerm
  )

  // debounce searchTerm value before querying address autocomplete service
  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm)
    },
    500,
    [searchTerm]
  )

  useEffect(() => {
    if (searchTerm === '') {
      setOptions(selectedOption ? [selectedOption] : [])
      return
    }

    setOptions([
      ...(selectedOption ? [selectedOption] : []),
      ...(addressAutocompleteQuery.data
        ? addressAutocompleteQuery.data.features
        : []),
    ])
  }, [selectedOption, addressAutocompleteQuery.data])

  return (
    <BaseAutoComplete<React.FC<SingleProps<MapBoxFeature>>>
      single
      label={label}
      placeholder={placeholder}
      error={error}
      optionLabel={(option) => option.place_name.split(',')[0]}
      filterOptions={(x) => x}
      options={options}
      selectedOption={selectedOption}
      disableAutofill
      noOptionsText={
        searchTerm === '' ? (
          ''
        ) : addressAutocompleteQuery.data?.features.length === 0 ? (
          t('No Addresses Found')
        ) : (
          <LoadingContainer>
            <LoadingIcon width="30px" height="30px" />
          </LoadingContainer>
        )
      }
      onChange={(newSelectedOption) => {
        setOptions(
          newSelectedOption ? [newSelectedOption, ...options] : options
        )
        setSelectedOption(newSelectedOption)

        // call onChange prop
        onChange(newSelectedOption)
      }}
      onInputValueChange={(newSearchTerm) => {
        setSearchTerm(newSearchTerm)
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <OptionContainer>
            <AutoCompleteIcon type="location" />
            <ResultTextContainer>
              <ResultMainText>{option.text}</ResultMainText>
              <ResultSecondaryText>{option.place_name}</ResultSecondaryText>
            </ResultTextContainer>
          </OptionContainer>
        </li>
      )}
      className={className}
      disabled={disabled}
    />
  )
}

export default AddressAutoComplete

const AutoCompleteIcon = tw(Icon)`h-full self-center mr-2`

const BaseAutoComplete = styled(AutoComplete)<{ className?: string }>(
  ({ className }) => ['min-width: 12rem;', className]
)

const OptionContainer = tw.div`flex -ml-2 overflow-hidden`

const ResultTextContainer = tw.div`overflow-hidden`

const ResultMainText = tw.h4`font-medium`

const ResultSecondaryText = tw.p`truncate`

const LoadingContainer = tw.div`flex justify-center py-2`
