import React, { useRef, useState } from 'react'
import { FadeInSlideRight, FadeIn } from 'animations'
import { Button, Empty } from 'atlas'
import Breadcrumbs, { BreadcrumbsProps } from 'atlas/Breadcrumbs/Breadcrumbs'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import {
  LoadingIcon,
  Unauthorized,
  Dropdown,
  DropdownItem,
  Icon,
} from 'elements'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

type LayoutProps = {
  title: React.ReactNode | string
  controls?:
    | React.ReactElement
    | Array<{ label: string; onClick: () => void; disabled?: boolean }>
    | null
  children: React.ReactNode
  description?: string
  breadcrumbs?: BreadcrumbsProps['breadcrumbs']
  isLoading?: boolean
  isUnauthorized?: boolean
  isEmpty?: boolean
  emptyComponent?: React.ReactNode
}

const Layout = ({
  title,
  controls,
  children,
  breadcrumbs,
  description,
  isLoading,
  isUnauthorized,
  isEmpty,
  emptyComponent,
}: LayoutProps) => {
  const { t } = useTranslation()

  const dropdownAnchorEl = useRef(null)
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

  return (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: {
          autoHide: 'scroll',
        },
      }}
      className="h-full"
    >
      {isLoading ? (
        <div className="flex flex-col h-full flex-grow">
          <LoadingIcon height="100%" />
        </div>
      ) : (
        <div className="flex flex-col min-h-full flex-grow">
          <div className={clsx(['px-8 pt-8 pb-4 flex flex-col flex-grow'])}>
            <div className="flex justify-between pb-4 border-b mb-8 items-center">
              <FadeInSlideRight className="flex-initial">
                {breadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}

                <div className="flex">
                  {/* use the default styling if a string is provided, else use the override */}
                  {typeof title === 'string' ? (
                    <h1 className="text-2xl font-bold">{title}</h1>
                  ) : (
                    title
                  )}
                </div>

                <p className="text-gray-700">{description}</p>
              </FadeInSlideRight>
              {!isUnauthorized ? (
                <FadeIn className="flex-grow flex justify-end ml-4">
                  {!controls || React.isValidElement(controls) ? (
                    controls
                  ) : (
                    <div className="relative" ref={dropdownAnchorEl}>
                      <Button
                        type="primary-filled"
                        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                      >
                        {t('Actions')}&nbsp;
                        <Icon
                          type="chevron-down"
                          className="ml-1"
                          style={{ color: 'relative' }}
                        />
                      </Button>
                      <Dropdown
                        visible={isDropdownVisible}
                        setVisible={setIsDropdownVisible}
                        parentRef={dropdownAnchorEl}
                        verticalOffset={5}
                      >
                        {controls.map(({ label, onClick, disabled }, index) => (
                          // TODO: determine why wrapping DropdownItem in styled errors out
                          <DropdownItem
                            key={index}
                            onClick={!disabled ? onClick : undefined}
                            className={clsx(
                              disabled && 'text-gray-600 cursor-not-allowed',
                              !disabled && 'cursor-pointer'
                            )}
                          >
                            {label}
                          </DropdownItem>
                        ))}
                      </Dropdown>
                    </div>
                  )}
                </FadeIn>
              ) : null}
            </div>
            {(() => {
              if (isUnauthorized) return <Unauthorized />

              if (isEmpty) return emptyComponent || <Empty />

              return children
            })()}
          </div>
        </div>
      )}
    </OverlayScrollbarsComponent>
  )
}

export default Layout
