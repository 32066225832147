import React from 'react'
import tw, { styled } from 'twin.macro'
import { FadeInSlideDown } from 'animations'
import { useTranslation } from 'react-i18next'
import { Icon, Text } from 'elements'
import WarrantyCard from './WarrantyCard'
import { Tooltip } from 'atlas'
import { formatPrice } from 'utils'
import { useAPIQuery } from 'hooks'

type ProductCardProps = {
  lineItem: (
    | ContractLineItem['primaryProductLineItem']
    | ContractLineItem['deviceOrServiceLineItem']
    | (ContractLineItem['productTypeLineItem'] & {
        productId?: string | null | undefined
      })
  ) & {
    selectedWarrantyId: string | null | undefined
    isSelected?: boolean
  }
  setLineItem: (
    lineItem: (
      | ContractLineItem['primaryProductLineItem']
      | ContractLineItem['deviceOrServiceLineItem']
      | (ContractLineItem['productTypeLineItem'] & {
          productId?: string | null | undefined
        })
    ) & {
      selectedWarrantyId: string | null | undefined
      isSelected?: boolean
    }
  ) => void
  optional?: boolean
  delay?: number
}

const ProductCard = ({
  lineItem,
  setLineItem,
  optional,
  delay,
}: ProductCardProps) => {
  const { t } = useTranslation()

  const productsQuery = useAPIQuery('products')

  const product = productsQuery.data?.items?.find(
    (product) => product.id === lineItem.productId
  )
  if (!product) return null

  const productImage = product.productImage?.find((image) => !image.deleted)

  return (
    <Container
      delay={delay}
      optional={optional}
      checked={lineItem.isSelected}
      onClick={() =>
        optional &&
        setLineItem({ ...lineItem, isSelected: !lineItem.isSelected })
      }
    >
      <ProductImageContainer>
        {productImage?.imageUrl ? (
          <ProductImage
            src={productImage.imageUrl}
            alt={product.title || undefined}
          />
        ) : (
          <ProductIcon
            type={product.productType === 'Service' ? 'radio' : 'hardware'}
          />
        )}
      </ProductImageContainer>
      {optional ? (
        <SelectionCircle isSelected={!!lineItem.isSelected}>
          <CheckIcon type="check" isSelected={!!lineItem.isSelected} />
        </SelectionCircle>
      ) : null}
      <ProductCardContainer>
        <HeaderContainer>
          <LeftContent>
            <Title>{lineItem.descriptionInfo?.title}</Title>
            <ItemDescription>
              {lineItem.descriptionInfo?.description || ''}
            </ItemDescription>
          </LeftContent>
        </HeaderContainer>
        <Pricing>
          <ProductFee>
            <FeeTitle>
              {lineItem.pricing?.productFee?.descriptionInfo?.title ||
                t('Product Fee')}
              {lineItem.pricing?.productFee?.descriptionInfo?.description ? (
                <InfoTooltip
                  content={
                    <TooltipText>
                      {lineItem.pricing.productFee.descriptionInfo.description}
                    </TooltipText>
                  }
                >
                  <InfoIcon type="info" />
                </InfoTooltip>
              ) : null}
            </FeeTitle>
            <FeePrice>
              {lineItem.pricing?.productFee?.price
                ? formatPrice(lineItem.pricing.productFee.price)
                : t('N/A')}
            </FeePrice>
          </ProductFee>
          <ActivationFee>
            <FeeTitle>
              {lineItem.pricing?.activationFee?.descriptionInfo?.title ||
                t('Activation Fee')}
              {lineItem.pricing?.activationFee?.descriptionInfo?.description ? (
                <InfoTooltip
                  content={
                    <TooltipText>
                      {
                        lineItem.pricing.activationFee.descriptionInfo
                          .description
                      }
                    </TooltipText>
                  }
                >
                  <InfoIcon type="info" />
                </InfoTooltip>
              ) : null}
            </FeeTitle>

            <FeePrice>
              {lineItem.pricing?.activationFee?.price
                ? formatPrice(lineItem.pricing.activationFee.price)
                : t('N/A')}
            </FeePrice>
          </ActivationFee>
          <WarrantyCard lineItem={lineItem} setLineItem={setLineItem} />
        </Pricing>
      </ProductCardContainer>
    </Container>
  )
}

export default ProductCard

const Container = styled(FadeInSlideDown)<{
  optional?: boolean
  checked?: boolean
}>(({ optional, checked }) =>
  optional
    ? [
        tw`bg-white flex my-2 p-5 rounded-lg border-2 cursor-pointer hover:shadow-lg relative`,
        checked
          ? tw`border-blue-400`
          : tw`border-gray-200  hover:border-gray-300`,
      ]
    : [tw`bg-white flex p-5 mt-2 border rounded-lg`]
)

const HeaderContainer = tw.div`border-b pb-2 mb-2 align-top flex items-center justify-between`

const ProductCardContainer = tw.div`flex-grow relative`

const Title = tw.div`text-lg font-medium`

const LeftContent = tw.div`w-full`

const ItemDescription = tw(
  Text
)`font-normal text-gray-600 -mt-2 h-5 w-full block`

const Pricing = tw.div`flex mt-2`

const ProductFee = tw.div`pr-4`

const ActivationFee = tw.div`px-4`

const FeeTitle = tw.div`text-sm text-gray-600 mr-4 leading-4 flex`

const FeePrice = tw.div``

const ProductImageContainer = tw.div`w-24 mr-6 flex items-center justify-center`

const ProductIcon = tw(Icon)`w-14 h-14 text-gray-400`

const ProductImage = styled.img`
  object-fit: cover;
  ${() => tw`h-full`}
`

const SelectionCircle = styled.div<{
  isSelected: boolean
}>(({ isSelected }) => [
  tw`absolute top-5 right-5 border-2 rounded-full`,
  isSelected
    ? tw`bg-blue-400 border-blue-400 shadow-md`
    : tw`border-gray-300 bg-white`,
])

const CheckIcon = styled(Icon)<{ isSelected: boolean }>(({ isSelected }) => [
  tw`w-7 h-7 pt-0.5`,
  isSelected ? tw` text-white` : tw`text-gray-300`,
])

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-4 h-4 -mt-0.5`

const InfoTooltip = tw(Tooltip)`ml-2 h-fit-content`

const TooltipText = tw.p`w-60`
