import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { orgsApi } from 'utils'

const useDeactivatePartnerTypeMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<DeactivatePartnerTypeResponse, string>({
    mutationFn: deactivatePartnerType,
    successMsg: t('Partner Type deactivated'),
    optimisticUpdates: [
      {
        cacheKey: 'getPartnerTypes',
        updateFn: updateFnConstructor<GetPartnerTypesResponse, string>(
          (oldCache, partnerTypeId) =>
            oldCache
              ? {
                  ...oldCache,
                  items: oldCache.items.map((partnerType) => {
                    if (partnerType.id === partnerTypeId)
                      return {
                        ...partnerType,
                        activeInfo: {
                          ...partnerType.activeInfo,
                          active: false,
                        },
                      }

                    return partnerType
                  }),
                }
              : oldCache
        ),
      },
      {
        cacheKey: (partnerTypeId) => ['getPartnerType', partnerTypeId],
        updateFn: updateFnConstructor<GetPartnerTypeResponse, string>(
          (oldCache) =>
            oldCache
              ? {
                  ...oldCache,
                  activeInfo: {
                    ...oldCache.activeInfo,
                    active: false,
                  },
                }
              : oldCache
        ),
      },
    ],
  })

  return mutation
}

export default useDeactivatePartnerTypeMutation

const deactivatePartnerType = async (adminId: string) => {
  const result = orgsApi
    .post(`api/partner-types/${adminId}/deactivate`)
    .json<DeactivatePartnerTypeResponse>()

  return result
}
