import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { isPresent, typedOrgsApi } from 'utils'
import _ from 'lodash'

const useUpdateApplicationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/applications/{applicationId}'],
    EditApplicationProps
  >({
    mutationFn: updateApplication,
    successMsg: t('Application updated'),
    additionalCachesToInvalidate: ['getModules'],
    optimisticUpdates: [
      {
        cacheKey: 'getApplications',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/applications'],
          EditApplicationProps
        >((oldCache, { existingApplication, applicationData }) =>
          oldCache?.items
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items.map((application) =>
                    application.id === existingApplication.id
                      ? {
                          ...existingApplication,
                          ...applicationData,
                          modules: [],
                        }
                      : application
                  ),
                ],
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useUpdateApplicationMutation

type EditApplicationProps = {
  existingApplication: Application
  applicationData: CreateApplicationForm
}

const updateApplication = async ({
  existingApplication,
  applicationData,
}: EditApplicationProps) => {
  const modulesToAdd = _.difference(
    applicationData.modules,
    existingApplication.modules?.map((module) => module.id) || []
  )

  const ApplicationFields = {
    name: applicationData.name,
    cognitoAppId: applicationData.cognitoAppId,
    description: applicationData.description,
  }

  const patchApplicationReq: OrgsAPIRequest['PATCH']['/api/applications/{applicationId}']['body'] = Object.entries(
    ApplicationFields
  )
    .filter((field): field is [string, string] => isPresent(field[1]))
    .map(([fieldKey, fieldValue]) => ({
      value: fieldValue,
      path: `/${fieldKey}`,
      op: 'replace',
    }))

  // patch normal application fields
  const patchApplicationResult = await typedOrgsApi.patch(
    '/api/applications/{applicationId}',
    {
      pathParams: { applicationId: existingApplication.id },
      body: patchApplicationReq,
    }
  )

  let assignApplicationModulesResult

  // If there are modules to attach, make separate API call to assign them
  if (modulesToAdd.length > 0) {
    const assignAppModulesReq: OrgsAPIRequest['POST']['/api/applications/{applicationId}/modules']['body'] = {
      moduleIds: modulesToAdd,
    }
    assignApplicationModulesResult = await typedOrgsApi.post(
      '/api/applications/{applicationId}/modules',
      {
        pathParams: { applicationId: existingApplication.id },
        body: assignAppModulesReq,
      }
    )
  }
  return assignApplicationModulesResult || patchApplicationResult
}
