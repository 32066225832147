import React from 'react'
import { Chip } from 'atlas'
import { colors } from 'atlas/Chip/Chip'

type OrderStatusChipProps = {
  status: OrderStatus
  className?: string | undefined
}

const OrderStatusChip = ({ status, className }: OrderStatusChipProps) => (
  <Chip
    className={className}
    color={
      orderStatusToColors[
        status.title && isOrderStatusTitle(status.title)
          ? status.title
          : 'default'
      ]
    }
  >
    {status.title}
  </Chip>
)

export default OrderStatusChip

export enum OrderStatusTitles {
  CONFIRMED = 'Confirmed',
  IN_PROCESS = 'In-Process',
  PROVISIONING_IN_PROCESS = 'Provisioning In-Process',
  PROVISIONING_COMPLETE = 'Provisioning Complete',
  SHIPPED = 'Shipped',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
}

export const orderStatusToColors: Record<
  OrderStatusTitles | 'default',
  keyof typeof colors
> = {
  Confirmed: 'gray',
  'In-Process': 'yellow',
  'Provisioning In-Process': 'orange',
  'Provisioning Complete': 'cyan',
  Shipped: 'lightblue',
  Delivered: 'green',
  Cancelled: 'red',
  default: 'gray',
}

export const isOrderStatusTitle = (str: string): str is OrderStatusTitles =>
  Object.keys(orderStatusToColors).includes(str)
