import React from 'react'
import { InformationCard } from 'elements'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next'
import { parseISO } from 'date-fns'
import { formatDate } from 'utils'

type AlertDetailsInformationProps = {
  alert: Alert
}

const AlertInformationCard = ({ alert }: AlertDetailsInformationProps) => {
  const { t } = useTranslation()
  return (
    <>
      <InformationCard className="mb-4" size="md">
        <InformationCard.Item
          label={t('Type')}
          value={
            alert.alertCategory.code === 'vitals-reading'
              ? alert.alertCategory.name
              : ''
          }
        />
        <InformationCard.Item
          label={t('Created At')}
          value={
            alert.auditInfo.createdAt
              ? formatDate(parseISO(alert.auditInfo.createdAt), 'Pp')
              : ''
          }
        />
        <InformationCard.Item
          label={t('Status')}
          value={
            alert.addressed ? (
              <Chip color="green">{t('Addressed')}</Chip>
            ) : (
              <Chip color="red">{t('Unaddressed')}</Chip>
            )
          }
        />
        {alert.addressed ? (
          <>
            <InformationCard.Item
              label={t('Addressed At')}
              value={formatDate(parseISO(alert.addressedAt || ''), 'Pp')}
            />
            <InformationCard.Item
              label={t('Addressed By')}
              value={alert.addressedBy}
            />
          </>
        ) : null}
        {/* Extra information fields for specific alert types */}
        {
          {
            'vitals-reading': (
              <>
                <InformationCard.Item
                  label={t('Reading')}
                  value={
                    alert.vitalReading
                      ? `${alert.vitalReading.reading} (${alert.vitalReading.unit})`
                      : '-'
                  }
                />
                <InformationCard.Item
                  label={t('Severity')}
                  value={<SeverityChip row={alert} />}
                />
              </>
            ),
            //TODO: uncomment when phone log is included by alerts query
            //   'phone-log': <>
            //   {
            //   alert.observation.log.from ? (
            //     <InformationCard.Item
            //       label={t('Phone Number')}
            //       value={alert.observation.log.from}
            //     />
            //   ) : null}
            //   {
            //   alert.observation.callerName ? (
            //     <InformationCard.Item
            //       label={t('Call Name')}
            //       value={alert.observation.callerName}
            //     />
            //   ) : null}
            //   {
            //   alert.observation.log.duration ? (
            //     <InformationCard.Item
            //       label={t('Call Duration')}
            //       value={`${alert.observation.log.duration} ${t(
            //         'minutes'
            //       )}`}
            //     />
            //   ) : null}
            // </>,
          }[alert.alertCategory.code]
        }
      </InformationCard>
      {/* TODO: refactor this to work with the new vital charts when they are done */}
      {/* Show vital sign chart if alert is a vital sign reading */}
      {/* {alert.alertType === 'vital-sign-reading' ? (
            <HeartRateChart
              data={vitalData}
              title={`${vitalSign?.name || ''} ${t('Below Threshold By')} ${
                isVitalObservation(alert.observation) && alert.observation.delta
              } ${vitalSign?.defaultUnitCode}`}
            />
          ) : null} */}
    </>
  )
}

export default AlertInformationCard

const SeverityChip = ({ row }: { row: Alert }) => {
  const { t } = useTranslation()

  return (
    <div>
      {row.vitalReading ? (
        <Chip
          color={
            (['yellow', 'orange', 'red'] as const)[
              row.vitalReading.thresholdSeverity - 1
            ]
          }
        >{`${t('Severity')} ${row.vitalReading.thresholdSeverity} - ${
          row.vitalReading.reading > row.vitalReading.normalHigh
            ? t('Above Maximum')
            : t('Below Minimum')
        }`}</Chip>
      ) : (
        '-'
      )}
    </div>
  )
}
