import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

type useVitalReadingsChartQueryArgs = {
  dateRangeState: DateRangeState
  vitalSignId: string
  organizationId?: string
  subscriberId?: string
}

const useVitalReadingsChartQuery = ({
  dateRangeState,
  vitalSignId,
  organizationId,
  subscriberId,
}: useVitalReadingsChartQueryArgs) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/insights/vital-observations/statistics/chart'],
    KyError
  >(
    [
      'getVitalReadingsChart',
      dateRangeState,
      vitalSignId,
      organizationId,
      subscriberId,
    ],
    () =>
      getVitalReadingsChart(
        dateRangeState,
        vitalSignId,
        organizationId,
        subscriberId
      )
  )

  return query
}

export default useVitalReadingsChartQuery

const getVitalReadingsChart = async (
  dateRangeState: DateRangeState,
  vitalSignId: string,
  organizationId?: string,
  subscriberId?: string
) => {
  const baseSearchParams = new URLSearchParams([
    ['dateRangeType', dateRangeState.preset],
    ['vitalSignId', vitalSignId],
  ])

  if (organizationId) baseSearchParams.append('organizationId', organizationId)

  if (subscriberId) baseSearchParams.append('subscriberId', subscriberId)

  if (dateRangeState.preset === 'custom') {
    baseSearchParams.append('from', dateRangeState.value[0].toISOString())
    baseSearchParams.append('to', dateRangeState.value[1].toISOString())
  }

  // vitals-reading call
  const result = await vitalsApi
    .get(`insights/vital-observations/statistics/chart`, {
      searchParams: baseSearchParams.toString(),
    })
    .json<
      VitalsAPIResponse['GET']['/insights/vital-observations/statistics/chart']
    >()

  return result
}
