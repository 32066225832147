import { Button, Tooltip } from 'atlas'
import { format } from 'date-fns'
import { RightPopup, Icon, InformationCard } from 'elements'
import { useContractQuery, useContractLineItemsQuery } from 'hooks/contracts'
import React from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { getLineItemFields, formatPrice } from 'utils'

type OrderContractInfoCardProps = {
  open: boolean
  setOpen: (newValue: boolean) => void
  contractId?: string | null
  organizationId?: string | null
}

const OrderContractInfoCard = ({
  open,
  setOpen,
  contractId,
  organizationId,
}: OrderContractInfoCardProps) => {
  const { t } = useTranslation()

  const contractQuery = useContractQuery({ contractId, organizationId })

  const lineItemsQuery = useContractLineItemsQuery({
    organizationId: organizationId || '',
    contractId: contractId || '',
  })

  const contract = contractQuery.data

  return (
    <RightPopup
      title={t('Contract Details')}
      open={open}
      setOpen={setOpen}
      width="700px"
      controls={
        <CancelButton onClick={() => setOpen(false)} type="secondary">
          {t('Cancel')}
        </CancelButton>
      }
    >
      <ContractDetailsContainer>
        <ContractSpecs>
          <InformationCard.Item
            displayMode="labelValue"
            label={t('Title')}
            value={contract?.descriptionInfo?.title}
          />
          <InformationCard.Item
            displayMode="labelValue"
            label={t('Description')}
            value={contract?.descriptionInfo?.description}
          />
          <InformationCard.Item
            displayMode="labelValue"
            label={t('Currency Code')}
            value={contract?.currencyCode}
          />
          <InformationCard.Item
            displayMode="labelValue"
            label={t('Effective dates')}
            value={
              (contract?.effectiveDates?.fromDate
                ? format(new Date(contract.effectiveDates.fromDate || 0), 'PP')
                : t('Unspecified')) +
              ' - ' +
              (contract?.effectiveDates?.toDate
                ? format(new Date(contract.effectiveDates.toDate || 0), 'PP')
                : t('Unspecified'))
            }
          />
        </ContractSpecs>
        <Title>{t('Available Line Items')}</Title>
        {lineItemsQuery.data?.items?.map((lineItem, index) => {
          // get the line item fields that correspond to the lineItemType
          const lineItemFields = getLineItemFields<
            | 'primaryProductLineItem'
            | 'deviceOrServiceLineItem'
            | 'productTypeLineItem'
          >(lineItem)

          return (
            <LineItemCard key={index}>
              <LineItemTitle>
                {lineItemFields?.descriptionInfo?.title}
                <Tip
                  content={lineItemFields?.descriptionInfo?.description}
                  orientation="start"
                >
                  <InfoIcon type="info" />
                </Tip>
              </LineItemTitle>
              <LineItemProductFee>
                <LineItemProductFeeTitle>
                  {lineItemFields?.pricing?.productFee?.descriptionInfo
                    ?.title || t('Product Fee')}
                </LineItemProductFeeTitle>
                <LineItemProductFeePrice>
                  {(lineItemFields?.pricing?.productFee?.price &&
                    formatPrice(lineItemFields.pricing.productFee.price)) ||
                    '-'}
                </LineItemProductFeePrice>
              </LineItemProductFee>
              <LineItemActivationFee>
                <LineItemActivationFeeTitle>
                  {lineItemFields?.pricing?.activationFee?.descriptionInfo
                    ?.title || t('Activation Fee')}
                </LineItemActivationFeeTitle>
                <LineItemActivationFeePrice>
                  {(lineItemFields?.pricing?.activationFee?.price &&
                    formatPrice(lineItemFields.pricing.activationFee.price)) ||
                    '-'}
                </LineItemActivationFeePrice>
              </LineItemActivationFee>
            </LineItemCard>
          )
        })}
      </ContractDetailsContainer>
    </RightPopup>
  )
}

export default OrderContractInfoCard

const ContractDetailsContainer = tw.div`flex flex-col`

const ContractSpecs = tw.div`grid grid-cols-2 gap-x-2 gap-y-4 bg-white rounded border p-4`

const LineItemCard = tw.div` flex items-center w-full my-2 py-2 px-4 border bg-white rounded`

const LineItemTitle = tw.div`flex flex-row text-lg flex-grow`

const LineItemProductFee = tw.div`px-4`

const LineItemProductFeeTitle = tw.div`text-sm text-gray-600 truncate mr-4`

const LineItemProductFeePrice = tw.div``

const LineItemActivationFee = tw.div`px-4`

const LineItemActivationFeeTitle = tw.div`text-sm text-gray-600 truncate mr-4`

const LineItemActivationFeePrice = tw.div``

const Title = tw.div`text-lg font-semibold flex-grow pt-4`

const CancelButton = tw(Button)``

const Tip = tw(Tooltip)`ml-2`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`
