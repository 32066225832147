/**
 * type assertion that narrows down a generic string to the specific LocaleCode type
 * @param str
 * @returns a boolean for if the given string can be assigned in the LocaleCode string literal union
 */
const isLocaleCode = (str?: string): str is LocaleCode => {
  return (
    str === 'en-US' ||
    str === 'en-IN' ||
    str === 'es-MX' ||
    str === 'tr-TR' ||
    str === 'ru-RU' ||
    str === 'ro-RO' ||
    str === 'pt-PT' ||
    str === 'pl-PL' ||
    str === 'nl-NL' ||
    str === 'it-IT' ||
    str === 'is-IS' ||
    str === 'fr-FR' ||
    str === 'es-ES' ||
    str === 'de-DE' ||
    str === 'ko-KR' ||
    str === 'en-GB-WLS' ||
    str === 'hi-IN' ||
    str === 'arb' ||
    str === 'cy-GB' ||
    str === 'cmn-CN' ||
    str === 'da-DK' ||
    str === 'en-AU' ||
    str === 'pt-BR' ||
    str === 'nb-NO' ||
    str === 'sv-SE' ||
    str === 'ja-JP' ||
    str === 'es-US' ||
    str === 'fr-CA' ||
    str === 'en-GB'
  )
}

export default isLocaleCode
