import React, { useCallback, useMemo } from 'react'
import { EntityLabel, List, LoadingIcon } from 'elements'
import { useNavigate } from 'hooks'
import { useAdminListQuery } from 'hooks/admins'
import { useEntityLabelSort } from 'hooks/react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Column, Row, useGlobalFilter, useSortBy, useTable } from 'react-table'

const AdminList = () => {
  const { data: adminList, isLoading } = useAdminListQuery()

  const adminLabelSort = useEntityLabelSort({
    accessor: ['firstName', 'lastName'],
  })

  const navigate = useNavigate()

  const { t } = useTranslation()

  const tableData: Admin[] = useMemo(() => {
    return adminList?.items || []
  }, [adminList, i18next.language])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Admin>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const adminName = `${row.original.person.firstName.toLowerCase()} ${row.original.person.lastName.toLowerCase()}`

        return adminName.includes(globalFilter.toLowerCase())
      })
    },
    []
  )

  const columns: Column<Admin>[] = useMemo(
    () => [
      {
        id: 'ADMIN',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ADMIN'),
        accessor: (row) => (
          <EntityLabel
            name={`${row.person.firstName} ${row.person.lastName}`}
            id={row.id}
            bold
          />
        ),
        sortType: adminLabelSort,
      },
      // TODO: Determine if admin activate/deactivate is a v1 feature
      // {
      //   id: 'STATUS',
      //   //@ts-expect-error can't expect the exact wording due to translation
      //   Header: t('STATUS'),
      //   accessor: (row) =>
      //     row.activeInfo.active ? (
      //       <Chip color="green">{t('Active')}</Chip>
      //     ) : (
      //       <Chip color="red">{t('Inactive')}</Chip>
      //     ),
      //   width: '0.5fr',
      //   sortType: statusSort,
      // },
      // {
      //   id: 'ACTIONS',
      //   //@ts-expect-error can't expect the exact wording due to translation
      //   Header: t('ACTIONS'),
      //   accessor: (row) => <ActionsCell row={row} />,
      //   disableSortBy: true,
      //   width: '0.5fr',
      // },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      onRowClick: (row) => navigate(row.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'ADMIN',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy
  )

  if (isLoading) return <LoadingIcon />

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'admins'
      )}`}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default AdminList

// type ActionsCellProps = {
//   row: Admin
// }

// const ActionsCell = ({ row }: ActionsCellProps) => {
//   const { t } = useTranslation()
//   const dropdownAnchorEl = useRef(null)
//   const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

//   const {mutate: activateAdmin} = useActivateAdminMutation()
//   const {mutate: deactivateAdmin} = useDeactivateAdminMutation()

//   return (
//     <div className="relative inline-flex self-center" ref={dropdownAnchorEl}>
//       <Icon
//         type="ellipsis"
//         className="text-gray-900 w-5 h-5"
//         onClick={() => setIsDropdownVisible(!isDropdownVisible)}
//         data-testid={`${row.person.firstName} ${row.person.lastName}-ellipsis`}
//       />
//       <Dropdown
//         visible={isDropdownVisible}
//         setVisible={setIsDropdownVisible}
//         parentRef={dropdownAnchorEl}
//       >
//         <DropdownItem>{t('Edit')}</DropdownItem>
//         <DropdownItem
//           onClick={async () => {
//             row.activeInfo.active
//               ? deactivateAdmin(row.id)
//               : activateAdmin(row.id)

//             setIsDropdownVisible(false)
//           }}
//         >
//           {row.activeInfo.active ? t('Deactivate') : t('Activate')}
//         </DropdownItem>
//       </Dropdown>
//     </div>
//   )
// }
