import React from 'react'
import { FadeInSlideDown } from 'animations'
import { Button } from 'atlas'
import { useShippingOptionsQuery } from 'hooks/contracts'
import { useOrganizationQuery } from 'hooks/organizations'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useDelay } from 'hooks'
import tw from 'twin.macro'
import { InformationCard } from 'elements'

type ShippingDetailsCardProps = {
  order?: Order
}

const ShippingDetailsCard = ({ order }: ShippingDetailsCardProps) => {
  const { t } = useTranslation()
  const delay = useDelay()
  const shippingOptionsQuery = useShippingOptionsQuery({
    organizationId: order?.organizationId || '',
    contractId: order?.contractId || '',
  })

  const organizationQuery = useOrganizationQuery(order?.organizationId || '')

  const organizationName = organizationQuery.data?.displayName

  const shippingAddress = order?.shippingInformation?.address

  const shippingMethod = shippingOptionsQuery.data?.items?.find(
    (item) => item.id === order?.shippingInformation?.shippingMethodId
  )

  const shippingContactName =
    order?.shippingInformation?.shippingContactName?.firstName +
    ' ' +
    order?.shippingInformation?.shippingContactName?.lastName

  return (
    <ShippingContainer delay={delay()}>
      <Title>{t('Shipping Details')} </Title>
      <InfoCardContainer>
        <InformationItem
          displayMode="labelValue"
          label={t('Details')}
          value={shippingMethod?.priceDescriptor?.descriptionInfo?.description}
        />
        <InformationItem
          displayMode="labelValue"
          label={t('Ship To')}
          value={
            order?.shippingInformation?.sameAsPrimaryAddress
              ? shippingContactName
              : order?.shippingInformation?.sameAsOrganizationAddress
              ? organizationName
              : t('Custom Address')
          }
        />
        <InformationItem
          displayMode="labelValue"
          label={t('Est Service Start Date')}
          value={
            order?.serviceDetails?.expectedServiceStartDate
              ? format(
                  new Date(order.serviceDetails.expectedServiceStartDate),
                  'PP'
                )
              : '-'
          }
        />
        <InformationItem
          displayMode="labelValue"
          label={
            <DeliveryButton type="primary-link">
              {t('Schedule Delivery')}
            </DeliveryButton>
          }
          value={null}
        />
        <AddressItem
          displayMode="labelValue"
          label={t('Delivery Address')}
          value={`${shippingAddress?.addressLine1},
              ${shippingAddress?.city}, 
              ${shippingAddress?.stateProvinceCode} 
              ${shippingAddress?.postalCode}`}
        />
        <InformationItem
          displayMode="labelValue"
          label={t('Shipping Price')}
          /* This shipping price doesn't seem right, since the shipping method differs */
          value={'$' + (order?.shippingInformation?.shippingCost || '0')}
        />
        <InformationItem
          displayMode="labelValue"
          label={t('Est Delivery Day')}
          value={t('Jan 31, 2021')}
        />
      </InfoCardContainer>
    </ShippingContainer>
  )
}

export default ShippingDetailsCard

const ShippingContainer = tw(FadeInSlideDown)`mb-6`

const Title = tw.div`text-lg font-medium text-gray-700 mb-4`

const InfoCardContainer = tw.div`grid grid-cols-4 bg-white border rounded`

const InformationItem = tw(InformationCard.Item)`mx-6 py-4`

const AddressItem = tw(InformationItem)`col-span-2`

const DeliveryButton = tw(Button)`w-fit-content`
