import { Row } from 'react-table'

/**
 * manually sorts an array of react-table Rows, helpful for custom react-table sorting and
 * needed when pinning items to the top of a table
 * @param arr
 * @param funcs
 * @param dirs
 * @returns sorted array
 */
const customOrderBy = <T extends Record<string, unknown>>(
  arr: Array<Row<T>>,
  funcs: Array<(rowA: Row<T>, rowB: Row<T>) => number>,
  dirs: Array<boolean | string>
) => {
  return [...arr].sort((rowA, rowB) => {
    if (rowA.original.pinned && !rowB.original.pinned) return -1
    if (!rowA.original.pinned && rowB.original.pinned) return 1

    for (let i = 0; i < funcs.length; i += 1) {
      const sortFn = funcs[i]
      const sortInt = sortFn(rowA, rowB)
      const desc = dirs[i] === false || dirs[i] === 'desc'

      if (sortInt !== 0) {
        return desc ? -sortInt : sortInt
      }
    }

    return dirs[0] ? rowA.index - rowB.index : rowB.index - rowA.index
  })
}

export default customOrderBy
