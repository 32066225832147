import React from 'react'
import { InformationCard, LoadingIcon, RightPopup } from 'elements'
import { useMedicationDetailsQuery } from 'hooks/medications'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type ViewMedicationDetailsProps = {
  isOpen: boolean
  setIsOpen: (isFormOpen: boolean) => void
  medicationId?: string
}
const ViewMedicationDetails = ({
  isOpen,
  setIsOpen,
  medicationId,
}: ViewMedicationDetailsProps) => {
  const { t } = useTranslation()

  const medicationQuery = useMedicationDetailsQuery({
    medicationId,
  })

  return (
    <RightPopup
      title={t('Medication Details')}
      open={isOpen}
      setOpen={setIsOpen}
    >
      {medicationQuery.isLoading ? (
        <LoadingContainer>
          <LoadingIcon height="100%" />
        </LoadingContainer>
      ) : (
        <>
          <InformationCard size={'sm'}>
            {medicationQuery.data?.brandName ? (
              <InformationCard.Item
                label={t('Brand Name')}
                value={medicationQuery.data.brandName}
              />
            ) : null}
            {medicationQuery.data?.brandNameBase ? (
              <InformationCard.Item
                label={t('Brand Name Base')}
                value={medicationQuery.data.brandNameBase}
              />
            ) : null}
            {medicationQuery.data?.description ? (
              <InformationCard.Item
                label={t('Description')}
                value={medicationQuery.data.description}
              />
            ) : null}
            {medicationQuery.data?.genericName ? (
              <InformationCard.Item
                label={t('Generic Name')}
                value={medicationQuery.data.genericName}
              />
            ) : null}
            {medicationQuery.data?.labelerName ? (
              <InformationCard.Item
                label={t('Labeler Name')}
                value={medicationQuery.data.labelerName}
              />
            ) : null}
            {medicationQuery.data?.source ? (
              <InformationCard.Item
                label={t('Source')}
                value={medicationQuery.data.source}
              />
            ) : null}
            {medicationQuery.data?.dosageForm ? (
              <InformationCard.Item
                label={t('Dosage Form')}
                value={medicationQuery.data.dosageForm}
              />
            ) : null}
            {medicationQuery.data?.route ? (
              <InformationCard.Item
                label={t('Route')}
                value={medicationQuery.data.route}
              />
            ) : null}
          </InformationCard>

          {medicationQuery.data?.activeIngredients &&
          medicationQuery.data.activeIngredients.length != 0 ? (
            <Card>
              <Header>{t('Active Ingredients')}</Header>
              <Label>
                <span>{t('Name')}</span>
                <span>{t('Strength')}</span>
              </Label>
              {medicationQuery.data.activeIngredients.map((item, i) => (
                <Value key={i}>
                  <span>{item.name}</span>
                  <span>{item.strength}</span>
                </Value>
              ))}
            </Card>
          ) : null}
        </>
      )}
    </RightPopup>
  )
}
export default ViewMedicationDetails

const LoadingContainer = tw.div`flex flex-col h-full flex-grow`

const Header = tw.h2`text-xl font-semibold mb-4`

const Card = tw.div`bg-white p-5 rounded-lg border border-gray-200 min-h-full mt-4`

const Label = tw.div`text-gray-500 grid gap-10 grid-cols-2 mb-2`

const Value = tw.div`text-gray-900 grid gap-10 grid-cols-2`
