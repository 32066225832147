import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { orgsApi } from 'utils'

const useDeletePartnerTypeChildrenMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    DeletePartnerTypeChildrenResponse,
    DeletePartnerTypeChildrenProps
  >({
    mutationFn: deletePartnerTypeChildren,
    successMsg: t('Parent-of Association deleted'),
    optimisticUpdates: [
      {
        cacheKey: ({ parentPartnerTypeId }) => [
          'getPartnerTypeChildren',
          parentPartnerTypeId,
        ],
        updateFn: updateFnConstructor<
          GetPartnerTypeChildrenResponse,
          DeletePartnerTypeChildrenProps
        >((oldCache, { childPartnerTypeId }) =>
          oldCache
            ? oldCache.filter(
                (childPartnerType) => childPartnerType.id !== childPartnerTypeId
              )
            : oldCache
        ),
      },
    ],
  })

  return mutation
}

export default useDeletePartnerTypeChildrenMutation

type DeletePartnerTypeChildrenProps = {
  parentPartnerTypeId: string
  childPartnerTypeId: string
}

const deletePartnerTypeChildren = async ({
  parentPartnerTypeId,
  childPartnerTypeId,
}: DeletePartnerTypeChildrenProps) => {
  const result = await orgsApi
    .delete(
      `api/partner-types/${parentPartnerTypeId}/children/${childPartnerTypeId}`
    )
    .json<DeletePartnerTypeChildrenResponse>()

  return result
}
