import React from 'react'
import { Chip } from 'atlas'
import { colors } from 'atlas/Chip/Chip'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

type LineItemStatusChipProps = {
  status: OrderLineItemStatus
}

const LineItemStatusChip = ({ status }: LineItemStatusChipProps) => {
  const { t } = useTranslation()

  return (
    <Chip
      color={
        lineItemStatusToColor[
          status.title && isLineItemStatusTitle(status.title)
            ? status.title
            : 'default'
        ]
      }
    >
      {lineItemStatusToText(t, status.title)}
    </Chip>
  )
}

export default LineItemStatusChip

export enum LineItemStatusTitles {
  CONFIRMED = 'Confirmed',
  IN_PROCESS = 'In-Process',
  PROVISIONING_IN_PROCESS = 'Provisioning-In-Process',
  PROVISIONING_COMPLETE = 'Provisioning-Complete',
}

const lineItemStatusToColor: Record<
  LineItemStatusTitles | 'default',
  keyof typeof colors
> = {
  Confirmed: 'yellow',
  'In-Process': 'yellow',
  'Provisioning-In-Process': 'orange',
  'Provisioning-Complete': 'cyan',
  default: 'gray',
}
const lineItemStatusToText = (
  t: TFunction,
  status: OrderLineItemStatus['title']
) =>
  ({
    Confirmed: t('Provisioning Needed'),
    'In-Process': t('In-Process'),
    'Provisioning-In-Process': t('Provisioning In-Process'),
    'Provisioning-Complete': t('Provisioning Complete'),
    default: t('Provisioning Not Needed'),
  }[status && isLineItemStatusTitle(status) ? status : 'default'])

const isLineItemStatusTitle = (str: string): str is LineItemStatusTitles =>
  Object.keys(lineItemStatusToColor).includes(str)
