import { eachDayOfInterval, eachMonthOfInterval } from 'date-fns'
import _ from 'lodash'
import customLineChartDataToDatesArray from './customLineChartDataToDatesArray'

/**
 * Takes line chart data and returns an array of dates optimize for line charts
 * @param data - An array of line chart data where x is the date
 * @param preset - The date range preset currently being displayed
 * @returns An array of dates optimized for line charts
 */
type lineChartDataToDatesArray = (
  data: {
    y: number
    x: Date
  }[],
  preset: DateRangePreset,
  isGroupedByMonth?: boolean
) => Array<Date>

const lineChartDataToDatesArray: lineChartDataToDatesArray = (
  data,
  preset,
  isGroupedByMonth
) => {
  const minDate = _.minBy(data, (item) => new Date(item.x).getTime())?.x
  const maxDate = _.maxBy(data, (item) => new Date(item.x).getTime())?.x

  const intervalInDays = eachDayOfInterval({
    start: minDate || new Date(),
    end: maxDate || new Date(),
  })

  const intervalInMonths = eachMonthOfInterval({
    start: minDate || new Date(),
    end: maxDate || new Date(),
  })

  if (
    preset === 'this-week' ||
    preset === 'last-week' ||
    preset === 'this-month' ||
    preset === 'last-month'
  ) {
    if (intervalInDays.length < 20) return intervalInDays
    return intervalInDays.filter((_, index) => !(index % 2))
  }

  if (preset === 'custom') {
    if (isGroupedByMonth) return intervalInMonths
    return customLineChartDataToDatesArray(data.map((item) => item.x))
  }

  // every month
  return intervalInMonths
}

export default lineChartDataToDatesArray
