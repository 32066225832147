import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import {
  generateArchivable,
  generateAuditable,
  generateDisableable,
} from 'test-utils/api/utils'
import { typedOrgsApi } from 'utils'

const useAddApplicationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/applications'],
    AddApplicationProps
  >({
    mutationFn: addApplication,
    successMsg: t('Application created'),
    additionalCachesToInvalidate: ['getModules'],
    optimisticUpdates: [
      {
        cacheKey: 'getApplications',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/applications'],
          AddApplicationProps
        >((oldCache, applicationForm) => {
          return oldCache?.items
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items,
                  {
                    id: '123',
                    cognitoAppId: '123',
                    ...applicationForm,
                    modules: [],
                    ...generateArchivable(),
                    ...generateAuditable(),
                    ...generateDisableable(),
                  },
                ],
              }
            : undefined
        }),
      },
    ],
  })

  return mutation
}

export default useAddApplicationMutation

type AddApplicationProps = CreateApplicationForm

const addApplication = (applicationData: AddApplicationProps) =>
  typedOrgsApi.post('/api/applications', {
    body: applicationData,
  })
