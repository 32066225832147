import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useDeletePolicyGroupMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/api/policy-groups/{policyGroupId}'],
    KyError,
    string
  >(deletePolicyGroup, {
    onSuccess: (_, policyGroupId) => {
      const policyGroups:
        | OrgsAPIResponse['GET']['/api/policy-groups']
        | undefined = queryCache.getQueryData(['getPolicyGroups'])

      if (policyGroups) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/policy-groups'] = {
          ...policyGroups,
          items: policyGroups.items?.filter(
            (policyGroup) => policyGroup.id !== policyGroupId
          ),
        }
        // immediately update the policyGroup list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/policy-groups']>(
          ['getPolicyGroups'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the policyGroup list is in sync with the server
      queryCache.invalidateQueries('getPolicyGroups')

      // notify the user
      success({ message: t('Policy Group deleted') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useDeletePolicyGroupMutation

const deletePolicyGroup = async (policyGroupId: string) => {
  const result = typedOrgsApi.delete('/api/policy-groups/{policyGroupId}', {
    pathParams: { policyGroupId },
  })

  return result
}
