import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

const useSubscribersQuery = (filters?: SubscriberFilters) => {
  const query = useQuery<OrgsAPIResponse['GET']['/api/subscribers'], KyError>(
    ['getSubscriberList', filters],
    () => getSubscriberList(filters || { skip: 0 }),
    { keepPreviousData: true }
  )

  return query
}

export default useSubscribersQuery

const getSubscriberList = async ({
  skip = 0,
  take = 9999,
  searchTerm,
  organizationId,
  order_by,
  nonPERS,
}: SubscriberFilters) =>
  typedOrgsApi.get('/api/subscribers', {
    searchParams: [
      ['include', 'organization'],
      ['order_by', order_by || 'ASC'],
      ['skip', skip],
      ['take', take],
      ['organizationId', organizationId],
      ['searchTerm', searchTerm],
      ['nonPERS', nonPERS],
    ],
  })
