import React from 'react'
import { LoadingIcon, Statistic } from 'elements'
import { FadeInSlideDown } from 'animations'
import { useDelay } from 'hooks'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import useMedicationStatisticsQuery from 'hooks/statistics/useMedicationStatistics'
import { dateRangePresetToDateRange } from 'utils'

const MedicationStatistics = () => {
  const { t } = useTranslation()
  const delay = useDelay()
  const dateRangeState: DateRangeState = {
    preset: 'this-year',
    value: dateRangePresetToDateRange('this-year'),
  }
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  // get medication statistics
  const missedMedicationStatistics = useMedicationStatisticsQuery({
    organizationId: assumedOrganizationRole?.orgID,
    status: 'missed',
    dateRangeState: dateRangeState,
  })

  const confirmedMedicationStatistics = useMedicationStatisticsQuery({
    organizationId: assumedOrganizationRole?.orgID,
    status: 'ack',
    dateRangeState: dateRangeState,
  })

  if (
    missedMedicationStatistics.isLoading ||
    confirmedMedicationStatistics.isLoading
  )
    return (
      <LoadingContainer>
        <LoadingIcon height="100%" />
      </LoadingContainer>
    )

  return (
    <>
      {
        <MedInfoGrid>
          <FadeInSlideDown delay={delay()}>
            <Statistic
              icon="medications"
              iconColor="red"
              label={t('Missed Medications (This year)')}
              value={missedMedicationStatistics.data?.total || 0}
            />
          </FadeInSlideDown>

          <FadeInSlideDown delay={delay()}>
            <Statistic
              icon="medications"
              iconColor="green"
              label={t('Confirmed Medications (This year)')}
              value={confirmedMedicationStatistics.data?.total || 0}
            />
          </FadeInSlideDown>

          <FadeInSlideDown delay={delay()}>
            <Statistic
              icon="vitals"
              iconColor="yellow"
              label={t('Medications Activity')}
              value={'0'}
            />
          </FadeInSlideDown>

          <FadeInSlideDown delay={delay()}>
            <Statistic
              icon="calendar"
              iconColor="purple"
              label={t('Medication Schedules')}
              value={'0'}
            />
          </FadeInSlideDown>
        </MedInfoGrid>
      }
    </>
  )
}

export default MedicationStatistics

const MedInfoGrid = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`

const LoadingContainer = tw.div`flex flex-col h-full flex-grow`
