import React from 'react'
import { area } from 'd3-shape'
import { CustomLayerProps, Datum, ComputedDatum } from '@nivo/line'
import { Defs } from '@nivo/core'
import colors from 'tailwindcss/colors'

type NormalRangeLayerProps = CustomLayerProps

const NormalRangeLayer = ({ series, yScale }: NormalRangeLayerProps) => {
  const areaGenerator = area<{
    position: { x: number; y: number }
    data: Datum
  }>()
    .x0((d) => d.position.x)
    .y0((d) => yScale(d.data.normalHigh))
    .y1((d) => yScale(d.data.normalLow))

  const uniqueNormalRanges: Array<ComputedDatum[]> = []

  let currentAreaIndex = 0

  for (
    let currentItemIndex = 0;
    currentItemIndex < series[0].data.length;
    currentItemIndex++
  ) {
    // for the first item, just add it to the first uniqueNormalRange
    if (currentItemIndex === 0) {
      uniqueNormalRanges[0] = [series[0].data[currentItemIndex]]
    } else {
      // if the normal range of the current reading is the same as the last, just add it on
      if (
        series[0].data[currentItemIndex].data.normalLow ===
          series[0].data[currentItemIndex - 1].data.normalLow &&
        series[0].data[currentItemIndex].data.normalHigh ===
          series[0].data[currentItemIndex - 1].data.normalHigh &&
        // (and if it's a truthy value)
        series[0].data[currentItemIndex].data.normalLow
      ) {
        uniqueNormalRanges[currentAreaIndex] = [
          ...(uniqueNormalRanges[currentAreaIndex] || []),
          series[0].data[currentItemIndex],
        ]

        // else increment the currentAreaIndex
      } else {
        // only increment the currentAreaIndex if a value exists for the current point
        if (series[0].data[currentItemIndex].data.normalLow) {
          currentAreaIndex++

          uniqueNormalRanges[currentAreaIndex] = [
            ...(uniqueNormalRanges[currentAreaIndex] || []),
            series[0].data[currentItemIndex],
          ]
        }
      }
    }
  }

  const uniqueAreas = uniqueNormalRanges.map((range) => {
    const areaPath = areaGenerator(range)

    return areaPath
  })

  return (
    <>
      <Defs
        defs={[
          {
            id: 'pattern',
            type: 'patternLines',
            background: 'transparent',
            color: colors.emerald[200],
            lineWidth: 1,
            spacing: 6,
            rotation: -45,
          },
        ]}
      />
      {uniqueAreas.map((areaPath) => (
        <path
          key={areaPath}
          d={areaPath || undefined}
          fill="url(#pattern)"
          fillOpacity={1}
          stroke={colors.emerald[300]}
          strokeWidth={1}
        />
      ))}
    </>
  )
}

export default NormalRangeLayer
