import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  AutoComplete,
  Icon,
  LoadingIcon,
  RightPopup,
  TextField,
} from 'elements'
import { Button, DatePicker, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { useSubscribersQuery } from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  useCreateManualObservationMutation,
  useECGVitalSignsQuery,
} from 'hooks/vitals'
import { formatISO } from 'date-fns'
import { assignmentsToDisplayAssignments, isVitalSignLabel } from 'utils'
import { VitalSignChip } from '.'
import _ from 'lodash'
import { useParams } from 'react-router'
import { SubscriberAutoComplete } from 'components/subscribers'

type ManualObservationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
}

const ManualObservationForm = ({
  isFormOpen,
  setIsFormOpen,
}: ManualObservationFormProps) => {
  const { subscriberId } = useParams()
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const { errors, reset, control, watch, trigger } = useForm<
    Omit<ManualObservationForm, 'timestamp'> & {
      subscriberId: string
      timestamp: Date
    }
  >({
    defaultValues: {
      timestamp: new Date(),
      subscriberId: subscriberId || '',
    },
  })

  const [observations, setObservations] = useState<Array<ExistingObservation>>(
    []
  )
  const [observationsError, setObservationsError] = useState<string>()
  const [newObservation, setNewObservation] = useState<NewObservationForm>({
    vitalSign: null,
    reading: '',
    unit: '',
  })
  const [
    newObservationErrors,
    setNewObservationErrors,
  ] = useState<NewObservationErrorObj>()

  const subscribersQuery = useSubscribersQuery({
    organizationId: assumedOrganizationRole?.orgID,
    skip: 0,
  })
  const vitalSignsQuery = useECGVitalSignsQuery()

  const createManualObservationMutation = useCreateManualObservationMutation()

  // reset form state when popup opens
  useEffect(() => {
    if (isFormOpen) {
      reset({
        timestamp: new Date(),
        subscriberId: subscriberId || '',
      })
      setObservations([])
      setObservationsError(undefined)
      setNewObservation({ vitalSign: null, reading: '', unit: '' })
      setNewObservationErrors(undefined)
    }
  }, [isFormOpen])

  const submitForm = ({ closeForm }: { closeForm?: boolean } = {}) => {
    const formData = watch()
    createManualObservationMutation.mutate(
      {
        subscriberId: formData.subscriberId,
        observationForm: {
          timestamp: formatISO(formData.timestamp),
          observations: _.flatten(
            observations.map((observation) =>
              typeof observation.reading === 'string'
                ? {
                    vitalSignId: observation.vitalSign.id,
                    unit: observation.unit,
                    reading: observation.reading,
                  }
                : [
                    // if reading matches type for blood pressure create two readings one for each BP vital
                    {
                      vitalSignId:
                        vitalSignsQuery.data?.items?.find((vitalSign) =>
                          vitalSign.name.includes('Systolic')
                        )?.id || '',
                      unit: observation.unit,
                      reading: observation.reading.sys,
                    },
                    {
                      vitalSignId:
                        vitalSignsQuery.data?.items?.find((vitalSign) =>
                          vitalSign.name.includes('Diastolic')
                        )?.id || '',
                      unit: observation.unit,
                      reading: observation.reading.dia,
                    },
                  ]
            )
          ),
        },
      },
      {
        onSuccess: () => {
          if (closeForm)
            // close popup
            setIsFormOpen(false)
        },
      }
    )
  }

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Create Manual Observations')}
      width={'500px'}
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={async () => {
              const isFormValid = await trigger()
              if (!observations.length)
                setObservationsError(t('At least one observation must exist'))

              if (!isFormValid || !observations.length) return

              submitForm({ closeForm: true })
            }}
          >
            {t('Create & Close')}
          </Button>
          &nbsp;
          <Button
            type="primary-filled"
            onClick={async () => {
              const isFormValid = await trigger()
              if (!observations.length)
                setObservationsError(t('At least one observation must exist'))

              if (!isFormValid || !observations.length) return

              submitForm()

              setObservations([])
            }}
          >
            {t('Create & Add More')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {!subscribersQuery.data?.items || !vitalSignsQuery.data?.items ? (
        <LoadingIcon />
      ) : (
        <>
          <RowContainer>
            <Controller
              render={({ onChange, value }) => (
                <SubscriberAutoComplete
                  selectedSubscriber={value}
                  setSelectedSubscriber={(selectedSubscriber) => {
                    onChange(selectedSubscriber?.id || '')
                  }}
                  disabled={!!subscriberId}
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  error={errors?.subscriberId?.message}
                  className="flex-grow mb-3"
                  label={t('Subscriber *')}
                />
              )}
              control={control}
              name="subscriberId"
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Subscriber is required') }}
            />
          </RowContainer>
          <RowContainer>
            <Controller
              control={control}
              as={DatePicker}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="timestamp"
              label={t('Observation Timestamp *')}
              dense={false}
              buttonClassName="w-80"
              showTimeSelect
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Timestamp is required') }}
              error={errors.timestamp?.message}
            />
          </RowContainer>
          <ObservationsTitle>{t('Observations')}</ObservationsTitle>
          {/* Observations list */}
          {observations.length ? (
            observations.map((observation, index) => (
              <ObservationCard key={index}>
                <VitalSignNameContainer>
                  {isVitalSignLabel(observation.vitalSign.name) ? (
                    <VitalSignChip label={observation.vitalSign.name} />
                  ) : (
                    observation.vitalSign.name
                  )}
                </VitalSignNameContainer>
                <ReadingContainer>
                  <>
                    <ObservationReading>
                      {typeof observation.reading === 'string'
                        ? observation.reading
                        : observation.reading.sys +
                          '/' +
                          observation.reading.dia}
                      &nbsp;
                    </ObservationReading>
                    <ObservationUnit>
                      {
                        observation.vitalSign.units.find(
                          (unit) => unit.code === observation.unit
                        )?.displayName
                      }
                    </ObservationUnit>
                  </>
                </ReadingContainer>
                <TrashIcon
                  type="trash"
                  onClick={() =>
                    // remove this observation from array
                    setObservations(observations.filter((_, i) => index !== i))
                  }
                />
              </ObservationCard>
            ))
          ) : (
            <>
              <EmptyObservations title={t('No Observations Created Yet')} />
              {observationsError ? (
                <ObservationsError>{observationsError}</ObservationsError>
              ) : null}
            </>
          )}
          <CreateObservationTitle>
            {t('Create Observation')}
          </CreateObservationTitle>
          <RowContainer>
            <AutoComplete
              label={t('Vital Sign')}
              single
              className="flex-grow"
              options={assignmentsToDisplayAssignments([
                ...vitalSignsQuery.data.items,
              ])}
              onChange={(vitalSign) =>
                setNewObservation({
                  ...newObservation,
                  vitalSign,
                  unit: vitalSign?.defaultUnitCode || '',
                })
              }
              optionLabel={(vitalSign) => vitalSign.name}
              disableAutofill
              error={
                typeof newObservationErrors?.vitalSign === 'string'
                  ? newObservationErrors.vitalSign
                  : undefined
              }
            />
          </RowContainer>
          <RowContainer>
            {newObservation.vitalSign?.name.includes('Blood Pressure') ? (
              <>
                <TextField
                  className="w-64"
                  label={t('Systolic Reading')}
                  value={
                    typeof newObservation.reading !== 'string'
                      ? newObservation.reading.sys
                      : ''
                  }
                  onChange={(e) =>
                    setNewObservation({
                      ...newObservation,
                      reading: {
                        dia:
                          typeof newObservation.reading !== 'string'
                            ? newObservation.reading.dia
                            : '',
                        sys: e.target.value,
                      },
                    })
                  }
                  error={
                    typeof newObservationErrors?.reading !== 'string' &&
                    newObservationErrors?.reading?.sys
                      ? t('Systolic Reading is required')
                      : undefined
                  }
                />
                &nbsp;
                <TextField
                  className="w-64"
                  label={t('Diastolic Reading')}
                  value={
                    typeof newObservation.reading !== 'string'
                      ? newObservation.reading.dia
                      : ''
                  }
                  onChange={(e) =>
                    setNewObservation({
                      ...newObservation,
                      reading: {
                        sys:
                          typeof newObservation.reading !== 'string'
                            ? newObservation.reading.sys
                            : '',
                        dia: e.target.value,
                      },
                    })
                  }
                  error={
                    typeof newObservationErrors?.reading !== 'string' &&
                    newObservationErrors?.reading?.dia
                      ? t('Diastolic Reading is required')
                      : undefined
                  }
                />
              </>
            ) : (
              <TextField
                className="w-64"
                label={t('Reading')}
                value={newObservation.reading}
                onChange={(e) =>
                  setNewObservation({
                    ...newObservation,
                    reading: e.target.value,
                  })
                }
                error={
                  typeof newObservationErrors?.reading === 'string'
                    ? newObservationErrors.reading
                    : undefined
                }
                type="number"
              />
            )}
          </RowContainer>
          <RowContainer>
            <AutoComplete
              label={t('Unit')}
              single
              className="w-64"
              selectedOption={newObservation.vitalSign?.units.find(
                (unit) => unit.code === newObservation.unit
              )}
              options={newObservation.vitalSign?.units || []}
              onChange={(selectedOption) =>
                selectedOption &&
                setNewObservation({
                  ...newObservation,
                  unit: selectedOption.code,
                })
              }
              optionLabel={(unit) => unit.name}
              disableAutofill
              error={newObservationErrors?.unit}
            />
          </RowContainer>
          <CreateObservationButton
            onClick={() => {
              // if there is an error in the newObservation fields
              if (
                !newObservation.vitalSign ||
                (typeof newObservation.reading !== 'string'
                  ? !newObservation.reading.sys || !newObservation.reading.dia
                  : !newObservation.reading)
              ) {
                setNewObservationErrors({
                  vitalSign: !newObservation.vitalSign
                    ? t('Vital Sign is required')
                    : undefined,
                  reading:
                    typeof newObservation.reading !== 'string'
                      ? {
                          sys: !newObservation.reading.sys
                            ? t('Systolic Reading is required')
                            : undefined,
                          dia: !newObservation.reading.dia
                            ? t('Diastolic Reading is required')
                            : undefined,
                        }
                      : !newObservation.reading
                      ? t('Reading is required')
                      : undefined,
                })
                // if validation passes add newObservation to observations array and reset newObservation
              } else {
                setObservations([
                  ...observations,
                  // this is a safe assertion because the potentially nullable fields have been validated when this is executed
                  newObservation as ExistingObservation,
                ])
                setNewObservationErrors(undefined)
                setNewObservation({
                  ...newObservation,
                  reading: newObservation.vitalSign.name.includes(
                    'Blood Pressure'
                  )
                    ? { dia: '', sys: '' }
                    : '',
                })
              }
            }}
          >
            {t('Create Observation')}
          </CreateObservationButton>
        </>
      )}
    </RightPopup>
  )
}

export default ManualObservationForm

const RowContainer = tw.div`flex transition-all`

const ObservationsTitle = tw.h2`text-xl font-medium mb-2 mt-6`

const CreateObservationTitle = tw.h3`text-lg font-medium mb-2 mt-4`

const CreateObservationButton = tw(Button)`mt-4`

const ObservationCard = tw.div`flex border border-gray-200 rounded p-2 mb-2`

const EmptyObservations = tw(Empty)`border-2 border-dashed rounded-lg`

const VitalSignNameContainer = tw.div`flex-grow`

const ReadingContainer = tw.div`flex items-center`

const ObservationReading = tw.p`font-medium text-gray-500`

const ObservationUnit = tw.p`text-sm pt-0.5 text-gray-600`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600 ml-4`

const ObservationsError = tw.p`text-xs mt-1 -mb-1 ml-4 text-mui-error`

type ExistingObservation = {
  unit: string
  vitalSign: VitalSign
  reading: string | { sys: string; dia: string }
}

type NewObservationForm = {
  unit: string
  vitalSign: VitalSign | null
  reading: string | { sys: string; dia: string }
}

type NewObservationErrorObj =
  // the type of the error object should mimic the object structure of newObservation but with value types of only string | undefined
  {
    [key in keyof NewObservationForm]?: keyof Extract<
      NewObservationForm[key],
      Record<string, unknown>
    > extends string
      ? // if the form field could be a string or object
        | {
              [subKey in keyof Extract<
                NewObservationForm[key],
                Record<string, unknown>
              >]: string | undefined
            }
          | string
          | undefined
      : // form field could only be a string
        string | undefined
  }
