import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCreateLineItemMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/organizations/{organizationId}/contracts/{contractId}/line-items'],
    createLineItemArgs
  >({
    mutationFn: createLineItem,
    successMsg: { message: t('Line item created'), showOn: 'onSuccess' },
    // TODO: replace with Optimistic Update
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getOrganizationContracts', organizationId],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ contractId, organizationId }) => [
          'getLineItems',
          organizationId,
          contractId,
        ],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}/line-items'],
          createLineItemArgs
        >((oldCache, { lineItemForm }) =>
          oldCache
            ? {
                ...oldCache,
                items: [
                  ...(oldCache.items || []),
                  { id: '' + new Date().getTime(), ...lineItemForm },
                ],
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useCreateLineItemMutation

type createLineItemArgs = {
  lineItemForm: ContractLineItemForm
  organizationId: string
  contractId: string
}

const createLineItem = async ({
  lineItemForm,
  organizationId,
  contractId,
}: createLineItemArgs) =>
  ordersApi.post(
    '/organizations/{organizationId}/contracts/{contractId}/line-items',
    {
      pathParams: { organizationId, contractId },
      body: lineItemForm,
    }
  )
