import React, { useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { Button, Tooltip } from 'atlas'
import { ConfirmationDialogs, Icon } from 'elements'
import { useDeletePartnerTypeCommissionMutation } from 'hooks/partners'
import { useProductsQuery } from 'hooks/seed-data'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import {
  CreatePartnerTypeCommissionsForm,
  EditPartnerTypeCommissionForm,
} from './'

type PartnerTypeDirectCommissionsProps = {
  currentPartnerType: PartnerType
  partnerTypeCommissions: Commission[]
  delay: (diff?: number) => number
  isCreateCommissionsFormOpen: boolean
  setIsCreateCommissionsFormOpen: (isCreateCommissionsFormOpen: boolean) => void
}

const PartnerTypeDirectCommissions = ({
  currentPartnerType,
  partnerTypeCommissions,
  delay,
  isCreateCommissionsFormOpen,
  setIsCreateCommissionsFormOpen,
}: PartnerTypeDirectCommissionsProps) => {
  const { t } = useTranslation()

  const { data: productList } = useProductsQuery()
  const { mutate: deleteCommission } = useDeletePartnerTypeCommissionMutation()

  const [commissionToMutate, setCommissionToMutate] = useState<Commission>()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const [
    isEditCommissionFormOpen,
    setIsEditCommissionFormOpen,
  ] = useState<boolean>(false)

  const confirmations = [
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(
        `Delete Commissions For ${
          productList?.items?.find(
            (product) => product.id === commissionToMutate?.productId
          )?.name || ''
        }`
      ),
      content: <p>{t('Are you sure you want to delete this commission?')}</p>,
      actions: (
        <Button
          onClick={() => {
            commissionToMutate &&
              deleteCommission({
                partnerTypeId: currentPartnerType.id,
                commissionId: commissionToMutate.id,
              })

            setIsDeleteDialogOpen(false)
            setCommissionToMutate(undefined)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <>
      {partnerTypeCommissions.length ? (
        <>
          <FadeInSlideDown delay={delay()}>
            <CommissionsHeader>
              <CommissionsTitleGroup>
                <CommissionsTitle>{t('Direct Commissions')}</CommissionsTitle>
                <InfoTooltip>
                  <Tooltip
                    className="flex items-center "
                    content={
                      <p tw="w-60">{`${t(
                        "Commissions earned from a partner's direct sales"
                      )}.`}</p>
                    }
                  >
                    <InfoIcon type="info" />
                  </Tooltip>
                </InfoTooltip>
              </CommissionsTitleGroup>
              <Button
                type="primary-filled"
                onClick={() => setIsCreateCommissionsFormOpen(true)}
                disabled={
                  partnerTypeCommissions.length === productList?.items?.length
                }
                tw="h-fit-content justify-self-end"
              >
                {t('Create Commissions')}
              </Button>
            </CommissionsHeader>
          </FadeInSlideDown>
          <CommissionsGrid>
            {_.orderBy(
              partnerTypeCommissions,
              (commission) =>
                productList?.items?.find(
                  (product) => product.id === commission.productId
                )?.name
            ).map((commission, index) => (
              <FadeInSlideDown delay={delay()} key={index}>
                <CommissionCard key={index}>
                  <CommissionInfoContainer>
                    <CommissionCardTitle>
                      {productList?.items?.find(
                        (product) => product.id === commission.productId
                      )?.name || ''}
                    </CommissionCardTitle>
                    <CommissionAmountsContainer>
                      {commission.isOneTimeCommission ? (
                        <OneTimeCommission>
                          {`${t('One-Time')}:`}
                          &nbsp;
                          <CommissionAmountText>
                            {`$${commission.oneTimeCommissionAmount}`}
                          </CommissionAmountText>
                        </OneTimeCommission>
                      ) : null}
                      {commission.isRecurringCommission ? (
                        <RecurringCommission>
                          {`${t('Recurring')}:`}
                          &nbsp;
                          <CommissionAmountText>
                            {`$${commission.recurringCommissionAmount}/mo`}
                          </CommissionAmountText>
                        </RecurringCommission>
                      ) : null}
                    </CommissionAmountsContainer>
                  </CommissionInfoContainer>
                  <IconContainer>
                    <EditIcon
                      type="edit"
                      onClick={() => {
                        setIsEditCommissionFormOpen(true)
                        setCommissionToMutate(commission)
                      }}
                      data-testid={`${
                        productList?.items?.find(
                          (product) => product.id === commission.productId
                        )?.name
                      }-edit`}
                    />
                    &nbsp;&nbsp;
                    <TrashIcon
                      type="trash"
                      onClick={() => setCommissionToMutate(commission)}
                      data-testid={`${
                        productList?.items?.find(
                          (product) => product.id === commission.productId
                        )?.name
                      }-delete`}
                    />
                  </IconContainer>
                </CommissionCard>
              </FadeInSlideDown>
            ))}
          </CommissionsGrid>
        </>
      ) : null}
      <CreatePartnerTypeCommissionsForm
        currentPartnerType={currentPartnerType}
        isFormOpen={isCreateCommissionsFormOpen}
        setIsFormOpen={setIsCreateCommissionsFormOpen}
        existingCommissions={partnerTypeCommissions}
      />
      <EditPartnerTypeCommissionForm
        currentPartnerType={currentPartnerType}
        isFormOpen={isEditCommissionFormOpen}
        setIsFormOpen={setIsEditCommissionFormOpen}
        commissionToMutate={commissionToMutate}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </>
  )
}

export default PartnerTypeDirectCommissions

const CommissionsHeader = tw.div`flex justify-between mb-2 mt-5`

const CommissionsTitleGroup = tw.div`flex h-full items-center self-center`

const CommissionsTitle = tw.h3`text-xl font-semibold`

const CommissionsGrid = tw.div`grid grid-cols-1 xl:grid-cols-2 gap-2`

const CommissionCard = tw.div`bg-white p-4 rounded border border-gray-200 h-full flex justify-between items-center`

const CommissionInfoContainer = tw.div``

const CommissionCardTitle = tw.p`self-start font-bold`

const CommissionAmountsContainer = tw.div`flex`

const OneTimeCommission = tw.div`flex mr-2`

const RecurringCommission = tw.div`flex`

const CommissionAmountText = tw.p`text-center font-medium`

const IconContainer = tw.div`mt-1`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600`

const InfoIcon = tw(
  Icon
)`text-gray-600 hover:text-gray-900 ml-2 w-5 h-5 align-middle`

const InfoTooltip = tw.div`h-fit-content`
