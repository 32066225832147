import {
  AutoComplete,
  EntityLabel,
  Icon,
  RightPopup,
  TextField,
} from 'elements'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useOrganizationListQuery } from 'hooks/organizations'
import { Button, DatePicker, Tooltip } from 'atlas'
import { useUpdateContractMutation } from 'hooks/contracts'
import { addYears, formatISO, parseISO } from 'date-fns'
import { useSearchParam } from 'react-use'

type EditContractFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  contractToEdit: Contract | undefined
}

const EditContractForm = ({
  isFormOpen,
  setIsFormOpen,
  contractToEdit,
}: EditContractFormProps) => {
  const { t } = useTranslation()
  const organizationId = useSearchParam('organizationId')

  const [organization, setOrganization] = useState<OrganizationListItem | null>(
    null
  )
  const [organizationError, setOrganizationError] = useState<string>()

  const { errors, control, trigger, watch, reset } = useForm<
    Omit<ContractForm, 'effectiveDates'> & {
      effectiveDates: { fromDate?: Date; toDate?: Date }
    }
  >()

  const organizationListQuery = useOrganizationListQuery()

  const updateContractMutation = useUpdateContractMutation()

  // reset form fields when form opens or contractToEdit changes
  useEffect(() => {
    if (isFormOpen) {
      reset({
        descriptionInfo: contractToEdit?.descriptionInfo,
        effectiveDates: {
          fromDate: contractToEdit?.effectiveDates?.fromDate
            ? parseISO(contractToEdit.effectiveDates.fromDate)
            : undefined,
          toDate: contractToEdit?.effectiveDates?.toDate
            ? parseISO(contractToEdit.effectiveDates.toDate)
            : undefined,
        },
      })
      setOrganization(
        organizationListQuery.data?.items.find(
          (org) => org.id === organizationId
        ) || null
      )
      setOrganizationError(undefined)
    }
  }, [contractToEdit, isFormOpen, organizationId])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Edit Contract Details')}
      controls={
        <>
          <Button
            onClick={async () => {
              if (!organization)
                setOrganizationError(t('Organization is required'))
              else setOrganizationError(undefined)

              if (!(await trigger()) || !organization) return

              const formData = watch()

              updateContractMutation.mutate({
                contractForm: {
                  ...formData,
                  effectiveDates: {
                    fromDate: formatISO(
                      formData.effectiveDates.fromDate || new Date()
                    ),
                    ...(formData.effectiveDates.toDate
                      ? { toDate: formatISO(formData.effectiveDates.toDate) }
                      : {}),
                  },
                },
                organizationId: organization.id,
                contractId: contractToEdit?.id || '',
              })

              setIsFormOpen(false)
            }}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <RowContainer>
        <OrganizationAutoComplete
          label={t('Organization *')}
          options={organizationListQuery.data?.items || []}
          single
          onChange={(org) => setOrganization(org)}
          optionLabel={(option) => option.businessName || ''}
          selectedOption={organization}
          renderOption={(props, option) => (
            <AutoCompleteOption {...props}>
              <EntityLabel id={option.id} name={option.businessName} />
            </AutoCompleteOption>
          )}
          renderTags={(options) =>
            options.map((option) => (
              <EntityLabel
                id={option.id}
                name={option.businessName}
                key={option.id}
              />
            ))
          }
          error={organizationError}
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="descriptionInfo.title"
          label={t('Title')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Title is required') }}
          error={errors.descriptionInfo?.title?.message}
          className="flex-grow max-w-md mt-4"
          required
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="descriptionInfo.description"
          label={t('Description')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Description is required') }}
          error={errors.descriptionInfo?.description?.message}
          className="flex-grow max-w-xl mt-4"
          multiline
          rows={3}
          required
        />
        <InfoTooltip
          anchor="right"
          content={
            <TooltipText>{`${t(
              'Enter a meaningful description that will help customers understand the exact details of the contract'
            )}. ${t('Be as specific as possible')}`}</TooltipText>
          }
        >
          <InfoIcon type="info" />
        </InfoTooltip>
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={DatePicker}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="effectiveDates.fromDate"
          label={t('Effective From *')}
          dense={false}
          minDate={new Date()}
          // max effective date start should either be the effective date end or 10 years into future
          maxDate={
            (watch('effectiveDates.toDate') as Date | undefined) ||
            addYears(new Date(), 10)
          }
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Effective From date is required') }}
          error={errors.effectiveDates?.fromDate?.message}
          className="mt-4 mr-4"
          buttonClassName="w-56"
        />
        <Controller
          control={control}
          as={DatePicker}
          // this is purely here to prevent console.warns
          defaultValue=""
          label={t('Effective To (optional)')}
          dense={false}
          name="effectiveDates.toDate"
          // min effective date range end should either be the effective date start or the present
          minDate={
            (watch('effectiveDates.fromDate') as Date | undefined) || new Date()
          }
          maxDate={addYears(new Date(), 10)}
          className="mt-4"
          buttonClassName="w-56"
        />
      </RowContainer>
    </RightPopup>
  )
}

export default EditContractForm

const RowContainer = tw.div`flex`

const OrganizationAutoComplete = tw(AutoComplete)`mt-2 flex-grow max-w-md`

const AutoCompleteOption = tw.li`cursor-pointer hover:bg-gray-100 py-2 px-4`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`ml-2 mt-4 h-fit-content`

const TooltipText = tw.p`w-60`
