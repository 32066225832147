import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

const useProductsQuery = () => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/seed-data/ecg-products'],
    KyError
  >('getProducts', getProducts)

  return query
}

export default useProductsQuery

type getProducts = () => Promise<
  OrgsAPIResponse['GET']['/api/seed-data/ecg-products']
>
const getProducts: getProducts = async () =>
  typedOrgsApi.get(`/api/seed-data/ecg-products`)
