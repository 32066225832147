import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

const useStaffQuery = (orgId: string, staffId: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}'],
    KyError
  >(['getStaff', orgId, staffId], () => getStaff(orgId, staffId), {
    enabled: !!orgId && !!staffId,
  })

  return query
}

export default useStaffQuery

const getStaff = async (orgId: string, staffId: string) =>
  typedOrgsApi.get(
    '/api/organizations/{organizationId}/staff-members/{personId}',
    {
      pathParams: { organizationId: orgId, personId: staffId },
      searchParams: [['include', 'organization']],
    }
  )
