import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { typedOrgsApi } from 'utils'
import { useTranslation } from 'react-i18next'

const useCreatePhoneMutation = () => {
  const { t } = useTranslation()
  const mutation = useMutation<
    OrgsAPIResponse['POST']['/subscribers-profile/{subscriberId}/phone-numbers'],
    CreateSubscriberPhoneProps
  >({
    mutationFn: createSubscriberPhone,
    successMsg: t('Phone Number created'),
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => ['getSubscriberPhones', subscriberId],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/phone-numbers'],
          CreateSubscriberPhoneProps
        >((oldCache, { phoneForm }) => [
          ...(oldCache || []),
          { id: 'placeholder', phoneType: phoneForm.type, ...phoneForm },
        ]),
      },
    ],
  })

  return mutation
}

export default useCreatePhoneMutation

type CreateSubscriberPhoneProps = {
  phoneForm: SubscriberPhoneForm
  subscriberId: string
}

const createSubscriberPhone = ({
  phoneForm,
  subscriberId,
}: CreateSubscriberPhoneProps) => {
  return typedOrgsApi.post(
    '/subscribers-profile/{subscriberId}/phone-numbers',
    {
      pathParams: { subscriberId },
      body: phoneForm,
    }
  )
}
