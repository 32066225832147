import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { vitalsApi, handleQueryError, success } from 'utils'

// used to delete Reminder preset
const useDeleteReminderPresetMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['DELETE']['/organizations/{organizationId}/reminder-presets/{presetId}'],
    KyError,
    deleteReminderPresetArgs,
    | VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
    | undefined
  >(deleteReminderPreset, {
    onMutate: (data) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
      >(['getReminderPresets', data.orgId])

      // optimistic update
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
        | undefined
      >(['getReminderPresets', data.orgId], (oldData) => {
        if (!oldData?.items) return undefined
        return {
          ...oldData,
          items: oldData.items.filter((preset) => preset.id !== data.presetId),
        }
      })

      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Reminder preset deleted') })
    },
    onError: (error, { orgId }, snapshot) => {
      //revert to snapshot
      if (snapshot)
        queryCache.setQueryData<
          VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
        >(['getReminderPresets', orgId], snapshot)
      handleQueryError({ error })
    },
    onSettled: (_, error, req) => {
      // refetch from API to sync with server
      queryCache.invalidateQueries(['getReminderPresets', req.orgId])
    },
  })

  return mutation
}

export default useDeleteReminderPresetMutation

type deleteReminderPresetArgs = {
  orgId: string
  presetId: string
}

const deleteReminderPreset = async (data: deleteReminderPresetArgs) => {
  const result = await vitalsApi
    .delete(`organizations/${data.orgId}/reminder-presets/${data.presetId}`)
    .json<
      VitalsAPIResponse['DELETE']['/organizations/{organizationId}/reminder-presets/{presetId}']
    >()

  return result
}
