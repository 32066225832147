import React, { useState } from 'react'
import {
  InputAdornment,
  InputProps,
  TextField as MuiTextField,
} from '@mui/material'
import clsx from 'clsx'
import { Icon } from 'elements'

type TextFieldProps = {
  label?: React.ReactNode
  required?: boolean
  error?: string
  defaultValue?: unknown
  fullWidth?: boolean
  disabled?: boolean
  name?: string
  inputRef?: React.Ref<unknown>
  autoComplete?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  value?: unknown
  className?: string
  multiline?: boolean
  rows?: number
  type?: 'text' | 'number' | 'password'
  inputProps?: InputProps['inputProps']
  disableHelperText?: boolean
  noMargin?: boolean
  placeholder?: string
  shrink?: boolean
  helperText?: string
  ['data-testid']?: string
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
  InputProps?: InputProps
  autoFocus?: boolean
  max?: number
  size?: 'small' | 'medium'
}

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      label,
      required,
      error,
      defaultValue,
      fullWidth,
      name,
      inputRef,
      autoComplete,
      onChange,
      onBlur,
      onFocus,
      value,
      className,
      multiline,
      rows,
      disabled,
      type,
      inputProps,
      noMargin,
      disableHelperText = false,
      placeholder,
      shrink,
      helperText,
      ['data-testid']: dataTestId,
      endAdornment,
      startAdornment,
      InputProps,
      autoFocus,
      max,
      size,
    }: TextFieldProps,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
      <MuiTextField
        id={name}
        name={name}
        label={label}
        required={required}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        inputRef={inputRef}
        autoComplete={autoComplete}
        size={size}
        // If showPassword is true change type to text
        type={type === 'password' && showPassword ? 'text' : type}
        margin="none"
        InputLabelProps={{ shrink }}
        inputProps={{ ...inputProps, max, ['data-testid']: dataTestId }}
        // add empty space to prevent content shifting when error occurs
        helperText={error || helperText || ' '}
        FormHelperTextProps={
          disableHelperText ? { className: 'h-0 m-0' } : undefined
        }
        error={!!error}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        ref={ref}
        className={clsx(!noMargin && 'mb-2', className)}
        multiline={multiline}
        rows={rows}
        variant="outlined"
        disabled={disabled}
        placeholder={placeholder}
        InputProps={{
          endAdornment:
            type === 'password' ? (
              <InputAdornment position="end">
                <Icon
                  type={showPassword ? 'visible' : 'invisible'}
                  onClick={() => setShowPassword(!showPassword)}
                  className="m-1 text-black cursor-pointer"
                />
              </InputAdornment>
            ) : (
              endAdornment
            ),
          ...(startAdornment
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                ),
              }
            : {}),
          ...(InputProps ? InputProps : {}),
        }}
        autoFocus={autoFocus}
      />
    )
  }
)

export default TextField
