import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { isPresent, ordersApi } from 'utils'

const useUpdateOrderMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['PATCH']['/orders/{orderId}'],
    updateOrderArgs
  >({
    mutationFn: updateOrder,
    successMsg: { message: t('Order updated'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['order', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          updateOrderArgs
        >((oldCache, { orderForm }) => ({
          ...oldCache,
          ...orderForm,
        })),
      },
    ],
  })

  return mutation
}

export default useUpdateOrderMutation

type updateOrderArgs = {
  orderForm: Partial<OrderForm>
  organizationId?: string
  orderId: string
}

const updateOrder = async ({ orderForm, orderId }: updateOrderArgs) =>
  ordersApi.patch('/orders/{orderId}', {
    pathParams: { orderId },
    body: Object.entries(orderForm)
      .filter((field): field is [string, Record<string, unknown>] =>
        isPresent(field[1])
      )
      .map(([fieldKey, fieldValue]) => ({
        value: fieldValue,
        path: `/${fieldKey}`,
        op: 'replace',
      })),
  })
