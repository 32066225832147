import React from 'react'
import { Button } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Dialog } from 'elements'
import { useNavigate } from 'hooks'
import { useTranslation } from 'react-i18next'

type AssumeRoleDialogArgs = {
  organization?: Organization
  isOpen: boolean
  onCancel: () => void
  onSuccess?: () => void
  children: React.ReactNode
}

const AssumeRoleDialog = ({
  organization,
  isOpen,
  onCancel,
  onSuccess,
  children,
}: AssumeRoleDialogArgs) => {
  const { t } = useTranslation()

  const { assumeOrganizationRole } = useAssumedOrganizationRole()

  const navigate = useNavigate()

  return (
    <Dialog
      open={isOpen}
      title={`${t('Assume Role of')} ${
        organization?.businessName
          ? organization.businessName
          : 'Electronic Caregiver'
      }?`}
      content={children}
      actions={
        <>
          <Button
            type="primary-filled"
            onClick={() => {
              // assume the organization role
              assumeOrganizationRole({
                orgID:
                  // assume ECG Admin Role when org doesn't exist
                  organization?.id || '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
                onSuccess: (searchParams) => navigate({ searchParams }),
              })
              if (onSuccess) onSuccess()
            }}
          >
            {t('Assume Role')}
          </Button>
          <Button onClick={() => onCancel()} type="secondary">
            {t('Cancel')}
          </Button>
        </>
      }
    />
  )
}

export default AssumeRoleDialog
