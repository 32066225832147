import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

// used to get locales
const useLocalesQuery = () => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/locales/preferred-locales'],
    KyError
  >(['getLocales'], getLocales)

  return query
}

export default useLocalesQuery

// get locales
type getLocales = () => Promise<
  VitalsAPIResponse['GET']['/locales/preferred-locales']
>
const getLocales: getLocales = async () => {
  const result = await vitalsApi
    .get('locales/preferred-locales')
    .json<VitalsAPIResponse['GET']['/locales/preferred-locales']>()
  return result
}
