import React from 'react'
import { InformationCard } from 'elements'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type SubscriberInfoCardProps = {
  subscriberInfo: SubscriberDisplay
}

const SubscriberInfoCard = ({ subscriberInfo }: SubscriberInfoCardProps) => {
  const { t } = useTranslation()

  return (
    <Container>
      <InformationCard size="lg" className="flex-grow">
        <InformationCard.Item
          label={t('Name')}
          value={`${subscriberInfo.person.firstName} ${subscriberInfo.person.lastName}`}
        />

        <InformationCard.Item
          label={t('Status')}
          value={
            subscriberInfo.activeInfo.active ? (
              <Chip color="green">{t('Active')}</Chip>
            ) : (
              <Chip color="red">{t('Inactive')}</Chip>
            )
          }
        />

        {subscriberInfo.person.suffix ? (
          <InformationCard.Item
            label={t('Suffix')}
            value={subscriberInfo.person.suffix}
          />
        ) : null}

        {subscriberInfo.person.emailAddress ? (
          <InformationCard.Item
            label={t('Email')}
            value={subscriberInfo.person.emailAddress || '-'}
          />
        ) : null}

        {/* TODO: If we need to show phoneNumbers then we will need a separate API call to get the SubscriberProfile */}
        {/* {subscriberInfo.phoneNumbers.length > 0 ? (
          <InformationCard.Item
            label={t('Phone')}
            value={subscriberInfo.phoneNumbers[0].phoneNumber}
          />
        ) : null} */}

        <InformationCard.Item
          label={t('ID')}
          value={<IDText>{subscriberInfo.id}</IDText>}
        />
      </InformationCard>
    </Container>
  )
}

export default SubscriberInfoCard

const Container = tw.div`flex flex-col mb-4`

const IDText = tw.p`font-mono`
