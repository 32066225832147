import React from 'react'
import { RightPopup, Checkbox, LoadingIcon } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useAssignApplicationsToModuleMutation } from 'hooks/access-control/modules'
import { useApplicationsQuery } from 'hooks/access-control/applications'
import _ from 'lodash'
import tw, { styled } from 'twin.macro'

type AssignApplicationsFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  module: Module
}

const AssignApplicationsForm = ({
  isFormOpen,
  setIsFormOpen,
  module,
}: AssignApplicationsFormProps) => {
  const { t } = useTranslation()
  const formMethods = useForm<{ [key: string]: unknown }>()
  const assignApplicationsToModule = useAssignApplicationsToModuleMutation()
  const applications = useApplicationsQuery()
  const { handleSubmit, control } = formMethods

  const assignableApplications: Application[] = _.orderBy(
    _.differenceBy(
      applications.data?.items || [],
      module.applications || [],
      'id'
    ),
    [(application) => (application.activeInfo.active ? -1 : 1), 'name']
  )

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Assign Applications')}
      controls={
        <>
          <Button
            type="primary-filled"
            // TODO: also disable button if every option is unchecked, might need to keep track through useState
            disabled={assignApplicationsToModule.isLoading}
            onClick={handleSubmit((formData) => {
              assignApplicationsToModule.mutate(
                {
                  applicationIds: Object.keys(formData).filter(
                    (item) => formData[item]
                  ),
                  moduleId: module.id,
                },
                { onSuccess: () => setIsFormOpen(false) }
              )
            })}
          >
            {t('Assign')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            onClick={() => {
              // Close form drawer
              setIsFormOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <ApplicationForm>
          {(() => {
            if (applications.isLoading) return <LoadingIcon />

            if (!assignableApplications.length)
              return (
                <EmptyContainer>
                  <Empty
                    title={t('No Data Found')}
                    description={t('No applications have been created yet')}
                    callToAction={
                      <Button
                        type="primary-filled"
                        to="/access-control/applications"
                      >
                        {t('Create Application')}
                      </Button>
                    }
                    disableAnimations
                  />
                </EmptyContainer>
              )

            return assignableApplications.map((application) => (
              <Controller
                key={application.name}
                control={control}
                name={application.id}
                defaultValue={false}
                render={({
                  onChange,
                  value,
                  name,
                  ref,
                }: CheckboxRenderArgs) => {
                  return (
                    <Checkbox
                      onChange={(e) => {
                        onChange(e.target.checked)
                      }}
                      checked={!!value}
                      ref={ref}
                      label={
                        <CheckboxContainer>
                          <CheckBoxActive
                            active={application.activeInfo.active}
                          />

                          <ApplicationCheckBoxName>
                            {application.name}
                          </ApplicationCheckBoxName>
                        </CheckboxContainer>
                      }
                      name={name}
                    />
                  )
                }}
              />
            ))
          })()}
        </ApplicationForm>
      </FormProvider>
    </RightPopup>
  )
}

export default AssignApplicationsForm

const ApplicationForm = tw.form`flex flex-col gap-2`

const EmptyContainer = tw.div`flex flex-grow border-2 border-dashed rounded`

const CheckboxContainer = tw.span`flex items-center`

const CheckBoxActive = styled.div<{ active: boolean }>(({ active }) => [
  tw`rounded-full mr-2 inline-flex items-center justify-center flex-shrink-0 h-4 w-4 bg-red-200`,
  active && tw`bg-emerald-200`,
])

const ApplicationCheckBoxName = tw.p`text-gray-900 text-lg`
