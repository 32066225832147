import React, { useState, useEffect } from 'react'
import { LineChart, CustomSelect, Icon } from 'elements'
import { useAlertStatistics } from 'hooks/statistics'
import colors from 'tailwindcss/colors'
import tw from 'twin.macro'
import { useNavigate } from 'hooks'
import {
  dateRangePresetToAxisFormat,
  dateRangePresetToTooltipFormat,
  dateRangeStateToURL,
} from 'utils'
import { useTranslation } from 'react-i18next'
import { isMatch } from 'date-fns'

type AlertsLineChartProps = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const AlertsLineChart = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: AlertsLineChartProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: alertChartData, isLoading } = useAlertStatistics({
    dateRangeState,
    organizationId,
    subscriberId,
  })
  const [severityFilter, setSeverityFilter] = useState<
    'all' | 'severity-1' | 'severity-2' | 'severity-3'
  >('all')
  const [barChartSeries, setBarChartSeries] = useState<Series[]>([
    { key: 'Severity 1', color: colors.amber[200], active: true },
    { key: 'Severity 2', color: colors.orange[300], active: true },
    { key: 'Severity 3', color: colors.red[400], active: true },
  ])

  // check if the raw API data is grouped by month
  const isGroupedByMonth = isMatch(
    // @ts-expect-error bad swagger type
    '' + alertChartData?.[0]?.data?.[0]?.X,
    'yyyy-m'
  )

  useEffect(() => {
    if (severityFilter === 'all')
      return setBarChartSeries(
        barChartSeries.map((series) => {
          return { ...series, active: true }
        })
      )

    if (severityFilter === 'severity-1')
      return setBarChartSeries(
        barChartSeries.map((series) => {
          if (series.key === 'Severity 1') return { ...series, active: true }

          return { ...series, active: false }
        })
      )

    if (severityFilter === 'severity-2')
      return setBarChartSeries(
        barChartSeries.map((series) => {
          if (series.key === 'Severity 2') return { ...series, active: true }

          return { ...series, active: false }
        })
      )

    // default case: 'severity-3'
    return setBarChartSeries(
      barChartSeries.map((series) => {
        if (series.key === 'Severity 3') return { ...series, active: true }

        return { ...series, active: false }
      })
    )
  }, [severityFilter])

  return (
    <Card>
      <HeaderRow>
        <Header
          onClick={() =>
            navigate({
              pathname: subscriberId
                ? `/subscribers/${subscriberId}/alerts-activity`
                : '/vitals/alerts-activity',
              searchParams: {
                addressed: 'unaddressed',
                // filter alerts to vital sign readings to match what this chart shows
                alertTypes: 'vital-sign-reading',
                // if a severityFilter is specified send it in the url
                ...(severityFilter !== 'all'
                  ? { severities: severityFilter.slice(-1) }
                  : {}),
                ...dateRangeStateToURL(dateRangeState),
              },
            })
          }
        >
          <Title>{t('Vital Alerts by Severity Over Time')}</Title>
          <LinkIcon type="link" />
        </Header>
        <CustomSelectContainer>
          <CustomSelect<typeof severityFilter>
            className="w-full"
            options={[
              {
                label: 'All',
                value: 'all',
              },
              {
                label: 'Severity 1',
                value: 'severity-1',
              },
              {
                label: 'Severity 2',
                value: 'severity-2',
              },
              {
                label: 'Severity 3',
                value: 'severity-3',
              },
            ]}
            value={severityFilter}
            onChange={(e) => setSeverityFilter(e.value)}
            variant="thin"
          />
        </CustomSelectContainer>
      </HeaderRow>
      <LineChart
        // reverse the data and series to be in the correct order
        data={[
          // @ts-expect-error bad swagger type
          ...(alertChartData?.map((item) => item.data || []) || []),
        ].reverse()}
        series={[...barChartSeries].reverse()}
        YAxis={{
          label: 'Total Vital Alerts',
        }}
        XAxis={{
          format: (() => {
            if (dateRangeState.preset === 'custom' && isGroupedByMonth)
              return 'LLL'
            return dateRangePresetToAxisFormat[dateRangeState.preset]
          })(),
        }}
        tooltip={{
          unit: 'Alerts',
          x: {
            format: (() => {
              if (dateRangeState.preset === 'custom' && isGroupedByMonth)
                return 'LLL y'
              return dateRangePresetToTooltipFormat[dateRangeState.preset]
            })(),
          },
        }}
        dateRangePreset={dateRangeState.preset}
        isLoading={isLoading}
        curve="monotoneX"
      />
    </Card>
  )
}

export default AlertsLineChart

const Card = tw.div`bg-white p-3 h-72`

const HeaderRow = tw.div`flex items-center justify-between`

const Header = tw.div`flex items-center cursor-pointer`

const Title = tw.h3`text-lg lg:mb-0 mr-1`

const CustomSelectContainer = tw.div`w-52`

const LinkIcon = tw(Icon)`w-3.5 h-3.5`
