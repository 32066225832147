import { Button } from 'atlas'
import {
  AddressAutoComplete,
  AutoComplete,
  Checkbox,
  RightPopup,
  TextField,
} from 'elements'
import { useCountryCodesQuery, useStateCodesQuery } from 'hooks/seed-data'
import {
  useCreateSubscriberAddressMutation,
  useUpdateSubscriberAddressMutation,
} from 'hooks/user-subscriber'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { addressToMapBox } from 'utils'

type AddressFormProps = {
  subscriberId: string
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  takenAddressTypes: SubscriberAddressForm['types']
  addressToMutate?: SubscriberAddress
}

const SubscriberAddressForm = ({
  subscriberId,
  isFormOpen,
  setIsFormOpen,
  takenAddressTypes,
  addressToMutate,
}: AddressFormProps) => {
  const { t } = useTranslation()

  const {
    errors,
    register,
    reset,
    setValue,
    control,
    trigger,
    watch,
    clearErrors,
  } = useForm<SubscriberAddress>({
    defaultValues: {
      address: {
        addressLine2: '',
        city: '',
        postalCode: '',
      },
      crossStreet: '',
      lockBox: '',
    },
  })

  const [customFields, setCustomFields] = useState<CustomFields>({
    address: null,
    country: null,
    province: null,
  })
  const [customErrors, setCustomErrors] = useState<CustomErrors>()
  const [isPrimaryAddress, setIsPrimaryAddress] = useState<boolean>(false)
  const [isBillingAddress, setIsBillingAddress] = useState<boolean>(false)
  const [isShippingAddress, setIsShippingAddress] = useState<boolean>(false)
  const [newStateCode, setNewStateCode] = useState<string>()

  const countryCodes = useCountryCodesQuery()
  const stateCodes = useStateCodesQuery(customFields.country?.isoCode || '')

  const updateAddressMutation = useUpdateSubscriberAddressMutation()
  const createAddressMutation = useCreateSubscriberAddressMutation()

  // reset form state when popup opens
  useEffect(() => {
    if (isFormOpen) {
      reset(
        addressToMutate || {
          address: {
            addressLine2: '',
            city: '',
            postalCode: '',
          },
          crossStreet: '',
          lockBox: '',
        }
      )

      const country =
        countryCodes.data?.items?.find(
          (country) => country.isoCode === addressToMutate?.address.countryCode
        ) || null
      setCustomFields({
        address: addressToMutate
          ? addressToMapBox(addressToMutate.address)
          : null,
        country,
        province:
          stateCodes.data?.items?.find(
            (province) =>
              province.isoCode === addressToMutate?.address.stateProvinceCode
          ) || null,
      })
      setCustomErrors(undefined)
      setNewStateCode(undefined)
      setIsPrimaryAddress(!!addressToMutate?.types.includes('primary'))
      setIsBillingAddress(!!addressToMutate?.types.includes('billing'))
      setIsShippingAddress(!!addressToMutate?.types.includes('shipping'))
    }
  }, [isFormOpen, countryCodes.data, addressToMutate?.address])

  // set state code when query loads since the query is dependent on country being set
  useEffect(() => {
    setCustomFields((customFields) => ({
      ...customFields,
      province:
        stateCodes.data?.items?.find((province) =>
          newStateCode?.includes(province.isoCode)
        ) ||
        stateCodes.data?.items?.find(
          (province) =>
            province.isoCode === addressToMutate?.address.stateProvinceCode
        ) ||
        null,
    }))
  }, [stateCodes.data])

  // reset custom errors if a field is filled
  useEffect(() => {
    setCustomErrors((errors) =>
      Object.fromEntries(
        Object.keys(customFields).map((fieldKey) => [
          fieldKey,
          // if a custom field is falsey continue showing the error field, if not set to undefined
          !customFields[fieldKey as keyof typeof customFields]
            ? errors?.[fieldKey as keyof typeof errors]
            : undefined,
        ])
      )
    )
  }, [customFields])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={addressToMutate ? t('Edit Address') : t('Add Address')}
      width={'500px'}
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={async () => {
              const isCustomFieldsValid = validateCustomFields(
                customFields,
                setCustomErrors,
                t
              )
              if (
                !(await trigger(['address.city', 'address.postalCode'])) ||
                !isCustomFieldsValid
              )
                return

              const formData = watch()

              addressToMutate
                ? updateAddressMutation.mutate({
                    addressForm: {
                      ...formData,
                      address: {
                        ...formData.address,
                        // add the custom controlled fields
                        addressLine1:
                          customFields.address?.place_name.split(',')[0] || '',
                        countryCode: customFields.country?.isoCode || '',
                        stateProvinceCode: customFields.province?.isoCode || '',
                      },

                      types: ([
                        'primary',
                        'billing',
                        'shipping',
                      ] as const).filter(
                        (addressType) =>
                          (addressType === 'primary' && isPrimaryAddress) ||
                          (addressType === 'billing' && isBillingAddress) ||
                          (addressType === 'shipping' && isShippingAddress)
                      ),
                    },
                    addressId: addressToMutate.id,
                    subscriberId: subscriberId || '',
                  })
                : createAddressMutation.mutate({
                    addressForm: {
                      ...formData,
                      address: {
                        ...formData.address,
                        // add the custom controlled fields
                        addressLine1:
                          customFields.address?.place_name.split(',')[0] || '',
                        countryCode: customFields.country?.isoCode || '',
                        stateProvinceCode: customFields.province?.isoCode || '',
                      },

                      types: ([
                        'primary',
                        'billing',
                        'shipping',
                      ] as const).filter(
                        (addressType) =>
                          (addressType === 'primary' && isPrimaryAddress) ||
                          (addressType === 'billing' && isBillingAddress) ||
                          (addressType === 'shipping' && isShippingAddress)
                      ),
                    },
                    subscriberId: subscriberId || '',
                  })

              // close popup
              setIsFormOpen(false)
            }}
            // disables if no address type is specified
            disabled={
              !(isPrimaryAddress || isShippingAddress || isBillingAddress)
            }
          >
            {addressToMutate ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <form>
        <RowContainer>
          <AddressAutoComplete
            selectedOption={customFields.address}
            setSelectedOption={(address) =>
              setCustomFields((fields) => ({ ...fields, address }))
            }
            onChange={(selectedOption) => {
              setValue(
                'address.postalCode',
                selectedOption?.context.find(
                  (parent) => parent.id.split('.')[0] === 'postcode'
                )?.text || '',
                { shouldDirty: true }
              )
              setValue(
                'address.city',
                selectedOption?.context.find(
                  (parent) => parent.id.split('.')[0] === 'place'
                )?.text || '',
                { shouldDirty: true }
              )

              const country =
                countryCodes.data?.items?.find(
                  (country) =>
                    country.isoCode.toLowerCase() ===
                    selectedOption?.context.find(
                      (parent) => parent.id.split('.')[0] === 'country'
                    )?.short_code
                ) || null
              setCustomFields({
                address: selectedOption,
                country,
                province: null,
              })
              setNewStateCode(
                selectedOption?.context.find(
                  (parent) => parent.id.split('.')[0] === 'region'
                )?.short_code
              )
              setCustomErrors(undefined)
              clearErrors()
            }}
            label={t('Address *')}
            className="flex-grow"
            error={customErrors?.address}
          />
        </RowContainer>
        <RowContainer>
          <TextField
            name="address.addressLine2"
            className="flex-grow"
            inputRef={register()}
            label={t('Apt / Suite / Other')}
          />
        </RowContainer>
        <RowContainer>
          <Controller
            control={control}
            as={TextField}
            error={errors.address?.city?.message}
            name="address.city"
            className="flex-grow"
            label={t('City')}
            rules={{ required: { value: true, message: 'City is required' } }}
            required
          />
        </RowContainer>
        <RowContainer>
          <AutoComplete
            label={t('Country *')}
            single
            className="flex-grow"
            options={countryCodes.data?.items || []}
            onChange={(country) =>
              setCustomFields((fields) => ({
                ...fields,
                country,
                province: null,
              }))
            }
            selectedOption={customFields.country}
            optionLabel={(country) => country.name}
            error={customErrors?.country}
            disableAutofill
          />
        </RowContainer>
        <RowContainer>
          <AutoComplete
            label={t('State *')}
            single
            className="w-1/2 mr-4"
            options={stateCodes.data?.items || []}
            onChange={(province) =>
              setCustomFields((fields) => ({ ...fields, province }))
            }
            selectedOption={customFields.province}
            optionLabel={(province) => province.name}
            error={customErrors?.province}
            disableAutofill
          />
          <Controller
            control={control}
            as={TextField}
            label={t('Postal Code')}
            required
            error={errors.address?.postalCode?.message}
            name="address.postalCode"
            type="number"
            className="flex-grow"
            rules={{
              required: { value: true, message: 'Postal Code is required' },
            }}
          />
        </RowContainer>
        <AddressDetailsTitle>{t('Extra Information')}</AddressDetailsTitle>
        <RowContainer>
          <TextField
            name={`crossStreet`}
            className="flex-grow"
            inputRef={register()}
            label={t('Cross Street')}
          />
        </RowContainer>
        <RowContainer>
          <TextField
            name={`lockBox`}
            fullWidth
            multiline
            rows={2}
            inputRef={register()}
            label={t('Lock Box Instructions')}
          />
        </RowContainer>
        <AddressTypeTitle>{t('Address Type *')}</AddressTypeTitle>
        <Checkbox
          onChange={(e) => setIsPrimaryAddress(e.target.checked)}
          value={isPrimaryAddress}
          checked={isPrimaryAddress}
          label={t('Primary Address')}
          className="mt-4"
          disabled={
            takenAddressTypes.includes('primary') &&
            !addressToMutate?.types.includes('primary')
          }
        />
        <Checkbox
          onChange={(e) => setIsBillingAddress(e.target.checked)}
          value={isBillingAddress}
          checked={isBillingAddress}
          label={t('Billing Address')}
          className="mt-3"
          disabled={
            takenAddressTypes.includes('billing') &&
            !addressToMutate?.types.includes('billing')
          }
        />
        <Checkbox
          onChange={(e) => setIsShippingAddress(e.target.checked)}
          value={isShippingAddress}
          checked={isShippingAddress}
          label={t('Shipping Address')}
          className="mt-3"
          disabled={
            takenAddressTypes.includes('shipping') &&
            !addressToMutate?.types.includes('shipping')
          }
        />
      </form>
    </RightPopup>
  )
}

export default SubscriberAddressForm

const RowContainer = tw.div`flex transition-all`

const AddressTypeTitle = tw.h3`text-lg font-medium -mb-2`

const AddressDetailsTitle = tw.h3`text-lg font-medium mb-2`

type CustomFields = {
  address: MapBoxFeature | null
  country: Country | null
  province: Province | null
}

type CustomErrors = Partial<Record<keyof CustomFields, string>>

const validateCustomFields = (
  customFields: CustomFields,
  setCustomErrors: React.Dispatch<
    React.SetStateAction<CustomErrors | undefined>
  >,
  t: TFunction
) => {
  setCustomErrors({
    address: !customFields.address
      ? t('Address is required')
      : // prevents user from creating an address with only the street defined
      !customFields.address.address
      ? t('This is not an address')
      : undefined,
    country: !customFields.country ? t('Country is required') : undefined,
    province: !customFields.province ? t('State is required') : undefined,
  })
  return (
    Object.values(customFields).every((val) => !!val) &&
    !!customFields.address?.address
  )
}
