import React, { useEffect, useState } from 'react'
import { RightPopup, TextField, LoadingIcon } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useOrganizationTypesQuery } from 'hooks/seed-data'
import {
  useCreateOrganizationMutation,
  useOrganizationListQuery,
} from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { MetadataForm } from 'components/metadata'
import tw from 'twin.macro'
import { OrganizationAutoComplete } from '.'

type CreateOrganizationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
}

const CreateOrganizationForm = ({
  isFormOpen,
  setIsFormOpen,
}: CreateOrganizationFormProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const formMethods = useForm<{
    displayName: string
    businessName: string
  }>()
  const { handleSubmit, errors, reset } = formMethods

  const [metadata, setMetadata] = useState<
    Array<[string, string]> | undefined
  >()
  const [parentId, setParentId] = useState(assumedOrganizationRole?.orgID)
  const [parentIdError, setParentIdError] = useState<string>()

  const organizationsList = useOrganizationListQuery({
    orgId: assumedOrganizationRole?.orgID,
    organizationFilters: { searchTerm: 'Electronic Caregiver' },
  })
  const organizationTypes = useOrganizationTypesQuery({ enabled: isFormOpen })

  const createOrganization = useCreateOrganizationMutation()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset()
      setMetadata(undefined)
      setParentId(
        assumedOrganizationRole?.orgID ||
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          organizationsList.data?.items?.find(
            (org) => org.businessName === 'Electronic Caregiver'
          )?.id ||
          ''
      )
      setParentIdError(undefined)
    }
  }, [isFormOpen, organizationsList.data])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Create Organization')}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={createOrganization.isLoading}
            disabled={
              !parentId ||
              (metadata &&
                metadata.some(([key, value]) => key === '' || value === ''))
            }
            onClick={handleSubmit((formData) =>
              !parentId
                ? setParentIdError(t('Parent Organization is required'))
                : createOrganization.mutate(
                    {
                      organizationForm: {
                        ...formData,
                        parentId,
                        // set this organization as an HCO type
                        type: String(
                          organizationTypes.data?.items?.find(
                            (orgType) => orgType.code === 'HCO'
                          )?.id
                        ),
                        legacyIdentifier:
                          '56702356-a640-41de-960f-92b766c109ba',
                        originationSource: 'ADDI',
                        onBoardingStatus: 1,
                        organizationStatus: 1,
                      },
                      assumedOrganizationRole,
                      ...(metadata ? { metadata } : {}),
                    },
                    { onSuccess: () => setIsFormOpen(false) }
                  )
            )}
          >
            {t('Create')}
          </Button>
          &nbsp;
          {/* Cancel Button (shown for both) */}
          <Button
            type="secondary"
            disabled={createOrganization.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {organizationTypes.isLoading || organizationsList.isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <FormProvider {...formMethods}>
            <form>
              <OrganizationAutoComplete
                label={t('Parent Organization')}
                placeholder={t('Search Organization')}
                error={parentIdError}
                selectedOrganization={parentId || null}
                setSelectedOrganization={(newOrganization) =>
                  setParentId(newOrganization?.id)
                }
                disabled={!!assumedOrganizationRole}
              />

              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Business Name is required') }}
                name="businessName"
                label={t('Business Name')}
                fullWidth
                error={errors.businessName?.message}
                required
              />

              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Display Name is required') }}
                name="displayName"
                label={t('Display Name')}
                fullWidth
                error={errors.businessName?.message}
                required
              />
            </form>
          </FormProvider>
          <MetadataTitle>{t('Organization Metadata')}</MetadataTitle>
          <MetadataForm metadata={metadata} setMetadata={setMetadata} />
        </>
      )}
    </RightPopup>
  )
}

export default CreateOrganizationForm

const MetadataTitle = tw.h3`text-lg font-medium my-2`
