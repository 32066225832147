export const ecgAdminPermissions: string[] = [
  'vitals.settings.readonly',
  'vitals.settings.admin',
  'acm.readonly',
  'acm.admin',
]

export const ecgOrgPermissions: string[] = [
  'organization.readonly',
  'organization.admin',
  'staff.readonly',
  'staff.admin',
  'subscriber.readonly',
  'subscriber.admin',
  'vitals.settings.readonly',
  'vitals.settings.admin',
]

export const orgPermissions: string[] = [
  'organization.readonly',
  'organization.admin',
  'staff.readonly',
  'staff.admin',
  'subscriber.readonly',
  'subscriber.admin',
  'provider.readonly',
  'vitals.settings.readonly',
  'vitals.settings.admin',
]

/**
 * Presets
 */

type getECGAdminPermissionsPreset = (args: {
  organizationList: Array<Organization>
}) => AccountPermissions
export const getECGAdminPermissionsPreset: getECGAdminPermissionsPreset = ({
  organizationList,
}) => {
  const permissions: AccountPermissions = {
    firstName: 'Jim',
    lastName: 'doesnt matter',
    ecgAdmin: {
      type: 'super',
      permissions: ecgAdminPermissions,
    },
    noOrg: {
      permissions: [],
    },
    orgs: organizationList.map((org) => {
      return {
        orgID: org.id,
        orgDisplayName: org.displayName,
        staff: {
          permissions: ecgOrgPermissions,
          subscribersDeny: [],
        },
        subscriber: {
          permissions: [],
        },
      }
    }),
  }

  return permissions
}

type getOrgAdminPermissionsPreset = (args: {
  organizationList: Array<Organization>
}) => AccountPermissions
export const getOrgAdminPermissionsPreset: getOrgAdminPermissionsPreset = ({
  organizationList,
}) => {
  const permissions: AccountPermissions = {
    firstName: 'Jim',
    lastName: 'doesnt matter',
    ecgAdmin: {
      type: 'super',
      permissions: [],
    },
    noOrg: {
      permissions: [],
    },
    orgs: organizationList.map((org) => {
      return {
        orgDisplayName: org.displayName,
        orgID: org.id,
        staff: {
          permissions: orgPermissions,
          subscribersDeny: [],
        },
        subscriber: {
          permissions: [],
        },
      }
    }),
  }

  return permissions
}

type getNoPermissionsPermissionsPreset = () => AccountPermissions
export const getNoPermissionsPermissionsPreset: getNoPermissionsPermissionsPreset = () => {
  const permissions: AccountPermissions = {
    firstName: 'Jim',
    lastName: 'doesnt matter',
    ecgAdmin: {
      type: 'super',
      permissions: [],
    },
    noOrg: {
      permissions: [],
    },
    orgs: [],
  }

  return permissions
}
