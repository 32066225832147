import React, { useRef, useState } from 'react'
import _ from 'lodash'
import { useUpdateEffect } from 'react-use'
import { isPresent } from 'utils'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Icon from '../Icon'
import tw, { styled } from 'twin.macro'

type TableFilterProps<T extends string | string[] = string> = {
  icon: IconType
  label: string
  categories: TableCategory<T>[]
  selectedCategories: TableCategory<T>[]
  setSelectedCategories: (selectedCategories: TableCategory<T>[]) => void
  renderOption?: (props: { label: string; parent?: string }) => React.ReactNode
}

const TableFilter = <T extends string | string[] = string>({
  icon,
  label,
  categories,
  selectedCategories,
  setSelectedCategories,
  renderOption,
}: TableFilterProps<T>) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const [localCategories, setLocalCategories] = useState<TableCategory<T>[]>(
    categories
  )

  const containerRef = useRef(null)

  useUpdateEffect(() => {
    if (isDropdownVisible) setLocalCategories(categories)
  }, [isDropdownVisible])

  return (
    <Container ref={containerRef}>
      <FilterButton
        isDropdownVisible={isDropdownVisible}
        hasCategories={!!categories.length}
        hasSelectedCategories={!!selectedCategories.length}
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        disabled={!categories.length}
      >
        <Icon type={icon} /> &nbsp;
        <div>{label}</div>
      </FilterButton>

      <FilterDropdown
        visible={isDropdownVisible}
        setVisible={setIsDropdownVisible}
        parentRef={containerRef}
        parentAnchor={{ vertical: 'bottom', horizontal: 'right' }}
        contentAnchor={{ vertical: 'top', horizontal: 'right' }}
        search
        onSearch={(e) =>
          setLocalCategories(() => {
            // filter by text input
            const filterByText = categories.filter((category) =>
              category.label.toLowerCase().includes(e.toLowerCase())
            )
            //find the parents of the filtered by text arrays
            const parentsOfFilter = categories.map((top) => {
              return filterByText.map((bottom) => {
                if (bottom.parent === top.value) return top
              })
            })

            // flatten the array of arrays, and filter out undefined as well as insure there are no duplicates
            const parents = _.uniq(
              _.flattenDeep(parentsOfFilter).filter(isPresent)
            )
            return _.orderBy(_.uniq([...parents, ...filterByText]), 'order')
          })
        }
      >
        {localCategories.map((category) => (
          <FilterDropdownItem
            key={JSON.stringify(category.value)}
            isChild={!!category.parent}
            onClick={() =>
              // If category is already selected remove from selectedCategories, else add it
              setSelectedCategories(
                selectedCategories.some((selectedCategory) =>
                  _.isEqual(selectedCategory.value, category.value)
                )
                  ? selectedCategories.filter(
                      (item) => !_.isEqual(category.value, item.value)
                    )
                  : [...selectedCategories, category]
              )
            }
          >
            {renderOption ? renderOption(category) : <p>{category.label}</p>}
            <CheckIcon
              type="check"
              isChecked={selectedCategories.some((selectedCategory) =>
                _.isEqual(selectedCategory.value, category.value)
              )}
            />
          </FilterDropdownItem>
        ))}
      </FilterDropdown>
    </Container>
  )
}

export default TableFilter

const Container = tw.div`relative`

const FilterButton = styled.button<{
  isDropdownVisible: boolean
  hasCategories: boolean
  hasSelectedCategories: boolean
}>(({ isDropdownVisible, hasCategories, hasSelectedCategories }) => [
  tw`h-full flex items-center px-4 rounded border cursor-pointer transition-all select-none disabled:opacity-50 outline-none`,
  // if disabled
  !hasCategories && tw`bg-gray-100 hover:bg-gray-100 cursor-not-allowed`,
  isDropdownVisible
    ? hasSelectedCategories
      ? // if dropdown is visible and a category is selected
        tw`bg-blue-500 border-blue-300  text-white`
      : // if dropdown is visible and no category is selected
        tw`bg-gray-100 hover:bg-gray-100 border-gray-300`
    : hasSelectedCategories
    ? // if dropdown is not visible category is selected
      tw`bg-blue-400 hover:bg-blue-500 border-blue-100 text-white`
    : // if dropdown is not visible and no category is selected
      tw`bg-white hover:bg-gray-100 border-gray-200 hover:border-gray-300`,
])

const FilterDropdown = tw(Dropdown)`mt-2 w-120`

const FilterDropdownItem = styled(DropdownItem)<{ isChild: boolean }>(
  ({ isChild }) => [
    tw`flex items-center justify-between gap-2 select-none whitespace-nowrap`,
    isChild && tw`ml-4`,
  ]
)

const CheckIcon = styled(Icon)<{ isChecked: boolean }>(({ isChecked }) => [
  isChecked ? tw`text-gray-900` : tw`opacity-0`,
])
