/**
 * given an object containing price information, converts the object to a readable string
 * @param price
 * @returns formated price string
 */
const formatPrice = (price: PriceModel): string =>
  `${price.flatOrPercent !== 'Percent' ? '$' : ''}${price.amount}${
    price.flatOrPercent === 'Percent' ? '%' : ''
  }${
    { Monthly: ' /mo', Yearly: ' /yo', OneTime: '' }[
      price.interval || 'OneTime'
    ]
  }`

export default formatPrice
