export type SessionAuth = {
  id_token: string
  access_token: string
  refresh_token: string
  token_type: string // TODO: strict type to Bearer?
  scope: string
  profile: {
    sub: string
    event_id: string
    token_use: string
    auth_time: number
    name: string
    'cognito:username': string
    email: string
    username: string
  }
  expires_at: number
}

/**
 * get access token used for backend authenitcation of HTTP calls
 * @returns any
 */
const getAccessToken = () => {
  const sessionObj: SessionAuth = JSON.parse(
    window.sessionStorage.getItem(
      `oidc.user:https://cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}:${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}`
    ) || '{}'
  )
  return sessionObj.access_token
}
export default getAccessToken
