import React, { useEffect, useState } from 'react'
import {
  AddressAutoComplete,
  AutoComplete,
  RightPopup,
  TextField,
} from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import tw from 'twin.macro'
import {
  useCreateResponsiblePartyMutation,
  useUpdateResponsiblePartyMutation,
} from 'hooks/responsible-parties'
import { addressToMapBox, emailValidation, phoneValidation } from 'utils'
import { useCountryCodesQuery, useStateCodesQuery } from 'hooks/seed-data'

type ResponsiblePartyFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  subscriberId: string
  responsiblePartyToMutate?: ResponsibleParty | undefined
}

const ResponsiblePartyForm = ({
  isFormOpen,
  setIsFormOpen,
  subscriberId,
  responsiblePartyToMutate,
}: ResponsiblePartyFormProps) => {
  const { t } = useTranslation()

  const [newStateCode, setNewStateCode] = useState<string>()

  const formMethods = useForm<
    Omit<CreateResponsiblePartiesForm, 'addresses'> & {
      addresses:
        | Array<
            Omit<
              CreateResponsiblePartiesForm['addresses'][number],
              'addressLine1' | 'countryCode' | 'stateProvinceCode'
            > & {
              addressLine1: MapBoxFeature | null
              countryCode: { name: string; isoCode: string } | null
              stateProvinceCode: { name: string; isoCode: string } | null
            }
          >
        | undefined
    }
  >({
    defaultValues: {
      addresses: [
        {
          addressLine2: '',
          city: '',
          postalCode: '',
        },
      ],
    },
  })
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    watch,
    clearErrors,
    reset,
  } = formMethods

  const countryCodes = useCountryCodesQuery()
  const stateCodes = useStateCodesQuery(
    watch('addresses')?.[0]?.countryCode?.isoCode || ''
  )

  const createResponsibleParty = useCreateResponsiblePartyMutation()
  const editResponsibleParty = useUpdateResponsiblePartyMutation()

  // reset when form opens; update values if responsiblePartyToMutate changes
  useEffect(() => {
    if (isFormOpen) {
      if (responsiblePartyToMutate) {
        const country =
          countryCodes.data?.items?.find(
            (country) =>
              country.isoCode ===
              responsiblePartyToMutate.addresses[0].countryCode
          ) || null

        setNewStateCode(responsiblePartyToMutate.addresses[0].stateProvinceCode)

        reset({
          ...responsiblePartyToMutate,
          person: {
            ...responsiblePartyToMutate.person,
            email: responsiblePartyToMutate.person.emailAddress || '',
          },
          addresses: [
            {
              ...(responsiblePartyToMutate.addresses[0] || {}),
              addressLine1: addressToMapBox(
                responsiblePartyToMutate.addresses[0]
              ),
              countryCode: country,
              stateProvinceCode:
                stateCodes.data?.items?.find(
                  (province) =>
                    province.isoCode ===
                    responsiblePartyToMutate.addresses[0].stateProvinceCode
                ) || null,
            },
          ],
        })
      } else {
        reset({
          addresses: [
            {
              addressLine2: '',
              city: '',
              postalCode: '',
            },
          ],
        })
        setNewStateCode(undefined)
      }
    }
  }, [isFormOpen, countryCodes.data, responsiblePartyToMutate])

  // set state code when query loads since the query is dependent on country being set
  useEffect(() => {
    setValue(
      'addresses.0.stateProvinceCode',
      stateCodes.data?.items?.find((province) =>
        newStateCode?.includes(province.isoCode)
      ) || null
    )
  }, [stateCodes.data])

  return (
    <RightPopup
      title={t(
        `${responsiblePartyToMutate ? 'Update' : 'Create'} Responsible Party`
      )}
      open={isFormOpen}
      setOpen={setIsFormOpen}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={createResponsibleParty.isLoading}
            onClick={handleSubmit(async (formData) =>
              responsiblePartyToMutate
                ? editResponsibleParty.mutate(
                    {
                      subscriberId: responsiblePartyToMutate.subscriber.id,
                      responsiblePartyId: responsiblePartyToMutate.id,
                      updateResponsiblePartyForm: {
                        ...responsiblePartyToMutate,
                        ...responsiblePartyToMutate.person,
                        ...formData.person,
                        contactEmailAddress: formData.person.email || '',
                        relationship: formData.relationship,
                      },
                      addresses: [
                        formData.addresses?.[0]
                          ? {
                              ...responsiblePartyToMutate.addresses[0],
                              ...formData.addresses[0],
                              addressLine1:
                                formData.addresses[0].addressLine1?.place_name.split(
                                  ','
                                )[0] || '',
                              countryCode:
                                formData.addresses[0].countryCode?.isoCode ||
                                '',
                              stateProvinceCode:
                                formData.addresses[0].stateProvinceCode
                                  ?.isoCode || '',
                            }
                          : responsiblePartyToMutate.addresses[0],
                      ],
                      ...(formData.phoneNumbers[0]?.number
                        ? {
                            phoneNumbers: [
                              {
                                ...responsiblePartyToMutate.phoneNumbers[0],
                                ...formData.phoneNumbers[0],
                              },
                            ],
                          }
                        : {}),
                    },
                    { onSuccess: () => setIsFormOpen(false) }
                  )
                : createResponsibleParty.mutate(
                    {
                      subscriberId,
                      responsibleParty: {
                        ...formData,
                        addresses: formData.addresses?.[0]
                          ? [
                              {
                                ...formData.addresses[0],
                                addressType: 1,
                                addressLine1:
                                  formData.addresses[0].addressLine1?.place_name.split(
                                    ','
                                  )[0] || '',
                                countryCode:
                                  formData.addresses[0].countryCode?.isoCode,
                                stateProvinceCode:
                                  formData.addresses[0].stateProvinceCode
                                    ?.isoCode,
                              },
                            ]
                          : [],
                        phoneNumbers: [
                          { ...formData.phoneNumbers[0], type: 'Main' },
                        ],
                      },
                    },
                    { onSuccess: () => setIsFormOpen(false) }
                  )
            )}
          >
            {t(responsiblePartyToMutate ? 'Update' : 'Create')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={createResponsibleParty.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Title> {t('Personal Information')} </Title>
          <RowContainer>
            <TextField
              name="person.firstName"
              // incorrect type, errors may not exist
              error={errors.person?.firstName?.message}
              inputRef={register({ required: 'First Name is required' })}
              label={t('First Name')}
              fullWidth
              required
            />
            <LastNameTextField
              name="person.lastName"
              error={errors.person?.lastName?.message}
              inputRef={register({ required: 'Last Name is required' })}
              label={t('Last Name')}
              fullWidth
              required
            />
            <TextField
              name="person.suffix"
              inputRef={register}
              error={errors.person?.suffix?.message}
              label={t('Suffix')}
            />
          </RowContainer>
          <TextField
            name="relationship"
            required
            inputRef={register({ required: 'Relationship is required' })}
            error={errors.relationship?.message}
            label={t('Relationship')}
            fullWidth
          />
          <Title>{t('Contact Information')}</Title>
          <Controller
            render={({ onChange, ref }) => (
              <TextField
                name="person.email"
                inputRef={register}
                error={errors.person?.email?.message}
                label={t('Email Address')}
                fullWidth
                ref={ref}
                onChange={(e) => onChange(e)}
              />
            )}
            name="person.email"
            rules={{
              pattern: {
                value: emailValidation,
                message: t('Invalid email format'),
              },
            }}
          />
          <Controller
            render={({ onChange, ref }) => (
              <TextField
                name="phoneNumbers.0.number"
                inputRef={register}
                error={errors.phoneNumbers?.[0]?.number?.message}
                label={t('Phone Number')}
                ref={ref}
                onChange={(e) => onChange(e)}
              />
            )}
            name="phoneNumbers.0.number"
            rules={{
              pattern: {
                value: phoneValidation,
                message: t('Phone Number is invalid'),
              },
            }}
          />
          <ExtensionTextField
            name="phoneNumbers.0.extension"
            inputRef={register}
            type="number"
            label={t('Ext.')}
          />
          <SubTitle>{t('Address')}</SubTitle>
          <Controller
            name="addresses.0.addressLine1"
            render={({ value, onChange }) => (
              <AddressAutoComplete
                selectedOption={value}
                setSelectedOption={onChange}
                onChange={(selectedOption) => {
                  const country =
                    countryCodes.data?.items?.find(
                      (country) =>
                        country.isoCode.toLowerCase() ===
                        selectedOption?.context.find(
                          (parent) => parent.id.split('.')[0] === 'country'
                        )?.short_code
                    ) || null

                  const stateIsoCode = selectedOption?.context.find(
                    (parent) => parent.id.split('.')[0] === 'region'
                  )?.short_code

                  setValue('addresses.0.addressLine1', selectedOption, {
                    shouldDirty: true,
                  })
                  setValue('addresses.0.countryCode', country, {
                    shouldDirty: true,
                  })
                  setValue(
                    'addresses.0.stateProvinceCode',
                    stateCodes.data?.items?.find((province) =>
                      stateIsoCode?.includes(province.isoCode)
                    ) || null,
                    {
                      shouldDirty: true,
                    }
                  )
                  setValue(
                    'addresses.0.postalCode',
                    selectedOption?.context.find(
                      (parent) => parent.id.split('.')[0] === 'postcode'
                    )?.text || '',
                    {
                      shouldDirty: true,
                    }
                  )
                  setValue(
                    'addresses.0.city',
                    selectedOption?.context.find(
                      (parent) => parent.id.split('.')[0] === 'place'
                    )?.text || '',
                    {
                      shouldDirty: true,
                    }
                  )

                  setNewStateCode(stateIsoCode)
                  clearErrors()
                }}
                label={t('Address')}
                className="flex-grow"
                error={errors.addresses?.[0]?.addressLine1?.message}
              />
            )}
          />
          <TextField
            name="addresses.0.addressLine2"
            inputRef={register}
            error={errors.addresses?.[0]?.addressLine2?.message}
            label={t('Apt / Suite / Other')}
            fullWidth
          />
          <Controller
            as={TextField}
            name="addresses.0.city"
            label={t('City')}
            fullWidth
          />
          <Controller
            name="addresses.0.countryCode"
            render={({ value, onChange }) => (
              <AutoComplete
                label={t('Country')}
                single
                className="flex-grow"
                options={countryCodes.data?.items || []}
                onChange={onChange}
                selectedOption={value || null}
                optionLabel={(country) => country.name}
                error={errors.addresses?.[0]?.countryCode?.message}
                disableAutofill
              />
            )}
          />
          <RowContainer>
            <Controller
              name="addresses.0.stateProvinceCode"
              render={({ value, onChange }) => (
                <StateAutoComplete
                  label={t('State')}
                  single
                  options={stateCodes.data?.items || []}
                  onChange={onChange}
                  selectedOption={value}
                  optionLabel={(province) => province.name}
                  disableAutofill
                />
              )}
            />
            <Controller
              as={TextField}
              label={t('Postal Code')}
              name="addresses.0.postalCode"
              type="number"
              fullWidth
            />
          </RowContainer>
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default ResponsiblePartyForm

const Title = tw.h3`text-xl font-semibold mb-2`

const SubTitle = tw.h4`text-lg font-medium mb-2`

const RowContainer = tw.div`flex`

const ExtensionTextField = tw(TextField)`w-20 ml-4!`

const LastNameTextField = tw(TextField)`w-20 mx-4!`

const StateAutoComplete = tw(AutoComplete)`w-full mr-4`
