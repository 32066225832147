import React from 'react'
import ReactDOM from 'react-dom'
import materialTheme from 'styles/material-theme'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider as MaterialThemeProvider } from '@mui/material'
import worker from './test-utils/api/worker'
import {
  isFirefox,
  isChrome,
  isEdgeChromium,
  isSafari,
  browserName,
} from 'react-device-detect'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { App } from './components/display'
import db from './test-utils/data/db'
import enUS from 'lang/en-US'
import de from 'lang/de'
import numeral from 'numeral'
import { AuthProvider } from 'oidc-react'
import { GlobalStyles } from 'twin.macro'
import { AssumedOrganizationRoleProvider } from 'context/assumed-organization-role'
import 'numeral/locales/de'
import 'styles/global-styles.css'
import 'styles/tailwind.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { handleQueryError } from 'utils'

// grab the user's preferred language from localStorage
const defaultLanguage =
  localStorage.getItem('user-profile__language') || 'en-US'

// initialize react-i18next
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      'en-US': enUS,
      de: de,
    },
    // get the default value from localStorage
    lng: defaultLanguage,
    fallbackLng: 'en-US',
  })

// function to update the numeraljs language
const updateNumeraljsLanguage = (lng: string) => {
  if (lng === 'en-US') return numeral.locale('en')
  if (lng === 'de') return numeral.locale('de')

  return numeral.locale()
}

// set the default Numeraljs language
updateNumeraljsLanguage(
  localStorage.getItem('user-profile__language') || 'en-US'
)

// update numeraljs formatting when the language changes
i18n.on('languageChanged', updateNumeraljsLanguage)

// start MSW worker if inside Cypress
if (window.Cypress) worker.start()

// Initialize mock database for dev
db.init()

// react-query config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,

      // 2 minute stale time
      staleTime: 120000,
      // @ts-expect-error No way to pass generic for defaultOptions to override TError from unknown to KyError | Error
      onError: (error: KyError | Error) => handleQueryError({ error }),
    },
  },
})

// block app for unsupported browsers
if (!isFirefox && !isChrome && !isEdgeChromium && !isSafari) {
  ReactDOM.render(
    <div style={{ marginLeft: '18px' }}>
      <h1>Unsupported browser: {browserName}</h1>
      <p>
        Please install{' '}
        <a href="https://www.google.com/chrome/">Google Chrome</a>,{' '}
        <a href="https://www.apple.com/safari/">Safari,</a>{' '}
        <a href="https://www.mozilla.org/en-US/firefox/">Firefox</a>, or the new{' '}
        <a href="https://www.microsoft.com/en-us/edge">Chromium-based Edge</a>{' '}
        to continue.
      </p>
    </div>,
    document.getElementById('root')
  )
} else {
  ReactDOM.render(
    <React.StrictMode>
      <AuthProvider
        authority={process.env.REACT_APP_AUTH_AUTHORITY}
        clientId={process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}
        redirectUri={window.location.origin}
        scope="aws.cognito.signin.user.admin phone openid profile email"
        clientSecret={process.env.REACT_APP_CLIENT_SECRET}
        responseType="code"
        automaticSilentRenew={true}
        // TODO: add autoSignIn
        autoSignIn={false}
        onSignIn={(e) => {
          // TODO: replace this with some less hacky (appends a questions mark when logging in)
          window.location.replace(
            `${window.location.origin}${e?.state.targetUrl}`
          )
        }}
        onSignOut={() => {
          // TODO: replace this with a get call so it's less janky
          window.location.replace(
            `${process.env.REACT_APP_LOGOUT_URL}?client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}&logout_uri=${window.location.origin}`
          )
        }}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <MaterialThemeProvider theme={materialTheme}>
              <GlobalStyles />
              <AssumedOrganizationRoleProvider>
                <App />
              </AssumedOrganizationRoleProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}
