import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const usePartnersQuery = () => {
  const query = useQuery<GetPartnersResponse, KyError>(
    'getPartners',
    getPartners
  )

  return query
}

export default usePartnersQuery

const searchParams = [
  ['order_by', 'ASC'],
  ['take', '10000'],
  ['include', 'subscribers'],
  ['include', 'subsidiaries'],
  ['include', 'parentPartner'],
]

type getPartners = () => Promise<GetPartnersResponse>
const getPartners: getPartners = async () => {
  const result = await orgsApi
    .get(`api/partners`, {
      searchParams,
    })
    .json<GetPartnersResponse>()
  return result
}
