import React, { useState } from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import {
  CreateEditPolicyForm,
  PoliciesList,
} from 'components/access-control/policies'
import { usePoliciesQuery } from 'hooks/access-control/policies'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const AccessControlPolicies = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { data: policies, isLoading: isLoadingPolicies } = usePoliciesQuery()
  const [isCreateEditPolicyFormOpen, setIsCreateEditPolicyFormOpen] = useState(
    false
  )
  const [policy, setPolicy] = useState<Policy | undefined>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEditPolicy = (policy: Policy) => {
    setPolicy(policy)
    setIsCreateEditPolicyFormOpen(true)
  }

  const handlePolicyFormToggle = (isFormOpen: boolean) => {
    // If the form is closing, reset the selected Permission to edit
    if (isFormOpen) setPolicy(undefined)

    // Call the normal setState
    setIsCreateEditPolicyFormOpen(isFormOpen)
  }

  const filteredPolicies =
    policies?.items?.filter((policy) => !policy.archiveInfo.archived) || []

  return (
    <>
      <Layout
        title={t('Policies')}
        description={t('Organize groups of permissions into policies.')}
        breadcrumbs={[
          { name: t('Access Control'), route: '/access-control' },
          { name: t('Policies') },
        ]}
        controls={[
          {
            label: t('Create Policies'),
            onClick: () => handlePolicyFormToggle(true),
          },
          {
            label: t('View Archived Policies'),
            onClick: () => navigate('archive'),
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
          },
        ]}
        isLoading={isLoadingPolicies}
        isUnauthorized={
          !accountPermissions?.data.ecgAdmin.permissions.includes(
            'acm.readonly'
          )
        }
        isEmpty={filteredPolicies.length === 0}
        emptyComponent={<PoliciesEmpty title={t('No Policies Created')} />}
      >
        <PoliciesList
          // Only show unarchived policies
          data={filteredPolicies}
          onEditPolicy={handleEditPolicy}
        />
      </Layout>
      <CreateEditPolicyForm
        isFormOpen={isCreateEditPolicyFormOpen}
        setIsFormOpen={handlePolicyFormToggle}
        policy={policy}
      />
    </>
  )
}

export default AccessControlPolicies

const PoliciesEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`
