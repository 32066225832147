import { rest } from 'msw'
import {
  generatePaginatable,
  generateArchivable,
  generateAuditable,
  generateDisableable,
  updateDatabaseArray,
} from '../utils'
import db from '../../data/db'
import faker from 'faker'
import { hidePhoneNumber, hideEmail, subscriberDisplayToProfile } from 'utils'

const responsiblePartiesHandlers = [
  // get vital sign audit trail list
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/responsible-parties']
  >('/subscribers-profile/:subscriberId/responsible-parties', (req, res, ctx) =>
    res(
      ctx.json({
        ...generatePaginatable,
        items: db.get<Array<ResponsibleParty>>('responsibleParties'),
      })
    )
  ),

  rest.post<
    OrgsAPIRequest['POST']['/subscribers-profile/{subscriberId}/responsible-parties']['body'],
    OrgsAPIResponse['POST']['/subscribers-profile/{subscriberId}/responsible-parties']
  >(
    '/subscribers-profile/:subscriberId/responsible-parties',
    (req, res, ctx) => {
      const responsibleParties = db.get<ResponsibleParty[]>(
        'responsibleParties'
      )
      const subscriber = db
        .get<SubscriberDisplay[]>('subscriberList')
        .find((subscriber) => subscriber.id === req.params.subscriberId)

      if (!subscriber) throw new Error('Subscriber not found!')

      // add new responsible party
      db.set<ResponsibleParty[]>('responsibleParties', [
        ...responsibleParties,
        {
          id: faker.random.uuid(),
          ...generateArchivable(),
          ...generateAuditable(),
          ...generateDisableable(),
          priority: responsibleParties.length + 1,
          person: {
            ...req.body[0].person,
            salutation: 1,
            suffix: req.body[0].person.suffix || '',
            firstName: req.body[0].person.firstName || '',
            lastName: req.body[0].person.lastName || '',

            // email name is different for getting then when posting
            emailAddress: hideEmail({
              email: req.body[0].person.email || '',
            }),
          },
          addresses: [
            {
              id: faker.random.uuid(),
              addressLine1: req.body[0].addresses[0].addressLine1 || '',
              addressLine2: req.body[0].addresses[0].addressLine2 || '',

              city: req.body[0].addresses[0].city || '',
              countryCode: req.body[0].addresses[0].countryCode || '',
              postalCode: req.body[0].addresses[0].postalCode || '',
              stateProvinceCode:
                req.body[0].addresses[0].stateProvinceCode || '',
            },
          ],
          phoneNumbers:
            // we only care about one phone number for displaying
            [
              {
                id: faker.random.uuid(),
                type: req.body[0].phoneNumbers[0]?.type,
                extension: req.body[0].phoneNumbers[0].extension || '',
                number: hidePhoneNumber({
                  phoneNumber: req.body[0].phoneNumbers[0].number || '',
                }),
              },
            ],
          relationship: req.body[0].relationship,
          subscriber: subscriberDisplayToProfile(subscriber),
        },
      ])

      return res(ctx.json(responsibleParties))
    }
  ),

  rest.patch<
    OrgsAPIRequest['PATCH']['/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}']['body'],
    OrgsAPIResponse['PATCH']['/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}']
  >(
    '/subscribers-profile/:subscriberId/responsible-parties/:responsiblePartyId',
    (req, res, ctx) => {
      const responsibleParties = db.get<Array<ResponsibleParty>>(
        'responsibleParties'
      )

      // update responsible party

      const rpToUpdate = responsibleParties.find(
        (rp) => rp.id === req.params.responsiblePartyId
      )
      if (!rpToUpdate) throw new Error('Responsible party not found')

      const updatedResponsibleParties = updateDatabaseArray<ResponsibleParty>({
        array: responsibleParties,
        requests: req.body,
        itemToUpdate: rpToUpdate,
        matchBy: 'id',
      }).map((responsibleParty) => ({
        ...responsibleParty,
        person: {
          ...responsibleParty.person,
          // hide email with asterisks
          emailAddress: `${responsibleParty.person.emailAddress[0]}${'*'.repeat(
            responsibleParty.person.emailAddress.split('@')[0].length - 1
          )}@${responsibleParty.person.emailAddress.split('@')[1]}`,
        },
      }))

      db.set<Array<ResponsibleParty>>(
        'responsibleParties',
        updatedResponsibleParties
      )

      return res(ctx.json(rpToUpdate))
    }
  ),
  rest.delete<
    undefined,
    OrgsAPIResponse['DELETE']['/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}']
  >(
    '/subscribers-profile/:subscriberId/responsible-parties/:responsiblePartyId',
    (req, res, ctx) => {
      const responsibleParties = db.get<Array<ResponsibleParty>>(
        'responsibleParties'
      )

      const filterRp = responsibleParties.filter(
        (rp) => rp.id !== req.params.responsiblePartyId
      )

      db.set<Array<ResponsibleParty>>('responsibleParties', filterRp)

      return res(ctx.json({ result: true }))
    }
  ),
]

export default responsiblePartiesHandlers
