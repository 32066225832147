import React from 'react'
import tw, { styled } from 'twin.macro'

type SwitchProps = {
  id?: string
  label?: string
  checked?: boolean
  onChange?: (newValue: boolean) => void
  className?: string
  disabled?: boolean
  ['data-testid']?: string
}

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      id,
      label,
      checked,
      onChange,
      className = '',
      'data-testid': dataTestId,
      disabled,
    }: SwitchProps,
    ref
  ) => {
    return (
      <SwitchContainer
        htmlFor={id}
        disabled={disabled}
        className={className}
        role="checkbox"
        data-testid={dataTestId}
      >
        <ToggleContainer>
          <input
            checked={checked}
            id={id}
            type="checkbox"
            ref={ref}
            className="hidden"
            onChange={(e) => {
              onChange && onChange(e.target.checked)
            }}
            disabled={disabled}
          />
          <ToggleBackground checked={checked} />
          <CircleToggle checked={checked} disabled={disabled} />
        </ToggleContainer>
        <Label disabled={disabled}>{label}</Label>
      </SwitchContainer>
    )
  }
)

export default Switch

const SwitchContainer = styled.label<{
  className: string
  disabled: boolean | undefined
}>(({ className, disabled }) => [
  tw`flex items-center`,
  disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`,
  className,
])

const ToggleContainer = tw.div`relative`

const ToggleBackground = styled.div<{
  checked: boolean | undefined
}>(({ checked }) => [
  tw`w-10 h-4 rounded-full shadow-inner`,
  checked ? tw`bg-blue-200` : tw`bg-gray-300`,
])

const CircleToggle = styled.div<{
  checked: boolean | undefined
  disabled: boolean | undefined
}>(({ checked, disabled }) => [
  tw`absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition-all`,
  checked ? tw`transform-x-full bg-blue-400` : tw`bg-gray-400`,
  disabled && (checked ? tw`bg-blue-300` : tw`bg-gray-300`),
])

const Label = styled.p<{ disabled: boolean | undefined }>(({ disabled }) => [
  tw`ml-3 select-none`,
  disabled && tw`text-gray-500`,
])
