import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCompleteProvisioningLineItemMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    Array<
      | OrdersAPIResponse['POST']['/orders/{orderId}/line-items/{lineItemId}/provisioning-complete']
      | OrdersAPIResponse['DELETE']['/orders/{orderId}/line-items/{lineItemId}/provisioning-data/{code}']
    >,
    orderCompleteProvisioningArgs
  >({
    mutationFn: completeProvisioningLineItem,
    successMsg: {
      message: t('Line item provisioning completed'),
      showOn: 'onSuccess',
    },
    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['orderLineItems', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}/line-items'],
          orderCompleteProvisioningArgs
        >((oldCache, { lineItemId, newProvisioningAttributes }) => {
          if (!oldCache) return undefined

          return oldCache.map((lineItem) =>
            lineItem.id === lineItemId
              ? {
                  ...lineItem,
                  orderLineItemStatusId: 4,
                  provisioningData: newProvisioningAttributes,
                }
              : lineItem
          )
        }),
      },
    ],
    additionalCachesToInvalidate: [
      ({ orderId }) => ['orderActivity', { orderId }],
      ({ orderId }) => ['order', { orderId }],
    ],
  })

  return mutation
}

export default useCompleteProvisioningLineItemMutation

type orderCompleteProvisioningArgs = {
  orderId: string
  lineItemId: string
  oldProvisioningAttributes: Record<string, string>
  newProvisioningAttributes: Record<string, string>
}

const completeProvisioningLineItem = ({
  orderId,
  lineItemId,
  oldProvisioningAttributes,
  newProvisioningAttributes: provisioningData,
}: orderCompleteProvisioningArgs) => {
  const newProvisioningAttributeIds = Object.entries(provisioningData).map(
    ([attributeId]) => attributeId
  )

  const attributesToDelete = Object.entries(oldProvisioningAttributes).filter(
    ([attributeId]) => !newProvisioningAttributeIds.includes(attributeId)
  )
  return Promise.all<
    | OrdersAPIResponse['POST']['/orders/{orderId}/line-items/{lineItemId}/provisioning-complete']
    | OrdersAPIResponse['DELETE']['/orders/{orderId}/line-items/{lineItemId}/provisioning-data/{code}']
  >([
    ordersApi.post(
      '/orders/{orderId}/line-items/{lineItemId}/provisioning-complete',
      {
        pathParams: { orderId, lineItemId },
        body: { provisioningData },
      }
    ),
    ...attributesToDelete.map(([attributeId]) =>
      ordersApi.delete(
        '/orders/{orderId}/line-items/{lineItemId}/provisioning-data/{code}',
        {
          pathParams: { orderId, lineItemId, code: attributeId },
        }
      )
    ),
  ])
}
