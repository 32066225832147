import React, { useState, useEffect } from 'react'
import { LoadingIcon, EntityLabel, AutoComplete } from 'elements'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { useDebounce } from 'react-use'
import {
  useOrganizationListQuery,
  useOrganizationQuery,
} from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { SingleProps } from 'elements/AutoComplete'

type OrganizationAutoCompleteProps = {
  selectedOrganization: string | OrganizationListItem | null
  setSelectedOrganization: (
    selectedOrganization: OrganizationListItem | null
  ) => void
  organizationId?: string
  label?: string
  placeholder?: string
  className?: string
  disabled?: boolean
  error?: string
}

const OrganizationAutoComplete = ({
  selectedOrganization,
  setSelectedOrganization,
  organizationId,
  label,
  placeholder,
  className,
  disabled,
  error,
}: OrganizationAutoCompleteProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [options, setOptions] = useState<OrganizationListItem[]>([])

  const organizations = useOrganizationListQuery({
    organizationFilters: {
      searchTerm: debouncedSearchTerm,
    },
    pageLength: 5,
    orgId: organizationId || assumedOrganizationRole?.orgID,
  })

  const organizationQuery = useOrganizationQuery(
    typeof selectedOrganization === 'string' ? selectedOrganization : ''
  )

  // debounce searchTerm value before querying subscriber autocomplete service
  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm)
    },
    500,
    [searchTerm]
  )

  // empty options array if debouncedSearchTerm is updated
  useEffect(() => {
    setOptions([])
  }, [debouncedSearchTerm])

  // update options array when a query is updated
  useEffect(() => {
    setOptions([
      ...(organizationQuery.data
        ? [
            {
              ...organizationQuery.data,
              legacyIdentifier: '',
            },
          ]
        : []),
      //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ...(organizations.data?.items?.filter(
        (org) => org.id !== organizationQuery.data?.id
      ) || []),
    ])
  }, [organizations.data, organizationQuery.data])

  // Initialize the searchTerm correctly when an org is selected
  useEffect(() => {
    if (organizationQuery.data?.businessName)
      setSearchTerm(organizationQuery.data.businessName)
    else if (selectedOrganization && typeof selectedOrganization !== 'string')
      setSearchTerm(selectedOrganization.businessName)
  }, [organizationQuery.data, selectedOrganization])

  return (
    <BaseAutoComplete<React.FC<SingleProps<OrganizationListItem>>>
      single
      label={label}
      placeholder={placeholder}
      optionLabel={(option) => option.businessName}
      options={options}
      error={error}
      // disable frontend filtering
      filterOptions={(options) => options}
      selectedOption={
        organizationQuery.data
          ? { ...organizationQuery.data, legacyIdentifier: '' }
          : null
      }
      noOptionsText={
        //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        organizations.data?.items?.length === 0 ? (
          t('No Organizations Found')
        ) : (
          <LoadingContainer>
            <LoadingIcon width="30px" height="30px" />
          </LoadingContainer>
        )
      }
      onChange={(newSelectedOption) => {
        setOptions(
          newSelectedOption ? [newSelectedOption, ...options] : options
        )
        setSelectedOrganization(newSelectedOption)
      }}
      inputValue={searchTerm}
      onInputValueChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <EntityLabel id={option.id} name={option.businessName} />
        </li>
      )}
      className={className}
      disabled={disabled}
    />
  )
}

export default OrganizationAutoComplete

const BaseAutoComplete = styled(AutoComplete)<{ className?: string }>(
  ({ className }) => ['min-width: 12rem;', className]
)

const LoadingContainer = tw.div`flex justify-center py-2`
