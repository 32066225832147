import React from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSubscriberQuery } from 'hooks/organizations'
import {
  SubscriberDetailsContainer,
  SubscriberSelect,
} from 'components/subscribers'

const SubscriberDetails = () => {
  const { t } = useTranslation()
  const { subscriberId } = useParams()
  const { data: subscriber, isLoading: isSubLoading } = useSubscriberQuery(
    subscriberId
  )

  return (
    <Layout
      title={
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold">{t('Subscriber Details')}:</h1>
          <SubscriberSelect />
        </div>
      }
      isLoading={isSubLoading}
      isEmpty={!subscriber}
      emptyComponent={
        <Empty
          title={t('Subscriber Not Found')}
          description={t(
            `Either this subscriber doesn't exist or you don't have access to view them`
          )}
        />
      }
      description={t('Manage and review subscriber-specific activity.')}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '/subscribers',
        },
        {
          name: `${subscriber?.person.firstName} ${subscriber?.person.lastName}`,
        },
      ]}
    >
      {/* @ts-expect-error Layout will take care of this case */}
      <SubscriberDetailsContainer subscriber={subscriber} />
    </Layout>
  )
}

export default SubscriberDetails
