import React, { useState, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import tw, { styled } from 'twin.macro'
import Icon from '../../elements/Icon'
import {
  dateRangePresetToInputLabel,
  dateRangePresetToDateRange,
  dateRangePresets,
  dateRangePresetToPretty,
} from 'utils'
import { format, startOfYear } from 'date-fns'
import { FadeInScaleUp } from 'animations'
import 'styles/react-datepicker.css'
import { useClickAway } from 'react-use'

type DateRangeObject = { preset: DateRangePreset; value: [Date, Date] }

export type DateRangePickerProps = {
  /**
   * Sets the state of the DateRangePicker, overriding the internal state.
   */
  value?: DateRangeObject

  /**
   * Callback function called when the state of the DateRangePicker changes.
   */
  onChange?: (preset: DateRangePreset, value: [Date, Date]) => void

  /**
   * Sets the minimum date that can be selected with the DateRangePicker.
   */
  minDate?: Date

  /**
   * Disables the DateRangePicker if **true**.
   */
  disabled?: boolean

  /**
   * The x-axis orientation of the DateRangePicker.
   */
  justify?: 'start' | 'end' | 'auto'

  /**
   * Hide presets, this is needed if we want to disallow interaction with a set of
   * of specific date presets
   */
  hiddenPresets?: DateRangePreset[]
}

const DateRangePicker = ({
  value,
  onChange,
  minDate,
  disabled,
  justify = 'auto',
  hiddenPresets = [],
}: DateRangePickerProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [inputMode, setInputMode] = useState<'startDate' | 'endDate'>(
    'startDate'
  )
  const startDateInputRef = useRef<HTMLInputElement>(null)
  const endDateInputRef = useRef<HTMLInputElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAnchorModalRight, setIsAnchorModalRight] = useState(false)

  const [internalValue, setInternalValue] = useState<DateRangeObject>(
    value || {
      preset: 'this-year',
      value: [startOfYear(new Date()), new Date()],
    }
  )
  const {
    value: [startDate, endDate],
  } = internalValue

  // sync external & internal value if external changes
  useEffect(() => value && setInternalValue(value), [value])

  // if a click is outside of the dropdown, set the visibility to false
  useClickAway(modalRef, () => setIsModalOpen(false))

  // set the focus target when the modal is opened
  useEffect(() => {
    if (isModalOpen && startDateInputRef.current && endDateInputRef.current) {
      if (inputMode === 'startDate') {
        startDateInputRef.current.focus()
      } else {
        endDateInputRef.current.focus()
      }
    }
  }, [isModalOpen])

  // if the user selects a start date, automatically change the input mode to end date
  useEffect(() => {
    if (inputMode === 'startDate' && isModalOpen) setInputMode('endDate')
  }, [startDate])

  // reset the input mode back to start date when the modal is closed
  useEffect(() => {
    if (!isModalOpen) setInputMode('startDate')
  }, [isModalOpen])

  // if the user selects
  useEffect(() => {
    if (internalValue.preset !== 'custom') setInputMode('startDate')
  }, [internalValue.preset])

  // check if element is too far right to anchor to left of element rect and anchor right instead
  useEffect(() => {
    if (
      modalRef.current &&
      window.innerWidth - modalRef.current.getBoundingClientRect().left < 480 // width of modal in px
    )
      setIsAnchorModalRight(true)
    else setIsAnchorModalRight(false)
  }, [modalRef])

  return (
    <RelativeContainer ref={modalRef}>
      <DateRangeButton
        disabled={disabled}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <Calendar type="calendar" /> &nbsp;
        <DateRangeText>
          {format(startDate, dateRangePresetToInputLabel[internalValue.preset])}{' '}
          - {format(endDate, dateRangePresetToInputLabel[internalValue.preset])}
        </DateRangeText>
        <PresetText>
          ({dateRangePresetToPretty[internalValue.preset]})
        </PresetText>
      </DateRangeButton>

      {isModalOpen ? (
        <Modal
          justify={(() => {
            if (justify === 'auto' && isAnchorModalRight) return 'end'
            if (justify === 'auto') return 'start'

            return justify
          })()}
        >
          <DatePresetItemList>
            {dateRangePresets.map((dateRangePreset) => (
              <DatePresetItem
                hidden={hiddenPresets.includes(dateRangePreset)}
                key={dateRangePreset}
                isSelected={internalValue.preset === dateRangePreset}
                onClick={() => {
                  const newValue =
                    // don't update the dates if the custom preset is selected
                    dateRangePreset === 'custom'
                      ? internalValue.value
                      : dateRangePresetToDateRange(dateRangePreset)
                  onChange && onChange(dateRangePreset, newValue)
                  !value &&
                    setInternalValue({
                      preset: dateRangePreset,
                      value: newValue,
                    })
                }}
              >
                {dateRangePresetToPretty[dateRangePreset]}
              </DatePresetItem>
            ))}
          </DatePresetItemList>

          <ModalCalendar>
            <ModalHeader>
              <DateInput
                value={format(startDate, 'P')}
                ref={startDateInputRef}
                onClick={() => setInputMode('startDate')}
                isActive={inputMode === 'startDate'}
              />

              <RightArrow type="arrow-right" />

              <DateInput
                value={format(endDate, 'P')}
                ref={endDateInputRef}
                onClick={() => setInputMode('endDate')}
                isActive={inputMode === 'endDate'}
              />
            </ModalHeader>
            <ModalContent>
              <DatePicker
                selected={inputMode === 'startDate' ? startDate : endDate}
                onChange={(newDate: Date) => {
                  // disallow custom date picking if 'custom' is included as a hidden preset
                  if (hiddenPresets.includes('custom')) return undefined

                  if (inputMode === 'startDate') {
                    onChange && onChange('custom', [newDate, endDate])
                    !value &&
                      setInternalValue({
                        preset: 'custom',
                        value: [newDate, endDate],
                      })
                    return
                  }

                  onChange && onChange('custom', [startDate, newDate])
                  !value &&
                    setInternalValue({
                      preset: 'custom',
                      value: [startDate, newDate],
                    })
                }}
                selectsStart={inputMode === 'startDate'}
                selectsEnd={inputMode === 'endDate'}
                startDate={startDate}
                endDate={endDate}
                minDate={inputMode === 'startDate' ? minDate : startDate}
                maxDate={inputMode === 'startDate' ? endDate : new Date()}
                inline
                disabledKeyboardNavigation
              />
            </ModalContent>
          </ModalCalendar>
        </Modal>
      ) : null}
    </RelativeContainer>
  )
}

export default DateRangePicker

const RelativeContainer = tw.div`relative z-10`

const DateRangeButton = styled.button<{ disabled: boolean | undefined }>(
  ({ disabled }) => [
    tw`flex items-center px-4 py-2 rounded border transition-all select-none outline-none focus:outline-none border-gray-200`,
    !disabled &&
      tw`bg-white hover:bg-gray-100 hover:border-gray-300 cursor-pointer`,
    disabled && tw`opacity-50 bg-gray-100 hover:bg-gray-100 cursor-not-allowed`,
  ]
)

const Modal = styled(FadeInScaleUp)<{ justify: 'start' | 'center' | 'end' }>(
  ({ justify }) => [
    tw`absolute flex bg-white rounded border border-gray-200 shadow-lg mt-2`,
    justify === 'start' && tw`left-0`,
    justify === 'end' && tw`right-0`,
  ]
)

const ModalCalendar = tw.div`border-l border-gray-200`

const ModalHeader = tw.div`w-80 grid grid-cols-7 items-center mb-1 px-4 py-2 border-b border-gray-200 bg-gray-50`

const ModalContent = tw.div`flex justify-center`

const DateInput = styled.input<{ isActive: boolean }>(({ isActive }) => [
  tw`px-2 py-1 rounded outline-none focus:ring-1 ring-blue-200 col-span-3`,
  isActive ? tw`border-blue-300 border-2` : tw`border-gray-200 border`,
])

const DatePresetItemList = tw.div`h-auto`

const DatePresetItem = styled.p<{ isSelected: boolean }>(({ isSelected }) => [
  tw`text-sm p-2 w-40 border-b border-gray-200  cursor-pointer`,
  isSelected ? tw`bg-blue-400 text-white` : tw`bg-white hover:bg-blue-50`,
])

const DateRangeText = styled.p`
  ${tw`align-middle`}
  color: inherit;
`

const PresetText = tw.p`ml-1.5 text-gray-500`

const Calendar = tw(Icon)`mr-1`

const RightArrow = tw(Icon)`justify-self-center`
