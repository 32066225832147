import React, { useState, useEffect, useMemo } from 'react'
import {
  RightPopup,
  Checkbox,
  LoadingIcon,
  Icon,
  SearchBox,
  Switch,
  Select,
  SelectItem,
} from 'elements'
import { Button, Empty, Tooltip } from 'atlas'
import { useTranslation } from 'react-i18next'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import { usePoliciesQuery } from 'hooks/access-control/policies'
import { useUpdateStaffMutation } from 'hooks/user-staff'
import { useStaffMemberTypesQuery } from 'hooks/seed-data'
import _ from 'lodash'
import tw from 'twin.macro'

type AssignPoliciesFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (newValue: boolean) => void
  existingPolicies: Policy[]
  setExistingPolicies?: (newValue: Policy[]) => void
  userType: 'admin' | 'staff'
  staff?: StaffMember
}

const AssignPoliciesForm = ({
  isFormOpen,
  setIsFormOpen,
  existingPolicies,
  setExistingPolicies,
  userType,
  staff,
}: AssignPoliciesFormProps) => {
  const [expandedPolicies, setExpandedPolicies] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const { t } = useTranslation()
  const [selectedPolicies, setSelectedPolicies] = useState<Policy[]>([])
  const policiesQuery = usePoliciesQuery()
  const staffMemberTypesQuery = useStaffMemberTypesQuery()

  const formMethods = useForm<EditStaffMemberForm>({
    defaultValues: {
      memberType: staff?.memberType,
      isProvider: staff?.isProvider,
    },
  })

  const { handleSubmit, watch, reset } = formMethods
  const updateStaffMutation = useUpdateStaffMutation()

  const assignablePolicies: Policy[] = useMemo(() => {
    return _.orderBy(
      _.differenceBy(policiesQuery.data?.items || [], existingPolicies, 'id'),
      [(policy) => (policy.activeInfo.active ? -1 : 1), 'name']
    )
      .filter(
        (policy) =>
          // filter out internal policies of not an admin
          userType === 'admin' || !policy.internalUseOnly
      )

      .filter((policy) =>
        // filter policies by searched value
        {
          return (
            policy.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            policy.code.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
      )
  }, [policiesQuery.data, searchValue, existingPolicies])

  // reset form state when modal is opened
  useEffect(() => {
    if (isFormOpen) {
      reset({
        memberType: staff?.memberType,
        isProvider: staff?.isProvider,
      })
      setSelectedPolicies([])
      setExpandedPolicies([])
      setSearchValue('')
    }
  }, [isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      width="45rem"
      setOpen={setIsFormOpen}
      title={`${staff ? t('Edit Staff & Policies') : t('Assign Policies')}`}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateStaffMutation.isLoading}
            onClick={handleSubmit(async (formData) => {
              //handle staff and policies
              if (staff)
                updateStaffMutation.mutate({
                  staffMember: staff,
                  editStaffForm: {
                    ...formData,
                    policies: [...existingPolicies, ...selectedPolicies].map(
                      (newPolicies) => newPolicies.id
                    ),
                  },
                })

              //handle policies only, applies to forms without staff members
              if (setExistingPolicies && !staff)
                setExistingPolicies([...existingPolicies, ...selectedPolicies])
              setIsFormOpen(false)
            })}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updateStaffMutation.isLoading}
            onClick={() => {
              // Close form drawer
              setIsFormOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {staff ? (
        staffMemberTypesQuery.isLoading ? (
          <LoadingIcon />
        ) : (
          staffMemberTypesQuery.data && (
            <FormProvider {...formMethods}>
              <FormContainer>
                <Controller
                  as={
                    <Select
                      label={t('Staff Member Type')}
                      variant="outlined"
                      fullWidth={false}
                      selectClassName="w-54"
                    >
                      {staffMemberTypesQuery.data.items?.map(
                        (staffMemberType, index) => (
                          <SelectItem value={staffMemberType.id} key={index}>
                            {staffMemberType.name}
                          </SelectItem>
                        )
                      )}
                    </Select>
                  }
                  name="memberType"
                  defaultValue={staffMemberTypesQuery.data.items?.[0].id}
                />
                {/* If Staff Member type has a description, show an info tooltip for it */}
                {staffMemberTypesQuery.data.items?.find(
                  (memberType) => Number(memberType.id) === watch('memberType')
                )?.description ? (
                  <Tooltip
                    className="flex items-center"
                    content={
                      <ToolTipContent>
                        {
                          // @ts-expect-error This find will return a result because this is conditionally rendered for such
                          staffMemberTypesQuery.data.items.find(
                            (memberType) =>
                              Number(memberType.id) === watch('memberType')
                          ).description
                        }
                      </ToolTipContent>
                    }
                  >
                    <InfoIcon type="info" />
                  </Tooltip>
                ) : null}
                <SwitchContainer>
                  <Controller
                    render={({ onChange, value, name, ref }) => (
                      <Switch
                        onChange={onChange}
                        checked={value}
                        id={name}
                        ref={ref}
                        label={t('Assign Staff Member As Provider')}
                        className="mt-3 mr-1"
                      />
                    )}
                    defaultValue={'false'}
                    name="isProvider"
                  />
                  <Tooltip
                    className="flex items-center"
                    content={
                      <ToolTipContent>{`${t(
                        'Selecting this option will allow this staff member to act as a provider for subscribers'
                      )}. ${t(
                        'This gives them access rights to view and manage PHI'
                      )}`}</ToolTipContent>
                    }
                  >
                    <InfoIcon type="info" />
                  </Tooltip>
                </SwitchContainer>
              </FormContainer>
            </FormProvider>
          )
        )
      ) : null}
      {policiesQuery.isLoading ? (
        <LoadingIcon />
      ) : (
        (() => {
          if (!assignablePolicies.length)
            return (
              <>
                <PoliciesTitle>{`${t('Assign Policies')} ${
                  selectedPolicies.length ? `(${selectedPolicies.length})` : ''
                }`}</PoliciesTitle>
                <Search
                  disabled={searchValue === ''}
                  placeholder="Search policies"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Empty
                  title={t('No Data Found')}
                  description={t('No assignable policies are available')}
                  disableAnimations
                />
              </>
            )

          return (
            <>
              <PoliciesTitle>{`${t('Assign Policies')} ${
                selectedPolicies.length ? `(${selectedPolicies.length})` : ''
              }`}</PoliciesTitle>
              <Search
                placeholder="Search policies"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              {assignablePolicies.map((policy) => (
                <>
                  <Policy
                    onClick={() =>
                      // either toggle the displayed policy list off or display the new policy list
                      setExpandedPolicies(
                        expandedPolicies.includes(policy.id)
                          ? expandedPolicies.filter(
                              (expandedPolicyId) =>
                                policy.id !== expandedPolicyId
                            )
                          : [...expandedPolicies, policy.id]
                      )
                    }
                  >
                    <LeftContent>
                      <Checkbox
                        onChange={() => {
                          // if the checkbox is already check, uncheck it
                          if (
                            selectedPolicies.find(
                              (item) => item.id === policy.id
                            )
                          )
                            return setSelectedPolicies(
                              selectedPolicies.filter(
                                (item) => item.id !== policy.id
                              )
                            )

                          // otherwise check it
                          setSelectedPolicies([...selectedPolicies, policy])
                        }}
                        checked={
                          !!selectedPolicies.find(
                            (item) => item.id === policy.id
                          )
                        }
                        testid={`${policy.name}-checkbox`}
                      />

                      <PolicyIcon type="policies" />
                      <PolicyName>{policy.name}</PolicyName>
                      <PolicyCode>({policy.code})</PolicyCode>
                    </LeftContent>

                    {expandedPolicies.includes(policy.id) ? (
                      <Icon type="chevron-up" />
                    ) : (
                      <Icon type="chevron-down" />
                    )}
                  </Policy>
                  <PolicyPermissionsList
                    hidden={!expandedPolicies.includes(policy.id)}
                  >
                    {policy.permissions?.length ? (
                      policy.permissions.map((permission) => (
                        <Tooltip
                          content={permission.description}
                          key={permission.id}
                        >
                          <Permission>
                            <PermissionIcon type="permissions" />
                            <PermissionName>{permission.name}</PermissionName>
                            <PermissionCode>({permission.code})</PermissionCode>
                          </Permission>
                        </Tooltip>
                      ))
                    ) : (
                      // empty case
                      <Permission>
                        <PermissionCode>
                          {t(
                            "There aren't any permissions attached to this policy"
                          )}
                        </PermissionCode>
                      </Permission>
                    )}
                  </PolicyPermissionsList>
                </>
              ))}
            </>
          )
        })()
      )}
    </RightPopup>
  )
}

export default AssignPoliciesForm

const LeftContent = tw.div`flex items-center`

const Policy = tw.div`py-1 px-2 hover:bg-blue-50 flex items-center justify-between cursor-pointer`

const PolicyName = tw.p`mr-2`

const PolicyCode = tw.p`text-gray-500`

const PolicyPermissionsList = tw.div``

const Permission = tw.div`flex items-center py-1 px-6 border-l border-gray-400 ml-4`

const PermissionName = tw.p`mr-2`

const PermissionCode = tw.p`text-gray-500`

const InfoIcon = tw(
  Icon
)`text-gray-600 hover:text-gray-900 mt-3 w-5 h-5 align-middle`

const Search = tw(SearchBox)`flex-grow self-end mr-2 mb-2`

const FormContainer = tw.div`flex gap-4 items-center mb-10`

const PoliciesTitle = tw.h3`text-xl font-semibold mb-4`

const ToolTipContent = tw.p`w-60`

const SwitchContainer = tw.span`flex items-center`

const PolicyIcon = tw(Icon)`mr-2`

const PermissionIcon = tw(Icon)`mr-2`
