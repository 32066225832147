import React, { useEffect } from 'react'
import { RightPopup, TextField } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useAddressAlertsMutation } from 'hooks/alerts'
import { useResponsiblePartiesQuery } from 'hooks/responsible-parties'
import { AlertInformationCard, AlertNotes } from 'components/alerts'
import { SubscriberInfoCard } from 'components/subscriber-info'
import { useCreateAlertNoteMutation } from 'hooks/alerts'
import { useForm, Controller } from 'react-hook-form'
import _ from 'lodash'
import { formatPhoneNumber } from 'utils'
import tw, { styled } from 'twin.macro'

type AlertDetailsProps = {
  alert: Alert | undefined
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  subscriber: SubscriberDisplay | undefined
}

const AlertDetails = ({
  alert,
  isFormOpen,
  setIsFormOpen,
  subscriber,
}: AlertDetailsProps) => {
  const { t } = useTranslation()

  const { mutate: addressAlerts } = useAddressAlertsMutation()

  const createAlertNoteMutation = useCreateAlertNoteMutation()

  const responsibleParties = useResponsiblePartiesQuery({
    subscriberId: subscriber?.id || '',
  })

  const { handleSubmit, errors, reset, control } = useForm<AlertNotesForm>({
    defaultValues: { note: '' },
  })

  // reset notes field when form opens
  useEffect(() => {
    if (isFormOpen) reset()
  }, [isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={`${t('Alert Details for')} ${subscriber?.person.firstName} ${
        subscriber?.person.lastName
      }`}
      width="750px"
      controls={
        <>
          {!alert?.addressed && (
            <>
              <Button
                type="primary-filled"
                onClick={() => {
                  if (alert) {
                    addressAlerts([{ alert }])
                    setIsFormOpen(false)
                  }
                  reset()
                }}
              >
                {t('Address')}
              </Button>
              &nbsp;
            </>
          )}
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Close')}
          </Button>
        </>
      }
    >
      {alert && subscriber ? (
        <>
          <InformationHeader>{t('Alert Information')}</InformationHeader>
          <AlertInformationCard alert={alert} />
          <InformationHeader>{t('Subscriber Information')}</InformationHeader>
          <SubscriberInfoCard subscriberInfo={subscriber} />
          <ResponsiblePartiesContainer>
            <PopupHeader>{`${t('Responsible Parties')}`}</PopupHeader>
            <Card>
              {responsibleParties.data?.items?.length ? (
                _.orderBy(responsibleParties.data.items, 'priority').map(
                  (responsibleParty) => (
                    <ResponsibleParty key={responsibleParty.id}>
                      <ResponsiblePartyAttribute>
                        {responsibleParty.priority}
                      </ResponsiblePartyAttribute>
                      <p>
                        {responsibleParty.person.firstName}{' '}
                        {responsibleParty.person.lastName}
                      </p>
                      <ResponsiblePartyAttribute>
                        {responsibleParty.relationship || '-'}
                      </ResponsiblePartyAttribute>
                      <ResponsiblePartyAttribute>
                        {responsibleParty.person.emailAddress || '-'}
                      </ResponsiblePartyAttribute>
                      <ResponsiblePartyAttribute>
                        {responsibleParty.phoneNumbers[0]?.number
                          ? formatPhoneNumber(
                              responsibleParty.phoneNumbers[0].number
                            )
                          : '-'}
                      </ResponsiblePartyAttribute>
                    </ResponsibleParty>
                  )
                )
              ) : (
                <Empty
                  title="No Data Found"
                  description="No responsible party information exists for this subscriber"
                />
              )}
            </Card>
          </ResponsiblePartiesContainer>
          <PopupHeader>{`${t('Notes')}`}</PopupHeader>
          <AlertNotes alertId={alert.id} />
          <Controller
            label={t('Add Notes')}
            control={control}
            as={TextField}
            defaultValue=""
            name="note"
            rules={{ required: t('Note should not be empty').toString() }}
            error={errors.note?.message}
            fullWidth
            multiline
          />
          <Button
            isLoading={createAlertNoteMutation.isLoading}
            type="primary-filled"
            onClick={handleSubmit(async (formData) => {
              createAlertNoteMutation.mutate(
                { alert, note: formData.note },
                { onSuccess: () => reset() }
              )
            })}
          >
            {t('Add Note')}
          </Button>
        </>
      ) : null}
    </RightPopup>
  )
}

export default AlertDetails

const InformationHeader = tw.h3`text-xl font-semibold mb-2`

const PopupHeader = tw.h4`font-medium text-xl mb-2`

const ResponsiblePartiesContainer = tw.div`mb-4`

const ResponsiblePartyAttribute = tw.p`text-gray-500`

const Card = tw.div`grid grid-cols-1 gap-4 bg-white p-4 rounded-lg border border-gray-200 flex-grow`

const ResponsibleParty = styled.div`
  ${tw`grid`}
  grid-template-columns: 30px 1fr 1fr 1.5fr 1fr;
  grid-auto-rows: min-content;
`
