import React, { useMemo, useCallback, useState } from 'react'
import { List, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { useStatusSort, useEntityLabelSort } from 'hooks/react-table'
import tw from 'twin.macro'

import {
  useDeleteWebhookSubscriptionMutation,
  useRestoreWebhookSubscriptionMutation,
} from 'hooks/settings-webhooks'

type ArchivedWebhooksListProps = {
  data: WebhookSubscription[]
}

const ArchivedWebhooksList = ({ data }: ArchivedWebhooksListProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const modeSort = useEntityLabelSort<WebhookSubscription>({
    accessor: (row) => (row.isDevelopment ? 'dev' : undefined),
  })
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['postUri'],
  })

  const tableData: WebhookSubscription[] = useMemo(() => {
    return data
  }, [data])

  const statusSort = useStatusSort()

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (
      rows: Row<WebhookSubscription>[],
      columnIDs: string[],
      globalFilter: string
    ) => {
      return rows.filter((row) => {
        const name = row.original.postUri
        const description = row.original.version

        return (
          name.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<WebhookSubscription>[] = useMemo(
    () => [
      {
        id: 'URL',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('URL'),
        accessor: (row) => <PrimaryColumnText>{row.postUri}</PrimaryColumnText>,
        sortType: primaryLabelSort,
      },
      {
        id: 'VERSION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('VERSION'),
        accessor: 'version',
        width: '100px',
      },
      {
        id: 'MODE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('MODE'),
        accessor: (row) =>
          row.isDevelopment ? (
            <Chip color="cyan">{t('DEV')}</Chip>
          ) : (
            <Chip color="green">{t('LIVE')}</Chip>
          ),
        sortType: modeSort,
        width: '100px',
      },
      {
        id: 'ERRORS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ERRORS'),
        accessor: (row) => (
          <p
            className={clsx(
              'font-semibold',
              row.errors === 0 ? 'text-emerald-400' : 'text-red-400'
            )}
          >
            {row.errors}
          </p>
        ),
        width: '100px',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: () => <Chip color="gray">{t('Archived')}</Chip>,
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => <ActionsCell row={row} />,
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (row) =>
        navigate({
          pathname: `../${row.original.id}`,
        }),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'URL',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'webhooks'
      )}`}
      emptyTitle={t('No Webhooks Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default ArchivedWebhooksList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

type ActionsCellProps = {
  row: WebhookSubscription
}

const ActionsCell = ({ row }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const {
    mutate: restoreWebhookSubscription,
  } = useRestoreWebhookSubscriptionMutation()
  const {
    mutate: deleteWebhookSubscription,
  } = useDeleteWebhookSubscriptionMutation()
  const navigate = useNavigate()

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${row.postUri}"`),
      content: <p>{t('Are you sure you want to restore this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            restoreWebhookSubscription({
              organizationId: row.organizationId,
              subscriptionId: row.id,
            })
            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${row.postUri}" webhook`),
      content: <p>{t('Are you sure you want to delete this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deleteWebhookSubscription({
              subscriptionId: row.id,
              organizationId: row.organizationId,
            })
            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Manage'),
            onClick: () => {
              navigate({ pathname: `../${row.id}` })
            },
          },
          {
            label: t('Restore'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsRestoreDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
          {
            label: t('Delete'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDeleteDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.postUri}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
