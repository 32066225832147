import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, vitalsApi, handleQueryError } from 'utils'

// used to update default reminder messages
const useCreateReminderMessageTranslationMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['POST']['/organization/{organizationId}/reminder-messages'],
    KyError,
    createReminderMessageTranslationArgs,
    | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
    | undefined
  >(createReminderMessageTranslation, {
    onMutate: (data) => {
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
      >([
        ['getReminderMessageTranslations', data.orgId, data.localeKey],
        data.orgId,
        data.localeKey,
      ])

      queryCache.setQueryData<ReminderMessage[] | undefined>(
        ['getReminderMessageTranslations', data.orgId, data.localeKey],
        // @ts-expect-error bad swagger type
        (oldData) => {
          if (!oldData) return undefined
          return oldData.map((reminderMessage) => {
            return {
              ...reminderMessage,
              message: data.messages.find((newReminder) => {
                return newReminder.reminderMessageId === reminderMessage.id
              })?.message,
            }
          })
        }
      )

      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Translation created') })
    },
    onError: (error, { orgId, localeKey }, snapshot) => {
      //revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(['getReminderMessageTranslations', orgId, localeKey], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      queryCache.invalidateQueries([
        'getReminderMessageTranslations',
        args.orgId,
        args.localeKey,
      ])
      queryCache.invalidateQueries(['getAvailableTranslations', args.orgId])
    },
  })

  return mutation
}

export default useCreateReminderMessageTranslationMutation

type createReminderMessageTranslationArgs = {
  orgId?: string
  localeKey: string
  messages: Array<{
    messageTypeId: string
    message: string
    reminderMessageId?: string
  }>
}

// update default reminder
const createReminderMessageTranslation = async (
  data: createReminderMessageTranslationArgs
) => {
  const requestBody: VitalsAPIRequest['POST']['/organization/{organizationId}/reminder-messages']['body'] = {
    localeKey: data.localeKey,
    messages: data.messages,
  }

  const result = await vitalsApi
    .post(`organization/${data.orgId}/reminder-messages`, {
      json: requestBody,
    })
    .json<
      VitalsAPIResponse['POST']['/organization/{organizationId}/reminder-messages']
    >()
  return result
}
