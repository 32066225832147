import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { typedOrgsApi } from 'utils'

const useAssignApplicationsToModuleMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/applications'],
    assignApplicationsToModuleArgs
  >({
    mutationFn: assignApplicationsToModule,
    successMsg: t('Application(s) assigned to module'),
    additionalCachesToInvalidate: ['getApplications'],
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          assignApplicationsToModuleArgs
        >((oldCache, { moduleId, applicationIds }) => {
          // get array of applications corresponding to provided applicationIds
          const applicationsToAdd =
            queryCache
              .getQueryData<OrgsAPIResponse['GET']['/api/applications']>([
                'getApplications',
              ])
              ?.items?.filter((application) =>
                applicationIds.includes(application.id)
              ) || []

          return oldCache?.items
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items.map((module) =>
                    module.id === moduleId
                      ? {
                          ...module,
                          applications: [
                            ...(module.applications || []),
                            ...applicationsToAdd,
                          ],
                        }
                      : module
                  ),
                ],
              }
            : undefined
        }),
      },
    ],
  })

  return mutation
}

export default useAssignApplicationsToModuleMutation

type assignApplicationsToModuleArgs = {
  applicationIds: Array<string>
  moduleId: string
}

const assignApplicationsToModule = ({
  applicationIds,
  moduleId,
}: assignApplicationsToModuleArgs) =>
  typedOrgsApi.post('/api/modules/{moduleId}/applications', {
    pathParams: { moduleId },
    body: {
      applicationIds,
    },
  })
