import React from 'react'
import { Layout, NavigationCard, Statistic } from 'elements'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import { useNavigate, useDelay } from 'hooks'
import { dateRangePresetToDateRange } from 'utils'
import { useVitalObservationStatisticsQuery } from 'hooks/statistics'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'

const dateRangeState: DateRangeState = {
  preset: 'this-week',
  value: dateRangePresetToDateRange('this-week'),
}

const OrgAdminVitals = () => {
  const { t } = useTranslation()
  const delay = useDelay()
  const navigate = useNavigate()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  // get observations
  const vitalObservations = useVitalObservationStatisticsQuery({
    dateRangeState,
    organizationId: assumedOrganizationRole?.orgID,
  })

  // get total values for vital-signs and phone logs
  const totalObservations = vitalObservations.data
    ? vitalObservations.data
        .map((observation) => {
          return observation.total
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    : 0

  return (
    <Layout
      isLoading={vitalObservations.isLoading}
      title={t('Vitals')}
      description={t(
        'Manage all aspects of the vitals module from this central location.'
      )}
    >
      <VitalsObservations>
        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="vitals"
            iconColor="yellow"
            label="Total Observations (This week)"
            value={totalObservations || 0}
            to="vitals-activity"
          />
        </FadeInSlideDown>
      </VitalsObservations>
      <VitalsNav>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="vitals"
            iconColor="yellow"
            label={t('Vitals Activity')}
            onClick={() => navigate('vitals-activity')}
            description={t(
              'View Vital Sign readings for all Subscribers in this organization.'
            )}
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="alert"
            iconColor="red"
            label={t('Alerts Activity')}
            onClick={() => navigate('alerts-activity')}
            description={t(
              'View, manage, and address all alerts activity for Subscribers in this organization.'
            )}
          />
        </FadeInSlideDown>
      </VitalsNav>
    </Layout>
  )
}

export default OrgAdminVitals

const VitalsObservations = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`

const VitalsNav = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-4`
