import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, typedVitalsApi, handleQueryError } from 'utils'

// used to edit Reminder schedule
const useUpdateReminderScheduleMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-reminders/{reminderId}'],
    KyError,
    updateReminderScheduleArgs,
    | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
    | undefined
  >(updateReminderSchedule, {
    onMutate: (data) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
      >(['getReminderSchedules', data.subscriberId])

      //optimist update
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
        | undefined
        // @ts-expect-error bad API type
      >(['getReminderSchedules', data.subscriberId], (oldData) => {
        if (!oldData) return undefined
        const filteredReminderSchedules =
          oldData.items?.filter((preset) => preset.id !== data.scheduleId) || []

        return {
          ...oldData,
          items: [...filteredReminderSchedules, { ...data }],
        }
      })
      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Reminder schedule updated') })
    },
    onError: (error, { subscriberId }, snapshot) => {
      //revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
        | undefined
      >(['getReminderSchedules', subscriberId], snapshot)
      handleQueryError({ error })
    },
    onSettled: (_, error, req) => {
      // refetch from API to sync with server
      queryCache.invalidateQueries(['getReminderSchedules', req.subscriberId])
    },
  })

  return mutation
}

export default useUpdateReminderScheduleMutation

type updateReminderScheduleArgs = {
  reminderSchedule: UpdateReminderScheduleForm
  subscriberId: string
  scheduleId: string
}

// update default reminder
const updateReminderSchedule = async ({
  reminderSchedule,
  subscriberId,
  scheduleId,
}: updateReminderScheduleArgs) => {
  return typedVitalsApi.patch(
    `/subscribers/{subscriberId}/vital-reminders/{reminderId}`,
    {
      body: reminderSchedule,
      pathParams: { reminderId: scheduleId, subscriberId },
    }
  )
}
