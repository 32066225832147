import React from 'react'
import Icon from './Icon'
import { FadeIn } from 'animations'
import tw, { styled, TwStyle } from 'twin.macro'
import { useDelay } from 'hooks'

type FlashProps = {
  title?: string
  description?: string
  callToAction?: React.ReactNode
  iconType?: Extract<IconType, 'error' | 'alert' | 'alert2' | 'check-marked'>
}

const Flash = ({
  title,
  description,
  callToAction,
  iconType = 'alert2',
}: FlashProps) => {
  const delay = useDelay()

  return (
    <Container delay={delay()}>
      <FlashIcon type={iconType} iconColor={iconType} />
      <Title>{title}</Title>
      {description !== undefined ? (
        <Description>{description}</Description>
      ) : (
        description
      )}
      {callToAction}
    </Container>
  )
}

export default Flash

const Container = tw(
  FadeIn
)`w-full h-full flex justify-center items-center text-center flex-col py-6`

const FlashIcon = styled(Icon)<{
  iconColor: NonNullable<FlashProps['iconType']>
}>`
  ${tw`h-7 w-7`}
  ${({ iconColor }) => iconToColor[iconColor]}
`

const Title = tw.h5`text-lg`

const Description = tw.p`text-gray-600 mb-2`

const iconToColor: Record<NonNullable<FlashProps['iconType']>, TwStyle> = {
  alert: tw`text-gray-600`,
  alert2: tw`text-red-600`,
  'check-marked': tw`text-blue-300`,
  error: tw`text-red-600`,
}
