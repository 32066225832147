import { vitalsApi } from 'utils'
import { useQuery } from 'react-query'
import _ from 'lodash'

const useThresholdsQuery = (orgId: string | undefined) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']['items'],
    KyError
  >(['vitalsThresholds', orgId], () => getVitalsThresholds(orgId), {
    enabled: !!orgId,
  })

  return query
}

export default useThresholdsQuery

// get list of vital thresholds from backend
const getVitalsThresholds = async (orgId?: string) => {
  const result = await vitalsApi
    .get(`organizations/${orgId}/thresholds`)
    .json<
      VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
    >()

  return _.orderBy(result.items, 'vitalSign.displayName').reverse()
}
