import React from 'react'
import { FadeInSlideDown } from 'animations'
import { useTranslation } from 'react-i18next'
import { NavigationCard } from 'elements'
import { useNavigate, useDelay } from 'hooks'
import { Alert } from 'atlas'
import { useParams } from 'react-router-dom'
import { useSubscriberOnboardingQuery } from 'hooks/user-subscriber'
import _ from 'lodash'
import tw from 'twin.macro'

type Setting = {
  name: string | React.ReactNode
  icon: IconType
  onboardingKey?: string
  iconColor: IconColor
  permission: string
  to: string
  description?: string
}

const SubscriberSettingsGrid = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const { subscriberId } = useParams()

  const delay = useDelay()

  const subscriberOnboardingQuery = useSubscriberOnboardingQuery({
    subscriberId: subscriberId,
  })

  const settingCards: Setting[] = [
    {
      name: t('Vital Sign Assignments'),
      icon: 'vitals',
      onboardingKey: 'vitalSignAssignments',
      iconColor: 'yellow',
      permission: 'vitals.settings.readonly',
      to: 'vital-sign-assignments',
      description: t(
        'Review and manage the vital signs that are assigned to this Subscriber. Remember, readings will only be collected for vital signs configured here.'
      ),
    },
    {
      name: t('Vital Thresholds'),
      icon: 'vitals',
      onboardingKey: 'vitalThresholds',
      iconColor: 'yellow',
      permission: 'vitals.settings.readonly',
      to: 'vitals-thresholds',
      description: t(
        'Customize the vital sign thresholds for this Subscriber. If no settings are configured, we will use your Organization’s defaults. View Organization defaults here.'
      ),
    },
    {
      name: t('Reminder Schedules'),
      icon: 'reminders',
      onboardingKey: 'reminderSchedules',
      iconColor: 'purple',
      permission: 'vitals.settings.readonly',
      to: 'reminder-schedules',
      description: t('View and manage subscriber reminder scheduling.'),
    },
    {
      name: t('Manage Medications'),
      icon: 'medications',
      iconColor: 'green',
      permission: 'vitals.settings.readonly',
      to: 'manage-medication',
      description: t("View and Manage Subscriber's Medications."),
    },
  ]

  return (
    <>
      {_.find(subscriberOnboardingQuery.data, (value) => !value.complete) ? (
        <FadeInSlideDown delay={delay()}>
          <Alert
            message={t('Subscriber setup incomplete')}
            type="warning"
            onClick={() => {
              navigate('../setup')
            }}
          />
        </FadeInSlideDown>
      ) : null}

      <SettingsCard>
        {settingCards.map((setting, index) => (
          <FadeInSlideDown
            delay={0.1 + index * 0.05}
            key={`settings-card-${index}`}
          >
            <NavigationCard
              icon={setting.icon}
              iconColor={setting.iconColor}
              label={setting.name}
              onClick={() => navigate(setting.to)}
              description={setting.description}
              disabled={
                subscriberOnboardingQuery.data &&
                !!Object.entries(subscriberOnboardingQuery.data).find(
                  ([key, value]) =>
                    key === setting.onboardingKey && !value.complete
                )
              }
            />
          </FadeInSlideDown>
        ))}
      </SettingsCard>
    </>
  )
}

export default SubscriberSettingsGrid

const SettingsCard = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
