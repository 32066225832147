import React from 'react'
import { Layout, NavigationCard } from 'elements'
import { useTranslation } from 'react-i18next'
import { useContractQuery } from 'hooks/contracts'
import { useParams } from 'react-router'
import { useSearchParam } from 'react-use'
import { FadeInSlideDown } from 'animations'
import { useDelay, useNavigate } from 'hooks'
import tw from 'twin.macro'

const AddLineItem = () => {
  const { t } = useTranslation()
  const delay = useDelay()
  const navigate = useNavigate()

  const { contractId } = useParams()
  const organizationId = useSearchParam('organizationId')

  const contractQuery = useContractQuery({
    organizationId: organizationId || '',
    contractId,
  })

  return (
    <Layout
      title={t('Add Line Item')}
      description={
        t('Select a type of line item to add to this contract') + '.'
      }
      breadcrumbs={[
        { name: t('Contracts'), route: '../../' },
        {
          name:
            contractQuery.data?.descriptionInfo?.title || t('Contract Details'),
          route: '../',
        },
        { name: t('Add Line Item') },
      ]}
      isLoading={contractQuery.isLoading}
    >
      <LineItemsGrid>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="hardware"
            iconColor="green"
            label={t('Add Base Product')}
            onClick={() => navigate('PrimaryProduct')}
            description={
              t('Add the Base Product that the customer will be buying') +
              '. ' +
              t('(e.g. Premier or Pro-Health)')
            }
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="hardware"
            iconColor="cyan"
            label={t('Add Device or Service')}
            onClick={() => navigate('DeviceOrService')}
            description={
              t('Add a line item containing a specific product') +
              '. ' +
              t('(e.g. Pro-Health Hub for $40/month)')
            }
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="devices"
            iconColor="cyan"
            label={t('Add Product Type')}
            onClick={() => navigate('ProductType')}
            description={
              t('Add a line item containing a product type') +
              '. ' +
              t('(e.g. Premier Devices at $5/month)')
            }
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            disabled
            icon="devices"
            iconColor="red"
            label={t('Group Product (advanced)')}
            onClick={() => navigate('group-product')}
            description={
              t('Group products by complex criteria') +
              '. ' +
              t(
                '(e.g. 2 Premier Devices at $5/month or 1 Regular and 1 Premier Device at $3/month)'
              )
            }
          />
        </FadeInSlideDown>
      </LineItemsGrid>
    </Layout>
  )
}

export default AddLineItem

const LineItemsGrid = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
