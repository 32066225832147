type AddressFormat = {
  addressLine1: string
  addressLine2?: string
  city: string
  stateProvinceCode: string
  countryCode?: string
  postalCode: string
}

/**
 * formats an address object to a readable string
 * @param address
 * @returns
 */
const formatAddress = (address: AddressFormat): string => {
  return `${address.addressLine1}${
    address.addressLine2 ? ' ' + address.addressLine2 : ''
  } ${address.city}, ${address.stateProvinceCode}${
    address.countryCode ? ' ' + address.countryCode : ''
  } ${address.postalCode}`
}

export default formatAddress
