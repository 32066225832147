import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useDeletePermissionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/api/permissions/{permissionId}'],
    KyError,
    string
  >(deletePermission, {
    onSuccess: (_, permissionId) => {
      const permissions:
        | OrgsAPIResponse['GET']['/api/permissions']
        | undefined = queryCache.getQueryData(['getPermissions'])

      if (permissions) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/permissions'] = {
          ...permissions,
          items: permissions.items?.filter(
            (permission) => permission.id !== permissionId
          ),
        }
        // immediately update the permission list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/permissions']>(
          ['getPermissions'],
          updatedQueryCacheValue
        )
      }

      queryCache.invalidateQueries('getPermissions')
      queryCache.invalidateQueries('getModules')

      success({ message: t('Permission deleted') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useDeletePermissionMutation

const deletePermission = async (permissionId: string) => {
  const result = typedOrgsApi.delete('/api/permissions/{permissionId}', {
    pathParams: { permissionId },
  })

  return result
}
