import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'
import { useAuth, User } from 'oidc-react'

const useUserQuery = () => {
  const auth = useAuth()

  const query = useQuery<
    OrgsAPIResponse['GET']['/api/users/{userId}/accounts'],
    KyError
  >(['getUserInfo', auth?.userData], () => getUserInfo(auth?.userData), {
    enabled: !!auth?.userData || !!window.Cypress,
  })

  return query
}

export default useUserQuery

const getUserInfo = async (user?: User | null) =>
  await typedOrgsApi.get('/api/users/{userId}/accounts', {
    searchParams: { type: 'idp' },
    pathParams: { userId: user?.profile['cognito:username'] },
  })
