import { rest } from 'msw'
import faker from 'faker'
import db from '../../data/db'
import {
  generateAuditable,
  generatePaginatable,
  updateDatabaseArray,
} from '../utils'

const alertDeliveryRulesModuleHandlers = [
  // get alert delivery rules
  rest.get<undefined, GetAlertDeliveryRulesResponse>(
    '/organizations/:orgId/alert-delivery-rules',
    (req, res, ctx) =>
      res(
        ctx.json({
          ...generatePaginatable(),
          items: db.get<AlertDeliveryRule[]>('alertDeliveryRules'),
        })
      )
  ),

  // create alert delivery rule
  rest.post<CreateAlertDeliveryRuleRequest, CreateAlertDeliveryRuleResponse>(
    '/organizations/:orgId/alert-delivery-rules',
    (req, res, ctx) => {
      const alertDeliveryRule = {
        id: faker.random.uuid(),
        organizationId: req.params.orgId,
        ...req.body,
        ...generateAuditable(),
      }

      db.set<AlertDeliveryRule[]>('alertDeliveryRules', [
        ...db.get<AlertDeliveryRule[]>('alertDeliveryRules'),
        alertDeliveryRule,
      ])

      return res(ctx.json(alertDeliveryRule))
    }
  ),

  // update alert delivery rule
  rest.patch<UpdateAlertDeliveryRuleRequest, UpdateAlertDeliveryRuleResponse>(
    '/organizations/:orgId/alert-delivery-rules/:deliveryRuleId',
    (req, res, ctx) => {
      const alertDeliveryRules = db.get<AlertDeliveryRule[]>(
        'alertDeliveryRules'
      )

      const alertDeliveryRuleToUpdate = alertDeliveryRules.find(
        (alertDeliveryRule) =>
          alertDeliveryRule.id === req.params.deliveryRuleId
      )

      if (!alertDeliveryRuleToUpdate)
        throw new Error('Alert Delivery Rule not found')

      // update the db
      db.set<AlertDeliveryRule[]>(
        'alertDeliveryRules',
        updateDatabaseArray<AlertDeliveryRule>({
          array: alertDeliveryRules,
          requests: req.body,
          itemToUpdate: alertDeliveryRuleToUpdate,
          matchBy: 'id',
        })
      )
      return res(ctx.json({ result: true }))
    }
  ),
]

export default alertDeliveryRulesModuleHandlers
