import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, webhooksApi, handleQueryError } from 'utils'

const useDeleteWebhookSubscriptionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    DeleteWebhookSubscriptionResponse,
    KyError,
    deleteWebhookSubscriptionArgs,
    GetWebhookSubscriptionsResponse | undefined
  >(deleteWebhookSubscription, {
    onMutate: ({ subscriptionId, organizationId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<GetWebhookSubscriptionsResponse>(
        ['getWebhookSubscriptions', organizationId]
      )

      // optimistic update
      queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
        ['getWebhookSubscriptions', organizationId],
        (old) => {
          if (!old) return

          const newValue: GetWebhookSubscriptionsResponse = {
            ...old,
            items: old.items.filter((item) => item.id !== subscriptionId),
          }

          return newValue
        }
      )

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Webhook subscription deleted') })
    },
    onError: async (error, { organizationId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
        ['getWebhookSubscriptions', organizationId],
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries([
        'getWebhookSubscriptions',
        args.organizationId,
      ])
      queryCache.invalidateQueries([
        'getWebhookSubscription',
        args.organizationId,
        args.subscriptionId,
      ])
    },
  })

  return mutation
}

export default useDeleteWebhookSubscriptionMutation

type deleteWebhookSubscriptionArgs = {
  organizationId: string
  subscriptionId: string
}

const deleteWebhookSubscription = async ({
  organizationId,
  subscriptionId,
}: deleteWebhookSubscriptionArgs) => {
  const result = await webhooksApi
    .delete(
      `api/organizations/${organizationId}/webhook-subscriptions/${subscriptionId}`
    )
    .json<DeleteWebhookSubscriptionResponse>()

  return result
}
