import React from 'react'
import { LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import { FadeInSlideDown } from 'animations'
import { useECGVitalSignsQuery } from 'hooks/vitals'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import ECGAdminArchivedVitalSignCard from './ECGAdminArchivedVitalSignsCard'

const ECGAdminArchivedVitalSignsGrid = () => {
  const { t } = useTranslation()
  const { isLoading, data: vitalSigns } = useECGVitalSignsQuery()

  const archivedVitalSigns = vitalSigns?.items?.filter(
    (vitalSign) => vitalSign.archiveInfo.archived
  )

  if (isLoading) return <LoadingIcon />

  // If no vitalSigns are returned show empty grid placeholder
  if (!archivedVitalSigns?.length)
    return (
      <ArchivedVitalSignsEmpty
        title={t('No Archived Vital Sign Assignments Found')}
        delay={0.1}
      />
    )

  return (
    <GridContainer>
      {archivedVitalSigns.map((vitalSign, index) => (
        <FadeInSlideDown
          delay={0.1 + index * 0.05}
          key={`vitals-card-${index}`}
        >
          <ECGAdminArchivedVitalSignCard vitalSign={vitalSign} />
        </FadeInSlideDown>
      ))}
    </GridContainer>
  )
}

export default ECGAdminArchivedVitalSignsGrid

const ArchivedVitalSignsEmpty = tw(
  Empty
)`border-2 border-dashed rounded flex-grow`

const GridContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
