import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Box } from '@nivo/core'
import tw from 'twin.macro'
import numeral from 'numeral'
import { Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import LoadingIcon from './LoadingIcon'

type PieChartProps = {
  data: Record<string, unknown>[]
  isLoading?: boolean
  margin?: Box
}

const PieChart = ({ data, margin, isLoading }: PieChartProps) => {
  const { t } = useTranslation()

  if (isLoading) return <LoadingIcon height="230px" />

  if (data.length === 0)
    return (
      <PieChartEmpty
        title="No Data Found"
        description={t('Try changing the date range or filter criteria')}
      />
    )

  return (
    <ResponsivePie
      data={data}
      margin={
        margin || {
          top: 10,
          right: 20,
          // compensate for single label view
          bottom: data.length > 1 ? 30 : 50,
          left: 20,
        }
      }
      colors={{ datum: 'data.color' }}
      enableSliceLabels={false}
      tooltip={(e) => {
        return (
          <Tooltip>
            <TooltipData>
              <TooltipColorIndicator
                style={{ backgroundColor: e.datum.color }}
              />

              <p>
                {e.datum.value}
                &nbsp;({numeral(e.datum.data.percentage).format('0.00%')})
                &nbsp;<TooltipUnit>{e.datum.label}</TooltipUnit>
              </p>
            </TooltipData>
          </Tooltip>
        )
      }}
    />
  )
}

export default PieChart

const Tooltip = tw.div`bg-white p-2 rounded shadow-lg border border-gray-200`

const TooltipData = tw.div`flex items-center`

const TooltipColorIndicator = tw.div`w-4 h-4 mr-2 rounded-full`

const TooltipUnit = tw.span`text-gray-500`

const PieChartEmpty = tw(Empty)`pt-4`
