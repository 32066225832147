import React, { useState } from 'react'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { useSearchParam } from 'react-use'
import _ from 'lodash'
import { ConfirmationDialogs, Icon, LoadingIcon } from 'elements'
import { ChildStepContainer } from 'elements/WizardForm'
import {
  useDeleteResponsiblePartyMutation,
  useResponsiblePartiesQuery,
} from 'hooks/responsible-parties'
import { ResponsiblePartyForm } from 'components/subscribers'
import { formatPhoneNumber } from 'utils'
import { useParams } from 'react-router'

const ResponsibleParties = () => {
  const { t } = useTranslation()

  const createSubscriberId = useSearchParam('subscriberId')
  const { subscriberId: editSubscriberId } = useParams()
  const subscriberId = createSubscriberId || editSubscriberId

  // address variables
  const [
    isResponsiblePartyFormOpen,
    setIsResponsiblePartyFormOpen,
  ] = useState<boolean>(false)
  const [
    responsiblePartyToMutate,
    setResponsiblePartyToMutate,
  ] = useState<ResponsibleParty>()
  const [
    isDeleteResponsiblePartyDialogOpen,
    setIsDeleteResponsiblePartyDialogOpen,
  ] = useState<boolean>(false)

  const responsibleParties = useResponsiblePartiesQuery({ subscriberId })

  const deleteResponsibleParty = useDeleteResponsiblePartyMutation()

  const confirmations = [
    // Delete Responsible Party Dialog
    {
      setIsOpen: setIsDeleteResponsiblePartyDialogOpen,
      isOpen: isDeleteResponsiblePartyDialogOpen,
      title: t(`Delete Responsible Party`),
      content: (
        <p>
          {t(
            'Are you sure you want to permanently delete this responsible party?'
          )}
        </p>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            deleteResponsibleParty.mutate({
              responsiblePartyId: responsiblePartyToMutate?.id || '',
              subscriberId: subscriberId || '',
            })
            setIsDeleteResponsiblePartyDialogOpen(false)
          }}
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]
  if (!responsibleParties.data) return <LoadingIcon />

  return (
    <ChildStepContainer>
      <Header>
        <ResponsiblePartiesTitle>
          {t('Responsible Parties')}
        </ResponsiblePartiesTitle>
        {responsibleParties.data.items?.length ? (
          <Button
            onClick={() => {
              setResponsiblePartyToMutate(undefined)
              setIsResponsiblePartyFormOpen(true)
            }}
          >
            {t('Add Responsible Party')}
          </Button>
        ) : null}
      </Header>
      {responsibleParties.data.items?.length !== 0 ? (
        _.orderBy(responsibleParties.data.items, 'priority').map(
          (responsibleParty) => (
            <ResponsibleParty key={responsibleParty.id}>
              <ResponsiblePartyPriority>
                {responsibleParty.priority}
              </ResponsiblePartyPriority>
              <ResponsiblePartyName>
                {responsibleParty.person.firstName}{' '}
                {responsibleParty.person.lastName}
              </ResponsiblePartyName>
              <ResponsiblePartyAttribute>
                {responsibleParty.relationship}
              </ResponsiblePartyAttribute>
              <ResponsiblePartyAttribute>
                {responsibleParty.person.emailAddress || ''}
              </ResponsiblePartyAttribute>
              <ResponsiblePartyAttribute>
                {responsibleParty.phoneNumbers[0].number
                  ? formatPhoneNumber(responsibleParty.phoneNumbers[0].number)
                  : ''}
              </ResponsiblePartyAttribute>
              <IconContainer>
                <EditIcon
                  type="edit"
                  onClick={() => {
                    setResponsiblePartyToMutate(responsibleParty)
                    setIsResponsiblePartyFormOpen(true)
                  }}
                  buttonClassName="align-middle"
                />
                <TrashIcon
                  type="trash"
                  onClick={() => {
                    setResponsiblePartyToMutate(responsibleParty)
                    setIsDeleteResponsiblePartyDialogOpen(true)
                  }}
                  buttonClassName="align-middle"
                />
              </IconContainer>
            </ResponsibleParty>
          )
        )
      ) : (
        <EmptyContainer>
          <EmptyDisplay
            title={t('No Responsible Parties Exist')}
            callToAction={
              <Button
                onClick={() => {
                  setResponsiblePartyToMutate(undefined)
                  setIsResponsiblePartyFormOpen(true)
                }}
              >
                {t('Add Responsible Party')}
              </Button>
            }
          />
        </EmptyContainer>
      )}
      <ResponsiblePartyForm
        isFormOpen={isResponsiblePartyFormOpen}
        setIsFormOpen={setIsResponsiblePartyFormOpen}
        subscriberId={subscriberId}
        responsiblePartyToMutate={responsiblePartyToMutate}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </ChildStepContainer>
  )
}

export default ResponsibleParties

const Header = tw.div`flex mb-4`

const ResponsiblePartiesTitle = tw.h3`text-xl font-semibold flex-grow`

const ResponsibleParty = tw.div`grid grid-template-columns[1rem 1fr 0.75fr 1.25fr 1fr 2rem] mt-2`

const ResponsiblePartyAttribute = tw.p`text-gray-500 pl-2`

const ResponsiblePartyPriority = tw.p`font-medium text-gray-500`

const ResponsiblePartyName = tw.p`flex-grow`

const IconContainer = tw.div`flex items-center`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600 ml-2`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900`

const EmptyDisplay = tw(Empty)`border-2 border-dashed rounded`

const EmptyContainer = tw.div`flex-grow`
