import React, { useRef, useState } from 'react'
import { Dropdown, DropdownItem, Icon, ConfirmationDialogs } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useArchiveVitalSignMutation,
  useDeleteVitalSignMutation,
} from 'hooks/vitals'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import tw from 'twin.macro'

type ECGAdminVitalSignCardProps = {
  vitalSign: VitalSign
}

const ECGAdminArchivedVitalSignsCard = ({
  vitalSign,
}: ECGAdminVitalSignCardProps) => {
  const { mutate: toggleArchiveVitalSign } = useArchiveVitalSignMutation()
  const { mutate: deleteArchiveVitalSign } = useDeleteVitalSignMutation()
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const dropdownAnchorEl = useRef(null)
  const { t } = useTranslation()

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const restoreVitalSign = async () => {
    // API Call
    toggleArchiveVitalSign({ vitalSign })
    // Reset Popover
    setIsDropdownVisible(false)
  }

  const deleteVitalSign = async () => {
    // API Call
    deleteArchiveVitalSign(vitalSign)
    // Reset Popover
    setIsDropdownVisible(false)
  }

  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${vitalSign.name}" vital sign`),
      content: <p>{t('Are you sure you want to restore this vital sign?')}</p>,
      actions: (
        <Button
          onClick={() => {
            restoreVitalSign()

            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${vitalSign.name}" vital sign`),
      content: <p>{t('Are you sure you want to delete this vital sign?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deleteVitalSign()

            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <ArchivedVitalsSignsContainer>
      <DropDownContainer>
        <DropdownItems>
          <VitalSignName>{vitalSign.name}</VitalSignName>

          {/* vitals.settings.admin as an ECG Admin is required to manage vital signs */}
          {accountPermissions?.data.ecgAdmin.permissions.includes(
            'vitals.settings.admin'
          ) ? (
            <IconContainer ref={dropdownAnchorEl}>
              <EllipsisIcon
                data-testid={`${vitalSign.name}-ellipsis`}
                type="ellipsis"
                onClick={handleDropdownToggle}
              />
              <Dropdown
                visible={isDropdownVisible}
                setVisible={setIsDropdownVisible}
                parentRef={dropdownAnchorEl}
              >
                <DropdownItem onClick={() => setIsRestoreDialogOpen(true)}>
                  {t('Restore')}
                </DropdownItem>
                <DropdownItem onClick={() => setIsDeleteDialogOpen(true)}>
                  {t('Delete')}
                </DropdownItem>
              </Dropdown>
              <ConfirmationDialogs confirmations={confirmations} />
            </IconContainer>
          ) : null}
        </DropdownItems>
        <Chip color="red">{t('Archived')}</Chip>
      </DropDownContainer>
      <EllipsisItem>
        {t('Display Name')}: &nbsp;
        <EllipsisItemName>{vitalSign.displayName}</EllipsisItemName>
      </EllipsisItem>
      <EllipsisItem>
        {t('Units')}:{' '}
        <EllipsisItemName>
          {vitalSign.units.map((unit) => unit.displayName).join(', ')}
        </EllipsisItemName>
      </EllipsisItem>
    </ArchivedVitalsSignsContainer>
  )
}

export default ECGAdminArchivedVitalSignsCard

const ArchivedVitalsSignsContainer = tw.div`bg-white p-5 rounded-lg border border-gray-200`

const DropDownContainer = tw.div`border-b pb-2 mb-2`

const DropdownItems = tw.div`mb-1 flex justify-between align-middle`

const VitalSignName = tw.h3`text-xl`

const IconContainer = tw.div`relative inline-flex self-center`

const EllipsisIcon = tw(Icon)`text-gray-900 w-5 h-5`

const EllipsisItem = tw.p`text-gray-500`

const EllipsisItemName = tw.span`text-gray-800`
