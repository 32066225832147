import React, { useState } from 'react'
import { ConfirmationDialogs, Icon, Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import {
  ContractInfoCard,
  EditContractForm,
  ShippingOptionForm,
} from 'components/contracts'
import {
  useContractQuery,
  useDeleteLineItemMutation,
  useDeleteShippingOptionMutation,
  useContractLineItemsQuery,
} from 'hooks/contracts'
import { useParams } from 'react-router'
import { useSearchParam } from 'react-use'
import tw from 'twin.macro'
import { Button, Empty, Chip } from 'atlas'
import { FadeInSlideDown } from 'animations'
import { useDelay, useNavigate } from 'hooks'
import { getLineItemFields } from 'utils'

const ContractDetails = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const delay = useDelay()

  const { contractId } = useParams()
  const organizationId = useSearchParam('organizationId') || ''

  const [isContractFormOpen, setIsContractFormOpen] = useState<boolean>(false)
  const [isShippingFormOpen, setIsShippingFormOpen] = useState<boolean>(false)

  const [
    isDeleteShippingOptionDialogOpen,
    setIsDeleteShippingOptionDialogOpen,
  ] = useState<boolean>(false)

  const [
    shippingOptionToMutate,
    setShippingOptionToMutate,
  ] = useState<ContractShippingOption>()

  const [
    isDeleteLineItemDialogOpen,
    setIsDeleteLineItemDialogOpen,
  ] = useState<boolean>(false)

  const [lineItemToMutate, setLineItemToMutate] = useState<ContractLineItem>()

  const contractQuery = useContractQuery({
    organizationId,
    contractId,
  })
  const lineItemsQuery = useContractLineItemsQuery({
    organizationId,
    contractId,
  })
  const deleteLineItemMutation = useDeleteLineItemMutation()
  const deleteShippingOptionMutation = useDeleteShippingOptionMutation()

  const confirmations = [
    //Delete Shipping Option Dialog
    {
      setIsOpen: setIsDeleteShippingOptionDialogOpen,
      isOpen: isDeleteShippingOptionDialogOpen,
      title: t('Delete Shipping Option'),
      content: (
        <p>
          {t(
            'Are you sure you want to permanently delete this shipping option?'
          )}
        </p>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            deleteShippingOptionMutation.mutate({
              optionId: shippingOptionToMutate?.id || '',
              contractId,
              organizationId,
            })

            setIsDeleteShippingOptionDialogOpen(false)
          }}
        >
          {t('Delete')}
        </Button>
      ),
    },
    //Delete Line Item Dialog
    {
      setIsOpen: setIsDeleteLineItemDialogOpen,
      isOpen: isDeleteLineItemDialogOpen,
      title: t('Delete Line Item'),
      content: (
        <p>
          {t('Are you sure you want to permanently delete this line item?')}
        </p>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            deleteLineItemMutation.mutate({
              contractId,
              organizationId,
              lineItemId: lineItemToMutate?.id || '',
            })

            setIsDeleteLineItemDialogOpen(false)
          }}
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <Layout
      title={`${t('Contract Details')}`}
      description={
        t('View and edit contract details, line items, and shipping options') +
        '.'
      }
      breadcrumbs={[
        { name: t('Contracts'), route: '../' },
        {
          name:
            contractQuery.data?.descriptionInfo?.title || t('Contract Details'),
        },
      ]}
      controls={[
        {
          label: t('Edit Contract Details'),
          onClick: () => setIsContractFormOpen(true),
        },
      ]}
      isLoading={contractQuery.isLoading}
    >
      <FadeInSlideDown delay={delay()}>
        <ContractInfoCard contract={contractQuery.data} />
      </FadeInSlideDown>
      <Header delay={delay()}>
        <Title>{t('Line Items')}</Title>
        {lineItemsQuery.data?.items?.length ? (
          <Button to="add-line-item">{t('Add Line Item')}</Button>
        ) : null}
      </Header>
      {lineItemsQuery.data?.items?.length ? (
        lineItemsQuery.data.items.map((lineItem, index) => {
          // get the line item fields that correspond to the lineItemType
          const lineItemFields = getLineItemFields<
            | 'primaryProductLineItem'
            | 'deviceOrServiceLineItem'
            | 'productTypeLineItem'
          >(lineItem)
          return (
            <LineItemCard delay={delay()} key={index}>
              <LineItemTitle>
                {lineItemFields?.descriptionInfo?.title}
              </LineItemTitle>
              <LineItemDescription>
                {lineItemFields?.descriptionInfo?.description}
              </LineItemDescription>
              <LineItemMandatoryChip>
                {lineItem.optional ? (
                  <Chip color="gray">{t('Optional')} </Chip>
                ) : (
                  <Chip color="blue"> {t('Mandatory')}</Chip>
                )}
              </LineItemMandatoryChip>
              <LineItemProductFee>
                <LineItemProductFeeTitle>
                  {lineItemFields?.pricing?.productFee?.descriptionInfo
                    ?.title || t('Product Fee')}
                </LineItemProductFeeTitle>
                <LineItemProductFeePrice>
                  {
                    //TODO: replace with formatPrice
                    (lineItemFields?.pricing?.productFee?.price
                      ?.flatOrPercent === 'Percent'
                      ? '%'
                      : '$') +
                      lineItemFields?.pricing?.productFee?.price?.amount +
                      (lineItemFields?.pricing?.productFee?.price?.interval ===
                      'Monthly'
                        ? ' /mo'
                        : lineItemFields?.pricing?.productFee?.price
                            ?.interval === 'Yearly'
                        ? ' /yr'
                        : '')
                  }
                </LineItemProductFeePrice>
              </LineItemProductFee>
              <LineItemActivationFee>
                <LineItemActivationFeeTitle>
                  {lineItemFields?.pricing?.activationFee?.descriptionInfo
                    ?.title || t('Activation Fee')}
                </LineItemActivationFeeTitle>
                <LineItemActivationFeePrice>
                  {
                    //TODO: replace with formatPrice
                    (lineItemFields?.pricing?.activationFee?.price
                      ?.flatOrPercent === 'Percent'
                      ? '%'
                      : '$') +
                      lineItemFields?.pricing?.activationFee?.price?.amount +
                      (lineItemFields?.pricing?.activationFee?.price
                        ?.interval === 'Monthly'
                        ? ' /mo'
                        : lineItemFields?.pricing?.activationFee?.price
                            ?.interval === 'Yearly'
                        ? ' /yr'
                        : '')
                  }
                </LineItemActivationFeePrice>
              </LineItemActivationFee>
              <IconContainer>
                <EditIcon
                  type="edit"
                  onClick={() => {
                    setLineItemToMutate(lineItem)
                    navigate({
                      pathname: 'add-line-item/' + lineItem.lineItemType,
                      searchParams: { lineItemId: lineItem.id as string },
                    })
                  }}
                />
                <TrashIcon
                  type="trash"
                  onClick={() => {
                    setLineItemToMutate(lineItem)
                    setIsDeleteLineItemDialogOpen(true)
                  }}
                />
              </IconContainer>
            </LineItemCard>
          )
        })
      ) : (
        <FadeInSlideDown delay={delay()}>
          <Empty
            title={t('No Line Items Added')}
            description={`${t(
              'No Line Items added for this contract yet'
            )}. ${t('You can add one or more line items here')}.`}
            callToAction={
              <Button to="add-line-item">{t('Add Line Item')}</Button>
            }
          />
        </FadeInSlideDown>
      )}
      <Header delay={delay()}>
        <Title>{t('Shipping Options')}</Title>
        {contractQuery.data?.shippingPrices?.length ? (
          <Button
            className="h-fit-content"
            onClick={() => {
              setShippingOptionToMutate(undefined)
              setIsShippingFormOpen(true)
            }}
          >
            {t('Add Pricing Option')}
          </Button>
        ) : null}
      </Header>
      {contractQuery.data?.shippingPrices?.length ? (
        contractQuery.data.shippingPrices.map((shippingPrice, index) => (
          <ShippingOptionCard delay={delay()} key={index}>
            <ShippingOptionTitle>
              {shippingPrice.priceDescriptor?.descriptionInfo?.title + ': $'}
              <ShippingOptionPrice>
                {shippingPrice.priceDescriptor?.price?.amount}
              </ShippingOptionPrice>
            </ShippingOptionTitle>
            <ShippingOptionDescription>
              {shippingPrice.priceDescriptor?.descriptionInfo?.description}
            </ShippingOptionDescription>
            <IconContainer>
              <EditIcon
                type="edit"
                onClick={() => {
                  setShippingOptionToMutate(shippingPrice)
                  setIsShippingFormOpen(true)
                }}
              />
              <TrashIcon
                type="trash"
                onClick={() => {
                  setShippingOptionToMutate(shippingPrice)
                  setIsDeleteShippingOptionDialogOpen(true)
                }}
              />
            </IconContainer>
          </ShippingOptionCard>
        ))
      ) : (
        <FadeInSlideDown delay={delay()}>
          <Empty
            title={t('Shipping Prices Not Configured')}
            description={`${t(
              'Shipping Prices are not configured for this contract'
            )}. ${t('You can add one or more shipping options here')}.`}
            callToAction={
              <Button
                onClick={() => {
                  setShippingOptionToMutate(undefined)
                  setIsShippingFormOpen(true)
                }}
              >
                {t('Add Pricing Option')}
              </Button>
            }
          />
        </FadeInSlideDown>
      )}
      <EditContractForm
        isFormOpen={isContractFormOpen}
        setIsFormOpen={setIsContractFormOpen}
        contractToEdit={contractQuery.data}
      />
      <ShippingOptionForm
        isFormOpen={isShippingFormOpen}
        setIsFormOpen={setIsShippingFormOpen}
        shippingOptionToEdit={shippingOptionToMutate}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </Layout>
  )
}

export default ContractDetails

const Header = tw(FadeInSlideDown)`flex items-center mb-2 mt-4`

const Title = tw.div`text-2xl font-semibold flex-grow py-1`

const IconContainer = tw.div`flex items-center`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600 ml-2`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900 ml-4`

const ShippingOptionCard = tw(
  FadeInSlideDown
)`bg-white flex items-center w-full my-2 py-2 px-4 border rounded`

const ShippingOptionTitle = tw.div`text-lg flex-grow`

const ShippingOptionPrice = tw.div`font-semibold inline text-lg`

const ShippingOptionDescription = tw.div`text-gray-600 flex-grow truncate px-4`

const LineItemCard = tw(
  FadeInSlideDown
)`bg-white flex items-center w-full my-2 py-2 px-4 border rounded`

const LineItemTitle = tw.div`text-lg flex-grow`

const LineItemDescription = tw.div`text-gray-600 flex-grow truncate px-4`

const LineItemProductFee = tw.div`px-4`

const LineItemProductFeeTitle = tw.div`text-sm text-gray-600 truncate mr-4`

const LineItemProductFeePrice = tw.div``

const LineItemActivationFee = tw.div`px-4`

const LineItemActivationFeeTitle = tw.div`text-sm text-gray-600 truncate mr-4`

const LineItemActivationFeePrice = tw.div``

const LineItemMandatoryChip = tw.div`px-3`
