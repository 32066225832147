import { vitalsApi } from 'utils'
import { useQuery } from 'react-query'

const useECGVitalSignsQuery = (enabled = true) => {
  const query = useQuery<VitalsAPIResponse['GET']['/vital-signs'], KyError>(
    'ECGAdmin_getVitalSigns',
    getECGVitalSigns,
    { enabled }
  )

  return query
}

export default useECGVitalSignsQuery

const getECGVitalSigns = async () => {
  const result = await vitalsApi
    .get('vital-signs')
    .json<VitalsAPIResponse['GET']['/vital-signs']>()

  return result
}
