import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'
import { formatISO } from 'date-fns'

const useSubscriberSeverityReportQuery = ({
  organizationId,
  dateRangeState,
}: {
  organizationId?: string
  dateRangeState?: DateRangeState
}) => {
  const query = useQuery(
    ['getSubscriberSeverityReport', organizationId, dateRangeState],
    () => getSubscriberSeverityReport({ organizationId, dateRangeState }),
    {
      enabled: !!organizationId && !!dateRangeState,
      staleTime: 0,
    }
  )

  return query
}

type getSubscriberSeverityReportArgs = {
  organizationId?: string
  dateRangeState?: DateRangeState
}

const getSubscriberSeverityReport = async ({
  organizationId,
  dateRangeState,
}: getSubscriberSeverityReportArgs) => {
  if (!organizationId || !dateRangeState) return

  const result = await typedVitalsApi
    .get(
      '/organizations/{organizationId}/insights/subscriber-severity-report',
      {
        pathParams: { organizationId },
        searchParams: {
          dateRangeType: dateRangeState.preset,
          ...(dateRangeState.preset === 'custom'
            ? {
                from: formatISO(dateRangeState.value[0]),
                to: formatISO(dateRangeState.value[1]),
              }
            : {}),
        },
      }
    )
    .catch(async (e) => {
      const error = await e.response.json()
      if (error.code === 404) {
        const result: VitalsAPIResponse['GET']['/organizations/{organizationId}/insights/subscriber-severity-report'] = {
          criteria: {
            dateRange: {
              from: dateRangeState.value[0].toString(),
              to: dateRangeState.value[1].toString(),
            },
            severity: 0,
          },
          subscribers: {
            currentPageNumber: 0,
            recordsPerPage: 0,
            totalRecords: 0,
            totalPages: 0,
            items: [],
          },
        }

        return result
      }
      throw e
    })

  return result
}

export default useSubscriberSeverityReportQuery
