import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useDeleteSubscriberVitalThresholdsMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['DELETE']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds'],
    KyError<ErrorResponse>,
    DeleteVitalThresholdsProps,
    | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']
    | undefined
  >(deleteSubscriberVitalThresholds, {
    onMutate: (mutationProps) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']
      >(['vitalsThresholds', mutationProps.subscriberId])

      // optimistic update the query cache
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']['items']
      >(['vitalsThresholds', mutationProps.subscriberId], (oldThresholds) =>
        oldThresholds?.filter(
          (threshold) => threshold.vitalSign.id !== mutationProps.vitalSignId
        )
      )

      return snapshot
    },
    onSuccess: () => {
      // Notify user of successful thresholds creation
      success({
        message: t('Vital thresholds deleted'),
      })
    },
    onError: async (error, { subscriberId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']
        | undefined
      >(['vitalsThresholds', subscriberId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { subscriberId }) => {
      // refetch from API to make sure the new threshold is in sync with the server
      queryCache.invalidateQueries(['vitalsThresholds', subscriberId])
    },
  })

  return mutation
}

export default useDeleteSubscriberVitalThresholdsMutation

type DeleteVitalThresholdsProps = {
  orgId: string
  subscriberId: string
  vitalSignId: string
}

// Post new vital thresholds to backend
const deleteSubscriberVitalThresholds = async ({
  subscriberId,
  vitalSignId,
}: DeleteVitalThresholdsProps) => {
  const result = await vitalsApi
    .delete(`subscribers/${subscriberId}/vital-signs/${vitalSignId}/thresholds`)
    .json<
      VitalsAPIResponse['DELETE']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds']
    >()
  return result
}
