import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useUpdateDisclaimerTypeMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/disclaimer-types/{disclaimerType}'],
    KyError<ErrorResponse>,
    DisclaimerType
  >(updateDisclaimerType, {
    onSuccess: (_, updatedDisclaimerType) => {
      // Notify user of successful disclaimer type update
      success({
        message: t('Disclaimer Type updated'),
      })

      // Immediately add updated disclaimerType to local cache
      queryCache.setQueryData<VitalsAPIResponse['GET']['/disclaimer-types']>(
        'getDisclaimerTypes',
        (oldCache) => ({
          currentPageNumber: 1,
          totalRecords: 1,
          totalPages: 1,
          recordsPerPage: 25,
          ...oldCache,
          items: [
            // Remove old disclaimerType that was updated from oldCache
            ...(oldCache?.items?.filter(
              (disclaimerType) => disclaimerType.id !== updatedDisclaimerType.id
            ) || []),
            updatedDisclaimerType,
          ],
        })
      )

      // Refetch from API to make sure the updated disclaimerType is in sync with the server
      queryCache.invalidateQueries('getDisclaimerTypes')
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useUpdateDisclaimerTypeMutation

// Post updated disclaimer type to backend
const updateDisclaimerType = async (disclaimerType: DisclaimerType) => {
  const request: VitalsAPIRequest['PATCH']['/disclaimer-types/{disclaimerType}']['body'] = {
    name: disclaimerType.name,
    code: disclaimerType.code,
  }
  const result = await vitalsApi
    .patch(`disclaimer-types/${disclaimerType.id}`, {
      json: request,
    })
    .json<VitalsAPIResponse['PATCH']['/disclaimer-types/{disclaimerType}']>()
  return result
}
