import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { formatISO } from 'date-fns'
import { useQuery } from 'react-query'
import { isPresent, typedVitalsApi } from 'utils'

// total records never goes above page length, https://github.com/electronic-caregiver/svcs-api-vitals/issues/617
const PAGE_LENGTH = 9999

const useVitalsReadingsQuery = (filters: VitalReadingFilters) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<
    Extract<
      VitalsAPIResponse['GET']['/vital-readings-grouped'],
      { items?: VitalReadingGrouped[] }
    >,
    KyError
  >(
    ['getVitalsReadings', filters, assumedOrganizationRole?.orgID],
    () => getVitalsReadings(filters, assumedOrganizationRole?.orgID),
    {
      keepPreviousData: true,
      enabled: isPresent(filters) || isPresent(assumedOrganizationRole?.orgID),
    }
  )

  return query
}

export default useVitalsReadingsQuery

const getVitalsReadings = async (
  {
    search,
    subscriberIds,
    dateRangeState,
    vitalSignIds,
    offset = 0,
    orderBy,
  }: VitalReadingFilters,
  organizationId: string | undefined
) =>
  typedVitalsApi.get('/vital-readings-grouped', {
    searchParams: {
      order_by: orderBy,
      take: PAGE_LENGTH,
      skip: offset,
      dateRangeType: dateRangeState.preset,
      ...(dateRangeState.preset === 'custom'
        ? {
            from: formatISO(dateRangeState.value[0]),
            to: formatISO(dateRangeState.value[1]),
          }
        : {}),
      vitalSignIds,
      subscriberIds,
      search,
      organizationId,
    },
  }) as Extract<
    VitalsAPIResponse['GET']['/vital-readings-grouped'],
    { items?: VitalReadingGrouped[] }
  >
