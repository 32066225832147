import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useAddPolicyGroupMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/policy-groups'],
    KyError,
    AddPolicyGroupProps
  >(addPolicyGroup, {
    onSuccess: (policyGroup) => {
      const policyGroups:
        | OrgsAPIResponse['GET']['/api/policy-groups']
        | undefined = queryCache.getQueryData(['getPolicyGroups'])

      if (policyGroups) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/policy-groups'] = {
          ...policyGroups,
          items: [policyGroup, ...(policyGroups.items || [])],
        }
        // immediately update the policyGroup list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/policy-groups']>(
          ['getPolicyGroups'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the policyGroup list is in sync with the server
      queryCache.invalidateQueries('getPolicyGroups')

      // notify the user
      success({ message: t('Policy Group created') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useAddPolicyGroupMutation

type AddPolicyGroupProps = CreatePolicyGroupForm

const addPolicyGroup = async (policyGroupData: AddPolicyGroupProps) => {
  // POST new policyGroup
  const createPolicyGroupReq: OrgsAPIRequest['POST']['/api/policy-groups']['body'] = {
    title: policyGroupData.title,
    code: policyGroupData.code,
    ...(policyGroupData.description
      ? { description: policyGroupData.description }
      : {}),
    policies: policyGroupData.policies,
  }

  const createPolicyGroupResult = typedOrgsApi.post('/api/policy-groups', {
    body: createPolicyGroupReq,
  })

  return createPolicyGroupResult
}
