import { eachDayOfInterval, eachMonthOfInterval } from 'date-fns'
import _ from 'lodash'

/**
 * Takes an array of dates and groups them based off the date range to optimize for line charts
 * @param original - The original array of dates
 * @returns An array of dates optimized for line charts
 */
type customLineChartDataToDatesArray = (original: Array<Date>) => Array<Date>

const customLineChartDataToDatesArray: customLineChartDataToDatesArray = (
  data
) => {
  const minDate = _.minBy(data, (date) => new Date(date).getTime())
  const maxDate = _.maxBy(data, (date) => new Date(date).getTime())

  const intervalInDays = eachDayOfInterval({
    start: minDate || new Date(),
    end: maxDate || new Date(),
  })

  const intervalInMonths = eachMonthOfInterval({
    start: minDate || new Date(),
    end: maxDate || new Date(),
  })

  // every day
  if (intervalInDays.length < 13) return intervalInDays

  // every other day
  if (intervalInDays.length < 26)
    return intervalInDays.filter((_, index) => !(index % 2))

  // every 3 days
  if (intervalInDays.length < 39)
    return intervalInDays.filter((_, index) => !(index % 3))

  // every 4 days
  if (intervalInDays.length < 52)
    return intervalInDays.filter((_, index) => !(index % 4))

  // every 5 days
  if (intervalInDays.length < 65)
    return intervalInDays.filter((_, index) => !(index % 5))

  // every 6 days
  if (intervalInDays.length < 78)
    return intervalInDays.filter((_, index) => !(index % 6))

  // every week
  if (intervalInDays.length < 91)
    return intervalInDays.filter((_, index) => !(index % 7))

  // twice a month (roughly)
  if (intervalInMonths.length < 6)
    return intervalInDays.filter((_, index) => !(index % 15))

  // every month
  return intervalInMonths
}

export default customLineChartDataToDatesArray
