import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { OrdersList } from 'components/orders'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const Orders = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  return (
    <Layout
      title={t('Orders')}
      description={
        assumedOrganizationRole
          ? t('Create and organize orders for this organization')
          : t('Create and organize private-pay and organization orders')
      }
      breadcrumbs={[{ name: t('Orders') }]}
      controls={[
        {
          label: t('Create Order'),
          onClick: () => navigate('create-order'),
        },
        {
          label: t('View Audit Trail'),
          onClick: () => navigate('audit-trail'),
          // TODO: enable this audit trail when Orders API has audit trails
          disabled: true,
        },
      ]}
    >
      <OrdersList />
    </Layout>
  )
}

export default Orders
