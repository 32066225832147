import React from 'react'
import { EntityLabel, CustomSelect } from 'elements'
import { useNavigate } from 'hooks'
import { useAdminListQuery } from 'hooks/admins'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AdminSelect = () => {
  const { adminId } = useParams()
  const adminList = useAdminListQuery()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const currentAdmin = adminList.data?.items.find(
    (staffMember) => staffMember.id === adminId
  )

  const adminSelect = adminList.data?.items.map((ad) => {
    return {
      value: ad.id,
      label: (
        <>
          <EntityLabel
            name={ad.person.firstName + ' ' + ad.person.lastName}
            id={ad.id}
            iconOnly
            size="sm"
          />
          {ad.person.firstName + ' ' + ad.person.lastName}
        </>
      ),
      searchValue: `${ad.person.firstName} ${ad.person.lastName}`,
    }
  })
  if (!currentAdmin) return null

  return (
    <CustomSelect
      className="w-64"
      placeholder={
        currentAdmin.person.firstName + ' ' + currentAdmin.person.lastName
      }
      options={adminSelect || [{ label: '', value: '' }]}
      id={adminId}
      onChange={(e) => navigate(pathname.replace(adminId, e.value))}
      variant="thin"
      searchable
      emptyText={t('No admins found')}
    />
  )
}

export default AdminSelect
