import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError, isPresent } from 'utils'
import { useTranslation } from 'react-i18next'

const useUpdatePolicyMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/policies/{policyId}'],
    KyError,
    EditPolicyProps
  >(updatePolicy, {
    onSuccess: (returnedPolicy) => {
      const policies:
        | OrgsAPIResponse['GET']['/api/policies']
        | undefined = queryCache.getQueryData(['getPolicies'])

      if (policies) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/policies'] = {
          ...policies,
          items:
            policies.items?.map((policy) => {
              if (policy.id === returnedPolicy.id) {
                const updatedPolicy: Policy = {
                  ...returnedPolicy,
                  permissions: policy.permissions && {
                    ...policy.permissions,
                  },
                }
                return updatedPolicy
              }

              return policy
            }) || [],
        }
        // immediately update the policy list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/policies']>(
          ['getPolicies'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the policy list is in sync with the server
      queryCache.invalidateQueries('getPolicies')

      // notify the user
      success({ message: t('Policy updated') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useUpdatePolicyMutation

type EditPolicyProps = {
  existingPolicy: Policy
  policyData: CreatePolicyForm
}

const updatePolicy = async ({
  existingPolicy,
  policyData,
}: EditPolicyProps) => {
  const patchPolicyReq: OrgsAPIRequest['PATCH']['/api/policies/{policyId}']['body'] = Object.entries(
    policyData
  )
    .filter((field): field is [string, string | string[]] =>
      isPresent(field[1])
    )
    .map(([fieldKey, fieldValue]) => ({
      value: fieldValue,
      path: `/${fieldKey}`,
      op: 'replace',
    }))

  return typedOrgsApi.patch('/api/policies/{policyId}', {
    pathParams: { policyId: existingPolicy.id },
    body: patchPolicyReq,
  })
}
