import { useMutation } from 'hooks'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useCreateSubscriberMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/subscribers-profile/{subscriberId}'],
    CreateSubscriberProps
  >({
    mutationFn: createSubscriber,
    successMsg: t('Subscriber created'),
    // To optimistically update this would require mapping a subscribers-profile type to an subscriber display type
    additionalCachesToInvalidate: ['getSubscribers'],
  })

  return mutation
}

export default useCreateSubscriberMutation

type CreateSubscriberProps = SubscriberForm & {
  firstName: string
  lastName: string
  nonPERS: boolean
}

const createSubscriber = async (subscriberForm: CreateSubscriberProps) => {
  const subscriber = await typedOrgsApi.post('/api/subscribers', {
    body: {
      organizationId: subscriberForm.organizationId,
      person: {
        firstName: subscriberForm.firstName,
        lastName: subscriberForm.lastName,
      },
      nonPERS: subscriberForm.nonPERS,
      status: 1,
      primary: true,
    },
  })

  return typedOrgsApi.post('/subscribers-profile/{subscriberId}', {
    pathParams: { subscriberId: subscriber.id },
    body: subscriberForm,
  })
}
