import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

const useStaffListQuery = (orgId?: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members'],
    KyError
  >(['getStaffList', orgId], () => getStaffList(orgId), { enabled: !!orgId })

  return query
}

export default useStaffListQuery

// get list of staff the current role has access to
const getStaffList = async (orgId?: string) =>
  typedOrgsApi.get('/api/organizations/{organizationId}/staff-members', {
    pathParams: { organizationId: orgId || '' },
    searchParams: [
      ['include', 'organization'],
      ['order_by', 'ASC'],
      ['take', 10000],
    ],
  })
