/**
 * type assertion that narrows down the given value to a specific Vital Sign
 * @param str
 * @returns a boolean for if the given string is an existing literal
 */
const isVitalSignLabel = (str?: string): str is VitalSignLabel => {
  return (
    str === 'Heart Rate' ||
    str === 'Blood Pressure' ||
    str === 'Spirometer PEF' ||
    str === 'Spirometer FEV1' ||
    str === 'Weight' ||
    str === 'Glucose' ||
    str === 'Temperature' ||
    str === 'Oxygen'
  )
}

export default isVitalSignLabel
