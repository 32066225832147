import { rest } from 'msw'
import faker from 'faker'
import {
  generateAuditable,
  generateArchivable,
  generateDisableable,
  generateParty,
  generatePaginatable,
} from '../utils'
import db from '../../data/db'

const organizationsModuleHandlers = [
  // get organization list
  rest.get<undefined, OrgsAPIResponse['GET']['/api/organizations']>(
    '/api/organizations',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Organization[]>('organizationList'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // get organization
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}']
  >('/api/organizations/:id', (req, res, ctx) => {
    const organization = db
      .get<Organization[]>('organizationList')
      .find((org) => org.id === req.params.id)

    // if organization not found return 400
    if (!organization) return res(ctx.status(400, 'Organization not found'))

    return res(ctx.json(organization))
  }),

  // get organization hierarchy
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/hierarchy']
  >('/api/organizations/:organizationId/hierarchy', (req, res, ctx) =>
    res(
      ctx.json({
        items: db.get<OrganizationHierarchyItem[]>('organizationHierarchy'),
        ...generatePaginatable(),
      })
    )
  ),

  // add organization
  rest.post<
    OrgsAPIRequest['POST']['/api/organizations']['body'],
    OrgsAPIResponse['POST']['/api/organizations']
  >('/api/organizations', (req, res, ctx) => {
    const organizations = db.get<Organization[]>('organizationList')

    const response = {
      id: faker.random.uuid(),
      status: 'Inactive',
      hcoXrefId: '',
      ...req.body,
      nodes: [],
      rrmsGroupNumber: 1,
      party: generateParty(),
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
    }

    // if a parentId is supplied update childOrganizations of parent
    const updatedOrganizations: Organization[] = organizations.map((org) =>
      org.id === req.body.parentId
        ? {
            ...org,
            childOrganizations: [
              ...(org.childOrganizations ? org.childOrganizations : []),
              response,
            ],
          }
        : org
    )

    // update the db
    db.set<Organization[]>('organizationList', [
      ...updatedOrganizations,
      response,
    ])

    return res(ctx.json(response))
  }),

  // get organization hierarchy
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/hierarchy']
  >('/api/organizations/:orgId/hierarchy', (req, res, ctx) =>
    res(
      ctx.json(
        db.get<
          OrgsAPIResponse['GET']['/api/organizations/{organizationId}/hierarchy']
        >('organizationHierarchy')
      )
    )
  ),

  // activate organization
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/activate']
  >('/api/organizations/:orgId/activate', (req, res, ctx) => {
    db.set<Organization[]>(
      'organizationList',
      db.get<Organization[]>('organizationList').map((org) =>
        org.id === req.params.orgId
          ? {
              ...org,
              status: 'Active',
            }
          : org
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // deactivate organization
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/deactivate']
  >('/api/organizations/:orgId/deactivate', (req, res, ctx) => {
    db.set<Organization[]>(
      'organizationList',
      db.get<Organization[]>('organizationList').map((org) =>
        org.id === req.params.orgId
          ? {
              ...org,
              status: 'Inactive',
            }
          : org
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // archive organization
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/archive']
  >('/api/organizations/:orgId/archive', (req, res, ctx) => {
    db.set<Organization[]>(
      'organizationList',
      db.get<Organization[]>('organizationList').map((org) =>
        org.id === req.params.orgId
          ? {
              ...org,
              status: 'Archived',
            }
          : org
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // restore organization
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/restore']
  >('/api/organizations/:orgId/restore', (req, res, ctx) => {
    db.set<Organization[]>(
      'organizationList',
      db.get<Organization[]>('organizationList').map((org) =>
        org.id === req.params.orgId
          ? {
              ...org,
              status: 'Active',
            }
          : org
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // delete organization
  rest.delete<
    undefined,
    OrgsAPIResponse['DELETE']['/api/organizations/{organizationId}']
  >('/api/organizations/:orgId', (req, res, ctx) => {
    db.set<Organization[]>(
      'organizationList',
      db
        .get<Organization[]>('organizationList')
        .filter((org) => org.id !== req.params.orgId)
    )

    return res(ctx.json({ value: true }))
  }),

  // update organization
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/organizations/{organizationId}']['body'],
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}']
  >('/api/organizations/:orgId', (req, res, ctx) => {
    const businessName = req.body.find((item) => item.path === '/businessName')
      ?.value

    const displayName = req.body.find((item) => item.path === '/displayName')
      ?.value

    // update the organization
    const updatedOrganizations = db
      .get<Organization[]>('organizationList')
      .map((org) => {
        if (org.id === req.params.orgId)
          return { ...org, businessName, displayName } as Organization

        return org
      })

    // update the db
    db.set<Organization[]>('organizationList', updatedOrganizations)

    const updatedOrganization = updatedOrganizations.find(
      (org) => org.id === req.params.orgId
    )

    if (!updatedOrganization)
      throw new Error('No organization exists for this update')

    return res(ctx.json(updatedOrganization))
  }),

  // create organization metadata
  rest.post<
    OrgsAPIRequest['POST']['/api/organizations/{organizationId}/metadata']['body'],
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/metadata']
  >('/api/organizations/:orgId/metadata', (req, res, ctx) => {
    const updatedOrganizations = db
      .get<Organization[]>('organizationList')
      .map((org) =>
        org.id === req.params.orgId
          ? // update the organization
            {
              ...org,
              integrationMetadata: Object.fromEntries(
                req.body.map(({ key, value }) => [key, value])
              ),
            }
          : org
      )

    // update the db
    db.set<Organization[]>('organizationList', updatedOrganizations)

    const updatedOrganization = updatedOrganizations.find(
      (org) => org.id === req.params.orgId
    )

    if (!updatedOrganization)
      throw new Error('No organization exists for this update')

    return res(ctx.json(updatedOrganization))
  }),

  // update organization metadata
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/organizations/{organizationId}/metadata']['body'],
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}/metadata']
  >('/api/organizations/:orgId/metadata', (req, res, ctx) => {
    // update the organization
    const updatedOrganizations = db
      .get<Organization[]>('organizationList')
      .map((org) =>
        org.id === req.params.orgId
          ? {
              ...org,
              integrationMetadata: {
                ...(org.integrationMetadata || {}),
                ...Object.fromEntries(
                  req.body.map(({ path, value }) => [path.slice(1), value])
                ),
              },
            }
          : org
      )

    // update the db
    db.set<Organization[]>('organizationList', updatedOrganizations)

    const updatedOrganization = updatedOrganizations.find(
      (org) => org.id === req.params.orgId
    )

    if (!updatedOrganization)
      throw new Error('No organization exists for this update')

    return res(ctx.json(updatedOrganization))
  }),
]

export default organizationsModuleHandlers
