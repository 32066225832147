import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const usePolicyGroupQuery = () => {
  const query = useQuery<OrgsAPIResponse['GET']['/api/policy-groups'], KyError>(
    'getPolicyGroups',
    getPolicyGroups
  )

  return query
}

export default usePolicyGroupQuery

const getPolicyGroups = async () =>
  typedOrgsApi.get('/api/policy-groups', {
    searchParams: [
      ['include', 'policies'],
      ['order_by', 'ASC'],
      ['take', 10000],
    ],
  })
