import React, { useRef } from 'react'
import tw from 'twin.macro'
import { useSize } from 'react-use'
import { Tooltip } from 'atlas'

type TextProps = {
  children: string
  truncate?: boolean
  className?: string
}

const Text = ({ children, truncate = true, className }: TextProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [sizedElement] = useSize(({ width }) => (
    <Container className={className} ref={containerRef}>
      {truncate && containerRef.current ? (
        <TruncatedText
          width={width}
          font={window.getComputedStyle(containerRef.current).font}
        >
          {children}
        </TruncatedText>
      ) : (
        children
      )}
    </Container>
  ))

  return sizedElement
}

export default Text

const Container = tw.div``

const TruncatedText = ({
  children: text,
  width,
  font,
}: {
  children: string
  width: number
  font: string
}) => {
  // to measure width that the text requires; create a canvas, insert the text content and font, and call measureText()
  // this method works independent of font size, style, weight, etc
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (context) {
    context.font = font

    const { width: textWidth } = context.measureText(text)

    return textWidth > width ? (
      <Tooltip content={text}>
        {text.slice(
          0,
          Math.max(
            Math.floor(
              text.length *
                // proportion of 'chars to show' / 'text.length' should equal 'available width' / 'needed width'
                (width / (textWidth + 30)) - // the + 30 leaves a width buffer to avoid momentary overflows onto a new line
                // subtract 3 characters to leave room for the ellipsis being added
                3
            ),
            // leave a minimum of 2 characters
            2
          )
        ) + '...'}
      </Tooltip>
    ) : (
      <>{text}</>
    )
  } else {
    return <>{text}</>
  }
}
