import React, { useState, useEffect } from 'react'
import {
  RightPopup,
  TextField,
  Select,
  SelectItem,
  TimePicker,
  Icon,
} from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import FrequencyOfReminderPreset from './FrequencyOfReminderPreset'
import {
  useCreateReminderPresetMutation,
  useUpdateReminderPresetMutation,
} from 'hooks/reminder-presets'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import DayOfTheWeekToggleGroup from './DayOfTheWeekToggleGroup'
import { parse, format } from 'date-fns'
import tw from 'twin.macro'

type CreateEditReminderPresetFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  currentReminderPreset?: ReminderPreset
}

const CreateEditReminderPresetForm = ({
  isFormOpen,
  setIsFormOpen,
  currentReminderPreset,
}: CreateEditReminderPresetFormProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const formMethods = useForm<ReminderPreset>()
  const { handleSubmit, errors, register, watch, reset } = formMethods

  const [timePicker, setTimePicker] = useState<Date | null>(new Date())
  const [toggled, setToggled] = useState<Weekdays[]>(['mon'])

  const createReminderPreset = useCreateReminderPresetMutation()
  const updateReminderPreset = useUpdateReminderPresetMutation()

  // reset frequency object when form is opened
  useEffect(() => {
    if (isFormOpen) {
      setToggled(currentReminderPreset?.schedule.weekdays || ['mon'])
      reset({
        schedule: { frequency: currentReminderPreset?.schedule.frequency },
      })
    }
  }, [isFormOpen])

  return (
    <RightPopup
      title={
        !currentReminderPreset
          ? t('Create Reminder Preset')
          : t('Edit Reminder Preset')
      }
      open={isFormOpen}
      setOpen={setIsFormOpen}
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={handleSubmit(async (formData) => {
              // Close form drawer
              if (!currentReminderPreset) {
                createReminderPreset.mutate({
                  orgId: assumedOrganizationRole?.orgID || '',

                  schedule: {
                    frequency: formData.schedule.frequency,
                    interval: formData.schedule.interval,
                    time: format(
                      new Date(formData.schedule.time || ''),
                      'HH:mm'
                    ),
                    weekdays: formData.schedule.weekdays,
                    daysOfMonth: formData.schedule.daysOfMonth,
                    months: formData.schedule.months,
                  },
                  preset: {
                    name: formData.name,
                    description: formData.description,
                  },
                })
              } else {
                updateReminderPreset.mutate({
                  orgId: assumedOrganizationRole?.orgID || '',
                  schedule: {
                    frequency: formData.schedule.frequency,
                    interval: formData.schedule.interval,
                    time: format(
                      new Date(formData.schedule.time || ''),
                      'HH:mm'
                    ),
                    weekdays: formData.schedule.weekdays,
                    daysOfMonth: formData.schedule.daysOfMonth,
                    months: formData.schedule.months,
                  },
                  preset: {
                    id: currentReminderPreset.id,
                    name: formData.name,
                    description: formData.description,
                  },
                })
              }
              return setIsFormOpen(false)
            })}
          >
            {!currentReminderPreset ? t('Create') : t('Update')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <TextField
            name="name"
            error={errors.name?.message}
            inputRef={register({ required: 'Name is required' })}
            defaultValue={
              !currentReminderPreset ? '' : currentReminderPreset.name
            }
            label={t('Preset Name')}
            fullWidth
            required
          />
          <TextField
            name="description"
            error={errors.description?.message}
            inputRef={register}
            defaultValue={
              !currentReminderPreset ? '' : currentReminderPreset.description
            }
            label={t('Description for preset')}
            fullWidth
          />
          <FrequencyContainer>
            <Controller
              render={({ onChange }) => (
                <Select
                  defaultValue={
                    !currentReminderPreset
                      ? 'daily'
                      : currentReminderPreset.schedule.frequency
                  }
                  name="schedule.frequency"
                  label={t('Reminder Frequency')}
                  onChange={onChange}
                >
                  <SelectItem value="daily">{t('Daily')}</SelectItem>
                  <SelectItem value="weekly">{t('Weekly')}</SelectItem>
                </Select>
              )}
              name="schedule.frequency"
              defaultValue={
                !currentReminderPreset
                  ? 'daily'
                  : currentReminderPreset.schedule.frequency
              }
            />
          </FrequencyContainer>

          <div>
            <Controller
              as={
                <TimePicker
                  name="schedule.time"
                  value={timePicker}
                  onChange={setTimePicker}
                  label={t('Reminder Time')}
                />
              }
              name="schedule.time"
              defaultValue={
                !currentReminderPreset
                  ? new Date()
                  : parse(
                      currentReminderPreset.schedule.time || '',
                      'HH:mm',
                      new Date()
                    )
              }
            />
          </div>
          <InfoIcon>
            <Icon type="info" />
            &nbsp;
            {t(
              'Reminder time should be set relative to the subscribers timezone'
            )}
          </InfoIcon>

          {watch('schedule.frequency') !== 'weekly' ? (
            <FrequencySelect>
              <Controller
                render={({ onChange, name }) => (
                  <FrequencyOfReminderPreset
                    label={t('Repeat Every')}
                    name={name}
                    defaultValue={
                      !currentReminderPreset?.schedule.interval
                        ? 1
                        : currentReminderPreset.schedule.interval
                    }
                    frequency={watch('schedule.frequency')}
                    onChange={onChange}
                  />
                )}
                name="schedule.interval"
                defaultValue={
                  !currentReminderPreset
                    ? 1
                    : currentReminderPreset.schedule.interval
                }
              />
            </FrequencySelect>
          ) : null}
          {watch('schedule.frequency') === 'weekly' ? (
            <Controller
              render={({ onChange }) => (
                <DayOfTheWeekToggleGroup
                  label={t('Repeat Every')}
                  name="schedule.weekdays"
                  onChange={onChange}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
              name="schedule.weekdays"
              defaultValue={currentReminderPreset?.schedule.weekdays || ['mon']}
            />
          ) : null}
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default CreateEditReminderPresetForm

const FrequencyContainer = tw.div`mb-4`

const InfoIcon = tw.span`inline-flex items-center whitespace-nowrap text-xs mb-4 opacity-75`

const FrequencySelect = tw.div`flex flex-col w-full mb-4`
