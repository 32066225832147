import {
  useQueryClient,
  useMutation as useReactQueryMutation,
  Query,
  QueryKey,
  QueryClient,
  QueryCache,
} from 'react-query'
import { handleQueryError, success } from 'utils'
import _ from 'lodash'

type MutationProps<ResType, ArgsType> = {
  mutationFn: (args: ArgsType) => Promise<ResType>
  successMsg?:
    | string
    | { message: string; showOn: 'onMutate' | 'onSuccess' | 'onSettled' }
  additionalCachesToInvalidate?: Array<
    string | unknown[] | ((args: ArgsType) => string | unknown[])
  >
  optimisticUpdates?: Array<OptimisticUpdate<ArgsType>>
}

const useMutation = <ResType, ArgsType>({
  mutationFn,
  successMsg,
  additionalCachesToInvalidate,
  optimisticUpdates,
}: MutationProps<ResType, ArgsType>) => {
  const queryClient = useQueryClient()

  const cache = queryClient.getQueryCache()

  const mutation = useReactQueryMutation<
    ResType,
    KyError,
    ArgsType,
    Array<unknown | undefined | Array<unknown | undefined>>
  >(mutationFn, {
    onMutate: (args) => {
      // display successMsg if one is passed
      if (
        successMsg &&
        typeof successMsg !== 'string' &&
        successMsg.showOn !== 'onSuccess' &&
        successMsg.showOn !== 'onSettled'
      )
        success({ message: successMsg.message })

      if (typeof successMsg === 'string') success({ message: successMsg })

      // get snapshots for each optimistic update
      const snapshots =
        optimisticUpdates?.map((ou) => {
          // if multiple cacheKeys are passed get the snapshot for each
          if (isMultipleCacheKeys(ou))
            return ou.cacheKeys.map((cacheKey) =>
              queryClient.getQueryData(resolveCacheKey(cacheKey, args))
            )

          // if a cacheKey predicate fn is passed get the snapshot for each match
          if (isCacheKeyFilter(ou))
            return getMatchedCaches(
              ou.cacheKeyFilter,
              args,
              cache
            ).map(({ queryKey }) =>
              queryClient.getQueryData(resolveCacheKey(queryKey, args))
            )

          // if a single cache key is provided
          return queryClient.getQueryData(resolveCacheKey(ou.cacheKey, args))
        }) || []

      executeOptimisticUpdates(
        args,
        optimisticUpdates?.filter(
          // execute optimistic updates flagged for onMutate or if updateOn is not specified
          (ou) => ou.updateOn === 'onMutate' || !ou.updateOn
        ) || [],
        queryClient
      )

      return snapshots
    },
    onSuccess: (_, args) => {
      if (
        successMsg &&
        typeof successMsg !== 'string' &&
        successMsg.showOn !== 'onMutate' &&
        successMsg.showOn !== 'onSettled'
      ) {
        success({ message: successMsg.message || '' })
      }

      executeOptimisticUpdates(
        args,
        optimisticUpdates?.filter(
          // execute optimistic updates flagged for onSuccess
          (ou) => ou.updateOn === 'onSuccess'
        ) || [],
        queryClient
      )
    },
    onError: async (error, args, mutationReturn) => {
      // revert to snapshots
      optimisticUpdates?.forEach((ou, ouIndex) => {
        // if multiple cacheKeys are passed revert to snapshot for each key
        if (isMultipleCacheKeys(ou))
          return ou.cacheKeys.map((cacheKey, cacheIndex) => {
            const cacheSnapshots = mutationReturn?.[ouIndex]
            Array.isArray(cacheSnapshots) &&
              queryClient.setQueryData(
                resolveCacheKey(cacheKey, args),
                cacheSnapshots[cacheIndex]
              )
          })

        // if a cacheKey predicate fn is passed revert to snapshot for each match
        if (isCacheKeyFilter(ou))
          return getMatchedCaches(ou.cacheKeyFilter, args, cache).map(
            ({ queryKey }: Query, keyMatchIndex: number) => {
              const cacheSnapshots = mutationReturn?.[ouIndex]
              Array.isArray(cacheSnapshots) &&
                queryClient.setQueryData(
                  resolveCacheKey(queryKey, args),
                  cacheSnapshots[keyMatchIndex]
                )
            }
          )

        // if a single cache key is provided
        queryClient.setQueryData(
          resolveCacheKey(ou.cacheKey, args),
          mutationReturn?.[ouIndex]
        )
      })

      // pass the error to the standard API error-handling
      handleQueryError({ error })
    },
    onSettled: (__, ___, args) => {
      if (
        successMsg &&
        typeof successMsg !== 'string' &&
        successMsg.showOn !== 'onSuccess' &&
        successMsg.showOn !== 'onMutate'
      ) {
        success({ message: successMsg.message || '' })
      }

      executeOptimisticUpdates(
        args,
        optimisticUpdates?.filter(
          // execute optimistic updates flagged for onSettled
          (ou) => ou.updateOn === 'onSettled'
        ) || [],
        queryClient
      )

      // invalidate the query caches
      ;[
        ..._.flatten<CacheKey<ArgsType>>(
          optimisticUpdates?.map((ou) => {
            if (isMultipleCacheKeys(ou))
              return ou.cacheKeys.map((cacheKey) =>
                resolveCacheKey(cacheKey, args)
              )

            if (isCacheKeyFilter(ou))
              return getMatchedCaches(
                ou.cacheKeyFilter,
                args,
                cache
              ).map(({ queryKey }) => resolveCacheKey(queryKey, args))

            return resolveCacheKey(ou.cacheKey, args)
          })
        ),
        ...(additionalCachesToInvalidate || []),
      ].forEach((cacheKey) =>
        // background fetch to sync new data with the server
        queryClient.invalidateQueries(resolveCacheKey(cacheKey, args))
      )
    },
  })

  return mutation
}

export default useMutation

// this allows parameters to be typed when creating updateFns in the mutations
export const updateFnConstructor = <CacheType, ArgsType>(
  updateFn: (
    oldCache: CacheType | undefined,
    Args: ArgsType,
    queryKey: QueryKey
  ) => CacheType | undefined
) => updateFn

// this allows parameters to be typed when creating filterFns in the mutations
export const filterFnConstructor = <CacheType, ArgsType>(
  filterFn: (query: Query<CacheType>, args: ArgsType) => boolean
) => filterFn

// util function that executes the updateFn's of a given array of optimistic updates
const executeOptimisticUpdates = <ArgsType>(
  mutationArgs: ArgsType,
  optimisticUpdates: Array<OptimisticUpdate<ArgsType>>,
  queryClient: QueryClient
) =>
  // execute the optimistic updates
  optimisticUpdates.forEach((ou) => {
    // if multiple cacheKeys are passed execute updateFn on each key
    if (isMultipleCacheKeys(ou))
      return ou.cacheKeys.map((cacheKey) =>
        queryClient.setQueryData(
          resolveCacheKey(cacheKey, mutationArgs),
          (oldCache) =>
            ou.updateFn(
              oldCache,
              mutationArgs,
              resolveCacheKey(cacheKey, mutationArgs)
            )
        )
      )

    // if a cacheKey predicate fn is passed execute updateFn for each match
    if (isCacheKeyFilter(ou))
      return getMatchedCaches(
        ou.cacheKeyFilter,
        mutationArgs,
        queryClient.getQueryCache()
      ).map(({ queryKey }) =>
        queryClient.setQueryData(queryKey, (oldCache) =>
          ou.updateFn(oldCache, mutationArgs, queryKey)
        )
      )

    // if a single cache key is provided
    queryClient.setQueryData(
      resolveCacheKey(ou.cacheKey, mutationArgs),
      (oldCache) =>
        ou.updateFn(
          oldCache,
          mutationArgs,
          resolveCacheKey(ou.cacheKey, mutationArgs)
        )
    )
  })

// util function to check if cache key is a function or not
const resolveCacheKey = <ArgsType>(
  cacheKey: CacheKey<ArgsType>,
  args: ArgsType
): string | readonly unknown[] =>
  // if the cacheKey is a function pass mutation args into it
  cacheKey instanceof Function ? cacheKey(args) : cacheKey

type UpdateFn<ArgsType> = (
  // TODO: Determine how to pass the CacheType generic directly into this type
  // currently updateFnConstructor is used to grab the CacheType generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldCache: any,
  mutationArgs: ArgsType,
  queryKey: QueryKey
) => unknown

type CacheKey<ArgsType> =
  | string
  | readonly unknown[]
  | ((args: ArgsType) => string | readonly unknown[])

type OptimisticUpdate<ArgsType> =
  | SingleCacheKeyOU<ArgsType>
  | MultipleCacheKeyOU<ArgsType>
  | CacheKeyFilterOU<ArgsType>

type BaseOU<ArgsType> = {
  updateFn: UpdateFn<ArgsType>
  updateOn?: 'onMutate' | 'onSuccess' | 'onSettled'
}

type SingleCacheKeyOU<ArgsType> = BaseOU<ArgsType> & {
  cacheKey: CacheKey<ArgsType>
}

type MultipleCacheKeyOU<ArgsType> = BaseOU<ArgsType> & {
  cacheKeys: CacheKey<ArgsType>[]
}

type CacheKeyFilterOU<ArgsType> = BaseOU<ArgsType> & {
  cacheKeyFilter: {
    prefix?: string | string[]
    // TODO: Determine how to pass the CacheType generic directly into this type
    // currently filterFnConstructor is used to grab the CacheType generic
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterFn?: (query: Query<any>, args: ArgsType) => boolean
  }
}

// type guard to check if an optimistic update has one or more cacheKeys
const isMultipleCacheKeys = <ArgsType>(
  optimisticUpdate: OptimisticUpdate<ArgsType>
): optimisticUpdate is MultipleCacheKeyOU<ArgsType> => {
  return (
    // incorrect typing, cache keys could be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (optimisticUpdate as MultipleCacheKeyOU<ArgsType>).cacheKeys !== undefined
  )
}

// type guard to check if an optimistic update has a cacheKeyFilter fn
const isCacheKeyFilter = <ArgsType>(
  optimisticUpdate: OptimisticUpdate<ArgsType>
): optimisticUpdate is CacheKeyFilterOU<ArgsType> => {
  return (
    // incorrect typing, cache key filter function could be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (optimisticUpdate as CacheKeyFilterOU<ArgsType>).cacheKeyFilter !==
    undefined
  )
}

// find all nonempty caches that match the provided cache key filter function
const getMatchedCaches = <ArgsType>(
  filter: CacheKeyFilterOU<ArgsType>['cacheKeyFilter'],
  args: ArgsType,
  cache: QueryCache
) =>
  cache.findAll(filter.prefix).filter(
    (query) =>
      // if filterFn not sent return true for all
      !filter.filterFn || filter.filterFn(query, args)
  )
