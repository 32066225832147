import { useCallback } from 'react'
import _ from 'lodash'
import { Row } from 'react-table'

const useEntityLabelSort = <
  T extends Record<string, unknown> = Record<string, unknown>
>({
  accessor,
}: {
  accessor: string | string[] | ((row: T) => string | undefined)
}) => {
  const memoizedFunction = useCallback((rowA, rowB) => {
    const rowATerm = getRowProperty(accessor, rowA)
    const rowBTerm = getRowProperty(accessor, rowB)

    if (rowATerm === undefined) return -1
    if (rowBTerm === undefined) return 1

    return rowATerm < rowBTerm ? -1 : 1
  }, [])

  return memoizedFunction
}

export default useEntityLabelSort

// return the row property that will be used to sort by
const getRowProperty = <
  T extends Record<string, unknown> = Record<string, unknown>
>(
  accessor: string | string[] | ((row: T) => string | undefined),
  row: Row<T>
) => {
  // if an array of string keys is provided, join values at those keys by spaces
  if (Array.isArray(accessor))
    return accessor.map((item) => _.get(row.original, item)).join(' ')

  // if a string key is provided, get value stored in row at that property key
  if (typeof accessor === 'string') return _.get(row.original, accessor)

  // if a function is provided, call it and pass in the row object
  if (typeof accessor === 'function') return accessor(row.original)
}
