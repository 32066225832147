import { useQuery } from 'react-query'
import { webhooksApi } from 'utils'

const useWebhookCategoriesQuery = () => {
  const query = useQuery<GetWebhookCategoriesResponse, KyError>(
    'getWebhookCategories',
    getWebhookCategories
  )

  return query
}

export default useWebhookCategoriesQuery

const getWebhookCategories = async () => {
  const searchParams = [
    ['order_by', 'ASC'],
    ['take', '10000'],
  ]

  const result = webhooksApi
    .get('api/webhook-categories', {
      searchParams,
    })
    .json<GetWebhookCategoriesResponse>()

  return result
}
