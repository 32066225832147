import React from 'react'
import { Icon, TextField } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type MetadataFormProps = {
  metadata: Array<[string, string]> | undefined
  setMetadata: (metadata: [string, string][] | undefined) => void
  errors?: MetadataError[]
  originalMetadata?: Array<[string, string]> | undefined
}

const MetadataForm = ({
  metadata,
  setMetadata,
  errors,
  originalMetadata,
}: MetadataFormProps) => {
  const { t } = useTranslation()

  return metadata?.length ? (
    <>
      {metadata.map(([key, value], index) => (
        <MutableEntriesContainer key={index}>
          <MutableEntriesKeyField
            label="Key"
            value={key}
            onChange={(e) =>
              setMetadata(
                metadata.map((metadatum, metadataIndex) =>
                  metadataIndex === index ? [e.target.value, value] : metadatum
                )
              )
            }
            disabled={
              originalMetadata?.map(([key]) => key).includes(key) &&
              originalMetadata.length > index
            }
            noMargin
            data-testid={`KeyField-${index}`}
            error={errors?.find((error) => error.index === index)?.key}
          />
          <TextField
            label="Value"
            value={value}
            onChange={(e) =>
              setMetadata(
                metadata.map((metadatum, metadataIndex) =>
                  metadataIndex === index ? [key, e.target.value] : metadatum
                )
              )
            }
            noMargin
            data-testid={`ValueField-${index}`}
            error={errors?.find((error) => error.index === index)?.value}
          />
          <TrashIcon
            data-testid={`deleteMeta-${index}`}
            type="trash"
            onClick={() => {
              setMetadata(
                metadata.filter((_, metadataIndex) => metadataIndex !== index)
              )
            }}
          />
        </MutableEntriesContainer>
      ))}
      <AddFieldButton onClick={() => setMetadata([...metadata, ['', '']])}>
        {t('Add Field')}
      </AddFieldButton>
    </>
  ) : (
    <MetadataEmpty
      title={t('No Data Found')}
      description={t('No metadata fields created yet')}
      callToAction={
        <Button onClick={() => setMetadata([['', '']])}>
          {t('Add Field')}
        </Button>
      }
    />
  )
}

export default MetadataForm

const MutableEntriesContainer = tw.div`flex`

const AddFieldButton = tw(Button)`mb-4`

const TrashIcon = tw(
  Icon
)`w-5 h-5 text-red-500 hover:text-red-600 self-center mb-7 ml-2`

const MetadataEmpty = tw(Empty)`border-2 border-dashed rounded flex-grow`

const MutableEntriesKeyField = tw(TextField)`mr-2!`

export type MetadataError = {
  index: number
  key?: string
  value?: string
}

export const getMetadataErrorMessage = (
  { index, key, value }: MetadataError,
  metadata: [string, string][]
) => {
  if (key === '') return 'Key is required'
  if (value === '') return 'Value is required'
  if (
    metadata.find(
      (metadatum, metadatumIndex) =>
        index > metadatumIndex && metadatum[0] === key
    )
  )
    return 'Key must be unique'
  return undefined
}
