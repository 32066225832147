import { FadeInSlideDown } from 'animations'
import { Alert, Button, DateRangePicker } from 'atlas'
import { AlertsBarChart, AlertsLineChart } from 'components/statistics'
import { VitalReadingsLineChart } from 'components/vitals'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  CopyToClipboardButton,
  Dialog,
  InformationCard,
  LoadingIcon,
  NavigationCard,
} from 'elements'
import { useDelay, useNavigate } from 'hooks'
import { useResponsiblePartiesQuery } from 'hooks/responsible-parties'
import { useSubscriberOnboardingQuery } from 'hooks/user-subscriber'
import { useSubscriberVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { dateRangePresetToDateRange } from 'utils'

type SubscriberDetailsContainerProps = {
  subscriber: SubscriberDisplay
}

const SubscriberDetailsContainer = ({
  subscriber,
}: SubscriberDetailsContainerProps) => {
  const navigate = useNavigate()
  const delay = useDelay()
  const {
    assumedOrganizationRole,
    assumeOrganizationRole,
  } = useAssumedOrganizationRole()

  const [redirect, setRedirect] = useState('')
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [dateRangeState, setDateRangeState] = useState<DateRangeState>({
    preset: 'this-quarter',
    value: dateRangePresetToDateRange('this-quarter'),
  })

  const {
    isLoading: isLoadingSubscriberVitals,
    data: subVitalSigns,
  } = useSubscriberVitalAssignmentsQuery(subscriber.id)
  const { isLoading: isLoadingResponsibleParties } = useResponsiblePartiesQuery(
    {
      subscriberId: subscriber.id,
    }
  )
  const subscriberOnboardingQuery = useSubscriberOnboardingQuery({
    subscriberId: subscriber.id,
  })

  const { t } = useTranslation()

  if (
    isLoadingSubscriberVitals ||
    isLoadingResponsibleParties ||
    subscriberOnboardingQuery.isLoading
  )
    return <LoadingIcon />

  const numberOfValidVitalSigns =
    (
      subVitalSigns?.assignedVitalSigns.filter(
        (vitalAssignment) => !vitalAssignment.name?.includes('Blood Pressure')
      ) || []
    ).length || 0

  return (
    <>
      {_.find(subscriberOnboardingQuery.data, (value) => !value.complete) ? (
        <FadeInSlideDown delay={delay()}>
          <Alert
            message={t('Subscriber setup incomplete')}
            type="warning"
            onClick={() => {
              navigate('setup')
            }}
          />
        </FadeInSlideDown>
      ) : null}

      <FadeInSlideDown delay={delay()}>
        <InfoCard size="lg">
          <InformationCard.Item
            label={t('ID')}
            value={<IDText value={subscriber.id}>{subscriber.id}</IDText>}
          />
        </InfoCard>
      </FadeInSlideDown>

      <NavigationCardGrid>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="info"
            iconColor="cyan"
            label={t('Contact Details')}
            onClick={() => navigate('information')}
            description={t(
              'View and manage personal and contact related details'
            )}
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="settings"
            iconColor="grey"
            label={t('Medical Profile Settings')}
            data-testid="subscriber-settings"
            onClick={() => {
              setRedirect('settings')
              // if subscriber belongs to an organization and hasn't assumed a role, show an assume role prompt
              if (!assumedOrganizationRole) setIsDialogOpen(true)
              else navigate('settings')
            }}
            description={t(
              'View medications, vitals and related medical information'
            )}
          />
        </FadeInSlideDown>
      </NavigationCardGrid>
      <FadeInSlideDown delay={delay()}>
        <HealthActivityTitle>{t('Health Activity')}</HealthActivityTitle>
      </FadeInSlideDown>
      <NavigationCardGrid>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="vitals"
            iconColor="yellow"
            label={t('Vitals Activity')}
            onClick={() => navigate('vitals')}
            description={
              assumedOrganizationRole
                ? t('View Vital Sign readings for your Subscriber')
                : t(
                    'Monitor data for all vitals signs assigned to this subscriber'
                  )
            }
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="alert"
            iconColor="red"
            label={t('Alerts Activity')}
            onClick={() => navigate('alerts-activity')}
            description={
              assumedOrganizationRole
                ? t(
                    'View, manage, and address all alerts activity associated with this Subscriber'
                  )
                : t(
                    'Address active alerts and review alert history for this subscriber'
                  )
            }
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="medications"
            iconColor="purple"
            label={t('Medication Activity')}
            onClick={() => alert('this is medication activity')}
            description={t('View and manage medication activity')}
          />
        </FadeInSlideDown>
      </NavigationCardGrid>

      <StickyHeader>
        <FadeInSlideDown delay={delay()}>
          <ReportsSectionHeader>
            <ReportsTitle>{t('Reports')}</ReportsTitle>

            <DateRangePicker
              value={dateRangeState}
              onChange={(preset, [startDate, endDate]) =>
                setDateRangeState({ preset, value: [startDate, endDate] })
              }
            />
          </ReportsSectionHeader>
        </FadeInSlideDown>
      </StickyHeader>

      {/* //TODO show grid when API is fixed */}
      {/* only show the vitals matrix if the subscriber has assigned vital signs */}
      {/* {subVitalSigns?.assignedVitalSigns.length ? (
        <FadeInSlideDown delay={delay()}>
          <SubscriberVitalsMatrix dateRangeState={dateRangeState} />
        </FadeInSlideDown>
      ) : (
        <Empty
          title={t('No Data Found')}
          description={t('Subscriber has no assigned vitals')}
        />
      )} */}

      <FadeInSlideDown delay={delay()}>
        <ReportsGrid noBorderBottom>
          <ReportsGridItem>
            <AlertsBarChart
              dateRangeState={dateRangeState}
              subscriberId={subscriber.id}
            />
          </ReportsGridItem>
          <ReportsGridItem>
            <AlertsLineChart
              dateRangeState={dateRangeState}
              subscriberId={subscriber.id}
            />
          </ReportsGridItem>
        </ReportsGrid>
        <ReportsGrid
          isOneColumn={numberOfValidVitalSigns === 1}
          isThreeColumns={!(numberOfValidVitalSigns % 3)}
        >
          {subVitalSigns?.assignedVitalSigns
            .filter(
              (vitalAssignment) =>
                !vitalAssignment.name?.includes('Blood Pressure')
            )
            .map((vitalAssignment) => (
              <ReportsGridItem key={vitalAssignment.id}>
                <VitalReadingsLineChart
                  dateRangeState={dateRangeState}
                  subscriberId={subscriber.id}
                  vitalAssignment={vitalAssignment}
                />
              </ReportsGridItem>
            ))}
        </ReportsGrid>
      </FadeInSlideDown>

      <Dialog
        open={isDialogOpen}
        title={`${t('Assume Role of')} ${
          subscriber.organization?.businessName
            ? subscriber.organization.businessName
            : 'ECG Admin'
        }?`}
        content={
          <p>
            {t(
              `To manage settings for this subscriber you must assume the organization's role`
            )}
          </p>
        }
        actions={
          <>
            <Button onClick={() => setIsDialogOpen(false)} type="secondary">
              {t('Cancel')}
            </Button>
            <Button
              type="primary-filled"
              onClick={() => {
                // assume the organization role or ecg admin role

                assumeOrganizationRole({
                  orgID:
                    subscriber.organization?.id ||
                    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
                  onSuccess: (searchParams) =>
                    navigate({ pathname: redirect, searchParams }),
                })

                setIsDialogOpen(false)
              }}
            >
              {t('Assume Role')}
            </Button>
          </>
        }
      />
    </>
  )
}

export default SubscriberDetailsContainer

const InfoCard = styled(InformationCard)`
  ${tw`flex-grow`}
`

const NavigationCardGrid = tw.div`grid gap-3 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 my-4`

const StickyHeader = tw.div`sticky top-0 bg-gray-50 z-20`

const ReportsSectionHeader = tw.div` justify-between items-center mt-3 mb-4`

const ReportsTitle = tw.h2`text-2xl font-semibold mb-2`

const HealthActivityTitle = tw.h2`text-2xl font-semibold mt-3 -mb-2`

const ReportsGrid = styled(FadeInSlideDown)<{
  isThreeColumns?: boolean
  noBorderBottom?: boolean
  isOneColumn?: boolean
}>`
  ${tw`grid grid-cols-1 border-l border-gray-200`}
  ${({ isThreeColumns, isOneColumn }) => {
    if (isOneColumn) return tw`grid-cols-1`
    return isThreeColumns
      ? tw`lg:grid-cols-2 2xl:grid-cols-3`
      : tw`2xl:grid-cols-2`
  }}
    ${({ noBorderBottom }) => !noBorderBottom && tw`border-b`}
`

const ReportsGridItem = styled.div<{
  isTwoColumns?: boolean
}>`
  ${tw`bg-white border-r border-t border-gray-200`}
  ${({ isTwoColumns }) => isTwoColumns && tw`col-span-2`}
`

const IDText = tw(CopyToClipboardButton)`font-mono block`
