import React, { useEffect, useState } from 'react'
import {
  RightPopup,
  TextField,
  LoadingIcon,
  Checkbox,
  SimpleList,
  Icon,
} from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form'
import {
  useAddApplicationMutation,
  useUpdateApplicationMutation,
} from 'hooks/access-control/applications'
import { useModulesQuery } from 'hooks/access-control/modules'
import _ from 'lodash'

type CreateEditApplicationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  application?: Application
}

const CreateEditApplicationForm = ({
  isFormOpen,
  setIsFormOpen,
  application,
}: CreateEditApplicationFormProps) => {
  const { data: modules, isLoading: isLoadingModules } = useModulesQuery()

  const [selectedModules, setSelectedModules] = useState<Module[]>([])
  const [selectedModulesError, setSelectedModulesError] = useState<string>()
  const [action, setAction] = useState<'add-another' | 'close'>()

  const { t } = useTranslation()

  const formMethods = useForm<{
    applicationFields: CreateApplicationForm
    availableModules: {
      values: { checked: boolean; module: Module }
    }[]
  }>({ defaultValues: { availableModules: [] } })
  const { handleSubmit, errors, reset, watch, setValue, control } = formMethods

  const { fields } = useFieldArray({
    control,
    name: 'availableModules',
  })

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen && modules?.items) {
      reset({
        applicationFields: {
          name: application?.name || '',
          cognitoAppId: application?.cognitoAppId || '',
          description: application?.description || '',
        },
        availableModules: resetAvailableModules(modules.items, application),
      })
      setSelectedModules(
        application?.modules && application.modules.length > 0
          ? application.modules
          : []
      )
    }
  }, [isFormOpen, modules?.items])

  // reset selectedModulesError when module is added
  useEffect(() => {
    if (selectedModules.length && selectedModulesError)
      setSelectedModulesError(undefined)
  }, [selectedModules])

  const {
    mutate: addApplication,
    isLoading: isAddApplicationLoading,
  } = useAddApplicationMutation()

  const {
    mutate: updateApplication,
    isLoading: isUpdateApplicationLoading,
  } = useUpdateApplicationMutation()

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={
        application
          ? application.archiveInfo.archived
            ? t('Edit Archived Application')
            : t('Edit Application')
          : t('Create Applications')
      }
      controls={
        <>
          {/* Create & Add Another Button (only shown for creating modules) */}
          {!application ? (
            <>
              <Button
                type="primary-filled"
                disabled={action !== 'add-another' && isAddApplicationLoading}
                isLoading={action === 'add-another' && isAddApplicationLoading}
                onClick={handleSubmit((formData) => {
                  if (!selectedModules.length) {
                    setSelectedModulesError(
                      t('At least one module is required')
                    )
                    return
                  }

                  setAction('add-another')
                  addApplication(
                    {
                      ...formData.applicationFields,
                      modules: selectedModules.map((module) => module.id),
                    },
                    {
                      onSuccess: () => {
                        // reset the form (but don't close the form)
                        reset({
                          applicationFields: {
                            name: '',
                            cognitoAppId: '',
                            description: '',
                          },
                          availableModules: resetAvailableModules(
                            modules?.items || []
                          ),
                        })
                        setSelectedModules([])
                      },
                    }
                  )
                })}
              >
                {t('Create & Add Another')}
              </Button>
              &nbsp;
            </>
          ) : null}
          {/* Create & Close / Update Button (shown for both) */}
          <Button
            type="primary-filled"
            isLoading={
              isUpdateApplicationLoading ||
              (action === 'close' && isAddApplicationLoading)
            }
            disabled={action !== 'close' && isAddApplicationLoading}
            onClick={handleSubmit((formData) => {
              if (!selectedModules.length) {
                setSelectedModulesError(t('At least one module is required'))
                return
              }

              application
                ? updateApplication(
                    {
                      applicationData: {
                        ...formData.applicationFields,
                        modules: selectedModules.map((module) => module.id),
                      },
                      existingApplication: application,
                    },
                    { onSuccess: () => setIsFormOpen(false) }
                  )
                : (setAction('close'),
                  addApplication(
                    {
                      ...formData.applicationFields,
                      modules: selectedModules.map((module) => module.id),
                    },
                    { onSuccess: () => setIsFormOpen(false) }
                  ))
            })}
          >
            {application ? t('Update') : t('Create & Close')}
          </Button>
          &nbsp;
          {/* Cancel Button (shown for both) */}
          <Button
            type="secondary"
            disabled={isAddApplicationLoading || isUpdateApplicationLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {isLoadingModules || !modules ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Application Name is required') }}
              name="applicationFields.name"
              label={t('Application Name')}
              fullWidth
              error={errors.applicationFields?.name?.message}
              disabled={application?.archiveInfo.archived}
              required
            />

            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="applicationFields.cognitoAppId"
              label={t('Cognito App Id')}
              fullWidth
              error={errors.applicationFields?.cognitoAppId?.message}
              disabled={application?.archiveInfo.archived}
            />

            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Application Description is required') }}
              name="applicationFields.description"
              label={t('Description')}
              fullWidth
              multiline
              rows={4}
              error={errors.applicationFields?.description?.message}
              disabled={application?.archiveInfo.archived}
              required
            />
            {/* MODULES SELECT */}
            {
              // If application is archived don't show Add Modules
              !application?.archiveInfo.archived ? (
                <Title>{t('Available Modules')}</Title>
              ) : null
            }
            {modules.items && modules.items.length > 0 ? (
              <>
                {
                  // If application is archived don't show UI to Add Modules
                  !application?.archiveInfo.archived ? (
                    <>
                      <AvailableModulesContainer>
                        {fields.length > 0 ? (
                          // Sort checkboxes so that when adding and removing, order is preserved
                          _.sortBy(fields, (field) =>
                            _.lowerCase(field.values.module.name)
                          ).map((avaliableModule, index) => (
                            <Controller
                              key={avaliableModule.values.module.id}
                              name={`availableModules[${index}].values`}
                              defaultValue={avaliableModule.values}
                              render={({
                                onChange,
                                value,
                                name,
                                ref,
                              }: CheckboxRenderArgs<{
                                module: Module
                                checked: boolean
                              }>) => (
                                <Checkbox
                                  onChange={(e) =>
                                    onChange({
                                      module: JSON.parse(e.target.value).module,
                                      checked: e.target.checked,
                                    })
                                  }
                                  value={value}
                                  checked={!!value?.checked}
                                  ref={ref}
                                  label={
                                    <p>{avaliableModule.values.module.name} </p>
                                  }
                                  name={name}
                                  className="text-gray-900 py-1 hover:bg-gray-200 px-4"
                                />
                              )}
                            />
                          ))
                        ) : (
                          <Empty
                            title={t('No Data Found')}
                            description={t('No modules are available to add')}
                          />
                        )}
                      </AvailableModulesContainer>
                      <ModuleBtn
                        disabled={
                          // disable if no modules are checked
                          watch('availableModules').every(
                            (avaliableModule) => !avaliableModule.values.checked
                          )
                        }
                        onClick={() => {
                          // add availableModules that are checked to array of selectedModules
                          setSelectedModules([
                            ...selectedModules,
                            ...watch('availableModules')
                              .filter(
                                (availableModule) =>
                                  availableModule.values.checked
                              )
                              .map(
                                (availableModule) =>
                                  availableModule.values.module
                              ),
                          ])
                          // remove them from availableModules
                          setValue(
                            'availableModules',
                            watch('availableModules').filter(
                              (avaliableModule) =>
                                !avaliableModule.values.checked
                            )
                          )
                        }}
                      >
                        {t('Add Selected Modules To Application')}
                      </ModuleBtn>
                    </>
                  ) : null
                }
                {/* SELECTED MODULES */}
                <Title>{t('Selected Modules')}</Title>
                {selectedModulesError ? (
                  <ErrorText>{selectedModulesError}</ErrorText>
                ) : null}
                <SelectedModulesContainer>
                  {selectedModules.length > 0 ? (
                    <SimpleList
                      data={selectedModules}
                      animate={false}
                      renderItem={(module) => (
                        <SelectedModule
                          onClick={() => {
                            // remove this module from selectedModules
                            setSelectedModules(
                              selectedModules.filter(
                                (selectedModule) =>
                                  selectedModule.id !== module.id
                              )
                            )
                            // add this module back to availableModules
                            setValue('availableModules', [
                              ...watch('availableModules'),
                              { values: { checked: false, module } },
                            ])
                          }}
                        >
                          <TrashIcon
                            type="trash"
                            data-testid={`${module.name}-delete`}
                          />

                          <p>{module.name}</p>
                        </SelectedModule>
                      )}
                    />
                  ) : (
                    <Empty
                      title={t('No Data Found')}
                      description={t('No modules have been selected yet')}
                    />
                  )}
                </SelectedModulesContainer>
              </>
            ) : (
              <Empty
                title={t('No Data Found')}
                description={t(
                  'At least one module must exist in order to assign modules to a Application'
                )}
                callToAction={
                  <Button type="primary-filled" to="/access-control/modules">
                    {t('Create Modules')}
                  </Button>
                }
              />
            )}
          </form>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default CreateEditApplicationForm

const resetAvailableModules = (
  modules: Module[],
  application?: Application | undefined
) =>
  modules
    .filter(
      (module) =>
        // only include active modules
        module.activeInfo.active &&
        // don't include modules that are already attached to the application
        !application?.modules?.map((module) => module.id).includes(module.id)
    )
    .map((module) => ({
      values: {
        checked: !!application?.modules
          ?.map((module) => module.id)
          .includes(module.id),
        module,
      },
    }))

const AvailableModulesContainer = tw.div`h-32 overflow-auto bg-gray-50 rounded border border-gray-300`

const ModuleBtn = tw(Button)`my-4`

const SelectedModulesContainer = tw.div`h-32 overflow-auto bg-gray-50 rounded border border-gray-300`

const SelectedModule = tw.div`text-gray-900 py-1 hover:bg-gray-200 px-4 flex items-center cursor-pointer`

const Title = tw.h3`text-xl mb-2`

const TrashIcon = tw(Icon)`mr-4 text-gray-500 flex-shrink-0`

const ErrorText = tw.p`text-xs text-mui-error font-medium ml-2 -mt-2`
