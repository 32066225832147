import React, { useCallback, useMemo, useState } from 'react'
import { Button, Chip } from 'atlas'
import {
  ConfirmationDialogs,
  EllipsisDropdown,
  List,
  LoadingIcon,
} from 'elements'
import { useNavigate } from 'hooks'
import {
  useActivatePartnerTypeMutation,
  useDeactivatePartnerTypeMutation,
  usePartnerTypesQuery,
} from 'hooks/partners'
import { useEntityLabelSort, useStatusSort } from 'hooks/react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import tw from 'twin.macro'

type PartnerTypeListProps = {
  onEditPartnerType: (partnerType: PartnerType) => void
}

const PartnerTypeList = ({ onEditPartnerType }: PartnerTypeListProps) => {
  const { t } = useTranslation()
  const statusSort = useStatusSort()
  const navigate = useNavigate()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['name'],
  })

  const { data: partnerTypeList, isLoading } = usePartnerTypesQuery()
  // const {
  //   data: partnerTypeCommissionsResponse,
  // } = usePartnerTypeCommissionsQuery()

  // const partnerTypeCommissions = partnerTypeCommissionsResponse?.items || []

  const tableData: PartnerType[] = useMemo(() => {
    return partnerTypeList?.items || []
  }, [partnerTypeList, i18next.language])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<PartnerType>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const partnerTypeName = row.original.name.toLowerCase()
        const partnerTypeDescription = row.original.description.toLowerCase()

        return (
          partnerTypeName.includes(globalFilter.toLowerCase()) ||
          partnerTypeDescription.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )

  const columns: Column<PartnerType>[] = useMemo(
    () => [
      {
        id: 'PARTNER TYPE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('PARTNER TYPE'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.name}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortType: primaryLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
      },
      {
        id: 'PAYOUT SETUP',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('PAYOUT SETUP'),
        accessor: () => (
          // TODO: when this becomes a property returned by API fix the sorting on this column
          // partnerTypeCommissions.find(
          //   (partnerTypeCommission) =>
          //     partnerTypeCommission.partnerTypeId === row.id
          // ) ? (
          //   <Chip color="green">{t('Complete')}</Chip>
          // ) : (
          <Chip color="yellow">{t('Pending')}</Chip>
        ),
        width: '120px',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) =>
          row.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          ),
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => (
          <ActionsCell row={row} onEdit={() => onEditPartnerType(row)} />
        ),
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (row) => navigate(row.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'PARTNER TYPE',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  if (isLoading) return <LoadingIcon />

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={t('Search partner types')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default PartnerTypeList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: PartnerType
  onEdit: () => void
}

const ActionsCell = ({ row }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )
  const { mutate: activatePartnerType } = useActivatePartnerTypeMutation()
  const { mutate: deactivatePartnerType } = useDeactivatePartnerTypeMutation()

  const confirmations = [
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${row.name}" Partner Type`),
      content: (
        <p>{t('Are you sure you want to deactivate this partner type?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            deactivatePartnerType(row.id)

            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${row.name}" Partner Type`),
      content: (
        <p>{t('Are you sure you want to activate this partner type?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            activatePartnerType(row.id)

            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          row.activeInfo.active
            ? {
                label: t('Deactivate'),
                onClick: ({ setIsDropdownVisible }) => {
                  setIsDeactivateDialogOpen(true)
                  setIsDropdownVisible(false)
                },
              }
            : {
                label: t('Activate'),
                onClick: ({ setIsDropdownVisible }) => {
                  setIsActivateDialogOpen(true)

                  setIsDropdownVisible(false)
                },
              },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
