type DateRangePresetToTooltipFormat = { [key in DateRangePreset]: string }

const dateRangePresetToTooltipFormat: DateRangePresetToTooltipFormat = {
  today: 'PPPP',
  'this-week': 'cccc - LLLL do',
  'last-week': 'cccc - LLLL do',
  'this-month': 'LLLL do',
  'last-month': 'LLLL do',
  'this-quarter': 'LLLL y',
  'last-quarter': 'LLLL y',
  'this-year': 'LLLL y',
  'last-year': 'LLLL y',
  custom: 'cccc - LLLL do',
}

export default dateRangePresetToTooltipFormat
