import React from 'react'
import {
  ToggleButton as MuiToggle,
  ToggleButtonGroup as MuiToggleGroup,
} from '@mui/material'
import { InputLabel } from '@mui/material'

type DayOfTheWeekToggleProps = {
  label: string
  name?: string
  onChange: (toggled: Weekdays[]) => void
  disabled?: boolean
  toggled: Weekdays[]
  setToggled: React.Dispatch<React.SetStateAction<Weekdays[]>>
}
const DayOfTheWeekToggleGroup = ({
  label,
  name,
  onChange,
  disabled,
  toggled,
  setToggled,
}: DayOfTheWeekToggleProps) => {
  const handleToggled = (
    event: React.MouseEvent<HTMLElement>,
    newSelected: Weekdays[]
  ) => {
    // must have one day selected, and can't select all days
    if (newSelected.length && newSelected.length < 7) {
      setToggled(newSelected)
      onChange(newSelected)
    }
  }
  return (
    <div className={'h-16 mb-4'}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <MuiToggleGroup
        onChange={handleToggled}
        value={toggled}
        className="flex flex-row w-full justify-start py-1 "
      >
        <MuiToggle
          className={
            'bg-blue-100 text-blue-900 px-3 py-1 rounded-lg transition-all border-gray-300 disabled:opacity-50 mr-1'
          }
          value="mon"
          disabled={disabled}
          disableRipple
        >
          MON
        </MuiToggle>
        <MuiToggle
          className={
            'bg-blue-100 text-blue-900 px-3 py-1 rounded-lg transition-all border-gray-300 disabled:opacity-50 mr-1'
          }
          value="tue"
          disabled={disabled}
          disableRipple
        >
          TUE
        </MuiToggle>
        <MuiToggle
          className={
            'bg-blue-100 text-blue-900 px-3 py-1 rounded-lg transition-all border-gray-300 disabled:opacity-50 mr-1'
          }
          value="wed"
          disabled={disabled}
          disableRipple
        >
          WED
        </MuiToggle>
        <MuiToggle
          className={
            'bg-blue-100 text-blue-900 px-3 py-1 rounded-lg transition-all border-gray-300 disabled:opacity-50 mr-1'
          }
          value="thu"
          disabled={disabled}
          disableRipple
        >
          THU
        </MuiToggle>
        <MuiToggle
          className={
            'bg-blue-100 text-blue-900 px-3 py-1 rounded-lg transition-all border-gray-300 disabled:opacity-50 mr-1'
          }
          value="fri"
          disabled={disabled}
          disableRipple
        >
          FRI
        </MuiToggle>
        <MuiToggle
          className={
            'bg-blue-100 text-blue-900 px-3 py-1 rounded-lg transition-all border-gray-300 isabled:opacity-50 mr-1'
          }
          value="sat"
          disabled={disabled}
          disableRipple
        >
          SAT
        </MuiToggle>
        <MuiToggle
          className={
            'bg-blue-100 text-blue-900 px-3 py-1 rounded-lg transition-all border-gray-300 disabled:opacity-50 mr-1'
          }
          value="sun"
          disabled={disabled}
          disableRipple
        >
          SUN
        </MuiToggle>
      </MuiToggleGroup>
    </div>
  )
}

export default DayOfTheWeekToggleGroup
