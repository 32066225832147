import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, webhooksApi, handleQueryError } from 'utils'

const useCreateWebhookSubscriptionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    CreateWebhookSubscriptionResponse,
    KyError,
    createWebhookSubscriptionArgs
  >(createWebhookSubscription, {
    onSuccess: (webhookSubscription) => {
      // optimistic update
      queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
        'getWebhookSubscriptions',
        (old) => {
          if (!old) return

          const newValue: GetWebhookSubscriptionsResponse = {
            ...old,
            items: [...old.items, webhookSubscription],
          }

          return newValue
        }
      )

      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries('getWebhookSubscriptions')

      // notify the user
      success({ message: t('Webhook subscription created') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useCreateWebhookSubscriptionMutation

type createWebhookSubscriptionArgs = CreateWebhookSubscriptionRequest & {
  organizationId: string
}

const createWebhookSubscription = async ({
  postUri,
  events,
  version,
  isDevelopment,
  organizationId,
}: createWebhookSubscriptionArgs) => {
  const result = await webhooksApi
    .post(`api/organizations/${organizationId}/webhook-subscriptions`, {
      json: { postUri, events, version, isDevelopment },
    })
    .json<CreateWebhookSubscriptionResponse>()

  return result
}
