import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { orgsApi } from 'utils'

const useUpdateOrganizationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}'],
    updateOrganizationArgs
  >({
    mutationFn: updateOrganization,
    successMsg: { message: t('Organization updated'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        updateOn: 'onSuccess',
        cacheKey: ({ organization }) => ['getOrganization', organization.id],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/organizations/{organizationId}'],
          updateOrganizationArgs
        >((oldOrganization, updatedOrgArgs) => {
          return (
            oldOrganization && {
              ...oldOrganization,
              displayName:
                updatedOrgArgs.displayName || oldOrganization.displayName,
              businessName:
                updatedOrgArgs.businessName || oldOrganization.businessName,
              ...(updatedOrgArgs.metadata
                ? {
                    integrationMetadata: Object.fromEntries(
                      updatedOrgArgs.metadata
                    ),
                  }
                : {}),
            }
          )
        }),
      },
    ],
    additionalCachesToInvalidate: ['getOrganizationList'],
  })

  return mutation
}

export default useUpdateOrganizationMutation

type updateOrganizationArgs = {
  organization: Organization
  displayName?: string
  businessName?: string
  metadata?: Array<[string, string]>
}

const updateOrganization = async ({
  organization,
  displayName,
  businessName,
  metadata,
}: updateOrganizationArgs) => {
  // if metadata is sent call endpoints to PATCH/POST metadata props
  if (metadata) {
    // PATCH metadata call
    const patchbody: OrgsAPIRequest['PATCH']['/api/organizations/{organizationId}/metadata']['body'] = []

    // for each existing metadata field that changed add to the requestBody of the PATCH
    organization.integrationMetadata &&
      Object.entries(organization.integrationMetadata).forEach(
        ([entryKey, originalValue]) => {
          const [, newValue] = metadata.find(([key]) => key === entryKey) || [
            entryKey,
            undefined,
          ]
          if (!newValue) {
            patchbody.push({
              value: 'remove',
              path: `/${entryKey}`,
              op: 'remove',
            })
          }
          if (newValue && originalValue !== newValue)
            patchbody.push({
              value: newValue,
              path: `/${entryKey}`,
              op: 'replace',
            })
        }
      )

    if (patchbody.length)
      await orgsApi
        .patch(`api/organizations/${organization.id}/metadata`, {
          json: patchbody,
        })
        .json<
          OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}/metadata']
        >()

    // POST metadata call
    const postbody: OrgsAPIRequest['POST']['/api/organizations/{organizationId}/metadata']['body'] = []

    const existingMetadataKeys = Object.keys(
      organization.integrationMetadata || {}
    )

    // for each metadata field that doesn't exist in the existing integrationMetadata add to the requestBody of the POST
    metadata.forEach(([key, value]) => {
      if (!existingMetadataKeys.includes(key))
        postbody.push({
          key,
          value,
        })
    })

    if (postbody.length)
      await orgsApi
        .post(`api/organizations/${organization.id}/metadata`, {
          json: postbody,
        })
        .json<
          OrgsAPIResponse['POST']['/api/organizations/{organizationId}/metadata']
        >()
  }

  // PATCH traditional org properties call
  const requestBody: OrgsAPIRequest['PATCH']['/api/organizations/{organizationId}']['body'] = [
    ...(displayName
      ? [
          {
            value: displayName,
            path: '/displayName',
            op: 'replace' as const,
          },
        ]
      : []),
    ...(businessName
      ? [
          {
            value: businessName,
            path: '/businessName',
            op: 'replace' as const,
          },
        ]
      : []),
  ]

  const result = await orgsApi
    .patch(`api/organizations/${organization.id}`, {
      json: requestBody,
    })
    .json<OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}']>()

  return result
}
