import React, { useState } from 'react'
import { Icon, Dialog } from 'elements'
import { Button, Empty, Tooltip } from 'atlas'
import { useTranslation } from 'react-i18next'
import PartnerTypeAssociationsForm from './PartnerTypeAssociationsForm'
import { FadeInSlideDown } from 'animations'
import tw from 'twin.macro'
import _ from 'lodash'
import { useDeletePartnerTypeChildrenMutation } from 'hooks/partners'

type PartnerTypeAssociationsGridProps = {
  childPartnerTypes: PartnerType[]
  parentPartnerType: PartnerType
  delay: (diff?: number) => number
}

const PartnerTypeAssociationsGrid = ({
  childPartnerTypes,
  parentPartnerType,
  delay,
}: PartnerTypeAssociationsGridProps) => {
  const { t } = useTranslation()

  const {
    mutate: deletePartnerTypeChild,
  } = useDeletePartnerTypeChildrenMutation()

  const [
    isPartnerTypeAssociationsFormOpen,
    setIsPartnerTypeAssociationsFormOpen,
  ] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [childPartnerToDelete, setChildPartnerToDelete] = useState<
    PartnerType | undefined
  >()

  return (
    <>
      <FadeInSlideDown delay={delay()}>
        <AssociationHeader>
          <AssociationTitleGroup>
            <AssociationTitle>{t('Parent-of Associations')}</AssociationTitle>
            <InfoTooltip>
              <Tooltip
                className="flex items-center"
                content={
                  <p tw="w-60">{`${t(
                    'This is a list of other Partner Types that can be onboarded by a'
                  )} ${parentPartnerType.name} ${t(
                    'to potentially gain indirect commissions from'
                  )}.`}</p>
                }
              >
                <InfoIcon type="info" />
              </Tooltip>
            </InfoTooltip>
          </AssociationTitleGroup>
          {childPartnerTypes.length ? (
            <Button
              type="primary-filled"
              onClick={() => setIsPartnerTypeAssociationsFormOpen(true)}
              tw="h-fit-content justify-self-end"
            >
              {t('Create Associations')}
            </Button>
          ) : null}
        </AssociationHeader>
      </FadeInSlideDown>
      {childPartnerTypes.length ? (
        <>
          <AssociationsGrid>
            {_.orderBy(childPartnerTypes, 'name').map((childPartner, index) => (
              <FadeInSlideDown delay={delay(0.02)} key={index}>
                <AssociationCard>
                  <ChildPartnerName>{childPartner.name}</ChildPartnerName>

                  <TrashIcon
                    type="trash"
                    data-testid={`${childPartner.name}-delete`}
                    onClick={() => {
                      setChildPartnerToDelete(childPartner)
                      setIsDialogOpen(true)
                    }}
                  />
                </AssociationCard>
              </FadeInSlideDown>
            ))}
          </AssociationsGrid>
          <Dialog
            open={isDialogOpen}
            title={`${t('Remove')} ${childPartnerToDelete?.name} ${t(
              'as Child Partner Type'
            )}?`}
            content={t(
              'Are you sure you want to remove this parent-of association?'
            )}
            actions={
              <>
                <Button
                  type="danger-filled"
                  onClick={() => {
                    if (childPartnerToDelete)
                      deletePartnerTypeChild({
                        parentPartnerTypeId: parentPartnerType.id,
                        childPartnerTypeId: childPartnerToDelete.id,
                      })

                    setIsDialogOpen(false)
                  }}
                >
                  {t('Delete')}
                </Button>

                <Button
                  onClick={() => {
                    setIsDialogOpen(false)
                  }}
                  type="secondary"
                >
                  {t('Cancel')}
                </Button>
              </>
            }
          />
        </>
      ) : (
        <FadeInSlideDown delay={delay()}>
          <BorderedEmpty
            title={t('No Data Found')}
            description={t(
              'No parent-of associations exist for this partner type'
            )}
            callToAction={
              <Button
                type="primary-filled"
                onClick={() => setIsPartnerTypeAssociationsFormOpen(true)}
              >
                {t('Create Associations')}
              </Button>
            }
          />
        </FadeInSlideDown>
      )}
      <PartnerTypeAssociationsForm
        parentPartnerType={parentPartnerType}
        childPartnerTypes={childPartnerTypes}
        isFormOpen={isPartnerTypeAssociationsFormOpen}
        setIsFormOpen={setIsPartnerTypeAssociationsFormOpen}
      />
    </>
  )
}

export default PartnerTypeAssociationsGrid

const AssociationHeader = tw.div`flex justify-between mb-2`

const AssociationTitleGroup = tw.div`flex h-full items-center self-center`

const AssociationTitle = tw.h3`text-xl font-semibold`

const AssociationsGrid = tw.div`grid lg:grid-cols-2 gap-2`

const AssociationCard = tw.div`bg-white p-4 rounded border border-gray-200 h-full flex justify-between`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600`

const InfoIcon = tw(
  Icon
)`text-gray-600 hover:text-gray-900 ml-2 w-5 h-5 align-middle`

const InfoTooltip = tw.div`h-fit-content`

const BorderedEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`

const ChildPartnerName = tw.p`text-lg`
