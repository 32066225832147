import React, { useEffect } from 'react'
import { RightPopup, TextField } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  useCreateDisclaimerMutation,
  useUpdateDisclaimerMutation,
} from 'hooks/disclaimers'
import { useLocalesQuery } from 'hooks/locales'

type DisclaimerFormProps = {
  disclaimer?: Disclaimer | undefined | void
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  autoFocus?: string
}

const DisclaimerForm = ({
  disclaimer,
  isFormOpen,
  setIsFormOpen,
  autoFocus,
}: DisclaimerFormProps) => {
  const { disclaimerTypeId } = useParams()

  const { t } = useTranslation()

  const formMethods = useForm<DisclaimerForm>()
  const { handleSubmit, errors, reset, control, watch } = formMethods
  const { fields } = useFieldArray({
    control,
    name: 'translations',
    keyName: 'RHFId',
  })

  const {
    mutate: addDisclaimer,
    isLoading: isAddDisclaimerLoading,
  } = useCreateDisclaimerMutation()
  const {
    mutate: updateDisclaimer,
    isLoading: isUpdateDisclaimerLoading,
  } = useUpdateDisclaimerMutation()

  const { data: locales } = useLocalesQuery()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset(
        disclaimer
          ? {
              // Edit existing
              ...disclaimer,
              translations: locales?.items
                ?.filter((locale) => locale.name !== 'en-US')
                .map((locale) => ({
                  locale: locale.name,
                  disclaimer:
                    disclaimer.translations?.find(
                      (translation) => translation.locale === locale.name
                    )?.disclaimer ?? '',
                })),
            }
          : {
              // Create new
              disclaimer: '',
              locale: 'en-US',
              disclaimerType: disclaimerTypeId,
              translations: locales?.items
                ?.filter((locale) => locale.name !== 'en-US')
                .map((locale) => ({
                  locale: locale.name,
                  disclaimer: '',
                })),
            }
      )
    }
  }, [isFormOpen, locales])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={disclaimer ? t('Edit Disclaimer') : t('Create Disclaimer')}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={isAddDisclaimerLoading || isUpdateDisclaimerLoading}
            onClick={handleSubmit(async (formData) => {
              if (disclaimer) {
                const disclaimerFields = {
                  disclaimer: formData.disclaimer,
                  locale: 'en-US',
                  disclaimerType: disclaimerTypeId,
                  translations: formData.translations
                    // only add translations that are nonempty
                    .filter((translation) => translation.disclaimer)
                    .map((disclaimer) => ({
                      locale: disclaimer.locale,
                      disclaimer: disclaimer.disclaimer,
                    })),
                }
                updateDisclaimer({
                  updatedDisclaimer: { ...disclaimer, ...disclaimerFields },
                  disclaimerTypeId,
                })

                setIsFormOpen(false)
              } else {
                const disclaimerFields = {
                  disclaimer: formData.disclaimer,
                  locale: 'en-US',
                  disclaimerType: disclaimerTypeId,
                  translations: formData.translations
                    // only add translations that are nonempty
                    .filter((translation) => translation.disclaimer)
                    .map((translation) => ({
                      locale: translation.locale,
                      disclaimer: translation.disclaimer,
                    })),
                }
                addDisclaimer({
                  newDisclaimer: disclaimerFields,
                  disclaimerTypeId,
                })

                setIsFormOpen(false)
              }
            })}
          >
            {disclaimer ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={isAddDisclaimerLoading || isUpdateDisclaimerLoading}
            onClick={() => {
              // Close form drawer
              setIsFormOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Disclaimer Text is required') }}
            name="disclaimer"
            label={t('Disclaimer Text')}
            fullWidth
            multiline
            error={errors.disclaimer?.message}
            required
          />
          {fields.map((field, index) => (
            <div key={field.RHFId}>
              <Controller
                as={TextField}
                defaultValue={field.disclaimer}
                name={`translations[${index}].disclaimer`}
                label={`${watch(`translations[${index}].locale`)} ${t(
                  'Translation'
                )}`}
                fullWidth
                multiline
                error={errors.translations?.[index]?.disclaimer?.message}
                // If this locale is specified to autoFocus, focus to this input
                autoFocus={autoFocus === watch(`translations[${index}].locale`)}
              />
              {/* Render this hidden controller to prevent the locale property of each field array item to become undefined */}
              <Controller
                as={TextField}
                name={`translations[${index}].locale`}
                className="hidden"
              />
            </div>
          ))}
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default DisclaimerForm
