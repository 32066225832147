import React, { useState, useRef, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import { TimePicker } from 'elements'
import tw, { styled } from 'twin.macro'
import Icon from '../../elements/Icon'
import CustomSelect from '../../elements/CustomSelect'
import {
  format,
  getMonth,
  getYear,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  parse,
  set,
  setMonth,
  setYear,
  subYears,
} from 'date-fns'
import { FadeInScaleUp } from 'animations'
import 'styles/react-datepicker.css'
import { useClickAway } from 'react-use'
import _ from 'lodash'

const MODAL_HEIGHT = 353.78

const MODAL_WIDTH = 295.78

export type DatePickerProps = {
  /**
   * Displays the string as the input label if passed
   */
  label?: string

  /**
   * Sets the state of the DatePicker, overriding the internal state.
   */
  value?: Date

  /**
   * Callback function called when the state of the DatePicker changes.
   */
  onChange?: (date: Date | undefined) => void

  /**
   * Sets the minimum date that can be selected with the DatePicker. Default is 125 years before current date.
   */
  minDate?: Date

  /**
   * Sets the maximum date that can be selected with the DatePicker. Default is current date.
   */
  maxDate?: Date

  /**
   * Shows UI to select time if **true**.
   */
  showTimeSelect?: boolean

  /**
   * Disables the DatePicker if **true**.
   */
  disabled?: boolean

  /**
   * Applies denser spacing styles to the DatePicker if **true**. Default is true
   */
  dense?: boolean

  /**
   * Applies denser spacing styles to the DatePicker if **true**. Default is true
   */
  error?: string | undefined

  /**
   * Appends to the className parent element of the popup. *Note: never directly use this prop, always opt for styled-components.*
   */
  className?: string

  /**
   * Appends to the className parent element of the button
   */
  buttonClassName?: string

  /**
   * Sets a default selected date on the date picker
   */
  datepickerDefaultDate?: Date
}

const DatePicker = ({
  label,
  value,
  onChange,
  minDate = subYears(new Date(), 125),
  maxDate = new Date(),
  showTimeSelect,
  disabled,
  dense = true,
  error,
  className,
  buttonClassName,
  datepickerDefaultDate = new Date(),
}: DatePickerProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const dateInputRef = useRef<HTMLInputElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAnchorModalRight, setIsAnchorModalRight] = useState(false)
  const [isAnchorModalTop, setIsAnchorModalTop] = useState(false)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)

  const [internalValue, setInternalValue] = useState<Date | undefined>(value)

  const [textInput, setTextInput] = useState<string>(
    internalValue ? format(internalValue, 'P') : ''
  )

  // sync external & internal value if external changes
  useEffect(() => value && setInternalValue(value), [value])

  // if a click is outside of the dropdown, set the visibility to false
  useClickAway(containerRef, () => !isTimeModalOpen && setIsModalOpen(false))

  // set the focus target when the modal is opened
  useEffect(() => {
    if (isModalOpen && dateInputRef.current) dateInputRef.current.focus()
  }, [isModalOpen])

  // check if element is too far right to anchor to left of element rect and anchor right instead
  useEffect(() => {
    if (
      containerRef.current &&
      window.innerWidth - containerRef.current.getBoundingClientRect().left <
        MODAL_WIDTH // width of modal in px
    )
      setIsAnchorModalRight(true)
    else setIsAnchorModalRight(false)
  }, [containerRef, window.innerWidth])

  // check if element is too far down on the page to anchor to the bottom of element rect and anchor top instead
  useEffect(() => {
    if (
      containerRef.current &&
      window.innerHeight - containerRef.current.getBoundingClientRect().bottom <
        MODAL_HEIGHT // height of modal in px
    )
      setIsAnchorModalTop(true)
    else setIsAnchorModalTop(false)
  }, [containerRef, window.innerHeight])

  // sync textInput to internalValue when it changes
  useEffect(() => {
    if (internalValue) setTextInput(format(internalValue, 'P'))
  }, [internalValue])

  // if textInput can be formatted as a date update internalValue
  useEffect(() => {
    if (internalValue) {
      const textDate = parse(textInput, 'P', internalValue)
      if (isValid(textDate) && !isSameDay(textDate, internalValue))
        setInternalValue(textDate)
    }
  }, [textInput])

  // create array of years between maxDate and minDate
  const years = _.range(getYear(maxDate), getYear(minDate) - 1, -1)

  return (
    <RelativeContainer ref={containerRef} className={className}>
      {label && internalValue ? (
        <TextLabel error={error}>{label}</TextLabel>
      ) : null}
      <DateRangeButton
        onClick={() => setIsModalOpen(!isModalOpen)}
        disabled={disabled}
        dense={dense}
        error={error}
        className={buttonClassName}
      >
        <CalendarIcon type="calendar" /> &nbsp;
        <DateText empty={!internalValue} error={error}>
          {internalValue
            ? format(internalValue, showTimeSelect ? 'Pp' : 'P')
            : label || ''}
        </DateText>
      </DateRangeButton>
      {internalValue ? (
        <DeleteIconContainer
          onClick={() => {
            setInternalValue(undefined)
            onChange?.(undefined)
            setIsModalOpen(false)
          }}
          dense={dense}
        >
          <DeleteIcon type="x" />
        </DeleteIconContainer>
      ) : null}
      {error ? <ErrorText>{error}</ErrorText> : null}
      {isModalOpen ? (
        <Modal
          alignModalRight={isAnchorModalRight}
          alignModalTop={isAnchorModalTop}
          parentRect={containerRef.current?.getBoundingClientRect()}
        >
          <ModalCalendar>
            {showTimeSelect ? (
              <ModalTimeHeader>
                <TimePicker
                  value={internalValue || null}
                  onChange={(date) =>
                    setInternalValue(
                      internalValue
                        ? set(internalValue, {
                            hours: date?.getHours(),
                            minutes: date?.getMinutes(),
                          })
                        : date || undefined
                    )
                  }
                  label=""
                  setIsModalOpen={setIsTimeModalOpen}
                />
              </ModalTimeHeader>
            ) : null}
            <ModalHeader>
              {!isSameMonth(minDate, maxDate) ? (
                <MonthSelect
                  options={months.map((month, index) => ({
                    label: month,
                    value: index,
                    disabled:
                      // disable option if it's after maxDate
                      (internalValue &&
                        isSameYear(internalValue, maxDate) &&
                        index > getMonth(maxDate)) ||
                      // disable option if it's before minDate
                      (internalValue &&
                        isSameYear(internalValue, minDate) &&
                        index < getMonth(minDate)),
                  }))}
                  value={getMonth(internalValue || datepickerDefaultDate)}
                  onChange={(month) =>
                    internalValue &&
                    setInternalValue(setMonth(internalValue, month.value))
                  }
                />
              ) : null}
              &nbsp;
              {!isSameYear(minDate, maxDate) ? (
                <YearSelect
                  options={years.map((year) => ({ label: year, value: year }))}
                  value={getYear(internalValue || datepickerDefaultDate)}
                  onChange={(year) =>
                    setInternalValue(
                      setYear(
                        internalValue || datepickerDefaultDate,
                        year.value
                      )
                    )
                  }
                />
              ) : null}
            </ModalHeader>
            <ModalContent>
              <ReactDatePicker
                selected={internalValue || datepickerDefaultDate}
                onChange={(newDate: Date) => {
                  onChange && onChange(newDate)
                  !value && setInternalValue(newDate)
                }}
                minDate={minDate}
                maxDate={maxDate}
                inline
                disabledKeyboardNavigation
              />
            </ModalContent>
          </ModalCalendar>
        </Modal>
      ) : null}
    </RelativeContainer>
  )
}

export default DatePicker

const RelativeContainer = tw.div`relative w-fit-content h-fit-content z-10`

const DateRangeButton = styled.button<{
  disabled: boolean | undefined
  dense: boolean
  error: string | undefined
  className?: string | undefined
}>(({ disabled, dense, error }) => [
  tw`flex items-center px-4 py-2 rounded border transition-all select-none outline-none focus:outline-none border-gray-200 w-44`,
  !disabled &&
    tw`bg-white hover:bg-gray-100 hover:border-gray-300 cursor-pointer`,
  disabled && tw`opacity-50 bg-gray-100 cursor-not-allowed`,
  !dense && tw`h-14`,
  error && tw`border-red-600 text-mui-error text-opacity-90`,
  error && !disabled && tw`hover:bg-gray-100 hover:border-red-700`,
])

const TextLabel = styled.span<{ error: string | undefined }>(({ error }) => [
  tw`absolute bg-white text-xs text-black text-opacity-50 px-1 -top-4`,
  error && tw`text-mui-error text-opacity-90`,
])

const DateText = styled.p<{ empty: boolean; error: string | undefined }>(
  ({ empty, error }) => [
    tw`align-middle`,
    'color: inherit;',
    empty && tw`text-black text-opacity-50`,
    error && tw`text-mui-error text-opacity-90`,
  ]
)

const ErrorText = tw.p`absolute text-xs text-mui-error mt-1 ml-4`

const Modal = styled(FadeInScaleUp)<{
  alignModalRight: boolean
  alignModalTop: boolean
  parentRect?: DOMRect
}>(({ alignModalRight, alignModalTop, parentRect }) => [
  tw`fixed flex bg-white rounded border border-gray-200 shadow-lg`,
  alignModalRight
    ? `right: ${window.innerWidth - (parentRect?.right || 0)}px;`
    : `left: ${parentRect?.left || 0}px;`,
  ...(alignModalTop
    ? [`bottom: ${window.innerHeight - (parentRect?.top || 0)}px;`, tw`mb-2`]
    : [`top: ${parentRect?.bottom || 0}px;`, tw`mt-2`]),
])

const ModalCalendar = tw.div`border-l border-gray-200`

const ModalHeader = tw.div`flex justify-center mb-1 px-4 py-2 border-b border-gray-200 bg-gray-50`

const ModalTimeHeader = tw.div`flex justify-center px-4 bg-gray-50`

const ModalContent = tw.div`flex justify-center`

const DeleteIconContainer = styled.div<{ dense: boolean }>(({ dense }) => [
  tw`absolute right-3 p-1 cursor-pointer rounded-full hover:bg-gray-200 w-6 h-6`,
  dense ? tw`bottom-2` : tw`bottom-4`,
])

const DeleteIcon = tw(Icon)`mb-3`

const CalendarIcon = tw(Icon)`mr-1 mb-0.5`

const MonthSelect = tw(CustomSelect)`w-32`

const YearSelect = tw(CustomSelect)`w-20`

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
