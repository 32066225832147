import { vitalsQueryConstructor } from 'utils/api/queryConstructors'

export default {
  subscriberMedication: vitalsQueryConstructor(
    'subscriberMedication',
    '/api/subscribers/{subscriberId}/medications/{medicationId}'
  ),

  subscriberMedications: vitalsQueryConstructor(
    'subscriberMedications',
    '/api/subscribers/{subscriberId}/medications',
    {
      mappers: { searchParams: () => ({ include: ['medicationMaster'] }) },
    }
  ),
  routeTypes: vitalsQueryConstructor(
    'routeTypes',
    '/api/seed-data/route-types'
  ),
  actionTypes: vitalsQueryConstructor(
    'actionTypes',
    '/api/seed-data/action-types'
  ),

  medications: vitalsQueryConstructor('medications', '/api/medications'),

  vitalsReadings: vitalsQueryConstructor(
    'vitalsReadings',
    '/vital-readings-grouped'
  ),
}

export type VitalsQueryStr =
  | 'subscriberMedication'
  | 'subscriberMedications'
  | 'vitalsReadings'
  | 'routeTypes'
  | 'actionTypes'
  | 'medications'
