import React, { useState } from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useNavigate } from 'hooks'
import {
  ApplicationsList,
  CreateEditApplicationForm,
} from 'components/access-control/applications'
import { useApplicationsQuery } from 'hooks/access-control/applications'
import tw from 'twin.macro'

const AccessControlApplications = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const {
    data: applications,
    isLoading: isLoadingApplications,
  } = useApplicationsQuery()

  const [
    isCreateEditApplicationFormOpen,
    setIsCreateEditApplicationFormOpen,
  ] = useState(false)
  const [application, setApplication] = useState<Application | undefined>()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEditApplication = (application: Application) => {
    setApplication(application)
    setIsCreateEditApplicationFormOpen(true)
  }

  const handleApplicationFormToggle = (isFormOpen: boolean) => {
    // If the form is closing, reset the selected Permission to edit
    if (isFormOpen) setApplication(undefined)

    // Call the normal setState
    setIsCreateEditApplicationFormOpen(isFormOpen)
  }

  const filteredApplications =
    applications?.items?.filter(
      (application) => !application.archiveInfo.archived
    ) || []

  return (
    <>
      <Layout
        title={t('Applications')}
        description={t(
          'Create applications and manage their associated modules.'
        )}
        breadcrumbs={[
          { name: t('Access Control'), route: '/access-control' },
          { name: t('Applications') },
        ]}
        controls={[
          {
            label: t('Create Applications'),
            onClick: () => handleApplicationFormToggle(true),
          },
          {
            label: t('View Archived Applications'),
            onClick: () => navigate('archive'),
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
          },
        ]}
        isLoading={isLoadingApplications}
        isUnauthorized={
          !accountPermissions?.data.ecgAdmin.permissions.includes(
            'acm.readonly'
          )
        }
        isEmpty={filteredApplications.length === 0}
        emptyComponent={
          <ApplicationsEmpty
            title={t('No Applications Created')}
            description={t(
              'To begin utilizing modules, create applications to which they can be assigned.'
            )}
          />
        }
      >
        <ApplicationsList
          // Only show unarchived applications
          data={filteredApplications}
          onEditApplication={handleEditApplication}
        />
      </Layout>
      <CreateEditApplicationForm
        isFormOpen={isCreateEditApplicationFormOpen}
        setIsFormOpen={handleApplicationFormToggle}
        application={application}
      />
    </>
  )
}

export default AccessControlApplications

const ApplicationsEmpty = tw(
  Empty
)`flex flex-grow border-2 border-dashed rounded`
