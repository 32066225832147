import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useUpdateSubscriberProfileMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/subscribers-profile/{subscriberId}'],
    UpdateSubscriberProps
  >({
    mutationFn: updateSubscriberProfile,
    successMsg: { message: t('Subscriber updated'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => ['getSubscriberDetails', subscriberId],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}'],
          UpdateSubscriberProps
        >((oldCache, { subscriberForm, subscriberId }) =>
          oldCache
            ? {
                ...oldCache,
                id: subscriberId,
                ...subscriberForm,
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useUpdateSubscriberProfileMutation

type UpdateSubscriberProps = {
  subscriberForm: Partial<SubscriberForm>
  subscriberId: string
}

const updateSubscriberProfile = ({
  subscriberForm,
  subscriberId,
}: UpdateSubscriberProps) =>
  typedOrgsApi.patch('/subscribers-profile/{subscriberId}', {
    pathParams: { subscriberId },
    body: Object.entries(subscriberForm)
      // type is not allowed to change
      .filter(
        (field): field is [string, string] =>
          !!field[1] &&
          field[0] !== 'type' &&
          field[0] !== 'id' &&
          field[0] !== 'metadata' &&
          field[0] !== 'nonPERS'
      )
      .map(([fieldKey, fieldValue]) => ({
        value: fieldValue,
        path: `/${fieldKey}`,
        op: 'replace' as const,
      })),
  })
