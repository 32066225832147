import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const usePartnersQuery = (partnerId: string | undefined) => {
  const query = useQuery<GetPartnerResponse, KyError>(
    ['getPartner', partnerId],
    () => getPartner(partnerId),
    { enabled: !!partnerId }
  )

  return query
}

export default usePartnersQuery

type getPartner = (partnerId?: string) => Promise<GetPartnerResponse>
const getPartner: getPartner = async (partnerId) => {
  const result = await orgsApi
    .get(`api/partners/${partnerId}`)
    .json<GetPartnerResponse>()
  return result
}
