/**
 * update organization hierarchy form contents to an organization hierarchy item
 * @param organizationForm
 * @param existingHierarchy
 * @returns OrganizationHierarchyItem
 */
const organizationFormToOrganizationHierarchyItem = (
  organizationForm: OrganizationForm,
  existingHierarchy?: OrganizationHierarchyItem[]
): OrganizationHierarchyItem => ({
  id: 'placeholder',
  legacyIdentifier: 'placeholder',
  level:
    // reduce to correct type (since the response is flat or nested) or default to a level 2 org in the hierarchy
    (Array.isArray(existingHierarchy)
      ? existingHierarchy.find((org) => org.id === organizationForm.parentId)
          ?.level ?? 1
      : 1) + 1,
  parentId: organizationForm.parentId ?? '',
  businessName: organizationForm.businessName,
  displayName: organizationForm.displayName,
  status: status,
  integrationMetadata: {},
})

export default organizationFormToOrganizationHierarchyItem
