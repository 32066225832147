import React, { useState } from 'react'
import { Layout } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'hooks'
import { useSubscriberQuery, useSubscribersQuery } from 'hooks/organizations'
import { useReminderSchedulesQuery } from 'hooks/reminder-schedules'
import { useReminderMessagesQuery } from 'hooks/reminders'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  SubscriberReminderSchedulesList,
  SubscriberReminderSchedulesForm,
} from 'components/subscriber-reminder-schedules'
import { SubscriberSelect } from 'components/subscribers'
import tw from 'twin.macro'

const SubscriberReminderPresets = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false)
  const [selectedReminderPreset, setSelectedReminderPreset] = useState<
    ReminderSchedule | undefined
  >()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const { subscriberId } = useParams()
  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)
  const subscribers = useSubscribersQuery()

  const reminderMessagesQuery = useReminderMessagesQuery(
    // if the subscriber has an org, use the organization's vital assignments
    currentSubscriber?.organization
      ? assumedOrganizationRole?.orgID || ''
      : // else if they're private pay, use the ECG's organization's vital assignments
        '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const reminderSchedulesQuery = useReminderSchedulesQuery(subscriberId)

  const editReminderPreset = (selectedPreset: ReminderSchedule) => {
    setSelectedReminderPreset(selectedPreset)
    setIsCreateFormOpen(true)
  }

  const handleFormToggle = (isFormOpen: boolean) => {
    // reset selected reminder preset when form is closing
    if (isFormOpen) setSelectedReminderPreset(undefined)

    setIsCreateFormOpen(isFormOpen)
  }
  // empty props for layout depending on what action must be taken next
  const renderEmpty = () => {
    if (!currentSubscriber)
      return (
        <GenericEmpty
          title={t('Subscriber Not Found')}
          description={t(
            `Either this subscriber doesn't exist or you don't have access to view them`
          )}
        />
      )

    if (
      !reminderMessagesQuery.data?.items ||
      reminderMessagesQuery.data.items.length <= 0
    )
      return (
        <GenericEmpty
          title={t('No Reminder Messages Found')}
          description={t(
            'Reminder messages must be created before reminder schedules'
          )}
          callToAction={
            <Button
              type="primary-filled"
              onClick={() =>
                navigate('/organization-settings/reminder-messages')
              }
            >
              {t('Create Reminder Messages')}
            </Button>
          }
        />
      )

    return (
      <GenericEmpty
        title={t('No Data Found')}
        description={t('No reminder schedules exist for this subsciber')}
        callToAction={
          <Button
            type="primary-filled"
            onClick={() => setIsCreateFormOpen(true)}
          >
            {t('Create Reminder Schedule')}
          </Button>
        }
      />
    )
  }

  return (
    <>
      <Layout
        title={
          <div className="flex gap-2">
            <h1 className="text-2xl font-bold">{t('Reminder Schedules')}:</h1>
            <SubscriberSelect />
          </div>
        }
        description={t('View and manage subscriber reminder scheduling.')}
        isLoading={
          reminderMessagesQuery.isLoading ||
          isSubLoading ||
          reminderSchedulesQuery.isLoading ||
          subscribers.isLoading
        }
        isEmpty={
          !reminderSchedulesQuery.data?.items ||
          reminderSchedulesQuery.data.items.length <= 0 ||
          !currentSubscriber
        }
        emptyComponent={renderEmpty()}
        isUnauthorized={
          currentSubscriber?.organization && !assumedOrganizationRole
        }
        breadcrumbs={[
          { name: t('Subscribers'), route: '/subscribers' },
          {
            name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
            route: `/subscribers/${currentSubscriber?.id}`,
          },
          {
            name: t('Subscriber Settings'),
            route: `/subscribers/${currentSubscriber?.id}/settings`,
          },
          { name: t('Reminder Schedules') },
        ]}
        controls={[
          {
            label: t('Create Reminder Schedule'),
            onClick: () => handleFormToggle(true),
            disabled:
              !reminderMessagesQuery.data?.items ||
              reminderMessagesQuery.data.items.length <= 0,
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
            // TODO: enable this audit trail when Vitals API supports subscriber level audits
            disabled: true,
          },
        ]}
      >
        <SubscriberReminderSchedulesList
          editReminderPreset={editReminderPreset}
        />
      </Layout>
      <SubscriberReminderSchedulesForm
        isFormOpen={isCreateFormOpen}
        setIsFormOpen={handleFormToggle}
        currentReminderSchedule={selectedReminderPreset}
      />
    </>
  )
}

export default SubscriberReminderPresets

const GenericEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`
