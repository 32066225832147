import React from 'react'
import clsx from 'clsx'

type RadioButtonGroupProps = {
  options: Array<{ label: string; value: string }>
  value: string
  onChange: (newValue: string) => void
}

const RadioButtonGroup = ({
  options,
  value,
  onChange,
}: RadioButtonGroupProps) => {
  return (
    <div className="inline-flex rounded-lg overflow-hidden">
      {options.map((option) => (
        <div
          className={clsx([
            'px-5 py-2 outline-none focus:ring-2 focus:ring-gray-300 disabled:opacity-50 cursor-pointer',
            option.value === value
              ? 'bg-blue-400 text-white'
              : 'bg-gray-100 text-gray-800',
          ])}
          key={option.value}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  )
}

export default RadioButtonGroup
