import React, { useEffect } from 'react'
import { RightPopup, TextField } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  useAddModuleMutation,
  useUpdateModuleMutation,
} from 'hooks/access-control/modules'

type CreateEditModuleFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentModule?: Module
}

const CreateEditModuleForm = ({
  isFormOpen,
  setIsFormOpen,
  currentModule,
}: CreateEditModuleFormProps) => {
  const { t } = useTranslation()
  const formMethods = useForm<OrgsAPIRequest['POST']['/api/modules']['body']>({
    defaultValues: currentModule
      ? {
          name: currentModule.name,
          description: currentModule.description,
        }
      : undefined,
  })
  const { handleSubmit, errors, reset } = formMethods
  const {
    mutate: addModule,
    isLoading: isAddModuleLoading,
  } = useAddModuleMutation()
  const {
    mutate: updateModule,
    isLoading: isUpdateModuleLoading,
  } = useUpdateModuleMutation()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen)
      reset(
        currentModule
          ? {
              name: currentModule.name,
              description: currentModule.description,
            }
          : undefined
      )
  }, [isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={currentModule ? t('Edit Module') : t('Create Module')}
      controls={
        <>
          <Button
            type="primary-filled"
            htmlType="submit"
            isLoading={isAddModuleLoading || isUpdateModuleLoading}
            onClick={handleSubmit((formData) => {
              if (currentModule) {
                updateModule(
                  {
                    requests: [
                      {
                        value: formData.name,
                        path: '/name',
                        op: 'replace',
                      },
                      ...(formData.description
                        ? [
                            {
                              value: formData.description,
                              path: '/description',
                              op: 'replace' as const,
                            },
                          ]
                        : []),
                    ],
                    moduleId: currentModule.id,
                  },
                  { onSuccess: () => setIsFormOpen(false) }
                )
              } else {
                addModule(
                  {
                    name: formData.name,
                    description: formData.description,
                  },
                  { onSuccess: () => setIsFormOpen(false) }
                )
              }
            })}
          >
            {currentModule ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={isAddModuleLoading || isUpdateModuleLoading}
            onClick={() => {
              // Close form drawer
              setIsFormOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Module Name is required') }}
            name="name"
            label={t('Module Name')}
            fullWidth
            error={errors.name?.message}
            required
          />

          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Module Description is required') }}
            name="description"
            label={t('Description')}
            fullWidth
            multiline
            rows={4}
            error={errors.description?.message}
            required
          />
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default CreateEditModuleForm
