import { FadeInSlideDown } from 'animations'
import React from 'react'
import tw, { styled } from 'twin.macro'

type InformationCardSize = 'lg' | 'md' | 'sm'

type InformationCard = {
  children: React.ReactNode
  className?: string
  size?: InformationCardSize
  noCard?: boolean
  delay?: number
}

const InformationCard = ({
  children,
  className,
  size = 'lg',
  noCard = false,
  delay = 0,
}: InformationCard) => {
  return (
    <Container className={className} delay={delay}>
      {noCard ? (
        <Grid size={size}>{children}</Grid>
      ) : (
        <Card>
          <Grid size={size}>{children}</Grid>
        </Card>
      )}
    </Container>
  )
}

const Container = tw(FadeInSlideDown)``

const Card = tw.div`bg-white p-5 rounded-lg border border-gray-200 min-h-full`

const Grid = styled.div(({ size }: { size: InformationCardSize }) => [
  tw`gap-x-3 gap-4`,
  size === 'lg' && tw`grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3`,
  size === 'md' && tw`grid grid-cols-1 xl:grid-cols-2`,
  size === 'sm' && tw`grid grid-cols-1`,
])

export default InformationCard

type InformationCardItemProps = {
  label: React.ReactNode
  value: React.ReactNode
  displayMode?: 'labelValue' | 'keyValue'
  className?: string | undefined
}

const InformationCardItem = ({
  label,
  value,
  className,
  displayMode = 'labelValue',
}: InformationCardItemProps) => {
  return (
    <Item displayMode={displayMode} className={className}>
      <Label displayMode={displayMode}>{label}</Label>
      {displayMode === 'keyValue' ? <>:&nbsp;&nbsp;</> : null}
      <Value displayMode={displayMode}>{value}</Value>
    </Item>
  )
}

const Item = styled.span(
  ({
    displayMode,
  }: {
    displayMode: InformationCardItemProps['displayMode']
  }) => [
    tw`inline-flex`,
    displayMode === 'keyValue' && tw`items-center h-6`,
    displayMode === 'labelValue' && tw`flex-col gap-0.5`,
  ]
)

const Label = styled.p(
  ({
    displayMode,
  }: {
    displayMode: InformationCardItemProps['displayMode']
  }) => [
    tw`text-gray-500 leading-4`,
    displayMode === 'labelValue' && tw`font-medium text-sm`,
  ]
)

const Value = styled.p(
  ({
    displayMode,
  }: {
    displayMode: InformationCardItemProps['displayMode']
  }) => [
    tw`text-gray-900 leading-4`,
    displayMode === 'labelValue' && tw`text-lg font-medium whitespace-normal`,
  ]
)

InformationCard.Item = InformationCardItem
