import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

type DropdownItemChildren = string | React.ReactNode

export type DropdownItemProps = {
  children: DropdownItemChildren | DropdownItemChildren[]
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
  hoverBackgroundColor?: string
  disableAnimations?: boolean
  variant?: 'default' | 'thin'
}

const DropdownItem = ({
  children,
  onClick,
  className,
  hoverBackgroundColor = 'hover:bg-gray-50',
  disableAnimations,
  variant = 'default',
}: DropdownItemProps) => {
  return (
    <motion.div
      variants={disableAnimations ? undefined : itemVariants}
      onClick={onClick}
      className={clsx(
        'whitespace-nowrap cursor-default',
        variant === 'default' && 'py-2 px-4',
        variant === 'thin' && 'py-1 px-2 text-sm',
        className,
        hoverBackgroundColor
      )}
      role="option"
    >
      {children}
    </motion.div>
  )
}

export default DropdownItem

const itemVariants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: -10,
  },
}
