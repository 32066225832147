import _ from 'lodash'

/**
 * Converts an email into a hidden email for Hipaa guide lines
 * @param email - email string
 * @returns A string of original email param, but username is hidden other than first character
 */

type hideEmailArgs = {
  email: string
}
const hideEmail = ({ email }: hideEmailArgs) => {
  // split email into username and domain, keep username
  const split = _.split(email, '@')[0]

  // replace the username with '*', then replace 1st '*' with 1st char of username
  if (email) {
    const hide = _.replace(
      _.replace(email, split, _.repeat('*', split.length)),
      '*',
      split[0]
    )
    return hide
  }
  return email
}

export default hideEmail
