import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { MedicationStatistics } from 'components/medications'

const Medications = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  return (
    <Layout
      title={t('Medications')}
      description={
        assumedOrganizationRole
          ? t('Manage medications for this organization')
          : t('Manage private-pay and organization medications')
      }
      controls={[
        {
          label: t('View Audit Trail'),
          onClick: () => navigate('audit-trail'),
          disabled: true,
        },
      ]}
    >
      <MedicationStatistics />
    </Layout>
  )
}

export default Medications
