import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useUpdatePermissionMutation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/permissions/{permissionId}'],
    KyError,
    editPermissionProps
  >(updatePermission, {
    onSuccess: () => {
      queryClient.invalidateQueries('getPermissions')
      queryClient.invalidateQueries('getModules')

      success({ message: t('Permission updated') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useUpdatePermissionMutation

export type editPermissionProps = {
  permissionId: string
  req: OrgsAPIRequest['PATCH']['/api/permissions/{permissionId}']['body']
}

const updatePermission = async ({ permissionId, req }: editPermissionProps) => {
  const result = typedOrgsApi.patch('/api/permissions/{permissionId}', {
    pathParams: { permissionId },
    body: req,
  })

  return result
}

type ouGetModulesQueryToUpdatePermissionArgs = {
  newPermission: Permission
}

// optimistic update for the getModules query when a permission is updated
export const ouGetModulesQueryToUpdatePermission = ({
  newPermission,
}: ouGetModulesQueryToUpdatePermissionArgs) => {
  const queryClient = useQueryClient()
  queryClient.setQueryData<OrgsAPIResponse['GET']['/api/modules'] | undefined>(
    ['getModules'],
    (old) => {
      if (!old) return undefined

      const newValue: OrgsAPIResponse['GET']['/api/modules'] = {
        ...old,
        items: old.items?.map((module) => {
          if (module.id === newPermission.module.id) {
            // if no permissions object, don't try to update the module
            if (!module.permissions) return module

            return {
              ...module,
              permissions: module.permissions.map((permission) => {
                if (permission.id === newPermission.id) return newPermission

                return permission
              }),
            }
          }

          return module
        }),
      }

      return newValue
    }
  )
}
