import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

const useDisclaimerTypesQuery = () => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/disclaimer-types'],
    KyError
  >('getDisclaimerTypes', getDisclaimerTypes)

  return query
}

export default useDisclaimerTypesQuery

const getDisclaimerTypes = async () => {
  const result = vitalsApi
    .get('disclaimer-types')
    .json<VitalsAPIResponse['GET']['/disclaimer-types']>()

  return result
}
