import { rest } from 'msw'

const statisticHandlers = [
  // get global member statistics
  rest.get<undefined, OrgsAPIResponse['GET']['/api/member-statistics']>(
    '/api/member-statistics',
    (req, res, ctx) =>
      res(
        ctx.json({
          organizations: {
            total: 1,
          },
          partners: {
            total: 0,
          },
          subscribers: { total: 1 },
        })
      )
  ),

  // get organization member statistics
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/member-statistics']
  >('/api/organizations/:organizationId/member-statistics', (req, res, ctx) =>
    res(
      ctx.json({
        organizations: {
          total: 0,
        },
        staffMembers: { total: 1 },
        subscribers: {
          total: 0,
        },
      })
    )
  ),
]

export default statisticHandlers
