import { rest } from 'msw'
import faker from 'faker'
import {
  generateAuditable,
  generateArchivable,
  generateDisableable,
  generatePaginatable,
  generateParty,
} from '../utils'
import db from '../../data/db'
import { isPresent } from 'utils'

const staffHandlers = [
  // get staff list
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members']
  >('/api/organizations/:orgId/staff-members', (req, res, ctx) =>
    res(
      ctx.json({
        items: db.get<StaffMember[]>('staffList'),
        recordsPerPage: 10,
        totalRecords: 0,
        currentPageNumber: 1,
        totalPages: 0,
      })
    )
  ),

  // add staff
  rest.post<
    OrgsAPIRequest['POST']['/api/organizations/{organizationId}/staff-members']['body'],
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/staff-members']
  >('/api/organizations/:orgId/staff-members', (req, res, ctx) => {
    const staffList = db.get<StaffMember[]>('staffList')

    const organization = db
      .get<Organization[]>('organizationList')
      .find((org) => org.id === req.params.orgId)

    if (!organization) throw new Error('Organization not found')

    const response = {
      id: faker.random.uuid(),
      isProvider: req.body.isProvider,
      memberType: req.body.memberType,
      policies: req.body.policies || [],
      person: {
        id: faker.random.uuid(),
        suffix: '',
        firstName: '',
        lastName: '',
        salutation: 0,
        emailAddress: '',
        party: generateParty(),
      },
      organization: organization,
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
    }

    // update the db
    db.set<StaffMember[]>('staffList', [...staffList, response])

    return res(ctx.json(response))
  }),

  // staff member details
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members/{personId}']
  >('/api/organizations/:orgId/staff-members/:staffId', (req, res, ctx) => {
    const staffList = db.get<StaffMember[]>('staffList')

    const staffMember = staffList.find(
      (staff) => staff.id === req.params.staffId
    )

    if (!staffMember) throw new Error('No staff member with provided id found')

    return res(ctx.json(staffMember))
  }),

  // patch staff member details
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/organizations/{organizationId}/staff-members/{personId}']['body'],
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}/staff-members/{personId}']
  >('/api/organizations/:orgId/staff-members/:staffId', (req, res, ctx) => {
    const staffList = db.get<StaffMember[]>('staffList')

    const staffMember = staffList.find(
      (staff) => staff.id === req.params.staffId
    )

    if (!staffMember) throw new Error('No staff member with provided id found')

    // update the staff member list
    const updatedStaffList = staffList.map((staff) => {
      if (staff.id === req.params.staffId)
        return {
          ...staff,
          policies:
            req.body.find((updateObj) => updateObj.path.includes('policies'))
              ?.value || staff.policies,
          person: {
            ...staff.person,
            firstName:
              req.body.find((updateObj) => updateObj.path.includes('firstName'))
                ?.value || staff.person.firstName,
            lastName:
              req.body.find((updateObj) => updateObj.path.includes('lastName'))
                ?.value || staff.person.lastName,
            salutation:
              req.body.find((updateObj) =>
                updateObj.path.includes('salutation')
              )?.value || staff.person.salutation,
            suffix:
              req.body.find((updateObj) => updateObj.path.includes('suffix'))
                ?.value || staff.person.suffix,
          },
        } as StaffMember

      return staff
    })

    // update the db
    db.set<StaffMember[]>('staffList', updatedStaffList)

    const updatedStaffMember = updatedStaffList.find(
      (staff) => staff.id === req.params.staffId
    )

    if (!updatedStaffMember)
      throw new Error('No staff member exists for this update')

    return res(ctx.json(updatedStaffMember))
  }),

  //get blocked organizations
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/staff-members/{personId}/blocked-organizations']
  >(
    '/api/staff-members/:staffMemberId/blocked-organizations',
    (req, res, ctx) => {
      const blockedOrgs = db.get<Organization[]>('blockedOrganizationList')

      if (!isPresent(blockedOrgs))
        throw new Error('No blocked organizations with provided staff id found')

      return res(
        ctx.json({
          items: blockedOrgs,
          ...generatePaginatable(),
        })
      )
    }
  ),

  // block an organization
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/staff-members/{personId}/block'],
    { organizationId: string; staffMemberId: string }
  >(
    '/api/organizations/:organizationId/staff-members/:staffMemberId/block',
    (req, res, ctx) => {
      const blockedOrgList = db.get<Organization[]>('blockedOrganizationList')

      const blockItem: Organization = db
        .get<Organization[]>('organizationList')
        .find((org) => org.id === req.params.organizationId) as Organization

      db.set<Organization[]>('blockedOrganizationList', [
        ...blockedOrgList,
        blockItem,
      ])

      return res(ctx.json({ value: true }))
    }
  ),

  //unblock an organization
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/staff-members/{personId}/unblock'],
    { organizationId: string; staffMemberId: string }
  >(
    '/api/organizations/:organizationId/staff-members/:staffMemberId/unblock',
    (req, res, ctx) => {
      const updatedBlockedList = db
        .get<Organization[]>('blockedOrganizationList')
        .filter((org) => org.id !== req.params.organizationId)

      db.set<Organization[]>('blockedOrganizationList', updatedBlockedList)

      return res(ctx.json({ value: true }))
    }
  ),
]

export default staffHandlers
