import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, typedVitalsApi, handleQueryError } from 'utils'
import faker from 'faker'

// used to create Reminder preset
const useCreateReminderPresetMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['POST']['/organizations/{organizationId}/reminder-presets'],
    KyError,
    createReminderPresetArgs,
    | VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
    | undefined
  >(createReminderPreset, {
    onMutate: (data) => {
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
      >(['getReminderPresets', data.orgId])

      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
        | undefined
      >(['getReminderPresets', data.orgId], (oldData) => {
        if (!oldData?.items) return undefined
        return {
          items: [
            ...oldData.items,
            {
              ...data.preset,
              schedule: data.schedule,
              organizationId: data.orgId,
              id: faker.random.uuid(),
            },
          ],
        }
      })

      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Reminder preset created') })
    },
    onError: (error, { orgId }, snapshot) => {
      //revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
        | undefined
      >(['getReminderPresets', orgId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      queryCache.invalidateQueries(['getReminderPresets', args.orgId])
    },
  })

  return mutation
}

export default useCreateReminderPresetMutation

type createReminderPresetArgs = {
  orgId: string

  preset: { name: string; description: string }
  schedule: Schedule
}

const createReminderPreset = async (data: createReminderPresetArgs) =>
  typedVitalsApi.post(`/organizations/{organizationId}/reminder-presets`, {
    body: {
      schedule: data.schedule,
      name: data.preset.name,
      description: data.preset.description,
    },
    pathParams: { organizationId: data.orgId },
  })
