import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError, isPresent } from 'utils'

const useArchivePermissionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/permissions/{permissionId}/archive'],
    KyError,
    string
  >(archivePermission, {
    onSuccess: () => {
      queryCache.invalidateQueries('getPermissions')
      queryCache.invalidateQueries('getModules')

      success({ message: t('Permission archived') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useArchivePermissionMutation

const archivePermission = async (permissionId: string) => {
  const result = typedOrgsApi.post('/api/permissions/{permissionId}/archive', {
    pathParams: { permissionId },
  })

  return result
}

type ouGetPermissionsQueryToArchivePermissionArgs = {
  permissionId: string
}

export const ouGetPermissionsQueryToArchivePermission = ({
  permissionId,
}: ouGetPermissionsQueryToArchivePermissionArgs) => {
  const queryCache = useQueryClient()
  queryCache.setQueryData<
    OrgsAPIResponse['GET']['/api/permissions'] | undefined
  >(['getPermissions'], (old) => {
    if (!old) return undefined

    const newValue: OrgsAPIResponse['GET']['/api/permissions'] = {
      ...old,
      items:
        old.items?.map((permission) => {
          if (permission.id === permissionId)
            return {
              ...permission,
              archiveInfo: {
                ...permission.archiveInfo,
                archived: true,
              },
            }

          return permission
        }) || [],
    }

    return newValue
  })
}

type ouGetModulesQueryToArchivePermissionArgs = {
  currentModule: Module
  permissionId: string
}

export const ouGetModulesQueryToArchivePermission = ({
  currentModule,
  permissionId,
}: ouGetModulesQueryToArchivePermissionArgs) => {
  const queryCache = useQueryClient()
  queryCache.setQueryData<OrgsAPIResponse['GET']['/api/modules'] | undefined>(
    ['getModules'],
    (old) => {
      if (!old || !isPresent(currentModule)) return undefined

      const newModuleValue: Module = {
        ...currentModule,
        permissions: currentModule.permissions?.map((permission) => {
          if (permission.id === permissionId) {
            return {
              ...permission,
              archiveInfo: {
                ...permission.archiveInfo,
                archived: true,
              },
            }
          }

          return permission
        }),
      }

      const newValue: OrgsAPIResponse['GET']['/api/modules'] = {
        ...old,
        items: old.items?.map((module) => {
          if (module.id === currentModule.id) {
            return newModuleValue
          }

          return module
        }),
      }

      return newValue
    }
  )
}
