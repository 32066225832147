import React, { useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { useClickAway } from 'react-use'
import { Icon } from 'elements'
import { Tooltip } from 'atlas'
import { formatPrice } from 'utils'

const OPTION_HEIGHT = 56

const CONTAINER_Y_OFFSET = 67

const TOOLTIP_Y_OFFSET = 62

type WarrantyCardProps = {
  lineItem: (
    | ContractLineItem['primaryProductLineItem']
    | ContractLineItem['deviceOrServiceLineItem']
  ) & {
    selectedWarrantyId: string | null | undefined
  }
  setLineItem: (
    lineItem: (
      | ContractLineItem['primaryProductLineItem']
      | ContractLineItem['deviceOrServiceLineItem']
    ) & {
      selectedWarrantyId: string | null | undefined
    }
  ) => void
}

const WarrantyCard = ({ lineItem, setLineItem }: WarrantyCardProps) => {
  const { t } = useTranslation()

  const [isWarrantyOptionsOpen, setIsWarrantyOptionsOpen] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  useClickAway(containerRef, () => setIsWarrantyOptionsOpen(false))

  return lineItem.pricing?.warrantySelection?.options?.length ? (
    <>
      <Container
        ref={containerRef}
        transition={{ type: 'spring', duration: 0.3 }}
        isOptionsOpen={isWarrantyOptionsOpen}
        role="combobox"
        aria-label={
          lineItem.pricing.warrantySelection.options.find(
            (option) => option.id === lineItem.selectedWarrantyId
          )?.priceDescriptor?.descriptionInfo?.title || 'No Warranty Selected'
        }
        animate={{
          height: isWarrantyOptionsOpen
            ? OPTION_HEIGHT *
              (lineItem.pricing.warrantySelection.options.length + 1)
            : OPTION_HEIGHT,
          top: isWarrantyOptionsOpen
            ? CONTAINER_Y_OFFSET -
              (lineItem.selectedWarrantyId
                ? OPTION_HEIGHT *
                  ((lineItem.pricing.warrantySelection.options.findIndex(
                    (option) => option.id === lineItem.selectedWarrantyId
                  ) || 0) +
                    1)
                : 0)
            : CONTAINER_Y_OFFSET,
        }}
        onClick={(e) => {
          e.stopPropagation()

          setIsWarrantyOptionsOpen(!isWarrantyOptionsOpen)
        }}
      >
        <OptionsContainer
          transition={{ type: 'spring', duration: 0.3 }}
          animate={{
            top:
              lineItem.selectedWarrantyId && !isWarrantyOptionsOpen
                ? -(
                    ((lineItem.pricing.warrantySelection.options.findIndex(
                      (option) => option.id === lineItem.selectedWarrantyId
                    ) || 0) +
                      1) *
                    OPTION_HEIGHT
                  )
                : 0,
          }}
        >
          <NoWarrantyOption
            isSelected={!lineItem.selectedWarrantyId}
            onClick={() => {
              if (isWarrantyOptionsOpen && lineItem.selectedWarrantyId) {
                setLineItem({
                  ...lineItem,
                  selectedWarrantyId: null,
                })
              }
            }}
          >
            <WarrantyFeeTitle>{t('No Warranty Selected')}</WarrantyFeeTitle>
          </NoWarrantyOption>
          {lineItem.pricing.warrantySelection.options.map((option) => (
            <WarrantyOption
              key={option.id}
              isOptionsOpen={isWarrantyOptionsOpen}
              isSelected={option.id === lineItem.selectedWarrantyId}
              onClick={() => {
                if (
                  isWarrantyOptionsOpen &&
                  lineItem.selectedWarrantyId !== option.id
                ) {
                  setLineItem({
                    ...lineItem,
                    selectedWarrantyId: option.id || null,
                  })
                }
              }}
            >
              <WarrantyFeeTitle>
                {option.priceDescriptor?.descriptionInfo?.title || ''}
              </WarrantyFeeTitle>
              {option.priceDescriptor?.price ? (
                <WarrantyFeePrice>
                  {formatPrice(option.priceDescriptor.price)}
                </WarrantyFeePrice>
              ) : null}
            </WarrantyOption>
          ))}
        </OptionsContainer>
      </Container>

      {/* Tooltips have to be outside Container or they're occluded because Container has overflow-hidden */}
      {(() => {
        const selectedWarrantyIndex = lineItem.pricing.warrantySelection.options.findIndex(
          (option) => option.id === lineItem.selectedWarrantyId
        )

        return lineItem.pricing.warrantySelection.options.map((option, index) =>
          (isWarrantyOptionsOpen || index === selectedWarrantyIndex) &&
          option.priceDescriptor?.descriptionInfo?.description ? (
            <TooltipContainer
              top={
                (index - selectedWarrantyIndex) * OPTION_HEIGHT +
                TOOLTIP_Y_OFFSET
              }
            >
              <InfoTooltip
                anchor="right"
                content={
                  <TooltipText>
                    {option.priceDescriptor.descriptionInfo.description}
                  </TooltipText>
                }
              >
                <InfoIcon type="info" />
              </InfoTooltip>
            </TooltipContainer>
          ) : null
        )
      })()}
    </>
  ) : null
}

export default WarrantyCard

const Container = styled(motion.div)<{
  isOptionsOpen: boolean
}>(({ isOptionsOpen }) => [
  tw`border rounded shadow -mt-2 hover:shadow-md cursor-pointer absolute overflow-hidden w-64 right-0`,
  // This makes sure open warranty options are always above closed ones
  isOptionsOpen ? tw`z-40` : tw`z-30`,
])
const OptionsContainer = tw(motion.div)`absolute left-0`

const WarrantyFeeTitle = tw.div`text-sm text-gray-600 truncate mr-4 leading-4`

const WarrantyOption = styled.div<{
  isOptionsOpen: boolean
  isSelected: boolean
}>(({ isOptionsOpen, isSelected }) => [
  tw`h-14 px-4 py-2 w-64`,
  isOptionsOpen && tw`border-t`,
  isSelected ? tw`bg-green-50` : tw`bg-white`,
])

const NoWarrantyOption = styled.div<{ isSelected: boolean }>(
  ({ isSelected }) => [
    tw`h-14 px-4 py-2 w-64`,
    isSelected ? tw`bg-red-50` : tw`bg-white`,
  ]
)

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`ml-2 h-fit-content`

const TooltipText = tw.p`w-60`

const TooltipContainer = styled.div<{ top: number }>`
  ${() => tw`absolute z-50`}
  right: 4px;
  top: ${({ top }) => top}px;
`

const WarrantyFeePrice = tw.div``
