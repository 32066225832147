import React, { useState, useRef, useMemo, useEffect } from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import EntityLabel from './EntityLabel'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { useDebounce } from 'react-use'
import { LoadingIcon } from 'elements'

type Option<Value> = {
  value: Value
  label: React.ReactNode
  searchValue?: string
  disabled?: boolean
}

type BaseCustomSelectProps<Value> = {
  options: Array<Option<Value>>
  placeholder?: string
  placeholderIcon?: React.ReactNode
  className?: string
  defaultValue?: Value
  value?: Value
  disabled?: boolean
  onChange?: (value: Option<Value>) => void
  variant?: 'default' | 'thin'
  ['data-testid']?: string
  id?: string
}

export type ExtendedCustomSelectProps<Value> = BaseCustomSelectProps<Value> &
  // only allow search props when searchable
  (| {
        searchable?: false
        emptyText?: never
        setDebouncedSearchTerm?: never
        isLoading?: never
      }
    | {
        searchable: true
        emptyText?: string
        setDebouncedSearchTerm?: (searchTerm: string) => void
        isLoading?: boolean
      }
  )

const CustomSelect = <Value extends unknown>({
  options,
  placeholder = 'Select...',
  placeholderIcon,
  className,
  defaultValue,
  value,
  disabled,
  onChange,
  variant = 'default',
  ['data-testid']: dataTestId,
  id,
  searchable = false,
  emptyText,
  setDebouncedSearchTerm,
  isLoading,
}: ExtendedCustomSelectProps<Value>) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const [dropdownItems, setDropdownItems] = useState<Array<Option<Value>>>(
    options
  )

  const [selectedOption, setSelectedOption] = useState<
    { value: Value; label: React.ReactNode } | undefined
  >(() => {
    // don't define this state if controlled component
    if (value) return undefined

    return options.find((item) => _.isEqual(item.value, defaultValue))
  })

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

  const [selected, setSelected] = useState<boolean>(false)

  const dropdownAnchorEl = useRef<HTMLDivElement>(null)

  const inputEl = useRef<HTMLInputElement>(null)

  const [selectedIndex, setSelectedIndex] = useState(
    dropdownItems.findIndex((item) => item.value === value) || 0
  )

  // debounce searchTerm value before setting public value.. used when calling API
  useDebounce(
    () => {
      setDebouncedSearchTerm?.(searchTerm)
    },
    500,
    [searchTerm]
  )

  useEffect(() => {
    setDropdownItems(options)
  }, [options])

  const paginated =
    setDebouncedSearchTerm !== undefined || isLoading !== undefined

  const controlledValue = useMemo(() => {
    return options.find((item) => _.isEqual(item.value, value))
  }, [value])

  return (
    <div className={className}>
      <DropdownContainer
        tabIndex={0}
        disabled={disabled}
        variant={variant}
        ref={dropdownAnchorEl}
        onClick={() => {
          setIsDropdownVisible(!isDropdownVisible)

          // focus searchbox onClick
          if (searchable) inputEl.current?.focus()
        }}
        data-testid={dataTestId}
        selected={selected}
        searchable={searchable}
      >
        {searchable ? (
          <>
            <EntityLabel
              name={placeholder}
              id={id || `${placeholder}-id`}
              iconOnly
              size="sm"
            />
            <Search
              onFocus={() => setSelected(true)}
              onBlur={() => setSelected(false)}
              ref={inputEl}
              placeholder={placeholder}
              selected={selected}
              value={searchTerm}
              searchable={searchable}
              onChange={(e) => {
                setSearchTerm(e.target.value)

                // Only filter items if server based filtering does not seem to be used
                if (!paginated)
                  setDropdownItems(
                    options.filter((op) => {
                      if (op.searchValue)
                        return op.searchValue
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())

                      return (typeof op.label === 'string'
                        ? op.label.toLowerCase()
                        : ''
                      ).includes(e.target.value.toLowerCase())
                    })
                  )
              }}
            />

            <Icon
              className="ml-1 z-50"
              type={selected ? 'search' : 'chevron-down'}
            />
          </>
        ) : placeholderIcon ? (
          <>
            <div>
                {placeholderIcon}
              <PlaceholderIconText variant={variant}>
                {placeholder}
              </PlaceholderIconText>
            </div>
            <Icon type="chevron-down" />
          </>
        ) : (
          <>
            <span
              className={clsx(
                controlledValue?.label || selectedOption?.label
                  ? 'text-gray-900'
                  : 'text-gray-600',
                variant === 'thin' && 'text-sm',
                variant === 'default' && 'ml-2'
              )}
            >
              {controlledValue?.label || selectedOption?.label || placeholder}
            </span>

            <Icon type="chevron-down" />
          </>
        )}

        <Dropdown
          scrollToIndex={selectedIndex}
          visible={isDropdownVisible}
          setVisible={setIsDropdownVisible}
          parentRef={dropdownAnchorEl}
          isParentWidth
          verticalOffset={4}
          animationType="simple"
        >
          {(() => {
            // if there is server-based pagination ignore local dropdownItems
            if (!(paginated ? options : dropdownItems).length)
              return (
                <EmptyContainer>
                  <EmptyIcon type="empty" />
                  <EmptyText>{emptyText || t('No data found')}</EmptyText>
                </EmptyContainer>
              )

            if (isLoading)
              return (
                <EmptyContainer>
                  <LoadingIcon />
                </EmptyContainer>
              )

            return (paginated ? options : dropdownItems).map((option, key) => {
              return (
                <DropdownItem
                  key={key}
                  className={clsx(
                    'truncate flex',
                    (controlledValue?.value === option.value ||
                      selectedOption?.value === option.value) &&
                      'bg-blue-400 text-white hover:bg-blue-400',
                    option.disabled
                      ? 'text-gray-500 bg-gray-100 hover:bg-gray-100 cursor-not-allowed'
                      : 'cursor-pointer'
                  )}
                  onClick={(e) => {
                    // if disabled prevent dropdown from closing and return immediately
                    if (option.disabled) {
                      e.stopPropagation()
                      return
                    }

                    // clear the search term
                    setSearchTerm('')

                    setSelectedIndex(key)

                    // if controlled just call the onChange
                    if (onChange) return onChange(option)

                    setSelectedOption(option)
                  }}
                  hoverBackgroundColor={clsx(
                    controlledValue?.value === option.value ||
                      selectedOption?.value === option.value
                      ? 'hover:bg-blue-400'
                      : 'hover:bg-gray-50'
                  )}
                  variant={variant}
                >
                  {option.label}
                </DropdownItem>
              )
            })
          })()}
        </Dropdown>
      </DropdownContainer>
    </div>
  )
}

export default CustomSelect

const DropdownContainer = styled.div<{
  disabled: boolean | undefined
  variant: 'default' | 'thin'
  selected: boolean
  searchable: boolean
}>(({ disabled, variant, selected, searchable }) => [
  tw`flex items-center justify-between bg-white relative border border-gray-200 hover:border-gray-300 outline-none focus:ring-2 focus:ring-blue-300 focus:border-white rounded select-none transition-all appearance-none`,
  disabled && tw`opacity-50 cursor-not-allowed`,
  variant === 'default' && tw`p-2`,
  variant === 'thin' && tw`px-2 py-1`,
  selected && tw`ring-2 ring-blue-300`,
  searchable && tw`cursor-text`,
])

const Search = styled.input<{ selected: boolean; searchable: boolean }>`
  ${tw`cursor-default focus:outline-none min-w-0`} // min-w is needed to prevent weird overflow on Firefox
  ${({ selected, searchable }) => [
    selected ? tw`placeholder-gray-400` : tw`placeholder-gray-900`,
    searchable ? tw`cursor-text` : tw`cursor-default`,
  ]}
`

const EmptyContainer = tw.div`p-2 flex flex-row items-center h-32`

const EmptyIcon = tw(Icon)`text-gray-400 mr-2.5 w-6 h-5`

const EmptyText = tw.p`text-sm text-gray-400`

const PlaceholderIconText = styled.span<{ variant: 'default' | 'thin' }>(
  ({ variant }) => [
    tw`inline-block ml-2 text-gray-900`,
    variant === 'thin' && tw`text-sm`,
    variant === 'default' && tw`ml-2`,
  ]
)
