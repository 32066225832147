import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

const useUsersInfo = (userIds: string[]) => {
  const query = useQuery<
    Array<OrgsAPIResponse['GET']['/api/users/{userId}/accounts']>,
    KyError
  >(['getUserInfo', userIds], () => getUsersInfo(userIds), {
    enabled: !!window.Cypress || userIds.length > 0,
  })

  return query
}

export default useUsersInfo

const getUsersInfo = async (userIds: Array<string>) =>
  await Promise.all(
    userIds.map(
      async (userId) =>
        await typedOrgsApi.get('/api/users/{userId}/accounts', {
          searchParams: { type: 'idp' },
          pathParams: { userId },
        })
    )
  )
