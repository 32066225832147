import React from 'react'
import { RightPopup, TextField } from 'elements'
import { Button } from 'atlas'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePasswordMutation } from 'hooks/user-profile'
import { getAccessToken } from 'utils'
import { useUserQuery } from 'hooks/user-profile'

type ChangePasswordFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ChangePasswordForm = ({
  isFormOpen,
  setIsFormOpen,
}: ChangePasswordFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<Omit<UpdatePasswordForm, 'accessToken'>>()
  const { handleSubmit, errors, register } = formMethods

  const user = useUserQuery()

  const changePassword = usePasswordMutation()

  const token = getAccessToken()

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Change Password')}
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={handleSubmit(async (formData) => {
              changePassword.mutate(
                {
                  ...formData,
                  accessToken: token.toString(),
                  personId: user.data?.person.id || '',
                },
                {
                  onSuccess: () =>
                    // close the popup
                    setIsFormOpen(false),
                }
              )
            })}
            isLoading={changePassword.isLoading}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            onClick={() => setIsFormOpen(false)}
            disabled={changePassword.isLoading}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <TextField
            name="previousPassword"
            error={errors.previousPassword?.message}
            inputRef={register({
              required: `${t('Password is required')}`,
            })}
            label={t('Old Password')}
            fullWidth
            required
            type="password"
          />

          <TextField
            name="proposedPassword"
            error={errors.proposedPassword?.message}
            inputRef={register({
              required: `${t('Password is required')}`,
            })}
            label={t('New Password')}
            fullWidth
            required
            type="password"
          />
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default ChangePasswordForm
