import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, vitalsApi, handleQueryError } from 'utils'

const useUpdateVitalAssignmentMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/organizations/{organizationId}/vital-sign-assignments/{vitalSignAssignmentId}/update'],
    KyError,
    updateVitalAssignmentProps,
    | VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
    | undefined
  >(updateVitalAssignment, {
    onMutate: ({
      vitalAssignments: { id: vitalAssignmentsId },
      orgId,
      updatedVitalAssignment,
    }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
        | undefined
      >(['getVitalAssignments', orgId])

      // optimistic update
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
      >(['getVitalAssignments', orgId], (oldVitalAssignments) => ({
        id: '',
        organizationId: orgId,
        assignedVitalSigns:
          oldVitalAssignments?.assignedVitalSigns.map((vitalAssignment) => {
            if (vitalAssignment.id === vitalAssignmentsId)
              return {
                ...vitalAssignment,
                code: updatedVitalAssignment.code,
              }

            return vitalAssignment
          }) || [],
      }))

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Vital Sign updated') })
    },
    onError: async (error, { orgId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
        | undefined
      >(['getVitalAssignments', orgId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { orgId }) => {
      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries(['getVitalAssignments', orgId])
    },
  })

  return mutation
}

export default useUpdateVitalAssignmentMutation

type updateVitalAssignmentProps = {
  updatedVitalAssignment: VitalAssignmentForm
  orgId: string
  vitalAssignments: OrgVitalAssignments
}

const updateVitalAssignment = async ({
  updatedVitalAssignment,
  orgId,
  vitalAssignments,
}: updateVitalAssignmentProps) => {
  const request: VitalsAPIRequest['PATCH']['/organizations/{organizationId}/vital-sign-assignments/{vitalSignAssignmentId}/update']['body'] = {
    vitalSign: updatedVitalAssignment,
  }
  const result = vitalsApi
    .patch(
      `organizations/${orgId}/vital-sign-assignments/${vitalAssignments.id}/update`,
      {
        json: request,
      }
    )
    .json<
      VitalsAPIResponse['PATCH']['/organizations/{organizationId}/vital-sign-assignments/{vitalSignAssignmentId}/update']
    >()

  // if the updated vital assignment is a blood pressure assignment find and update the other one
  const otherBPVitalAssignment = vitalAssignments.assignedVitalSigns
    .find((vitalAssignment) => vitalAssignment.id === updatedVitalAssignment.id)
    ?.name?.includes('Blood Pressure')
    ? vitalAssignments.assignedVitalSigns.find(
        (vitalAssignment) =>
          vitalAssignment.id !== updatedVitalAssignment.id &&
          vitalAssignment.name?.includes('Blood Pressure')
      )
    : undefined

  if (otherBPVitalAssignment) {
    const request: VitalsAPIRequest['PATCH']['/organizations/{organizationId}/vital-sign-assignments/{vitalSignAssignmentId}/update']['body'] = {
      vitalSign: {
        id: otherBPVitalAssignment.id,
        code: updatedVitalAssignment.code,
      },
    }
    vitalsApi
      .patch(
        `organizations/${orgId}/vital-sign-assignments/${vitalAssignments.id}/update`,
        {
          json: request,
        }
      )
      .json<
        VitalsAPIResponse['PATCH']['/organizations/{organizationId}/vital-sign-assignments/{vitalSignAssignmentId}/update']
      >()
  }
  return result
}
