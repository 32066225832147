import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

type useTotalAlertsByCategoryQueryArgs = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const useTotalAlertsByCategoryQuery = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: useTotalAlertsByCategoryQueryArgs) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/insights/vitals-metrics/alert-categories/statistics'],
    KyError
  >(
    ['getTotalAlertsByCategory', dateRangeState, organizationId, subscriberId],
    () => getTotalAlertsByCategory(dateRangeState, organizationId, subscriberId)
  )

  return query
}

export default useTotalAlertsByCategoryQuery

const getTotalAlertsByCategory = async (
  dateRangeState: DateRangeState,
  organizationId?: string,
  subscriberId?: string
) =>
  typedVitalsApi.get(`/insights/vitals-metrics/alert-categories/statistics`, {
    searchParams: {
      dateRangeType: dateRangeState.preset,
      organizationId,
      subscriberId,
      ...(dateRangeState.preset === 'custom'
        ? {
            from: dateRangeState.value[0].toISOString(),
            to: dateRangeState.value[1].toISOString(),
          }
        : {}),
      alertCategoryCode: ['MED-DEV-COND', 'VSA', 'sys-conn-lost'],
    },
  })
