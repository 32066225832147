import React, { useState } from 'react'
import { Checkbox, EntityLabel, LoadingIcon, RightPopup } from 'elements'
import { Button, Empty } from 'atlas'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateAlertDeliveryRuleMutation } from 'hooks/alert-delivery-rules'
import { toUpdateObj } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useStaffListQuery } from 'hooks/user-staff'
import tw from 'twin.macro'

type EditRecipientsFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  alertDeliveryRule: AlertDeliveryRule
}

const EditRecipientsForm = ({
  isFormOpen,
  setIsFormOpen,
  alertDeliveryRule,
}: EditRecipientsFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const [staffMembers, setStaffMembers] = useState<
    Array<{ staff: StaffMember; selected: boolean }>
  >([])

  const staffList = useStaffListQuery(assumedOrganizationRole?.orgID)

  const updateAlertDeliveryRule = useUpdateAlertDeliveryRuleMutation()

  // set staff members when form opens or data loads
  useEffect(() => {
    if (isFormOpen)
      setStaffMembers(
        staffList.data?.items?.map((staffMember) => ({
          staff: staffMember,
          selected: !!alertDeliveryRule.delivery.contacts.find(
            (contact) => contact.id === staffMember.id
          ),
        })) || []
      )
  }, [staffList.data, alertDeliveryRule, isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Edit Recipients')}
      description={alertDeliveryRule.name || ''}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateAlertDeliveryRule.isLoading}
            disabled={staffMembers.every(
              (staffMember) => !staffMember.selected
            )}
            onClick={() => {
              // API call
              updateAlertDeliveryRule.mutate(
                {
                  requests: [
                    toUpdateObj('delivery', {
                      message: alertDeliveryRule.delivery.message,
                      contacts: staffMembers
                        .filter((staffMember) => staffMember.selected)
                        .map((staffMember) => ({
                          id: staffMember.staff.id,
                          email: staffMember.staff.person.emailAddress,
                        })),
                    }),
                  ],
                  orgId: assumedOrganizationRole?.orgID || '',
                  deliveryRuleId: alertDeliveryRule.id,
                },
                {
                  // Close form drawer
                  onSuccess: () => setIsFormOpen(false),
                }
              )
            }}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updateAlertDeliveryRule.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {staffList.isLoading ? (
        <LoadingIcon />
      ) : staffMembers.length ? (
        staffMembers.map((currentStaffMember, index) => (
          <CheckboxContainer key={index}>
            <Checkbox
              onChange={(e) =>
                setStaffMembers(
                  staffMembers.map((staffMember) =>
                    staffMember.staff.id === currentStaffMember.staff.id
                      ? { ...staffMember, selected: e.target.checked }
                      : staffMember
                  )
                )
              }
              checked={currentStaffMember.selected}
              label={
                <>
                  <EntityLabel
                    name={`${currentStaffMember.staff.person.firstName} ${currentStaffMember.staff.person.lastName}`}
                    id={currentStaffMember.staff.id}
                  />
                  &nbsp;
                  <p>({currentStaffMember.staff.person.emailAddress})</p>
                </>
              }
              className="py-0.5"
              testid={`${currentStaffMember.staff.person.firstName}-checkbox`}
            />
          </CheckboxContainer>
        ))
      ) : (
        <Empty
          title={t('No Data Found')}
          description={t('No staff members exist in this organization')}
        />
      )}
    </RightPopup>
  )
}

export default EditRecipientsForm

const CheckboxContainer = tw.div`flex`
