import _ from 'lodash'
import { TableInstance, Row } from 'react-table'
import { isPresent } from 'utils'

const useCategoryFilter = <
  T extends Record<string, unknown> = Record<string, unknown>
>() => {
  // grabs all the unique values in the tableInstance for the provided accessor
  const getCategories = <S extends string = string>({
    tableInstance,
    accessor,
  }: {
    tableInstance: TableInstance<T>
    accessor: keyof T | ((row: T) => TableCategory<S> | string | undefined)
  }): TableCategory<S>[] =>
    _.sortedUniqBy(
      tableInstance.preFilteredRows
        .map((row) =>
          // If the accessor is a function pass the row into it
          accessor instanceof Function
            ? accessor(row.original)
            : row.original[accessor]
        )

        // Make sure the category is truthy to remove empty values
        .filter(isPresent)
        .map((category) =>
          isTableCategory(category)
            ? category
            : {
                label: category as string,
                value: category as S,
              }
        )

        // sort categories alphabetically and smallest to largest
        .sort((a, b) => +a.label - +b.label),
      'label'
    )

  // filters out rows by category (id) using filterValue
  const filterRowsByCategory = (accessor?: (row: T) => string | undefined) => (
    rows: Row<T>[],
    id: string[],
    filterValues: TableCategory[]
  ) => {
    // if no filter selected, show everything
    if (filterValues.length === 0) return rows

    // filter out any rows that don't include one of the filtered values
    return rows.filter((row) => {
      // if accessor is passed filter by the return value
      const rowValue = accessor ? accessor(row.original) : row.values[id[0]]

      return filterValues
        .map((filterValue) => filterValue.value)
        .includes(rowValue)
    })
  }

  return {
    getCategories,
    filterRowsByCategory,
  }
}

export default useCategoryFilter

const isTableCategory = (tableCat: unknown): tableCat is TableCategory =>
  !!tableCat &&
  Object.prototype.hasOwnProperty.call(tableCat, 'label') &&
  Object.prototype.hasOwnProperty.call(tableCat, 'value')
