import React, { useMemo, useCallback, useState } from 'react'
import { List, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useStatusSort, useEntityLabelSort } from 'hooks/react-table'
import {
  useActivatePolicyMutation,
  useArchivePolicyMutation,
  useDeactivatePolicyMutation,
} from 'hooks/access-control/policies'
import tw from 'twin.macro'

type PoliciesListProps = {
  data: Policy[]
  onEditPolicy: (policy: Policy) => void
}

const PoliciesList = ({ data, onEditPolicy }: PoliciesListProps) => {
  const { t } = useTranslation()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['name'],
  })

  const tableData: Policy[] = useMemo(() => {
    return data
  }, [data])

  const statusSort = useStatusSort()

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Policy>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const code = row.original.code.toLowerCase()
        // optional when creating/editing policy, description may be null
        const description = String(row.original.description).toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          code.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<Policy>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.name}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortType: primaryLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) =>
          row.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          ),
        width: '80px',
        sortType: statusSort,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => (
          <ActionsCell row={row} onEdit={() => onEditPolicy(row)} />
        ),
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'policies'
      )}`}
      emptyTitle={t('No Policies Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default PoliciesList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: Policy
  onEdit: () => void
}

const ActionsCell = ({ row, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )

  const { mutate: archivePolicy } = useArchivePolicyMutation()
  const { mutate: activatePolicy } = useActivatePolicyMutation()
  const { mutate: deactivatePolicy } = useDeactivatePolicyMutation()

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t(`Archive "${row.name}" Policy`),
      content: <p>{t('Are you sure you want to archive this policy?')}</p>,
      actions: (
        <Button
          onClick={() => {
            archivePolicy(row.id)
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${row.name}" Policy`),
      content: <p>{t('Are you sure you want to deactivate this policy?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deactivatePolicy(row.id)
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${row.name}" Policy`),
      content: <p>{t('Are you sure you want to activate this policy?')}</p>,
      actions: (
        <Button
          onClick={() => {
            activatePolicy(row.id)
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: () => {
              onEdit()
            },
          },
          {
            label: row.activeInfo.active ? t('Deactivate') : t('Activate'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDropdownVisible(false)
              if (row.activeInfo.active) return setIsDeactivateDialogOpen(true)
              setIsActivateDialogOpen(true)
            },
          },
          {
            label: t('Archive'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsArchiveDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
