import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

type useAlertStatisticsArgs = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const useAlertStatistics = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: useAlertStatisticsArgs) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/insights/vitals-metrics/vital-readings/statistics'],
    KyError
  >(['getAlertStatistics', dateRangeState, organizationId, subscriberId], () =>
    getAlertStatistics(dateRangeState, organizationId, subscriberId)
  )

  return query
}

export default useAlertStatistics

const getAlertStatistics = async (
  dateRangeState: DateRangeState,
  organizationId?: string,
  subscriberId?: string
) => {
  const searchParams = new URLSearchParams([
    ['dateRangeType', dateRangeState.preset],
    ['mode', 'chart'],
  ])

  if (organizationId) searchParams.append('organizationId', organizationId)

  if (subscriberId) searchParams.append('subscriberId', subscriberId)

  if (dateRangeState.preset === 'custom') {
    searchParams.append('from', dateRangeState.value[0].toISOString())
    searchParams.append('to', dateRangeState.value[1].toISOString())
  }
  const result = vitalsApi
    .get(`insights/vitals-metrics/vital-readings/statistics`, {
      searchParams: searchParams.toString(),
    })
    .json<
      VitalsAPIResponse['GET']['/insights/vitals-metrics/vital-readings/statistics']
    >()
    .catch(async (e) => {
      const error = await e.response.json()
      if (error.code === 404) {
        const result: VitalsAPIResponse['GET']['/insights/vitals-metrics/vital-readings/statistics'] = []

        return result
      }
      throw e
    })

  return result
}
