import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { isPresent, ordersApi } from 'utils'

const useUpdateLineItemMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['PATCH'][`/organizations/{organizationId}/contracts/{contractId}/line-items/{lineItemId}/${LineItemURL}`],
    updateLineItemArgs
  >({
    mutationFn: updateLineItem,
    successMsg: { message: t('Line Item updated'), showOn: 'onSuccess' },
    // TODO: replace with Optimistic Update
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getOrganizationContracts', organizationId],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ contractId, organizationId }) => [
          'getLineItems',
          organizationId,
          contractId,
        ],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}/line-items'],
          updateLineItemArgs
        >((oldCache, { lineItemId, lineItemForm, lineItemType }) => ({
          ...oldCache,
          items: oldCache?.items?.map((lineItem) =>
            lineItem.id === lineItemId
              ? {
                  id: lineItemId,
                  lineItemType,
                  [lineItemTypeToFormKey[lineItemType]]: { ...lineItemForm },
                }
              : lineItem
          ),
        })),
      },
    ],
  })

  return mutation
}

export default useUpdateLineItemMutation

type updateLineItemArgs<T extends LineItemType = LineItemType> = {
  lineItemForm: Partial<
    Required<ContractLineItemForm>[LineItemTypeToFormKey<T>]
  >
  organizationId: string
  contractId: string
  lineItemId: string
  lineItemType: T
}

const updateLineItem = async ({
  lineItemForm,
  organizationId,
  contractId,
  lineItemId,
  lineItemType,
}: updateLineItemArgs) =>
  ordersApi.patch(
    `/organizations/{organizationId}/contracts/{contractId}/line-items/{lineItemId}/${lineItemTypeToUrl[lineItemType]}` as const,
    {
      pathParams: { organizationId, contractId, lineItemId },
      body: Object.entries(lineItemForm)
        .filter((field): field is [string, Record<string, unknown>] =>
          isPresent(field[1])
        )
        .map(([fieldKey, fieldValue]) => ({
          value: fieldValue,
          path: `/${fieldKey}`,
          op: 'replace',
        })),
    }
  )

// TODO: Remove these when the orders API uses string enums
type LineItemURL =
  | 'primary-product'
  | 'device-service'
  | 'product-type-selection'
  | 'grouped-products'

const lineItemTypeToUrl: Record<
  Required<ContractLineItemForm>['lineItemType'],
  LineItemURL
> = {
  PrimaryProduct: 'primary-product',
  DeviceOrService: 'device-service',
  ProductType: 'product-type-selection',
  GroupedProduct: 'grouped-products',
}

const lineItemTypeToFormKey: Record<
  Required<ContractLineItemForm>['lineItemType'],
  keyof Omit<ContractLineItemForm, 'optional' | 'lineItemType'>
> = {
  PrimaryProduct: 'primaryProductLineItem',
  DeviceOrService: 'deviceOrServiceLineItem',
  ProductType: 'productTypeLineItem',
  GroupedProduct: 'groupedProductsLineItem',
}

type LineItemTypeToFormKey<
  T extends LineItemType = LineItemType
> = T extends 'PrimaryProduct'
  ? 'primaryProductLineItem'
  : T extends 'DeviceOrService'
  ? 'deviceOrServiceLineItem'
  : T extends 'ProductType'
  ? 'productTypeLineItem'
  : T extends 'GroupedProduct'
  ? 'groupedProductsLineItem'
  : never
