import { typedVitalsApi } from 'utils'
import { useQuery } from 'react-query'

type UseVitalSignsAuditTrailQueryProps = {
  filters: VitalsAuditFilters
}

const useVitalSignsAuditTrailQuery = ({
  filters,
}: UseVitalSignsAuditTrailQueryProps) => {
  const query = useQuery<VitalsAPIResponse['GET']['/audit-trails'], KyError>(
    ['getAuditTrail', filters],
    () => getAuditTrail(filters),
    {
      staleTime: 0,
    }
  )

  return query
}

export default useVitalSignsAuditTrailQuery

// get audit trail list from the vitals service
const getAuditTrail = async (filters: VitalsAuditFilters) =>
  typedVitalsApi.get('/audit-trails', {
    searchParams: filters,
  })
