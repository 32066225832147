import React, { useMemo, useCallback } from 'react'
import { List, CategoryFilter } from 'elements'
import { Chip, DateRangePicker } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
  TableInstance,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  useEntityLabelSort,
  useCategoryFilter,
  useDateFilter,
} from 'hooks/react-table'
import { formatDate, dateRangePresetToDateRange } from 'utils'

type WebhookActivityListProps = {
  webhookActivity: Array<WebhookActivityEvent>
}

const WebhookActivityList = ({ webhookActivity }: WebhookActivityListProps) => {
  const { t } = useTranslation()

  const statusCodeSort = useEntityLabelSort({ accessor: 'httpStatusCode' })

  const {
    getCategories,
    filterRowsByCategory,
  } = useCategoryFilter<WebhookActivityEvent>()

  const { filterRowsByDate } = useDateFilter<WebhookActivityEvent>()

  const tableData: WebhookActivityEvent[] = useMemo(() => {
    return webhookActivity
  }, [webhookActivity])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (
      rows: Row<WebhookActivityEvent>[],
      columnIDs: string[],
      globalFilter: string
    ) => {
      return rows.filter((row) => {
        const url = '' + row.original.httpStatusCode
        const version = row.original.event.name.toLowerCase()
        const errors = '' + row.original.postedAt.toLowerCase()

        return (
          url.includes(globalFilter.toLowerCase()) ||
          version.includes(globalFilter.toLowerCase()) ||
          errors.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<WebhookActivityEvent>[] = useMemo(
    () => [
      {
        id: 'RESPONSE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('RESPONSE'),
        accessor: (row) => {
          if (!row.httpStatusCode)
            return <Chip color="gray">{'' + row.httpStatusCode}</Chip>

          return row.httpStatusCode >= 200 && row.httpStatusCode < 300 ? (
            <Chip color="green">{'' + row.httpStatusCode}</Chip>
          ) : (
            <Chip color="red">{'' + row.httpStatusCode}</Chip>
          )
        },
        sortType: statusCodeSort,
        Filter: (tableInstance: TableInstance<WebhookActivityEvent>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="response"
            label={t('Response')}
            categories={getCategories({
              tableInstance,
              accessor: (row) => '' + row.httpStatusCode,
            })}
          />
        ),
        filter: filterRowsByCategory((row) => '' + row.httpStatusCode),
      },
      {
        id: 'EVENT',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('EVENT'),
        accessor: (row) => row.event.name,
      },
      {
        id: 'TIMESTAMP',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('TIMESTAMP'),
        accessor: (row) => formatDate(new Date(row.postedAt), 'Pp'),
        Filter: (tableInstance: TableInstance<WebhookActivityEvent>) => (
          <DateRangePicker
            value={
              tableInstance.state.filters.find(
                (filter) => filter.id === tableInstance.column.id
              )?.value
            }
            onChange={(preset, value) =>
              tableInstance.setFilter(tableInstance.column.id, {
                preset,
                value,
              })
            }
            disabled={tableInstance.preFilteredRows.length === 0}
          />
        ),
        filter: filterRowsByDate((row) => new Date(row.postedAt)),
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'URL',
            desc: false,
          },
        ],
        filters: [
          {
            id: 'TIMESTAMP',
            value: {
              preset: 'this-week',
              value: dateRangePresetToDateRange('this-week'),
            },
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={t('Search webhook activity')}
      emptyTitle={t('No Webhook Activity Found')}
      emptyDescription={t(
        'Try triggering one of the attached webhook events or send a sample event'
      )}
    />
  )
}

export default WebhookActivityList
