import { Empty, Button, Chip } from 'atlas'
import { EllipsisDropdown, Icon, LoadingIcon } from 'elements'
import { motion, AnimatePresence } from 'framer-motion'
import React, { useMemo, useState, useEffect } from 'react'
import { NonPERSProvisioning } from '.'
import { useSubscriberQuery } from 'hooks/organizations'
import { useCheckoutLineItemMutation } from 'hooks/provisioning'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import ProvisionLineItemForm from './ProvisionLineItemForm'
import { LineItemStatusChip } from '.'
import { LineItemStatusTitles } from './LineItemStatusChip'
import { OrderStatusTitles } from './OrderStatusChip'
import { useAPIQuery } from 'hooks'

export type OrderProductLineItem = {
  orderLineItem: OrderLineItem
  product: Product
}

type OrderDetailsLineItemsProps = {
  orderLineItems: ReadonlyArray<OrderLineItem>
  continueToShipping: boolean
  subscriberId?: string | null | undefined
  orderStatus: OrderStatus
}

export const isAneltoNonPersBaseConsole = (
  lineItem: OrderProductLineItem | undefined,
  nonPERS: boolean | undefined
) => {
  if (
    lineItem?.product.tags?.includes('Base Console') &&
    nonPERS &&
    lineItem.product.code?.includes('anelto')
  )
    return true
  return false
}

const OrderDetailsLineItems = ({
  orderLineItems,
  continueToShipping,
  subscriberId,
  orderStatus,
}: OrderDetailsLineItemsProps) => {
  const { t } = useTranslation()

  const [
    lineItemToProvision,
    setLineItemToProvision,
  ] = useState<OrderProductLineItem>()
  const [isProvisionFormOpen, setIsProvisionFormOpen] = useState(false)

  const [isListExpanded, setIsListExpanded] = useState<boolean>(true)

  const [
    isNonPersProvisioningOpen,
    setIsNonPersProvisioningOpen,
  ] = useState<boolean>(false)

  const productsQuery = useAPIQuery('products')

  const provisioningAttributesQuery = useAPIQuery('orderAttributes')

  const lineItemStatusesQuery = useAPIQuery('orderLineItemStatuses')

  const subscriberQuery = useSubscriberQuery(subscriberId || '')

  const checkoutLineItem = useCheckoutLineItemMutation()

  const orderProductLineItems = useMemo<ReadonlyArray<OrderProductLineItem>>(
    () =>
      orderLineItems.map((lineItem) => ({
        orderLineItem: lineItem,
        product:
          productsQuery.data?.items?.find(
            (product) => product.id === lineItem.productId
          ) || {},
      })),
    [productsQuery.data, orderLineItems]
  )

  // update line item to provision if the line items are updated
  useEffect(() => {
    if (lineItemToProvision)
      setLineItemToProvision({
        ...lineItemToProvision,
        orderLineItem:
          orderLineItems.find(
            (lineItem) => lineItem.id === lineItemToProvision.orderLineItem.id
          ) || lineItemToProvision.orderLineItem,
      })
  }, [orderLineItems])

  useEffect(() => {
    if (continueToShipping) setIsListExpanded(false)
  }, [continueToShipping])

  if (productsQuery.isLoading) return <LoadingIcon />
  return (
    <Container>
      <TitleContainer>
        <Title>{t('Order Line Items')}</Title>
        {orderProductLineItems.length > 1 ? (
          <LineItemsButton
            type="primary-link"
            onClick={() => setIsListExpanded(!isListExpanded)}
          >
            {isListExpanded ? t('Show less') : t('Show more')}
            {<TitleIcon type={isListExpanded ? 'arrow-up' : 'arrow-down'} />}
          </LineItemsButton>
        ) : null}
      </TitleContainer>

      <OrderLineItemsContainer>
        <AnimatePresence initial={false}>
          {orderProductLineItems.length ? (
            orderProductLineItems.map((lineItem, index) => {
              const lineItemStatus = lineItemStatusesQuery.data?.find(
                (status) =>
                  status.id === lineItem.orderLineItem.orderLineItemStatusId
              )

              return (
                <AnimateLineItems
                  key={lineItem.product.id}
                  initial={index > 0 ? 'collapsed' : 'open'}
                  animate={isListExpanded ? 'open' : 'collapsed'}
                  exit="collapsed"
                  variants={{
                    open: { height: 'auto' },
                    collapsed: { height: 3.5 },
                  }}
                  transition={{
                    duration: 0.8,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  <OrderLineItemCard
                    isFirstCard={index === 0}
                    isOpen={isListExpanded}
                  >
                    <ImageTitleContainer
                      hasProvisioningData={
                        !!Object.entries(
                          lineItem.orderLineItem.provisioningData || {}
                        ).length
                      }
                    >
                      <ProductImageContainer>
                        {lineItem.product.productImage ? (
                          <ProductImage
                            src={
                              lineItem.product.productImage.find(
                                (image) => !image.deleted
                              )?.imageUrl || ''
                            }
                          />
                        ) : (
                          <ProductIcon
                            type={
                              lineItem.product.productType === 'Service'
                                ? 'radio'
                                : 'hardware'
                            }
                          />
                        )}
                      </ProductImageContainer>
                      <LineItemTitleContainer>
                        <LineItemTitle>
                          {lineItem.product.title ||
                            t('No product details found')}
                        </LineItemTitle>
                        {lineItem.orderLineItem.productCode ? (
                          <LineItemProductCode>
                            {lineItem.orderLineItem.productCode}
                          </LineItemProductCode>
                        ) : null}
                        {lineItem.orderLineItem.provisioningNeeded &&
                        lineItemStatus ? (
                          <LineItemStatusChip status={lineItemStatus} />
                        ) : (
                          <Chip color="gray">
                            {t('No Provisioning Needed')}
                          </Chip>
                        )}
                      </LineItemTitleContainer>
                    </ImageTitleContainer>
                    {Object.entries(
                      lineItem.orderLineItem.provisioningData || {}
                    ).length ? (
                      <ProvisioningAttributesContainer>
                        {
                          Object.entries(
                            lineItem.orderLineItem.provisioningData || {}
                          ).map(([attributeCode, value]) => (
                            <>
                              <AttributeTitle>
                                {
                                  provisioningAttributesQuery.data?.find(
                                    (provisioningAttribute) =>
                                      provisioningAttribute.code ===
                                      attributeCode
                                  )?.title
                                }
                              </AttributeTitle>
                              <AttributeValue>{value}</AttributeValue>
                            </>
                          ))[0] // only show first attribute
                        }
                      </ProvisioningAttributesContainer>
                    ) : null}
                    <EllipsisDropdownContainer>
                      <EllipsisDropdown
                        testid={`${lineItem.orderLineItem.id}-ellipsis`}
                        options={[
                          // Provision Option
                          ...(lineItem.product.provisioningNeeded
                            ? [
                                {
                                  label: t('Provision'),
                                  onClick: async () => {
                                    // update provisioning status of line item if not already in provisioning
                                    if (
                                      lineItemStatusesQuery.data?.find(
                                        (status) =>
                                          status.id ===
                                          lineItem.orderLineItem
                                            .orderLineItemStatusId
                                      )?.title !==
                                      LineItemStatusTitles.PROVISIONING_IN_PROCESS
                                    )
                                      // only if user can successfully checkout the line item do we open the form
                                      await checkoutLineItem.mutateAsync({
                                        orderId:
                                          lineItem.orderLineItem.orderId || '',
                                        lineItemId:
                                          lineItem.orderLineItem.id || '',
                                      })

                                    // if non-pers open pers form
                                    setLineItemToProvision(lineItem)

                                    isAneltoNonPersBaseConsole(
                                      lineItem,
                                      subscriberQuery.data?.nonPERS
                                    ) &&
                                    !lineItem.orderLineItem.provisioningData
                                      ?.ANL_ACCT_NUM
                                      ? setIsNonPersProvisioningOpen(true)
                                      : setIsProvisionFormOpen(true)
                                  },
                                },
                              ]
                            : []),
                          // Remove Line Item option
                          ...(orderStatus.title !==
                            OrderStatusTitles.PROVISIONING_COMPLETE &&
                          lineItemStatus?.title !==
                            LineItemStatusTitles.PROVISIONING_COMPLETE
                            ? [
                                {
                                  label: t('Remove from Order'),
                                  onClick: () => null,
                                },
                              ]
                            : []),
                          // Change line item details option
                          { label: t('Change Details'), onClick: () => null },
                        ]}
                      />
                    </EllipsisDropdownContainer>
                  </OrderLineItemCard>
                </AnimateLineItems>
              )
            })
          ) : (
            <EmptyDashed title={t('No products from order found')} />
          )}
        </AnimatePresence>
      </OrderLineItemsContainer>
      <ProvisionLineItemForm
        open={isProvisionFormOpen}
        setOpen={setIsProvisionFormOpen}
        lineItem={lineItemToProvision}
      />
      <NonPERSProvisioning
        open={isNonPersProvisioningOpen}
        setOpen={setIsNonPersProvisioningOpen}
        subscriberId={subscriberId}
        setProvisionFormOpen={setIsProvisionFormOpen}
      />
    </Container>
  )
}

export default OrderDetailsLineItems

const Container = tw.div``

const TitleContainer = tw.div`flex flex-row justify-between`

const Title = tw.div`text-lg font-semibold text-gray-700`

const TitleIcon = tw(Icon)`mb-1`

const LineItemsButton = tw(Button)``

const OrderLineItemsContainer = tw.div`my-4`

const AnimateLineItems = styled(motion.div)(() => [tw`my-4`])

const OrderLineItemCard = styled.div<{
  isOpen: boolean
  isFirstCard: boolean
}>(({ isOpen, isFirstCard }) => [
  tw`relative border rounded bg-white  grid grid-cols-7 justify-items-stretch`,
  !isOpen && !isFirstCard && tw`z-10 -mt-4`,
])

const ImageTitleContainer = styled.div<{
  hasProvisioningData: boolean
}>(({ hasProvisioningData }) => [
  tw`flex flex-row`,
  hasProvisioningData ? tw`col-span-3` : tw`col-span-6`,
])

const ProvisioningAttributesContainer = tw.div`justify-self-center self-center col-span-3`

const ProductImageContainer = tw.div`h-28 w-28 flex items-center justify-center`

const ProductImage = styled(motion.img)`
  object-fit: cover;
  ${() => tw`h-full`}
`
const ProductIcon = tw(Icon)`w-14 h-14 text-gray-400`

const LineItemTitleContainer = tw.div`ml-2 pl-2 self-center`

const LineItemTitle = tw.div`text-gray-800 font-semibold`

const LineItemProductCode = tw.div`text-gray-700 text-sm leading-4 mb-0.5`

const AttributeTitle = tw.div`text-sm font-medium text-gray-500`

const AttributeValue = tw.div`text-lg font-semibold`

const EllipsisDropdownContainer = tw.div`mr-4 justify-self-end flex items-center`

const EmptyDashed = tw(Empty)`border-2 border-dashed rounded`
