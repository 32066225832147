import i18n from 'i18next'
import { enUS, de } from 'date-fns/locale'

/**
 * Gets locale for language translation
 * @returns Locale
 */
const getDateFnLocale = () => {
  if (i18n.language === 'en-US') return enUS
  if (i18n.language === 'de') return de

  throw new Error('Invalid locale selected')
}

export default getDateFnLocale
