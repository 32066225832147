import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useOrganizationListQuery } from 'hooks/organizations'
import { useSearchParams } from 'react-router-dom'
import { success } from 'utils'
import { useNavigate } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useUserQuery } from 'hooks/user-profile'

const AssumedOrganizationRoleContext = createContext<AssumedOrganizationRoleContext>(
  undefined
)

type AssumedOrganizationRoleProviderProps = {
  children: React.ReactNode
}

export const AssumedOrganizationRoleProvider = ({
  children,
}: AssumedOrganizationRoleProviderProps) => {
  const [state, setState] = useState<OrganizationPermissions | undefined>()
  const [showDiscardToast, setShowDiscardToast] = useState(false)
  const [isDiscardingRole, setIsDiscardingRole] = useState(false)
  const [searchParams] = useSearchParams({ role: '' })

  const { data: user } = useUserQuery()
  const { data: organizationList } = useOrganizationListQuery({
    disabled: !user,
    // TODO: remove this once we integrate the ACM api (should be using useOrganizationQuery instead)
    pageLength: 99999,
    organizationFilters: {
      status: ['Active', 'Archived', 'Inactive', 'Restored', 'Suspended'],
    },
  })

  const navigate = useNavigate()
  const { t } = useTranslation()

  // Listen to href changes and if discarding role clear assumed role
  // This allows navigation warnings to display before the assumed role is actually cleared
  useEffect(() => {
    setIsDiscardingRole(false)
    // if the new url has role then the role was not discarded so skip resetting state
    if (searchParams.get('role')) return

    if (isDiscardingRole) {
      // update the state by removing the role
      setState(undefined)

      if (showDiscardToast) {
        success({ message: t('Discarded assumed organization role') })
        setShowDiscardToast(false)
      }
    }
  }, [window.location.href])

  const context = useMemo<AssumedOrganizationRoleContext>(
    () => ({
      assumedOrganizationRole: state,
      assumeOrganizationRole: ({
        orgID,
        onSuccess,
        showToast = true,
        changeUrl = true,
      }) => {
        const newAssumedOrganization = organizationList?.items.find(
          (org) => org.id === orgID
        )

        if (!newAssumedOrganization) return

        const newAssumedOrganizationRole = {
          orgDisplayName: newAssumedOrganization.businessName,
          orgID: newAssumedOrganization.id,
          staff: {
            permissions: [],
            subscribersDeny: [],
          },
          subscriber: { permissions: [] },
        }

        // update the state with the new role
        setState(newAssumedOrganizationRole)

        if (changeUrl) {
          navigate({
            // if an onSuccess is not provided default behavior should navigate to homepage
            pathname: !onSuccess ? '/' : undefined,
            // narrow the permissions scope down to the specified org
            searchParams: {
              role: newAssumedOrganizationRole.orgID,
            },
          })
        }

        // call the onSuccess callback function
        if (onSuccess) onSuccess({ role: newAssumedOrganizationRole.orgID })

        // notify the user
        if (showToast)
          success({
            message: `${t('Assumed organization role of')} ${
              newAssumedOrganization.businessName
            }`,
          })
      },
      discardAssumedOrganizationRole: (
        { showToast, navigate: navigateArg } = {
          showToast: true,
        }
      ) => {
        // send the user back to the homepage & remove the query param
        navigate({ pathname: navigateArg || '/', discardRole: true })

        setIsDiscardingRole(true)

        // notify the user
        if (showToast) setShowDiscardToast(true)
      },
    }),
    [state, organizationList]
  )

  // set the assumed role in memory (if one exist) when the app is loaded or the organizationList is updated
  useEffect(() => {
    const role = searchParams.get('role')

    // if no role query param, don't do anything
    if (!role) return

    context?.assumeOrganizationRole({
      orgID: role,
      // role is already in the url so no need to change it
      changeUrl: false,
      showToast: false,
    })
  }, [organizationList])

  return (
    <AssumedOrganizationRoleContext.Provider value={context}>
      {children}
    </AssumedOrganizationRoleContext.Provider>
  )
}

export const useAssumedOrganizationRole = () => {
  const context = useContext(AssumedOrganizationRoleContext)

  if (!context) {
    throw new Error(
      'useAssumedOrganizationRole must be used within an AssumedOrganizationRoleProvider'
    )
  }

  return context
}
