import { FadeInSlideDown } from 'animations'
import { Empty } from 'atlas'
import { useWizardForm } from 'context/wizard-form'
import { Icon, LoadingIcon, RadioGroup, TextField } from 'elements'
import { useAPIQuery, useDelay } from 'hooks'
import { useContractQuery } from 'hooks/contracts'
import {
  useCreateShippingInformationMutation,
  useUpdateShippingInformationMutation,
} from 'hooks/orders'
import { useOrganizationQuery, useSubscriberQuery } from 'hooks/organizations'
import { useSubscriberAddressesQuery } from 'hooks/user-subscriber'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { formatPrice } from 'utils'
import CustomOrderAddress from './CustomOrderAddress'
import OrderAddressCard from './OrderAddressCard'
import _ from 'lodash'

export type ShippingOption = 'Subscriber' | 'Organization' | 'Custom'

const ShippingInformation = () => {
  const { t } = useTranslation()

  const delay = useDelay()

  const [searchParams] = useSearchParams()

  const [shippingOption, setShippingOption] = useState<ShippingOption>(
    'Subscriber'
  )

  const [shippingMethodId, setShippingMethodId] = useState<string>()

  const [shippingMethodError, setShippingMethodError] = useState<string>()
  const [selectedAddressError, setSelectedAddressError] = useState<string>()

  const [shippingNotes, setShippingNotes] = useState<string>()

  const [initialAddressId, setInitialAddressId] = useState<string>()
  const [isApiAddressCustom, setIsApiAddressCustom] = useState(false)

  const [selectedAddress, setSelectedAddress] = useState<
    OrderShippingInformationForm['shippingAddress']
  >()

  const orderQuery = useAPIQuery('order', {
    pathParams: {
      orderId: searchParams.get('orderId') || '',
    },
  })

  const subscriberQuery = useSubscriberQuery(
    orderQuery.data?.subscriberId || ''
  )

  const subscriberAddressQuery = useSubscriberAddressesQuery(
    orderQuery.data?.subscriberId || ''
  )

  const organizationQuery = useOrganizationQuery(
    orderQuery.data?.organizationId || ''
  )

  const contractsQuery = useContractQuery({
    contractId: orderQuery.data?.contractId,
    organizationId: orderQuery.data?.organizationId,
  })

  const { stepState, setStepState } = useWizardForm()

  const createShippingInfoMutation = useCreateShippingInformationMutation()
  const updateShippingInfoMutation = useUpdateShippingInformationMutation()

  const submitShippingAddress = useCallback(
    (shippingAddress: OrderShippingInformationForm['shippingAddress']) =>
      (orderQuery.data?.shippingInformation
        ? updateShippingInfoMutation
        : createShippingInfoMutation
      ).mutateAsync({
        orderId: orderQuery.data?.id || '',

        shippingInformationForm: {
          sameAsPrimaryAddress: shippingOption === 'Subscriber',
          sameAsOrganizationAddress: shippingOption === 'Organization',
          shippingContactName: {
            lastName: subscriberQuery.data?.person.lastName,
            firstName: subscriberQuery.data?.person.firstName,
          },
          shippingAddress,
          shippingNotes,
          shippingMethodId,
        },
      }),
    [orderQuery.data, subscriberQuery.data, shippingMethodId, shippingNotes]
  )

  const shippingPrices = contractsQuery.data?.shippingPrices

  // If shipping information exists in the order correctly set the local state to match
  useEffect(() => {
    if (
      // we need some way to prevent rerunning this logic if the user updates a subscriber/org address
      // if shippingMethodId is truthy we know this was already once which populated
      !shippingMethodId &&
      subscriberAddressQuery.data &&
      organizationQuery.data &&
      orderQuery.data?.shippingInformation
    ) {
      // sync simple fields
      setShippingNotes(
        orderQuery.data.shippingInformation.shippingNotes || undefined
      )
      setShippingMethodId(
        orderQuery.data.shippingInformation.shippingMethodId || undefined
      )

      // sync address field
      const orderAddress = orderQuery.data.shippingInformation.address

      if (orderAddress) {
        const subscriberMatch = subscriberAddressQuery.data.addresses.find(
          (subscriberAddress) =>
            _.isEqual(
              _.pick(subscriberAddress.address, addressFieldsToMatch),
              _.pick(orderAddress, addressFieldsToMatch)
            )
        )
        const organizationMatch = organizationQuery.data.party.addresses.find(
          (orgAddress) =>
            _.isEqual(
              _.pick(orgAddress, addressFieldsToMatch),
              _.pick(orderAddress, addressFieldsToMatch)
            )
        )

        setInitialAddressId(subscriberMatch?.id || organizationMatch?.id)
        setShippingOption(
          subscriberMatch
            ? 'Subscriber'
            : organizationMatch
            ? 'Organization'
            : 'Custom'
        )
        if (subscriberMatch || organizationMatch) {
          setSelectedAddress(orderAddress)
        } else setIsApiAddressCustom(true)
      }
    }
  }, [
    orderQuery.data?.shippingInformation,
    subscriberAddressQuery.data,
    organizationQuery.data,
  ])

  // validation & submission logic for the shipping step
  useEffect(() => {
    shippingMethodId && setShippingMethodError(undefined)

    // check if form is different from apiData
    const apiData = orderQuery.data?.shippingInformation
    const isFormChanged =
      !apiData ||
      shippingNotes !== (apiData.shippingNotes || undefined) ||
      shippingMethodId !==
        (orderQuery.data?.shippingInformation?.shippingMethodId || undefined) ||
      !_.isEqual(
        _.pick(apiData.address, addressFieldsToMatch),
        _.pick(selectedAddress, addressFieldsToMatch)
      )
    if (shippingOption !== 'Custom')
      setStepState({
        ...stepState,
        isSubmitDisabled: false,
        submitFn: isFormChanged
          ? () => submitShippingAddress(selectedAddress)
          : undefined,
        validationFn: () => {
          if (!selectedAddress)
            setSelectedAddressError(t('An address must be selected'))
          else setSelectedAddressError(undefined)

          if (!shippingMethodId)
            setShippingMethodError(t('Please select a shipping method'))
          else setShippingMethodError(undefined)

          return !!selectedAddress && !!shippingMethodId
        },
      })
  }, [
    shippingOption,
    selectedAddress,
    shippingMethodId,
    shippingNotes,
    submitShippingAddress,
  ])

  if (subscriberAddressQuery.isLoading) return <LoadingIcon />

  return (
    <div>
      <Container>
        <TitleContainer delay={delay()}>
          <Title>{t('Enter Shipping Information')}</Title>
        </TitleContainer>

        <FadeInSlideDown delay={delay()}>
          <RadioGroup
            options={[
              { value: 'Subscriber', label: t('Ship To Subscriber') },
              { value: 'Organization', label: t('Ship To Organization') },
              { value: 'Custom', label: t('Ship To Another Address') },
            ]}
            onChange={(e) => {
              setShippingOption(e)
              setSelectedAddressError(undefined)
            }}
            checked={shippingOption}
          />
        </FadeInSlideDown>
        {selectedAddressError ? (
          <SelectedAddressErrorContainer>
            <ErrorText>
              <ErrorIcon type={'error'} />
              {selectedAddressError}
            </ErrorText>
          </SelectedAddressErrorContainer>
        ) : null}
        <AddressInfoContainer delay={delay()}>
          {
            {
              Subscriber: (
                <OrderAddressCard
                  customerName={`${subscriberQuery.data?.person.firstName} ${subscriberQuery.data?.person.lastName}`}
                  shipTo={shippingOption}
                  addresses={subscriberAddressQuery.data?.addresses || []}
                  setShippingAddress={setSelectedAddress}
                  initialShippingAddressId={initialAddressId}
                />
              ),
              Organization: (
                <OrderAddressCard
                  customerName={organizationQuery.data?.displayName || ''}
                  shipTo={shippingOption}
                  addresses={organizationQuery.data?.party.addresses || []}
                  setShippingAddress={setSelectedAddress}
                  initialShippingAddressId={initialAddressId}
                />
              ),

              Custom: (
                <CustomAddressCard>
                  <CustomOrderAddress
                    validationFn={() =>
                      shippingMethodId
                        ? (setShippingMethodError(undefined), true)
                        : (setShippingMethodError(
                            t('Please select a shipping method')
                          ),
                          false)
                    }
                    submitShippingAddress={submitShippingAddress}
                    isApiAddressCustom={isApiAddressCustom}
                    isShippingInfoChanged={
                      shippingNotes !==
                        (orderQuery.data?.shippingInformation?.shippingNotes ||
                          undefined) ||
                      shippingMethodId !==
                        (orderQuery.data?.shippingInformation
                          ?.shippingMethodId || undefined)
                    }
                  />
                </CustomAddressCard>
              ),
            }[shippingOption]
          }
        </AddressInfoContainer>
      </Container>

      <Container>
        <TitleContainer delay={delay()}>
          <Title>{t('Shipping Notes')}</Title>
        </TitleContainer>
        <ShippingNotesCard delay={delay()}>
          <ShippingNotesField
            placeholder={t('Add Shipping Notes')}
            value={shippingNotes}
            onChange={(e) => setShippingNotes(e.target.value)}
            multiline
          />
        </ShippingNotesCard>
      </Container>

      <Container>
        <TitleContainer delay={delay()}>
          <Title>{t('Shipping Method')}</Title>
          {shippingMethodError ? (
            <ShippingMethodErrorContainer>
              <ErrorText>
                <ErrorIcon type={'error'} />
                {shippingMethodError}
              </ErrorText>
            </ShippingMethodErrorContainer>
          ) : null}
        </TitleContainer>
        <FadeInSlideDown delay={delay()}>
          {shippingPrices?.length ? (
            shippingPrices.map((shippingPrice, index) => (
              <ShippingMethodContainer
                key={index}
                onClick={() => {
                  setShippingMethodError(undefined)
                  setShippingMethodId(shippingPrice.id || '')
                }}
                isSelected={shippingMethodId === shippingPrice.id}
              >
                <IconContainer>
                  <CheckIcon
                    isSelected={shippingMethodId === shippingPrice.id}
                    type={
                      shippingMethodId === shippingPrice.id
                        ? 'check-marked'
                        : 'check-circle'
                    }
                  />
                </IconContainer>

                <ShippingMethodTitle>
                  {shippingPrice.priceDescriptor?.descriptionInfo?.title}
                  <ShippingMethodDescription>
                    {
                      shippingPrice.priceDescriptor?.descriptionInfo
                        ?.description
                    }
                  </ShippingMethodDescription>
                </ShippingMethodTitle>
                <span>
                  {shippingPrice.priceDescriptor?.price
                    ? formatPrice(shippingPrice.priceDescriptor.price)
                    : ''}
                </span>
              </ShippingMethodContainer>
            ))
          ) : (
            <EmptyDashed title={t('No Shipping Methods Found')} />
          )}
        </FadeInSlideDown>
      </Container>
    </div>
  )
}

export default ShippingInformation

// only use these keys to check address equality
export const addressFieldsToMatch = [
  'addressLine1',
  'city',
  'stateProvinceCode',
  'postalCode',
]

const Container = tw.div`mb-8`

const TitleContainer = tw(FadeInSlideDown)``

const Title = tw.h3`text-xl font-semibold flex-grow mb-2`

const AddressInfoContainer = tw(FadeInSlideDown)`mt-4`

const ShippingMethodContainer = styled.div<{
  isSelected: boolean
}>(({ isSelected }) => [
  tw`bg-white border border-gray-200 rounded-lg mb-2 p-4 w-96 flex flex-row cursor-pointer hover:shadow-lg`,
  isSelected && tw`border-blue-400 bg-white`,
])

const IconContainer = tw.div`self-center pr-2 text-xl`

const CheckIcon = styled(Icon)<{ isSelected: boolean }>(({ isSelected }) => [
  tw`text-gray-300 text-2xl`,
  isSelected && tw`text-blue-400`,
])

const ShippingMethodTitle = tw.div`text-base flex-grow`

const ShippingMethodDescription = tw.p`text-sm text-gray-500`

const EmptyDashed = tw(Empty)`flex h-52 border-2 border-dashed rounded`

const CustomAddressCard = tw.div`p-6 pb-1 bg-white border border-gray-200 rounded-lg`

const ShippingNotesCard = tw(
  FadeInSlideDown
)`grid grid-cols-1 gap-4 h-auto bg-white p-6 pb-0 rounded-lg border border-gray-200`

const ShippingNotesField = tw(TextField)`max-w-2xl`

const ShippingMethodErrorContainer = tw.div`bg-red-50 border rounded border-red-200 w-96 mb-4`

const SelectedAddressErrorContainer = tw.div`bg-red-50 border rounded border-red-200 w-96 my-4`

const ErrorText = tw.p`text-xs text-red-800 font-medium pl-2 py-2`

const ErrorIcon = tw(Icon)`mr-2 -mt-0.5`
