import React, { useState } from 'react'
import { EntityLabel, CustomSelect } from 'elements'
import { useNavigate } from 'hooks'
import { useSubscriberQuery, useSubscribersQuery } from 'hooks/organizations'
import { useParams, useLocation } from 'react-router-dom'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTranslation } from 'react-i18next'

const SubscriberSelect = () => {
  const { subscriberId } = useParams()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  const subscribers = useSubscribersQuery({
    organizationId: assumedOrganizationRole?.orgID,
    skip: 0,
    take: 5,
    searchTerm: debouncedSearchTerm,
  })

  const currentSubscriber = useSubscriberQuery(subscriberId)

  if (!currentSubscriber.data) return null

  return (
    <CustomSelect
      id={currentSubscriber.data.id}
      className="w-64"
      options={
        subscribers.data?.items?.map((sub) => ({
          value: sub.id,
          label: (
            <>
              <EntityLabel
                name={sub.person.firstName + ' ' + sub.person.lastName}
                id={sub.id}
                iconOnly
                size="sm"
              />
              {sub.person.firstName + ' ' + sub.person.lastName}
            </>
          ),
          searchValue: `${sub.person.firstName} ${sub.person.lastName}`,
        })) || [{ label: '', value: '' }]
      }
      placeholder={
        currentSubscriber.data.person.firstName +
        ' ' +
        currentSubscriber.data.person.lastName
      }
      onChange={(e) => navigate(pathname.replace(subscriberId, e.value))}
      variant="thin"
      searchable
      emptyText={t('No subscribers found')}
      setDebouncedSearchTerm={setDebouncedSearchTerm}
      isLoading={subscribers.isFetching}
    />
  )
}

export default SubscriberSelect
