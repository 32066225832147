import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Column,
  Row,
  TableInstance,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import i18next from 'i18next'
import { useCategoryFilter, useEntityLabelSort } from 'hooks/react-table'
import { CategoryFilter, List } from 'elements'
import { Chip } from 'atlas'
import _ from 'lodash'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

type AlertDeliveryRulesListProps = {
  alertDeliveryRules: AlertDeliveryRule[]
  alertCategories: AlertCategory[]
}

const AlertDeliveryRulesList = ({
  alertDeliveryRules,
  alertCategories,
}: AlertDeliveryRulesListProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['name'],
  })

  // Sort Function for the Severity column
  const severitySortBy = useCallback(
    (rowA: Row<AlertDeliveryRule>, rowB: Row<AlertDeliveryRule>) => {
      return rowA.original.criteria.severity < rowB.original.criteria.severity
        ? -1
        : 1
    },
    []
  )

  const tableData: AlertDeliveryRule[] = useMemo(() => {
    // filter Alerts by the addressed filter
    return alertDeliveryRules
  }, [alertDeliveryRules, i18next.language])

  const {
    getCategories,
    filterRowsByCategory,
  } = useCategoryFilter<AlertDeliveryRule>()

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (
      rows: Row<AlertDeliveryRule>[],
      columnIDs: string[],
      globalFilter: string
    ) => {
      return rows.filter((row) => {
        const ruleName = row.original.name.toLowerCase()
        const ruleDescription = row.original.description.toLowerCase()
        const alertCategory = row.original.criteria.alertCategory

        return (
          ruleName.includes(globalFilter.toLowerCase()) ||
          ruleDescription.includes(globalFilter.toLowerCase()) ||
          alertCategory.includes(globalFilter.toLowerCase())
        )
      })
    },
    [alertDeliveryRules, i18next.language]
  )

  const columns: Column<AlertDeliveryRule>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => <PrimaryColumnText>{row.name}</PrimaryColumnText>,
        sortType: primaryLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '3fr',
      },
      {
        id: 'ALERT CATEGORY',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ALERT CATEGORY'),
        accessor: (row) =>
          alertCategories.find(
            (category) => category.id === row.criteria.alertCategory
          )?.name || '',
        Filter: (tableInstance: TableInstance<AlertDeliveryRule>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="vitals"
            label={t('Type')}
            categories={getCategories({
              tableInstance,
              accessor: (row) =>
                alertCategories.find(
                  (category) => category.id === row.criteria.alertCategory
                )?.name || '',
            })}
          />
        ),
        filter: filterRowsByCategory(),
        width: '1.5fr',
      },
      {
        id: 'SEVERITY',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('SEVERITY'),
        accessor: (row) => <SeverityChip row={row} />,
        Filter: (tableInstance: TableInstance<AlertDeliveryRule>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="alert"
            label={t('Severity')}
            categories={getCategories({
              tableInstance,
              accessor: (row) => '' + (row.criteria.severity || ''),
            })}
          />
        ),
        filter: filterRowsByCategory(
          (row) => '' + (row.criteria.severity || '')
        ),
        sortType: severitySortBy,
        width: '90px',
      },
      {
        id: 'FREQUENCY',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('FREQUENCY'),
        accessor: (row) => {
          return (
            <FrequencyColumn>
              <span>
                {(() => {
                  if (row.schedule.frequency === 'weekly')
                    return _.orderBy(
                      row.schedule.weekday,
                      (row) => weekDaySorter[row]
                    ).map((day: AlertWeekdays) => (
                      <DayOfTheWeekChip key={day} color="cyan">
                        {_.upperCase(weekdayMap[day])}
                      </DayOfTheWeekChip>
                    ))
                  if (row.schedule.frequency === 'daily')
                    if (row.schedule.interval && row.schedule.interval > 1)
                      return (
                        <>
                          {t('Every ')}
                          {row.schedule.interval}
                          {t(' Days')}
                        </>
                      )
                    else return <>{t('Every Day')}</>
                  if (row.schedule.frequency === 'hourly')
                    if (row.schedule.interval && row.schedule.interval > 1)
                      return (
                        <>
                          {t('Every ')}
                          {row.schedule.interval}
                          {t(' Hours')}
                        </>
                      )
                    else return <>{t('Every Hour')}</>
                  if (row.schedule.frequency === 'minutely')
                    if (row.schedule.interval && row.schedule.interval > 1)
                      return (
                        <>
                          {t('Every ')}
                          {row.schedule.interval}
                          {t(' Minutes')}
                        </>
                      )
                    else return <>{t('Every Minute')}</>
                  // default case: 'real-time'
                  return 'Real-Time'
                })()}
              </span>
            </FrequencyColumn>
          )
        },
        Filter: (tableInstance: TableInstance<AlertDeliveryRule>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="reminders"
            label={t('Frequency')}
            categories={getCategories({
              tableInstance,
              accessor: (row) => _.startCase(row.schedule.frequency),
            })}
          />
        ),
        filter: filterRowsByCategory((row) =>
          _.startCase(row.schedule.frequency)
        ),
        width: '1.5fr',
      },
    ],
    [alertDeliveryRules, i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (row) => navigate(row.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useRowSelect
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={t('Search alert delivery rules')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default AlertDeliveryRulesList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const FrequencyColumn = tw.div`flex self-center`

const DayOfTheWeekChip = tw(Chip)`mr-1`

const SeverityChip = ({ row }: { row: AlertDeliveryRule }) => {
  const { t } = useTranslation()

  return (
    <div>
      {row.criteria.severity &&
      //insure it is vital sign reading alert category
      row.criteria.alertCategory === '694f33b4-1a16-4b38-8041-e6b600b0dc0e' ? (
        <Chip
          color={
            (['yellow', 'orange', 'red'] as const)[row.criteria.severity - 1]
          }
        >{`${t('Severity')} ${row.criteria.severity}`}</Chip>
      ) : (
        '-'
      )}
    </div>
  )
}

const weekDaySorter: { [key: string]: number } = {
  M: 1,
  T: 2,
  W: 3,
  H: 4,
  F: 5,
  S: 6,
  U: 7,
}

const weekdayMap: Record<AlertWeekdays, Weekdays> = {
  M: 'mon',
  T: 'tue',
  W: 'wed',
  H: 'thu',
  F: 'fri',
  S: 'sat',
  U: 'sun',
}
