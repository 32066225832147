import React, { useMemo, useCallback, useState } from 'react'
import { List, Dialog, EllipsisDropdown } from 'elements'
import { Button } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useArchivePolicyGroupMutation } from 'hooks/access-control/policy-groups'
import tw from 'twin.macro'
import { useEntityLabelSort } from 'hooks/react-table'

type PolicyGroupsListProps = {
  data: PolicyGroup[]
  onEditPolicyGroup: (policy: PolicyGroup) => void
}

const PolicyGroupsList = ({
  data,
  onEditPolicyGroup,
}: PolicyGroupsListProps) => {
  const { t } = useTranslation()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['title'],
  })

  const tableData: PolicyGroup[] = useMemo(() => {
    return data
  }, [data])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<PolicyGroup>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const title = row.original.title.toLowerCase()
        const code = row.original.code.toLowerCase()
        // optional when creating/editing, description may be null
        const description = String(row.original.description).toLowerCase()

        return (
          title.includes(globalFilter.toLowerCase()) ||
          code.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<PolicyGroup>[] = useMemo(
    () => [
      {
        id: 'TITLE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('TITLE'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.title}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortType: primaryLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => (
          <ActionsCell row={row} onEdit={() => onEditPolicyGroup(row)} />
        ),
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'TITLE',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={t('Search policy groups')}
      emptyTitle={t('No Policy Groups Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default PolicyGroupsList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: PolicyGroup
  onEdit: () => void
}

const ActionsCell = ({ row, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { mutate: archivePolicy } = useArchivePolicyGroupMutation()

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: () => {
              onEdit()
            },
          },
          {
            label: t('Archive'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.title}-ellipsis`}
      />

      <Dialog
        open={isDialogOpen}
        title={t(`Archive "${row.title}" policy group`)}
        content={
          <p>{t('Are you sure you want to archive this policy group?')}</p>
        }
        actions={
          <>
            <Button
              type="danger-filled"
              onClick={() => {
                archivePolicy(row.id)

                return setIsDialogOpen(false)
              }}
            >
              {t('Archive')}
            </Button>

            <Button onClick={() => setIsDialogOpen(false)} type="secondary">
              {t('Cancel')}
            </Button>
          </>
        }
      />
    </div>
  )
}
