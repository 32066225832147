import vitalsModuleHandlers from './vitals'
import organizationsModuleHandlers from './organizations'
import auditTrailHandlers from './audit-trails'
import seedDataModuleHandlers from './seed-data'
import accessControlModuleHandlers from './access-control'
import adminsModuleHandlers from './admins'
import disclaimerHandlers from './disclaimers'
import vitalsThresholdsModuleHandlers from './vitals-thresholds'
import webhookHandlers from './webhooks'
import statisticHandlers from './statistics'
import alertContactsModuleHandlers from './alert-delivery'
import partnersModuleHandlers from './partners'
import userProfileHandlers from './user-profile'
import staffHandlers from './user-staff'
import responsiblePartiesHandlers from './responsible-parties'
import subscriberHandlers from './subscribers'
import alertCategoryHandlers from './alert-category'
import orderHandlers from './orders'

const handlers = [
  ...vitalsModuleHandlers,
  ...organizationsModuleHandlers,
  ...auditTrailHandlers,
  ...seedDataModuleHandlers,
  ...accessControlModuleHandlers,
  ...adminsModuleHandlers,
  ...disclaimerHandlers,
  ...vitalsThresholdsModuleHandlers,
  ...webhookHandlers,
  ...statisticHandlers,
  ...alertContactsModuleHandlers,
  ...partnersModuleHandlers,
  ...userProfileHandlers,
  ...staffHandlers,
  ...responsiblePartiesHandlers,
  ...subscriberHandlers,
  ...alertCategoryHandlers,
  ...orderHandlers,
]

export default handlers
