import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, orgsApi, handleQueryError } from 'utils'

const useCreatePartnerMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    CreatePartnerResponse,
    KyError,
    CreatePartnerProps,
    GetPartnersResponse | undefined
  >(createPartner, {
    onMutate: () => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<GetPartnersResponse | undefined>(
        ['getPartners']
      )

      // not optimistically updating here because entityLabel color is derived from id that isn't available on create

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Partner created') })
    },
    onError: async (error, _, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetPartnersResponse | undefined>(
        'getPartners',
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: () => {
      // refetch from API to make sure the partner list is in sync with the server
      queryCache.invalidateQueries('getPartners')
    },
  })

  return mutation
}

export default useCreatePartnerMutation

type CreatePartnerProps = {
  partnerData: CreatePartnerForm
}

const createPartner = async ({ partnerData }: CreatePartnerProps) => {
  // @ts-expect-error Bad Swagger type https://github.com/electronic-caregiver/svcs-api-organizations/issues/176
  const createPartnerReq: CreatePartnerRequest = {
    ...partnerData,
    ...(partnerData.organization
      ? {
          organization: {
            ...partnerData.organization,
            originationSource: 'ADDI',
            onBoardingStatus: 1,
            organizationStatus: 1,
          },
        }
      : {}),
  }

  const createPartnerResult = await orgsApi
    .post(`api/partners`, {
      json: createPartnerReq,
    })
    .json<CreatePartnerResponse>()

  return createPartnerResult
}
