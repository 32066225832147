import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCreateShippingInformationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/shipping-information'],
    createShippingInfoArgs
  >({
    mutationFn: createShippingInformation,
    successMsg: {
      message: t('Order updated'),
      showOn: 'onSuccess',
    },
    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['order', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          createShippingInfoArgs
        >((oldOrder, { shippingInformationForm }) =>
          oldOrder
            ? {
                ...oldOrder,
                shippingInformation: { ...shippingInformationForm },
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useCreateShippingInformationMutation

type createShippingInfoArgs = {
  orderId: string
  shippingInformationForm: OrderShippingInformationForm
}

const createShippingInformation = async ({
  orderId,
  shippingInformationForm,
}: createShippingInfoArgs) =>
  ordersApi.post('/orders/{orderId}/shipping-information', {
    pathParams: { orderId },
    body: shippingInformationForm,
  })
