import React from 'react'
import { Button, Empty } from 'atlas'
import { Checkbox, LoadingIcon } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type AvailableWebhooksFormListProps = {
  availableWebhookCategoryEvents?: WebhookEvent[]
  checkedEvents: WebhookEvent[]
  setCheckedEvents: (newValue: WebhookEvent[]) => void
  setDisplayedSampleEvent: (newValue: WebhookEvent) => void
  isLoadingAvailableWebhookCategoryEvents: boolean
}

const AvailableWebhooksFormList = ({
  isLoadingAvailableWebhookCategoryEvents,
  availableWebhookCategoryEvents,
  checkedEvents,
  setCheckedEvents,
  setDisplayedSampleEvent,
}: AvailableWebhooksFormListProps) => {
  const { t } = useTranslation()

  if (isLoadingAvailableWebhookCategoryEvents)
    return (
      <LoadingContainer>
        <LoadingIcon className="h-64" height={undefined} />
      </LoadingContainer>
    )

  if (!availableWebhookCategoryEvents?.length)
    return (
      <EmptyContainer>
        <Empty
          title={t('No Data Found')}
          description={t('No webhook events exist for this category')}
        />
      </EmptyContainer>
    )

  return (
    <WebhookContainer>
      {availableWebhookCategoryEvents.map((event) => (
        <AvailableWebhooksContainer key={event.code}>
          <Checkbox
            onChange={() => {
              // if event is checked, uncheck it
              if (
                checkedEvents.find(
                  (checkedEvent) => checkedEvent.code === event.code
                )
              )
                return setCheckedEvents(
                  checkedEvents.filter(
                    (checkedEvent) => checkedEvent.code !== event.code
                  )
                )

              // otherwise check the event
              return setCheckedEvents([...checkedEvents, event])
            }}
            checked={
              !!checkedEvents.find(
                (checkedEvent) => checkedEvent.code === event.code
              )
            }
            testid={`${event.name}-checkbox`}
          />
          <div>
            <p>
              {event.name} <Code>({event.code})</Code>
            </p>
            <Button
              type="primary-link"
              onClick={() => setDisplayedSampleEvent(event)}
            >
              {t('View sample data')}
            </Button>
          </div>
        </AvailableWebhooksContainer>
      ))}
    </WebhookContainer>
  )
}

export default AvailableWebhooksFormList

const LoadingContainer = tw.div`h-64 overflow-hidden bg-gray-50 rounded border border-gray-300`

const EmptyContainer = tw.div`h-64 overflow-auto bg-gray-50 rounded border border-gray-300`

const WebhookContainer = tw.div`h-64 overflow-auto bg-gray-50 rounded border border-gray-300`

const AvailableWebhooksContainer = tw.div`flex text-gray-900 py-1 hover:bg-gray-200 px-4`

const Code = tw.span`text-gray-500`
