import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorScreen } from 'components/display'
import {
  Overview,
  ECGAdminVitalSigns,
  ECGAdminArchivedVitalSigns,
  ECGAdminVitalSignsAuditTrail,
  Organizations,
  Subscribers,
  Staff,
  StaffDetails,
  OrganizationDetails,
  AccessControl,
  Settings,
  OrgAdminVitalsSettings,
  OrgAdminVitalsAuditTrail,
  AccessControlModules,
  ECGAdminReminderMessages,
  OrgAdminVitalsThresholdsSettings,
  AccessControlModuleDetails,
  SubscriberDetails,
  ECGAdminReminderMessagesAuditTrail,
  SubscriberVitals,
  Vitals,
  AccessControlPermissions,
  ArchivedAccessControlPermissions,
  OrganizationReminderMessages,
  AccessControlPermissionsAuditTrail,
  OrganizationReminderMessagesAuditTrail,
  AccessControlPolicies,
  ArchivedAccessControlPolicies,
  AccessControlPoliciesAuditTrail,
  ArchivedAccessControlModules,
  ReminderPresets,
  AccessControlPolicyGroups,
  ArchivedAccessControlPolicyGroups,
  AccessControlPolicyGroupsAuditTrail,
  AccessControlModulesAuditTrail,
  AccessControlApplications,
  ArchivedAccessControlApplications,
  AccessControlApplicationsAuditTrail,
  CreateStaffForm,
  ReminderPresetsAuditTrail,
  SubscriberSettings,
  SubscriberReminderSchedules,
  SubscriberAlerts,
  DisclaimerTypes,
  Webhooks,
  Admins,
  AdminDetails,
  CreateAdminForm,
  DisclaimerTypeDetails,
  WebhookDetails,
  Organization,
  AlertDeliveryRules,
  DisclaimerTypesAuditTrail,
  DisclaimersAuditTrail,
  AlertsActivity,
  SubscriberVitalsThresholds,
  Partners,
  SubscriberVitalAssignments,
  SubscriberVitalAssignmentsAuditTrail,
  SubscriberReminderSchedulesAuditTrail,
  SubscriberArchivedVitalAssignments,
  PartnersList,
  CreatePartnerForm,
  PartnerTypes,
  UserProfile,
  PartnerTypeDetails,
  CreateAlertDeliveryRuleForm,
  AlertDeliveryRuleDetails,
  ArchivedOrganizations,
  AdminAccessControl,
  PartnerDetails,
  StaffAccessControl,
  VitalsActivity,
  ArchivedWebhooks,
  SubscriberInformation,
  SubscriberSetup,
  CreateSubscriber,
  OrganizationSetup,
  SubscriberDirectory,
  Contracts,
  ContractDetails,
  CreateContract,
  AddLineItem,
  BaseProduct,
  SpecificProduct,
  ProductType,
  ProductGroup,
  Orders,
  CreateOrder,
  OrderDetails,
  EditSubscriber,
  SubscriberManageMedication,
  Medications,
  SubscriberMedicationDetails,
} from 'pages'

const DataDisplay = () => {
  const location = useLocation()

  return (
    <ErrorBoundary
      FallbackComponent={ErrorScreen}
      resetKeys={[location.pathname]}
    >
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/vitals">
          <Route path="/" element={<Vitals />} />
          <Route path="vital-signs">
            <Route path="/" element={<ECGAdminVitalSigns />} />
            <Route path="archive" element={<ECGAdminArchivedVitalSigns />} />
            <Route
              path="audit-trail"
              element={<ECGAdminVitalSignsAuditTrail />}
            />
          </Route>
          <Route path="reminder-messages">
            <Route path="/" element={<ECGAdminReminderMessages />} />
            <Route
              path="audit-trail"
              element={<ECGAdminReminderMessagesAuditTrail />}
            />
          </Route>
          <Route path="alerts-activity" element={<AlertsActivity />} />
          <Route path="vitals-activity" element={<VitalsActivity />} />
        </Route>
        <Route path="/organizations">
          <Route path="/" element={<Organizations />} />
          <Route path="/:orgid">
            <Route path="/" element={<OrganizationDetails />} />
            <Route path="setup" element={<OrganizationSetup />} />
          </Route>
          <Route path="/archive" element={<ArchivedOrganizations />} />
        </Route>
        <Route path="/organization">
          <Route path="/" element={<Organization />} />
          <Route path="setup" element={<OrganizationSetup />} />
          <Route path="/:orgid" element={<OrganizationDetails />} />
        </Route>
        <Route path="/subscribers">
          <Route path="/" element={<Subscribers />} />
          <Route path="directory" element={<SubscriberDirectory />} />
          <Route path="create-subscriber" element={<CreateSubscriber />} />

          <Route path="/:subscriberId">
            <Route path="/" element={<SubscriberDetails />} />
            <Route path="setup" element={<SubscriberSetup />} />
            <Route path="vitals" element={<SubscriberVitals />} />
            <Route path="alerts-activity" element={<SubscriberAlerts />} />
            <Route path="information">
              <Route path="/" element={<SubscriberInformation />} />
              <Route path="edit-subscriber" element={<EditSubscriber />} />
            </Route>
            <Route path="settings">
              <Route path="/" element={<SubscriberSettings />} />
              <Route path="reminder-schedules">
                <Route path="/" element={<SubscriberReminderSchedules />} />
                <Route
                  path="audit-trail"
                  element={<SubscriberReminderSchedulesAuditTrail />}
                />
              </Route>
              <Route
                path="vitals-thresholds"
                element={<SubscriberVitalsThresholds />}
              />
              <Route path="vital-sign-assignments">
                <Route path="/" element={<SubscriberVitalAssignments />} />
                <Route
                  path="archive"
                  element={<SubscriberArchivedVitalAssignments />}
                />
                <Route
                  path="audit-trail"
                  element={<SubscriberVitalAssignmentsAuditTrail />}
                />
              </Route>
              <Route path="manage-medication">
                <Route path="/" element={<SubscriberManageMedication />} />
                <Route
                  path="/:medicationId"
                  element={<SubscriberMedicationDetails />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/staff">
          <Route path="/" element={<Staff />} />
          <Route path="/:staffid">
            <Route path="/" element={<StaffDetails />} />
            <Route path="/access-control" element={<StaffAccessControl />} />
          </Route>
          <Route path="/create-staff-member" element={<CreateStaffForm />} />
        </Route>
        <Route path="/admins">
          <Route path="/" element={<Admins />} />
          <Route path="/:adminId">
            <Route path="/" element={<AdminDetails />} />
            <Route path="/access-control" element={<AdminAccessControl />} />
          </Route>
          <Route path="/create-admin" element={<CreateAdminForm />} />
        </Route>
        <Route path="/partners">
          <Route path="/" element={<Partners />} />
          <Route path="list">
            <Route path="/" element={<PartnersList />} />
            <Route path="/:partnerId" element={<PartnerDetails />} />
          </Route>
          <Route path="create-partner" element={<CreatePartnerForm />} />
          <Route path="partner-types">
            <Route path="/" element={<PartnerTypes />} />
            <Route
              path="/:partnerTypeId"
              // specifying this key forces a remount of the page to rerun animations when partnerTypeId changes
              element={<PartnerTypeDetails key={location.pathname} />}
            />
          </Route>
        </Route>
        <Route path="/settings">
          <Route path="/" element={<Settings />} />
          <Route path="disclaimer-types">
            <Route path="/" element={<DisclaimerTypes />} />
            <Route path="/:disclaimerTypeId">
              <Route path="/" element={<DisclaimerTypeDetails />} />
              <Route path="/audit-trail" element={<DisclaimersAuditTrail />} />
            </Route>
            <Route
              path="/audit-trail"
              element={<DisclaimerTypesAuditTrail />}
            />
          </Route>
        </Route>
        <Route path="/organization-settings">
          <Route path="/" element={<Settings />} />
          <Route path="vital-sign-assignments">
            <Route path="/" element={<OrgAdminVitalsSettings />} />
            <Route path="audit-trail" element={<OrgAdminVitalsAuditTrail />} />
          </Route>
          <Route path="vitals-thresholds">
            <Route path="/" element={<OrgAdminVitalsThresholdsSettings />} />
          </Route>
          <Route path="reminder-messages">
            <Route path="/" element={<OrganizationReminderMessages />} />
            <Route
              path="audit-trail"
              element={<OrganizationReminderMessagesAuditTrail />}
            />
          </Route>
          <Route path="reminder-presets">
            <Route path="/" element={<ReminderPresets />} />
            <Route path="audit-trail" element={<ReminderPresetsAuditTrail />} />
          </Route>
          <Route path="webhooks">
            <Route path="/" element={<Webhooks />} />
            <Route path="/archive" element={<ArchivedWebhooks />} />
            <Route path="/:subscriptionId" element={<WebhookDetails />} />
          </Route>
          <Route path="alert-delivery-rules">
            <Route path="/" element={<AlertDeliveryRules />} />
            <Route
              path="/create-alert-delivery-rule"
              element={<CreateAlertDeliveryRuleForm />}
            />
            <Route
              path="/:deliveryRuleId"
              element={<AlertDeliveryRuleDetails />}
            />
          </Route>
        </Route>
        <Route path="/contracts">
          <Route path="/" element={<Contracts />} />
          <Route path="/create-contract" element={<CreateContract />} />
          <Route path="/:contractId">
            <Route path="/" element={<ContractDetails />} />
            <Route path="/add-line-item">
              <Route path="/" element={<AddLineItem />} />
              <Route path="/PrimaryProduct" element={<BaseProduct />} />
              <Route path="/DeviceOrService" element={<SpecificProduct />} />
              <Route path="/ProductType" element={<ProductType />} />
              <Route path="/product-group" element={<ProductGroup />} />
            </Route>
          </Route>
        </Route>
        <Route path="/orders">
          <Route path="/" element={<Orders />} />
          <Route path="/create-order" element={<CreateOrder />} />
          <Route path="/:orderId">
            <Route path="/" element={<OrderDetails />} />
          </Route>
        </Route>
        <Route path="/access-control">
          <Route path="/" element={<AccessControl />} />
          <Route path="/modules">
            <Route path="/" element={<AccessControlModules />} />
            <Route path="/:moduleid" element={<AccessControlModuleDetails />} />
            <Route path="/archive" element={<ArchivedAccessControlModules />} />
            <Route
              path="/audit-trail"
              element={<AccessControlModulesAuditTrail />}
            />
          </Route>
          <Route path="/permissions">
            <Route path="/" element={<AccessControlPermissions />} />
            <Route
              path="/archive"
              element={<ArchivedAccessControlPermissions />}
            />
            <Route
              path="/audit-trail"
              element={<AccessControlPermissionsAuditTrail />}
            />
          </Route>
          <Route path="/policies">
            <Route path="/" element={<AccessControlPolicies />} />
            <Route
              path="/archive"
              element={<ArchivedAccessControlPolicies />}
            />
            <Route
              path="/audit-trail"
              element={<AccessControlPoliciesAuditTrail />}
            />
          </Route>
          <Route path="/policy-groups">
            <Route path="/" element={<AccessControlPolicyGroups />} />
            <Route
              path="/archive"
              element={<ArchivedAccessControlPolicyGroups />}
            />
            <Route
              path="/audit-trail"
              element={<AccessControlPolicyGroupsAuditTrail />}
            />
          </Route>
          <Route path="/applications">
            <Route path="/" element={<AccessControlApplications />} />
            <Route
              path="/archive"
              element={<ArchivedAccessControlApplications />}
            />
            <Route
              path="/audit-trail"
              element={<AccessControlApplicationsAuditTrail />}
            />
          </Route>
        </Route>
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/medications">
          <Route path="/" element={<Medications />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  )
}

export default DataDisplay
