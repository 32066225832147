import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { ReminderPresetsAuditTrailList } from 'components/reminder-presets'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const ReminderPresetsAuditTrail = () => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  return (
    <Layout
      title={t('Audit Trail')}
      description={t(
        'Review all organization-level actions in relation to reminder presets.'
      )}
      isUnauthorized={!assumedOrganizationRole}
      breadcrumbs={[
        {
          name: t('Organization Settings'),
          route: '/organization-settings',
        },
        {
          name: t('Reminder Presets'),
          route: '/organization-settings/reminder-presets',
        },
        {
          name: t('Audit Trail'),
        },
      ]}
    >
      <ReminderPresetsAuditTrailList />
    </Layout>
  )
}

export default ReminderPresetsAuditTrail
