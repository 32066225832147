import React, { useState, useEffect } from 'react'
import Icon from '../Icon'
import { Button } from 'atlas'
import tw, { styled } from 'twin.macro'
import { useTranslation } from 'react-i18next'

type ListPaginationControlsProps = {
  paginationInfo?: {
    recordsPerPage?: number
    totalRecords?: number
    currentPageNumber?: number
    totalPages?: number
  }
  onPageChange?: (newPage: number) => void
}

const ListPaginationControls = ({
  paginationInfo,
  onPageChange,
}: ListPaginationControlsProps) => {
  const { t } = useTranslation()

  const offset = paginationInfo?.currentPageNumber
    ? paginationInfo.currentPageNumber - 1
    : 0

  const [
    optimisticCurrentPageNumber,
    setOptimisticCurrentPageNumber,
  ] = useState<number>(offset + 1)

  const [goToPageInput, setGoToPageInput] = useState<string>('')

  // when the optimistic current page number changes, let react-query know so the data can be pulled
  useEffect(() => {
    onPageChange?.(optimisticCurrentPageNumber)
  }, [optimisticCurrentPageNumber])

  // listen to changes to paginationInfo and update current page number if so
  useEffect(() => {
    if (offset !== optimisticCurrentPageNumber + 1)
      setOptimisticCurrentPageNumber(offset + 1)
  }, [offset])

  const goToPage = (
    e: React.FormEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    // only change the page if the input is valid
    if (isValidPageNumberInput) setOptimisticCurrentPageNumber(+goToPageInput)
  }

  // show a placeholder if paginationInfo is still loading
  if (
    !(
      paginationInfo &&
      paginationInfo.currentPageNumber &&
      paginationInfo.totalPages &&
      paginationInfo.totalRecords &&
      paginationInfo.recordsPerPage
    )
  )
    return (
      <Pagination>
        <PaginationInfo>
          {t('Showing')}&nbsp;
          <PaginationInfoBold>
            - {t('to')} - {t('of')} -
          </PaginationInfoBold>
          &nbsp;{t('entries')}
        </PaginationInfo>

        <PaginationControls>
          <GoToPageContainer>
            <GoToPageLabel>{t('Go to Page')}:</GoToPageLabel>
            <form onSubmit={goToPage}>
              <PageInput
                value={goToPageInput}
                disabled
                onChange={(e) => setGoToPageInput(e.target.value)}
              />
              <Button
                type="primary-link"
                onClick={goToPage}
                htmlType="submit"
                disabled
              >
                {t('Go')}
              </Button>
            </form>
          </GoToPageContainer>
        </PaginationControls>
      </Pagination>
    )

  // calculate common variables
  const showingNumber =
    (optimisticCurrentPageNumber - 1) * paginationInfo.recordsPerPage || 1
  const showingOutOfNumber = Math.min(
    optimisticCurrentPageNumber * paginationInfo.recordsPerPage,
    paginationInfo.totalRecords
  )
  const isFirstPage = optimisticCurrentPageNumber <= 1
  const isLastPage = optimisticCurrentPageNumber === paginationInfo.totalPages
  const isValidPageNumberInput =
    !isNaN(+goToPageInput) &&
    +goToPageInput > 0 &&
    +goToPageInput < paginationInfo.totalPages + 1

  return (
    <Pagination>
      <PaginationInfo>
        {t('Showing')}&nbsp;
        <PaginationInfoBold>
          {showingNumber} {t('to')} {showingOutOfNumber} {t('of')}{' '}
          {paginationInfo.totalRecords}
        </PaginationInfoBold>
        &nbsp;{t('entries')}
      </PaginationInfo>

      <PaginationControls>
        <PageNavigationButton
          type="arrow-left"
          action="prev"
          disabled={isFirstPage}
          onClick={() => {
            setOptimisticCurrentPageNumber(
              isFirstPage ? 1 : optimisticCurrentPageNumber - 1
            )
          }}
        />

        {new Array(paginationInfo.totalPages)
          .fill(null)
          .map((_, index) => {
            return (
              <PageNumberButton
                isCurrentPage={optimisticCurrentPageNumber === index + 1}
                key={`page-${index + 1}-button`}
                onClick={() => setOptimisticCurrentPageNumber(index + 1)}
              >
                {index + 1}
              </PageNumberButton>
            )
          })
          .filter((component, index) => {
            if (!paginationInfo.totalPages) return false

            // anything with 7 or less pages is fine
            if (paginationInfo.totalPages && paginationInfo.totalPages <= 7)
              return true

            // show the first and the last page
            if (index === 0 || index === paginationInfo.totalPages - 1)
              return true

            // show pages within 2 pages of the current page
            if (
              index <= optimisticCurrentPageNumber + 1 &&
              index >= optimisticCurrentPageNumber - 3
            )
              return true

            return false
          })
          // now we need to dynamically add the seperators (...)
          .map((component, index, components) => {
            // if we're on the last component or if there is a gap between the numbering
            // add a seperator
            if (
              index !== components.length - 1 &&
              +components[index + 1]?.props.children - 1 !==
                +component.props.children
            )
              return (
                <>
                  {component}
                  <PageNumberButton isCurrentPage={false} disabled>
                    ...
                  </PageNumberButton>
                </>
              )

            return component
          })}

        <PageNavigationButton
          type="arrow-right"
          action="next"
          disabled={isLastPage}
          onClick={() =>
            setOptimisticCurrentPageNumber(
              isLastPage
                ? optimisticCurrentPageNumber
                : optimisticCurrentPageNumber + 1
            )
          }
        />
        <GoToPageContainer>
          <GoToPageLabel>{t('Go to Page')}:</GoToPageLabel>
          <form onSubmit={goToPage}>
            <PageInput
              value={goToPageInput}
              onChange={(e) => setGoToPageInput(e.target.value)}
            />
            <Button
              type="primary-link"
              onClick={goToPage}
              htmlType="submit"
              disabled={!isValidPageNumberInput}
            >
              {t('Go')}
            </Button>
          </form>
        </GoToPageContainer>
      </PaginationControls>
    </Pagination>
  )
}

export default ListPaginationControls

const Pagination = tw.div`flex justify-between items-center pt-8`

const PaginationInfo = tw.div`flex text-gray-500`

const PaginationInfoBold = tw.span`font-semibold text-gray-900`

const PaginationControls = tw.div`flex justify-between items-center`

const PageNumberButton = styled.p<{
  isCurrentPage: boolean
  disabled?: boolean
}>`
  ${tw`px-1 w-8 text-center select-none`}
  ${({ isCurrentPage }) =>
    isCurrentPage
      ? tw`bg-blue-400 rounded-lg text-white`
      : tw`cursor-pointer text-gray-600`}
  ${({ disabled }) => disabled && tw`cursor-default!`}
`

const PageNavigationButton = styled(Icon)<{
  disabled?: boolean
  action: 'prev' | 'next'
}>`
  ${tw`text-gray-700`}
  ${({ disabled }) =>
    disabled ? tw`opacity-70 cursor-default` : tw`cursor-pointer`}
  ${({ action }) => (action === 'prev' ? tw`mr-2.5` : tw`ml-2.5`)}
`

const GoToPageContainer = tw.div`border-l flex items-center ml-6 pl-6`

const GoToPageLabel = tw.p`text-gray-600`

const PageInput = tw.input`rounded border border-gray-200 mx-2 w-12 py-0.5 px-1 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50`
