import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { ContractsList } from 'components/contracts'

const Contracts = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Layout
      title={t('Contracts')}
      description={t('Create and organize order contracts with organizations.')}
      breadcrumbs={[{ name: t('Contracts') }]}
      controls={[
        {
          label: t('Create Contract'),
          onClick: () => navigate('create-contract'),
        },
        {
          label: t('View Audit Trail'),
          onClick: () => navigate('audit-trail'),
          // TODO: enable this audit trail when Orders API has audit trails
          disabled: true,
        },
      ]}
    >
      <ContractsList />
    </Layout>
  )
}

export default Contracts
