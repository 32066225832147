import { FadeInSlideDown } from 'animations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Select, SelectItem, TextField, AutoComplete } from 'elements'
import { Button, Empty } from 'atlas'
import { useDelay } from 'hooks'
import { useCreateAlertDeliveryRuleMutation } from 'hooks/alert-delivery-rules'
import { useStaffListQuery } from 'hooks/user-staff'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { formatDate } from 'utils'
import tw from 'twin.macro'

type CreateAlertDeliveryRuleFormRecipientsProps = {
  previousStep: () => void
  className?: string
}

const CreateAlertDeliveryRuleFormRecipients = ({
  previousStep,
  className,
}: CreateAlertDeliveryRuleFormRecipientsProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const delay = useDelay()

  const {
    mutate: createAlertDeliveryRule,
    isLoading: isLoadingAlertDeliveryMutation,
  } = useCreateAlertDeliveryRuleMutation()

  const {
    data: staffMembersResponse,
    isLoading: isLoadingStaffMembers,
  } = useStaffListQuery(assumedOrganizationRole?.orgID)

  const { errors, handleSubmit } = useFormContext<AlertDeliveryRuleFormLocal>()

  const [selectStaffMembers, setSelectStaffMembers] = useState<
    Array<StaffMember> | undefined
  >()

  if (isLoadingStaffMembers || !staffMembersResponse) return null
  return (
    <div className={className}>
      <FadeInSlideDown delay={delay()}>
        <DeliveryDetailsCard>
          <DetailsTitle>{t('Delivery Details')}</DetailsTitle>
          <Select
            name="criteria.alertCategory"
            label={t('Delivery Method')}
            variant="outlined"
            fullWidth={false}
            selectClassName="w-48"
            defaultValue="email"
            disabled
          >
            <SelectItem value={'email'}>Email</SelectItem>
          </Select>
          <Controller
            as={TextField}
            name="delivery.message.subject"
            label={t('Email Subject')}
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Email Subject is required') }}
            error={errors.delivery?.message?.subject?.message}
            // this is purely here to prevent console.warns
            defaultValue=""
            className="w-96 mt-11 mr-4"
            placeholder="Severity 2 Vitals Alerts"
            required
          />
          <br />
          <Controller
            as={TextField}
            name="delivery.message.message"
            label={t('Email Message')}
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Email Message is required') }}
            error={errors.delivery?.message?.message?.message}
            // this is purely here to prevent console.warns
            defaultValue=""
            className="w-96 mt-4"
            placeholder="This is an automated email containing all severity 2 vitals alerts of the past week"
            multiline
            required
          />
        </DeliveryDetailsCard>
        <RecipientsCard>
          <RecipientsTitle>{t('Recipients')}</RecipientsTitle>
          {staffMembersResponse.items &&
          staffMembersResponse.items.length > 0 ? (
            <AutoComplete
              placeholder="Search Recipients"
              entityLabel={(value) => value.id}
              chipColor="gray"
              options={staffMembersResponse.items}
              onChange={setSelectStaffMembers}
              selectedOptions={selectStaffMembers}
              optionLabel={(value) => {
                return (
                  value.person.firstName +
                  ' ' +
                  value.person.lastName +
                  ' (' +
                  value.person.emailAddress +
                  ')'
                )
              }}
            />
          ) : (
            <Empty
              title={t('No Data Found')}
              description={t('No staff members exist in this organization')}
            />
          )}
        </RecipientsCard>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <Button disabled={isLoadingAlertDeliveryMutation} to="../">
          {t('Cancel')}
        </Button>
        &nbsp;
        <Button
          disabled={isLoadingAlertDeliveryMutation}
          onClick={previousStep}
        >
          {t('Back')}
        </Button>
        &nbsp;
        <Button
          onClick={handleSubmit((formData) => {
            if (!selectStaffMembers)
              return new Error('No selected staff members')
            createAlertDeliveryRule(
              {
                alertDeliveryRuleForm: {
                  ...formData,
                  schedule: {
                    ...formData.schedule,
                    weekdays: undefined,
                    // @ts-expect-error API discrepancy
                    weekday: formData.schedule.weekdays
                      ? formatWeekdays(formData.schedule.weekdays)
                      : undefined,
                    time:
                      formData.schedule.frequency === 'weekly' ||
                      formData.schedule.frequency === 'daily'
                        ? formatDate(formData.schedule.time, 'HHmm')
                        : undefined,
                    interval:
                      formData.schedule.frequency !== 'weekly' &&
                      formData.schedule.frequency !== 'real-time'
                        ? Number(formData.schedule.interval)
                        : undefined,
                  },
                  delivery: {
                    ...formData.delivery,
                    contacts: selectStaffMembers.map((staffMember) => ({
                      id: staffMember.id,
                      email: staffMember.person.emailAddress,
                    })),
                  },
                },
                orgId: assumedOrganizationRole?.orgID || '',
              },
              {
                onSuccess: () => navigate('../'),
              }
            )
          })}
          type="primary-filled"
          data-testid="basic-information-continue"
          disabled={
            !selectStaffMembers ||
            selectStaffMembers.length <= 0 ||
            isLoadingAlertDeliveryMutation
          }
        >
          {t('Create Alert Delivery Rule')}
        </Button>
      </FadeInSlideDown>
    </div>
  )
}

export default CreateAlertDeliveryRuleFormRecipients

// temporary function to match discrepancy with weekday enums across different API endpoints
const formatWeekdays = (weekdays: Array<Weekdays>) => {
  const weekdayMap: Record<Weekdays, string> = {
    mon: 'M',
    tue: 'T',
    wed: 'W',
    thu: 'H',
    fri: 'F',
    sat: 'S',
    sun: 'U',
  }
  return weekdays.map((weekday) => weekdayMap[weekday])
}

const DeliveryDetailsCard = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const DetailsTitle = tw.h3`text-xl font-semibold mb-4`

const RecipientsCard = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const RecipientsTitle = tw.h3`text-xl font-semibold mb-2`
