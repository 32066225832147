import React, { useState, useRef } from 'react'
import tw, { styled } from 'twin.macro'
import Icon from './Icon'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'

type EllipsisDropdownProps = {
  testid?: string
  options: Array<{
    label: string
    onClick: (args: {
      setIsDropdownVisible: (newValue: boolean) => void
    }) => void
  }>
  disabled?: boolean
}

const EllipsisDropdown = ({
  testid,
  options,
  disabled,
}: EllipsisDropdownProps) => {
  const dropdownAnchorEl = useRef(null)
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

  return (
    <Button
      disabled={disabled}
      ref={dropdownAnchorEl}
      onClick={(e) => {
        e.currentTarget.focus()
        setIsDropdownVisible(!isDropdownVisible)
      }}
      data-testid={testid}
    >
      <Icon type="ellipsis" className="text-gray-900 w-5 h-5" />
      <Dropdown
        visible={isDropdownVisible}
        setVisible={setIsDropdownVisible}
        parentRef={dropdownAnchorEl}
        verticalOffset={5}
      >
        {options.map((option) => (
          <DropdownItem
            key={option.label}
            onClick={() => option.onClick({ setIsDropdownVisible })}
          >
            {option.label}
          </DropdownItem>
        ))}
      </Dropdown>
    </Button>
  )
}

export default EllipsisDropdown

const Button = styled.button<{ disabled?: boolean }>`
  ${({ disabled }) => {
    if (disabled)
      return tw`relative inline-flex self-center text-left py-0.5 px-1 cursor-not-allowed`
    else
      return tw`relative inline-flex self-center hover:bg-white border transition-all border-gray-200 focus:border-opacity-100 rounded-lg shadow-none hover:shadow-md focus:shadow-md border-opacity-0 hover:border-opacity-100 py-0.5 px-1 outline-none focus:outline-none focus:ring-2 focus:ring-blue-300 text-left`
  }}
`
