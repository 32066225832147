import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCreateShippingOptionMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/organizations/{organizationId}/contracts/{contractId}/shipping-options'],
    createShippingOptionArgs
  >({
    mutationFn: createShippingOption,
    successMsg: t('Shipping option created'),
    // TODO: replace with Optimistic Update
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getOrganizationContracts', organizationId],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ contractId }) => ['getContract', contractId],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}'],
          createShippingOptionArgs
        >((oldCache, { shippingOptionForm, contractId }) =>
          oldCache
            ? {
                id: contractId,
                ...oldCache,
                shippingPrices: [
                  ...(oldCache.shippingPrices || []),
                  {
                    contractId: contractId,
                    priceDescriptor: shippingOptionForm,
                  },
                ],
              }
            : undefined
        ),
      },
      {
        cacheKey: ({ contractId }) => [
          'getContractShippingOptions',
          contractId,
        ],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}/shipping-options'],
          createShippingOptionArgs
        >((oldCache, { shippingOptionForm, contractId }) =>
          oldCache?.items
            ? {
                items: [
                  ...oldCache.items,
                  {
                    contractId: contractId,
                    priceDescriptor: shippingOptionForm,
                  },
                ],
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useCreateShippingOptionMutation

type createShippingOptionArgs = {
  shippingOptionForm: ContractShippingOptionForm
  organizationId: string
  contractId: string
}

const createShippingOption = async ({
  shippingOptionForm,
  organizationId,
  contractId,
}: createShippingOptionArgs) =>
  ordersApi.post(
    '/organizations/{organizationId}/contracts/{contractId}/shipping-options',
    {
      pathParams: { organizationId, contractId },
      body: shippingOptionForm,
    }
  )
