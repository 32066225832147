import React from 'react'
import { FadeInSlideDown } from 'animations'
import { Empty } from 'atlas'
import {
  OnboardedSubscribersList,
  PartnerInfoCard,
  PartnerSelect,
} from 'components/partners'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { useDelay } from 'hooks'
import { useSubscribersQuery } from 'hooks/organizations'
import { usePartnerQuery } from 'hooks/partners'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'

const StaffDetails = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { partnerId } = useParams()
  const { t } = useTranslation()
  const delay = useDelay()

  const { data: partner, isLoading: isLoadingPartner } = usePartnerQuery(
    partnerId
  )

  const { isLoading: isLoadingSubscribers } = useSubscribersQuery()

  // TODO: show subscribers when they have an onBoardingPartner like organization does
  // const partnerSubscribers = subscribers?.items.filter((subscriber) => subscriber.)

  return (
    <Layout
      description={t('Manage and review partner details.')}
      isUnauthorized={!!assumedOrganizationRole}
      title={
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold">{t('Partner Details')}:</h1>
          <PartnerSelect />
        </div>
      }
      isLoading={isLoadingPartner || isLoadingSubscribers}
      isEmpty={!partner}
      emptyComponent={
        <Empty
          title={t('Partner Not Found')}
          description={t(
            `Either this partner does not exist or you don't have access to view them`
          )}
        />
      }
      breadcrumbs={[
        {
          name: t('Partners'),
          route: '../../',
        },
        {
          name: t('Partner List'),
          route: '../',
        },
        {
          name: partner?.person
            ? `${partner.person.firstName} ${partner.person.lastName}`
            : partner?.organization
            ? partner.organization.businessName
            : '',
        },
      ]}
    >
      <FadeInSlideDown delay={delay()}>
        {/* @ts-expect-error Layout handles the empty case */}
        <PartnerInfoCard partnerInfo={partner} />
      </FadeInSlideDown>
      <br />
      <FadeInSlideDown delay={delay()}>
        <Title>{t('Onboarded Subscribers')}</Title>
      </FadeInSlideDown>
      <OnboardedSubscribersList subscribers={[]} delay={delay} />
    </Layout>
  )
}

export default StaffDetails

const Title = tw.h3`text-xl font-semibold mb-2`
