import React, { useMemo, useCallback, useState, useRef } from 'react'
import { List, EntityLabel } from 'elements'
import { Button } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTable, Column, useSortBy, Row, SortByFn } from 'react-table'
import i18next from 'i18next'
import {
  useOrganizationListQuery,
  useSubscribersQuery,
} from 'hooks/organizations'
import {
  useAccountPermissionsQuery,
  usePinnedOrganizationsQuery,
  usePinnedOrganizationsMutation,
} from 'hooks/organizations'
import { Pin } from 'animations'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useNavigate } from 'hooks'
import { useEntityLabelSort } from 'hooks/react-table'
import { OrganizationStatusChip } from 'components/organizations'
import {
  customOrderBy,
  urlParamsToOrganizationFilters,
  organizationFiltersToUrlParams,
} from 'utils'
import { useSearchParams } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'

type OrganizationListProps = {
  archived?: boolean
}

type OrganizationRowItem = {
  id: string
  legacyIdentifier: string
  displayName: string
  status: string
  pinned: boolean
  businessName: string
}

const OrganizationList = ({ archived }: OrganizationListProps) => {
  const accountPermissionsQuery = useAccountPermissionsQuery()
  const {
    assumedOrganizationRole,
    assumeOrganizationRole,
  } = useAssumedOrganizationRole()

  const pinnedOrganizationsQuery = usePinnedOrganizationsQuery()
  const [searchParams] = useSearchParams()

  // set initial filters to urlParams if they exist
  const [
    organizationFilters,
    setOrganizationFilters,
  ] = useState<OrganizationFilters>({
    ...urlParamsToOrganizationFilters(searchParams),
    ...(archived ? { status: ['Archived'] } : {}),
  })

  const skipSyncToURL = useRef<boolean>(false)
  const prevFilters = useRef<OrganizationFilters | undefined>(undefined)
  const prevHref = useRef<string>(location.href)

  // update the url with the latest List filters
  useUpdateEffect(() => {
    !skipSyncToURL.current &&
      navigate({
        searchParams: organizationFiltersToUrlParams(organizationFilters),
      })

    skipSyncToURL.current = false
  }, [organizationFilters])

  // if the url changes but organizationFilters doesn't (i.e the user goes back in history) sync filter state to url params
  useUpdateEffect(() => {
    if (
      prevHref.current !== location.href &&
      _.isEqual(prevFilters.current, organizationFilters)
    ) {
      setOrganizationFilters(urlParamsToOrganizationFilters(searchParams))
      // don't trigger an update to the url to preserve the current history
      skipSyncToURL.current = true
    }
    prevHref.current = location.href
    prevFilters.current = organizationFilters
  }, [location.href, organizationFilters])

  type OrderType = (
    rows: Array<Row<OrganizationRowItem>>,
    sortFns: Array<SortByFn<OrganizationRowItem>>,
    directions: boolean[]
  ) => Array<Row<OrganizationRowItem>>

  const order: OrderType = useCallback((...row: Parameters<OrderType>) => {
    // @ts-expect-error Wrong type with react table
    return customOrderBy<OrganizationRowItem>(...row)
  }, [])

  const organizationListQuery = useOrganizationListQuery({
    orgId: assumedOrganizationRole?.orgID,
    organizationFilters,
    pageLength: 30,
  })
  const entityLabelSort = useEntityLabelSort({ accessor: 'businessName' })

  const navigate = useNavigate()
  const { t } = useTranslation()

  const tableData: Array<OrganizationRowItem> = useMemo(() => {
    //  structure org query to have pinned object, pinned object depends on pinned query
    const data = organizationListQuery.data?.items.map((org) => {
      return {
        id: org.id,
        legacyIdentifier: org.legacyIdentifier,
        displayName: org.displayName,
        status: org.status,
        pinned: pinnedOrganizationsQuery.data?.includes(org.id) || false,
        businessName: org.businessName,
      }
    })

    // remove orgs that aren't pinned
    return _.orderBy(data, (org) => !org.pinned)
  }, [
    organizationListQuery.data,
    accountPermissionsQuery.data,
    pinnedOrganizationsQuery.data,
    i18next.language,
  ])

  const subscribers = useSubscribersQuery()

  const columns: Column<OrganizationRowItem>[] = useMemo(
    () => [
      {
        id: 'ORGANIZATION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ORGANIZATION'),
        accessor: (row) => (
          <EntityLabel
            name={row.businessName}
            description={row.displayName}
            id={row.id}
            bold
          />
        ),
        sortType: entityLabelSort,
      },
      {
        id: 'LEGACY IDENTIFIER',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('LEGACY IDENTIFIER'),
        accessor: 'legacyIdentifier',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) => <OrganizationStatusChip status={row.status} />,
        width: '80px',
      },
      {
        id: 'ACTIONS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ACTIONS'),
        accessor: (row) => (
          <ActionsCell
            row={row}
            assumeOrganizationRole={assumeOrganizationRole}
            accountPermissions={accountPermissionsQuery.data?.data}
          />
        ),
        width: '0.5fr',
        disableSortBy: true,
      },
    ],
    [accountPermissionsQuery.data, i18next.language, subscribers.data]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      onRowClick: (row) => navigate(`/organizations/${row.original.id}`),
      orderByFn: order,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'ORGANIZATION',
            desc: false,
          },
        ],
      },
    },
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      setSearchTerm={(searchTerm) =>
        setOrganizationFilters({
          ...organizationFilters,
          searchTerm,
          skip: 0,
        })
      }
      searchTerm={organizationFilters.searchTerm}
      searchPlaceholder={`${t('Search')} ${
        organizationListQuery.data?.totalRecords
          ? organizationListQuery.data.totalRecords + ' '
          : ''
      }${t('organizations')}`}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
      isLoading={organizationListQuery.isFetching || subscribers.isFetching}
      paginatable
      paginationInfo={organizationListQuery.data}
      onPageChange={(newPageNumber) => {
        setOrganizationFilters({
          ...organizationFilters,
          skip: newPageNumber - 1,
        })
      }}
    />
  )
}

export default OrganizationList

type ActionsCellProps = {
  row: {
    id: string
    displayName: string
    businessName: string
    status: string
    pinned: boolean
  }
  assumeOrganizationRole: (args: {
    orgID: string
    onSuccess?: (searchParams: Record<string, string | string[]>) => void
  }) => void
  accountPermissions: AccountPermissions | undefined
}

const ActionsCell = ({ row, assumeOrganizationRole }: ActionsCellProps) => {
  const { t } = useTranslation()
  const pinnedOrganizationsMutation = usePinnedOrganizationsMutation()

  return (
    <>
      <Button
        type="primary-link"
        data-testid={`${row.businessName}-assume-role`}
        onClick={(e) => {
          e.stopPropagation()

          assumeOrganizationRole({
            orgID: row.id,
          })
        }}
      >
        {t('Assume Role')}
      </Button>
      <Pin
        testId={row.id}
        className="flex"
        pinned={row.pinned}
        onClick={() => {
          pinnedOrganizationsMutation.mutate({
            action: row.pinned ? 'unpin' : 'pin',
            organizationId: row.id,
          })
        }}
      />
    </>
  )
}
