import { vitalsApi } from 'utils'
import { useQuery } from 'react-query'

const useSubscriberVitalAssignmentsQuery = (
  subscriberId: string | undefined
) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments'],
    KyError
  >(
    ['getSubVitalAssignments', subscriberId],
    () => getSubscriberVitalSigns(subscriberId),
    { enabled: !!subscriberId }
  )

  return query
}

export default useSubscriberVitalAssignmentsQuery

// get list of vital signs from backend
const getSubscriberVitalSigns = async (subscriberId?: string) => {
  const result = await vitalsApi
    .get(`subscribers/${subscriberId}/vital-sign-assignments`)
    .json<
      VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
    >()
    .catch(async (e) => {
      const error = await e.response.json()
      // TODO: Ask API team for a better error message when vital sign assignments are empty
      if (error.code === 404) {
        const result: VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments'] = {
          id: '',
          subscriberId: subscriberId || '',
          assignedVitalSigns: [],
        }

        return result
      }
      throw e
    })

  return result
}
