import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, vitalsApi, fromUpdateObjects, handleQueryError } from 'utils'

const useUpdateAlertDeliveryRuleMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    UpdateAlertDeliveryRuleResponse,
    KyError,
    updateAlertDeliveryRuleArgs,
    GetAlertDeliveryRulesResponse | undefined
  >(updateAlertDeliveryRule, {
    onMutate: ({ requests: updateObjects, orgId, deliveryRuleId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<GetAlertDeliveryRulesResponse>([
        'getAlertDeliveryRules',
        orgId,
      ])

      // immediately update the module list
      if (snapshot) {
        queryCache.setQueryData<GetAlertDeliveryRulesResponse | undefined>(
          ['getAlertDeliveryRules', orgId],
          (oldCache) =>
            oldCache
              ? {
                  ...oldCache,
                  items: oldCache.items.map((alertDeliveryRule) => {
                    if (alertDeliveryRule.id === deliveryRuleId)
                      return fromUpdateObjects(alertDeliveryRule, updateObjects)

                    return alertDeliveryRule
                  }),
                }
              : undefined
        )
      }

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Alert Delivery Rule updated') })
    },
    onError: async (error, { orgId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetAlertDeliveryRulesResponse | undefined>(
        ['getAlertDeliveryRules', orgId],
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: (_, __, { orgId }) => {
      // refetch from API to make sure the alert contact list is in sync with the server
      queryCache.invalidateQueries(['getAlertDeliveryRules', orgId])
    },
  })

  return mutation
}

export default useUpdateAlertDeliveryRuleMutation

type updateAlertDeliveryRuleArgs = {
  requests: UpdateAlertDeliveryRuleRequest
  deliveryRuleId: string
  orgId: string
}

const updateAlertDeliveryRule = async ({
  requests,
  deliveryRuleId,
  orgId,
}: updateAlertDeliveryRuleArgs) => {
  const result = vitalsApi
    .patch(`organizations/${orgId}/alert-delivery-rules/${deliveryRuleId}`, {
      json: requests,
    })
    .json<UpdateAlertDeliveryRuleResponse>()

  return result
}
