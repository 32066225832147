import { FadeInSlideDown, FadeInSlideRight } from 'animations'
import { Button } from 'atlas'
import { NavigateWarningProvider } from 'context/navigate-warning'
import { useWizardForm, WizardFormProvider } from 'context/wizard-form'
import { useDelay, useNavigate } from 'hooks'
import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import Stepper from './Stepper'

type WizardStep = {
  /** component that is rendered when this step is the activeStep */
  component: JSX.Element
  /** text to be shown on the stepper */
  label: string
  /** icon shown on the stepper */
  icon: IconType
}

type WizardFormProps = {
  children: WizardStep[]
  /** The name of the form used on close confirmation dialogs */
  formName?: string
  /** function called when final step of form is successful */
  onComplete?: () => unknown
  /** toggle form logic for editing an existing entity */
  isEditForm?: boolean
  /** manually toggle disabling navigation warnings */
  disableNavWarning?: boolean
}

const WizardForm = ({
  children: steps,
  formName,
  onComplete,
  isEditForm = false,
  disableNavWarning,
}: WizardFormProps) => {
  const delay = useDelay()

  return (
    <NavigateWarningProvider>
      <WizardFormProvider
        formName={formName}
        isEditForm={isEditForm}
        disableNavWarning={disableNavWarning}
      >
        <StepperContainer delay={delay()}>
          <VerticalStepperContainer steps={steps} isEditForm={isEditForm} />
        </StepperContainer>
        <StepContainer>
          <div>
            <Step steps={steps} />
          </div>
          <FadeInSlideDown delay={delay()}>
            <FormControls
              steps={steps}
              completeFn={onComplete}
              isEditForm={isEditForm}
              formName={formName}
            />
          </FadeInSlideDown>
        </StepContainer>
      </WizardFormProvider>
    </NavigateWarningProvider>
  )
}

export default WizardForm

const Step = ({ steps }: { steps: WizardStep[] }) => {
  const { stepState } = useWizardForm()

  return steps[stepState.activeStep].component
}

const VerticalStepperContainer = ({
  steps,
  isEditForm,
}: {
  steps: WizardStep[]
  isEditForm: boolean
}) => {
  const { stepState, setStepState } = useWizardForm()
  return (
    <VerticalStepper
      orientation="vertical"
      steps={steps}
      activeStep={stepState.activeStep}
      setActiveStep={(activeStep) => setStepState({ ...stepState, activeStep })}
      navigatableSteps={isEditForm}
    />
  )
}

// Component that renders the form buttons at the bottom
const FormControls = ({
  steps,
  completeFn,
  isEditForm = false,
  formName,
}: {
  steps: WizardStep[]
  completeFn?: () => unknown
  isEditForm?: boolean
  formName?: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { stepState, setStepState } = useWizardForm()
  const { validationFn, submitFn, isSubmitDisabled, formData } = stepState

  // if the activeStep is changed, reset the wizard form context
  // use LayoutEffect to insure this runs before any initial mount logic on the new Step
  useLayoutEffect(() => {
    setStepState({
      activeStep: stepState.activeStep,
      validationFn: undefined,
      submitFn: undefined,
      isSubmitDisabled: undefined,
      formData: undefined,
      disableNavigationWarning: undefined,
    })
  }, [stepState.activeStep])

  const handleSubmit = async () => {
    if (!validationFn || (await validationFn())) {
      if (submitFn) {
        setIsSubmitting(true)

        // disable navigation warning if this is the last step
        if (stepState.activeStep === steps.length - 1)
          setStepState({
            ...stepState,
            disableNavigationWarning: true,
          })

        // go to next step after submitFn completes
        submitFn(formData)
          .then(() => {
            if (!isEditForm)
              stepState.activeStep !== steps.length - 1
                ? setStepState({
                    ...stepState,
                    activeStep: stepState.activeStep + 1,
                  })
                : completeFn?.()
          })
          .finally(() => setIsSubmitting(false))
      } else
        stepState.activeStep !== steps.length - 1
          ? setStepState({
              ...stepState,
              activeStep: stepState.activeStep + 1,
            })
          : completeFn?.()
    }
  }

  return (
    <FormControlContainer>
      {stepState.activeStep > 0 && !isEditForm ? (
        <>
          <Button
            disabled={isSubmitting}
            type="primary"
            onClick={() =>
              setStepState({
                ...stepState,
                activeStep: stepState.activeStep - 1,
              })
            }
          >
            {t('Back')}
          </Button>
          &nbsp;
        </>
      ) : null}
      {/* Don't show this button if editing and there is no validationFn meaning no reason to save here */}
      {!isEditForm || validationFn ? (
        <>
          <Button
            onClick={() => handleSubmit()}
            disabled={isSubmitDisabled || (isEditForm && !submitFn)}
            isLoading={isSubmitting}
          >
            {isEditForm
              ? t('Save')
              : submitFn
              ? stepState.activeStep === steps.length - 1
                ? formName
                  ? `${t('Complete')} ${formName.replace(' Form', '')}`
                  : t('Complete Form')
                : t('Save & Continue')
              : t('Continue')}
          </Button>
          &nbsp;
        </>
      ) : null}
      {!isEditForm ? (
        <CancelButton
          disabled={isSubmitting}
          type="primary-link"
          onClick={() => navigate('../')}
        >
          {t('Cancel')}
        </CancelButton>
      ) : null}
    </FormControlContainer>
  )
}

export const ChildStepContainer = styled(FadeInSlideDown)<{
  className?: string
}>(({ className }) => [
  tw`bg-white p-5 rounded-lg border border-gray-300 mb-4`,
  className,
])

const StepContainer = tw.div`flex flex-col justify-between flex-grow`

const CancelButton = tw(Button)`ml-5`

const StepperContainer = tw(FadeInSlideRight)`h-auto min-w-max`

const VerticalStepper = styled(Stepper)`
  // Stepper height will always fit the available vertical space on screen (will not overflow with step content)
  height: calc(100vh - 213.25px);
  ${() => tw`-mt-4 mr-8 mb-4 top-0 sticky`}
`

const FormControlContainer = tw.div`mt-2`
