import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { ModulesAuditTrailList } from 'components/access-control/modules'

const AccessControlModulesAuditTrail = () => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Audit Trail')}
      description={t(
        'Review all actions taken in relation to ECG Platform modules.'
      )}
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Modules'), route: '/access-control/modules' },
        { name: t('Audit Trail') },
      ]}
    >
      <ModulesAuditTrailList />
    </Layout>
  )
}

export default AccessControlModulesAuditTrail
