import { useWizardForm } from 'context/wizard-form'
import { AddressAutoComplete, AutoComplete, TextField } from 'elements'
import { useCountryCodesQuery, useStateCodesQuery } from 'hooks/seed-data'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParam } from 'react-use'
import tw from 'twin.macro'
import { addressToMapBox } from 'utils'
import { addressFieldsToMatch } from './ShippingInformation'
import _ from 'lodash'
import { useAPIQuery } from 'hooks'

type OrderShippingAddress = Required<
  Required<Order>['shippingInformation']
>['address']

type CustomOrderAddressProps = {
  submitShippingAddress: (
    shippingAddress: OrderShippingInformationForm['shippingAddress']
  ) => Promise<unknown>
  validationFn: () => boolean
  isApiAddressCustom: boolean
  isShippingInfoChanged: boolean
}

const CustomOrderAddress = ({
  submitShippingAddress,
  validationFn,
  isApiAddressCustom,
  isShippingInfoChanged,
}: CustomOrderAddressProps) => {
  const { t } = useTranslation()

  const orderId = useSearchParam('orderId') || ''

  const { stepState, setStepState } = useWizardForm()

  const [customFields, setCustomFields] = useState<CustomFields>({
    address: null,
    country: null,
    province: null,
  })

  const [customErrors, setCustomErrors] = useState<CustomErrors>()

  const [newStateCode, setNewStateCode] = useState<string>()

  const countryCodes = useCountryCodesQuery()

  const stateCodes = useStateCodesQuery(customFields.country?.isoCode || '')

  const orderQuery = useAPIQuery('order', {
    pathParams: {
      orderId,
    },
  })

  const {
    errors,
    register,
    watch,
    reset,
    control,
    setValue,
    clearErrors,
    trigger,
  } = useForm<OrderShippingAddress>({
    defaultValues: {
      addressLine2: '',
      city: '',
      postalCode: '',
    },
  })

  // sync submission logic to stepState
  useEffect(() => {
    const customAddress = {
      addressLine1: customFields.address?.place_name.split(',')[0] || '',
      countryCode: customFields.country?.isoCode || '',
      stateProvinceCode: customFields.province?.isoCode || '',
      ...watch(),
    }

    // check if form is different from apiData
    const apiData = orderQuery.data?.shippingInformation
    const isFormChanged = !_.isEqual(
      _.pick(apiData?.address, addressFieldsToMatch),
      _.pick(customAddress, addressFieldsToMatch)
    )
    setStepState({
      ...stepState,
      isSubmitDisabled: false,
      submitFn:
        isShippingInfoChanged || isFormChanged
          ? () => submitShippingAddress(customAddress)
          : undefined,
      validationFn: async () => {
        const isFormValid = await trigger()
        const isCustomFieldsValid = validateCustomFields(
          customFields,
          setCustomErrors,
          t
        )

        return validationFn() && isFormValid && isCustomFieldsValid
      },
    })
  }, [
    customFields,
    JSON.stringify(watch()),
    submitShippingAddress,
    orderQuery.data?.shippingInformation,
  ])

  // initialize fields when query data loads
  useEffect(() => {
    // if address from api isn't a custom address don't use it to populate fields
    const apiAddress = isApiAddressCustom
      ? orderQuery.data?.shippingInformation?.address
      : undefined

    reset(
      apiAddress || {
        addressLine2: '',
        city: '',
        postalCode: '',
      }
    )

    const country =
      countryCodes.data?.items?.find(
        (country) =>
          country.isoCode === apiAddress?.countryCode ||
          country.name === apiAddress?.countryCode
      ) || null

    setCustomFields({
      address: apiAddress?.addressLine1
        ? addressToMapBox({ addressLine1: apiAddress.addressLine1 || '' })
        : null,
      country,
      province:
        stateCodes.data?.items?.find((province) => {
          return (
            province.isoCode === apiAddress?.stateProvinceCode ||
            province.name === apiAddress?.stateProvinceCode
          )
        }) || null,
    })
    setCustomErrors(undefined)
    setNewStateCode(apiAddress?.stateProvinceCode || undefined)
  }, [orderQuery.data?.shippingInformation?.address, countryCodes.data])

  // set state code when query loads since the query is dependent on country being set
  useEffect(() => {
    setCustomFields((customFields) => ({
      ...customFields,
      province:
        stateCodes.data?.items?.find((province) =>
          newStateCode?.includes(province.isoCode)
        ) || null,
    }))
  }, [stateCodes.data, newStateCode])

  // reset custom errors if a field is filled
  useEffect(() => {
    setCustomErrors((errors) =>
      Object.fromEntries(
        Object.keys(customFields).map((fieldKey) => [
          fieldKey,
          // if a custom field is falsey continue showing the error field, if not set to undefined
          !customFields[fieldKey as keyof typeof customFields]
            ? errors?.[fieldKey as keyof typeof errors]
            : undefined,
        ])
      )
    )
  }, [customFields])

  return (
    <CustomAddressContainer>
      <RowContainer>
        <AddressAutoComplete
          selectedOption={customFields.address}
          setSelectedOption={(address) =>
            setCustomFields((fields) => ({ ...fields, address }))
          }
          onChange={(selectedOption) => {
            setValue(
              'postalCode',
              selectedOption?.context.find(
                (parent) => parent.id.split('.')[0] === 'postcode'
              )?.text || '',
              { shouldDirty: true }
            )
            setValue(
              'city',
              selectedOption?.context.find(
                (parent) => parent.id.split('.')[0] === 'place'
              )?.text || '',
              { shouldDirty: true }
            )

            const country =
              countryCodes.data?.items?.find(
                (country) =>
                  country.isoCode.toLowerCase() ===
                  selectedOption?.context.find(
                    (parent) => parent.id.split('.')[0] === 'country'
                  )?.short_code
              ) || null
            setCustomFields({
              address: selectedOption,
              country,
              province: _.isEqual(country, customFields.country)
                ? customFields.province
                : null,
            })
            setNewStateCode(
              selectedOption?.context.find(
                (parent) => parent.id.split('.')[0] === 'region'
              )?.short_code
            )
            setCustomErrors(undefined)
            clearErrors()
          }}
          label={t('Address *')}
          className="flex-grow"
          error={customErrors?.address}
        />
      </RowContainer>
      <RowContainer>
        <TextField
          name="addressLine2"
          className="flex-grow"
          inputRef={register()}
          label={t('Apt / Suite / Other')}
        />
      </RowContainer>
      <RowContainer>
        <AutoComplete
          label={t('Country *')}
          single
          className="flex-grow"
          options={countryCodes.data?.items || []}
          onChange={(country) =>
            setCustomFields((fields) => ({
              ...fields,
              country,
              province: null,
            }))
          }
          selectedOption={customFields.country}
          optionLabel={(country) => country.name}
          error={customErrors?.country}
          disableAutofill
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          error={errors.city?.message}
          name="city"
          className="flex-grow"
          label={t('City')}
          shrink={!!watch('city')}
          rules={{ required: { value: true, message: 'City is required' } }}
          required
        />
      </RowContainer>
      <RowContainer>
        <AutoComplete
          label={t('State *')}
          single
          className="w-1/2 mr-4"
          options={stateCodes.data?.items || []}
          onChange={(province) =>
            setCustomFields((fields) => ({ ...fields, province }))
          }
          selectedOption={customFields.province}
          optionLabel={(province) => province.name}
          error={customErrors?.province}
          disableAutofill
        />
        <Controller
          control={control}
          as={TextField}
          label={t('Postal Code')}
          required
          error={errors.postalCode?.message}
          name="postalCode"
          type="number"
          shrink={!!watch('city')}
          className="flex-grow"
          rules={{
            required: { value: true, message: 'Postal Code is required' },
          }}
        />
      </RowContainer>
    </CustomAddressContainer>
  )
}

export default CustomOrderAddress

const CustomAddressContainer = tw.div`max-w-2xl`

const RowContainer = tw.div`flex transition-all`

type CustomFields = {
  address: MapBoxFeature | null
  country: Country | null
  province: Province | null
}

type CustomErrors = Partial<Record<keyof CustomFields, string>>

const validateCustomFields = (
  customFields: CustomFields,
  setCustomErrors: React.Dispatch<
    React.SetStateAction<CustomErrors | undefined>
  >,
  t: TFunction
) => {
  setCustomErrors({
    address: !customFields.address
      ? t('Address is required')
      : // prevents user from creating an address with only the street defined
      !customFields.address.address
      ? t('This is not an address')
      : undefined,
    country: !customFields.country ? t('Country is required') : undefined,
    province: !customFields.province ? t('State is required') : undefined,
  })
  return (
    Object.values(customFields).every((val) => !!val) &&
    !!customFields.address?.address
  )
}
