import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCreateOrderMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders'],
    createOrderArgs
  >({
    mutationFn: createOrder,
    successMsg: { message: t('Order created'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        cacheKey: ['orders'],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders'],
          createOrderArgs
        >((oldCache, orderForm) =>
          oldCache
            ? {
                ...oldCache,
                items: [
                  ...(oldCache.items || []),
                  { id: 'tempOrderId', ...orderForm },
                ],
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useCreateOrderMutation

type createOrderArgs = OrderForm

const createOrder = async (orderForm: createOrderArgs) =>
  ordersApi.post('/orders', {
    body: orderForm,
  })
