import { FadeInSlideDown } from 'animations'
import clsx from 'clsx'
import {
  Checkbox,
  Icon,
  LoadingIcon,
  RadioGroup,
  Select,
  SelectItem,
  Switch,
  TextField,
} from 'elements'
import { Button, Tooltip } from 'atlas'
import { generate } from 'generate-password'
import { useDelay } from 'hooks'
import { useCreatePartnerMutation, usePartnerTypesQuery } from 'hooks/partners'
import { useAddressTypesQuery, useSalutationTypesQuery } from 'hooks/seed-data'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { emailValidation, phoneValidation } from 'utils'
import tw, { styled } from 'twin.macro'

type CreatePartnerFormBasicInformationProps = {
  className?: string
}

const CreatePartnerFormBasicInformation = ({
  className,
}: CreatePartnerFormBasicInformationProps) => {
  const { t } = useTranslation()
  const delay = useDelay()
  const navigate = useNavigate()

  const {
    data: salutationTypes,
    isLoading: isLoadingSalutationTypes,
  } = useSalutationTypesQuery()
  const {
    data: partnerTypes,
    isLoading: isLoadingPartnerTypes,
  } = usePartnerTypesQuery()
  const {
    data: addressTypes,
    isLoading: isLoadingAddressTypes,
  } = useAddressTypesQuery()

  const {
    mutate: createPartner,
    isLoading: isCreatePartnerLoading,
  } = useCreatePartnerMutation()

  const {
    errors,
    watch,
    setValue,
    register,
    reset,
    unregister,
    handleSubmit,
  } = useFormContext<CreatePartnerForm>()

  const [passwordType, setPasswordType] = useState<string>('Auto-Generated')
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false)
  const [contactEmailTouched, setContactEmailTouched] = useState<boolean>(false)
  const [accountEmailTouched, setAccountEmailTouched] = useState<boolean>(false)
  const [isShippingAddressSame, setIsShippingAddressSame] = useState<boolean>(
    true
  )

  const selectedPartnerType = partnerTypes?.items.find(
    (partnerType) => partnerType.id === watch('partnerType')
  )

  // set value of partnerType once API response comes in
  useEffect(() => {
    setValue('partnerType', partnerTypes?.items[0].id)
  }, [partnerTypes])

  // handle resetting fields when switching between org partner type or individual partner
  useEffect(() => {
    if (selectedPartnerType?.isOrganization) {
      unregister(['person', 'account'])
      reset({
        partnerType: watch('partnerType'),
        allowSubscriberAccess: true,
        organization: {
          type: 'anything',
          displayName: '',
          businessName: '',
        },
      })
    } else {
      unregister('organization')
      reset({
        partnerType: watch('partnerType'),
        allowSubscriberAccess: false,
        account: {
          email: '',
          password: generate({
            length: 10,
            numbers: true,
            symbols: true,
            lowercase: true,
            uppercase: true,
            excludeSimilarCharacters: true,
            strict: true,
          }),
        },
        person: {
          salutation: 1,
          firstName: '',
          lastName: '',
          suffix: '',
          emailAddress: '',
        },
      })
    }
  }, [selectedPartnerType])

  useEffect(() => {
    if (passwordType === 'Manual') setValue('account.password', '')
    if (passwordType === 'Auto-Generated')
      setValue(
        'account.password',
        generate({
          length: 10,
          numbers: true,
          symbols: true,
          lowercase: true,
          uppercase: true,
          excludeSimilarCharacters: true,
          strict: true,
        })
      )
  }, [passwordType])

  if (
    isLoadingSalutationTypes ||
    isLoadingPartnerTypes ||
    isLoadingAddressTypes ||
    !salutationTypes?.items ||
    !partnerTypes ||
    !addressTypes ||
    isCreatePartnerLoading
  )
    return <LoadingIcon />

  return (
    <div className={className}>
      <FadeInSlideDown delay={delay()}>
        <InfoCard>
          <InfoTitle>{t('Partner Information')}</InfoTitle>
          <PartnerInfo>
            <Controller
              as={
                <Select
                  label={t('Partner Type *')}
                  variant="outlined"
                  fullWidth={false}
                  selectClassName="w-72"
                >
                  {partnerTypes.items.map((partnerType, index) => (
                    <SelectItem value={partnerType.id} key={index}>
                      {partnerType.name}
                    </SelectItem>
                  ))}
                </Select>
              }
              name="partnerType"
              defaultValue={partnerTypes.items[0].id}
            />
            {!selectedPartnerType?.isOrganization ? (
              <PartnerSwitch>
                <Controller
                  render={({ onChange, value, name, ref }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      id={name}
                      ref={ref}
                      label={t('Allow Subscriber Access')}
                      className="mt-3 mr-1"
                    />
                  )}
                  defaultValue={false}
                  name="allowSubscriberAccess"
                />
                <Tooltip
                  className="flex items-center"
                  content={
                    <p className="w-60">{`${t(
                      'Selecting this option will allow this partner to act as a provider for subscribers'
                    )}. ${t(
                      'This gives them access rights to view and manage PHI'
                    )}.`}</p>
                  }
                >
                  <InfoIcon type="info" />
                </Tooltip>
              </PartnerSwitch>
            ) : null}
          </PartnerInfo>
        </InfoCard>
      </FadeInSlideDown>
      {selectedPartnerType?.isOrganization ? (
        <FadeInSlideDown delay={delay()}>
          <InfoCard>
            <OrganizationTitle>
              {t('Organization Information')}
            </OrganizationTitle>
            <Info>
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Business Name is required') }}
                name="organization.businessName"
                label={t('Business Name')}
                fullWidth
                error={errors.organization?.businessName?.message}
                required
              />

              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Display Name is required') }}
                name="organization.displayName"
                label={t('Display Name')}
                fullWidth
                error={errors.organization?.businessName?.message}
                required
              />
            </Info>
          </InfoCard>
        </FadeInSlideDown>
      ) : null}
      {!selectedPartnerType?.isOrganization ? (
        <FadeInSlideDown className={clsx()} delay={delay()}>
          <InfoCard>
            <InfoTitle>{t('Personal Information')}</InfoTitle>
            <Info>
              <Controller
                as={
                  <Select
                    label={t('Prefix')}
                    variant="outlined"
                    fullWidth={false}
                    selectClassName="w-24"
                  >
                    {salutationTypes.items.map((salutation, index) => (
                      <SelectItem value={salutation.id} key={index}>
                        {salutation.name}
                      </SelectItem>
                    ))}
                  </Select>
                }
                name="person.salutation"
                defaultValue={salutationTypes.items[0].id}
              />
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="person.firstName"
                label={t('First Name')}
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('First Name is required') }}
                error={errors.person?.firstName?.message}
                className="w-56 mt-4"
                required
              />
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="person.lastName"
                label={t('Last Name')}
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Last Name is required') }}
                error={errors.person?.lastName?.message}
                className="w-56 mt-4"
                required
              />
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="person.suffix"
                label={t('Suffix')}
                error={errors.person?.suffix?.message}
                className="w-20 mt-4"
              />
            </Info>
          </InfoCard>
        </FadeInSlideDown>
      ) : null}
      <FadeInSlideDown delay={delay()}>
        <InfoCard>
          <InfoTitle>{t('Contact Information')}</InfoTitle>
          <Info>
            {!selectedPartnerType?.isOrganization ? (
              <Controller
                render={({ onChange, value, name, ref }) => (
                  <TextField
                    onChange={(e) => {
                      // If contact email field hasn't been modified mimic this value
                      if (!accountEmailTouched)
                        setValue('account.email', e.target.value)
                      return onChange(e)
                    }}
                    value={value}
                    name={name}
                    ref={ref}
                    className="w-72 mt-4"
                    error={errors.person?.emailAddress?.message}
                    label={t('Contact Email Address')}
                    onFocus={() => setContactEmailTouched(true)}
                    required
                  />
                )}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="person.emailAddress"
                rules={{
                  //@ts-expect-error it's fine if the required message is undefined
                  required: t('Contact Email is required'),
                  pattern: {
                    value: emailValidation,
                    message: 'Invalid email format',
                  },
                }}
              />
            ) : null}
            <TextField
              error={errors.phoneNumbers?.[0]?.number?.message}
              name={`phoneNumbers[0].phoneNumber`}
              className={clsx(
                'w-56 mt-4',
                !selectedPartnerType?.isOrganization && 'ml-4'
              )}
              inputRef={register({
                pattern: {
                  value: phoneValidation,
                  message: t('Phone Number is invalid'),
                },
              })}
              label={t('Business Phone')}
            />
            <TextField
              name={`phoneNumbers[0].extension`}
              className="w-20 mt-4"
              type="number"
              inputRef={register()}
              label={t('Ext')}
            />
          </Info>
          <SubInfoTitle>{t('Billing Address')}</SubInfoTitle>
          <TextFieldContainer>
            <TextField
              error={errors.addresses?.[0]?.addressLine1?.message}
              name={`addresses[0].addressLine1`}
              className="w-72 mt-4 mr-4"
              // @ts-expect-error t('') will return a string
              inputRef={register({
                validate: (value) =>
                  // error if empty and any other address field is filled in
                  !!value ||
                  Object.values(
                    watch<'addresses', CreateAddressForm[]>('addresses')?.[0] ||
                      {}
                  ).every((val) => !val) ||
                  t('Address Line 1 is required'),
              })}
              label={t('Address Line 1')}
            />
            <TextField
              error={errors.addresses?.[0]?.city?.message}
              name={`addresses[0].city`}
              className="w-56 mt-4 mr-4"
              // @ts-expect-error t('') will return a string
              inputRef={register({
                validate: (value) =>
                  // error if empty and any other address field is filled in
                  !!value ||
                  Object.values(
                    watch<'addresses', CreateAddressForm[]>('addresses')?.[0] ||
                      {}
                  ).every((val) => !val) ||
                  t('City is required'),
              })}
              label={t('City')}
            />
            <TextField
              error={errors.addresses?.[0]?.stateProvinceCode?.message}
              name={`addresses[0].stateProvinceCode`}
              className="w-36 mt-4 mr-4"
              // @ts-expect-error t('') will return a string
              inputRef={register({
                validate: (value) =>
                  // error if empty and any other address field is filled in
                  !!value ||
                  Object.values(
                    watch<'addresses', CreateAddressForm[]>('addresses')?.[0] ||
                      {}
                  ).every((val) => !val) ||
                  t('State Code is required'),
              })}
              label={t('State Code')}
            />
            <TextField
              error={errors.addresses?.[0]?.postalCode?.message}
              name={`addresses[0].postalCode`}
              type="number"
              className="w-36 mt-4"
              // @ts-expect-error t('') will return a string
              inputRef={register({
                validate: (value) =>
                  // error if empty and any other address field is filled in
                  !!value ||
                  Object.values(
                    watch<'addresses', CreateAddressForm[]>('addresses')?.[0] ||
                      {}
                  ).every((val) => !val) ||
                  t('Postal Code is required'),
              })}
              label={t('Postal Code')}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <TextField
              name={`addresses[0].addressLine2`}
              className="w-72 mr-4"
              inputRef={register()}
              label={t('Address Line 2')}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <SubInfoTitle>{t('Shipping Address')}</SubInfoTitle>
            <Checkbox
              onChange={(e) => setIsShippingAddressSame(e.target.checked)}
              checked={isShippingAddressSame}
              label={<p className="-ml-1">{t('Same as Billing Address')} </p>}
              className="text-gray-900 pl-8"
            />
          </TextFieldContainer>
          <ShippingAddressContainer hidden={isShippingAddressSame}>
            <TextFieldContainer>
              <TextField
                error={errors.addresses?.[1]?.addressLine1?.message}
                name={`addresses[1].addressLine1`}
                className="w-72 mt-4 mr-4"
                // @ts-expect-error t('') will return a string
                inputRef={register({
                  validate: (value) =>
                    // error if empty and any other address field is filled in
                    !!value ||
                    Object.values(
                      watch<'addresses', CreateAddressForm[]>(
                        'addresses'
                      )?.[1] || {}
                    ).every((val) => !val) ||
                    t('Address Line 1 is required'),
                })}
                label={t('Address Line 1')}
                disabled={isShippingAddressSame}
              />
              <TextField
                error={errors.addresses?.[1]?.city?.message}
                name={`addresses[1].city`}
                className="w-56 mt-4 mr-4"
                // @ts-expect-error t('') will return a string
                inputRef={register({
                  validate: (value) =>
                    // error if empty and any other address field is filled in
                    !!value ||
                    Object.values(
                      watch<'addresses', CreateAddressForm[]>(
                        'addresses'
                      )?.[1] || {}
                    ).every((val) => !val) ||
                    t('City is required'),
                })}
                label={t('City')}
                disabled={isShippingAddressSame}
              />
              <TextField
                error={errors.addresses?.[1]?.stateProvinceCode?.message}
                name={`addresses[1].stateProvinceCode`}
                className="w-36 mt-4 mr-4"
                // @ts-expect-error t('') will return a string
                inputRef={register({
                  validate: (value) =>
                    // error if empty and any other address field is filled in
                    !!value ||
                    Object.values(
                      watch<'addresses', CreateAddressForm[]>(
                        'addresses'
                      )?.[1] || {}
                    ).every((val) => !val) ||
                    t('State Code is required'),
                })}
                label={t('State Code')}
                disabled={isShippingAddressSame}
              />
              <TextField
                error={errors.addresses?.[1]?.postalCode?.message}
                name={`addresses[1].postalCode`}
                type="number"
                className="w-36 mt-4"
                // @ts-expect-error t('') will return a string
                inputRef={register({
                  validate: (value) =>
                    // error if empty and any other address field is filled in
                    !!value ||
                    Object.values(
                      watch<'addresses', CreateAddressForm[]>(
                        'addresses'
                      )?.[1] || {}
                    ).every((val) => !val) ||
                    t('Postal Code is required'),
                })}
                label={t('Postal Code')}
                disabled={isShippingAddressSame}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                name={`addresses[1].addressLine2`}
                className="w-72 mr-4"
                inputRef={register()}
                label={t('Address Line 2')}
                disabled={isShippingAddressSame}
              />
            </TextFieldContainer>
          </ShippingAddressContainer>
        </InfoCard>
      </FadeInSlideDown>
      {!selectedPartnerType?.isOrganization ? (
        <FadeInSlideDown delay={delay()}>
          <InfoCard>
            <AccountInfoTitle>{t('Account Information')}</AccountInfoTitle>
            <AccountDisclaimer>
              {`${t(
                'This information will be emailed to the user once their account has been created'
              )}. ${t(
                'They will then be able to login to ADDI with these credentials'
              )}.`}
            </AccountDisclaimer>
            <Controller
              render={({ onChange, value, name, ref }) => (
                <TextField
                  onChange={(e) => {
                    // If contact email field hasn't been modified mimic this value
                    if (!contactEmailTouched)
                      setValue('person.emailAddress', e.target.value)
                    return onChange(e)
                  }}
                  value={value}
                  name={name}
                  ref={ref}
                  className="w-72 mt-4"
                  error={errors.account?.email?.message}
                  label={t('Account Email Address')}
                  onFocus={() => setAccountEmailTouched(true)}
                  required
                />
              )}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="account.email"
              rules={{
                //@ts-expect-error it's fine if the required message is undefined
                required: t('Account Email is required'),
                pattern: {
                  value: emailValidation,
                  message: 'Invalid email format',
                },
              }}
            />
            <Info>
              <PasswordOptionsContainer>
                <PasswordTitle>{`${t('Password Options')}: `}</PasswordTitle>
                <RadioGroup
                  onChange={(value) => setPasswordType(value)}
                  options={[
                    { value: 'Auto-Generated', label: t('Auto-Generated') },
                    { value: 'Manual', label: t('Manual') },
                  ]}
                  checked={passwordType}
                />
              </PasswordOptionsContainer>
              <PasswordTextfield>
                <Controller
                  render={({ onBlur, onChange, value, name, ref }) => (
                    <TextField
                      onFocus={() => setIsPasswordFocused(true)}
                      onBlur={() => {
                        setIsPasswordFocused(false)
                        onBlur()
                      }}
                      onChange={onChange}
                      value={value}
                      name={name}
                      ref={ref}
                      type="password"
                      label={t('Password')}
                      error={errors.account?.password?.message}
                      className={clsx(
                        'w-56',
                        passwordType === 'Auto-Generated' && 'hidden'
                      )}
                      defaultValue=""
                      required
                    />
                  )}
                  // this is purely here to prevent console.warns
                  name="account.password"
                  rules={{
                    //@ts-expect-error it's fine if the required message is undefined
                    required: t('Password is required'),
                    validate: {
                      isLongEnough: (value) =>
                        isPasswordLongEnough(value) ||
                        'Password requires at least 8 characters',
                      hasSpecialChar: (value) =>
                        isPasswordSpecialChar(value) ||
                        'Password requires a special character',
                      hasNumber: (value) =>
                        isPasswordNumber(value) || 'Password requires a number',
                      hasCapitalLetter: (value) =>
                        isPasswordCapitalLetter(value) ||
                        'Password requires a capital letter',
                      hasLowercaseLetter: (value) =>
                        isPasswordLowercaseLetter(value) ||
                        'Password requires a lowercase letter',
                    },
                  }}
                />

                <PasswordContainer isPasswordFocused={!isPasswordFocused}>
                  <PasswordErrorTitle>
                    {t('Password must include')}:
                  </PasswordErrorTitle>
                  <PasswordErrorContainer>
                    <Icon
                      type={
                        isPasswordLongEnough(watch('account.password'))
                          ? 'check'
                          : 'x'
                      }
                      className={clsx(
                        'mt-1 mr-1 min-w-max',
                        isPasswordLongEnough(watch('account.password'))
                          ? 'text-green-600'
                          : 'text-red-600'
                      )}
                    />
                    <p>{t('minimum of eight characters')}</p>
                  </PasswordErrorContainer>
                  <PasswordErrorContainer>
                    <Icon
                      type={
                        isPasswordSpecialChar(watch('account.password'))
                          ? 'check'
                          : 'x'
                      }
                      className={clsx(
                        'mt-1 mr-1 min-w-max',
                        isPasswordSpecialChar(watch('account.password'))
                          ? 'text-green-600'
                          : 'text-red-600'
                      )}
                    />
                    <p>{t('special character')}</p>
                  </PasswordErrorContainer>

                  <PasswordErrorContainer>
                    <Icon
                      type={
                        isPasswordNumber(watch('account.password'))
                          ? 'check'
                          : 'x'
                      }
                      className={clsx(
                        'mt-1 mr-1 min-w-max',
                        isPasswordNumber(watch('account.password'))
                          ? 'text-green-600'
                          : 'text-red-600'
                      )}
                    />
                    <p>{t('number')}</p>
                  </PasswordErrorContainer>

                  <PasswordErrorContainer>
                    <Icon
                      type={
                        isPasswordCapitalLetter(watch('account.password'))
                          ? 'check'
                          : 'x'
                      }
                      className={clsx(
                        'mt-1 mr-1 min-w-max',
                        isPasswordCapitalLetter(watch('account.password'))
                          ? 'text-green-600'
                          : 'text-red-600'
                      )}
                    />
                    <p>{t('capital letter')}</p>
                  </PasswordErrorContainer>

                  <PasswordErrorContainer>
                    <Icon
                      type={
                        isPasswordLowercaseLetter(watch('account.password'))
                          ? 'check'
                          : 'x'
                      }
                      className={clsx(
                        'mt-1 mr-1 min-w-max',
                        isPasswordLowercaseLetter(watch('account.password'))
                          ? 'text-green-600'
                          : 'text-red-600'
                      )}
                    />
                    <p>{t('lowercase letter')}</p>
                  </PasswordErrorContainer>
                </PasswordContainer>
              </PasswordTextfield>
            </Info>
          </InfoCard>
        </FadeInSlideDown>
      ) : null}
      <FadeInSlideDown delay={delay()}>
        <Button
          onClick={handleSubmit(() => {
            const formData = watch()
            const billingAddressType = addressTypes.items?.find((addressType) =>
              addressType.name.toLowerCase().includes('billing')
            )
            const shippingAddressType = addressTypes.items?.find(
              (addressType) =>
                addressType.name.toLowerCase().includes('shipping')
            )

            // if any billing address or shipping address fields are truthy add them to the API call
            const isBillingAddress =
              formData.addresses?.[0]?.addressLine1 ||
              formData.addresses?.[0]?.city ||
              formData.addresses?.[0]?.postalCode ||
              formData.addresses?.[0]?.stateProvinceCode
            const isShippingAddress =
              formData.addresses?.[1]?.addressLine1 ||
              formData.addresses?.[1]?.city ||
              formData.addresses?.[1]?.postalCode ||
              formData.addresses?.[1]?.stateProvinceCode

            createPartner(
              {
                partnerData: {
                  ...formData,
                  // THIS IS TEMPORARY as the API requires legacyId to be a valid UUID
                  legacyIdentifier: formData.partnerType,
                  ...(formData.organization
                    ? {
                        organization: {
                          ...formData.organization,
                          legacyIdentifier: formData.partnerType,
                          type: 'anything',
                        },
                      }
                    : {}),
                  addresses: [
                    ...(isBillingAddress && billingAddressType
                      ? [
                          {
                            ...formData.addresses?.[0],
                            addressType: Number(billingAddressType.id),
                            stateProvinceCode: formData.addresses?.[0].stateProvinceCode?.toUpperCase(),
                          },
                        ]
                      : []),
                    ...(isShippingAddress && shippingAddressType
                      ? [
                          {
                            // if isShippingAddressSame is true then spread the
                            // values of billing address here instead of shipping
                            ...formData.addresses?.[
                              isShippingAddressSame ? 0 : 1
                            ],
                            addressType: Number(shippingAddressType.id),
                            stateProvinceCode: formData.addresses?.[0].stateProvinceCode?.toUpperCase(),
                          },
                        ]
                      : []),
                  ],
                  phoneNumbers: [
                    ...(formData.phoneNumbers?.[0].number
                      ? // set the phone type to be the id for the business type
                        [{ ...formData.phoneNumbers[0], type: 'Main' as const }]
                      : []),
                  ],
                },
              },
              {
                onSuccess: () => navigate('/partners/list'),
              }
            )
          })}
          type="primary-filled"
        >
          {t('Create Partner')}
        </Button>
        <CancelButton type="primary-link" to="/partners/list">
          {t('Cancel')}
        </CancelButton>
      </FadeInSlideDown>
    </div>
  )
}

export default CreatePartnerFormBasicInformation

const isPasswordLongEnough = (password: string): boolean =>
  !!password && password.length >= 8

const isPasswordSpecialChar = (password: string): boolean =>
  /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/.test(password)

const isPasswordNumber = (password: string): boolean => /\d/.test(password)

const isPasswordCapitalLetter = (password: string): boolean =>
  /[A-Z]/.test(password)

const isPasswordLowercaseLetter = (password: string): boolean =>
  /[a-z]/.test(password)

const ShippingAddressContainer = styled.div<{ hidden: boolean }>`
  ${({ hidden }) => {
    if (hidden) return tw`hidden`
  }}
`

const CancelButton = tw(Button)`ml-5`

const InfoCard = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const InfoTitle = tw.h3`text-xl font-semibold mb-2`

const PartnerInfo = tw.div`flex gap-4 items-center`

const PartnerSwitch = tw.span`flex items-center`

const InfoIcon = tw(
  Icon
)`text-gray-600 hover:text-gray-900 mt-3 w-5 h-5 align-middle`

const OrganizationTitle = tw.h3`text-xl font-semibold mb-6`

const Info = tw.div`flex gap-4`

const SubInfoTitle = tw.h4`text-lg font-semibold`

const TextFieldContainer = tw.div`flex`

const AccountInfoTitle = tw.h3`text-xl font-semibold`

const AccountDisclaimer = tw.p`text-gray-500`

const PasswordOptionsContainer = tw.div`flex mt-1`

const PasswordTitle = tw.p`text-gray-600 mr-4 mt-3`

const PasswordTextfield = tw.div`relative`

const PasswordContainer = styled.div<{ isPasswordFocused: boolean }>(
  ({ isPasswordFocused }) => [
    tw`bg-white border shadow-lg transition-all p-2 absolute bottom-24`,
    isPasswordFocused && tw`invisible`,
  ]
)

const PasswordErrorTitle = tw.p`font-semibold`

const PasswordErrorContainer = tw.div`flex`
