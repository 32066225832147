import ky from 'ky'
import _ from 'lodash'
import { isPresent } from 'utils'
import getAccessToken from './getAccessToken'

/**
 * gets auth token and applies it to the request headers
 * @param request
 */
const getAuthToken = async (request: Request) => {
  // don't do any of this if we're in Cypress
  if (!window.Cypress) {
    const session = getAccessToken()
    request.headers.set('Authorization', session)
  }
}

/**
 * set the request's header language
 * @param request
 */
const getCurrentLanguage = async (request: Request) => {
  // set default value to value in localStorage
  const language =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (localStorage.getItem('user-profile__language') as Language) || 'en-US'

  request.headers.set('Accept-Language', language)
}

/**
 * returns a dynamic formatted url depending if the application is running on cypress
 * @param prefixUrl
 * @returns a url path
 */
const getPrefixUrl = (prefixUrl: string | undefined) =>
  window.Cypress ? '/' : prefixUrl

/* ------------- Ky Objects ---------------- */
const vitalsApi = ky.create({
  // base url
  prefixUrl: getPrefixUrl(process.env.REACT_APP_VITALS_MODULE_BASE_URL),
  hooks: {
    beforeRequest: [
      // send the access token in the header
      getAuthToken,
      getCurrentLanguage,
    ],
  },
})

const orgsApi = ky.create({
  // base url
  prefixUrl: getPrefixUrl(process.env.REACT_APP_ORGANIZATIONS_MODULE_BASE_URL),
  hooks: {
    beforeRequest: [
      // send the access token in the header
      getAuthToken,
      getCurrentLanguage,
    ],
  },
})

const webhooksApi = ky.create({
  // base url
  prefixUrl: getPrefixUrl(process.env.REACT_APP_WEBHOOKS_MODULE_BASE_URL),
  hooks: {
    beforeRequest: [
      // send the access token in the header
      getAuthToken,
      getCurrentLanguage,
    ],
  },
})

const mapboxApi = ky.create({
  // base url
  prefixUrl: getPrefixUrl(process.env.REACT_APP_MAP_BOX_BASE_URL),
})

/**
 * constructor for typed http client functions
 * @param kyObj
 * @param fnType
 * @returns
 */
const createHTTPFn = <APIReqT, APIResT, HTTPMethod extends HTTPMethods>(
  kyObj: ReturnType<typeof ky['create']>,
  fnType: Lowercase<HTTPMethod>
): HttpFn<APIReqT, APIResT, HTTPMethod> =>
  ((url, params) => {
    // parse url if query params are included
    const parsedUrl = hasPathParams<typeof params, typeof url>(params)
      ? urlWithQueryParams(url, params.pathParams)
      : (url as string).slice(1)

    const searchParams = hasSearchParams(params)
      ? params.searchParams
      : undefined

    const apiResponse = kyObj[fnType](parsedUrl, {
      // send search params if provided
      searchParams: searchParams
        ? new URLSearchParams(
            _.flatten(
              // if search params were sent as an object convert them to an array
              (Array.isArray(searchParams)
                ? searchParams
                : Object.entries(searchParams)
              )
                // remove undefined or null value elements
                .filter(([, value]) => isPresent(value))
                .map(([key, value]) => {
                  // if there are more than 1 value for the key rename key to include the indexes
                  return Array.isArray(value)
                    ? value.map((val, index) => [`${key}[${index}]`, '' + val])
                    : [[key, '' + value]]
                })
            )
          ).toString()
        : undefined,

      // send json body if provided
      json: hasReqBody<typeof params, typeof url, APIReqT, HTTPMethod>(params)
        ? params.body
        : undefined,
    })
      // correctly type the response
      .json<
        HTTPMethod extends keyof APIResT
          ? typeof url extends keyof APIResT[HTTPMethod]
            ? APIResT[HTTPMethod][typeof url]
            : unknown
          : unknown
      >()

    return apiResponse
  }) as HttpFn<APIReqT, APIResT, HTTPMethod>

type createApiObject = <APIReqT, APIResT extends Record<string, unknown>>(
  baseUrl: string
) => {
  get: HttpFn<APIReqT, APIResT, 'GET'>
  post: HttpFn<APIReqT, APIResT, 'POST'>
  patch: HttpFn<APIReqT, APIResT, 'PATCH'>
  delete: HttpFn<APIReqT, APIResT, 'DELETE'>
  put: HttpFn<APIReqT, APIResT, 'PUT'>
}

/**
 * constructor for a typed Http client wrapped around Ky
 * @param baseUrl
 * @returns
 */
const createApiObject: createApiObject = <APIReqT, APIResT>(
  baseUrl: string
) => {
  // create ky obj to use for calling HTTP methods
  const kyObj = ky.create({
    // base url
    prefixUrl: window.Cypress ? '/' : baseUrl,
    hooks: {
      beforeRequest: [
        // send the access token in the header
        getAuthToken,
        getCurrentLanguage,
      ],
    },
  })

  return Object.fromEntries([
    ['get', createHTTPFn<APIReqT, APIResT, 'GET'>(kyObj, 'get')],
    ['post', createHTTPFn<APIReqT, APIResT, 'POST'>(kyObj, 'post')],
    ['patch', createHTTPFn<APIReqT, APIResT, 'PATCH'>(kyObj, 'patch')],
    ['delete', createHTTPFn<APIReqT, APIResT, 'DELETE'>(kyObj, 'delete')],
    ['put', createHTTPFn<APIReqT, APIResT, 'PUT'>(kyObj, 'put')],
  ])
}

const typedOrgsApi = createApiObject<OrgsAPIRequest, OrgsAPIResponse>(
  getPrefixUrl(process.env.REACT_APP_ORGANIZATIONS_MODULE_BASE_URL) || '/'
)

const typedVitalsApi = createApiObject<VitalsAPIRequest, VitalsAPIResponse>(
  getPrefixUrl(process.env.REACT_APP_VITALS_MODULE_BASE_URL) || '/'
)

const ordersApi = createApiObject<OrdersAPIRequest, OrdersAPIResponse>(
  getPrefixUrl(process.env.REACT_APP_ORDERS_MODULE_BASE_URL) || '/'
)

const typedWebhooksApi = createApiObject<
  WebhooksAPIRequest,
  WebhooksAPIResponse
>(getPrefixUrl(process.env.REACT_APP_WEBHOOKS_MODULE_BASE_URL) || '/')

export {
  vitalsApi,
  orgsApi,
  webhooksApi,
  mapboxApi,
  typedOrgsApi,
  typedVitalsApi,
  ordersApi,
  typedWebhooksApi,
}

/* ------------- UTIL FNs ---------------- */

// check if key is in obj and return value of key
const getObjProperty = <Q extends Record<string, unknown> | undefined>(
  obj: Q,
  key: string
) => !!obj && key in obj && obj[key]

// return a url string with the queryParam values inserted
const urlWithQueryParams = <URL extends string>(
  url: URL,
  pathParams: PathParams<URL>
) => {
  // if no curly braces are in the url then there is no need to parse out the query params
  if (!url.includes('{')) return url

  // convert url to array of strings split by curly braces
  return (
    _.flatten(
      url
        // remove starting '/'
        .slice(1)
        .split('{')
        .map((urlPiece) => urlPiece.split('}'))
    )
      // if a urlPiece matches a key in pathParams replace urlPiece with the queryParam value
      .map((urlPiece) => getObjProperty(pathParams, urlPiece) || urlPiece)
      .join('')
  )
}

/* ------------- TYPE GUARDS ---------------- */

// type guard to check if api params includes pathParams
export const hasPathParams = <
  T extends Record<string, unknown> | undefined,
  URL extends string
>(
  obj: T
): obj is T & { pathParams: PathParams<URL> } =>
  !!obj &&
  !!Object.prototype.hasOwnProperty.call(obj, 'pathParams') &&
  !!obj.pathParams

// type guard to check if api params includes pathParams
export const hasSearchParams = <T extends Record<string, unknown> | undefined>(
  obj: T
): obj is NonNullable<T> & {
  searchParams: Record<string, unknown> | Array<[string, unknown]>
} =>
  !!obj &&
  !!Object.prototype.hasOwnProperty.call(obj, 'searchParams') &&
  !!obj.searchParams

// type guard to check if api params includes a request body
export const hasReqBody = <
  T extends Record<string, unknown> | undefined,
  URL,
  APIReqT,
  HTTPMethod
>(
  obj: T
): obj is T & {
  body: HTTPMethod extends keyof APIReqT
    ? URL extends keyof APIReqT[HTTPMethod]
      ? unknown extends APIReqT[HTTPMethod][URL]
        ? APIReqT[HTTPMethod][URL]
        : never
      : never
    : never
} => !!obj && !!Object.prototype.hasOwnProperty.call(obj, 'body') && !!obj.body

/* ------------- TYPES ---------------- */

export type HttpFn<APIReqT, APIResT, HTTPMethod extends HTTPMethods> = <
  // extract only string keys so typeof url is 'string' to allow template literal manipulation
  URL extends Extract<URLType<HTTPMethod, APIResT>, string>
>(
  url: URL,
  ...params: HTTPReqParams<HTTPMethod, URL, APIReqT>
) => Promise<
  HTTPMethod extends keyof APIResT
    ? typeof url extends keyof APIResT[HTTPMethod]
      ? APIResT[HTTPMethod][typeof url]
      : unknown
    : unknown
>

/**
 * given a URL string type return an object literal type with
 * Return a string literal union of possible urls based on the HTTPMethod and the API's response type
 */
export type URLType<HTTPMethod extends HTTPMethods, APIResT> = keyof {
  /* K extends K trick is from https://github.com/microsoft/TypeScript/issues/40833 to bypass this problem:
  https://github.com/microsoft/TypeScript/issues/38646 */
  [K in HTTPMethod extends keyof APIResT
    ? keyof APIResT[HTTPMethod]
    : never as K extends K
    ? // filter the APIResT type to only have keys (url paths) that contain the provided HTTPMethod
      HTTPMethod extends keyof APIResT
      ? K extends keyof APIResT[HTTPMethod]
        ? K
        : never
      : never
    : never]: HTTPMethod extends keyof APIResT ? APIResT[HTTPMethod][K] : never
}

/**
 * given a URL string type return an object literal type with
 * Return a string literal union of possible urls based on the HTTPMethod and the API's response type
 */
export type HTTPReqParams<
  HTTPMethod extends HTTPMethods,
  URL extends string,
  APIReqT,
  AdditionalConfig extends Record<string, unknown> = Record<string, unknown>,
  SearchParamT extends Record<string, unknown> | Array<unknown> = SearchParams<
    HTTPMethod,
    URL,
    APIReqT
  >
> =
  // Only accept a second parameter if any potential params exist for this URL
  Exclude<
    // path params
    | PathParams<URL>
    // request body
    | ReqBody<HTTPMethod, URL, APIReqT>,
    undefined | never
  > extends never
    ? // if searchParams is defined optionally allow for a second parameter that contains searchParams
      SearchParamT extends never
      ? [AdditionalConfig] | []
      :
          | [
              {
                searchParams?: SearchParamT
              } & AdditionalConfig
            ]
          | []
    : [
        Pick<
          {
            pathParams: PathParams<URL>
            body: ReqBody<HTTPMethod, URL, APIReqT>
            searchParams?: SearchParamT
          },
          // remove keys that will have a never type
          | (PathParams<URL> extends undefined ? never : 'pathParams')
          | (ReqBody<HTTPMethod, URL, APIReqT> extends never ? never : 'body')
          | (SearchParamT extends never ? never : 'searchParams')
        > &
          AdditionalConfig
      ]

/**
 * Create Record type where keys are extracted from URL where text is wrapped in {}
 */
type PathParams<URL extends string> = string extends URL
  ? Record<string, string>
  : URL extends `${string}{${infer QueryParam}}${infer Rest}`
  ? { [k in QueryParam | keyof PathParams<Rest>]: string }
  : URL extends `${string}{${infer QueryParam}}`
  ? { [k in QueryParam]: string }
  : undefined

type ReqBody<
  HTTPMethod extends HTTPMethods,
  URL extends string,
  APIReqT
> = HTTPMethod extends keyof APIReqT
  ? URL extends keyof APIReqT[HTTPMethod]
    ? 'body' extends keyof APIReqT[HTTPMethod][URL]
      ? APIReqT[HTTPMethod][URL]['body'] extends
          | boolean
          | string
          | number
          | Record<string, unknown>
          | Array<unknown>
        ? APIReqT[HTTPMethod][URL]['body']
        : never
      : never
    : never
  : never

export type SearchParams<
  HTTPMethod extends HTTPMethods,
  URL extends string,
  APIReqT
> = HTTPMethod extends keyof APIReqT
  ? URL extends keyof APIReqT[HTTPMethod]
    ? 'searchParams' extends keyof APIReqT[HTTPMethod][URL]
      ? APIReqT[HTTPMethod][URL]['searchParams'] extends
          | Record<string, unknown>
          | Array<unknown>
        ? APIReqT[HTTPMethod][URL]['searchParams']
        : never
      : never
    : never
  : never
