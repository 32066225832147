/**
 * Tries to extract optional search parameters to build an OrganizationFilters object
 * @param urlSearchParams - URLSearchParams object
 * @returns OrganizationFilters object
 */
const urlParamsToOrganizationFilters = (
  urlSearchParams: URLSearchParams
): OrganizationFilters => {
  const urlSearchObj = Object.fromEntries(urlSearchParams.entries())

  return {
    ...(urlSearchObj.searchTerm ? { searchTerm: urlSearchObj.searchTerm } : {}),
    skip: +urlSearchObj.skip || 0,
    take: +urlSearchObj.take || 0,
  }
}

export default urlParamsToOrganizationFilters
