import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useArchiveModuleMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/archive'],
    archiveModuleArgs
  >({
    mutationFn: archiveModule,
    successMsg: t('Module archived'),
    additionalCachesToInvalidate: ['getPermissions'],
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          archiveModuleArgs
        >((oldCache, { moduleId }) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items?.map((module) => {
                  if (module.id === moduleId)
                    return {
                      ...module,
                      archiveInfo: {
                        ...module.archiveInfo,
                        archived: true,
                      },
                    }

                  return module
                }),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useArchiveModuleMutation

type archiveModuleArgs = {
  moduleId: string
}

const archiveModule = ({ moduleId }: archiveModuleArgs) =>
  typedOrgsApi.post('/api/modules/{moduleId}/archive', {
    pathParams: { moduleId },
  })
