import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, typedVitalsApi, handleQueryError } from 'utils'

// used to create Reminder preset
const useCreateReminderScheduleMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['POST']['/subscribers/{subscriberId}/vital-reminders'],
    KyError,
    createReminderScheduleArgs,
    | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
    | undefined
  >(createReminderSchedule, {
    onMutate: (data) => {
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
      >(['getReminderSchedules', data.subscriberId])
      // if presetId is given, find schedule from given presetId
      if (data.reminderSchedule.presetId) {
        const presets = queryCache.getQueryData<
          VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
        >(['getReminderPresets', data.reminderSchedule.organizationId])
        // find preset that matches newly created schedule presetId
        const selectedPresetSchedule = presets?.items?.find(
          (preset) => preset.id === data.reminderSchedule.presetId
        )?.schedule

        queryCache.setQueryData<
          | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
          | undefined
          // @ts-expect-error bad API type
        >(['getReminderSchedules', data.subscriberId], (oldData) => {
          if (!oldData?.items) return undefined

          return {
            ...oldData,
            items: [
              ...oldData.items,
              { ...data, schedule: selectedPresetSchedule },
            ],
          }
        })
      } else
        queryCache.setQueryData<
          | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
          | undefined
          // @ts-expect-error bad API type
        >(['getReminderSchedules', data.subscriberId], (oldData) => {
          if (!oldData?.items) return undefined
          return { items: [...oldData.items, { ...data }] }
        })

      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Reminder schedule created') })
    },
    onError: (error, { subscriberId }, snapshot) => {
      //revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
        | undefined
      >(['getReminderSchedules', subscriberId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      // if preset is saved, invalidate reminder preset
      if (args.reminderSchedule.savePreset)
        queryCache.invalidateQueries([
          'getReminderPresets',
          args.reminderSchedule.organizationId,
        ])
      queryCache.invalidateQueries(['getReminderSchedules', args.subscriberId])
    },
  })

  return mutation
}

export default useCreateReminderScheduleMutation

// type cre

type createReminderScheduleArgs = {
  reminderSchedule: ReminderScheduleForm
  subscriberId: string
}

const createReminderSchedule = async (data: createReminderScheduleArgs) => {
  return typedVitalsApi.post(`/subscribers/{subscriberId}/vital-reminders`, {
    body: data.reminderSchedule,
    pathParams: { subscriberId: data.subscriberId },
  })
}
