import React, { useState, useRef, useEffect } from 'react'
import { FadeInSlideDown } from 'animations'
import { Dropdown, DropdownItem, Icon, EntityLabel } from 'elements'
import { useEventListener } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw, { styled } from 'twin.macro'

type RoleInformationProps = {
  isCollapsedSidebar: boolean
  isSidebarHovered: boolean
}

const RoleInformation = ({
  isCollapsedSidebar,
  isSidebarHovered,
}: RoleInformationProps) => {
  const { t } = useTranslation()

  const {
    assumedOrganizationRole,
    discardAssumedOrganizationRole,
  } = useAssumedOrganizationRole()

  const [isVisible, setIsVisible] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  // if the staff clicks outside of the dropdown, set the visibility to false
  useEventListener({
    eventName: 'mousedown',
    handler: (event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsVisible(false)
      }
    },
  })

  /**
   * Hide discard button when sidebar is collapsed
   */
  useEffect(() => {
    if (!isSidebarHovered && isCollapsedSidebar) {
      setIsVisible(false)
    }
  }, [isSidebarHovered, isCollapsedSidebar])

  if (!assumedOrganizationRole) return null

  return (
    <FadeInSlideDown>
      <RoleInformationContainer
        isVisible={isVisible}
        isCollapsedSidebar={isCollapsedSidebar}
        isSidebarHovered={isSidebarHovered}
        onClick={() => setIsVisible(!isVisible)}
        ref={ref}
      >
        <EntityLabelRoleInfoContainer>
          <EntityLabelContainer>
            <EntityLabel
              name={assumedOrganizationRole.orgDisplayName || ''}
              id={assumedOrganizationRole.orgID || ''}
              size="lg"
              description="Assumed Role"
            />
          </EntityLabelContainer>

          <RoleInfoIconContainer>
            <RoleInfoIcon type="chevron-up" />
          </RoleInfoIconContainer>
        </EntityLabelRoleInfoContainer>

        <Dropdown
          parentRef={ref}
          visible={isVisible}
          setVisible={setIsVisible}
          isParentWidth={true}
          verticalOffset={-10}
          parentAnchor={{ vertical: 'top', horizontal: 'left' }}
          contentAnchor={{ vertical: 'bottom', horizontal: 'left' }}
          animationType="reverse"
          disableBorder
        >
          <DropdownItem
            key="discard-assumed-role"
            onClick={() => {
              // discard the role
              discardAssumedOrganizationRole()

              // close the dropdown
              setIsVisible(false)
            }}
            hoverBackgroundColor="hover:bg-red-100"
          >
            <RoleDiscardButton data-testid="discardRole">
              <RoleDiscardIcon type="x" />
              &nbsp;
              <RoleDiscardContent>
                {t('Discard assumed role')}
              </RoleDiscardContent>
            </RoleDiscardButton>
          </DropdownItem>
        </Dropdown>
      </RoleInformationContainer>
    </FadeInSlideDown>
  )
}

const RoleInformationContainer = styled.div<{
  isVisible: boolean
  isCollapsedSidebar: boolean
  isSidebarHovered: boolean
}>(({ isVisible, isCollapsedSidebar, isSidebarHovered }) => [
  tw`mb-4 relative flex select-none border rounded py-4 cursor-pointer items-center justify-between hover:bg-gray-100 hover:border-gray-200 `,
  isVisible ? tw`bg-gray-100 border-gray-200` : tw`bg-white border-gray-100`,
  isCollapsedSidebar && !isSidebarHovered
    ? tw`shadow-none border-opacity-0`
    : tw`shadow-lg`,
])

const EntityLabelRoleInfoContainer = tw.div`overflow-hidden flex relative px-2.5`

const EntityLabelContainer = tw.div`flex-none w-44`

const RoleInfoIconContainer = tw.div`pl-2 flex w-full items-center`

const RoleInfoIcon = tw(Icon)`w-4 h-4`

const RoleDiscardButton = tw.p`text-red-500`

const RoleDiscardIcon = tw(Icon)`align-middle`

const RoleDiscardContent = tw.span`align-middle`

export default RoleInformation
