import React from 'react'
import { FadeIn } from 'animations'
import Icon from './Icon'
import { useTranslation } from 'react-i18next'

type UnauthorizedProps = {
  className?: string
  delay?: number
  title?: string
  description?: string
}

const Unauthorized = ({
  className,
  delay,
  title = 'Unauthorized',
  description = "You don't have permission to view this content",
}: UnauthorizedProps) => {
  const { t } = useTranslation()

  return (
    <FadeIn
      delay={delay}
      className={`w-full h-full flex-grow flex justify-center items-center text-center flex-col ${
        className || ''
      }`}
    >
      <div className="bg-red-100 p-2 rounded-full mb-2">
        <Icon type="unauthorized" className="h-7 w-7 text-red-800" />
      </div>
      <h5 className="text-lg">{t(title)}</h5>
      <p className="text-m text-gray-600">{description}</p>
    </FadeIn>
  )
}

export default Unauthorized
