import { formatDate } from 'utils'
import { ordersQueryConstructor } from 'utils/api/queryConstructors'

export default {
  orderActivity: ordersQueryConstructor(
    'orderActivity',
    '/orders/{orderId}/activity'
  ),

  orderAttributes: ordersQueryConstructor('orderAttributes', '/attributes'),

  order: ordersQueryConstructor('order', '/orders/{orderId}', {
    mappers: { searchParams: () => ({ include: 'order-line-items' }) },
  }),

  aneltoSubscriber: ordersQueryConstructor(
    'aneltoSubscriber',
    '/organizations/{organizationId}/orders/{orderId}/check-anelto-subscriber'
  ),

  orders: ordersQueryConstructor<'/orders', OrderFilters>('orders', '/orders', {
    mappers: {
      searchParams: ({
        organizationIds,
        skip,
        orderBy,
        searchTerm,
        orderStatus,
        dateRangeState: {
          value: [fromDate, toDate],
        },
      }) => [
        ['organizationIds', organizationIds],
        ['skip', skip],
        ['take', 30],
        ['fromDate', formatDate(fromDate, 'yyyy-MM-dd')],
        ['toDate', formatDate(toDate, 'yyyy-MM-dd')],
        ['orderBy', orderBy?.toLowerCase()],
        ['searchTerm', searchTerm],
        ['orderStatus', orderStatus],
      ],
    },
  }),

  orderStatuses: ordersQueryConstructor(
    'orderStatuses',
    '/orders/status-listing'
  ),

  orderLineItemStatuses: ordersQueryConstructor(
    'orderLineItemStatuses',
    '/line-items/status-listing'
  ),

  orderLineItems: ordersQueryConstructor(
    'orderLineItems',
    '/orders/{orderId}/line-items'
  ),

  products: ordersQueryConstructor('products', '/products', {
    mappers: {
      // if no params are sent default to take: 9999
      searchParams: (searchParams) =>
        (
          !Array.isArray(searchParams)
            ? Object.values(searchParams).length
            : searchParams.length
        )
          ? searchParams
          : { take: 9999 },
    },
  }),
}

export type OrdersQueryStr =
  | 'orderActivity'
  | 'orderAttributes'
  | 'order'
  | 'orders'
  | 'orderStatuses'
  | 'orderLineItemStatuses'
  | 'orderLineItems'
  | 'products'
  | 'aneltoSubscriber'
