import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useRestorePermissionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/permissions/{permissionId}/restore'],
    KyError,
    string
  >(restorePermission, {
    onSuccess: (_, permissionId) => {
      const permissions:
        | OrgsAPIResponse['GET']['/api/permissions']
        | undefined = queryCache.getQueryData(['getPermissions'])

      if (permissions) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/permissions'] = {
          ...permissions,
          items:
            permissions.items?.map((permission) => {
              if (permission.id === permissionId)
                return {
                  ...permission,
                  archiveInfo: {
                    ...permission.archiveInfo,
                    archived: false,
                  },
                }

              return permission
            }) || [],
        }
        // immediately update the permission list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/permissions']>(
          ['getPermissions'],
          updatedQueryCacheValue
        )
      }

      queryCache.invalidateQueries('getPermissions')
      queryCache.invalidateQueries('getModules')

      // notify the user
      success({ message: t('Permission restored') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useRestorePermissionMutation

const restorePermission = async (permissionId: string) => {
  const result = typedOrgsApi.post('/api/permissions/{permissionId}/restore', {
    pathParams: { permissionId },
  })

  return result
}
