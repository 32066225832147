import React from 'react'
import {
  LoadingIcon,
  RightPopup,
  TextField,
  Icon,
  Select,
  SelectItem,
} from 'elements'
import { Button } from 'atlas'
import _ from 'lodash'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  useAvailableTranslationsQuery,
  useCreateReminderMessageTranslationMutation,
  useReminderMessagesQuery,
  useReminderMessageTypesQuery,
} from 'hooks/reminders'
import { useTranslation } from 'react-i18next'
import { useLocalesQuery } from 'hooks/locales'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'

type OrganizationCreateTranslationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const OrganizationCreateTranslationForm = ({
  isFormOpen,
  setIsFormOpen,
}: OrganizationCreateTranslationFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const formMethods = useForm<EditTranslationForm>()

  const locales = useLocalesQuery()
  const reminderMessageTypes = useReminderMessageTypesQuery()
  const reminderMessages = useReminderMessagesQuery(
    assumedOrganizationRole?.orgID || ''
  )
  const availableTranslations = useAvailableTranslationsQuery(
    assumedOrganizationRole?.orgID || ''
  )

  const createReminderMessageTranslation = useCreateReminderMessageTranslationMutation()

  const { handleSubmit, errors, register } = formMethods

  return (
    <RightPopup
      title={t('Create Translation')}
      open={isFormOpen}
      setOpen={setIsFormOpen}
      width="800px"
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={handleSubmit(async (formData) => {
              const pickMessages = _.pickBy(formData, (value, key) => {
                return key !== 'localeKey'
              })

              createReminderMessageTranslation.mutate({
                orgId: assumedOrganizationRole?.orgID || '',
                localeKey: formData['localeKey'],
                messages: _.map(pickMessages, (value, key) => {
                  return {
                    messageTypeId: key,
                    message: value,
                  }
                }),
              })
              setIsFormOpen(false)
            })}
          >
            {t('Create')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {reminderMessages.isLoading ||
      reminderMessageTypes.isLoading ||
      availableTranslations.isLoading ||
      locales.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <LocaleContainer>
            <Controller
              as={
                <Select
                  className="col-span-9"
                  name="localeKey"
                  inputRef={register({ required: `${t('Locale Required')}` })}
                  label={t('Language Locale')}
                >
                  {locales.data?.items?.map((translations) => {
                    if (
                      !availableTranslations.data?.items?.some((selectable) => {
                        return selectable.locale.key === translations.name
                      })
                    )
                      return (
                        <SelectItem value={translations.name}>
                          {translations.displayName}
                        </SelectItem>
                      )
                  })}
                </Select>
              }
              name="localeKey"
              error={errors.localeKey?.message}
              defaultValue=""
              rules={{ required: 'Locale is required' }}
            />
          </LocaleContainer>
          <InputContainer>
            {_.orderBy(
              reminderMessages.data?.items,
              (reminderMessage) => reminderMessage.messageType.displayOrder
            ).map((item, index) => (
              <TextField
                key={index}
                disabled={true}
                label={item.messageType.name}
                defaultValue={`${item.message}`}
                fullWidth
                className={`col-span-4 row-span-1 self-start`}
                helperText={item.messageType.description}
              />
            ))}

            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />

            {_.orderBy(
              reminderMessageTypes.data?.items,
              (reminderMessage) => reminderMessage.displayOrder
            ).map((item, index) => (
              <TextField
                key={`${index}`}
                error={errors[item.name || index]?.message}
                name={item.id}
                inputRef={register({
                  required: `${item.name} ${t('message is required')}`,
                })}
                label={item.name}
                fullWidth
                className="col-span-4 row-span-1 self-start"
                required
              />
            ))}
          </InputContainer>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default OrganizationCreateTranslationForm

const LocaleContainer = tw.div`grid grid-cols-9 mb-6`

const InputContainer = tw.div`grid grid-cols-9 grid-flow-col grid-rows-5 justify-items-center items-center mb-4`

const RightIcon = tw(Icon)`w-6 h-6 transform -translate-y-8`
