import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const usePermissionQuery = () => {
  const query = useQuery<OrgsAPIResponse['GET']['/api/permissions'], KyError>(
    'getPermissions',
    getPermissions
  )

  return query
}

export default usePermissionQuery

const getPermissions = async () =>
  typedOrgsApi.get('/api/permissions', {
    searchParams: [
      ['include', ['module', 'policies']],
      ['order_by', 'ASC'],
      ['take', 10000],
    ],
  })
