import { isAfter, isBefore } from 'date-fns'
import { Row } from 'react-table'

const useDateFilter = <
  T extends Record<string, unknown> = Record<string, unknown>
>() => {
  // filters out rows by category (id) using filterValue
  const filterRowsByDate = (accessor: (row: T) => Date) => (
    rows: Row<T>[],
    id: string[],
    dateValue: DateRangeState | undefined
  ) => {
    // if no filter selected, show everything
    if (!dateValue) return rows

    const [beginDate, endDate] = dateValue.value

    // filter out any rows whose time is not between beginDate and endDate
    return rows.filter((row) => {
      const rowDate = accessor(row.original)

      return (
        rowDate instanceof Date &&
        // TODO: remove eslint disable and refine webhook activity list filter logic when sample data exists
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (!beginDate || isAfter(rowDate, beginDate)) &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (!endDate || isBefore(rowDate, endDate))
      )
    })
  }

  return {
    filterRowsByDate,
  }
}

export default useDateFilter
