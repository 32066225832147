import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, orgsApi, toUpdateObj, handleQueryError } from 'utils'

const useUpdateAdminPoliciesMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    UpdateAdminPoliciesResponse,
    KyError,
    updateAdminPoliciesArgs,
    Admin | undefined
  >(updateAdminPolicies, {
    onMutate: ({ adminId, policies }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<Admin>(['getAdmin', adminId])

      // immediately update the module list
      if (snapshot) {
        queryCache.setQueryData<Admin | undefined>(
          ['getAdmin', adminId],
          (oldCache) => {
            if (!oldCache) return undefined

            const updatedValue: Admin = {
              ...oldCache,
              policies: policies.map((policy) => policy.id),
            }

            return updatedValue
          }
        )
      }

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Admin policies updated') })
    },
    onError: async (error, { adminId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<Admin | undefined>(
        ['getAdmin', adminId],
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: (_, __, { adminId }) => {
      // background fetch to sync new data with the server
      queryCache.invalidateQueries(['getAdmin', adminId])
    },
  })

  return mutation
}

export default useUpdateAdminPoliciesMutation

type updateAdminPoliciesArgs = {
  adminId: string
  policies: Policy[]
}

const updateAdminPolicies = async ({
  adminId,
  policies,
}: updateAdminPoliciesArgs) => {
  const request = [
    toUpdateObj(
      'policies',
      policies.map((policy) => policy.id)
    ),
  ]

  const result = orgsApi
    .patch(`api/ecg-admins/${adminId}`, {
      json: request,
    })
    .json<UpdateAdminPoliciesResponse>()

  return result
}
