import React, { useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { useParams } from 'react-router-dom'
import { Empty } from 'atlas'
import { LoadingIcon } from 'elements'
import { useSubscriberVitalHealthReportQuery } from 'hooks/vitals'
import {
  dateRangePresetToMatrixColumnLabel,
  dateRangePresetToDatesArray,
} from 'utils'
import { useNavigate } from 'hooks'
import { formatISO, sub, add, startOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import { useDelay } from 'hooks'
import _ from 'lodash'

type SubscriberVitalsMatrixProps = {
  dateRangeState: DateRangeState
}

type BloodPressureReadings = {
  date: Date
  systolicReading: number | undefined
  diastolicReading: number | undefined
  normalRangeLow: number | undefined
  normalRangeHigh: number | undefined
  delta: number | undefined
  severity: number | undefined
}

const SubscriberVitalsMatrix = ({
  dateRangeState,
}: SubscriberVitalsMatrixProps) => {
  const delay = useDelay()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { subscriberId } = useParams()

  const {
    data: subscriberVitalHealthReportQuery,
    isLoading: isVitalHealthReportLoading,
  } = useSubscriberVitalHealthReportQuery({
    subscriberId: subscriberId,
    dateRangeState: dateRangeState,
  })

  const columns = useMemo(() => {
    return dateRangePresetToDatesArray(dateRangeState.preset)
  }, [dateRangeState])

  const vitalSignData = useMemo(() => {
    return _.uniqBy(
      _.flatten(
        subscriberVitalHealthReportQuery?.data.map(
          (vitalSign) => vitalSign.vitalSignData
        )
      ),
      'vitalSignId'
    )
  }, [subscriberVitalHealthReportQuery])

  const bloodPressureReadings = useMemo<BloodPressureReadings[]>(() => {
    return _.flatten(
      subscriberVitalHealthReportQuery?.data.map(({ vitalSigns }) => {
        return vitalSigns.map((vitalRecords) => {
          const systolicReading = vitalRecords.vitalSigns.find(
            (vitalReading) =>
              vitalReading.vitalSignId.toString() ===
              vitalSignData.find((vitalSign) => vitalSign.code === 'sys')
                ?.vitalSignId
          )

          const diastolicReading = vitalRecords.vitalSigns.find(
            (vitalReadings) =>
              vitalReadings.vitalSignId.toString() ===
              vitalSignData.find((vitalSign) => vitalSign.code === 'dia')
                ?.vitalSignId
          )

          if (systolicReading && diastolicReading)
            return {
              date: datePresetToDate(dateRangeState.preset, vitalRecords.date),
              systolicReading: systolicReading.reading,
              diastolicReading: diastolicReading.reading,
              normalRangeLow:
                (systolicReading.normalRangeLow +
                  diastolicReading.normalRangeLow) /
                2,
              normalRangeHigh:
                (systolicReading.normalRangeHigh +
                  diastolicReading.normalRangeHigh) /
                2,
              delta: (systolicReading.delta + diastolicReading.delta) / 2,
              severity:
                (systolicReading.severity + diastolicReading.severity) / 2,
            }

          return {
            date: datePresetToDate(dateRangeState.preset, vitalRecords.date),
            systolicReading: undefined,
            diastolicReading: undefined,
            normalRangeLow: undefined,
            normalRangeHigh: undefined,
            delta: undefined,
            severity: undefined,
          }
        })
      })
    )
  }, [subscriberVitalHealthReportQuery])

  if (isVitalHealthReportLoading) return <LoadingIcon />
  return (
    <Container>
      {subscriberVitalHealthReportQuery?.data.length ? (
        <Table>
          {/** DATE ROW */}
          <FadeInSlideDown delay={delay()}>
            <Row numberOfColumns={columns.length}>
              <div />
              {columns.map((date) => {
                return (
                  <ColumnLabel key={date.toString()}>
                    {dateRangePresetToMatrixColumnLabel({
                      date,
                      preset: dateRangeState.preset,
                    })}
                  </ColumnLabel>
                )
              })}
            </Row>
          </FadeInSlideDown>
          {subscriberVitalHealthReportQuery.data.map(({ vitalSigns }) => {
            return vitalSignData.map((vitalData) => {
              /** Vital Label Rows*/
              if (vitalData.code === 'sys' || vitalData.code === 'dia')
                return null
              return (
                <FadeInSlideDown key={vitalData.vitalSignId} delay={delay()}>
                  <Row numberOfColumns={columns.length}>
                    <RowLabel>
                      <RowLabelName>{vitalData.name}</RowLabelName>
                      <p>{vitalData.unit}</p>
                    </RowLabel>
                    {/** Vital Reading Squares */}
                    {vitalSigns.map((vitalDateRangeReadings, index) => {
                      const vitalReadingDate = datePresetToDate(
                        dateRangeState.preset,
                        vitalDateRangeReadings.date
                      )

                      const vitalReading = vitalDateRangeReadings.vitalSigns.find(
                        (reading) =>
                          vitalData.vitalSignId ===
                          reading.vitalSignId.toString()
                      )

                      return vitalReading ? (
                        <Square
                          key={
                            '' +
                            vitalReading.delta +
                            index +
                            vitalReading.vitalSignId
                          }
                          severity={vitalReading.severity}
                          reading={vitalReading.reading}
                          onClick={
                            vitalReading.reading
                              ? () =>
                                  navigate({
                                    pathname: 'vitals',
                                    searchParams: {
                                      vitalSignIds: [
                                        vitalReading.vitalSignId.toString(),
                                      ],
                                      dateRangePreset: 'custom',
                                      dateRangeValues: [
                                        formatISO(columns[index]),
                                        formatISO(
                                          /* subtract one second from the second date so that the
                                     second date label displays the last day of this interval */
                                          sub(
                                            index !== columns.length - 1
                                              ? columns[index + 1]
                                              : // if this is the last column index then calculate the end date for this interval
                                                getLastColumnEndDate(
                                                  columns[index],
                                                  dateRangeState.preset
                                                ),
                                            { seconds: 1 }
                                          )
                                        ),
                                      ],
                                    },
                                  })
                              : undefined
                          }
                        >
                          <SquareReading>
                            {_.round(vitalReading.reading)}
                          </SquareReading>
                          <SquareOffset>
                            {`(${_.round(vitalReading.delta)})`}
                          </SquareOffset>
                        </Square>
                      ) : vitalReadingDate < new Date() ? (
                        <Square
                          severity={0}
                          reading={0}
                          onClick={undefined}
                          key={index}
                        >
                          <SquareReading>-</SquareReading>
                        </Square>
                      ) : null
                    })}
                  </Row>
                </FadeInSlideDown>
              )
            })
          })}
          {/* Custom blood pressure reading , do not render if all readings are empty */}
          {!bloodPressureReadings.every(
            (entry) => entry.diastolicReading === undefined
          ) ? (
            <FadeInSlideDown delay={delay()}>
              <Row numberOfColumns={columns.length}>
                <RowLabel>
                  <RowLabelName>{t('Blood Pressure')}</RowLabelName>
                  <p>{t('mmhg')}</p>
                </RowLabel>
                {bloodPressureReadings.map((bp, index) => {
                  return bp.date < new Date() ? (
                    <Square
                      key={index}
                      severity={bp.severity || 0}
                      reading={bp.diastolicReading || 0}
                      // TODO: figure out why search Params arent applying ?
                      onClick={
                        bp.diastolicReading
                          ? () =>
                              navigate({
                                pathname: 'vitals',
                                searchParams: {
                                  dateRangePreset: dateRangeState.preset,
                                  vitalSignIds: [
                                    vitalSignData.find(
                                      (vitalSign) => vitalSign.code === 'sys'
                                    )?.vitalSignId || '',
                                    vitalSignData.find(
                                      (vitalSign) => vitalSign.code === 'dia'
                                    )?.vitalSignId || '',
                                  ],
                                },
                              })
                          : undefined
                      }
                    >
                      <SquareReading>
                        {bp.systolicReading
                          ? `${_.round(bp.systolicReading, 0)}`
                          : '-'}{' '}
                        /{' '}
                        {bp.diastolicReading
                          ? `${_.round(bp.diastolicReading, 0)}`
                          : '-'}
                      </SquareReading>
                      <SquareOffset>
                        {bp.delta ? `(${_.round(bp.delta)})` : null}
                      </SquareOffset>
                    </Square>
                  ) : null
                })}
              </Row>
            </FadeInSlideDown>
          ) : null}
        </Table>
      ) : (
        <>
          <Empty
            title={t('No Data Found')}
            description={t('Try changing the date range')}
          />
        </>
      )}
    </Container>
  )
}

export default SubscriberVitalsMatrix

const Container = tw.div`mb-6`

const Table = tw.div`grid gap-1`

const Row = styled.div<{ numberOfColumns: number }>`
  ${tw`grid gap-1`}
  grid-template-columns: 250px repeat(${({ numberOfColumns }) =>
    numberOfColumns}, 1fr);
`

const RowLabel = tw.div`flex flex-col justify-center mr-1 bg-white border border-gray-200 p-2`

const RowLabelName = tw.p`font-semibold`

const ColumnLabel = tw.div`flex justify-center`

const Square = styled.div<{
  severity: number
  reading: number | string
  onClick: (() => void) | undefined
}>`
  ${tw`flex flex-col items-center justify-center py-2`}
  ${({ onClick }) => {
    if (onClick) return tw`cursor-pointer hover:opacity-90`
  }}
  ${({ severity }) => {
    if (severity > 2) return tw`bg-red-400`
    if (severity > 1) return tw`bg-orange-300`
    if (severity) return tw`bg-amber-200`

    return tw`bg-gray-200`
  }}
  ${({ reading }) => {
    if (!reading) return tw`bg-gray-300`
  }}
`

const SquareReading = tw.p`text-lg leading-5`

const SquareOffset = tw.p`opacity-75 text-sm leading-5`

const getLastColumnEndDate = (startDate: Date, preset: DateRangePreset) => {
  switch (preset) {
    case 'today':
    case 'this-week':
    case 'last-week':
    default:
      return startOfDay(add(startDate, { days: 1 }))
    case 'this-month':
    case 'last-month':
      return startOfDay(add(startDate, { weeks: 1 }))
    case 'this-quarter':
    case 'last-quarter':
    case 'this-year':
    case 'last-year':
      return startOfDay(add(startDate, { months: 1 }))
  }
}

const datePresetToDate = (
  datePreset: DateRangePreset,
  // TODO: open issue to strictly type vital reading date? can come in as {to: string from: string} | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vitalReadingDate: any
) => {
  switch (datePreset) {
    case 'today':
    case 'this-week':
    case 'last-week':
    case 'this-quarter':
    case 'last-quarter':
    case 'this-year':
    case 'last-year':
    case 'custom':
    default:
      return new Date(vitalReadingDate.toString())
    case 'this-month':
    case 'last-month':
      return new Date(vitalReadingDate.from)
  }
}
