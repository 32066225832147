import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const useOrganizationQuery = (orgId?: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}'],
    KyError
  >(['getOrganization', orgId], () => getOrganization(orgId), {
    enabled: !!orgId,
  })

  return query
}

export default useOrganizationQuery

// get organization details
type getOrganization = (
  orgId?: string
) => Promise<OrgsAPIResponse['GET']['/api/organizations/{organizationId}']>
const getOrganization: getOrganization = async (orgId) => {
  const searchParams = [
    ['include', 'parent'],
    ['include', 'children'],
  ]

  const result = await orgsApi
    .get(`api/organizations/${orgId}`, {
      searchParams,
    })
    .json<OrgsAPIResponse['GET']['/api/organizations/{organizationId}']>()
  return result
}
