import React from 'react'
import { SimpleList } from 'elements'
import { Chip } from 'atlas'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { isPast, isDate, format } from 'date-fns'
import { useNavigate } from 'hooks'
import _ from 'lodash'

type MedicationsListProps = {
  subscriberMedications: Array<SubscriberMedications> | undefined
}

const MedicationsList = ({ subscriberMedications }: MedicationsListProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <MedicationsListContainer>
      <SimpleList
        data={subscriberMedications || []}
        baseDelay={0.25}
        renderItem={(medication) => {
          const isActive =
            isDate(new Date(medication.effectiveTo || '')) ||
            isPast(new Date(medication.effectiveTo || ''))
          return (
            <SimpleList.Item
              key={medication.id}
              onClick={() => navigate(medication.id)}
            >
              <ListContainer>
                <Column>
                  <Title>{t('Medication')}</Title>
                  <Item>
                    {_.startCase(
                      typeof medication.medicationMaster !== 'string'
                        ? medication.medicationMaster.brandNameBase ||
                            medication.medicationMaster.genericName
                        : medication.medicationMaster
                    )}
                  </Item>
                </Column>
                <Column>
                  <Title>{t('Purpose')}</Title>
                  <Item>{_.startCase(medication.medicationFor)}</Item>
                </Column>

                <Column>
                  <Title>{isActive ? t('Commenced') : t('Concluded')}</Title>
                  <Item>
                    {isActive
                      ? format(new Date(medication.effectiveFrom || ''), 'PP')
                      : format(new Date(medication.effectiveTo || ''), 'PP')}
                  </Item>
                </Column>

                <Active>
                  <Chip color={isActive ? 'green' : 'red'}>
                    {isActive ? t('Active') : t('Inactive')}{' '}
                  </Chip>
                </Active>
              </ListContainer>
            </SimpleList.Item>
          )
        }}
      />
    </MedicationsListContainer>
  )
}

export default MedicationsList

const MedicationsListContainer = tw.div`w-full mb-8`

const ListContainer = tw.div`grid grid-template-columns[1fr 1fr 1fr 3rem] mr-4 items-center`

const Column = tw.p`ml-4 truncate`

const Active = tw.p`flex justify-end`

const Title = tw.h1`text-xs font-medium text-gray-500`

const Item = tw.h1`font-semibold truncate`
