import React, { useRef } from 'react'
import { FadeInSlideDown } from 'animations'
import { Chip } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { EllipsisDropdown, EntityLabel } from 'elements'
import { useOrganizationQuery } from 'hooks/organizations'
import {
  useBlockedStaffOrganizationsQuery,
  useBlockStaffOrganizationMutation,
  useUnblockStaffOrganizationMutation,
} from 'hooks/user-staff'
import { useNavigate } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'

type OrgSubItemProps = {
  isListFlat: boolean
  org: OrganizationHierarchyItem
  isBlockedParent: boolean
  isBlockedChild: boolean
}

const OrganizationStaffHierarchyItem = ({
  isListFlat,
  org,
  isBlockedParent,
  isBlockedChild,
}: OrgSubItemProps) => {
  const dropdownEllipsisEl = useRef(null)
  const { staffid } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const blockStaffOrganization = useBlockStaffOrganizationMutation()
  const unblockStaffOrganization = useUnblockStaffOrganizationMutation()

  const { data: blockedSubOrganizations } = useBlockedStaffOrganizationsQuery(
    staffid
  )

  const { data: typedOrganization } = useOrganizationQuery(org.id)

  return (
    <FadeInSlideDown className="flex items-center w-full">
      <OrganizationLine
        style={{
          width: `${isListFlat ? 0 : (org.level - 2) * 4}rem`,
        }}
      />
      <OrganizationContainer
        blocked={isBlockedChild || isBlockedParent}
        onClick={() =>
          navigate(
            assumedOrganizationRole
              ? `/organization/${org.id}`
              : `/organizations/${org.id}`
          )
        }
      >
        <OrganizationContent>
          <EntityLabel
            className="flex-grow"
            name={org.businessName}
            description={org.displayName}
            id={org.id}
            size="md"
          />
          {isBlockedParent ? (
            <BlockedChildrenLabel>
              {t('Blocked Children')} (
              {
                blockedSubOrganizations?.items?.find(
                  (blockedOrg) => blockedOrg.id === org.id
                )?.nodes.length
              }
              )
            </BlockedChildrenLabel>
          ) : null}
          {isBlockedParent || isBlockedChild ? (
            <Chip className="align-text-top" color="red">
              {t('Blocked')}
            </Chip>
          ) : null}
          <div ref={dropdownEllipsisEl} onClick={(e) => e.stopPropagation()}>
            &nbsp;
            <EllipsisDropdown
              disabled={isBlockedChild}
              testid={`ellipsis-${org.businessName.replaceAll(' ', '')}`}
              options={
                typedOrganization
                  ? isBlockedParent
                    ? [
                        {
                          label: t('Unblock Organization'),
                          onClick: () => {
                            unblockStaffOrganization.mutate({
                              staffMemberId: staffid,
                              organization: typedOrganization,
                            })
                          },
                        },
                      ]
                    : [
                        {
                          label: t('Block Organization'),
                          onClick: () => {
                            blockStaffOrganization.mutate({
                              staffMemberId: staffid,
                              organization: typedOrganization,
                            })
                          },
                        },
                      ]
                  : []
              }
            />
            &nbsp;
          </div>
        </OrganizationContent>
      </OrganizationContainer>
    </FadeInSlideDown>
  )
}

export default OrganizationStaffHierarchyItem

const OrganizationContainer = styled.div<{ blocked?: boolean }>`
  ${tw`border bg-white border-gray-200 p-4 mb-2 flex justify-between w-full items-center rounded flex-grow hover:border-blue-200 hover:shadow cursor-pointer`}
  ${({ blocked }) => {
    if (blocked) return tw`bg-gray-200 border-gray-300`
  }}
`

const OrganizationContent = tw.div`flex flex-row items-center justify-between space-x-2 flex-grow w-64 mr-4`

const OrganizationLine = tw.span`border-b-2 border-gray-200 -mt-2`

const BlockedChildrenLabel = tw.p`text-gray-600 text-sm mr-2`
