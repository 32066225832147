import React from 'react'
import { ReactComponent as Logo } from 'assets/addi-logo-text.svg'
import { FadeInSlideDown } from 'animations'
import loginbg from 'assets/login-bg.jpg'
import tw from 'twin.macro'
import { useDelay } from 'hooks'
import { useTranslation } from 'react-i18next'

type LoginProps = {
  children: React.ReactNode
}

const Login = ({ children }: LoginProps) => {
  const { t } = useTranslation()

  const delay = useDelay()

  return (
    <LoginContainer>
      <LoginSideBarContainer>
        <LogoContainer>
          <FadeInSlideDown delay={delay()}>
            <ADDILogo />
          </FadeInSlideDown>

          <FadeInSlideDown delay={delay()}>
            <LoginText>
              {t('The next generation of Remote Patient Monitoring') + '.'}
            </LoginText>
          </FadeInSlideDown>

          <FadeInSlideDown delay={delay()}>{children}</FadeInSlideDown>
        </LogoContainer>

        <LoginFooter>
          <Copyright>
            &copy;{' '}
            {`2020-${new Date().getFullYear()} ${t(
              'Electronic Caregiver, Inc.'
            )}`}
          </Copyright>
        </LoginFooter>
      </LoginSideBarContainer>
      <ImageContainer>
        <Image src={loginbg} style={{ objectFit: 'cover' }} />
      </ImageContainer>
    </LoginContainer>
  )
}

export default Login

const LoginContainer = tw.div`w-screen h-screen flex overflow-hidden`

const LoginSideBarContainer = tw.div`relative background-color[#1F4472] w-full p-8 pb-24 flex flex-col justify-center items-center md:(flex-grow-0) xl:(w-1/3) md:(w-1/2)`

const LogoContainer = tw.div`flex flex-col justify-center w-full items-center`

const LoginText = tw.p`my-5 text-lg text-white font-light text-opacity-70 text-center`

const ImageContainer = tw.div`xl:w-2/3 md:w-1/2`

const Image = tw.img` h-full w-full hidden flex-grow-0 xl:(block flex-grow) md:(block flex-grow)`

const ADDILogo = tw(Logo)`w-52`

const LoginFooter = tw.div`absolute bottom-0 py-4`

const Copyright = tw.span`text-white text-opacity-30 text-sm`
