import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useArchiveVitalSignMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    {
      response:
        | VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/archive']
        | VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/restore']
      isArchived: boolean
    },
    KyError<ErrorResponse>,
    ToggleArchiveVitalSignProps
  >(toggleArchiveVitalSign, {
    onSuccess: ({ isArchived }, { vitalSign: vitalSignToToggle }) => {
      // Notify user of successful vital sign archival or restoration
      success({
        message: `${t('Vital sign')} ${
          isArchived ? t('archived') : t('restored')
        }`,
      })

      // Grab the old vital sign list from the queryCache
      const oldVitalSigns: VitalSign[] =
        queryCache.getQueryData<VitalsAPIResponse['GET']['/vital-signs']>(
          'ECGAdmin_getVitalSigns'
        )?.items || []

      // Update the vital sign in oldVitalSigns
      const newVitalSigns = oldVitalSigns.map((vitalSign) => {
        if (vitalSign.id === vitalSignToToggle.id) {
          return {
            ...vitalSign,
            archiveInfo: {
              ...vitalSign.archiveInfo,
              archived: isArchived,
            },
          }
        }
        return vitalSign
      })
      // Immediately update vital sign to local cache
      queryCache.setQueryData(['ECGAdmin_getVitalSigns'], newVitalSigns)

      // Refetch from API to make sure the new vital sign is in sync with the server
      queryCache.invalidateQueries('ECGAdmin_getVitalSigns')
      queryCache.invalidateQueries('getVitalAssignments')
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useArchiveVitalSignMutation

type ToggleArchiveVitalSignProps = {
  vitalSign: VitalSign
}

// Toggle whether this vital sign is archived or not
const toggleArchiveVitalSign = async ({
  vitalSign,
}: ToggleArchiveVitalSignProps) => {
  const result = await vitalsApi
    .post(
      `vital-signs/${vitalSign.id}/${
        vitalSign.archiveInfo.archived ? 'restore' : 'archive'
      }`
    )
    .json<
      | VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/archive']
      | VitalsAPIResponse['POST']['/vital-signs/{vitalSignId}/restore']
    >()
  return { response: result, isArchived: !vitalSign.archiveInfo.archived }
}
