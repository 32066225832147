import React, { useEffect, useState } from 'react'
import { Button } from 'atlas'
import { RightPopup, TextField, Select, SelectItem } from 'elements'
import { useForm, Controller } from 'react-hook-form'
import { phoneValidation } from 'utils'
import _ from 'lodash'
import {
  useSubscriberCreatePhoneMutation,
  useUpdateSubscriberPhoneMutation,
} from 'hooks/user-subscriber'
import { useSearchParam } from 'react-use'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

type PhoneFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  takenPhoneTypes: ('Mobile' | 'Home' | 'Work' | 'Main')[]
  phoneToMutate?: SubscriberPhone
}

const PhoneNumberForm = ({
  isFormOpen,
  setIsFormOpen,
  takenPhoneTypes,
  phoneToMutate,
}: PhoneFormProps) => {
  const { t } = useTranslation()

  const createSubscriberId = useSearchParam('subscriberId')
  const { subscriberId: editSubscriberId } = useParams()
  const subscriberId = createSubscriberId || editSubscriberId

  const {
    register,
    reset,
    errors,
    control,
    handleSubmit,
  } = useForm<SubscriberPhoneForm>({
    defaultValues: {
      number: '',
      extension: '',
      type: phoneToMutate?.type || 'Mobile',
    },
  })

  const [availablePhoneTypes, setAvailablePhoneTypes] = useState<
    typeof takenPhoneTypes
  >(
    [
      'Mobile' as const,
      'Home' as const,
      'Work' as const,
      'Main' as const,
    ].filter((phoneType) => {
      return !takenPhoneTypes.includes(phoneType)
    })
  )

  const subscriberCreatePhoneMutation = useSubscriberCreatePhoneMutation()
  const subscriberUpdatePhoneMutation = useUpdateSubscriberPhoneMutation()

  // reset form when opened
  useEffect(() => {
    if (isFormOpen) {
      reset(
        phoneToMutate || {
          number: '',
          extension: '',
          type: availablePhoneTypes[0] || '',
        }
      )
    }
  }, [isFormOpen])

  // set available phone types based on what types have been used
  useEffect(() => {
    setAvailablePhoneTypes(() => {
      return (['Main', 'Mobile', 'Home', 'Work'] as const).filter(
        (phoneType) => {
          return !takenPhoneTypes.includes(phoneType)
        }
      )
    })
  }, [takenPhoneTypes])

  return (
    <RightPopup
      width="500px"
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={phoneToMutate ? t('Edit Phone Number') : t('Add Phone Number')}
      controls={
        <>
          <Button
            onClick={handleSubmit(async (formData) => {
              !phoneToMutate
                ? subscriberCreatePhoneMutation.mutate({
                    subscriberId: subscriberId || '',
                    phoneForm: {
                      number: formData.number
                        // if dashes, parentheses or spaces were added remove these
                        .replaceAll('-', '')
                        .replaceAll('(', '')
                        .replaceAll(')', '')
                        .replaceAll(' ', ''),
                      type: formData.type,
                      extension: formData.extension,
                    },
                  })
                : subscriberUpdatePhoneMutation.mutate({
                    subscriberId: subscriberId || '',
                    phoneNumberId: phoneToMutate.id || '',
                    phoneForm: {
                      number: formData.number
                        .replaceAll('-', '')
                        .replaceAll('(', '')
                        .replaceAll(')', '')
                        .replaceAll(' ', ''),
                      type: formData.type,
                      extension: formData.extension,
                    },
                  })
              setIsFormOpen(false)
            })}
          >
            {phoneToMutate ? 'Update' : 'Create'}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <form>
        <Controller
          control={control}
          as={TextField}
          name="number"
          className="w-80 mt-4 mr-4"
          rules={{
            pattern: {
              value: phoneValidation,
              message: 'Phone Number is invalid',
            },
            required: 'Phone Number is required',
          }}
          label={t('Phone Number')}
          error={errors.number?.message}
          defaultValue=""
        />
        <TextField
          name="extension"
          className="w-20 mt-4 mr-4"
          type="number"
          inputRef={register()}
          label={t('Ext')}
        />

        <Controller
          control={control}
          render={({ onChange }) => {
            return (
              <Select
                label={t('Phone Type')}
                defaultValue={
                  phoneToMutate?.type || availablePhoneTypes[0] || ''
                }
                className="w-48"
                variant="outlined"
                onChange={(event) => {
                  onChange(event.target.value)
                }}
              >
                {availablePhoneTypes.map((phoneType) => {
                  return (
                    <SelectItem value={phoneType} key={phoneType}>
                      {_.capitalize(t(`${phoneType}`))}
                    </SelectItem>
                  )
                })}
              </Select>
            )
          }}
          name="type"
          defaultValue={phoneToMutate?.type || availablePhoneTypes[0] || ''}
          error={errors.type?.message}
        />
      </form>
    </RightPopup>
  )
}

export default PhoneNumberForm
