import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useProvisioningCompleteMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/provisioning-complete'],
    ProvisioningCompleteArgs
  >({
    mutationFn: checkoutOrder,
    successMsg: {
      message: t('Order was provisioned successfully'),
      showOn: 'onSuccess',
    },

    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['order', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          ProvisioningCompleteArgs
        >((oldCache) => {
          if (!oldCache) return undefined

          return {
            ...oldCache,
            orderStatus: {
              id: 4,
              description: '',
              title: 'Provisioning Complete',
            },
          }
        }),
      },
    ],
    additionalCachesToInvalidate: [
      ({ orderId }) => ['order', { orderId }],
      ({ orderId }) => ['orderActivity', { orderId }],
    ],
  })

  return mutation
}

export default useProvisioningCompleteMutation

type ProvisioningCompleteArgs = { orderId: string }

const checkoutOrder = ({ orderId }: ProvisioningCompleteArgs) =>
  ordersApi.post('/orders/{orderId}/provisioning-complete', {
    pathParams: { orderId },
    body: { note: '-' },
  })
