import React from 'react'
import { RightPopup } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useWebhookEventPayloadsQuery } from 'hooks/settings-webhooks'
import SampleDataCodeViewer from './SampleDataCodeViewer'

type WebhookEventSampleDataModalProps = {
  isOpen: boolean
  setIsOpen: (newValue: boolean) => void
  displayedEvent?: WebhookEvent
}

const WebhookEventSampleDataModal = ({
  isOpen,
  setIsOpen,
  displayedEvent,
}: WebhookEventSampleDataModalProps) => {
  const { t } = useTranslation()
  const { data: webhookEventPayload, isLoading } = useWebhookEventPayloadsQuery(
    {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      webhookCategoryId: displayedEvent?.webhookCategory?.id,
      webhookEventId: displayedEvent?.id,
    }
  )

  return (
    <RightPopup
      open={isOpen}
      setOpen={setIsOpen}
      title={t('Sample Data')}
      width="700px"
      description={`${displayedEvent?.name} (${displayedEvent?.code})`}
    >
      <SampleDataCodeViewer
        isLoading={isLoading}
        data={webhookEventPayload?.payload}
        controls={
          <Button type="secondary" tw="mt-4" onClick={() => setIsOpen(false)}>
            {t('Close')}
          </Button>
        }
      />
    </RightPopup>
  )
}

export default WebhookEventSampleDataModal
