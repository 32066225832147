import React from 'react'
import { Button, Empty } from 'atlas'
import { useDelay } from 'hooks'
import { useTranslation } from 'react-i18next'
import { FadeInSlideDown } from 'animations'
import tw from 'twin.macro'

const SubscriberMedicationReminderList = () => {
  const { t } = useTranslation()

  const delay = useDelay()

  return (
    <ReminderListContainer>
      <FadeInSlideDown delay={delay(0.2)}>
        <Title>{t('Reminders')}</Title>
        <Description>
          {t(
            'Add one or more reminders for this medication. Subscribers will get reminders based on these schedules.'
          )}
        </Description>
        <EmptyDashed
          title={t('No Reminders Added')}
          description={t(
            'Subscribers will not get any alerts until a schedule has been added for this medication.'
          )}
          callToAction={
            <Button type="primary-filled" onClick={() => null}>
              {t('Add Reminders')}
            </Button>
          }
        />
      </FadeInSlideDown>
    </ReminderListContainer>
  )
}

export default SubscriberMedicationReminderList

const Title = tw.div`text-2xl font-semibold`

const EmptyDashed = tw(Empty)`flex-grow h-52 border-2 border-dashed rounded`

const ReminderListContainer = tw.div`my-5`

const Description = tw.p`mb-2 text-gray-600`
