import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useTimezonesQuery = () =>
  useQuery<OrgsAPIResponse['GET']['/api/seed-data/timezones'], KyError>(
    'getTimezones',
    () => typedOrgsApi.get('/api/seed-data/timezones')
  )

export default useTimezonesQuery
