import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

type useAddressedAlertStatisticsArgs = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const useAddressedAlertStatistics = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: useAddressedAlertStatisticsArgs) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/insights/vitals-metrics/addressable-alerts-metrics'],
    KyError
  >(
    [
      'getAddressedAlertStatistics',
      dateRangeState,
      organizationId,
      subscriberId,
    ],
    () =>
      getAddressedAlertStatistics(dateRangeState, organizationId, subscriberId)
  )

  return query
}

export default useAddressedAlertStatistics

const getAddressedAlertStatistics = async (
  dateRangeState: DateRangeState,
  organizationId?: string,
  subscriberId?: string
) => {
  const searchParams = new URLSearchParams([
    ['dateRangeType', dateRangeState.preset],
  ])

  if (organizationId) searchParams.append('organizationId', organizationId)

  if (subscriberId) searchParams.append('subscriberId', subscriberId)

  if (dateRangeState.preset === 'custom') {
    searchParams.append('from', dateRangeState.value[0].toISOString())
    searchParams.append('to', dateRangeState.value[1].toISOString())
  }

  const result = vitalsApi
    .get(`insights/vitals-metrics/addressable-alerts-metrics`, {
      searchParams: searchParams.toString(),
    })
    .json<
      VitalsAPIResponse['GET']['/insights/vitals-metrics/addressable-alerts-metrics']
    >()
    .catch(async (e) => {
      const error = await e.response.json()
      if (error.code === 404) {
        const result: VitalsAPIResponse['GET']['/insights/vitals-metrics/addressable-alerts-metrics'] = {
          fromDate: dateRangeState.value[0].toString(),
          toDate: dateRangeState.value[1].toString(),
          alertData: { addressed: 0, addressed_percentage: 0, total: 0 },
        }

        return result
      }
      throw e
    })

  return result
}
