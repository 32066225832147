import React from 'react'
import { FadeInSlideDown } from 'animations'
import { Button } from 'atlas'
import {
  AutoComplete,
  LoadingIcon,
  Select,
  SelectItem,
  TextField,
} from 'elements'
import { useDelay } from 'hooks'
import { useAlertCategoryQuery } from 'hooks/alerts'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type CreateAlertDeliveryRuleFormBasicInformationProps = {
  nextStep: () => void
  className?: string
}

const CreateAlertDeliveryRuleFormBasicInformation = ({
  nextStep,
  className,
}: CreateAlertDeliveryRuleFormBasicInformationProps) => {
  const { t } = useTranslation()
  const delay = useDelay()
  const alertCategories = useAlertCategoryQuery()
  const {
    errors,
    watch,
    trigger,
  } = useFormContext<AlertDeliveryRuleFormLocal>()

  if (!alertCategories.data) return <LoadingIcon />
  return (
    <div className={className}>
      <FadeInSlideDown delay={delay()}>
        <BasicInfoCard>
          <BasicInfoTitle>{t('Basic Information')}</BasicInfoTitle>
          <BasicInfoContainer>
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="name"
              label={t('Name')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Name is required') }}
              error={errors.name?.message}
              className="w-96 mt-4"
              placeholder={t('Severity 2 Vitals Alerts')}
              required
            />
          </BasicInfoContainer>
          <BasicInfoContainer>
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="description"
              label={t('Description')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Description is required') }}
              error={errors.description?.message}
              className="w-96 mt-4"
              placeholder={t('Email blast severity 2 vitals alerts weekly')}
              multiline
              required
            />
          </BasicInfoContainer>
          <AlertCategoryContainer>
            <Controller
              render={({ onChange }) => {
                return (
                  <CategoryAutoComplete<AlertCategory>
                    label={t('Alert Category')}
                    single
                    error={errors.criteria?.alertCategory?.message}
                    options={alertCategories.data.items || []}
                    optionLabel={(option) => option.name}
                    onChange={(option) => {
                      onChange(option?.id)
                    }}
                  />
                )
              }}
              name="criteria.alertCategory"
              required
              error={errors.criteria?.alertCategory?.message}
              rules={{ required: 'Alert Category Required' }}
            />
            {watch('criteria.alertCategory') ===
            // check for vitals reading selected
            '694f33b4-1a16-4b38-8041-e6b600b0dc0e' ? (
              <Controller
                as={
                  <Select
                    label={t('Severity Level')}
                    variant="outlined"
                    fullWidth={false}
                    selectClassName="w-48"
                  >
                    {[1, 2, 3].map((severity, index) => (
                      <SelectItem value={severity} key={index}>
                        {`Severity ${severity}`}
                      </SelectItem>
                    ))}
                  </Select>
                }
                name="criteria.severity"
                defaultValue={1}
              />
            ) : null}
          </AlertCategoryContainer>
        </BasicInfoCard>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <Button to="../">{t('Cancel')}</Button>
        &nbsp;
        <Button
          onClick={async () => {
            // If no errors for fields on this page, call nextStep
            if (
              await trigger(['name', 'description', 'criteria.alertCategory'])
            ) {
              nextStep()
            }
          }}
          type="primary-filled"
          data-testid="basic-information-continue"
        >
          {t('Save & Continue')}
        </Button>
      </FadeInSlideDown>
    </div>
  )
}

export default CreateAlertDeliveryRuleFormBasicInformation

const CategoryAutoComplete = tw(AutoComplete)`w-96`

const AlertCategoryContainer = tw.div`flex flex-col gap-4 mt-2 mb-6`

const BasicInfoCard = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const BasicInfoTitle = tw.h3`text-xl font-semibold mb-2`

const BasicInfoContainer = tw.div`flex gap-4`
