import React, { useEffect } from 'react'
import { RightPopup, Checkbox, LoadingIcon } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form'
import {
  useCreatePartnerTypeChildrenMutation,
  usePartnerTypesQuery,
} from 'hooks/partners'
import _ from 'lodash'
import tw from 'twin.macro'

type PartnerTypeAssociationsFormProps = {
  parentPartnerType: PartnerType
  childPartnerTypes: PartnerType[]
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
}

const PartnerTypeAssociationsForm = ({
  parentPartnerType,
  childPartnerTypes,
  isFormOpen,
  setIsFormOpen,
}: PartnerTypeAssociationsFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<{
    availableChildPartnerTypes: Array<{
      partnerType: PartnerType
      selected: boolean
    }>
  }>({
    defaultValues: { availableChildPartnerTypes: [] },
  })
  const { handleSubmit, reset, watch, control } = formMethods

  const { fields } = useFieldArray<{
    partnerType: PartnerType
    selected: boolean
  }>({
    control,
    name: 'availableChildPartnerTypes',
  })

  const partnerTypes = usePartnerTypesQuery()
  const createPartnerTypeChildren = useCreatePartnerTypeChildrenMutation()

  // reset form fields if isFormOpen toggled true
  useEffect(() => {
    const childPartnerTypeIds = childPartnerTypes.map(
      (childPartnerType) => childPartnerType.id
    )
    if (isFormOpen)
      reset({
        availableChildPartnerTypes: _.orderBy(
          partnerTypes.data?.items
            .filter(
              (partnerType) =>
                // remove parentPartnerType as a potential childPartnerType
                partnerType.id !== parentPartnerType.id &&
                // remove partnerTypes that are already children
                !childPartnerTypeIds.includes(partnerType.id)
            )
            .map((partnerType) => ({
              selected: false,
              partnerType,
            })),
          'partnerType.name'
        ),
      })
  }, [isFormOpen, partnerTypes.data])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Create Parent-of Associations')}
      width="600px"
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={createPartnerTypeChildren.isLoading}
            disabled={
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              !watch('availableChildPartnerTypes')?.filter(
                (availableChildPartnerType) =>
                  availableChildPartnerType.selected
              ).length
            }
            onClick={handleSubmit((formData) => {
              createPartnerTypeChildren.mutate({
                parentPartnerTypeId: parentPartnerType.id,
                childPartnerTypeIds: formData.availableChildPartnerTypes.reduce(
                  (acc, availableChildPartnerType, index) => {
                    const currentPartnerType = fields[index].partnerType

                    // add currentPartnerType as a child partner type if it is selected & not disabled or undefined
                    if (
                      availableChildPartnerType.selected &&
                      currentPartnerType
                    )
                      acc.push(currentPartnerType.id)

                    return acc
                  },
                  [] as string[]
                ),
              })

              // close form drawer
              setIsFormOpen(false)
            })}
          >
            {t('Create')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={createPartnerTypeChildren.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {partnerTypes.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            {fields.map((field, index) => (
              <CheckboxContainer key={field.id}>
                <Controller
                  render={({ onChange, value, ref }) => (
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      ref={ref}
                      label={field.partnerType?.name}
                      className="py-0.5"
                      value={value}
                    />
                  )}
                  name={`availableChildPartnerTypes[${index}].selected`}
                  className="mr-4"
                />
              </CheckboxContainer>
            ))}
          </form>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default PartnerTypeAssociationsForm

const CheckboxContainer = tw.div`flex`
