import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const useSubscriberQuery = (subscriberId: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/subscribers/{personId}'],
    KyError
  >(['getSubscriber', subscriberId], () => getSubscriber(subscriberId), {
    enabled: !!subscriberId,
  })

  return query
}

export default useSubscriberQuery

const searchParams = [['include', 'organization']]

// get list of subscribers the current role has access to
type getSubscriber = (
  subscriberId: string
) => Promise<OrgsAPIResponse['GET']['/api/subscribers/{personId}']>
const getSubscriber: getSubscriber = async (subscriberId) => {
  const result = await orgsApi
    .get(`api/subscribers/${subscriberId}`, {
      searchParams,
    })
    .json<OrgsAPIResponse['GET']['/api/subscribers/{personId}']>()
  return result
}
