import { mapboxApi } from 'utils'
import { useQuery } from 'react-query'
import i18next from 'i18next'
import { useGeolocation } from 'react-use'
import { GeoLocationSensorState } from 'react-use/lib/useGeolocation'
import { useEffect } from 'react'

const useAddressAutocompleteQuery = (searchTerm: string) => {
  const geoPosition = useGeolocation()

  const query = useQuery<GetAddressAutocompleteResponse, KyError>(
    ['getAddressMatches', searchTerm],
    () => getAddressMatches(searchTerm, geoPosition),
    { enabled: !!searchTerm && !geoPosition.loading }
  )

  // when geoPosition finishes loading trigger a query refetch to possibly fix
  // hard to reproduce bug where loading hangs
  useEffect(() => {
    !!searchTerm && !geoPosition.loading && query.refetch()
  }, [geoPosition.loading])

  return query
}

export default useAddressAutocompleteQuery

// get admin info from an searchTerm
type getAddressMatches = (
  searchTerm: string,
  geoPosition: GeoLocationSensorState
) => Promise<GetAddressAutocompleteResponse>
const getAddressMatches: getAddressMatches = async (
  searchTerm,
  geoPosition
) => {
  const result = await mapboxApi
    .get(`geocoding/v5/mapbox.places/${searchTerm}.json`, {
      searchParams: {
        language: i18next.language,
        ...(geoPosition.longitude && geoPosition.latitude
          ? {
              proximity: [geoPosition.longitude, geoPosition.latitude].join(
                ','
              ),
            }
          : {}),
        types: 'address',
        access_token: process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN || '',
      },
    })
    .json<GetAddressAutocompleteResponse>()
  return result
}
