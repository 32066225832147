import React, { useEffect, useState, useMemo } from 'react'
import { FadeInSlideDown } from 'animations'
import { Button } from 'atlas'
import { useWizardForm } from 'context/wizard-form'
import { parseISO } from 'date-fns'
import {
  Checkbox,
  EntityLabel,
  Icon,
  InformationCard,
  LoadingIcon,
  Dialog,
} from 'elements'
import { ChildStepContainer } from 'elements/WizardForm'
import { motion } from 'framer-motion'
import { useAPIQuery, useDelay } from 'hooks'
import { useContractQuery } from 'hooks/contracts'
import { useOrderConfirmMutation } from 'hooks/orders'
import _ from 'lodash'
import numeral from 'numeral'
import { useOrganizationQuery, useSubscriberQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import { useSearchParam } from 'react-use'
import tw, { styled } from 'twin.macro'
import { formatDate, formatPrice, isPresent } from 'utils'
import { lineItemTypeMap } from 'utils/contracts/getLineItemFields'

const Summary = () => {
  const delay = useDelay()
  const { t } = useTranslation()

  const orderId = useSearchParam('orderId') || ''

  const [disclaimersAccepted, setDisclaimersAccepted] = useState<number>(0)

  const [confirmationDialogOpen, setConfirmationDialog] = useState(false)

  const { stepState, setStepState } = useWizardForm()

  const orderQuery = useAPIQuery('order', {
    pathParams: {
      orderId,
    },
  })

  const productsQuery = useAPIQuery('products')

  const subscriberQuery = useSubscriberQuery(
    orderQuery.data?.subscriberId || ''
  )
  const organizationQuery = useOrganizationQuery(
    orderQuery.data?.organizationId || ''
  )
  const contractQuery = useContractQuery({
    contractId: orderQuery.data?.contractId,
    organizationId: orderQuery.data?.organizationId,
  })
  const confirmOrder = useOrderConfirmMutation()

  const address = orderQuery.data?.shippingInformation?.address

  const orderLineItems = orderQuery.data?.orderLineItems

  const orderTotals = orderQuery.data?.totals

  // grab warranties from the contract for line items within the order
  const warranties = useMemo(
    () =>
      _.flatten(
        contractQuery.data?.lineItems
          ?.map(
            (contract) =>
              contract[
                // lineItemType may be undefined
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                lineItemTypeMap[contract.lineItemType || 'PrimaryProduct'] ||
                  'primaryProductLineItem'
              ]?.pricing?.warrantySelection?.options
          )
          .filter(isPresent)
      ).filter((warranty) =>
        orderLineItems?.find(
          (orderLineItem) =>
            orderLineItem.priceSelection?.warrantySelection?.id === warranty.id
        )
      ),
    [contractQuery.data, orderQuery.data]
  )

  const warrantyTotals = {
    oneTime: warrantyBreakdown(warranties, 'OneTime'),
    monthly: warrantyBreakdown(warranties, 'Monthly'),
    yearly: warrantyBreakdown(warranties, 'Yearly'),
  }

  // calculate activation totals
  const activationFees = orderLineItems?.reduce(
    (accumulator, current) =>
      accumulator + (current.priceSelection?.activationPrice?.amount || 0),
    0
  )

  const productFees = {
    oneTime: productFeesBreakdown(orderLineItems, 'OneTime'),
    monthly: productFeesBreakdown(orderLineItems, 'Monthly'),
    yearly: productFeesBreakdown(orderLineItems, 'Yearly'),
  }

  // handle submission
  useEffect(
    () =>
      setStepState({
        ...stepState,
        isSubmitDisabled: disclaimersAccepted !== 3,
        disableNavigationWarning: confirmationDialogOpen,
        submitFn:
          // only confirm order if the order is 'In-Process' to avoid confirming an order multiple times
          /**
           * TODO: Once submitted, orders cannot revert to 'In-Process' unless cancelled then reactivated
           */
          //orderQuery.data?.orderStatus?.title === OrderStatusTitles.IN_PROCESS
          () =>
            confirmOrder.mutateAsync(
              { orderId: orderId },
              {
                onSuccess: () => {
                  setConfirmationDialog(true)
                },
              }
            ),
      }),
    [disclaimersAccepted, orderQuery.data, confirmationDialogOpen]
  )

  if (
    !subscriberQuery.data ||
    !organizationQuery.data ||
    !contractQuery.data ||
    !productsQuery.data
  )
    return <LoadingIcon />

  return (
    <div>
      <PageTitle>{t('Summary')}</PageTitle>

      <SummaryContainer>
        <Header delay={delay()}>
          <TopTitle>{t('Basic Information')}</TopTitle>
          <ManageButton
            type="primary-link"
            onClick={() => setStepState({ ...stepState, activeStep: 0 })}
          >
            {t('Manage')}
          </ManageButton>
        </Header>
        <InformationCard noCard delay={delay()}>
          <InformationCard.Item
            label={t('Organization')}
            value={
              <EntityLabel
                id={organizationQuery.data.id}
                name={organizationQuery.data.businessName}
              />
            }
          />
          <InformationCard.Item
            label={t('Contract')}
            value={contractQuery.data.descriptionInfo?.title || '-'}
          />
          <InformationCard.Item
            label={t('Subscriber')}
            value={
              <EntityLabel
                id={subscriberQuery.data.id}
                name={`${subscriberQuery.data.person.firstName} ${subscriberQuery.data.person.lastName}`}
              />
            }
          />
        </InformationCard>
        <Header delay={delay()}>
          <Title>{t('Shipping')}</Title>
          <ManageButton
            type="primary-link"
            onClick={() => setStepState({ ...stepState, activeStep: 2 })}
          >
            {t('Manage')}
          </ManageButton>
        </Header>
        <InformationCard noCard delay={delay()}>
          <InformationCard.Item
            label={t('Address')}
            value={
              address
                ? `${address.addressLine1} ${address.city}, ${address.stateProvinceCode} ${address.countryCode} ${address.postalCode}`
                : '-'
            }
          />
          <InformationCard.Item
            label={t('Shipping Method')}
            value={
              contractQuery.data.shippingPrices?.find(
                (shippingPrice) =>
                  shippingPrice.id ===
                  orderQuery.data?.shippingInformation?.shippingMethodId
              )?.priceDescriptor?.descriptionInfo?.title || '-'
            }
          />
          {/* 614e2fc37f7a04059efc04ad*/}
          <InformationCard.Item
            label={t('Shipping Notes')}
            value={orderQuery.data?.shippingInformation?.shippingNotes || '-'}
          />
        </InformationCard>
        <Header delay={delay()}>
          <Title>{t('Service Details')}</Title>
          <ManageButton
            type="primary-link"
            onClick={() => setStepState({ ...stepState, activeStep: 3 })}
          >
            {t('Manage')}
          </ManageButton>
        </Header>
        <InformationCard noCard delay={delay()}>
          <InformationCard.Item
            label={t('Service Start Date')}
            value={
              orderQuery.data?.serviceDetails?.expectedServiceStartDate
                ? formatDate(
                    parseISO(
                      orderQuery.data.serviceDetails.expectedServiceStartDate
                    ),
                    'PPP'
                  )
                : '-'
            }
          />
        </InformationCard>
        <Header delay={delay()}>
          <Title>{t('Products Cost Breakdown')}</Title>
          <ManageButton
            type="primary-link"
            onClick={() => setStepState({ ...stepState, activeStep: 1 })}
          >
            {t('Manage')}
          </ManageButton>
        </Header>

        <ProductBreakdown>
          {orderLineItems?.map((lineItem) => {
            const product = productsQuery.data.items?.find(
              (product) => product.id === lineItem.productId
            )
            const productImage = product?.productImage?.find(
              (image) => !image.deleted
            )

            const warranty = warranties.find(
              (warranty) =>
                warranty.id === lineItem.priceSelection?.warrantySelection?.id
            )

            return (
              <ProductItemGrid delay={delay()} key={lineItem.id}>
                <ProductImageContainer>
                  {productImage?.imageUrl ? (
                    <ProductImage
                      src={productImage.imageUrl}
                      alt={product?.title || undefined}
                      transition={{ type: 'spring', duration: 0.3 }}
                    />
                  ) : (
                    <ProductIcon
                      type={
                        product?.productType === 'Service'
                          ? 'radio'
                          : 'hardware'
                      }
                    />
                  )}
                </ProductImageContainer>
                <ProductDetailContainer>
                  <ProductTitle>{product?.title}</ProductTitle>
                  <ProductDescription>{product?.category}</ProductDescription>
                </ProductDetailContainer>

                <ProductPriceContainer>
                  <ProductPrice>
                    {lineItem.priceSelection?.productPrice?.amount ||
                    lineItem.priceSelection?.activationPrice?.amount
                      ? lineItem.priceSelection.productPrice?.amount
                        ? formatPrice(lineItem.priceSelection.productPrice)
                        : lineItem.priceSelection.activationPrice
                        ? formatPrice(lineItem.priceSelection.activationPrice)
                        : ''
                      : t('Free')}
                  </ProductPrice>

                  {lineItem.priceSelection?.activationPrice?.amount &&
                  lineItem.priceSelection.productPrice?.amount ? (
                    <ProductOneTimePrice>
                      {`+ ${formatPrice(
                        lineItem.priceSelection.activationPrice
                      )} ${t('activation')}`}
                    </ProductOneTimePrice>
                  ) : null}
                  {warranty?.priceDescriptor?.price ? (
                    <ProductOneTimePrice>
                      {`+ ${formatPrice(warranty.priceDescriptor.price)} ${t(
                        'warranty'
                      )}`}
                    </ProductOneTimePrice>
                  ) : null}
                </ProductPriceContainer>
              </ProductItemGrid>
            )
          })}
        </ProductBreakdown>
      </SummaryContainer>
      <TotalsContainer delay={delay()}>
        <TotalsContent>
          <TotalDetails>
            <PaymentTitlesContainer>
              <TextSemiBold>{t('One Time')}</TextSemiBold>
            </PaymentTitlesContainer>
            {warrantyTotals.oneTime !== 0 ? (
              <SubTitlesContainer>
                <SubTitle>{t('Warranty')}</SubTitle>
                <div>{formatCurrency(warrantyTotals.oneTime) || 'Free'}</div>
              </SubTitlesContainer>
            ) : null}
            <SubTitlesContainer>
              <SubTitle>{t('Activation Fees')}</SubTitle>
              <div>
                {activationFees ? formatCurrency(activationFees) : 'Free'}
              </div>
            </SubTitlesContainer>
            <SubTitlesContainer>
              <SubTitle>{t('Shipping Fees')}</SubTitle>
              <div>
                {orderQuery.data?.shippingInformation?.shippingCost
                  ? formatCurrency(
                      orderQuery.data.shippingInformation.shippingCost
                    )
                  : 'Free'}
              </div>
            </SubTitlesContainer>
            {productFees.oneTime ? (
              <SubTitlesContainer>
                <SubTitle>{t('Product Fees')}</SubTitle>
                <div>{formatCurrency(productFees.oneTime)}</div>
              </SubTitlesContainer>
            ) : null}
          </TotalDetails>
          {orderTotals?.yearlySubTotal !== 0 ? (
            <TotalDetails>
              <PaymentTitlesContainer>
                <TextSemiBold>{t('Yearly')}</TextSemiBold>
              </PaymentTitlesContainer>
              {warrantyTotals.yearly !== 0 ? (
                <SubTitlesContainer>
                  <SubTitle>{t('Warranty')}</SubTitle>
                  <div>{formatCurrency(warrantyTotals.yearly)}</div>
                </SubTitlesContainer>
              ) : null}
              {productFees.yearly !== 0 ? (
                <SubTitlesContainer>
                  <SubTitle>{t('Product Fees')}</SubTitle>
                  <div>
                    {productFees.yearly
                      ? formatCurrency(productFees.yearly)
                      : 'Free'}
                  </div>
                </SubTitlesContainer>
              ) : null}
            </TotalDetails>
          ) : null}
          <Divider />

          <TotalDetails>
            <PaymentTitlesContainer>
              <TextBold>{t('Due Today')}</TextBold>
              <TextBold>
                {orderTotals
                  ? formatCurrency(
                      (orderTotals.oneTimeSubTotal || 0) +
                        (orderTotals.yearlySubTotal || 0)
                    )
                  : 'Free'}
              </TextBold>
            </PaymentTitlesContainer>
          </TotalDetails>

          {orderTotals?.monthlySubTotal !== 0 ? (
            <>
              <TotalDetails seperate>
                <PaymentTitlesContainer>
                  <TextSemiBold>{t('Monthly')}</TextSemiBold>
                </PaymentTitlesContainer>
                {warrantyTotals.monthly !== 0 ? (
                  <SubTitlesContainer>
                    <SubTitle>{t('Warranty')}</SubTitle>
                    <div>{formatCurrency(warrantyTotals.monthly)}</div>
                  </SubTitlesContainer>
                ) : null}
                {productFees.monthly !== 0 ? (
                  <SubTitlesContainer>
                    <SubTitle>{t('Product Fees')}</SubTitle>
                    <div>
                      {productFees.monthly
                        ? formatCurrency(productFees.monthly)
                        : 'Free'}
                    </div>
                  </SubTitlesContainer>
                ) : null}
              </TotalDetails>

              <Divider />
              <TotalDetails>
                <PaymentTitlesContainer>
                  <TextBold>{t('Due Service Date')}</TextBold>
                  <TextBold>
                    {orderTotals?.monthlySubTotal
                      ? formatCurrency(orderTotals.monthlySubTotal)
                      : 'Free'}
                  </TextBold>
                </PaymentTitlesContainer>
              </TotalDetails>
            </>
          ) : null}
        </TotalsContent>
      </TotalsContainer>

      <DisclaimersContainer delay={delay()}>
        {[
          t(
            'I understand the price breakdown for monthly and one-time fees (if any)'
          ) + '.',
          t(
            'I understand that I will only be able to make changes until a shipment is ready'
          ) + '.',
          t(
            'I hereby authorize ECG to contact the Subscriber with a "Welcome Package" upon delivery'
          ) + '.',
        ].map((disclaimerText, index) => (
          <DisclaimerCheckbox
            key={index}
            onChange={(e) =>
              setDisclaimersAccepted((current) =>
                e.target.checked ? ++current : --current
              )
            }
            label={disclaimerText}
          />
        ))}
      </DisclaimersContainer>

      <Dialog
        open={confirmationDialogOpen && confirmOrder.isSuccess}
        title=""
        content={
          <ContentContainer>
            <IconContainer>
              <ConfirmationIcon type="check-marked" />
            </IconContainer>
            <ConfirmationHeader>
              {t('Your Order is Confirmed')}
            </ConfirmationHeader>
            <DetailsContainer>
              <GrayText>
                {`${t('Confirmation Code')}: `}
                <span>{orderQuery.data?.confirmationCode}</span>
              </GrayText>
              <GrayText>{t('Questions? Contact support@ecg-hq.com')}</GrayText>
            </DetailsContainer>
          </ContentContainer>
        }
        actions={
          <NavigationButton type="primary" to={`/orders/${orderId}`}>
            {t('Go to Order Details')}
          </NavigationButton>
        }
      />
    </div>
  )
}

export default Summary

const SummaryContainer = tw(ChildStepContainer)`pb-0 mb-6`

const TopTitle = tw.h3`text-lg font-medium flex-grow`

const Header = tw(
  FadeInSlideDown
)`flex justify-between border-b mb-3 items-center pb-1`

const Title = tw.h3`text-lg font-medium flex-grow mt-10`

const ManageButton = tw(Button)`self-end`

const PageTitle = tw.h3`text-xl font-semibold mb-2`

// Product Breakdown
const ProductBreakdown = tw.div``

const ProductItemGrid = tw(
  FadeInSlideDown
)`grid grid-template-columns[4rem 1.5fr 1fr] mb-6 max-w-2xl h-24`

const ProductImageContainer = tw.div`w-16 h-16 flex items-center justify-center border border-gray-300 shadow-lg rounded-full overflow-hidden`

const ProductIcon = tw(Icon)`w-12 h-12 text-gray-400`

const ProductImage = styled(motion.img)`
  object-fit: cover;
  ${() => tw`w-full h-full`}
`

const ProductDetailContainer = tw.div`ml-2 mt-2`

const ProductTitle = tw.p`font-bold text-gray-700`

const ProductDescription = tw.p`text-gray-400`

const ProductPriceContainer = tw(ProductDetailContainer)``

const ProductPrice = tw.p`text-lg font-semibold text-gray-700`

const ProductOneTimePrice = tw.p`text-gray-500`

// Order Totals Card
const TotalsContainer = tw(FadeInSlideDown)`relative max-w-lg mb-6`

const TotalsContent = tw.div`bg-white rounded border-t-4 border-blue-300 shadow-md p-4`

const TotalDetails = styled.div<{ seperate?: boolean }>(({ seperate }) => [
  tw`w-full mb-4`,
  seperate && tw`mt-8`,
])

const Divider = tw.div`h-0.5 w-full bg-gray-300`

const PaymentTitlesContainer = tw.div`flex flex-row text-2xl font-bold justify-between sticky`

const TextSemiBold = tw.div`text-lg font-medium text-gray-700`

const TextBold = tw.div`text-xl font-semibold text-gray-700`

const SubTitlesContainer = tw.div`flex flex-row text-gray-400 text-sm justify-between`

const SubTitle = tw.div`ml-4`

// Disclaimers
const DisclaimersContainer = tw(FadeInSlideDown)`mr-4 text-lg mb-4`

const DisclaimerCheckbox = tw(Checkbox)`mb-2`

// order confirmation dialog
const ContentContainer = tw.div`px-10`

const IconContainer = tw.div`flex justify-center mb-4`

const ConfirmationIcon = tw(Icon)`text-blue-400 text-7xl`

const ConfirmationHeader = tw.div`text-lg font-bold text-center mb-2`

const DetailsContainer = tw.div`text-sm text-center`

const GrayText = tw.div`text-gray-400`

const NavigationButton = tw(Button)`mx-auto`

// calculate the warranty totals for each interval
const warrantyBreakdown = (
  warranties: Array<WarrantyOption>,
  interval: 'OneTime' | 'Monthly' | 'Yearly'
) =>
  warranties
    .filter(
      (warranty) => warranty.priceDescriptor?.price?.interval === interval
    )
    .reduce(
      (accumulator, current) =>
        accumulator + (current.priceDescriptor?.price?.amount || 0),
      0
    )

// calculate product fees totals
const productFeesBreakdown = (
  orderLineItems: Array<OrderLineItem> | undefined | null,
  interval: 'Monthly' | 'Yearly' | 'OneTime'
) =>
  orderLineItems
    ?.filter(
      (lineItem) => lineItem.priceSelection?.productPrice?.interval === interval
    )
    .reduce(
      (accumulator, current) =>
        accumulator + (current.priceSelection?.productPrice?.amount || 0),
      0
    )

const formatCurrency = (fee: number) => numeral(fee).format('$0[.]00')
