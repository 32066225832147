import React, { useState } from 'react'
import tw from 'twin.macro'
import { FadeInSlideDown } from 'animations'
import { useTranslation } from 'react-i18next'
import { Icon } from 'elements'
import { Button, Empty, Tooltip } from 'atlas'
import { formatPrice } from 'utils'
import AddProductForm from './AddProductForm'
import ProductCard from './ProductCard'
import { useAPIQuery, useDelay } from 'hooks'

type LineItem = ContractLineItem['productTypeLineItem'] & {
  selectedProducts?: {
    id: string
    label: string
    selectedWarrantyId?: string | null | undefined
  }[]
}

type ProductTypeSectionProps = {
  lineItem: LineItem
  setLineItem: (lineItem: LineItem) => void
  delay: number
}

const ProductTypeSection = ({
  lineItem,
  setLineItem,
  delay,
}: ProductTypeSectionProps) => {
  const { t } = useTranslation()
  const delayFn = useDelay({ initial: delay })

  const [isProductTypeFormOpen, setIsProductTypeFormOpen] = useState(false)

  const productsQuery = useAPIQuery('products')

  const selectedProductIds = lineItem.selectedProducts?.map(
    (selectedProduct) => selectedProduct.id
  )

  return (
    <>
      <SectionContainer delay={delayFn()}>
        <Header>
          <LeftContent>
            <Title>{lineItem.descriptionInfo?.title}</Title>
            <Description>{lineItem.descriptionInfo?.description}</Description>
          </LeftContent>
          {lineItem.selectedProducts?.length ? (
            <EditProductsButton
              onClick={() => {
                setIsProductTypeFormOpen(true)
              }}
            >
              {t('Edit Products')}
            </EditProductsButton>
          ) : null}
        </Header>
        <PricingContainer>
          <Pricing>
            <ProductFee>
              <FeeTitle>
                {lineItem.pricing?.productFee?.descriptionInfo?.title ||
                  t('Product Fee')}
                {lineItem.pricing?.productFee?.descriptionInfo?.description ? (
                  <InfoTooltip
                    content={
                      <TooltipText>
                        {
                          lineItem.pricing.productFee.descriptionInfo
                            .description
                        }
                      </TooltipText>
                    }
                  >
                    <InfoIcon type="info" />
                  </InfoTooltip>
                ) : null}
              </FeeTitle>
              <FeePrice>
                {lineItem.groupPrice
                  ? lineItem.pricing?.productFee?.price
                    ? formatPrice(lineItem.pricing.productFee.price)
                    : null
                  : t('N/A (pricing per device)')}
              </FeePrice>
            </ProductFee>
            <ActivationFee>
              <FeeTitle>
                {lineItem.pricing?.activationFee?.descriptionInfo?.title ||
                  t('Activation Fee')}
                {lineItem.pricing?.activationFee?.descriptionInfo
                  ?.description ? (
                  <InfoTooltip
                    content={
                      <TooltipText>
                        {
                          lineItem.pricing.activationFee.descriptionInfo
                            .description
                        }
                      </TooltipText>
                    }
                  >
                    <InfoIcon type="info" />
                  </InfoTooltip>
                ) : null}
              </FeeTitle>

              <FeePrice>
                {lineItem.groupPrice
                  ? lineItem.pricing?.activationFee?.price
                    ? formatPrice(lineItem.pricing.activationFee.price)
                    : null
                  : t('N/A (pricing per device)')}
              </FeePrice>
            </ActivationFee>
          </Pricing>
        </PricingContainer>
      </SectionContainer>
      {lineItem.selectedProducts?.length ? (
        productsQuery.data?.items
          ?.filter(
            (product) => product.id && selectedProductIds?.includes(product.id)
          )
          .map((product) => (
            <ProductCard
              key={product.id}
              delay={delayFn()}
              lineItem={{
                ...lineItem,
                descriptionInfo: { title: product.title || '' },
                pricing: !lineItem.groupPrice
                  ? lineItem.pricing
                  : lineItem.pricing?.warrantySelection
                  ? { warrantySelection: lineItem.pricing.warrantySelection }
                  : undefined,
                productId: product.id,
                selectedWarrantyId:
                  lineItem.selectedProducts?.find(
                    (selectedProduct) => selectedProduct.id === product.id
                  )?.selectedWarrantyId || null,
              }}
              setLineItem={(newLineItem) =>
                setLineItem({
                  ...lineItem,
                  selectedProducts: lineItem.selectedProducts?.map(
                    (selectedProduct) =>
                      selectedProduct.id === product.id
                        ? {
                            ...selectedProduct,
                            selectedWarrantyId: newLineItem.selectedWarrantyId,
                          }
                        : selectedProduct
                  ),
                })
              }
            />
          ))
      ) : (
        <EmptyContainer delay={delayFn()}>
          <EmptyDisplay
            title={t('No Products Selected')}
            callToAction={
              <Button onClick={() => setIsProductTypeFormOpen(true)}>
                {t('Add Products')}
              </Button>
            }
          />
        </EmptyContainer>
      )}

      <AddProductForm
        isFormOpen={isProductTypeFormOpen}
        setIsFormOpen={setIsProductTypeFormOpen}
        productType={lineItem}
        setProductTypeSelectedProducts={(selectedProducts) =>
          setLineItem({ ...lineItem, selectedProducts })
        }
      />
    </>
  )
}

export default ProductTypeSection

const Header = tw.div`flex justify-between`

const Title = tw.div`text-xl font-semibold flex-grow mt-6`

const SectionContainer = tw(FadeInSlideDown)`mb-4`

const PricingContainer = tw.div`mt-2`

const LeftContent = tw.div`sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg 2xl:max-w-3xl`

const Description = tw.div`font-normal text-gray-600 truncate -mt-2`

const Pricing = tw.div`flex mt-2`

const ProductFee = tw.div`pr-4`

const ActivationFee = tw.div`px-4`

const FeeTitle = tw.div`text-sm text-gray-600 mr-4 leading-4 flex`

const FeePrice = tw.div``

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-4 h-4 -mt-0.5`

const InfoTooltip = tw(Tooltip)`ml-2 h-fit-content`

const TooltipText = tw.p`w-60`

const EditProductsButton = tw(Button)`h-fit-content self-end`

const EmptyDisplay = tw(Empty)`border-2 border-dashed rounded`

const EmptyContainer = tw(FadeInSlideDown)`flex-grow mt-2`
