import React from 'react'
import Chip, { colors } from 'atlas/Chip/Chip'
import { useTranslation } from 'react-i18next'

type OrganizationStatusChipProps = {
  status: string
}

const OrganizationStatusChip = ({ status }: OrganizationStatusChipProps) => {
  const { t } = useTranslation()

  return <Chip color={entityStatusToColor[status]}>{t(status)}</Chip>
}

export default OrganizationStatusChip

const entityStatusToColor: { [key: string]: keyof typeof colors } = {
  Active: 'green',
  Inactive: 'red',
  Archived: 'gray',
}
