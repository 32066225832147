import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { ArchivedModulesList } from 'components/access-control/modules'
import { useModulesQuery } from 'hooks/access-control/modules'

const ArchivedAccessControlModules = () => {
  const { data: accountModules } = useAccountPermissionsQuery()
  const { data: modules, isLoading: isLoadingModules } = useModulesQuery()

  const { t } = useTranslation()

  return (
    <Layout
      title={t('Archived Modules')}
      description={t('Restore, manage, or delete archived modules.')}
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Modules'), route: '/access-control/modules' },
        { name: t('Archived Modules') },
      ]}
      isLoading={isLoadingModules}
      isUnauthorized={
        !accountModules?.data.ecgAdmin.permissions.includes('acm.readonly')
      }
    >
      <ArchivedModulesList
        // Only show archived modules
        data={
          modules?.items?.filter((module) => module.archiveInfo.archived) || []
        }
      />
    </Layout>
  )
}

export default ArchivedAccessControlModules
