import React, { useEffect, useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { AutoComplete, EntityLabel, LoadingIcon } from 'elements'
import { SingleProps } from 'elements/AutoComplete'
import { useSubscriberQuery, useSubscribersQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import tw, { styled } from 'twin.macro'

type SubscriberAutoCompleteProps = {
  selectedSubscriber: string | SubscriberDisplay | null
  setSelectedSubscriber: (selectedSubscriber: SubscriberDisplay | null) => void
  organizationId?: string
  label?: string
  placeholder?: string
  className?: string
  disabled?: boolean
  error?: string
}

const SubscriberAutoComplete = ({
  selectedSubscriber,
  setSelectedSubscriber,
  organizationId,
  label,
  placeholder,
  className,
  disabled,
  error,
}: SubscriberAutoCompleteProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [options, setOptions] = useState<SubscriberDisplay[]>([])

  const subscribers = useSubscribersQuery({
    take: 5,
    searchTerm: debouncedSearchTerm,
    organizationId: organizationId || assumedOrganizationRole?.orgID,
  })

  const subscriberQuery = useSubscriberQuery(
    typeof selectedSubscriber === 'string' ? selectedSubscriber : ''
  )

  // debounce searchTerm value before querying subscriber autocomplete service
  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm)
    },
    500,
    [searchTerm]
  )

  // empty options array if debouncedSearchTerm is updated
  useEffect(() => {
    setOptions([])
  }, [debouncedSearchTerm])

  // update options array when a query is updated
  useEffect(() => {
    setOptions([
      ...(subscriberQuery.data ? [subscriberQuery.data] : []),
      ...(subscribers.data?.items?.filter(
        (subscriber) => subscriber.id !== subscriberQuery.data?.id
      ) || []),
    ])
  }, [subscribers.data, subscriberQuery.data])

  return (
    <BaseAutoComplete<React.FC<SingleProps<SubscriberDisplay>>>
      single
      label={label}
      placeholder={placeholder}
      optionLabel={(option) =>
        `${option.person.firstName} ${option.person.lastName}`
      }
      options={options}
      error={error}
      filterOptions={(options) => options}
      selectedOption={subscriberQuery.data}
      noOptionsText={
        subscribers.data?.items?.length === 0 ? (
          t('No Subscribers Found')
        ) : (
          <LoadingContainer>
            <LoadingIcon width="30px" height="30px" />
          </LoadingContainer>
        )
      }
      onChange={(newSelectedOption) => {
        setOptions(
          newSelectedOption ? [newSelectedOption, ...options] : options
        )
        setSelectedSubscriber(newSelectedOption)
      }}
      inputValue={searchTerm}
      onInputValueChange={(newSearchTerm) => {
        setSearchTerm(newSearchTerm)
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <EntityLabel
            id={option.id}
            name={`${option.person.firstName} ${option.person.lastName}`}
          />
        </li>
      )}
      className={className}
      disabled={disabled}
    />
  )
}

export default SubscriberAutoComplete

const BaseAutoComplete = styled(AutoComplete)<{ className?: string }>(
  ({ className }) => ['min-width: 12rem;', className]
)

const LoadingContainer = tw.div`flex justify-center py-2`
