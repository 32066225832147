import React, { useMemo } from 'react'
import { Layout } from 'elements'
import { Button, Empty } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTranslation } from 'react-i18next'
import { useAlertDeliveryRulesQuery } from 'hooks/alert-delivery-rules'
import tw from 'twin.macro'
import { AlertDeliveryRulesList } from 'components/settings-alert-delivery-rules'
import { useAlertCategoryQuery } from 'hooks/alerts'
import { useStaffListQuery } from 'hooks/user-staff'

const AlertDeliveryRules = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const alertCategory = useAlertCategoryQuery()
  const alertDeliveryRulesQuery = useAlertDeliveryRulesQuery()
  const staffListQuery = useStaffListQuery(assumedOrganizationRole?.orgID)

  const alertDeliveryRules = useMemo<AlertDeliveryRule[]>(
    () => alertDeliveryRulesQuery.data?.items || [],
    [alertDeliveryRulesQuery.data]
  )

  return (
    <>
      <Layout
        title="Alert Delivery Rules"
        description={t(
          'Manage when and who in your organization is contacted when specific alerts are generated.'
        )}
        breadcrumbs={[
          { name: t('Organization Settings'), route: '/organization-settings' },
          { name: t('Alert Delivery Rules') },
        ]}
        isUnauthorized={!assumedOrganizationRole}
        isLoading={staffListQuery.isLoading || alertCategory.isLoading}
        isEmpty={!staffListQuery.data?.items?.length}
        emptyComponent={
          <DeliveryRulesEmpty
            title={t('No Data Found')}
            description={t(
              'Staff members are required to specify recipients of alert delivery rules'
            )}
            callToAction={
              <Button type="primary-filled" to="/staff">
                {t('Go To Staff Members Page')}
              </Button>
            }
          />
        }
        controls={
          alertDeliveryRules.length ? (
            <Button type="primary-filled" to="create-alert-delivery-rule">
              {t('Create Alert Delivery Rule')}
            </Button>
          ) : null
        }
      >
        {alertDeliveryRules.length ? (
          <AlertDeliveryRulesList
            alertDeliveryRules={alertDeliveryRules}
            alertCategories={alertCategory.data?.items || []}
          />
        ) : (
          <BorderedEmpty
            title={t('No Data Found')}
            description={t(
              'No alert delivery rules have been created for this organization'
            )}
            callToAction={
              <Button type="primary-filled" to="create-alert-delivery-rule">
                {t('Create Alert Delivery Rule')}
              </Button>
            }
          />
        )}
      </Layout>
      <div />
    </>
  )
}

export default AlertDeliveryRules

const BorderedEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`

const DeliveryRulesEmpty = tw(Empty)`flex-grow`
