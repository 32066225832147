import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const usePeopleQuery = ({ personId }: { personId: string }) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/people/{personId}'],
    KyError
  >(['getPeople', personId], () => getPeople(personId), {
    enabled: !!personId,
  })

  return query
}

export default usePeopleQuery

const getPeople = (personId: string) =>
  typedOrgsApi.get(`/api/people/{personId}`, {
    pathParams: { personId },
  })
