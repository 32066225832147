import React, { useState } from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDelay, useNavigate } from 'hooks'
import { FadeInSlideDown } from 'animations'
import { useSubscriberQuery } from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { SubscriberSelect } from 'components/subscribers'
import { AssumeRoleDialog } from 'components/organizations'
import {
  ResponsiblePartiesInfoCard,
  SubscriberInfoCard,
  SubscriberMetadata,
} from 'components/subscriber-info'
import tw from 'twin.macro'
import { useSubscriberDetailsQuery } from 'hooks/user-subscriber'

const SubscriberInformation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const delay = useDelay()

  const [isAssumeRoleDialogOpen, setIsAssumeRoleDialogOpen] = useState<boolean>(
    false
  )

  const { subscriberId } = useParams()

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)
  const subscriberDetails = useSubscriberDetailsQuery(subscriberId)

  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  return (
    <Layout
      isLoading={isSubLoading}
      title={
        <TitleContainer>
          <Title>{t('Subscriber Information')}:</Title>
          <SubscriberSelect />
        </TitleContainer>
      }
      isEmpty={!currentSubscriber || !subscriberDetails.data}
      emptyComponent={<Empty title={t('Subscriber Not Found')} />}
      controls={[
        {
          label: t('Edit Subscriber'),
          onClick: () => {
            if (!assumedOrganizationRole) {
              setIsAssumeRoleDialogOpen(true)
            } else navigate('edit-subscriber')
          },
        },
      ]}
      description={t('View Subscriber Information')}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '/subscribers',
        },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `/subscribers/${subscriberId}`,
        },
        {
          name: t('Subscriber Information'),
        },
      ]}
    >
      <InformationCards>
        <SubscriberInfoCardContainer delay={delay()}>
          <SubscriberInfoTitle>{t('General Information')}</SubscriberInfoTitle>
          <SubscriberInfoCard
            subscriberInfo={{
              ...currentSubscriber,
              // @ts-expect-error layout guards this
              person: {
                ...currentSubscriber?.person,
                ...subscriberDetails.data?.personalInformation,
              },
            }}
          />
        </SubscriberInfoCardContainer>
        <SubscriberMetadataContainer delay={delay()}>
          <SubscriberMetadata
            metadata={currentSubscriber?.integrationMetadata}
          />
        </SubscriberMetadataContainer>
        <ResponsiblePartiesInfoCardContainer delay={delay()}>
          {/* @ts-expect-error layout with guard this */}
          <ResponsiblePartiesInfoCard subscriberInfo={currentSubscriber} />
        </ResponsiblePartiesInfoCardContainer>
      </InformationCards>

      <AssumeRoleDialog
        organization={currentSubscriber?.organization}
        isOpen={isAssumeRoleDialogOpen}
        onCancel={() => setIsAssumeRoleDialogOpen(false)}
        onSuccess={() => {
          navigate('edit-subscriber')
          setIsAssumeRoleDialogOpen(false)
        }}
      >
        <p>
          {t(
            "To edit subscriber information you must assume the organization's role"
          )}
        </p>
      </AssumeRoleDialog>
    </Layout>
  )
}
export default SubscriberInformation

const TitleContainer = tw.div`flex gap-2`

const SubscriberInfoTitle = tw.h2`text-2xl font-semibold mb-2`

const Title = tw.h1`text-2xl font-bold`

const InformationCards = tw.div`grid grid-cols-3 gap-4`

const SubscriberInfoCardContainer = tw(FadeInSlideDown)`col-span-3`

const SubscriberMetadataContainer = tw(
  FadeInSlideDown
)`col-span-3 xl:col-span-1 mb-4`

const ResponsiblePartiesInfoCardContainer = tw(
  FadeInSlideDown
)`col-span-3 xl:col-span-2 mb-4`
