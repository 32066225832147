import React from 'react'
import { LoadingIcon, RightPopup, TextField, Icon } from 'elements'
import { Button } from 'atlas'
import _ from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import {
  useUpdateReminderMessageTranslationsMutation,
  useReminderMessagesQuery,
  useReminderMessageTranslationsQuery,
} from 'hooks/reminders'
import { useTranslation } from 'react-i18next'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'
import { isPresent } from 'utils'

type OrganizationEditTranslationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedTranslation: { key: string; name: string }
}

const OrganizationEditTranslationForm = ({
  isFormOpen,
  setIsFormOpen,
  selectedTranslation,
}: OrganizationEditTranslationFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const formMethods = useForm<EditTranslationForm>()

  const reminderMessages = useReminderMessagesQuery(
    assumedOrganizationRole?.orgID || ''
  )
  const reminderMessageTranslations = useReminderMessageTranslationsQuery(
    assumedOrganizationRole?.orgID || '',
    selectedTranslation.key
  )

  const updateReminderMessageTranslation = useUpdateReminderMessageTranslationsMutation()

  const { handleSubmit, errors, register } = formMethods

  if (!isPresent(selectedTranslation)) return null

  return (
    <RightPopup
      title={`${t('Edit Translation')} - ${selectedTranslation.name}`}
      open={isFormOpen}
      setOpen={setIsFormOpen}
      width="800px"
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={handleSubmit(async (formData) => {
              // structure reminders

              updateReminderMessageTranslation.mutate({
                orgId: assumedOrganizationRole?.orgID || '',
                localeKey: selectedTranslation.key,
                messages: _.map(formData, (value, key) => {
                  return {
                    messageTypeId: key,
                    message: value,
                    reminderMessageId: reminderMessageTranslations.data?.items?.find(
                      (message) => message.messageType.id === key
                    )?.id,
                  }
                }),
              })

              // Close form drawer
              setIsFormOpen(false)
            })}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {reminderMessages.isLoading || reminderMessageTranslations.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <InputContainer>
            {_.orderBy(
              reminderMessages.data?.items,
              (reminderMessages) => reminderMessages.messageType.displayOrder
            ).map((item, index) => (
              <TextField
                key={index}
                disabled={true}
                name={`${index}-disabled`}
                // Change label to error message if one exists
                label={item.messageType.name}
                defaultValue={`${item.message}`}
                fullWidth
                className="col-span-4 row-span-1 self-start"
                helperText={item.messageType.description}
              />
            ))}

            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />
            <RightIcon type="chevron-right" />

            {_.orderBy(
              reminderMessageTranslations.data?.items,
              (translation) => translation.messageType.displayOrder
            ).map((item, index) => (
              <TextField
                key={`${index}`}
                error={errors[item.messageType.name || index]?.message}
                name={item.messageType.id}
                inputRef={register({
                  required: `${item.messageType.name} ${t(
                    'message is required'
                  )}`,
                })}
                // Change label to error message if one exists
                label={item.messageType.name}
                defaultValue={`${item.message}`}
                fullWidth
                className="col-span-4 row-span-1 self-start"
                required
              />
            ))}
          </InputContainer>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default OrganizationEditTranslationForm

const InputContainer = tw.div`grid grid-cols-9 grid-flow-col grid-rows-5 justify-items-center items-center mb-4`

const RightIcon = tw(Icon)`w-6 h-6 transform -translate-y-8`
