import React, { useMemo, useCallback, useState } from 'react'
import {
  List,
  EntityLabel,
  ConfirmationDialogs,
  EllipsisDropdown,
  entityStatusToColor,
} from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  TableInstance,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import CategoryFilter from 'elements/CategoryFilter'
import { useEntityLabelFilter, useEntityLabelSort } from 'hooks/react-table'
import {
  useDeletePermissionMutation,
  useRestorePermissionMutation,
} from 'hooks/access-control/permissions'
import tw from 'twin.macro'

type ArchivedPermissionsListProps = {
  data: Permission[]
}

const ArchivedPermissionsList = ({ data }: ArchivedPermissionsListProps) => {
  const { t } = useTranslation()
  const {
    getEntityLabels,
    filterRowsByEntityLabel,
  } = useEntityLabelFilter<Permission>()

  const entityLabelSort = useEntityLabelSort<Permission>({
    accessor: (row) => row.module.name,
  })

  const tableData: Permission[] = useMemo(() => {
    return data
  }, [data])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Permission>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const code = row.original.code.toLowerCase()
        const description = row.original.description.toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          code.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<Permission>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.name}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortBy: useEntityLabelSort,
      },
      {
        id: 'MODULE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('MODULE'),
        accessor: (row) => (
          <EntityLabel
            name={row.module.name || ''}
            id={row.module.id || ''}
            to={`/access-control/modules/${row.module.id}`}
            statusTag={entityStatusToColor({
              isActive: row.module.activeInfo.active,
              isArchived: row.module.archiveInfo.archived,
            })}
          />
        ),
        Filter: (tableInstance: TableInstance<Permission>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="module"
            label={t('Module')}
            categories={getEntityLabels({
              tableInstance,
              accessor: (row) => row.module.name,
            })}
          />
        ),
        filter: filterRowsByEntityLabel,
        sortType: entityLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: () => <Chip color="gray">{t('Archived')}</Chip>,
        width: '80px',
        disableSortBy: true,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => <ActionsCell row={row} />,
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'permissions'
      )}`}
      emptyTitle={t('No Permissions Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default ArchivedPermissionsList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: Permission
}

const ActionsCell = ({ row }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const { mutate: deletePermission } = useDeletePermissionMutation()
  const { mutate: restorePermission } = useRestorePermissionMutation()

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${row.name}" permission`),
      content: <p>{t('Are you sure you want to restore this permission?')}</p>,
      actions: (
        <Button
          onClick={() => {
            restorePermission(row.id)
            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${row.name}" permission`),
      content: <p>{t('Are you sure you want to delete this permission?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deletePermission(row.id)
            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Restore'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsRestoreDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
          {
            label: t('Delete'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDeleteDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
