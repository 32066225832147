import React from 'react'
import Icon from '../../elements/Icon'
import { FadeIn } from 'animations'
import tw, { styled } from 'twin.macro'

export type EmptyProps = AnimationProps & {
  /**
   * Text used for the title.
   */
  title?: string

  /**
   * Text used for the description.
   */
  description?: string

  /**
   * Appends to the className of the outer-most element of the component. *Note: never directly use this prop, always opt for styled-components.*
   */
  className?: string

  /**
   * React component rendered as a "call to action" at the end bottom of the Empty.
   */
  callToAction?: React.ReactNode
}

// delay and disableAnimations can't co-exist
type AnimationProps =
  | {
      /**
       * The animation delay in seconds.
       */
      delay?: number
      disableAnimations?: never
    }
  | {
      delay?: never

      /**
       * If **true**, animations will not be played and the delay prop will be ignored.
       */
      disableAnimations?: boolean
    }

const Empty = ({
  className,
  delay,
  title,
  description,
  disableAnimations,
  callToAction,
}: EmptyProps) => {
  return (
    <Container delay={delay} className={className} disable={disableAnimations}>
      <EmptyIconBackground>
        <EmptyIcon type="empty" />
      </EmptyIconBackground>
      <Title>{title}</Title>
      {typeof description === 'string' ? (
        <Description>{description}</Description>
      ) : (
        description
      )}
      {callToAction}
    </Container>
  )
}

export default Empty

const Container = styled(FadeIn)<{ className?: string }>`
  ${tw`w-full h-full flex justify-center items-center text-center flex-col py-6`}
  ${({ className }) => className}
`

const EmptyIconBackground = tw.div`bg-gray-300 p-2 rounded-full mb-2`

const EmptyIcon = tw(Icon)`h-7 w-7 text-gray-600`

const Title = tw.h5`text-lg`

const Description = tw.p`text-gray-600 mb-2`
