import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useUpdateSubscriberVitalThresholdMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds'],
    KyError<ErrorResponse>,
    UpdateVitalThresholdsProps,
    | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']
    | undefined
  >(updateSubscriberVitalThreshold, {
    onMutate: (mutationProps) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']
      >(['vitalsThresholds', mutationProps.subscriberId])

      // optimistic update the subscriber vital thresholds query
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']['items']
      >(
        ['vitalsThresholds', mutationProps.subscriberId],
        (oldCache) =>
          // Replace old threshold that was updated from oldCache
          oldCache?.map((thresholds) =>
            thresholds.vitalSign.id === mutationProps.vitalSign.id
              ? thresholdsFormToObject(mutationProps)
              : thresholds
          ) || []
      )

      return snapshot
    },
    onSuccess: () => {
      // Notify user of successful vital sign creation
      success({
        message: t('Vital Thresholds updated'),
      })
    },
    onError: async (error, { subscriberId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']
        | undefined
      >(['vitalsThresholds', subscriberId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { subscriberId }) => {
      // refetch from API to make sure the new threshold is in sync with the server
      queryCache.invalidateQueries(['vitalsThresholds', subscriberId])
    },
  })

  return mutation
}

export default useUpdateSubscriberVitalThresholdMutation

type UpdateVitalThresholdsProps = {
  vitalThresholds: VitalThresholdsForm
  orgId: string
  subscriberId: string
  vitalSign: VitalAssignment
}

// Post updated vital sign to backend
const updateSubscriberVitalThreshold = async ({
  vitalThresholds,
  subscriberId,
  vitalSign,
}: UpdateVitalThresholdsProps) => {
  const request: VitalsAPIRequest['PATCH']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']['body'] = {
    thresholds: vitalThresholds.thresholds,
    normalLow: vitalThresholds.normalLow || 0,
    normalHigh: vitalThresholds.normalHigh || 0,
    acceptedDisclaimers: vitalThresholds.acceptedDisclaimers,
  }

  const result = await vitalsApi
    .patch(
      `subscribers/${subscriberId}/vital-signs/${vitalSign.id}/thresholds`,
      {
        json: request,
      }
    )
    .json<
      VitalsAPIResponse['PATCH']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']
    >()

  return result
}

const thresholdsFormToObject = ({
  vitalThresholds,
  vitalSign,
  orgId,
}: UpdateVitalThresholdsProps): VitalThresholds => ({
  organizationId: orgId,
  // @ts-expect-error TODO: fix this type
  vitalSign,
  thresholds: vitalThresholds.thresholds,
  normalHigh: vitalThresholds.normalHigh || 0,
  normalLow: vitalThresholds.normalLow || 0,
  disclaimers: vitalThresholds.acceptedDisclaimers,
})
