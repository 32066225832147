import React, { useEffect, useState } from 'react'
import {
  ECGAdminVitalSignsGrid,
  ECGAdminCreateUpdateVitalSignsForm,
} from 'components/ecg-admin-vital-signs'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import { Layout, Unauthorized } from 'elements'
import { useNavigate } from 'hooks'

const ECGAdminVitalSigns = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isCreateUpdateFormOpen, setIsCreateUpdateFormOpen] = useState<boolean>(
    false
  )
  const [vitalSignToEdit, setVitalSignToEdit] = useState<VitalSign | null>(null)

  const editVitalSign = (vitalSign: VitalSign) => {
    setIsCreateUpdateFormOpen(true)
    setVitalSignToEdit(vitalSign)
  }

  // Reset the vital sign being edited to null if the form closes
  useEffect(() => {
    if (!isCreateUpdateFormOpen) setVitalSignToEdit(null)
  }, [isCreateUpdateFormOpen])

  // ECG Admin case
  if (
    accountPermissions?.data.ecgAdmin.permissions.includes(
      'vitals.settings.readonly'
    )
  )
    return (
      <Layout
        title={t('Vital Signs')}
        description={t(
          'Create and manage vital signs available to onboarded organizations.'
        )}
        breadcrumbs={[
          { name: t('Vitals'), route: '/vitals' },
          { name: t('Vital Signs') },
        ]}
        controls={[
          // vitals.settings.admin as an ECG Admin is required to create vital signs
          ...(accountPermissions.data.ecgAdmin.permissions.includes(
            'vitals.settings.admin'
          )
            ? [
                {
                  label: t('Create Vital Sign'),
                  onClick: () => setIsCreateUpdateFormOpen(true),
                },
              ]
            : []),
          {
            label: t('View Archived Vital Signs'),
            onClick: () => navigate('archive'),
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
          },
        ]}
      >
        <ECGAdminCreateUpdateVitalSignsForm
          isFormOpen={isCreateUpdateFormOpen}
          setIsFormOpen={setIsCreateUpdateFormOpen}
          existingVitalSign={vitalSignToEdit}
        />
        <ECGAdminVitalSignsGrid openEditForm={editVitalSign} />
      </Layout>
    )

  return (
    <Layout
      title={t('Vital Signs')}
      breadcrumbs={[
        { name: t('Vitals'), route: '/vitals' },
        { name: t('Vital Signs') },
      ]}
    >
      <Unauthorized />
    </Layout>
  )
}

export default ECGAdminVitalSigns
