import React, { useEffect, useState } from 'react'
import { Button, Empty } from 'atlas'
import { RightPopup, Icon, LoadingIcon } from 'elements'
import { useTranslation } from 'react-i18next'
import { useCreatePartnerTypeCommissionMutation } from 'hooks/partners'
import tw from 'twin.macro'
import ProductCommissionForm from './ProductCommissionForm'
import { useProductsQuery } from 'hooks/seed-data'

type CreatePartnerTypeCommissionsFormProps = {
  currentPartnerType: PartnerType
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  existingCommissions: Commission[]
}

const CreatePartnerTypeCommissionsForm = ({
  currentPartnerType,
  isFormOpen,
  setIsFormOpen,
  existingCommissions,
}: CreatePartnerTypeCommissionsFormProps) => {
  const { t } = useTranslation()

  const [commissionIndexToEdit, setCommissionIndexToEdit] = useState<
    number | undefined
  >()
  const [productCommissions, setProductCommissions] = useState<
    CommissionForm[]
  >([])
  const [productsWithNoCommissions, setProductsWithNoCommissions] = useState<
    // TODO: migrate to new Product type
    { id: string; name: string }[]
  >([])

  const productList = useProductsQuery()

  const createPartnerTypeCommissions = useCreatePartnerTypeCommissionMutation()

  // update available products to add commissions to whenever commissions or products changes
  useEffect(() => {
    setProductsWithNoCommissions(
      productList.data?.items?.filter(
        (product) =>
          ![...productCommissions, ...existingCommissions].find(
            (commission) => commission.productId === product.id
          )
      ) || []
    )
  }, [productList.data, productCommissions, existingCommissions])

  // reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      setProductCommissions([])
    }
  }, [isFormOpen, productList.data])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Create Direct Commissions')}
      description={currentPartnerType.name}
      width="600px"
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={createPartnerTypeCommissions.isLoading}
            disabled={
              // disabled if no product commissions created and no HCO override
              !productCommissions.length ||
              // disabled if editing a commission
              commissionIndexToEdit !== undefined
            }
            onClick={() => {
              createPartnerTypeCommissions.mutate(
                {
                  partnerType: currentPartnerType,
                  commissionsForm: productCommissions,
                },
                // close form drawer
                { onSuccess: () => setIsFormOpen(false) }
              )
            }}
          >
            {t('Save')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={createPartnerTypeCommissions.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {productList.isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <NewCommissionsTitle>
            {t('Create New Product Commission')}
          </NewCommissionsTitle>
          {productsWithNoCommissions.length ? (
            <ProductCommissionForm
              isModalOpen={isFormOpen}
              isFormOpen={true}
              productCommissions={productCommissions}
              setProductCommissions={setProductCommissions}
              productsWithNoCommissions={productsWithNoCommissions}
            />
          ) : (
            <BorderedEmpty title="No New Products to Add" />
          )}
          <ExistingCommissionsTitle>
            {t('Existing Product Commissions')}
          </ExistingCommissionsTitle>
          {productCommissions.length ? (
            productCommissions.map((productCommission, index) => (
              <CommissionCard key={index}>
                <CommissionCardContent>
                  <LeftContent>
                    <CardTitle>
                      {productList.data?.items?.find(
                        (product) => product.id === productCommission.productId
                      )?.name || ''}
                    </CardTitle>
                    <CardCommissionsContainer>
                      {productCommission.oneTimeCommission.allowed ? (
                        <ActivationCommissionContainer>
                          <p>{`${t('One-Time')}:`}</p>&nbsp;
                          <ActivationCommissionValue>
                            {`$${productCommission.oneTimeCommission.commissionAmount}`}
                          </ActivationCommissionValue>
                        </ActivationCommissionContainer>
                      ) : null}
                      {productCommission.recurringCommission.allowed ? (
                        <RecurringCommissionContainer>
                          <p>{`${t('Recurring')}:`}</p>&nbsp;
                          <RecurringCommissionValue>
                            {`$${productCommission.recurringCommission.commissionAmount}/mo`}
                          </RecurringCommissionValue>
                        </RecurringCommissionContainer>
                      ) : null}
                    </CardCommissionsContainer>
                  </LeftContent>
                  {commissionIndexToEdit === undefined ? (
                    <RightContent>
                      <EditIcon
                        type="edit"
                        data-testid={`commission-${index}-edit`}
                        onClick={() => setCommissionIndexToEdit(index)}
                      />
                      &nbsp;&nbsp;
                      <TrashIcon
                        type="trash"
                        data-testid={`commission-${index}-delete`}
                        onClick={() =>
                          // remove this product commission from list
                          setProductCommissions(
                            productCommissions.filter(
                              (productCom) =>
                                productCommission.productId !==
                                productCom.productId
                            )
                          )
                        }
                      />
                    </RightContent>
                  ) : null}
                </CommissionCardContent>
                <ProductCommissionForm
                  isModalOpen={isFormOpen}
                  isFormOpen={commissionIndexToEdit === index}
                  productCommissions={productCommissions}
                  setProductCommissions={setProductCommissions}
                  productsWithNoCommissions={productsWithNoCommissions}
                  productCommissionToEdit={productCommission}
                  setIsFormOpen={() => {
                    setCommissionIndexToEdit(undefined)
                  }}
                />
              </CommissionCard>
            ))
          ) : (
            <BorderedEmpty title={t('No Product Commissions Created')} />
          )}
        </>
      )}
    </RightPopup>
  )
}

export default CreatePartnerTypeCommissionsForm

const BorderedEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`

const NewCommissionsTitle = tw.h3`text-lg font-medium mb-2 `

const ExistingCommissionsTitle = tw.h3`text-lg font-medium mb-2 mt-4`

const CommissionCard = tw.div`bg-white px-4 py-2 rounded border border-gray-200 h-full mb-2`

const CommissionCardContent = tw.div`flex justify-between`

const LeftContent = tw.div``

const CardTitle = tw.h4`self-start text-lg font-medium`

const CardCommissionsContainer = tw.div`flex`

const ActivationCommissionContainer = tw.div`flex mr-2`

const ActivationCommissionValue = tw.div`text-center`

const RecurringCommissionContainer = tw.div`flex`

const RecurringCommissionValue = tw.div`text-center`

const RightContent = tw.div`self-center mt-1.5`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500 hover:text-red-600`
