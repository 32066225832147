import React from 'react'
import clsx from 'clsx'
import 'styles/loading-icon.css'
import tw, { styled } from 'twin.macro'

type LoadingIconProps = {
  height?: string
  width?: string
  className?: string
  fluid?: boolean
}

const LoadingIcon = ({
  height = '100%',
  className,
  width,
  fluid,
}: LoadingIconProps) => {
  return (
    <div
      className={clsx(['loading-spinner-container flex-grow', className])}
      role="alert"
      aria-label="loading"
      style={{ height, ...(width ? { width } : {}) }}
    >
      <FoldingCube fluid={!!fluid} className="sk-folding-cube">
        <div className="sk-cube1 sk-cube" />
        <div className="sk-cube2 sk-cube" />
        <div className="sk-cube4 sk-cube" />
        <div className="sk-cube3 sk-cube" />
      </FoldingCube>
    </div>
  )
}

export default LoadingIcon

const FoldingCube = styled.div<{ fluid: boolean }>`
  margin: 20px auto;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  ${({ fluid }) => (fluid ? tw`w-full h-full` : tw`w-10 h-10`)}
`
