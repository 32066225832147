import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { ArchivedWebhooksList } from 'components/settings-webhooks'
import { useWebhookSubscriptionsQuery } from 'hooks/settings-webhooks'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const ArchivedWebhooks = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const {
    data: webhooks,
    isLoading: isLoadingWebhooks,
  } = useWebhookSubscriptionsQuery()

  const { t } = useTranslation()

  return (
    <Layout
      title={t('Archived Webhooks')}
      description={t('Restore, manage, or delete archived webhooks.')}
      breadcrumbs={[
        { name: t('Organization Settings'), route: '/organization-settings' },
        { name: t('Webhooks'), route: '/organization-settings/webhooks' },
        { name: t('Archived Webhooks') },
      ]}
      isLoading={isLoadingWebhooks}
      isUnauthorized={!assumedOrganizationRole}
    >
      <ArchivedWebhooksList
        // Only show archived webhooks
        data={
          webhooks?.items.filter((webhook) => webhook.archiveInfo.archived) ||
          []
        }
      />
    </Layout>
  )
}

export default ArchivedWebhooks
