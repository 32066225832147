import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useDeletePhoneMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/subscribers-profile/{subscriberId}/phone-numbers/{phoneNumberId}'],
    DeletePhoneProps
  >({
    mutationFn: deleteSubscriberPhone,
    successMsg: t('Phone Number deleted'),
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => ['getSubscriberPhones', subscriberId],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/phone-numbers'],
          DeletePhoneProps
        >((oldCache, { phoneNumberId }) =>
          oldCache?.filter((phone) => phone.id !== phoneNumberId)
        ),
      },
    ],
  })

  return mutation
}

export default useDeletePhoneMutation

type DeletePhoneProps = {
  phoneNumberId: string
  subscriberId: string
}

const deleteSubscriberPhone = ({
  phoneNumberId,
  subscriberId,
}: DeletePhoneProps) =>
  typedOrgsApi.delete(
    '/subscribers-profile/{subscriberId}/phone-numbers/{phoneNumberId}',
    { pathParams: { phoneNumberId, subscriberId } }
  )
