import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { success, orgsApi, handleQueryError } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const useDeleteOrganizationMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()
  const { discardAssumedOrganizationRole } = useAssumedOrganizationRole()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/api/organizations/{organizationId}'],
    KyError,
    deleteOrganizationArgs,
    OrgsAPIResponse['GET']['/api/organizations'] | undefined
  >(deleteOrganization, {
    onMutate: ({ organization }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        OrgsAPIResponse['GET']['/api/organizations']
      >(['getOrganizationList', null])

      // optimistic update
      queryCache.setQueryData<
        OrgsAPIResponse['GET']['/api/organizations'] | undefined
      >(['getOrganizationList'], (old) =>
        old
          ? {
              ...old,
              items: old.items?.filter((org) => org.id !== organization.id),
            }
          : undefined
      )

      return snapshot
    },
    onSuccess: () => {
      // discard the assumed role
      discardAssumedOrganizationRole({
        showToast: false,
        navigate: 'organizations',
      })

      // notify the user
      success({ message: t('Organization deleted') })
    },
    onError: async (error, __, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        OrgsAPIResponse['GET']['/api/organizations'] | undefined
      >(['getOrganizationList'], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      queryCache.invalidateQueries(['getOrganization', args.organization.id])
      queryCache.invalidateQueries(['getOrganizationList'])
    },
  })

  return mutation
}

export default useDeleteOrganizationMutation

type deleteOrganizationArgs = {
  organization: Organization
}

const deleteOrganization = async ({ organization }: deleteOrganizationArgs) => {
  const result = orgsApi
    .delete(`api/organizations/${organization.id}`)
    .json<OrgsAPIResponse['DELETE']['/api/organizations/{organizationId}']>()

  return result
}
