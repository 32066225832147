import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { Empty } from 'atlas'
import clsx from 'clsx'
import { LoadingIcon } from 'elements'
import { useDelay } from 'hooks'
import _ from 'lodash'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useTranslation } from 'react-i18next'
import { TableInstance } from 'react-table'
import { useDebounce, useMeasure, useUpdateEffect } from 'react-use'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import tw, { styled } from 'twin.macro'
import Icon from './Icon'
import ListPaginationControls from './Table/ListPaginationControls'
import SearchBox from './Table/SearchBox'
import { isPresent } from 'utils'

type ListProps<T extends Record<string, unknown>> = {
  // TODO: when refactoring List make sure that any isn't needed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableInstance: TableInstance<any>
  searchPlaceholder?: string
  setSearchTerm?: (searchTerm: string) => void
  searchTerm?: string
  emptyTitle?: string
  emptyDescription?: string
  baseDelay?: number
  isLoading?: boolean
  controls?: React.ReactNode
  selectedControls?: React.ReactNode
  paginatable?: boolean
  paginationInfo?: {
    recordsPerPage?: number
    totalRecords?: number
    currentPageNumber?: number
    totalPages?: number
  }
  onPageChange?: (newPage: number) => void
  urlState?: T
  setUrlState?: (newState: T) => void
}

const List = <
  T extends Record<string, unknown> & {
    orderBy?: 'ASC' | 'DESC' | undefined
    orderByColId?: string | undefined
  } = Record<string, unknown> & {
    orderBy?: 'ASC' | 'DESC' | undefined
    orderByColId?: string | undefined
  }
>({
  tableInstance,
  searchPlaceholder,
  setSearchTerm,
  searchTerm,
  emptyTitle,
  emptyDescription,
  baseDelay = 0.1,
  isLoading,
  controls,
  selectedControls,
  paginatable,
  paginationInfo,
  onPageChange,
  urlState,
  setUrlState,
}: ListProps<T>) => {
  const { t } = useTranslation()
  const delay = useDelay({ initial: baseDelay })

  const [localSearchTerm, setLocalSearchTerm] = useState<string>(
    searchTerm || ''
  )

  // generate the grid-template-columns css attribute based off the input
  const gridTemplateColumns = useMemo(() => {
    return tableInstance.columns
      .map((column) => {
        return typeof column.width === 'string' ? column.width : '1fr'
      })
      .join(' ')
  }, [tableInstance.columns])

  // function used to render a single row
  const RenderRow = useCallback(
    ({ index: rowIndex, style }) => {
      const row = tableInstance.rows[rowIndex]

      // don't render filtered out rows
      if (!isPresent(row)) return null

      tableInstance.prepareRow(row)

      return (
        <Row
          {...row.getRowProps({
            style: {
              ...style,
              gridTemplateColumns,
            },
          })}
          key={`data-row-${rowIndex}`}
          rowIndex={rowIndex}
          className={clsx(
            row.isSelected ? 'bg-blue-50' : undefined,
            'border-b border-gray-200',
            tableInstance.onRowClick && 'hover:bg-blue-50 cursor-pointer'
          )}
          onClick={() =>
            tableInstance.onRowClick ? tableInstance.onRowClick(row) : undefined
          }
        >
          {row.cells.map((cell, columnIndex) => {
            return (
              <Cell
                {...cell.getCellProps()}
                // prevent cells from taking up more space then they should
                style={{ minWidth: '0px' }}
                key={`data-cell-${rowIndex}-${columnIndex}`}
                data-testid={`${cell.column.id}-data-cell`}
                title={
                  cell.value?.length && cell.value.length > 85
                    ? cell.value
                    : undefined
                }
                isString={typeof cell.value === 'string'}
              >
                {(() => {
                  // if the cell is empty render a dash
                  if (cell.value === '') return '-'

                  if (typeof cell.value === 'string')
                    return _.truncate(cell.value, { length: 85 })

                  return cell.render('Cell')
                })()}
              </Cell>
            )
          })}
        </Row>
      )
    },
    [
      tableInstance.rows,
      tableInstance.prepareRow,
      tableInstance.state.selectedRowIds,
    ]
  )

  const [searchBoxRef, { width: searchBoxWidth }] = useMeasure<HTMLDivElement>()

  // debounce controlled searchTerm value
  const [, cancel] = useDebounce(
    () => {
      setSearchTerm?.(localSearchTerm || '')
      // incorrect typing, optional chain needed to check if function exists
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      tableInstance.setGlobalFilter?.(localSearchTerm)
    },
    500,
    [localSearchTerm]
  )

  // cancel debounce on initial render
  useEffect(() => cancel(), [])

  // skip debounce and immediately update if localSearchTerm is set back to empty
  useUpdateEffect(() => {
    if (!localSearchTerm && (searchTerm || tableInstance.state.globalFilter)) {
      cancel()

      setSearchTerm?.(localSearchTerm || '')
      // incorrect typing, optional chain needed to check if function exists
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      tableInstance.setGlobalFilter?.(localSearchTerm)
    }
  }, [localSearchTerm])

  const skipSyncToURL = useRef(false)

  // if urlState is provided sync orderBy state based on urlState
  useEffect(() => {
    if (urlState?.orderByColId && !skipSyncToURL.current)
      tableInstance.setSortBy([
        {
          id: urlState.orderByColId,
          desc: urlState.orderBy === 'DESC' || !urlState.orderBy,
        },
      ])

    skipSyncToURL.current = false
  }, [urlState])

  // if orderBy is toggled set the urlState
  useUpdateEffect(() => {
    if (setUrlState && urlState)
      setUrlState({
        ...urlState,
        orderBy: tableInstance.state.sortBy[0]?.desc ? 'DESC' : 'ASC',
        orderByColId: tableInstance.state.sortBy[0]?.id,
      })

    skipSyncToURL.current = true
  }, [tableInstance.state.sortBy])

  return (
    <>
      {
        // if space for searchBox is less than 320px show it here
        (isPresent(tableInstance.setGlobalFilter) ||
          setSearchTerm !== undefined) &&
        searchBoxWidth < 320 ? (
          <SearchBox
            value={localSearchTerm || ''}
            onChange={(e) => setLocalSearchTerm(e.target.value)}
            placeholder={searchPlaceholder}
            className="mb-2"
            disabled={
              !searchTerm &&
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (tableInstance.preGlobalFilteredRows?.length === 0 ||
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                !!tableInstance.selectedFlatRows?.length)
            }
          />
        ) : null
      }
      {/* conditionally render a SearchBox if the useGlobalFilter hook is provided */}
      <FadeInSlideDown delay={delay()} className="flex mb-2 gap-2">
        {controls || null}
        {
          // if space for searchBox is less than 320px show it above on its own line instead of here
          isPresent(tableInstance.setGlobalFilter) ||
          (setSearchTerm !== undefined && searchBoxWidth > 320) ? (
            <SearchBox
              ref={searchBoxRef}
              value={localSearchTerm || ''}
              onChange={(e) => setLocalSearchTerm(e.target.value)}
              placeholder={searchPlaceholder}
              className="flex-grow"
              disabled={
                !searchTerm &&
                // incorrect typing, optional chain needed to check if array exists
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (tableInstance.preGlobalFilteredRows?.length === 0 ||
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !!tableInstance.selectedFlatRows?.length)
              }
            />
          ) : (
            <div ref={searchBoxRef} className="flex-grow" />
          )
        }
        {
          // If rows have been selected, show selectedControls instead of filters
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          tableInstance.selectedFlatRows?.length
            ? selectedControls
            : tableInstance.columns.map((column) => {
                // a Filter component for the column is required for the Filter component to be rendered
                if (!column.Filter) return null

                return column.render('Filter', { key: column.id })
              })
        }
      </FadeInSlideDown>

      <FadeInSlideDown className="flex-grow flex" delay={delay()}>
        <div className="flex-grow border border-gray-200 rounded bg-white overflow-hidden">
          {/* AutoSizer for filling the parent's width and height */}
          <AutoSizer>
            {({ height, width }) => (
              <div {...tableInstance.getTableProps()}>
                <div>
                  {tableInstance.headerGroups.map((headerGroup, rowIndex) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      key={`header-row-${rowIndex}`}
                      className="grid gap-4 px-8 h-14 border-b border-gray-200"
                      // dynamic styles
                      style={{ width: `${width}px`, gridTemplateColumns }}
                    >
                      {headerGroup.headers.map((column, columnIndex) => (
                        // table header
                        <div
                          {...column.getHeaderProps(
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            column.getSortByToggleProps?.()
                          )}
                          // prevent cells from taking up more space then they should
                          style={{ minWidth: '0px' }}
                          key={`header-cell-${rowIndex}-${columnIndex}`}
                          className={`
                          flex items-center text-gray-600
                          ${
                            column.canSort
                              ? 'hover:text-gray-900 cursor-pointer'
                              : ''
                          }`}
                        >
                          <p
                            className="font-semibold text-sm transition-all select-none align-middle"
                            // listen to the parent divs color!
                            style={{ color: 'inherit' }}
                          >
                            {column.render('Header')}
                          </p>
                          &nbsp;
                          <SortIndicator
                            isSorted={column.isSorted}
                            isSortedDesc={!!column.isSortedDesc}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                {isLoading ? (
                  <LoadingIcon
                    width={`${width}px`}
                    height={`${height - 58}px`}
                  />
                ) : (
                  // table rows
                  <div {...tableInstance.getTableBodyProps()}>
                    {/* display Empty if no data provided */}
                    {tableInstance.rows.length === 0 ? (
                      <div
                        className="pb-24"
                        style={{
                          width,
                          height,
                        }}
                      >
                        <Empty
                          title={emptyTitle || t('No Data Found')}
                          description={emptyDescription}
                        />
                      </div>
                    ) : (
                      <FixedSizeList
                        height={height - 58}
                        itemCount={tableInstance.rows.length}
                        itemSize={56}
                        width={width}
                        overscanCount={25}
                        outerElementType={CustomScrollbarsVirtualList}
                      >
                        {RenderRow}
                      </FixedSizeList>
                    )}
                  </div>
                )}
              </div>
            )}
          </AutoSizer>
        </div>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        {paginatable ? (
          <ListPaginationControls
            paginationInfo={paginationInfo}
            onPageChange={onPageChange}
          />
        ) : null}
      </FadeInSlideDown>
    </>
  )
}

export default List

const Row = styled.div(({ rowIndex }: { rowIndex: number }) => [
  tw`grid gap-4 px-8 h-14`,
  rowIndex % 2 ? tw`bg-gray-50` : tw`bg-white`,

  // fix for right hidden right border with custom scrollbar
  `
    width: calc(100% - 2px) !important;
  `,
])

const Cell = styled.div(({ isString }: { isString: boolean }) => [
  tw`flex items-center text-gray-900`,
  isString && tw`overflow-hidden`,
])

type SortIndicatorProps = {
  isSorted: boolean
  isSortedDesc: boolean
}

const SortIndicator = ({ isSorted, isSortedDesc }: SortIndicatorProps) => {
  // if not sorted, render an invisible icon to prevent jumping
  if (!isSorted) return <Icon type="chevron-down" className="opacity-0" />

  // if descending sort
  if (isSortedDesc)
    return (
      <Icon
        type="chevron-down"
        data-testid="descending-icon"
        className="align-middle"
      />
    )

  // if ascending sort
  return (
    <Icon
      type="chevron-up"
      data-testid="ascending-icon"
      className="align-middle"
    />
  )
}

const CustomScrollbars = ({
  onScroll,
  forwardedRef,
  style,
  children,
}: {
  children?: React.ReactNode
  style?: Record<string, unknown>
  onScroll?: () => void
  forwardedRef: React.Ref<OverlayScrollbarsComponent>
}) => {
  useEffect(() => {
    const el =
      typeof forwardedRef !== 'function'
        ? forwardedRef?.current?.osInstance()?.getElements().viewport
        : null

    if (onScroll) el?.addEventListener('scroll', onScroll)

    return () => {
      if (onScroll) el?.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  return (
    <OverlayScrollbarsComponent
      ref={forwardedRef}
      style={{ ...style, overflow: 'hidden' }}
      className="os-theme-dark"
    >
      {children}
    </OverlayScrollbarsComponent>
  )
}

const CustomScrollbarsVirtualList = React.forwardRef<OverlayScrollbarsComponent>(
  (props, ref) => <CustomScrollbars {...props} forwardedRef={ref} />
)
