import React, { useMemo, useCallback } from 'react'
import { List, EntityLabel } from 'elements'
import { Empty } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  useFilters,
  Row,
} from 'react-table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useEntityLabelSort } from 'hooks/react-table'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

type OnboardedSubscribersListProps = {
  subscribers: SubscriberDisplay[]
  delay?: () => number
}

const OnboardedSubscribersList = ({
  subscribers,
  delay,
}: OnboardedSubscribersListProps) => {
  const subscriberLabelSort = useEntityLabelSort({
    accessor: ['firstName', 'lastName'],
  })
  const { t } = useTranslation()
  const navigate = useNavigate()

  // Sort Function for the CreatedAt column
  const createdAtSortBy = useCallback(
    (rowA: Row<SubscriberDisplay>, rowB: Row<SubscriberDisplay>) =>
      rowA.original.auditInfo.createdAt > rowB.original.auditInfo.createdAt
        ? -1
        : 1,
    []
  )

  const tableData: SubscriberDisplay[] = useMemo(() => {
    return subscribers
  }, [subscribers, i18next.language])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (
      rows: Row<SubscriberDisplay>[],
      columnIDs: string[],
      globalFilter: string
    ) => {
      return rows.filter((row) => {
        const subscriberName = `${row.original.person.firstName.toLowerCase()} ${row.original.person.lastName.toLowerCase()}`
        const organizationName = row.original.organization?.businessName.toLowerCase()

        return (
          subscriberName.includes(globalFilter.toLowerCase()) ||
          organizationName?.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )

  const columns: Column<SubscriberDisplay>[] = useMemo(
    () => [
      {
        id: 'SUBSCRIBER',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('SUBSCRIBER'),
        accessor: (row) => (
          <EntityLabel
            name={`${row.person.firstName} ${row.person.lastName}`}
            id={row.id}
            to={`/subscribers/${row.id}`}
          />
        ),
        sortType: subscriberLabelSort,
      },
      {
        id: 'ACTIVATION DATE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ACTIVATION DATE'),
        accessor: (row) => row.auditInfo.createdAt,
        sortType: createdAtSortBy,
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (row) => navigate(row.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'SUBSCRIBER',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return tableData.length ? (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'subscribers'
      )}`}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
      baseDelay={delay?.()}
    />
  ) : (
    <BorderedEmpty delay={delay?.()} title="No Onboarded Subscribers Found" />
  )
}

export default OnboardedSubscribersList

const BorderedEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`
