import React from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { FadeInSlideDown } from 'animations'
import { useTranslation } from 'react-i18next'
import { useDelay } from 'hooks'
import { useAlertDeliveryRulesQuery } from 'hooks/alert-delivery-rules'
import { useAlertCategoryQuery } from 'hooks/alerts'
import {
  AlertDeliveryRuleDeliveryInformation,
  AlertDeliveryRuleGeneralInformation,
  AlertDeliveryRuleRecipients,
  AlertDeliveryRuleSchedule,
} from 'components/settings-alert-delivery-rules'
import tw from 'twin.macro'

const AlertDeliveryRuleDetails = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { deliveryRuleId } = useParams()
  const alertCategory = useAlertCategoryQuery()
  const { t } = useTranslation()
  const delay = useDelay()

  const {
    data: alertDeliveryRules,
    isLoading: isLoadingDeliveryRules,
  } = useAlertDeliveryRulesQuery()

  const alertDeliveryRule = alertDeliveryRules?.items.find(
    (deliveryRule) => deliveryRule.id === deliveryRuleId
  )

  return (
    <Layout
      description={t('Manage and review alert delivery rule details.')}
      title={alertDeliveryRule?.name}
      breadcrumbs={[
        { name: t('Organization Settings'), route: '../../' },
        {
          name: t('Alert Delivery Rules'),
          route: '../',
        },
        {
          name: alertDeliveryRule?.name || '',
        },
      ]}
      isUnauthorized={!assumedOrganizationRole}
      isLoading={isLoadingDeliveryRules || alertCategory.isLoading}
      isEmpty={!alertDeliveryRule}
      emptyComponent={
        <Empty
          title={t('No Data Found')}
          description={t('No alert delivery rules exist for this organization')}
        />
      }
    >
      {alertDeliveryRule ? (
        <DetailsContainer>
          <FullRowSectionContainer delay={delay()}>
            <AlertDeliveryRuleGeneralInformation
              alertDeliveryRule={alertDeliveryRule}
              alertCategory={alertCategory.data?.items?.find(
                (category) =>
                  category.id === alertDeliveryRule.criteria.alertCategory
              )}
            />
          </FullRowSectionContainer>
          <SectionContainer delay={delay()}>
            <AlertDeliveryRuleSchedule alertDeliveryRule={alertDeliveryRule} />
          </SectionContainer>
          <SectionContainer delay={delay()}>
            <AlertDeliveryRuleDeliveryInformation
              alertDeliveryRule={alertDeliveryRule}
            />
          </SectionContainer>
          <FullRowSectionContainer delay={delay()}>
            <AlertDeliveryRuleRecipients
              alertDeliveryRule={alertDeliveryRule}
            />
          </FullRowSectionContainer>
        </DetailsContainer>
      ) : null}
    </Layout>
  )
}

export default AlertDeliveryRuleDetails

const DetailsContainer = tw.div`grid grid-cols-1 2xl:grid-cols-2 gap-4`

const SectionContainer = tw(FadeInSlideDown)`col-span-1`

const FullRowSectionContainer = tw(FadeInSlideDown)`col-span-1 2xl:col-span-2`
