import React from 'react'
import { InformationCard } from 'elements'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next'
import { formatPhoneNumber } from 'utils'
import tw from 'twin.macro'

type PartnerInfoCardProps = {
  partnerInfo: Partner
}

const PartnerInfoCard = ({ partnerInfo }: PartnerInfoCardProps) => {
  const { t } = useTranslation()

  const partnerDisplayName = partnerInfo.person
    ? `${partnerInfo.person.firstName} ${partnerInfo.person.lastName}`
    : partnerInfo.organization
    ? partnerInfo.organization.businessName
    : ''

  return (
    <InformationCard>
      <InformationCard.Item label={t('Name')} value={partnerDisplayName} />

      <InformationCard.Item
        label={t('Status')}
        value={
          partnerInfo.party.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          )
        }
      />

      {partnerInfo.person ? (
        <>
          <InformationCard.Item
            label={t('Suffix')}
            value={partnerInfo.person.suffix || '-'}
          />

          <InformationCard.Item
            label={t('Email')}
            value={partnerInfo.person.emailAddress || '-'}
          />
        </>
      ) : null}

      {partnerInfo.party.phoneNumbers.length > 0 && (
        <InformationCard.Item
          label={t('Phone')}
          value={formatPhoneNumber(
            partnerInfo.party.phoneNumbers[0].phoneNumber
          )}
        />
      )}

      <InformationCard.Item
        label={t('ID')}
        value={<IDText>{partnerInfo.id}</IDText>}
      />
    </InformationCard>
  )
}

export default PartnerInfoCard

const IDText = tw.p`font-mono`
