import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import { TableInstance } from 'react-table'
import { useUpdateEffect } from 'react-use'
import { isPresent } from 'utils'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import Icon from './Icon'

type CategoryFilterProps<T extends string | string[] = string> = {
  icon: IconType
  label: string
  // TODO: when refactoring List make sure that any isn't needed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableInstance?: TableInstance<any>
  categories?: TableCategory<T>[]
  selectedCategories?: TableCategory<T>[]
  setSelectedCategories?: (selectedCategories: TableCategory<T>[]) => void
  renderOption?: (props: { label: string; parent?: string }) => React.ReactNode
  unordered?: boolean
}

const CategoryFilter = <T extends string | string[] = string>({
  icon,
  label,
  tableInstance,
  categories = [],
  selectedCategories,
  setSelectedCategories,
  renderOption,
  unordered,
}: CategoryFilterProps<T>) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const [localCategories, setLocalCategories] = useState<TableCategory<T>[]>(
    categories
  )

  const [localSelectedCategories, setLocalSelectedCategories] = useState<
    TableCategory<T>[]
  >(selectedCategories || [])
  const ref = useRef(null)

  const skipSyncToTable = useRef<boolean>(false)

  useUpdateEffect(() => {
    if (isDropdownVisible) setLocalCategories(categories)
  }, [isDropdownVisible])

  // if local selected categories changes set table filter
  useEffect(() => {
    if (!selectedCategories && tableInstance && !skipSyncToTable.current)
      // loose typing, setFilter may not exist
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      tableInstance.setFilter?.(
        tableInstance.column.id,
        localSelectedCategories
      )

    skipSyncToTable.current = false
  }, [localSelectedCategories])

  // if selected categories changes then synchronize local selected categories to it
  useUpdateEffect(() => {
    if (
      selectedCategories &&
      !_.isEqual(selectedCategories, localSelectedCategories)
    )
      setLocalSelectedCategories(selectedCategories)
  }, [selectedCategories])

  // listen to uncontrolled changes to the table filter and update UI to stay in sync
  useUpdateEffect(() => {
    // eslint-disable-next-line
    const filterObj = tableInstance?.state.filters?.find(
      (filter) => filter.id === tableInstance.column.id
    )

    // only update local categories if filters exist, is nonempty, and isn't equal to local filters already
    if (filterObj && !_.isEqual(filterObj.value, localSelectedCategories)) {
      setLocalSelectedCategories(filterObj.value || [])
      // don't allow this change to local state update table state since it was triggered by table state
      skipSyncToTable.current = true
    }
  }, [tableInstance?.state.filters])

  return (
    <div className="relative" ref={ref}>
      <button
        className={clsx(
          'h-full flex items-center px-4 rounded border cursor-pointer transition-all select-none disabled:opacity-50 outline-none',
          (() => {
            // if disabled
            if (categories.length === 0)
              return 'bg-gray-100 hover:bg-gray-100 cursor-not-allowed'

            // if dropdown is visible and no category is selected
            if (isDropdownVisible && localSelectedCategories.length === 0)
              return 'bg-gray-100 hover:bg-gray-100 border-gray-300'

            // if dropdown is visible and a category is selected
            if (isDropdownVisible && localSelectedCategories.length !== 0)
              return 'bg-blue-500 border-blue-300  text-white'

            // if dropdown is not visible category is selected
            if (localSelectedCategories.length !== 0)
              return 'bg-blue-400 hover:bg-blue-500 border-blue-100 text-white'

            // if dropdown is not visible and no category is selected
            return 'bg-white hover:bg-gray-100 border-gray-200 hover:border-gray-300'
          })()
        )}
        onClick={() => {
          setIsDropdownVisible(!isDropdownVisible)
        }}
        disabled={categories.length === 0}
      >
        <Icon type={icon} /> &nbsp;
        <p className="align-middle" style={{ color: 'inherit' }}>
          {label}
        </p>
      </button>

      <Dropdown
        visible={isDropdownVisible}
        setVisible={setIsDropdownVisible}
        parentRef={ref}
        parentAnchor={{ vertical: 'bottom', horizontal: 'right' }}
        contentAnchor={{ vertical: 'top', horizontal: 'right' }}
        className="mt-2 w-120"
        search
        onSearch={(e) =>
          setLocalCategories(() => {
            // filter by text input
            const filterByText = categories.filter((category) =>
              category.label.toLowerCase().includes(e.toLowerCase())
            )
            //find the parents of the filtered by text arrays
            const parentsOfFilter = categories.map((top) => {
              return filterByText.map((bottom) => {
                if (bottom.parent === top.value) return top
              })
            })

            // flatten the array of arrays, and filter out undefined as well as insure there are no duplicates
            const parents = _.uniq(
              _.flattenDeep(parentsOfFilter).filter(isPresent)
            )
            return _.orderBy(_.uniq([...parents, ...filterByText]), 'order')
          })
        }
      >
        {(unordered
          ? localCategories
          : _.orderBy(localCategories, 'label')
        ).map((category) => {
          return (
            <DropdownItem
              key={JSON.stringify(category.value)}
              className={clsx(
                'flex items-center justify-between gap-2 select-none whitespace-nowrap',
                category.parent && 'ml-4'
              )}
              onClick={() => {
                // if the category is selected then de-select it
                if (
                  localSelectedCategories.some((localSelectedCategory) =>
                    _.isEqual(localSelectedCategory.value, category.value)
                  )
                ) {
                  setLocalSelectedCategories(
                    localSelectedCategories.filter(
                      (item) => !_.isEqual(category.value, item.value)
                    )
                  )
                  setSelectedCategories?.(
                    localSelectedCategories.filter(
                      (item) => !_.isEqual(category.value, item.value)
                    )
                  )
                  return
                }

                // otherwise select it
                setLocalSelectedCategories([
                  ...localSelectedCategories,
                  category,
                ])
                setSelectedCategories?.([...localSelectedCategories, category])
              }}
            >
              {renderOption ? (
                renderOption({
                  label: category.label,
                  parent: category.parent,
                })
              ) : (
                <p>{category.label}</p>
              )}
              <Icon
                type="check"
                className={clsx(
                  localSelectedCategories.some((localCategory) =>
                    _.isEqual(localCategory.value, category.value)
                  )
                    ? 'text-gray-900'
                    : 'opacity-0'
                )}
              />
            </DropdownItem>
          )
        })}
      </Dropdown>
    </div>
  )
}

export default CategoryFilter
