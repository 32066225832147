import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'atlas'
import { useAPIQuery, useNavigate } from 'hooks'
import {
  useReactivateOrderMutation,
  useCancelOrderMutation,
} from 'hooks/orders'
import {
  useCheckoutOrderMutation,
  useReleaseOrderMutation,
} from 'hooks/provisioning'
import { useAneltoCredentialsQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useClickAway, useWindowSize } from 'react-use'
import tw, { styled } from 'twin.macro'
import { isProvisioningNeeded } from 'utils'
import { ConfirmationDialogs, Layout } from 'elements'
import {
  OrderActivity,
  OrderDetailsLineItems,
  OrderInfoCard,
  ProvisionCompleteCallToAction,
} from 'components/orders'
import { LineItemStatusTitles } from 'components/orders/LineItemStatusChip'
import { OrderStatusTitles } from 'components/orders/OrderStatusChip'

const OrderDetails = () => {
  const { t } = useTranslation()
  const { orderId } = useParams()
  const navigate = useNavigate()

  const [
    showCallToActionAneltoCreds,
    setShowCallToActionAneltoCreds,
  ] = useState<boolean>(false)
  const [isReleaseDialogOpen, setIsReleaseDialogOpen] = useState<boolean>(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [isReactivateDialogOpen, setIsReactivateDialogOpen] = useState(false)
  const [isToggled, setIsToggled] = useState<boolean>(false)
  const [showCallToAction, setShowCallToAction] = useState<boolean>(false)

  const orderQuery = useAPIQuery('order', { pathParams: { orderId } })
  const lineItemStatusesQuery = useAPIQuery('orderLineItemStatuses')
  const aneltoCreds = useAneltoCredentialsQuery(
    orderQuery.data?.organizationId || ''
  )

  const checkoutOrder = useCheckoutOrderMutation()
  const releaseOrder = useReleaseOrderMutation()
  const reactivateOrder = useReactivateOrderMutation()
  const cancelOrder = useCancelOrderMutation()

  const orderActivityClickAwayRef = useRef<HTMLDivElement>(null)

  useClickAway(orderActivityClickAwayRef, () => setIsToggled(false))

  const provisioningStatus = isProvisioningNeeded({
    orderStatusTitle: orderQuery.data?.orderStatus?.title,
    provisioningNeeded: orderQuery.data?.provisioningNeeded,
  })

  const showShipping = [
    OrderStatusTitles.PROVISIONING_COMPLETE,
    OrderStatusTitles.SHIPPED,
    OrderStatusTitles.DELIVERED,
  ].some((status) => status === orderQuery.data?.orderStatus?.title)

  // Show call to action to continue to shipping if all line items are provisioned.
  useEffect(() => {
    if (orderQuery.data?.provisioningNeeded && orderQuery.data.orderLineItems) {
      setShowCallToAction(
        (orderQuery.data.orderStatus?.title === OrderStatusTitles.CONFIRMED ||
          orderQuery.data.orderStatus?.title ===
            OrderStatusTitles.PROVISIONING_IN_PROCESS) &&
          orderQuery.data.orderLineItems.every(
            (lineItem) =>
              !lineItem.provisioningNeeded ||
              lineItem.orderLineItemStatusId ===
                lineItemStatusesQuery.data?.find(
                  (status) =>
                    status.title === LineItemStatusTitles.PROVISIONING_COMPLETE
                )?.id
          )
      )
    }
  }, [orderQuery.data])

  useEffect(() => {
    if (aneltoCreds.data?.aneltoUsername === '') {
      setShowCallToActionAneltoCreds(true)
    }
  }, [aneltoCreds])

  const windowsWidth = useWindowSize()

  useEffect(() => {
    setIsToggled((prevIsToggled) => windowsWidth.width < 1280 && prevIsToggled)
  }, [windowsWidth.width])

  const confirmations = [
    // Release from provisioning Dialog
    {
      setIsOpen: setIsReleaseDialogOpen,
      isOpen: isReleaseDialogOpen,
      title: t(
        `Release Order "${orderQuery.data?.orderCode}" from provisioning`
      ),
      content: (
        <p>
          {t(
            'This will release the Order back to provisioning. Any items that are already provisioned will remain provisioned. Are you sure you want to continue?'
          )}
        </p>
      ),
      actions: (
        <Button
          onClick={() => {
            // release order from provisioning
            releaseOrder.mutate({ orderId })
            setIsReleaseDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Release')}
        </Button>
      ),
    },
    //Cancel Order
    {
      setIsOpen: setIsCancelDialogOpen,
      isOpen: isCancelDialogOpen,
      title: t('Cancel this order'),
      content: (
        <DialogContent>
          {t('Are you sure you want to cancel this order?')}
        </DialogContent>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            cancelOrder.mutateAsync(orderId || '')
            return setIsCancelDialogOpen(false)
          }}
        >
          {t('Cancel Order')}
        </Button>
      ),
    },
    //Reactivate Order
    {
      setIsOpen: setIsReactivateDialogOpen,
      isOpen: isReactivateDialogOpen,
      title: t('Reactivate this order'),
      content: (
        <DialogContent>
          {t('Are you sure you want to reactivate this order?')}
        </DialogContent>
      ),
      actions: (
        <Button
          type="primary"
          onClick={() => {
            reactivateOrder.mutateAsync(orderId || '')
            return setIsReactivateDialogOpen(false)
          }}
        >
          {t('Reactivate Order')}
        </Button>
      ),
    },

    // Anelto Creds Call to Action
    {
      setIsOpen: setShowCallToActionAneltoCreds,
      isOpen: showCallToActionAneltoCreds,
      title: t('Set Anelto Credentials before provisioning'),
      content: (
        <div>
          {t(
            'In order to provision this order, you must set up the Anelto Credentials for this Organization. Navigate to Organization details to set these credentials'
          )}
        </div>
      ),
      actions: (
        <Button
          type="primary"
          onClick={() => {
            navigate(`/organizations/${orderQuery.data?.organizationId}`)
            setShowCallToActionAneltoCreds(false)
          }}
        >
          {t('Navigate to Organization')}
        </Button>
      ),
      disableCancellation: true,
    },
  ]

  return (
    <Layout
      title={`${t('Order Details')}`}
      description={
        t('View and edit order details, line items, and provision products') +
        '.'
      }
      breadcrumbs={[
        { name: t('Orders'), route: '../' },
        {
          name: t('Order Details'),
        },
      ]}
      isLoading={orderQuery.isLoading}
      isEmpty={!orderQuery.data}
      controls={[
        {
          label: t(provisioningStatus),
          onClick: () => {
            if (provisioningStatus === 'Provision')
              checkoutOrder.mutate({ orderId })
            if (provisioningStatus === 'Release from provision')
              setIsReleaseDialogOpen(true)
          },
          disabled: checkoutOrder.isLoading || releaseOrder.isLoading,
        },
        {
          label: t('Change Order Details'),
          onClick: () =>
            navigate({
              pathname: '/orders/create-order',
              searchParams: { orderId },
            }),
        },
        ...(orderQuery.data?.orderStatus?.title === OrderStatusTitles.CONFIRMED
          ? [
              {
                label: t('Cancel Order'),
                onClick: () => setIsCancelDialogOpen(true),
              },
            ]
          : []),
        ...(orderQuery.data?.orderStatus?.title === OrderStatusTitles.CANCELLED
          ? [
              {
                label: t('Reactivate Order'),
                onClick: () => setIsReactivateDialogOpen(true),
              },
            ]
          : []),
      ]}
    >
      <OrderDetailsContainer>
        {showCallToAction ? (
          <ProvisionCompleteCallToAction
            setShowCallToAction={setShowCallToAction}
            orderId={orderId}
          />
        ) : null}

        <OrderActivityContainer ref={orderActivityClickAwayRef}>
          <ActivityButton
            type="primary-filled"
            onClick={(e) => (
              setIsToggled((prevIsToggled) => !prevIsToggled),
              e.stopPropagation()
            )}
          >
            {t('Order Activity')}
          </ActivityButton>

          <FloatingOrderActivity toggled={isToggled}>
            <OrderActivity orderId={orderId} />
          </FloatingOrderActivity>
        </OrderActivityContainer>

        <OrderInfoCard order={orderQuery.data} />

        <BottomRowContainer>
          <BottomRow>
            <LeftColumn>
              {showShipping
                ? null
                : // TODO: uncomment this when shipping is ready
                  // <ShippingDetailsCard order={orderQuery.data} />
                  null}
              <OrderDetailsLineItems
                continueToShipping={showShipping}
                subscriberId={orderQuery.data?.subscriberId}
                orderLineItems={orderQuery.data?.orderLineItems || []}
                orderStatus={orderQuery.data?.orderStatus || {}}
              />
            </LeftColumn>

            <RightColumn>
              <OrderActivity orderId={orderId} />
            </RightColumn>
          </BottomRow>
        </BottomRowContainer>
      </OrderDetailsContainer>

      <ConfirmationDialogs confirmations={confirmations} />
    </Layout>
  )
}

export default OrderDetails

const DialogContent = tw.div`mb-8 pr-6`

const OrderDetailsContainer = tw.div`relative flex flex-col -mt-4`

const OrderActivityContainer = tw.div`w-fit-content self-end`

const BottomRowContainer = tw.div`relative mt-8 flex flex-grow`

const ActivityButton = tw(
  Button
)`mb-4 ml-auto flex opacity-100 xl:opacity-0 xl:hidden`

const BottomRow = tw.div`position[inherit] flex flex-grow`

const LeftColumn = tw.div`w-full p-0 xl:w-2/3 xl:pr-8`

const RightColumn = tw.div`hidden xl:flex flex-col w-full xl:opacity-100 xl:w-1/3 md:w-1/2 height[63vh] max-height[70vh]`

const FloatingOrderActivity = styled(RightColumn)<{ toggled: boolean }>(
  ({ toggled }) => [
    tw`z-30`,
    toggled
      ? tw`absolute right-0 flex opacity-100 `
      : tw`xl:w-1/3 md:w-1/2 hidden opacity-0`,
  ]
)
