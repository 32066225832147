import React, { useEffect } from 'react'
import { LoadingIcon, RightPopup, TextField } from 'elements'
import { Button } from 'atlas'
import _ from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import {
  useReminderMessageTypesQuery,
  useCreateReminderMessagesMutation,
  useReminderMessagesQuery,
  useUpdateReminderMessagesMutation,
} from 'hooks/reminders'
import { useTranslation } from 'react-i18next'

type ECGAdminEditReminderMessagesFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  shouldEdit: boolean
}

const ECGAdminEditReminderMessagesForm = ({
  isFormOpen,
  setIsFormOpen,
  shouldEdit,
}: ECGAdminEditReminderMessagesFormProps) => {
  const { t } = useTranslation()
  const formMethods = useForm<EditReminderForm>()

  const reminderMessageTypes = useReminderMessageTypesQuery()

  // the key provided is for test purposes for ECG Reminders, it is the org 'ECG Admin'
  const reminderMessages = useReminderMessagesQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const { mutate: updateReminderMessage } = useUpdateReminderMessagesMutation()

  const { mutate: createReminderMessage } = useCreateReminderMessagesMutation()

  const { handleSubmit, errors, register } = formMethods

  // Refetch form fields if isFormOpen is toggled
  useEffect(() => {
    reminderMessages.refetch()
  }, [isFormOpen])

  return (
    <RightPopup
      title={
        shouldEdit
          ? t(`Edit Reminder Messages - English (United States)`)
          : t(`Create Reminder Messages - English (United States)`)
      }
      open={isFormOpen}
      setOpen={setIsFormOpen}
      width="800px"
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={handleSubmit((formData) => {
              // Default reminders key will always be en-US
              if (shouldEdit) {
                updateReminderMessage({
                  orgId: '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
                  localeKey: 'en-US',
                  messages: _.map(formData, (value, key) => {
                    return {
                      messageTypeId: key,
                      message: value,
                      reminderMessageId: reminderMessages.data?.items?.find(
                        (message) => message.messageType.id === key
                      )?.id,
                    }
                  }),
                })
              } else {
                createReminderMessage({
                  orgId: '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
                  localeKey: 'en-US',
                  messages: _.map(formData, (value, key) => {
                    return {
                      messageTypeId: key,
                      message: value,
                      reminderMessageId: reminderMessages.data?.items?.find(
                        (message) => message.messageType.id === key
                      )?.id,
                    }
                  }),
                })
              }

              // Close form drawer
              setIsFormOpen(false)
            })}
          >
            {shouldEdit ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {reminderMessages.isLoading || reminderMessageTypes.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            {/* Order by sort order then display */}
            {_.orderBy(
              reminderMessageTypes.data?.items,
              (reminderMessage) => reminderMessage.displayOrder
            ).map((item, index) => (
              <TextField
                key={index}
                className="mb-8"
                helperText={item.description}
                error={errors[item.name || index]?.message}
                name={item.id}
                inputRef={register({
                  required: `${item.name} ${t('message is required')}`,
                })}
                // Change label to error message if one exists
                label={item.name}
                defaultValue={
                  reminderMessages.data?.items?.find(
                    (message) => message.messageType.id === item.id
                  )?.message
                }
                fullWidth
                required
                multiline
                rows={2}
              />
            ))}
          </form>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default ECGAdminEditReminderMessagesForm
