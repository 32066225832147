import React from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { EntityLabel } from 'elements'
import { Button, Empty } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Pin } from 'animations'
import { usePinnedOrganizationsMutation } from 'hooks/organizations'
import { useNavigate } from 'hooks'

type PinnedOrganizationsProps = {
  pinnedOrganizations: OrganizationListItem[]
}

const PinnedOrganizations = ({
  pinnedOrganizations = [],
}: PinnedOrganizationsProps) => {
  const { t } = useTranslation()
  const { assumeOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()
  const pinnedOrganizationsMutation = usePinnedOrganizationsMutation()

  return (
    <div>
      <Header>{t('Pinned Organizations')}</Header>
      {pinnedOrganizations.length ? (
        pinnedOrganizations.map((org) => (
          <Card
            key={org.id}
            onClick={() => navigate(`organizations/${org.id}`)}
          >
            <EntityLabel name={org.businessName} id={org.id} />

            <Actions>
              <Button
                type="primary-link"
                onClick={(e) => {
                  e.stopPropagation()

                  assumeOrganizationRole({
                    orgID: org.id,
                  })
                }}
              >
                {t('Assume Role')}
              </Button>
              <Pin
                className="flex"
                pinned={true}
                onClick={() => {
                  pinnedOrganizationsMutation.mutate({
                    action: 'unpin',
                    organizationId: org.id,
                  })
                }}
              />
            </Actions>
          </Card>
        ))
      ) : (
        <EmptyDisplay
          title={t('No Data Found')}
          description={t('Pin an organization to see them here')}
        />
      )}
    </div>
  )
}

export default PinnedOrganizations

const Header = tw.h3`text-xl font-semibold  mb-4`

const Card = tw.div`flex items-center justify-between p-4 bg-white hover:shadow-sm hover:bg-gray-50 transition-all mb-1 border border-gray-200 rounded-lg cursor-pointer`

const Actions = tw.div`flex items-center`

const EmptyDisplay = tw(Empty)`border-2 border-dashed rounded`
