import React, { useState, useMemo } from 'react'
import { useStaffQuery } from 'hooks/user-staff'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout, InformationCard } from 'elements'
import { Button, Empty, Chip } from 'atlas'
import { StaffMemberSelect } from 'components/staff'
import { AssignedPoliciesList } from 'components/access-control/policies'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FadeInSlideDown } from 'animations'
import { usePoliciesQuery } from 'hooks/access-control/policies'
import { useStaffMemberTypesQuery } from 'hooks/seed-data'
import { useDelay } from 'hooks'
import { useUpdateStaffPoliciesMutation } from 'hooks/user-staff'
import tw from 'twin.macro'

const StaffAccessControl = () => {
  const { staffid } = useParams()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const delay = useDelay()
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>('')

  const [isAssignPoliciesFormOpen, setIsAssignPoliciesFormOpen] = useState(
    false
  )

  const policiesQuery = usePoliciesQuery()

  const staffQuery = useStaffQuery(
    assumedOrganizationRole?.orgID || '',
    staffid
  )

  const updateStaffPoliciesMutation = useUpdateStaffPoliciesMutation()

  const staffMemberTypesQuery = useStaffMemberTypesQuery()

  const staffPolicies: Policy[] = useMemo(() => {
    if (!staffQuery.data?.policies || !policiesQuery.data) return []

    const result: Policy[] = staffQuery.data.policies
      .map((policyId) =>
        policiesQuery.data.items?.find((policy) => policy.id === policyId)
      )

      // filter out the undefined's
      .filter((item): item is Policy => !!item)

      // filter by searched value
      .filter((policy) =>
        policy.name.toLowerCase().includes(searchValue.toLowerCase())
      )

    return result
  }, [staffQuery.data, policiesQuery.data, searchValue])

  if (!staffQuery.data)
    return (
      <Empty
        title={t('Staff Member Not Found')}
        description={t(
          `Either this staff member doesn't exist or you don't have access to view them`
        )}
      />
    )

  return (
    <Layout
      isLoading={
        policiesQuery.isLoading ||
        staffMemberTypesQuery.isLoading ||
        staffQuery.isLoading
      }
      title={
        <TitleContainer>
          <PageTitle>{t('Access Control')}:</PageTitle>
          <StaffMemberSelect />
        </TitleContainer>
      }
      description={t('Manage access control settings for this admin.')}
      breadcrumbs={[
        {
          name: t('Staff'),
          route: '../../',
        },
        {
          name: `${staffQuery.data.person.firstName} ${staffQuery.data.person.lastName}`,
          route: '../',
        },
        {
          name: t('Access Control'),
        },
      ]}
      controls={
        <Button
          type="primary-filled"
          onClick={() => setIsAssignPoliciesFormOpen(true)}
        >
          {t('Edit Staff & Policies')}
        </Button>
      }
    >
      <FadeInSlideDown delay={delay()}>
        <InformationCard>
          <InformationCard.Item
            label={t('Member Type')}
            value={
              staffMemberTypesQuery.data?.items?.find(
                (memberType) => +memberType.id === staffQuery.data.memberType
              )?.name || '-'
            }
          ></InformationCard.Item>

          <InformationCard.Item
            label={t('Provider')}
            value={
              staffQuery.data.isProvider ? (
                <Chip color="green">{t('Assigned')}</Chip>
              ) : (
                <Chip color="gray">{t('Not Assigned')}</Chip>
              )
            }
          ></InformationCard.Item>
        </InformationCard>
        <PageHeader>
          {`${t('Assigned Policies for')} ${
            assumedOrganizationRole?.orgDisplayName
          } (${staffQuery.data.policies?.length || '0'})`}
        </PageHeader>

        <AssignedPoliciesList
          userType="staff"
          staff={staffQuery.data}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          attachedPolicies={staffPolicies}
          onRemovePolicy={(policyToRemove) => {
            updateStaffPoliciesMutation.mutate({
              orgId: assumedOrganizationRole?.orgID || '',
              staffId: staffid,
              policies: staffPolicies.filter(
                (policy) => policy.id !== policyToRemove.id
              ),
            })
          }}
          onUpdatePolicies={(newPolicies) => {
            updateStaffPoliciesMutation.mutate({
              orgId: assumedOrganizationRole?.orgID || '',
              staffId: staffid,
              policies: newPolicies,
            })
          }}
          isAssignPoliciesFormOpen={isAssignPoliciesFormOpen}
          setIsAssignPoliciesFormOpen={setIsAssignPoliciesFormOpen}
        />
      </FadeInSlideDown>
    </Layout>
  )
}

export default StaffAccessControl

const PageTitle = tw.h1`text-2xl font-bold`

const PageHeader = tw.h3`text-xl font-semibold my-4`

const TitleContainer = tw.div`flex items-center gap-2`
