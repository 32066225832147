import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

// TODO: Refactor Commissions when the actual API is released for it
const usePartnerTypeIndirectCommissionsQuery = (
  partnerTypeId: string | undefined
) => {
  const query = useQuery<GetPartnerTypeIndirectCommissionsResponse, KyError>(
    ['getPartnerTypeIndirectCommissions', partnerTypeId],
    () => getPartnerTypeIndirectCommissions(partnerTypeId),
    { enabled: !!partnerTypeId }
  )

  return query
}

export default usePartnerTypeIndirectCommissionsQuery

type getPartnerTypeIndirectCommissions = (
  partnerTypeId: string | undefined
) => Promise<GetPartnerTypeIndirectCommissionsResponse>
const getPartnerTypeIndirectCommissions: getPartnerTypeIndirectCommissions = async (
  partnerTypeId
) => {
  const result = await orgsApi
    .get(`api/partner-types/${partnerTypeId}/indirect-commissions`)
    .json<GetPartnerTypeIndirectCommissionsResponse>()
  return result
}
