import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { isAfter } from 'date-fns'
import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import {
  generateArchivable,
  generateAuditable,
  generateDisableable,
} from 'test-utils/api/utils'
import { typedVitalsApi } from 'utils'

const useCreateManualObservationMutation = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const mutation = useMutation<
    VitalsAPIResponse['POST']['/subscribers/{subscriberId}/vitals-observations'],
    CreateManualObservationProps
  >({
    mutationFn: createManualObservation,
    successMsg: t('Manual Observation(s) created'),
    // TODO: invalidating the cache doesn't work because there is a delay before the new observations are returned by this query
    additionalCachesToInvalidate: ['getVitalsReadings'],

    /** TODO: this OU is a non-trivial problem because multiple vital readings are created
        and each one needs to be filtered based on the specific filters for a cache. Getting
        the filter object for the query cache to filter each new observation against will probably
        involve sending the entire Query as a param to the updateFn instead of just oldCache. */

    optimisticUpdates: [
      {
        cacheKeyFilter: {
          prefix: 'getVitalsReadings',
          // only update caches in the case that some observation in the new reading passes the cache's filters object
          //! filtering by multiple vitalSignIds will return empty results even if a match exists
          //! https://github.com/electronic-caregiver/svcs-api-vitals/issues/606
          filterFn: ({ queryKey }, { observationForm }) => {
            const filters = queryKey[1] as VitalReadingFilters
            const hasVitalSignIds = filters.vitalSignIds?.some((vitalSignId) =>
              observationForm.observations.some(
                (observation) => observation.vitalSignId === vitalSignId
              )
            )

            // return a boolean if a cache should be updated
            return (
              isAfter(
                new Date(observationForm.timestamp),
                filters.dateRangeState.value[0]
              ) &&
              /**
               * TODO: fix locale issues, observations timestamps and filters date state locale is not in sync
               * this is because
               * */
              // &&
              //   isBefore(
              //     new Date(observationForm.timestamp),
              //     filters.dateRangeState.value[1]
              //   )) ||
              (hasVitalSignIds || !filters.vitalSignIds?.length)
            )
          },
        },
        updateFn: updateFnConstructor<
          Extract<
            VitalsAPIResponse['GET']['/vital-readings-grouped'],
            { items?: VitalReadingGrouped[] }
          >,
          CreateManualObservationProps
        >((oldCache, { observationForm, subscriberId }) => {
          const vitalSignsQuery = queryClient.getQueryData<
            VitalsAPIResponse['GET']['/vital-signs']
          >('ECGAdmin_getVitalSigns')

          return oldCache?.items
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items,
                  {
                    subscriberId: subscriberId,
                    organizationId: assumedOrganizationRole?.orgID || '',
                    readingCount: observationForm.observations.length,
                    recordedAt: observationForm.timestamp,
                    vitalSignIds: [
                      ...observationForm.observations.map(
                        (observation) => observation.vitalSignId
                      ),
                    ],
                    readings: [
                      ...observationForm.observations.map((observation) => ({
                        id: '',
                        rawRecordId: '',
                        organizationId: assumedOrganizationRole?.orgID || '',
                        subscriberId: subscriberId,
                        vitalSign: {
                          code: '',
                          name: '',
                          displayName: '',
                          // keeping both unit arrays until issue is fixed https://github.com/electronic-caregiver/svcs-api-vitals/issues/616
                          units: [],
                          defaultUnitCode: '',
                          ...generateAuditable(),
                          ...generateArchivable(),
                          ...generateDisableable(),

                          id: observation.vitalSignId,
                          //let found vitalSign overwrite mock data
                          ...vitalSignsQuery?.items?.find(
                            (vitalSign) =>
                              vitalSign.id === observation.vitalSignId
                          ),
                        },
                        reading: +observation.reading,
                        unit: observation.unit,
                        metadata: { unit: observation.unit },
                        recordedAt: observationForm.timestamp,

                        // mock updates
                        delta: 0,
                        normalHigh: 0,
                        normalLow: 0,
                        thresholdSeverity: 0,
                        thresholdDirection: '',
                        thresholdLimit: 0,
                      })),
                    ],
                    // mock updates
                    rawRecordId: '',
                  },
                ],
              }
            : undefined
        }),
      },
    ],
  })

  return mutation
}

export default useCreateManualObservationMutation

type CreateManualObservationProps = {
  observationForm: ManualObservationForm
  subscriberId: string
}

const createManualObservation = ({
  observationForm,
  subscriberId,
}: CreateManualObservationProps) =>
  typedVitalsApi.post('/subscribers/{subscriberId}/vitals-observations', {
    pathParams: { subscriberId },
    body: observationForm,
  })
