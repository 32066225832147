import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, vitalsApi, handleQueryError } from 'utils'

// used to update default reminder messages
const useDeleteReminderMessageTranslationMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['DELETE']['/organization/{organizationId}/reminder-messages/{reminderMessageId}'][],
    KyError,
    deleteReminderMessageTranslationArgs,
    {
      messageTranslations?: VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
      availableTranslations?: VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
    }
  >(deleteReminderMessageTranslation, {
    onMutate: (data) => {
      const messageTranslationsSnapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
      >([
        ['getReminderMessageTranslations', data.orgId, data.locale],
        data.orgId,
      ])

      const availableTranslationsSnapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
      >(['getAvailableTranslations', data.orgId])

      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(
        ['getReminderMessageTranslations', data.orgId, data.locale],
        (oldData) => {
          if (!oldData?.items) return undefined
          return {
            items: oldData.items.filter(
              (reminder) =>
                !(
                  reminder.id &&
                  data.reminderMessageIds.indexOf(reminder.id) >= 0
                )
            ),
          }
        }
      )

      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
        | undefined
      >(['getAvailableTranslations', data.orgId], (oldData) => {
        if (!oldData?.items) return undefined
        return {
          items: oldData.items.filter(
            (translation) => translation.locale.key !== data.locale
          ),
        }
      })

      return {
        messageTranslations: messageTranslationsSnapshot,
        availableTranslations: availableTranslationsSnapshot,
      }
    },
    onSuccess: () => {
      success({ message: t('Translation deleted') })
    },
    onError: (error, { orgId, locale }, snapshot) => {
      // revert to snapshots

      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
        | undefined
      >(
        ['getReminderMessageTranslations', orgId, locale],
        snapshot?.messageTranslations
      )

      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages/available-translations']
        | undefined
      >(['getAvailableTranslations', orgId], snapshot?.availableTranslations)
      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      queryCache.invalidateQueries([
        'getReminderMessageTranslations',
        args.orgId,
        args.locale,
      ])
      queryCache.invalidateQueries(['getAvailableTranslations', args.orgId])
    },
  })

  return mutation
}

export default useDeleteReminderMessageTranslationMutation

type deleteReminderMessageTranslationArgs = {
  locale: string
  orgId: string
  reminderMessageIds: Array<string>
}
// update default reminder
const deleteReminderMessageTranslation = async (
  data: deleteReminderMessageTranslationArgs
) => {
  // update each reminder message
  const response = await Promise.all(
    data.reminderMessageIds.map(async (reminderMessage) => {
      return await vitalsApi
        .delete(
          `organization/${data.orgId}/reminder-messages/${reminderMessage}`
        )
        .json<
          VitalsAPIResponse['DELETE']['/organization/{organizationId}/reminder-messages/{reminderMessageId}']
        >()
    })
  )
  return response
}
