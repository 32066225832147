import React from 'react'
import { EntityLabel } from 'elements'
import { useTranslation } from 'react-i18next'
import { dateRangePresetToDateRange, formatDate } from 'utils'
import { useNavigate, useAPIQuery } from 'hooks'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  useOrganizationListQuery,
  useSubscribersQuery,
} from 'hooks/organizations'
import { OrderStatusChip } from 'components/orders'
import tw from 'twin.macro'
import { Table } from 'elements'

const OrdersList = () => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()

  const orderStatusesQuery = useAPIQuery('orderStatuses')

  const organizationsQuery = useOrganizationListQuery({
    orgId: assumedOrganizationRole?.orgID,
  })
  // TODO: When we have enough subscribers that this gets unwieldy the API will need to contain the subscriber firstName & lastName
  const subscribersQuery = useSubscribersQuery()

  return (
    <Table
      query={[
        'orders',
        {
          searchParams: {
            dateRangeState: {
              preset: 'this-month',
              value: dateRangePresetToDateRange('this-month'),
            },
            orderBy: 'DESC',
          },
        },
      ]}
      columns={[
        {
          id: 'subscriberId',
          Header: t('SUBSCRIBER'),
          accessor: (row) => {
            const subscriber = subscribersQuery.data?.items?.find(
              (subscriber) => subscriber.id === row.subscriberId
            )
            return subscriber ? (
              <EntityLabel
                name={`${subscriber.person.firstName} ${subscriber.person.lastName}`}
                id={subscriber.id}
              />
            ) : (
              '-'
            )
          },
          width: '0.75fr',
        },
        {
          id: 'organizationId',
          Header: t('ORGANIZATION'),
          accessor: (row) => {
            const rowOrg = organizationsQuery.data?.items.find(
              (org) => org.id === row.organizationId
            )
            return rowOrg ? (
              <EntityLabel
                name={rowOrg.businessName || ''}
                id={rowOrg.id}
                to={`/organizations/${rowOrg.id}`}
              />
            ) : (
              '-'
            )
          },
          isHidden: !!assumedOrganizationRole,
        },
        {
          id: 'orderStatus',
          Header: t('ORDER STATUS'),
          accessor: (row) =>
            row.orderStatus?.title ? (
              <OrderStatusChip status={row.orderStatus} />
            ) : (
              '-'
            ),
          width: '0.5fr',
        },
        {
          id: 'audit.createdAt',
          Header: t('ORDER DATE'),
          accessor: (row, index) => (
            <CreatedAtContainer key={index}>
              {row.audit?.createdAt
                ? formatDate(new Date(row.audit.createdAt), 'P')
                : '-'}
            </CreatedAtContainer>
          ),
          width: '0.5fr',
          sortable: true,
        },
      ]}
      columnDependencies={[subscribersQuery.data, organizationsQuery.data]}
      filters={[
        {
          type: 'single-category',
          singleCategorykey: 'orderStatus',
          label: t('Status'),
          icon: 'vitals',
          categories:
            orderStatusesQuery.data?.map((category) => ({
              label: category.title || '',
              value: '' + category.id,
            })) || [],
        },
        {
          type: 'multi-category',
          multiCategorykey: 'organizationIds',
          label: t('Organizations'),
          icon: 'organizations',
          categories:
            organizationsQuery.data?.items.map((category) => ({
              label: category.businessName,
              value: category.id,
            })) || [],
          isHidden: !!assumedOrganizationRole,
        },
        { type: 'date-range', dateRangekey: 'dateRangeState' },
      ]}
      offsetKey="skip"
      sort={{ orderByKey: 'orderBy' }}
      search={{
        key: 'searchTerm',
        placeholder: `${t(
          'Search order attributes (Order Code, Account Number'
        )}...)`,
      }}
      onRowClick={(row) =>
        row.original.orderStatus?.title === 'In-Process'
          ? navigate({
              pathname: `/orders/create-order`,
              searchParams: { orderId: row.original.id || '' },
            })
          : navigate(row.original.id || '')
      }
    />
  )
}

export default OrdersList

const CreatedAtContainer = tw.div`flex`
