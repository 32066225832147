import useMutation, {
  updateFnConstructor,
  filterFnConstructor,
} from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedVitalsApi } from 'utils'

const useAddressAlertsMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['POST']['/vitals-alerts/{alertId}/address'][],
    addressAlertArgs
  >({
    mutationFn: addressAlert,
    successMsg: t('Alert addressed'),
    optimisticUpdates: [
      // update addressed alert caches
      {
        cacheKeyFilter: {
          // updated all addressed caches. The calculations to compile an array of orgIds/subscriberIds to update isn't worth it
          prefix: ['getAlerts', 'addressed'],
        },
        updateFn: updateFnConstructor<
          VitalsAPIResponse['GET']['/insights/vitals-alerts'],
          addressAlertArgs
        >((oldCache, alerts) => {
          const alertsToAdd: Alert[] = alerts.map((alertObj) => ({
            ...alertObj.alert,
          }))

          if (oldCache && oldCache.items)
            return {
              ...oldCache,
              items: [...oldCache.items, ...alertsToAdd],
            }

          return undefined
        }),
      },
      // update unaddressed alert caches
      {
        cacheKeyFilter: {
          prefix: ['getAlerts', 'unaddressed'],
          filterFn: filterFnConstructor<
            VitalsAPIResponse['GET']['/insights/vitals-alerts'],
            addressAlertArgs
          >((query, alerts) => {
            const alertIds = alerts.map((alertObj) => alertObj.alert.id)
            // update all query caches that contain the alert being addressed
            return !!query.state.data?.items?.some((cachedAlert) =>
              alertIds.includes(cachedAlert.id)
            )
          }),
        },
        updateFn: updateFnConstructor<
          VitalsAPIResponse['GET']['/insights/vitals-alerts'],
          addressAlertArgs
        >((oldCache, alerts) => {
          const alertIds = alerts.map((alertObj) => alertObj.alert.id)

          if (oldCache && oldCache.items)
            return {
              ...oldCache,
              items: oldCache.items.filter(
                (cachedAlert) => !alertIds.includes(cachedAlert.id)
              ),
            }

          return undefined
        }),
      },
    ],
  })

  return mutation
}

export default useAddressAlertsMutation

type addressAlertArgs = { alert: Alert }[]

const addressAlert = async (alerts: addressAlertArgs) =>
  Promise.all(
    alerts.map((alertObj) =>
      typedVitalsApi.post('/vitals-alerts/{alertId}/address', {
        pathParams: { alertId: alertObj.alert.id },
      })
    )
  )
