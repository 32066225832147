import React, { useState } from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import {
  ArchivedApplicationsList,
  CreateEditApplicationForm,
} from 'components/access-control/applications'
import { useApplicationsQuery } from 'hooks/access-control/applications'

const ArchivedAccessControlPolicyGroups = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const {
    data: applications,
    isLoading: isLoadingApplications,
  } = useApplicationsQuery()

  const [
    isCreateEditApplicationFormOpen,
    setIsCreateEditApplicationFormOpen,
  ] = useState(false)
  const [application, setApplication] = useState<Application | undefined>()

  const { t } = useTranslation()

  const handleEditApplication = (application: Application) => {
    setApplication(application)
    setIsCreateEditApplicationFormOpen(true)
  }

  const handleApplicationFormToggle = (isFormOpen: boolean) => {
    // If the form is closing, reset the selected Permission to edit
    if (isFormOpen) setApplication(undefined)

    // Call the normal setState
    setIsCreateEditApplicationFormOpen(isFormOpen)
  }
  return (
    <Layout
      title={t('Archived Applications')}
      description={t('Restore, manage, or delete archived applications.')}
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Applications'), route: '/access-control/applications' },
        { name: t('Archived Applications') },
      ]}
      isLoading={isLoadingApplications}
      isUnauthorized={
        !accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly')
      }
    >
      <ArchivedApplicationsList
        // Only show archived applications
        data={
          applications?.items?.filter(
            (application) => application.archiveInfo.archived
          ) || []
        }
        onEditApplication={handleEditApplication}
      />
      <CreateEditApplicationForm
        isFormOpen={isCreateEditApplicationFormOpen}
        setIsFormOpen={handleApplicationFormToggle}
        application={application}
      />
    </Layout>
  )
}

export default ArchivedAccessControlPolicyGroups
