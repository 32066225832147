import React from 'react'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import tw, { styled } from 'twin.macro'
import Icon from '../../elements/Icon'
import { useNavigate } from 'hooks'

export type BreadcrumbsProps = {
  /**
   * An array of breadcrumb objects. **name** is the contents of the breadcrumb, **route** is the route the breadcrumb links to, and **component** is an override that allows for JSX content. *Note: the name and route fields can't be used if the component field is used.*
   */
  breadcrumbs: Array<
    | {
        name?: string
        route?: string
        component?: never
        hidden?: boolean
      }
    | {
        name?: never
        route?: never
        component?: React.ReactNode
        hidden?: boolean
      }
  >
}

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const activeBreadcrumbs = breadcrumbs.filter(
    (breadcrumbs) => !breadcrumbs.hidden
  )
  const navigate = useNavigate()

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator={<SeperatorIcon type="navigate-next" />}
    >
      {activeBreadcrumbs.map((breadcrumb) => {
        // if a React component is provided, use it
        if (breadcrumb.component) return breadcrumb.component

        // the current route shouldn't have a route attribute
        if (!breadcrumb.route) {
          return (
            <CurrentBreadcrumb key={breadcrumb.name}>
              {breadcrumb.name}
            </CurrentBreadcrumb>
          )
        }

        return (
          <Breadcrumb
            role="link"
            key={breadcrumb.name}
            onClick={() => breadcrumb.route && navigate(breadcrumb.route)}
          >
            {breadcrumb.name}
          </Breadcrumb>
        )
      })}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs

const Breadcrumb = tw.div`text-gray-500 hover:text-gray-700 transition-all text-sm align-middle hover:underline cursor-pointer`

const CurrentBreadcrumb = tw.p`text-blue-500 font-semibold text-sm align-middle`

const SeperatorIcon = styled(Icon)`
  ${tw`text-base`}
`
