import React, { useState, useEffect } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useSubscribersQuery } from 'hooks/organizations'
import { Button } from 'atlas'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import {
  OrganizationAlertsActivityList,
  ExportAlertsForm,
} from 'components/alerts'
import { useExportAlertsQuery } from 'hooks/alerts'
import { dateRangePresetToDateRange } from 'utils'
import { useURLSyncState } from 'hooks'
import tw from 'twin.macro'

const Alerts = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const subscribers = useSubscribersQuery()
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const [exportAlerts, setExportAlerts] = useState<AlertReportRequest | null>()
  const { t } = useTranslation()

  const { isLoading: exportLoading, isSuccess } = useExportAlertsQuery({
    ...exportAlerts,
  })

  // If user has assumed an Org Role filter out subscribers belonging to other orgs
  const subscriberList = assumedOrganizationRole
    ? subscribers.data?.items?.filter(
        (subscriber) =>
          subscriber.organization?.id === assumedOrganizationRole.orgID
      )
    : subscribers.data?.items

  // set initial filters to urlParams if they exist
  const [alertFilters, setAlertFilters] = useURLSyncState<AlertFilters>({
    defaultValue: {
      addressed: false,
      dateRangeState: {
        preset: 'this-week',
        value: dateRangePresetToDateRange('this-week'),
      },
      skip: 0,
      order_by: 'DESC',
      orderByColId: 'CREATED AT',
    },
    isListFilters: true,
  })

  useEffect(() => {
    if (isSuccess) setIsFormOpen(false)
  }, [isSuccess])

  return (
    <Layout
      title={t('Alerts Activity')}
      description={t(
        assumedOrganizationRole
          ? 'Review, sort, and address any alerts generated by Subscribers and their devices for this organization'
          : 'Review, sort, and address any alerts generated by Subscribers and their devices'
      )}
      isLoading={subscribers.isLoading}
      breadcrumbs={[
        { name: t('Vitals'), route: '/vitals' },
        { name: t('Alerts Activity') },
      ]}
      controls={
        <Button onClick={() => setIsFormOpen(true)} type="primary-filled">
          {t('Export')}
        </Button>
      }
    >
      <OrgAlertsListContainer>
        <OrganizationAlertsActivityList
          subscribers={subscriberList || []}
          alertFilters={alertFilters}
          setAlertFilters={setAlertFilters}
        />
      </OrgAlertsListContainer>
      <ExportAlertsForm
        isOpen={isFormOpen}
        setIsOpen={setIsFormOpen}
        setExportAlerts={setExportAlerts}
        exportLoading={exportLoading}
        dateRange={alertFilters.dateRangeState}
        subscriberIds={alertFilters.subscriberIds}
      />
    </Layout>
  )
}

export default Alerts

const OrgAlertsListContainer = tw.div`flex flex-col flex-grow`
