import React from 'react'
import clsx from 'clsx'
import { useNavigate } from 'hooks'
import _ from 'lodash'

type EntityLabelProps = {
  name: string
  to?: string
  id: string
  size?: 'sm' | 'md' | 'lg'
  description?: string
  iconOnly?: boolean
  statusTag?: 'green' | 'red' | 'gray' | 'purple' | 'yellow'
  className?: string
  bold?: boolean
  condensed?: boolean
}

const EntityLabel = ({
  name,
  to,
  id,
  size = 'sm',
  description,
  iconOnly,
  statusTag,
  className,
  bold,
  condensed,
}: EntityLabelProps) => {
  const navigate = useNavigate()

  const words = _.words(name)

  return (
    <div
      className={clsx(
        'text-gray-700 hover:text-gray-900 leading-4',
        to &&
          'cursor-pointer border rounded-full pr-2 hover:shadow bg-white inline-block',
        className
      )}
      onClick={
        to
          ? (e) => {
              // prevent parent onClick takeover
              e.stopPropagation()

              navigate(to)
            }
          : undefined
      }
    >
      <div className={clsx(iconOnly ? 'inline' : 'flex items-center')}>
        {/* Show status tag if statusTag as a prop */}
        {statusTag ? (
          <div
            className={clsx(
              'rounded-full mr-2 inline-flex items-center justify-center flex-shrink-0',
              statusColorVariants[statusTag],
              size === 'sm' && 'w-3 h-3',
              size === 'md' && 'w-4 h-4',
              size === 'lg' && 'w-6 h-6'
            )}
          />
        ) : (
          // Show Avatar placeholder if statusTag is not provided
          <div
            className={clsx(
              'rounded-full inline-flex items-center justify-center flex-shrink-0 select-none',
              condensed ? 'mr-1.5' : 'mr-3',
              colorVariants[getColorValue(id)],
              size === 'sm' && 'w-6 h-6',
              size === 'md' && 'w-8 h-8',
              size === 'lg' && 'w-10 h-10'
            )}
          >
            <span
              className={clsx(
                size === 'sm' && 'text-xs',
                size === 'md' && 'text-base',
                size === 'lg' && 'text-lg',
                'font-bold'
              )}
              style={{ color: 'inherit' }}
            >
              {words[0]?.[0].toUpperCase()}
              {words[1]?.[0].toUpperCase()}
            </span>
          </div>
        )}
        {iconOnly ? null : (
          <div>
            <p
              className={clsx(bold && 'font-semibold')}
              style={{ color: 'inherit' }}
            >
              {name}
            </p>
            {description ? (
              <p className="text-gray-500 text-xs tracking-tight pt-1 leading-3">
                {description.toUpperCase()}
              </p>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

export default EntityLabel

const colorVariants = [
  'bg-red-500 text-red-100',
  'bg-blue-500 text-blue-100',
  'bg-purple-500 text-violet-100',
  'bg-amber-500 text-amber-100',
  'bg-emerald-500 text-emerald-100',
  'bg-cyan-500 text-cyan-100',
  'bg-pink-500 text-pink-100',
]

const statusColorVariants = {
  red: 'bg-red-200',
  purple: 'bg-purple-200',
  green: 'bg-emerald-200',
  yellow: 'bg-yellow-200',
  gray: 'bg-gray-200',
}

const getColorValue = (value: string) => {
  const hashValue = value
    // split the string into an array chars ("ML" => ["M", "L"])
    .split('')
    // convert each char into it's charCode value ("B" => 66)
    .map((char) => +char.charCodeAt(0))
    // add up all the values
    .reduce((accumulator, currentValue) => (accumulator += currentValue))
  // modulus the hashValue by colorVariants.length to get a random
  // value between 0 and colorVariants.length - 1
  return hashValue % colorVariants.length
}
