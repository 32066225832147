const dateRangePresetToPretty: { [key in DateRangePreset]: string } = {
  today: 'Today',
  'this-week': 'This Week',
  'last-week': 'Last Week',
  'this-month': 'This Month',
  'last-month': 'Last Month',
  'this-quarter': 'This Quarter',
  'last-quarter': 'Last Quarter',
  'this-year': 'This Year',
  'last-year': 'Last Year',
  custom: 'Custom',
}

export default dateRangePresetToPretty
