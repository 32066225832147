/**
 * Converts an OrganizationFilters object to an object ready to be formatted to a URLSearchParams
 * @param organizationFilters - OrganizationFilters object
 * @returns An object with only string | string[] properties suitable for conversion to url params
 */
const organizationFiltersToUrlParams = (
  organizationFilters: OrganizationFilters
): URLOrganizationFilters => {
  const {
    searchTerm,
    skip,
    take,
    ...unchangedVitalReadingFilters
  } = organizationFilters

  const result: URLOrganizationFilters = {
    skip: '' + (skip || 0),
    take: '' + (take || 0),
    ...unchangedVitalReadingFilters,
    // only include search term if it is truthy
    ...(searchTerm ? { searchTerm } : {}),
  }

  return result
}

export default organizationFiltersToUrlParams
