import { FadeInSlideDown } from 'animations'
import clsx from 'clsx'
import {
  Icon,
  LoadingIcon,
  RadioGroup,
  Select,
  SelectItem,
  TextField,
} from 'elements'
import { Button } from 'atlas'
import { generate } from 'generate-password'
import { useDelay } from 'hooks'
import { useAddressTypesQuery, useSalutationTypesQuery } from 'hooks/seed-data'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { emailValidation } from 'utils'
import tw, { styled } from 'twin.macro'

type CreateAdminFormBasicInformationProps = {
  nextStep: () => void
  className?: string
}

const CreateAdminFormBasicInformation = ({
  nextStep,
  className,
}: CreateAdminFormBasicInformationProps) => {
  const { t } = useTranslation()
  const delay = useDelay()

  const {
    data: salutationTypes,
    isLoading: isLoadingSalutationTypes,
  } = useSalutationTypesQuery()
  const {
    data: addressTypes,
    isLoading: isLoadingAddressTypes,
  } = useAddressTypesQuery()

  const { errors, watch, trigger, setValue } = useFormContext<CreateAdminForm>()

  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false)
  const [contactEmailTouched, setContactEmailTouched] = useState<boolean>(false)
  const [accountEmailTouched, setAccountEmailTouched] = useState<boolean>(false)

  // generate password or reset value of password if manual
  useEffect(() => {
    setValue(
      'account.password',
      watch('account').isAutoGenerated
        ? generate({
            length: 10,
            numbers: true,
            symbols: true,
            lowercase: true,
            uppercase: true,
            excludeSimilarCharacters: true,
            strict: true,
          })
        : ''
    )
  }, [watch('account').isAutoGenerated])

  if (
    isLoadingSalutationTypes ||
    isLoadingAddressTypes ||
    !salutationTypes?.items ||
    !addressTypes
  )
    return <LoadingIcon className={className} />

  return (
    <div className={className}>
      <FadeInSlideDown delay={delay()}>
        <SubSectionContainer>
          <SubSectionHeader>{t('Personal Information')}</SubSectionHeader>
          <InfoContainer>
            <Controller
              as={
                <Select
                  label={t('Prefix')}
                  variant="outlined"
                  fullWidth={false}
                  selectClassName="w-24"
                >
                  {salutationTypes.items.map((salutation, index) => (
                    <SelectItem value={salutation.id} key={index}>
                      {salutation.name}
                    </SelectItem>
                  ))}
                </Select>
              }
              name="person.salutation"
            />
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.firstName"
              label={t('First Name')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('First Name is required') }}
              error={errors.person?.firstName?.message}
              className="w-56 mt-4"
              required
            />
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.lastName"
              label={t('Last Name')}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Last Name is required') }}
              error={errors.person?.lastName?.message}
              className="w-56 mt-4"
              required
            />
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.suffix"
              label={t('Suffix')}
              error={errors.person?.suffix?.message}
              className="w-20 mt-4"
            />
          </InfoContainer>
        </SubSectionContainer>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <SubSectionContainer>
          <SubSectionHeader>{t('Contact Information')}</SubSectionHeader>
          <ContactInfoContainer>
            <Controller
              render={({ onChange, value, name, ref }) => (
                <TextField
                  onChange={(e) => {
                    // If contact email field hasn't been modified mimic this value
                    if (!accountEmailTouched)
                      setValue('account.email', e.target.value)
                    return onChange(e)
                  }}
                  value={value}
                  name={name}
                  ref={ref}
                  className="w-72 mt-4 mr-8 flex-shrink-0"
                  error={errors.person?.contactEmailAddress?.message}
                  label={t('Email Address')}
                  onFocus={() => setContactEmailTouched(true)}
                  required
                />
              )}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="person.contactEmailAddress"
              rules={{
                //@ts-expect-error it's fine if the required message is undefined
                required: t('Contact Email is required'),
                pattern: {
                  value: emailValidation,
                  message: 'Invalid email format',
                },
              }}
            />
          </ContactInfoContainer>
        </SubSectionContainer>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <SubSectionContainer>
          <AccountInfoHeader>{t('Account Information')}</AccountInfoHeader>
          <AccountDisclosure>
            {`${t(
              'This information will be emailed to the admin once the user has been created'
            )}. ${t(
              'They will then be able to login to ADDI with these credentials'
            )}.`}
          </AccountDisclosure>
          <Controller
            render={({ onChange, value, name, ref }) => (
              <TextField
                onChange={(e) => {
                  // If contact email field hasn't been modified mimic this value
                  if (!contactEmailTouched)
                    setValue('person.contactEmailAddress', e.target.value)
                  return onChange(e)
                }}
                value={value}
                name={name}
                ref={ref}
                className="w-72 mt-4"
                error={errors.account?.email?.message}
                label={t('Email Address')}
                onFocus={() => setAccountEmailTouched(true)}
                required
              />
            )}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="account.email"
            rules={{
              //@ts-expect-error it's fine if the required message is undefined
              required: t('Account Email is required'),
              pattern: {
                value: emailValidation,
                message: 'Invalid email format',
              },
            }}
          />
          <InfoContainer>
            <RadioContainer>
              <PasswordTitle>{`${t('Password Options')}: `}</PasswordTitle>
              <Controller
                render={({ onChange, value, name, ref }) => (
                  <RadioGroup
                    onChange={(newValue) => onChange(newValue === 'true')}
                    options={[
                      { value: 'true' as string, label: t('Auto-Generated') },
                      { value: 'false', label: t('Manual') },
                    ]}
                    checked={value ? 'true' : 'false'}
                    ref={ref}
                    name={name}
                  />
                )}
                name="account.isAutoGenerated"
                defaultValue={true}
              />
            </RadioContainer>
            <PasswordContainer>
              <Controller
                render={({ onBlur, onChange, value, name, ref }) => (
                  <TextField
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => {
                      setIsPasswordFocused(false)
                      onBlur()
                    }}
                    onChange={onChange}
                    value={value}
                    name={name}
                    ref={ref}
                    type="password"
                    label={t('Password')}
                    error={errors.account?.password?.message}
                    className={clsx(
                      'w-56',
                      watch('account').isAutoGenerated && 'hidden'
                    )}
                    defaultValue=""
                    required
                  />
                )}
                // this is purely here to prevent console.warns
                name="account.password"
                rules={{
                  //@ts-expect-error it's fine if the required message is undefined
                  required: t('Password is required'),
                  validate: {
                    isLongEnough: (value) =>
                      isPasswordLongEnough(value) ||
                      'Password requires at least 8 characters',
                    hasSpecialChar: (value) =>
                      isPasswordSpecialChar(value) ||
                      'Password requires a special character',
                    hasNumber: (value) =>
                      isPasswordNumber(value) || 'Password requires a number',
                    hasCapitalLetter: (value) =>
                      isPasswordCapitalLetter(value) ||
                      'Password requires a capital letter',
                    hasLowercaseLetter: (value) =>
                      isPasswordLowercaseLetter(value) ||
                      'Password requires a lowercase letter',
                  },
                }}
              />

              <PasswordError isFocused={!isPasswordFocused}>
                <PasswordErrorTitle>
                  {t('Password must include')}:
                </PasswordErrorTitle>
                <PasswordErrorContainer>
                  <Icon
                    type={
                      isPasswordLongEnough(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordLongEnough(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('minimum of eight characters')}</p>
                </PasswordErrorContainer>
                <PasswordErrorContainer>
                  <Icon
                    type={
                      isPasswordSpecialChar(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordSpecialChar(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('special character')}</p>
                </PasswordErrorContainer>

                <PasswordErrorContainer>
                  <Icon
                    type={
                      isPasswordNumber(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordNumber(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('number')}</p>
                </PasswordErrorContainer>

                <PasswordErrorContainer>
                  <Icon
                    type={
                      isPasswordCapitalLetter(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordCapitalLetter(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('capital letter')}</p>
                </PasswordErrorContainer>

                <PasswordErrorContainer>
                  <Icon
                    type={
                      isPasswordLowercaseLetter(watch('account.password'))
                        ? 'check'
                        : 'x'
                    }
                    className={clsx(
                      'mt-1 mr-1 min-w-max',
                      isPasswordLowercaseLetter(watch('account.password'))
                        ? 'text-green-600'
                        : 'text-red-600'
                    )}
                  />
                  <p>{t('lowercase letter')}</p>
                </PasswordErrorContainer>
              </PasswordError>
            </PasswordContainer>
          </InfoContainer>
        </SubSectionContainer>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <Button
          onClick={async () => {
            // If no errors for fields on this page, call nextStep
            if (
              await trigger([
                'person.firstName',
                'person.lastName',
                'person.contactEmailAddress',
                'account.email',
                'account.password',
              ])
            ) {
              nextStep()
            }
          }}
          type="primary-filled"
          data-testid="basic-information-continue"
        >
          {t('Save & Continue')}
        </Button>
        <CancelButton type="primary-link" to="/admins">
          {t('Cancel')}
        </CancelButton>
      </FadeInSlideDown>
    </div>
  )
}

export default CreateAdminFormBasicInformation

const isPasswordLongEnough = (password: string): boolean =>
  !!password && password.length >= 8

const isPasswordSpecialChar = (password: string): boolean =>
  /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/.test(password)

const isPasswordNumber = (password: string): boolean => /\d/.test(password)

const isPasswordCapitalLetter = (password: string): boolean =>
  /[A-Z]/.test(password)

const isPasswordLowercaseLetter = (password: string): boolean =>
  /[a-z]/.test(password)

const CancelButton = tw(Button)`ml-5`

const SubSectionContainer = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const SubSectionHeader = tw.h3`text-xl font-semibold mb-2`

const AccountInfoHeader = tw.h3`text-xl font-semibold`

const InfoContainer = tw.div`flex gap-4`

const ContactInfoContainer = tw.div`flex`

const AccountDisclosure = tw.p`text-gray-500`

const RadioContainer = tw.div`flex mt-1`

const PasswordTitle = tw.p`text-gray-600 mr-4 mt-3`

const PasswordContainer = tw.div`relative`

const PasswordError = styled.div<{ isFocused: boolean }>(({ isFocused }) => [
  tw`bg-white border shadow-lg transition-all p-2 absolute bottom-24`,
  isFocused && tw`invisible`,
])

const PasswordErrorTitle = tw.p`font-semibold`

const PasswordErrorContainer = tw.div`flex`
