import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { generateAuditable } from 'test-utils/api/utils'
import { success, vitalsApi, handleQueryError } from 'utils'

const useCreateAlertDeliveryRuleMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    CreateAlertDeliveryRuleResponse,
    KyError,
    createAlertDeliveryRuleProps,
    GetAlertDeliveryRulesResponse | undefined
  >(creatAlertDeliveryRule, {
    onMutate: ({ alertDeliveryRuleForm, orgId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<GetAlertDeliveryRulesResponse>([
        'getAlertDeliveryRules',
        orgId,
      ])

      // optimistic update the alert delivery rules query
      queryCache.setQueryData<GetAlertDeliveryRulesResponse | undefined>(
        ['getAlertDeliveryRules', orgId],
        (oldCache) =>
          oldCache
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items,
                  {
                    id: '123',
                    organizationId: orgId,
                    ...alertDeliveryRuleForm,
                    ...generateAuditable(),
                  },
                ],
              }
            : oldCache
      )

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Alert Delivery Rule created') })
    },
    onError: async (error, { orgId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetAlertDeliveryRulesResponse | undefined>(
        ['getAlertDeliveryRules', orgId],
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: (_, __, { orgId }) => {
      // refetch from API to make sure the alert contact list is in sync with the server
      queryCache.invalidateQueries(['getAlertDeliveryRules', orgId])
    },
  })

  return mutation
}

export default useCreateAlertDeliveryRuleMutation

type createAlertDeliveryRuleProps = {
  alertDeliveryRuleForm: AlertDeliveryRuleForm
  orgId: string
}

const creatAlertDeliveryRule = async ({
  alertDeliveryRuleForm,
  orgId,
}: createAlertDeliveryRuleProps) => {
  const request: CreateAlertDeliveryRuleRequest = alertDeliveryRuleForm
  const result = vitalsApi
    .post(`organizations/${orgId}/alert-delivery-rules`, {
      json: request,
    })
    .json<CreateAlertDeliveryRuleResponse>()

  return result
}
