import { useQuery } from 'react-query'
import { webhooksApi } from 'utils'

type getWebhookEventsArgs = {
  webhookCategoryId?: string
}

const useWebhookEventsQuery = ({ webhookCategoryId }: getWebhookEventsArgs) => {
  const query = useQuery<GetWebhookEventsResponse, KyError>(
    ['getWebhookEvents', webhookCategoryId],
    () => getWebhookEvents(webhookCategoryId),
    {
      enabled: !!webhookCategoryId,
    }
  )

  return query
}

export default useWebhookEventsQuery

const getWebhookEvents = async (webhookCategoryId?: string) => {
  const searchParams = [
    ['order_by', 'ASC'],
    ['take', '10000'],
  ]

  const result = webhooksApi
    .get(`api/webhook-categories/${webhookCategoryId}/webhook-events`, {
      searchParams,
    })
    .json<GetWebhookEventsResponse>()

  return result
}
