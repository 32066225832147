import React, { useState } from 'react'
import { Button, Empty } from 'atlas'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  ReminderPresetsList,
  CreateEditReminderPresetForm,
} from 'components/reminder-presets'
import { useReminderPresetsQuery } from 'hooks/reminder-presets'
import tw from 'twin.macro'
import { useNavigate } from 'hooks'

const ReminderPresets = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false)
  const [selectedReminderPreset, setSelectedReminderPreset] = useState<
    ReminderPreset | undefined
  >()

  const editReminderPreset = (selectedPreset: ReminderPreset) => {
    setSelectedReminderPreset(selectedPreset)
    setIsCreateFormOpen(true)
  }
  const reminderPresetQuery = useReminderPresetsQuery(
    assumedOrganizationRole?.orgID || ''
  )

  const handleFormToggle = (isFormOpen: boolean) => {
    // reset selected reminder preset when form is closing
    if (isFormOpen) setSelectedReminderPreset(undefined)

    setIsCreateFormOpen(isFormOpen)
  }

  return (
    <>
      <Layout
        title={t('Reminder Presets')}
        description={t(
          'Create and manage reminder presets available to your organization.'
        )}
        isLoading={reminderPresetQuery.isLoading}
        isEmpty={
          !reminderPresetQuery.data?.items ||
          reminderPresetQuery.data.items.length <= 0
        }
        emptyComponent={
          <ReminderPresetsEmpty
            title={t('No Data Found')}
            description={t('No reminder presets exist for this organization.')}
            callToAction={
              <Button
                type="primary-filled"
                onClick={() => handleFormToggle(true)}
              >
                {t('Create Reminder Preset')}
              </Button>
            }
          />
        }
        isUnauthorized={!assumedOrganizationRole}
        breadcrumbs={[
          { name: t('Organization Settings'), route: '/organization-settings' },
          { name: t('Reminder Presets') },
        ]}
        controls={[
          {
            label: t('Create Reminder Preset'),
            onClick: () => handleFormToggle(true),
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
          },
        ]}
      >
        <ReminderPresetsList editReminderPreset={editReminderPreset} />
      </Layout>
      <CreateEditReminderPresetForm
        isFormOpen={isCreateFormOpen}
        setIsFormOpen={handleFormToggle}
        currentReminderPreset={selectedReminderPreset}
      />
    </>
  )
}

export default ReminderPresets

const ReminderPresetsEmpty = tw(
  Empty
)`flex flex-grow border-2 border-dashed rounded`
