import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, handleQueryError, typedOrgsApi } from 'utils'

const useCreateStaffMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/staff-members'],
    KyError,
    CreateStaffProps
  >(createStaff, {
    onSuccess: (staffMember) => {
      const staffMembers:
        | OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members']
        | undefined = queryCache.getQueryData(['getStaffList'])

      if (staffMembers?.items) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members'] = {
          ...staffMembers,
          items: [staffMember, ...staffMembers.items],
        }
        // immediately update the staffMember list
        queryCache.setQueryData<
          OrgsAPIResponse['GET']['/api/organizations/{organizationId}/staff-members']
        >(['getStaffList'], updatedQueryCacheValue)
      }

      // refetch from API to make sure the staff member list is in sync with the server
      queryCache.invalidateQueries('getStaffList')

      // notify the user
      success({ message: t('Staff Member created') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useCreateStaffMutation

type CreateStaffProps = {
  staffData: CreateStaffMemberForm
  orgId: string
}

const createStaff = async ({ staffData, orgId }: CreateStaffProps) =>
  typedOrgsApi.post('/api/organizations/{organizationId}/staff-members', {
    pathParams: { organizationId: orgId },
    body: staffData,
  })
