import { useNavigate as useRouterNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useSearchParam } from 'react-use'

export type URLParamCompatible = Record<string, number | string | string[]>

const useNavigate = () => {
  const navigate = useRouterNavigate()

  // param that saves an order contract's organizationId
  const contractOrgId = useSearchParam('organizationId')
  const { contractId } = useParams()

  return (
    to:
      | {
          pathname?: string
          searchParams?: Partial<{ role: string }> & URLParamCompatible
          discardRole?: boolean
          hash?: string
        }
      | string
  ) => {
    // param that saves the assumed org role
    // manually find role in url instead of using a hook so this arrow function doesn't encapsulate outdated role
    const role = location.search.includes('role=')
      ? location.search
          .split('&')
          .find((search) => search.includes('role='))
          ?.split('role=')[1]
      : null

    const discardRole = typeof to !== 'string' && to.discardRole

    const newSearchParams = new URLSearchParams({
      // when navigating keep the assumed role persistent as a search param
      ...(role && !discardRole
        ? {
            role: role,
          }
        : {}),
      // when navigating within contract details keep the organizationId persistent as a search param
      ...(contractOrgId && contractId
        ? {
            organizationId: contractOrgId,
          }
        : {}),
      // add string searchParams to url
      ...(typeof to !== 'string' &&
        _.pickBy(
          to.searchParams,
          (paramVal) =>
            typeof paramVal === 'string' || typeof paramVal === 'number'
        )),
    })

    // add searchParams that are string arrays to url
    if (typeof to !== 'string')
      Object.entries(
        _.pickBy<URLParamCompatible[keyof URLParamCompatible], string[]>(
          to.searchParams,
          (paramVal): paramVal is string[] => Array.isArray(paramVal)
        )
      ).forEach(([key, paramVal]) =>
        paramVal.forEach((value) => newSearchParams.append(key, value))
      )

    const newUrl =
      // if 'to' is a string use it as pathname. If pathname not given don't change existing path
      (typeof to === 'string' ? to : to.pathname || location.pathname) +
      (newSearchParams.toString() ? '?' : '') +
      newSearchParams +
      // add fragment parameter to the url if one is provided
      (typeof to !== 'string' && to.hash ? '#' + to.hash : '')

    // if the url is identical don't add to history
    navigate(newUrl, { replace: newUrl === location.href })
  }
}
export default useNavigate
