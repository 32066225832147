import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

// used to get default reminders
const useReminderMessageTypesQuery = () => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/reminder-message-types'],
    KyError
  >(['getReminderMessageTypes'], getReminderMessageTypes)

  return query
}

export default useReminderMessageTypesQuery

// get admin reminders
const getReminderMessageTypes = async () => {
  const result = vitalsApi
    .get('reminder-message-types')
    .json<VitalsAPIResponse['GET']['/reminder-message-types']>()
  return result
}
