import { formatISO } from 'date-fns'

type generateArchivableArgs = {
  archived?: boolean
  archivedStatusChangedAt?: Date
}

const generateArchivable = ({
  archived,
  archivedStatusChangedAt,
}: generateArchivableArgs = {}) => {
  const result: Archivable = {
    archiveInfo: {
      archived: archived || false,
      archivedStatusChangedAt: archivedStatusChangedAt
        ? formatISO(archivedStatusChangedAt)
        : '',
    },
  }

  return result
}

export default generateArchivable
