import { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'hooks'
import { useUpdateEffect } from 'react-use'
import _ from 'lodash'
import { parseISO } from 'date-fns'

type URLSyncParams<S> =
  | {
      defaultValue: S
      urlObjKey: string
      isListFilters?: false
    }
  | {
      defaultValue: S
      urlObjKey?: undefined
      // TODO: when old List is no longer used remove this prop. It's no longer needed!
      isListFilters: true
    }

/**
 * Returns a stateful value synced with the URL, and a function to update it.
 */
type useURLSync =
  | (<S>(initialState: S | (() => S)) => [S, (newState: S) => void])
  | (<S = undefined>() => [S | undefined, (newState: S | undefined) => void])

const useURLSync = <S extends Record<string, unknown>>(
  params: URLSyncParams<S>
) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { defaultValue } = params

  const [syncObject, setSyncObject] = useState<S>({
    ...defaultValue,
    // any existing url state overwrites the defaultValue
    ...urlToObj(
      searchParams,
      // TODO: when able to upgrade Typescript destructure params normally
      params.isListFilters ? 'listFilters' : params.urlObjKey
    ),
  })

  const skipSyncToURL = useRef<boolean>(false)
  // update the url with the latest state of syncObject
  useUpdateEffect(() => {
    if (!skipSyncToURL.current)
      navigate({
        searchParams: objToUrl(
          syncObject,
          params.isListFilters ? 'listFilters' : params.urlObjKey
        ),
      })

    skipSyncToURL.current = false
  }, [syncObject])

  const prevSyncObj = useRef<S | undefined>(undefined)
  const prevHref = useRef<string>(location.href)

  // if the url changes but syncObj doesn't (i.e the user goes back in history) sync filter state to url params
  useUpdateEffect(() => {
    if (
      prevHref.current !== location.href &&
      _.isEqual(prevSyncObj.current, syncObject)
    ) {
      setSyncObject(
        urlToObj(
          searchParams,
          params.isListFilters ? 'listFilters' : params.urlObjKey
        )
      )
      // don't trigger an update to the url to preserve the current history
      skipSyncToURL.current = true
    }
    prevHref.current = location.href
    prevSyncObj.current = syncObject
  }, [location.href, syncObject])

  return [syncObject, setSyncObject] as const
}

export default useURLSync

export const urlToObj = (urlParams: URLSearchParams, urlObjKey: string) => {
  const urlValue = urlParams.get(urlObjKey)
  const urlObj = urlValue ? JSON.parse(urlValue) : {}

  // if the urlObj has a dateRangeState property manually convert the dates from string to Date
  if (Object.prototype.hasOwnProperty.call(urlObj, 'dateRangeState'))
    urlObj.dateRangeState = {
      ...urlObj.dateRangeState,
      value: urlObj.dateRangeState.value.map((dateVal: string) =>
        parseISO(dateVal)
      ),
    }

  return urlObj
}

export const objToUrl = (obj: Record<string, unknown>, urlObjKey: string) => ({
  [urlObjKey]: JSON.stringify(obj),
})
