import React, { useMemo } from 'react'
import { List, EntityLabel, CategoryFilter } from 'elements'
import { useTable, Column, useSortBy } from 'react-table'
import { useTranslation } from 'react-i18next'
import {
  usePinnedSubscribersQuery,
  usePinnedSubscribersMutation,
  useSubscribersQuery,
  useOrganizationListQuery,
} from 'hooks/organizations'
import i18next from 'i18next'
import { useNavigate, useURLSyncState } from 'hooks'
import { Pin } from 'animations'
import _ from 'lodash'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Chip } from 'atlas'

const SubscribersList = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const [
    subscriberFilters,
    setSubscriberFilters,
  ] = useURLSyncState<SubscriberFilters>({
    defaultValue: {
      skip: 0,
      order_by: 'ASC',
      orderByColId: 'SUBSCRIBER',
      take: 30,
    },
    isListFilters: true,
  })

  const organizations = useOrganizationListQuery()

  const subscribers = useSubscribersQuery({
    ...subscriberFilters,
    // if assuming an org role bypass the url params and directly send the orgId to query
    ...(assumedOrganizationRole
      ? { organizationId: assumedOrganizationRole.orgID }
      : {}),
  })
  const pinnedSubscribers = usePinnedSubscribersQuery()

  const pinnedSubscribersMutation = usePinnedSubscribersMutation()

  const tableData: Array<
    SubscriberDisplay & { pinned: boolean }
  > = useMemo(() => {
    const data = subscribers.data?.items?.map((subscriber) => ({
      ...subscriber,
      pinned: pinnedSubscribers.data?.includes(subscriber.id) || false,
    }))
    return _.orderBy(data, (sub) => !sub.pinned)
  }, [subscribers.data, i18next.language, pinnedSubscribers])

  const columns: Array<
    Column<SubscriberDisplay & { pinned: boolean }>
  > = useMemo(
    () => [
      {
        id: 'SUBSCRIBER',
        Header: t('SUBSCRIBER').toString(),
        accessor: (row) => (
          <EntityLabel
            name={`${row.person.firstName} ${row.person.lastName}`}
            id={row.id}
            bold
          />
        ),
      },
      {
        id: 'NONPERS',
        Header: t('PERS TYPE').toString(),
        accessor: (row) =>
          row.nonPERS ? (
            <Chip color="orange">{t('Non-PERS')}</Chip>
          ) : (
            <Chip color="lightblue">{t('PERS')}</Chip>
          ),
        Filter: (
          <CategoryFilter
            icon="radio"
            label={t('Non-PERS')}
            categories={[
              { label: t('PERS'), value: 'pers' },
              { label: t('Non-PERS'), value: 'nonPers' },
            ]}
            selectedCategories={
              subscriberFilters.nonPERS === undefined
                ? []
                : [
                    subscriberFilters.nonPERS
                      ? { label: t('Non-PERS'), value: 'nonPers' }
                      : { label: t('PERS'), value: 'pers' },
                  ]
            }
            setSelectedCategories={(selectedOptions) =>
              setSubscriberFilters({
                ...subscriberFilters,
                nonPERS: selectedOptions.length
                  ? [...selectedOptions].pop()?.value === 'nonPers'
                  : undefined,
                skip: 0,
              })
            }
          />
        ),
        disableSortBy: true,
      },
      ...(!assumedOrganizationRole
        ? [
            {
              id: 'ORGANIZATION',
              Header: t('ORGANIZATION'),
              accessor: (row) =>
                row.organization ? (
                  <EntityLabel
                    name={row.organization.businessName || ''}
                    id={row.organization.id || ''}
                    to={`/organizations/${row.organization.id}`}
                  />
                ) : (
                  '-'
                ),
              Filter: (
                <CategoryFilter
                  icon="organizations"
                  label={t('Organization')}
                  categories={organizations.data?.items.map((organization) => ({
                    label: organization.businessName,
                    value: organization.id,
                  }))}
                  selectedCategories={organizations.data?.items
                    .filter(
                      (organization) =>
                        subscriberFilters.organizationId === organization.id
                    )
                    .map((organization) => ({
                      label: organization.businessName,
                      value: organization.id,
                    }))}
                  setSelectedCategories={(selectedOrganizations) =>
                    setSubscriberFilters({
                      ...subscriberFilters,
                      organizationId:
                        selectedOrganizations[selectedOrganizations.length - 1]
                          ?.value,
                      skip: 0,
                    })
                  }
                />
              ),
              disableSortBy: true,
              width: '1fr',
            } as Column<SubscriberDisplay & { pinned: boolean }>,
          ]
        : []),
      {
        id: 'PINS',
        accessor: (row) => (
          <Pin
            testId={row.id}
            className="flex justify-end"
            pinned={row.pinned}
            onClick={() => {
              pinnedSubscribersMutation.mutate({
                action: row.pinned ? 'unpin' : 'pin',
                subscriberId: row.id,
              })
            }}
          />
        ),
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language, subscriberFilters, organizations.data]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetSortBy: false,
      manualSortBy: true,
      onRowClick: (row) => navigate(`/subscribers/${row.original.id}`),
      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'SUBSCRIBER',
            desc: false,
          },
        ],
      },
    },
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      // Only show loading on the initial loading but if there is previous data don't show loading
      isLoading={subscribers.isFetching}
      setSearchTerm={(searchTerm) =>
        setSubscriberFilters({
          ...subscriberFilters,
          searchTerm,
          skip: 0,
        })
      }
      searchTerm={subscriberFilters.searchTerm}
      searchPlaceholder={`${t('Search')} ${subscribers.data?.totalRecords} ${t(
        'subscribers'
      )}`}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
      paginatable
      paginationInfo={subscribers.data}
      onPageChange={(newPageNumber) => {
        setSubscriberFilters({
          ...subscriberFilters,
          skip: newPageNumber - 1,
        })
      }}
      urlState={subscriberFilters}
      setUrlState={setSubscriberFilters}
    />
  )
}

export default SubscribersList
