import React, { useEffect, useState } from 'react'
import { Switch, Icon, Select, SelectItem, TextField } from 'elements'
import { Button, Tooltip } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import tw, { styled } from 'twin.macro'
import { useProductsQuery } from 'hooks/seed-data'

type ProductCommissionFormProps = {
  isFormOpen: boolean
  isModalOpen: boolean
  productCommissions: CommissionForm[]
  setProductCommissions: (productCommissions: CommissionForm[]) => void
  productsWithNoCommissions: { id: string; name: string }[]
  productCommissionToEdit?: CommissionForm | undefined
  setIsFormOpen?: (isFormOpen: boolean) => void
}

const ProductCommissionForm = ({
  isFormOpen,
  isModalOpen,
  productCommissions,
  setProductCommissions,
  productsWithNoCommissions,
  productCommissionToEdit,
  setIsFormOpen,
}: ProductCommissionFormProps) => {
  const { t } = useTranslation()

  const { data: productList } = useProductsQuery()
  const [isOneTimeCommission, setIsActivationCommission] = useState<boolean>(
    false
  )
  const [isRecurringCommission, setIsRecurringCommission] = useState<boolean>(
    false
  )

  const formMethods = useForm<{
    product: string
    recurringCommissionAmount: undefined | string
    oneTimeCommissionAmount: undefined | string
  }>({
    defaultValues: {
      product: JSON.stringify(productsWithNoCommissions[0]),
    },
  })

  const { reset, watch } = formMethods

  // reset form fields if isModalOpen toggled true
  useEffect(() => {
    if (isModalOpen) {
      reset({
        product: JSON.stringify(productsWithNoCommissions[0]),
        oneTimeCommissionAmount: productCommissionToEdit
          ? String(productCommissionToEdit.oneTimeCommission.commissionAmount)
          : undefined,
        recurringCommissionAmount: productCommissionToEdit
          ? String(productCommissionToEdit.recurringCommission.commissionAmount)
          : undefined,
      })
      setIsActivationCommission(
        !!productCommissionToEdit?.oneTimeCommission.allowed
      )
      setIsRecurringCommission(
        !!productCommissionToEdit?.recurringCommission.allowed
      )
    }
  }, [isModalOpen, productList, productCommissionToEdit])

  return (
    <FormProvider {...formMethods}>
      <form>
        <FormContainer isFormOpen={isFormOpen}>
          {!productCommissionToEdit ? (
            <Controller
              render={({ onChange, value, name, ref }) => (
                <ProductSelect
                  name={name}
                  label="Product"
                  variant="outlined"
                  disabled={!!productCommissionToEdit}
                  onChange={(e) =>
                    onChange(
                      JSON.stringify(
                        productList?.items?.find(
                          (product) =>
                            product.id === JSON.parse(String(e.target.value)).id
                        )
                      )
                    )
                  }
                  value={value}
                  ref={ref}
                >
                  {productsWithNoCommissions.map((product, index) => (
                    <SelectItem value={JSON.stringify(product)} key={index}>
                      {product.name}
                    </SelectItem>
                  ))}
                </ProductSelect>
              )}
              name="product"
            />
          ) : null}
          <ActivationCommissionSwitchContainer>
            <Switch
              onChange={(e) => setIsActivationCommission(!!e)}
              checked={isOneTimeCommission}
              label={t('One-Time Activation Commission')}
              className="mr-2"
            />
            <StyledTooltip
              content={
                <TooltipText>{`${t(
                  'A one-time payout given after a subscriber is activated'
                )}.`}</TooltipText>
              }
            >
              <InfoIcon type="info" />
            </StyledTooltip>
          </ActivationCommissionSwitchContainer>
          {isOneTimeCommission ? (
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue={watch('oneTimeCommissionAmount')}
              name="oneTimeCommissionAmount"
              label={t('Activation Commission')}
              className="mt-4"
              type="number"
              startAdornment="$"
              data-testid="one-time-commission"
            />
          ) : null}
          <RecurringCommissionSwitchContainer>
            <Switch
              onChange={(e) => setIsRecurringCommission(!!e)}
              checked={isRecurringCommission}
              label={t('Recurring Commission')}
              className="mr-2"
            />
            <StyledTooltip
              content={
                <TooltipText>{`${t(
                  'A monthly recurring payout for as long as an onboarded subscriber is activated and paying'
                )}.`}</TooltipText>
              }
            >
              <InfoIcon type="info" />
            </StyledTooltip>
          </RecurringCommissionSwitchContainer>
          {isRecurringCommission ? (
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue={watch('recurringCommissionAmount')}
              name="recurringCommissionAmount"
              label={t('Recurring Commission')}
              className="mt-4"
              type="number"
              startAdornment="$"
              data-testid="recurring-commission"
            />
          ) : null}
          <ButtonContainer>
            <Button
              type="primary-filled"
              disabled={
                !(
                  (isRecurringCommission &&
                    watch('recurringCommissionAmount')) ||
                  (isOneTimeCommission && watch('oneTimeCommissionAmount'))
                )
              }
              onClick={() => {
                if (productCommissionToEdit) {
                  setProductCommissions(
                    productCommissions.map((productCommission) =>
                      productCommission.productId ===
                      productCommissionToEdit.productId
                        ? {
                            productId: productCommissionToEdit.productId,
                            recurringCommission: {
                              allowed: isRecurringCommission,
                              commissionAmount:
                                watch('recurringCommissionAmount') &&
                                isRecurringCommission
                                  ? Number(watch('recurringCommissionAmount'))
                                  : 0,
                            },
                            oneTimeCommission: {
                              allowed: isOneTimeCommission,
                              commissionAmount:
                                watch('oneTimeCommissionAmount') &&
                                isOneTimeCommission
                                  ? Number(watch('oneTimeCommissionAmount'))
                                  : 0,
                            },
                          }
                        : productCommission
                    )
                  )
                  setIsFormOpen && setIsFormOpen(false)
                } else {
                  setProductCommissions([
                    ...productCommissions,
                    {
                      productId: JSON.parse(watch('product')).id,
                      recurringCommission: {
                        allowed: isRecurringCommission,
                        commissionAmount:
                          watch('recurringCommissionAmount') &&
                          isRecurringCommission
                            ? Number(watch('recurringCommissionAmount'))
                            : 0,
                      },
                      oneTimeCommission: {
                        allowed: isOneTimeCommission,
                        commissionAmount:
                          watch('oneTimeCommissionAmount') &&
                          isOneTimeCommission
                            ? Number(watch('oneTimeCommissionAmount'))
                            : 0,
                      },
                    },
                  ])
                  const newProduct = productsWithNoCommissions.find(
                    (product) => product.id !== JSON.parse(watch('product')).id
                  )
                  reset({
                    product: newProduct
                      ? JSON.stringify(newProduct)
                      : undefined,
                    oneTimeCommissionAmount: undefined,
                    recurringCommissionAmount: undefined,
                  })
                }
                setIsActivationCommission(false)
                setIsRecurringCommission(false)
              }}
            >
              {productCommissionToEdit
                ? t('Update Commission')
                : t('Add Commission')}
            </Button>
            {setIsFormOpen ? (
              <>
                &nbsp;
                <Button
                  type="secondary"
                  onClick={() => {
                    // close form drawer
                    setIsFormOpen(false)
                  }}
                >
                  {t('Cancel')}
                </Button>
              </>
            ) : null}
          </ButtonContainer>
        </FormContainer>
      </form>
    </FormProvider>
  )
}

export default ProductCommissionForm

const FormContainer = styled.div<{ isFormOpen: boolean }>(({ isFormOpen }) => [
  tw`mt-4`,
  !isFormOpen && tw`hidden`,
])

const TooltipText = tw.p`w-60`

const InfoIcon = tw(Icon)`text-gray-600 w-5 h-5 align-middle`

const ButtonContainer = tw.div`flex my-4`

const RecurringCommissionSwitchContainer = tw.span`flex items-center mt-3`

const ActivationCommissionSwitchContainer = tw.span`flex items-center`

const StyledTooltip = tw(Tooltip)`flex items-center`

const ProductSelect = tw(Select)`mb-6`
