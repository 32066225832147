import React, { useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout, Stepper } from 'elements'
import { useAlertCategoryQuery } from 'hooks/alerts'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import clsx from 'clsx'
import { FadeInSlideDown } from 'animations'
import {
  CreateAlertDeliveryRuleFormBasicInformation,
  CreateAlertDeliveryRuleFormRecipients,
  CreateAlertDeliveryRuleFormSchedule,
} from 'components/settings-alert-delivery-rules'
import tw from 'twin.macro'

const CreateStaffForm = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const alertCategories = useAlertCategoryQuery()

  // Label Text and Icons for Stepper
  const steps: { label: string; icon: IconType }[] = [
    { label: t('Basic Information'), icon: 'user' },
    { label: t('Delivery Schedule'), icon: 'reminders' },
    { label: t('Recipients'), icon: 'subscribers' },
  ]

  const [activeStep, setActiveStep] = useState<number>(0)

  const formMethods = useForm<AlertDeliveryRuleFormLocal>({
    defaultValues: {
      name: '',
      description: '',
      criteria: {
        alertCategory: '',
        severity: 1,
      },
      schedule: {
        frequency: 'weekly',
        time: new Date(),
        interval: 1,
      },
      delivery: {
        contacts: [],
        message: {
          subject: '',
          message: '',
        },
      },
    },
  })

  // default case
  return (
    <Layout
      isLoading={alertCategories.isLoading}
      title={t('Create Alert Delivery Rule')}
      breadcrumbs={[
        { name: t('Organization Settings'), route: '../../' },
        {
          name: t('Alert Delivery Rules'),
          route: '../',
        },
        {
          name: t('Create Alert Delivery Rule'),
        },
      ]}
      isUnauthorized={!assumedOrganizationRole}
    >
      <AlertDeliveryFormContainer>
        <FadeInSlideDown>
          <Stepper steps={steps} activeStep={activeStep} className="mb-4" />
        </FadeInSlideDown>
        <FormProvider {...formMethods}>
          <CreateAlertDeliveryRuleFormBasicInformation
            nextStep={() => setActiveStep(1)}
            className={clsx(activeStep !== 0 && 'hidden')}
            aria-hidden={activeStep !== 0}
          />
          <CreateAlertDeliveryRuleFormSchedule
            previousStep={() => setActiveStep(0)}
            nextStep={() => setActiveStep(2)}
            className={clsx(activeStep !== 1 && 'hidden')}
            aria-hidden={activeStep !== 1}
          />
          <CreateAlertDeliveryRuleFormRecipients
            previousStep={() => setActiveStep(1)}
            className={clsx(activeStep !== 2 && 'hidden')}
            aria-hidden={activeStep !== 2}
          />
        </FormProvider>
      </AlertDeliveryFormContainer>
    </Layout>
  )
}

export default CreateStaffForm

const AlertDeliveryFormContainer = tw.div`flex flex-col flex-grow`
