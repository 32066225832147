import useMutation from 'hooks/useMutation'

const usePinnedOrganizationsMutation = () => {
  const mutation = useMutation<
    { result: boolean },
    pinnedOrganizationsMutationArgs
  >({
    mutationFn: pinnedOrganizationsMutation,
    additionalCachesToInvalidate: ['getPinnedOrganizations'],
  })

  return mutation
}

export default usePinnedOrganizationsMutation

type pinnedOrganizationsMutationArgs = {
  action: 'pin' | 'unpin'
  organizationId: string
}

const pinnedOrganizationsMutation = async ({
  action,
  organizationId,
}: pinnedOrganizationsMutationArgs) => {
  const rawOldValue = localStorage.getItem('orgPinned')
  const oldValue = rawOldValue ? JSON.parse(rawOldValue) : []

  if (action === 'pin') {
    localStorage.setItem(
      'orgPinned',
      JSON.stringify([...oldValue, organizationId])
    )

    return { result: true }
  }

  // case: action is 'unpin'
  localStorage.setItem(
    'orgPinned',
    JSON.stringify(oldValue.filter((item: string) => item !== organizationId))
  )

  return { result: true }
}
