import { vitalsApi } from 'utils'
import { useQuery } from 'react-query'

const useOrgVitalAssignmentsQuery = (orgId: string | undefined) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments'],
    KyError
  >(['getVitalAssignments', orgId], () => getOrgVitalSigns(orgId), {
    enabled: !!orgId,
  })

  return query
}

export default useOrgVitalAssignmentsQuery

// get list of vital signs from backend
const getOrgVitalSigns = async (orgId?: string) => {
  const result = await vitalsApi
    .get(`organizations/${orgId}/vital-sign-assignments`)
    .json<
      VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
    >()
    .catch(async (e) => {
      const error = await e.response.json()
      // TODO: Ask API team for a better error message when vital sign assignments are empty
      if (error.code === 404) {
        const result: VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments'] = {
          id: '',
          assignedVitalSigns: [],
          organizationId: orgId || '',
        }
        return result
      }

      throw e
    })

  return result
}
