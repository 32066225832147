import _ from 'lodash'

/**
 * @param alertCategory - an array of alert categories
 * @returns returns array of alert categories ordered by name
 */
const sortAlertCategories = (alertCategory: ReadonlyArray<AlertCategory>) => {
  const group: { undefined?: AlertCategory[] } & Record<
    string,
    AlertCategory[]
  > = _.groupBy(alertCategory, 'parentCategory.id')

  return _.flattenDeep(
    group.undefined?.map((gp) => [gp, _.orderBy(group[gp.id], 'name')])
  ).map((category, i) => {
    return {
      value: category.id || '',
      label: category.name || '',
      // TODO: bad swagger type: https://github.com/electronic-caregiver/svcs-api-vitals/issues/766
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      parent: category.parentCategory?.id,
      order: i,
    }
  })
}

export default sortAlertCategories
