import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useModulesQuery = () => {
  const query = useQuery<OrgsAPIResponse['GET']['/api/modules'], KyError>(
    'getModules',
    getModules
  )

  return query
}

export default useModulesQuery

const getModules = () =>
  typedOrgsApi.get('/api/modules', {
    searchParams: [
      ['include', ['permissions', 'applications']],
      ['order_by', 'ASC'],
      ['take', 10000],
    ],
  })
