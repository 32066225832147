import React, { useEffect, useState, useMemo } from 'react'
import {
  RightPopup,
  TextField,
  Checkbox,
  SimpleList,
  Icon,
  SearchBox,
  LoadingIcon,
} from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form'
import { usePoliciesQuery } from 'hooks/access-control/policies'
import {
  useAddPolicyGroupMutation,
  useUpdatePolicyGroupMutation,
} from 'hooks/access-control/policy-groups'
import _ from 'lodash'
import tw from 'twin.macro'

type CreateEditPolicyGroupFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  policyGroup?: PolicyGroup
}

const CreateEditPolicyGroupForm = ({
  isFormOpen,
  setIsFormOpen,
  policyGroup,
}: CreateEditPolicyGroupFormProps) => {
  const { data: policies, isLoading: isLoadingPolicies } = usePoliciesQuery()

  const [selectedPolicies, setSelectedPolicies] = useState<Policy[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [action, setAction] = useState<'add-another' | 'close'>()

  const { t } = useTranslation()
  // use useEffect to update-setValue to policies
  const formMethods = useForm<{
    policyGroupFields: CreatePolicyGroupForm
    availablePolicies: {
      values: { checked: boolean; policy: Policy }
    }[]
  }>({ defaultValues: { availablePolicies: [] } })
  const {
    handleSubmit,
    errors,
    reset,
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    trigger,
  } = formMethods

  const { fields } = useFieldArray({
    control,
    name: 'availablePolicies',
  })

  const policyField = useMemo(() => {
    const filteredFields = fields.filter((field) =>
      field.values.policy.name.toLowerCase().includes(searchValue.toLowerCase())
    )

    return filteredFields
  }, [searchValue, fields])

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen && policies?.items) {
      reset({
        policyGroupFields: {
          title: policyGroup?.title || '',
          code: policyGroup?.code || '',
          description: policyGroup?.description || '',
        },
        availablePolicies: resetAvailablePolicies(policies.items, policyGroup),
      })
      setSelectedPolicies(
        policyGroup?.policies && policyGroup.policies.length > 0
          ? policyGroup.policies
          : []
      )
    }
  }, [isFormOpen, policies?.items])

  const {
    mutate: addPolicyGroup,
    isLoading: isAddPolicyGroupLoading,
  } = useAddPolicyGroupMutation()

  const {
    mutate: updatePolicyGroup,
    isLoading: isUpdatePolicyGroupLoading,
  } = useUpdatePolicyGroupMutation()

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={policyGroup ? t('Edit Policy Group') : t('Create Policy Groups')}
      controls={
        <>
          {/* Create & Add Another Button (only shown for creating policies) */}
          {!policyGroup ? (
            <>
              <Button
                type="primary-filled"
                disabled={
                  (action !== 'add-another' && isUpdatePolicyGroupLoading) ||
                  selectedPolicies.length === 0
                }
                isLoading={action === 'add-another' && isAddPolicyGroupLoading}
                onClick={() => {
                  // If no policies have been added, set an error
                  if (selectedPolicies.length === 0) {
                    setError('policies', {
                      type: 'validate',
                      message: 'At least one policy must be attached',
                    })
                    // Trigger form validation on the rest of the fields as well
                    trigger()
                    return
                  }
                  setAction('add-another')
                  handleSubmit((formData) => {
                    addPolicyGroup(
                      {
                        ...formData.policyGroupFields,
                        policies: selectedPolicies.map((policy) => policy.id),
                      },
                      {
                        onSuccess: () => {
                          // reset the form (but don't close the form)
                          reset({
                            policyGroupFields: {
                              title: '',
                              code: '',
                              description: '',
                            },
                            availablePolicies: resetAvailablePolicies(
                              policies?.items || []
                            ),
                          })
                          setSelectedPolicies([])
                        },
                      }
                    )
                  })()
                }}
              >
                {t('Create & Add Another')}
              </Button>
              &nbsp;
            </>
          ) : null}
          {/* Create & Close / Update Button (shown for both) */}
          {policyGroup ? (
            <Button
              type="primary-filled"
              disabled={selectedPolicies.length === 0}
              isLoading={isUpdatePolicyGroupLoading}
              onClick={() => {
                // If no policies have been added, set an error
                if (selectedPolicies.length === 0) {
                  setError('policies', {
                    type: 'validate',
                    message: 'At least one policy must be attached',
                  })
                  // Trigger form validation on the rest of the fields as well
                  trigger()
                  return
                }
                handleSubmit((formData) => {
                  updatePolicyGroup(
                    {
                      policyGroupData: {
                        ...formData.policyGroupFields,
                        policies: selectedPolicies.map((policy) => policy.id),
                      },
                      existingPolicyGroup: policyGroup,
                    },
                    {
                      onSuccess: () => setIsFormOpen(false),
                    }
                  )
                })()
              }}
            >
              {t('Update')}
            </Button>
          ) : (
            <Button
              type="primary-filled"
              disabled={
                (action !== 'close' && isAddPolicyGroupLoading) ||
                selectedPolicies.length === 0
              }
              isLoading={action === 'close' && isAddPolicyGroupLoading}
              onClick={() => {
                // If no policies have been added, set an error
                if (selectedPolicies.length === 0) {
                  setError('policies', {
                    type: 'validate',
                    message: 'At least one policy must be attached',
                  })
                  // Trigger form validation on the rest of the fields as well
                  trigger()
                  return
                }
                setAction('close')
                handleSubmit((formData) => {
                  addPolicyGroup(
                    {
                      ...formData.policyGroupFields,
                      policies: selectedPolicies.map((policy) => policy.id),
                    },
                    {
                      onSuccess: () => setIsFormOpen(false),
                    }
                  )
                })()
              }}
            >
              {t('Create & Close')}
            </Button>
          )}
          &nbsp;
          {/* Cancel Button (shown for both) */}
          <Button
            type="secondary"
            disabled={isAddPolicyGroupLoading || isUpdatePolicyGroupLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {isLoadingPolicies || !policies ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Policy Group Title is required') }}
              name="policyGroupFields.title"
              label={t('Policy Group Title')}
              fullWidth
              error={errors.policyGroupFields?.title?.message}
              required
            />

            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Policy Group Code is required') }}
              name="policyGroupFields.code"
              label={t('Policy Group Code')}
              fullWidth
              error={errors.policyGroupFields?.code?.message}
              required
            />

            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="policyGroupFields.description"
              label={t('Description')}
              fullWidth
              multiline
              rows={4}
              error={errors.policyGroupFields?.description?.message}
            />
            {/* POLICIES SELECT */}

            <PoliciesTitle>
              {t('Add Policies')}{' '}
              <PolicyErrors>
                {_.get(errors, 'policies') && _.get(errors, 'policies.message')}
              </PolicyErrors>
            </PoliciesTitle>
            <SearchBox
              className="flex-grow self-end mr-2 mb-4"
              placeholder="Search policies"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {policies.items && policies.items.length > 0 ? (
              <>
                <PoliciesCard>
                  {policyField.length > 0 ? (
                    // Sort checkboxes so that when adding and removing, order is preserved
                    _.sortBy(policyField, (field) =>
                      _.lowerCase(field.values.policy.name)
                    ).map((avaliablePolicy, index) => (
                      <Controller
                        key={avaliablePolicy.values.policy.id}
                        name={`availablePolicies[${index}].values`}
                        defaultValue={avaliablePolicy.values}
                        render={({
                          onChange,
                          value,
                          name,
                          ref,
                        }: CheckboxRenderArgs<{
                          policy: Policy
                          checked: boolean
                        }>) => {
                          return (
                            <Checkbox
                              onChange={(e) => {
                                onChange({
                                  policy: JSON.parse(e.target.value).policy,
                                  checked: e.target.checked,
                                })
                              }}
                              value={value}
                              checked={!!value?.checked}
                              ref={ref}
                              label={
                                <p>
                                  {avaliablePolicy.values.policy.name}{' '}
                                  <PolicyCode>
                                    ({avaliablePolicy.values.policy.code})
                                  </PolicyCode>
                                </p>
                              }
                              name={name}
                              className="text-gray-900 py-1 hover:bg-gray-200 px-4"
                            />
                          )
                        }}
                      />
                    ))
                  ) : (
                    <Empty
                      title={t('No Data Found')}
                      description={t(
                        'No policies exist that are available to add'
                      )}
                    />
                  )}
                </PoliciesCard>
                <Button
                  disabled={
                    // disable if no policies are checked
                    watch('availablePolicies').every(
                      (avaliablePolicy) => !avaliablePolicy.values.checked
                    )
                  }
                  tw="my-4"
                  onClick={() => {
                    // add availablePolicies that are checked to array of selectedPolicies
                    setSelectedPolicies([
                      ...selectedPolicies,
                      ...watch('availablePolicies')
                        .filter(
                          (availablePolicy) => availablePolicy.values.checked
                        )
                        .map(
                          (availablePolicy) => availablePolicy.values.policy
                        ),
                    ])
                    // remove them from availablePolicies
                    setValue(
                      'availablePolicies',
                      watch('availablePolicies').filter(
                        (avaliablePolicy) => !avaliablePolicy.values.checked
                      )
                    )
                    // clear any error related to no policies being added
                    clearErrors('policies')
                  }}
                >
                  {t('Add Selected Policies To Policy Group')}
                </Button>
                <SelectedPoliciesTitle>
                  {t('Selected Policies')}
                </SelectedPoliciesTitle>
                {/* SELECTED POLICIES */}
                <PoliciesCard>
                  {selectedPolicies.length > 0 ? (
                    <>
                      <SimpleList
                        data={selectedPolicies}
                        animate={false}
                        renderItem={(policy) => (
                          <ListItemContainer
                            onClick={() => {
                              // remove this policy from selectedPolicies
                              setSelectedPolicies(
                                selectedPolicies.filter(
                                  (selectedPolicy) =>
                                    selectedPolicy.id !== policy.id
                                )
                              )
                              // add this policy back to availablePolicies
                              setValue('availablePolicies', [
                                ...watch('availablePolicies'),
                                { values: { checked: false, policy } },
                              ])
                            }}
                          >
                            <TrashIcon type="trash" />
                            <p>
                              {policy.name}{' '}
                              <PolicyCode>({policy.code})</PolicyCode>
                            </p>
                          </ListItemContainer>
                        )}
                      />
                    </>
                  ) : (
                    <Empty title={t('No Policies Selected')} />
                  )}
                </PoliciesCard>
              </>
            ) : (
              <Empty
                title={t('No Policies Found')}
                description={t(
                  'At least one policy must exist in order to assign policies to a policy group'
                )}
                callToAction={
                  <Button type="primary-filled" to="/access-control/policies">
                    {t('Create Policies')}
                  </Button>
                }
              />
            )}
          </form>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default CreateEditPolicyGroupForm

const resetAvailablePolicies = (
  policies: Policy[],
  policyGroup?: PolicyGroup | undefined
) =>
  policies
    .filter(
      (policy) =>
        // only include active policies
        policy.activeInfo.active &&
        // don't include policies that are already attached to the policyGroup
        !policyGroup?.policies.map((policy) => policy.id).includes(policy.id)
    )
    .map((policy) => ({
      values: {
        checked: !!policyGroup?.policies
          .map((policy) => policy.id)
          .includes(policy.id),
        policy,
      },
    }))

const PoliciesTitle = tw.p`inline-flex items-center gap-2 text-xl mb-2`

const PolicyErrors = tw.span`text-sm text-red-600`

const PoliciesCard = tw.div`h-32 overflow-auto bg-gray-50 rounded border border-gray-300`

const PolicyCode = tw.span`text-gray-500`

const SelectedPoliciesTitle = tw.div`text-xl mb-2 mt-4`

const ListItemContainer = tw.div`text-gray-900 py-1 hover:bg-gray-200 px-4 flex items-center cursor-pointer`

const TrashIcon = tw(Icon)`mr-4 text-gray-500 flex-shrink-0`
