import React, { useState } from 'react'
import {
  Layout,
  ConfirmationDialogs,
  LoadingIcon,
  EntityStatusChip,
} from 'elements'
import { Button, Alert } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import {
  useActivatePartnerTypeMutation,
  useDeactivatePartnerTypeMutation,
  usePartnerTypeCommissionsQuery,
  usePartnerTypeQuery,
  usePartnerTypeChildrenQuery,
  usePartnerTypeIndirectCommissionsQuery,
  useDeletePartnerTypeMutation,
} from 'hooks/partners'
import {
  PartnerTypeAssociationsGrid,
  PartnerTypeDirectCommissions,
  PartnerTypeForm,
  PartnerTypeIndirectCommissions,
} from 'components/partners'
import { useDelay, useNavigate } from 'hooks'
import { FadeInSlideDown } from 'animations'
import { useProductsQuery } from 'hooks/seed-data'

const PartnerTypeDetails = () => {
  const { partnerTypeId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const delay = useDelay({ interval: 0.05 })

  const { mutate: activatePartnerType } = useActivatePartnerTypeMutation()
  const { mutate: deactivatePartnerType } = useDeactivatePartnerTypeMutation()
  const { mutate: deletePartnerType } = useDeletePartnerTypeMutation()

  // this query is only called to keep animation cascade smooth by keeping children from rendering later
  const { data: productList } = useProductsQuery()

  const { data: currentPartnerType } = usePartnerTypeQuery(partnerTypeId)
  const { data: partnerTypeChildren } = usePartnerTypeChildrenQuery(
    partnerTypeId
  )
  const { data: partnerTypeCommissions } = usePartnerTypeCommissionsQuery(
    currentPartnerType?.id
  )
  const {
    data: partnerTypeIndirectCommissions,
  } = usePartnerTypeIndirectCommissionsQuery(currentPartnerType?.id)

  const { data: accountPermissions } = useAccountPermissionsQuery()

  const [isPartnerTypeFormOpen, setIsPartnerTypeFormOpen] = useState<boolean>(
    false
  )
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [
    isCreateCommissionsFormOpen,
    setIsCreateCommissionsFormOpen,
  ] = useState(false)

  const isPartnerTypeActive = currentPartnerType?.activeInfo.active

  if (
    !currentPartnerType ||
    !partnerTypeChildren ||
    !partnerTypeCommissions ||
    !productList
  )
    return <LoadingIcon />

  const confirmations = [
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${currentPartnerType.name}" Partner Type`),
      content: (
        <p>{t('Are you sure you want to deactivate this partner type?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            deactivatePartnerType(currentPartnerType.id)

            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${currentPartnerType.name}" Partner Type`),
      content: (
        <p>{t('Are you sure you want to activate this partner type?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            activatePartnerType(currentPartnerType.id)

            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${currentPartnerType.name}" Partner Type`),
      content: <p>{t('Are you sure you want to delete this partner type?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deletePartnerType(currentPartnerType.id)

            setIsDeleteDialogOpen(false)

            navigate({
              pathname: `../`,
            })
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <>
      <Layout
        title={
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-bold">{currentPartnerType.name}</h1>
            &nbsp;
            <EntityStatusChip
              isActive={!!isPartnerTypeActive}
              isArchived={false}
            />
          </div>
        }
        description={currentPartnerType.description}
        isUnauthorized={
          !accountPermissions?.data.ecgAdmin.permissions.includes(
            'acm.readonly'
          )
        }
        breadcrumbs={[
          {
            name: t('Partners'),
            route: '/partners',
          },
          {
            name: t('Partner Types'),
            route: '/partners/partner-types',
          },
          {
            name: currentPartnerType.name || '',
          },
        ]}
        controls={
          // acm.admin required for these actions
          accountPermissions?.data.ecgAdmin.permissions.includes('acm.admin')
            ? [
                {
                  label: t('Edit'),
                  onClick: () => setIsPartnerTypeFormOpen(true),
                },
                ...(isPartnerTypeActive
                  ? [
                      {
                        label: t('Deactivate'),
                        onClick: () => setIsDeactivateDialogOpen(true),
                      },
                    ]
                  : [
                      {
                        label: t('Activate'),
                        onClick: () => () => setIsActivateDialogOpen(true),
                      },
                    ]),
                {
                  label: t('Delete'),
                  onClick: () => setIsDeleteDialogOpen(true),
                },
              ]
            : null
        }
      >
        {!partnerTypeCommissions.length ? (
          <FadeInSlideDown delay={delay()}>
            <Alert
              message={t('Direct commissions setup incomplete')}
              type="warning"
              onClick={() => setIsCreateCommissionsFormOpen(true)}
            />
          </FadeInSlideDown>
        ) : null}
        {partnerTypeChildren
          .filter(
            (partnerTypeChild) =>
              !partnerTypeIndirectCommissions?.find(
                (indirectCommission) =>
                  indirectCommission.childPartnerType.id === partnerTypeChild.id
              )
          )
          .map((childPartnerType, index) => (
            <FadeInSlideDown delay={delay()} key={index}>
              <Alert
                message={`${t('Commissions setup for child partner type')} ${
                  childPartnerType.name
                } ${t('are incomplete')}`}
                type="warning"
                onClick={() =>
                  navigate({
                    pathname: `../${childPartnerType.id}`,
                  })
                }
              />
            </FadeInSlideDown>
          ))}
        <PartnerTypeAssociationsGrid
          parentPartnerType={currentPartnerType}
          childPartnerTypes={partnerTypeChildren}
          delay={delay}
        />
        <PartnerTypeDirectCommissions
          currentPartnerType={currentPartnerType}
          partnerTypeCommissions={partnerTypeCommissions}
          delay={delay}
          isCreateCommissionsFormOpen={isCreateCommissionsFormOpen}
          setIsCreateCommissionsFormOpen={(isFormOpen) =>
            setIsCreateCommissionsFormOpen(isFormOpen)
          }
        />
        <PartnerTypeIndirectCommissions
          currentPartnerType={currentPartnerType}
          childPartnerTypes={partnerTypeChildren}
          delay={delay}
        />
      </Layout>

      <PartnerTypeForm
        isFormOpen={isPartnerTypeFormOpen}
        setIsFormOpen={setIsPartnerTypeFormOpen}
        partnerType={currentPartnerType}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </>
  )
}

export default PartnerTypeDetails
