import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useRestoreModuleMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/restore'],
    restoreModuleArgs
  >({
    mutationFn: restoreModule,
    successMsg: t('Module restored'),
    additionalCachesToInvalidate: ['getPermissions'],
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          restoreModuleArgs
        >((oldCache, { moduleId }) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items?.map((module) => {
                  if (module.id === moduleId)
                    return {
                      ...module,
                      archiveInfo: {
                        ...module.archiveInfo,
                        archived: false,
                      },
                    }

                  return module
                }),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useRestoreModuleMutation

type restoreModuleArgs = {
  moduleId: string
}

const restoreModule = ({ moduleId }: restoreModuleArgs) =>
  typedOrgsApi.post('/api/modules/{moduleId}/restore', {
    pathParams: { moduleId },
  })
