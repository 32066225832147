import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { StaffList } from 'components/staff'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { Button } from 'atlas'
import { Layout, Unauthorized } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

const Staff = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { t } = useTranslation()

  // ECG Admin case (ECG Admin that's NOT assuming an organization role)
  if (
    !assumedOrganizationRole &&
    accountPermissions?.data.ecgAdmin.permissions.length !== 0
  )
    return (
      <Layout
        title="Staff"
        description={t(
          'Manage and add new staff members to your organization.'
        )}
      >
        <Unauthorized />
      </Layout>
    )

  // default case
  return (
    <Layout
      title={t('Staff')}
      description={t('Manage and add new staff members to your organization.')}
      controls={
        <Button type="primary-filled" to="create-staff-member">
          {t('Create Staff Member')}
        </Button>
      }
    >
      <StaffListContainer>
        <StaffList />
      </StaffListContainer>
    </Layout>
  )
}

export default Staff

const StaffListContainer = tw.div`flex flex-col flex-grow`
