import { FadeInSlideDown } from 'animations'
import { DayOfTheWeekToggleGroup } from 'components/reminder-presets'
import { Select, SelectItem, TimePicker, AutoComplete } from 'elements'
import { Button } from 'atlas'
import { useDelay } from 'hooks'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'

type CreateAlertDeliveryRuleFormScheduleProps = {
  previousStep: () => void
  nextStep: () => void
  className?: string
}

const CreateAlertDeliveryRuleFormSchedule = ({
  previousStep,
  nextStep,
  className,
}: CreateAlertDeliveryRuleFormScheduleProps) => {
  const { t } = useTranslation()
  const delay = useDelay()

  const {
    watch,
    errors,
    trigger,
    setValue,
  } = useFormContext<AlertDeliveryRuleFormLocal>()

  const [toggled, setToggled] = useState<Weekdays[]>(['mon'])
  const [timePicker, setTimePicker] = useState<Date>(new Date())

  // clear the interval autocomplete
  useEffect(() => {
    setValue('schedule.interval', '')
  }, [watch('schedule.frequency')])

  return (
    <div className={className}>
      <FadeInSlideDown delay={delay()}>
        <DeliveryScheduleCard>
          <DeliveryScheduleTitle>
            {t('Delivery Schedule')}
          </DeliveryScheduleTitle>
          <DeliverySelect>
            <Controller
              as={
                <Select
                  defaultValue="weekly"
                  variant="outlined"
                  className="w-64 mr-6"
                >
                  <SelectItem value="real-time">{t('Real-Time')}</SelectItem>
                  <SelectItem value="minutely">{t('Minutely')}</SelectItem>
                  <SelectItem value="hourly">{t('Hourly')}</SelectItem>
                  <SelectItem value="daily">{t('Daily')}</SelectItem>
                  <SelectItem value="weekly">{t('Weekly')}</SelectItem>
                </Select>
              }
              label={t('Alert Delivery Frequency')}
              name="schedule.frequency"
              defaultValue="weekly"
            />
            <DeliveryTimeContainer
              hidden={
                watch('schedule.frequency') === 'real-time' ||
                watch('schedule.frequency') === 'minutely' ||
                watch('schedule.frequency') === 'hourly'
              }
            >
              <Controller
                as={TimePicker}
                value={timePicker}
                onChange={(e: Date | null) => e && setTimePicker(e)}
                label={t('Delivery Time')}
                name="schedule.time"
                rules={{ pattern: { value: /an/, message: 'text' } }}
                defaultValue={new Date()}
              />
            </DeliveryTimeContainer>
          </DeliverySelect>
          <DeliveryAutoComplete>
            {watch('schedule.frequency') === 'real-time' ? null : watch(
                'schedule.frequency'
              ) !== 'weekly' ? (
              <DeliveryController>
                <Controller
                  render={({ onChange, value }) => (
                    <AutoComplete
                      single
                      error={errors.schedule?.interval?.message}
                      selectedOption={value}
                      label={t('Delivery Interval')}
                      placeholder={t('Select Interval')}
                      onChange={onChange}
                      options={_.times(
                        watch('schedule.frequency') === 'daily'
                          ? 6
                          : watch('schedule.frequency') === 'hourly'
                          ? 23
                          : 59,
                        (i) => (i + 1).toString()
                      )}
                      optionLabel={(option) =>
                        `${option} ${
                          watch('schedule.frequency') === 'daily'
                            ? 'Day'
                            : watch('schedule.frequency') === 'hourly'
                            ? 'Hour'
                            : 'Minute'
                        }${option !== '1' ? 's' : ''}`
                      }
                    />
                  )}
                  //@ts-expect-error it's fine if the required message is undefined
                  rules={{ required: t('Interval is required') }}
                  name="schedule.interval"
                />
              </DeliveryController>
            ) : (
              <Controller
                render={({ onChange, name }) => (
                  <DayOfTheWeekToggleGroup
                    onChange={onChange}
                    name={name}
                    label={t('Delivery Days')}
                    setToggled={setToggled}
                    toggled={toggled}
                  />
                )}
                name="schedule.weekdays"
                defaultValue={['mon']}
              />
            )}
          </DeliveryAutoComplete>
        </DeliveryScheduleCard>
      </FadeInSlideDown>
      <FadeInSlideDown delay={delay()}>
        <Button to="../">{t('Cancel')}</Button>
        &nbsp;
        <Button onClick={previousStep}>{t('Back')}</Button>&nbsp;
        <Button
          onClick={async () => {
            if (
              watch('schedule.frequency') === 'weekly' ||
              watch('schedule.frequency') === 'real-time' ||
              (await trigger(['schedule.interval']))
            )
              nextStep()
          }}
          type="primary-filled"
          data-testid="basic-information-continue"
        >
          {t('Save & Continue')}
        </Button>
      </FadeInSlideDown>
    </div>
  )
}

export default CreateAlertDeliveryRuleFormSchedule

const DeliveryTimeContainer = styled.div<{ hidden: boolean }>(({ hidden }) => [
  hidden && tw`hidden`,
])

const DeliveryScheduleCard = tw.div`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const DeliveryScheduleTitle = tw.h3`text-xl font-semibold mb-4`

const DeliverySelect = tw.div`flex gap-4 items-center`

const DeliveryAutoComplete = tw.div`mt-8`

const DeliveryController = tw.div`flex items-center`
