import React, { ReactNode, useRef } from 'react'
import { Drawer } from '@mui/material'
import { Icon } from 'elements'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import tw, { styled } from 'twin.macro'

type RightPopupProps = {
  open: boolean
  setOpen: (newValue: boolean) => void
  children: ReactNode
  title?: string
  description?: string
  width?: string
  contentFullHeight?: boolean
  disableScroll?: boolean
  controls?: ReactNode
}

const RightPopup = ({
  open,
  setOpen,
  children,
  title,
  description,
  width = '600px',
  contentFullHeight = false,
  disableScroll,
  controls,
}: RightPopupProps) => {
  // const ref = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<OverlayScrollbarsComponent>(null)
  return (
    <Drawer open={open} anchor="right" className="relative">
      <OverlayScrollbarContainer
        ref={scrollRef}
        options={{
          scrollbars: { autoHide: 'scroll' },
          overflowBehavior: {
            x: disableScroll ? 'hidden' : 'scroll',
            y: disableScroll ? 'hidden' : 'scroll',
          },
          callbacks: {
            // scroll to top of Header, when scroll is set to hidden
            onOverflowChanged: (event) => {
              if (!event?.yScrollable) {
                scrollRef.current?.osInstance()?.scroll('0%')
              }
            },
          },
        }}
        width={width}
      >
        <Header>
          <Title>{title}</Title>
          <Icon type="x" onClick={() => setOpen(false)} className="text-xl" />
        </Header>
        <Description>{description}</Description>
        <Content contentFullHeight={contentFullHeight}>{children}</Content>
      </OverlayScrollbarContainer>
      {controls ? <Controls>{controls}</Controls> : null}
    </Drawer>
  )
}

export default RightPopup

const OverlayScrollbarContainer = styled(OverlayScrollbarsComponent)<{
  width: string
}>`
  ${tw`p-6 h-full`}
  ${({ width }) => `
    width: min(${width}, 100vw);
  `}
`

const Header = tw.div`flex justify-between items-center`

const Title = tw.h1`text-2xl font-semibold`

const Description = tw.p`text-lg text-gray-600 mb-6`

const Content = styled.div<{ contentFullHeight: boolean }>`
  ${tw`pb-6`} ${({ contentFullHeight }) =>
    contentFullHeight &&
    `height: calc(100% - 64px);`} /* Need to subtract height of title and description to prevent scroll when fullHeight */
`

const Controls = styled.div(() => [
  tw`p-5 border-t border-gray-200`,
  `
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.05);
  `,
])
