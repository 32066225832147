import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useAssignVitalSignsMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['POST']['/organizations/{organizationId}/vital-signs'],
    KyError<ErrorResponse>,
    AssignVitalSignProps,
    | VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
    | undefined
  >(assignVitalSign, {
    onMutate: ({ orgId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
      >(['getVitalAssignments', orgId])

      // optimistic update
      // TODO: figure out how to OU here if this mutation is needed long term

      return snapshot
    },
    onSuccess: () => {
      // notify user of successful vital sign assignation
      success({
        message: t('Vital sign assigned'),
      })
    },
    onError: async (error, { orgId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/vital-sign-assignments']
        | undefined
      >(['getVitalAssignments', orgId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, { orgId }) => {
      // refetch from API to make sure the new vital sign is in sync with the server
      queryCache.invalidateQueries(['getVitalAssignments', orgId])
    },
  })

  return mutation
}

export default useAssignVitalSignsMutation

type AssignVitalSignProps = {
  newVitalSigns: VitalAssignmentForm[]
  orgId: string
}

// Post assigned vital sign to backend
const assignVitalSign = async ({
  newVitalSigns,
  orgId,
}: AssignVitalSignProps) => {
  const request = { vitalSigns: newVitalSigns }

  const result = await vitalsApi
    .post(`organizations/${orgId}/vital-signs`, {
      json: request,
    })
    .json<
      VitalsAPIResponse['POST']['/organizations/{organizationId}/vital-signs']
    >()

  return result
}
