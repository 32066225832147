import React, { useState } from 'react'
import { EntityLabel, Icon, LoadingIcon } from 'elements'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { useStaffListQuery } from 'hooks/user-staff'
import { useNavigate } from 'hooks'
import EditRecipientsForm from './EditRecipientsForm'

type AlertDeliveryRuleRecipientsProps = {
  alertDeliveryRule: AlertDeliveryRule
}

const AlertDeliveryRuleRecipients = ({
  alertDeliveryRule,
}: AlertDeliveryRuleRecipientsProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  const { data: staffList, isLoading: isLoadingStaff } = useStaffListQuery(
    assumedOrganizationRole?.orgID
  )

  if (isLoadingStaff) return <LoadingIcon />

  return (
    <>
      <Header>
        <Title>{t('Recipients')}</Title>
        <EditIcon
          type="edit"
          onClick={() => setIsFormOpen(true)}
          data-testid="recipients-edit"
        />
      </Header>
      <Grid>
        {alertDeliveryRule.delivery.contacts.map((contact) => {
          const staff = staffList?.items?.find(
            (staff) => staff.id === contact.id
          )
          return staff ? (
            <Card
              onClick={() =>
                navigate({
                  pathname: `/staff/${staff.id}`,
                })
              }
            >
              <EntityLabel
                name={`${staff.person.firstName} ${staff.person.lastName}`}
                id={staff.id}
              />
              &nbsp;<p>({contact.email})</p>
            </Card>
          ) : null
        })}
      </Grid>
      <EditRecipientsForm
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        alertDeliveryRule={alertDeliveryRule}
      />
    </>
  )
}

export default AlertDeliveryRuleRecipients

const Header = tw.div`flex items-center`

const Title = tw.h3`text-xl font-semibold mb-2`

const EditIcon = tw(Icon)`text-gray-600 hover:text-gray-900 mb-2 ml-2`

const Card = tw.div`bg-white flex p-4 rounded border border-gray-200 transition-all cursor-pointer hover:shadow-lg`

const Grid = tw.div`grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2`
