import React, { useMemo, useCallback, useState } from 'react'
import { List, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  useRestoreApplicationMutation,
  useDeleteApplicationMutation,
} from 'hooks/access-control/applications'

type ArchivedApplicationsListProps = {
  data: Application[]
  onEditApplication: (application: Application) => void
}

const ArchivedApplicationsList = ({
  data,
  onEditApplication,
}: ArchivedApplicationsListProps) => {
  const { t } = useTranslation()

  const tableData: Application[] = useMemo(() => {
    return data
  }, [data])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<Application>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const cognitoAppId = row.original.cognitoAppId.toLowerCase()
        const description = row.original.description.toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          cognitoAppId.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )

  const columns: Column<Application>[] = useMemo(
    () =>
      [
        {
          id: 'NAME',
          Header: t('NAME'),
          accessor: 'name',
        },
        {
          id: 'COGNITO_APP_ID',
          Header: t('COGNITO APP ID'),
          accessor: 'cognitoAppId',
        },
        {
          id: 'DESCRIPTION',
          Header: t('DESCRIPTION'),
          accessor: 'description',
          width: '2fr',
        },
        {
          id: 'STATUS',
          Header: t('STATUS'),
          accessor: () => <Chip color="gray">{t('Archived')}</Chip>,
          width: '80px',
          disableSortBy: true,
        },
        {
          id: 'ACTIONS',
          Header: '',
          accessor: (row) => (
            <ActionsCell row={row} onEdit={() => onEditApplication(row)} />
          ),
          disableSortBy: true,
          width: '30px',
        },
      ] as Column<Application>[],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'applications'
      )}`}
      emptyTitle={t('No Applications Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default ArchivedApplicationsList

type ActionsCellProps = {
  row: Application
  onEdit: () => void
}

const ActionsCell = ({ row, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const { mutate: deleteApplication } = useDeleteApplicationMutation()
  const { mutate: restoreApplication } = useRestoreApplicationMutation()

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${row.name}" application`),
      content: <p>{t('Are you sure you want to restore this application?')}</p>,
      actions: (
        <Button
          onClick={() => {
            restoreApplication(row.id)

            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${row.name}" application`),
      content: <p>{t('Are you sure you want to delete this application?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deleteApplication({ applicationId: row.id })

            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: () => {
              onEdit()
            },
          },
          {
            label: t('Restore'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsRestoreDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
          {
            label: t('Delete'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDeleteDialogOpen(true)

              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
