import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useUpdateOrderLineItemsMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    Array<
      | OrdersAPIResponse['POST']['/orders/{orderId}/line-items']
      | OrdersAPIResponse['DELETE']['/orders/{orderId}/line-items/{lineItemId}/delete']
      | OrdersAPIResponse['PATCH']['/orders/{orderId}/line-items/{lineItemId}']
    >,
    updateOrderLineItemsArgs
  >({
    mutationFn: updateOrderLineItems,
    successMsg: { message: t('Order updated'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        cacheKey: ({ order }) => ['order', { orderId: order?.id }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          updateOrderLineItemsArgs
        >((oldCache, { newLineItems }) => ({
          ...oldCache,
          orderLineItems: newLineItems.map((lineItem) => {
            const existingLineItem = oldCache?.orderLineItems?.find(
              (existingLineItem) =>
                existingLineItem.productId === lineItem.productId
            )
            return {
              ...existingLineItem,
              productId: lineItem.productId,
              priceSelection: {
                ...existingLineItem?.priceSelection,
                warrantySelection: lineItem.warrantySelection,
              },
            }
          }),
        })),
      },
    ],
  })

  return mutation
}

export default useUpdateOrderLineItemsMutation

type updateOrderLineItemsArgs = {
  newLineItems: OrderLineItemForm[]
  order: Order | undefined
}

const updateOrderLineItems = async ({
  newLineItems,
  order,
}: updateOrderLineItemsArgs) => {
  const newLineItemProductIds = newLineItems.map(
    (lineItem) => lineItem.productId
  )
  const existingLineItemProductIds =
    order?.orderLineItems?.map((lineItem) => lineItem.productId) || []

  const lineItemsToAdd = newLineItems.filter(
    (newLineItem) => !existingLineItemProductIds.includes(newLineItem.productId)
  )

  const lineItemsToDelete = order?.orderLineItems?.filter(
    (existingLineItem) =>
      !newLineItemProductIds.includes(existingLineItem.productId)
  )

  const lineItemsToPatch = newLineItems
    .map((newLineItem) => ({
      ...newLineItem,
      ...order?.orderLineItems?.find(
        (existingLineItem) =>
          existingLineItem.productId === newLineItem.productId &&
          // only include line item fields if the warranty changed
          existingLineItem.priceSelection?.warrantySelection?.id !==
            newLineItem.warrantySelection?.id
      ),
    }))
    .filter(({ id }) => !!id)

  return Promise.all<
    | OrdersAPIResponse['POST']['/orders/{orderId}/line-items']
    | OrdersAPIResponse['PATCH']['/orders/{orderId}/line-items/{lineItemId}']
    | OrdersAPIResponse['DELETE']['/orders/{orderId}/line-items/{lineItemId}/delete']
  >([
    ...lineItemsToAdd.map((lineItem) =>
      ordersApi.post('/orders/{orderId}/line-items', {
        pathParams: { orderId: order?.id || '' },
        body: lineItem,
      })
    ),
    ...lineItemsToPatch.map((lineItem) =>
      ordersApi.patch('/orders/{orderId}/line-items/{lineItemId}', {
        pathParams: { orderId: order?.id || '', lineItemId: lineItem.id || '' },
        // Only need to patch the key containing the warranty
        body: [
          {
            path: 'warrantySelection',
            op: 'replace',
            value: lineItem.warrantySelection,
          },
        ],
      })
    ),
    ...(lineItemsToDelete?.map((lineItem) =>
      ordersApi.delete('/orders/{orderId}/line-items/{lineItemId}/delete', {
        pathParams: { orderId: order?.id || '', lineItemId: lineItem.id || '' },
      })
    ) || []),
  ])
}
