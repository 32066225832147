const addressToMapBox = (address: { addressLine1: string }): MapBoxFeature => ({
  id: 'address.placeholder',
  place_name: address.addressLine1,
  text: address.addressLine1,
  address: address.addressLine1,
  properties: {},
  place_type: ['address'],
  context: [],
  relevance: 1,
  type: 'Feature',
})

export default addressToMapBox
