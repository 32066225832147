import { Layout } from 'elements'
import { SubscriberSelect } from 'components/subscribers'
import { MedicationsList } from 'components/medications'
import { MedicationForm } from 'components/subscriber-medications'
import React, { useState } from 'react'
import { Button, Empty } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useParams } from 'react-router-dom'
import { useSubscriberQuery } from 'hooks/organizations'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useAPIQuery } from 'hooks'

const SubscriberManageMedication = () => {
  const { t } = useTranslation()

  const [isMedicationFormOpen, setIsMedicationFormOpen] = useState<boolean>(
    false
  )

  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { subscriberId } = useParams()
  const subscriber = useSubscriberQuery(subscriberId)
  const medications = useAPIQuery('subscriberMedications', {
    pathParams: { subscriberId },
  })

  return (
    <Layout
      title={
        <SubscriberSelectContainer>
          <Title>{t('Manage Medications')}:</Title>
          <SubscriberSelect />
        </SubscriberSelectContainer>
      }
      description={t('View and manage subscriber medications') + '.'}
      isLoading={subscriber.isLoading}
      isEmpty={!medications.data?.items?.length}
      emptyComponent={
        <GenericEmpty
          title={t('No Medications Added')}
          description={
            t('No medications have been added so far') +
            '. ' +
            t('To get started, click on the button below') +
            '.'
          }
          callToAction={
            <Button
              type="primary-filled"
              onClick={() => setIsMedicationFormOpen(true)}
            >
              {t('Add Medication')}
            </Button>
          }
        />
      }
      isUnauthorized={subscriber.data?.organization && !assumedOrganizationRole}
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${subscriber.data?.person.firstName} ${subscriber.data?.person.lastName}`,
          route: `../../`,
        },
        {
          name: t('Subscriber Settings'),
          route: `../`,
        },
        { name: t('Manage Medications') },
      ]}
      controls={[
        {
          label: t('Add medication'),
          onClick: () => setIsMedicationFormOpen(true),
        },
      ]}
    >
      <MedicationsList subscriberMedications={medications.data?.items} />
      <MedicationForm
        isOpen={isMedicationFormOpen}
        setIsOpen={setIsMedicationFormOpen}
      />
    </Layout>
  )
}

export default SubscriberManageMedication

const GenericEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`

const SubscriberSelectContainer = tw.div`flex gap-2`

const Title = tw.h1`text-2xl font-bold`
