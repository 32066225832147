import React, { useEffect } from 'react'
import {
  RightPopup,
  TextField,
  Select,
  SelectItem,
  LoadingIcon,
} from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import tw from 'twin.macro'
import { useSalutationTypesQuery } from 'hooks/seed-data'
import { useUpdateStaffMutation } from 'hooks/user-staff'

type EditStaffFormProps = {
  staff: StaffMember
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EditStaffForm = ({
  staff,
  isFormOpen,
  setIsFormOpen,
}: EditStaffFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<EditStaffMemberForm>({
    defaultValues: {
      firstName: staff.person.firstName,
      lastName: staff.person.lastName,
      salutation: Number(staff.person.salutation),
      suffix: staff.person.suffix,
    },
  })
  const { handleSubmit, errors, reset } = formMethods

  const salutationTypes = useSalutationTypesQuery()

  const updateStaffMutation = useUpdateStaffMutation()

  // reset form fields on open
  useEffect(() => {
    if (isFormOpen)
      reset({
        firstName: staff.person.firstName,
        lastName: staff.person.lastName,
        salutation: Number(staff.person.salutation),
        suffix: staff.person.suffix,
      })
  }, [isFormOpen])

  return (
    <RightPopup
      title={t('Edit Personal Details')}
      open={isFormOpen}
      setOpen={setIsFormOpen}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateStaffMutation.isLoading}
            onClick={handleSubmit(async (formData) =>
              updateStaffMutation.mutate(
                {
                  staffMember: staff,
                  editStaffForm: {
                    ...formData,
                    // isProvider: formData.isProvider === 'true',
                  },
                },
                { onSuccess: () => setIsFormOpen(false) }
              )
            )}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updateStaffMutation.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {salutationTypes.isLoading || !salutationTypes.data?.items ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            <InputContainer>
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="firstName"
                label={t('First Name')}
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('First Name is required') }}
                error={errors.firstName?.message}
                className="w-56"
                required
              />
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="lastName"
                label={t('Last Name')}
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Last Name is required') }}
                error={errors.lastName?.message}
                className="w-56"
                required
              />
            </InputContainer>
            <InputContainer>
              <Controller
                as={
                  <Select
                    label={t('Prefix')}
                    variant="outlined"
                    fullWidth={false}
                    selectClassName="w-24"
                  >
                    {salutationTypes.data.items.map((salutation, index) => (
                      <SelectItem value={Number(salutation.id)} key={index}>
                        {salutation.name}
                      </SelectItem>
                    ))}
                  </Select>
                }
                name="salutation"
                defaultValue={Number(salutationTypes.data.items[0].id)}
              />
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue=""
                name="suffix"
                label={t('Suffix')}
                error={errors.suffix?.message}
                className="w-20 mt-4"
              />
            </InputContainer>
          </form>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default EditStaffForm

const InputContainer = tw.div`flex gap-4`
