import React, { useMemo } from 'react'
import { useVitalObservationStatisticsQuery } from 'hooks/statistics'
import colors from 'tailwindcss/colors'
import { Icon, BarChart } from 'elements'
import { useNavigate } from 'hooks'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { dateRangeStateToURL } from 'utils'

type TotalObservationsByCategoryBarChartProps = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const TotalObservationsByCategoryBarChart = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: TotalObservationsByCategoryBarChartProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: observationsChartData,
    isLoading,
  } = useVitalObservationStatisticsQuery({
    dateRangeState,
    organizationId,
    subscriberId,
  })
  const barChartSeries: Series[] = [
    {
      key: 'vitalReadingTotal',
      color: colors.violet[400],
      active: true,
      unit: 'Vital Observations',
    },
    {
      key: 'phoneLogTotal',
      color: colors.emerald[400],
      active: true,
      unit: 'Phone Log Observations',
    },
    {
      key: 'reminderAcknowledgementTotal',
      color: colors.blue[400],
      active: true,
      unit: 'Reminder Acknowledgement Observations',
    },
  ]

  const formattedData = useMemo(() => {
    return (
      observationsChartData?.map(({ category, total }) => {
        if (category === 'phone-log')
          return {
            category: 'Phone Log',
            phoneLogTotal: total,
          }

        if (category === 'vital-reading')
          return {
            category: 'Vital Reading',
            vitalReadingTotal: total,
          }

        // default case: 'reminder-acknowledgement'
        return {
          category: 'Reminder Acknowledgement',
          reminderAcknowledgementTotal: total,
        }
      }) || []
    )
  }, [observationsChartData])

  return (
    <Card>
      <HeaderRow>
        <Header
          onClick={() =>
            navigate({
              pathname: subscriberId
                ? `/subscribers/${subscriberId}/vitals`
                : '/vitals/vitals-activity',
              searchParams: dateRangeStateToURL(dateRangeState),
            })
          }
        >
          <Title>{t('Total Observations by Type')}</Title>
          <LinkIcon type="link" />
        </Header>
      </HeaderRow>
      <BarChart
        data={formattedData}
        series={barChartSeries}
        indexBy="category"
        groupMode="stacked"
        isLoading={isLoading}
        legends={[]}
        margin={{ top: 20, right: 10, bottom: 55, left: 30 }}
      />
    </Card>
  )
}

export default TotalObservationsByCategoryBarChart

const Card = tw.div`bg-white p-3 h-72`

const HeaderRow = tw.div`flex items-center justify-between`

const Header = tw.div`flex items-center cursor-pointer`

const Title = tw.h3`text-lg lg:mb-0 mr-1`

const LinkIcon = tw(Icon)`w-3.5 h-3.5`
