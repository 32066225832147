import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCreateServiceDetailsMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/service-details'],
    createServiceDetailsArgs
  >({
    mutationFn: createServiceDetails,
    successMsg: { message: t('Order updated'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['order', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          createServiceDetailsArgs
        >((oldOrder, { serviceDetailsForm }) =>
          oldOrder
            ? {
                ...oldOrder,
                serviceDetails: serviceDetailsForm,
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useCreateServiceDetailsMutation

type createServiceDetailsArgs = {
  serviceDetailsForm: Required<OrderForm>['serviceDetails']
  orderId: string
}

const createServiceDetails = async ({
  serviceDetailsForm,
  orderId,
}: createServiceDetailsArgs) =>
  ordersApi.post('/orders/{orderId}/service-details', {
    pathParams: { orderId },
    body: serviceDetailsForm,
  })
