import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useDeleteModuleMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/api/modules/{moduleId}'],
    deleteModuleArgs
  >({
    mutationFn: deleteModule,
    successMsg: t('Module deleted'),
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          deleteModuleArgs
        >((oldCache, { moduleId }) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items?.filter(
                  (module) => module.id !== moduleId
                ),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useDeleteModuleMutation

type deleteModuleArgs = {
  moduleId: string
}

const deleteModule = ({ moduleId }: deleteModuleArgs) =>
  typedOrgsApi.delete('/api/modules/{moduleId}', {
    pathParams: { moduleId },
  })
