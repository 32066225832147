import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

const useAlertNotesQuery = (alertId: string) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/api/alerts/{alertId}/notes'],
    KyError
  >(['getAlertNotes', alertId], () => getAlerts(alertId), {
    keepPreviousData: true,
    enabled: !!alertId,
  })

  return query
}

export default useAlertNotesQuery

const getAlerts = async (alertId: string) =>
  typedVitalsApi.get('/api/alerts/{alertId}/notes', {
    pathParams: { alertId },
    searchParams: [
      ['order_by', 'ASC'],
      ['skip', 0],
      ['take', 99999],
      ['sort_by', 'createdAt'],
    ],
  })
