import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const useAlertDeliveryRulesQuery = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<GetAlertDeliveryRulesResponse, KyError>(
    ['getAlertDeliveryRules', assumedOrganizationRole?.orgID],
    () => getAlertDeliveryRules(assumedOrganizationRole?.orgID || ''),
    {
      enabled: !!assumedOrganizationRole?.orgID,
    }
  )

  return query
}

export default useAlertDeliveryRulesQuery

const getAlertDeliveryRules = async (organizationId: string) => {
  const result = vitalsApi
    .get(`organizations/${organizationId}/alert-delivery-rules`, {
      searchParams: [['take', '9999']],
    })
    .json<GetAlertDeliveryRulesResponse>()

  return result
}
