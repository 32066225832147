import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { EntityLabel, LoadingIcon, Icon, TextField } from 'elements'
import { useContractQuery } from 'hooks/contracts'
import { useOrganizationQuery, useSubscriberQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import OrderContractInfoCard from './OrderContractInfoCard'
import { usePeopleQuery } from 'hooks/people'
import Chip, { colors } from 'atlas/Chip/Chip'
import OrderStatusChip, {
  orderStatusToColors,
  isOrderStatusTitle,
} from './OrderStatusChip'
import { Button, Tooltip } from 'atlas'
import ProvisioningStatusChip, {
  OrderProvisioningStatusTitles,
} from './ProvisioningStatusChip'
import { LineItemStatusTitles } from './LineItemStatusChip'
import _ from 'lodash'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { usePERSSyncFromAneltoMutation } from 'hooks/provisioning'
import { motion, useAnimation } from 'framer-motion'
import { useAPIQuery } from 'hooks'

type OrderInfoCardProps = {
  order?: Order
}

const OrderInfoCard = ({ order }: OrderInfoCardProps) => {
  const { t } = useTranslation()

  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false)
  const [isSyncFieldTouched, setIsSyncFieldTouched] = useState(false)
  const [prefilledXmitId, setPrefilledXmitId] = useState<string | undefined>('')

  const subscriberQuery = useSubscriberQuery(order?.subscriberId || '')
  const organizationQuery = useOrganizationQuery(order?.organizationId || '')
  const contractQuery = useContractQuery({
    contractId: order?.contractId,
    organizationId: order?.organizationId,
  })
  const orderCreatorQuery = usePeopleQuery({
    // TODO: this id seems to successfully fetch with orgs user query
    personId: order?.audit?.createdBy || '',
  })
  const orderActivityQuery = useAPIQuery('orderActivity', {
    pathParams: { orderId: order?.id || '' },
  })
  const productsQuery = useAPIQuery('products')

  const syncFromAneltoMutation = usePERSSyncFromAneltoMutation()

  const subscriber = subscriberQuery.data
  const organization = organizationQuery.data
  const contract = contractQuery.data

  // the person shown as doing the provisioning is the Person attached to the latest activity event related to provisioning
  const provisionedBy = _.orderBy(
    orderActivityQuery.data,
    (activity) => new Date(activity.metaData?.CreatedAt),
    'desc'
  ).find(
    (activity) =>
      activity.activityType &&
      [
        'order_prov_in_process',
        'line_item_prov_in_process',
        'order_prov_complete',
        'line_item_prov_complete',
      ].includes(activity.activityType) &&
      activity.metaData?.Person?.FirstName
  )?.metaData?.Person

  const formMethods = useForm<{ xmitId: string }>()
  const { handleSubmit, errors, clearErrors } = formMethods

  const iconAnimationControls = useAnimation()

  // dynamically starts sync animation when sync request is sent
  useEffect(() => {
    if (syncFromAneltoMutation.isLoading)
      iconAnimationControls.start({ rotate: [0, 180, 360] })
  }, [syncFromAneltoMutation.isLoading])

  // reset any form errors when checking out/releasing order
  useEffect(() => {
    clearErrors('xmitId')
  }, [order?.orderStatus])

  // if an existing base console already has an anelto number, use it to prefill xmitId input
  useEffect(() => {
    setPrefilledXmitId(
      Object.entries(
        order?.orderLineItems?.find((item) =>
          productsQuery.data?.items?.find(
            (product) =>
              product.id === item.productId &&
              product.tags?.includes('Base Console')
          )
        )?.provisioningData || {}
      ).find(([key]) => key === 'ANL_ACCT_NUM')?.[1]
    )
  }, [order, productsQuery.data])

  if (
    !order ||
    subscriberQuery.isLoading ||
    organizationQuery.isLoading ||
    contractQuery.isLoading
  )
    return <Loading width="32" height="32" />
  return (
    <StatusAndCardContainer>
      {order.orderStatus ? <OrderStatus status={order.orderStatus} /> : '-'}
      <InfoCardContainer>
        <TopStatusBorder
          color={
            orderStatusToColors[
              order.orderStatus?.title &&
              isOrderStatusTitle(order.orderStatus.title)
                ? order.orderStatus.title
                : 'default'
            ]
          }
        />
        <ContentContainer>
          <LeftContentContainer>
            <LeftColContainer>
              <DetailsContainer>
                <OrderCode>
                  <OrderCodeLabel>{t('Order Code')}:</OrderCodeLabel>
                  <OrderCodeText>{order.orderCode || '-'}</OrderCodeText>
                </OrderCode>
                <PlacementInfo>
                  {`${t('Placed On')} ${
                    order.serviceDetails?.dateServiceDisclaimerAccepted
                      ? format(
                          new Date(
                            order.serviceDetails
                              .dateServiceDisclaimerAccepted || 0
                          ),
                          'PP'
                        )
                      : '-'
                  } By`}
                  &nbsp;
                  <Button
                    type="primary-link"
                    // TODO: This could conceivably be a staff not an admin... we need some way to know!
                    to={`/admins/${orderCreatorQuery.data?.id}`}
                  >
                    {orderCreatorQuery.data
                      ? `${orderCreatorQuery.data.firstName} ${orderCreatorQuery.data.lastName}`
                      : '-'}
                  </Button>
                </PlacementInfo>
              </DetailsContainer>

              <ContractItem onClick={() => setIsDetailsOpen(true)}>
                <ContractIcon type="clipboard" />
                <div>
                  <ContractLabel>{t('Contract')}</ContractLabel>
                  <ContractText>
                    {contract?.descriptionInfo?.title || '-'}
                  </ContractText>
                </div>
              </ContractItem>
            </LeftColContainer>

            <RightColContainer>
              <SubscriberItem>
                <SubscriberIcon type="user" />
                <div>
                  <ExtraSmallLabel>{t('Subscriber')}</ExtraSmallLabel>
                  <EntityLabel
                    name={
                      subscriber?.person.firstName +
                        ' ' +
                        subscriber?.person.lastName || '-'
                    }
                    id={subscriber?.id || '-'}
                    to={`/subscribers/${subscriber?.id}`}
                    size="sm"
                    bold
                  />
                </div>
              </SubscriberItem>

              <OrganizationItem>
                <OrganizationIcon type="organizations" />
                <div>
                  <ExtraSmallLabel>{t('Organization')}</ExtraSmallLabel>
                  <EntityLabel
                    name={organization?.displayName || '-'}
                    id={organization?.displayName || '-'}
                    to={`/organizations/${organization?.id}`}
                    size="sm"
                    bold
                  />
                </div>
              </OrganizationItem>
            </RightColContainer>
          </LeftContentContainer>

          <RightContentContainer>
            <ProvisioningHeader>
              <ProvisioningIcon type="module" />
              <ProvisioningTitle>
                {t('Provisioning')}
                {provisionedBy ? (
                  <ProvisionedBy>
                    <Button
                      type="primary-link"
                      to={`/admins/${provisionedBy.Id}`}
                    >
                      {`${provisionedBy.FirstName} ${provisionedBy.LastName}`}
                    </Button>
                  </ProvisionedBy>
                ) : null}
              </ProvisioningTitle>
            </ProvisioningHeader>

            <ProvisionChipContainer>
              {order.orderStatus ? (
                <ProvisioningStatusChip
                  status={order.orderStatus}
                  provisionNeeded={order.provisioningNeeded}
                />
              ) : null}
              {subscriberQuery.data ? (
                subscriberQuery.data.nonPERS ? (
                  <Chip color="orange">{t('Non-PERS')}</Chip>
                ) : (
                  <Chip color="lightblue">{t('PERS')}</Chip>
                )
              ) : null}
            </ProvisionChipContainer>

            {order.orderLineItems ? (
              <ProvisioningProgress lineItems={order.orderLineItems} />
            ) : null}

            {/* PERS Subscribers can sync with anelto */}
            {subscriber?.nonPERS === false ? (
              <SyncAneltoFormContainer>
                <FormProvider {...formMethods}>
                  <SyncHeaderContainer>
                    <SyncHeader>{t('Sync With Anelto')}</SyncHeader>
                    {order.orderStatus?.title !==
                    OrderProvisioningStatusTitles.PROVISIONING_IN_PROCESS ? (
                      <SyncTooltip
                        content={
                          <TooltipContent>
                            {t('Order must be checked out for provisioning')}
                          </TooltipContent>
                        }
                        anchor="left"
                      >
                        <WarningIcon type="alert2" />
                      </SyncTooltip>
                    ) : (
                      <SyncTooltip
                        content={
                          <TooltipContent>
                            {t(
                              'Use XMIT ID to pair bluetooth devices from Anelto'
                            )}
                          </TooltipContent>
                        }
                        anchor="left"
                      >
                        <Icon type="info" />
                      </SyncTooltip>
                    )}
                  </SyncHeaderContainer>
                  <InputContainer>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          disabled={
                            order.orderStatus?.title !==
                            OrderProvisioningStatusTitles.PROVISIONING_IN_PROCESS
                          }
                          placeholder="XMIT ID ANZ#"
                          size="small"
                          error={errors.xmitId?.message}
                          defaultValue={prefilledXmitId}
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            setIsSyncFieldTouched(e.target.value.length > 0)
                          }}
                          className="mt-3"
                        />
                      )}
                      defaultValue={prefilledXmitId}
                      name="xmitId"
                      rules={{
                        required: `${t('Subscriber Identifier is required')}`,
                      }}
                    />

                    <SyncIconContainer
                      animate={iconAnimationControls}
                      transition={{ ease: 'linear' }}
                    >
                      <SyncIcon
                        type="sync"
                        disabled={
                          syncFromAneltoMutation.isLoading ||
                          order.orderStatus?.title !==
                            OrderProvisioningStatusTitles.PROVISIONING_IN_PROCESS
                        }
                        syncFieldTouched={isSyncFieldTouched}
                        onClick={handleSubmit((formData) => {
                          syncFromAneltoMutation.mutateAsync({
                            orderId: order.id || '',
                            organizationId: order.organizationId || '',
                            xmitId: formData.xmitId,
                          })
                        })}
                      />
                    </SyncIconContainer>
                  </InputContainer>
                </FormProvider>
              </SyncAneltoFormContainer>
            ) : null}
          </RightContentContainer>

          <OrderContractInfoCard
            open={isDetailsOpen}
            setOpen={setIsDetailsOpen}
            contractId={order.contractId}
            organizationId={order.organizationId}
          />
        </ContentContainer>
      </InfoCardContainer>
    </StatusAndCardContainer>
  )
}

export default OrderInfoCard

const Loading = tw(LoadingIcon)`self-center`

// Order Status
const OrderStatus = tw(
  OrderStatusChip
)`absolute -top-4 xl:-top-2 left-4 font-medium rounded-b-none rounded-t-lg z-10`

const TopStatusBorder = styled.div<{ color: keyof typeof colors }>`
  ${() => tw`h-1 rounded-t-sm`}
  ${({ color }) => colors[color]}
`

// Primary Containers
const StatusAndCardContainer = tw.div`relative`

const InfoCardContainer = tw.div`bg-white border rounded xl:mt-2 overflow-visible`

const ContentContainer = tw.div`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3`

const LeftContentContainer = tw.div`grid grid-cols-1 lg:grid-cols-2 col-span-1 lg:col-span-2`

const LeftColContainer = tw.div`grid grid-template-rows[1fr 1fr]`

const RightColContainer = tw.div`grid grid-template-rows[1fr 1fr] mb-2 lg:mb-0`

// Contract Info
const ContractItem = tw.div`flex items-center border rounded w-fit-content pr-4 py-2 mx-4 my-6 cursor-pointer hover:shadow`

const ContractIcon = tw(Icon)`w-8 h-8 ml-1 mr-3 stroke-width[1.25px]`

const ContractLabel = tw.div`text-xs font-medium text-gray-500 leading-3`

const ContractText = tw.div`font-medium text-blue-500`

// Subscriber/Org Info
const SubscriberItem = tw.div`flex items-center ml-5 lg:mx-2 lg:my-3`

const SubscriberIcon = tw(Icon)`w-8 h-8 mr-3 stroke-width[1.25px]`

const ExtraSmallLabel = tw.div`text-xs font-medium text-gray-500 leading-3 -mt-1.5 mb-1`

const OrganizationItem = tw.div`flex items-center ml-5 my-2 lg:mx-2 lg:my-3`

const OrganizationIcon = tw(Icon)`w-8 h-8 mr-3 stroke-width[1.25px]`

// Order Details
const DetailsContainer = tw.div`mx-4 mt-6`

const OrderCodeLabel = tw.p`text-lg font-medium leading-4`

const OrderCodeText = tw.p`font-mono leading-4 ml-2`

const OrderCode = tw.p`flex`

const PlacementInfo = tw.p`text-sm mt-2 leading-4`

// Provisioning
const RightContentContainer = tw.div`mt-2 md:mt-5 ml-4 md:ml-0`

const ProvisioningHeader = tw.div`flex items-center`

const ProvisioningIcon = tw(Icon)`w-8 h-8 ml-1 mr-3 stroke-width[1.25px]`

const ProvisioningTitle = tw.p`text-lg font-medium text-gray-700 leading-4`

const ProvisionedBy = tw.p`text-sm font-normal leading-4 mt-0.5`

const ProvisionChipContainer = tw.div`flex gap-2 ml-8 mt-2`

type ProvisioningProgressProps = {
  lineItems: OrderLineItem[]
}

const ProvisioningProgress = ({ lineItems }: ProvisioningProgressProps) => {
  const { t } = useTranslation()

  const lineItemStatusesQuery = useAPIQuery('orderLineItemStatuses')

  // Find number of line items that have already been provisioned
  const provisioningCompleteId = lineItemStatusesQuery.data?.find(
    (status) => status.title === LineItemStatusTitles.PROVISIONING_COMPLETE
  )?.id
  const completeItems = lineItems.filter(
    (lineItem) => lineItem.orderLineItemStatusId === provisioningCompleteId
  ).length

  // Find number of line items currently in process of being provisioned
  const provisioningInProcessId = lineItemStatusesQuery.data?.find(
    (status) => status.title === LineItemStatusTitles.PROVISIONING_IN_PROCESS
  )?.id
  const inProcessItems = lineItems.filter(
    (lineItem) => lineItem.orderLineItemStatusId === provisioningInProcessId
  ).length

  // Find total number of line items that are provisionable
  const provisionableItems = lineItems.filter(
    (lineItem) => lineItem.provisioningNeeded
  ).length

  return (
    <>
      <ProgressBar>
        <CompleteProgressBar
          totalItems={provisionableItems}
          completedItems={completeItems}
        />
        <InProcessProgressBar
          totalItems={provisionableItems}
          completedItems={completeItems}
          inProcessItems={inProcessItems}
        />
      </ProgressBar>
      <ProgressText>{`${completeItems}/${provisionableItems} ${t(
        'Provisioned'
      )}${
        inProcessItems ? `, ${inProcessItems} ${t('In-Process')}` : ''
      }`}</ProgressText>
    </>
  )
}

const ProgressBar = tw.div`relative h-2.5 bg-gray-200 border border-gray-300 rounded-full mx-8 mt-4`

const CompleteProgressBar = styled.div<{
  totalItems: number
  completedItems: number
}>`
  width: calc(
    ${({ totalItems, completedItems }) => (completedItems / totalItems) * 100}%
  );
  ${() => tw`absolute h-2 bg-cyan-300 rounded-full z-20`}
`

const InProcessProgressBar = styled.div<{
  totalItems: number
  completedItems: number
  inProcessItems: number
}>`
  width: calc(
    ${({ totalItems, completedItems, inProcessItems }) =>
      ((completedItems + inProcessItems) / totalItems) * 100}%
  );
  ${() => tw`absolute h-2 bg-orange-300 rounded-full z-10`}
`

const ProgressText = tw.p`text-xs font-medium text-center text-gray-500 mt-1 mb-4`

const SyncAneltoFormContainer = tw.div`ml-8`

const SyncHeaderContainer = tw.div`flex flex-row`

const SyncHeader = tw.div`text-xs font-medium text-gray-500 leading-3 self-end`

const SyncTooltip = tw(Tooltip)`ml-1 inline-flex self-end`

const TooltipContent = tw.div`w-48`

const WarningIcon = tw(Icon)`text-amber-500`

const InputContainer = tw.div`flex`

const SyncIconContainer = tw(motion.div)`ml-2 mb-6 self-center`

const SyncIcon = styled(Icon)<{ syncFieldTouched: boolean }>`
  ${({ syncFieldTouched }) => [
    tw`w-5 h-5 cursor-pointer mb-0.5`,
    syncFieldTouched ? tw`text-blue-500` : tw`text-gray-500`,
  ]}
`
