import { useMutation } from 'react-query'
import { handleQueryError, success } from 'utils'
import { getI18n, useTranslation } from 'react-i18next'

const useDisplayLanguageMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<boolean, KyError, { language: Language }>(
    updateDisplayLanguage,
    {
      onSuccess: () => {
        // notify user
        success({ message: t('Language preference updated') })
      },
      onError: (error) => handleQueryError({ error }),
    }
  )

  return mutation
}

export default useDisplayLanguageMutation

const updateDisplayLanguage = async ({ language }: { language: Language }) => {
  // update account settings in localStorage
  localStorage.setItem('user-profile__language', language)

  await getI18n().changeLanguage(language)

  return true
}
