import React, { useEffect, useRef, useState } from 'react'
import { Button, Tooltip } from 'atlas'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { add, format, parse } from 'date-fns'
import {
  AutoComplete,
  Checkbox,
  ConfirmationDialogs,
  Icon,
  LoadingIcon,
  RightPopup,
  Select,
  SelectItem,
  TextField,
  TimePicker,
} from 'elements'
import { useNavigate, useSpeechQuery } from 'hooks'
import { useLocalesQuery } from 'hooks/locales'
import { useSubscriberQuery } from 'hooks/organizations'
import { useReminderPresetsQuery } from 'hooks/reminder-presets'
import {
  useCreateReminderScheduleMutation,
  useUpdateReminderScheduleMutation,
} from 'hooks/reminder-schedules'
import { useReminderTypesQuery } from 'hooks/reminder-types'
import { useSubscriberVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import _ from 'lodash'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import Howler from 'react-howler'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import tw, { styled, TwStyle } from 'twin.macro'
import { isLocaleCode, vitalsApi } from 'utils'
import DayOfTheWeekToggleGroup from '../reminder-presets/DayOfTheWeekToggleGroup'
import FrequencyOfReminderPreset from '../reminder-presets/FrequencyOfReminderPreset'
import { assignmentsToDisplayAssignments } from 'utils'

type CreateEditReminderPresetFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  currentReminderSchedule?: ReminderSchedule
}

const CreateEditReminderPresetForm = ({
  isFormOpen,
  setIsFormOpen,
  currentReminderSchedule,
}: CreateEditReminderPresetFormProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()
  const { subscriberId } = useParams()
  const [messageToPlay, setMessageToPlay] = useState<{
    message: string
    locale?: LocaleCode
  }>({ message: '' })

  const howlerRef = useRef<Howler>(null)

  const [isSpeechPlaying, setIsSpeechPlaying] = useState<boolean>(false)

  const speechQuery = useSpeechQuery(messageToPlay)

  const vitalAssignments = useSubscriberVitalAssignmentsQuery(subscriberId)

  const subVitals = vitalAssignments.data?.assignedVitalSigns

  const bloodPressureSigns =
    subVitals?.filter((vitalSign) =>
      vitalSign.name?.includes('Blood Pressure')
    ) || []

  const subscriberVitalSigns = assignmentsToDisplayAssignments(
    subVitals?.filter((vitalSign) => !vitalSign.archived) || []
  )

  const queryClient = useQueryClient()

  const reminderTypes = useReminderTypesQuery()

  const [vitalSigns, setVitalSigns] = useState<Array<VitalAssignment>>([])

  const [selectedVitalSigns, setSelectedVitalSigns] = useState<
    Array<VitalAssignment>
  >([])

  const formMethods = useForm<ReminderScheduleForm>({
    shouldUnregister: false,
    defaultValues: {
      reminderTypeId: 'vitals-reminder',
    },
  })

  const {
    handleSubmit,
    errors,
    register,
    watch,
    reset,
    setValue,
    getValues,
  } = formMethods

  const [isManageDialogOpen, setIsManageDialogOpen] = useState<boolean>(false)
  const [savePreset, setSavePreset] = useState<boolean>(false)
  const [disableSchedule, setDisableSchedule] = useState<boolean>(false)
  const [toggled, setToggled] = useState<Weekdays[]>(['mon'])
  const [timePicker, setTimePicker] = useState<Date | null>(new Date())
  const [locale, setLocale] = useState<LocaleCode>(
    currentReminderSchedule && isLocaleCode(currentReminderSchedule.locale)
      ? currentReminderSchedule.locale
      : 'en-US'
  )

  const [vitalSignsError, setVitalSignsError] = useState<string>()
  const [reminderMessagesError, setReminderMessagesError] = useState<string>()

  // reminder type needs to be set from 'currentReminderPreset' as well
  const [reminderDisplayOrder, setReminderDisplayOrder] = useState<number>(1)

  const currentSubscriber = useSubscriberQuery(subscriberId)

  const availableTranslations = useLocalesQuery()

  const reminderPresets = useReminderPresetsQuery(
    // if the subscriber has an org, use the organization's vital assignments
    currentSubscriber.data?.organization
      ? assumedOrganizationRole?.orgID || ''
      : // else if they're private pay, use the ECG's organization's vital assignments
        '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const organizationId = currentSubscriber.data?.organization
    ? assumedOrganizationRole?.orgID || ''
    : // else if they're private pay, use the ECG's organization's vital assignments
      '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'

  const reminderMessages = useQuery<
    VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages'],
    KyError
  >(
    ['getReminderMessageTranslations', organizationId, locale],
    () => getReminderMessageTranslations(organizationId, locale),
    {
      enabled: !!currentReminderSchedule || isFormOpen,
      onSuccess: (data) => {
        if (isFormOpen && currentReminderSchedule) {
          const currentReminderDisplayOrder = data.items?.find(
            (rm) => rm.id === currentReminderSchedule.message?.id
          )?.messageType.displayOrder

          setValue(
            'messageId',
            (() => {
              if (currentReminderSchedule.customReminderMessage)
                return 'createReminder'

              // set the reminder message to the current set display order, to ensure consistency
              return (
                data.items?.find((rm) => {
                  return (
                    rm.messageType.displayOrder === currentReminderDisplayOrder
                  )
                })?.id || 'createReminder'
              )
            })()
          )
        }
      },
    }
  )

  // get translation keys
  const getReminderMessageTranslations = async (
    organizationId: string,
    locale: string
  ) => {
    const result = await vitalsApi
      .get(`organization/${organizationId}/reminder-messages?locale=${locale}`)
      .json<
        VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
      >()

    return result
  }

  const createReminderSchedule = useCreateReminderScheduleMutation()
  const updateReminderSchedule = useUpdateReminderScheduleMutation()

  // need to find out how to set selectedVitalSigns when there is no blood pressure
  useEffect(() => {
    if (vitalSigns.some((vs) => vs.name === 'Blood Pressure')) {
      const removeVitalSign = vitalSigns.filter(
        (vs) => vs.name !== 'Blood Pressure'
      )
      setSelectedVitalSigns([...removeVitalSign, ...bloodPressureSigns])
    } else {
      setSelectedVitalSigns(vitalSigns)
    }
  }, [vitalSigns])

  // use effect to handle formData, when selecting a preset
  useEffect(() => {
    if (watch('presetId') === 'n/a' || getValues('presetId') === undefined) {
      setDisableSchedule(false)
    } else {
      const reminderPreset = reminderPresets.data?.items?.find((item) => {
        return item.id === watch('presetId')
      })
      if (!reminderPreset) return
      // set Values for the form if preset is selected
      setValue('description', reminderPreset.name)
      setValue('schedule.frequency', reminderPreset.schedule.frequency)
      setValue(
        'schedule.time',
        parse(reminderPreset.schedule.time || '', 'HH:mm', new Date())
      )
      setValue('schedule.interval', reminderPreset.schedule.interval)

      // ensure there are weekdays and set the weekdays for toggle group
      if (reminderPreset.schedule.weekdays) {
        setToggled(reminderPreset.schedule.weekdays)
        setValue('schedule.weekdays', reminderPreset.schedule.weekdays)
      }
      setSavePreset(false)
      setDisableSchedule(true)
    }
  }, [watch('presetId')])

  // reset form values or set values when form opens, dependant on form being 'Create' or 'Edit'
  useEffect(() => {
    if (isFormOpen) {
      queryClient.invalidateQueries('getReminderMessageTranslations')
      reset({
        // messageId: reminderMessages.data?.items.,
        reminderTypeId: currentReminderSchedule
          ? reminderTypes.data?.items?.find(
              (type) => type.id === currentReminderSchedule.reminderTypeId
            )?.code
          : 'vitals-reminder',
      })
      // if no vital signs, reset state to empty array
      if (!currentReminderSchedule?.vitalSignIds?.length) setVitalSigns([])
      setSavePreset(false)
      setLocale(
        currentReminderSchedule && isLocaleCode(currentReminderSchedule.locale)
          ? currentReminderSchedule.locale
          : 'en-US'
      )
    }

    if (isFormOpen && currentReminderSchedule) {
      queryClient.invalidateQueries('getReminderMessageTranslations')

      reset({
        messageId: (() => {
          if (currentReminderSchedule.customReminderMessage)
            return 'createReminder'

          // set the reminder message to the current set display order, to ensure consistency
          return (
            reminderMessages.data?.items?.find((rm) => {
              return rm.messageType.displayOrder === reminderDisplayOrder
            })?.id || 'createReminder'
          )
        })(),
        reminderTypeId: reminderTypes.data?.items?.find(
          (type) => type.id === currentReminderSchedule.reminderTypeId
        )?.code,
      })
    }

    // set reminder type to currentReminderPresets reminder type
    if (isFormOpen && currentReminderSchedule?.reminderTypeId) {
      setValue(
        'reminderTypeId',
        reminderTypes.data?.items?.find(
          (type) => type.id === currentReminderSchedule.reminderTypeId
        )?.code || 'vitals-reminder'
      )
    }

    // if vital signs exist, set them to current ones selected
    if (isFormOpen && currentReminderSchedule?.vitalSignIds) {
      setVitalSigns(
        currentReminderSchedule.vitalSignIds.length
          ? subVitals?.filter((vital) =>
              currentReminderSchedule.vitalSignIds?.find(
                (current) => current === vital.id
              )
            ) || []
          : []
      )
    }

    // set toggled when weekdays exists
    if (isFormOpen && currentReminderSchedule?.schedule.weekdays) {
      setValue('schedule.weekdays', currentReminderSchedule.schedule.weekdays)
      setToggled(currentReminderSchedule.schedule.weekdays)
    }
  }, [isFormOpen])

  // set form data messageId when locale changes
  useEffect(() => {
    setValue('messageId', '')
  }, [locale])

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsManageDialogOpen,
      isOpen: isManageDialogOpen,
      title: t(`Manage Reminder Messages`),
      content: (
        <p>
          {t(
            'If you leave this page, all progress will be lost. Are you sure you want to manage reminders?'
          )}
        </p>
      ),
      actions: (
        <Button
          onClick={() => {
            setIsManageDialogOpen(false)
            return navigate('/organization-settings/reminder-messages')
          }}
          type="primary-filled"
        >
          {t('Manage')}
        </Button>
      ),
    },
  ]

  return (
    <>
      {speechQuery.data && !speechQuery.isFetching ? (
        <Howler
          src={speechQuery.data}
          html5
          ref={howlerRef}
          playing={isSpeechPlaying}
          onEnd={() => setIsSpeechPlaying(false)}
        />
      ) : null}

      <RightPopup
        title={
          !currentReminderSchedule
            ? t('Create Reminder Schedule')
            : t('Edit Reminder Schedule')
        }
        open={isFormOpen}
        setOpen={setIsFormOpen}
        controls={
          <>
            <Button
              isLoading={
                reminderMessages.isLoading ||
                createReminderSchedule.isLoading ||
                updateReminderSchedule.isLoading
              }
              type="primary-filled"
              onClick={handleSubmit(async (formData) => {
                if (!vitalSigns.length) {
                  setVitalSignsError(t('Vital Signs is required'))
                  return
                } else setVitalSignsError(undefined)

                if (!watch('messageId') || watch('messageId') === '') {
                  setReminderMessagesError(t('Reminder messages is required'))
                  return
                } else setReminderMessagesError(undefined)

                // structure request object for creating a schedule
                if (!currentReminderSchedule) {
                  createReminderSchedule.mutate(
                    {
                      reminderSchedule: {
                        ...formData,
                        vitalSignIds: selectedVitalSigns.length
                          ? selectedVitalSigns.map((vitalSign) => vitalSign.id)
                          : undefined,
                        reminderTypeId:
                          reminderTypes.data?.items?.find(
                            (type) => type.code === watch('reminderTypeId')
                          )?.id || '',
                        messageId:
                          formData.messageId !== 'createReminder'
                            ? formData.messageId
                            : undefined,
                        customReminderMessage:
                          formData.messageId === 'createReminder'
                            ? formData.customReminderMessage
                            : undefined,
                        locale: locale,
                        presetId:
                          formData.presetId === 'n/a'
                            ? undefined
                            : formData.presetId,
                        schedule:
                          formData.presetId === 'n/a' && formData.schedule
                            ? {
                                frequency: formData.schedule.frequency,
                                interval:
                                  formData.schedule.frequency !== 'weekly'
                                    ? formData.schedule.interval
                                    : undefined,
                                weekdays: formData.schedule.weekdays,
                                months: formData.schedule.months,
                                daysOfMonth: formData.schedule.daysOfMonth,
                                time: format(
                                  new Date(
                                    formData.schedule.time || '01/01/2020'
                                  ),
                                  'HH:mm'
                                ),
                              }
                            : undefined,
                        startDate: format(new Date(), 'MM-dd-yyyy'),
                        endDate: format(
                          add(new Date(), { years: 100 }),
                          'MM-dd-yyyy'
                        ),
                        organizationId:
                          formData.presetId === 'n/a'
                            ? assumedOrganizationRole?.orgID
                            : undefined,
                        savePreset,
                        preset: savePreset
                          ? {
                              name: formData.preset?.name || '',
                              description: formData.preset?.description || '',
                            }
                          : undefined,
                      },
                      subscriberId,
                    },
                    { onSuccess: () => setIsFormOpen(false) }
                  )
                }
                // structure request object for updating a schedule
                else {
                  updateReminderSchedule.mutate(
                    {
                      reminderSchedule: {
                        ...formData,
                        vitalSignIds: selectedVitalSigns.length
                          ? selectedVitalSigns.map((vitalSign) => vitalSign.id)
                          : undefined,
                        reminderTypeId: reminderTypes.data?.items?.find(
                          (type) => type.code === watch('reminderTypeId')
                        )?.id,
                        messageId:
                          formData.messageId !== 'createReminder'
                            ? formData.messageId
                            : undefined,
                        customReminderMessage:
                          formData.messageId === 'createReminder'
                            ? formData.customReminderMessage
                            : undefined,
                        presetId:
                          formData.presetId === 'n/a'
                            ? undefined
                            : formData.presetId,
                        locale: locale,
                        schedule: {
                          frequency: formData.schedule?.frequency || 'daily',
                          interval:
                            formData.schedule?.frequency !== 'weekly'
                              ? formData.schedule?.interval
                              : undefined,
                          weekdays: formData.schedule?.weekdays,
                          months: formData.schedule?.months,
                          daysOfMonth: formData.schedule?.daysOfMonth,
                          time: format(
                            new Date(formData.schedule?.time || '01/01/2020'),
                            'HH:mm'
                          ),
                        },
                        startDate: format(new Date(), 'MM-dd-yyyy'),
                        endDate: format(
                          add(new Date(), { years: 100 }),
                          'MM-dd-yyyy'
                        ),
                      },
                      subscriberId,
                      scheduleId: currentReminderSchedule.id,
                    },
                    { onSuccess: () => setIsFormOpen(false) }
                  )
                }
              })}
            >
              {!currentReminderSchedule ? t('Create') : t('Update')}
            </Button>
            &nbsp;
            <Button
              disabled={
                createReminderSchedule.isLoading ||
                updateReminderSchedule.isLoading
              }
              type="secondary"
              onClick={() => setIsFormOpen(false)}
            >
              {t('Cancel')}
            </Button>
          </>
        }
      >
        {reminderPresets.isLoading || currentSubscriber.isLoading ? (
          <LoadingIcon />
        ) : (
          <FormProvider {...formMethods}>
            <form>
              {/* Reminder Preset Select */}
              <SelectItemContainer>
                <Controller
                  render={({ onChange }) => (
                    <Select
                      defaultValue="n/a"
                      name="presetId"
                      label={t('Select Reminder Preset')}
                      onChange={onChange}
                    >
                      <SelectItem value={'n/a'}>{t('None')}</SelectItem>
                      {reminderPresets.data?.items?.map((reminder, key) => {
                        return (
                          <SelectItem key={key} value={reminder.id}>
                            {reminder.name}
                          </SelectItem>
                        )
                      })}
                    </Select>
                  )}
                  name="presetId"
                  defaultValue={'n/a'}
                />
              </SelectItemContainer>

              {/* Description for schedule*/}
              <div>
                <TextField
                  name="description"
                  error={errors.description?.message}
                  inputRef={register({
                    required: `${t('Schedule Name is required')}`,
                  })}
                  defaultValue={currentReminderSchedule?.description || ''}
                  label={t('Schedule Name')}
                  fullWidth
                  required
                  shrink={
                    !!watch('presetId') ||
                    !!currentReminderSchedule?.description ||
                    !!watch('description')
                  }
                />
              </div>

              {/*Select a reminder type */}
              <SelectItemContainer>
                <Controller
                  name="reminderTypeId"
                  defaultValue={
                    reminderTypes.data?.items?.find(
                      (type) =>
                        type.id === currentReminderSchedule?.reminderTypeId
                    )?.code || 'vitals-reminder'
                  }
                  render={({ onChange }) => (
                    <Select
                      defaultValue={
                        reminderTypes.data?.items?.find(
                          (type) =>
                            type.id === currentReminderSchedule?.reminderTypeId
                        )?.code || 'vitals-reminder'
                      }
                      name="reminderTypeId"
                      label={t('Select a Reminder Type')}
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                    >
                      {reminderTypes.data?.items?.map((reminderType) => (
                        <SelectItem
                          key={reminderType.id}
                          value={reminderType.code}
                        >
                          {reminderType.title}
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                />
              </SelectItemContainer>

              {/* Vital Sign Select */}
              {watch('reminderTypeId') === 'vitals-reminder' ? (
                <div>
                  <Controller
                    render={() => (
                      <AutoComplete
                        label={t('Vital Signs')}
                        error={vitalSignsError}
                        placeholder={t('Search Vital Sign Assignment')}
                        options={subscriberVitalSigns}
                        selectedOptions={vitalSigns}
                        onChange={setVitalSigns}
                        optionLabel={(value) => {
                          return value.name || 'unknown'
                        }}
                      />
                    )}
                    name="vitalSignIds"
                  />
                </div>
              ) : null}

              {/* Select Locale */}
              <SelectItemContainer>
                <Select
                  value={locale}
                  name="locale"
                  label={t('Select a Locale')}
                  onChange={(e) => {
                    setLocale(e.target.value as LocaleCode)
                  }}
                >
                  {availableTranslations.data?.items?.map((locale) => {
                    return (
                      <SelectItem
                        key={locale.displayName}
                        value={locale.name === 'de' ? 'de-DE' : locale.name}
                      >
                        {locale.displayName}
                      </SelectItem>
                    )
                  })}
                </Select>
              </SelectItemContainer>

              {/* Reminder Message Select*/}
              {reminderMessages.isLoading || reminderMessages.isFetching ? (
                <Loading height="3rem" width="3rem" fluid />
              ) : watch('reminderTypeId') === 'vitals-reminder' ? (
                <SelectReminderMessage>
                  <Controller
                    render={({ onChange, value }) => (
                      <Select
                        name="messageId"
                        value={value}
                        error={reminderMessagesError}
                        label={t('Select a Reminder Message')}
                        onChange={(e) => {
                          // setReminderDisplay dependant on the selected reminder message
                          setReminderDisplayOrder(() => {
                            const displayOrder = reminderMessages.data?.items?.find(
                              (rm) => rm.id === e.target.value
                            )?.messageType.displayOrder
                            if (displayOrder) return displayOrder
                            return 1
                          })
                          return onChange(e.target.value)
                        }}
                      >
                        {_.orderBy(
                          reminderMessages.data?.items,
                          'messageType.displayOrder'
                        ).map((reminder, key) => {
                          return (
                            <SelectItem
                              key={`${key}-reminderMessage`}
                              value={reminder.id}
                            >
                              <SelectContainer>
                                <>{reminder.messageType.name}</>
                                <SelectMessage>
                                  &quot;{reminder.message}&quot;
                                </SelectMessage>
                              </SelectContainer>
                            </SelectItem>
                          )
                        })}
                        <SelectItem value={'createReminder'}>
                          <NewMessageContainer>
                            <>{t('Custom Reminder Message')}</>
                            <SelectMessage>
                              {t('Create a custom reminder message')}
                            </SelectMessage>
                          </NewMessageContainer>
                        </SelectItem>
                      </Select>
                    )}
                    name="messageId"
                  />
                  &nbsp;
                  {/* Audio Controls */}
                  <MessageActions>
                    <ManageBtn
                      onClick={() => setIsManageDialogOpen(true)}
                      type="primary-link"
                    >
                      {t('Manage')}
                    </ManageBtn>

                    <AudioControls>
                      <Tip
                        content={
                          isSpeechPlaying ? 'Pause Message' : 'Play Message'
                        }
                        orientation="start"
                      >
                        <PlayPauseBtn
                          type={
                            isSpeechPlaying ? 'pause-button' : 'play-button'
                          }
                          onClick={() => {
                            if (isSpeechPlaying) {
                              setIsSpeechPlaying(false)
                              return
                            }

                            if (watch('messageId') === 'createReminder')
                              setMessageToPlay({
                                message: watch('customReminderMessage') || '',
                                locale,
                              })
                            else {
                              const reminder = reminderMessages.data?.items?.find(
                                (message) => message.id === watch('messageId')
                              )?.message

                              setMessageToPlay({
                                message: reminder || '',
                                locale,
                              })
                            }

                            setIsSpeechPlaying(true)
                            return
                          }}
                        />
                      </Tip>
                      <Tip content="Restart" orientation="start" anchor="right">
                        <RestartBtn
                          disabled={!speechQuery.data}
                          type="skip-back"
                          onClick={() => {
                            if (speechQuery.data && !speechQuery.isFetching) {
                              howlerRef.current?.howler.seek(0)
                            }
                          }}
                          className={'h-fit-content' as string & TwStyle}
                        />
                      </Tip>
                    </AudioControls>
                  </MessageActions>
                </SelectReminderMessage>
              ) : null}

              {/* Custom Reminder Message Textfield */}
              {watch('messageId') === 'createReminder' ||
              watch('reminderTypeId') !== 'vitals-reminder' ? (
                <CustomMessageContainer>
                  <TextField
                    name="customReminderMessage"
                    error={errors.customReminderMessage?.message}
                    inputRef={register({
                      required: `${t('Custom reminder message is required')}`,
                    })}
                    defaultValue={
                      currentReminderSchedule?.customReminderMessage || ''
                    }
                    label={t('Custom Reminder Message')}
                    fullWidth
                    required
                  />

                  {/* Audio Controls */}
                  {watch('reminderTypeId') !== 'vitals-reminder' ? (
                    <AudioControls>
                      <Tip
                        content="Play Message"
                        orientation="end"
                        anchor="right"
                      >
                        <PlayPauseBtn
                          type={
                            isSpeechPlaying ? 'pause-button' : 'play-button'
                          }
                          onClick={() => {
                            if (isSpeechPlaying) {
                              setIsSpeechPlaying(false)
                              return
                            }
                            setMessageToPlay({
                              message: watch('customReminderMessage') || '',
                              locale,
                            })

                            setIsSpeechPlaying(true)
                            return
                          }}
                          className={'mt-4 h-fit-content' as TwStyle & string}
                        />
                      </Tip>
                      <Tip content="Restart" orientation="start" anchor="right">
                        <RestartBtn
                          disabled={!speechQuery.data}
                          type="skip-back"
                          onClick={() => {
                            if (speechQuery.data && !speechQuery.isFetching) {
                              howlerRef.current?.howler.seek(0)
                            }
                          }}
                          className={'mt-4 h-fit-content' as string & TwStyle}
                        />
                      </Tip>
                    </AudioControls>
                  ) : null}
                </CustomMessageContainer>
              ) : null}

              <ScheduleTitle>{t('Reminder Scheduling')}</ScheduleTitle>

              {/* Reminder Frequency Select*/}
              <SelectFrequencyContainer>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      defaultValue={
                        !currentReminderSchedule
                          ? 'daily'
                          : currentReminderSchedule.schedule.frequency
                      }
                      name="schedule.frequency"
                      value={value}
                      label={t('Reminder Frequency')}
                      onChange={onChange}
                      disabled={disableSchedule}
                    >
                      <SelectItem value="daily">{t('Daily')}</SelectItem>
                      <SelectItem value="weekly">{t('Weekly')}</SelectItem>
                    </Select>
                  )}
                  name="schedule.frequency"
                  defaultValue={
                    !currentReminderSchedule
                      ? 'daily'
                      : currentReminderSchedule.schedule.frequency
                  }
                />
              </SelectFrequencyContainer>

              {/* Reminder Time Picker */}
              <div>
                <Controller
                  as={
                    <TimePicker
                      name="schedule.time"
                      value={timePicker}
                      onChange={setTimePicker}
                      label={t('Reminder Time')}
                      disabled={disableSchedule}
                    />
                  }
                  name="schedule.time"
                  defaultValue={
                    !currentReminderSchedule?.schedule.time
                      ? new Date()
                      : parse(
                          currentReminderSchedule.schedule.time,
                          'HH:mm',
                          new Date()
                        )
                  }
                />
              </div>
              <InfoIconContainer>
                <Icon type="info" />
                &nbsp;{' '}
                {t(
                  'Reminder time should be set relative to the subscribers timezone'
                )}
              </InfoIconContainer>

              {/* Reminder Frequency Select*/}
              {watch('schedule.frequency') !== 'weekly' ? (
                <IntervalContainer>
                  <Controller
                    render={({ onChange }) => (
                      <FrequencyOfReminderPreset
                        label="Repeat Every"
                        name={'schedule.interval'}
                        defaultValue={
                          currentReminderSchedule?.schedule.interval || 1
                        }
                        frequency={watch('schedule.frequency')}
                        onChange={onChange}
                        disabled={disableSchedule}
                      />
                    )}
                    name="schedule.interval"
                    defaultValue={
                      currentReminderSchedule?.schedule.interval || 1
                    }
                  />
                </IntervalContainer>
              ) : null}

              {/* Days of the Week Toggle*/}
              {watch('schedule.frequency') === 'weekly' ? (
                <Controller
                  render={({ onChange }) => (
                    <DayOfTheWeekToggleGroup
                      label="Repeat Every"
                      name="schedule.weekdays"
                      onChange={onChange}
                      disabled={disableSchedule}
                      toggled={toggled}
                      setToggled={setToggled}
                    />
                  )}
                  name="schedule.weekdays"
                  defaultValue={toggled}
                />
              ) : null}

              {/* Save schedule Checkbox*/}
              {!currentReminderSchedule ? (
                <Checkbox
                  checked={savePreset}
                  className="mb-4"
                  label={t('Save schedule as reminder preset for future use')}
                  onChange={() => setSavePreset(!savePreset)}
                  disabled={disableSchedule}
                />
              ) : null}

              {/* Name and Description fields for save reminder preset*/}
              {savePreset ? (
                <div>
                  <TextField
                    name="preset.name"
                    error={errors.preset?.name?.message}
                    inputRef={register({ required: 'Name is required' })}
                    label={t('Preset Name')}
                    fullWidth
                    required
                  />

                  <TextField
                    name="preset.description"
                    error={errors.preset?.description?.message}
                    inputRef={register}
                    label={t('Preset Description')}
                    fullWidth
                  />
                </div>
              ) : null}
            </form>
          </FormProvider>
        )}
        <ConfirmationDialogs confirmations={confirmations} />
      </RightPopup>
    </>
  )
}

export default CreateEditReminderPresetForm

const SelectContainer = tw.div`w-96`
const NewMessageContainer = tw.div`w-96 text-blue-400`
const SelectMessage = tw.p`text-xs text-gray-500 whitespace-normal`
const ManageBtn = tw(Button)`align-top mr-2`
const PlayPauseBtn = styled(Icon)<{ className?: TwStyle }>(({ className }) => [
  tw`h-6 w-6 mx-2 mt-2 text-blue-400 hover:text-blue-500`,
  className,
])
const RestartBtn = styled(Icon)<{ className?: TwStyle }>(({ className }) => [
  tw`h-6 w-6 ml-2 mt-2 text-blue-400 hover:text-blue-500`,
  className,
])
const AudioControls = tw.div`flex flex-row justify-items-center`
const Tip = tw(Tooltip)`h-fit-content`
const MessageActions = tw.div`flex flex-row text-gray-400 divide-x divide-black divide-opacity-50 items-center mt-6 mx-4`
const CustomMessageContainer = tw.div`flex flex-row`
const Loading = tw(LoadingIcon)`my-12 mx-16`

const SelectItemContainer = tw.div`mb-8`

const SelectReminderMessage = tw.div`flex flex-row mb-8`

const ScheduleTitle = tw.h3`text-xl align-middle flex-grow-0 mb-4`

const SelectFrequencyContainer = tw.div`mb-4`

const InfoIconContainer = tw.span`inline-flex items-center whitespace-nowrap text-xs mb-4 opacity-75`

const IntervalContainer = tw.div`flex flex-col w-full h-16 mb-4`
