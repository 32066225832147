import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
// import { DisclaimerTypesAuditTrailList } from 'components/disclaimers'

const DisclaimerTypesAuditTrail = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Disclaimer Types Audit Trail')}
      breadcrumbs={[
        {
          name: t('Settings'),
          route: '/settings',
        },
        {
          name: t('Disclaimer Types'),
          route: '/settings/disclaimer-types',
        },
        {
          name: t('Disclaimer Types Audit Trail'),
        },
      ]}
      isUnauthorized={!!assumedOrganizationRole}
    >
      {/* <DisclaimerTypesAuditTrailList /> */}
    </Layout>
  )
}

export default DisclaimerTypesAuditTrail
