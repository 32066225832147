import React, { useState } from 'react'
import { FadeIn } from 'animations'
import tw, { styled } from 'twin.macro'

// TODO: make this more responsive
export type TooltipProps = {
  /**
   * The contents shown inside the Tooltip.
   */
  content: React.ReactNode

  /**
   * Collapses the white space of the Tooltip content's text
   */
  wrapText?: boolean

  /**
   * The contents shown outside of the Tooltip. Hovering this will show the Tooltip.
   */
  children: React.ReactNode

  /**
   * Appends to the className of the outer-most element of the component. *Note: never directly use this prop, always opt for styled-components.*
   */
  className?: string

  /**
   * The horizontal orientation the Tooltip appears in relation to the children.
   */
  orientation?: 'start' | 'center' | 'end'

  /**
   * The corner of the content that should be anchored to the children
   */
  anchor?: 'left' | 'right'
}

const Tooltip = ({
  content,
  wrapText = true,
  children,
  className,
  orientation = 'center',
  anchor = 'left',
}: TooltipProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false)

  return (
    <Container
      className={className}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering ? (
        <AbsoluteContainer anchor={anchor}>
          <FlexContainer orientation={orientation}>
            <TooltipCard wrapText={wrapText}>{content}</TooltipCard>
          </FlexContainer>
        </AbsoluteContainer>
      ) : null}
      {children}
    </Container>
  )
}

export default Tooltip

const Container = styled.div(
  ({ className }: { className: string | undefined }) => [
    tw`relative cursor-default h-fit-content`,
    className,
  ]
)

const AbsoluteContainer = styled(FadeIn)<{ anchor: 'left' | 'right' }>`
  ${tw`absolute z-50 min-w-full text-base`}
  ${({ anchor }) => (anchor === 'left' ? tw`left-0` : tw`right-0`)}

  top: 110%;

  p {
    color: white;
  }
`

const FlexContainer = styled.div<{ orientation: 'start' | 'center' | 'end' }>(
  ({ orientation }) => [
    tw`flex`,
    orientation === 'start' && tw`flex justify-start`,
    orientation === 'center' && tw`flex justify-center`,
    orientation === 'end' && tw`flex justify-end`,
  ]
)

const TooltipCard = styled.div<{ wrapText: boolean }>(({ wrapText }) => [
  tw`bg-gray-900 text-white p-2 rounded z-10 transition-all select-none shadow-lg`,
  wrapText && tw`whitespace-nowrap`,
])
