import React, { useMemo, useState } from 'react'
import {
  List,
  Dialog,
  CategoryFilter,
  LoadingIcon,
  EllipsisDropdown,
} from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  useFilters,
  TableInstance,
} from 'react-table'
import i18next from 'i18next'
import _ from 'lodash'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useCategoryFilter } from 'hooks/react-table'
import { useTranslation } from 'react-i18next'
import {
  useReminderPresetsQuery,
  useDeleteReminderPresetMutation,
} from 'hooks/reminder-presets'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'

type ReminderPresetsListProps = {
  editReminderPreset: (selectedPreset: ReminderPreset) => void
}

const ReminderPresetsList = ({
  editReminderPreset,
}: ReminderPresetsListProps) => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const {
    getCategories,
    filterRowsByCategory,
  } = useCategoryFilter<ReminderPreset>()

  const { data: reminderPresets, isLoading } = useReminderPresetsQuery(
    assumedOrganizationRole?.orgID || ''
  )
  const { t } = useTranslation()

  const weekDaySorter: { [key: string]: number } = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  }

  const tableData: Array<ReminderPreset> = useMemo(() => {
    if (!reminderPresets?.items) return []
    return reminderPresets.items
  }, [reminderPresets])

  const columns: Column<ReminderPreset>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => row.name,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: (row) => row.description,
      },
      {
        id: 'FREQUENCY',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('FREQUENCY'),
        accessor: (row) => {
          return (
            <FrequencyColumn>
              <span>
                {(() => {
                  if (row.schedule.frequency === 'weekly')
                    return _.orderBy(
                      row.schedule.weekdays,
                      (row) => weekDaySorter[row]
                    ).map((day) => (
                      <DayOfTheWeekChip key={day} color="cyan">
                        {_.upperCase(day)}
                      </DayOfTheWeekChip>
                    ))
                  if (row.schedule.frequency === 'daily')
                    if (row.schedule.interval && row.schedule.interval > 1)
                      return (
                        <>
                          {t('Every ')}
                          {row.schedule.interval}
                          {t(' Days')}
                        </>
                      )
                    else return <>{t('Every Day')}</>
                  return <>null</>
                })()}
              </span>
            </FrequencyColumn>
          )
        },
        Filter: (tableInstance: TableInstance<ReminderPreset>) => (
          <CategoryFilter
            tableInstance={tableInstance}
            icon="reminders"
            label={t('Frequency')}
            categories={getCategories({
              tableInstance,
              accessor: (row) => _.startCase(row.schedule.frequency),
            })}
          />
        ),
        filter: filterRowsByCategory((row) =>
          _.startCase(row.schedule.frequency)
        ),
      },
      {
        id: 'ACTIONS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t(''),
        accessor: (row) => (
          <ActionsCell editReminderPreset={editReminderPreset} row={row} />
        ),
        width: '30px',
        disableSortBy: true,
      },
    ],
    [accountPermissions, i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  if (isLoading) return <LoadingIcon />
  return (
    <>
      <List
        tableInstance={tableInstance}
        searchPlaceholder={t('Search reminder presets')}
        emptyDescription={t(
          'Try changing your search term or contact your administrator'
        )}
      />
    </>
  )
}

const DayOfTheWeekChip = tw(Chip)`mr-1`
const FrequencyColumn = tw.div`flex self-center`

export default ReminderPresetsList

type ActionsCellProps = {
  row: ReminderPreset
  editReminderPreset: (selectedPreset: ReminderPreset) => void
}

const ActionsCell = ({ row, editReminderPreset }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { mutate: deleteReminderPreset } = useDeleteReminderPresetMutation()

  return (
    <>
      <EllipsisDropdown
        options={[
          {
            label: t('Edit'),
            onClick: ({ setIsDropdownVisible }) => {
              editReminderPreset(row)
              setIsDropdownVisible(false)
            },
          },
          {
            label: t('Delete'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDropdownVisible(false)
              setIsDialogOpen(true)
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
      <Dialog
        open={isDialogOpen}
        title={`${t('Delete')} ${row.name} ${t(' reminder preset')}?`}
        content={
          <p>
            {t(
              'Are you sure you want to delete this reminder preset? This will remove it completely'
            )}
          </p>
        }
        actions={
          <>
            <Button
              type="danger-filled"
              onClick={() => {
                deleteReminderPreset({
                  orgId: assumedOrganizationRole?.orgID || '',
                  presetId: row.id || '',
                })
                return setIsDialogOpen(false)
              }}
            >
              {t('Delete')}
            </Button>
            <Button type="secondary" onClick={() => setIsDialogOpen(false)}>
              {t('Cancel')}
            </Button>
          </>
        }
      />
    </>
  )
}
