import { vitalsApi } from 'utils'
import { useQuery } from 'react-query'

// used to get translation keys
const useReminderMessageTranslationsQuery = (
  organizationId: string,
  locale: string
) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages'],
    KyError
  >(['getReminderMessageTranslations', organizationId, locale], () =>
    getReminderMessageTranslations(organizationId, locale)
  )

  return query
}

export default useReminderMessageTranslationsQuery

// get translation keys
const getReminderMessageTranslations = async (
  organizationId: string,
  locale: string
) => {
  const result = await vitalsApi
    .get(`organization/${organizationId}/reminder-messages?locale=${locale}`)
    .json<
      VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
    >()

  return result
}
