import { rest } from 'msw'
import faker from 'faker'
import {
  generateAuditable,
  generateArchivable,
  generateDisableable,
  generateParty,
} from '../utils'
import db from '../../data/db'

const adminsAdminHandlers = [
  // get admin list
  rest.get<undefined, GetAdminListResponse>(
    '/api/ecg-admins',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Admin[]>('adminList'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // add admin
  rest.post<CreateAdminRequest, CreateAdminResponse>(
    '/api/ecg-admins',
    (req, res, ctx) => {
      const newAdmin = {
        id: faker.random.uuid(),
        policies: req.body.policies,
        person: {
          id: faker.random.uuid(),
          firstName: req.body.person.firstName,
          lastName: req.body.person.lastName,
          salutation: req.body.person.salutation || 1,
          emailAddress:
            req.body.person.contactEmailAddress || req.body.account.email || '',
          party: generateParty(),
        },
        ...generateAuditable(),
        ...generateArchivable(),
        ...generateDisableable(),
      }

      // update the db
      db.set<Admin[]>('adminList', [...db.get<Admin[]>('adminList'), newAdmin])

      return res(ctx.json(newAdmin))
    }
  ),
  // activate admin
  rest.post<undefined, ActivateAdminResponse>(
    '/api/ecg-admins/:adminId/activate',
    (req, res, ctx) => {
      const admins = db.get<Admin[]>('adminList')

      const adminToUpdate = admins.find(
        (admin) => admin.id === req.params.adminId
      )

      if (!adminToUpdate) throw new Error('Admin not found')

      // update the db
      db.set<Admin[]>(
        'adminList',
        admins.map((admin) =>
          admin.id === req.params.adminId
            ? {
                ...adminToUpdate,
                activeInfo: {
                  ...adminToUpdate.activeInfo,
                  active: true,
                },
              }
            : admin
        )
      )

      return res(ctx.json({ value: true }))
    }
  ),

  // deactivate admin
  rest.post<undefined, DeactivateAdminResponse>(
    '/api/ecg-admins/:adminId/deactivate',
    (req, res, ctx) => {
      const admins = db.get<Admin[]>('adminList')

      const adminToUpdate = admins.find(
        (admin) => admin.id === req.params.adminId
      )

      if (!adminToUpdate) throw new Error('Admin not found')

      // update the db
      db.set<Admin[]>(
        'adminList',
        admins.map((admin) =>
          admin.id === req.params.adminId
            ? {
                ...adminToUpdate,
                activeInfo: {
                  ...adminToUpdate.activeInfo,
                  active: false,
                },
              }
            : admin
        )
      )

      return res(ctx.json({ value: true }))
    }
  ),

  // admin details
  rest.get<undefined, GetAdminResponse>(
    '/api/ecg-admins/:adminId',
    (req, res, ctx) => {
      const admin = db
        .get<Admin[]>('adminList')
        .find((admin) => admin.id === req.params.adminId)

      if (!admin) throw new Error('No admin with provided id found')

      return res(ctx.json(admin))
    }
  ),

  // patch admin details
  rest.patch<UpdateObject[], Admin>(
    '/api/ecg-admins/:adminId',
    (req, res, ctx) => {
      const adminList = db.get<Admin[]>('adminList')

      const admin = adminList.find((admin) => admin.id === req.params.adminId)

      if (!admin) throw new Error('No admin with provided id found')

      // update the admin list
      const updatedAdminList = adminList.map((admin) =>
        admin.id === req.params.adminId
          ? { ...admin, policies: req.body[0].value as string[] }
          : admin
      )

      // update the db
      db.set<Admin[]>('adminList', updatedAdminList)

      const updatedAdmin = updatedAdminList.find(
        (admin) => admin.id === req.params.adminId
      )

      if (!updatedAdmin) throw new Error('No admin exists for this update')

      return res(ctx.json(updatedAdmin))
    }
  ),
]

export default adminsAdminHandlers
