import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useUserQuery } from 'hooks/user-profile'
import {
  ECGAdminOverview,
  OrganizationOverview,
  StaffOverview,
} from 'components/overview'

const Overview = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const userInfoQuery = useUserQuery()

  if (assumedOrganizationRole) return <OrganizationOverview />

  if (userInfoQuery.data?.roles.includes('org-admin')) return <StaffOverview />

  return <ECGAdminOverview />
}

export default Overview
