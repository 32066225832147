import { rest } from 'msw'
import faker from 'faker'
import {
  generateAuditable,
  generateArchivable,
  generateDisableable,
  updateDatabaseArray,
} from '../utils'
import db from '../../data/db'
import { isPresent } from 'utils'

const accessControlModuleHandlers = [
  // get modules
  rest.get<undefined, OrgsAPIResponse['GET']['/api/modules']>(
    '/api/modules',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Module[]>('modules'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // add modules
  rest.post<
    OrgsAPIRequest['POST']['/api/modules']['body'],
    OrgsAPIResponse['POST']['/api/modules']
  >('/api/modules', (req, res, ctx) => {
    const newModule = {
      name: req.body.name,
      description: req.body.description || '',
      id: faker.random.uuid(),
      applications: [],
      permissions: [],
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
    }

    // update the db
    db.set<Module[]>('modules', [...db.get<Module[]>('modules'), newModule])

    return res(ctx.json(newModule))
  }),

  // update module
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/modules/{moduleId}']['body'],
    OrgsAPIResponse['PATCH']['/api/modules/{moduleId}']
  >('/api/modules/:moduleId', (req, res, ctx) => {
    const modules = db.get<Module[]>('modules')

    const moduleToUpdate = modules.find(
      (module) => module.id === req.params.moduleId
    )

    if (!moduleToUpdate) throw new Error('Module not found')

    const updatedModules = updateDatabaseArray<Module>({
      array: modules,
      requests: req.body,
      itemToUpdate: moduleToUpdate,
      matchBy: 'id',
    })

    // update the db
    db.set<Module[]>('modules', updatedModules)

    return res(ctx.json(moduleToUpdate))
  }),

  // activate module
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/activate']
  >('/api/modules/:moduleId/activate', (req, res, ctx) => {
    const modules = db.get<Module[]>('modules')

    const moduleToUpdate = modules.find(
      (module) => module.id === req.params.moduleId
    )

    if (!moduleToUpdate) throw new Error('Module not found')

    const updatedModules: Module[] = modules.map((module) => {
      if (module.id === req.params.moduleId)
        return {
          ...moduleToUpdate,
          activeInfo: {
            ...moduleToUpdate.activeInfo,
            active: true,
          },
        }

      return module
    })

    // update the db
    db.set<Module[]>('modules', updatedModules)

    return res(ctx.json({ value: true }))
  }),

  // deactivate module
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/deactivate']
  >('/api/modules/:moduleId/deactivate', (req, res, ctx) => {
    const modules = db.get<Module[]>('modules')

    const moduleToUpdate = modules.find(
      (module) => module.id === req.params.moduleId
    )

    if (!moduleToUpdate) throw new Error('Module not found')

    const updatedModules: Module[] = modules.map((module) => {
      if (module.id === req.params.moduleId)
        return {
          ...moduleToUpdate,
          activeInfo: {
            ...moduleToUpdate.activeInfo,
            active: false,
          },
        }

      return module
    })

    // update the db
    db.set<Module[]>('modules', updatedModules)

    return res(ctx.json({ value: true }))
  }),

  // archive module
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/archive']
  >('/api/modules/:moduleId/archive', (req, res, ctx) => {
    const modules = db.get<Module[]>('modules')

    const moduleToUpdate = modules.find(
      (module) => module.id === req.params.moduleId
    )

    if (!moduleToUpdate) throw new Error('Module not found')

    const updatedModules: Module[] = modules.map((module) => {
      if (module.id === req.params.moduleId)
        return {
          ...moduleToUpdate,
          archiveInfo: {
            ...moduleToUpdate.archiveInfo,
            archived: true,
          },
          activeInfo: {
            ...moduleToUpdate.activeInfo,
            active: false,
          },
        }

      return module
    })

    // update the db
    db.set<Module[]>('modules', updatedModules)

    return res(ctx.json({ value: true }))
  }),

  // restore module
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/restore']
  >('/api/modules/:moduleId/restore', (req, res, ctx) => {
    const modules = db.get<Module[]>('modules')

    const moduleToUpdate = modules.find(
      (module) => module.id === req.params.moduleId
    )

    if (!moduleToUpdate) throw new Error('Module not found')

    const updatedModules: Module[] = modules.map((module) => {
      if (module.id === req.params.moduleId)
        return {
          ...moduleToUpdate,
          archiveInfo: {
            ...moduleToUpdate.archiveInfo,
            archived: false,
          },
          activeInfo: {
            ...moduleToUpdate.activeInfo,
            active: false,
          },
        }

      return module
    })

    // update the db
    db.set<Module[]>('modules', updatedModules)

    return res(ctx.json({ value: true }))
  }),

  // assign application to module
  rest.post<
    OrgsAPIRequest['POST']['/api/modules/{moduleId}/applications']['body'],
    OrgsAPIResponse['POST']['/api/modules/{moduleId}/applications']
  >('/api/modules/:moduleId/applications', (req, res, ctx) => {
    const modules = db.get<Module[]>('modules')
    const applications = db.get<Application[]>('applications')

    const moduleToUpdate = modules.find(
      (module) => module.id === req.params.moduleId
    )

    const applicationsToAdd = applications.filter((module) =>
      req.body.applicationIds.includes(module.id)
    )

    if (!moduleToUpdate || !isPresent(applicationsToAdd))
      throw new Error('Module not found')

    // generate the updated module value
    const updatedModule = {
      ...moduleToUpdate,
      applications: [
        ...(moduleToUpdate.applications || []),
        ...applicationsToAdd,
      ],
    }

    // update the db
    db.set<Module[]>(
      'modules',
      modules.map((module) => {
        if (module.id === req.params.moduleId) return updatedModule

        return module
      })
    )

    return res(ctx.json(updatedModule))
  }),

  // remove application from module
  rest.delete<
    undefined,
    OrgsAPIResponse['DELETE']['/api/modules/{moduleId}/application/{applicationId}']
  >('/api/modules/:moduleId/application/:applicationId', (req, res, ctx) => {
    const modules = db.get<Module[]>('modules')

    const moduleToUpdate = modules.find(
      (module) => module.id === req.params.moduleId
    )

    if (!moduleToUpdate) throw new Error('Module not found')

    // generate the updated module value
    const updatedModule = {
      ...moduleToUpdate,
      applications: moduleToUpdate.applications?.filter(
        (application) => application.id !== req.params.applicationId
      ),
    }

    // update the db
    db.set<Module[]>(
      'modules',
      modules.map((module) => {
        if (module.id === req.params.moduleId) return updatedModule

        return module
      })
    )

    return res(ctx.json({ value: true }))
  }),

  // delete module
  rest.delete<undefined, OrgsAPIResponse['DELETE']['/api/modules/{moduleId}']>(
    '/api/modules/:moduleId',
    (req, res, ctx) => {
      const modules = db.get<Module[]>('modules')

      const newValue: Module[] = modules.filter(
        (module) => module.id !== req.params.moduleId
      )

      // update the db
      db.set<Module[]>('modules', newValue)

      return res(ctx.json({ value: true }))
    }
  ),

  // get permissions
  rest.get<undefined, OrgsAPIResponse['GET']['/api/permissions']>(
    '/api/permissions',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Permission[]>('permissions'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // add permission
  rest.post<
    OrgsAPIRequest['POST']['/api/permissions']['body'],
    OrgsAPIResponse['POST']['/api/permissions']
  >('/api/permissions', (req, res, ctx) => {
    const permissions = db.get<Permission[]>('permissions')
    const modules = db.get<Module[]>('modules')

    const response = {
      name: req.body.name,
      description: req.body.description || '',
      code: req.body.code,
      id: faker.random.uuid(),
      module: modules.find(
        (module) => module.id === req.body.moduleId
      ) as Module,
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
    }

    // update the db
    db.set<Permission[]>('permissions', [response, ...permissions])
    db.set<Module[]>(
      'modules',
      modules.map((module) => {
        if (module.id === req.body.moduleId)
          return {
            ...module,
            permissions: [...(module.permissions || []), response],
          }

        return module
      })
    )

    return res(ctx.json(response))
  }),

  // update permission
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/permissions/{permissionId}']['body'],
    OrgsAPIResponse['PATCH']['/api/permissions/{permissionId}']
  >('/api/permissions/:permissionId', (req, res, ctx) => {
    const permissions = db.get<Permission[]>('permissions')

    const permissionToUpdate = permissions.find(
      (permission) => permission.id === req.params.permissionId
    )

    if (!permissionToUpdate) throw new Error('Permission not found')

    const updatedPermissions = updateDatabaseArray<Permission>({
      array: permissions,
      requests: req.body,
      itemToUpdate: permissionToUpdate,
      matchBy: 'id',
    })

    // update the db
    db.set<Permission[]>('permissions', updatedPermissions)

    // update the related module
    const modules = db.get<Module[]>('modules')

    const moduleToUpdate = modules.find(
      (module) => module.id === permissionToUpdate.module.id
    )
    const permissionIndex = moduleToUpdate?.permissions?.findIndex(
      (permission) => permission.id === permissionToUpdate.id
    )

    if (moduleToUpdate && permissionIndex !== undefined) {
      const updatedModules = updateDatabaseArray<Module>({
        array: modules,
        requests: [
          {
            path: '/permissions',
            op: 'replace',
            value: [
              ...(moduleToUpdate.permissions?.filter(
                (permission) => permission.id !== permissionToUpdate.id
              ) || []),
              updatedPermissions.find(
                (permission) => permission.id === permissionToUpdate.id
              ),
            ],
          },
        ],
        itemToUpdate: moduleToUpdate,
        matchBy: 'id',
      })

      // update the db
      db.set<Module[]>('modules', updatedModules)
    }

    return res(ctx.json(permissionToUpdate))
  }),

  // activate permission
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/permissions/{permissionId}/activate']
  >('/api/permissions/:permissionId/activate', (req, res, ctx) => {
    const permissions = db.get<Permission[]>('permissions')
    const modules = db.get<Module[]>('modules')

    const permissionToUpdate = permissions.find(
      (permission) => permission.id === req.params.permissionId
    )

    if (!permissionToUpdate) throw new Error('Permission not found')

    const updatedPermissions: Permission[] = permissions.map((permission) => {
      if (permission.id === req.params.permissionId)
        return {
          ...permissionToUpdate,
          activeInfo: {
            ...permissionToUpdate.activeInfo,
            active: true,
          },
        }

      return permission
    })

    // update the db
    db.set<Permission[]>('permissions', updatedPermissions)
    db.set<Module[]>(
      'modules',
      modules.map((module) => {
        if (module.id === permissionToUpdate.module.id)
          return {
            ...module,
            permissions: module.permissions?.map((permission) => {
              if (permission.id === permissionToUpdate.id)
                return {
                  ...permissionToUpdate,
                  activeInfo: {
                    ...permissionToUpdate.activeInfo,
                    active: true,
                  },
                }

              return permission
            }),
          }

        return module
      })
    )

    return res(ctx.json({ value: true }))
  }),

  // deactivate permission
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/permissions/{permissionId}/deactivate']
  >('/api/permissions/:permissionId/deactivate', (req, res, ctx) => {
    const permissions = db.get<Permission[]>('permissions')

    const permissionToUpdate = permissions.find(
      (permission) => permission.id === req.params.permissionId
    )

    if (!permissionToUpdate) throw new Error('Permission not found')

    // update the db
    db.set<Permission[]>(
      'permissions',
      permissions.map((permission) =>
        permission.id === req.params.permissionId
          ? {
              ...permissionToUpdate,
              activeInfo: {
                ...permissionToUpdate.activeInfo,
                active: false,
              },
            }
          : permission
      )
    )
    db.set<Module[]>(
      'modules',
      db.get<Module[]>('modules').map((module) =>
        module.id === permissionToUpdate.module.id
          ? {
              ...module,
              permissions: module.permissions?.map((permission) =>
                permission.id === permissionToUpdate.id
                  ? {
                      ...permissionToUpdate,
                      activeInfo: {
                        ...permissionToUpdate.activeInfo,
                        active: false,
                      },
                    }
                  : permission
              ),
            }
          : module
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // archive permission
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/permissions/{permissionId}/archive']
  >('/api/permissions/:permissionId/archive', (req, res, ctx) => {
    const permissions = db.get<Permission[]>('permissions')

    const permissionToUpdate = permissions.find(
      (permission) => permission.id === req.params.permissionId
    )

    if (!permissionToUpdate) throw new Error('Permission not found')

    // update the db
    db.set<Permission[]>(
      'permissions',
      permissions.map((permission) =>
        permission.id === req.params.permissionId
          ? {
              ...permissionToUpdate,
              archiveInfo: {
                ...permissionToUpdate.archiveInfo,
                archived: true,
              },
              activeInfo: {
                ...permissionToUpdate.activeInfo,
                active: false,
              },
            }
          : permission
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // restore permission
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/permissions/{permissionId}/restore']
  >('/api/permissions/:permissionId/restore', (req, res, ctx) => {
    const permissions = db.get<Permission[]>('permissions')

    const permissionToUpdate = permissions.find(
      (permission) => permission.id === req.params.permissionId
    )

    if (!permissionToUpdate) throw new Error('Permission not found')

    // update the db
    db.set<Permission[]>(
      'permissions',
      permissions.map((permission) =>
        permission.id === req.params.permissionId
          ? {
              ...permissionToUpdate,
              archiveInfo: {
                ...permissionToUpdate.archiveInfo,
                archived: false,
              },
            }
          : permission
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // delete permission
  rest.delete<
    undefined,
    OrgsAPIResponse['DELETE']['/api/permissions/{permissionId}']
  >('/api/permissions/:permissionId', (req, res, ctx) => {
    const permissions = db.get<Permission[]>('permissions')

    const permissionToUpdate = permissions.find(
      (permission) => permission.id === req.params.permissionId
    )

    if (!permissionToUpdate) throw new Error('Permission not found')

    // update the db
    db.set<Permission[]>(
      'permissions',
      permissions.filter(
        (permission) => permission.id !== req.params.permissionId
      )
    )

    return res(ctx.json({ value: true }))
  }),

  /* POLICIES */

  // get policies
  rest.get<undefined, OrgsAPIResponse['GET']['/api/policies']>(
    '/api/policies',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Policy[]>('policies'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // add policy
  rest.post<
    OrgsAPIRequest['POST']['/api/policies']['body'],
    OrgsAPIResponse['POST']['/api/policies']
  >('/api/policies', (req, res, ctx) => {
    const newPolicy = {
      name: req.body.name,
      description:
        req.body.description !== undefined ? req.body.description : '',
      code: req.body.code,
      IsInUse: false,
      permissions: db
        .get<Permission[]>('permissions')
        .filter((permission) => req.body.permissions?.includes(permission.id)),
      internalUseOnly: false,
      id: faker.random.uuid(),
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
    }

    // update the db
    db.set<Policy[]>('policies', [...db.get<Policy[]>('policies'), newPolicy])

    return res(ctx.json(newPolicy))
  }),

  // update policy
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/policies/{policyId}']['body'],
    OrgsAPIResponse['PATCH']['/api/policies/{policyId}']
  >('/api/policies/:policyId', (req, res, ctx) => {
    const policies = db.get<Policy[]>('policies')

    const policyToUpdate = policies.find(
      (policy) => policy.id === req.params.policyId
    )

    if (!policyToUpdate) throw new Error('Policy not found')

    // update the db
    db.set<Policy[]>(
      'policies',
      updateDatabaseArray<Policy>({
        array: policies,
        requests: req.body,
        itemToUpdate: policyToUpdate,
        matchBy: 'id',
      })
    )

    return res(ctx.json(policyToUpdate))
  }),

  // activate policy
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/policies/{policyId}/activate']
  >('/api/policies/:policyId/activate', (req, res, ctx) => {
    const policies = db.get<Policy[]>('policies')

    const policyToUpdate = policies.find(
      (policy) => policy.id === req.params.policyId
    )

    if (!policyToUpdate) throw new Error('Policy not found')

    // update the db
    db.set<Policy[]>(
      'policies',
      policies.map((policy) =>
        policy.id === req.params.policyId
          ? {
              ...policyToUpdate,
              activeInfo: {
                ...policyToUpdate.activeInfo,
                active: true,
              },
            }
          : policy
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // deactivate policy
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/policies/{policyId}/deactivate']
  >('/api/policies/:policyId/deactivate', (req, res, ctx) => {
    const policies = db.get<Policy[]>('policies')

    const policyToUpdate = policies.find(
      (policy) => policy.id === req.params.policyId
    )

    if (!policyToUpdate) throw new Error('Policy not found')

    // update the db
    db.set<Policy[]>(
      'policies',
      policies.map((policy) =>
        policy.id === req.params.policyId
          ? {
              ...policyToUpdate,
              activeInfo: {
                ...policyToUpdate.activeInfo,
                active: false,
              },
            }
          : policy
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // archive policy
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/policies/{policyId}/archive']
  >('/api/policies/:policyId/archive', (req, res, ctx) => {
    const policies = db.get<Policy[]>('policies')

    const policyToUpdate = policies.find(
      (policy) => policy.id === req.params.policyId
    )

    if (!policyToUpdate) throw new Error('Policy not found')

    // update the db
    db.set<Policy[]>(
      'policies',
      policies.map((policy) =>
        policy.id === req.params.policyId
          ? {
              ...policyToUpdate,
              archiveInfo: {
                ...policyToUpdate.archiveInfo,
                archived: true,
              },
            }
          : policy
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // restore policy
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/policies/{policyId}/restore']
  >('/api/policies/:policyId/restore', (req, res, ctx) => {
    const policies = db.get<Policy[]>('policies')

    const policyToUpdate = policies.find(
      (policy) => policy.id === req.params.policyId
    )

    if (!policyToUpdate) throw new Error('Policy not found')

    // update the db
    db.set<Policy[]>(
      'policies',
      policies.map((policy) =>
        policy.id === req.params.policyId
          ? {
              ...policyToUpdate,
              archiveInfo: {
                ...policyToUpdate.archiveInfo,
                archived: false,
              },
            }
          : policy
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // delete policy
  rest.delete<undefined, OrgsAPIResponse['DELETE']['/api/policies/{policyId}']>(
    '/api/policies/:policyId',
    (req, res, ctx) => {
      const policies = db.get<Policy[]>('policies')

      const policyToUpdate = policies.find(
        (policy) => policy.id === req.params.policyId
      )

      if (!policyToUpdate) throw new Error('Policy not found')

      // update the db
      db.set<Policy[]>(
        'policies',
        policies.filter((policy) => policy.id !== req.params.policyId)
      )

      return res(ctx.json({ value: true }))
    }
  ),

  /* POLICY GROUPS */

  // get policy groups
  rest.get<undefined, OrgsAPIResponse['GET']['/api/policy-groups']>(
    '/api/policy-groups',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<PolicyGroup[]>('policyGroups'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // add policy group
  rest.post<
    OrgsAPIRequest['POST']['/api/policy-groups']['body'],
    OrgsAPIResponse['POST']['/api/policy-groups']
  >('/api/policy-groups', (req, res, ctx) => {
    const policyGroups = db.get<PolicyGroup[]>('policyGroups')
    const policies = db.get<Policy[]>('policies')

    const newPolicyGroup = {
      title: req.body.title,
      description:
        req.body.description !== undefined ? req.body.description : '',
      code: req.body.code,
      policies: policies.filter((permission) =>
        req.body.policies.includes(permission.id)
      ),
      id: faker.random.uuid(),
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
    }

    // update the db
    db.set<PolicyGroup[]>('policyGroups', [newPolicyGroup, ...policyGroups])

    return res(ctx.json(newPolicyGroup))
  }),

  // update policy group
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/policy-groups/{policyGroupId}']['body'],
    OrgsAPIResponse['PATCH']['/api/policy-groups/{policyGroupId}']
  >('/api/policy-groups/:policyGroupId', (req, res, ctx) => {
    const policyGroups = db.get<PolicyGroup[]>('policyGroups')

    const policyGroupToUpdate = policyGroups.find(
      (policy) => policy.id === req.params.policyGroupId
    )

    if (!policyGroupToUpdate) throw new Error('Policy group not found')

    // update the db
    db.set<PolicyGroup[]>(
      'policyGroups',
      updateDatabaseArray<PolicyGroup>({
        array: policyGroups,
        requests: req.body,
        itemToUpdate: policyGroupToUpdate,
        matchBy: 'id',
      })
    )

    return res(ctx.json(policyGroupToUpdate))
  }),

  // archive policy group
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/policy-groups/{policyGroupId}/archive']
  >('/api/policy-groups/:policyGroupId/archive', (req, res, ctx) => {
    const policyGroups = db.get<PolicyGroup[]>('policyGroups')

    const policyGroupToUpdate = policyGroups.find(
      (policy) => policy.id === req.params.policyGroupId
    )

    if (!policyGroupToUpdate) throw new Error('Policy group not found')

    // update the db
    db.set<PolicyGroup[]>(
      'policyGroups',
      policyGroups.map((policyGroup) =>
        policyGroup.id === req.params.policyGroupId
          ? {
              ...policyGroupToUpdate,
              archiveInfo: {
                ...policyGroupToUpdate.archiveInfo,
                archived: true,
              },
            }
          : policyGroup
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // restore policy group
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/policy-groups/{policyGroupId}/restore']
  >('/api/policy-groups/:policyGroupId/restore', (req, res, ctx) => {
    const policyGroups = db.get<PolicyGroup[]>('policyGroups')

    const policyGroupToUpdate = policyGroups.find(
      (policyGroup) => policyGroup.id === req.params.policyGroupId
    )

    if (!policyGroupToUpdate) throw new Error('Policy group not found')

    // update the db
    db.set<PolicyGroup[]>(
      'policyGroups',
      policyGroups.map((policyGroup) =>
        policyGroup.id === req.params.policyGroupId
          ? {
              ...policyGroupToUpdate,
              archiveInfo: {
                ...policyGroupToUpdate.archiveInfo,
                archived: false,
              },
            }
          : policyGroup
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // delete policy group
  rest.delete<
    undefined,
    OrgsAPIResponse['DELETE']['/api/policy-groups/{policyGroupId}']
  >('/api/policy-groups/:policyGroupId', (req, res, ctx) => {
    const policyGroups = db.get<PolicyGroup[]>('policyGroups')

    const policyGroupToUpdate = policyGroups.find(
      (policyGroup) => policyGroup.id === req.params.policyGroupId
    )

    if (!policyGroupToUpdate) throw new Error('Policy group not found')

    // update the db
    db.set<PolicyGroup[]>(
      'policyGroups',
      policyGroups.filter(
        (policyGroup) => policyGroup.id !== req.params.policyGroupId
      )
    )

    return res(ctx.json({ value: true }))
  }),

  /* APPLICATIONS */

  // get applications
  rest.get<undefined, OrgsAPIResponse['GET']['/api/applications']>(
    '/api/applications',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Application[]>('applications'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // add application
  rest.post<
    OrgsAPIRequest['POST']['/api/applications']['body'],
    OrgsAPIResponse['POST']['/api/applications']
  >('/api/applications', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')
    const modules = db.get<Module[]>('modules')

    const newApplication = {
      name: req.body.name,
      description: req.body.description,
      cognitoAppId:
        req.body.cognitoAppId !== undefined ? req.body.cognitoAppId : '',
      modules: modules.filter((module) =>
        req.body.modules?.includes(module.id)
      ),
      id: faker.random.uuid(),
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
    }

    // update the db
    db.set<Application[]>('applications', [newApplication, ...applications])

    return res(ctx.json(newApplication))
  }),

  // update application
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/applications/{applicationId}']['body'],
    OrgsAPIResponse['PATCH']['/api/applications/{applicationId}']
  >('/api/applications/:applicationId', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')

    const applicationToUpdate = applications.find(
      (application) => application.id === req.params.applicationId
    )

    if (!applicationToUpdate) throw new Error('Application not found')

    // update the db
    db.set<Application[]>(
      'applications',
      updateDatabaseArray<Application>({
        array: applications,
        requests: req.body,
        itemToUpdate: applicationToUpdate,
        matchBy: 'id',
      })
    )

    return res(ctx.json(applicationToUpdate))
  }),

  // activate application
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/applications/{applicationId}/activate']
  >('/api/applications/:applicationId/activate', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')

    const applicationToUpdate = applications.find(
      (application) => application.id === req.params.applicationId
    )

    if (!applicationToUpdate) throw new Error('Application not found')

    // update the db
    db.set<Application[]>(
      'applications',
      applications.map((application) =>
        application.id === req.params.applicationId
          ? {
              ...applicationToUpdate,
              activeInfo: {
                ...applicationToUpdate.activeInfo,
                active: true,
              },
            }
          : application
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // deactivate application
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/applications/{applicationId}/deactivate']
  >('/api/applications/:applicationId/deactivate', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')

    const applicationToUpdate = applications.find(
      (application) => application.id === req.params.applicationId
    )

    if (!applicationToUpdate) throw new Error('Application not found')

    // update the db
    db.set<Application[]>(
      'applications',
      applications.map((application) =>
        application.id === req.params.applicationId
          ? {
              ...applicationToUpdate,
              activeInfo: {
                ...applicationToUpdate.activeInfo,
                active: false,
              },
            }
          : application
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // archive application
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/applications/{applicationId}/archive']
  >('/api/applications/:applicationId/archive', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')

    const applicationToUpdate = applications.find(
      (application) => application.id === req.params.applicationId
    )

    if (!applicationToUpdate) throw new Error('Application not found')

    // update the db
    db.set<Application[]>(
      'applications',
      applications.map((application) =>
        application.id === req.params.applicationId
          ? {
              ...applicationToUpdate,
              archiveInfo: {
                ...applicationToUpdate.archiveInfo,
                archived: true,
              },
            }
          : application
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // restore application
  rest.post<
    undefined,
    OrgsAPIResponse['POST']['/api/applications/{applicationId}/restore']
  >('/api/applications/:applicationId/restore', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')

    const applicationToUpdate = applications.find(
      (application) => application.id === req.params.applicationId
    )

    if (!applicationToUpdate) throw new Error('Application not found')

    // update the db
    db.set<Application[]>(
      'applications',
      applications.map((application) =>
        application.id === req.params.applicationId
          ? {
              ...applicationToUpdate,
              archiveInfo: {
                ...applicationToUpdate.archiveInfo,
                archived: false,
              },
            }
          : application
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // delete application
  rest.delete<
    undefined,
    OrgsAPIResponse['DELETE']['/api/applications/{applicationId}']
  >('/api/applications/:applicationId', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')

    const applicationToUpdate = applications.find(
      (application) => application.id === req.params.applicationId
    )

    if (!applicationToUpdate) throw new Error('Application not found')

    // update the db
    db.set<Application[]>(
      'applications',
      applications.filter(
        (application) => application.id !== req.params.applicationId
      )
    )

    return res(ctx.json({ value: true }))
  }),

  // assign modules to application
  rest.post<
    OrgsAPIRequest['POST']['/api/applications/{applicationId}/modules']['body'],
    OrgsAPIResponse['POST']['/api/applications/{applicationId}/modules']
  >('/api/applications/:applicationId/modules', (req, res, ctx) => {
    const applications = db.get<Application[]>('applications')
    const modules = db.get<Module[]>('modules')

    const applicationToUpdate = applications.find(
      (application) => application.id === req.params.applicationId
    )

    if (!applicationToUpdate) throw new Error('Application not found')

    // update the db
    db.set<Application[]>(
      'applications',
      updateDatabaseArray<Application>({
        array: applications,
        requests: [
          {
            value: modules.filter((module) =>
              req.body.moduleIds.includes(module.id)
            ),
            path: '/modules',
            op: 'replace',
          },
        ],
        itemToUpdate: applicationToUpdate,
        matchBy: 'id',
      })
    )

    return res(ctx.json(applicationToUpdate))
  }),
]

export default accessControlModuleHandlers
