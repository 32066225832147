import React from 'react'
import { isPresent } from 'utils'
import { FadeInSlideDown } from 'animations'
import { useGlobalMemberStatistics } from 'hooks/statistics'
import {
  usePinnedOrganizationsQuery,
  useOrganizationListQuery,
} from 'hooks/organizations'
import {
  usePinnedSubscribersQuery,
  useSubscribersQuery,
} from 'hooks/organizations'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useDelay } from 'hooks'
import tw from 'twin.macro'
import PinnedOrganizations from './PinnedOrganizations'
import PinnedSubscribers from './PinnedSubscribers'

const StaffOverview = () => {
  const globalMemberStatistics = useGlobalMemberStatistics()
  const pinnedOrganizationQuery = usePinnedOrganizationsQuery()
  const organizationListQuery = useOrganizationListQuery()
  const pinnedSubscribersQuery = usePinnedSubscribersQuery()
  const subscribersListQuery = useSubscribersQuery()
  const { t } = useTranslation()
  const delay = useDelay()

  return (
    <Layout
      title={t('Overview')}
      description={t('Gain a quick understanding of your subscribers.')}
      isLoading={
        globalMemberStatistics.isLoading ||
        pinnedOrganizationQuery.isLoading ||
        organizationListQuery.isLoading ||
        pinnedSubscribersQuery.isLoading ||
        subscribersListQuery.isLoading
      }
    >
      <PinnedGrid>
        {pinnedOrganizationQuery.data && organizationListQuery.data ? (
          <FadeInSlideDown delay={delay()}>
            <PinnedOrganizations
              pinnedOrganizations={pinnedOrganizationQuery.data
                .map((orgId) =>
                  organizationListQuery.data.items.find(
                    (org) => org.id === orgId
                  )
                )
                // filter out the undefined's
                .filter(isPresent)}
            />
          </FadeInSlideDown>
        ) : null}

        {pinnedSubscribersQuery.data && subscribersListQuery.data ? (
          <FadeInSlideDown delay={delay()}>
            <PinnedSubscribers
              pinnedSubscribers={pinnedSubscribersQuery.data
                .map((subId) =>
                  subscribersListQuery.data.items?.find(
                    (sub) => sub.id === subId
                  )
                )
                // filter out the undefined's
                .filter(isPresent)}
            />
          </FadeInSlideDown>
        ) : null}
      </PinnedGrid>
    </Layout>
  )
}

export default StaffOverview

const PinnedGrid = tw.div`grid grid-cols-1 xl:grid-cols-2 gap-4`
