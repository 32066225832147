import pWaitFor from 'p-wait-for'
import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

const useExportAlerts = ({
  dateRangeState,
  organizationId,
  subscriberId,
  mode,
}: AlertReportRequest) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/api/insights/reporting/status/{requestId}'],
    KyError
  >(
    ['getExportAlerts', dateRangeState, organizationId, subscriberId],
    () => getExportAlerts(dateRangeState, mode, organizationId, subscriberId),
    { enabled: !!(dateRangeState && mode) }
  )

  return query
}

export default useExportAlerts

const getExportAlerts = async (
  dateRangeState?: DateRangeState,
  mode?: string,
  organizationId?: string,
  subscriberId?: Array<string> | 'all'
) => {
  if (!dateRangeState || !mode) {
    throw new Error('Date range or mode does not exist')
  }
  const searchParams = new URLSearchParams([
    ['dateRangeType', dateRangeState.preset],
    ['mode', mode],
    ['reportType', 'vitals-alert'],
  ])
  if (organizationId && organizationId !== 'all')
    searchParams.append('organizationId', organizationId)

  if (subscriberId && subscriberId !== 'all')
    subscriberId.forEach((sub) => searchParams.append('subscriberId', sub))

  if (dateRangeState.preset === 'custom') {
    searchParams.append('from', dateRangeState.value[0].toISOString())
    searchParams.append('to', dateRangeState.value[1].toISOString())
  }

  const report = await vitalsApi
    .get(`api/insights/reporting`, {
      searchParams: searchParams.toString(),
    })
    .json<VitalsAPIResponse['GET']['/api/insights/reporting']>()

  let response = await vitalsApi
    .get(`api/insights/reporting/status/${report.requestId}`)
    .json<
      VitalsAPIResponse['GET']['/api/insights/reporting/status/{requestId}']
    >()

  // refetch status of pdf until its done or if 10 secs have passed
  await pWaitFor(
    async () => {
      if (response.status !== 'processing') return true
      response = await vitalsApi
        .get(`api/insights/reporting/status/${report.requestId}`)
        .json<
          VitalsAPIResponse['GET']['/api/insights/reporting/status/{requestId}']
        >()
      return false
    },
    // before set to false since response will likely return processing first call
    { interval: 1500, timeout: 10000, before: false }
  )
  if (response.reportUrl) {
    window.location.href = response.reportUrl
  }
  return response
}
