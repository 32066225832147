import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { orgsApi } from 'utils'

const useDeletePartnerTypeMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    DeletePartnerTypeResponse,
    DeletePartnerTypeProps
  >({
    mutationFn: deletePartnerType,
    successMsg: t('Partner Type deleted'),
    optimisticUpdates: [
      {
        cacheKey: 'getPartnerTypes',
        updateFn: updateFnConstructor<
          GetPartnerTypesResponse,
          DeletePartnerTypeProps
        >((oldCache, partnerTypeId) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items.filter(
                  (partnerType) => partnerType.id !== partnerTypeId
                ),
              }
            : oldCache
        ),
      },
    ],
  })

  return mutation
}

export default useDeletePartnerTypeMutation

type DeletePartnerTypeProps = string

const deletePartnerType = async (partnerTypeId: DeletePartnerTypeProps) => {
  const result = await orgsApi
    .delete(`api/partner-types/${partnerTypeId}`)
    .json<DeletePartnerTypeResponse>()

  return result
}
