import React, { useState } from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import {
  CreateEditModuleForm,
  ModulesList,
} from 'components/access-control/modules'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useModulesQuery } from 'hooks/access-control/modules'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const AccessControlModules = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const [isCreateModuleFormOpen, setIsCreateModuleFormOpen] = useState(false)
  const { t } = useTranslation()
  const { data: modules, isLoading: isLoadingModules } = useModulesQuery()
  const navigate = useNavigate()

  const filteredModules =
    modules?.items?.filter((module) => !module.archiveInfo.archived) || []

  return (
    <Layout
      title={t('Modules')}
      description={t(
        'Manage applications and permissions associated with modules.'
      )}
      isLoading={isLoadingModules}
      isUnauthorized={
        !accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly')
      }
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Modules') },
      ]}
      isEmpty={filteredModules.length === 0}
      emptyComponent={<ModulesEmpty title={t('No Modules Created')} />}
      controls={[
        {
          label: t('Create Module'),
          onClick: () => setIsCreateModuleFormOpen(true),
        },
        {
          label: t('View Archived Modules'),
          onClick: () => navigate('archive'),
        },
        {
          label: t('View Audit Trail'),
          onClick: () => navigate('audit-trail'),
        },
      ]}
    >
      <ModulesList data={filteredModules} />
      <CreateEditModuleForm
        isFormOpen={isCreateModuleFormOpen}
        setIsFormOpen={setIsCreateModuleFormOpen}
      />
    </Layout>
  )
}

export default AccessControlModules

const ModulesEmpty = tw(Empty)`flex flex-grow border-2 border-dashed rounded`
