import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useUpdateOrganizationAddressMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/api/organizations/{organizationId}/addresses/{addressId}'],
    updateOrganizationAddressArgs
  >({
    mutationFn: updateOrganizationAddress,
    successMsg: { message: t('Organization updated'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        updateOn: 'onSuccess',
        cacheKey: ({ organization }) => ['getOrganization', organization.id],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/organizations/{organizationId}'],
          updateOrganizationAddressArgs
        >(
          (oldOrganization, { newAddress }) =>
            oldOrganization && {
              ...oldOrganization,
              party: {
                ...oldOrganization.party,
                addresses: oldOrganization.party.addresses.map((address) =>
                  address.id === newAddress.id ? newAddress : address
                ),
              },
            }
        ),
      },
    ],
    additionalCachesToInvalidate: ['getOrganizationList'],
  })

  return mutation
}

export default useUpdateOrganizationAddressMutation

type updateOrganizationAddressArgs = {
  organization: Organization
  newAddress: OrganizationAddress
}

const updateOrganizationAddress = async ({
  organization,
  newAddress,
}: updateOrganizationAddressArgs) =>
  typedOrgsApi.patch(
    '/api/organizations/{organizationId}/addresses/{addressId}',
    {
      pathParams: { organizationId: organization.id, addressId: newAddress.id },
      // List of properties that will get PATCHed
      body: Object.entries({
        addressLine1: newAddress.addressLine1,
        addressLine2: newAddress.addressLine2,
        stateProvinceCode: newAddress.stateProvinceCode,
        city: newAddress.city,
        postalCode: newAddress.postalCode,
        countryCode: newAddress.countryCode,
      }).map(([key, value]) => ({
        op: 'replace' as const,
        path: `/${key}`,
        value,
      })),
    }
  )
