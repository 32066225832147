import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useSalutationTypesQuery = () => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/seed-data/salutation-types'],
    KyError
  >('getSalutationTypes', getSalutationTypes)

  return query
}

export default useSalutationTypesQuery

const getSalutationTypes = async () =>
  typedOrgsApi.get(`/api/seed-data/salutation-types`)
