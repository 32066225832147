import _ from 'lodash'

/**
 * Used to crawl through org levels
 * @param {OrganizationHierarchyItem} orgList
 * @param {number} level
 * @returns {OrganizationHierarchyItem} return a sorted array of Organizations with a children following parents
 */
const sortOrgHierarchy = (
  orgList: OrganizationHierarchyItem[],
  level = 3 // begin with the grandchildren of the selected org
): OrganizationHierarchyItem[] => {
  if (!orgList.find((org) => org.level >= level)) return orgList

  // first filter out all orgs at this level
  const sortedOrgList = orgList.filter((org) => org.level !== level)

  _.orderBy(
    // filter all but this level
    orgList.filter((org) => org.level === level),
    'displayName',
    'desc'
  ).forEach((org) =>
    // insert each child directly after the parent org's index
    sortedOrgList.splice(
      sortedOrgList.findIndex((parentOrg) => parentOrg.id === org.parentId) + 1,
      0,
      org
    )
  )
  return sortOrgHierarchy(sortedOrgList, level + 1)
}

export default sortOrgHierarchy
