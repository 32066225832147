import React, { useRef, useState } from 'react'
import { Icon, Dropdown, DropdownItem, ConfirmationDialogs } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useArchiveVitalSignMutation,
  useActiveVitalSignMutation,
} from 'hooks/vitals'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type ECGAdminVitalSignsCardProps = {
  vitalSign: VitalSign
  openEditForm: (vitalSign: VitalSign) => void
}

const ECGAdminVitalSignsCard = ({
  vitalSign,
  openEditForm,
}: ECGAdminVitalSignsCardProps) => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { mutate: toggleArchiveVitalSign } = useArchiveVitalSignMutation()
  const { mutate: toggleActiveVitalSign } = useActiveVitalSignMutation()
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const dropdownAnchorEl = useRef(null)
  const { t } = useTranslation()

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const editVitalSign = () => {
    // Open Form to edit this Vital Sign
    openEditForm(vitalSign)

    // Reset Popover
    setIsDropdownVisible(false)
  }
  const toggleActive = async () => {
    // API Call
    toggleActiveVitalSign({ vitalSign })

    // Reset Popover
    setIsDropdownVisible(false)
  }
  const archiveVitalSign = async () => {
    // API Call
    toggleArchiveVitalSign({ vitalSign })

    // Reset Popover
    setIsDropdownVisible(false)
  }

  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )

  const confirmations = [
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${vitalSign.name}" vital sign`),
      content: <p>{t('Are you sure you want to activate this vital sign?')}</p>,
      actions: (
        <Button
          onClick={() => {
            toggleActive()

            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${vitalSign.name}" vital sign`),
      content: <p>{t('Are you sure you want to delete this vital sign?')}</p>,
      actions: (
        <Button
          onClick={() => {
            // deleteApplication(row.id)

            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${vitalSign.name}" vital sign`),
      content: (
        <p>{t('Are you sure you want to deactivate this vital sign?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            toggleActive()

            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t(`Archive "${vitalSign.name}" vital sign`),
      content: <p>{t('Are you sure you want to archive this vital sign?')}</p>,
      actions: (
        <Button
          onClick={() => {
            archiveVitalSign()

            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
  ]

  return (
    <VitalSignsCardContainer>
      <DropdownBorderContainer>
        <OuterDropdownContainer>
          <TitleContainer>
            {vitalSign.name}
            &nbsp;&nbsp;
          </TitleContainer>

          {/* vitals.settings.admin as an ECG Admin is required to manage vital signs */}
          {accountPermissions?.data.ecgAdmin.permissions.includes(
            'vitals.settings.admin'
          ) ? (
            <InnerDropdownContainer ref={dropdownAnchorEl}>
              <EllipsisIcon
                data-testid={`${vitalSign.name}-ellipsis`}
                type="ellipsis"
                onClick={handleDropdownToggle}
              />
              <Dropdown
                visible={isDropdownVisible}
                setVisible={setIsDropdownVisible}
                parentRef={dropdownAnchorEl}
              >
                <DropdownItem onClick={() => editVitalSign()}>
                  {t('Edit')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    if (!vitalSign.activeInfo.active)
                      setIsActivateDialogOpen(true)
                    else setIsDeactivateDialogOpen(true)
                  }}
                >
                  {vitalSign.activeInfo.active
                    ? t('Deactivate')
                    : t('Activate')}
                </DropdownItem>
                <DropdownItem onClick={() => setIsArchiveDialogOpen(true)}>
                  {t('Archive')}
                </DropdownItem>
              </Dropdown>
              <ConfirmationDialogs confirmations={confirmations} />
            </InnerDropdownContainer>
          ) : null}
        </OuterDropdownContainer>
        {vitalSign.activeInfo.active ? (
          <Chip color="green">{t('Active')}</Chip>
        ) : (
          <Chip color="red">{t('Inactive')}</Chip>
        )}
      </DropdownBorderContainer>
      <DisplayContainer>
        {t('Display Name')}: <DisplaySpan>{vitalSign.displayName}</DisplaySpan>
      </DisplayContainer>
      <DisplayContainer>
        {t('Units')}:{' '}
        <DisplaySpan>
          {vitalSign.units.map((unit) => unit.displayName).join(', ')}
        </DisplaySpan>
      </DisplayContainer>
    </VitalSignsCardContainer>
  )
}

export default ECGAdminVitalSignsCard

const VitalSignsCardContainer = tw.div`bg-white p-5 rounded-lg border border-gray-200`

const DropdownBorderContainer = tw.div`border-b pb-2 mb-2`

const OuterDropdownContainer = tw.div`mb-1 flex justify-between align-middle`

const TitleContainer = tw.h3`text-xl`

const InnerDropdownContainer = tw.div`relative inline-flex self-center`

const DisplayContainer = tw.p`text-gray-500`

const DisplaySpan = tw.span`text-gray-800`

const EllipsisIcon = tw(Icon)`text-gray-900 w-5 h-5`
