import { formatISO } from 'date-fns'

/**
 * Converts a dateRangeState object to a standard object that can be serialized to url params
 * @param dateRangeState - date range to convert to url params
 * @returns object storing date that is serializable to url params
 */
const dateRangeStateToURL = (dateRangeState: DateRangeState) => ({
  dateRangePreset: dateRangeState.preset,
  // only include dateRangeValues if the preset is custom
  ...(dateRangeState.preset === 'custom'
    ? {
        dateRangeValues: [
          formatISO(dateRangeState.value[0]),
          formatISO(dateRangeState.value[1]),
        ] as [string, string],
      }
    : {}),
})

export default dateRangeStateToURL
