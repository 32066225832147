import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from 'atlas'
import { Icon, LoadingIcon, RightPopup, Switch, TextField } from 'elements'
import { useUpdatePartnerTypeCommissionMutation } from 'hooks/partners'
import { useProductsQuery } from 'hooks/seed-data'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { toUpdateObj } from 'utils'

type CreatePartnerTypeCommissionsFormProps = {
  currentPartnerType: PartnerType
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  commissionToMutate: Commission | undefined
}

const CreatePartnerTypeCommissionsForm = ({
  currentPartnerType,
  isFormOpen,
  setIsFormOpen,
  commissionToMutate,
}: CreatePartnerTypeCommissionsFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<{
    product: string
    recurringCommissionAmount: undefined | string
    oneTimeCommissionAmount: undefined | string
  }>()
  const { reset, watch } = formMethods

  const [isOneTimeCommission, setIsOneTimeCommission] = useState<boolean>(false)
  const [isRecurringCommission, setIsRecurringCommission] = useState<boolean>(
    false
  )

  const productList = useProductsQuery()
  const product = productList.data?.items?.find(
    (product) => product.id === commissionToMutate?.productId
  )

  const updatePartnerTypeCommission = useUpdatePartnerTypeCommissionMutation()

  // reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset({
        product: commissionToMutate?.productId,
        oneTimeCommissionAmount: commissionToMutate?.isOneTimeCommission
          ? String(commissionToMutate.oneTimeCommissionAmount)
          : undefined,
        recurringCommissionAmount: commissionToMutate?.isRecurringCommission
          ? String(commissionToMutate.recurringCommissionAmount)
          : undefined,
      })
      setIsOneTimeCommission(!!commissionToMutate?.isOneTimeCommission)
      setIsRecurringCommission(!!commissionToMutate?.isRecurringCommission)
    }
  }, [isFormOpen, productList.data, commissionToMutate])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={`${t('Edit Direct Commissions For')} ${product?.name || ''}`}
      description={currentPartnerType.name}
      width="600px"
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={productList.isLoading}
            disabled={
              // disabled if both commission types are disabled
              !(isOneTimeCommission || isRecurringCommission)
            }
            onClick={() => {
              commissionToMutate &&
                updatePartnerTypeCommission.mutate(
                  {
                    partnerTypeId: currentPartnerType.id,
                    commission: commissionToMutate,
                    updateObjects: [
                      toUpdateObj(
                        'isRecurringCommission',
                        isRecurringCommission
                      ),
                      toUpdateObj('isOneTimeCommission', isOneTimeCommission),
                      ...(watch('recurringCommissionAmount') &&
                      isRecurringCommission
                        ? [
                            toUpdateObj(
                              'recurringCommissionAmount',
                              Number(watch('recurringCommissionAmount'))
                            ),
                          ]
                        : []),
                      ...(watch('oneTimeCommissionAmount') &&
                      isOneTimeCommission
                        ? [
                            toUpdateObj(
                              'oneTimeCommissionAmount',
                              Number(watch('oneTimeCommissionAmount'))
                            ),
                          ]
                        : []),
                    ],
                  },
                  { onSuccess: () => setIsFormOpen(false) }
                )
            }}
          >
            {t('Save')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updatePartnerTypeCommission.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {productList.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            <ActivationCommissionSwitchContainer>
              <Switch
                onChange={(e) => setIsOneTimeCommission(!!e)}
                checked={isOneTimeCommission}
                label={t('One-Time Activation Commission')}
                className="mr-2"
              />
              <StyledTooltip
                content={
                  <TooltipText>{`${t(
                    'A one-time payout given after a subscriber is activated'
                  )}.`}</TooltipText>
                }
              >
                <InfoIcon type="info" />
              </StyledTooltip>
            </ActivationCommissionSwitchContainer>
            {isOneTimeCommission ? (
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue={watch('oneTimeCommissionAmount')}
                name="oneTimeCommissionAmount"
                label={t('Activation Commission')}
                className="mt-4"
                type="number"
                startAdornment="$"
                data-testid="one-time-commission"
              />
            ) : null}
            <RecurringCommissionSwitchContainer>
              <Switch
                onChange={(e) => setIsRecurringCommission(!!e)}
                checked={isRecurringCommission}
                label={t('Recurring Commission')}
                className="mr-2"
              />
              <StyledTooltip
                content={
                  <TooltipText>{`${t(
                    'A monthly recurring payout for as long as an onboarded subscriber is activated and paying'
                  )}.`}</TooltipText>
                }
              >
                <InfoIcon type="info" />
              </StyledTooltip>
            </RecurringCommissionSwitchContainer>
            {isRecurringCommission ? (
              <Controller
                as={TextField}
                // this is purely here to prevent console.warns
                defaultValue={watch('recurringCommissionAmount')}
                name="recurringCommissionAmount"
                label={t('Recurring Commission')}
                className="mt-4"
                type="number"
                startAdornment="$"
                data-testid="recurring-commission"
              />
            ) : null}
          </form>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default CreatePartnerTypeCommissionsForm

const TooltipText = tw.p`w-60`

const InfoIcon = tw(Icon)`text-gray-600 w-5 h-5 align-middle`

const RecurringCommissionSwitchContainer = tw.span`flex items-center mt-3`

const ActivationCommissionSwitchContainer = tw.span`flex items-center`

const StyledTooltip = tw(Tooltip)`flex items-center`
