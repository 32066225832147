import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const usePartnerTypesQuery = () => {
  const query = useQuery<GetPartnerTypesResponse, KyError>(
    'getPartnerTypes',
    getPartnerTypes
  )

  return query
}

export default usePartnerTypesQuery

const searchParams = [
  ['order_by', 'ASC'],
  ['take', '10000'],
  ['include', 'parent'],
  ['include', 'children'],
]

type getPartnerTypes = () => Promise<GetPartnerTypesResponse>
const getPartnerTypes: getPartnerTypes = async () => {
  const result = await orgsApi
    .get(`api/partner-types`, {
      searchParams,
    })
    .json<GetPartnerTypesResponse>()
  return result
}
