import React, { useState } from 'react'
import { RightPopup, Select, SelectItem, TextField, TimePicker } from 'elements'
import { Button } from 'atlas'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUpdateAlertDeliveryRuleMutation } from 'hooks/alert-delivery-rules'
import { toUpdateObj, formatDate } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { DayOfTheWeekToggleGroup } from 'components/reminder-presets'
import tw, { styled } from 'twin.macro'
import { parse } from 'date-fns'

type EditScheduleFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  alertDeliveryRule: AlertDeliveryRule
}

const EditScheduleForm = ({
  isFormOpen,
  setIsFormOpen,
  alertDeliveryRule,
}: EditScheduleFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const { handleSubmit, errors, control, watch, reset } = useForm<
    AlertDeliveryRuleFormLocal['schedule']
  >()

  const [toggled, setToggled] = useState<Weekdays[]>(
    alertDeliveryRule.schedule.weekday
      ? formatWeekdaysToLocal(alertDeliveryRule.schedule.weekday)
      : ['mon']
  )
  const [timePicker, setTimePicker] = useState<Date>(new Date())

  const updateAlertDeliveryRule = useUpdateAlertDeliveryRuleMutation()

  // reset values when form opens or alertDeliveryRule changes
  useEffect(() => {
    if (isFormOpen)
      reset({
        ...alertDeliveryRule.schedule,
        weekdays: alertDeliveryRule.schedule.weekday
          ? formatWeekdaysToLocal(alertDeliveryRule.schedule.weekday)
          : ['mon'],
        time:
          alertDeliveryRule.schedule.frequency === 'weekly' ||
          alertDeliveryRule.schedule.frequency === 'daily'
            ? parse(alertDeliveryRule.schedule.time || '', 'HHmm', new Date())
            : new Date(),
      })
  }, [alertDeliveryRule, isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={t('Edit Schedule')}
      description={alertDeliveryRule.name || ''}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={updateAlertDeliveryRule.isLoading}
            onClick={handleSubmit(async (formData) => {
              // API call
              updateAlertDeliveryRule.mutate(
                {
                  requests: [
                    toUpdateObj('schedule', {
                      ...formData,
                      weekdays: undefined,
                      weekday: formData.weekdays
                        ? formatWeekdays(formData.weekdays)
                        : undefined,
                      time:
                        formData.frequency === 'weekly' ||
                        formData.frequency === 'daily'
                          ? formatDate(formData.time, 'HHmm')
                          : undefined,
                      interval:
                        formData.frequency !== 'weekly' &&
                        formData.frequency !== 'real-time'
                          ? Number(formData.interval)
                          : undefined,
                    }),
                  ],
                  orgId: assumedOrganizationRole?.orgID || '',
                  deliveryRuleId: alertDeliveryRule.id,
                },
                {
                  // Close form drawer
                  onSuccess: () => setIsFormOpen(false),
                }
              )
            })}
          >
            {t('Update')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={updateAlertDeliveryRule.isLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <form>
        <DeliveryTimeSelect>
          <Controller
            control={control}
            as={
              <Select variant="outlined" className="w-64 mr-6">
                <SelectItem value="real-time">{t('Real-Time')}</SelectItem>
                <SelectItem value="minutely">{t('Minutely')}</SelectItem>
                <SelectItem value="hourly">{t('Hourly')}</SelectItem>
                <SelectItem value="daily">{t('Daily')}</SelectItem>
                <SelectItem value="weekly">{t('Weekly')}</SelectItem>
              </Select>
            }
            label={t('Alert Delivery Frequency')}
            name="frequency"
            defaultValue="daily"
          />
          <DeliveryTimeContainer
            hidden={
              watch('frequency') === 'real-time' ||
              watch('frequency') === 'minutely' ||
              watch('frequency') === 'hourly'
            }
          >
            <Controller
              control={control}
              as={TimePicker}
              value={timePicker}
              onChange={(e: Date | null) => e && setTimePicker(e)}
              label={t('Delivery Time')}
              name="time"
              rules={{ pattern: { value: /an/, message: 'text' } }}
              defaultValue={new Date()}
            />
          </DeliveryTimeContainer>
        </DeliveryTimeSelect>
        <DeliveryFrequency>
          {watch('frequency') === 'real-time' ? null : watch('frequency') !==
            'weekly' ? (
            <FrequencyTextField>
              <Controller
                control={control}
                as={TextField}
                label={t('Delivery Interval')}
                rules={{
                  validate: (interval) => {
                    const frequency = watch('frequency')

                    if (
                      frequency === 'daily' &&
                      !String(interval).match(/^(0*[1-6])$/)
                    )
                      return 'Must be a whole number between 1-6'
                    if (
                      frequency === 'hourly' &&
                      !String(interval).match(/^(0*([1-9]|1[0-9]|2[0-3]))$/)
                    )
                      return 'Must be a whole number between 1-23'
                    if (
                      frequency === 'minutely' &&
                      !String(interval).match(/^(0*([1-9]|[1-5][0-9]))$/)
                    )
                      return 'Must be a whole number between 1-59'
                  },
                }}
                error={errors.interval?.message}
                name="interval"
                defaultValue={1}
                type="number"
                className="w-64"
                helperText={`${t('Must be a whole number between')} ${(() => {
                  const frequency = watch('frequency')

                  if (frequency === 'daily') return '1-6'
                  if (frequency === 'hourly') return '1-23'
                  if (frequency === 'minutely') return '1-59'
                })()}`}
              />
            </FrequencyTextField>
          ) : (
            <Controller
              control={control}
              render={({ onChange, name }) => (
                <DayOfTheWeekToggleGroup
                  onChange={onChange}
                  name={name}
                  label={t('Delivery Days')}
                  setToggled={setToggled}
                  toggled={toggled}
                />
              )}
              name="weekdays"
            />
          )}
        </DeliveryFrequency>
      </form>
    </RightPopup>
  )
}

export default EditScheduleForm

const DeliveryTimeContainer = styled.div<{ hidden: boolean }>(({ hidden }) => [
  hidden && tw`hidden`,
])

const DeliveryTimeSelect = tw.div`flex gap-4 items-center`

const DeliveryFrequency = tw.div`mt-8`

const FrequencyTextField = tw.div`flex items-center`

// temporary function to match discrepancy with weekday enums across different API endpoints
const formatWeekdaysToLocal = (weekdays: Array<string>) => {
  const weekdayMap: Record<string, Weekdays> = {
    M: 'mon',
    T: 'tue',
    W: 'wed',
    H: 'thu',
    F: 'fri',
    S: 'sat',
    U: 'sun',
  }
  return weekdays.map((weekday) => weekdayMap[weekday])
}

// temporary function to match discrepancy with weekday enums across different API endpoints
const formatWeekdays = (weekdays: Array<Weekdays>) => {
  const weekdayMap: Record<Weekdays, string> = {
    mon: 'M',
    tue: 'T',
    wed: 'W',
    thu: 'H',
    fri: 'F',
    sat: 'S',
    sun: 'U',
  }
  return weekdays.map((weekday) => weekdayMap[weekday])
}
