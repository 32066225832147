import React from 'react'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next/'

type CrudToChipProps = {
  operation: 'CREATE' | 'INSERT' | 'UPDATE' | 'DELETE'
}

const CrudOperationToChip = ({ operation }: CrudToChipProps) => {
  const { t } = useTranslation()

  return (
    <Chip
      color={
        ({
          CREATE: 'green',
          INSERT: 'green',
          UPDATE: 'yellow',
          DELETE: 'red',
        } as const)[operation]
      }
    >
      {t(operation)}
    </Chip>
  )
}

export default CrudOperationToChip

export const isCrudOperation = (
  str: string
): str is 'CREATE' | 'INSERT' | 'UPDATE' | 'DELETE' =>
  ['CREATE', 'INSERT', 'UPDATE', 'DELETE'].includes(str)
