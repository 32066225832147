import { useCallback } from 'react'

const useStatusSort = () => {
  const memoizedFunction = useCallback((rowA, rowB) => {
    const rowATerm = recordToStatusString(rowA.original)
    const rowBTerm = recordToStatusString(rowB.original)

    return rowATerm < rowBTerm ? -1 : 1
  }, [])

  return memoizedFunction
}

export default useStatusSort

const recordToStatusString = (record: Archivable & Disableable) => {
  if (record.archiveInfo.archived) return 'Archived'

  return record.activeInfo.active ? 'Active' : 'Inactive'
}
