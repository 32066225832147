import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

const useOrganizationOnboardingQuery = ({
  organizationId,
}: {
  organizationId?: string
}) => {
  const query = useQuery(
    ['getOrganizationOnboarding', organizationId],
    () => getOrganizationOnboarding({ organizationId }),
    {
      enabled: !!organizationId,
      staleTime: 0,
    }
  )

  return query
}

type getOrganizationOnboardingArgs = { organizationId?: string }

const getOrganizationOnboarding = async ({
  organizationId,
}: getOrganizationOnboardingArgs) => {
  if (!organizationId) return

  const result = await typedVitalsApi.get(
    '/organizations/{organizationId}/on-boarding',
    { pathParams: { organizationId } }
  )

  return result
}

export default useOrganizationOnboardingQuery
