import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { DateRangePicker, Empty } from 'atlas'
import clsx from 'clsx'
import { LoadingIcon } from 'elements'
import { useAPIQuery, useDelay, useURLSyncState } from 'hooks'
import _ from 'lodash'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useTranslation } from 'react-i18next'
import { useDebounce, useMeasure, useUpdateEffect } from 'react-use'
import AutoSizer from 'react-virtualized-auto-sizer'
import tw, { styled } from 'twin.macro'
import Icon from '../Icon'
import { isPresent } from 'utils'
import { Column, Row, useSortBy, useTable } from 'react-table'
import queries, { APIQueryStr } from 'hooks/queries'
import i18next from 'i18next'
import ListPaginationControls from './ListPaginationControls'
import TableFilter from './TableFilter'
import SearchBox from './SearchBox'

type Paginatable = {
  items: unknown[] | null
  recordsPerPage: number
  totalRecords: number
  currentPageNumber: number
  totalPages: number
}

// only potential QueryStrs where the ReturnType is a Paginatable and searchParams exist
type PaginatedQueryStrs = {
  [K in APIQueryStr]: NonNullable<
    ReturnType<Pick<typeof queries, K>[K]>['data']
  > extends Partial<Paginatable>
    ? Extract<
        NonNullable<
          NonNullable<Parameters<typeof queries[K]>[0]>['searchParams']
        >,
        Record<string, unknown>
      > extends Record<string, unknown>
      ? K
      : never
    : never
}[APIQueryStr]

type PaginatedQueries = {
  [K in PaginatedQueryStrs]: typeof queries[K]
}

type QueryReturn<QueryStr extends PaginatedQueryStrs> = NonNullable<
  ReturnType<PaginatedQueries[QueryStr]>['data']
>

type QueryItem<QueryStr extends PaginatedQueryStrs> = NonNullable<
  QueryReturn<QueryStr>['items']
>[number]

type QueryParams<QueryStr extends PaginatedQueryStrs> = Omit<
  NonNullable<Parameters<PaginatedQueries[QueryStr]>[0]>,
  'searchParams'
> & {
  // don't allow sending searchParams as an array. It is too complicated to handle the types
  searchParams: Extract<
    NonNullable<Parameters<PaginatedQueries[QueryStr]>[0]>['searchParams'],
    Record<string, unknown>
  >
}

type QueryFilters<QueryStr extends PaginatedQueryStrs> = NonNullable<
  QueryParams<QueryStr>['searchParams']
>

/** sort key can only be a key in searchParams that accepts ASC | DESC */
type OrderByKey<QueryStr extends PaginatedQueryStrs> = {
  [K in keyof QueryFilters<QueryStr>]: NonNullable<
    QueryFilters<QueryStr>[K]
  > extends 'ASC' | 'DESC'
    ? K extends string
      ? K
      : never
    : never
}[keyof QueryFilters<QueryStr>]

/** search key can only be a key in searchParams that is 'search' or 'searchTerm' */
type SearchKey<QueryStr extends PaginatedQueryStrs> = {
  [K in keyof QueryFilters<QueryStr>]: K extends 'search' | 'searchTerm'
    ? K
    : never
}[keyof QueryFilters<QueryStr>]

/** offset key can only be a key in searchParams that is 'offset' or 'skip' */
type OffsetKey<QueryStr extends PaginatedQueryStrs> = {
  [K in keyof QueryFilters<QueryStr>]: K extends 'offset' | 'skip' ? K : never
}[keyof QueryFilters<QueryStr>]

/** date key can only be a key in searchParams that has a DateRangeState value type */
type DateKey<QueryStr extends PaginatedQueryStrs> = {
  [K in keyof QueryFilters<QueryStr>]: QueryFilters<QueryStr>[K] extends DateRangeState
    ? K
    : never
}[keyof QueryFilters<QueryStr>]

/** array filter key can only be a key in searchParams that has an array value type */
type ArrayFilterKey<QueryStr extends PaginatedQueryStrs> = {
  [K in keyof QueryFilters<QueryStr>]: NonNullable<
    QueryFilters<QueryStr>[K]
  > extends Array<unknown>
    ? K
    : never
}[keyof QueryFilters<QueryStr>]

/** nonarray filter key can only be a key in searchParams that doesn't have an array value type */
type NonArrayFilterKey<QueryStr extends PaginatedQueryStrs> = {
  [K in keyof QueryFilters<QueryStr>]: NonNullable<
    QueryFilters<QueryStr>[K]
  > extends Array<unknown>
    ? never
    : K
}[keyof QueryFilters<QueryStr>]

/* ----- PROP TYPES ----- */
type SortProps<
  QueryStr extends PaginatedQueryStrs
> = OrderByKey<QueryStr> extends never
  ? { sort: never }
  : {
      sort: {
        orderByKey: OrderByKey<QueryStr>
      }
    }
type SearchProps<
  QueryStr extends PaginatedQueryStrs
> = SearchKey<QueryStr> extends never
  ? { search: never }
  : {
      search: {
        key: SearchKey<QueryStr>
        placeholder?:
          | string
          | ((data: QueryReturn<QueryStr> | undefined) => string)
      }
    }

type CategoryFilterProps = {
  icon: IconType
  label: string
  categories: TableCategory[]
  isHidden?: boolean
}

type TableProps<QueryStr extends PaginatedQueryStrs> = {
  query: [QueryStr, QueryParams<QueryStr>]
  columns: {
    // column.id can only be keys that exist on an item.
    id: DeepKeys<QueryItem<QueryStr>>
    Header: string
    accessor?: Column<QueryItem<QueryStr>>['accessor']
    width?: `${number | `${number}.${number}`}${'fr' | 'px'}`
    isHidden?: boolean
    sortable?: boolean
  }[]
  columnDependencies?: Array<unknown>
  filters?: Array<
    | ({
        type: 'single-category'
        singleCategorykey: NonArrayFilterKey<QueryStr>
      } & CategoryFilterProps)
    | ({
        type: 'multi-category'
        multiCategorykey: ArrayFilterKey<QueryStr>
      } & CategoryFilterProps)
    | { type: 'date-range'; dateRangekey: DateKey<QueryStr> }
  >
  offsetKey: OffsetKey<QueryStr>
  empty?: { title?: string; description?: string }
  onRowClick?: (row: Row<QueryItem<QueryStr>>) => void
  baseDelay?: number
  controls?: React.ReactNode
  selectedControls?: React.ReactNode
} & SortProps<QueryStr> &
  SearchProps<QueryStr>

const Table = <QueryStr extends PaginatedQueryStrs>({
  query,
  columns,
  columnDependencies = [],
  filters: columnFilters,
  offsetKey,
  sort: { orderByKey },
  search: { key: searchKey, placeholder: searchPlaceholder },
  empty: { title: emptyTitle, description: emptyDescription } = {},
  onRowClick,
  baseDelay = 0.1,
  controls,
  selectedControls,
}: TableProps<QueryStr>) => {
  const { t } = useTranslation()
  const delay = useDelay({ initial: baseDelay })

  const [filters, setFilters] = useURLSyncState({
    defaultValue: query[1].searchParams as QueryFilters<QueryStr> &
      Record<OffsetKey<QueryStr>, number>,
    isListFilters: true,
  })

  const [localSearchTerm, setLocalSearchTerm] = useState<string>('')

  // just a utility fn to reset page when filters change
  const updateFilterState = (newState: typeof filters) => ({
    ...newState,
    [offsetKey]: 0,
  })

  const queryReturn: ReturnType<
    PaginatedQueries[QueryStr]
    // @ts-expect-error QueryStr could technically still be a literal union where the
    // parameter types never resolve to correlate to a single key of the queries obj
  > = useAPIQuery<QueryStr>(query[0], {
    ...query[1],
    searchParams: filters,
  })

  const data: QueryItem<QueryStr>[] = useMemo(
    () => queryReturn.data?.items || [],
    [queryReturn.data]
  )

  const tableColumns = useMemo(
    () =>
      columns
        .filter((column) => !column.isHidden)
        .map((column) => ({
          ...column,
          // when accessor is omitted fallback to the id property
          accessor: column.accessor || column.id,
          // remove custom props before passing to react-table
          isHidden: undefined,
          disableSortBy: !column.sortable,
        })),
    [i18next.language, ...columnDependencies]
  )

  const tableInstance = useTable<QueryItem<QueryStr>>(
    {
      // @ts-expect-error Column type is full of issues...
      // Migrate to React-Table v8 when released https://github.com/tannerlinsley/react-table/issues/1591
      columns: tableColumns,
      data,
      onRowClick,
      manualSortBy: true,
      disableSortRemove: true,
    },
    useSortBy
  )

  // generate the grid-template-columns css attribute based off the input
  const gridTemplateColumns = useMemo(
    () => columns.map((column) => column.width || '1fr').join(' '),
    [columns]
  )

  // function used to render a single row
  // This is memoized because performance is noticeably impacted if in JSX directly
  const RenderRow = useCallback(
    ({
      row,
      rowIndex,
    }: {
      row: Row<QueryItem<QueryStr>>
      rowIndex: number
    }) => {
      // don't render filtered out rows
      if (!isPresent(row)) return null

      tableInstance.prepareRow(row)

      return (
        <TableRow
          {...row.getRowProps({
            style: {
              gridTemplateColumns,
            },
          })}
          key={`data-row-${rowIndex}`}
          rowIndex={rowIndex}
          className={clsx(
            row.isSelected ? 'bg-blue-50' : undefined,
            'border-b border-gray-200',
            tableInstance.onRowClick && 'hover:bg-blue-50 cursor-pointer'
          )}
          onClick={() =>
            tableInstance.onRowClick ? tableInstance.onRowClick(row) : undefined
          }
        >
          {row.cells.map((cell, columnIndex) => (
            <Cell
              {...cell.getCellProps()}
              // prevent cells from taking up more space then they should
              style={{ minWidth: '0px' }}
              key={`data-cell-${rowIndex}-${columnIndex}`}
              data-testid={`${cell.column.id}-data-cell`}
              title={
                cell.value?.length && cell.value.length > 85
                  ? cell.value
                  : undefined
              }
              isString={typeof cell.value === 'string'}
            >
              {(() => {
                // if the cell is empty render a dash
                if (cell.value === '') return '-'

                if (typeof cell.value === 'string')
                  return _.truncate(cell.value, { length: 85 })

                return cell.render('Cell')
              })()}
            </Cell>
          ))}
        </TableRow>
      )
    },
    [
      tableInstance.rows,
      tableInstance.prepareRow,
      tableInstance.state.selectedRowIds,
    ]
  )

  const [searchBoxRef, { width: searchBoxWidth }] = useMeasure<HTMLDivElement>()

  // debounce controlled searchTerm value
  const [, cancel] = useDebounce(
    () => {
      setFilters(
        updateFilterState({ ...filters, [searchKey]: localSearchTerm || '' })
      )
    },
    500,
    [localSearchTerm]
  )

  // cancel debounce on initial render
  useEffect(() => cancel(), [])

  // skip debounce and immediately update if localSearchTerm is set back to empty
  useUpdateEffect(() => {
    if (!localSearchTerm) {
      cancel()

      setFilters(updateFilterState({ ...filters, [searchKey]: '' }))
    }
  }, [localSearchTerm])

  // if orderBy is toggled set the filters
  useUpdateEffect(() => {
    if (orderByKey)
      setFilters(
        updateFilterState({
          ...filters,
          [orderByKey]: tableInstance.state.sortBy[0]?.desc ? 'DESC' : 'ASC',
        })
      )
  }, [tableInstance.state.sortBy])

  const searchPlaceholderText = useMemo(
    () =>
      searchPlaceholder
        ? typeof searchPlaceholder === 'string'
          ? searchPlaceholder
          : searchPlaceholder(
              queryReturn.data as
                | NonNullable<ReturnType<PaginatedQueries[QueryStr]>['data']>
                | undefined
            )
        : `${t('Search')} ${
            queryReturn.data?.totalRecords
              ? queryReturn.data.totalRecords + ' '
              : ''
          }${t(query[0])}`,
    [searchPlaceholder, queryReturn.data]
  )

  return (
    <>
      {
        // if space for searchBox is less than 320px show it here
        searchBoxWidth < 320 ? (
          <FullWidthSearchBox
            value={localSearchTerm || ''}
            onChange={(e) => setLocalSearchTerm(e.target.value)}
            placeholder={searchPlaceholderText}
            disabled={
              // incorrectly thinks filters[searchKey] is always falsey
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              !filters[searchKey] &&
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (tableInstance.preGlobalFilteredRows?.length === 0 ||
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                !!tableInstance.selectedFlatRows?.length)
            }
          />
        ) : null
      }
      {/* conditionally render a SearchBox if the useGlobalFilter hook is provided */}
      <FilterContainer delay={delay()}>
        {controls || null}
        {
          // if space for searchBox is less than 320px show it above on its own line instead of here
          searchBoxWidth > 320 ? (
            <InlineSearchBox
              ref={searchBoxRef}
              value={localSearchTerm || ''}
              onChange={(e) => setLocalSearchTerm(e.target.value)}
              placeholder={searchPlaceholderText}
              disabled={
                // incorrectly thinks filters[searchKey] is always falsey
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                !filters[searchKey] &&
                // react-table types should set preGlobalFilteredRows as optional
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (tableInstance.preGlobalFilteredRows?.length === 0 ||
                  // react-table types should set selectedFlatRows as optional
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !!tableInstance.selectedFlatRows?.length)
              }
            />
          ) : (
            <SearchBoxFiller ref={searchBoxRef} />
          )
        }
        {
          // If rows have been selected, show selectedControls instead of filters
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          tableInstance.selectedFlatRows?.length
            ? selectedControls
            : columnFilters
            ? columnFilters.map((filter) => {
                // This doesn't use the object/key pattern because we need type narrowing in each branch
                if (filter.type === 'date-range')
                  return (
                    <DateRangePicker
                      key={filter.dateRangekey + ''}
                      // @ts-expect-error QueryStr could technically still be a literal union where the
                      // parameter types never resolve to correlate to a single key of the queries obj
                      value={filters[filter.dateRangekey as unknown]}
                      onChange={(dateRangePreset, dateRange) =>
                        setFilters(
                          updateFilterState({
                            ...filters,
                            [filter.dateRangekey]: {
                              preset: dateRangePreset,
                              value: dateRange,
                            },
                          })
                        )
                      }
                    />
                  )

                if (filter.isHidden) return null

                if (filter.type === 'single-category')
                  return (
                    <TableFilter
                      key={filter.label}
                      icon={filter.icon}
                      label={filter.label}
                      categories={filter.categories}
                      selectedCategories={filter.categories.filter(
                        (category) =>
                          // @ts-expect-error QueryStr could technically still be a literal union where the
                          // parameter types never resolve to correlate to a single key of the queries obj
                          filters[filter.singleCategorykey as unknown] ===
                          category.value
                      )}
                      setSelectedCategories={(selectedCategories) =>
                        setFilters(
                          updateFilterState({
                            ...filters,
                            [filter.singleCategorykey]: _.last(
                              selectedCategories
                            )?.value,
                          })
                        )
                      }
                    />
                  )

                // multi-category case
                return (
                  <TableFilter
                    key={filter.label}
                    icon={filter.icon}
                    label={filter.label}
                    categories={filter.categories}
                    selectedCategories={filter.categories.filter((category) =>
                      // @ts-expect-error QueryStr could technically still be a literal union where the
                      // parameter types never resolve to correlate to a single key of the queries obj
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      filters[filter.multiCategorykey as unknown]?.includes(
                        category.value
                      )
                    )}
                    setSelectedCategories={(selectedCategories) =>
                      setFilters(
                        updateFilterState({
                          ...filters,
                          [filter.multiCategorykey]: selectedCategories.map(
                            (category) => category.value
                          ),
                        })
                      )
                    }
                  />
                )
              })
            : null
        }
      </FilterContainer>

      <TableContainer delay={delay()}>
        {/* AutoSizer for filling the parent's width and height */}
        <AutoSizer>
          {({ height, width }) => {
            // 58px is the height of the row of column headers
            const tableBodyHeight = height - 58

            return (
              <div {...tableInstance.getTableProps()}>
                {tableInstance.headerGroups.map((headerGroup, rowIndex) => (
                  <HeaderGroup
                    key={rowIndex}
                    width={width}
                    gridTemplateColumns={gridTemplateColumns}
                  >
                    {headerGroup.headers.map((column, columnIndex) => (
                      <Header
                        isSortable={column.canSort}
                        {...column.getHeaderProps(
                          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                          column.getSortByToggleProps?.()
                        )}
                        key={`${rowIndex}-${columnIndex}`}
                      >
                        <HeaderText>{column.render('Header')}</HeaderText>
                        &nbsp;
                        <SortIndicator
                          isSorted={!!orderByKey && column.canSort}
                          {...(!!orderByKey &&
                          // @ts-expect-error TODO: SearchParams[orderByKey] cannot be strictly typed unless
                          // there was some way to guarantee QueryStr as a single literal not a union literal
                          filters[orderByKey as unknown] === 'DESC'
                            ? {
                                type: 'chevron-down',
                                'data-testid': 'descending-icon',
                              }
                            : {
                                type: 'chevron-up',
                                'data-testid': 'ascending-icon',
                              })}
                        />
                      </Header>
                    ))}
                  </HeaderGroup>
                ))}

                {queryReturn.isLoading ? (
                  <LoadingIcon
                    width={`${width}px`}
                    height={`${tableBodyHeight}px`}
                  />
                ) : (
                  // table rows
                  <RowsContainer height={tableBodyHeight} width={width}>
                    {/* display Empty if no data provided */}
                    {tableInstance.rows.length === 0 ? (
                      <Empty
                        title={emptyTitle || t('No Data Found')}
                        description={
                          emptyDescription || t('Try changing your search term')
                        }
                      />
                    ) : (
                      <RowsOverlayScrollbar maxHeight={tableBodyHeight}>
                        {tableInstance.rows.map((row, rowIndex) => {
                          // don't render filtered out rows
                          if (!isPresent(row)) return null

                          tableInstance.prepareRow(row)

                          return RenderRow({ row, rowIndex })
                        })}
                      </RowsOverlayScrollbar>
                    )}
                  </RowsContainer>
                )}
              </div>
            )
          }}
        </AutoSizer>
      </TableContainer>
      <FadeInSlideDown delay={delay()}>
        <ListPaginationControls
          paginationInfo={queryReturn.data}
          onPageChange={(newPage) =>
            setFilters({ ...filters, [offsetKey]: newPage - 1 })
          }
        />
      </FadeInSlideDown>
    </>
  )
}

export default Table

const FilterContainer = tw(FadeInSlideDown)`flex mb-2 gap-2`

const FullWidthSearchBox = tw(SearchBox)`mb-2`

const InlineSearchBox = tw(SearchBox)`flex-grow`

const SearchBoxFiller = tw.div`flex-grow`

const TableContainer = tw(
  FadeInSlideDown
)`flex-grow border border-gray-200 rounded bg-white overflow-hidden`

const HeaderGroup = styled.div<{ width: number; gridTemplateColumns: string }>(
  ({ width, gridTemplateColumns }) => [
    tw`grid gap-4 px-8 h-14 border-b border-gray-200`,
    `
      width: ${width}px;
      grid-template-columns: ${gridTemplateColumns};
    `,
  ]
)

const Header = styled.div<{ isSortable: boolean }>(({ isSortable }) => [
  tw`flex items-center text-gray-600 min-w-0`,
  isSortable && tw`hover:text-gray-900 cursor-pointer`,
])

const HeaderText = styled.p`
  ${() => tw`font-semibold text-sm transition-all select-none align-middle`}
  color: inherit;
`

const RowsContainer = styled.div<{ height: number; width: number }>(
  ({ height, width }) => `
  height: ${height}px;
  width: ${width}px;`
)

const RowsOverlayScrollbar = styled(OverlayScrollbarsComponent)<{
  maxHeight: number
}>(({ maxHeight }) => `max-height: ${maxHeight}px;`)

const TableRow = styled.div<{ rowIndex: number }>(({ rowIndex }) => [
  tw`grid gap-4 px-8 h-14`,
  rowIndex % 2 ? tw`bg-gray-50` : tw`bg-white`,

  // fix for right hidden right border with custom scrollbar
  `
    width: calc(100% - 2px) !important;
  `,
])

const Cell = styled.div(({ isString }: { isString: boolean }) => [
  tw`flex items-center text-gray-900`,
  isString && tw`overflow-hidden`,
])

const SortIndicator = styled(Icon)<{ isSorted: boolean }>(({ isSorted }) => [
  tw`align-middle`,
  !isSorted && tw`opacity-0`,
])
