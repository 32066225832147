import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  OrganizationSubscriberOverview,
  SubscribersList,
} from 'components/subscribers'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const Subscribers = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Layout
      title={
        assumedOrganizationRole ? t('Subscribers') : t('Subscriber Directory')
      }
      description={
        assumedOrganizationRole
          ? t(`Get a quick overview of subscribers in the organization.`)
          : t('View and manage subscribers in list format.')
      }
      controls={[
        {
          label: t('Create Subscriber'),
          onClick: () => navigate('create-subscriber'),
        },
      ]}
    >
      <SubscribersContainer>
        {assumedOrganizationRole ? (
          <OrganizationSubscriberOverview />
        ) : (
          <SubscribersList />
        )}
      </SubscribersContainer>
    </Layout>
  )
}

export default Subscribers

const SubscribersContainer = tw.div`flex flex-col flex-grow`
