import { rest } from 'msw'
import faker from 'faker'
import db from '../../data/db'
import { generatePaginatable } from '../utils'
import { getLineItemFields } from 'utils/contracts'
import { isPresent } from 'utils'

const orderHandlers = [
  // get contracts
  rest.get<undefined, OrdersAPIResponse['GET']['/contracts']>(
    '/contracts',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Contract[]>('contracts').filter((contract) =>
            Array.from(req.url.searchParams.entries())
              .filter(([key]) => key.includes('organizationIds'))
              .map(([, value]) => value)
              .includes(contract.organizationId || '')
          ),
          ...generatePaginatable(),
        })
      )
  ),

  // get a contract
  rest.get<
    undefined,
    OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}']
  >('/organizations/:organizationId/contracts/:contractId', (req, res, ctx) =>
    res(
      ctx.json(
        db
          .get<Contract[]>('contracts')
          .find((contract) => contract.id === req.params.contractId) || {}
      )
    )
  ),

  // get a contract's line items
  rest.get<
    undefined,
    OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}/line-items']
  >(
    '/organizations/:organizationId/contracts/:contractId/line-items',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db
            .get<Contract[]>('contracts')
            .find((contract) => contract.id === req.params.contractId)
            ?.lineItems,
        })
      )
  ),

  // get products
  rest.get<undefined, OrdersAPIResponse['GET']['/products']>(
    '/products',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<Product[]>('products'),
          ...generatePaginatable(),
        })
      )
  ),

  // get order statuses
  rest.get<undefined, OrdersAPIResponse['GET']['/orders/status-listing']>(
    '/orders/status-listing',
    (req, res, ctx) => res(ctx.json(db.get<OrderStatus[]>('orderStatuses')))
  ),

  // get line item statuses
  rest.get<undefined, OrdersAPIResponse['GET']['/line-items/status-listing']>(
    '/line-items/status-listing',
    (req, res, ctx) =>
      res(ctx.json(db.get<OrderLineItemStatus[]>('orderLineItemStatuses')))
  ),

  // get possible provisioning attributes
  rest.get<undefined, OrdersAPIResponse['GET']['/attributes']>(
    '/attributes',
    (req, res, ctx) =>
      res(ctx.json(db.get<ProvisioningAttribute[]>('provisioningAttributes')))
  ),

  // get an order
  rest.get<undefined, OrdersAPIResponse['GET']['/orders/{orderId}']>(
    '/orders/:orderId',
    (req, res, ctx) =>
      res(
        ctx.json(
          db
            .get<Order[]>('orders')
            .find((order) => order.id === req.params.orderId) || {}
        )
      )
  ),

  // get orders
  rest.get<undefined, OrdersAPIResponse['GET']['/orders']>(
    '/orders',
    (req, res, ctx) => res(ctx.json({ items: db.get<Order[]>('orders') }))
  ),

  // get an order's line items
  rest.get<undefined, OrdersAPIResponse['GET']['/orders/{orderId}/line-items']>(
    '/orders/:orderId/line-items',
    (req, res, ctx) =>
      res(
        ctx.json(
          db
            .get<Order[]>('orders')
            .find((order) => order.id === req.params.orderId)?.orderLineItems ||
            []
        )
      )
  ),

  // get an order's activity history
  rest.get<undefined, OrdersAPIResponse['GET']['/orders/{orderId}/activity']>(
    '/orders/:orderId/activity',
    (req, res, ctx) =>
      res(ctx.json(db.get<OrderActivity[]>('orderActivityHistory')))
  ),

  // post order
  rest.post<
    OrdersAPIRequest['POST']['/orders']['body'],
    OrdersAPIResponse['POST']['/orders']
  >('/orders', (req, res, ctx) => {
    const newOrder = {
      id: faker.random.uuid(),
      ...req.body,
      orderLineItems: [],
    }

    db.set<Order[]>('orders', [...db.get<Order[]>('orders'), newOrder])

    return res(ctx.json(newOrder))
  }),

  // post order line item
  rest.post<
    OrdersAPIRequest['POST']['/orders/{orderId}/line-items']['body'],
    OrdersAPIResponse['POST']['/orders/{orderId}/line-items']
  >('/orders/:orderId/line-items', (req, res, ctx) => {
    let updatedOrder = {}
    const contractsDB = db.get<Array<Contract>>('contracts')
    const order = db
      .get<Array<Order>>('orders')
      .find((order) => order.id === req.params.orderId)

    const contractLineItems = contractsDB.find(
      (contract) => contract.id === order?.contractId
    )?.lineItems

    db.set<Order[]>(
      'orders',
      db.get<Order[]>('orders').map((order) => {
        if (order.id === req.params.orderId) {
          const contractLineItem = contractLineItems?.find((lineItem) => {
            // find the lineItem where primaryProduct exists
            if (lineItem.primaryProductLineItem)
              return (
                lineItem.primaryProductLineItem.productId === req.body.productId
              )

            // find the lineItem where deviceOrService exists
            if (lineItem.deviceOrServiceLineItem)
              return (
                lineItem.deviceOrServiceLineItem.productId ===
                req.body.productId
              )

            // find the lineItem where productType exists
            if (lineItem.productTypeLineItem)
              return lineItem.productTypeLineItem.availableProducts?.some(
                (item) => item === req.body.productId
              )
          })

          const productPricing =
            contractLineItem && getLineItemFields(contractLineItem)?.pricing

          updatedOrder = {
            ...order,
            totals: {
              oneTimeSubTotal: 95,
              monthlySubTotal: 35,
              yearlySubTotal: 0,
              grandTotal: 0,
            },
            orderLineItems: [
              ...(order.orderLineItems || []),
              {
                productId: req.body.productId,
                priceSelection: {
                  warrantySelection: req.body.warrantySelection,
                  productPrice: productPricing?.productFee?.price,
                  activationPrice: productPricing?.activationFee?.price,
                },
              },
            ],
          }
          return updatedOrder
        }
        return order
      })
    )

    if (!isPresent(updatedOrder)) throw new Error('Order to update not found!')

    return res(ctx.json(updatedOrder))
  }),

  // post order shipping information
  rest.post<
    OrdersAPIRequest['POST']['/orders/{orderId}/shipping-information']['body'],
    OrdersAPIResponse['POST']['/orders/{orderId}/shipping-information']
  >('/orders/:orderId/shipping-information', (req, res, ctx) => {
    let updatedOrder: Order | undefined

    db.set<Order[]>(
      'orders',
      db.get<Order[]>('orders').map((order) => {
        if (order.id === req.params.orderId) {
          updatedOrder = {
            ...order,
            shippingInformation: req.body,
          }
          return updatedOrder
        }
        return order
      })
    )

    if (!updatedOrder) throw new Error('Order to update not found!')

    return res(ctx.json(updatedOrder))
  }),

  // post order service details
  rest.post<
    OrdersAPIRequest['POST']['/orders/{orderId}/service-details']['body'],
    OrdersAPIResponse['POST']['/orders/{orderId}/service-details']
  >('/orders/:orderId/service-details', (req, res, ctx) => {
    let updatedOrder: Order | undefined

    db.set<Order[]>(
      'orders',
      db.get<Order[]>('orders').map((order) => {
        if (order.id === req.params.orderId) {
          updatedOrder = {
            ...order,
            serviceDetails: req.body,
          }
          return updatedOrder
        }
        return order
      })
    )

    if (!updatedOrder) throw new Error('Order to update not found!')

    return res(ctx.json(updatedOrder))
  }),

  // post order confirmation
  rest.post<
    OrdersAPIRequest['POST']['/orders/{orderId}/confirm']['body'],
    OrdersAPIResponse['POST']['/orders/{orderId}/confirm']
  >('/orders/:orderId/confirm', (req, res, ctx) => {
    let updatedOrder: Order | undefined

    const {
      authorizeECGToContactWithWelcomePackageDelivery,
      makeChangesUntilShipmentIsReady,
      priceBreakdownForMonthlyAndOneTimeFees,
    } = req.body

    if (
      authorizeECGToContactWithWelcomePackageDelivery &&
      makeChangesUntilShipmentIsReady &&
      priceBreakdownForMonthlyAndOneTimeFees
    ) {
      // update confirmation id
      db.set<Order[]>(
        'orders',
        db.get<Order[]>('orders').map((order) => {
          if (order.id === req.params.orderId) {
            updatedOrder = {
              ...order,
              confirmationCode: 'ECG-123121',
            }
            return updatedOrder
          }
          return order
        })
      )

      if (!updatedOrder) throw new Error('Order to update not found!')

      return res(ctx.json({ value: true }))
    }
  }),

  // post checking out provisioning of an order line item
  rest.post<
    undefined,
    OrdersAPIResponse['POST']['/orders/{orderId}/line-items/{lineItemId}/provisioning-checkout']
  >(
    '/orders/:orderId/line-items/:lineItemId/provisioning-checkout',
    (req, res, ctx) => {
      let updatedOrder: Order | undefined

      db.set<Order[]>(
        'orders',
        db.get<Order[]>('orders').map((order) => {
          if (order.id === req.params.orderId) {
            updatedOrder = {
              ...order,
              orderStatus: db.get<OrderLineItemStatus[]>('orderStatuses')[2],
              orderLineItems: [
                { ...order.orderLineItems?.[0], orderLineItemStatusId: 3 },
              ],
            }
            return updatedOrder
          }
          return order
        })
      )

      if (!updatedOrder) throw new Error('Order to update not found!')

      return res(ctx.json({ value: true }))
    }
  ),

  // post complete provisioning for an order line item
  rest.post<
    OrdersAPIRequest['POST']['/orders/{orderId}/line-items/{lineItemId}/provisioning-complete']['body'],
    OrdersAPIResponse['POST']['/orders/{orderId}/line-items/{lineItemId}/provisioning-complete']
  >(
    '/orders/:orderId/line-items/:lineItemId/provisioning-complete',
    (req, res, ctx) => {
      let updatedOrder: Order | undefined

      db.set<Order[]>(
        'orders',
        db.get<Order[]>('orders').map((order) => {
          if (order.id === req.params.orderId) {
            updatedOrder = {
              ...order,
              orderLineItems: [
                {
                  ...order.orderLineItems?.[0],
                  orderLineItemStatusId: 4,
                  provisioningData: req.body.provisioningData,
                },
              ],
            }
            return updatedOrder
          }
          return order
        })
      )

      if (!updatedOrder) throw new Error('Order to update not found!')

      return res(ctx.json({ value: true }))
    }
  ),

  // post complete provisioning for an order
  rest.post<
    undefined,
    OrdersAPIResponse['POST']['/orders/{orderId}/provisioning-complete']
  >('/orders/:orderId/provisioning-complete', (req, res, ctx) => {
    let updatedOrder: Order | undefined

    db.set<Order[]>(
      'orders',
      db.get<Order[]>('orders').map((order) => {
        if (order.id === req.params.orderId) {
          updatedOrder = {
            ...order,
            orderStatus: db.get<OrderLineItemStatus[]>('orderStatuses')[3],
          }
          return updatedOrder
        }
        return order
      })
    )

    if (!updatedOrder) throw new Error('Order to update not found!')

    return res(ctx.json({ value: true }))
  }),
]

export default orderHandlers
