import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { SubscribersList } from 'components/subscribers'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const SubscriberDirectory = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Layout
      title={t('Subscriber Directory')}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '..',
        },
        {
          name: t('Subscriber Directory'),
        },
      ]}
      description={t(`View and manage subscribers in list format.`)}
      controls={
        assumedOrganizationRole
          ? [
              {
                label: t('Create Subscriber'),
                onClick: () => navigate('../create-subscriber'),
              },
            ]
          : undefined
      }
    >
      <SubscriberListContainer>
        <SubscribersList />
      </SubscriberListContainer>
    </Layout>
  )
}

export default SubscriberDirectory

const SubscriberListContainer = tw.div`flex flex-col flex-grow`
