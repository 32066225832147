import React from 'react'
import {
  LoadingIcon,
  RightPopup,
  TextField,
  Icon,
  Select,
  SelectItem,
} from 'elements'
import _ from 'lodash'
import { Button } from 'atlas'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  useAvailableTranslationsQuery,
  useCreateReminderMessageTranslationMutation,
  useReminderMessagesQuery,
  useReminderMessageTypesQuery,
} from 'hooks/reminders'
import { useTranslation } from 'react-i18next'
import { useLocalesQuery } from 'hooks/locales'
import tw from 'twin.macro'

type ECGAdminCreateTranslationFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ECGAdminCreateTranslationForm = ({
  isFormOpen,
  setIsFormOpen,
}: ECGAdminCreateTranslationFormProps) => {
  const { t } = useTranslation()
  const formMethods = useForm<EditTranslationForm>()

  const locales = useLocalesQuery()
  const reminderMessages = useReminderMessagesQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )
  const availableTranslations = useAvailableTranslationsQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )
  const reminderMessageTypes = useReminderMessageTypesQuery()

  const createReminderMessageTranslation = useCreateReminderMessageTranslationMutation()

  const { handleSubmit, errors, register } = formMethods

  return (
    <RightPopup
      title={t('Create Translation')}
      open={isFormOpen}
      setOpen={setIsFormOpen}
      width="1200px"
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={handleSubmit(async (formData) => {
              const pickMessages = _.pickBy(formData, (value, key) => {
                return key !== 'localeKey'
              })

              createReminderMessageTranslation.mutate({
                orgId: '7151e6ce-07e1-4a81-823e-6db3f5f14dd5',
                localeKey: formData['localeKey'],
                messages: _.map(pickMessages, (value, key) => {
                  return {
                    messageTypeId: key,
                    message: value,
                  }
                }),
              })
              setIsFormOpen(false)
            })}
          >
            {t('Create')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {reminderMessageTypes.isLoading ||
      availableTranslations.isLoading ||
      reminderMessages.isLoading ||
      locales.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <LocaleSelectContainer>
            <Controller
              as={
                <Select
                  className="col-span-3"
                  name="localeKey"
                  inputRef={register({ required: `${t('Locale Required')}` })}
                  label={t('Language Locale')}
                >
                  {locales.data?.items?.map((translations) => {
                    if (
                      !availableTranslations.data?.items?.some((selectable) => {
                        return selectable.locale.key === translations.name
                      })
                    )
                      return (
                        <SelectItem value={translations.name}>
                          {translations.displayName}
                        </SelectItem>
                      )
                  })}
                </Select>
              }
              name="localeKey"
              error={errors.localeKey?.message}
              defaultValue=""
              rules={{ required: 'Locale is required' }}
            />
          </LocaleSelectContainer>
          <ReminderMessagesContainer>
            {/* Order by sort order then display */}
            {_.orderBy(
              reminderMessages.data?.items,
              (reminderMessage) => reminderMessage.messageType.displayOrder
            ).map((item, index) => (
              <TextField
                key={index}
                disabled={true}
                label={item.messageType.name}
                defaultValue={`${item.message}`}
                fullWidth
                className={`col-span-5 row-span-1 self-start`}
                helperText={item.messageType.description}
                multiline
                rows={2}
              />
            ))}

            <Pointer type="chevron-right" />
            <Pointer type="chevron-right" />
            <Pointer type="chevron-right" />
            <Pointer type="chevron-right" />
            <Pointer type="chevron-right" />

            {/* Order by sort order then display */}
            {_.orderBy(
              reminderMessageTypes.data?.items,
              (reminderMessage) => reminderMessage.displayOrder
            ).map((item, index) => (
              <TextField
                key={`${index}`}
                error={errors[item.name || index]?.message}
                name={item.id}
                inputRef={register({
                  required: `${item.name} ${t('message is required')}`,
                })}
                label={item.name}
                fullWidth
                className="col-span-5 row-span-1 self-start"
                required
                multiline
                rows={2}
              />
            ))}
          </ReminderMessagesContainer>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default ECGAdminCreateTranslationForm

const LocaleSelectContainer = tw.div`grid grid-cols-11 mb-8`

const ReminderMessagesContainer = tw.div`grid grid-cols-11 grid-flow-col grid-rows-5 justify-items-center items-center mb-4`

const Pointer = tw(Icon)`w-6 h-6 transform -translate-y-8`
