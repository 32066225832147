import React, { useMemo, useCallback, useState } from 'react'
import {
  List,
  EntityLabel,
  CategoryFilter,
  CustomSelect,
  LoadingIcon,
} from 'elements'
import { Button, Chip, DateRangePicker } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  Row,
  useRowSelect,
  HeaderProps,
  CellProps,
  TableToggleAllRowsSelectedProps,
} from 'react-table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useEntityLabelSort } from 'hooks/react-table'
import { useECGVitalSignsQuery } from 'hooks/vitals'
import { formatDate, sortAlertCategories, isVitalSignLabel } from 'utils'
import { parseISO } from 'date-fns'
import { AlertDetails } from '.'
import {
  useAddressAlertsMutation,
  useAlertsQuery,
  useAlertCategoryQuery,
} from 'hooks/alerts'
import { VitalSignChip } from 'components/vitals'
import tw, { styled } from 'twin.macro'

type TableAlert = Alert & {
  subscriber: SubscriberDisplay
}

type AlertsListProps = {
  subscribers: SubscriberDisplay[]
  alertFilters: AlertFilters
  setAlertFilters: React.Dispatch<React.SetStateAction<AlertFilters>>
}

const OrganizationAlertsActivityList = ({
  subscribers,
  alertFilters,
  setAlertFilters,
}: AlertsListProps) => {
  const { t } = useTranslation()

  const { data: vitalSigns } = useECGVitalSignsQuery()

  const alertsQuery = useAlertsQuery(alertFilters)

  const { mutate: addressAlerts } = useAddressAlertsMutation()

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)

  const [selectedAlert, setSelectedAlert] = useState<TableAlert | undefined>()

  const subscriberLabelSort = useEntityLabelSort({
    accessor: ['subscriber.person.firstName', 'subscriber.person.lastName'],
  })

  const alertCategories = useAlertCategoryQuery()

  const sortedCategories = sortAlertCategories(
    alertCategories.data?.items || []
  )
  // Sort Function for the CreatedAt column
  const createdAtSortBy = useCallback(
    (rowA: Row<TableAlert>, rowB: Row<TableAlert>) =>
      rowA.original.auditInfo.createdAt > rowB.original.auditInfo.createdAt
        ? 1
        : -1,
    []
  )

  const tableData: TableAlert[] = useMemo(() => {
    const tableAlerts: TableAlert[] =
      alertsQuery.data?.items
        ?.map((alert) => ({
          ...alert,
          // add subscriber obj to alert to keep from finding the subscriber inline
          subscriber: subscribers.find(
            (subscriber) => subscriber.id === alert.subscriberId
          ),
        }))
        // filter alerts if no subscriber is found matching the id
        .filter((alert): alert is TableAlert => !!alert.subscriber) || []

    return tableAlerts
  }, [vitalSigns, i18next.language, alertsQuery.data, subscribers])

  const columns: Column<TableAlert>[] = useMemo(
    //@ts-expect-error type for Header is incorrectly inferred when spreading conditional columns
    () => [
      ...(!alertFilters.addressed
        ? [
            {
              id: 'SELECT',
              Header: ({
                getToggleAllRowsSelectedProps,
              }: HeaderProps<TableAlert>) => (
                <CheckboxContainer
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </CheckboxContainer>
              ),
              Cell: ({ row }: CellProps<TableAlert>) => (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              width: '0.1fr',
            },
          ]
        : []),
      {
        id: 'SUBSCRIBER',
        Header: t('SUBSCRIBER'),
        accessor: (row) => (
          <EntityLabel
            name={`${row.subscriber.person.firstName} ${row.subscriber.person.lastName}`}
            id={row.subscriber.id}
            to={`/subscribers/${row.subscriber.id}`}
          />
        ),
        Filter: (
          <CategoryFilter
            key="SUBSCRIBER"
            icon="subscribers"
            label={t('Subscribers')}
            categories={subscribers.map((subscriber) => ({
              label: `${subscriber.person.firstName} ${subscriber.person.lastName}`,
              value: subscriber.id,
            }))}
            selectedCategories={subscribers
              .filter((subscriber) =>
                alertFilters.subscriberIds?.includes(subscriber.id)
              )
              .map((subscriber) => ({
                label: `${subscriber.person.firstName} ${subscriber.person.lastName}`,
                value: subscriber.id,
              }))}
            setSelectedCategories={(selectedSubscribers) =>
              setAlertFilters({
                ...alertFilters,
                subscriberIds: selectedSubscribers.map(
                  (selectedSubscriber) => selectedSubscriber.value
                ),
                skip: 0,
              })
            }
          />
        ),
        sortType: subscriberLabelSort,
        disableSortBy: true,
      },
      {
        id: 'TYPE',
        Header: t('TYPE'),
        accessor: (row) => row.alertCategory.name,
        Filter: (
          <CategoryFilter
            key="TYPE"
            icon="vitals"
            label={t('Type')}
            unordered
            categories={sortedCategories}
            selectedCategories={sortedCategories.filter((category) =>
              alertFilters.alertCategories?.includes(category.value)
            )}
            setSelectedCategories={(selectedTypes) =>
              setAlertFilters({
                ...alertFilters,
                alertCategories: selectedTypes.map((type) => type.value),
                skip: 0,
              })
            }
            renderOption={({ label, parent }) =>
              isVitalSignLabel(label) ? (
                <VitalSignChip label={label} className="-my-1" />
              ) : (
                <TypeLabel parent={parent}>
                  {parent ? label : label.toUpperCase()}
                </TypeLabel>
              )
            }
          />
        ),
        disableSortBy: true,
      },
      {
        id: 'SEVERITY',
        Header: t('SEVERITY'),
        accessor: (row) => <SeverityChip row={row} />,
        Filter: (
          <CategoryFilter<string>
            key="SEVERITY"
            icon="alert"
            label={t('Severity')}
            categories={(['1', '2', '3'] as const).map((severity) => ({
              value: severity,
              label: severity,
            }))}
            selectedCategories={
              alertFilters.severities?.map((severity) => ({
                label: '' + severity,
                value: '' + severity,
              })) || []
            }
            setSelectedCategories={(selectedSeverities) =>
              setAlertFilters({
                ...alertFilters,
                severities: selectedSeverities.map(
                  (selectedSeverity) => +selectedSeverity.value
                ),
                skip: 0,
              })
            }
          />
        ),
        disableSortBy: true,
      },
      {
        id: 'CREATED AT',
        Header: t('CREATED AT'),
        accessor: (row: Alert, index: number) =>
          row.auditInfo.createdAt ? (
            <CreatedAtContainer key={index}>
              {formatDate(parseISO(row.auditInfo.createdAt), 'Pp')}
            </CreatedAtContainer>
          ) : (
            '-'
          ),
        Filter: (
          <DateRangePicker
            value={alertFilters.dateRangeState}
            onChange={(dateRangePreset, dateRange) =>
              setAlertFilters({
                ...alertFilters,
                dateRangeState: { preset: dateRangePreset, value: dateRange },
                skip: 0,
              })
            }
          />
        ),
        sortType: createdAtSortBy,
      },
      ...(alertFilters.addressed
        ? ([
            {
              id: 'ADDRESSED AT',
              Header: t('ADDRESSED AT'),
              accessor: (row: TableAlert, index: number) =>
                row.addressedAt ? (
                  <AddressedAtContainer key={index}>
                    {formatDate(parseISO(row.addressedAt), 'Pp')}
                  </AddressedAtContainer>
                ) : (
                  '-'
                ),
              disableSortBy: true,
            },
            {
              id: 'ADDRESSED BY',
              Header: t('ADDRESSED BY'),
              accessor: (row: TableAlert) => row.addressedBy,
              disableSortBy: true,
            },
          ] as Column<TableAlert>[])
        : []),
    ],
    [i18next.language, alertFilters, vitalSigns]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetSortBy: false,
      onRowClick: (row) => {
        setSelectedAlert(row.original)
        setIsFormOpen(true)
      },

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: alertFilters.addressed ? 'ADDRESSED AT' : 'CREATED AT',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useRowSelect
  )

  return vitalSigns && !alertCategories.isLoading ? (
    <>
      <List
        tableInstance={tableInstance}
        emptyDescription={t(
          'Try changing your search term or contact your administrator'
        )}
        isLoading={alertsQuery.isFetching || alertCategories.isFetching}
        controls={
          <CustomSelect<boolean>
            options={[
              {
                label: 'Addressed Alerts',
                value: true,
              },
              {
                label: 'Unaddressed Alerts',
                value: false,
              },
            ]}
            value={alertFilters.addressed}
            onChange={(e) =>
              setAlertFilters({
                ...alertFilters,
                addressed: e.value,
                skip: 0,
              })
            }
            disabled={!!tableInstance.selectedFlatRows.length}
            className="w-52"
          />
        }
        selectedControls={
          <>
            <Button
              type="primary-filled"
              onClick={() => {
                addressAlerts(
                  tableInstance.selectedFlatRows.map((row) => ({
                    alert: row.original,
                  }))
                )
                tableInstance.toggleAllRowsSelected(false)
              }}
            >
              {t('Address Selected')}
            </Button>
            <Button
              type="secondary"
              onClick={() => tableInstance.toggleAllRowsSelected(false)}
            >
              {t('Cancel')}
            </Button>
          </>
        }
        paginatable
        paginationInfo={alertsQuery.data}
        onPageChange={(newPageNumber) => {
          setAlertFilters({
            ...alertFilters,
            skip: newPageNumber - 1,
          })
        }}
      />
      <AlertDetails
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        alert={selectedAlert}
        subscriber={selectedAlert?.subscriber}
      />
    </>
  ) : (
    <LoadingIcon />
  )
}

export default OrganizationAlertsActivityList

const SeverityChip = ({ row }: { row: Alert }) => {
  const { t } = useTranslation()

  return (
    <div>
      {row.vitalReading ? (
        <Chip
          color={
            (['yellow', 'orange', 'red'] as const)[
              row.vitalReading.thresholdSeverity - 1
            ]
          }
        >{`${t('Severity')} ${row.vitalReading.thresholdSeverity} - ${
          row.vitalReading.reading > row.vitalReading.normalHigh
            ? t('Above Maximum')
            : t('Below Minimum')
        }`}</Chip>
      ) : (
        '-'
      )}
    </div>
  )
}

const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  TableToggleAllRowsSelectedProps
>(
  (
    { indeterminate, ...rest }: TableToggleAllRowsSelectedProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const defaultRef = React.useRef(null)
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      if (typeof resolvedRef === 'object' && resolvedRef.current) {
        resolvedRef.current.indeterminate = Boolean(indeterminate)
      }
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const TypeLabel = styled.p<{ parent?: string }>(({ parent }) => [
  !parent && tw`text-sm text-gray-500`,
])

const CheckboxContainer = tw.div`mt-0.5`

const CreatedAtContainer = tw.div`flex`

const AddressedAtContainer = tw.div`flex`
