import React from 'react'
import clsx from 'clsx'
import { IconType as ReactIcon } from 'react-icons'
import {
  AiFillPushpin,
  AiOutlineLoading,
  AiOutlinePushpin,
} from 'react-icons/ai'
import {
  BsCheckCircle,
  BsCircle,
  BsQuestion,
  BsStopwatch,
} from 'react-icons/bs'
import {
  FaCheckCircle,
  FaCircleNotch,
  FaRegHandshake,
  FaShippingFast,
  FaSyncAlt,
  FaTablets,
  FaSprayCan,
  FaPumpMedical,
} from 'react-icons/fa'
import {
  FiAlertCircle,
  FiAlertTriangle,
  FiAlignJustify,
  FiAlignRight,
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiBarChart2,
  FiBook,
  FiCalendar,
  FiCheck,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiClipboard,
  FiCoffee,
  FiCornerUpLeft,
  FiEdit2,
  FiEye,
  FiEyeOff,
  FiGlobe,
  FiHome,
  FiInbox,
  FiInfo,
  FiKey,
  FiLayers,
  FiLink,
  FiList,
  FiLock,
  FiLogOut,
  FiMap,
  FiMapPin,
  FiMonitor,
  FiMoreHorizontal,
  FiPackage,
  FiPauseCircle,
  FiPlayCircle,
  FiPlus,
  FiRadio,
  FiSearch,
  FiServer,
  FiSettings,
  FiShieldOff,
  FiShoppingCart,
  FiSkipBack,
  FiStopCircle,
  FiTool,
  FiTrash2,
  FiTrendingUp,
  FiUser,
  FiUsers,
  FiX,
  FiXCircle,
} from 'react-icons/fi'
import { GiMedicines } from 'react-icons/gi'
import { MdNavigateNext, MdOutlineAdminPanelSettings } from 'react-icons/md'
import { RiArrowGoBackFill, RiSyringeLine } from 'react-icons/ri'
import { VscOrganization } from 'react-icons/vsc'
import tw, { styled } from 'twin.macro'

export type IconProps = {
  type: IconType
  htmlType?: 'button' | 'submit' | 'reset'
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  disabled?: boolean
  ['data-testid']?: string
  className?: string
  buttonClassName?: string
  style?: React.CSSProperties
}

const Icon = ({
  type,
  onClick,
  disabled,
  ['data-testid']: testId,
  className,
  buttonClassName,
  style,
}: IconProps) => {
  let IconComponent: ReactIcon
  switch (type) {
    case 'edit':
      IconComponent = FiEdit2
      break
    case 'trash':
      IconComponent = FiTrash2
      break
    case 'chevron-down':
      IconComponent = FiChevronDown
      break
    case 'chevron-left':
      IconComponent = FiChevronLeft
      break
    case 'chevron-up':
      IconComponent = FiChevronUp
      break
    case 'chevron-right':
      IconComponent = FiChevronRight
      break
    case 'logout':
      IconComponent = FiLogOut
      break
    case 'x':
      IconComponent = FiX
      break
    case 'home':
      IconComponent = FiHome
      break
    case 'organizations':
      IconComponent = FiLayers
      break
    case 'vitals':
      IconComponent = FiBarChart2
      break
    case 'empty':
      IconComponent = FiInbox
      break
    case 'search':
      IconComponent = FiSearch
      break
    case 'check':
      IconComponent = FiCheck
      break
    case 'subscribers':
      IconComponent = FiUsers
      break
    case 'user':
      IconComponent = FiUser
      break
    case 'reminders':
      IconComponent = BsStopwatch
      break
    case 'check-circle':
      IconComponent = BsCircle
      break
    case 'check-marked':
      IconComponent = BsCheckCircle
      break
    case 'check-marked-full':
      IconComponent = FaCheckCircle
      break
    case 'shipped':
      IconComponent = FaShippingFast
      break
    case 'return':
      IconComponent = RiArrowGoBackFill
      break
    case 'processing':
      IconComponent = FaCircleNotch
      break
    case 'admin':
      IconComponent = MdOutlineAdminPanelSettings
      break
    case 'partners':
      IconComponent = FaRegHandshake
      break
    case 'partner-types':
      IconComponent = VscOrganization
      break
    case 'unauthorized':
      IconComponent = FiShieldOff
      break
    case 'ellipsis':
      IconComponent = FiMoreHorizontal
      break
    case 'tool':
      IconComponent = FiTool
      break
    case 'settings':
      IconComponent = FiSettings
      break
    case 'coffee':
      IconComponent = FiCoffee
      break
    case 'lock':
      IconComponent = FiLock
      break
    case 'module':
      IconComponent = FiPackage
      break
    case 'application':
      IconComponent = FiMonitor
      break
    case 'permissions':
      IconComponent = FiKey
      break
    case 'policies':
      IconComponent = FiBook
      break
    case 'alert':
      IconComponent = FiAlertCircle
      break
    case 'alert2':
      IconComponent = FiAlertTriangle
      break
    case 'medications':
      IconComponent = GiMedicines
      break
    case 'locale':
      IconComponent = FiGlobe
      break
    case 'active':
      IconComponent = FiTrendingUp
      break
    case 'map':
      IconComponent = FiMap
      break
    case 'info':
      IconComponent = FiInfo
      break
    case 'visible':
      IconComponent = FiEye
      break
    case 'invisible':
      IconComponent = FiEyeOff
      break
    case 'disclaimers':
      IconComponent = FiList
      break
    case 'clipboard':
      IconComponent = FiClipboard
      break
    case 'link':
      IconComponent = FiLink
      break
    case 'plus':
      IconComponent = FiPlus
      break
    case 'response':
      IconComponent = FiCornerUpLeft
      break
    case 'overview':
      IconComponent = (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-globe"
          {...props}
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="2" y1="12" x2="22" y2="12"></line>
          <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
        </svg>
      )
      break
    case 'phone':
      IconComponent = (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-phone"
          {...props}
        >
          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
        </svg>
      )
      break
    case 'hardware':
      IconComponent = (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-hard-drive"
          {...props}
        >
          <line x1="22" y1="12" x2="2" y2="12"></line>
          <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
          <line x1="6" y1="16" x2="6.01" y2="16"></line>
          <line x1="10" y1="16" x2="10.01" y2="16"></line>
        </svg>
      )
      break
    case 'error':
      IconComponent = FiXCircle
      break
    case 'arrow-left':
      IconComponent = FiArrowLeft
      break
    case 'arrow-right':
      IconComponent = FiArrowRight
      break
    case 'arrow-down':
      IconComponent = FiArrowDown
      break
    case 'arrow-up':
      IconComponent = FiArrowUp
      break
    case 'fill-pin':
      IconComponent = AiFillPushpin
      break
    case 'outline-pin':
      IconComponent = AiOutlinePushpin
      break
    case 'calendar':
      IconComponent = FiCalendar
      break
    case 'navigate-next':
      IconComponent = MdNavigateNext
      break
    case 'align-right':
      IconComponent = FiAlignRight
      break
    case 'align-justify':
      IconComponent = FiAlignJustify
      break
    case 'location':
      IconComponent = FiMapPin
      break
    case 'devices':
      IconComponent = FiServer
      break
    case 'shopping-cart':
      IconComponent = FiShoppingCart
      break
    case 'radio':
      IconComponent = FiRadio
      break
    case 'loading':
      IconComponent = AiOutlineLoading
      break
    case 'play-button':
      IconComponent = FiPlayCircle
      break
    case 'stop-button':
      IconComponent = FiStopCircle
      break
    case 'pause-button':
      IconComponent = FiPauseCircle
      break
    case 'skip-back':
      IconComponent = FiSkipBack
      break
    case 'unknown':
      IconComponent = BsQuestion
      break
    case 'sync':
      IconComponent = FaSyncAlt
      break
    case 'tablets':
      IconComponent = FaTablets
      break
    case 'spray':
      IconComponent = FaSprayCan
      break
    case 'lotion':
      IconComponent = FaPumpMedical
      break
    case 'syringe':
      IconComponent = RiSyringeLine
      break
    default:
      IconComponent = BsQuestion
  }

  // if no onClick prop is provided, we'll assume this Icon isn't a button
  if (!onClick) {
    return (
      <IconComponent
        data-testid={testId}
        className={clsx('inline', className)}
        style={style}
      />
    )
  }

  return (
    <IconButton
      onClick={(e) => {
        if (!disabled) return onClick(e)
      }}
      disabled={disabled}
      data-testid={testId}
      className={buttonClassName}
    >
      <IconComponent
        className={clsx('inline', className, disabled && 'cursor-not-allowed')}
        style={style}
      />
    </IconButton>
  )
}

export default Icon

const IconButton = styled.div<{ disabled?: boolean }>(({ disabled }) => [
  disabled && tw`cursor-not-allowed`,
  tw`self-center outline-none `,
])
