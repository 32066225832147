import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCheckoutLineItemMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/line-items/{lineItemId}/provisioning-checkout'],
    orderCheckoutArgs
  >({
    mutationFn: checkoutLineItem,
    successMsg: {
      message: t('Line item checked out for provisioning'),
      showOn: 'onSuccess',
    },
    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['orderLineItems', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}/line-items'],
          orderCheckoutArgs
        >((oldCache, { lineItemId }) => {
          if (!oldCache) return undefined

          return oldCache.map((lineItem) =>
            lineItem.id === lineItemId
              ? { ...lineItem, orderLineItemStatusId: 3 }
              : lineItem
          )
        }),
      },
    ],
    additionalCachesToInvalidate: [
      ({ orderId }) => ['orderActivity', { orderId }],
      ({ orderId }) => ['order', { orderId }],
    ],
  })

  return mutation
}

export default useCheckoutLineItemMutation

type orderCheckoutArgs = { orderId: string; lineItemId: string }

const checkoutLineItem = ({ orderId, lineItemId }: orderCheckoutArgs) =>
  ordersApi.post(
    '/orders/{orderId}/line-items/{lineItemId}/provisioning-checkout',
    {
      pathParams: { orderId, lineItemId },
    }
  )
