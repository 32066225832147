import React, { useMemo } from 'react'
import { useAddressedAlertStatistics } from 'hooks/statistics'
import colors from 'tailwindcss/colors'
import tw from 'twin.macro'
import { useNavigate } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Icon, PieChart } from 'elements'
import { dateRangeStateToURL } from 'utils'

type AddressedAlertsPieChartProps = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const AddressedAlertsPieChart = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: AddressedAlertsPieChartProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data, isLoading } = useAddressedAlertStatistics({
    dateRangeState,
    organizationId,
    subscriberId,
  })

  const formattedData = useMemo(() => {
    if (!data) return []

    return [
      {
        id: 'Unaddressed Alerts',
        label: 'Unaddressed Alerts',
        value: data.alertData.total - data.alertData.addressed,
        color: colors.gray[200],
        percentage:
          (data.alertData.total - data.alertData.addressed) /
          data.alertData.total,
      },
      {
        id: 'Addressed Alerts',
        label: 'Addressed Alerts',
        value: data.alertData.addressed,
        color: colors.emerald[300],
        percentage: data.alertData.addressed / data.alertData.total,
      },
    ].filter((series) => series.value > 0)
  }, [data])

  return (
    <Card>
      <HeaderRow>
        <Header
          onClick={() =>
            navigate({
              pathname: subscriberId
                ? `/subscribers/${subscriberId}/alerts-activity`
                : '/vitals/alerts-activity',
              searchParams: {
                addressed: 'unaddressed',
                ...dateRangeStateToURL(dateRangeState),
              },
            })
          }
        >
          <Title>{t('Addressed vs Unaddressed Alerts')}</Title>
          <LinkIcon type="link" />
        </Header>
      </HeaderRow>
      <PieChart data={formattedData} isLoading={isLoading} />
    </Card>
  )
}

export default AddressedAlertsPieChart

const Card = tw.div`bg-white p-3 h-72`

const HeaderRow = tw.div`flex items-center justify-between`

const Header = tw.div`flex items-center cursor-pointer`

const Title = tw.h3`text-lg lg:mb-0 mr-1`

const LinkIcon = tw(Icon)`w-3.5 h-3.5`
