import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { success, orgsApi, handleQueryError } from 'utils'

const useDeactivateOrganizationMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/organizations/{organizationId}/deactivate'],
    KyError,
    deactivateOrganizationArgs,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}'] | undefined
  >(deactivateOrganization, {
    onMutate: ({ organization }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        OrgsAPIResponse['GET']['/api/organizations/{organizationId}']
      >(['getOrganization', organization.id])

      // optimistic update
      queryCache.setQueryData<
        | OrgsAPIResponse['GET']['/api/organizations/{organizationId}']
        | undefined
      >(['getOrganization', organization.id], (old) => {
        if (!old) return

        const newValue: OrgsAPIResponse['GET']['/api/organizations/{organizationId}'] = {
          ...old,
          status: 'Inactive',
        }

        return newValue
      })

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Organization deactivated') })
    },
    onError: async (error, { organization }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | OrgsAPIResponse['GET']['/api/organizations/{organizationId}']
        | undefined
      >(['getOrganization', organization.id], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      queryCache.invalidateQueries(['getOrganization', args.organization.id])
      queryCache.invalidateQueries(['getOrganizationList'])
    },
  })

  return mutation
}

export default useDeactivateOrganizationMutation

type deactivateOrganizationArgs = {
  organization: Organization
}

const deactivateOrganization = async ({
  organization,
}: deactivateOrganizationArgs) => {
  const result = orgsApi
    .post(`api/organizations/${organization.id}/deactivate`)
    .json<
      OrgsAPIResponse['POST']['/api/organizations/{organizationId}/deactivate']
    >()

  return result
}
