import React from 'react'
import { motion } from 'framer-motion'

type FadeInProps = {
  children: React.ReactNode
  delay?: number
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
  disable?: boolean
}

const start = {
  opacity: 0,
}

const end = {
  opacity: 1,
}

const FadeIn = ({
  children,
  delay = 0,
  className,
  onClick,
  style,
  disable,
}: FadeInProps) => {
  return (
    <motion.div
      initial={disable ? false : start}
      animate={end}
      transition={{ duration: 0.2, delay }}
      className={className}
      onClick={onClick}
      style={style}
    >
      {children}
    </motion.div>
  )
}

export default FadeIn
