type DateRangePresetToInputLabel = { [key in DateRangePreset]: string }

// TODO: dynamically show the year
const dateRangePresetToInputLabel: DateRangePresetToInputLabel = {
  today: 'MMM d, yyyy',
  'this-week': 'MMM d, yyyy',
  'last-week': 'MMM d, yyyy',
  'this-month': 'MMM d, yyyy',
  'last-month': 'MMM d, yyyy',
  'this-quarter': 'MMM d, yyyy',
  'last-quarter': 'MMM d, yyyy',
  'this-year': 'MMM d, yyyy',
  'last-year': 'MMM d yyyy',
  custom: 'MMM d, yyyy',
}

export default dateRangePresetToInputLabel
