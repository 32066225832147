import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useCreateSubscriberAddressMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/subscribers-profile/{subscriberId}/addresses'],
    CreateSubscriberAddressProps
  >({
    mutationFn: createSubscriberAddress,
    successMsg: t('Address created'),
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => [
          'getSubscriberAddresses',
          subscriberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/addresses'],
          CreateSubscriberAddressProps
        >((oldCache, { addressForm }) => ({
          addresses: [{ id: 'placeholder', ...addressForm }],
        })),
      },
    ],
  })

  return mutation
}

export default useCreateSubscriberAddressMutation

type CreateSubscriberAddressProps = {
  addressForm: SubscriberAddressForm
  subscriberId: string
}

const createSubscriberAddress = ({
  addressForm,
  subscriberId,
}: CreateSubscriberAddressProps) =>
  typedOrgsApi.post('/subscribers-profile/{subscriberId}/addresses', {
    pathParams: { subscriberId },
    body: addressForm,
  })
