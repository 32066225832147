import { Dropdown, DropdownItem, Icon, ConfirmationDialogs } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useArchiveSubscriberVitalAssignmentMutation,
  useActivateSubscriberVitalAssignmentMutation,
  useDeactivateSubscriberVitalAssignmentMutation,
} from 'hooks/vitals/vital-assignments'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'

type SubscriberVitalSignCardProps = {
  vitalAssignment: VitalAssignment
  vitalAssignments: SubscriberVitalAssignments
}

const SubscriberVitalSignCard = ({
  vitalAssignment,
  vitalAssignments,
}: SubscriberVitalSignCardProps) => {
  const { t } = useTranslation()
  const { subscriberId } = useParams()

  const {
    mutate: archiveVitalSign,
  } = useArchiveSubscriberVitalAssignmentMutation()
  const {
    mutate: activateVitalAssignment,
  } = useActivateSubscriberVitalAssignmentMutation()
  const {
    mutate: deactivateVitalAssignment,
  } = useDeactivateSubscriberVitalAssignmentMutation()

  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const dropdownAnchorEl = useRef(null)

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t(`Archive "${vitalAssignment.name}" vital sign assignment`),
      content: (
        <p>
          {t('Are you sure you want to archive this vital sign assignment?')}
        </p>
      ),
      actions: (
        <Button
          onClick={() => {
            archiveVitalSign({
              subscriberId,
              vitalAssignments,
              vitalSignId: vitalAssignment.id,
            })
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${vitalAssignment.name}" vital sign assignment`),
      content: (
        <p>
          {t('Are you sure you want to deactivate this vital sign assignment?')}
        </p>
      ),
      actions: (
        <Button
          onClick={() => {
            deactivateVitalAssignment({
              subscriberId,
              vitalAssignments,
              vitalSignId: vitalAssignment.id,
            })
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${vitalAssignment.name}" vital sign assignment`),
      content: (
        <p>
          {t('Are you sure you want to activate this vital sign assignment?')}
        </p>
      ),
      actions: (
        <Button
          onClick={() => {
            activateVitalAssignment({
              subscriberId,
              vitalAssignments,
              vitalSignId: vitalAssignment.id,
            })
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
  ]

  return (
    <VitalSignCard>
      <DropdownItemCard>
        <DropDownItemCardContainer>
          <AssignmentName>
            {vitalAssignment.name}
            &nbsp;&nbsp;
          </AssignmentName>
          <DropdownAnchor ref={dropdownAnchorEl}>
            <EllipsisIcon
              data-testid={`${vitalAssignment.name}-ellipsis`}
              type="ellipsis"
              onClick={handleDropdownToggle}
            />
            <Dropdown
              visible={isDropdownVisible}
              setVisible={setIsDropdownVisible}
              parentRef={dropdownAnchorEl}
            >
              <DropdownItem
                onClick={() => {
                  vitalAssignment.active
                    ? setIsDeactivateDialogOpen(true)
                    : setIsActivateDialogOpen(true)

                  // Reset Popover
                  setIsDropdownVisible(false)
                }}
              >
                {vitalAssignment.active ? t('Deactivate') : t('Activate')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setIsArchiveDialogOpen(true)

                  // Reset Popover
                  setIsDropdownVisible(false)
                }}
              >
                {t('Archive')}
              </DropdownItem>
            </Dropdown>
          </DropdownAnchor>
        </DropDownItemCardContainer>
        {vitalAssignment.active ? (
          <Chip color="green">{t('Active')}</Chip>
        ) : (
          <Chip color="red">{t('Inactive')}</Chip>
        )}
      </DropdownItemCard>
      <DisplayContainer>
        {t('Display Name')}:&nbsp;
        <DisplayValue>{vitalAssignment.displayName}</DisplayValue>
      </DisplayContainer>
      <DisplayContainer>
        {t('Units')}:&nbsp;
        <DisplayValue>
          {
            vitalAssignment.units.find(
              (unit) => unit.code === vitalAssignment.defaultUnitCode
            )?.displayName
          }
        </DisplayValue>
      </DisplayContainer>
      <ConfirmationDialogs confirmations={confirmations} />
    </VitalSignCard>
  )
}

export default SubscriberVitalSignCard

const VitalSignCard = tw.div`bg-white p-5 rounded-lg border border-gray-200 h-full`

const DropdownItemCard = tw.div`border-b pb-2 mb-2`

const DropDownItemCardContainer = tw.div`mb-1 flex justify-between align-middle`

const AssignmentName = tw.h3`text-xl`

const DropdownAnchor = tw.div`relative inline-flex self-center`

const EllipsisIcon = tw(Icon)`text-gray-900 w-5 h-5`

const DisplayContainer = tw.p`text-gray-500`

const DisplayValue = tw.span`text-gray-800`
