import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

const useSubscriberOnboardingQuery = ({
  subscriberId,
}: {
  subscriberId?: string
}) => {
  const query = useQuery(
    ['getSubscriberOnboarding', subscriberId],
    () => getSubscriberOnboarding({ subscriberId }),
    {
      enabled: !!subscriberId,
      staleTime: 0,
    }
  )

  return query
}

type getSubscriberOnboardingArgs = { subscriberId?: string }

const getSubscriberOnboarding = async ({
  subscriberId,
}: getSubscriberOnboardingArgs) => {
  if (!subscriberId) return

  const result = await typedVitalsApi.get(
    '/subscribers/{subscriberId}/on-boarding',
    { pathParams: { subscriberId } }
  )

  return result
}

export default useSubscriberOnboardingQuery
