import React from 'react'
import Icon from '../Icon'
import tw, { styled } from 'twin.macro'

type SearchBoxProps = {
  placeholder?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  disabled?: boolean
}

const SearchBox = React.forwardRef<HTMLDivElement, SearchBoxProps>(
  (
    { placeholder, value, onChange, className, disabled }: SearchBoxProps,
    ref
  ) => {
    return (
      <Container className={className} ref={ref}>
        <SearchIcon type="search" />
        <SearchInput
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={!!disabled}
        />
      </Container>
    )
  }
)

export default SearchBox

const Container = tw.div`relative`

const SearchIcon = tw(Icon)`absolute left-3 w-4 h-4 text-gray-400 top[13px]`

const SearchInput = styled.input<{ disabled: boolean }>(({ disabled }) => [
  tw`bg-white rounded w-full p-2 pl-9 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50`,
  disabled && 'cursor-not-allowed',
])
