import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, vitalsApi, handleQueryError } from 'utils'

// used to delete Reminder schedule
const useDeleteReminderScheduleMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['DELETE']['/vital-reminders/{reminderId}'],
    KyError,
    deleteReminderScheduleArgs,
    | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
    | undefined
  >(deleteReminderSchedule, {
    onMutate: (data) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
      >(['getReminderSchedules', data.subscriberId])

      //optimistic update
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
        | undefined
      >(['getReminderSchedules', data.subscriberId], (oldData) => {
        if (!oldData?.items) return undefined

        return {
          ...oldData,
          data: oldData.items.filter((preset) => preset.id !== data.reminderId),
        }
      })

      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Reminder schedule deleted') })
    },
    onError: (error, { subscriberId }, snapshot) => {
      //revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
        | undefined
      >(['getReminderSchedules', subscriberId], snapshot)

      handleQueryError({ error })
    },

    onSettled: (_, error, req) => {
      // refetch from API to validate sync with server
      queryCache.invalidateQueries(['getReminderSchedules', req.subscriberId])
    },
  })

  return mutation
}

export default useDeleteReminderScheduleMutation

type deleteReminderScheduleArgs = {
  reminderId: string
  subscriberId: string
}

const deleteReminderSchedule = async (data: deleteReminderScheduleArgs) => {
  const result = await vitalsApi
    .delete(`vital-reminders/${data.reminderId}`)
    .json<VitalsAPIResponse['DELETE']['/vital-reminders/{reminderId}']>()
  return result
}
