import { rest } from 'msw'
import db from '../../data/db'
import {
  generateArchivable,
  generateAuditable,
  generateDisableable,
} from '../utils'
import _ from 'lodash'
import { isPresent } from 'utils'

const vitalsThresholdsModuleHandlers = [
  // SUBSCRIBER VITAL THRESHOLD API
  // get subscriber vitals thresholds
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/subscribers/{subscriberId}/thresholds']
  >('/subscribers/:subId/thresholds', (req, res, ctx) =>
    res(ctx.json({ items: db.get<VitalThresholds[]>('subVitalThresholds') }))
  ),

  // create subscriber vital thresholds
  rest.post<
    VitalsAPIRequest['POST']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds']['body'],
    VitalsAPIResponse['POST']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds']
  >(
    '/subscribers/:subId/vital-signs/:vitalSignId/thresholds',
    (req, res, ctx) => {
      const currentThresholds = db.get<VitalThresholds[]>('subVitalThresholds')
      const vitalSign = db
        .get<VitalSign[]>('vitalSigns')
        .find((vitalSign) => vitalSign.id === req.params.vitalSignId)

      const newThreshold = {
        ...req.body,
        disclaimers: req.body.acceptedDisclaimers,
        subscriberId: req.params.subId,
        vitalSignId: vitalSign?.id || '',
        vitalSign: {
          id: vitalSign?.id || '',
          code: vitalSign?.code || '',
          name: vitalSign?.name || '',
          displayName: vitalSign?.displayName || '',
          units: vitalSign?.units || [],
          media: { audioURL: '', ssmlURL: '' },
          defaultUnitCode: vitalSign?.defaultUnitCode || '',
          ...generateAuditable(),
          ...generateArchivable(),
          ...generateDisableable(),
        },
        organizationId: req.params.orgId,
        ...generateAuditable(),
      }

      db.set<VitalThresholds[]>('subVitalThresholds', [
        ...currentThresholds,
        newThreshold,
      ])

      return res(ctx.json(newThreshold))
    }
  ),

  // update org vital thresholds
  rest.patch<
    VitalsAPIRequest['PATCH']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds']['body'],
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-signs/{vitalSignId}/thresholds']
  >(
    '/subscribers/:subId/vital-signs/:vitalSignId/thresholds',
    (req, res, ctx) => {
      // get all thresholds
      const currentThresholds = db.get<VitalThresholds[]>('subVitalThresholds')

      // Find threshold to be updated
      const [thresholdToUpdate] = _.remove(
        currentThresholds,
        (threshold) => threshold.vitalSign.id === req.params.vitalSignId
      )

      // If vital sign with specified id doesn't exist return 400
      if (!isPresent(thresholdToUpdate))
        throw new Error('There was an error updating vital thresholds')

      const newThreshold = {
        ...thresholdToUpdate,
        ...req.body,
      }

      db.set<VitalThresholds[]>('subVitalThresholds', [
        ...currentThresholds,
        newThreshold,
      ])

      return res(ctx.json({ result: true }))
    }
  ),

  // ORGANIZATION VITAL THRESHOLD API

  // get org vitals thresholds
  rest.get<
    undefined,
    VitalsAPIResponse['GET']['/organizations/{organizationId}/thresholds']
  >('/organizations/:orgId/thresholds', (req, res, ctx) =>
    res(
      ctx.json({
        items: db.get<VitalThresholds[]>('orgVitalThresholds'),
      })
    )
  ),

  // create org vital thresholds
  rest.post<
    VitalsAPIRequest['POST']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']['body'],
    VitalsAPIResponse['POST']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']
  >(
    '/organizations/:orgId/vital-signs/:vitalSignId/thresholds',
    (req, res, ctx) => {
      const currentThresholds = db.get<VitalThresholds[]>('orgVitalThresholds')
      const vitalSign = db
        .get<VitalSign[]>('vitalSigns')
        .find((vitalSign) => vitalSign.id === req.params.vitalSignId)

      const newThreshold = {
        ...req.body,
        disclaimers: req.body.acceptedDisclaimers,
        vitalSignId: vitalSign?.id || '',
        vitalSign: {
          id: vitalSign?.id || '',
          code: vitalSign?.code || '',
          name: vitalSign?.name || '',
          displayName: vitalSign?.displayName || '',
          units: vitalSign?.units || [],
          defaultUnitCode: vitalSign?.defaultUnitCode || '',
          media: { audioURL: '', ssmlURL: '' },
          ...generateAuditable(),
          ...generateArchivable(),
          ...generateDisableable(),
        },
        subscriberId: '',
        organizationId: req.params.orgId,
        ...generateAuditable(),
      }

      db.set<VitalThresholds[]>('orgVitalThresholds', [
        ...currentThresholds,
        newThreshold,
      ])

      return res(ctx.json(newThreshold))
    }
  ),

  // update org vital thresholds
  rest.patch<
    VitalsAPIRequest['PATCH']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']['body'],
    VitalsAPIResponse['PATCH']['/organizations/{organizationId}/vital-signs/{vitalSignId}/thresholds']
  >(
    '/organizations/:orgId/vital-signs/:vitalSignId/thresholds',
    (req, res, ctx) => {
      // get all thresholds
      const currentThresholds = db.get<VitalThresholds[]>('orgVitalThresholds')

      // Find threshold to be updated
      const [thresholdToUpdate] = _.remove(
        currentThresholds,
        (threshold) => threshold.vitalSign.id === req.params.vitalSignId
      )

      if (!isPresent(thresholdToUpdate))
        throw new Error('There was an error updating vital thresholds')

      db.set<VitalThresholds[]>('orgVitalThresholds', [
        ...currentThresholds,
        {
          ...thresholdToUpdate,
          ...req.body,
        },
      ])

      return res(ctx.json({ result: true }))
    }
  ),
]

export default vitalsThresholdsModuleHandlers
