/**
 * Formats a phone number string of different lengths to readable format
 * @param phoneNum
 * @returns formated number string
 */
const formatPhoneNumber = (phoneNum: string): string => {
  // case of international code
  if (phoneNum.length > 10)
    return `+${phoneNum.slice(0, -10)} (${phoneNum.slice(
      -10,
      -7
    )}) ${phoneNum.slice(-7, -4)}-${phoneNum.slice(-4)}`
  // case of no international code
  else
    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(
      6
    )}`
}

export default formatPhoneNumber
