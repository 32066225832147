import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { orgsApi } from 'utils'

const useCreatePartnerTypeChildrenMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    CreatePartnerTypeResponse,
    CreatePartnerTypeChildrenProps
  >({
    mutationFn: createPartnerTypeChildren,
    successMsg: t('Parent-of Association created'),
    optimisticUpdates: [
      {
        cacheKey: ({ parentPartnerTypeId }) => [
          'getPartnerTypeChildren',
          parentPartnerTypeId,
        ],
        updateFn: updateFnConstructor<
          GetPartnerTypeChildrenResponse,
          CreatePartnerTypeChildrenProps
        >((oldCache, { childPartnerTypeIds }) => {
          const partnerTypes = queryCache.getQueryData<
            GetPartnerTypesResponse | undefined
          >(['getPartnerTypes'])

          return oldCache && partnerTypes
            ? [
                ...oldCache,
                ...childPartnerTypeIds.reduce((acc, childPartnerTypeId) => {
                  const childPartnerType = partnerTypes.items.find(
                    (partnerType) => partnerType.id === childPartnerTypeId
                  )
                  if (childPartnerType) acc.push(childPartnerType)
                  return acc
                }, [] as PartnerType[]),
              ]
            : oldCache
        }),
      },
    ],
  })

  return mutation
}

export default useCreatePartnerTypeChildrenMutation

type CreatePartnerTypeChildrenProps = {
  parentPartnerTypeId: string
  childPartnerTypeIds: string[]
}

const createPartnerTypeChildren = async ({
  parentPartnerTypeId,
  childPartnerTypeIds,
}: CreatePartnerTypeChildrenProps) => {
  const request: CreatePartnerTypeChildrenRequest = { childPartnerTypeIds }
  const createPartnerTypeChildrenResult = await orgsApi
    .post(`api/partner-types/${parentPartnerTypeId}/children`, {
      json: request,
    })
    .json<CreatePartnerTypeResponse>()

  return createPartnerTypeChildrenResult
}
