import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import {
  generateArchivable,
  generateAuditable,
  generateDisableable,
} from 'test-utils/api/utils'
import { typedOrgsApi } from 'utils'

const useAddModuleMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/modules'],
    OrgsAPIRequest['POST']['/api/modules']['body']
  >({
    mutationFn: addModule,
    successMsg: t('Module created'),
    additionalCachesToInvalidate: ['getModules'],
    optimisticUpdates: [
      {
        cacheKey: 'getModules',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/modules'],
          OrgsAPIRequest['POST']['/api/modules']['body']
        >((oldCache, moduleForm) =>
          oldCache?.items
            ? {
                ...oldCache,
                items: [
                  ...oldCache.items,
                  {
                    id: '123',
                    ...moduleForm,
                    description: moduleForm.description || '',
                    ...generateArchivable(),
                    ...generateAuditable(),
                    ...generateDisableable(),
                  },
                ],
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useAddModuleMutation

const addModule = (body: OrgsAPIRequest['POST']['/api/modules']['body']) =>
  typedOrgsApi.post('/api/modules', {
    body,
  })
