import React, { useMemo } from 'react'
import { formatDistanceToNow } from 'date-fns'
import { FadeInSlideDown } from 'animations'
import { LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import { useDelay } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useAlertNotesQuery } from 'hooks/alerts'
import { useUsersQuery } from 'hooks/user-profile'
import tw from 'twin.macro'
import { isPresent } from 'utils'
import _ from 'lodash'

type AlertNotesProps = {
  alertId: string
}

const AlertNotes = ({ alertId }: AlertNotesProps) => {
  const { t } = useTranslation()
  const delay = useDelay()

  const notesQuery = useAlertNotesQuery(alertId)

  const noteAuthorIds = useMemo(
    () =>
      _.uniq(
        notesQuery.data?.items
          ?.map((note) => note.auditInfo.createdBy)
          .filter(isPresent)
      ),
    [notesQuery.data]
  )

  const usersQuery = useUsersQuery(noteAuthorIds)

  if (notesQuery.isLoading || usersQuery.isLoading)
    return <LoadingIcon className="h-40" />

  return (
    <AlertNotesContainer>
      {notesQuery.data?.items?.length ? (
        notesQuery.data.items.map((alertNote, index) => {
          return (
            <FadeInSlideDown key={index} delay={delay()}>
              <AlertNote>{alertNote.notes}</AlertNote>
              <AuditInfoContainer>
                <NoteDate>
                  {`${formatDistanceToNow(
                    new Date(alertNote.auditInfo.createdAt)
                  )} ${t('ago')} -`}
                </NoteDate>
                <NoteAuthor>
                  &nbsp;
                  {/** //TODO: navigate name to profile on click? */}
                  {formatAuthor(
                    usersQuery.data?.find(
                      (user) =>
                        user.cognitoUserId === alertNote.auditInfo.createdBy
                    )
                  )}
                </NoteAuthor>
              </AuditInfoContainer>
              {/* Do not render Line on the last note */}
              {index !== (notesQuery.data.items?.length || 1) - 1 ? (
                <Line />
              ) : null}
            </FadeInSlideDown>
          )
        })
      ) : (
        <AlertNotesEmpty title={t('No Notes Have Been Added')} />
      )}
    </AlertNotesContainer>
  )
}

export default AlertNotes

const AlertNotesContainer = tw.div`mb-8`

const AlertNote = tw.p`text-sm my-2 text-justify`

const AuditInfoContainer = tw.div`flex flex-row`

const NoteDate = tw.p`text-sm text-gray-300 text-left`

const NoteAuthor = tw.p`text-sm text-gray-300 text-right`

const Line = tw.div`border-b-2 border-gray-100 w-full mt-2`

const AlertNotesEmpty = tw(Empty)`h-32 border-2 border-dashed rounded`

const formatAuthor = (user: User | undefined) =>
  (user && `${user.person.firstName} ${user.person.lastName}`) ||
  'Unknown Author'
