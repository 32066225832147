import useMutation from 'hooks/useMutation'

const usePinnedSubscribersMutation = () => {
  const mutation = useMutation<
    { result: boolean },
    pinnedSubscribersMutationArgs
  >({
    mutationFn: pinnedSubscribersMutation,
    additionalCachesToInvalidate: ['getPinnedSubscribers'],
  })

  return mutation
}

export default usePinnedSubscribersMutation

type pinnedSubscribersMutationArgs = {
  action: 'pin' | 'unpin'
  subscriberId: string
}

const pinnedSubscribersMutation = async ({
  action,
  subscriberId,
}: pinnedSubscribersMutationArgs) => {
  const rawOldValue = localStorage.getItem('subPinned')
  const oldValue = rawOldValue ? JSON.parse(rawOldValue) : []

  if (action === 'pin') {
    localStorage.setItem(
      'subPinned',
      JSON.stringify([...oldValue, subscriberId])
    )

    return { result: true }
  }
  // case: action is 'unpin'
  localStorage.setItem(
    'subPinned',
    JSON.stringify(oldValue.filter((item: string) => item !== subscriberId))
  )
  return { result: true }
}
