import React, { useEffect, useState } from 'react'
import { Chip } from 'atlas'
import { FadeInSlideDown } from 'animations'
import { useDelay } from 'hooks'
import { isPresent } from 'utils'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'

type SetupStepsFlowProps<T extends Steps<string>> = {
  stepsData: T | undefined
  stepOrder: Array<T[number]['key']>
}

type Steps<T> = Array<{
  complete: boolean
  message: string
  onClick: () => void
  key: T
}>

const SetupStepsFlow = <T extends Steps<string>>({
  stepsData,
  stepOrder,
}: SetupStepsFlowProps<T>) => {
  const { t } = useTranslation()

  const delay = useDelay()

  const windowsWidth = useWindowSize()

  const [isMaxGridColumn, setIsMaxGridColumn] = useState(false)

  const [isSmallerWindow, setIsSmallerWindow] = useState(false)

  useEffect(() => {
    setIsSmallerWindow(windowsWidth.width < 1280)
  }, [windowsWidth.width])

  /**
   * Detect when the steps data length is above 4
   */
  useEffect(() => {
    stepsData && setIsMaxGridColumn(stepsData.length > 4)
  }, [stepsData?.length])

  return (
    <StepsFlowContainer
      isMaxGridColumn={isMaxGridColumn}
      gridColumn={stepsData ? stepsData.length : 0}
      isSmallerWindow={isSmallerWindow}
    >
      {stepOrder
        .map((key) => stepsData?.find((item) => item.key === key))
        .filter(isPresent)
        .map((value, index, arr) => {
          // the card should be clickable if it is the first card or
          // if the previous card has complete status
          const isClickable = index > 0 ? arr[index - 1].complete : true

          return (
            <StepsCardContainer
              key={index}
              delay={delay()}
              isMaxGridColumn={isMaxGridColumn}
              isSmallerWindow={isSmallerWindow}
              isComplete={value.complete}
              precursor={isClickable}
              onClick={isClickable ? value.onClick : undefined}
            >
              <StepsCard
                isSmallerWindow={isSmallerWindow}
                isMaxGridColumn={isMaxGridColumn}
                precursor={isClickable}
                isComplete={value.complete}
              >
                <Header>{_.startCase(value.key)}</Header>

                <StatusContainer>
                  <Status>
                    {value.complete ? (
                      <ChipStatus color="green">
                        <ChipStepCount>{index + 1}</ChipStepCount>
                        {t('Complete')}
                      </ChipStatus>
                    ) : (
                      <ChipStatus color="red">
                        <ChipStepCount>{index + 1}</ChipStepCount>
                        {t('Incomplete')}
                      </ChipStatus>
                    )}
                  </Status>
                </StatusContainer>

                <DescriptionContainer>
                  <Description>{value.message}</Description>
                </DescriptionContainer>
              </StepsCard>
            </StepsCardContainer>
          )
        })}
    </StepsFlowContainer>
  )
}

export default SetupStepsFlow

const StepsFlowContainer = styled.div<{
  isMaxGridColumn: boolean
  gridColumn: number
  isSmallerWindow: boolean
}>(({ isMaxGridColumn, isSmallerWindow, gridColumn }) => [
  tw`grid gap-9`,
  isMaxGridColumn || isSmallerWindow
    ? tw`grid-rows-1`
    : {
        ...tw`xl:gap-20`,
        gridTemplateColumns: `repeat(${gridColumn}, minmax(0, 1fr))`,
      },
])

const StepsCardContainer = styled(FadeInSlideDown)<{
  isComplete: boolean
  precursor: boolean
  isMaxGridColumn: boolean
  isSmallerWindow: boolean
}>(({ isComplete, precursor, isMaxGridColumn, isSmallerWindow }) => [
  tw`bg-white relative p-4 w-full text-center rounded border cursor-pointer border-gray-200 first:all-child:first:before:hidden transition-all hover:shadow-lg`,
  (isMaxGridColumn || isSmallerWindow) && tw`xl:w-1/3 md:w-1/2`,
  !isComplete && !precursor
    ? tw`bg-opacity-0 border-dashed cursor-not-allowed hover:shadow-none`
    : tw`bg-opacity-100`,
])

const StepsCard = styled.div<{
  precursor: boolean
  isComplete: boolean
  isSmallerWindow: boolean
  isMaxGridColumn: boolean
}>(({ precursor, isComplete, isSmallerWindow, isMaxGridColumn }) => [
  tw`h-full flex flex-col justify-center items-center before:(block w-14 height[1px] absolute left[-69px] top-1/2 border-b-4 border-dotted border-gray-300 cursor-default pointer-events-none)`,
  (isMaxGridColumn || isSmallerWindow) &&
    tw`before:(h-6 width[1px] left-1/2 top[-31.5px] border-r-4 )`,
  precursor && isComplete && tw`before:(border-green-600)`,
])

const Header = tw.h3`text-lg font-semibold inline-block`

const StatusContainer = tw.div`mt-3`

const Status = tw.span`px-2 rounded-full text-xs inline-flex font-semibold leading-5`

const DescriptionContainer = tw.div`mt-3.5 h-full`

const Description = tw.p`text-gray-700 text-sm`

const ChipStatus = tw(Chip)`relative pl-5`

const ChipStepCount = tw.span`absolute flex background-color[inherit] filter brightness-110 text-sm font-extrabold w-7 h-7 -left-3.5 -top-1 rounded-full leading-normal justify-center items-center border-2 border-r-white`
