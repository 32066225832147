import React from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { SubscriberArchivedVitalSignsGrid } from 'components/subscriber-settings'
import { useParams } from 'react-router-dom'
import { useSubscriberQuery } from 'hooks/organizations'
import { useOrgVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'

const SubscriberArchivedVitalAssignments = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const { subscriberId } = useParams()

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  // Get both all vital signs and vital signs already assigned to this org
  const {
    isLoading: orgVitalsLoading,
    data: orgVitalSigns,
  } = useOrgVitalAssignmentsQuery(assumedOrganizationRole?.orgID)
  const isDataLoading = isSubLoading || orgVitalsLoading

  return (
    <Layout
      title={t('Archived Vital Sign Assignments')}
      description={t('View and restore archived vital signs for this user.')}
      breadcrumbs={[
        { name: t('Subscribers'), route: '/subscribers' },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `/subscribers/${currentSubscriber?.id}`,
        },
        {
          name: t('Subscriber Settings'),
          route: `/subscribers/${currentSubscriber?.id}/settings`,
        },
        {
          name: t('Vital Sign Assignments'),
          route: `/subscribers/${currentSubscriber?.id}/settings/vital-sign-assignments`,
        },
        {
          name: t('Archived Vital Sign Assignments'),
        },
      ]}
      isUnauthorized={!assumedOrganizationRole}
      isLoading={isDataLoading}
      // Show empty here if no org vital signs exist
      isEmpty={!orgVitalSigns?.assignedVitalSigns.length}
      emptyComponent={
        <Empty
          title={t('No Data Found')}
          description={t('No archived vital signs exist for this subscriber')}
        />
      }
    >
      <SubscriberArchivedVitalSignsGrid />
    </Layout>
  )
}

export default SubscriberArchivedVitalAssignments
