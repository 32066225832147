import React, { ReactNode } from 'react'
import { Dialog as Popup } from '@mui/material'
import tw from 'twin.macro'

type DialogProps = {
  open: boolean
  title: string
  content: ReactNode
  actions: ReactNode
}
const Dialog = ({ open, title, content, actions }: DialogProps) => {
  return (
    <Popup open={open}>
      <DialogContainer>
        <TitleContainer>{title}</TitleContainer>
        <ContentContainer>{content}</ContentContainer>
        <ActionsContainer>{actions}</ActionsContainer>
      </DialogContainer>
    </Popup>
  )
}

export default Dialog

const DialogContainer = tw.div`p-4`

const TitleContainer = tw.div`text-xl font-bold mb-4`

const ContentContainer = tw.div`mb-4`

const ActionsContainer = tw.div`flex gap-1 justify-end`
