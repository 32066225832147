import { rest } from 'msw'
import { generateParty } from '../utils'

const userProfileHandlers = [
  // get global member statistics
  rest.get<undefined, OrgsAPIResponse['GET']['/api/users/{userId}/accounts']>(
    '/api/users/:cognitoid/accounts',
    (req, res, ctx) =>
      res(
        ctx.json({
          cognitoUserId: 'fjd90sajf90dsja90fjdsa',
          email: 'cypress@ecg-hq.com',
          person: {
            id: 'userId',
            emailAddress: 'cypress@ecg-hq.com',
            firstName: 'John',
            lastName: 'Doe',
            salutation: 0,
            suffix: 'mr',
            party: generateParty(),
          },
          roles: JSON.parse(localStorage.getItem('roles') || '[]'),
        })
      )
  ),
]

export default userProfileHandlers
