import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, typedVitalsApi, handleQueryError } from 'utils'

// used to edit Reminder preset
const useUpdateReminderPresetMutation = () => {
  const { t } = useTranslation()
  const queryCache = useQueryClient()
  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/organizations/{organizationId}/reminder-presets/{presetId}'],
    KyError,
    updateReminderPresetArgs,
    | VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
    | undefined
  >(updateReminderPreset, {
    onMutate: (data) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
      >(['getReminderPresets', data.orgId])

      //optimist update
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
        | undefined
      >(['getReminderPresets', data.orgId], (oldData) => {
        if (!oldData) return undefined
        const filteredReminderPresets = oldData.items?.filter(
          (preset) => preset.id !== data.preset.id
        )
        return {
          ...oldData,
          items: [
            ...(filteredReminderPresets || []),
            {
              ...data.preset,
              schedule: data.schedule,
              organizationId: data.orgId,
              id: data.preset.id || '',
            },
          ],
        }
      })

      return snapshot
    },
    onSuccess: () => {
      success({ message: t('Reminder preset updated') })
    },
    onError: (error, { orgId }, snapshot) => {
      //revert to snapshot
      if (snapshot)
        queryCache.setQueryData<
          VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
        >(['getReminderPresets', orgId], snapshot)
      handleQueryError({ error })
    },
    onSettled: (_, error, req) => {
      // refetch from API to sync with server
      queryCache.invalidateQueries(['getReminderPresets', req.orgId])
    },
  })

  return mutation
}

export default useUpdateReminderPresetMutation

type updateReminderPresetArgs = {
  orgId: string

  preset: { id?: string; name: string; description: string }
  schedule: Schedule
}

// update default reminder
const updateReminderPreset = async (data: updateReminderPresetArgs) =>
  typedVitalsApi.patch(
    `/organizations/{organizationId}/reminder-presets/{presetId}`,
    {
      body: {
        name: data.preset.name,
        description: data.preset.description,
        schedule: data.schedule,
      },
      pathParams: {
        organizationId: data.orgId,
        presetId: data.preset.id || '',
      },
    }
  )
