import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useDeleteVitalSignMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['DELETE']['/vital-signs/{vitalSignId}'],
    KyError<ErrorResponse>,
    VitalSign
  >(deleteVitalSign, {
    onSuccess: (_, deletedVitalSign) => {
      // Notify user of successful vital sign deletion
      success({ message: t('Vital sign deleted') })

      // Grab the old vital sign list from the queryCache except for the vital sign that was deleted
      const oldVitalSigns: VitalSign[] =
        queryCache
          .getQueryData<VitalsAPIResponse['GET']['/vital-signs']>(
            'ECGAdmin_getVitalSigns'
          )
          ?.items?.filter(
            (vitalSign) => vitalSign.id !== deletedVitalSign.id
          ) || []

      // Immediately remove Vital from local cache
      queryCache.setQueryData(['ECGAdmin_getVitalSigns'], [...oldVitalSigns])

      // Refetch from API to make sure the new vital sign is in sync with the server
      queryCache.invalidateQueries('ECGAdmin_getVitalSigns')
      queryCache.invalidateQueries('getVitalAssignments')
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useDeleteVitalSignMutation

// Delete vital sign from backend
const deleteVitalSign = async (vitalSign: VitalSign) => {
  const result = await vitalsApi
    .delete(`vital-signs/${vitalSign.id}`)
    .json<VitalsAPIResponse['DELETE']['/vital-signs/{vitalSignId}']>()
  return result
}
