import faker from 'faker'
import { rest } from 'msw'
import db from '../../data/db'
import {
  generateArchivable,
  generateAuditable,
  generateDisableable,
  updateDatabaseArray,
} from '../utils'

const partnersModuleHandlers = [
  // get partner list
  rest.get<undefined, GetPartnersResponse>('/api/partners', (req, res, ctx) =>
    res(
      ctx.json({
        items: db.get<Partner[]>('partners'),
        recordsPerPage: 10,
        totalRecords: 0,
        currentPageNumber: 1,
        totalPages: 0,
      })
    )
  ),

  // create partner
  rest.post<CreatePartnerRequest, CreatePartnerResponse>(
    '/api/partners',
    (req, res, ctx) => {
      const partners = db.get<Partner[]>('partners')
      const partnerTypes = db.get<PartnerType[]>('partnerTypes')

      const partnerType = partnerTypes.find(
        (partnerType) => partnerType.id === req.body.partnerType
      )

      if (!partnerType) throw new Error('Partner Type not found')

      const response: Partner = {
        id: faker.random.uuid(),
        allowSubscriberAccess: req.body.allowSubscriberAccess,
        partnerType,
        party: {
          id: faker.random.uuid(),
          legacyIdentifier: '',
          phoneNumbers:
            req.body.phoneNumbers?.map((phoneNumber) => ({
              id: faker.random.uuid(),
              phoneNumber: phoneNumber.number,
              phoneType: +phoneNumber.type,
              extension: phoneNumber.extension || '',
            })) || [],
          addresses: req.body.addresses?.map((address) => ({
            id: faker.random.uuid(),
            ...address,
          })) as Address[],
          ...generateDisableable(),
          ...generateArchivable(),
          ...generateAuditable(),
        },
        account: req.body.account,
        person: req.body.person,
        ...(req.body.organization
          ? {
              organization: {
                id: faker.random.uuid(),
                rrmsGroupNumber: 1,
                ...req.body.organization,
                ...generateArchivable(),
                ...generateDisableable(),
                ...generateAuditable(),
                onBoardingPartner: '',
                status: 'active',
                nodes: [],
                party: {
                  legacyIdentifier: '123',
                  id: faker.random.uuid(),
                  phoneNumbers:
                    req.body.phoneNumbers?.map((phoneNumber) => ({
                      id: faker.random.uuid(),
                      phoneNumber: phoneNumber.number,
                      phoneType: +phoneNumber.type,
                      extension: phoneNumber.extension,
                    })) || [],
                  addresses: req.body.addresses?.map((address) => ({
                    id: faker.random.uuid(),
                    ...address,
                  })) as Address[],
                  ...generateDisableable(),
                  ...generateArchivable(),
                  ...generateAuditable(),
                },
                integrationMetadata: {},
              } as Organization,
            }
          : {}),
      }

      // update the db
      db.set<Partner[]>('partners', [...partners, response])

      return res(ctx.json(response))
    }
  ),

  /* -------- PARTNER TYPES ------------- */

  // get partner types
  rest.get<undefined, GetPartnerTypesResponse>(
    '/api/partner-types',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<PartnerType[]>('partnerTypes'),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        } as GetPartnerTypesResponse)
      )
  ),

  // get partner type
  rest.get<undefined, GetPartnerTypeResponse>(
    '/api/partner-types/:partnerTypeId',
    (req, res, ctx) => {
      const partnerType = db
        .get<PartnerType[]>('partnerTypes')
        .find((partnerType) => partnerType.id === req.params.partnerTypeId)

      if (!partnerType) throw new Error('Partner Type not found')

      return res(ctx.json(partnerType))
    }
  ),

  // get partner type children
  rest.get<undefined, GetPartnerTypeChildrenResponse>(
    '/api/partner-types/:partnerTypeId/children',
    (req, res, ctx) =>
      res(
        ctx.json(
          db
            .get<PartnerTypeRelationship[]>('partnerTypeRelationships')
            // find relationships where the provided partnerTypeIf is the parent
            .filter(
              (partnerTypeRelationship) =>
                partnerTypeRelationship.parentPartnerType.id ===
                req.params.partnerTypeId
            )
            // return the child partnerType
            .map(
              (partnerTypeRelationship) =>
                partnerTypeRelationship.childPartnerType
            )
        )
      )
  ),

  // activate partner type
  rest.post<undefined, ActivatePartnerTypeResponse>(
    '/api/partner-types/:partnerTypeId/activate',
    (req, res, ctx) => {
      const partnerTypes = db.get<PartnerType[]>('partnerTypes')

      const partnerTypeToUpdate = partnerTypes.find(
        (partnerType) => partnerType.id === req.params.partnerTypeId
      )

      if (!partnerTypeToUpdate) throw new Error('Partner Type not found')

      const updatedPartnerTypes = partnerTypes.map((partnerType) =>
        partnerType.id === req.params.partnerTypeId
          ? {
              ...partnerTypeToUpdate,
              activeInfo: {
                ...partnerTypeToUpdate.activeInfo,
                active: true,
              },
            }
          : partnerType
      )

      // update the db
      db.set<PartnerType[]>('partnerTypes', updatedPartnerTypes)

      return res(ctx.json({ value: true }))
    }
  ),
  // deactivate partner type
  rest.post<undefined, ActivatePartnerTypeResponse>(
    '/api/partner-types/:partnerTypeId/deactivate',
    (req, res, ctx) => {
      const partnerTypes = db.get<PartnerType[]>('partnerTypes')

      const partnerTypeToUpdate = partnerTypes.find(
        (partnerType) => partnerType.id === req.params.partnerTypeId
      )

      if (!partnerTypeToUpdate) throw new Error('Partner Type not found')

      const updatedPartnerTypes = partnerTypes.map((partnerType) =>
        partnerType.id === req.params.partnerTypeId
          ? {
              ...partnerTypeToUpdate,
              activeInfo: {
                ...partnerTypeToUpdate.activeInfo,
                active: false,
              },
            }
          : partnerType
      )

      // update the db
      db.set<PartnerType[]>('partnerTypes', updatedPartnerTypes)

      return res(ctx.json({ value: true }))
    }
  ),

  // create partner type
  rest.post<CreatePartnerTypeRequest, CreatePartnerTypeResponse>(
    '/api/partner-types',
    (req, res, ctx) => {
      const partnerTypes = db.get<PartnerType[]>('partnerTypes')

      const newPartnerType = {
        id: faker.random.uuid(),
        ...req.body,
        description: req.body.description || '',
        ...generateDisableable(),
      }

      // update the db
      db.set<PartnerType[]>('partnerTypes', [...partnerTypes, newPartnerType])

      return res(ctx.json(newPartnerType))
    }
  ),

  // update partner type
  rest.patch<UpdatePartnerTypeRequest, UpdatePartnerTypeResponse>(
    '/api/partner-types/:partnerTypeId',
    (req, res, ctx) => {
      const partnerTypes = db.get<PartnerType[]>('partnerTypes')

      const partnerTypeToUpdate = partnerTypes.find(
        (partnerType) => partnerType.id === req.params.partnerTypeId
      )

      if (!partnerTypeToUpdate) throw new Error('Partner Type not found')

      const updatedPartnerTypes = updateDatabaseArray<PartnerType>({
        array: partnerTypes,
        requests: req.body,
        itemToUpdate: partnerTypeToUpdate,
        matchBy: 'id',
      })

      // update the db
      db.set<PartnerType[]>('partnerTypes', updatedPartnerTypes)

      return res(ctx.json(partnerTypeToUpdate))
    }
  ),

  // create partner type relationships
  rest.post<
    CreatePartnerTypeChildrenRequest,
    CreatePartnerTypeChildrenResponse
  >('/api/partner-types/:partnerTypeId/children', (req, res, ctx) => {
    const partnerTypes = db.get<PartnerType[]>('partnerTypes')

    const childPartnerTypes = partnerTypes.filter((partnerType) =>
      req.body.childPartnerTypeIds.includes(partnerType.id)
    )
    const parentPartnerType = partnerTypes.find(
      (partnerType) => req.params.partnerTypeId === partnerType.id
    )

    if (!parentPartnerType) throw new Error('Parent Partner Type Not Found!')

    const newPartnerTypeRelationships: PartnerTypeRelationship[] = childPartnerTypes.map(
      (childPartnerType) => ({
        id: faker.random.uuid(),
        childPartnerType,
        parentPartnerType,
      })
    )

    const partnerTypeRelationships = db.get<PartnerTypeRelationship[]>(
      'partnerTypeRelationships'
    )

    // update the db
    db.set<PartnerTypeRelationship[]>('partnerTypeRelationships', [
      ...partnerTypeRelationships,
      ...newPartnerTypeRelationships,
    ])

    return res(ctx.json(childPartnerTypes))
  }),

  // delete a partner type relationship
  rest.delete<undefined, DeletePartnerTypeChildrenResponse>(
    '/api/partner-types/:partnerTypeId/children/:childrenId',
    (req, res, ctx) => {
      const partnerTypeRelationships = db.get<PartnerTypeRelationship[]>(
        'partnerTypeRelationships'
      )

      const newPartnerTypeRelationships: PartnerTypeRelationship[] = partnerTypeRelationships.filter(
        (partnerTypeRelationship) =>
          !(
            partnerTypeRelationship.parentPartnerType.id ===
              req.params.partnerTypeId &&
            partnerTypeRelationship.childPartnerType.id ===
              req.params.childrenId
          )
      )

      // update the db
      db.set<PartnerTypeRelationship[]>(
        'partnerTypeRelationships',
        newPartnerTypeRelationships
      )

      return res(ctx.json({ value: true }))
    }
  ),

  /* -------- COMMISSIONS ------------- */

  // get partner type commissions
  rest.get<undefined, GetPartnerTypeCommissionsResponse>(
    '/api/partner-types/:partnerTypeId/direct-commissions',
    (req, res, ctx) =>
      res(
        ctx.json(
          db
            .get<Commission[]>('partnerTypeCommissions')
            .filter(
              (commission) =>
                commission.partnerType.id === req.params.partnerTypeId
            )
        )
      )
  ),

  // get partner type indirect commissions
  rest.get<undefined, GetPartnerTypeCommissionsResponse>(
    '/api/partner-types/:partnerTypeId/indirect-commissions',
    (req, res, ctx) => res(ctx.json([]))
  ),

  // create partner type commissions
  rest.post<
    CreatePartnerTypeCommissionRequest,
    CreatePartnerTypeCommissionResponse
  >('/api/partner-types/:partnerTypeId/direct-commissions', (req, res, ctx) => {
    const partnerType = db
      .get<PartnerType[]>('partnerTypes')
      .find((partnerType) => partnerType.id === req.params.partnerTypeId)

    if (!partnerType) throw new Error('Partner Type not found!')

    const newCommission = {
      id: faker.random.uuid(),
      partnerType,
      productId: req.body.productId,
      isOneTimeCommission: req.body.oneTimeCommission.allowed,
      isRecurringCommission: req.body.recurringCommission.allowed,
      ...(req.body.oneTimeCommission.allowed
        ? {
            oneTimeCommissionAmount:
              req.body.oneTimeCommission.commissionAmount,
          }
        : {}),
      ...(req.body.recurringCommission.allowed
        ? {
            recurringCommissionAmount:
              req.body.recurringCommission.commissionAmount,
          }
        : {}),
    }

    const commissions = db.get<Commission[]>('partnerTypeCommissions')

    // update the db
    db.set<Commission[]>('partnerTypeCommissions', [
      ...commissions,
      newCommission,
    ])

    return res(ctx.json(newCommission))
  }),

  // update partner type commissions
  rest.patch<
    UpdatePartnerTypeCommissionRequest,
    UpdatePartnerTypeCommissionResponse
  >(
    '/api/partner-types/:partnerTypeId/direct-commissions/:commissionId',
    (req, res, ctx) => {
      const commissions = db.get<Commission[]>('partnerTypeCommissions')

      const commissionToUpdate = commissions.find(
        (commission) => commission.id === req.params.commissionId
      )

      if (!commissionToUpdate) throw new Error('Commission not found')

      const updatedPartnerTypes = updateDatabaseArray<Commission>({
        array: commissions,
        requests: req.body,
        itemToUpdate: commissionToUpdate,
        matchBy: 'id',
      })

      // update the db
      db.set<Commission[]>('partnerTypeCommissions', updatedPartnerTypes)

      return res(ctx.json(commissionToUpdate))
    }
  ),

  // delete partner type commissions
  rest.delete<undefined, DeletePartnerTypeCommissionResponse>(
    '/api/partner-types/:partnerTypeId/direct-commissions/:commissionId',
    (req, res, ctx) => {
      const commissions = db.get<Commission[]>('partnerTypeCommissions')

      // update the db
      db.set<Commission[]>(
        'partnerTypeCommissions',
        commissions.filter(
          (commission) => commission.id !== req.params.commissionId
        )
      )

      return res(ctx.json({ value: true }))
    }
  ),
]

export default partnersModuleHandlers
