import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

const useOrganizationMemberStatistics = ({
  organizationId,
}: {
  organizationId?: string
} = {}) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/member-statistics'],
    KyError
  >(
    [
      'getOrganizationMemberStatistics',
      organizationId || assumedOrganizationRole?.orgID,
    ],
    () =>
      getOrganizationMemberStatistics(
        organizationId || assumedOrganizationRole?.orgID
      ),
    {
      enabled: !!(organizationId || assumedOrganizationRole?.orgID),
    }
  )

  return query
}

export default useOrganizationMemberStatistics

const getOrganizationMemberStatistics = async (organizationId = '') =>
  typedOrgsApi.get(`/api/organizations/{organizationId}/member-statistics`, {
    pathParams: { organizationId },
  })
