import db from './db'

// prevent tests from failing
db.init()

export const reminderMessageTypes: Array<ReminderMessageType> = [
  {
    id: 'reminder',
    name: 'Reminder Message',
    description: 'The message to play the Vitals readings.',
    displayOrder: 1,
  },
  {
    id: 'snooze-confirmation',
    name: 'Snooze Confirmation Message',
    description:
      'The message to be played when a Subscriber snoozes the Vitals reading reminder.',
    displayOrder: 2,
  },
  {
    id: 'repeated-reminder',
    name: 'Repeated Reminder Message',
    description:
      'The message to be played after the Subscriber has snoozed the reminder.',
    displayOrder: 3,
  },
  {
    id: 'final-reminder',
    name: 'Final Reminder Message',
    description:
      'The message to be played after the max snooze attempts have been exhausted.',
    displayOrder: 4,
  },
  {
    id: 'failed-observation',
    name: 'Failed Observation Message',
    description:
      'The message to be played when no Vitals readings are collected and all snooze attempts have been exhausted.',
    displayOrder: 5,
  },
]

export const webhookAPIVersions = ['1.0']
