import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useDeleteSubscriberAddressMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['DELETE']['/subscribers-profile/{subscriberId}/addresses/{addressId}'],
    DeleteAddressProps
  >({
    mutationFn: deleteSubscriberAddress,
    successMsg: t('Address deleted'),
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => [
          'getSubscriberAddresses',
          subscriberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/addresses'],
          DeleteAddressProps
        >((oldCache, { addressId }) => ({
          addresses:
            oldCache?.addresses.filter((address) => address.id !== addressId) ||
            [],
        })),
      },
    ],
  })

  return mutation
}

export default useDeleteSubscriberAddressMutation

type DeleteAddressProps = {
  addressId: string
  subscriberId: string
}

const deleteSubscriberAddress = ({
  addressId,
  subscriberId,
}: DeleteAddressProps) =>
  typedOrgsApi.delete(
    '/subscribers-profile/{subscriberId}/addresses/{addressId}',
    {
      pathParams: { addressId, subscriberId },
    }
  )
