import React, { useState } from 'react'
import { Layout, CustomSelect } from 'elements'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DisclaimersList, DisclaimerForm } from 'components/disclaimers'
import { useDisclaimersQuery, useDisclaimerTypesQuery } from 'hooks/disclaimers'
import { FadeInSlideDown } from 'animations'
import { useLocalesQuery } from 'hooks/locales'
import { useNavigate } from 'hooks'
import _ from 'lodash'
import tw from 'twin.macro'

const DisclaimerTypeDetails = () => {
  const { disclaimerTypeId } = useParams()

  const [isDisclaimerFormOpen, setIsDisclaimerFormOpen] = useState(false)
  const [disclaimer, setDisclaimer] = useState<Disclaimer | void>()
  const [selectedLocale, setSelectedLocale] = useState<string>('en-US')

  const { data: locales } = useLocalesQuery()

  const {
    data: disclaimers,
    isLoading: isLoadingDisclaimers,
  } = useDisclaimersQuery(undefined, undefined, disclaimerTypeId)

  const {
    data: disclaimerTypesResponse,
    isLoading: isLoadingDisclaimerTypes,
  } = useDisclaimerTypesQuery()

  const { t } = useTranslation()
  const navigate = useNavigate()

  // set initial disclaimer to edit

  const disclaimerTypeName =
    disclaimerTypesResponse?.items?.find(
      (disclaimerType) => disclaimerType.id === disclaimerTypeId
    )?.name || ''

  return (
    <>
      <Layout
        title={disclaimerTypeName}
        description={t(
          'Create, edit, and delete disclaimers for a specific disclaimer type.'
        )}
        breadcrumbs={[
          { name: t('Settings'), route: '/settings' },
          { name: t('Disclaimer Types'), route: '/settings/disclaimer-types' },
          {
            name: disclaimerTypeName,
          },
        ]}
        controls={[
          {
            label: t('Create Disclaimers'),
            onClick: () => {
              setDisclaimer()
              setIsDisclaimerFormOpen(true)
            },
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
            disabled: true,
          },
        ]}
        isLoading={isLoadingDisclaimers || isLoadingDisclaimerTypes}
      >
        <FadeInSlideDown
          delay={0.1}
          className="flex justify-between items-center mb-4"
        >
          <Title>{t('Disclaimers')}</Title>
          <CustomSelect
            className="w-56"
            value={selectedLocale}
            onChange={(e) => setSelectedLocale(e.value)}
            options={_.sortBy(
              locales?.items?.map((locale) => ({
                value: locale.name,
                label: locale.name,
              })) || [],
              'label'
            )}
          />
        </FadeInSlideDown>
        <DisclaimersList
          data={
            disclaimers?.items?.filter(
              (disclaimer) => disclaimer.locale === selectedLocale
            ) || []
          }
          onEditDisclaimer={(selectedDisclaimer) => {
            // if the selected disclaimer is a translation, find the en-US disclaimer that its a translation of
            if (selectedDisclaimer.locale !== 'en-US')
              setDisclaimer(
                disclaimers?.items
                  ?.filter((disclaimer) => disclaimer.locale === 'en-US')
                  .find((disclaimer) =>
                    disclaimer.translations
                      ?.map((translation) => translation.disclaimer)
                      .includes(selectedDisclaimer.disclaimer)
                  )
              )
            else setDisclaimer(selectedDisclaimer)
            setIsDisclaimerFormOpen(true)
          }}
        />
      </Layout>
      <DisclaimerForm
        isFormOpen={isDisclaimerFormOpen}
        setIsFormOpen={setIsDisclaimerFormOpen}
        disclaimer={disclaimer}
        autoFocus={selectedLocale}
      />
    </>
  )
}

export default DisclaimerTypeDetails

const Title = tw.h3`text-xl font-semibold mb-2`
