import React, { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { TextField, Icon } from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'

type ECGAdminCreateUnitFormProps = {
  setIsUnitFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ECGAdminCreateUnitForm = ({
  setIsUnitFormOpen,
}: ECGAdminCreateUnitFormProps) => {
  const {
    errors,
    watch,
    register,
    trigger,
    setValue,
  } = useFormContext<VitalSign>()
  const { fields, append, remove } = useFieldArray({
    name: 'units',
  })
  const { t } = useTranslation()

  const [editUnit, setEditUnit] = useState<EditUnit>({ index: null })
  const [isCreatingUnit, setIsCreatingUnit] = useState<boolean>(false)

  // returns boolean used to show either input fields or unit display card
  const isInputFieldsShown = (unitIndex: number) =>
    // show input fields if user is inputting a new unit and unitIndex is the last index
    (isCreatingUnit && unitIndex === fields.length - 1) ||
    // show input fields if user has selected unit to edit
    editUnit.index === unitIndex

  return (
    <>
      <AddUnitContainer>
        <TitleContainer>
          <Title>{t('Vital Sign Unit of Measurement')}</Title>
        </TitleContainer>
        <Button
          disabled={isCreatingUnit || editUnit.index !== null}
          onClick={() => {
            // Show inputs for last index in fields array
            setIsCreatingUnit(true)
            // Append a new empty unit to fields array for user to edit
            append({ name: '', displayName: '', code: '' })
            // Let parent know unit form open
            setIsUnitFormOpen(true)
          }}
        >
          {t('Add Unit')}
        </Button>
      </AddUnitContainer>
      {fields.map(({ name, displayName, code, id }, index) => {
        // These unit fields show only if creating a new unit
        return (
          <div key={id}>
            {/* Create/Edit Unit Form */}
            <UnitCodeContainer hidden={!isInputFieldsShown(index)}>
              <TextField
                error={errors.units?.[index]?.name?.message}
                name={`units[${index}].name`}
                //@ts-expect-error it's fine if the required message is undefined
                inputRef={register({ required: t('Unit Name is required') })}
                label={t('Unit Name')}
                defaultValue={name}
                fullWidth
                required
              />
              <TextField
                error={errors.units?.[index]?.displayName?.message}
                name={`units[${index}].displayName`}
                //@ts-expect-error it's fine if the required message is undefined
                inputRef={register({
                  required: t('Unit Display Name is required'),
                })}
                label={t('Unit Display Name')}
                defaultValue={displayName}
                fullWidth
                required
              />
              <TextField
                error={errors.units?.[index]?.code?.message}
                name={`units[${index}].code`}
                //@ts-expect-error it's fine if the required message is undefined
                inputRef={register({ required: t('Unit Code is required') })}
                label={t('Unit Code')}
                defaultValue={code}
                fullWidth
                required
              />
              {/* At most, only one set of these buttons are ever rendered at a time for testing purposes */}
              {isInputFieldsShown(index) && (
                <UnitCodeButtonsContainer>
                  <Button
                    type="primary-filled"
                    onClick={async () => {
                      // Run validation on the unit fields before allowing to collapse the fields
                      const isValid = await trigger([
                        `units[${index}].name`,
                        `units[${index}].displayName`,
                        `units[${index}].code`,
                      ])
                      if (!isValid) return

                      if (isCreatingUnit) {
                        // Hide fields for creating new unit
                        setIsCreatingUnit(false)
                      } else {
                        // Reset editUnitIndex
                        setEditUnit({ index: null })
                      }
                      // Let parent know unit form closed
                      setIsUnitFormOpen(false)
                    }}
                  >
                    {t('Save Unit')}
                  </Button>
                  &nbsp;
                  <Button
                    type="secondary"
                    onClick={() => {
                      if (isCreatingUnit) {
                        // Remove new unit entry from field array
                        remove(index)
                        // Hide fields for creating new unit
                        setIsCreatingUnit(false)
                      } else {
                        // Reset Unit Fields to what their originalFieldVals were before editing
                        setValue(`units[${index}]`, editUnit.originalFieldVals)
                        // Reset editUnitIndex
                        setEditUnit({ index: null })
                      }
                      // Let parent know unit form closed
                      setIsUnitFormOpen(false)
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                </UnitCodeButtonsContainer>
              )}
            </UnitCodeContainer>

            {/* Unit Card */}
            <UnitCardContainer
              isInputShown={isInputFieldsShown(index)}
              key={index}
            >
              <p>
                {`${watch('units')[index]?.displayName} (${
                  watch('units')[index]?.code
                })`}
              </p>
              <div>
                <EditIcon
                  type="edit"
                  data-testid={`unit-${index}-edit`}
                  onClick={() => {
                    // When edit unit toggled, save originalVals to restore them if cancel is clicked
                    setEditUnit({
                      index,
                      originalFieldVals: { ...watch('units')[index] },
                    })
                    // Let parent know unit form open
                    setIsUnitFormOpen(true)
                  }}
                  disabled={isCreatingUnit || editUnit.index !== null}
                />
                &nbsp;&nbsp;
                <TrashIcon
                  type="trash"
                  data-testid={`unit-${index}-delete`}
                  onClick={() => {
                    // remove unit from field array
                    remove(index)
                  }}
                  disabled={isCreatingUnit || editUnit.index !== null}
                />
              </div>
            </UnitCardContainer>
          </div>
        )
      })}

      {/* If there are no units created show placeholder */}
      {!watch('units').length && (
        <UnitEmpty
          title={t('No Units Added Yet')}
          description={t(
            'Add one or more units of measurement for this Vital Sign by clicking the "Add Unit" button'
          )}
        />
      )}
    </>
  )
}

export default ECGAdminCreateUnitForm

type EditUnit = {
  index: number | null
  originalFieldVals?: VitalUnit
}

const UnitEmpty = tw(Empty)`border-2 border-dashed p-2 rounded`

const AddUnitContainer = tw.span`flex justify-between mb-4`

const TitleContainer = tw.div`flex items-center`

const Title = tw.h3`text-xl align-middle flex-grow-0`

const UnitCodeContainer = tw.div`mt-6`

const UnitCodeButtonsContainer = tw.div`mt-2 mb-4`

const UnitCardContainer = styled.div<{ isInputShown: boolean }>(
  ({ isInputShown }) => [
    tw`flex border-2 p-4 border-gray-100 rounded-lg justify-between mb-2`,
    isInputShown && tw`hidden`,
  ]
)

const EditIcon = tw(Icon)`w-4 h-4`

const TrashIcon = tw(Icon)`w-4 h-4 text-red-500`
