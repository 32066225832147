import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, webhooksApi, handleQueryError } from 'utils'

const useDeactivateWebhookSubscriptionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    DeactivateWebhookSubscriptionResponse,
    KyError,
    deactivateWebhookSubscriptionArgs,
    {
      inidividualSubscriptionSnapshot?: GetWebhookSubscriptionResponse
      subscriptionListSnapshot?: GetWebhookSubscriptionsResponse
    }
  >(deactivateWebhookSubscription, {
    onMutate: ({
      webhookSubscription: { organizationId, id: subscriptionId },
    }) => {
      // snapshot the individual subscription
      const inidividualSubscriptionSnapshot = queryCache.getQueryData<GetWebhookSubscriptionResponse>(
        ['getWebhookSubscription', organizationId, subscriptionId]
      )

      // snapshot the individual subscription
      const subscriptionListSnapshot = queryCache.getQueryData<GetWebhookSubscriptionsResponse>(
        ['getWebhookSubscriptions', organizationId]
      )

      // optimistic update individual subscription
      queryCache.setQueryData<GetWebhookSubscriptionResponse | undefined>(
        ['getWebhookSubscription', organizationId, subscriptionId],
        (old) => {
          if (!old) return

          const newValue: GetWebhookSubscriptionResponse = {
            ...old,
            activeInfo: {
              ...old.activeInfo,
              active: false,
            },
          }

          return newValue
        }
      )

      // optimistic update subscription list
      queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
        ['getWebhookSubscriptions', organizationId],
        (old) => {
          if (!old) return

          const newValue: GetWebhookSubscriptionsResponse = {
            ...old,
            items: old.items.map((webhook) => {
              if (webhook.id === subscriptionId)
                return {
                  ...webhook,
                  activeInfo: {
                    ...webhook.activeInfo,
                    active: false,
                  },
                }

              return webhook
            }),
          }

          return newValue
        }
      )

      return { inidividualSubscriptionSnapshot, subscriptionListSnapshot }
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Webhook deactivated') })
    },
    onError: async (
      error,
      { webhookSubscription: { organizationId, id: subscriptionId } },
      snapshots
    ) => {
      // revert to snapshots
      queryCache.setQueryData<GetWebhookSubscriptionResponse | undefined>(
        ['getWebhookSubscription', organizationId, subscriptionId],
        snapshots?.inidividualSubscriptionSnapshot
      )

      queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
        ['getWebhookSubscriptions', organizationId],
        snapshots?.subscriptionListSnapshot
      )

      handleQueryError({ error })
    },
    onSettled: (
      _,
      error,
      { webhookSubscription: { organizationId, id: subscriptionId } }
    ) => {
      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries(['getWebhookSubscriptions', organizationId])
      queryCache.invalidateQueries([
        'getWebhookSubscription',
        organizationId,
        subscriptionId,
      ])
    },
  })

  return mutation
}

export default useDeactivateWebhookSubscriptionMutation

type deactivateWebhookSubscriptionArgs = {
  webhookSubscription: WebhookSubscription
}

const deactivateWebhookSubscription = async ({
  webhookSubscription,
}: deactivateWebhookSubscriptionArgs) => {
  const result = webhooksApi
    .post(
      `api/organizations/${webhookSubscription.organizationId}/webhook-subscriptions/${webhookSubscription.id}/deactivate`
    )
    .json<DeactivateWebhookSubscriptionResponse>()

  return result
}
