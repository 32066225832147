import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useDeleteDisclaimerMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['DELETE']['/disclaimers/{disclaimerId}'],
    KyError<ErrorResponse>,
    deleteDisclaimerProps,
    VitalsAPIResponse['GET']['/disclaimers'] | undefined
  >(deleteDisclaimer, {
    onMutate: ({ disclaimerTypeId, disclaimerId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/disclaimers']
      >(['getDisclaimers', undefined, undefined, disclaimerTypeId])

      // optimistic delete
      queryCache.setQueryData<VitalsAPIResponse['GET']['/disclaimers']>(
        ['getDisclaimers', undefined, 'en-US', disclaimerTypeId],
        (oldDisclaimers) => {
          return {
            items:
              oldDisclaimers?.items?.filter(
                (disclaimer) => disclaimer.id !== disclaimerId
              ) || [],
          }
        }
      )

      return snapshot
    },
    onSuccess: () => {
      // Notify user of successful disclaimer delete
      success({
        message: t('Disclaimer deleted'),
      })
    },
    onError: async (error, { disclaimerTypeId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/disclaimers'] | undefined
      >(['getDisclaimers', undefined, undefined, disclaimerTypeId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { disclaimerTypeId }) => {
      // Refetch from API to make sure the deleted disclaimer is in sync with the server
      queryCache.invalidateQueries([
        'getDisclaimers',
        undefined,
        undefined,
        disclaimerTypeId,
      ])
    },
  })

  return mutation
}

export default useDeleteDisclaimerMutation

type deleteDisclaimerProps = {
  disclaimerId: string
  disclaimerTypeId: string
}

// Post deleted disclaimer type to backend
const deleteDisclaimer = async ({ disclaimerId }: deleteDisclaimerProps) => {
  const result = vitalsApi
    .delete(`disclaimers/${disclaimerId}`)
    .json<VitalsAPIResponse['DELETE']['/disclaimers/{disclaimerId}']>()

  return result
}
