import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

type UsePermissionsAuditTrailQueryProps = {
  filters: PermissionAuditSearchParams
}

const usePermissionsAuditTrailQuery = ({
  filters,
}: UsePermissionsAuditTrailQueryProps) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/permissions/audit-trails'],
    KyError
  >(['getAuditTrail', 'permissions', filters], () => getAuditTrail(filters), {
    staleTime: 0,
  })

  return query
}

export default usePermissionsAuditTrailQuery

const getAuditTrail = async (filters: PermissionAuditSearchParams) =>
  typedOrgsApi.get('/api/permissions/audit-trails', {
    searchParams: {
      ...filters,
    },
  })

export type PermissionAuditSearchParams = NonNullable<
  Extract<
    OrgsAPIRequest['GET']['/api/permissions/audit-trails']['searchParams'],
    Record<string, unknown>
  >
>
