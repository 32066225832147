import React from 'react'
import { OrganizationStatusChip } from 'components/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { EntityLabel, InformationCard } from 'elements'
import { useOrganizationQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type OrganizationInfoCardProps = {
  organization: Organization
}

const OrganizationInfoCard = ({ organization }: OrganizationInfoCardProps) => {
  const { t } = useTranslation()

  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const { data: parentOrganization } = useOrganizationQuery(
    organization.parentOrganization?.id
  )

  return (
    <InformationCard>
      {organization.businessName ? (
        <InformationCard.Item
          label={t('Business Name')}
          value={organization.businessName}
        />
      ) : null}

      {organization.displayName ? (
        <InformationCard.Item
          label={t('Display Name')}
          value={organization.displayName}
        />
      ) : null}

      <InformationCard.Item
        label={t('ID')}
        value={<IDText>{organization.id}</IDText>}
      />

      <InformationCard.Item
        label={t('Status')}
        value={<OrganizationStatusChip status={organization.status} />}
      />

      {organization.parentOrganization ? (
        <InformationCard.Item
          label={t('Parent Organization')}
          value={
            <EntityLabel
              name={parentOrganization?.businessName ?? ''}
              id={organization.parentOrganization.id}
              to={generateOrgLink(
                organization,
                organization.parentOrganization.id,
                assumedOrganizationRole
              )}
              condensed
            />
          }
        />
      ) : null}

      {organization.onBoardingPartner ? (
        <InformationCard.Item
          label={t('On Boarding Partner')}
          value={organization.onBoardingPartner}
        />
      ) : null}

      {organization.hcoXrefId ? (
        <InformationCard.Item
          label={t('hcoXrefId')}
          value={organization.hcoXrefId}
        />
      ) : null}
    </InformationCard>
  )
}

export default OrganizationInfoCard

const IDText = tw.p`font-mono`

const generateOrgLink = (
  currentOrg: Organization,
  destinationOrgId: string,
  assumedOrganizationRole?: OrganizationPermissions
): string | undefined => {
  // If the current org is the assumed organization don't link to the parent at all
  if (
    assumedOrganizationRole &&
    currentOrg.id === assumedOrganizationRole.orgID
  )
    return undefined

  // by default redirect to the details page of the parent org
  return assumedOrganizationRole
    ? `/organization/${destinationOrgId}`
    : `/organizations/${destinationOrgId}`
}
