import React, { useState } from 'react'
import {
  RightPopup,
  Icon,
  ConfirmationDialogs,
  TextField,
  Checkbox,
  InformationCard,
} from 'elements'
import { Empty, Button, Tooltip, Alert } from 'atlas'
import { useNavigate } from 'hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSubscriberQuery } from 'hooks/organizations'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useResponsiblePartiesQuery } from 'hooks/responsible-parties'
import { useSubscriberDetailsQuery } from 'hooks/user-subscriber'
import { useCreateAneltoSubscriberMutation } from 'hooks/provisioning'
import { formatAddress } from 'utils'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'

type PersProvisioningProps = {
  subscriberId?: string | null | undefined
  open: boolean
  setOpen: (isOpen: boolean) => void
  setProvisionFormOpen: (isOpen: boolean) => void
}

const NonPERSProvisioning = ({
  subscriberId,
  open,
  setOpen,
  setProvisionFormOpen,
}: PersProvisioningProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { orderId } = useParams()

  const [isChecked, setIsChecked] = useState<boolean>(false)

  const formMethods = useForm<AneltoSubscriber>()
  const { handleSubmit, errors } = formMethods

  const subscriberQuery = useSubscriberQuery(subscriberId || '')

  const subscriberDetailsQuery = useSubscriberDetailsQuery(subscriberId || '')

  const responsiblePartiesQuery = useResponsiblePartiesQuery({
    subscriberId: subscriberId || '',
  })

  const createAneltoSubMutation = useCreateAneltoSubscriberMutation()

  const responsibleParties = responsiblePartiesQuery.data?.items

  const subscriber = subscriberQuery.data

  const subscriberDetails = subscriberDetailsQuery.data

  const subscriberAddress = subscriber?.person.party.addresses[0]

  const [isEditSubDialogOpen, setIsEditSubDialogOpen] = useState<boolean>(false)

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsEditSubDialogOpen,
      isOpen: isEditSubDialogOpen,
      title: t(
        `Edit "${subscriber?.person.firstName} ${subscriber?.person.lastName}"`
      ),
      content: (
        <p>{t('Are you sure you want to navigate away from Orders?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            navigate(`/subscribers/${subscriber?.id}`)

            setIsEditSubDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Edit')}
        </Button>
      ),
    },
  ]

  return (
    <RightPopup
      open={open}
      title={t('Anelto Provisioning')}
      setOpen={setOpen}
      width={'700px'}
      controls={
        <>
          <Button
            isLoading={createAneltoSubMutation.isLoading}
            disabled={!isChecked}
            onClick={handleSubmit((formData) => {
              createAneltoSubMutation.mutate(
                {
                  orderId: orderId || '',
                  organizationId: subscriber?.organization?.id || '',
                  aneltoSub: formData,
                },
                {
                  onSuccess: () => {
                    setOpen(false)
                    setProvisionFormOpen(true)
                  },
                }
              )
            })}
          >
            {t('Continue')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <ProvisioningContainer>
        <Alert
          message={`
            ${t('Non-PERS Provisioning requires Anelto Subscriber Setup')}.
            ${t('Please Verify Subscriber Information')}. ${t(
            'We will send this information to Anelto'
          )}.`}
          type="warning"
        />
        <SubscriberTitle>{t('Subscriber Information')}</SubscriberTitle>
        <SubscriberCard>
          <EditIcon type="edit" onClick={() => setIsEditSubDialogOpen(true)} />
          <SubscriberBasicInfo>
            <InformationCard.Item
              displayMode="labelValue"
              label={t('First Name')}
              value={subscriber?.person.firstName}
            />
            <InformationCard.Item
              displayMode="labelValue"
              label={t('Last Name')}
              value={subscriber?.person.lastName}
            />
            <InformationCard.Item
              displayMode="labelValue"
              label={t('Address')}
              value={
                subscriberAddress ? formatAddress(subscriberAddress) : 'N/A'
              }
            />

            <InformationCard.Item
              displayMode="labelValue"
              label={t('Date of Birth')}
              value={subscriberDetails?.personalInformation?.dob || 'N/A'}
            />

            <InformationCard.Item
              displayMode="labelValue"
              label={t('Locale/Language')}
              value={subscriberDetails?.personalInformation?.locale}
            />
          </SubscriberBasicInfo>
          <Title> {t('Responsible Parties')}</Title>
          <ResponsibleParties>
            {responsibleParties?.length ? (
              _.orderBy(responsibleParties, 'priority').map((rp) => {
                return (
                  <ResponsiblePartyCard key={rp.id}>
                    <ResponsiblePartyItem>{rp.priority}</ResponsiblePartyItem>
                    <ResponsiblePartyItem>
                      {`${rp.person.firstName} ${rp.person.lastName}`}
                    </ResponsiblePartyItem>
                    <ResponsiblePartySubItem>
                      {rp.relationship}
                    </ResponsiblePartySubItem>
                    <ResponsiblePartySubItem>
                      {rp.person.emailAddress || undefined}
                    </ResponsiblePartySubItem>
                    <ResponsiblePartySubItem>
                      {rp.phoneNumbers[0].number}
                    </ResponsiblePartySubItem>
                  </ResponsiblePartyCard>
                )
              })
            ) : (
              <EmptyDisplay
                title={t('No Data Found')}
                description={t('No responsible party information found')}
              />
            )}
          </ResponsibleParties>
        </SubscriberCard>
        <AneltoTitle>{t('Anelto Console Information')} </AneltoTitle>
        <FormProvider {...formMethods}>
          <form>
            <AneltoContainer>
              <Controller
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('Anelto Account # is required') }}
                name="aneltoAccountNumber"
                required
                error={errors.aneltoAccountNumber?.message}
                fullWidth
                label={t('Anelto Account #')}
                as={AneltoValue}
              />
              <AneltoTip
                content={t(
                  'Enter Anelto Account Number i.e. XMIT ID or a similar ID here'
                )}
              >
                <InfoIcon type="info" />
              </AneltoTip>
            </AneltoContainer>
            <AneltoContainer>
              <Controller
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('IMEI is required') }}
                error={errors.imei?.message}
                name="imei"
                required
                fullWidth
                label={t('IMEI #')}
                as={AneltoValue}
              />
              <AneltoTip
                content={t(
                  'Enter the IMEI number you see on the Anelto Device'
                )}
              >
                <InfoIcon type="info" />
              </AneltoTip>
            </AneltoContainer>
            <AneltoContainer>
              <Controller
                //@ts-expect-error it's fine if the required message is undefined
                rules={{ required: t('ICCID is required') }}
                name="iccid"
                error={errors.iccid?.message}
                required
                fullWidth
                label={t('ICCID #')}
                as={AneltoValue}
              />
              <AneltoTip
                content={t(
                  'Enter the ICCID number you see on the Anelto Device'
                )}
              >
                <InfoIcon type="info" />
              </AneltoTip>
            </AneltoContainer>
          </form>
        </FormProvider>
      </ProvisioningContainer>
      <CheckboxAgreement
        checked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
        label={t(
          'I have reviewed the Subscriber information and confirm it is correct'
        )}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </RightPopup>
  )
}

export default NonPERSProvisioning

const ProvisioningContainer = tw.div`flex flex-col gap-8`

const SubscriberCard = tw.div`relative bg-white border-gray-100 border shadow-md rounded p-3`

const EditIcon = tw(Icon)`absolute w-4 h-4 right-3 top-2 cursor-pointer`

const SubscriberBasicInfo = tw.div`grid grid-cols-3 p-1 gap-y-4`

const ResponsibleParties = tw.div`grid grid-cols-1 gap-4 flex-grow px-2`

const ResponsiblePartyItem = tw.div`font-medium`

const ResponsiblePartySubItem = tw.div`font-medium text-gray-500`

const ResponsiblePartyCard = styled.div`
  ${tw`grid`}
  grid-template-columns: 15px 1.25fr 1fr 1fr 1fr;
  grid-auto-rows: min-content;
`

const Title = tw.h2`text-lg font-semibold ml-1 mb-2 py-2`

const AneltoContainer = tw.div`flex flex-row`

const AneltoValue = tw(TextField)``

const AneltoTip = tw(Tooltip)`w-28`

const InfoIcon = tw(Icon)` w-5 h-5 text-gray-600 ml-2 mt-5 hover:text-gray-900`

const CheckboxAgreement = tw(Checkbox)``

const SubscriberTitle = tw.h2`text-xl font-semibold ml-1  pb-1 -mb-8`

const AneltoTitle = tw.h2`text-xl font-semibold`

const EmptyDisplay = tw(Empty)`border-2 border-dashed rounded`
