import React, { useState } from 'react'
import {
  CreateOrganizationForm,
  OrganizationList,
} from 'components/organizations'
import { useTranslation } from 'react-i18next'
import { Layout } from 'elements'
import tw from 'twin.macro'

const ArchivedOrganizations = () => {
  const { t } = useTranslation()

  const [isOrganizationFormOpen, setIsOrganizationFormOpen] = useState<boolean>(
    false
  )

  return (
    <Layout
      title={t('Archived Organizations')}
      description={t(
        'Restore or delete organizations that have been archived.'
      )}
      breadcrumbs={[
        {
          name: t('Organizations'),
          route: '/organizations',
        },
        {
          name: t('Archived Organizations'),
        },
      ]}
    >
      <OrgListContainer>
        <OrganizationList archived />
      </OrgListContainer>
      <CreateOrganizationForm
        isFormOpen={isOrganizationFormOpen}
        setIsFormOpen={setIsOrganizationFormOpen}
      />
    </Layout>
  )
}

export default ArchivedOrganizations

const OrgListContainer = tw.div`flex flex-col flex-grow`
