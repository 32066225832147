import { rest } from 'msw'
import faker from 'faker'
import db from '../../data/db'
import { generatePaginatable } from '../utils'

const disclaimerHandlers = [
  // get disclaimer types
  rest.get<undefined, VitalsAPIResponse['GET']['/disclaimer-types']>(
    '/disclaimer-types',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<DisclaimerType[]>('disclaimerTypes'),
          ...generatePaginatable(),
        })
      )
  ),

  // post disclaimer type
  rest.post<
    VitalsAPIRequest['POST']['/disclaimer-types']['body'],
    VitalsAPIResponse['POST']['/disclaimer-types']
  >('/disclaimer-types', (req, res, ctx) => {
    const newDisclaimerType = {
      id: faker.random.uuid(),
      ...req.body,
    }

    db.set<DisclaimerType[]>('disclaimerTypes', [
      ...db.get<DisclaimerType[]>('disclaimerTypes'),
      newDisclaimerType,
    ])

    return res(ctx.json(newDisclaimerType))
  }),

  // update disclaimer type
  rest.patch<
    VitalsAPIRequest['PATCH']['/disclaimer-types/{disclaimerType}']['body'],
    VitalsAPIResponse['PATCH']['/disclaimer-types/{disclaimerType}']
  >('/disclaimer-types/:disclaimerTypeId', (req, res, ctx) => {
    db.set<DisclaimerType[]>(
      'disclaimerTypes',
      db.get<DisclaimerType[]>('disclaimerTypes').map((disclaimerType) =>
        disclaimerType.id === req.params.disclaimerTypeId
          ? {
              id: req.params.disclaimerTypeId,
              ...req.body,
            }
          : disclaimerType
      )
    )

    return res(ctx.json({ result: true }))
  }),

  // get disclaimers
  rest.get<undefined, VitalsAPIResponse['GET']['/disclaimers']>(
    '/disclaimers',
    (req, res, ctx) =>
      res(ctx.json({ items: db.get<Disclaimer[]>('disclaimers') }))
  ),

  // create disclaimer
  rest.post<
    VitalsAPIRequest['POST']['/disclaimers']['body'],
    VitalsAPIResponse['POST']['/disclaimers']
  >('/disclaimers', (req, res, ctx) => {
    db.set<Disclaimer[]>('disclaimers', [
      ...db.get<Disclaimer[]>('disclaimers'),
      {
        id: faker.random.uuid(),
        ...req.body,
        createdAt: '',
        createdBy: '',
      },
    ])

    return res(ctx.json({ result: true }))
  }),

  // update disclaimer
  rest.patch<
    VitalsAPIRequest['PATCH']['/disclaimers/{disclaimerId}']['body'],
    VitalsAPIResponse['PATCH']['/disclaimers/{disclaimerId}']
  >('/disclaimers/:disclaimerId', (req, res, ctx) => {
    const oldDisclaimers = db.get<VitalsAPIResponse['GET']['/disclaimers']>(
      'disclaimers'
    )
    const newDisclaimers = {
      items: oldDisclaimers.items?.map((disclaimer) => {
        if (disclaimer.id === req.params.disclaimerId)
          return {
            id: req.body.parentDisclaimerId,
            locale: req.body.locale,
            disclaimer: req.body.disclaimerText,
            createdAt: '',
            createdBy: '',
            translations: req.body.translations,
            disclaimerType: req.body.disclaimerType,
          }
        return disclaimer
      }),
    }

    db.set<VitalsAPIResponse['GET']['/disclaimers']>(
      'disclaimers',
      newDisclaimers
    )

    return res(ctx.json({ result: true }))
  }),

  // delete disclaimer
  rest.delete<
    undefined,
    VitalsAPIResponse['DELETE']['/disclaimers/{disclaimerId}']
  >('/disclaimers/:disclaimerId', (req, res, ctx) => {
    const oldDisclaimers = db.get<Disclaimer[]>('disclaimers')

    const newDisclaimers = oldDisclaimers.filter(
      (disclaimer) => disclaimer.id !== req.params.disclaimerId
    )

    db.set<Disclaimer[]>('disclaimers', newDisclaimers)

    return res(ctx.json({ result: true }))
  }),
]

export default disclaimerHandlers
