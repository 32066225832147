import React, { useState } from 'react'
import { Button } from 'atlas'
import DayOfTheWeekToggleGroup from 'components/reminder-presets/DayOfTheWeekToggleGroup'
import {
  LoadingIcon,
  RightPopup,
  Select,
  SelectItem,
  TimePicker,
} from 'elements'
import { useLocalesQuery } from 'hooks/locales'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type AddMedicationScheduleFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
}

const AddMedicationScheduleForm = ({
  isFormOpen,
  setIsFormOpen,
}: AddMedicationScheduleFormProps) => {
  const { t } = useTranslation()
  const [locale, setLocale] = useState<string>('en-US')
  const [timePicker, setTimePicker] = useState<Date | null>(new Date())
  const [selectedWeekdays, setSelectedWeekdays] = useState<Weekdays[]>(['mon'])
  const locales = useLocalesQuery()
  return (
    <>
      <RightPopup
        title={t('Add Medication Schedule')}
        open={isFormOpen}
        setOpen={setIsFormOpen}
        controls={
          <>
            <Button
              isLoading={false}
              type="primary-filled"
              onClick={() => setIsFormOpen(false)}
            >
              {t('Add & Close')}
            </Button>
            &nbsp;
            <Button type="secondary" onClick={() => setIsFormOpen(false)}>
              {t('Cancel')}
            </Button>
          </>
        }
      >
        {locales.isLoading ? (
          <LoadingContainer>
            <LoadingIcon height="100%" />
          </LoadingContainer>
        ) : (
          <form>
            {/*Schedule Reminder Time Picker */}
            <RowContainer>
              <TimePicker
                name="schedule.time"
                value={timePicker}
                onChange={setTimePicker}
                label={t('Reminder Time')}
                disabled={false}
              />
            </RowContainer>
            {/* Reminder Frequency Select*/}
            <RowContainer>
              <DayOfTheWeekToggleGroup
                label={t('Repeat Every')}
                name="schedule.weekdays"
                disabled={false}
                toggled={selectedWeekdays}
                onChange={setSelectedWeekdays}
                setToggled={setSelectedWeekdays}
              />
            </RowContainer>
            {/* Select Locale */}
            <RowContainer>
              <SelectContainer
                required
                variant="outlined"
                label={t('Locale*')}
                defaultValue={locale}
                onChange={(e) => setLocale(e.target.value + '')}
              >
                {locales.data?.items?.map((locale) => (
                  <SelectItem key={locale.name} value={locale.name}>
                    {t(locale.displayName)}
                  </SelectItem>
                ))}
              </SelectContainer>
            </RowContainer>
          </form>
        )}
      </RightPopup>
    </>
  )
}
export default AddMedicationScheduleForm

const SelectContainer = tw(Select)`mt-2 flex-grow max-w-md mb-8`

const RowContainer = tw.div`flex mb-4`

const LoadingContainer = tw.div`flex flex-col h-full flex-grow`
