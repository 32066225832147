import React, { useState } from 'react'
import { ConfirmationDialogs, Layout, WizardForm } from 'elements'
import { useTranslation } from 'react-i18next'
import { Button } from 'atlas'
import tw from 'twin.macro'
import { useAPIQuery, useNavigate } from 'hooks'
import {
  useCancelOrderMutation,
  useReactivateOrderMutation,
} from 'hooks/orders'
import { useSearchParam } from 'react-use'
import {
  BasicInformation,
  Products,
  ServiceDetails,
  Summary,
} from 'components/orders'
import ShippingInformation from 'components/orders/create-orders/ShippingInformation'
import { OrderStatusTitles } from 'components/orders/OrderStatusChip'

const CreateOrder = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const orderId = useSearchParam('orderId')

  const cancelOrder = useCancelOrderMutation()

  const reactivateOrder = useReactivateOrderMutation()

  const orderQuery = useAPIQuery('order', {
    pathParams: { orderId: orderId || '' },
  })

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const [isReactivateDialogOpen, setIsReactivateDialogOpen] = useState(false)

  const confirmations = [
    //Cancel Order
    {
      setIsOpen: setIsCancelDialogOpen,
      isOpen: isCancelDialogOpen,
      title: t('Cancel this order'),
      content: (
        <DialogContent>
          {t('Are you sure you want to cancel this order?')}
        </DialogContent>
      ),
      actions: (
        <Button
          type="danger-filled"
          onClick={() => {
            cancelOrder.mutateAsync(orderId || '', {
              onSuccess: () => navigate('/orders'),
            })
          }}
        >
          {t('Cancel Order')}
        </Button>
      ),
    },
    //Reactivate Order
    {
      setIsOpen: setIsReactivateDialogOpen,
      isOpen: isReactivateDialogOpen,
      title: t('Reactivate this order'),
      content: (
        <DialogContent>
          {t('Are you sure you want to reactivate this order?')}
        </DialogContent>
      ),
      actions: (
        <Button
          type="primary"
          onClick={() => {
            reactivateOrder.mutateAsync(orderId || '')
            return setIsReactivateDialogOpen(false)
          }}
        >
          {t('Reactivate Order')}
        </Button>
      ),
    },
  ]

  return (
    <Layout
      title={t('Create Order')}
      description={t('Create a new order contract for an organization.')}
      breadcrumbs={[
        { name: t('Orders'), route: '../' },
        { name: t('Create Order') },
      ]}
      controls={[
        orderQuery.data?.orderStatus?.title === OrderStatusTitles.CANCELLED
          ? {
              label: t('Reactivate Order'),
              onClick: () => setIsReactivateDialogOpen(true),
              disabled: !orderId,
            }
          : {
              label: t('Cancel Order'),
              onClick: () => setIsCancelDialogOpen(true),
              disabled: !orderId,
            },
      ]}
    >
      <FormContainer>
        <WizardForm
          formName={t('Order Form')}
          // need to resolve to undefined if false for the wizard form the render one navigation warning dialog at a time
          disableNavWarning={isCancelDialogOpen || undefined}
          // TODO: uncomment when a form's status is changed by the backend when editing pre-confirmed orders
          // need to determine when an existing form is being edited and when it is being created for the first time
          // isEditForm={
          //   orderQuery.data?.orderStatus?.title === OrderStatusTitles.IN_PROCESS
          // }
        >
          {[
            {
              label: t('Basic Information'),
              icon: 'shopping-cart',
              component: <BasicInformation />,
            },
            {
              label: t('Products'),
              icon: 'devices',
              component: <Products />,
            },
            {
              label: t('Shipping'),
              icon: 'module',
              component: <ShippingInformation />,
            },
            {
              label: t('Service Details'),
              icon: 'radio',
              component: <ServiceDetails />,
            },
            {
              label: t('Summary'),
              icon: 'disclaimers',
              component: <Summary />,
            },
          ]}
        </WizardForm>
      </FormContainer>
      <ConfirmationDialogs confirmations={confirmations} />
    </Layout>
  )
}

export default CreateOrder

const FormContainer = tw.div`flex flex-grow`

const DialogContent = tw.div`mb-8 pr-6`
