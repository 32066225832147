import React from 'react'
import { LoadingIcon } from 'elements'
import { Empty } from 'atlas'
import { FadeInSlideDown } from 'animations'
import { useSubscriberVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import SubscriberArchivedVitalSignCard from './SubscriberArchivedVitalSignCard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'
import { assignmentsToDisplayAssignments } from 'utils'

const SubscriberArchivedVitalSignsGrid = () => {
  const { subscriberId } = useParams()

  const {
    isLoading,
    data: vitalAssignments,
  } = useSubscriberVitalAssignmentsQuery(subscriberId)

  const { t } = useTranslation()

  const archivedVitalSigns = vitalAssignments?.assignedVitalSigns.filter(
    (vitalAssignment) => vitalAssignment.archived
  )

  if (isLoading) return <LoadingIcon />

  // If no vitalAssignments are returned show empty grid placeholder
  if (!archivedVitalSigns?.length || !vitalAssignments)
    return (
      <VitalSignsEmpty
        title={t('No Archived Vital Sign Assignments Found')}
        delay={0.1}
      />
    )

  return (
    <ArchivedVitalSigns>
      {assignmentsToDisplayAssignments(archivedVitalSigns).map(
        (vitalAssignment, index) => (
          <FadeInSlideDown
            delay={0.1 + index * 0.05}
            key={`vitals-card-${index}`}
          >
            <SubscriberArchivedVitalSignCard
              vitalAssignment={vitalAssignment}
              vitalAssignments={vitalAssignments}
            />
          </FadeInSlideDown>
        )
      )}
    </ArchivedVitalSigns>
  )
}
export default SubscriberArchivedVitalSignsGrid

const VitalSignsEmpty = tw(Empty)`border-2 border-dashed rounded flex-grow`

const ArchivedVitalSigns = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
