import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { typedOrgsApi } from 'utils'

const useDeactivateApplicationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/applications/{applicationId}/deactivate'],
    string
  >({
    mutationFn: deactivateApplication,
    successMsg: t('Application deactivated'),
    additionalCachesToInvalidate: ['getModules'],
    optimisticUpdates: [
      {
        cacheKey: 'getApplications',
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/api/applications'],
          string
        >((oldCache, applicationId) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items?.map((application) => {
                  if (application.id === applicationId)
                    return {
                      ...application,
                      activeInfo: {
                        ...application.activeInfo,
                        active: false,
                      },
                    }

                  return application
                }),
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useDeactivateApplicationMutation

const deactivateApplication = (applicationId: string) =>
  typedOrgsApi.post('/api/applications/{applicationId}/deactivate', {
    pathParams: { applicationId },
  })
