import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { isPresent, ordersApi } from 'utils'

const useUpdateContractMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/organizations/{organizationId}/contracts'],
    updateContractArgs
  >({
    mutationFn: updateContract,
    successMsg: t('Contract updated'),
    // TODO: replace with Optimistic Update
    additionalCachesToInvalidate: [
      ({ organizationId }) => ['getContracts', organizationId],
      ['getContracts', undefined],
    ],
    optimisticUpdates: [
      {
        cacheKey: ({ contractId }) => ['getContract', contractId],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}'],
          updateContractArgs
        >((oldCache, { contractForm, organizationId }) => ({
          ...oldCache,
          ...contractForm,
          organization: { id: organizationId },
        })),
      },
    ],
  })

  return mutation
}

export default useUpdateContractMutation

type updateContractArgs = {
  contractForm: ContractForm
  organizationId: string
  contractId: string
}

const updateContract = async ({
  contractForm,
  organizationId,
  contractId,
}: updateContractArgs) =>
  ordersApi.patch('/organizations/{organizationId}/contracts/{contractId}', {
    pathParams: { organizationId, contractId },
    body: Object.entries(contractForm)
      .filter((field): field is [string, Record<string, unknown>] =>
        isPresent(field[1])
      )
      .map(([fieldKey, fieldValue]) => ({
        value: fieldValue,
        path: `/${fieldKey}`,
        op: 'replace',
      })),
  })
