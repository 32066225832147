import React from 'react'
import { FadeInSlideDown } from 'animations'
import { Alert, Button } from 'atlas'
import { useDelay } from 'hooks'
import { useProvisioningCompleteMutation } from 'hooks/provisioning'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type ProvisionCompleteCallToActionProps = {
  setShowCallToAction: (newValue: boolean) => void
  orderId: string
}

const ProvisionCompleteCallToAction = ({
  setShowCallToAction,
  orderId,
}: ProvisionCompleteCallToActionProps) => {
  const delay = useDelay()

  const { t } = useTranslation()

  const provisioningComplete = useProvisioningCompleteMutation()

  return (
    <FadeInSlideDown delay={delay()}>
      <Alert type="success">
        <CallToActionContainer>
          <Title>{t('Provisioning Complete')}</Title>
          <Content>
            {`${t(
              'All Items needed for provisioning have been provisioned'
            )}. ${t(
              //TODO: change back to this text when Shipping is added: 'To schedule order delivery, please click on the button below'
              'To mark order as provisioning completed, please click on the button below'
            )}.`}
          </Content>
          <Button
            type={'success-filled'}
            onClick={() => {
              provisioningComplete.mutate({ orderId })
              setShowCallToAction(false)
            }}
          >
            {
              //TODO: change back to this text when Shipping is added: 'Continue to Shipping'
              t('Complete Provisioning')
            }
          </Button>
        </CallToActionContainer>
      </Alert>
    </FadeInSlideDown>
  )
}

export default ProvisionCompleteCallToAction

const CallToActionContainer = tw.div`py-2`

const Title = tw.h1`text-lg font-bold color[inherit]`

const Content = tw.p`pb-4 color[inherit]`
