import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { List, LoadingIcon, EllipsisDropdown, CustomSelect } from 'elements'
import { useDisclaimerTypesQuery } from 'hooks/disclaimers'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import i18next from 'i18next'
import { useEntityLabelSort } from 'hooks/react-table'
import { useModulesQuery } from 'hooks/access-control/modules'
import { DisclaimerTypeForm } from '.'
import tw from 'twin.macro'

type DisclaimerTypesListProps = {
  setIsDisclaimerTypeFormOpen: Dispatch<SetStateAction<boolean>>
  isDisclaimerTypeFormOpen: boolean
}

const DisclaimerTypesList = ({
  setIsDisclaimerTypeFormOpen,
  isDisclaimerTypeFormOpen,
}: DisclaimerTypesListProps) => {
  const { data: disclaimerTypesResponse, isLoading } = useDisclaimerTypesQuery()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['name'],
  })

  const [selectedModule, setSelectedModule] = useState<Module | undefined>()
  const [isEditForm, setIsEditForm] = useState<boolean>(false)

  const [disclaimerType, setDisclaimerType] = useState<
    DisclaimerType | undefined
  >()

  const { data: modules } = useModulesQuery()

  // whenever modules loads set selectedModule to the first one
  useEffect(
    () =>
      setSelectedModule(
        modules?.items
          // Only display unarchived modules
          ?.filter((module) => !module.archiveInfo.archived)[0]
      ),
    [modules]
  )
  useEffect(() => {
    if (isDisclaimerTypeFormOpen && !isEditForm) setDisclaimerType(undefined)
    setIsEditForm(false)
  }, [isDisclaimerTypeFormOpen])

  const tableData: DisclaimerType[] = useMemo(() => {
    return (
      (selectedModule &&
        disclaimerTypesResponse?.items
          // Only show disclaimers belonging to selected module
          ?.filter((disclaimerType) =>
            disclaimerType.code.startsWith(selectedModule.name)
          )
          .map((disclaimerType) => ({
            ...disclaimerType,
            // remove the module name from the beginning of the disclaimerType code
            code: disclaimerType.code.slice(selectedModule.name.length),
          }))) ||
      []
    )
  }, [disclaimerTypesResponse, selectedModule])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (
      rows: Row<DisclaimerType>[],
      columnIDs: string[],
      globalFilter: string
    ) => {
      return rows.filter((row) => {
        const name = row.original.name.toLowerCase()
        const code = row.original.code.toLowerCase()

        return (
          name.includes(globalFilter.toLowerCase()) ||
          code.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<DisclaimerType>[] = useMemo(
    () => [
      {
        id: 'NAME',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('NAME'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.name}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortType: primaryLabelSort,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => (
          <ActionsCell
            row={row}
            onEdit={() => {
              setIsEditForm(true)
              setDisclaimerType(row)
              setIsDisclaimerTypeFormOpen(true)
            }}
          />
        ),
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language, selectedModule]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (e) => navigate(e.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'NAME',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  if (isLoading || !modules?.items) return <LoadingIcon />

  return (
    <>
      <List
        baseDelay={0.15}
        tableInstance={tableInstance}
        searchPlaceholder={t('Search disclaimer types')}
        emptyTitle={t('No Disclaimer Types Found')}
        emptyDescription={t(
          'Try changing your search term or contact your administrator'
        )}
        controls={
          <CustomSelect
            className="w-60"
            value={selectedModule?.id}
            onChange={(e) =>
              setSelectedModule(
                modules.items?.find((module) => module.id === e.value)
              )
            }
            options={modules.items
              // Only display unarchived modules
              .filter((module) => !module.archiveInfo.archived)
              .map((module) => ({
                value: module.id,
                label: module.name,
              }))}
          />
        }
      />
      <DisclaimerTypeForm
        disclaimerType={disclaimerType}
        modules={modules.items}
        defaultModule={selectedModule}
        isFormOpen={isDisclaimerTypeFormOpen}
        setIsFormOpen={setIsDisclaimerTypeFormOpen}
      />
    </>
  )
}

export default DisclaimerTypesList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: DisclaimerType
  onEdit: () => void
}

const ActionsCell = ({ row, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Manage'),
            onClick: () => {
              navigate(row.id)
            },
          },
          {
            label: t('Edit'),
            onClick: () => {
              onEdit()
            },
          },
        ]}
        testid={`${row.name}-ellipsis`}
      />
    </div>
  )
}
