import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { handleQueryError, vitalsApi } from 'utils'
import { success } from 'utils/toast'

const useCreateDisclaimerMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['POST']['/disclaimers'],
    KyError<ErrorResponse>,
    CreateDisclaimerProps,
    VitalsAPIResponse['GET']['/disclaimers'] | undefined
  >(createDisclaimer, {
    onMutate: ({ disclaimerTypeId, newDisclaimer }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/disclaimers']
      >(['getDisclaimers', undefined, undefined, disclaimerTypeId])

      // optimistic update
      queryCache.setQueryData<VitalsAPIResponse['GET']['/disclaimers']>(
        ['getDisclaimers', undefined, 'en-US', disclaimerTypeId],
        (oldDisclaimers) => {
          return {
            items: [
              ...(oldDisclaimers?.items || []),
              {
                ...newDisclaimer,
                id: '',
                createdAt: '',
                createdBy: '',
              },
            ],
          }
        }
      )

      return snapshot
    },
    onSuccess: () => {
      // Notify user of successful disclaimer creation
      success({
        message: t('Disclaimer created'),
      })
    },
    onError: async (error, { disclaimerTypeId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/disclaimers'] | undefined
      >(['getDisclaimers', undefined, undefined, disclaimerTypeId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { disclaimerTypeId }) => {
      // Refetch from API to make sure the updated disclaimer is in sync with the server
      queryCache.invalidateQueries([
        'getDisclaimers',
        undefined,
        undefined,
        disclaimerTypeId,
      ])
    },
  })

  return mutation
}

export default useCreateDisclaimerMutation

type CreateDisclaimerProps = {
  newDisclaimer: DisclaimerForm
  disclaimerTypeId: string
}

// Post new disclaimer to backend
const createDisclaimer = async ({ newDisclaimer }: CreateDisclaimerProps) => {
  const request: VitalsAPIRequest['POST']['/disclaimers']['body'] = newDisclaimer

  const result = await vitalsApi
    .post(`disclaimers`, {
      json: request,
    })
    .json<VitalsAPIResponse['POST']['/disclaimers']>()
  return result
}
