import useMutation from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useCreateAneltoSubscriberMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/organizations/{organizationId}/orders/{orderId}/anelto-subscribers'],
    createAneltoSubscriberArgs
  >({
    mutationFn: createAneltoSubscriber,
    successMsg: {
      message: t('Anelto Subscriber Created'),
      showOn: 'onSuccess',
    },
    additionalCachesToInvalidate: [({ orderId }) => ['order', { orderId }]],
  })

  return mutation
}

export default useCreateAneltoSubscriberMutation

type createAneltoSubscriberArgs = {
  organizationId: string
  orderId: string
  aneltoSub: AneltoSubscriber
}

const createAneltoSubscriber = ({
  organizationId,
  orderId,
  aneltoSub,
}: createAneltoSubscriberArgs) =>
  ordersApi.post(
    '/organizations/{organizationId}/orders/{orderId}/anelto-subscribers',
    {
      pathParams: { organizationId, orderId },
      body: aneltoSub,
    }
  )
