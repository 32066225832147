import React, { useState } from 'react'
import { Button } from 'atlas'
import {
  Layout,
  Statistic,
  ConfirmationDialogs,
  EntityStatusChip,
} from 'elements'
import { FadeInSlideDown } from 'animations'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDelay, useNavigate } from 'hooks'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import {
  ModulePermissionsList,
  AssignedApplicationsList,
  CreateEditModuleForm,
} from 'components/access-control/modules'
import {
  useModulesQuery,
  useActivateModuleMutation,
  useDeactivateModuleMutation,
  useArchiveModuleMutation,
  useRestoreModuleMutation,
  useDeleteModuleMutation,
} from 'hooks/access-control/modules'
import tw from 'twin.macro'

const AccessControlModuleDetails = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const delay = useDelay()
  const { moduleid } = useParams()

  const [isEditModuleFormOpen, setIsEditModuleFormOpen] = useState<boolean>(
    false
  )
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { data: modules, isLoading: isLoadingModules } = useModulesQuery()
  const { mutate: activateModule } = useActivateModuleMutation()
  const { mutate: deactivateModule } = useDeactivateModuleMutation()
  const { mutate: archiveModule } = useArchiveModuleMutation()
  const { mutate: restoreModule } = useRestoreModuleMutation()
  const { mutate: deleteModule } = useDeleteModuleMutation()
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)

  const currentModule = modules?.items?.find((module) => module.id === moduleid)

  const isModuleActive = currentModule?.activeInfo.active
  const isModuleArchived = currentModule?.archiveInfo.archived

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      title: t(`Archive "${currentModule?.name}" module`),
      content: <p>{t('Are you sure you want to archive this module?')}</p>,
      actions: (
        <Button
          onClick={() => {
            archiveModule({ moduleId: currentModule?.id || '' })
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      title: t(`Deactivate "${currentModule?.name}" module`),
      content: <p>{t('Are you sure you want to deactivate this module?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deactivateModule({ moduleId: currentModule?.id || '' })
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      title: t(`Activate "${currentModule?.name}" module`),
      content: <p>{t('Are you sure you want to activate this module?')}</p>,
      actions: (
        <Button
          onClick={() => {
            activateModule({ moduleId: currentModule?.id || '' })
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${currentModule?.name}" module`),
      content: <p>{t('Are you sure you want to restore this module?')}</p>,
      actions: (
        <Button
          onClick={() => {
            restoreModule({ moduleId: currentModule?.id || '' })
            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${currentModule?.name}" module`),
      content: <p>{t('Are you sure you want to delete this module?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deleteModule(
              { moduleId: currentModule?.id || '' },
              {
                onSuccess: () => {
                  navigate('/access-control/modules')
                },
              }
            )
            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <Layout
      title={
        <div className="flex items-center gap-2 font-bold">
          <h1 className="text-2xl">{currentModule?.name}</h1>{' '}
          <EntityStatusChip
            isActive={!!isModuleActive}
            isArchived={!!isModuleArchived}
          />
        </div>
      }
      description={currentModule?.description}
      isLoading={isLoadingModules}
      isUnauthorized={
        !currentModule ||
        !accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly')
      }
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Modules'), route: '/access-control/modules' },
        { name: currentModule?.name || '-' },
      ]}
      controls={
        // acm.admin required for these actions
        accountPermissions?.data.ecgAdmin.permissions.includes('acm.admin')
          ? [
              ...(isModuleArchived
                ? [
                    {
                      label: t('Restore'),
                      onClick: () => setIsRestoreDialogOpen(true),
                    },
                    {
                      label: t('Delete'),
                      onClick: () => setIsDeleteDialogOpen(true),
                    },
                  ]
                : [
                    {
                      label: t('Edit'),
                      onClick: () => setIsEditModuleFormOpen(true),
                    },
                    ...(isModuleActive
                      ? [
                          {
                            label: t('Deactivate'),
                            onClick: () => setIsDeactivateDialogOpen(true),
                          },
                        ]
                      : [
                          {
                            label: t('Activate'),
                            onClick: () => setIsActivateDialogOpen(true),
                          },
                        ]),
                    {
                      label: t('Archive'),
                      onClick: () => setIsArchiveDialogOpen(true),
                    },
                  ]),
            ]
          : null
      }
    >
      {/* Statistics */}
      <StatisticsContainer>
        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="application"
            iconColor="green"
            label={t('Assigned Applications')}
            value={currentModule?.applications?.length || 0}
          />
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <Statistic
            icon="permissions"
            iconColor="yellow"
            label={t('Module Permissions')}
            value={currentModule?.permissions?.length || 0}
          />
        </FadeInSlideDown>
      </StatisticsContainer>

      <ModulePermissionsList
        delay={delay}
        data={
          currentModule?.permissions?.filter(
            (permission) => !permission.archiveInfo.archived
          ) || []
        }
        currentModule={currentModule}
        disabled={isModuleArchived}
      />

      <AssignedApplicationsList
        delay={delay}
        data={currentModule?.applications}
        currentModule={currentModule}
        disabled={isModuleArchived}
      />

      <CreateEditModuleForm
        isFormOpen={isEditModuleFormOpen}
        setIsFormOpen={setIsEditModuleFormOpen}
        currentModule={currentModule}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </Layout>
  )
}

export default AccessControlModuleDetails

const StatisticsContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-8`
