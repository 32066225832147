import { useQuery } from 'react-query'
import { orgsApi } from 'utils'

const useGlobalMemberStatistics = () => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/member-statistics'],
    KyError
  >('getGlobalMemberStatistics', getGlobalMemberStatistics)

  return query
}

export default useGlobalMemberStatistics

const getGlobalMemberStatistics = async () => {
  const result = orgsApi
    .get(`api/member-statistics`)
    .json<OrgsAPIResponse['GET']['/api/member-statistics']>()

  return result
}
