import React, { useState } from 'react'
import { motion } from 'framer-motion'
import tw, { styled } from 'twin.macro'
import Icon from '../elements/Icon'

type PinProps = {
  children?: React.ReactNode
  className?: string
  onClick: () => void
  style?: React.CSSProperties
  disable?: boolean
  pinned: boolean
  testId?: string
}

const PinIcon = styled(Icon)<{ filled: boolean }>`
  ${tw`ml-2 text-lg`}
  ${({ filled }) => {
    if (filled) return tw`text-blue-500 transform -rotate-45`
  }}
`

const Pin = ({ className, onClick, pinned, testId }: PinProps) => {
  const [isFilled, setIsFilled] = useState<boolean>(pinned)

  return (
    <motion.div
      key={testId}
      className={className}
      whileHover={
        !isFilled
          ? {
              scale: 1.4,
              rotateZ: -45,
              translateY: 1.5,
              color: '#60a5fa',
            }
          : { scale: 1.4, rotateZ: 0, translateY: -2, translateX: -1.5 }
      }
      onTap={() => {
        setIsFilled(!isFilled)
        onClick()
      }}
    >
      <PinIcon
        data-testid={`${testId}-${pinned}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
        filled={isFilled}
        type={isFilled ? 'fill-pin' : 'outline-pin'}
      />
    </motion.div>
  )
}

export default Pin
