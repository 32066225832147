import React, { useMemo, useCallback, useState } from 'react'
import { List, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Chip, Button, Tooltip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useEntityLabelSort, useStatusSort } from 'hooks/react-table'
import clsx from 'clsx'
import {
  useDeactivateWebhookSubscriptionMutation,
  useActivateWebhookSubscriptionMutation,
  useArchiveWebhookSubscriptionMutation,
} from 'hooks/settings-webhooks'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

type WebhooksListProps = {
  webhookSubscriptions: Array<WebhookSubscription>
}

const WebhooksList = ({ webhookSubscriptions }: WebhooksListProps) => {
  const { t } = useTranslation()
  const modeSort = useEntityLabelSort<WebhookSubscription>({
    accessor: (row) => (row.isDevelopment ? 'dev' : undefined),
  })
  const navigate = useNavigate()
  const statusSort = useStatusSort()
  const tableData: WebhookSubscription[] = useMemo(() => {
    return webhookSubscriptions
  }, [webhookSubscriptions])
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['postUri'],
  })

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (
      rows: Row<WebhookSubscription>[],
      columnIDs: string[],
      globalFilter: string
    ) => {
      return rows.filter((row) => {
        const url = row.original.postUri.toLowerCase()
        const version = row.original.version.toLowerCase()
        const errors = '' + row.original.errors

        return (
          url.includes(globalFilter.toLowerCase()) ||
          version.includes(globalFilter.toLowerCase()) ||
          errors.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<WebhookSubscription>[] = useMemo(
    () => [
      {
        id: 'URL',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('URL'),
        accessor: (row) => <PrimaryColumnText>{row.postUri}</PrimaryColumnText>,
        sortType: primaryLabelSort,
        width: '1.5fr',
      },
      {
        id: 'EVENTS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('EVENTS'),
        accessor: (row) => {
          return (
            <InfoTooltip
              content={
                <TooltipText>
                  {row.events.map(
                    (event, index) =>
                      `${event.name}${
                        row.events.length < index + 1 ? '.' : ', '
                      }`
                  )}
                </TooltipText>
              }
            >
              <WebhookEvent>
                {row.events.map((event, index) => (
                  <span key={event.id}>{`${event.name}${
                    row.events.length < index + 1 ? '.' : ', '
                  }`}</span>
                ))}
              </WebhookEvent>
            </InfoTooltip>
          )
        },
        width: '2fr',
      },
      {
        id: 'VERSION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('VERSION'),
        accessor: 'version',
        width: '100px',
      },
      {
        id: 'MODE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('MODE'),
        accessor: (row) =>
          row.isDevelopment ? (
            <Chip color="cyan">{t('DEV')}</Chip>
          ) : (
            <Chip color="green">{t('LIVE')}</Chip>
          ),
        sortType: modeSort,
        width: '100px',
      },
      {
        id: 'ERRORS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('ERRORS'),
        accessor: (row) => (
          <p
            className={clsx(
              'font-semibold',
              row.errors === 0 ? 'text-emerald-400' : 'text-red-400'
            )}
          >
            {row.errors}
          </p>
        ),
        width: '100px',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: (row) =>
          row.activeInfo.active ? (
            <Chip color="green">{t('Active')}</Chip>
          ) : (
            <Chip color="red">{t('Inactive')}</Chip>
          ),
        sortType: statusSort,
        width: '80px',
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => <ActionsCell row={row} />,
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      onRowClick: (row) => navigate(row.original.id),

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'URL',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={`${t('Search')} ${tableInstance.rows.length} ${t(
        'webhooks'
      )}`}
      emptyTitle={t('No Data Found')}
      emptyDescription={t(
        'No webhooks have been created for this organization'
      )}
    />
  )
}

export default WebhooksList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

type ActionsCellProps = {
  row: WebhookSubscription
}

const ActionsCell = ({ row }: ActionsCellProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    mutate: deactivateWebhookSubscription,
  } = useDeactivateWebhookSubscriptionMutation()
  const {
    mutate: activateWebhookSubscription,
  } = useActivateWebhookSubscriptionMutation()

  const {
    mutate: archiveWebhookSubscription,
  } = useArchiveWebhookSubscriptionMutation()

  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(
    false
  )
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(
    false
  )
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)

  const confirmations = [
    // Deactivate Dialog
    {
      setIsOpen: setIsDeactivateDialogOpen,
      isOpen: isDeactivateDialogOpen,
      //minification will remove all character before ':/'
      title: t('Deactivate "') + row.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to deactivate this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deactivateWebhookSubscription({ webhookSubscription: row })
            return setIsDeactivateDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Deactivate')}
        </Button>
      ),
    },
    // Activate Dialog
    {
      setIsOpen: setIsActivateDialogOpen,
      isOpen: isActivateDialogOpen,
      //minification will remove all character before ':/'
      title: t('Activate "') + row.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to activate this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            activateWebhookSubscription({ webhookSubscription: row })
            return setIsActivateDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Activate')}
        </Button>
      ),
    },
    // Archive Dialog
    {
      setIsOpen: setIsArchiveDialogOpen,
      isOpen: isArchiveDialogOpen,
      //minification will remove all character before ':/'
      title: t('Archive "') + row.postUri + t('" webhook'),
      content: <p>{t('Are you sure you want to archive this webhook?')}</p>,
      actions: (
        <Button
          onClick={() => {
            archiveWebhookSubscription({
              organizationId: row.organizationId,
              subscriptionId: row.id,
            })
            return setIsArchiveDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Archive')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Manage'),
            onClick: () => {
              navigate(row.id)
            },
          },
          {
            label: row.activeInfo.active ? t('Deactivate') : t('Activate'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDropdownVisible(false)
              if (row.activeInfo.active) return setIsDeactivateDialogOpen(true)
              setIsActivateDialogOpen(true)
            },
          },
          {
            label: t('Archive'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsArchiveDialogOpen(true)
              return setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.postUri}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}

const InfoTooltip = tw(Tooltip)`ml-2  h-fit-content `

const TooltipText = tw.p`w-72`

const WebhookEvent = tw.p`truncate w-96`
