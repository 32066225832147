import _ from 'lodash'
import { TableInstance, Row } from 'react-table'
import { isPresent } from 'utils'

const useEntityLabelFilter = <
  T extends Record<string, unknown> = Record<string, unknown>
>() => {
  // grabs all the unique values in the tableInstance for the provided property of row
  const getEntityLabels = ({
    tableInstance,
    accessor,
  }: {
    tableInstance: TableInstance<T>
    accessor: (row: T) => string | undefined
  }): TableCategory[] =>
    _.uniq(tableInstance.preFilteredRows.map((row) => accessor(row.original)))
      // Make sure the accessed value is truthy to remove empty values and cast to String
      .filter(isPresent)
      .map((label) => ({ value: label, label }))

  // filters out rows by category (id) using filterValue
  const filterRowsByEntityLabel = (
    rows: Row<T>[],
    id: string[],
    filterValues: TableCategory[]
  ) => {
    // if no filter selected, show everything
    if (filterValues.length === 0) return rows

    // filter out any rows that don't include one of the filtered values
    return rows.filter((row) => {
      // get the name prop that was sent to the EntityLabel component as the rowValue
      const rowValue = row.values[id[0]].props?.name
      return filterValues
        .map((filterValue) => filterValue.value)
        .includes(rowValue)
    })
  }

  return {
    getEntityLabels,
    filterRowsByEntityLabel,
  }
}

export default useEntityLabelFilter
