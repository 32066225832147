import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { success, handleQueryError, typedOrgsApi } from 'utils'

const usePasswordMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/people/{personId}/change-password'],
    KyError,
    changePasswordArgs
  >(changePassword, {
    onMutate: (data) => {
      return data
    },
    onSuccess: () => {
      // notify user
      success({ message: t('Password updated') })
    },
    onError: (error) => handleQueryError({ error }),
  })

  return mutation
}

export default usePasswordMutation

type changePasswordArgs = UpdatePasswordForm & { personId: string }

const changePassword = async ({
  personId,
  previousPassword,
  proposedPassword,
  accessToken,
}: changePasswordArgs) =>
  typedOrgsApi.post('/api/people/{personId}/change-password', {
    pathParams: { personId },
    body: { accessToken, previousPassword, proposedPassword },
  })
