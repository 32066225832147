import { useQuery } from 'react-query'
import { typedOrgsApi } from 'utils'

const useStateCodesQuery = (countryCode: string) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/seed-data/state-codes'],
    KyError
  >(['getStateCodes', countryCode], () => getStateCodes(countryCode), {
    enabled: !!countryCode,
  })

  return query
}

export default useStateCodesQuery

const getStateCodes = (countryCode: string) =>
  typedOrgsApi.get('/api/seed-data/state-codes', {
    searchParams: { countryCode },
  })
