import { orgsApi } from 'utils'
import { useQuery } from 'react-query'

const useAdminListQuery = () => {
  const query = useQuery<GetAdminListResponse, KyError>(
    'getAdminList',
    getAdminList
  )

  return query
}

export default useAdminListQuery

const searchParams = [
  ['order_by', 'ASC'],
  ['take', '10000'],
]

// get list of admins the current role has access to
type getAdminList = () => Promise<GetAdminListResponse>
const getAdminList: getAdminList = async () => {
  const result = await orgsApi
    .get(`api/ecg-admins`, {
      searchParams,
    })
    .json<GetAdminListResponse>()
  return result
}
