import React, { useEffect, useState } from 'react'
import {
  RightPopup,
  TextField,
  Select,
  SelectItem,
  LoadingIcon,
  Checkbox,
  SimpleList,
  Icon,
} from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form'
import {
  useAddPolicyMutation,
  useUpdatePolicyMutation,
} from 'hooks/access-control/policies'
import { useModulesQuery } from 'hooks/access-control/modules'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'

type CreateEditPolicyFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  policy?: Policy
}

const CreateEditPolicyForm = ({
  isFormOpen,
  setIsFormOpen,
  policy,
}: CreateEditPolicyFormProps) => {
  const { data: modules, isLoading: isLoadingModules } = useModulesQuery()
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  )
  const [action, setAction] = useState<'add-another' | 'close'>()

  const { t } = useTranslation()
  const formMethods = useForm<{
    selectedModuleId: string
    policyFields: CreatePolicyForm
    availablePermissions: {
      values: { checked: boolean; permission: Permission }
    }[]
    internalUseOnly: boolean
  }>({ defaultValues: { availablePermissions: [] } })
  const {
    handleSubmit,
    errors,
    reset,
    watch,
    setValue,
    control,
    clearErrors,
  } = formMethods

  const { fields } = useFieldArray({
    control,
    name: 'availablePermissions',
  })

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset({
        policyFields: {
          name: policy?.name || '',
          code: policy?.code || '',
          description: policy?.description || '',
        },
      })
      setSelectedPermissions(
        policy?.permissions?.map((permission) => {
          const permissionModule = modules?.items?.find((module) =>
            module.permissions?.find(
              (modPermission) => modPermission.id === permission.id
            )
          )
          return {
            ...permission,
            // Add module prop to initial permissions so they can immediately be reshown in the list for a selected module
            ...(permissionModule ? { module: permissionModule } : {}),
          }
        }) || []
      )
    }
  }, [isFormOpen])

  const {
    mutate: addPolicy,
    isLoading: isAddPolicyLoading,
  } = useAddPolicyMutation()

  const {
    mutate: updatePolicy,
    isLoading: isUpdatePolicyLoading,
  } = useUpdatePolicyMutation()

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={
        policy
          ? policy.archiveInfo.archived
            ? t('Edit Archived Policy')
            : t('Edit Policy')
          : t('Create Policies')
      }
      controls={
        <>
          {/* Create & Add Another Button (only shown for creating policies) */}
          {!policy ? (
            <>
              <Button
                type="primary-filled"
                disabled={action !== 'add-another' && isAddPolicyLoading}
                isLoading={action === 'add-another' && isAddPolicyLoading}
                onClick={handleSubmit((formData) => {
                  setAction('add-another')
                  addPolicy(
                    {
                      ...formData.policyFields,
                      permissions: selectedPermissions.map(
                        (permission) => permission.id
                      ),
                    },
                    {
                      onSuccess: () => {
                        // reset the form (but don't close the form)
                        reset({
                          policyFields: {
                            name: '',
                            code: '',
                            description: '',
                          },
                          selectedModuleId: '',
                        })
                        setSelectedPermissions([])
                      },
                    }
                  )
                })}
              >
                {t('Create & Add Another')}
              </Button>
              &nbsp;
            </>
          ) : null}
          {/* Create & Close / Update Button (shown for both) */}
          {policy ? (
            <Button
              type="primary-filled"
              isLoading={isUpdatePolicyLoading}
              onClick={handleSubmit((formData) => {
                updatePolicy(
                  {
                    policyData: {
                      ...formData.policyFields,
                      permissions: selectedPermissions.map(
                        (permission) => permission.id
                      ),
                    },
                    existingPolicy: policy,
                  },
                  { onSuccess: () => setIsFormOpen(false) }
                )
              })}
            >
              {t('Update')}
            </Button>
          ) : (
            <Button
              type="primary-filled"
              disabled={action !== 'close' && isAddPolicyLoading}
              isLoading={action === 'close' && isAddPolicyLoading}
              onClick={handleSubmit((formData) => {
                setAction('close')
                addPolicy(
                  {
                    ...formData.policyFields,
                    permissions: selectedPermissions.map(
                      (permission) => permission.id
                    ),
                  },
                  { onSuccess: () => setIsFormOpen(false) }
                )
              })}
            >
              {t('Create & Close')}
            </Button>
          )}
          &nbsp;
          {/* Cancel Button (shown for both) */}
          <Button
            type="secondary"
            disabled={isAddPolicyLoading || isUpdatePolicyLoading}
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {isLoadingModules || !modules ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Policy Name is required') }}
              name="policyFields.name"
              label={t('Policy Name')}
              fullWidth
              error={errors.policyFields?.name?.message}
              // if policy is archived, disable mutation
              disabled={policy?.archiveInfo.archived}
              required
            />

            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Policy Code is required') }}
              name="policyFields.code"
              label={t('Policy Code')}
              fullWidth
              error={errors.policyFields?.code?.message}
              // if policy is archived, disable mutation
              disabled={policy?.archiveInfo.archived}
              required
            />

            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              name="policyFields.description"
              label={t('Description')}
              fullWidth
              multiline
              rows={4}
              error={errors.policyFields?.description?.message}
              // if policy is archived, disable mutation
              disabled={policy?.archiveInfo.archived}
            />

            <Controller
              control={control}
              defaultValue={policy?.internalUseOnly}
              render={({ onChange, value, ref }) => (
                <Checkbox
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                  ref={ref}
                  label="Internal Use Only"
                  className="mb-6"
                  disabled={!!policy}
                />
              )}
              id="policyFields.internalUseOnly"
              name="policyFields.internalUseOnly"
            />

            {
              // If policy is archived don't show Add Permissions
              !policy?.archiveInfo.archived ? (
                <AvailabilityTitle>
                  {t('Available Permissions')}
                </AvailabilityTitle>
              ) : null
            }
            {modules.items && modules.items.length > 0 ? (
              <>
                {
                  // If policy is archived don't show UI to Add Permissions
                  !policy?.archiveInfo.archived ? (
                    <>
                      <Controller
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            name={name}
                            value={value}
                            label="Module"
                            variant="outlined"
                            className="mb-6"
                            onChange={(e) => {
                              const selectedModule = modules.items?.find(
                                (module) => module.id === e.target.value
                              )
                              // Set field array of checkboxes for available permissions for selected module
                              setValue(
                                'availablePermissions',
                                // pull out all items that are already in selectedPermissions
                                _.differenceBy(
                                  selectedModule?.permissions || [],
                                  selectedPermissions,
                                  'id'
                                )
                                  // Only display active permissions
                                  .filter(
                                    (permission) => permission.activeInfo.active
                                  )
                                  .map((permission) => ({
                                    values: {
                                      checked: false,
                                      permission: {
                                        ...permission,
                                        module: selectedModule,
                                      },
                                    },
                                  }))
                              )
                              onChange(e)
                            }}
                            ref={ref}
                          >
                            {modules.items
                              // Only display unarchived modules
                              ?.filter((module) => !module.archiveInfo.archived)
                              .map((module, index) => (
                                <SelectItem value={module.id} key={index}>
                                  {module.name}
                                </SelectItem>
                              ))}
                          </Select>
                        )}
                        name="selectedModuleId"
                        defaultValue=""
                      />
                      <ListCard>
                        {fields.length > 0 ? (
                          _.sortBy(fields, (field) =>
                            _.lowerCase(field.values.permission.name)
                          ).map((avaliablePermission, index) => (
                            <Controller
                              key={avaliablePermission.values.permission.id}
                              name={`availablePermissions[${index}].values`}
                              defaultValue={avaliablePermission.values}
                              render={({
                                onChange,
                                value,
                                name,
                                ref,
                              }: CheckboxRenderArgs<{
                                permission: Permission
                                checked: boolean
                              }>) => {
                                return (
                                  <Checkbox
                                    onChange={(e) => {
                                      onChange({
                                        permission: JSON.parse(e.target.value)
                                          .permission,
                                        checked: e.target.checked,
                                      })
                                    }}
                                    value={value}
                                    checked={!!value?.checked}
                                    ref={ref}
                                    label={
                                      <p>
                                        {
                                          avaliablePermission.values.permission
                                            .name
                                        }{' '}
                                        <CheckboxLabelCode>
                                          (
                                          {
                                            avaliablePermission.values
                                              .permission.code
                                          }
                                          )
                                        </CheckboxLabelCode>
                                      </p>
                                    }
                                    name={name}
                                    className="text-gray-900 py-1 hover:bg-gray-200 px-4"
                                  />
                                )
                              }}
                            />
                          ))
                        ) : (
                          <Empty
                            title={
                              watch('selectedModuleId')
                                ? t('No Permissions Found')
                                : t('No Module Selected')
                            }
                          />
                        )}
                      </ListCard>

                      <PolicyBtn
                        disabled={watch('availablePermissions').every(
                          (avaliablePermission) =>
                            !avaliablePermission.values.checked
                        )}
                        tw="my-4"
                        onClick={() => {
                          // add availablePermissions that are checked to array of selectedPermissions
                          setSelectedPermissions([
                            ...selectedPermissions,
                            ...watch('availablePermissions')
                              .filter(
                                (availablePermission) =>
                                  availablePermission.values.checked
                              )
                              .map(
                                (availablePermission) =>
                                  availablePermission.values.permission
                              ),
                          ])
                          // remove them from availablePermissions
                          setValue(
                            'availablePermissions',
                            watch('availablePermissions').filter(
                              (avaliablePermission) =>
                                !avaliablePermission.values.checked
                            )
                          )
                          // clear any errors related to no assigned permissions
                          clearErrors('permissions')
                        }}
                      >
                        {t('Add Selected Permissions To Policy')}
                      </PolicyBtn>
                    </>
                  ) : null
                }
                {/* SELECTED PERMISSIONS */}
                <>
                  <PermissionsTitle archived={!policy?.archiveInfo.archived}>
                    {t('Selected Permissions')}
                  </PermissionsTitle>
                  <ListCard>
                    {selectedPermissions.length > 0 ? (
                      <SimpleList
                        data={selectedPermissions}
                        animate={false}
                        renderItem={(permission) => (
                          <PermissionsSelect
                            onClick={() => {
                              // remove this permission from selectedPermissions
                              setSelectedPermissions(
                                selectedPermissions.filter(
                                  (selectedPermission) =>
                                    selectedPermission.id !== permission.id
                                )
                              )
                              // add this permission to availablePermissions if its module is selected
                              if (
                                watch('selectedModuleId') ===
                                permission.module.id
                              )
                                setValue('availablePermissions', [
                                  ...watch('availablePermissions'),
                                  { values: { checked: false, permission } },
                                ])
                            }}
                          >
                            <TrashIcon
                              type="trash"
                              data-testid={`${permission.name}-delete`}
                            />
                            <p>
                              {permission.name}{' '}
                              <CheckboxLabelCode>
                                ({permission.code})
                              </CheckboxLabelCode>
                            </p>
                          </PermissionsSelect>
                        )}
                      />
                    ) : (
                      <Empty title={t('No Permissions Selected')} />
                    )}
                  </ListCard>
                </>
              </>
            ) : (
              <Empty
                title={t('No Data Found')}
                description={t(
                  'At least one module must exist in order to assign permissions to a policy'
                )}
                callToAction={
                  <Button type="primary-filled" to="/access-control/modules">
                    {t('Create Modules')}
                  </Button>
                }
              />
            )}
          </form>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default CreateEditPolicyForm

const PolicyBtn = tw(Button)`my-4`

const AvailabilityTitle = tw.p`text-xl mb-2`

const ListCard = tw.div`h-32 overflow-auto bg-gray-50 rounded border border-gray-300`

const CheckboxLabelCode = tw.span`text-gray-500`

const PermissionsTitle = styled.h3<{ archived?: boolean }>(({ archived }) => [
  tw`text-xl mb-2`,
  archived && tw`mt-4`,
])

const PermissionsSelect = tw.div`text-gray-900 py-1 hover:bg-gray-200 px-4 flex items-center cursor-pointer`

const TrashIcon = tw(Icon)`mr-4 text-gray-500 flex-shrink-0`
