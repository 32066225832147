import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import {
  handleQueryError,
  typedOrgsApi,
  success,
  organizationFormToOrganization,
  organizationFormToOrganizationHierarchyItem,
} from 'utils'

const useCreateOrganizationMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/organizations'],
    KyError,
    CreateOrganizationProps,
    OrgsAPIResponse['GET']['/api/organizations'] | undefined
  >(createOrganization, {
    onMutate: ({ organizationForm, assumedOrganizationRole }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        OrgsAPIResponse['GET']['/api/organizations']
      >('getOrganizationList')

      // if user has assumed an org optimistically update the hierarchy
      if (assumedOrganizationRole)
        queryCache.setQueryData<OrganizationHierarchyItem[]>(
          'getOrganizationHierarchy',
          (oldOrganizationHierarchyResponse) => [
            ...(Array.isArray(oldOrganizationHierarchyResponse)
              ? oldOrganizationHierarchyResponse
              : []),
            organizationFormToOrganizationHierarchyItem(
              organizationForm,
              oldOrganizationHierarchyResponse
            ),
          ]
        )

      // optimistic update the organizationList query
      queryCache.setQueryData<OrgsAPIResponse['GET']['/api/organizations']>(
        'getOrganizationList',
        (oldOrganizationListResponse) => ({
          ...(oldOrganizationListResponse ?? {
            recordsPerPage: 25,
            totalRecords: 1,
            currentPageNumber: 1,
            totalPages: 1,
          }),
          items: [
            ...(oldOrganizationListResponse?.items ?? []),
            organizationFormToOrganization(organizationForm),
          ],
        })
      )

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Organization created') })
    },
    onError: async (error, _, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        OrgsAPIResponse['GET']['/api/organizations'] | undefined
      >('getOrganizationList', snapshot)

      handleQueryError({ error })
    },
    onSettled: () => {
      // refetch from API to make sure the organizations list and organization details is in sync with the server
      queryCache.invalidateQueries('getOrganizationList')
      queryCache.invalidateQueries('getOrganizationHierarchy')
      queryCache.invalidateQueries('getOrganization')
    },
  })

  return mutation
}

export default useCreateOrganizationMutation

type CreateOrganizationProps = {
  organizationForm: OrganizationForm
  assumedOrganizationRole?: OrganizationPermissions | undefined
  metadata?: Array<[string, string]>
}

const createOrganization = async ({
  organizationForm,
  metadata,
}: CreateOrganizationProps) =>
  typedOrgsApi.post(`/api/organizations`, {
    body: {
      ...organizationForm,
      integrationMetadata: metadata ? Object.fromEntries(metadata) : undefined,
    },
  })
