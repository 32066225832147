import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

type useMedicationDetailsQueryArgs = {
  medicationId?: string
}

const useMedicationDetailsQuery = ({
  medicationId,
}: useMedicationDetailsQueryArgs) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/api/medications/{medicationId}'],
    KyError
  >(['getMedication', medicationId], () => getMedication(medicationId || ''), {
    enabled: !!medicationId,
  })
  return query
}

export default useMedicationDetailsQuery

const getMedication = (medicationId: string) =>
  typedVitalsApi.get('/api/medications/{medicationId}', {
    pathParams: { medicationId },
  })
