import { formatISO } from 'date-fns'

type generateAuditableArgs = {
  createdAt?: Date
  updatedAt?: Date
}

const generateAuditable = ({
  createdAt,
  updatedAt,
}: generateAuditableArgs = {}) => {
  const now = new Date()

  const result: Auditable = {
    auditInfo: {
      createdAt: formatISO(createdAt || now),
      createdBy: 'Addison',
      updatedAt: formatISO(updatedAt || now),
      updatedBy: 'Addison',
    },
  }

  return result
}

export default generateAuditable
