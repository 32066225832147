import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

type UsePoliciesAuditTrailQueryProps = {
  filters: PolicyAuditSearchParams
}

const usePoliciesAuditTrailQuery = ({
  filters,
}: UsePoliciesAuditTrailQueryProps) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/policies/audit-trails'],
    KyError
  >(['getAuditTrail', 'policies', filters], () => getAuditTrail(filters), {
    staleTime: 0,
  })

  return query
}

export default usePoliciesAuditTrailQuery

const getAuditTrail = async (filters: PolicyAuditSearchParams) =>
  typedOrgsApi.get('/api/policies/audit-trails', {
    searchParams: {
      ...filters,
    },
  })

export type PolicyAuditSearchParams = NonNullable<
  Extract<
    OrgsAPIRequest['GET']['/api/policies/audit-trails']['searchParams'],
    Record<string, unknown>
  >
>
