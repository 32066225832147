import { AutoComplete, EntityLabel, Icon, TextField } from 'elements'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { useOrganizationListQuery } from 'hooks/organizations'
import { FadeInSlideDown } from 'animations'
import { Button, DatePicker, Tooltip } from 'atlas'
import { useCreateContractMutation } from 'hooks/contracts'
import { addYears, formatISO } from 'date-fns'

const ContractForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [organization, setOrganization] = useState<OrganizationListItem | null>(
    null
  )
  const [organizationError, setOrganizationError] = useState<string>()

  const { errors, control, trigger, watch } = useForm<
    Omit<ContractForm, 'effectiveDates'> & {
      effectiveDates: { fromDate: Date; toDate?: Date }
    }
  >()

  const organizationListQuery = useOrganizationListQuery()

  const createContractMutation = useCreateContractMutation()

  return (
    <FormContainer>
      <RowContainer>
        <OrganizationAutoComplete
          label={t('Organization *')}
          options={organizationListQuery.data?.items || []}
          single
          onChange={(org) => setOrganization(org)}
          optionLabel={(option) => option.businessName || ''}
          selectedOption={organization}
          renderOption={(props, option) => (
            <AutoCompleteOption {...props}>
              <EntityLabel id={option.id} name={option.businessName} />
            </AutoCompleteOption>
          )}
          renderTags={(options) =>
            options.map((option) => (
              <EntityLabel
                id={option.id}
                name={option.businessName}
                key={option.id}
              />
            ))
          }
          error={organizationError}
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="descriptionInfo.title"
          label={t('Title')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Title is required') }}
          error={errors.descriptionInfo?.title?.message}
          className="flex-grow max-w-md mt-4"
          required
        />
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={TextField}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="descriptionInfo.description"
          label={t('Description')}
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Description is required') }}
          error={errors.descriptionInfo?.description?.message}
          className="flex-grow max-w-xl mt-4"
          multiline
          rows={3}
          required
        />
        <InfoTooltip
          content={
            <TooltipText>{`${t(
              'Enter a meaningful description that will help customers understand the exact details of the contract'
            )}. ${t('Be as specific as possible')}`}</TooltipText>
          }
        >
          <InfoIcon type="info" />
        </InfoTooltip>
      </RowContainer>
      <RowContainer>
        <Controller
          control={control}
          as={DatePicker}
          // this is purely here to prevent console.warns
          defaultValue=""
          name="effectiveDates.fromDate"
          label={t('Effective From *')}
          dense={false}
          minDate={new Date()}
          // max effective date start should either be the effective date end or 10 years into future
          maxDate={
            (watch('effectiveDates.toDate') as Date | undefined) ||
            addYears(new Date(), 10)
          }
          //@ts-expect-error it's fine if the required message is undefined
          rules={{ required: t('Effective From date is required') }}
          error={errors.effectiveDates?.fromDate?.message}
          className="mt-4 mr-4"
          buttonClassName="w-56"
        />
        <Controller
          control={control}
          as={DatePicker}
          // this is purely here to prevent console.warns
          defaultValue=""
          label={t('Effective To (optional)')}
          dense={false}
          name="effectiveDates.toDate"
          // min effective date range end should either be the effective date start or the present
          minDate={
            (watch('effectiveDates.fromDate') as Date | undefined) || new Date()
          }
          maxDate={addYears(new Date(), 10)}
          datepickerDefaultDate={
            watch('effectiveDates.fromDate') as Date | undefined
          }
          className="mt-4"
          buttonClassName="w-56"
        />
      </RowContainer>
      <RowContainer>
        <SubmitButton
          isLoading={createContractMutation.isLoading}
          onClick={async () => {
            if (!organization)
              setOrganizationError(t('Organization is required'))
            else setOrganizationError(undefined)

            if (!(await trigger()) || !organization) return

            const formData = watch()

            createContractMutation.mutate(
              {
                contractForm: {
                  ...formData,
                  currencyCode: 'USD',
                  effectiveDates: {
                    fromDate: formatISO(formData.effectiveDates.fromDate),
                    ...(formData.effectiveDates.toDate
                      ? { toDate: formatISO(formData.effectiveDates.toDate) }
                      : {}),
                  },
                },
                organizationId: organization.id,
              },
              {
                // on successful creation redirect to the contract's detail page to continue contract setup
                onSuccess: (contract) => {
                  navigate({
                    pathname: `../${contract.id}`,
                    searchParams: { organizationId: organization.id },
                  })
                },
              }
            )
          }}
        >
          {t('Create Contract')}
        </SubmitButton>
      </RowContainer>
    </FormContainer>
  )
}

export default ContractForm

const FormContainer = tw(
  FadeInSlideDown
)`bg-white p-5 rounded-lg border border-gray-300 mb-4`

const RowContainer = tw.div`flex`

const OrganizationAutoComplete = tw(AutoComplete)`mt-2 flex-grow max-w-md`

const AutoCompleteOption = tw.li`cursor-pointer hover:bg-gray-100 py-2 px-4`

const InfoIcon = tw(Icon)`text-gray-600 hover:text-gray-900 w-5 h-5`

const InfoTooltip = tw(Tooltip)`ml-2 mt-4 h-fit-content`

const TooltipText = tw.p`w-60`

const SubmitButton = tw(Button)`mt-10`
