import React, { useEffect } from 'react'
import LogRocket from 'logrocket'
import { useTranslation } from 'react-i18next'
import { Button } from 'atlas'
import { CodeViewer, Icon } from 'elements'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

type ErrorScreenProps = {
  error: Error
  resetErrorBoundary?: (...args: unknown[]) => void
}

const ErrorScreen = ({ error, resetErrorBoundary }: ErrorScreenProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    // Error boundary for data display components
    if (
      resetErrorBoundary &&
      process.env.NODE_ENV === 'production' &&
      !window.Cypress
    ) {
      LogRocket.captureException(error, { tags: { priority: 'HIGH' } })
    }
  }, [error, resetErrorBoundary, history])

  return (
    <Container>
      <ErrorIcon type="error" />
      <Title>{t('Oops, an unexpected error has occurred')}</Title>
      <SubTitle>
        {t(
          'We suggest you go back to the Overview page while we fix the problem'
        )}
      </SubTitle>
      <Button
        tw="mt-2"
        type="primary-filled"
        onClick={() => {
          if (resetErrorBoundary) resetErrorBoundary()
          return navigate('/')
        }}
      >
        {t('Back to Overview')}
      </Button>
      {process.env.NODE_ENV === 'development' && (
        <StackTrace>
          <CodeViewer
            noBackgroundColor
            code={error.stack?.toString() || ''}
            language="json"
          />
        </StackTrace>
      )}
    </Container>
  )
}

const Container = tw.div`flex flex-col items-center justify-center h-full align-middle flex-grow`

const Title = tw.h1`text-3xl`

const SubTitle = tw.h2``

const StackTrace = tw.div`flex flex-wrap w-min text-sm h-52 overflow-y-scroll bg-white rounded mt-8`

const ErrorIcon = tw(Icon)`text-red-400 text-5xl `

export default ErrorScreen
