import React, { useMemo, useCallback, useState } from 'react'
import { List, ConfirmationDialogs, EllipsisDropdown } from 'elements'
import { Button, Chip } from 'atlas'
import {
  useTable,
  Column,
  useSortBy,
  useGlobalFilter,
  Row,
  useFilters,
} from 'react-table'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  useDeletePolicyGroupMutation,
  useRestorePolicyGroupMutation,
} from 'hooks/access-control/policy-groups'
import tw from 'twin.macro'
import { useEntityLabelSort } from 'hooks/react-table'

type ArchivedPolicyGroupsListProps = {
  data: PolicyGroup[]
}

const ArchivedPolicyGroupsList = ({ data }: ArchivedPolicyGroupsListProps) => {
  const { t } = useTranslation()
  const primaryLabelSort = useEntityLabelSort({
    accessor: ['title'],
  })

  const tableData: PolicyGroup[] = useMemo(() => {
    return data
  }, [data])

  // globalFilter (search) for this table
  const globalFilter = useCallback(
    (rows: Row<PolicyGroup>[], columnIDs: string[], globalFilter: string) => {
      return rows.filter((row) => {
        const title = row.original.title.toLowerCase()
        const code = row.original.code.toLowerCase()
        // optional when creating/editing, description may be null
        const description = String(row.original.description).toLowerCase()

        return (
          title.includes(globalFilter.toLowerCase()) ||
          code.includes(globalFilter.toLowerCase()) ||
          description.includes(globalFilter.toLowerCase())
        )
      })
    },
    []
  )
  const columns: Column<PolicyGroup>[] = useMemo(
    () => [
      {
        id: 'TITLE',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('TITLE'),
        accessor: (row) => (
          <div>
            <PrimaryColumnText>{row.title}</PrimaryColumnText>
            <CodeText>{row.code}</CodeText>
          </div>
        ),
        sortType: primaryLabelSort,
      },
      {
        id: 'DESCRIPTION',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('DESCRIPTION'),
        accessor: 'description',
        width: '2fr',
      },
      {
        id: 'STATUS',
        //@ts-expect-error can't expect the exact wording due to translation
        Header: t('STATUS'),
        accessor: () => <Chip color="gray">{t('Archived')}</Chip>,
        width: '80px',
        disableSortBy: true,
      },
      {
        id: 'ACTIONS',
        Header: '',
        accessor: (row) => <ActionsCell row={row} />,
        disableSortBy: true,
        width: '30px',
      },
    ],
    [i18next.language]
  )

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      globalFilter,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,

      // default: sort in ascending order by name
      initialState: {
        sortBy: [
          {
            id: 'TITLE',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  )

  return (
    <List
      tableInstance={tableInstance}
      searchPlaceholder={t('Search policy groups')}
      emptyTitle={t('No Policy Groups Found')}
      emptyDescription={t(
        'Try changing your search term or contact your administrator'
      )}
    />
  )
}

export default ArchivedPolicyGroupsList

const PrimaryColumnText = tw.p`text-blue-500 font-semibold`

const CodeText = tw.p`text-gray-500 text-sm leading-4`

type ActionsCellProps = {
  row: PolicyGroup
}

const ActionsCell = ({ row }: ActionsCellProps) => {
  const { t } = useTranslation()
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const { mutate: deletePolicy } = useDeletePolicyGroupMutation()
  const { mutate: restorePolicy } = useRestorePolicyGroupMutation()

  const confirmations = [
    // Restore Dialog
    {
      setIsOpen: setIsRestoreDialogOpen,
      isOpen: isRestoreDialogOpen,
      title: t(`Restore "${row.title}" policy group`),
      content: (
        <p>{t('Are you sure you want to restore this policy group?')}</p>
      ),
      actions: (
        <Button
          onClick={() => {
            restorePolicy(row.id)
            return setIsRestoreDialogOpen(false)
          }}
          type="primary-filled"
        >
          {t('Restore')}
        </Button>
      ),
    },
    // Delete Dialog
    {
      setIsOpen: setIsDeleteDialogOpen,
      isOpen: isDeleteDialogOpen,
      title: t(`Delete "${row.title}" policy group`),
      content: <p>{t('Are you sure you want to delete this policy group?')}</p>,
      actions: (
        <Button
          onClick={() => {
            deletePolicy(row.id)
            return setIsDeleteDialogOpen(false)
          }}
          type="danger-filled"
        >
          {t('Delete')}
        </Button>
      ),
    },
  ]

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EllipsisDropdown
        options={[
          {
            label: t('Restore'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsRestoreDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
          {
            label: t('Delete'),
            onClick: ({ setIsDropdownVisible }) => {
              setIsDeleteDialogOpen(true)
              setIsDropdownVisible(false)
            },
          },
        ]}
        testid={`${row.title}-ellipsis`}
      />
      <ConfirmationDialogs confirmations={confirmations} />
    </div>
  )
}
