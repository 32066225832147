import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { APIQueryStr } from 'hooks/queries'
import {
  ordersApi,
  typedOrgsApi,
  typedVitalsApi,
  typedWebhooksApi,
} from 'utils'
import {
  HttpFn,
  HTTPReqParams,
  URLType,
  SearchParams,
  hasPathParams,
  hasSearchParams,
  hasReqBody,
} from './api'

// optional config object when defining a new query. Allows overriding the searchParams type
type QueryConstructorConfig<
  APIGetReqT extends APIServiceRequest['GET'],
  APIGetResT extends APIServiceResponse['GET'],
  URL extends Extract<URLType<'GET', { GET: APIGetResT }>, string> &
    Extract<keyof APIGetReqT, string>,
  SearchParamT
> = {
  queryConfig?: UseQueryOptions<APIGetResT[URL], KyError>
} & (// if there is a custom SearchParam type require a mapper to convert it
SearchParams<'GET', URL, { GET: APIGetReqT }> extends SearchParamT
  ? {
      mappers?: {
        searchParams?: (
          searchParams: SearchParams<'GET', URL, { GET: APIGetReqT }>
        ) => SearchParams<'GET', URL, { GET: APIGetReqT }>
      }
    }
  : {
      mappers: {
        searchParams: (
          searchParams: SearchParamT
        ) => SearchParams<'GET', URL, { GET: APIGetReqT }>
      }
    })

/**
 * function used define a query constructor for a particular API
 *
 * @param httpClient the HTTP client for the API service
 * @returns query constructor function used to define queries
 */
const queryServiceConstructor = <
  APIGetReqT extends APIServiceRequest['GET'],
  APIGetResT extends APIServiceResponse['GET']
>(httpClient: {
  get: HttpFn<{ GET: APIGetReqT }, { GET: APIGetResT }, 'GET'>
}) =>
  /**
   * query constructor function used to define API queries
   * @template URL string literal type of the url
   * @template SearchParamT override to the type of searchParams so the query can accept a different type in searchParams and map it to the actual type internally
   *
   * @param {QueryStr} queryStr unique string used to reference this query
   * @param {URL} url API endpoint to make the GET request to
   * @param {object} [config] optional config object for overriding defaults and adding mapping behavior
   * @param {object} config.queryConfig override react-query defaults
   * @param {object} config.mappers provide functions to internally map params before sending them to the useQuery hook
   * @param {SearchParamT} config.mappers.searchParams
   *
   * @returns a function that returns a hook thinly wrapping useQuery
   */
  <
    URL extends Extract<URLType<'GET', { GET: APIGetResT }>, string> &
      Extract<keyof APIGetReqT, string>,
    SearchParamT extends
      | Record<string, unknown>
      | Array<unknown> = SearchParams<'GET', URL, { GET: APIGetReqT }>
  >(
    queryStr: APIQueryStr,
    url: URL,
    {
      queryConfig,
      mappers,
    }: QueryConstructorConfig<
      APIGetReqT,
      APIGetResT,
      URL,
      SearchParamT
    > = {} as QueryConstructorConfig<APIGetReqT, APIGetResT, URL, SearchParamT>
  ): QueryFn<APIGetReqT, APIGetResT, URL, SearchParamT> => (
    params = {} as Parameters<
      QueryFn<APIGetReqT, APIGetResT, URL, SearchParamT>
    >[0]
  ) => {
    const pathParams = hasPathParams(params) ? params.pathParams : undefined

    const searchParams = hasSearchParams(params)
      ? Object.fromEntries(
          Object.entries(params.searchParams).filter(
            ([, val]) => val !== undefined && val !== ''
          )
        )
      : undefined

    const body = hasReqBody(params) ? params.body : undefined

    const enabled = hasEnabled(params) ? params.enabled : undefined

    return useQuery<APIGetResT[URL], KyError>(
      [queryStr, pathParams, searchParams],
      () =>
        // @ts-expect-error can't find a way to fully connect these param types
        httpClient.get(url, {
          pathParams,
          searchParams: mappers?.searchParams
            ? // @ts-expect-error TODO: can hasSearchParams strictly type searchParams?
              mappers.searchParams(searchParams || {})
            : searchParams,
          body,
        }) as Promise<APIGetResT[URL]>,
      {
        ...queryConfig,
        keepPreviousData: true,
        enabled:
          (queryConfig?.enabled === undefined || queryConfig.enabled) &&
          (enabled === undefined || enabled) &&
          (!pathParams ||
            Object.values(pathParams).every((pathParam) => !!pathParam)),
      }
    )
  }

const orgsQueryConstructor = queryServiceConstructor<
  OrgsAPIRequest['GET'],
  OrgsAPIResponse['GET']
>(typedOrgsApi)

const vitalsQueryConstructor = queryServiceConstructor<
  VitalsAPIRequest['GET'],
  VitalsAPIResponse['GET']
>(typedVitalsApi)

const ordersQueryConstructor = queryServiceConstructor<
  OrdersAPIRequest['GET'],
  OrdersAPIResponse['GET']
>(ordersApi)

const typedWebhooksQueryConstructor = queryServiceConstructor<
  WebhooksAPIRequest['GET'],
  WebhooksAPIResponse['GET']
>(typedWebhooksApi)

export {
  orgsQueryConstructor,
  vitalsQueryConstructor,
  ordersQueryConstructor,
  typedWebhooksQueryConstructor,
}

type QueryFn<
  APIGetReqT extends APIServiceRequest['GET'],
  APIGetResT extends APIServiceResponse['GET'],
  URL extends Extract<keyof APIGetResT, string> &
    Extract<keyof APIGetReqT, string>,
  SearchParamT extends Record<string, unknown> | Array<unknown> = SearchParams<
    'GET',
    URL,
    { GET: APIGetReqT }
  >
> = (
  ...params: HTTPReqParams<
    'GET',
    URL,
    { GET: APIGetReqT },
    { enabled?: boolean },
    SearchParamT
  >
) => UseQueryResult<APIGetResT[URL], KyError>

// type guard to check if params includes 'enabled'
const hasEnabled = <T extends Record<string, unknown> | undefined>(
  obj: T
): obj is T & {
  enabled: boolean
} => !!obj && !!Object.prototype.hasOwnProperty.call(obj, 'enabled')
