import React from 'react'
import { FadeInSlideDown } from 'animations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout, NavigationCard } from 'elements'
import { useNavigate } from 'hooks'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type Setting = {
  name: string
  icon: IconType
  iconColor: IconColor
  permission: string
  to: string
  description?: string
  requiresOrgRole?: boolean
}

const Partners = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const settingCards: Setting[] = _.orderBy(
    [
      {
        name: t('Partner List'),
        icon: 'partners',
        iconColor: 'green',
        permission: 'vitals.settings.readonly',
        to: 'list',
        description: t('View and edit existing partners or add new partners.'),
      },
      {
        name: t('Partner Types'),
        icon: 'partner-types',
        iconColor: 'green',
        permission: 'vitals.settings.readonly',
        to: 'partner-types',
        description: t(
          'View and edit partner type commissions setup and hierarchy.'
        ),
      },
    ],
    ['icon', 'name']
  )

  return (
    <Layout
      title={t('Partners')}
      description={t('Manage partners and partner type hierarchy.')}
      isUnauthorized={!!assumedOrganizationRole}
    >
      <NavCardContainer>
        {settingCards.map((setting, index) => (
          <FadeInSlideDown
            delay={0.1 + index * 0.05}
            key={`settings-card-${index}`}
          >
            <NavigationCard
              icon={setting.icon}
              iconColor={setting.iconColor}
              label={setting.name}
              onClick={() => navigate(setting.to)}
              description={setting.description}
            />
          </FadeInSlideDown>
        ))}
      </NavCardContainer>
    </Layout>
  )
}

export default Partners

const NavCardContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
