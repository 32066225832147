import _ from 'lodash'
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'
import { useNavigateWarning } from './navigate-warning'

const WizardFormContext = createContext<WizardFormContext>(undefined)

type WizardFormProviderProps = {
  children: React.ReactNode
  formName?: string
  isEditForm?: boolean
  disableNavWarning?: boolean
}

export const WizardFormProvider = ({
  formName,
  children,
  isEditForm = false,
  disableNavWarning,
}: WizardFormProviderProps) => {
  const { t } = useTranslation()
  const [state, setState] = useState<StepState>({
    activeStep: 0,
    disableNavigationWarning: disableNavWarning,
  })

  const context = useMemo<WizardFormContext>(
    () => ({
      stepState: {
        ...state,
        disableNavigationWarning:
          disableNavWarning !== undefined
            ? disableNavWarning
            : state.disableNavigationWarning,
      },
      setStepState: (stepState) => {
        return setState((state) => ({
          ...state,
          // if a key exists in stepState (even if its value is undefined) update state to it
          ...Object.fromEntries(
            Object.entries(stepState)
              .filter(([key]) =>
                Object.prototype.hasOwnProperty.call(stepState, key)
              )
              // manually override disableNavigationWarning if the prop is defined
              .map(([key, value]) =>
                key === 'disableNavigationWarning'
                  ? [
                      key,
                      disableNavWarning !== undefined
                        ? disableNavWarning
                        : value,
                    ]
                  : [key, value]
              )
          ),
        }))
      },
    }),
    [state, disableNavWarning]
  )

  useNavigateWarning(!state.disableNavigationWarning && !isEditForm, {
    title: `${t('Leave')} ${formName || 'Form'}?`,
    description: `${t(
      'Are you sure you want to leave this form? Any unsaved data will be lost'
    )}.`,
    confirmationText: t('Leave Form'),
  })

  useEffect(() => {
    setState((state) => ({
      ...state,
      disableNavigationWarning: disableNavWarning,
    }))
  }, [disableNavWarning])

  return (
    <WizardFormContext.Provider value={context}>
      {children}
    </WizardFormContext.Provider>
  )
}

export const useWizardForm = () => {
  const context = useContext(WizardFormContext)

  if (!context) {
    throw new Error('useWizardForm must be used within a WizardFormProvider')
  }

  return context
}

type CustomFields = Record<
  string,
  {
    value: unknown
    // TODO: figure out how to infer this from value above using existential types: https://rubenpieters.github.io/programming/typescript/2018/07/13/existential-types-typescript.html
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customIsEqualFn?: (valueA: any, valueB: any) => boolean
  }
>

type WizardStepFormProps<FormT, ApiT> = {
  /** called when submit btn is pressed. Prevents submission if return is false */
  validationFn?: () => boolean
  /** Additional validation/submission logic dependencies (any customFields or apiData are already included as dependencies) */
  dependencies?: unknown[]
  /** Additional API -> form sync dependencies  */
  formSyncDependencies?: unknown[]
  /** Include any fields here that are not controlled by react-hook-form in the format that the come back from the API. Includes function to override default equality logic */
  customFields?: CustomFields
  /** the react-query data that this form is prefilled by (if it exists) */
  apiData: ApiT | undefined
  /** a mapping function that converts the react-query data to the form type */
  apiToForm: (apiData: ApiT) => FormT
  /** called on successful form validation */
  submitFn: SubmitFn<FormT>
  /** A lifecycle function called whenever the form is prefilled with API values.
   *  Allows manually setting fields outside react-hook-form */
  onSyncWithApi?: (apiData: ApiT) => void
  /** disable warning when navigating away from form. Use when showing a custom navigation warning */
  disableNavigationWarning?: boolean
  /** disable submit button */
  isSubmitDisabled?: boolean
}

export const useWizardStepForm = <
  FormT extends Record<string, unknown>,
  ApiT extends Record<string, unknown>
>({
  validationFn,
  apiData,
  apiToForm,
  submitFn,
  onSyncWithApi,
  dependencies,
  formSyncDependencies,
  customFields,
  disableNavigationWarning,
  isSubmitDisabled,
}: WizardStepFormProps<FormT, ApiT>) => {
  const context = useContext(WizardFormContext)

  if (!context) {
    throw new Error(
      'useWizardStepForm must be used within a WizardFormProvider'
    )
  }

  const formMethods = useForm<FormT>({ shouldUnregister: false })

  // keep validation/submission logic updated to latest form values
  useDeepCompareEffect(() => {
    context.setStepState({
      validationFn: async () => {
        const isCustomValidationValid = validationFn ? validationFn() : true
        return (await formMethods.trigger()) && isCustomValidationValid
      },
      submitFn:
        !apiData ||
        // only set the submitFn if the form has changed from the apiData
        // @ts-expect-error TODO: figure out why RHF is returning/expecting a different type
        isFormChanged(apiToForm(apiData), formMethods.watch(), customFields)
          ? submitFn
          : undefined,
      formData: {
        ...formMethods.watch(),
        ...(customFields &&
          Object.fromEntries(
            Object.entries(customFields).map(([key, { value }]) => [key, value])
          )),
      },
      ...(disableNavigationWarning !== undefined
        ? { disableNavigationWarning }
        : {}),
      isSubmitDisabled,
    })
  }, [
    ...(dependencies || []),
    // need these dependencies here because isFormChanged should be run on most up-to-date data
    ...(formSyncDependencies || []),
    formMethods.watch(),
    JSON.stringify(customFields),
    apiData,
    disableNavigationWarning,
    isSubmitDisabled,
  ])

  // sync form with API
  useDeepCompareEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (apiData && apiToForm) {
      onSyncWithApi?.(apiData)
      // @ts-expect-error TODO: figure out why RHF is returning/expecting a different type
      formMethods.reset(apiToForm(apiData))
    }
  }, [...(formSyncDependencies || []), apiData])

  return formMethods
}

type ValidationFn = () => Promise<boolean> | boolean
// TODO: when refactoring wizard form redo these types to allow passing this generic to submitFn
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SubmitFn<T = any> = (formData: T) => Promise<unknown>

type StepState = {
  activeStep: number
  formData?: unknown
  /** when true submitting & moving to the next step is disabled */
  isSubmitDisabled?: boolean
  validationFn?: ValidationFn
  submitFn?: SubmitFn
  /** stores name of the form, used when showing navigation warning dialog */
  disableNavigationWarning?: boolean
}

type WizardFormContext =
  | {
      /** stores data related to the current step */
      stepState: StepState
      /** allows a step to define the validation/submission logic that is executed when the submit btn is pressed */
      setStepState: (stepFns: Partial<StepState>) => void
    }
  | undefined

// compare apiData with formData to detect if the user has changed any field
const isFormChanged = <FormT extends Record<string, unknown>>(
  apiData: FormT,
  formData: FormT,
  customFormFields: CustomFields
) => {
  // remove custom fields with custom compare to check equality separately
  const customCompareFields = _.pickBy(
    { ...customFormFields },
    (value) => !!value.customIsEqualFn
  )
  const customFields = Object.fromEntries(
    Object.entries(
      _.omitBy({ ...customFormFields }, (value) => !!value.customIsEqualFn)
    ).map(([key, { value }]) => [key, value])
  )

  // if every custom isEqualFn doesn't return true then return true immediately b/c a difference is detected
  if (
    Object.entries(customCompareFields).length &&
    !Object.entries(customCompareFields).every(([key, field]) => {
      return field.customIsEqualFn?.(field.value, apiData[key])
    })
  ) {
    return true
  }

  // remove any fields in apiData not in formData
  const filteredApiData = filterUniqueProperties(apiData, {
    ...formData,
    ...customFields,
  })

  // remove any falsey fields from both
  const sanitizedFormData = filterFalseyProperties({
    ...formData,
    ...customFields,
  })
  const sanitizedApiData = filterFalseyProperties(filteredApiData)

  // deep compare api data with form data
  return !_.isEqual(sanitizedFormData, sanitizedApiData)
}

// deeply filters all properties of an object that are falsey
const filterFalseyProperties = (
  obj: Record<string, unknown> | unknown
): Record<string, unknown> | unknown =>
  _.isPlainObject(obj)
    ? Object.fromEntries(
        Object.entries(obj as Record<string, unknown>)
          .filter(([, value]) => !!value)
          .map(([key, value]) =>
            _.isPlainObject(value)
              ? [key, filterFalseyProperties(value as Record<string, unknown>)]
              : [key, value]
          )
      )
    : obj

// deeply filters all properties of an obj1 that are not included in obj2
const filterUniqueProperties = (
  obj1: Record<string, unknown>,
  obj2: unknown
): Record<string, unknown> | unknown =>
  _.isPlainObject(obj2)
    ? Object.fromEntries(
        Object.entries(obj1)
          .filter(([key]) => Object.prototype.hasOwnProperty.call(obj2, key))
          .map(([key, value]) =>
            _.isPlainObject(value)
              ? [
                  key,
                  filterUniqueProperties(
                    value as Record<string, unknown>,
                    (obj2 as Record<string, unknown>)[key]
                  ),
                ]
              : [key, value]
          )
      )
    : obj2
