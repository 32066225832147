import { useQuery } from 'react-query'
import { ordersApi } from 'utils'

const useContractQuery = ({
  organizationId,
  contractId,
}: {
  organizationId: string | undefined | null
  contractId: string | undefined | null
}) => {
  const query = useQuery<
    OrdersAPIResponse['GET']['/organizations/{organizationId}/contracts/{contractId}'],
    KyError
  >(
    ['getContract', contractId, organizationId],
    () => getContract(organizationId || '', contractId || ''),
    { enabled: !!contractId && !!organizationId }
  )
  return query
}

export default useContractQuery

const getContract = async (organizationId: string, contractId: string) =>
  ordersApi.get(`/organizations/{organizationId}/contracts/{contractId}`, {
    pathParams: { organizationId, contractId },
    searchParams: [['include', 'shipping,line-items']],
  })
