import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, webhooksApi, handleQueryError } from 'utils'

const useUpdateWebhookSubscriptionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    UpdateWebhookSubscriptionResponse,
    KyError,
    updateWebhookSubscriptionArgs
  >(updateWebhookSubscription, {
    onSuccess: (webhookSubscription, requestArgs) => {
      // optimistic update
      // queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
      //   'getWebhookSubscriptions',
      //   (old) => {
      //     if (!old) return

      //     const newValue: GetWebhookSubscriptionsResponse = {
      //       ...old,
      //       items: [...old.items, webhookSubscription],
      //     }

      //     return newValue
      //   }
      // )

      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries([
        'getWebhookSubscriptions',
        requestArgs.organizationId,
      ])
      queryCache.invalidateQueries([
        'getWebhookSubscription',
        requestArgs.organizationId,
        requestArgs.subscriptionId,
      ])

      // notify the user
      success({ message: t('Webhook subscription updated') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useUpdateWebhookSubscriptionMutation

type updateWebhookSubscriptionArgs = CreateWebhookSubscriptionRequest & {
  organizationId: string
  subscriptionId: string
}

const updateWebhookSubscription = async ({
  postUri,
  events,
  version,
  isDevelopment,
  organizationId,
  subscriptionId,
}: updateWebhookSubscriptionArgs) => {
  const requestBody: UpdateWebhookSubscriptionRequest = [
    {
      value: postUri,
      path: '/postUri',
      op: 'replace',
    },
    {
      value: events,
      path: '/events',
      op: 'replace',
    },
    {
      value: version,
      path: '/version',
      op: 'replace',
    },
    {
      value: isDevelopment,
      path: '/isDevelopment',
      op: 'replace',
    },
  ]

  const result = await webhooksApi
    .patch(
      `api/organizations/${organizationId}/webhook-subscriptions/${subscriptionId}`,
      {
        json: requestBody,
      }
    )
    .json<UpdateWebhookSubscriptionResponse>()

  return result
}
