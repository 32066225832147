import { useQuery } from 'react-query'
import { webhooksApi } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

type useWebhookSubscriptionQueryArgs = {
  subscriptionId: string
}

const useWebhookSubscriptionQuery = ({
  subscriptionId,
}: useWebhookSubscriptionQueryArgs) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<GetWebhookSubscriptionResponse, KyError>(
    ['getWebhookSubscription', assumedOrganizationRole?.orgID, subscriptionId],
    () =>
      getWebhookSubscription(assumedOrganizationRole?.orgID, subscriptionId),
    {
      enabled: !!assumedOrganizationRole?.orgID && !!subscriptionId,
    }
  )

  return query
}

export default useWebhookSubscriptionQuery

const getWebhookSubscription = async (
  organizationId?: string,
  subscriptionId?: string
) => {
  const searchParams = [
    ['order_by', 'ASC'],
    ['take', '10000'],
  ]

  const result = webhooksApi
    .get(
      `api/organizations/${organizationId}/webhook-subscriptions/${subscriptionId}`,
      {
        searchParams,
      }
    )
    .json<GetWebhookSubscriptionResponse>()

  return result
}
