import React from 'react'
import TextField from '@mui/material/TextField'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MobileTimePicker from '@mui/lab/MobileTimePicker'
import { InputLabel } from '@mui/material'

type TimePickerProps = {
  value: Date | null
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void
  label: string
  name?: string
  disabled?: boolean
  setIsModalOpen?: (isModalOpen: boolean) => void
}

const TimePicker = ({
  value,
  onChange,
  label,
  name,
  disabled,
  setIsModalOpen,
}: TimePickerProps) => {
  return (
    // @ts-expect-error similar to this issue https://github.com/mui-org/material-ui/issues/28569
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <InputLabel
        shrink
        htmlFor={name}
        className="absolute transform translate-y-1 text-xs"
      >
        {label}
      </InputLabel>
      <MobileTimePicker
        value={value}
        disabled={disabled}
        className="ml-4"
        onChange={(event) => onChange(event)}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid="time-picker"
            margin="normal"
            variant="standard"
          />
        )}
        onOpen={() => setIsModalOpen?.(true)}
        onClose={() => setIsModalOpen?.(false)}
      />
    </LocalizationProvider>
  )
}

export default TimePicker
