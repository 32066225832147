import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, vitalsApi, handleQueryError } from 'utils'

const useDeactivateSubscriberVitalAssignmentMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}/vital-signs/{vitalSignId}/deactivate'],
    KyError,
    deactivateVitalAssignmentProps,
    | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
    | undefined
  >(deactivateVitalAssignment, {
    onMutate: ({ subscriberId, vitalSignId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
      >(['getSubVitalAssignments', subscriberId])

      // optimistic update
      queryCache.setQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
      >(['getSubVitalAssignments', subscriberId], (oldVitalAssignments) => ({
        id: '',
        subscriberId,
        assignedVitalSigns:
          oldVitalAssignments?.assignedVitalSigns.map((vitalAssignment) => {
            if (vitalAssignment.id === vitalSignId)
              return {
                ...vitalAssignment,
                active: false,
              }

            return vitalAssignment
          }) || [],
      }))

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Vital Sign deactivated') })
    },
    onError: async (error, { subscriberId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
        | undefined
      >(['getSubVitalAssignments', subscriberId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, __, { subscriberId }) => {
      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries(['getSubVitalAssignments', subscriberId])
    },
  })

  return mutation
}

export default useDeactivateSubscriberVitalAssignmentMutation

type deactivateVitalAssignmentProps = {
  subscriberId: string
  vitalAssignments: SubscriberVitalAssignments
  vitalSignId: string
}

const deactivateVitalAssignment = async ({
  subscriberId,
  vitalAssignments,
  vitalSignId,
}: deactivateVitalAssignmentProps) => {
  const result = vitalsApi
    .patch(
      `subscribers/${subscriberId}/vital-sign-assignments/${vitalAssignments.id}/vital-signs/${vitalSignId}/deactivate`
    )
    .json<
      VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}/vital-signs/{vitalSignId}/deactivate']
    >()

  // If the assignment being mutated is Blood Pressure find the other BP vitalSign id and update that one as well
  if (
    vitalAssignments.assignedVitalSigns
      .find((vitalSign) => vitalSign.id === vitalSignId)
      ?.name?.includes('Blood Pressure')
  ) {
    const otherBPVitalSignId = vitalAssignments.assignedVitalSigns.find(
      (vitalSign) =>
        vitalSign.id !== vitalSignId &&
        vitalSign.name?.includes('Blood Pressure')
    )?.id
    vitalsApi
      .patch(
        `subscribers/${subscriberId}/vital-sign-assignments/${vitalAssignments.id}/vital-signs/${otherBPVitalSignId}/deactivate`
      )
      .json<
        VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}/vital-signs/{vitalSignId}/deactivate']
      >()
  }

  return result
}
