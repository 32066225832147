import { ECGAdminArchivedVitalSignsGrid } from 'components/ecg-admin-vital-signs'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout, Unauthorized } from 'elements'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ECGAdminArchivedVitalSigns = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { data: accountPermissions } = useAccountPermissionsQuery()

  const { t } = useTranslation()

  // Organization case
  if (assumedOrganizationRole)
    return (
      <Layout title={t('Archived Vitals')}>
        <p>ORG_ADMIN</p>
      </Layout>
    )

  // ECG Admin case
  if (accountPermissions?.data.ecgAdmin.permissions.length !== 0)
    return (
      <Layout
        title={t('Archived Vitals')}
        description={t('Restore or delete vitals that have been archived.')}
        breadcrumbs={[
          {
            name: t('Vitals'),
            route: '/vitals',
          },
          { name: t('Vital Signs'), route: '/vitals/vital-signs' },
          {
            name: t('Archived Vitals'),
          },
        ]}
      >
        <ECGAdminArchivedVitalSignsGrid />
      </Layout>
    )

  // default case
  return (
    <Layout title={t('Archived Vitals')}>
      <Unauthorized />
    </Layout>
  )
}

export default ECGAdminArchivedVitalSigns
