import React from 'react'
import { Layout, WizardForm } from 'elements'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import {
  ContactInformation,
  PersonalInformation,
  ResponsibleParties,
  SiteSettings,
} from 'components/subscriber-create'
import { useNavigate } from 'hooks'
import { useSearchParam } from 'react-use'
import { success } from 'utils'

const CreateSubscriber = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const subscriberId = useSearchParam('subscriberId') || ''

  return (
    <Layout
      title={t('Create Subscriber')}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '/subscribers',
        },
        {
          name: t('Create Subscriber'),
        },
      ]}
      description={t(
        'Fill out information required to onboard a new subscriber.'
      )}
    >
      <FormContainer>
        <WizardForm
          formName={t('Subscriber Form')}
          onComplete={() => {
            success({ message: t('Subscriber creation complete!') })
            navigate(`../${subscriberId}`)
          }}
        >
          {[
            {
              label: t('Personal Information'),
              icon: 'user',
              component: <PersonalInformation />,
            },
            {
              label: t('Contact Information'),
              icon: 'phone',
              component: <ContactInformation />,
            },
            {
              label: t('Responsible Parties'),
              icon: 'subscribers',
              component: <ResponsibleParties />,
            },
            // { label: t('Insurance Information'), icon: 'clipboard' },
            // { label: t('Service Providers'), icon: 'organizations' },
            // { label: t('Medical Providers'), icon: 'organizations' },
            // { label: t('Medical Information'), icon: 'medications' },
            {
              label: t('Site Settings'),
              icon: 'tool',
              component: <SiteSettings />,
            },
          ]}
        </WizardForm>
      </FormContainer>
    </Layout>
  )
}

export default CreateSubscriber

const FormContainer = tw.div`flex flex-row flex-grow`
