import React, { useEffect, useState } from 'react'
import { RightPopup, TextField, Select, SelectItem } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  useCreateDisclaimerTypeMutation,
  useUpdateDisclaimerTypeMutation,
} from 'hooks/disclaimers'

type DisclaimerTypesFormProps = {
  disclaimerType: DisclaimerType | undefined
  modules: Module[]
  defaultModule: Module | undefined
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DisclaimerTypesForm = ({
  disclaimerType,
  modules,
  defaultModule,
  isFormOpen,
  setIsFormOpen,
}: DisclaimerTypesFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<DisclaimerTypeForm & { module: string }>({
    defaultValues: disclaimerType
      ? {
          name: disclaimerType.name,
          code: disclaimerType.code,
        }
      : undefined,
  })
  const { handleSubmit, errors, reset } = formMethods

  const [selectedModule, setSelectedModule] = useState<Module | undefined>(
    defaultModule
  )

  const {
    mutate: addDisclaimerType,
    isLoading: isAddDisclaimerTypeLoading,
  } = useCreateDisclaimerTypeMutation()
  const {
    mutate: updateDisclaimerType,
    isLoading: isUpdateDisclaimerTypeLoading,
  } = useUpdateDisclaimerTypeMutation()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset(
        disclaimerType
          ? {
              name: disclaimerType.name,
              code: disclaimerType.code,
            }
          : { name: '', code: '' }
      )
      // reset selectedModule to defaultModule
      setSelectedModule(defaultModule)
    }
  }, [isFormOpen])

  // update to defaultModule whenever it changes
  useEffect(() => {
    setSelectedModule(defaultModule)
  }, [defaultModule])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={
        disclaimerType ? t('Edit Disclaimer Type') : t('Create Disclaimer Type')
      }
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={
              isAddDisclaimerTypeLoading || isUpdateDisclaimerTypeLoading
            }
            onClick={handleSubmit((formData) => {
              if (disclaimerType) {
                updateDisclaimerType(
                  {
                    ...disclaimerType,
                    // Add moduleId as a hidden prefix to the disclaimerType code
                    code: `${formData.module}${formData.code}`,
                    name: formData.name,
                  },
                  { onSuccess: () => setIsFormOpen(false) }
                )
              } else {
                addDisclaimerType(
                  {
                    code: `${formData.module}${formData.code}`,
                    name: formData.name,
                  },
                  {
                    onSuccess: () => setIsFormOpen(false),
                  }
                )
              }
            })}
          >
            {disclaimerType ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={
              isAddDisclaimerTypeLoading || isUpdateDisclaimerTypeLoading
            }
            onClick={() => {
              // Close form drawer
              setIsFormOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Controller
            render={({ onChange, value, name, ref }) => (
              <Select
                variant="outlined"
                className="w-60 mb-7"
                value={value}
                name={name}
                ref={ref}
                label={t('Module')}
                onChange={onChange}
                error={errors.module?.message}
              >
                {modules.map((module, index) => (
                  <SelectItem value={module.name} key={index}>
                    {module.name}
                  </SelectItem>
                ))}
              </Select>
            )}
            // this is purely here to prevent console.warns
            defaultValue={selectedModule?.name}
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Module is required') }}
            name="module"
            error={errors.name?.message}
          />
          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Disclaimer Type Name is required') }}
            name="name"
            label={t('Name')}
            fullWidth
            error={errors.name?.message}
            required
          />

          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Disclaimer Type Code is required') }}
            name="code"
            label={t('Code')}
            fullWidth
            error={errors.code?.message}
            required
          />
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default DisclaimerTypesForm
