import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, webhooksApi, handleQueryError } from 'utils'

const useArchiveWebhookSubscriptionMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    ArchiveWebhookSubscriptionResponse,
    KyError,
    archiveWebhookSubscriptionArgs,
    GetWebhookSubscriptionsResponse | undefined
  >(archiveWebhookSubscription, {
    onMutate: ({ organizationId, subscriptionId }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<GetWebhookSubscriptionsResponse>(
        ['getWebhookSubscriptions', organizationId]
      )

      // find the webhook to be updated
      const webhookToUpdate = snapshot?.items.find(
        (webhook) => webhook.id === subscriptionId
      )

      // optimistic update
      queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
        ['getWebhookSubscriptions', organizationId],
        (old) => {
          if (!old || !webhookToUpdate) return

          const newValue: GetWebhookSubscriptionsResponse = {
            ...old,
            items: old.items.map((webhook) => {
              // if webhook matches, we update this item
              if (webhook.id === subscriptionId) {
                return {
                  ...webhookToUpdate,
                  archiveInfo: {
                    ...webhookToUpdate.archiveInfo,
                    archived: true,
                  },
                  activeInfo: {
                    ...webhookToUpdate.activeInfo,
                    active: false,
                  },
                }
              }
              return webhook
            }),
          }
          return newValue
        }
      )

      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Webhook subscription archived') })
    },
    onError: async (error, { organizationId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetWebhookSubscriptionsResponse | undefined>(
        ['getWebhookSubscriptions', organizationId],
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: (_, error, args) => {
      // refetch from API to make sure the webhook subscriptions list is in sync with the server
      queryCache.invalidateQueries([
        'getWebhookSubscriptions',
        args.organizationId,
      ])
      queryCache.invalidateQueries([
        'getWebhookSubscription',
        args.organizationId,
      ])
    },
  })

  return mutation
}

export default useArchiveWebhookSubscriptionMutation

type archiveWebhookSubscriptionArgs = {
  organizationId: string
  subscriptionId: string
}

const archiveWebhookSubscription = async ({
  organizationId,
  subscriptionId,
}: archiveWebhookSubscriptionArgs) => {
  const result = await webhooksApi
    .post(
      `api/organizations/${organizationId}/webhook-subscriptions/${subscriptionId}/archive`
    )
    .json<ArchiveWebhookSubscriptionResponse>()

  return result
}
