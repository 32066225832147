import { rest } from 'msw'
import db from '../../data/db'

const seedDataModuleHandlers = [
  // get salutation types list
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/seed-data/salutation-types']
  >('/api/seed-data/salutation-types', (req, res, ctx) =>
    res(
      ctx.json({
        items: db.get<SalutationType[]>('salutationTypes'),
      })
    )
  ),

  // get staffMember types list
  rest.get<undefined, GetStaffMemberTypesResponse>(
    '/api/seed-data/staff-member-types',
    (req, res, ctx) =>
      res(ctx.json(db.get<StaffMemberType[]>('staffMemberTypes')))
  ),

  // get address types list
  rest.get<undefined, GetAddressTypesResponse>(
    '/api/seed-data/address-types',
    (req, res, ctx) => res(ctx.json(db.get<AddressType[]>('addressTypes')))
  ),

  // get address types list
  rest.get<undefined, OrgsAPIResponse['GET']['/api/seed-data/country-codes']>(
    '/api/seed-data/country-codes',
    (req, res, ctx) =>
      res(ctx.json({ items: db.get<Country[]>('addressTypes') }))
  ),

  // get organization types list
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/seed-data/organization-types']
  >('/api/seed-data/organization-types', (req, res, ctx) =>
    res(ctx.json({ items: db.get<OrganizationType[]>('organizationTypes') }))
  ),

  // get products
  rest.get<undefined, OrgsAPIResponse['GET']['/api/seed-data/ecg-products']>(
    '/api/seed-data/ecg-products',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<{ id: string; name: string }[]>('products'),
        })
      )
  ),
]

export default seedDataModuleHandlers
