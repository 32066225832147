import { useTranslation } from 'react-i18next'
import { useMutation } from 'hooks'
import { ordersApi } from 'utils'

const usePERSSyncFromAneltoMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/organizations/{organizationId}/orders/{orderId}/anelto-pairs'],
    PostSyncAneltoParams
  >({
    mutationFn: postSyncAnelto,
    successMsg: {
      message: t('Order provisioning synced from Anelto'),
      showOn: 'onSuccess',
    },
    additionalCachesToInvalidate: [
      ({ orderId }) => ['order', { orderId }],
      ({ orderId }) => ['orderLineItems', { orderId }],
      ({ orderId }) => ['orderActivity', { orderId }],
    ],
  })

  return mutation
}

export default usePERSSyncFromAneltoMutation

type PostSyncAneltoParams = {
  organizationId: string
  orderId: string
  xmitId: string
}

const postSyncAnelto = async ({
  organizationId,
  orderId,
  xmitId,
}: PostSyncAneltoParams) =>
  ordersApi.post(
    '/organizations/{organizationId}/orders/{orderId}/anelto-pairs',
    {
      pathParams: { organizationId, orderId },
      body: { aneltoAccountNumber: xmitId },
    }
  )
