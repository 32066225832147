import { rest } from 'msw'
import db from '../../data/db'
import faker from 'faker'
import {
  generatePaginatable,
  generateAuditable,
  generateDisableable,
  generateArchivable,
  updateDatabaseArray,
} from '../utils'
import { isPresent } from 'utils'

const webhookHandlers = [
  // get webhook subscriptions list
  rest.get<undefined, GetWebhookSubscriptionsResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<WebhookSubscription[]>('webhookSubscriptions'),
          ...generatePaginatable(),
        })
      )
  ),

  // get individual webhook subscriptions
  rest.get<undefined, GetWebhookSubscriptionResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId',
    (req, res, ctx) => {
      const webhook = db
        .get<WebhookSubscription[]>('webhookSubscriptions')
        .find((webhook) => webhook.id === req.params.subscriptionId)

      if (!webhook) throw new Error('Webhook subscription not found')

      return res(ctx.json(webhook))
    }
  ),

  // get webhook categories list
  rest.get<undefined, GetWebhookCategoriesResponse>(
    '/api/webhook-categories',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<WebhookCategory[]>('webhookCategories'),
          ...generatePaginatable(),
        })
      )
  ),

  // get webhook events list by category
  rest.get<undefined, GetWebhookEventsResponse>(
    '/api/webhook-categories/:webhookCategoryId/webhook-events',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db.get<WebhookEvent[]>('webhookEvents'),
          ...generatePaginatable(),
        })
      )
  ),

  // create webhook subscription
  rest.post<
    CreateWebhookSubscriptionRequest,
    CreateWebhookSubscriptionResponse
  >(
    '/api/organizations/:organizationId/webhook-subscriptions',
    (req, res, ctx) => {
      const webhookEvents = db.get<WebhookEvent[]>('webhookEvents')

      const newWebhookSubscription = {
        id: faker.random.uuid(),
        ...req.body,
        organizationId: req.params.organizationId,
        errors: 0,
        // convert the array of event ID's to an array of events
        events: [
          ...req.body.events.map((item: string) =>
            webhookEvents.find((event) => event.id === item)
          ),
        ].filter(isPresent),
        ...generateArchivable(),
        ...generateDisableable(),
        ...generateAuditable(),
      }

      const webhookSubscriptions = db.get<WebhookSubscription[]>(
        'webhookSubscriptions'
      )

      db.set<WebhookSubscription[]>('webhookSubscriptions', [
        ...webhookSubscriptions,
        newWebhookSubscription,
      ])

      return res(ctx.json(newWebhookSubscription))
    }
  ),

  // delete webhook subscription
  rest.delete<undefined, DeleteWebhookSubscriptionResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId',
    (req, res, ctx) => {
      db.set<WebhookSubscription[]>(
        'webhookSubscriptions',
        db
          .get<WebhookSubscription[]>('webhookSubscriptions')
          .filter((webhook) => webhook.id !== req.params.subscriptionId)
      )

      return res(ctx.json({ value: true }))
    }
  ),

  // update webhook subscription
  rest.patch<
    UpdateWebhookSubscriptionRequest,
    UpdateWebhookSubscriptionResponse
  >(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId',
    (req, res, ctx) => {
      const webhookEvents = db.get<WebhookEvent[]>('webhookEvents')

      // convert the array of event ID's to an array of events
      const updateObject: Array<UpdateObject> = req.body.map((item) => {
        if (item.path === '/events' && Array.isArray(item.value))
          return {
            ...item,
            value: item.value.map((item: string) => {
              return webhookEvents.find((event) => event.id === item)
            }),
          }

        return item
      })

      const webhookSubscriptions = db.get<WebhookSubscription[]>(
        'webhookSubscriptions'
      )

      const webhookSubscriptionToUpdate = webhookSubscriptions.find(
        (webhook) => webhook.id === req.params.subscriptionId
      )

      if (!webhookSubscriptionToUpdate) throw new Error('Webhook not found')

      const updateWebhookSubscriptions = updateDatabaseArray<WebhookSubscription>(
        {
          array: webhookSubscriptions,
          requests: updateObject,
          itemToUpdate: webhookSubscriptionToUpdate,
          matchBy: 'id',
        }
      )

      db.set<WebhookSubscription[]>(
        'webhookSubscriptions',
        updateWebhookSubscriptions
      )

      return res(ctx.json(webhookSubscriptionToUpdate))
    }
  ),

  // deactivate webhook subscription
  rest.post<undefined, DeactivateWebhookSubscriptionResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId/deactivate',
    (req, res, ctx) => {
      db.set<WebhookSubscription[]>(
        'webhookSubscriptions',
        db.get<WebhookSubscription[]>('webhookSubscriptions').map((webhook) =>
          webhook.id === req.params.subscriptionId
            ? {
                ...webhook,
                activeInfo: { ...webhook.activeInfo, active: false },
              }
            : webhook
        )
      )

      return res(ctx.json({ value: true }))
    }
  ),

  // activate webhook subscription
  rest.post<undefined, DeactivateWebhookSubscriptionResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId/activate',
    (req, res, ctx) => {
      db.set<WebhookSubscription[]>(
        'webhookSubscriptions',
        db.get<WebhookSubscription[]>('webhookSubscriptions').map((webhook) =>
          webhook.id === req.params.subscriptionId
            ? {
                ...webhook,
                activeInfo: { ...webhook.activeInfo, active: true },
              }
            : webhook
        )
      )

      return res(ctx.json({ value: true }))
    }
  ),

  // get webhook secret
  rest.get<undefined, GetWebhookSecretResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId/secrets',
    (req, res, ctx) => res(ctx.json({ secretKey: 'this is the secret' }))
  ),

  // get webhook event sample data
  rest.get<undefined, GetWebhookEventPayloadsResponse>(
    '/api/webhook-categories/:webhookCategoryId/webhook-events/:webhookEventId/sample-payloads',
    (req, res, ctx) =>
      res(
        ctx.json({
          ...db.get<WebhookEvent[]>('webhookEvents')[0],
          payload: {
            this: 'is a sample payload',
          },
        })
      )
  ),

  // get webhook activity
  rest.get<undefined, GetWebhookActivityResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId/activity',
    (req, res, ctx) =>
      res(
        ctx.json({
          ...generatePaginatable(),
          items: [],
        })
      )
  ),
  // archive webhook subscription
  rest.post<undefined, ArchiveWebhookSubscriptionResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId/archive',
    (req, res, ctx) => {
      const webhookSubscriptions = db.get<WebhookSubscription[]>(
        'webhookSubscriptions'
      )

      const webhookToUpdate = webhookSubscriptions.find(
        (webhook) => webhook.id === req.params.subscriptionId
      )

      if (!webhookToUpdate) throw new Error('Webhook not found')

      // update the db
      db.set<WebhookSubscription[]>(
        'webhookSubscriptions',
        webhookSubscriptions.map((webhookSubscription) =>
          webhookSubscription.id === req.params.subscriptionId
            ? {
                ...webhookToUpdate,
                archiveInfo: {
                  ...webhookToUpdate.archiveInfo,
                  archived: true,
                },
                activeInfo: {
                  ...webhookToUpdate.activeInfo,
                  active: false,
                },
              }
            : webhookSubscription
        )
      )

      return res(ctx.json({ value: true }))
    }
  ),
  // restore webhook subscription
  rest.post<undefined, RestoreWebhookSubscriptionResponse>(
    '/api/organizations/:organizationId/webhook-subscriptions/:subscriptionId/restore',
    (req, res, ctx) => {
      const webhookSubscriptions = db.get<WebhookSubscription[]>(
        'webhookSubscriptions'
      )

      const webhookToUpdate = webhookSubscriptions.find(
        (webhookSubscription) =>
          webhookSubscription.id === req.params.subscriptionId
      )

      if (!webhookToUpdate) throw new Error('Module not found')

      // update the db
      db.set<WebhookSubscription[]>(
        'webhookSubscriptions',
        webhookSubscriptions.map((webhookSubscription) =>
          webhookSubscription.id === req.params.subscriptionId
            ? {
                ...webhookToUpdate,
                archiveInfo: {
                  ...webhookToUpdate.archiveInfo,
                  archived: false,
                },
                activeInfo: {
                  ...webhookToUpdate.activeInfo,
                  active: false,
                },
              }
            : webhookSubscription
        )
      )

      return res(ctx.json({ value: true }))
    }
  ),
]

export default webhookHandlers
