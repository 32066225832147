import React, { useRef, useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { Icon } from 'elements'
import { useAPIQuery, useDelay } from 'hooks'
import _ from 'lodash'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import tw, { styled, TwStyle } from 'twin.macro'
import { formatDate } from 'utils'
import { isValid } from 'date-fns'

type OrderActivityProps = {
  orderId: string
}

type OrderActivityTypes = OrderActivity['activityType']

type OrderActivityTypeToIcon = {
  [key in Exclude<OrderActivityTypes, undefined>]: {
    icon: IconType
    color: TwStyle
  }
}

const OrderActivity = ({ orderId }: OrderActivityProps) => {
  const { t } = useTranslation()

  const delay = useDelay()

  const [scrollToTop, setScrollToTop] = useState<boolean>(false)

  const scrollRef = useRef<OverlayScrollbarsComponent>(null)

  const orderActivities = useAPIQuery('orderActivity', {
    pathParams: { orderId },
  })

  return (
    <Container delay={delay()}>
      <AutoSizeActivityContainer>
        {({ height }) => (
          <OrderActivityContainer>
            <Title>{t('Order Activity')}</Title>
            <ActivityContainer>
              <ActivityCard>
                <OverlayScrollbarsComponent
                  ref={scrollRef}
                  style={{
                    overflow: 'hidden',
                    maxHeight: height,
                  }}
                  options={{
                    scrollbars: { autoHide: 'scroll' },
                    callbacks: {
                      onScroll: (e) => {
                        if (!e?.target) return
                        // for some reason 'target' has a less specific type than it actually is
                        if ((e.target as HTMLDivElement).scrollTop > 234)
                          setScrollToTop(true)
                        else if ((e.target as HTMLDivElement).scrollTop < 234)
                          setScrollToTop(false)
                      },
                    },
                  }}
                  className="os-theme-dark"
                >
                  {_.orderBy(
                    orderActivities.data,
                    (activity) =>
                      activity.metaData &&
                      new Date(activity.metaData.CreatedAt),
                    ['desc']
                  ).map((orderActivity) => (
                    <Activity key={orderActivity.id}>
                      <ActivityIconContainer>
                        <ActivityIcon
                          type={
                            orderActivity.activityType &&
                            orderActivity.activityType in activityTypeToIcon
                              ? activityTypeToIcon[orderActivity.activityType]
                                  .icon
                              : 'unknown'
                          }
                          activityType={orderActivity.activityType}
                        />
                      </ActivityIconContainer>
                      <ActivityContent>
                        <ActivityTitle>{orderActivity.title}</ActivityTitle>
                        <ActivityOwner>
                          <h3>
                            {orderActivity.metaData?.Person.FirstName &&
                            orderActivity.metaData.Person.LastName
                              ? `${orderActivity.metaData.Person.FirstName} ${orderActivity.metaData.Person.LastName}`
                              : '-'}
                          </h3>
                        </ActivityOwner>
                        <ActivityTimeStamp>
                          {orderActivity.metaData?.CreatedAt &&
                            isValid(
                              new Date(orderActivity.metaData.CreatedAt)
                            ) &&
                            formatDate(
                              new Date(orderActivity.metaData.CreatedAt),
                              'Pp'
                            )}
                        </ActivityTimeStamp>
                      </ActivityContent>
                    </Activity>
                  ))}
                </OverlayScrollbarsComponent>
              </ActivityCard>

              <ScrollToTopButton
                onClick={() =>
                  scrollRef.current
                    ?.osInstance()
                    ?.scroll({ y: '0px' }, 500, 'linear')
                }
                isScrolling={scrollToTop}
              >
                <ScrollToTopIcon type="arrow-up" />
              </ScrollToTopButton>
            </ActivityContainer>
          </OrderActivityContainer>
        )}
      </AutoSizeActivityContainer>
    </Container>
  )
}

export default OrderActivity

const Container = tw(FadeInSlideDown)`flex flex-grow`

const AutoSizeActivityContainer = tw(AutoSizer)`flex flex-grow w-full`

const OrderActivityContainer = tw.div`w-full`

const Title = tw.h3`hidden xl:block text-lg font-semibold text-gray-700`

const ActivityContainer = tw.div`flex flex-col xl:mt-4 `

const ActivityCard = tw.div`shadow-lg xl:shadow-none border rounded border-gray-200 bg-white`

const Activity = tw.div`flex flex-row-reverse py-4 px-6 border-b border-gray-200 last-of-type:border-b-0`

const ActivityIconContainer = tw.div`-mt-0.5 `

const ActivityContent = tw.div`w-full`

const ActivityIcon = styled(Icon)<{ activityType: OrderActivityTypes }>(
  ({ activityType }) => [
    tw`block w-5 h-5 rounded-full`,
    activityType ? activityTypeToIcon[activityType].color : tw`text-red-500`,
  ]
)

const ActivityTitle = tw.h2`leading-5 text-base text-gray-700 font-medium `

const ActivityOwner = styled.div({
  ...tw` mt-1`,
  '> h3': tw`inline-block text-gray-400`,
})

const ActivityTimeStamp = styled.time({
  ...tw`mt-4 block text-gray-600 text-sm font-medium`,
})

const ScrollToTopButton = styled.button<{ isScrolling: boolean }>(
  ({ isScrolling }) => [
    tw`invisible w-10 h-10 flex justify-center items-center transform -translate-y-5 m-auto border-0 rounded-full bg-blue-400 bg-opacity-70 text-white text-center transition-all duration-700 focus:outline-none hover:bg-opacity-90`,
    isScrolling ? tw`opacity-100 visible` : tw`opacity-0`,
  ]
)

const ScrollToTopIcon = tw(Icon)`text-2xl animate-bounce`

const activityTypeToIcon: OrderActivityTypeToIcon = {
  order_delivered: { icon: 'check-marked-full', color: tw`text-green-500` },
  order_confirmed: { icon: 'check-marked-full', color: tw`text-blue-500` },
  order_shipped: { icon: 'shipped', color: tw`text-gray-500` },
  order_returned: { icon: 'return', color: tw`text-red-500` },
  order_prov_complete: { icon: 'check-marked-full', color: tw`text-blue-500` },
  order_delivery_in_process: { icon: 'processing', color: tw`text-amber-500` },
  order_prov_in_process: { icon: 'processing', color: tw`text-amber-500` },
  order_in_process: { icon: 'processing', color: tw`text-amber-500` },
  order_released: { icon: 'return', color: tw`text-amber-500` },
  line_item_prov_complete: {
    icon: 'check-marked-full',
    color: tw`text-blue-500`,
  },
  order_cancelled: { icon: 'error', color: tw`text-red-500` },
  order_reactivated: { icon: 'check-marked', color: tw`text-green-500` },
  line_item_prov_in_process: { icon: 'processing', color: tw`text-amber-500` },
}
