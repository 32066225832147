import _ from 'lodash'

/**
 * Reconstructs given argument to an ordered array and possibly combines blood pressures
 * @param vitalAssignments
 * @returns ordered array of vital assignments objects by name
 */
const assignmentsToDisplayAssignments = <T extends VitalAssignment | VitalSign>(
  vitalAssignments: T[]
): T[] => {
  // replace blood pressure vital signs with a single one if they exist
  const bloodPressureSigns = _.remove(vitalAssignments, (vitalSign) =>
    vitalSign.name?.includes('Blood Pressure')
  )
  // order assignments by name
  return _.orderBy(
    [
      ...vitalAssignments,
      ...(bloodPressureSigns.length
        ? [
            {
              ...bloodPressureSigns[0],
              name: 'Blood Pressure',
              displayName: 'SYS/DIA',
            },
          ]
        : []),
    ],
    ['name'],
    ['asc']
  )
}

export default assignmentsToDisplayAssignments
