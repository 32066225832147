import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Logo } from 'assets/ADDI-logo.svg'
import { ReactComponent as LogoIcon } from 'assets/ADDI-icon.svg'
import RoleInformation from 'components/display/RoleInformation'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Dropdown, DropdownItem, EntityLabel, Icon } from 'elements'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useUserQuery } from 'hooks/user-profile'
import { useAuth } from 'oidc-react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { Tooltip } from 'atlas'
import { useNavigate, useDelayState } from 'hooks'
import { useWindowSize } from 'react-use'

const routes: Route[] = [
  {
    path: '/',
    title: 'Overview',
  },
  {
    path: '/vitals',
    title: 'Vitals',
    subPaths: [
      '/vitals/vital-signs',
      '/vitals/vital-signs/archive',
      '/vitals/vital-signs/audit-trail',
      '/vitals/reminder-messages',
      '/vitals/reminder-messages/audit-trail',
      '/vitals/alerts-activity',
      '/vitals/vitals-activity',
    ],
  },
  {
    path: '/access-control',
    title: 'Access Control',
    subPaths: [
      '/access-control/modules',
      '/access-control/permissions',
      '/access-control/policies',
      '/access-control/policy-groups',
      '/access-control/applications',
    ],
    /* show this if the user is not assuming an organization role and had acm.readonly permissions for ECG */
    isVisible: (assumedOrganizationRole, accountPermissions, userInfo) =>
      !!(
        !!userInfo?.roles.includes('ecg-admin') &&
        !assumedOrganizationRole &&
        accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly')
      ),
  },
  {
    path: '/subscribers',
    title: 'Subscribers',
    subPaths: ['/subscribers/:name'],
  },
  {
    path: '/staff',
    title: 'Staff',
    subPaths: ['/staff', '/staff/:id'],

    /* show this if the user is assuming an organization role */
    isVisible: (assumedOrganizationRole) => !!assumedOrganizationRole,
  },
  {
    path: '/admins',
    title: 'Admins',
    subPaths: ['/admins', '/admins/:id'],

    /* show this if the user is not assuming an organization role */
    isVisible: (assumedOrganizationRole, accountPermissions, userInfo) =>
      !!userInfo?.roles.includes('ecg-admin') && !assumedOrganizationRole,
  },
  {
    path: '/partners',
    title: 'Partners',
    subPaths: ['/partners'],

    /* show this if the user is not assuming an organization role */
    isVisible: (assumedOrganizationRole, accountPermissions, userInfo) =>
      !!userInfo?.roles.includes('ecg-admin') && !assumedOrganizationRole,
  },
  {
    path: '/organizations',
    title: 'Organizations',
    subPaths: ['/organizations/:orgid'],
    /* show this if the user is not assuming an organization role */
    isVisible: (assumedOrganizationRole) => !assumedOrganizationRole,
  },
  {
    path: '/organization',
    title: 'Organization',
    subPaths: ['/organization/:orgid'],
    /* show this if the user is assuming an organization role */
    isVisible: (assumedOrganizationRole) => !!assumedOrganizationRole,
  },
  {
    path: '/contracts',
    title: 'Contracts',
    subPaths: ['/contracts'],
    /* don't show this if the user is assuming an organization role */
    isVisible: (assumedOrganizationRole, accountPermissions, userInfo) =>
      !!userInfo?.roles.includes('ecg-admin') && !assumedOrganizationRole,
  },
  {
    path: '/orders',
    title: 'Orders',
    subPaths: ['/orders'],
  },
  {
    path: '/medications',
    title: 'Medications',
    subPaths: ['/medications'],
    /* show this if the user is assuming an organization role */
    isVisible: (assumedOrganizationRole) => !!assumedOrganizationRole,
  },
  {
    path: '/settings',
    title: 'Settings',
    subPaths: ['/settings/disclaimer-types'],
    /* don't show this if the user is assuming an organization role */
    isVisible: (assumedOrganizationRole, accountPermissions, userInfo) =>
      !!userInfo?.roles.includes('ecg-admin') && !assumedOrganizationRole,
  },
  {
    path: '/organization-settings',
    title: 'Settings',
    subPaths: [
      '/organization-settings/vital-sign-assignments',
      '/organization-settings/vitals-reminders',
      '/organization-settings/vitals-thresholds',
      '/organization-settings/threshold-alerts',
      '/organization-settings/schedule-presets',
      '/organization-settings/reminder-messages',
      '/organization-settings/reminder-presets',
      '/organization-settings/webhooks',
      '/organization-settings/alert-delivery-rules',
    ],
    /* show this if the user is assuming an organization role */
    isVisible: (assumedOrganizationRole) => !!assumedOrganizationRole,
  },
]

const Sidebar = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const auth = useAuth()

  const userQuery = useUserQuery()

  const dropdownAnchorEl = useRef(null)

  const windowsWidth = useWindowSize()

  const { data: accountPermissions } = useAccountPermissionsQuery()

  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<boolean>(
    false
  )

  const [isSidebarHovered, setIsSidebarHovered] = useState<boolean>(false)

  const [isToggleButtonHovered, setIsToggleButtonHovered] = useState<boolean>(
    false
  )

  // Delay the display of the tooltip text that show up after a click event
  const [toggleTooltipText, setToggleTooltipText] = useDelayState<string>()

  const [isSmallerWindow, setIsSmallerWindow] = useState(
    windowsWidth.width < 1280
  )

  const [isCollapsedSidebar, setIsCollapsedSidebar] = useState<boolean>(
    windowsWidth.width < 1280
  )

  /**
   * Automatically collapse on smaller screens
   */
  useEffect(() => {
    const isWidthSmall = windowsWidth.width < 1280

    if (isWidthSmall && !isSmallerWindow) {
      setIsSmallerWindow(true)
      setIsCollapsedSidebar(true)
    }

    if (!isWidthSmall && isSmallerWindow) {
      setIsSmallerWindow(false)
      setIsCollapsedSidebar(false)
    }
  }, [windowsWidth.width])

  /**
   * Sync the tooltip content with the state of the sidebar
   */
  useEffect(() => {
    isCollapsedSidebar
      ? setToggleTooltipText('Expand')
      : setToggleTooltipText('Collapse')
  }, [isCollapsedSidebar])

  /**
   * Hide dropdown on Sidebar Mouseleave
   */
  useEffect(() => {
    if (isCollapsedSidebar) {
      !isSidebarHovered &&
        isProfileDropdownOpen &&
        setIsProfileDropdownOpen(false)
    }
  }, [isProfileDropdownOpen, isSidebarHovered])

  return (
    <SidebarContainer isCollapsedSidebar={isCollapsedSidebar}>
      <SideBar
        isCollapsedSidebar={isCollapsedSidebar}
        onMouseLeave={() => setIsSidebarHovered(false)}
        onMouseOver={() => setIsSidebarHovered(true)}
        isToggleButtonHovered={isToggleButtonHovered}
      >
        <div>
          <LogoContainer>
            <ADDILogo isCollapsedSidebar={isCollapsedSidebar} />
            <ADDILogoIcon isCollapsedSidebar={isCollapsedSidebar} />
          </LogoContainer>

          <NavMenuContainer>
            {routes.map((route) =>
              // double check the isVisible function if one exists,
              // otherwise just show the route
              !route.isVisible ||
              route.isVisible(
                assumedOrganizationRole,
                accountPermissions,
                userQuery.data
              ) ? (
                <SidebarNavButton
                  isCollapsedSidebar={isCollapsedSidebar}
                  route={route}
                  key={route.path}
                >
                  {t(route.title)}
                </SidebarNavButton>
              ) : null
            )}
          </NavMenuContainer>
        </div>

        <div>
          <RoleInformation
            isCollapsedSidebar={isCollapsedSidebar}
            isSidebarHovered={isSidebarHovered}
          />

          {userQuery.data ? (
            <>
              <RoleInfoContainer ref={dropdownAnchorEl}>
                <ActionButton
                  icon={
                    <EntityLabelContainer>
                      <EntityLabel
                        id={userQuery.data.cognitoUserId}
                        name={`${userQuery.data.person.firstName} ${userQuery.data.person.lastName}`}
                        size="md"
                        iconOnly
                      />
                    </EntityLabelContainer>
                  }
                  isCollapsed={isCollapsedSidebar}
                  onClick={() => setIsProfileDropdownOpen(true)}
                >
                  {`${userQuery.data.person.firstName} ${userQuery.data.person.lastName}`}
                </ActionButton>

                <Dropdown
                  visible={isProfileDropdownOpen}
                  setVisible={setIsProfileDropdownOpen}
                  parentRef={dropdownAnchorEl}
                  isParentWidth
                  animationType="simple-reverse"
                  parentAnchor={{ vertical: 'top', horizontal: 'left' }}
                  contentAnchor={{ vertical: 'bottom', horizontal: 'left' }}
                  horizontalOffset={0}
                  verticalOffset={-10}
                >
                  <DropdownItem
                    onClick={() => {
                      setIsProfileDropdownOpen(false)
                      navigate('/user-profile')
                    }}
                  >
                    {t('Profile')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setIsProfileDropdownOpen(false)
                      auth?.signOut()
                    }}
                  >
                    {t('Logout')}
                  </DropdownItem>
                </Dropdown>
              </RoleInfoContainer>
            </>
          ) : null}
        </div>
      </SideBar>

      <SidebarToggleButtonContainer
        isSidebarHovered={isSidebarHovered}
        isCollapsedSidebar={isCollapsedSidebar}
        content={t(toggleTooltipText || '')}
        orientation="start"
      >
        <ToggleButton
          onClick={() =>
            setIsCollapsedSidebar((collapsedSidebar) => !collapsedSidebar)
          }
          onMouseOver={() => setIsToggleButtonHovered(true)}
          onMouseLeave={() => setIsToggleButtonHovered(false)}
          data-testid="sidebarToggle"
        >
          <ToggleIcon
            type="chevron-right"
            isCollapsedSidebar={isCollapsedSidebar}
          />
        </ToggleButton>
      </SidebarToggleButtonContainer>
    </SidebarContainer>
  )
}

export default Sidebar

const SidebarContainer = styled.div<{ isCollapsedSidebar: boolean }>(
  ({ isCollapsedSidebar }) => [
    tw`flex flex-col relative transition-all duration-300`,
    isCollapsedSidebar ? tw`w-24` : tw`w-64`,
  ]
)

const SideBar = styled.div<{
  isCollapsedSidebar: boolean
  isToggleButtonHovered: boolean
}>(({ isCollapsedSidebar, isToggleButtonHovered }) => [
  tw`bg-white flex flex-col sticky top-0 left-0 bottom-0 h-full justify-between p-4 z-10 border-r border-gray-200 shadow-none transition-all duration-300`,
  isCollapsedSidebar ? tw`w-24 hover:(w-64 shadow-xl)` : tw`shadow-none w-64`,
  isCollapsedSidebar && isToggleButtonHovered && tw`w-24`,
])

const LogoContainer = tw.div`flex relative w-full h-14`

const ADDILogo = styled(Logo)<{ isCollapsedSidebar: boolean }>(
  ({ isCollapsedSidebar }) => [
    tw`h-16 transform absolute left[51px] transition-all duration-300 -mt-2`,
    isCollapsedSidebar
      ? tw`w-0 invisible opacity-0 scale-0 -translate-x-3`
      : tw`w-32 visible opacity-100 ml-0 xl:-ml-1 scale-100 translate-x-0 delay-75`,
  ]
)

const ADDILogoIcon = styled(LogoIcon)<{ isCollapsedSidebar: boolean }>(
  ({ isCollapsedSidebar }) => [
    tw`w-24 h-14 absolute left[51px] transition-all duration-300`,
    isCollapsedSidebar
      ? tw`left-0 visible opacity-100 -translate-x-4 scale-100`
      : tw`w-0 opacity-0 invisible translate-x-4 scale-0`,
  ]
)

const SidebarToggleButtonContainer = styled(Tooltip)<{
  isSidebarHovered: boolean
  isCollapsedSidebar: boolean
}>(({ isSidebarHovered, isCollapsedSidebar }) => [
  tw`width[32px] flex justify-end absolute top[76px] right[-15px] z-20 transition-all duration-300`,
  isCollapsedSidebar && isSidebarHovered && tw`translate-x-40`,
])

const ToggleButton = tw.button`flex flex-col items-center justify-center w-8 h-8 rounded-full bg-gray-50 text-gray-400 cursor-pointer border border-gray-200 shadow-sm hover:(text-gray-100 bg-blue-500 border-blue-500)`

const ToggleIcon = styled(Icon)<{ isCollapsedSidebar: boolean }>(
  ({ isCollapsedSidebar }) => [
    tw`w-4 h-4 color[inherit] transform transition-transform duration-300`,
    !isCollapsedSidebar && tw`rotate-180`,
  ]
)

const NavMenuContainer = tw.div`mt-10 px-4`

const RoleInfoContainer = tw.div`relative mb-4`

const EntityLabelContainer = tw.div`xl:(mr-0.5)`

const routeToIcon: { [key: string]: IconType } = {
  '/': 'overview',
  '/organizations': 'organizations',
  '/organization': 'organizations',
  '/staff': 'subscribers',
  '/admins': 'admin',
  '/partners': 'partners',
  '/subscribers': 'subscribers',
  '/vitals': 'vitals',
  '/access-control': 'lock',
  '/settings': 'settings',
  '/reminders': 'reminders',
  '/organization-settings': 'settings',
  '/contracts': 'clipboard',
  '/orders': 'shopping-cart',
  '/medications': 'medications',
}

/**
 * Sidebar Navigation buttons
 */
const SidebarNavButton = ({
  children,
  route,
  isCollapsedSidebar,
}: {
  children: string
  route: Route
  isCollapsedSidebar: boolean
}) => {
  const { pathname: currentPath } = useLocation()

  const navigate = useNavigate()

  return (
    <NavButton
      role="link"
      onClick={() => navigate(route.path)}
      currentPath={currentPath}
      route={route}
    >
      <NavButtonIconContainer>
        <NavButtonIcon type={routeToIcon[route.path]} />
      </NavButtonIconContainer>

      <NavButtonContent
        isCollapsedSidebar={isCollapsedSidebar}
        key={route.path}
      >
        {children}
      </NavButtonContent>
    </NavButton>
  )
}

const NavButton = styled.div<{ currentPath: string; route: Route }>(
  ({ currentPath, route }) => [
    tw`flex flex-row items-center my-5 cursor-pointer xl:justify-start overflow-x-hidden`,
    isActive({ currentPath, route })
      ? tw`text-blue-500 hover:text-blue-500`
      : tw`text-gray-600 hover:text-gray-900 `,
  ]
)

const NavButtonIconContainer = tw.div`margin-left[6px]`

const NavButtonIcon = tw(Icon)`h-5 w-5 transform -translate-y-0.5 xl:ml-0`

const NavButtonContent = styled.span<{ isCollapsedSidebar: boolean }>(
  ({ isCollapsedSidebar }) => [
    tw`flex-none pl-4 transition-all duration-300`,
    isCollapsedSidebar ? tw`text-opacity-0` : tw`text-opacity-100`,
  ]
)

const isActive = ({
  currentPath,
  route,
}: {
  currentPath: string
  route: Route
}) => {
  // if the currentPath matches the base path, return true
  if (currentPath === route.path) return true

  // split the currentPath into individual sections
  const splitPath = currentPath.split('/')

  // for each subPath, check for a match
  return route.subPaths?.find((subPath) => {
    // split the current subPath into individual sections
    const splitSubPaths = subPath.split('/')

    // return true if all currentPath sections match all subPath sections
    return splitSubPaths.every((_, index) => {
      // if the current subPath is dynamic
      if (splitSubPaths[index].startsWith(':')) {
        // check for the truthy condition (the section isn't empty)
        return splitPath[index].length > 0
      }

      // check for the truthy condition (the sections match up)
      return splitPath[index] === splitSubPaths[index]
    })
  })
}

/**
 * Sidebar Profile Dropdown Section
 */
type ActionButtonProps = {
  children: string
  icon: IconType | React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  className?: string
  highlight?: boolean
  isCollapsed: boolean
}

const ActionButton = ({
  children,
  icon,
  onClick,
  highlight,
  isCollapsed,
}: ActionButtonProps) => {
  return (
    <ButtonLink onClick={onClick} highlight={highlight}>
      {typeof icon === 'string' ? <ButtonIcon type={icon as IconType} /> : icon}
      <ButtonContent isCollapsedSidebar={isCollapsed}>{children}</ButtonContent>
    </ButtonLink>
  )
}

const ButtonLink = styled.a<{
  highlight?: boolean
}>(({ highlight }) => [
  tw`flex items-center mb-0 cursor-pointer overflow-x-hidden px-4`,

  highlight
    ? tw`text-purple-700 hover:text-purple-500`
    : tw`text-gray-600 hover:text-gray-900`,
])

const ButtonIcon = tw(Icon)`h-5 w-5`

const ButtonContent = styled.span<{ isCollapsedSidebar: boolean }>(
  ({ isCollapsedSidebar }) => [
    tw`flex-none align-middle transition-all duration-300 transform translate-x-1`,
    isCollapsedSidebar ? tw`text-opacity-0` : tw`text-opacity-100`,
  ]
)
