/**
 * Sets up the PATCH request body
 * @param key
 * @param value
 * @returns PATCH request object
 */
const toUpdateObj = (
  key: string,
  value: UpdateObject['value']
): UpdateObject => {
  return {
    value,
    path: `/${key}`,
    op: value === '' ? 'remove' : 'replace',
  }
}

export default toUpdateObj
