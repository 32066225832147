import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDelay } from 'hooks'
import { FadeInSlideDown } from 'animations'
import { InformationCard, Icon } from 'elements'
import { Tooltip } from 'atlas'
import { format } from 'date-fns'
import Chip from 'atlas/Chip/Chip'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'
import { actionTypesToIcon } from './MedicationSelect'

type SubscriberMedicationInfoCardProps = {
  subscriberMedication?: SubscriberMedications
  medicationDetails?: MedicationModel
}

const SubscriberMedicationInfoCard = ({
  subscriberMedication,
  medicationDetails,
}: SubscriberMedicationInfoCardProps) => {
  const { t } = useTranslation()

  const delay = useDelay()

  return (
    <FadeInSlideDown delay={delay()}>
      <InfoCard size="lg">
        <InformationCard.Item
          label={t('Brand Name')}
          value={_.startCase(medicationDetails?.brandNameBase)}
        />

        <InformationCard.Item
          label={t('NDC Code')}
          value={medicationDetails?.productNdc}
        />

        <InformationCard.Item
          label={t('Medication For')}
          value={
            <Value>{_.startCase(subscriberMedication?.medicationFor)}</Value>
          }
        />

        <InformationCard.Item
          label={t('Effective From')}
          value={format(
            new Date(subscriberMedication?.effectiveFrom || 0),
            'PP'
          )}
        />
        {subscriberMedication?.effectiveTo && (
          <InformationCard.Item
            label={t('Effective To')}
            value={format(new Date(subscriberMedication.effectiveTo), 'PP')}
          />
        )}

        <InformationCard.Item
          label={t('Route')}
          value={
            <Chip
              color={
                subscriberMedication &&
                subscriberMedication.actionType in actionTypesToIcon
                  ? actionTypesToIcon[subscriberMedication.actionType].color
                  : 'blue'
              }
            >
              {_.startCase(subscriberMedication?.route)}
            </Chip>
          }
        />

        <EditIconContainer>
          <Tooltip
            content={t('Edit Medication')}
            orientation="end"
            anchor="right"
          >
            <EditIcon type="edit" onClick={() => null} />
          </Tooltip>
        </EditIconContainer>
      </InfoCard>
    </FadeInSlideDown>
  )
}

export default SubscriberMedicationInfoCard

const InfoCard = styled(InformationCard)`
  ${tw`flex-grow relative min-width[200px]`}
`

const EditIconContainer = tw.div`flex absolute top-4 right-5`

const EditIcon = tw(
  Icon
)`w-4 h-4 text-gray-500 hover:text-gray-900 cursor-pointer`

const Value = tw.div`w-11/12`
