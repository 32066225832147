const useDelay = (
  { initial = 0, interval = 0.1 } = { initial: 0, interval: 0.1 }
) => {
  let delay = initial
  const delayFunc = (diff = interval) => {
    delay += diff

    return delay
  }

  return delayFunc
}

export default useDelay
