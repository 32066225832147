import React from 'react'
import { Toaster } from 'utils'
import {
  useAccountPermissionsQuery,
  useOrganizationListQuery,
} from 'hooks/organizations'
import { useLogRocket } from 'hooks'
import { Button } from 'atlas'
import { LoadingIcon, EntityLabel, Icon } from 'elements'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useAuth } from 'oidc-react'
import { useSearchParams } from 'react-router-dom'
import { useUserQuery } from 'hooks/user-profile'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw, { styled } from 'twin.macro'
import { Login, Sidebar, DataDisplay, Footer } from './'
import { useTranslation } from 'react-i18next'

const App = () => {
  const { t } = useTranslation()

  useLogRocket()

  const [searchParams] = useSearchParams()

  const { isLoading } = useAccountPermissionsQuery()

  const auth = useAuth()
  const { data: user } = useUserQuery()
  const { data: organizationList } = useOrganizationListQuery({
    disabled: !user,
  })

  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  // don't do any of these checks if we're in Cypress
  if (!window.Cypress) {
    if (searchParams.has('code') || isLoading)
      return (
        <LoadingIconContainer>
          <LoadingIcon />
        </LoadingIconContainer>
      )

    // if not logged in, show the login screen
    if (!auth?.userData)
      return (
        <Login>
          <SignInContainer>
            <SignInButton
              type="primary-filled"
              onClick={() =>
                auth?.signIn({ state: { targetUrl: window.location.pathname } })
              }
            >
              <SignInText>{t('Sign In')}</SignInText>
              <SignInIcon type="arrow-right"></SignInIcon>
            </SignInButton>
          </SignInContainer>
        </Login>
      )
  }

  // wait for user data to load
  if (!user) return null

  // TODO: remove this once assume-organization-role isn't reliant on the organization list
  if (!organizationList) return null

  // if there is a role query param, wait for the assumed role state to resolve
  if (
    new URLSearchParams(location.search).get('role') &&
    !assumedOrganizationRole
  )
    return null

  return (
    <AppContainer>
      <DisplayContainer>
        <Sidebar />
        <StatusBarContainer>
          {assumedOrganizationRole ? (
            <StatusBar>
              {t('Assuming organization role of') + ' '}
              <OrganizationLabel
                name={assumedOrganizationRole.orgDisplayName}
                id={assumedOrganizationRole.orgID}
                condensed
              />
            </StatusBar>
          ) : null}
          <DataDisplayContainer>
            <DataDisplay />
          </DataDisplayContainer>
          <Footer />
        </StatusBarContainer>
      </DisplayContainer>

      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />

      {Toaster}
    </AppContainer>
  )
}

export default App

const StatusBar = tw.div`bg-gray-700 flex justify-center items-center p-2 text-gray-100 shadow-sm text-sm w-full`

const OrganizationLabel = styled(EntityLabel)`
  ${tw`ml-2`}
  p {
    ${tw`text-gray-100! font-semibold!`}
  }
`

const LoadingIconContainer = tw.div`w-screen h-screen flex items-center justify-center flex-col bg-white`

const AppContainer = tw.div`flex flex-col h-screen max-h-screen`

const DisplayContainer = tw.div`flex h-full w-full overflow-hidden`

const StatusBarContainer = tw.div`overflow-y-hidden auto-cols-auto bg-gray-50 w-full flex flex-col h-full`

const DataDisplayContainer = tw.div`relative overflow-hidden flex-grow`

const SignInContainer = tw.div`flex items-center mt-2`

const SignInText = tw.span`absolute left[-75px] inline-block text-white text-opacity-70 text-xl font-light transition-all duration-300`

const SignInButton = tw(
  Button
)`relative rounded-full w-14 h-14 flex justify-center items-center ml-20 bg-opacity-0 border-2 border-opacity-70 hover:(border-opacity-0 all-child:all-child:(text-opacity-100)) focus:border-opacity-0 `

const SignInIcon = tw(Icon)`text-3xl`
