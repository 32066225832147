import React, { useState, useEffect } from 'react'
import {
  RightPopup,
  TextField,
  Select,
  SelectItem,
  RadioButtonGroup,
  Icon,
  LoadingIcon,
} from 'elements'
import { Button, Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import _ from 'lodash'
import { webhookAPIVersions } from 'test-utils/data/rawMockData'
import {
  useCreateWebhookSubscriptionMutation,
  useWebhookCategoriesQuery,
  useWebhookEventsQuery,
  useUpdateWebhookSubscriptionMutation,
  useWebhookEventPayloadsQuery,
} from 'hooks/settings-webhooks'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import AvailableWebhooksFormList from './AvailableWebhooksFormList'
import SampleDataCodeViewer from './SampleDataCodeViewer'
import tw, { styled } from 'twin.macro'

type CreateEditWebhooksFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  webhookSubscriptionToEdit?: WebhookSubscription
}

const CreateEditWebhooksForm = ({
  isFormOpen,
  setIsFormOpen,
  webhookSubscriptionToEdit,
}: CreateEditWebhooksFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const [action, setAction] = useState<'add-another' | 'close'>()

  const formMethods = useForm<CreateWebhookSubscriptionForm>({
    defaultValues: {
      isDevelopment: true,
    },
  })
  const {
    handleSubmit,
    errors,
    reset,
    watch,
    setValue,
    control,
    getValues,
  } = formMethods

  const [checkedEvents, setCheckedEvents] = useState<WebhookEvent[]>([])
  const [
    displayedSampleEvent,
    setDisplayedSampleEvent,
  ] = useState<WebhookEvent>()
  const [
    selectedWebhookCategory,
    setSelectedWebhookCategory,
  ] = useState<string>()

  const webhookCategories = useWebhookCategoriesQuery()
  const webhookCategoryEvents = useWebhookEventsQuery({
    webhookCategoryId: selectedWebhookCategory,
  })
  const webhookEventPayload = useWebhookEventPayloadsQuery({
    webhookCategoryId: displayedSampleEvent?.webhookCategory.id,
    webhookEventId: displayedSampleEvent?.id,
  })

  const createWebhookSubscription = useCreateWebhookSubscriptionMutation()
  const updatedWebhookSubscription = useUpdateWebhookSubscriptionMutation()

  // when webhook categories load set the selected webhook category to the first item
  useEffect(() => {
    if (webhookCategories.data)
      setSelectedWebhookCategory(webhookCategories.data.items[0].id)
  }, [webhookCategories.data])

  // reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset({
        events: webhookSubscriptionToEdit?.events,
        isDevelopment: webhookSubscriptionToEdit?.isDevelopment ?? true,
        postUri: webhookSubscriptionToEdit?.postUri,
        version: webhookSubscriptionToEdit?.version,
      })
      setCheckedEvents([])
      setDisplayedSampleEvent(undefined)
      setSelectedWebhookCategory(webhookCategories.data?.items[0].id)
    }
  }, [isFormOpen])

  // calculate the avilable events to add
  const availableWebhookEvents = _.sortBy(
    webhookCategoryEvents.data?.items,
    (event) => _.lowerCase(event.name)
    // incorrect type, 'watch' may return undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  ).filter((event) => !watch('events')?.find((item) => item.id === event.id))

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={
        webhookSubscriptionToEdit ? t('Edit Webhook') : t('Create Webhooks')
      }
      width="700px"
      disableScroll={!!displayedSampleEvent}
      controls={
        !displayedSampleEvent ? (
          <>
            {/* Create & Add Another Button (only shown for creating webhook subscription) */}
            {!webhookSubscriptionToEdit ? (
              <Button
                type="primary-filled"
                disabled={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !watch('events')?.length ||
                  (action !== 'add-another' &&
                    updatedWebhookSubscription.isLoading)
                }
                isLoading={
                  action === 'add-another' &&
                  createWebhookSubscription.isLoading
                }
                onClick={handleSubmit((formData) => {
                  setAction('add-another')
                  createWebhookSubscription.mutate(
                    {
                      ...formData,
                      events: formData.events.map((event) => event.id),
                      // @ts-expect-error this will never be undefined
                      organizationId: assumedOrganizationRole.orgID,
                    },
                    {
                      onSuccess: () => {
                        reset({
                          isDevelopment: true,
                          events: [],
                          postUri: '',
                        })
                        setCheckedEvents([])
                      },
                    }
                  )
                })}
              >
                {t('Create & Add Another')}
              </Button>
            ) : null}
            &nbsp;
            {/* Create & Close / Update Button (shown for both) */}
            {webhookSubscriptionToEdit ? (
              <Button
                type="primary-filled"
                disabled={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !watch('events')?.length || watch('events')?.length === 0
                }
                isLoading={updatedWebhookSubscription.isLoading}
                onClick={handleSubmit((formData) => {
                  updatedWebhookSubscription.mutate(
                    {
                      ...formData,
                      events: formData.events.map((event) => event.id),
                      organizationId: webhookSubscriptionToEdit.organizationId,
                      subscriptionId: webhookSubscriptionToEdit.id,
                    },
                    {
                      onSuccess: () =>
                        // close form drawer
                        setIsFormOpen(false),
                    }
                  )
                })}
              >
                {t('Update')}
              </Button>
            ) : (
              <Button
                type="primary-filled"
                disabled={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !watch('events')?.length ||
                  watch('events').length === 0 ||
                  (action !== 'close' && createWebhookSubscription.isLoading)
                }
                isLoading={
                  action === 'close' && createWebhookSubscription.isLoading
                }
                onClick={handleSubmit((formData) => {
                  setAction('close')
                  return createWebhookSubscription.mutate(
                    {
                      ...formData,
                      events: formData.events.map((event) => event.id),
                      // @ts-expect-error this will never be undefined
                      organizationId: assumedOrganizationRole.orgID,
                    },
                    {
                      onSuccess: () => {
                        // close form drawer
                        setIsFormOpen(false)
                      },
                    }
                  )
                })}
              >
                {t('Create & Close')}
              </Button>
            )}
            {/* Cancel Button (shown for both) */}
            &nbsp;
            <Button
              type="secondary"
              disabled={
                createWebhookSubscription.isLoading ||
                updatedWebhookSubscription.isLoading
              }
              onClick={() => setIsFormOpen(false)}
            >
              {t('Cancel')}
            </Button>
          </>
        ) : null
      }
    >
      {webhookCategories.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          {displayedSampleEvent ? (
            <OverlayScrollbarContainer
              className="absolute top-0 left-0 w-full h-full bg-white z-50 p-6 pt-6"
              width="700px"
            >
              <DisplayedEventContainer>
                <IconContainer>
                  <IconTitle>{t('Sample Data')}</IconTitle>
                  <XIcon
                    type="x"
                    onClick={() => setDisplayedSampleEvent(undefined)}
                  />
                </IconContainer>
                <NameAndCode>
                  {displayedSampleEvent.name} ({displayedSampleEvent.code})
                </NameAndCode>
              </DisplayedEventContainer>

              <SampleDataCodeViewer
                isLoading={webhookEventPayload.isLoading}
                data={webhookEventPayload.data?.payload}
                controls={
                  <Button
                    type="secondary"
                    tw="mt-4"
                    onClick={() => setDisplayedSampleEvent(undefined)}
                  >
                    {t('Close')}
                  </Button>
                }
              />
            </OverlayScrollbarContainer>
          ) : null}
          <FormContainer invisible={!!displayedSampleEvent}>
            <RadioContainer>
              <Controller
                control={control}
                name="isDevelopment"
                render={() => (
                  <RadioButtonGroup
                    value={watch('isDevelopment') ? 'dev' : 'live'}
                    onChange={(newValue) =>
                      setValue('isDevelopment', newValue === 'dev')
                    }
                    options={[
                      { label: 'Development', value: 'dev' },
                      { label: 'Live', value: 'live' },
                    ]}
                  />
                )}
              />
            </RadioContainer>

            <Controller
              as={TextField}
              // this is purely here to prevent console.warns
              defaultValue=""
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('API Endpoint URL is required') }}
              name="postUri"
              label={t('API Endpoint URL')}
              fullWidth
              error={errors.postUri?.message}
              required
            />

            <Controller
              as={
                <Select
                  name="version"
                  label="Version"
                  variant="outlined"
                  className="mb-6"
                >
                  {webhookAPIVersions.map((version, index) => (
                    <SelectItem value={version} key={index}>
                      {version}
                    </SelectItem>
                  ))}
                </Select>
              }
              name="version"
              defaultValue={webhookAPIVersions[0]}
              //@ts-expect-error it's fine if the required message is undefined
              rules={{ required: t('Version is required') }}
              error={errors.version?.message}
              required
            />

            {/* AVAILABLE EVENTS */}
            <>
              <SelectContainer>
                <EventTitle>{t('Available Events')}</EventTitle>
                <Select
                  className="w-64"
                  label="Category"
                  value={selectedWebhookCategory}
                  onChange={(e) =>
                    setSelectedWebhookCategory(e.target.value as string)
                  }
                >
                  {webhookCategories.data?.items.map((webookCategory) => (
                    <SelectItem
                      value={webookCategory.id}
                      key={webookCategory.id}
                    >
                      {webookCategory.name}
                    </SelectItem>
                  ))}
                </Select>
              </SelectContainer>

              <AvailableWebhooksFormList
                checkedEvents={checkedEvents}
                setDisplayedSampleEvent={setDisplayedSampleEvent}
                availableWebhookCategoryEvents={availableWebhookEvents}
                setCheckedEvents={setCheckedEvents}
                isLoadingAvailableWebhookCategoryEvents={
                  webhookCategoryEvents.isLoading
                }
              />
            </>

            <Button
              disabled={checkedEvents.length === 0}
              tw="my-4"
              onClick={() => {
                // add the checkedEvents to selected events (and sort)
                setValue(
                  'events',
                  _.sortBy([...getValues().events, ...checkedEvents], (event) =>
                    _.lowerCase(event.name)
                  )
                )

                // reset checkedEvents
                setCheckedEvents([])
              }}
            >
              {t('Add Selected Events To Webhook')}
            </Button>

            {/* SELECTED EVENTS */}
            <>
              <SelectedEventsTitle>{t('Selected Events')}</SelectedEventsTitle>
              <SelectedEventsContainer>
                <Controller
                  key="events"
                  defaultValue={[]}
                  name={'events'}
                  // @ts-expect-error doesn't like an array of components for some reason
                  render={({ value }: CheckboxRenderArgs<WebhookEvent[]>) => {
                    if (!value?.length)
                      return <Empty title={t('No Events Selected')} />

                    return value.map((event) => (
                      <SelectedEventsValue key={event.id}>
                        <TrashIcon
                          type="trash"
                          data-testid={`${event.name}-delete`}
                          onClick={() => {
                            setValue(
                              'events',
                              getValues().events.filter(
                                (selectedEvent) => event.id !== selectedEvent.id
                              )
                            )
                          }}
                        />
                        <ButtonContainer>
                          <p>
                            {event.name} <Code>({event.code})</Code>
                          </p>
                          <Button
                            type="primary-link"
                            onClick={() => setDisplayedSampleEvent(event)}
                          >
                            {t('View sample data')}
                          </Button>
                        </ButtonContainer>
                      </SelectedEventsValue>
                    ))
                  }}
                />
              </SelectedEventsContainer>
            </>
          </FormContainer>
        </FormProvider>
      )}
    </RightPopup>
  )
}

export default CreateEditWebhooksForm

const DisplayedEventContainer = tw.div`mb-6`

const IconContainer = tw.div`flex justify-between items-center`

const IconTitle = tw.h1`text-2xl font-semibold`

const XIcon = tw(Icon)`text-xl`

const NameAndCode = tw.p`text-lg text-gray-600 mb-6`

const RadioContainer = tw.div`mb-6`

const SelectContainer = tw.div`flex items-center justify-between mb-2 mt-4`

const EventTitle = tw.h3`text-xl`

const SelectedEventsTitle = tw.h3`text-xl mb-2 mt-4`

const SelectedEventsContainer = tw.div`h-64 overflow-auto bg-gray-50 rounded border border-gray-300`

const SelectedEventsValue = tw.div`text-gray-900 py-1 hover:bg-gray-200 px-4 flex items-center cursor-pointer`

const TrashIcon = tw(Icon)`text-gray-500 flex-shrink-0`

const ButtonContainer = tw.div`ml-4`

const Code = tw.span`text-gray-500`

const OverlayScrollbarContainer = styled(OverlayScrollbarsComponent)<{
  width: string
}>`
  ${tw`p-6 h-full`}
  ${({ width }) => `
    width: min(${width}, 100vw);
  `}
`

const FormContainer = styled.form(({ invisible }: { invisible: boolean }) => [
  invisible && tw`invisible`,
])
