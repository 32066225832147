import React, { useEffect } from 'react'
import { RightPopup, TextField, Switch, Icon } from 'elements'
import { Button, Tooltip } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  useCreatePartnerTypeMutation,
  useUpdatePartnerTypeMutation,
} from 'hooks/partners'
import { isPresent } from 'utils'
import tw from 'twin.macro'

type PartnerTypesFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  partnerType?: PartnerType | undefined
}

const PartnerTypesForm = ({
  partnerType,
  isFormOpen,
  setIsFormOpen,
}: PartnerTypesFormProps) => {
  const { t } = useTranslation()

  const formMethods = useForm<CreatePartnerTypeForm>({
    defaultValues: partnerType ? partnerType : undefined,
  })
  const { handleSubmit, errors, reset } = formMethods

  const createPartnerType = useCreatePartnerTypeMutation()
  const updatePartnerType = useUpdatePartnerTypeMutation()

  // Reset form fields if isFormOpen toggled true
  useEffect(() => {
    if (isFormOpen) {
      reset(
        partnerType || {
          name: '',
          code: '',
          description: '',
          isOrganization: false,
        }
      )
    }
  }, [isFormOpen])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={partnerType ? t('Edit Partner Type') : t('Create Partner Type')}
      controls={
        <>
          <Button
            type="primary-filled"
            isLoading={
              createPartnerType.isLoading || updatePartnerType.isLoading
            }
            onClick={handleSubmit(async (formData) => {
              if (partnerType) {
                updatePartnerType.mutate(
                  {
                    req: Object.entries({
                      name: formData.name,
                      code: formData.code,
                      description: formData.description,
                      // TODO: allow mutating isOrganization waiting on https://github.com/electronic-caregiver/svcs-api-organizations/issues/111
                      // isOrganization: formData.isOrganization,
                      directPayout: formData.directPayout,
                      successLadderBonus: formData.successLadderBonus,
                    })
                      .filter((field): field is [string, string | boolean] =>
                        isPresent(field[1])
                      )
                      .map(([fieldKey, fieldValue]) => ({
                        value: fieldValue,
                        path: `/${fieldKey}`,
                        op: 'replace',
                      })),
                    partnerTypeData: { ...partnerType, ...formData },
                  },
                  { onSuccess: () => setIsFormOpen(false) }
                )
              } else {
                createPartnerType.mutate(
                  {
                    partnerTypeData: { ...formData },
                  },
                  {
                    onSuccess: () => setIsFormOpen(false),
                  }
                )
              }
            })}
          >
            {partnerType ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            disabled={
              createPartnerType.isLoading || updatePartnerType.isLoading
            }
            onClick={() => setIsFormOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Partner Type Name is required') }}
            name="name"
            label={t('Name')}
            className="w-96"
            error={errors.name?.message}
            required
          />

          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Partner Type Code is required') }}
            name="code"
            label={t('Code')}
            className="w-96"
            error={errors.code?.message}
            required
          />
          <Controller
            render={({ onChange, value, name, ref }) => (
              <Switch
                onChange={onChange}
                checked={value}
                id={name}
                ref={ref}
                label={t('Organization')}
                className="mr-2 my-3"
              />
            )}
            defaultValue={false}
            name="isOrganization"
          />
          <DirectPayoutSwitch>
            <Controller
              render={({ onChange, value, name, ref }) => (
                <Switch
                  onChange={onChange}
                  checked={value}
                  id={name}
                  ref={ref}
                  label={t('Direct Payout Only')}
                  className="mr-2"
                />
              )}
              defaultValue={false}
              name="directPayout"
            />
            <Tooltip
              className="flex items-center"
              content={
                <p className="w-60">{`${t(
                  'Selecting this option implies that the earnings of individuals with this partner type will solely be offered to the individual in question'
                )}.`}</p>
              }
            >
              <InfoIcon type="info" />
            </Tooltip>
          </DirectPayoutSwitch>
          <Controller
            render={({ onChange, value, name, ref }) => (
              <Switch
                onChange={onChange}
                checked={value}
                id={name}
                ref={ref}
                label={t('Success Ladder Bonus')}
                className="mr-2 my-3"
              />
            )}
            defaultValue={false}
            name="successLadderBonus"
          />

          <Controller
            as={TextField}
            // this is purely here to prevent console.warns
            defaultValue=""
            name="description"
            label={t('Description')}
            className="mt-4"
            multiline
            fullWidth
            //@ts-expect-error it's fine if the required message is undefined
            rules={{ required: t('Description is required') }}
            error={errors.description?.message}
            required
          />
        </form>
      </FormProvider>
    </RightPopup>
  )
}

export default PartnerTypesForm

const DirectPayoutSwitch = tw.span`flex items-center`

const InfoIcon = tw(
  Icon
)`text-gray-600 hover:text-gray-900 w-5 h-5 align-middle`
