import React, { useState } from 'react'
import { Layout, OrganizationSelect } from 'elements'
import { Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { useParams } from 'react-router-dom'
import { useOrganizationQuery } from 'hooks/organizations'
import {
  OrganizationDetailsContainer,
  AneltoCredentials,
} from 'components/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  useOrganizationHierarchyQuery,
  useSubscribersQuery,
  useOrganizationOnboardingQuery,
} from 'hooks/organizations'
import { useStaffListQuery } from 'hooks/user-staff'

const OrganizationDetails = () => {
  const { orgid } = useParams()
  const {
    assumedOrganizationRole,
    assumeOrganizationRole,
  } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organizationOnboardingQuery = useOrganizationOnboardingQuery({
    organizationId: orgid,
  })

  const [isAneltoFormOpen, setIsAneltoFormOpen] = useState<boolean>(false)
  const { data: organizationDetails, isLoading } = useOrganizationQuery(
    orgid || assumedOrganizationRole?.orgID
  )

  // if the orgid is the same as the assumed organization redirect to /organization
  if (orgid === assumedOrganizationRole?.orgID) navigate('/organization')

  const { isLoading: isLoadingStaffList } = useStaffListQuery(
    organizationDetails?.id
  )
  const subscribers = useSubscribersQuery()
  const {
    isLoading: isLoadingOrganizationHeirarchy,
  } = useOrganizationHierarchyQuery(organizationDetails?.id)

  return (
    <Layout
      title={
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold">{t('Organization Details')}:</h1>
          <OrganizationSelect />
        </div>
      }
      isLoading={
        isLoading ||
        !organizationDetails ||
        isLoadingStaffList ||
        subscribers.isLoading ||
        isLoadingOrganizationHeirarchy ||
        organizationOnboardingQuery.isLoading
      }
      isEmpty={!organizationDetails}
      emptyComponent={
        <Empty
          title={t('Organization Not Found')}
          description={t(
            `Either this organization doesn't exist or you don't have access to view it`
          )}
        />
      }
      breadcrumbs={[
        {
          name: assumedOrganizationRole
            ? t('Organization')
            : t('Organizations'),
          route: assumedOrganizationRole ? '/organization' : '/organizations',
        },
        {
          name: organizationDetails?.businessName,
        },
      ]}
      description={t('View details related to a specific organization.')}
      controls={[
        {
          label: t('Assume Role'),
          onClick: () =>
            assumeOrganizationRole({
              // @ts-expect-error layout handles the empty case
              orgID: organizationDetails.id,
            }),
        },
        {
          label: t('Set Anelto Credentials'),
          onClick: () => setIsAneltoFormOpen(true),
        },
      ]}
      key={organizationDetails?.id}
    >
      <OrganizationDetailsContainer
        // @ts-expect-error layout handles the empty case
        organization={organizationDetails}
      />
      <AneltoCredentials
        isAneltoFormOpen={isAneltoFormOpen}
        setIsAneltoFormOpen={setIsAneltoFormOpen}
        organizationId={organizationDetails?.id}
      />
    </Layout>
  )
}

export default OrganizationDetails
