const translation: Translation = {
  translation: {
    Home: 'Home',
    Organizations: 'Organizations',
    Vitals: 'Vitals',
    Account: 'Account',
    Logout: 'Logout',
    'Account Settings': 'Account Settings',
    Update: 'Update',
    Cancel: 'Cancel',
    NAME: 'NAME',
    'TOTAL SUBSCRIBERS': 'TOTAL SUBSCRIBERS',
    ACTIONS: 'ACTIONS',
    'Assume Role': 'Assume Role',
    'Assumed organization role of': 'Assumed organization role of',
    'Search organizations': 'Search organizations',
    'Create Vital Sign': 'Create Vital Sign',
    'Archived Vital Signs': 'Archived Vital Signs',
    'Audit Trail': 'Audit Trail',
    'Account settings updated': 'Account settings updated',
    Edit: 'Edit',
    Archive: 'Archive',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    activated: 'activated',
    deactivated: 'deactivated',
    'Vital sign': 'Vital sign',
    archived: 'archived',
    restored: 'restored',
    Create: 'Create',
    'Vital Sign Name is required': 'Vital Sign Name is required',
    'Vital Sign Name': 'Vital Sign Name',
    'Display Name is required': 'Display Name is required',
    'Display Name': 'Display Name',
    'Code is required': 'Code is required',
    Code: 'Code',
    'Vital Sign Unit of Measurement': 'Vital Sign Unit of Measurement',
    'Unit Name is required': 'Unit Name is required',
    'Unit Name': 'Unit Name',
    'Unit Display Name is required': 'Unit Display Name is required',
    'Unit Display Name': 'Unit Display Name',
    'Unit Code is required': 'Unit Code is required',
    'Unit Code': 'Unit Code',
    'Save Unit': 'Save Unit',
    'No Units Added Yet': 'No Units Added Yet',
    'Add one or more units of measurement for this Vital Sign by clicking the "Add Unit" button':
      'Add one or more units of measurement for this Vital Sign by clicking the "Add Unit" button.',
    'Add Unit': 'Add Unit',
    'Vital sign created': 'Vital sign created',
    'Vital sign updated': 'Vital sign updated',
    'Archived Vitals': 'Archived Vitals',
    'No Archived Vital Sign Assignments Found':
      'No Archived Vital Sign Assignments Found',
    Restore: 'Restore',
    Delete: 'Delete',
    'Vital sign deleted': 'Vital sign deleted',
    'Assumed Role': 'Assumed Role',
    'Discard assumed role': 'Discard assumed role',
    'Discarded assumed organization role':
      'Discarded assumed organization role',
    Staff: 'Staff',
    Subscribers: 'Subscribers',
    'Create Staff Member': 'Create Staff Member',
    ORGANIZATION: 'ORGANIZATION',
    'Try changing your search term or contact your administrator':
      'Try changing your search term or contact your administrator',
    'No Data Found': 'No Data Found',
    'Search staff': 'Search staff',
    Details: 'Details',
    'Search subscribers': 'Search subscribers',
    'Create Subscriber': 'Create Subscriber',
    'Vitals Audit Trail': 'Vitals Audit Trail',
    'VITAL SIGN': 'VITAL SIGN',
    'ACTION TYPE': 'ACTION TYPE',
    'MODIFIED BY': 'MODIFIED BY',
    'MODIFIED AT': 'MODIFIED AT',
    DETAILS: 'DETAILS',
    'Active Vital Signs': 'Active Vital Signs',
    'Edit Vital Sign': 'Edit Vital Sign',
    'Audit Item Details': 'Audit Item Details',
    'No audit events found': 'No audit events found',
    Units: 'Units',
    'Access Control': 'Access Control',
    Modules: 'Modules',
    Applications: 'Applications',
    Permissions: 'Permissions',
    Policies: 'Policies',
    Settings: 'Settings',
    'You must assume the role of an Organization to view this page':
      'You must assume the role of an Organization to view this page',
    Unauthorized: 'Unauthorized',
    'Vital Signs': 'Vital Signs',
    'Vitals Settings': 'Vitals Settings',
    'No Vital Sign Assignments Assigned Yet':
      'No Vital Sign Assignments Assigned Yet',
    'Assign one or more Vital Sign Assignments by clicking the "Assign Vitals Sign" button':
      'Assign one or more Vital Sign Assignments by clicking the "Assign Vitals Sign" button',
    'Vital sign assigned to this organization':
      'Vital sign assigned to this organization',
    Assign: 'Assign',
    'Assign Vital Sign': 'Assign Vital Sign',
    Inactive: 'Inactive',
    'Inactive By ECG Admin': 'Inactive By ECG Admin',
    Active: 'Active',
    Archived: 'Archived',
    'Archived By ECG Admin': 'Archived By ECG Admin',
    'Create Module': 'Create Module',
    'Module Name': 'Module Name',
    Description: 'Description',
    Add: 'Add',
    'Module Name is required': 'Module Name is required',
    'Module created': 'Module created',
    'No Vital Sign Assignments Assigned To This Organization Yet':
      'No Vital Sign Assignments Assigned To This Organization Yet',
    'Assign one or more Vital Sign Assignments by going to "Vital Signs" on the Settings page':
      'Assign one or more Vital Sign Assignments by going to "Vital Signs" on the Settings page',
    'Vitals Thresholds': 'Vitals Thresholds',
    'Vitals Thresholds Settings': 'Vitals Thresholds Settings',
    Normal: 'Normal',
    Low: 'Low',
    High: 'High',
    'Severity Level': 'Severity Level',
    'Manage Module': 'Manage Module',
    'Assigned Applications': 'Assigned Applications',
    'Module Permissions': 'Module Permissions',
    'Create Permissions': 'Create Permissions',
    'Manage Applications': 'Manage Applications',
    Remove: 'Remove',
    Manage: 'Manage',
    Severity: 'Severity',
    'High Risk': 'High Risk',
    'Medium Risk': 'Medium Risk',
    'Low Risk': 'Low Risk',
    'Update Thresholds': 'Update Thresholds',
    LOW: 'LOW',
    HIGH: 'HIGH',
    Thresholds: 'Thresholds',
    'Vital Thresholds updated': 'Vital Thresholds updated',
    'Normal Low': 'Normal Low',
    'Normal High': 'Normal High',
    Range: 'Range',
    'Edit Module': 'Edit Module',
    'Module updated': 'Module updated',
    'Assign Applications': 'Assign Applications',
    'Applications assigned': 'Applications assigned',
    'Staff Members': 'Staff Members',
    'Parent Organization': 'Parent Organization',
    'View Flat List': 'View Flat List',
    'View Nested List': 'View Nested List',
    'Organization Directory': 'Organization Directory',
    'No sub organizations found for this organization':
      'No sub organizations found for this organization',
    'Subscriber Not Found': 'Subscriber Not Found',
    'Create & Add Another': 'Create & Add Another',
    'Create & Close': 'Create & Close',
    'Permission created': 'Permission created',
    'Permission Name': 'Permission Name',
    'Permission Code': 'Permission Code',
    'Permission Name is required': 'Permission Name is required',
    'Permission Code is required': 'Permission Code is required',
    Reminders: 'Reminders',
    'Edit Reminder Messages': 'Edit Reminder Messages',
    'Reminder Messages': 'Reminder Messages',
    Translations: 'Translations',
    LOCALE: 'LOCALE',
    Locale: 'Locale',
    'MESSAGE TYPE': 'MESSAGE TYPE',
    'Modified By': 'Modified By',
    'Reminders Audit Trail': 'Reminders Audit Trail',
    'Edit Translation': 'Edit Translation',
    translation: 'translation',
    'Are you sure you want to delete this translation? This will remove it completely':
      'Are you sure you want to delete this translation? This will remove it completely.',
    'Reminder messages updated': 'Reminder messages updated',
    'Reminder messages failed to update': 'Reminder messages failed to update',
    'Translation successfully deleted': 'Translation successfully deleted',
    'Translation failed to delete': 'Translation failed to delete',
    'Translation updated': 'Translation updated',
    'Translation messages failed to update':
      'Translation messages failed to update',
    'message is required': 'message is required',
    'Search audit trail': 'Search audit trail',
    'General Information': 'General Information',
    Organization: 'Organization',
    Email: 'Email',
    PCP: 'PCP',
    Age: 'Age',
    Location: 'Location',
    Phone: 'Phone',
    'Language Preference': 'Language Preference',
    Alerts: 'Alerts',
    'View subscriber-level vital signs, vital measurements, and vital thresholds':
      'View subscriber-level vital signs, vital measurements, and vital thresholds.',
    'View subscriber-level reminder types, messages, and missed reminders':
      'View subscriber-level reminder types, messages, and missed reminders.',
    'View subscriber-level active alerts and alert history':
      'View subscriber-level active alerts and alert history.',
    'Last Alert': 'Last Alert',
    'Active For': 'Active For',
    'Weight Medium Risk Threshold': 'Weight Medium Risk Threshold',
    ago: 'ago',
    pending: 'pending',
    'Organization Subscribers': 'Organization Subscribers',
    'Subscriber Vitals': 'Subscriber Vitals',
    'Graph View': 'Graph View',
    'List View': 'List View',
    'Vital Sign': 'Vital Sign',
    OBSERVATION: 'OBSERVATION',
    TIMESTAMP: 'TIMESTAMP',
    'Create and manage vital signs': 'Create and manage vital signs',
    'Create and manage organization reminder messages and translations':
      'Create and manage organization reminder messages and translations.',
    'Create Translation': 'Create Translation',
    'Policy Groups': 'Policy Groups',
    'Date Range': 'Date Range',
    Start: 'Start',
    End: 'End',
    'Assign and manage organization-level vital signs':
      'Assign and manage organization-level vital signs.',
    'Manage organization-level vital sign thresholds':
      'Manage organization-level vital sign thresholds.',
    'Create Reminder Messages': 'Create Reminder Messages',
    'No Reminder Messages Created Yet': 'No Reminder Messages Created Yet',
    'Create reminder messages by clicking the Create Reminder Messages button':
      'Create reminder messages by clicking the "Create Reminder Messages" button.',
    'Use ECG default messages for organization':
      'Use ECG default messages for organization',
    'No Modules Found': 'No Modules Found',
    STATUS: 'STATUS',
    CODE: 'CODE',
    MODULE: 'MODULE',
    DESCRIPTION: 'DESCRIPTION',
    'Archived Permissions': 'Archived Permissions',
    'Here are all the Permissions available for the ECG Platform':
      'Here are all the Permissions available for the ECG Platform',
    'Search permissions': 'Search permissions',
    'No Permissions Found': 'No Permissions Found',
    Module: 'Module',
    'Language Locale': 'Language Locale',
    'Create and manage modules (Vitals Module, Medications Module, etc) for the platform':
      'Create and manage modules (Vitals Module, Medications Module, etc.) for the platform.',
    'Create and manage applications (ARPM, Addison, etc) for the platform':
      'Create and manage applications (ARPM, Addison, etc.) for the platform.',
    'Create and manage permissions (vitals-readonly, medications-admin, etc) for the platform':
      'Create and manage permissions (vitals.readonly, medications.admin, etc.) for the platform.',
    'Create and manage policies (groups of permissions) for the platform':
      'Create and manage policies (groups of permissions) for the platform.',
    'Create and manage policy groups (groups of policies) for the platform':
      'Create and manage policy groups (groups of policies) for the platform.',
    'Create Policies': 'Create Policies',
    'Archived Policies': 'Archived Policies',
    'Search policies': 'Search policies',
    'No Policies Found': 'No Policies Found',
    'Policy Name': 'Policy Name',
    'Policy Code': 'Policy Code',
    'Available Permissions': 'Available Permissions',
    'No Permissions Added Yet': 'No Permissions Added Yet',
    'Add Selected Permissions To Policy': 'Add Selected Permissions To Policy',
    'You can group permissions from various modules into a POLICY':
      'You can group permissions from various modules into a POLICY',
    'Policies grant a set of permissions to assigned users':
      'Policies grant a set of permissions to assigned users',
    'At least one module must exist in order to assign permissions to a policy':
      'At least one module must exist in order to assign permissions to a policy',
    'Create Modules': 'Create Modules',
    'Selected Permissions': 'Selected Permissions',
    'No Permissions Available In This Module':
      'No Permissions Available In This Module',
    'Create Policy Groups': 'Create Policy Groups',
    'Archived Policy Groups': 'Archived Policy Groups',
    'Search policy groups': 'Search policy groups',
    'No Policy Groups Found': 'No Policy Groups Found',
    'You can group policies into a POLICY GROUP':
      'You can group policies into a POLICY GROUP',
    'Policy groups grant a set of policies to assigned users':
      'Policy groups grant a set of policies to assigned users',
    'Policy Group Title': 'Policy Group Title',
    'Policy Group Code': 'Policy Group Code',
    'Add Policies': 'Add Policies',
    'Add Selected Policies To Policy Group':
      'Add Selected Policies To Policy Group',
    'No Policies Added Yet': 'No Policies Added Yet',
    'No Policies Available To Add': 'No Policies Available To Add',
    'Selected Policies': 'Selected Policies',
    'At least one policy must exist in order to assign policies to a policy group':
      'At least one policy must exist in order to assign policies to a policy group',
    'Edit Archived Policy': 'Edit Archived Policy',
    'Permission updated': 'Permission updated',
    'Permission deactivated': 'Permission deactivated',
    'Permission activated': 'Permission activated',
    'Permission archived': 'Permission archived',
    'Permission restored': 'Permission restored',
    'Permission deleted': 'Permission deleted',
    'Policy created': 'Policy created',
    'Policy updated': 'Policy updated',
    'Policy deactivated': 'Policy deactivated',
    'Policy activated': 'Policy activated',
    'Policy archived': 'Policy archived',
    'Policy restored': 'Policy restored',
    'Policy deleted': 'Policy deleted',
    'Policy Group created': 'Policy Group created',
    'Policy Group updated': 'Policy Group updated',
    'Policy Group deactivated': 'Policy Group deactivated',
    'Policy Group activated': 'Policy Group activated',
    'Policy Group archived': 'Policy Group archived',
    'Policy Group restored': 'Policy Group restored',
    'Policy Group deleted': 'Policy Group deleted',
    'Application created': 'Application created',
    'Application updated': 'Application updated',
    'Application deactivated': 'Application deactivated',
    'Application activated': 'Application activated',
    'Application archived': 'Application archived',
    'Application restored': 'Application restored',
    'Application deleted': 'Application deleted',
    'Create Applications': 'Create Applications',
    'Archived Applications': 'Archived Applications',
    'Search applications': 'Search applications',
    'No Applications Found': 'No Applications Found',
    'COGNITO APP ID': 'COGNITO APP ID',
    'Edit Application': 'Edit Application',
    'Edit Archived Application': 'Edit Archived Application',
    'Available Modules': 'Available Modules',
    'Add Selected Modules To Application':
      'Add Selected Modules To Application',
    'Selected Modules': 'Selected Modules',
    'No Modules Selected': 'No Modules Selected',
    'Cognito App Id': 'Cognito App Id',
    'At least one module must exist in order to assign modules to a Application':
      'At least one module must exist in order to assign modules to a Application',
    'No Policy Groups Created': 'No Policy Groups Created',
    'Application Name': 'Application Name',
    'No Policies Created': 'No Policies Created',
    'No Permissions Created': 'No Permissions Created',
    'No Applications Created': 'No Applications Created',
    'No Modules Created': 'No Modules Created',
    DATE: 'DATE',
    ISSUE: 'ISSUE',
    Issue: 'Issue',
    'DATE ADDRESSED': 'DATE ADDRESSED',
    'ADDRESSED BY': 'ADDRESSED BY',
    'Addressed By': 'Addressed By',
    'Search alerts': 'Search alerts',
    'Try changing your search term': 'Try changing your search term',
    Disclaimers: 'Disclaimers',
    'Manage disclaimer types, messages, and translations for the platform':
      'Manage disclaimer types, messages, and translations for the platform.',
    'Basic Information': 'Basic Information',
    'Personal Information': 'Personal Information',
    Prefix: 'Prefix',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    Suffix: 'Suffix',
    'Staff Member Type': 'Staff Member Type',
    'Assign Staff Member As Provider': 'Assign Staff Member As Provider',
    'Selecting this option will allow this staff member to act as a provider for subscribers':
      'Selecting this option will allow this staff member to act as a provider for subscribers',
    'This gives them access rights to view and manage PHI':
      'This gives them access rights to view and manage PHI',
    'Contact Information': 'Contact Information',
    'Contact Email Address': 'Contact Email Address',
    'Account Information': 'Account Information',
    'Creating a staff member for': 'Creating a staff member for',
    'Create Staff': 'Create Staff',
    'Account Email Address': 'Account Email Address',
    'Password Options': 'Password Options',
    'Auto-Generated': 'Auto-Generated',
    Manual: 'Manual',
    'This information will be emailed to the staff member once the user has been created':
      'This information will be emailed to the staff member once the user has been created',
    'They will then be able to login to the RPM Platform with these credentials':
      'They will then be able to login to the RPM Platform with these credentials',
    'Save & Continue': 'Save & Continue',
    'First Name is required': 'First Name is required',
    'Last Name is required': 'Last Name is required',
    'Account Email is required': 'Account Email is required',
    'Password is required': 'Password is required',
    Password: 'Password',
    Back: 'Back',
    'Assign Policies': 'Assign Policies',
    'No Policies Assigned Yet': 'No Policies Assigned Yet',
    'No Policies are assigned to this Staff Member yet':
      'No Policies are assigned to this Staff Member yet',
    'Click on the button below to grant access rights to this Staff Member':
      'Click on the button below to grant access rights to this Staff Member',
    Save: 'Save',
    'Add Selected Policies': 'Add Selected Policies',
    'No Policies Selected': 'No Policies Selected',
    'Contact Email is required': 'Contact Email is required',
    'No Sub-Organizations Found': 'No Sub-Organizations Found',
    'Create a sub-organization through Apptivo and it will show up here':
      'Create a sub-organization through Apptivo and it will show up here.',
    'No Organizations Found': 'No Organizations Found',
    'Contact your administrator to get assigned to correct organization':
      'Contact your administrator to get assigned to correct organization.',
    'View, add and manage organization reminder presets':
      'View, add and manage organization reminder presets.',
    'View, add and manage organization webhooks':
      'View, add and manage organization webhooks.',
    'Search webhooks': 'Search webhooks',
    'No Webhooks Found': 'No Webhooks Found',
    Webhooks: 'Webhooks',
    URL: 'URL',
    VERSION: 'VERSION',
    MODE: 'MODE',
    ERRORS: 'ERRORS',
    'Reminder time should be set relative to the subscribers timezone':
      "Reminder time should be set relative to the subscriber's timezone.",
    ADMIN: 'ADMIN',
    Admins: 'Admins',
    'Search admins': 'Search admins',
    'Create Admin': 'Create Admin',
    'This information will be emailed to the admin once the user has been created':
      'This information will be emailed to the admin once the user has been created',
    'They will then be able to login to ADDI with these credentials':
      'They will then be able to login to ADDI with these credentials',
    'No Policies are assigned to this Admin yet':
      'No Policies are assigned to this Admin yet',
    'Click on the button below to grant access rights to this Admin':
      'Click on the button below to grant access rights to this Admin',
    'This organization has at least one assigned vital sign with no thresholds set':
      'This organization has at least one assigned vital sign with no thresholds set',
    'Create Thresholds': 'Create Thresholds',
    'Create Vital Thresholds': 'Create Vital Thresholds',
    'Normal Low is required': 'Normal Low is required',
    'Normal High is required': 'Normal High is required',
    'Go To Vital Signs': 'Go To Vital Signs',
    'Assign & Add Another': 'Assign & Add Another',
    'Assign & Close': 'Assign & Close',
    'No Vitals Thresholds Assigned To This Organization Yet':
      'No Vitals Thresholds Assigned To This Organization Yet',
    'Subscriber Thresholds': 'Subscriber Thresholds',
    'Default Organization Thresholds': 'Default Organization Thresholds',
    'No Default Organization Thresholds Found':
      'No Default Organization Thresholds Found',
    'Assign Thresholds': 'Assign Thresholds',
    'Global Platform Overview': 'Global Platform Overview',
    Overview: 'Overview',
    'Language preference updated': 'Language preference updated',
  },
}

export default translation
