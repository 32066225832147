import React, { useState } from 'react'
import { Layout } from 'elements'
import { Empty } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import {
  CreateEditPermissionForm,
  PermissionsList,
} from 'components/access-control/permissions'
import { usePermissionsQuery } from 'hooks/access-control/permissions'
import { useNavigate } from 'hooks'
import tw from 'twin.macro'

const AccessControlPermissions = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const {
    data: permissions,
    isLoading: isLoadingPermissions,
  } = usePermissionsQuery()
  const [
    isCreateEditPermissionFormOpen,
    setIsCreateEditPermissionFormOpen,
  ] = useState(false)
  const [permission, setPermission] = useState<Permission | undefined>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEditPermission = (permission: Permission) => {
    setPermission(permission)
    setIsCreateEditPermissionFormOpen(true)
  }

  const handlePermissionFormToggle = (isFormOpen: boolean) => {
    // If the form is closing, reset the selected Permission to edit
    if (isFormOpen) setPermission(undefined)

    // Call the normal setState
    setIsCreateEditPermissionFormOpen(isFormOpen)
  }

  const filteredPermissions =
    permissions?.items?.filter(
      (permission) => !permission.archiveInfo.archived
    ) || []

  return (
    <>
      <Layout
        title={t('Permissions')}
        description={t(
          'Create and manage permissions available to the platform.'
        )}
        breadcrumbs={[
          { name: t('Access Control'), route: '/access-control' },
          { name: t('Permissions') },
        ]}
        controls={[
          {
            label: t('Create Permissions'),
            onClick: () => handlePermissionFormToggle(true),
          },
          {
            label: t('View Archived Permissions'),
            onClick: () => navigate('archive'),
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
          },
        ]}
        isLoading={isLoadingPermissions}
        isUnauthorized={
          !accountPermissions?.data.ecgAdmin.permissions.includes(
            'acm.readonly'
          )
        }
        isEmpty={filteredPermissions.length === 0}
        emptyComponent={
          <PermissionsEmpty title={t('No Permissions Created')} />
        }
      >
        <PermissionsList
          // Only show unarchived permissions
          data={filteredPermissions}
          onEditPermission={handleEditPermission}
        />
      </Layout>
      <CreateEditPermissionForm
        isFormOpen={isCreateEditPermissionFormOpen}
        setIsFormOpen={handlePermissionFormToggle}
        permission={permission}
      />
    </>
  )
}

export default AccessControlPermissions

const PermissionsEmpty = tw(
  Empty
)`flex flex-grow border-2 border-dashed rounded`
