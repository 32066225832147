import React, { useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Layout } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { PartnerTypeForm, PartnerTypeList } from 'components/partners'
import tw from 'twin.macro'

const PartnerTypes = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const [isPartnerTypeFormOpen, setIsPartnerTypeFormOpen] = useState<boolean>(
    false
  )
  const [selectedPartnerType, setSelectedPartnerType] = useState<
    PartnerType | undefined
  >()

  const handleEditPartnerType = (partnerType: PartnerType) => {
    setSelectedPartnerType(partnerType)
    setIsPartnerTypeFormOpen(true)
  }

  return (
    <>
      <Layout
        title={t('Partner Types')}
        description={t(
          'View and edit partner type commissions setup and hierarchy.'
        )}
        isUnauthorized={!!assumedOrganizationRole}
        controls={
          <Button
            type="primary-filled"
            onClick={() => setIsPartnerTypeFormOpen(true)}
          >
            {t('Create Partner Type')}
          </Button>
        }
        breadcrumbs={[
          {
            name: t('Partners'),
            route: '/partners',
          },
          {
            name: t('Partner Types'),
          },
        ]}
      >
        <PartnerTypeListContainer>
          <PartnerTypeList onEditPartnerType={handleEditPartnerType} />
        </PartnerTypeListContainer>
      </Layout>
      <PartnerTypeForm
        isFormOpen={isPartnerTypeFormOpen}
        setIsFormOpen={(toggle) => {
          setSelectedPartnerType(undefined)
          setIsPartnerTypeFormOpen(toggle)
        }}
        partnerType={selectedPartnerType}
      />
    </>
  )
}

export default PartnerTypes

const PartnerTypeListContainer = tw.div`flex flex-col flex-grow`
