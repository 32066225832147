import React, { useState } from 'react'
import { FadeInSlideDown } from 'animations'
import { Empty } from 'atlas'
import { OrganizationStaffHierarchyItem } from 'components/organizations'
import {
  EditStaffForm,
  StaffInfoCard,
  StaffMemberSelect,
} from 'components/staff'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { Icon, Layout, NavigationCard } from 'elements'
import { useDelay, useNavigate } from 'hooks'
import { useOrganizationHierarchyQuery } from 'hooks/organizations'
import {
  useBlockedStaffOrganizationsQuery,
  useStaffListQuery,
  useStaffQuery,
} from 'hooks/user-staff'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'
import { sortOrgHierarchy } from 'utils'

const StaffDetails = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const delay = useDelay()
  const { staffid } = useParams()

  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
  const [isListFlat, setIsListFlat] = useState<boolean>(false)

  const staffListQuery = useStaffListQuery(assumedOrganizationRole?.orgID)
  const { data: staffMember, isLoading } = useStaffQuery(
    assumedOrganizationRole?.orgID || '',
    staffid
  )

  const organizationHierarchyQuery = useOrganizationHierarchyQuery(
    assumedOrganizationRole?.orgID
  )

  const blockedStaffOrganizationsQuery = useBlockedStaffOrganizationsQuery(
    staffid
  )

  const orgHierarchy = sortOrgHierarchy(
    Array.isArray(organizationHierarchyQuery.data)
      ? organizationHierarchyQuery.data.filter((org) => org.level > 1)
      : []
  )

  return (
    <Layout
      description={t('Manage and review staff member-related details.')}
      title={
        <TitleContainer>
          <PageTitle>{t('Staff Member Details')}:</PageTitle>
          <StaffMemberSelect />
        </TitleContainer>
      }
      isLoading={
        isLoading ||
        staffListQuery.isLoading ||
        organizationHierarchyQuery.isLoading
        /**
         * Bypassing loading icon for blocked orgs until issue gets resolved
         * Expect to not block/unblock sub orgs
         * https://github.com/electronic-caregiver/svcs-api-organizations/issues/306
         */
        //|| blockedStaffOrganizationsQuery.isLoading
      }
      isEmpty={!staffMember}
      emptyComponent={
        <Empty
          title={t('Staff Member Not Found')}
          description={t(
            `Either this staff member doesn't exist or you don't have access to view them`
          )}
        />
      }
      breadcrumbs={[
        {
          name: t('Staff'),
          route: '/staff',
        },
        {
          name: `${staffMember?.person.firstName} ${staffMember?.person.lastName}`,
        },
      ]}
      controls={[
        {
          label: t('Edit Personal Details'),
          onClick: () => setIsEditFormOpen(true),
        },
      ]}
    >
      <NavInfoGrid>
        <FadeInSlideDown delay={delay()} className="col-span-4">
          {staffMember ? <StaffInfoCard staffInfo={staffMember} /> : null}
        </FadeInSlideDown>
        <FadeInSlideDown delay={delay()}>
          <NavigationCard
            icon="lock"
            iconColor="pink"
            label={t('Access Control')}
            onClick={() => navigate('access-control')}
            description={t(
              'Manage this specific staff member’s access to data across the platform.'
            )}
          />
        </FadeInSlideDown>
      </NavInfoGrid>
      {/* Organization Hierarchy */}
      <div>
        <HierarchyHeaderContainer>
          <SubHeader>{t('Suborganization Directory')}</SubHeader>
          <HierarchyControlIcon
            type={isListFlat ? 'align-justify' : 'align-right'}
            onClick={() => setIsListFlat(!isListFlat)}
            disabled={!orgHierarchy.find((subOrg) => subOrg.level > 2)}
          />
        </HierarchyHeaderContainer>

        {orgHierarchy.length > 0 ? (
          orgHierarchy.map((org, index) => (
            <OrganizationStaffHierarchyItem
              key={org.id + index}
              isListFlat={isListFlat}
              org={org}
              isBlockedParent={
                blockedStaffOrganizationsQuery.data?.items?.some(
                  (blockedOrg) => blockedOrg.id === org.id
                ) || false
              }
              isBlockedChild={_.flatten(
                blockedStaffOrganizationsQuery.data?.items?.map(
                  (blockedOrg) => blockedOrg.nodes
                )
              ).some((blockedChildOrgId) => blockedChildOrgId === org.id)}
            />
          ))
        ) : (
          <FadeInSlideDown>
            <SubOrganizationEmpty
              title={t('No Data')}
              description={t(
                'No sub-organizations exists for this organization'
              )}
            />
          </FadeInSlideDown>
        )}
      </div>
      {staffMember ? (
        <EditStaffForm
          staff={staffMember}
          isFormOpen={isEditFormOpen}
          setIsFormOpen={setIsEditFormOpen}
        />
      ) : null}
    </Layout>
  )
}

export default StaffDetails

const TitleContainer = tw.div`flex items-center gap-2`

const PageTitle = tw.h1`text-2xl font-bold`

const NavInfoGrid = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`

const SubOrganizationEmpty = tw(Empty)`border-2 border-dashed rounded p-11`

const SubHeader = tw.h3`text-2xl font-semibold inline-block`

const HierarchyHeaderContainer = tw.div`flex justify-between align-middle mt-8 mb-4`

const HierarchyControlIcon = tw(Icon)`text-2xl text-gray-900`
