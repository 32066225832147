import { Button } from 'atlas'
import {
  AddressAutoComplete,
  AutoComplete,
  RadioGroup,
  RightPopup,
  TextField,
} from 'elements'
import {
  useOrganizationQuery,
  useUpdateOrganizationAddressMutation,
} from 'hooks/organizations'
import {
  useAddressTypesQuery,
  useCountryCodesQuery,
  useStateCodesQuery,
} from 'hooks/seed-data'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { addressToMapBox } from 'utils'

type AddressFormProps = {
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  organizationId: string
  addressToMutate?: OrganizationAddress
}

const OrganizationAddressForm = ({
  isFormOpen,
  setIsFormOpen,
  organizationId,
  addressToMutate,
}: AddressFormProps) => {
  const { t } = useTranslation()

  const organizationQuery = useOrganizationQuery(organizationId)

  const addressTypesQuery = useAddressTypesQuery()

  const {
    errors,
    register,
    reset,
    setValue,
    control,
    trigger,
    watch,
    clearErrors,
  } = useForm<OrganizationAddress>({
    defaultValues: {
      addressLine2: '',
      city: '',
      postalCode: '',
    },
    shouldUnregister: false,
  })

  const [customFields, setCustomFields] = useState<CustomFields>({
    address: null,
    country: null,
    province: null,
  })
  const [customErrors, setCustomErrors] = useState<CustomErrors>()

  const [addressTypeId, setAddressTypeId] = useState<number>()

  const [newStateCode, setNewStateCode] = useState<string>()

  const countryCodes = useCountryCodesQuery()

  const stateCodes = useStateCodesQuery(customFields.country?.isoCode || '')

  const updateOrganizationAddressMutation = useUpdateOrganizationAddressMutation()

  // reset form state when popup opens
  useEffect(() => {
    if (isFormOpen) {
      reset(
        addressToMutate || {
          addressLine2: '',
          city: '',
          postalCode: '',
        }
      )

      const country =
        countryCodes.data?.items?.find(
          (country) =>
            country.isoCode === addressToMutate?.countryCode ||
            country.name === addressToMutate?.countryCode
        ) || null

      setCustomFields({
        address: addressToMutate ? addressToMapBox(addressToMutate) : null,
        country,
        province:
          stateCodes.data?.items?.find((province) => {
            return (
              province.isoCode === addressToMutate?.stateProvinceCode ||
              province.name === addressToMutate?.stateProvinceCode
            )
          }) || null,
      })
      setCustomErrors(undefined)
      setNewStateCode(undefined)
      setAddressTypeId(addressToMutate?.addressType)
    }
  }, [isFormOpen, countryCodes.data, addressToMutate])

  // set state code when query loads since the query is dependent on country being set
  useEffect(() => {
    setCustomFields((customFields) => ({
      ...customFields,
      province:
        stateCodes.data?.items?.find((province) =>
          newStateCode?.includes(province.isoCode)
        ) ||
        stateCodes.data?.items?.find(
          (province) =>
            province.isoCode === addressToMutate?.stateProvinceCode ||
            province.name === addressToMutate?.stateProvinceCode
        ) ||
        null,
    }))
  }, [stateCodes.data])

  // reset custom errors if a field is filled
  useEffect(() => {
    setCustomErrors((errors) =>
      Object.fromEntries(
        Object.keys(customFields).map((fieldKey) => [
          fieldKey,
          // if a custom field is falsey continue showing the error field, if not set to undefined
          !customFields[fieldKey as keyof typeof customFields]
            ? errors?.[fieldKey as keyof typeof errors]
            : undefined,
        ])
      )
    )
  }, [customFields])

  return (
    <RightPopup
      open={isFormOpen}
      setOpen={setIsFormOpen}
      title={addressToMutate ? t('Edit Address') : t('Add Address')}
      width="500px"
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={async () => {
              if (!organizationQuery.data) return

              const isCustomFieldsValid = validateCustomFields(
                customFields,
                setCustomErrors,
                t
              )
              if (
                !(await trigger(['city', 'postalCode'])) ||
                !isCustomFieldsValid
              )
                return

              const formData = watch()

              updateOrganizationAddressMutation.mutate(
                {
                  organization: organizationQuery.data,
                  newAddress: {
                    ...formData,
                    // add the custom controlled fields
                    addressLine1:
                      customFields.address?.place_name.split(',')[0] || '',
                    countryCode: customFields.country?.isoCode || '',
                    stateProvinceCode: customFields.province?.isoCode || '',
                  },
                },
                // close popup
                { onSuccess: () => setIsFormOpen(false) }
              )
            }}
            // disables if no address type is specified
            disabled={!addressTypeId}
          >
            {addressToMutate ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      <form>
        <RowContainer>
          <AddressAutoComplete
            selectedOption={customFields.address}
            setSelectedOption={(address) =>
              setCustomFields((fields) => ({ ...fields, address }))
            }
            onChange={(selectedOption) => {
              setValue(
                'postalCode',
                selectedOption?.context.find(
                  (parent) => parent.id.split('.')[0] === 'postcode'
                )?.text || '',
                { shouldDirty: true }
              )
              setValue(
                'city',
                selectedOption?.context.find(
                  (parent) => parent.id.split('.')[0] === 'place'
                )?.text || '',
                { shouldDirty: true }
              )

              const country =
                countryCodes.data?.items?.find(
                  (country) =>
                    country.isoCode.toLowerCase() ===
                    selectedOption?.context.find(
                      (parent) => parent.id.split('.')[0] === 'country'
                    )?.short_code
                ) || null
              setCustomFields({
                address: selectedOption,
                country,
                province: null,
              })
              setNewStateCode(
                selectedOption?.context.find(
                  (parent) => parent.id.split('.')[0] === 'region'
                )?.short_code
              )
              setCustomErrors(undefined)
              clearErrors()
            }}
            label={t('Address *')}
            className="flex-grow"
            error={customErrors?.address}
          />
        </RowContainer>
        <RowContainer>
          <TextField
            name="address.addressLine2"
            className="flex-grow"
            inputRef={register()}
            label={t('Apt / Suite / Other')}
          />
        </RowContainer>
        <RowContainer>
          <AutoComplete
            label={t('Country *')}
            single
            className="flex-grow"
            options={countryCodes.data?.items || []}
            onChange={(country) =>
              setCustomFields((fields) => ({
                ...fields,
                country,
                province: null,
              }))
            }
            selectedOption={customFields.country}
            optionLabel={(country) => country.name}
            error={customErrors?.country}
            disableAutofill
          />
        </RowContainer>
        <RowContainer>
          <Controller
            control={control}
            as={TextField}
            error={errors.city?.message}
            name="city"
            className="flex-grow"
            label={t('City')}
            rules={{ required: { value: true, message: 'City is required' } }}
            required
          />
        </RowContainer>
        <RowContainer>
          <AutoComplete
            label={t('State *')}
            single
            className="w-1/2 mr-4"
            options={stateCodes.data?.items || []}
            onChange={(province) =>
              setCustomFields((fields) => ({ ...fields, province }))
            }
            selectedOption={customFields.province}
            optionLabel={(province) => province.name}
            error={customErrors?.province}
            disableAutofill
          />
          <Controller
            control={control}
            as={TextField}
            label={t('Postal Code')}
            required
            error={errors.postalCode?.message}
            name="postalCode"
            type="number"
            className="flex-grow"
            rules={{
              required: { value: true, message: 'Postal Code is required' },
            }}
          />
        </RowContainer>
        <RadioGroup
          onChange={(newAddressTypeId) => setAddressTypeId(newAddressTypeId)}
          options={addressTypesQuery.data?.items?.map((addressType) => ({
            value: addressType.id,
            label: addressType.name,
          }))}
          checked={addressTypeId}
          label={t('Address Type *')}
          className="flex-grow mb-2"
        />
      </form>
    </RightPopup>
  )
}

export default OrganizationAddressForm

const RowContainer = tw.div`flex transition-all`

type CustomFields = {
  address: MapBoxFeature | null
  country: Country | null
  province: Province | null
}

type CustomErrors = Partial<Record<keyof CustomFields, string>>

const validateCustomFields = (
  customFields: CustomFields,
  setCustomErrors: React.Dispatch<
    React.SetStateAction<CustomErrors | undefined>
  >,
  t: TFunction
) => {
  setCustomErrors({
    address: !customFields.address
      ? t('Address is required')
      : // prevents user from creating an address with only the street defined
      !customFields.address.address
      ? t('This is not an address')
      : undefined,
    country: !customFields.country ? t('Country is required') : undefined,
    province: !customFields.province ? t('State is required') : undefined,
  })
  return (
    Object.values(customFields).every((val) => !!val) &&
    !!customFields.address?.address
  )
}
