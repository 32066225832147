import { success, handleQueryError, vitalsApi } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useAssignSubscriberVitalSignsMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    | VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}']
    | VitalsAPIResponse['POST']['/subscribers/{subscriberId}/vital-signs'],
    KyError<ErrorResponse>,
    AssignVitalSignProps,
    | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
    | undefined
  >(assignVitalSign, {
    onMutate: ({ subscriberId, newVitalSigns }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
      >(['getSubVitalAssignments', subscriberId])

      // optimistic update
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
        | undefined
      >(['getSubVitalAssignments', subscriberId], (old) => ({
        id: old?.id || '',
        subscriberId,
        assignedVitalSigns: [
          ...(old?.assignedVitalSigns.length ? old.assignedVitalSigns : []),
          ...newVitalSigns,
        ],
      }))

      return snapshot
    },
    onSuccess: () => {
      // notify user of successful vital sign assignation
      success({
        message: t('Vital sign assigned'),
      })
    },
    onError: async (error, { subscriberId }, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<
        | VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-sign-assignments']
        | undefined
      >(['getSubVitalAssignments', subscriberId], snapshot)

      handleQueryError({ error })
    },
    onSettled: (_, error, { subscriberId }) => {
      // refetch from API to make sure the new vital sign is in sync with the server
      queryCache.invalidateQueries(['getSubVitalAssignments', subscriberId])
    },
  })

  return mutation
}

export default useAssignSubscriberVitalSignsMutation

type AssignVitalSignProps = {
  newVitalSigns: VitalAssignment[]
  subscriberId: string
  subscriberAssignmentId: string | undefined
}

// Post assigned vital sign to backend
const assignVitalSign = async ({
  newVitalSigns,
  subscriberId,
  subscriberAssignmentId,
}: AssignVitalSignProps) => {
  //  VFE-498  Vital sign type needs to be changed to only accepted id and code
  const request = {
    vitalSigns: newVitalSigns.map((vitalSign) => ({
      id: vitalSign.id,
      code: subscriberAssignmentId ? vitalSign.defaultUnitCode : vitalSign.code,
      name: vitalSign.name,
      displayName: vitalSign.displayName,
      active: vitalSign.active,
      archived: vitalSign.archived,
      units: vitalSign.units,
      defaultUnitCode: vitalSign.defaultUnitCode,
    })),
  }

  const result = subscriberAssignmentId
    ? await vitalsApi
        // create if vital assignments exist for subscriber
        .patch(
          `subscribers/${subscriberId}/vital-sign-assignments/${subscriberAssignmentId}`,
          {
            json: request,
          }
        )
        .json<
          VitalsAPIResponse['PATCH']['/subscribers/{subscriberId}/vital-sign-assignments/{vitalSignAssignmentId}']
        >()
    : await vitalsApi
        // create if no vital assignments exist for subscriber yet
        .post(`subscribers/${subscriberId}/vital-signs`, {
          json: request,
        })
        .json<
          VitalsAPIResponse['POST']['/subscribers/{subscriberId}/vital-signs']
        >()

  return result
}
