import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useUpdateShippingInformationMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['PATCH']['/orders/{orderId}/shipping-information'],
    updateShippingInfoArgs
  >({
    mutationFn: updateShippingInformation,
    successMsg: {
      message: t('Order updated'),
      showOn: 'onSuccess',
    },
    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['order', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          updateShippingInfoArgs
        >((oldOrder, { shippingInformationForm }) =>
          oldOrder
            ? {
                ...oldOrder,
                shippingInformation: { ...shippingInformationForm },
              }
            : undefined
        ),
      },
    ],
  })

  return mutation
}

export default useUpdateShippingInformationMutation

type updateShippingInfoArgs = {
  orderId: string
  shippingInformationForm: OrderShippingInformationForm
}

const updateShippingInformation = async ({
  orderId,
  shippingInformationForm,
}: updateShippingInfoArgs) =>
  ordersApi.patch('/orders/{orderId}/shipping-information', {
    pathParams: { orderId },
    // @ts-expect-error Bad swagger type https://github.com/electronic-caregiver/svcs-orders-api/issues/97
    body: Object.entries(shippingInformationForm).map(([key, value]) => ({
      op: 'replace',
      path: `/${key}`,
      value,
    })),
  })
