import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

// used to get default reminders
const useReminderMessagesQuery = (organizationId: string) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages'],
    KyError
  >(['getReminderMessages', organizationId], () =>
    getReminderMessages(organizationId)
  )

  return query
}

export default useReminderMessagesQuery

// get admin reminders
const getReminderMessages = async (organizationId: string) => {
  const result = await vitalsApi
    .get(`organization/${organizationId}/reminder-messages`)
    .json<
      VitalsAPIResponse['GET']['/organization/{organizationId}/reminder-messages']
    >()

  return result
}
