import React from 'react'
import toast, { Toaster as RHToast } from 'react-hot-toast'

const toastConfig = {
  success: {
    iconTheme: {
      primary: '#34d399',
      secondary: 'white',
    },
  },
  error: {
    iconTheme: {
      primary: '#ef4444',
      secondary: 'white',
    },
  },
}

export const Toaster = (
  <RHToast position="bottom-right" toastOptions={toastConfig} />
)

type SuccessArgs = {
  message: string
}

export const success = ({ message }: SuccessArgs) => {
  return toast.success(message, { duration: 4000 })
}

type ErrorArgs = {
  message: string
}

export const error = ({ message }: ErrorArgs) => {
  return toast.error(message, { duration: 4000 })
}
