import React from 'react'
import { Empty } from 'atlas'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

type OrganizationMetadataProps = {
  metadata: Organization['integrationMetadata']
}

const OrganizationMetadata = ({ metadata }: OrganizationMetadataProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <Header>{t('Metadata')}</Header>
      <Card>
        {metadata ? (
          _.orderBy(Object.entries(metadata), ([key]) => key).map(
            ([key, value]) => (
              <Item key={key}>
                <Label>{key}:</Label>
                &nbsp;&nbsp;
                <Value>{value}</Value>
              </Item>
            )
          )
        ) : (
          <Empty
            title={t('No Data Found')}
            description={t('No metadata exists for this organization')}
          />
        )}
      </Card>
    </div>
  )
}

export default OrganizationMetadata

const Header = tw.h3`text-2xl font-semibold mb-4 mt-8`

const Card = tw.div`grid gap-4 bg-white p-5 rounded-lg border border-gray-200`

const Item = tw.div`flex items-center h-4`

const Label = tw.p`text-gray-500 leading-4`

const Value = tw.p`text-gray-900 leading-4`
