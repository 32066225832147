import React, { useState } from 'react'
import { Empty, Button } from 'atlas'
import { Layout, Dialog, SetupStepsFlow } from 'elements'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { useSubscriberQuery, useSubscribersQuery } from 'hooks/organizations'
import { SubscriberSelect } from 'components/subscribers'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { error } from 'utils'
import { useSubscriberOnboardingQuery } from 'hooks/user-subscriber'
import tw from 'twin.macro'

const SubscriberSetup = () => {
  const {
    assumeOrganizationRole,
    assumedOrganizationRole,
  } = useAssumedOrganizationRole()

  const { t } = useTranslation()

  const { subscriberId } = useParams()

  const navigate = useNavigate()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const [redirect, setRedirect] = useState('')

  const subscribers = useSubscribersQuery()

  const subscriberOnboardingQuery = useSubscriberOnboardingQuery({
    subscriberId,
  })

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  return (
    <Layout
      isLoading={
        isSubLoading ||
        subscriberOnboardingQuery.isLoading ||
        subscribers.isLoading
      }
      title={
        <TitleContainer>
          <Title>{t('Subscriber Setup')}:</Title>
          <SubscriberSelect />
        </TitleContainer>
      }
      isEmpty={!currentSubscriber}
      emptyComponent={
        <Empty
          title={t('Subscriber Not Found')}
          description={t(
            `Either this subscriber doesn't exist or you don't have access to view them`
          )}
        />
      }
      description={t('View and continue the setup process for a subscriber')}
      breadcrumbs={[
        {
          name: t('Subscribers'),
          route: '/subscribers',
        },
        {
          name: `${currentSubscriber?.person.firstName} ${currentSubscriber?.person.lastName}`,
          route: `/subscribers/${subscriberId}`,
        },
        {
          name: t('Setup'),
        },
      ]}
    >
      {subscriberOnboardingQuery.data ? (
        <SetupStepsFlow
          stepsData={(Object.entries(subscriberOnboardingQuery.data) as [
            keyof typeof subscriberOnboardingQuery.data,
            typeof subscriberOnboardingQuery.data[keyof typeof subscriberOnboardingQuery.data]
          ][]).map(([key, value]) => ({
            ...value,
            key,
            onClick: () =>
              // if user hasn't assumed a role, show an assume role prompt
              currentSubscriber?.organization && !assumedOrganizationRole
                ? (setRedirect(onboardingKeyToRoute[key]),
                  setIsDialogOpen(true))
                : navigate(onboardingKeyToRoute[key]),
          }))}
          stepOrder={[
            'vitalSignAssignments',
            'vitalThresholds',
            'reminderSchedules',
          ]}
        />
      ) : null}

      <Dialog
        open={isDialogOpen}
        title={`${t('Assume Role of')} ${
          currentSubscriber?.organization?.businessName
            ? currentSubscriber.organization.businessName
            : null
        }?`}
        content={
          <p>
            {t(
              `To manage settings for this subscriber you must assume the organization's role`
            )}
          </p>
        }
        actions={
          <>
            <Button onClick={() => setIsDialogOpen(false)} type="secondary">
              {t('Cancel')}
            </Button>
            <Button
              type="primary-filled"
              onClick={() => {
                // assume the organization role
                if (!currentSubscriber?.organization) {
                  error({
                    message: t('Organization not found for subscriber'),
                  })
                } else {
                  assumeOrganizationRole({
                    orgID: currentSubscriber.organization.id,
                    onSuccess: (searchParams) =>
                      navigate({ pathname: redirect, searchParams }),
                  })
                }

                setIsDialogOpen(false)
              }}
            >
              {t('Assume Role')}
            </Button>
          </>
        }
      />
    </Layout>
  )
}

export default SubscriberSetup

const TitleContainer = tw.div`flex gap-2`

const Title = tw.h1`text-2xl font-bold`

const onboardingKeyToRoute: {
  [key in keyof VitalsAPIResponse['GET']['/subscribers/{subscriberId}/on-boarding']]: string
} = {
  vitalSignAssignments: '../settings/vital-sign-assignments',
  vitalThresholds: '../settings/vitals-thresholds',
  reminderSchedules: '../settings/reminder-schedules',
}
