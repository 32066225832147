import _ from 'lodash'

/**
 * Converts Vital Assignment form data to VitalAssignment for mutation POST request
 * @param originalAssignments
 * @param displayAssignments
 * @returns an array containing Vital Assignment content
 */
const displayAssignmentsToAssignments = (
  originalAssignments: VitalAssignment[],
  displayAssignments: VitalAssignment[]
) => {
  // if displayAssignments don't contain blood pressure just return them
  if (
    displayAssignments.every(
      (assignment) => !assignment.name?.includes('Blood Pressure')
    )
  )
    return displayAssignments

  // remove displayed blood pressure assignment
  _.remove(displayAssignments, (vitalSign) =>
    vitalSign.name?.includes('Blood Pressure')
  )

  // add the 2 blood pressure vital assignments
  return [
    ...displayAssignments,
    ...originalAssignments.filter((vitalSign) =>
      vitalSign.name?.includes('Blood Pressure')
    ),
  ]
}

export default displayAssignmentsToAssignments
