import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'

// used to get default reminder schedules
const useReminderSchedulesQuery = (subscriberId: string) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders'],
    KyError
  >(['getReminderSchedules', subscriberId], () =>
    getReminderSchedules(subscriberId)
  )

  return query
}

export default useReminderSchedulesQuery

// get reminder schedules
type getReminderSchedulesArgs = (
  subscriberId: string
) => Promise<
  VitalsAPIResponse['GET']['/subscribers/{subscriberId}/vital-reminders']
>

const getReminderSchedules: getReminderSchedulesArgs = async (subscriberId) =>
  typedVitalsApi.get(`/subscribers/{subscriberId}/vital-reminders`, {
    pathParams: { subscriberId },
  })
