import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

const useOrganizationListQuery = ({
  orgId,
  disabled,
  organizationFilters,
  pageLength,
}: {
  orgId?: string
  disabled?: boolean
  organizationFilters?: OrganizationFilters
  pageLength?: number
} = {}) => {
  const query = useQuery<
    { items: Array<OrganizationListItem> } & Paginatable,
    KyError
  >(
    ['getOrganizationList', orgId, organizationFilters],
    () => getOrganizationList(orgId, organizationFilters, pageLength),
    {
      enabled: !disabled,
      keepPreviousData: true,
    }
  )

  return query
}

export default useOrganizationListQuery

// get list user's organizations
type getOrganizationList = (
  orgId?: string,
  organizationFilters?: OrganizationFilters,
  pageLength?: number
) => Promise<{ items: Array<OrganizationListItem> } & Paginatable>
const getOrganizationList: getOrganizationList = async (
  orgId,
  organizationFilters,
  // TODO: Change this to 30 once we paginate every use of this query
  pageLength = 9999
) => {
  // if orgId is provided, only pull child organizations from the orgId
  if (orgId) {
    const result = await typedOrgsApi.get(
      '/api/organizations/{organizationId}/hierarchy',
      {
        searchParams: [
          ['mode', 'flat'],
          ['take', 9999],
        ],
        pathParams: { organizationId: orgId },
      }
    )

    // resolve response to the type expected when mode === flat and remove the junk
    const formattedItems = Array.isArray(result)
      ? result.map((item) => ({
          id: item.id,
          displayName: item.businessName,
          status: item.status,
          legacyIdentifier: item.legacyIdentifier,
          businessName: item.businessName,
        }))
      : []

    // typegaurd for union type (we only should be getting the flat result)
    if (!('items' in result)) throw new Error('API type mismatch')

    return { ...result, items: formattedItems }
  }

  // otherwise just pull all organizations for the user
  const result = await typedOrgsApi.get('/api/organizations', {
    searchParams: [
      ['include', ['children', 'parent']],
      ['order_by', 'ASC'],
      ['searchTerm', organizationFilters?.searchTerm],
      ['status', organizationFilters?.status],
      ['take', pageLength],
      ['skip', organizationFilters?.skip || 0],
    ],
  })

  // remove the junk
  const formattedItems =
    result.items?.map((item) => ({
      id: item.id,
      displayName: item.displayName,
      status: item.status,
      legacyIdentifier: item.party.legacyIdentifier,
      businessName: item.businessName,
    })) || []

  // typegaurd for union type (we only should be getting the flat result)
  if (!('items' in result)) throw new Error('API type mismatch')

  return { ...result, items: formattedItems }
}
