import { useQuery } from 'react-query'
import { webhooksApi } from 'utils'

type getWebhookEventPayloadsArgs = {
  webhookCategoryId?: string
  webhookEventId?: string
}

const useWebhookEventPayloadsQuery = ({
  webhookCategoryId,
  webhookEventId,
}: getWebhookEventPayloadsArgs) => {
  const query = useQuery<GetWebhookEventPayloadsResponse | undefined, KyError>(
    ['getWebhookEventPayloads', webhookCategoryId, webhookEventId],
    () => getWebhookEventPayloads(webhookCategoryId, webhookEventId),
    {
      enabled: !!webhookCategoryId && !!webhookEventId,
    }
  )

  return query
}

export default useWebhookEventPayloadsQuery

const getWebhookEventPayloads = async (
  webhookCategoryId?: string,
  webhookEventId?: string
) => {
  const result = webhooksApi
    .get(
      `api/webhook-categories/${webhookCategoryId}/webhook-events/${webhookEventId}/sample-payloads`,
      {
        searchParams: {
          version: 'v1',
        },
      }
    )
    .json<GetWebhookEventPayloadsResponse>()
    .catch(async (e) => {
      const error = await e.response.json()

      // check if the API is only erroring because a payload doesn't exist
      if (error.message.includes('was not found')) return undefined

      throw e
    })

  return result
}
