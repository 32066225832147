import React, { useState } from 'react'
import { Icon } from 'elements'
import { useTranslation } from 'react-i18next'
import { useOrgVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import _ from 'lodash'
import { OrgAdminVitalsThresholdsForm } from '.'
import tw, { styled } from 'twin.macro'

type OrgAdminVitalsThresholdsGridProps = {
  thresholds: VitalThresholds[]
}

const OrgAdminVitalsThresholdsGrid = ({
  thresholds,
}: OrgAdminVitalsThresholdsGridProps) => {
  const { t } = useTranslation()

  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const orgVitalAssignments = useOrgVitalAssignmentsQuery(
    assumedOrganizationRole?.orgID
  )

  const [thresholdsToEdit, setThresholdsToEdit] = useState<
    VitalThresholds | undefined
  >()
  const [isCreatingThresholds, setIsCreatingThresholds] = useState<boolean>(
    false
  )

  const vitalsWithNoThresholds = _.differenceWith(
    orgVitalAssignments.data?.assignedVitalSigns,
    thresholds,
    (vitalSign, threshold) => vitalSign.id === threshold.vitalSign.id
  )

  return (
    <ThresholdsGridContainer>
      {/* HEADER */}
      <ThresholdsGrid>
        <Spacer />
        <GridHeader>
          <HeaderTitle>
            <div>{t('Severity Level')}</div>
          </HeaderTitle>
          <AbnormalRangeTitleContainer>
            <LeftLineSpacer />
            <AbnormalRangeTitle>{t('Low')}</AbnormalRangeTitle>
            <RightLineSpacer />
          </AbnormalRangeTitleContainer>
          <TitleSpacer />
          <AbnormalRangeTitleContainer>
            <LeftLineSpacer />
            <AbnormalRangeTitle>{t('High')}</AbnormalRangeTitle>
            <RightLineSpacer />
          </AbnormalRangeTitleContainer>
          <SeverityValueTitle>3</SeverityValueTitle>
          <SeverityValueTitle>2</SeverityValueTitle>
          <SeverityValueTitle>1</SeverityValueTitle>
          <NormalTitle>{t('Normal')}</NormalTitle>
          <SeverityValueTitle>1</SeverityValueTitle>
          <SeverityValueTitle>2</SeverityValueTitle>
          <SeverityValueTitle>3</SeverityValueTitle>
        </GridHeader>
        {/* BODY */}
        {thresholds.map((threshold, index) =>
          // If the current threshold is not being edited show the normal row
          !(thresholdsToEdit?.vitalSign.id === threshold.vitalSign.id) ? (
            <React.Fragment key={`vitals-card-${index}`}>
              <ThresholdTypeContainer thresholdsToEdit={!!thresholdsToEdit}>
                <div>
                  <VitalSignTitle>{threshold.vitalSign.name}</VitalSignTitle>
                  <p>{`[${threshold.vitalSign.displayName}] (${
                    // find the org assignment matching the vitalSign and use the org's defaultUnitCode
                    (() => {
                      const vitalAssignment = orgVitalAssignments.data?.assignedVitalSigns.find(
                        (orgAssignment) =>
                          orgAssignment.id === threshold.vitalSign.id
                      )
                      return (
                        vitalAssignment?.units.find(
                          (unit) =>
                            unit.code === vitalAssignment.defaultUnitCode
                        )?.displayName || '-'
                      )
                    })()
                  })`}</p>
                </div>
                {!thresholdsToEdit && !isCreatingThresholds ? (
                  <EditIcon
                    type="edit"
                    onClick={() => setThresholdsToEdit(threshold)}
                    data-testid={`${threshold.vitalSign.displayName}-edit`}
                  />
                ) : null}
              </ThresholdTypeContainer>
              <LowSeverityThree thresholdsToEdit={!!thresholdsToEdit}>
                {
                  threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 3 && threshold.direction === 'low'
                  )?.limit
                }
              </LowSeverityThree>
              <LowSeverityTwo thresholdsToEdit={!!thresholdsToEdit}>
                {
                  threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 2 && threshold.direction === 'low'
                  )?.limit
                }
              </LowSeverityTwo>
              <LowSeverityOne thresholdsToEdit={!!thresholdsToEdit}>
                {
                  threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 1 && threshold.direction === 'low'
                  )?.limit
                }
              </LowSeverityOne>
              <NormalSeverity thresholdsToEdit={!!thresholdsToEdit}>
                {`${threshold.normalLow || ''} - ${threshold.normalHigh || ''}`}
              </NormalSeverity>
              <HighSeverityOne thresholdsToEdit={!!thresholdsToEdit}>
                {
                  threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 1 && threshold.direction === 'high'
                  )?.limit
                }
              </HighSeverityOne>
              <HighSeverityTwo thresholdsToEdit={!!thresholdsToEdit}>
                {
                  threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 2 && threshold.direction === 'high'
                  )?.limit
                }
              </HighSeverityTwo>
              <HighSeverityThree
                thresholdsToEdit={!!thresholdsToEdit}
                // On the bottom right corner, round the bottom right border to conform to the containing border radius
                // if there are vitals w/ no thresholds then the shadow row to create a new threshold will be below this row
                roundBottom={
                  index === thresholds.length - 1 &&
                  vitalsWithNoThresholds.length === 0
                }
              >
                {
                  threshold.thresholds.find(
                    (threshold) =>
                      threshold.severity === 3 && threshold.direction === 'high'
                  )?.limit
                }
              </HighSeverityThree>
            </React.Fragment>
          ) : (
            // Edit Form
            <OrgAdminVitalsThresholdsForm
              thresholds={thresholds}
              thresholdToUpdate={threshold}
              onFormFinished={() => setThresholdsToEdit(undefined)}
            />
          )
        )}
        {!thresholdsToEdit && vitalsWithNoThresholds.length > 0 ? (
          isCreatingThresholds ? (
            // Create Threshold Form
            <OrgAdminVitalsThresholdsForm
              thresholds={thresholds}
              onFormFinished={() => setIsCreatingThresholds(false)}
            />
          ) : (
            // Create Threshold Call to Action
            <CreateThresholdContainer
              onClick={() => setIsCreatingThresholds(true)}
            >
              <CreateThresholdTextContainer>
                <CreateThresholdText>
                  {t('Create New Threshold')}
                </CreateThresholdText>
              </CreateThresholdTextContainer>
            </CreateThresholdContainer>
          )
        ) : null}
      </ThresholdsGrid>
    </ThresholdsGridContainer>
  )
}

export default OrgAdminVitalsThresholdsGrid

const ThresholdsGridContainer = tw.div`bg-white rounded-lg border border-gray-300`

const ThresholdsGrid = tw.div`grid grid-cols-12 xl:grid-cols-10`

const Spacer = tw.div`col-span-4 xl:col-span-3`

const GridHeader = tw.div`col-span-8 xl:col-span-7 grid grid-cols-8 xl:grid-cols-7`

const HeaderTitle = tw.h1`col-span-8 xl:col-span-7 text-xl font-semibold text-gray-600 p-3 flex place-content-center items-center`

const AbnormalRangeTitleContainer = tw.div`col-span-3 flex place-content-center items-center`

const LeftLineSpacer = tw.div`border border-gray-500 translate-y-2/4 w-full ml-2`

const AbnormalRangeTitle = tw.span`text-gray-600 font-semibold mx-4`

const RightLineSpacer = tw.div`border border-gray-500 translate-y-2/4 w-full mr-2`

const TitleSpacer = tw.div`col-span-2 xl:col-span-1`

const SeverityValueTitle = tw.div`text-gray-600 text-center my-1`

const NormalTitle = tw.div`text-gray-600 text-center my-1 col-span-2 xl:col-span-1`

const ThresholdTypeContainer = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`col-span-4 xl:col-span-3 border-t border-gray-800 px-4 py-2 flex justify-between items-center`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const VitalSignTitle = tw.h5`font-semibold text-lg`

const EditIcon = tw(Icon)`w-4 h-4 text-gray-600 hover:text-gray-900`

const LowSeverityThree = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-red-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const LowSeverityTwo = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-orange-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const LowSeverityOne = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-amber-200 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const NormalSeverity = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-emerald-200 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const HighSeverityOne = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-amber-200 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const HighSeverityTwo = styled.div<{ thresholdsToEdit: boolean }>(
  ({ thresholdsToEdit }) => [
    tw`bg-orange-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
    thresholdsToEdit && tw`opacity-40`,
  ]
)

const HighSeverityThree = styled.div<{
  thresholdsToEdit: boolean
  roundBottom: boolean
}>(({ thresholdsToEdit, roundBottom }) => [
  tw`bg-red-300 border-l border-t border-gray-800 flex place-content-center items-center text-xl`,
  thresholdsToEdit && tw`opacity-40`,
  roundBottom && tw`rounded-br-lg`,
])

const CreateThresholdContainer = tw.div`col-span-12 xl:col-span-10 border-t rounded-b-lg border-gray-800 p-2 flex items-center cursor-pointer h-20 hover:bg-gray-100 text-gray-500 hover:text-gray-700 transition-all`

const CreateThresholdTextContainer = tw.div`h-full w-full flex place-content-center items-center`

const CreateThresholdText = tw.div`text-lg font-medium`
