import { useQuery } from 'react-query'
import { typedVitalsApi } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { formatISO } from 'date-fns'

const PAGE_LENGTH = 30

const useAlertsQuery = (filters: AlertFilters) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<
    VitalsAPIResponse['GET']['/insights/vitals-alerts'],
    KyError
  >(
    // 'addressed' is its own field of the query key to allow separate update logic on addressed/unaddressed caches when addressing an alert
    ['getAlerts', filters.addressed, filters, assumedOrganizationRole?.orgID],
    () => getAlerts(filters, assumedOrganizationRole?.orgID),
    { keepPreviousData: true }
  )

  return query
}

export default useAlertsQuery

const getAlerts = async (
  {
    addressed,
    subscriberIds,
    dateRangeState,
    alertCategories,
    alertTypes,
    severities,
    skip = 0,
    order_by,
  }: AlertFilters,
  organizationId: string | undefined
) => {
  const searchParams: VitalsAPIRequest['GET']['/insights/vitals-alerts']['searchParams'] = [
    ['order_by', order_by],
    ['take', PAGE_LENGTH],
    ['skip', skip],
    ['addressed', addressed],
    ['dateRangeType', dateRangeState.preset],
    ['alertCategories', alertCategories],
    ['alertTypes', alertTypes],
    ['severities', severities],
    ['organizationId', organizationId],
    ['subscriberIds', subscriberIds],
  ]

  if (dateRangeState.preset === 'custom') {
    searchParams.push(['from', formatISO(dateRangeState.value[0])])
    searchParams.push(['to', formatISO(dateRangeState.value[1])])
  }

  // if vitalSignIds or severities are sent set make sure their required alertCategory/alertType is sent if not already
  if (severities?.length) {
    if (!alertCategories?.includes('vitals'))
      searchParams.push(['alertCategories', ['vitals']])

    if (!alertTypes?.includes('vital-reading-alert'))
      searchParams.push(['alertTypes', ['vital-reading-alert']])
  }

  return typedVitalsApi.get('/insights/vitals-alerts', {
    searchParams,
  })
}
