import React from 'react'
import { LoadingIcon } from 'elements'
import { Button, Empty } from 'atlas'
import { FadeInSlideDown } from 'animations'
import {
  useOrgVitalAssignmentsQuery,
  useSubscriberVitalAssignmentsQuery,
} from 'hooks/vitals/vital-assignments'
import SubscriberVitalSignCard from './SubscriberVitalSignCard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'
import { useSubscriberQuery } from 'hooks/organizations'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { assignmentsToDisplayAssignments } from 'utils'

type SubscriberVitalSignsGridProps = {
  setIsAssignVitalSignFormOpen: (isAssignVitalSignFormOpen: boolean) => void
}

const SubscriberVitalSignsGrid = ({
  setIsAssignVitalSignFormOpen,
}: SubscriberVitalSignsGridProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()
  const { subscriberId } = useParams()

  const {
    data: currentSubscriber,
    isLoading: isSubLoading,
  } = useSubscriberQuery(subscriberId)

  // Get vital signs assigned to this org
  const {
    isLoading: orgVitalsLoading,
    data: orgVitalSigns,
  } = useOrgVitalAssignmentsQuery(
    // if the subscriber has an org, use the organization's vital assignments
    currentSubscriber?.organization
      ? assumedOrganizationRole?.orgID
      : // else if they're private pay, use the ECG's organization's vital assignments
        '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const {
    isLoading: subVitalsLoading,
    data: vitalAssignments,
  } = useSubscriberVitalAssignmentsQuery(subscriberId)

  const subscriberVitalSigns =
    vitalAssignments?.assignedVitalSigns.filter(
      (vitalSign) => !vitalSign.archived
    ) || []

  const subVitalSignIds = subscriberVitalSigns.map((vitalSign) => vitalSign.id)

  const unassignedVitalSigns = orgVitalSigns?.assignedVitalSigns // Only show vital signs that aren't already in subVitalSigns and that are unarchived
    .filter(
      (vitalSign) =>
        !subVitalSignIds.includes(vitalSign.id) && !vitalSign.archived
    )

  if (isSubLoading || orgVitalsLoading || subVitalsLoading)
    return <LoadingIcon />

  // If no vitalSigns are returned show empty grid placeholder
  if (!vitalAssignments || !subscriberVitalSigns.length)
    return (
      <VitalSignsEmpty
        title={t('No Data Found')}
        description={t('No vital signs have been assigned to this subscriber')}
        callToAction={
          unassignedVitalSigns?.length ? (
            <Button onClick={() => setIsAssignVitalSignFormOpen(true)}>
              {t('Assign Vital Signs')}
            </Button>
          ) : (
            t('No available organization vital sign assignments found')
          )
        }
        delay={0.1}
      />
    )

  return (
    <>
      <VitalSignContainer>
        {assignmentsToDisplayAssignments(subscriberVitalSigns).map(
          (vitalAssignment, index) => (
            <FadeInSlideDown
              delay={0.1 + index * 0.05}
              key={`vitals-card-${index}`}
            >
              <SubscriberVitalSignCard
                vitalAssignment={vitalAssignment}
                vitalAssignments={vitalAssignments}
              />
            </FadeInSlideDown>
          )
        )}
      </VitalSignContainer>
    </>
  )
}

export default SubscriberVitalSignsGrid

const VitalSignsEmpty = tw(Empty)`border-2 border-dashed rounded flex-grow`

const VitalSignContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
