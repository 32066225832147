import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, orgsApi, handleQueryError } from 'utils'

const useUpdatePartnerTypeMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    UpdatePartnerTypeResponse,
    KyError,
    UpdatePartnerTypeProps,
    GetPartnerTypesResponse | undefined
  >(updatePartnerType, {
    onMutate: ({ partnerTypeData }) => {
      // snapshot the current value
      const snapshot = queryCache.getQueryData<
        GetPartnerTypesResponse | undefined
      >(['getPartnerTypes'])

      // immediately update the Partner Type list
      queryCache.setQueryData<GetPartnerTypesResponse | undefined>(
        ['getPartnerTypes'],
        (oldCache) =>
          oldCache
            ? {
                ...oldCache,
                items: oldCache.items.map((partnerType) =>
                  partnerType.id === partnerTypeData.id
                    ? {
                        ...partnerTypeData,
                        description: partnerTypeData.description || '',
                      }
                    : partnerType
                ),
              }
            : oldCache
      )
      return snapshot
    },
    onSuccess: () => {
      // notify the user
      success({ message: t('Partner Type updated') })
    },
    onError: async (error, _, snapshot) => {
      // revert to snapshot
      queryCache.setQueryData<GetPartnerTypesResponse | undefined>(
        'getPartnerTypes',
        snapshot
      )

      handleQueryError({ error })
    },
    onSettled: (_, __, { partnerTypeData }) => {
      // refetch from API to make sure the partner list is in sync with the server
      queryCache.invalidateQueries('getPartnerTypes')
      queryCache.invalidateQueries(['getPartnerType', partnerTypeData.id])
    },
  })

  return mutation
}

export default useUpdatePartnerTypeMutation

type UpdatePartnerTypeProps = {
  partnerTypeData: PartnerType
  req: UpdateObject[]
}

const updatePartnerType = async ({
  partnerTypeData,
  req,
}: UpdatePartnerTypeProps) => {
  const updatePartnerTypeResult = await orgsApi
    .patch(`api/partner-types/${partnerTypeData.id}`, {
      json: req,
    })
    .json<UpdatePartnerTypeResponse>()

  return updatePartnerTypeResult
}
