import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useAddPolicyMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/policies'],
    KyError,
    AddPolicyProps
  >(addPolicy, {
    onSuccess: (policy) => {
      const policies:
        | OrgsAPIResponse['GET']['/api/policies']
        | undefined = queryCache.getQueryData(['getPolicies'])

      if (policies) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/policies'] = {
          ...policies,
          items: [policy, ...(policies.items || [])],
        }
        // immediately update the policy list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/policies']>(
          ['getPolicies'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the policy list is in sync with the server
      queryCache.invalidateQueries('getPolicies')

      // notify the user
      success({ message: t('Policy created') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useAddPolicyMutation

type AddPolicyProps = CreatePolicyForm
const addPolicy = async (policyData: AddPolicyProps) =>
  typedOrgsApi.post('/api/policies', {
    body: policyData,
  })
