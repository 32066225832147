import React, { useMemo } from 'react'
import { useTotalAlertsByCategoryQuery } from 'hooks/statistics'
import colors from 'tailwindcss/colors'
import { Icon, BarChart } from 'elements'
import { useNavigate } from 'hooks'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { dateRangeStateToURL } from 'utils'

type TotalAlertsByCategoryBarChartProps = {
  dateRangeState: DateRangeState
  organizationId?: string
  subscriberId?: string
}

const TotalAlertsByCategoryBarChart = ({
  dateRangeState,
  organizationId,
  subscriberId,
}: TotalAlertsByCategoryBarChartProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: alertChartData, isLoading } = useTotalAlertsByCategoryQuery({
    dateRangeState,
    organizationId,
    subscriberId,
  })
  const barChartSeries: Series[] = [
    {
      key: 'activityTotal',
      color: colors.violet[400],
      active: true,
      unit: 'Activity Alerts',
    },
    {
      key: 'systemTotal',
      color: colors.emerald[400],
      active: true,
      unit: 'System Alerts',
    },
    {
      key: 'vitalsTotal',
      color: colors.blue[400],
      active: true,
      unit: 'Vital Alerts',
    },
  ]

  const formattedData = useMemo(() => {
    return (
      alertChartData?.alertData.map(({ categoryCode, total }) => {
        if (categoryCode === 'MED-DEV-COND')
          return {
            category: 'Medical Device Conditions',
            activityTotal: total,
          }

        if (categoryCode === 'sys-conn-lost')
          return {
            category: 'System Connectivity Lost',
            systemTotal: total,
          }

        if (categoryCode === 'VSA')
          return {
            category: 'Vital Signs Alerts',
            vitalsTotal: total,
          }

        return { category: categoryCode, total }
      }) || []
    )
  }, [alertChartData])

  return (
    <Card>
      <HeaderRow>
        <Header
          onClick={() =>
            navigate({
              pathname: subscriberId
                ? `/subscribers/${subscriberId}/alerts-activity`
                : '/vitals/alerts-activity',
              searchParams: {
                addressed: 'unaddressed',
                ...dateRangeStateToURL(dateRangeState),
              },
            })
          }
        >
          <Title>{t('Total Alerts by Category')}</Title>
          <LinkIcon type="link" />
        </Header>
      </HeaderRow>
      <BarChart
        data={formattedData}
        series={barChartSeries}
        indexBy="category"
        groupMode="stacked"
        isLoading={isLoading}
        legends={[]}
        margin={{ top: 20, right: 10, bottom: 55, left: 30 }}
      />
    </Card>
  )
}

export default TotalAlertsByCategoryBarChart

const Card = tw.div`bg-white p-3 h-72`

const HeaderRow = tw.div`flex items-center justify-between`

const Header = tw.div`flex items-center cursor-pointer`

const Title = tw.h3`text-lg lg:mb-0 mr-1`

const LinkIcon = tw(Icon)`w-3.5 h-3.5`
