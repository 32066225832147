import React, { useRef } from 'react'
import { Autocomplete as MuiAutoComplete } from '@mui/lab'
import { TextField, Icon, EntityLabel } from 'elements'
import { Chip } from 'atlas'
import tw, { styled } from 'twin.macro'
import { AutocompleteProps } from '@mui/material'

type BaseAutoCompleteProps<T> = {
  label?: string
  placeholder?: string
  options: Array<T>
  optionLabel: (option: T) => string
  renderOption?: AutocompleteProps<
    T,
    boolean,
    undefined,
    undefined
  >['renderOption']
  renderTags?: AutocompleteProps<T, boolean, undefined, undefined>['renderTags']
  error?: string
  className?: string
  disableAutofill?: boolean
  disabled?: boolean
  chipColor?: 'lightblue' | 'gray' | 'red'
  entityLabel?: (option: T) => string
  inputValue?: string
  onInputValueChange?: (inputValue: string) => void
  filterOptions?: AutocompleteProps<
    T,
    boolean,
    undefined,
    undefined
  >['filterOptions']
  noOptionsText?: AutocompleteProps<
    T,
    boolean,
    undefined,
    undefined
  >['noOptionsText']
}

export type SingleProps<T> = BaseAutoCompleteProps<T> & {
  onChange: (option: T | null) => void
  selectedOption?: T | null
  selectedOptions?: undefined
  single: true
}

export type MultipleProps<T> = BaseAutoCompleteProps<T> & {
  onChange: (options: T[]) => void
  selectedOptions?: T[]
  selectedOption?: undefined
  single?: false
}

export type AutoCompleteProps<T> = MultipleProps<T> | SingleProps<T>

const AutoComplete = <T extends unknown>(props: AutoCompleteProps<T>) => {
  // destructuring in the parameters breaks the discriminated union
  const {
    label,
    placeholder,
    options,
    optionLabel,
    renderOption,
    renderTags,
    error,
    single,
    className,
    selectedOption,
    selectedOptions,
    disableAutofill,
    disabled,
    chipColor = 'lightblue',
    entityLabel,
    inputValue,
    onInputValueChange,
    filterOptions,
    noOptionsText,
  } = props

  // Comparing previous and current selectedOption is used to manually clear/show input text
  const previousSelectedOption = useRef<typeof selectedOption>(null)

  const clearTextInput =
    selectedOption === null && !!previousSelectedOption.current
  const showTextInput =
    !!selectedOption && previousSelectedOption.current === null

  return (
    // typescript... https://github.com/DefinitelyTyped/DefinitelyTyped/issues/39136#issuecomment-719950054
    <BaseAutoComplete<
      React.FC<AutocompleteProps<T, typeof single, undefined, undefined>>
    >
      data-testid="auto-complete"
      autoComplete
      autoHighlight
      includeInputInList
      multiple={!props.single}
      options={options}
      onChange={(event, value) => {
        return props.single
          ? // Can't discriminate typeof value based on value passed to 'multiple' prop :(
            props.onChange(value as T)
          : props.onChange(value as T[])
      }}
      getOptionLabel={optionLabel}
      renderOption={
        renderOption ||
        ((props, option) => (
          // set the key to guarantee uniqueness as much as possible
          <li {...props} key={JSON.stringify(option)}>
            {optionLabel(option) || JSON.stringify(option)}
          </li>
        ))
      }
      filterOptions={filterOptions}
      noOptionsText={noOptionsText}
      openOnFocus
      value={selectedOption || selectedOptions}
      aria-label={label}
      renderTags={
        renderTags ||
        ((value, getTagProps) =>
          value.map((option, index) => (
            <AutoCompleteChip
              key={`autoCompleteChip-${index}`}
              color={chipColor}
            >
              {entityLabel ? (
                <EntityLabel
                  condensed
                  className="static  h-fit-content -m-1"
                  iconOnly
                  size="md"
                  id={entityLabel(option)}
                  name={optionLabel(option) || ''}
                />
              ) : null}
              <span className="flex items-center ml-1">
                {optionLabel(option) || ''}
                <IconContainer
                  color={chipColor}
                  onClick={getTagProps({ index }).onDelete}
                >
                  <Icon
                    className="w-4 h-4"
                    style={{ color: 'inherit' }}
                    type="x"
                  />
                </IconContainer>
              </span>
            </AutoCompleteChip>
          )))
      }
      renderInput={(params) => {
        // update previousSelectedOption only when the input value matches what should be shown
        if (
          // @ts-expect-error Mui passes 'value' but it isn't typed
          (showTextInput && params.inputProps.value) ||
          // @ts-expect-error Mui passes 'value' but it isn't typed
          (clearTextInput && !params.inputProps.value)
        ) {
          previousSelectedOption.current = selectedOption
        }

        // @ts-expect-error Mui passes 'value' but it isn't typed
        onInputValueChange?.(params.inputProps.value)

        return (
          <TextField
            error={error}
            {...params}
            inputProps={{
              ...params.inputProps,
              // prevent chrome from autofilling this field
              ...(disableAutofill ? { autocomplete: 'chrome-off' } : {}),
              value:
                // the inputProps.value doesn't get the text of an externally set selectedOption. Override and show
                showTextInput
                  ? // showTextInput narrows the type here
                    optionLabel(selectedOption as T)
                  : // the inputProps.value text incorrectly persists. Override and show an empty string
                  clearTextInput
                  ? ''
                  : inputValue === undefined
                  ? // @ts-expect-error Mui passes 'value' but it isn't typed
                    params.inputProps.value
                  : inputValue,
            }}
            label={label}
            placeholder={placeholder}
          />
        )
      }}
      className={className}
      disabled={disabled}
    />
  )
}

export default AutoComplete

const AutoCompleteChip = tw(Chip)`mr-2 p-1`

const BaseAutoComplete = styled(MuiAutoComplete)<{ className?: string }>(
  ({ className }) => ['min-width: 12rem;', className]
)

const IconContainer = styled.div<{
  color: BaseAutoCompleteProps<unknown>['chipColor']
}>(({ color }) => [
  tw`flex items-center justify-center rounded-full transition-all w-8 h-8 -m-1 ml-1 cursor-pointer`,
  color === 'red' ? tw`hover:bg-red-300` : tw`hover:bg-blue-200`,
])
