import React, { useState } from 'react'
import {
  ECGAdminReminderMessagesList,
  ECGAdminCreateEditReminderMessagesForm,
  ECGAdminCreateTranslationForm,
  ECGAdminEditTranslationForm,
} from 'components/ecg-admin-reminder-messages'
import {
  useReminderMessagesQuery,
  useAvailableTranslationsQuery,
} from 'hooks/reminders'
import _ from 'lodash'
import { FadeInSlideDown } from 'animations'
import { Button, Empty } from 'atlas'
import { Layout, CustomSelect, Icon } from 'elements'
import { useTranslation } from 'react-i18next'
import { useLocalesQuery } from 'hooks/locales'
import tw, { styled } from 'twin.macro'
import { useNavigate } from 'hooks'

const ECGAdminReminderMessages = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedTranslation, setSelectedTranslation] = useState<{
    key: string
    name: string
  }>({ key: 'en-US', name: 'English (United States)' })
  const [
    isCreateTranslationFormOpen,
    setIsCreateTranslationFormOpen,
  ] = useState<boolean>(false)

  const [
    isEditTranslationFormOpen,
    setIsEditTranslationFormOpen,
  ] = useState<boolean>(false)

  const localesQuery = useLocalesQuery()
  const reminderMessagesQuery = useReminderMessagesQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )
  const availableTranslationsQuery = useAvailableTranslationsQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  return (
    <>
      <Layout
        title={t('Reminder Messages')}
        description={t(
          'Create and manage reminder messages here. Manage translations to allow your Subscribers to receive messages in multiple languages.'
        )}
        isLoading={
          reminderMessagesQuery.isLoading ||
          localesQuery.isLoading ||
          availableTranslationsQuery.isLoading
        }
        isEmpty={
          !reminderMessagesQuery.data?.items ||
          reminderMessagesQuery.data.items.length <= 0
        }
        emptyComponent={
          <ReminderMessagesEmpty
            title={t('No Data Found')}
            description={t('No reminder messages have been created')}
            callToAction={
              <Button
                type="primary-filled"
                onClick={() => setIsEditFormOpen(true)}
              >
                {t('Create Reminder Messages')}
              </Button>
            }
          />
        }
        breadcrumbs={[
          { name: t('Vitals'), route: '/vitals' },
          { name: t('Reminder Messages') },
        ]}
        controls={[
          {
            label: t('Create New Translation'),
            onClick: () => setIsCreateTranslationFormOpen(true),
            disabled:
              !availableTranslationsQuery.data?.items ||
              !localesQuery.data?.items ||
              !reminderMessagesQuery.data?.items?.length ||
              availableTranslationsQuery.data.items.length >=
                localesQuery.data.items.length,
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
            // TODO: we can't use the 'reminder-message' entity here because it will show events for all orgs' reminder messages
            // TODO: Discuss with API whether we need a different entity for the base global reminder messages
            // TODO: Or if we should use 'reminder-message' & pass the OrgID for "Electronic Caregiver"
            disabled: true,
          },
        ]}
      >
        <FadeInSlideDown delay={0.2}>
          <RemindersSelectContainer>
            <TitleContainer>
              <Title>
                {t('Reminder Messages - ')}
                {selectedTranslation.name}
              </Title>
              &nbsp;
            </TitleContainer>
            <ReminderSelect>
              <Edit
                onClick={() => {
                  if (selectedTranslation.key === 'en-US') {
                    return setIsEditFormOpen(true)
                  } else return setIsEditTranslationFormOpen(true)
                }}
              >
                <EditIcon data-testid="edit-reminder-messages" type="edit" />
                Edit
              </Edit>
              <Delete
                englishTranslation={selectedTranslation.key === 'en-US'}
                onClick={() => {
                  if (selectedTranslation.key !== 'en-US')
                    return setIsDialogOpen(true)
                }}
              >
                <TrashIcon
                  data-testid="delete-reminder-messages"
                  type="trash"
                  disabled={selectedTranslation.key === 'en-US'}
                />
                Delete
              </Delete>
              <CustomSelect<string>
                className={'w-56'}
                options={_.sortBy(
                  availableTranslationsQuery.data?.items?.map((translation) => {
                    return {
                      value: translation.locale.key,
                      label: (
                        <div className="flex items-center">
                          <Icon className="h-4 w-4 mr-2" type="locale" />
                          {translation.locale.name}
                        </div>
                      ),
                    }
                  }),
                  (option) => option.value
                )}
                value={selectedTranslation.key}
                onChange={(e) => {
                  const translationName = availableTranslationsQuery.data?.items?.find(
                    (translation) => translation.locale.key === e.value
                  )?.locale.name
                  setSelectedTranslation({
                    key: e.value,
                    name: translationName || '',
                  })
                }}
                variant="thin"
              />
            </ReminderSelect>
          </RemindersSelectContainer>
        </FadeInSlideDown>
        <ECGAdminReminderMessagesList
          selectedLocale={selectedTranslation}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      </Layout>
      <ECGAdminCreateEditReminderMessagesForm
        isFormOpen={isEditFormOpen}
        setIsFormOpen={setIsEditFormOpen}
        shouldEdit={
          !(
            !reminderMessagesQuery.data?.items ||
            reminderMessagesQuery.data.items.length <= 0
          )
        }
      />

      <ECGAdminCreateTranslationForm
        isFormOpen={isCreateTranslationFormOpen}
        setIsFormOpen={setIsCreateTranslationFormOpen}
      />
      <ECGAdminEditTranslationForm
        isFormOpen={isEditTranslationFormOpen}
        setIsFormOpen={setIsEditTranslationFormOpen}
        selectedTranslation={selectedTranslation}
      />
    </>
  )
}

export default ECGAdminReminderMessages

const ReminderMessagesEmpty = tw(
  Empty
)`flex flex-grow border-2 border-dashed rounded`

const RemindersSelectContainer = tw.div`flex flex-row justify-between items-center mb-2`

const TitleContainer = tw.div`flex flex-row`

const Title = tw.h3`text-xl font-semibold`

const ReminderSelect = tw.div`flex items-center`

const Edit = tw.span`flex items-center mr-4 hover:text-blue-400 cursor-pointer`

const EditIcon = tw(Icon)`h-4 w-4 mr-1`

const Delete = styled.span<{ englishTranslation: boolean }>(
  ({ englishTranslation }) => [
    tw`flex items-center mr-4 hover:text-red-400 cursor-pointer`,
    englishTranslation && tw`opacity-50 cursor-not-allowed hover:text-black`,
  ]
)

const TrashIcon = tw(Icon)`h-4 w-4 mr-1`
