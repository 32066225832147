import React from 'react'
import {
  Select as MuiSelect,
  MenuItem,
  MenuItemProps,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material'
import muiTheme, { theme } from 'styles/material-theme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import tw, { styled } from 'twin.macro'

const darkTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    mode: 'dark',
  },
})

type SelectProps = {
  children: React.ReactNode
  name?: string
  label?: string
  defaultValue?: unknown
  inputRef?: React.Ref<unknown>
  className?: string
  darkMode?: boolean
  value?: unknown
  disabled?: boolean
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void
  variant?: 'standard' | 'filled' | 'outlined'
  error?: string
  fullWidth?: boolean
  selectClassName?: string
  required?: boolean
}

const Select = React.forwardRef(
  (
    {
      children,
      label,
      name,
      defaultValue,
      className,
      darkMode,
      value,
      disabled,
      onChange,
      variant = 'standard',
      error,
      fullWidth = true,
      selectClassName,
      required,
    }: SelectProps,
    ref: React.Ref<unknown>
  ) => {
    return (
      <ThemeProvider theme={darkMode ? darkTheme : muiTheme}>
        <div className={className}>
          {/* @ts-expect-error Can't pass an extra prop (error) to a wrapped MUI element I think */}
          <Label shrink htmlFor={name} error={error || ''}>
            {label}
          </Label>
          <MuiSelect
            id={name}
            name={name}
            fullWidth={fullWidth}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            ref={ref}
            disabled={disabled}
            role="combobox"
            aria-label={label}
            inputProps={{
              role: 'combobox',
              ['data-testid']: `${label}-select`,
            }}
            variant={variant}
            error={!!error}
            className={selectClassName}
            required={required}
          >
            {children}
          </MuiSelect>
          <FormHelperText className="ml-4 text-mui-error absolute">
            {error}
          </FormHelperText>
        </div>
      </ThemeProvider>
    )
  }
)

export default Select

export const SelectItem = (props: MenuItemProps) => (
  <MenuItem {...props} className="block p-2" />
)

const Label = styled(InputLabel)<{ error: string }>(({ error }) => [
  // TODO: figure out why error is typed as 'never'
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  error && tw`text-mui-error`,
  'margin-bottom: -7px',
])
