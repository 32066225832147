import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { ArchivedPolicyGroupsList } from 'components/access-control/policy-groups'
import { usePolicyGroupsQuery } from 'hooks/access-control/policy-groups'

const ArchivedAccessControlPolicyGroups = () => {
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const {
    data: policyGroups,
    isLoading: isLoadingPolicyGroups,
  } = usePolicyGroupsQuery()

  const { t } = useTranslation()

  return (
    <Layout
      title={t('Archived Policy Groups')}
      description={t('Restore or delete archived policy groups.')}
      breadcrumbs={[
        { name: t('Access Control'), route: '/access-control' },
        { name: t('Policy Groups'), route: '/access-control/policy-groups' },
        { name: t('Archived Policy Groups') },
      ]}
      isLoading={isLoadingPolicyGroups}
      isUnauthorized={
        !accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly')
      }
    >
      <ArchivedPolicyGroupsList
        // Only show archived policy groups
        data={
          policyGroups?.items?.filter(
            (policyGroup) => policyGroup.archiveInfo.archived
          ) || []
        }
      />
    </Layout>
  )
}

export default ArchivedAccessControlPolicyGroups
