import { rest } from 'msw'
import db from '../../data/db'

const subscriberHandlers = [
  // get subscriber list
  rest.get<undefined, OrgsAPIResponse['GET']['/api/subscribers']>(
    '/api/subscribers',
    (req, res, ctx) =>
      res(
        ctx.json({
          items: db
            .get<SubscriberDisplay[]>('subscriberList')
            .filter(
              (subscriber) =>
                !req.url.searchParams.get('organizationId') ||
                subscriber.organization?.id ===
                  req.url.searchParams.get('organizationId')
            ),
          recordsPerPage: 10,
          totalRecords: 0,
          currentPageNumber: 1,
          totalPages: 0,
        })
      )
  ),

  // get subscriber
  rest.get<undefined, OrgsAPIResponse['GET']['/api/subscribers/{personId}']>(
    '/api/subscribers/:subId',
    (req, res, ctx) => {
      const subscriber = db
        .get<SubscriberDisplay[]>('subscriberList')
        .find((subscriber) => subscriber.id === req.params.subId)

      if (!subscriber) throw new Error('subscriber not found!')

      return res(ctx.json(subscriber))
    }
  ),

  // get address
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/addresses']
  >('/subscribers-profile/:subscriberId/addresses', (req, res, ctx) => {
    const subAddress = db.get<SubscriberAddress[]>('subscriberAddress')

    return res(ctx.json({ addresses: subAddress }))
  }),

  // update subscriber
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/subscribers/{personId}']['body'],
    OrgsAPIResponse['PATCH']['/api/subscribers/{personId}']
  >('/api/subscribers/:subscriberId', (req, res, ctx) => {
    const subscribers = db.get<SubscriberDisplay[]>('subscriberList')

    const firstName = req.body.find((item) => item.path === '/firstName')?.value

    const lastName = req.body.find((item) => item.path === '/lastName')?.value

    // update the subscriber
    const updatedSubscribers = subscribers.map((subscriber) => {
      if (subscriber.id === req.params.subscriberId)
        return {
          ...subscriber,
          person: { ...subscriber.person, firstName, lastName },
        } as SubscriberDisplay

      return subscriber
    })

    // update the db
    db.set<SubscriberDisplay[]>('subscriberList', updatedSubscribers)

    const updatedSubscriber = updatedSubscribers.find(
      (subscriber) => subscriber.id === req.params.subscriberId
    )

    if (!updatedSubscriber)
      throw new Error('No subscriber exists for this update')

    return res(ctx.json(updatedSubscriber))
  }),

  // create subscriber metadata
  rest.post<
    OrgsAPIRequest['POST']['/api/subscribers/{personId}/metadata']['body'],
    OrgsAPIResponse['POST']['/api/subscribers/{personId}/metadata']
  >('/api/subscribers/:subscriberId/metadata', (req, res, ctx) => {
    const subscribers = db.get<SubscriberDisplay[]>('subscriberList')

    // update the subscriber
    const updatedSubscribers = subscribers.map((subscriber) =>
      subscriber.id === req.params.subscriberId
        ? {
            ...subscriber,
            integrationMetadata: Object.fromEntries(
              req.body.map((updateObj) => [updateObj.key, updateObj.value])
            ),
          }
        : subscriber
    )

    // update the db
    db.set<SubscriberDisplay[]>('subscriberList', updatedSubscribers)

    const updatedSubscriber = updatedSubscribers.find(
      (subscriber) => subscriber.id === req.params.subscriberId
    )

    if (!updatedSubscriber)
      throw new Error('No subscriber exists for this update')

    return res(ctx.json(updatedSubscriber))
  }),

  // update subscriber metadata
  rest.patch<
    OrgsAPIRequest['PATCH']['/api/subscribers/{personId}/metadata']['body'],
    OrgsAPIResponse['PATCH']['/api/subscribers/{personId}/metadata']
  >('/api/subscribers/:subscriberId/metadata', (req, res, ctx) => {
    const subscribers = db.get<SubscriberDisplay[]>('subscriberList')

    // update the subscriber
    const updatedSubscribers = subscribers.map((subscriber) =>
      subscriber.id === req.params.subscriberId
        ? {
            ...subscriber,
            integrationMetadata: {
              ...(subscriber.integrationMetadata || {}),
              ...Object.fromEntries(
                req.body.map((updateObj) => [
                  updateObj.path.slice(1),
                  updateObj.value,
                ])
              ),
            },
          }
        : subscriber
    )

    // update the db
    db.set<SubscriberDisplay[]>('subscriberList', updatedSubscribers)

    const updatedSubscriber = updatedSubscribers.find(
      (subscriber) => subscriber.id === req.params.subscriberId
    )

    if (!updatedSubscriber)
      throw new Error('No subscriber exists for this update')

    return res(ctx.json(updatedSubscriber))
  }),
]

export default subscriberHandlers
