import { success, vitalsApi, handleQueryError } from 'utils'
import { useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

const useCreateVitalSignMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    VitalsAPIResponse['POST']['/vital-signs'],
    KyError<ErrorResponse>,
    | VitalsAPIRequest['POST']['/vital-signs']
    // TODO: remove this type
    | VitalSign
  >(createVitalSign, {
    onSuccess: (createdVitalSign) => {
      // Notify user of successful vital sign creation
      success({
        message: t('Vital sign created'),
      })

      // grab the old vital sign list from the queryCache
      const oldVitalSigns = queryCache.getQueryData<
        VitalsAPIResponse['GET']['/vital-signs']
      >('ECGAdmin_getVitalSigns')

      // Immediately add Vital to local cache
      queryCache.setQueryData(
        ['ECGAdmin_getVitalSigns'],

        [...(oldVitalSigns?.items || []), createdVitalSign]
      )

      // Refetch from API to make sure the new vital sign is in sync with the server
      queryCache.invalidateQueries('ECGAdmin_getVitalSigns')
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useCreateVitalSignMutation

// Post new vital sign to backend
const createVitalSign = async (
  newVitalSign: VitalsAPIRequest['POST']['/vital-signs'] | VitalSign
) => {
  const result = await vitalsApi
    .post('vital-signs', {
      json: newVitalSign,
    })
    .json<VitalsAPIResponse['POST']['/vital-signs']>()
  return result
}
