import React, { useState } from 'react'
import { Button, Empty } from 'atlas'
import { Layout, Dialog, SetupStepsFlow } from 'elements'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import {
  useOrganizationQuery,
  useOrganizationListQuery,
} from 'hooks/organizations'
import { useOrganizationOnboardingQuery } from 'hooks/organizations'
import { OrganizationSelect } from 'elements'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'

const OrganizationSetup = () => {
  const { t } = useTranslation()

  const { orgid } = useParams()

  const navigate = useNavigate()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const [redirect, setRedirect] = useState('')

  const organizationListQuery = useOrganizationListQuery()

  const {
    assumedOrganizationRole,
    assumeOrganizationRole,
  } = useAssumedOrganizationRole()

  const organizationOnboardingQuery = useOrganizationOnboardingQuery({
    organizationId: assumedOrganizationRole
      ? assumedOrganizationRole.orgID
      : orgid,
  })

  const organizationQuery = useOrganizationQuery(
    assumedOrganizationRole ? assumedOrganizationRole.orgID : orgid
  )

  return (
    <Layout
      isLoading={
        organizationQuery.isLoading ||
        organizationOnboardingQuery.isLoading ||
        organizationListQuery.isLoading
      }
      title={
        assumedOrganizationRole ? (
          <TitleContainer>
            <Title>{t('Organization Setup')}</Title>
          </TitleContainer>
        ) : (
          <TitleContainer>
            <Title>{t('Organization Setup')}:</Title>
            <OrganizationSelect />
          </TitleContainer>
        )
      }
      isEmpty={!organizationQuery.data}
      emptyComponent={
        <Empty
          title={t('Organization Not Found')}
          description={t(
            `Either this organization doesn't exist or you don't have access to view it`
          )}
        />
      }
      description={t('View and continue the setup process for an organization')}
      breadcrumbs={[
        {
          name: t('Organizations'),
          route: '../..',
          hidden: !!assumedOrganizationRole,
        },
        {
          name: organizationQuery.data?.businessName,
          route: `..`,
        },
        {
          name: t('Setup'),
        },
      ]}
    >
      {organizationOnboardingQuery.data ? (
        <SetupStepsFlow
          stepsData={(Object.entries(organizationOnboardingQuery.data) as [
            keyof typeof organizationOnboardingQuery.data,
            typeof organizationOnboardingQuery.data[keyof typeof organizationOnboardingQuery.data]
          ][]).map(([key, value]) => ({
            ...value,
            key,
            onClick: () =>
              // if user hasn't assumed a role, show an assume role prompt
              !assumedOrganizationRole
                ? (setRedirect(onboardingKeyToRoute[key]),
                  setIsDialogOpen(true))
                : navigate(onboardingKeyToRoute[key]),
          }))}
          stepOrder={[
            'vitalSignAssignments',
            'vitalThresholds',
            'reminderMessages',
            'alertDeliveryRules',
          ]}
        />
      ) : null}

      <Dialog
        open={isDialogOpen}
        title={`${t('Assume Role of')} ${
          organizationQuery.data?.businessName
        }?`}
        content={
          <p>
            {t(
              `To manage settings for this organization you must assume the organization's role`
            )}
          </p>
        }
        actions={
          <>
            <Button onClick={() => setIsDialogOpen(false)} type="secondary">
              {t('Cancel')}
            </Button>
            <Button
              type="primary-filled"
              onClick={() => {
                // assume the organization role
                if (organizationQuery.data?.id)
                  assumeOrganizationRole({
                    orgID: organizationQuery.data.id,
                    onSuccess: (searchParams) =>
                      navigate({ pathname: redirect, searchParams }),
                  })

                setIsDialogOpen(false)
              }}
            >
              {t('Assume Role')}
            </Button>
          </>
        }
      />
    </Layout>
  )
}
export default OrganizationSetup

const TitleContainer = tw.div`flex gap-2`

const Title = tw.h1`text-2xl font-bold`

const onboardingKeyToRoute: {
  [key in keyof VitalsAPIResponse['GET']['/organizations/{organizationId}/on-boarding']]: string
} = {
  reminderMessages: '/organization-settings/reminder-messages',
  vitalSignAssignments: '/organization-settings/vital-sign-assignments',
  vitalThresholds: '/organization-settings/vitals-thresholds',
  alertDeliveryRules: '/organization-settings/alert-delivery-rules',
}
