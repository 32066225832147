import { formatISO, subDays } from 'date-fns'
import faker from 'faker'
import _ from 'lodash'
import {
  generateArchivable,
  generateAuditable,
  generateDisableable,
  generateParty,
} from 'test-utils/api/utils'
import { getECGAdminPermissionsPreset } from 'utils'

type initialData = {
  [key: string]: unknown
  vitalSigns: VitalSign[]
  organizationList: Organization[]
  subscriberList: SubscriberDisplay[]
  staffList: StaffMember[]
  accountPermissions: AccountPermissions
  vitalSignAudit: VitalsAuditEvent[]
  orgVitalAssignments: OrgVitalAssignments[]
  subVitalAssignments: SubscriberVitalAssignments[]
  ecgDefaultReminderMessages: OrganizationReminderMessages
  ecgAdminTranslations: TranslationKey[]
  ecgTranslationsReminderMessages: OrganizationReminderMessages[]
  modules: Module[]
  permissions: Permission[]
  policies: Policy[]
  policyGroups: PolicyGroup[]
  applications: Application[]
  organizationReminderMessages: OrganizationReminderMessages | null
  salutationTypes: SalutationType[]
  staffMemberTypes: StaffMemberType[]
  addressTypes: AddressType[]
  countryCodes: Country[]
  organizationTypes: OrganizationType[]
  adminList: Admin[]
  disclaimerTypes: DisclaimerType[]
  disclaimers: Disclaimer[]
  orgVitalThresholds: VitalThresholds[]
  subVitalThresholds: VitalThresholds[]
  ecgReminderMessages: ReminderMessage[]
  orgReminderMessages: ReminderMessage[]
  reminderMessageTypes: ReminderMessageType[]
  ecgReminderMessageTranslations: ReminderMessage[]
  orgReminderMessageTranslations: ReminderMessage[]
  ecgAvailableTranslations: AvailableTranslation[] | []
  orgAvailableTranslations: AvailableTranslation[] | []
  webhookSubscriptions: Array<WebhookSubscription>
  webhookCategories: Array<WebhookCategory>
  webhookEvents: Array<WebhookEvent>
  alertContact: AlertContact | []
  reminderPresets: Array<ReminderPreset>
  reminderSchedule: Array<ReminderSchedule>
  preferredLocales: Array<ADDILocale>
  partners: Array<Partner>
  partnerTypes: Array<PartnerType>
  partnerTypeRelationships: Array<PartnerTypeRelationship>
  partnerTypeCommissions: Array<Commission>
  organizationHierarchy: Array<OrganizationHierarchyItem>
  alertDeliveryRules: Array<AlertDeliveryRule>
  responsibleParties: Array<ResponsibleParty>
  blockedOrganizationList: Organization[] | []
  contracts: Contract[]
  orders: Order[]
  orderActivityHistory: OrderActivity[]
  products: Product[]
  alertCategories: AlertCategory[]
  reminderTypes: ReminderTypes
  orderStatuses: OrderStatus[]
  orderLineItemStatuses: OrderLineItemStatus[]
  provisioningAttributes: ProvisioningAttribute[]
  subscriberAddress: SubscriberAddress[]
}

/**
 * there are a few values we need to generate before we initialize intialData
 * since other values rely on them
 *  */
const organizationList: Organization[] = [
  {
    id: 'electronicCaregiverId',
    displayName: `Electronic Caregiver`,
    type: '1',
    status: 'Active',
    hcoXrefId: faker.random.uuid(),
    businessName: `Electronic Caregiver`,
    nodes: ['Childsid', 'OtherChildsid'],
    party: generateParty(),
    ...generateArchivable(),
    ...generateAuditable(),
    ...generateDisableable(),
    onBoardingStatus: 1,
    originationSource: 'ADDI',
    organizationStatus: 1,
    // @ts-expect-error Bad Swagger type
    subscribers: [] as SubscriberDisplay[],
  },
  {
    id: 'Childsid',
    displayName: `Child Organization`,
    status: 'Active',
    hcoXrefId: faker.random.uuid(),
    businessName: `Child Organization`,
    nodes: ['OtherChildsid'],
    party: generateParty(),
    ...generateArchivable(),
    ...generateAuditable(),
    ...generateDisableable(),
    onBoardingStatus: 1,
    originationSource: 'ADDI',
    organizationStatus: 1,
    // @ts-expect-error Bad Swagger type
    subscribers: [] as Subscriber[],
  },
  {
    id: 'OtherChildsid',
    displayName: `Other Child`,
    status: 'Active',
    hcoXrefId: faker.random.uuid(),
    businessName: `Other Child`,
    nodes: [],
    party: generateParty(),
    ...generateArchivable(),
    ...generateAuditable(),
    ...generateDisableable(),
    onBoardingStatus: 1,
    originationSource: 'ADDI',
    organizationStatus: 1,
    // @ts-expect-error Bad Swagger type
    subscribers: [] as Subscriber[],
  },
]

const vitalSigns: VitalSign[] = [
  {
    id: faker.random.uuid(),
    name: 'Systolic Blood Pressure',
    displayName: 'SYS',
    code: 'sys',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    units: [
      {
        name: 'Millimeters of Mercury',
        displayName: 'mmHg',
        code: 'mmhg',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'mmhg',
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
  {
    id: faker.random.uuid(),
    name: 'Diastolic Blood Pressure',
    displayName: 'DIA',
    code: 'dia',
    units: [
      {
        name: 'Millimeters of Mercury',
        displayName: 'mmHg',
        code: 'mmhg',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'mmhg',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
  {
    id: 'body-temp-id',
    name: 'Body Temperature',
    displayName: 'TEMP',
    code: 'temp',
    units: [
      {
        name: 'Fahrenheit',
        displayName: 'F',
        code: 'f',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
      {
        name: 'Celsius',
        displayName: 'C',
        code: 'c',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'c',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
  {
    id: 'heart-rate-id',
    name: 'Heart Rate',
    displayName: 'HEART RATE',
    code: 'heartRate',
    units: [
      {
        name: 'Beats per Minute',
        displayName: 'BPM',
        code: 'bpm',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'bpm',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
  {
    id: faker.random.uuid(),
    name: 'Glucose',
    displayName: 'GLUCOSE',
    code: 'glucose',
    units: [
      {
        name: 'Milligrams per Deciliter',
        displayName: 'mg/dL',
        code: 'mgdl',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
      {
        name: 'Millimoles per Liter',
        displayName: 'mmol/L',
        code: 'mmoll',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'mmoll',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
  {
    id: faker.random.uuid(),
    name: 'Oxygen Saturation',
    displayName: 'OXYGEN',
    code: 'oxygen',
    units: [
      {
        name: 'Percentage',
        displayName: '%',
        code: 'percent',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'percent',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
  {
    id: faker.random.uuid(),
    name: 'Spirometry',
    displayName: 'SPIR',
    code: 'spir',
    units: [
      {
        name: 'Expiratory Forced Vital Capacity',
        displayName: 'FVC',
        code: 'fvc',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
      {
        name: 'Forced Expiratory Volume In One Second',
        displayName: 'FEV1',
        code: 'fev1',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'fev1',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
  {
    id: 'weight-id',
    name: 'Weight',
    displayName: 'WEIGHT',
    code: 'weight',
    units: [
      {
        name: 'Pounds',
        displayName: 'lb',
        code: 'lb',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
      {
        name: 'Kilograms',
        displayName: 'kg',
        code: 'kg',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
      },
    ],
    defaultUnitCode: 'kg',
    media: {
      audioURL: '',
      ssmlURL: '',
    },
    auditInfo: {
      createdAt: '2020-10-21T16:37:48.7819301Z',
      createdBy: faker.random.uuid(),
      updatedAt: '2020-10-21T16:37:48.7819301Z',
      updatedBy: faker.random.uuid(),
    },
    activeInfo: {
      active: true,
      activeStatusChangedAt: '',
    },
    archiveInfo: {
      archived: false,
      archivedStatusChangedAt: '',
    },
  },
]

type mockedPermissionArgs = {
  id: string
  name: string
  code: string
  moduleId: string
  moduleName: string
}

const generateMockedPermission = ({
  id,
  name,
  code,
  moduleId,
  moduleName,
}: mockedPermissionArgs) => {
  const permission: Permission = {
    id,
    name,
    code,
    description: '',
    module: {
      id: moduleId,
      name: moduleName,
      description: '',
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
    },
    ...generateArchivable(),
    ...generateAuditable(),
    ...generateDisableable(),
  }

  return permission
}

type mockedApplicationArgs = {
  id: string
  name: string
  modules: { id: string; name: string }[]
  cognitoAppId?: string
  description?: string
}

const generateMockedApplication = ({
  id,
  name,
  modules,
  cognitoAppId = '',
  description = '',
}: mockedApplicationArgs) => {
  const application: Application = {
    id,
    name,
    description,
    cognitoAppId,
    modules: modules.map(({ id, name }) => ({
      id,
      name,
      description: '',
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
    })),
    ...generateArchivable(),
    ...generateAuditable(),
    ...generateDisableable(),
  }

  return application
}

const mockedModuleWithPermission: Module = {
  id: 'mockedSurveysModuleID',
  name: 'Surveys',
  description: '',
  permissions: [
    generateMockedPermission({
      id: 'jf9dsja8fjd89sajf89dsha',
      name: 'Survey Admin',
      code: 'survey.admin',
      moduleId: 'mockedSurveysModuleID',
      moduleName: 'Surveys',
    }),
  ],
  ...generateArchivable(),
  ...generateAuditable(),
  ...generateDisableable(),
}

const orgVitalAssignmentNames = [
  'Weight',
  'Heart Rate',
  'Body Temperature',
  'Diastolic Blood Pressure',
]

/* ----- initialData ----- */
const initialData: initialData = {
  blockedOrganizationList: [],
  responsibleParties: [],
  organizationHierarchy: [
    {
      id: 'Childsid',
      businessName: `Child Organization`,
      displayName: `Child Organization`,
      status: 'Active',
      parentId: 'electronicCaregiverId',
      level: 2,
      legacyIdentifier: 'Child',
      integrationMetadata: {},
    },
    {
      id: 'OtherChildsid',
      businessName: `Other Child`,
      displayName: `Other Child`,
      status: 'Active',
      parentId: 'Childsid',
      level: 3,
      legacyIdentifier: 'OtherChild',
      integrationMetadata: {},
    },
  ],
  preferredLocales: [
    {
      name: 'es-MX',
      nativeName: 'español (México)',
      displayName: 'Spanish (Mexico)',
      englishName: 'Spanish (Mexico)',
      lcid: 2058,
      isPreferred: true,
    },
    {
      name: 'en-US',
      nativeName: 'English (United States)',
      displayName: 'English (United States)',
      englishName: 'English (United States)',
      lcid: 1033,
      isPreferred: true,
    },
    {
      name: 'de',
      nativeName: 'Deutsch',
      displayName: 'German',
      englishName: 'German',
      lcid: 7,
      isPreferred: true,
    },
  ],
  modules: [
    mockedModuleWithPermission,
    {
      id: 'jf9dsauf90udj9s0ajf90dsajf90dj9s0a',
      name: 'Vitals',
      description: '',
      permissions: [
        generateMockedPermission({
          id: 'kj9f090j0j90j90j90',
          name: 'Vitals Admin',
          code: 'vitals.admin',
          moduleId: 'jf9dsauf90udj9s0ajf90dsajf90dj9s0a',
          moduleName: 'Vitals',
        }),
      ],
      applications: [
        generateMockedApplication({
          id: 'fjdojsafdajfiodsa',
          name: 'ARPM',
          modules: [{ id: 'mockedSurveysModuleID', name: 'Surveys' }],
        }),
      ],
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
    },
    {
      id: 'fj9djsaf9jd9sajf90jd90sajf90dfj9dsja90',
      name: 'Medications',
      description: '',
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
    },
  ],
  permissions: [
    generateMockedPermission({
      id: 'jf9dsja8fjd89sajf89dsha',
      name: 'Survey Admin',
      code: 'survey.admin',
      moduleId: 'mockedSurveysModuleID',
      moduleName: 'Surveys',
    }),
    generateMockedPermission({
      id: 'kj9f090j0j90j90j90',
      name: 'Vitals Admin',
      code: 'vitals.admin',
      moduleId: 'jf9dsauf90udj9s0ajf90dsajf90dj9s0a',
      moduleName: 'Vitals',
    }),
  ],
  policies: [
    {
      id: faker.random.uuid(),
      name: 'Org Super Admin',
      code: 'org.superadmin',
      description: '',
      IsInUse: false,
      internalUseOnly: false,
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
      permissions: [
        generateMockedPermission({
          id: 'kj9f090j0j90j90j90',
          name: 'Vitals Admin',
          code: 'vitals.admin',
          moduleId: 'jf9dsauf90udj9s0ajf90dsajf90dj9s0a',
          moduleName: 'Vitals',
        }),
      ],
    },
    {
      id: faker.random.uuid(),
      name: 'ECG Super Admin',
      code: 'ecg.superadmin',
      description: '',
      IsInUse: false,
      internalUseOnly: true,
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
      permissions: [
        generateMockedPermission({
          id: 'kj9f090j0j90j90j90',
          name: 'Vitals Admin',
          code: 'vitals.admin',
          moduleId: 'jf9dsauf90udj9s0ajf90dsajf90dj9s0a',
          moduleName: 'Vitals',
        }),
      ],
    },
  ],
  policyGroups: [
    {
      id: faker.random.uuid(),
      title: 'Org Accounting',
      code: 'orgAccounting',
      description: '',
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
      policies: [],
    },
  ],
  applications: [
    generateMockedApplication({
      id: 'jf9da9jfj9edsaj9f0dsa',
      name: 'Addison Support',
      cognitoAppId: 'ADD_SUP',
      description: 'Internal business support platform',
      modules: [
        { id: 'mockedSurveysModuleID', name: 'Surveys' },
        { id: 'jf9dsauf90udj9s0ajf90dsajf90dj9s0a', name: 'Vitals' },
      ],
    }),
  ],
  vitalSigns,
  orgVitalAssignments: _.flatten(
    organizationList.map((org) => ({
      id: faker.random.uuid(),
      organizationId: org.id,
      assignedVitalSigns: vitalSigns
        // only add assignments listed in orgVitalAssignmentNames
        .filter((vitalSign) => orgVitalAssignmentNames.includes(vitalSign.name))
        .map((vitalSign) => ({
          id: vitalSign.id,
          code: vitalSign.code,
          defaultUnitCode: vitalSign.defaultUnitCode,
          name: vitalSign.name,
          displayName: vitalSign.displayName,
          active: true,
          archived: false,
          units: vitalSign.units,
        })),
    }))
  ),
  subVitalAssignments: [
    {
      id: faker.random.uuid(),
      subscriberId: 'jsmithId',
      assignedVitalSigns: [
        {
          id: 'heart-rate-id',
          code: 'heart-rate',
          name: 'Heart Rate',
          displayName: 'HEART RATE',
          active: true,
          archived: false,
          units: [
            {
              name: 'Beats per Minute',
              displayName: 'BPM',
              code: 'bpm',
              media: {
                audioURL: '',
                ssmlURL: '',
              },
            },
          ],
          defaultUnitCode: 'bpm',
        },
        {
          id: 'weight-id',
          code: 'weight',
          name: 'Weight',
          displayName: 'WEIGHT',
          active: true,
          archived: false,
          units: [
            {
              name: 'Pounds',
              displayName: 'lb',
              code: 'lb',
              media: {
                audioURL: '',
                ssmlURL: '',
              },
            },
            {
              name: 'Kilograms',
              displayName: 'kg',
              code: 'kg',
              media: {
                audioURL: '',
                ssmlURL: '',
              },
            },
          ],
          defaultUnitCode: 'lb',
        },
      ],
    },
  ],

  organizationList,
  subscriberList: [
    {
      id: 'jsmithId',
      policies: [],
      person: {
        id: faker.random.uuid(),
        salutation: 1,
        firstName: 'John',
        lastName: 'Smith',
        emailAddress: faker.internet.email(),
        suffix: '',
        party: generateParty(),
      },
      organization: organizationList[0],
      ...generateArchivable(),
      ...generateAuditable(),
      ...generateDisableable(),
      status: 0,
      integrationMetadata: {},
      nonPERS: false,
    },
  ],
  staffList: [
    {
      id: faker.random.uuid(),
      person: {
        id: faker.random.uuid(),
        salutation: 1,
        firstName: 'John',
        lastName: 'Smith',
        suffix: '',
        emailAddress: 'jsmith@ecg-hq.com',
        party: generateParty(),
      },
      ...generateAuditable(),
      ...generateArchivable(),
      ...generateDisableable(),
      phone: faker.phone.phoneNumberFormat(),
      // @ts-expect-error organizationList will never be undefined
      organization: _.sample(organizationList),
    },
    {
      id: faker.random.uuid(),
      person: {
        id: faker.random.uuid(),
        salutation: 1,
        firstName: 'Jane',
        lastName: 'Doe',
        suffix: '',
        emailAddress: 'jdoe@ecg-hq.com',
        party: generateParty(),
      },
      phone: faker.phone.phoneNumberFormat(),
      // @ts-expect-error organizationList will never be undefined
      organization: _.sample(organizationList),
    },
  ],
  accountPermissions: getECGAdminPermissionsPreset({ organizationList }),
  vitalSignAudit: [],
  ecgReminderMessages: [],
  orgReminderMessages: [],
  ecgReminderMessageTranslations: [],
  orgReminderMessageTranslations: [],
  ecgAvailableTranslations: [],
  orgAvailableTranslations: [],
  reminderMessageTypes: [
    {
      id: 'reminder',
      name: 'Reminder Message',
      description:
        'The message to be played when it is time to take the Vitals readings',
      displayOrder: 1,
    },
    {
      id: 'snooze-confirmation',
      name: 'Snooze Confirmation Message',
      description:
        'The message to be played when a Subscriber snoozes the Vitals reading reminder.',
      displayOrder: 2,
    },
    {
      id: 'repeated-reminder',
      name: 'Repeated Reminder Message',
      description:
        'The message to be played after the Subscriber has snoozed the reminder',
      displayOrder: 3,
    },
    {
      id: 'final-reminder',
      name: 'Final Reminder Message',
      description:
        'The message to be played after the max snooze attempts have been exhausted',
      displayOrder: 4,
    },
    {
      id: 'failed-observation',
      name: 'Failed Observation Message',
      description:
        'The message to be played when no Vitals readings are collected and all snooze attempts have been exhausted',
      displayOrder: 5,
    },
  ],
  ecgDefaultReminderMessages: {
    localeKey: 'en-US',
    messages: [
      {
        // @ts-expect-error bad swagger type
        id: 'reminder',
        name: 'Reminder Message',
        message: 'Time to take your vitals',
        description:
          'The message to be played when it is time to take the Vitals readings.',
      },
      {
        // @ts-expect-error bad swagger type
        id: 'snooze-confirmation',
        name: 'Snooze Confirmation Message',
        message: 'Reminder snoozed',
        description:
          'The message to be played when a Subscriber snoozes the Vitals reading reminder.',
      },
      {
        // @ts-expect-error bad swagger type
        id: 'repeated-reminder',
        name: 'Repeated Reminder Message',
        message: 'This is your second reminder to take your vitals',
        description:
          'The message to be played after the Subscriber has snoozed the reminder.',
      },
      {
        // @ts-expect-error bad swagger type
        id: 'final-reminder',
        name: 'Final Reminder Message',
        message: 'This is your final reminder to take your vitals',
        description:
          'The message to be played after the max snooze attempts have been exhausted.',
      },
      {
        // @ts-expect-error bad swagger type
        id: 'failed-observation',
        name: 'Failed Observation Message',
        message: 'You did not take your vitals, contacting responsible parties',
        description:
          'The message to be played when no Vitals readings are collected and all snooze attempts have been exhausted.',
      },
    ],
  },
  ecgTranslationsReminderMessages: [
    {
      localeKey: 'es-MX',
      messages: [
        {
          // @ts-expect-error bad swagger type
          id: 'reminder',
          name: 'Reminder Message',
          message: 'Es hora de tomar sus signos vitales',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'snooze-confirmation',
          name: 'Snooze Confirmation Message',
          message: 'Recordatorio pospuesto',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'repeated-reminder',
          name: 'Repeated Reminder Message',
          description:
            'The message to be played when it is time to take the Vitals readings.',
          message:
            'Este es su segundo recordatorio para tomar sus signos vitales',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'final-reminder',
          name: 'Final Reminder Message',
          message:
            'Este es su último recordatorio para tomar sus signos vitales.',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'failed-observation',
          name: 'Failed Observation Message',
          message:
            'No tomó sus signos vitales, contactando a las partes responsables',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
      ],
    },
    {
      localeKey: 'de-DE',
      messages: [
        {
          // @ts-expect-error bad swagger type
          id: 'reminder',
          name: 'Reminder Message',
          message: 'Zeit, Ihre Vitale zu nehmen',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'snooze-confirmation',
          name: 'Snooze Confirmation Message',
          message: 'Erinnerung döste',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'repeated-reminder',
          name: 'Repeated Reminder Message',
          message:
            'Dies ist Ihre zweite Erinnerung daran, Ihre Vitale zu nehmen',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'final-reminder',
          name: 'Final Reminder Message',
          message:
            'Dies ist Ihre letzte Erinnerung daran, Ihre Vitale zu nehmen',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
        {
          // @ts-expect-error bad swagger type
          id: 'failed-observation',
          name: 'Failed Observation Message',
          message:
            'Sie haben Ihre Vitaldaten nicht genommen und sich an die Verantwortlichen gewandt',
          description:
            'The message to be played when it is time to take the Vitals readings.',
        },
      ],
    },
  ],

  reminderTypes: {
    items: [
      {
        id: '9eb0ae84-1b57-4bc0-9171-317cd627e729',
        code: 'generic',
        description:
          'Allows sending a random one-off message/reminder to the Subscriber.',
        title: 'Generic Message/Reminder',
      },
      {
        id: 'e8f9ae65-96b5-46d1-9c2a-a92a485db18c',
        code: 'vitals-reminder',
        description: 'Allows sending a reminder to take Vitals.',
        title: 'Vitals Reminder',
      },
      {
        id: 'f2342cfc-b89c-47c0-9a52-5c9f443ac598',
        code: 'activity-reminder',
        description: 'Allows sending activity related reminders.',
        title: 'Activity Reminder',
      },
    ],
    totalRecords: 3,
  },
  ecgAdminTranslations: [
    {
      key: 'es-MX',
      name: 'Spanish',
      auditInfo: {
        createdAt: '2020-01-01T19:28:29Z',
        createdBy: 'Admin',
        updatedAt: '2020-01-02T19:28:29Z',
        updatedBy: 'Admin2',
      },
    },
    {
      key: 'de-DE',
      name: 'German',
      auditInfo: {
        createdAt: '2020-01-02T19:28:29Z',
        createdBy: 'Admin',
        updatedAt: '2020-01-03T19:28:29Z',
        updatedBy: 'Admin2',
      },
    },
  ],
  reminderPresets: [],
  reminderSchedule: [],
  organizationReminderMessages: null,
  salutationTypes: [{ id: 0, name: 'Dr.', code: 'dr' }],
  staffMemberTypes: [
    { id: '0', name: 'Doctor', code: 'doctor', description: '' },
    { id: '1', name: 'Nurse', code: 'nurse', description: '' },
  ],
  addressTypes: [
    {
      id: '0',
      name: 'I dont know what these look like',
      code: '',
      description: '',
    },
  ],
  countryCodes: [
    {
      isoCode: 'US',
      name: 'United States',
    },
  ],
  organizationTypes: [
    {
      id: 1,
      name: 'Healthcare Organization',
      description:
        'Organization that provides provides direct patient monitoring',
      code: 'HCO',
    },
  ],
  adminList: [
    {
      id: faker.random.uuid(),
      policies: ['123'],
      person: {
        id: faker.random.uuid(),
        salutation: 1,
        firstName: 'John',
        lastName: 'Smith',
        emailAddress: 'jsmith@email.com',
        party: generateParty(),
        ...generateAuditable(),
        ...generateDisableable(),
        ...generateArchivable(),
      },
      ...generateAuditable(),
      ...generateDisableable(),
      ...generateArchivable(),
    },
  ],
  disclaimerTypes: [
    {
      id: 'a68cf4b9-6cf7-4a7e-b8ef-71055947e80a',
      code: 'Vitalsthreshold-settings',
      name: 'Threshold Settings Disclaimer',
    },
  ],
  disclaimers: [
    {
      id: 'threshold-disclaimer',
      locale: 'en-US',
      disclaimerType: 'vital',
      disclaimer: 'Vital Thresholds Disclaimer 1 Text',
      createdAt: formatISO(faker.date.recent(30)),
      createdBy: 'Addison',
    },
  ],
  orgVitalThresholds: [
    {
      vitalSign: {
        id: 'body-temp-id',
        name: 'Body Temperature',
        displayName: 'TEMP',
        code: 'temp',
        units: [
          {
            name: 'Fahrenheit',
            displayName: 'F',
            code: 'f',
            media: {
              audioURL: '',
              ssmlURL: '',
            },
          },
          {
            name: 'Celsius',
            displayName: 'C',
            code: 'c',
            media: {
              audioURL: '',
              ssmlURL: '',
            },
          },
        ],
        defaultUnitCode: 'f',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
        ...generateAuditable(),
        ...generateDisableable(),
        ...generateArchivable(),
      },
      thresholds: [
        {
          severity: 1,
          direction: 'low',
          limit: 96,
        },
      ],
      normalLow: 96.7,
      normalHigh: 98.7,
      disclaimers: ['threshold-disclaimer'],
      ...generateAuditable(),
    },
  ],
  subVitalThresholds: [
    {
      vitalSign: {
        id: 'weight-id',
        name: 'Weight',
        displayName: 'WEIGHT',
        code: 'weight',
        units: [
          {
            name: 'Pounds',
            displayName: 'lb',
            code: 'lb',
            media: {
              audioURL: '',
              ssmlURL: '',
            },
          },
          {
            name: 'Kilograms',
            displayName: 'kg',
            code: 'kg',
            media: {
              audioURL: '',
              ssmlURL: '',
            },
          },
        ],
        defaultUnitCode: 'lb',
        media: {
          audioURL: '',
          ssmlURL: '',
        },
        ...generateAuditable(),
        ...generateDisableable(),
        ...generateArchivable(),
      },
      thresholds: [
        {
          severity: 1,
          direction: 'low',
          limit: 120,
        },
      ],
      normalLow: 140,
      normalHigh: 200,
      disclaimers: ['threshold-disclaimer'],
      ...generateAuditable(),
    },
  ],
  webhookSubscriptions: [
    {
      id: 'kfdsafjd9sajf9d0sajf90dsa',
      isDevelopment: true,
      organizationId: organizationList[0].id,
      postUri: 'https://gooogle.com/post',
      events: [],
      version: 'v1.0',
      errors: 0,
      ...generateArchivable(),
      ...generateDisableable(),
      ...generateAuditable(),
    },
    {
      id: 'kfodpsajfopdjsaopfdsa',
      isDevelopment: true,
      organizationId: organizationList[0].id,
      postUri: 'https://yahooo.com/post',
      events: [],
      version: 'v1.0',
      errors: 0,
      ...generateArchivable(),
      ...generateDisableable(),
      ...generateAuditable(),
    },
  ],
  webhookCategories: [
    {
      id: '0f09dasjf90ds90a',
      name: 'Vitals',
      code: 'vitals',
      description: 'Webhook category for the vitals module',
      ...generateDisableable(),
      ...generateArchivable(),
      ...generateAuditable(),
    },
  ],
  webhookEvents: [
    {
      id: 'j9f0djsa90fjds9a',
      name: 'Device Deactivated',
      code: 'vitals.device.deactivated',
      description: 'Device deactivated event',
      version: 'v1.0',
      webhookCategory: {
        id: '0f09dasjf90ds90a',
        name: 'Vitals',
        code: 'vitals',
        description: 'Webhook category for the vitals module',
        ...generateDisableable(),
        ...generateArchivable(),
        ...generateAuditable(),
      },
      ...generateDisableable(),
      ...generateAuditable(),
    },
    {
      id: 'k0f90dsua90fds90a',
      name: 'Device Activated',
      code: 'vitals.device.activated',
      description: 'Device activated event',
      version: 'v1.0',
      webhookCategory: {
        id: '0f09dasjf90ds90a',
        name: 'Vitals',
        code: 'vitals',
        description: 'Webhook category for the vitals module',
        ...generateDisableable(),
        ...generateArchivable(),
        ...generateAuditable(),
      },
      ...generateDisableable(),
      ...generateAuditable(),
    },
  ],
  alertContact: [],
  partners: [
    {
      id: faker.random.uuid(),
      allowSubscriberAccess: false,
      party: generateParty(),
      person: {
        id: faker.random.uuid(),
        salutation: 1,
        firstName: 'John',
        lastName: 'Smith',
        emailAddress: 'jsmith@email.com',
        party: generateParty(),
        ...generateAuditable(),
        ...generateDisableable(),
        ...generateArchivable(),
      },
      account: {
        email: 'jsmith@email.com',
        password: 'password',
        isAutoGenerated: false,
      },
      partnerType: {
        id: 'MCP',
        code: 'mcp',
        name: 'Master Care Partner',
        description: 'MCP Partner Type',
        isOrganization: false,
        apptivoValueId: 'VALUE_1600991726917_300',
        ...generateDisableable(),
        directPayout: false,
        successLadderBonus: false,
      },
    },
  ],
  partnerTypes: [
    {
      id: 'MCP',
      code: 'mcp',
      name: 'Master Care Partner',
      description: 'MCP Partner Type',
      isOrganization: false,
      apptivoValueId: 'VALUE_1600991726917_300',
      ...generateDisableable(),
      directPayout: false,
      successLadderBonus: false,
    },
    {
      id: 'HO',
      code: 'ho',
      name: 'Healthcare Organization',
      description: 'HO Partner Type',
      isOrganization: true,
      apptivoValueId: 'VALUE_1600991726917_300',
      ...generateDisableable(),
      directPayout: false,
      successLadderBonus: false,
    },
  ],
  partnerTypeRelationships: [],
  partnerTypeCommissions: [],
  alertDeliveryRules: [],

  alertCategories: [
    {
      id: '694f33b4-1a16-4b38-8041-e6b600b0dc0e',
      name: 'Vital Sign Reading Received',
      code: 'vitals-reading',
      description: 'Vital Sign Reading Received',
      rrmsCode: 'null',
      parentCategory: {
        id: '577c6570-50fc-47b0-8be1-d083aeb4eb18',
        name: 'Vital Signs Alerts',
        code: 'VSA',
        description: 'Vital Signs Alerts',
        rrmsCode: 'null',
        activeInfo: {
          active: true,
          activeStatusChangedAt: '2021-06-14',
        },
      },
      activeInfo: {
        active: true,
        activeStatusChangedAt: '2021-06-14',
      },
    },
  ],
  contracts: [
    {
      id: 'contractId',
      currencyCode: 'USD',
      descriptionInfo: {
        title: 'Base ProHealth Contract',
        description:
          'General ProHealth package that comes with standard hub and 3 peripherals',
      },
      audit: generateAuditable().auditInfo,
      // set effective range to start yesterday
      effectiveDates: { fromDate: formatISO(subDays(new Date(), 1)) },
      organization: { id: 'electronicCaregiverId' },
      organizationId: 'electronicCaregiverId',
      lineItems: [
        {
          id: faker.random.uuid(),
          contractId: 'contractId',
          lineItemType: 'PrimaryProduct',
          primaryProductLineItem: {
            descriptionInfo: {
              title: 'ProHealth Hub',
              description: 'Base hub unit that connects with peripherals',
            },
            pricing: {
              activationFee: { price: { amount: 50, interval: 'OneTime' } },
              productFee: { price: { amount: 15, interval: 'Monthly' } },
              warrantySelection: {
                options: [
                  {
                    id: 'hub-warranty-1',
                    priceDescriptor: {
                      price: { amount: 5, interval: 'OneTime' },
                      descriptionInfo: { title: '1 Year Warranty' },
                    },
                  },
                  {
                    id: 'hub-warranty-2',
                    priceDescriptor: {
                      price: { amount: 15, interval: 'OneTime' },
                      descriptionInfo: { title: '2 Year Warranty' },
                    },
                  },
                ],
              },
            },
            productId: 'prohealth-hub-id',
          },
          activation: generateDisableable().activeInfo,
          audit: generateAuditable().auditInfo,
        },
        {
          id: faker.random.uuid(),
          contractId: 'contractId',
          lineItemType: 'ProductType',
          productTypeLineItem: {
            productCategory: 'Premium',
            allowedQuantity: 2,
            groupPrice: false,
            descriptionInfo: {
              title: 'Premium Peripherals',
              description: 'premium ProHealth peripherals',
            },
            pricing: {
              activationFee: { price: { amount: 10, interval: 'OneTime' } },
              productFee: { price: { amount: 5, interval: 'Monthly' } },
              warrantySelection: {
                options: [
                  {
                    id: 'peripheral-warranty',
                    priceDescriptor: {
                      price: { amount: 5, interval: 'OneTime' },
                      descriptionInfo: { title: '1 Year Peripheral Warranty' },
                    },
                  },
                ],
              },
            },
            availableProducts: ['spirometer-id', 'weight-250-id'],
          },
          activation: generateDisableable().activeInfo,
          audit: generateAuditable().auditInfo,
        },
        {
          id: faker.random.uuid(),
          contractId: 'contractId',
          lineItemType: 'DeviceOrService',
          optional: true,
          deviceOrServiceLineItem: {
            productId: 'pocketmd-id',
            descriptionInfo: {
              title: 'PocketMD',
              description:
                'Opting in to this service allows 1 on 1 consulting with a doctor via the hub',
            },
            pricing: {
              activationFee: { price: { amount: 0, interval: 'OneTime' } },
              productFee: {
                price: { amount: 10, interval: 'Monthly' },
                descriptionInfo: { title: 'Service Fee' },
              },
            },
          },
          activation: generateDisableable().activeInfo,
          audit: generateAuditable().auditInfo,
        },
      ],
      shippingPrices: [
        {
          id: '614a03153c4c21e32062b425',
          contractId: '613237a32f7045a6e3d7f2fc',
          priceDescriptor: {
            descriptionInfo: {
              title: 'Ground',
              description: 'Testing Ground',
            },
            price: {
              flatOrPercent: 'Flat',
              amount: 50,
              interval: 'OneTime',
            },
          },
          archive: {
            archived: false,
            archivedAt: null,
          },
        },
      ],
    },
  ],
  products: [
    {
      id: 'prohealth-hub-id',
      title: 'ProHealth Hub',
      category: 'Core',
      productType: 'Core',
      provisioningNeeded: true,
    },
    {
      id: 'spirometer-id',
      title: 'Spirometer',
      category: 'Premium',
      productType: 'Device',
    },
    {
      id: 'weight-250-id',
      title: 'Weight Scale 250kg',
      category: 'Premium',
      productType: 'Device',
    },
    {
      id: 'pocketmd-id',
      title: 'PocketMD',
      category: 'Standard',
      productType: 'Service',
    },
  ],
  orders: [
    // used for provisioning tests
    {
      id: 'orderId',
      orderCode: 'order code',
      contractId: 'contractId',
      organizationId: 'electronicCaregiverId',
      subscriberId: 'jsmithId',
      audit: { createdAt: new Date().toISOString() },
      serviceDetails: { expectedServiceStartDate: new Date().toISOString() },
      provisioningNeeded: true,
      orderStatus: { id: 1, title: 'Confirmed', description: '' },
      orderType: 'New',
      orderLineItems: [
        {
          id: 'prohealthId',
          provisioningNeeded: true,
          orderId: 'orderId',
          productId: 'prohealth-hub-id',
          orderLineItemStatusId: 1,
        },
      ],
    },
  ],
  orderActivityHistory: [],
  provisioningAttributes: [{ id: 'xmitId', code: 'xmit', title: 'Xmit Id' }],
  orderStatuses: [
    {
      id: 1,
      title: 'Confirmed',
      description: '',
    },
    {
      id: 2,
      title: 'In-Process',
      description: '',
    },
    {
      id: 3,
      title: 'Provisioning In-Process',
      description: '',
    },
    {
      id: 4,
      title: 'Provisioning Complete',
      description: '',
    },
    {
      id: 5,
      title: 'Shipped',
      description: '',
    },
    {
      id: 6,
      title: 'Delivered',
      description: '',
    },
    {
      id: 7,
      title: 'Cancelled',
      description: '',
    },
  ],
  orderLineItemStatuses: [
    {
      id: 1,
      title: 'Confirmed',
      description: '',
    },
    {
      id: 2,
      title: 'In-Process',
      description: '',
    },
    {
      id: 3,
      title: 'Provisioning-In-Process',
      description: '',
    },
    {
      id: 4,
      title: 'Provisioning-Complete',
      description: '',
    },
  ],
  subscriberAddress: [
    {
      id: 'bc415a40-ce6a-4d0f-bd94-04e483ccfb1a',
      types: ['primary'],
      address: {
        addressLine1: '130 West Lohman Avenue',
        addressLine2: '43',
        city: 'Las Cruces',
        countryCode: 'US',
        postalCode: '88005',
        stateProvinceCode: 'NM',
      },
    },
    {
      id: '097d27b4-67e9-4892-b927-7d8381f951e2',
      types: ['shipping'],
      address: {
        addressLine1: '3445 South Locust Street',
        addressLine2: '',
        city: 'Las Cruces',
        countryCode: 'US',
        postalCode: '87529',
        stateProvinceCode: 'NM',
      },
    },
    {
      id: '5b7bb3d5-8c89-4656-a441-2367a366c3f5',
      types: ['billing'],
      address: {
        addressLine1: 'Buutendörp',
        addressLine2: '',
        city: 'Breese',
        countryCode: 'DE',
        postalCode: '88221',
        stateProvinceCode: 'BB',
      },
    },
  ],
}

const init = () => {
  Object.keys(initialData).forEach((key) => {
    window.localStorage.setItem(key, JSON.stringify(initialData[key]))
  })
}

type get = <Response>(key: string) => Response
const get: get = (key) => {
  const value = window.localStorage.getItem(key)

  // if value doesn't exist, return undefined
  if (!value)
    throw new Error('Object with provided key does not exist in mock DB')

  return JSON.parse(value)
}

type set = <Request>(key: string, item: Request) => void
const set: set = (key, item) => {
  window.localStorage.setItem(key, JSON.stringify(item))
}

export default { init, get, set }
