import React, { useMemo } from 'react'
import { LoadingIcon, SimpleList, Dialog } from 'elements'
import { Button } from 'atlas'
import {
  useReminderMessageTranslationsQuery,
  useDeleteReminderMessageTranslationMutation,
} from 'hooks/reminders'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'

type OrganizationRemidnerMessagesListProps = {
  selectedLocale: { key: string; name: string }
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const OrganizationReminderMessagesList = ({
  selectedLocale,
  isDialogOpen,
  setIsDialogOpen,
}: OrganizationRemidnerMessagesListProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const {
    data: reminderMessages,
    isLoading,
  } = useReminderMessageTranslationsQuery(
    assumedOrganizationRole?.orgID || '',
    selectedLocale.key
  )

  const {
    mutate: deleteReminderMessageTranslation,
  } = useDeleteReminderMessageTranslationMutation()

  const tableData: ReminderMessage[] = useMemo(() => {
    if (!reminderMessages) return []
    return _.orderBy(
      reminderMessages.items,
      (reminderMessage) => reminderMessage.messageType.displayOrder
    )
  }, [reminderMessages])

  if (isLoading) return <LoadingIcon />

  return (
    <ReminderMessagesContainer>
      <SimpleList
        data={tableData}
        baseDelay={0.25}
        emptyProps={{
          title: t('No Reminder Messages Created Yet'),
        }}
        renderItem={(item) => (
          <SimpleList.Item key={item.id}>
            <ListItemContainer>
              <MessageDetailsContainer>
                {item.messageType.name}
                <MessageDescription>
                  {item.messageType.description}
                </MessageDescription>
              </MessageDetailsContainer>

              <ReminderMessage>{item.message}</ReminderMessage>
            </ListItemContainer>
          </SimpleList.Item>
        )}
      />
      <Dialog
        open={isDialogOpen}
        title={`${t('Delete')} ${selectedLocale.name} ${t('translation')}?`}
        content={
          <p>
            {t(
              'Are you sure you want to delete this translation? This will remove it completely'
            )}
          </p>
        }
        actions={
          <>
            <Button
              type="danger-filled"
              onClick={() => {
                if (!reminderMessages?.items) return setIsDialogOpen(false)

                deleteReminderMessageTranslation({
                  locale: selectedLocale.key,
                  orgId: assumedOrganizationRole?.orgID || '',
                  reminderMessageIds: reminderMessages.items.map(
                    (translation) => translation.id || ''
                  ),
                })

                return setIsDialogOpen(false)
              }}
            >
              {t('Delete')}
            </Button>

            <Button onClick={() => setIsDialogOpen(false)} type="secondary">
              {t('Cancel')}
            </Button>
          </>
        }
      />
    </ReminderMessagesContainer>
  )
}

export default OrganizationReminderMessagesList

const ReminderMessagesContainer = tw.div`w-full mb-8`

const ListItemContainer = tw.div`grid grid-cols-6 mx-4 items-center`

const MessageDetailsContainer = tw.p`col-span-2 text-lg`

const MessageDescription = tw.p`opacity-50 text-xs`

const ReminderMessage = tw.p`col-start-4 col-end-7 text-lg`
