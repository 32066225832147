import { rest } from 'msw'
import db from '../../data/db'

const auditTrailHandlers = [
  // get vital sign audit trail list
  rest.get<undefined, VitalsAPIResponse['GET']['/audit-trails']>(
    '/audit-trails',
    (req, res, ctx) => {
      // get all possible searchParams
      const limit = Number(req.url.searchParams.get('limit')) || 25
      const offset = Number(req.url.searchParams.get('offset')) || 0
      const entity = req.url.searchParams.get('entity')
      const action = req.url.searchParams.get('action')

      // get vital sign audit events
      const allAuditEvents = db.get<VitalsAuditEvent[]>('vitalSignAudit')

      const filteredAuditEvents = allAuditEvents.filter(
        (auditEvent) =>
          // filter by entity name if provided
          (!entity || entity === auditEvent.entityName) &&
          // filter by action type if provided
          (!action || action === auditEvent.action)
      )

      const paginatedAuditEvents = filteredAuditEvents.filter(
        (_, index) =>
          // filter by paginated range
          index >= offset && index < limit
      )

      return res(
        ctx.json({
          items: paginatedAuditEvents,
          recordsPerPage: limit - offset,
          totalRecords: filteredAuditEvents.length,
          currentPageNumber: Math.ceil(offset / limit) + 1,
          totalPages: Math.ceil(filteredAuditEvents.length / limit),
        })
      )
    }
  ),
  // get vital sign assignment audit trail list for an org
  rest.get<
    undefined,
    OrgsAPIResponse['GET']['/api/organizations/{organizationId}/audit-trails']
  >('/organization/:orgId/audit-trails', (req, res, ctx) => {
    // get all possible searchParams
    const limit = Number(req.url.searchParams.get('limit')) || 25
    const offset = Number(req.url.searchParams.get('offset')) || 0
    const action = req.url.searchParams.get('action')

    // get vital sign audit events
    const allAuditEvents = db.get<OrgsAuditEvent[]>('vitalSignAudit')

    const filteredAuditEvents = allAuditEvents.filter(
      (auditEvent) =>
        // filter by orgId
        auditEvent.organizationId === req.params.orgId &&
        // filter by action type if provided
        (!action || action === auditEvent.action)
    )

    const paginatedAuditEvents = filteredAuditEvents.filter(
      (_, index) =>
        // filter by paginated range
        index >= offset && index < limit
    )

    return res(
      ctx.json({
        items: paginatedAuditEvents,
        recordsPerPage: limit - offset,
        totalRecords: filteredAuditEvents.length,
        currentPageNumber: Math.ceil(offset / limit) + 1,
        totalPages: Math.ceil(filteredAuditEvents.length / limit),
      })
    )
  }),
]

export default auditTrailHandlers
