import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import { success, typedOrgsApi, handleQueryError } from 'utils'

const useArchivePolicyGroupMutation = () => {
  const queryCache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['POST']['/api/policy-groups/{policyGroupId}/archive'],
    KyError,
    string
  >(archivePolicyGroup, {
    onSuccess: (_, policyGroupId) => {
      const policyGroups:
        | OrgsAPIResponse['GET']['/api/policy-groups']
        | undefined = queryCache.getQueryData(['getPolicyGroups'])

      if (policyGroups) {
        const updatedQueryCacheValue: OrgsAPIResponse['GET']['/api/policy-groups'] = {
          ...policyGroups,
          items:
            policyGroups.items?.map((policyGroup) => {
              if (policyGroup.id === policyGroupId)
                return {
                  ...policyGroup,
                  archiveInfo: { ...policyGroup.archiveInfo, archived: true },
                }

              return policyGroup
            }) || [],
        }
        // immediately update the policyGroup list
        queryCache.setQueryData<OrgsAPIResponse['GET']['/api/policy-groups']>(
          ['getPolicyGroups'],
          updatedQueryCacheValue
        )
      }

      // refetch from API to make sure the policyGroup list is in sync with the server
      queryCache.invalidateQueries('getPolicyGroups')

      // notify the user
      success({ message: t('Policy Group archived') })
    },
    onError: async (error) => {
      handleQueryError({ error })
    },
  })

  return mutation
}

export default useArchivePolicyGroupMutation

const archivePolicyGroup = async (policyGroupId: string) => {
  const result = typedOrgsApi.post(
    '/api/policy-groups/{policyGroupId}/archive',
    { pathParams: { policyGroupId } }
  )

  return result
}
