import React from 'react'
import { Chip } from 'atlas'
import { useTranslation } from 'react-i18next'

type EntityStatusChipProps = { isActive: boolean; isArchived: boolean }

const EntityStatusChip = ({ isActive, isArchived }: EntityStatusChipProps) => {
  const { t } = useTranslation()

  return (
    <Chip color={entityStatusToColor({ isActive, isArchived })}>
      {t(entityStatusToText({ isActive, isArchived }))}
    </Chip>
  )
}

export default EntityStatusChip

/**
 * Creates status colors from Archivable & Disableable objects
 * @param statusObject
 * @returns string representation of status color
 */
export const entityStatusToColor = ({
  isActive,
  isArchived,
}: EntityStatusChipProps): 'green' | 'gray' | 'red' => {
  // archived case
  if (isArchived) return 'gray'

  // active case
  if (isActive) return 'green'

  // inactive case
  return 'red'
}

/**
 * Creates a status string from Archivable & Disableable objects
 * @param statusObject
 * @returns string representation of Archived/Active status
 */
export const entityStatusToText = ({
  isActive,
  isArchived,
}: EntityStatusChipProps) => {
  // archived case
  if (isArchived) return 'Archived'

  // active case
  if (isActive) return 'Active'

  // inactive case
  return 'Inactive'
}
