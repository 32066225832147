import {
  generateArchivable,
  generateParty,
  generateAuditable,
  generateDisableable,
} from 'test-utils/api/utils'

/**
 * maps organization form contents to organization object
 * @param organizationForm
 * @returns Organization object
 */
const organizationFormToOrganization = (
  organizationForm: OrganizationForm
): Organization => ({
  id: 'placeholder',
  hcoXrefId: organizationForm.hcoXrefId ?? '',
  type: organizationForm.type,
  status: 'Inactive',
  businessName: organizationForm.businessName,
  displayName: organizationForm.displayName,
  nodes: [],
  party: generateParty({
    addresses: organizationForm.addresses,
    phoneNumbers: organizationForm.phoneNumbers,
  }),
  ...generateArchivable(),
  ...generateAuditable(),
  ...generateDisableable({ active: false }),
  integrationMetadata: {},
  organizationStatus: 1,
  onBoardingStatus: 1,
  originationSource: 'ADDI',
})

export default organizationFormToOrganization
