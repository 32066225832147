import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'
import _ from 'lodash'

const useDisclaimerQuery = (
  disclaimerCode?: string | undefined,
  locale?: string | undefined,
  disclaimerId?: string | undefined
) => {
  const query = useQuery<VitalsAPIResponse['GET']['/disclaimers'], KyError>(
    ['getDisclaimers', disclaimerCode, locale, disclaimerId],
    () => getDisclaimers(disclaimerCode, locale, disclaimerId)
  )

  return query
}

export default useDisclaimerQuery

const getDisclaimers = async (
  disclaimerCode?: string | undefined,
  locale?: string | undefined,
  disclaimerTypeId?: string | undefined
) => {
  if (disclaimerCode) {
    // get the disclaimer type that matches the provided disclaimerCode
    const disclaimerType = await vitalsApi
      .get('disclaimer-types', {
        searchParams: {
          code: disclaimerCode,
        },
      })
      .json<VitalsAPIResponse['GET']['/disclaimer-types']>()

    if (!disclaimerType.items) return { items: [], totalRecords: 0 }

    const result = await vitalsApi
      .get('disclaimers', {
        searchParams: {
          include: 'translations',
          type: disclaimerType.items[0].id,
          ...(locale ? { locale } : {}),
        },
      })
      .json<VitalsAPIResponse['GET']['/disclaimers']>()

    return {
      items: _.orderBy(result.items, 'createdAt'),
      totalRecords: result.totalRecords,
    }
  }

  const result = await vitalsApi
    .get('disclaimers', {
      searchParams: {
        include: 'translations',
        type: disclaimerTypeId || '',
        ...(locale ? { locale } : {}),
      },
    })
    .json<VitalsAPIResponse['GET']['/disclaimers']>()

  return {
    items: _.orderBy(result.items, 'createdAt'),
    totalRecords: result.totalRecords,
  }
}
