import { typedOrgsApi } from 'utils'
import { useQuery } from 'react-query'

type UsePolicyGroupsAuditTrailQueryProps = {
  filters: PolicyGroupAuditSearchParams
}

const usePolicyGroupsAuditTrailQuery = ({
  filters,
}: UsePolicyGroupsAuditTrailQueryProps) => {
  const query = useQuery<
    OrgsAPIResponse['GET']['/api/policy-groups/audit-trails'],
    KyError
  >(['getAuditTrail', 'policy-groups', filters], () => getAuditTrail(filters), {
    staleTime: 0,
  })

  return query
}

export default usePolicyGroupsAuditTrailQuery

const getAuditTrail = async (filters: PolicyGroupAuditSearchParams) =>
  typedOrgsApi.get('/api/policy-groups/audit-trails', {
    searchParams: {
      ...filters,
    },
  })

export type PolicyGroupAuditSearchParams = NonNullable<
  Extract<
    OrgsAPIRequest['GET']['/api/policy-groups/audit-trails']['searchParams'],
    Record<string, unknown>
  >
>
