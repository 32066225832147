import { useMutation } from 'hooks'
import { typedVitalsApi } from 'utils'
import { useTranslation } from 'react-i18next'
import { generateAuditable } from 'test-utils/api/utils'
import { updateFnConstructor } from 'hooks/useMutation'

const useCreateAlertNoteMutation = () => {
  const { t } = useTranslation()
  const mutation = useMutation<
    VitalsAPIResponse['POST']['/api/alerts/{alertId}/notes'],
    AlertNotesMutationArgs
  >({
    mutationFn: createAlertNote,
    successMsg: { message: t('Note Added'), showOn: 'onSuccess' },
    optimisticUpdates: [
      {
        cacheKey: ({ alert }) => ['getAlertNotes', alert.id],
        updateFn: updateFnConstructor<
          VitalsAPIResponse['GET']['/api/alerts/{alertId}/notes'],
          AlertNotesMutationArgs
        >((oldCache, { alert, note }) => {
          return oldCache
            ? {
                ...oldCache,
                items: [
                  ...(oldCache.items || []),
                  {
                    id: '123',
                    alert: alert,
                    notes: note,
                    ...generateAuditable(),
                  },
                ],
              }
            : undefined
        }),
      },
    ],
  })

  return mutation
}

export default useCreateAlertNoteMutation

type AlertNotesMutationArgs = {
  note: string
  alert: Alert
}

const createAlertNote = ({ note, alert }: AlertNotesMutationArgs) =>
  typedVitalsApi.post('/api/alerts/{alertId}/notes', {
    pathParams: { alertId: alert.id },
    body: { note },
  })
