import React from 'react'
import { EntityLabel, InformationCard } from 'elements'
import { useTranslation } from 'react-i18next'
import { useOrganizationQuery } from 'hooks/organizations'
import tw from 'twin.macro'

type ContractInfoCardProps = {
  contract: Contract | undefined
}

const ContractInfoCard = ({ contract }: ContractInfoCardProps) => {
  const { t } = useTranslation()

  const organizationQuery = useOrganizationQuery(contract?.organizationId || '')

  return (
    <InformationCard>
      <InformationCard.Item
        label={t('Title')}
        value={contract?.descriptionInfo?.title || '-'}
      />

      <InformationCard.Item
        label={t('Organization')}
        value={
          <EntityLabel
            name={organizationQuery.data?.businessName ?? '-'}
            id={contract?.organizationId || '-'}
            to={`/organizations/${contract?.organizationId}`}
            condensed
          />
        }
      />

      <InformationCard.Item
        label={t('Description')}
        value={contract?.descriptionInfo?.description || '-'}
      />

      <InformationCard.Item
        label={t('ID')}
        value={<IDText>{contract?.id}</IDText>}
      />
    </InformationCard>
  )
}

export default ContractInfoCard

const IDText = tw.p`font-mono`
