import queries from './queries'

const useAPIQuery = <T extends keyof typeof queries>(
  queryStr: T,
  ...params: Parameters<typeof queries[typeof queryStr]>
): ReturnType<Pick<typeof queries, T>[T]> =>
  // @ts-expect-error I really don't know...
  queries[queryStr](params[0]) as ReturnType<Pick<typeof queries, T>[T]>

export default useAPIQuery
