import React from 'react'
import { motion } from 'framer-motion'

type FadeInScaleUpProps = {
  children: React.ReactNode
  delay?: number
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
  disable?: boolean
}

const start = {
  opacity: 0,
  scale: 0.9,
}

const end = {
  opacity: 1,
  scale: 1,
}

const FadeInScaleUp = (
  {
    children,
    delay = 0,
    className,
    onClick,
    style,
    disable,
  }: FadeInScaleUpProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <motion.div
      ref={ref}
      initial={disable ? false : start}
      animate={end}
      transition={{ duration: 0.2, delay }}
      className={className}
      onClick={onClick}
      style={style}
    >
      {children}
    </motion.div>
  )
}

export default React.forwardRef(FadeInScaleUp)
