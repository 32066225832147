import React from 'react'
import { Layout, Unauthorized, NavigationCard } from 'elements'
import { useAccountPermissionsQuery } from 'hooks/organizations'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks'
import { FadeInSlideDown } from 'animations'
import tw from 'twin.macro'

const AccessControl = () => {
  const navigate = useNavigate()
  const { data: accountPermissions } = useAccountPermissionsQuery()
  const { t } = useTranslation()

  // ECG Admin case
  if (accountPermissions?.data.ecgAdmin.permissions.includes('acm.readonly'))
    return (
      <Layout
        title={t('Access Control')}
        description={t(
          'Manage access rights to users across the platform so they can only access explicitly assigned options.'
        )}
      >
        <AccessControlContainer>
          <FadeInSlideDown delay={0.1}>
            <NavigationCard
              label={t('Modules')}
              description={t(
                'Manage applications and permissions associated with modules.'
              )}
              iconColor="red"
              icon="module"
              onClick={() => navigate('modules')}
            />
          </FadeInSlideDown>

          <FadeInSlideDown delay={0.15}>
            <NavigationCard
              label={t('Applications')}
              description={t(
                'Create applications and manage their associated modules.'
              )}
              iconColor="green"
              icon="application"
              onClick={() => navigate('applications')}
            />
          </FadeInSlideDown>

          <FadeInSlideDown delay={0.2}>
            <NavigationCard
              label={t('Permissions')}
              description={t(
                'Create and manage permissions available to the platform.'
              )}
              iconColor="purple"
              icon="permissions"
              onClick={() => navigate('permissions')}
            />
          </FadeInSlideDown>

          <FadeInSlideDown delay={0.25}>
            <NavigationCard
              label={t('Policies')}
              description={t('Organize groups of permissions into policies.')}
              iconColor="yellow"
              icon="policies"
              onClick={() => navigate('policies')}
            />
          </FadeInSlideDown>

          <FadeInSlideDown delay={0.3}>
            <NavigationCard
              label={t('Policy Groups')}
              description={t('Structure existing policies into policy groups.')}
              iconColor="cyan"
              icon="policies"
              onClick={() => navigate('policy-groups')}
            />
          </FadeInSlideDown>
        </AccessControlContainer>
      </Layout>
    )

  return (
    <Layout title={t('Access Control')}>
      <Unauthorized />
    </Layout>
  )
}

export default AccessControl

const AccessControlContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
