import { useMutation } from 'hooks'
import { ordersApi } from 'utils'

const useReactivateOrderMutation = () => {
  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/reactivate'],
    reactivateOrderArgs
  >({
    mutationFn: reactivateOrder,
    successMsg: { message: 'Order Reactivated', showOn: 'onSuccess' },
    additionalCachesToInvalidate: [
      (orderId) => ['order', { orderId }],
      (orderId) => ['orderActivity', { orderId }],
    ],
  })
  return mutation
}

export default useReactivateOrderMutation

type reactivateOrderArgs = string

const reactivateOrder = (orderId: reactivateOrderArgs) =>
  ordersApi.post('/orders/{orderId}/reactivate', { pathParams: { orderId } })
