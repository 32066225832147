import React from 'react'
import { motion } from 'framer-motion'

type FadeInSlideRightProps = {
  children: React.ReactNode
  delay?: number
  className?: string
  onClick?: () => void
}

const start = {
  x: -10,
  opacity: 0,
}

const end = {
  x: 0,
  opacity: 1,
}

const FadeInSlideRight = ({
  children,
  delay = 0,
  className,
  onClick,
}: FadeInSlideRightProps) => {
  return (
    <motion.div
      initial={start}
      animate={end}
      transition={{ duration: 0.2, delay }}
      className={className}
      onClick={onClick}
    >
      {children}
    </motion.div>
  )
}

export default FadeInSlideRight
