import { useQuery } from 'react-query'
import { webhooksApi } from 'utils'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'

type useWebhookActivityQueryArgs = {
  subscriptionId?: string
}

const useWebhookActivityQuery = ({
  subscriptionId,
}: useWebhookActivityQueryArgs) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const query = useQuery<GetWebhookActivityResponse, KyError>(
    ['getWebhookActivity', assumedOrganizationRole?.orgID, subscriptionId],
    () => getWebhookActivity(assumedOrganizationRole?.orgID, subscriptionId),
    {
      enabled: !!assumedOrganizationRole?.orgID && !!subscriptionId,
    }
  )

  return query
}

export default useWebhookActivityQuery

const getWebhookActivity = async (
  organizationId?: string,
  subscriptionId?: string
) => {
  const searchParams = [
    ['order_by', 'DESC'],
    ['take', '10000'],
  ]

  const result = webhooksApi
    .get(
      `api/organizations/${organizationId}/webhook-subscriptions/${subscriptionId}/activity`,
      {
        searchParams,
      }
    )
    .json<GetWebhookActivityResponse>()

  return result
}
