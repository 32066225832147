import React from 'react'
import { Layout } from 'elements'
import { useTranslation } from 'react-i18next'
import { ECGAdminVitalSignsAuditTrailList } from 'components/ecg-admin-vital-signs'

const ECGAdminVitalSignsAuditTrail = () => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t('Audit Trail')}
      description={t(
        'Review all actions taken in relation to ECG Platform-level vital signs.'
      )}
      breadcrumbs={[
        {
          name: t('Vitals'),
          route: '/vitals',
        },
        { name: t('Vital Signs'), route: '/vitals/vital-signs' },
        {
          name: t('Audit Trail'),
        },
      ]}
    >
      <ECGAdminVitalSignsAuditTrailList />
    </Layout>
  )
}

export default ECGAdminVitalSignsAuditTrail
