import React, { useState, useMemo } from 'react'
import { useAdminQuery } from 'hooks/admins'
import { Button, Empty } from 'atlas'
import { Layout, LoadingIcon } from 'elements'
import { AdminSelect } from 'components/admins'
import { AssignedPoliciesList } from 'components/access-control/policies'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FadeInSlideDown } from 'animations'
import { usePoliciesQuery } from 'hooks/access-control/policies'
import { useDelay } from 'hooks'
import { useUpdateAdminPoliciesMutation } from 'hooks/admins'
import tw from 'twin.macro'

const AdminAccessControl = () => {
  const { adminId } = useParams()
  const { t } = useTranslation()
  const delay = useDelay()

  const policiesQuery = usePoliciesQuery()

  const [isAssignPoliciesFormOpen, setIsAssignPoliciesFormOpen] = useState(
    false
  )
  const adminQuery = useAdminQuery(adminId)

  const updateAdminPoliciesMutation = useUpdateAdminPoliciesMutation()

  const [searchValue, setSearchValue] = useState<string>('')

  const adminPolicies: Policy[] = useMemo(() => {
    if (!adminQuery.data?.policies || !policiesQuery.data) return []

    const result: Policy[] = adminQuery.data.policies
      .map((policyId) =>
        policiesQuery.data.items?.find((policy) => policy.id === policyId)
      )

      // filter out the undefined's
      .filter((item): item is Policy => !!item)

    // filter by searched value
    const searchResult = result.filter((policy) =>
      policy.name.toLowerCase().includes(searchValue.toLowerCase())
    )

    return searchResult
  }, [adminQuery.data, policiesQuery.data, searchValue])

  if (adminQuery.isLoading || policiesQuery.isLoading) return <LoadingIcon />

  if (!adminQuery.data)
    return (
      <Empty
        title={t('Admin Not Found')}
        description={t(
          `Either this admin doesn't exist or you don't have access to view them`
        )}
      />
    )

  return (
    <Layout
      title={
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold">{t('Access Control')}:</h1>
          <AdminSelect />
        </div>
      }
      description={t('Manage access control settings for this admin.')}
      breadcrumbs={[
        {
          name: t('Admins'),
          route: '../../',
        },
        {
          name: `${adminQuery.data.person.firstName} ${adminQuery.data.person.lastName}`,
          route: '../',
        },
        {
          name: t('Access Control'),
        },
      ]}
      controls={
        <Button
          type="primary-filled"
          onClick={() => setIsAssignPoliciesFormOpen(true)}
        >
          {t('Assign Policies')}
        </Button>
      }
    >
      <FadeInSlideDown delay={delay()}>
        <Title>
          {t('Assigned Policies')} (
          {` (${
            //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            adminQuery.data.policies?.length || '0'
          })`}
          )
        </Title>
        <AssignedPoliciesList
          userType="admin"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          attachedPolicies={adminPolicies}
          onRemovePolicy={(policyToRemove) => {
            updateAdminPoliciesMutation.mutate({
              adminId,
              policies: adminPolicies.filter(
                (policy) => policy.id !== policyToRemove.id
              ),
            })
          }}
          onUpdatePolicies={(newPolicies) => {
            updateAdminPoliciesMutation.mutate({
              adminId,
              policies: newPolicies,
            })
          }}
          isAssignPoliciesFormOpen={isAssignPoliciesFormOpen}
          setIsAssignPoliciesFormOpen={setIsAssignPoliciesFormOpen}
        />
      </FadeInSlideDown>
    </Layout>
  )
}

export default AdminAccessControl

const Title = tw.h3`text-xl font-semibold mb-2`
