import { isPresent, hideEmail, typedOrgsApi, toUpdateObj } from 'utils'
import { useTranslation } from 'react-i18next'
import faker from 'faker'
import { useMutation } from 'hooks'
import { updateFnConstructor } from 'hooks/useMutation'

const useUpdateResponsiblePartyMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrgsAPIResponse['PATCH']['/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}'],
    updateResponsiblePartyArgs
  >({
    mutationFn: updateResponsibleParty,
    successMsg: {
      message: t('Responsible party updated'),
      showOn: 'onSuccess',
    },
    optimisticUpdates: [
      {
        cacheKey: ({ subscriberId }) => [
          'getResponsiblePartiesQuery',
          subscriberId,
        ],
        updateFn: updateFnConstructor<
          OrgsAPIResponse['GET']['/subscribers-profile/{subscriberId}/responsible-parties'],
          updateResponsiblePartyArgs
        >((oldData, { updateResponsiblePartyForm, responsiblePartyId }) => {
          const filteredRp = oldData?.items?.filter(
            (rp) => rp.id !== responsiblePartyId
          )

          const responsibleParty = oldData?.items?.find(
            (rp) => rp.id === responsiblePartyId
          )
          if (!oldData || !oldData.items?.[0] || !responsibleParty)
            return undefined

          return {
            ...oldData,
            items: [
              ...(filteredRp || []),
              {
                ...responsibleParty,
                priority:
                  updateResponsiblePartyForm.priority ||
                  responsibleParty.priority,

                person: {
                  id: faker.random.uuid(),
                  salutation: updateResponsiblePartyForm.salutation || 1,
                  suffix: updateResponsiblePartyForm.suffix || '',
                  firstName: updateResponsiblePartyForm.firstName,
                  lastName: updateResponsiblePartyForm.lastName,

                  // hide email when updating it
                  emailAddress: updateResponsiblePartyForm.contactEmailAddress
                    ? hideEmail({
                        email: updateResponsiblePartyForm.contactEmailAddress,
                      })
                    : responsibleParty.person.emailAddress ||
                      oldData.items[0].person.emailAddress,
                },
              },
            ],
          }
        }),
      },
    ],
  })

  return mutation
}
export default useUpdateResponsiblePartyMutation

type updateResponsiblePartyArgs = {
  subscriberId: string
  responsiblePartyId: string
  updateResponsiblePartyForm: UpdateResponsiblePartiesForm
  phoneNumbers?: ResponsibleParty['phoneNumbers']
  addresses?: ResponsibleParty['addresses']
}

const updateResponsibleParty = async ({
  subscriberId,
  updateResponsiblePartyForm,
  responsiblePartyId,
  phoneNumbers,
  addresses,
}: updateResponsiblePartyArgs) => {
  const body = [
    toUpdateObj('relationship', updateResponsiblePartyForm.relationship),
    toUpdateObj('firstName', updateResponsiblePartyForm.firstName),
    toUpdateObj('lastName', updateResponsiblePartyForm.lastName),
    toUpdateObj('priority', updateResponsiblePartyForm.priority),
  ]

  // push salutation to request
  if (updateResponsiblePartyForm.salutation !== undefined) {
    body.push(toUpdateObj('salutation', updateResponsiblePartyForm.salutation))
  }

  // push suffix to request
  if (updateResponsiblePartyForm.suffix !== undefined) {
    body.push(toUpdateObj('suffix', updateResponsiblePartyForm.suffix))
  }

  // update email if given
  if (updateResponsiblePartyForm.contactEmailAddress !== undefined)
    body.push(
      toUpdateObj(
        'contactEmailAddress',
        updateResponsiblePartyForm.contactEmailAddress
      )
    )

  // update phone number if given
  if (phoneNumbers)
    await typedOrgsApi.patch(
      `/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}/phone-numbers/{phoneNumberId}`,
      {
        body: [
          toUpdateObj('number', phoneNumbers[0].number),
          toUpdateObj('type', phoneNumbers[0].type),
          toUpdateObj('extension', phoneNumbers[0].extension || ''),
        ],
        pathParams: {
          subscriberId,
          responsiblePartyId,
          phoneNumberId: phoneNumbers[0].id,
        },
      }
    )

  // update addresses if given
  if (addresses)
    await typedOrgsApi.patch(
      `/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}/addresses/{addressId}`,
      {
        body: Object.entries(addresses[0])
          .filter(
            (entries): entries is [string, string] =>
              // remove 'id' key and null/undefined values from object before converting to array of updateObjs
              entries[0] !== 'id' && isPresent(entries[1])
          )
          .map(([key, value]) => toUpdateObj(key, value)),
        pathParams: {
          subscriberId,
          responsiblePartyId,
          addressId: addresses[0].id,
        },
      }
    )

  return typedOrgsApi.patch(
    `/subscribers-profile/{subscriberId}/responsible-parties/{responsiblePartyId}`,
    {
      body,
      pathParams: { subscriberId, responsiblePartyId },
    }
  )
}
