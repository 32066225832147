import React, { useEffect, useState } from 'react'
import {
  useForm,
  Controller,
  ResolverSuccess,
  ResolverError,
} from 'react-hook-form'
import { Checkbox, CustomSelect, Dialog, TextField } from 'elements'
import { Button } from 'atlas'
import { useTranslation } from 'react-i18next'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useOrgVitalAssignmentsQuery } from 'hooks/vitals/vital-assignments'
import _ from 'lodash'
import { useDisclaimersQuery } from 'hooks/disclaimers'
import {
  useCreateOrgVitalThresholdsMutation,
  useUpdateOrgVitalThresholdsMutation,
} from 'hooks/vitals/thresholds'
import tw from 'twin.macro'

// validate that a lower threshold field is never higher in value than a higher threshold field
const customResolver = (
  formFields: VitalThresholdFields
):
  | ResolverSuccess<VitalThresholdFields>
  | ResolverError<VitalThresholdFields> => {
  const errorArray = [
    // convert fields to an array of key-val pairs sorted from lowest to highest threshold
    [
      'lowThresholdSeverity3',
      formFields.lowThresholdSeverity3,
      'Low Threshold Severity 3',
    ],
    [
      'lowThresholdSeverity2',
      formFields.lowThresholdSeverity2,
      'Low Threshold Severity 2',
    ],
    [
      'lowThresholdSeverity1',
      formFields.lowThresholdSeverity1,
      'Low Threshold Severity 1',
    ],
    ['normalLow', formFields.normalLow, 'Normal Low'],
    ['normalHigh', formFields.normalHigh, 'Normal High'],
    [
      'highThresholdSeverity1',
      formFields.highThresholdSeverity1,
      'High Threshold Severity 1',
    ],
    [
      'highThresholdSeverity2',
      formFields.highThresholdSeverity2,
      'High Threshold Severity 2',
    ],
    [
      'highThresholdSeverity3',
      formFields.highThresholdSeverity3,
      'High Threshold Severity 3',
    ],
  ]
    // remove all empty fields
    .filter(([, val]) => val !== '' && val !== undefined)
    // convert field values to Numbers
    .map<[string, number, string]>(([key, val, name]) => [
      String(key),
      Number(val),
      String(name),
    ])
    // if a field holds a lesser or equal value than the field before it, return an error for this field
    .filter(
      ([, val], index, array) => index !== 0 && val <= array[index - 1][1]
    )
    // keep only the key and name of the field since that is all that's needed for the error object
    .map(([key, , name]) => [key, name])

  const errors =
    // convert the array of field keys with errors to the errors object RHF expects
    Object.fromEntries(
      errorArray.map(([key, name]) => [
        key,
        {
          type: 'validate',
          message: `${name} cannot have a lesser or equal value than a field before it`,
        },
      ])
    )

  // if normalLow or normalHigh isn't filled in return an error
  if (formFields.normalLow === undefined || formFields.normalLow === '')
    errors.normalLow = { type: 'required', message: 'Normal Low is required' }
  if (formFields.normalHigh === undefined || formFields.normalHigh === '')
    errors.normalHigh = { type: 'required', message: 'Normal High is required' }

  return {
    values: {},
    errors,
  }
}

type OrgAdminAssignVitalSignsFormProps = {
  thresholds: VitalThresholds[]
  onFormFinished: () => void
  thresholdToUpdate?: VitalThresholds | undefined
}

const OrgAdminVitalsThresholdsForm = ({
  thresholds,
  onFormFinished,
  thresholdToUpdate,
}: OrgAdminAssignVitalSignsFormProps) => {
  const { t } = useTranslation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const orgVitalAssignments = useOrgVitalAssignmentsQuery(
    assumedOrganizationRole?.orgID
  )

  const {
    data: thresholdDisclaimers,
    // this is the code for the disclaimer type for Org Vitals Thresholds
  } = useDisclaimersQuery('Vitalsorg-threshold-settings', 'en-US')

  const {
    mutate: createVitalThresholds,
  } = useCreateOrgVitalThresholdsMutation()
  const {
    mutate: updateVitalThresholds,
  } = useUpdateOrgVitalThresholdsMutation()

  const availableVitalAssignments = _.differenceWith(
    orgVitalAssignments.data?.assignedVitalSigns,
    thresholds,
    (vitalSign, threshold) => vitalSign.id === threshold.vitalSign.id
  )

  const [selectedVitalSign, setSelectedVitalSign] = useState<
    VitalAssignment | undefined
  >(
    thresholdToUpdate
      ? orgVitalAssignments.data?.assignedVitalSigns.find(
          (vitalAssignment) =>
            vitalAssignment.id === thresholdToUpdate.vitalSign.id
        )
      : availableVitalAssignments[0]
  )

  const {
    handleSubmit,
    control,
    errors,
    watch,
    reset,
    trigger,
  } = useForm<VitalThresholdFields>({
    resolver: customResolver,
  })

  useEffect(() => {
    const lowThresholdSeverity3 = thresholdToUpdate?.thresholds.find(
      (threshold) => threshold.direction === 'low' && threshold.severity === 3
    )?.limit
    const lowThresholdSeverity2 = thresholdToUpdate?.thresholds.find(
      (threshold) => threshold.direction === 'low' && threshold.severity === 2
    )?.limit
    const lowThresholdSeverity1 = thresholdToUpdate?.thresholds.find(
      (threshold) => threshold.direction === 'low' && threshold.severity === 1
    )?.limit
    const normalHigh = thresholdToUpdate?.normalHigh
    const normalLow = thresholdToUpdate?.normalLow
    const highThresholdSeverity1 = thresholdToUpdate?.thresholds.find(
      (threshold) => threshold.direction === 'high' && threshold.severity === 1
    )?.limit
    const highThresholdSeverity2 = thresholdToUpdate?.thresholds.find(
      (threshold) => threshold.direction === 'high' && threshold.severity === 2
    )?.limit
    const highThresholdSeverity3 = thresholdToUpdate?.thresholds.find(
      (threshold) => threshold.direction === 'high' && threshold.severity === 3
    )?.limit

    // Convert from OrgVitalThresholds format to form format
    reset({
      lowThresholdSeverity3:
        lowThresholdSeverity3 !== undefined
          ? String(lowThresholdSeverity3)
          : lowThresholdSeverity3,
      lowThresholdSeverity2:
        lowThresholdSeverity2 !== undefined
          ? String(lowThresholdSeverity2)
          : lowThresholdSeverity2,
      lowThresholdSeverity1:
        lowThresholdSeverity1 !== undefined
          ? String(lowThresholdSeverity1)
          : lowThresholdSeverity1,
      normalHigh: normalHigh !== undefined ? String(normalHigh) : normalHigh,
      normalLow: normalLow !== undefined ? String(normalLow) : normalLow,
      highThresholdSeverity1:
        highThresholdSeverity1 !== undefined
          ? String(highThresholdSeverity1)
          : highThresholdSeverity1,
      highThresholdSeverity2:
        highThresholdSeverity2 !== undefined
          ? String(highThresholdSeverity2)
          : highThresholdSeverity2,
      highThresholdSeverity3:
        highThresholdSeverity3 !== undefined
          ? String(highThresholdSeverity3)
          : highThresholdSeverity3,
      // Require user to check all disclaimers again even if updating
      acceptedDisclaimers: thresholdDisclaimers?.items?.map(() => false) || [
        false,
      ],
    })
    if (thresholdToUpdate)
      setSelectedVitalSign(
        orgVitalAssignments.data?.assignedVitalSigns.find(
          (vitalAssignment) =>
            vitalAssignment.id === thresholdToUpdate.vitalSign.id
        )
      )
  }, [thresholdToUpdate, thresholdDisclaimers])
  return (
    <>
      <VitalsThresholdsForm>
        <ThresholdsContainer>
          {!thresholdToUpdate ? (
            <CustomSelect<VitalAssignment>
              className="w-full"
              onChange={(e) => setSelectedVitalSign(e.value)}
              value={selectedVitalSign}
              options={availableVitalAssignments.map((vitalAssignment) => ({
                value: vitalAssignment,
                label: `${vitalAssignment.name} [${
                  vitalAssignment.displayName
                }] (${
                  vitalAssignment.units.find(
                    (unit) => unit.code === vitalAssignment.defaultUnitCode
                  )?.displayName
                })`,
              }))}
              data-testid="vital-sign-select"
            />
          ) : (
            <div>
              <VitalTitle>{thresholdToUpdate.vitalSign.name}</VitalTitle>
              <p>{`[${thresholdToUpdate.vitalSign.displayName}] (${
                // find the org assignment matching the vitalSign and use the org's defaultUnitCode
                (() => {
                  const vitalAssignment = orgVitalAssignments.data?.assignedVitalSigns.find(
                    (orgAssignment) =>
                      orgAssignment.id === thresholdToUpdate.vitalSign.id
                  )
                  return vitalAssignment?.units.find(
                    (unit) => unit.code === vitalAssignment.defaultUnitCode
                  )?.displayName
                })()
              })`}</p>
            </div>
          )}
        </ThresholdsContainer>
        {/* LOW Threshold Fields */}
        <LowSeverityThree>
          <Controller
            className="ml-1"
            control={control}
            as={TextField}
            name="lowThresholdSeverity3"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-1 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="lowThresholdSeverity3"
            // Just show error styling, not error message
            error={errors.lowThresholdSeverity3?.message && ' '}
          />
          <SeverityUnit>
            {
              selectedVitalSign?.units.find(
                (unit) => unit.code === selectedVitalSign.code
              )?.displayName
            }
          </SeverityUnit>
        </LowSeverityThree>
        <LowSeverityTwo>
          <Controller
            className="ml-1"
            control={control}
            as={TextField}
            name="lowThresholdSeverity2"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-2 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="lowThresholdSeverity2"
            // Just show error styling, not error message
            error={errors.lowThresholdSeverity2?.message && ' '}
          />{' '}
          <SeverityUnit>
            {
              selectedVitalSign?.units.find(
                (unit) => unit.code === selectedVitalSign.code
              )?.displayName
            }
          </SeverityUnit>
        </LowSeverityTwo>
        <LowSeverityOne>
          <Controller
            className="ml-1"
            control={control}
            as={TextField}
            name="lowThresholdSeverity1"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-2 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="lowThresholdSeverity1"
            // Just show error styling, not error message
            error={errors.lowThresholdSeverity1?.message && ' '}
          />{' '}
          <SeverityUnit>
            {
              selectedVitalSign?.units.find(
                (unit) => unit.code === selectedVitalSign.code
              )?.displayName
            }
          </SeverityUnit>
        </LowSeverityOne>
        <NormalSeverity>
          <Controller
            className="mx-1"
            control={control}
            as={TextField}
            name="normalLow"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-2 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="normalLow"
            rules={{
              //@ts-expect-error it's fine if the required message is undefined
              required: t('Normal Low is required'),
            }}
            // Just show error styling, not error message
            error={errors.normalLow?.message && ' '}
            required
          />{' '}
          {'-'}
          <Controller
            className="mx-1"
            control={control}
            as={TextField}
            name="normalHigh"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-2 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="normalHigh"
            rules={{
              //@ts-expect-error it's fine if the required message is undefined
              required: t('Normal High is required'),
            }}
            // Just show error styling, not error message
            error={errors.normalHigh?.message && ' '}
            required
          />{' '}
        </NormalSeverity>
        {/* HIGH Threshold Fields */}
        <HighSeverityOne>
          <Controller
            className="ml-1"
            control={control}
            as={TextField}
            name="highThresholdSeverity1"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-2 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="highThresholdSeverity1"
            // Just show error styling, not error message
            error={errors.highThresholdSeverity1?.message && ' '}
          />{' '}
          <SeverityUnit>
            {
              selectedVitalSign?.units.find(
                (unit) => unit.code === selectedVitalSign.code
              )?.displayName
            }
          </SeverityUnit>
        </HighSeverityOne>
        <HighSeverityTwo>
          <Controller
            className="ml-1"
            control={control}
            as={TextField}
            name="highThresholdSeverity2"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-2 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="highThresholdSeverity2"
            // Just show error styling, not error message
            error={errors.highThresholdSeverity2?.message && ' '}
          />{' '}
          <SeverityUnit>
            {
              selectedVitalSign?.units.find(
                (unit) => unit.code === selectedVitalSign.code
              )?.displayName
            }
          </SeverityUnit>
        </HighSeverityTwo>
        <HighSeverityThree>
          <Controller
            className="ml-1"
            control={control}
            as={TextField}
            name="highThresholdSeverity3"
            type="number"
            InputProps={{ className: 'bg-white overflow-hidden' }}
            inputProps={{ className: 'px-2 py-2 text-xl' }}
            disableHelperText
            noMargin
            data-testid="highThresholdSeverity3"
            // Just show error styling, not error message
            error={errors.highThresholdSeverity3?.message && ' '}
          />{' '}
          <SeverityUnit>
            {
              selectedVitalSign?.units.find(
                (unit) => unit.code === selectedVitalSign.code
              )?.displayName
            }
          </SeverityUnit>
        </HighSeverityThree>
        {/* FORM BUTTONS */}
        <ButtonsContainer>
          <Button
            type="primary-filled"
            onClick={async () => (await trigger()) && setIsDialogOpen(true)}
          >
            {thresholdToUpdate ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button
            type="secondary"
            onClick={() => {
              // Close form drawer
              onFormFinished()
            }}
          >
            {t('Cancel')}
          </Button>
          {Object.values(errors).length > 0 ? (
            <InputWarning>
              {(() => {
                const errorValues = Object.values(errors)[0]
                return Array.isArray(errorValues)
                  ? errorValues[0]?.message
                  : errorValues?.message
              })()}
            </InputWarning>
          ) : null}
        </ButtonsContainer>
        <Dialog
          open={isDialogOpen}
          title={
            thresholdToUpdate
              ? `${t('Are you sure you want to update thresholds for')} ${
                  thresholdToUpdate.vitalSign.name
                }?`
              : `${t('Are you sure you want to create thresholds for')} ${
                  selectedVitalSign?.name
                }?`
          }
          content={
            <>
              <DisclaimersContainer>
                {t(
                  `Accept the disclaimers below to complete thresholds creation`
                )}
              </DisclaimersContainer>
              {thresholdDisclaimers?.items
                ? thresholdDisclaimers.items.map((disclaimer, index) => (
                    <Controller
                      key={disclaimer.id}
                      control={control}
                      render={({ onChange, value, ref }) => (
                        <Checkbox
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                          ref={ref}
                          label={disclaimer.disclaimer}
                          className="py-0.5"
                        />
                      )}
                      name={`acceptedDisclaimers[${index}]`}
                    />
                  ))
                : null}
            </>
          }
          actions={
            <>
              <Button onClick={() => setIsDialogOpen(false)} type="secondary">
                {t('Cancel')}
              </Button>
              <Button
                type="primary-filled"
                disabled={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !watch('acceptedDisclaimers')?.every((checked) => checked)
                }
                onClick={handleSubmit(async () => {
                  // convert formData to API format
                  const vitalThresholds: VitalThresholdsForm = {
                    acceptedDisclaimers:
                      thresholdDisclaimers?.items?.map(
                        (disclaimer) => disclaimer.id
                      ) || [],
                    normalLow: Number(watch().normalLow) || 0,
                    normalHigh: Number(watch().normalHigh) || 0,
                    thresholds: thresholdFieldsToArray(watch()),
                  }

                  // mutation to call when updating
                  if (thresholdToUpdate) {
                    const vitalSign = orgVitalAssignments.data?.assignedVitalSigns.find(
                      (vitalAssignment) =>
                        thresholdToUpdate.vitalSign.id === vitalAssignment.id
                    )

                    vitalSign &&
                      updateVitalThresholds({
                        vitalThresholds,
                        orgId: assumedOrganizationRole?.orgID || '',
                        vitalSign,
                      })
                  }
                  // mutation to call when creating
                  else
                    selectedVitalSign &&
                      createVitalThresholds({
                        newVitalThresholds: vitalThresholds,
                        orgId: assumedOrganizationRole?.orgID || '',
                        vitalSign: selectedVitalSign,
                      })

                  onFormFinished()
                })}
              >
                {thresholdToUpdate
                  ? t('Update Thresholds')
                  : t('Create Thresholds')}
              </Button>
            </>
          }
        />
      </VitalsThresholdsForm>
    </>
  )
}

export default OrgAdminVitalsThresholdsForm

interface VitalThresholdFields {
  normalHigh: string | undefined
  normalLow: string | undefined
  lowThresholdSeverity1: string | undefined
  lowThresholdSeverity2: string | undefined
  lowThresholdSeverity3: string | undefined
  highThresholdSeverity1: string | undefined
  highThresholdSeverity2: string | undefined
  highThresholdSeverity3: string | undefined
  acceptedDisclaimers: boolean[]
}

const thresholdFieldsToArray = (formData: VitalThresholdFields) => {
  const thresholdsArray: Threshold[] = []

  if (
    formData.lowThresholdSeverity1 !== undefined &&
    formData.lowThresholdSeverity1 !== ''
  )
    thresholdsArray.push({
      severity: 1,
      direction: 'low',
      limit: Number(formData.lowThresholdSeverity1),
    })
  if (
    formData.lowThresholdSeverity2 !== undefined &&
    formData.lowThresholdSeverity2 !== ''
  )
    thresholdsArray.push({
      severity: 2,
      direction: 'low',
      limit: Number(formData.lowThresholdSeverity2),
    })
  if (
    formData.lowThresholdSeverity3 !== undefined &&
    formData.lowThresholdSeverity3 !== ''
  )
    thresholdsArray.push({
      severity: 3,
      direction: 'low',
      limit: Number(formData.lowThresholdSeverity3),
    })
  if (
    formData.highThresholdSeverity1 !== undefined &&
    formData.highThresholdSeverity1 !== ''
  )
    thresholdsArray.push({
      severity: 1,
      direction: 'high',
      limit: Number(formData.highThresholdSeverity1),
    })
  if (
    formData.highThresholdSeverity2 !== undefined &&
    formData.highThresholdSeverity2 !== ''
  )
    thresholdsArray.push({
      severity: 2,
      direction: 'high',
      limit: Number(formData.highThresholdSeverity2),
    })
  if (
    formData.highThresholdSeverity3 !== undefined &&
    formData.highThresholdSeverity3 !== ''
  )
    thresholdsArray.push({
      severity: 3,
      direction: 'high',
      limit: Number(formData.highThresholdSeverity3),
    })

  return thresholdsArray
}

const VitalsThresholdsForm = tw.form`col-span-12 xl:col-span-10 grid grid-cols-12 xl:grid-cols-10 border-gray-800`

const ThresholdsContainer = tw.div`col-span-4 xl:col-span-3 border-t border-b border-gray-800 px-4 py-2 flex justify-between items-center`

const VitalTitle = tw.h5`font-semibold text-lg`

const SeverityUnit = tw.span`text-sm pt-4 mx-0.5`

const LowSeverityThree = tw.div`bg-red-300 border-l border-t border-b border-gray-800 flex items-center`

const LowSeverityTwo = tw.div`bg-orange-300 border-l border-t border-b border-gray-800 flex items-center`

const LowSeverityOne = tw.div`bg-amber-200 border-l border-t border-b border-gray-800 flex items-center`

const NormalSeverity = tw.div`bg-emerald-200 border-l border-t border-b border-gray-800 flex items-center text-xl col-span-2 xl:col-span-1`

const HighSeverityOne = tw.div`bg-amber-200 border-l border-t border-b border-gray-800 flex items-center`

const HighSeverityTwo = tw.div`bg-orange-300 border-l border-t border-b border-gray-800 flex items-center`

const HighSeverityThree = tw.div`bg-red-300 border-l border-t border-b border-gray-800 flex items-center`

const ButtonsContainer = tw.div`col-span-12 xl:col-span-10 flex p-2`

const InputWarning = tw.div`text-center flex-grow text-red-600 py-2`

const DisclaimersContainer = tw.p`mb-2`
