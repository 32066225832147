import React, { useEffect, useState } from 'react'
import {
  LoadingIcon,
  RightPopup,
  TextField,
  Checkbox,
  ConfirmationDialogs,
} from 'elements'
import { Button } from 'atlas'
import _ from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import {
  useReminderMessageTypesQuery,
  useCreateReminderMessagesMutation,
  useReminderMessagesQuery,
  useUpdateReminderMessageTranslationsMutation,
} from 'hooks/reminders'
import { useDisclaimersQuery } from 'hooks/disclaimers'
import { useTranslation } from 'react-i18next'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import tw from 'twin.macro'

type OrganizationCreateEditReminderMessagesFormProps = {
  isFormOpen: boolean
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  shouldEdit: boolean
}

const OrganizationCreateEditReminderMessagesForm = ({
  isFormOpen,
  setIsFormOpen,
  shouldEdit,
}: OrganizationCreateEditReminderMessagesFormProps) => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const [defaultCheckbox, setDefaultCheckbox] = useState<boolean>(true)
  const [isDisableInputs, setIsDisableInputs] = useState<boolean>(true)
  const [isDisclaimerDialogOpen, setIsDisclaimerDialogOpen] = useState<boolean>(
    false
  )

  const { t } = useTranslation()
  const formMethods = useForm<EditReminderForm>({ mode: 'onChange' })

  const reminderMessageTypes = useReminderMessageTypesQuery()

  const reminderMessages = useReminderMessagesQuery(
    assumedOrganizationRole?.orgID || ''
  )

  // the key provided is for test purposes for ECG Reminders, it is the org 'ECG Admin'
  const defaultECGReminders = useReminderMessagesQuery(
    '7151e6ce-07e1-4a81-823e-6db3f5f14dd5'
  )

  const remindersDisclaimers = useDisclaimersQuery(
    'Vitalsorg-reminder-messages-updates',
    'en-US'
  )

  const [isDisclaimersConfirmed, setIsDisclaimersConfirmed] = useState<
    Array<boolean>
  >(() => remindersDisclaimers.data?.items?.map(() => false) || [false])

  const { mutate: createReminderMessage } = useCreateReminderMessagesMutation()

  const {
    mutate: updateReminderMessageTranslation,
  } = useUpdateReminderMessageTranslationsMutation()

  const { handleSubmit, errors, register, setValue, watch } = formMethods

  // Refetch form fields if isFormOpen is toggled
  useEffect(() => {
    reminderMessages.refetch()
    // reset array of booleans when Dialog is opened
    if (isDisclaimerDialogOpen)
      setIsDisclaimersConfirmed(
        remindersDisclaimers.data?.items?.map(() => false) || [false]
      )
  }, [isFormOpen, isDisclaimerDialogOpen])

  const confirmations = [
    // Archive Dialog
    {
      setIsOpen: setIsDisclaimerDialogOpen,
      isOpen: isDisclaimerDialogOpen,
      title: t(`Update Reminder Messages`),
      content: (
        <div>
          {remindersDisclaimers.data?.items?.map((disclaimer, index) => {
            return (
              <CheckboxContainer key={disclaimer.id}>
                <Checkbox
                  onChange={(e) => {
                    // update state for specific item in the disclaimersConfirmed array
                    setIsDisclaimersConfirmed((currentCheckedDisclaimers) => {
                      const newCheckedDisclaimers = [
                        ...currentCheckedDisclaimers,
                      ]
                      newCheckedDisclaimers[index] = e.target.checked
                      return newCheckedDisclaimers
                    })
                  }}
                  label={disclaimer.disclaimer}
                />
              </CheckboxContainer>
            )
          })}
        </div>
      ),
      actions: (
        <Button
          onClick={handleSubmit(async (formData) => {
            updateReminderMessageTranslation({
              orgId: assumedOrganizationRole?.orgID || '',
              localeKey: 'en-US',
              messages: _.map(formData, (value, key) => {
                return {
                  messageTypeId: key,
                  message: value,
                  reminderMessageId: reminderMessages.data?.items?.find(
                    (message) => message.messageType.id === key
                  )?.id,
                }
              }),
            })
            // Close form drawer
            setIsDisclaimerDialogOpen(false)
            return setIsFormOpen(false)
          })}
          type="primary-filled"
          // ensure every item in the array is true
          disabled={!isDisclaimersConfirmed.every((checked) => checked)}
        >
          {t('Update')}
        </Button>
      ),
    },
  ]

  return (
    <RightPopup
      title={
        shouldEdit
          ? t('Edit Reminder Messages - English (United States)')
          : t('Create Reminder Messages - English (United States)')
      }
      open={isFormOpen}
      setOpen={setIsFormOpen}
      width="800px"
      controls={
        <>
          <Button
            type="primary-filled"
            onClick={handleSubmit(async (formData) => {
              // structure reminders
              // Default reminders key will always be en-US
              if (!shouldEdit && isDisableInputs) {
                createReminderMessage({
                  orgId: assumedOrganizationRole?.orgID || '',
                  localeKey: 'en-US',
                  messages: _.map(defaultECGReminders.data?.items, (value) => {
                    return {
                      messageTypeId: value.messageType.id,
                      message: value.message,
                    }
                  }),
                })
                setIsFormOpen(false)
                return
              }

              if (shouldEdit) {
                setIsDisclaimerDialogOpen(true)
              } else {
                createReminderMessage({
                  orgId: assumedOrganizationRole?.orgID || '',
                  localeKey: 'en-US',
                  messages: _.map(formData, (value, key) => {
                    return {
                      messageTypeId: key,
                      message: value,
                      reminderMessageId: reminderMessages.data?.items?.find(
                        (message) => message.messageType.id === key
                      )?.id,
                    }
                  }),
                })
                // Close form drawer
                setIsFormOpen(false)
              }
            })}
          >
            {shouldEdit ? t('Update') : t('Create')}
          </Button>
          &nbsp;
          <Button type="secondary" onClick={() => setIsFormOpen(false)}>
            {t('Cancel')}
          </Button>
        </>
      }
    >
      {reminderMessages.isLoading ||
      reminderMessageTypes.isLoading ||
      defaultECGReminders.isLoading ||
      remindersDisclaimers.isLoading ? (
        <LoadingIcon />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            {!shouldEdit ? (
              <Checkbox
                className="mb-4"
                label={t('Use ECG default messages for organization')}
                checked={defaultCheckbox}
                onChange={() => {
                  if (!defaultCheckbox) {
                    setIsDisableInputs(true)
                    defaultECGReminders.data?.items?.forEach((item) => {
                      setValue(item.messageType.id, item.message)
                    })
                    setDefaultCheckbox(!defaultCheckbox)
                    return
                  }
                  setIsDisableInputs(false)
                  setDefaultCheckbox(!defaultCheckbox)
                  return
                }}
              />
            ) : null}
            {/* Order by sort order then display */}
            {_.orderBy(
              reminderMessageTypes.data?.items,
              (reminderMessage) => reminderMessage.displayOrder
            ).map((item, index) => (
              <TextField
                key={index}
                className="mb-8"
                helperText={item.description}
                error={errors[item.name || index]?.message}
                name={item.id}
                inputRef={register({
                  required: `${item.name} ${t('message is required')}`,
                })}
                // Change label to error message if one exists
                label={item.name}
                shrink={!!watch(item.id)}
                defaultValue={
                  shouldEdit
                    ? reminderMessages.data?.items?.find(
                        (message) => message.messageType.id === item.id
                      )?.message
                    : defaultECGReminders.data?.items?.[index].message
                }
                fullWidth
                disabled={!shouldEdit && isDisableInputs}
                required
              />
            ))}
          </form>
        </FormProvider>
      )}
      <ConfirmationDialogs confirmations={confirmations} />
    </RightPopup>
  )
}

export default OrganizationCreateEditReminderMessagesForm

const CheckboxContainer = tw.div`flex flex-row`
