import { useQuery } from 'react-query'
import { vitalsApi } from 'utils'

// used to get default reminders
const useReminderPresetsQuery = (orgId: string) => {
  const query = useQuery<
    VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets'],
    KyError
  >(['getReminderPresets', orgId], () => getReminderPresets(orgId))

  return query
}

export default useReminderPresetsQuery

// get admin reminders
type getReminderPresetsType = (
  orgId: string
) => Promise<
  VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
>

const getReminderPresets: getReminderPresetsType = async (orgId) => {
  const result = await vitalsApi
    .get(`organizations/${orgId}/reminder-presets`)
    .json<
      VitalsAPIResponse['GET']['/organizations/{organizationId}/reminder-presets']
    >()
  return result
}
