import React, { useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import {
  OrganizationCreateEditReminderMessagesForm,
  OrganizationReminderMessagesList,
  OrganizationCreateTranslationForm,
  OrganizationEditTranslationForm,
} from 'components/org-admin-reminder-messages'
import { FadeInSlideDown } from 'animations'
import { Button, Empty } from 'atlas'
import { Layout, Icon, CustomSelect } from 'elements'
import { useTranslation } from 'react-i18next'
import {
  useReminderMessagesQuery,
  useAvailableTranslationsQuery,
} from 'hooks/reminders'
import { useLocalesQuery } from 'hooks/locales'
import tw, { styled } from 'twin.macro'
import { useNavigate } from 'hooks'

const OrganizationReminderMessages = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()

  const { data: locales, isLoading: localesIsLoading } = useLocalesQuery()
  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [
    isEditTranslationFormOpen,
    setIsEditTranslationFormOpen,
  ] = useState<boolean>(false)
  const [selectedTranslation, setSelectedTranslation] = useState<{
    key: string
    name: string
  }>({ key: 'en-US', name: 'English (United States)' })

  const { data: reminderMessage, isLoading } = useReminderMessagesQuery(
    assumedOrganizationRole?.orgID || ''
  )

  const {
    data: availableTranslations,
    isLoading: translationsIsLoading,
  } = useAvailableTranslationsQuery(assumedOrganizationRole?.orgID || '')
  const [
    isCreateTranslationFormOpen,
    setIsCreateTranslationFormOpen,
  ] = useState<boolean>(false)

  const selectLocale = () => {
    if (availableTranslations?.items) {
      const options = availableTranslations.items.map((translation) => {
        return {
          value: translation.locale.key,
          label: (
            <div className="flex items-center">
              <Icon className="h-4 w-4 mr-2" type="locale" />
              {translation.locale.name}
            </div>
          ),
        }
      })

      return options
    }

    return [{ value: 'null', label: 'null' }]
  }

  return (
    <>
      <Layout
        title={t('Reminder Messages')}
        description={t(
          'Create and manage organization reminder messages and translations.'
        )}
        isLoading={isLoading || translationsIsLoading || localesIsLoading}
        isEmpty={!reminderMessage?.items || reminderMessage.items.length <= 0}
        emptyComponent={
          <ReminderMessagesEmpty
            title={t('No Data Found')}
            description={t(
              'No reminder messages have been created for this organization'
            )}
            callToAction={
              <Button
                type="primary-filled"
                onClick={() => setIsCreateFormOpen(true)}
              >
                {t('Create Reminder Messages')}
              </Button>
            }
          />
        }
        isUnauthorized={!assumedOrganizationRole}
        breadcrumbs={[
          { name: t('Organization Settings'), route: '/organization-settings' },
          { name: t('Reminder Messages') },
        ]}
        controls={[
          {
            label: t('Create New Translation'),
            onClick: () => setIsCreateTranslationFormOpen(true),
            disabled:
              !availableTranslations?.items ||
              !locales?.items ||
              !reminderMessage?.items?.length ||
              availableTranslations.items.length >= locales.items.length,
          },
          {
            label: t('View Audit Trail'),
            onClick: () => navigate('audit-trail'),
          },
        ]}
      >
        <FadeInSlideDown delay={0.2}>
          <MessagesContainer>
            <Title>
              {t('Reminder Messages - ')}
              {selectedTranslation.name}
            </Title>
            <SelectContainer>
              <Edit
                onClick={() => {
                  if (selectedTranslation.key === 'en-US') {
                    return setIsCreateFormOpen(true)
                  } else return setIsEditTranslationFormOpen(true)
                }}
              >
                <EditIcon data-testid="edit-reminder-messages" type="edit" />
                Edit
              </Edit>

              <Trash
                englishTranslation={selectedTranslation.key === 'en-US'}
                onClick={() => {
                  if (selectedTranslation.key !== 'en-US')
                    return setIsDialogOpen(true)
                }}
              >
                <TrashIcon
                  data-testid="delete-reminder-messages"
                  type="trash"
                  disabled={selectedTranslation.key === 'en-US'}
                />
                Delete
              </Trash>

              <CustomSelect<string>
                className={'w-56'}
                options={selectLocale()}
                value={selectedTranslation.key}
                onChange={(e) => {
                  const translationName = availableTranslations?.items?.find(
                    (translation) => translation.locale.key === e.value
                  )?.locale.name
                  setSelectedTranslation({
                    key: e.value,
                    name: translationName || '',
                  })
                }}
                variant="thin"
              />
            </SelectContainer>
          </MessagesContainer>
        </FadeInSlideDown>
        <OrganizationReminderMessagesList
          selectedLocale={selectedTranslation}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      </Layout>
      <OrganizationCreateEditReminderMessagesForm
        isFormOpen={isCreateFormOpen}
        setIsFormOpen={setIsCreateFormOpen}
        shouldEdit={
          !(!reminderMessage?.items || reminderMessage.items.length <= 0)
        }
      />

      <OrganizationCreateTranslationForm
        isFormOpen={isCreateTranslationFormOpen}
        setIsFormOpen={setIsCreateTranslationFormOpen}
      />
      <OrganizationEditTranslationForm
        isFormOpen={isEditTranslationFormOpen}
        setIsFormOpen={setIsEditTranslationFormOpen}
        selectedTranslation={selectedTranslation}
      />
    </>
  )
}

export default OrganizationReminderMessages

const ReminderMessagesEmpty = tw(
  Empty
)`flex flex-grow border-2 border-dashed rounded`

const MessagesContainer = tw.div`flex flex-row justify-between items-center mb-2`

const Title = tw.h3`text-xl font-semibold`

const SelectContainer = tw.div`flex items-center`

const Edit = tw.span`flex items-center mr-4 hover:text-blue-400 cursor-pointer`

const EditIcon = tw(Icon)`h-4 w-4 mr-1`

const Trash = styled.span<{ englishTranslation: boolean }>(
  ({ englishTranslation }) => [
    tw`flex items-center mr-4 hover:text-red-400 cursor-pointer`,
    englishTranslation && tw`opacity-50 cursor-not-allowed hover:text-black`,
  ]
)

const TrashIcon = tw(Icon)`h-4 w-4 mr-1`
