import React, { useState } from 'react'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { LoadingIcon } from 'elements'
import { Button, Empty } from 'atlas'
import { FadeInSlideDown } from 'animations'
import {
  useAssignVitalSignsMutation,
  useOrgVitalAssignmentsQuery,
} from 'hooks/vitals/vital-assignments'
import OrgAdminVitalSignCard from './OrgAdminVitalSignCard'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import OrgEditVitalAssignmentForm from './OrgEditVitalAssignmentForm'
import { useECGVitalSignsQuery } from 'hooks/vitals'
import tw from 'twin.macro'
import { assignmentsToDisplayAssignments } from 'utils'

const OrgAdminVitalSignsGrid = () => {
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const { t } = useTranslation()

  const [
    isEditVitalAssignmentFormOpen,
    setIsEditVitalAssignmentFormOpen,
  ] = useState<boolean>(false)
  const [vitalAssignmentToEdit, setVitalAssignmentToEdit] = useState<
    VitalAssignment | undefined
  >()

  const { isLoading, data: vitalAssignments } = useOrgVitalAssignmentsQuery(
    assumedOrganizationRole?.orgID
  )
  const { data: ECGVitalSigns } = useECGVitalSignsQuery()

  // we only want active vital signs that aren't archived
  const activeECGVitalSigns = ECGVitalSigns?.items?.filter(
    (vitalSign) =>
      vitalSign.activeInfo.active && !vitalSign.archiveInfo.archived
  )

  const { mutate: assignVitalAssignments } = useAssignVitalSignsMutation()

  const handleEditVitalAssignment = (
    selectedVitalAssignment: VitalAssignment
  ) => {
    setVitalAssignmentToEdit(selectedVitalAssignment)
    setIsEditVitalAssignmentFormOpen(true)
  }

  const orgVitalSigns = _.orderBy(
    vitalAssignments?.assignedVitalSigns.filter(
      (vitalSign) => !vitalSign.archived
    ),
    ['active', 'name'],
    ['desc', 'asc']
  )

  if (isLoading) return <LoadingIcon />

  // If no vitalSigns are returned show empty grid placeholder
  if (!orgVitalSigns.length && activeECGVitalSigns)
    return (
      <VitalSignsEmpty
        title={t('No Data Found')}
        description={t(
          'No vital signs have been assigned to this organization'
        )}
        callToAction={
          <Button
            onClick={() =>
              // create an org vital assignment for each ECG vital sign
              //  VFE-498  Vital sign type needs to be changed to only accepted id and code
              assignVitalAssignments({
                newVitalSigns: activeECGVitalSigns.map((vitalSign) => ({
                  id: vitalSign.id,
                  code: vitalSign.code,
                  name: vitalSign.name,
                  displayName: vitalSign.displayName,
                  active: vitalSign.activeInfo.active,
                  archived: vitalSign.archiveInfo.archived,
                  units: vitalSign.units,
                  defaultUnitCode: vitalSign.defaultUnitCode,
                })),
                orgId: assumedOrganizationRole?.orgID || '',
              })
            }
          >
            {t('Populate Org Vital Signs')}
          </Button>
        }
        delay={0.1}
      />
    )

  return (
    <>
      <VitalSignGridContainer>
        {assignmentsToDisplayAssignments(orgVitalSigns).map(
          (vitalAssignment, index) => (
            <FadeInSlideDown
              delay={0.1 + index * 0.05}
              key={`vitals-card-${index}`}
            >
              <OrgAdminVitalSignCard
                vitalAssignment={vitalAssignment}
                onEditVitalAssignment={() =>
                  handleEditVitalAssignment(vitalAssignment)
                }
              />
            </FadeInSlideDown>
          )
        )}
      </VitalSignGridContainer>
      {vitalAssignments ? (
        <OrgEditVitalAssignmentForm
          isFormOpen={isEditVitalAssignmentFormOpen}
          setIsFormOpen={setIsEditVitalAssignmentFormOpen}
          vitalAssignment={vitalAssignmentToEdit}
          vitalAssignments={vitalAssignments}
        />
      ) : null}
    </>
  )
}

export default OrgAdminVitalSignsGrid

const VitalSignsEmpty = tw(Empty)`border-2 border-dashed rounded flex-grow`

const VitalSignGridContainer = tw.div`grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`
