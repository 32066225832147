import React from 'react'
import { EntityLabel, CustomSelect } from 'elements'
import { useNavigate } from 'hooks'
import { useOrganizationListQuery } from 'hooks/organizations'
import { useParams, useLocation } from 'react-router-dom'
import { useAssumedOrganizationRole } from 'context/assumed-organization-role'
import { useTranslation } from 'react-i18next'

const OrganizationSelect = () => {
  const { orgid } = useParams()
  const { pathname } = useLocation()
  const { assumedOrganizationRole } = useAssumedOrganizationRole()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data: organizationList } = useOrganizationListQuery({
    orgId: assumedOrganizationRole?.orgID,
  })

  const currentOrg = organizationList?.items.find((org) => org.id === orgid)

  const orgSelect = organizationList?.items.map((orgs) => {
    return {
      value: orgs.id,
      label: (
        <>
          <EntityLabel
            name={orgs.businessName}
            id={orgs.id}
            iconOnly
            size="sm"
          />
          {orgs.businessName}{' '}
        </>
      ),
      searchValue: orgs.businessName,
    }
  })

  if (!currentOrg) return null

  return (
    <CustomSelect
      id={orgid}
      className="w-64"
      options={orgSelect || [{ label: '', value: '' }]}
      placeholder={currentOrg.businessName}
      onChange={(e) => navigate(pathname.replace(orgid, e.value))}
      variant="thin"
      searchable
      emptyText={t('No organizations found')}
    />
  )
}

export default OrganizationSelect
