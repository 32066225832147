import useMutation, { updateFnConstructor } from 'hooks/useMutation'
import { useTranslation } from 'react-i18next'
import { ordersApi } from 'utils'

const useReleaseOrderMutation = () => {
  const { t } = useTranslation()

  const mutation = useMutation<
    OrdersAPIResponse['POST']['/orders/{orderId}/provisioning-release'],
    releaseOrderArgs
  >({
    mutationFn: checkoutOrder,
    successMsg: {
      message: t('Order released from provisioning'),
      showOn: 'onSuccess',
    },

    optimisticUpdates: [
      {
        cacheKey: ({ orderId }) => ['order', { orderId }],
        updateFn: updateFnConstructor<
          OrdersAPIResponse['GET']['/orders/{orderId}'],
          releaseOrderArgs
        >((oldCache) => {
          if (!oldCache) return undefined

          return {
            ...oldCache,
            orderStatus: {
              id: 1,
              description: '',
              title: 'Confirmed',
            },
          }
        }),
      },
    ],
    additionalCachesToInvalidate: [
      ({ orderId }) => ['orderActivity', { orderId }],
    ],
  })

  return mutation
}

export default useReleaseOrderMutation

type releaseOrderArgs = { orderId: string }

const checkoutOrder = ({ orderId }: releaseOrderArgs) =>
  ordersApi.post('/orders/{orderId}/provisioning-release', {
    pathParams: { orderId },
  })
